import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import { useMsal, useAccount } from "@azure/msal-react";
import { silentRequest } from "../../../../authConfig";

import { 
    mergeStyles, 
    mergeStyleSets,
    PrimaryButton, 
    Stack, 
    getTheme, 
    Spinner,
    Separator,
    ChoiceGroup,

} from '@fluentui/react';

const API_URL = process.env.REACT_APP_API_URL;

const theme = getTheme();

//Style

const centerItem = { display: "flex", alignItems: "center", justifyContent: "center" };

const stackTokens = { childrenGap: 10 };

const SmsCheckout = () => {

    const history = useHistory();
    //history.go(0);

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const [credit, setCredit] = useState(500);    
    const [price, setPrice] = useState(999);      
    
    const [vatTotal, setVatTotal] = useState(0);    
    const [grandTotal, setGrandTotal] = useState(0);

    const [isLoading, setIsLoading] = useState(true);

    const [subscriptionTo, setSubscriptionTo] = useState('');
    
    const [base64QrCode, setBase64QrCode] = useState('');

    
    // Processing dialog style
    const spinnerStyles = {
        circle: {
          height: 56,
          width: 56,
          borderWidth: 4
        }
    }

    // Success dialog style
    const iconClass = mergeStyles({
        fontSize: 120,
        height: 120,
        width: 120,
        margin: '0 60px',
        //alignContent: 'center'
    });
   
    const calGrandTotal = (_price) => {
        console.log('Price: ', _price);
        let vat = 0.07;        
        let _vatTotal = Math.round((vat*_price) * 100) / 100;
        setVatTotal(_vatTotal);
        setGrandTotal(_price+(_vatTotal));
    }   

    const handleSubmit = async (e) => {
        e.preventDefault();  
        //setProcessing(true);
        // setChargeStatus('');
        setIsLoading(true);


        console.log('Submit!');

        console.log('Credit: ', credit);        
        console.log('Price: ', price);        
        console.log('Vat Total: ', vatTotal);
        console.log('Grand Total: ', grandTotal);        
  
        instance.acquireTokenSilent({...silentRequest, account: account}).then(tokenResponse => {

            axios.post(API_URL + '/gbprimepay/qrcode', 
            {
                'creditType': 'sms',
                'creditAmount': credit
            }, {
                headers: { 
                    'Authorization': 'Bearer ' + tokenResponse.accessToken
                },
                responseType: 'arraybuffer',
            }).then((response) => {

                console.log('GB Prime Pay data: ', response);

                if (response.data) {

                    setBase64QrCode('data:image/png;base64,' + Buffer.from(response.data, 'binary').toString('base64'));
                    
                } 

                setIsLoading(false);
            
            });

        }).catch(error => {           
    
            //Acquire token silent failure, and send an interactive request
            console.log(error);
            instance.acquireTokenRedirect(silentRequest);

            setIsLoading(false);
        });
       
        
    }

    useEffect(() => {

        console.log('userEffect initialize Call!');

        calGrandTotal(price);

        setIsLoading(false);
       
    }, []);

    return (
        <form onSubmit={handleSubmit}>

        {!base64QrCode && !isLoading &&
        <Stack>
            {/* <h2 style={{ marginLeft: '0px', marginTop: '25px' }}>{`เติมเครดิตเอกสาร`}</h2> */}
{/*             <div className="ms-Grid" dir="ltr" style={{ paddingLeft: 30, paddingRight: 30, paddingTop: 30, background: '#ffffff', margin: '2%', boxShadow: theme.effects.elevation4 }}>  */}
            <div style={{ width: '850px', paddingLeft: 30, paddingRight: 30, paddingTop: 30, marginTop: 0, background: '#ffffff', marginLeft: '0px', marginTop: '0px' , boxShadow: theme.effects.elevation4 }}> 
                <div className="ms-Grid-row">                                            
                    <h2 style={{ margin: '0%' }}>{`1. เลือกปริมาณ SMS`}</h2>&nbsp;&nbsp; 
                    {/* <br/> */}
                    <ChoiceGroup 
                        name="plan"
                        label={`เติมเครดิต SMS จำนวน`} 
                        selectedKey={credit}
                        required
                        //styles={{ root: { width: 850 } }} 
                        options={[
                            /* { key: 'FREE', text: 'แพ็กเกจ ฟรี' }, 
                            { key: 'STARTER', text: 'แพ็กเกจ เริ่มต้น 200 เอกสาร/เดือน', iconProps: { iconName: 'CalendarDay' }, imageSize: { width: 150, height: 50 } },
                            { key: 'BUSINESS', text: 'แพ็กเกจ ธุรกิจ 1,000 เอกสาร/เดือน', iconProps: { iconName: 'CalendarDay' }, imageSize: { width: 150, height: 50 } },
                            { key: 'ENTERPRISE', text: 'แพ็กเกจ องค์กร 3,000 เอกสาร/เดือน', iconProps: { iconName: 'CalendarDay' }, imageSize: { width: 150, height: 50 } },                                    
*/
                            { key: 500, text: '              500 SMS (ราคา 999 บาท)             ', imageSrc: '/images/sms-package.png', selectedImageSrc: '/images/sms-package-select.png', imageSize: { height: 50 } },
                            { key: 1000, text: '             1,000 SMS (ราคา 1,499 บาท)           ', imageSrc: '/images/sms-package.png', selectedImageSrc: '/images/sms-package-select.png', imageSize: { height: 50 } },
                            { key: 3000, text: '             3,000 SMS (ราคา 2,999 บาท)           ', imageSrc: '/images/sms-package.png', selectedImageSrc: '/images/sms-package-select.png', imageSize: { height: 50 } },                                    
                            // { key: 500, text: '           500 เอกสาร (ราคา 3,499 บาท)         ', imageSrc: '/images/paper.png', selectedImageSrc: '/images/paper.png', imageSize: { height: 50 } },
                            // { key: 1000, text: '           1,000 เอกสาร (ราคา 4,999 บาท)          ', imageSrc: '/images/paper.png', selectedImageSrc: '/images/paper.png', imageSize: { height: 50 } },
                            // { key: 3000, text: '        3,000 เอกสาร (ราคา 8,999 บาท)        ', imageSrc: '/images/paper.png', selectedImageSrc: '/images/paper.png', imageSize: { height: 50 } },
                        ]} 
                        onChange={ (e, selectedOption) => {
                                console.log(selectedOption);                                        
                                setCredit(selectedOption.key);                                 
                                
                                switch(selectedOption.key) {
                                    case 500:
                                        setPrice(999);                                         
                                        calGrandTotal(999);
                                        //setStartEnd(1);
                                        break;
                                    case 1000:
                                        setPrice(1499);                                         
                                        calGrandTotal(1499);
                                        //setStartEnd(1);
                                        break;
                                    case 3000:
                                        setPrice(2999);                                         
                                        calGrandTotal(2999);
                                        //setStartEnd(1);
                                        break;                                      
                                    default:
                                        setPrice(0);  
                                }
                            }                         
                        }
                    />
                    <br/>                    
                    <br/>  

                  
                </div>
            </div>

            {/* <div className="ms-Grid" dir="ltr" style={{ paddingLeft: 30, paddingRight: 30, paddingTop: 30, background: '#ffffff', margin: '2%', boxShadow: theme.effects.elevation4 }}>  */}
            <div style={{ width: '850px', paddingLeft: 30, paddingRight: 30, paddingTop: 30, background: '#ffffff', marginLeft: '0px', marginTop: '30px' , boxShadow: theme.effects.elevation4 }}> 
                {/* <div className="ms-Grid-row">       */}                                      
                    
                    {/* <br/> */}
                    <Stack horizontal tokens={stackTokens}> 
                        <Stack vertical tokens={stackTokens}>
                            <h2 style={{ margin: '0%' }}>2. เลือกวิธีการชำระเงิน</h2>&nbsp;&nbsp; 
                            <h4 style={{ marginTop: '-5px' }}>เติมเครดิตอัตโนมัติ เร็วทันใจ ตลอด 24 ชม.</h4>
                            <Stack horizontal tokens={stackTokens}> 
                                <img src={'/images/promtpay-logo.png'} width="260" border="0" style={{ marginLeft: '0px', marginTop: '-20px' }}></img>
                                
                            </Stack>
                            {/* <br/> */}
                            
                            <small>*ซื้อแล้ว ไม่คืนเงิน</small>                          
                        

                        </Stack>


                        <Separator vertical /* style={{ marginLeft: '15px' }} *//>

                        <Stack vertical tokens={stackTokens} style={{ marginLeft: '0px', paddingLeft: '15px', /* borderLeft: '2px solid #E8E9E9' */ }} >
                            <h1 style={{ marginTop: '5px', marginLeft: '365px' }}>สรุปคำสั่งซื้อ</h1>                            
                            {/* <hr style={{ marginLeft: '0' }} width="520px" color='#E8E9E9' /> */}
                            <Separator />
                            <h3 style={{ textAlign: 'right' }}>ข้อความ SMS สำหรับส่งเอกสาร ({credit.toLocaleString(undefined, {maximumFractionDigits:0, minimumFractionDigits:0})}&nbsp;SMS)&nbsp;&nbsp;&nbsp;<span style={{ paddingLeft: 40 }}>{price.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2})} บาท</span></h3>
                            {/* <small style={{ marginTop: '-10px', marginLeft: '70px' }}>(สมัครใช้งาน {planStart.toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' })} - {planEnd.toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' })})</small> */}                            
                            <h3 style={{ textAlign: 'right' }}>ภาษีมูลค่าเพิ่ม 7%&nbsp;<span style={{ paddingLeft: 70 }}>{vatTotal.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2})} บาท</span></h3>
                            <br/>
                            {/* <hr style={{ marginLeft: '0' }} width="520px" color='#E8E9E9' /> */}
                            <Separator />
                            <h2 style={{ marginTop: '5px', textAlign: 'right' }}>ยอดรวม<span style={{ paddingLeft: 25 }}>{grandTotal.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2})} บาท</span></h2>
                            
                            <div></div>
                            <center>
                            <br/>
                            <small>หากกดปุ่ม "ชำระเงิน" ถือว่าท่านยอมรับ <a href="https://www.leceipt.com/terms-conditions" target="_blank">เงื่อนไขและข้อตกลงการใช้งาน</a></small>
                            <br/><br/><br/>
                            <Stack horizontal style={centerItem} tokens={{ childrenGap: 30 }} >
                                
                                {!isLoading
                                    ? <PrimaryButton /* iconProps={{ iconName: 'Add' }}*/ type="submit"  text="ชำระเงินผ่าน QR Code" value="pay" style={{ width: 550, height: 50, fontSize: 25, letterSpacing: "1.5px" }}  />
                                    : <Spinner label="กำลังดำเนินการ..." labelPosition="right" />
                                }
                                
                                
                            </Stack>
                            </center>
                        </Stack>
                    </Stack>
                    <br/>   
                    
                    <br/> 
                    <br/>  

                    

                  
                {/* </div> */}
            </div>
            {/* <br/> */}

            <br />
            <br />

            
        </Stack>
        }

        {base64QrCode && !isLoading &&
        
            <Stack vertical>
                <center>
                <h1 style={{ marginLeft: '0px', marginTop: '25px' }}>{`เติมเครดิต: ` + credit.toLocaleString(undefined, {maximumFractionDigits:0, minimumFractionDigits:0}) + ` เอกสาร`}</h1>
                <h3>ระบบจะเติมเครดิตให้อัตโนมัติหลังจากชำระเงิน ตลอด 24 ชม.</h3>
                <img src={base64QrCode} width='433px' />
                </center>
            </Stack>
            
        }

            {isLoading &&
                <Stack>
                    <br/>
                    <br/>
                    <br/>
                    {/* <Stack horizontal style={{ marginLeft: '170px'}}>  */}
                    <Stack horizontal horizontalAlign='center'> 

                        
                        <div>
                            <Spinner /* size={SpinnerSize.large} */ label="กำลังดำเนินการ..." labelPosition="down" styles={spinnerStyles} />
                        </div>
                        <br/>
                        
                    </Stack>

                </Stack>
            }
        </form>
    );
}

export default SmsCheckout;