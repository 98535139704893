import React from 'react';

//center loading
const centerItem = { display: "flex", alignItems: "center", justifyContent: "center" };

const Loading = () => {

    console.log('Loading re-render');    

    return (
        <div>                 
            <br/> 
            <br/>          
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <div style={centerItem}>
                <img width="60" src="/images/loading.gif" alt="loading"/>
            </div>
        </div>
    );
}

export default Loading;