import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

import { useMsal, useAccount } from "@azure/msal-react";
import { silentRequest } from "../../../../authConfig";

import {
    mergeStyles,
    mergeStyleSets,
    PrimaryButton,
    Stack,
    getTheme,
    Spinner,
    Separator,
    ChoiceGroup,
    TextField,
    Dropdown,
} from "@fluentui/react";

import {
    getProvinceList,
    getDistrictList,
    getSubDistrictList,
    getProvince,
    getDistrict,
    getSubDistrict,
} from "../../../../providers/AreaData";

const API_URL = process.env.REACT_APP_API_URL;

const theme = getTheme();

//Style

const centerItem = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
};

const stackTokens = { childrenGap: 10 };

const DocumentsCheckout = () => {
    const history = useHistory();
    //history.go(0);

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const [credit, setCredit] = useState(3000);
    const [price, setPrice] = useState(8999);

    const [vatTotal, setVatTotal] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);

    const [isLoading, setIsLoading] = useState(true);

    const [subscriptionTo, setSubscriptionTo] = useState("");

    const [base64QrCode, setBase64QrCode] = useState("");


    const [company, setCompany] = useState([]);
    const [companyName, setCompanyName] = useState('');
    const [companyPhone, setCompanyPhone] = useState('');
    const [companyEmail, setCompanyEmail] = useState('');
    const [companyTaxId, setCompanyTaxId] = useState('');
    const [companyBranchText, setCompanyBranchText] = useState('');
    const [companyAllAddress, setCompanyAllAddress] = useState('');
    
    //Customer Detail

    const [name, setName] = useState("");
    const [buildingNumber, setBuildingNumber] = useState("");
    const [address, setAddress] = useState("");
    const [streetPrefix, setStreetPrefix] = useState("");
    const [streetName, setStreetName] = useState("");
    const [provincePrefix, setProvincePrefix] = useState("");
    const [province, setProvince] = useState({ key: "", text: "" });
    const [subDistrictPrefix, setSubDistrictPrefix] = useState("");
    const [subDistrict, setSubDistrict] = useState({ key: "", text: "" });
    const [subDistrictList, setSubDistrictList] = useState([]);
    const [districtPrefix, setDistrictPrefix] = useState("");
    const [district, setDistrict] = useState({ key: "", text: "" });
    const [districtList, setDistrictList] = useState([]);
    const [postcode, setPostCode] = useState("");
    const [phone, setPhone] = useState("");
    const [taxNumberType, setTaxNumberType] = useState("");
    const [taxId, setTaxId] = useState("");
    const [branchText, setBranchText] = useState("");
    const [branchDisplay, setBranchDisplay] = useState([
        { key: "00000", text: "สำนักงานใหญ่" },
        { key: "branchNumber", text: "สาขาเลขที่" },
    ]);

    const [branch, setBranch] = useState("00000");
    const [branchNumber, setBranchNumber] = useState("00000");
    const [email, setEmail] = useState("");

    const [showTaxId, setShowTaxId] = useState(true);
    const [showBranch, setShowBranch] = useState(false);
    const [showBranchNumber, setShowBranchNumber] = useState(false);

    const phoneRegEx = /(^0[1-9][0-9]{1,13}$|^$)/g;
    let phoneRef = useRef(null);

    const [phoneNumberError, setPhoneNumberError] = useState(false);

    const [language, setLanguage] = useState("th");

    const provinceList = getProvinceList(language);

    //Error Message
    const [provinceErrMsg, setProvinceErrMsg] = useState("");
    const [districtErrMsg, setDistrictErrMsg] = useState("");
    const [subDistrictErrMsg, setSubDistrictErrMsg] = useState("");

    const [taxNumberTypeErrMsg, setTaxNumberTypeErrMsg] = useState("");

    const DropdownErrMsg = "กรุณาเลือก";

    // Processing dialog style
    const spinnerStyles = {
        circle: {
            height: 56,
            width: 56,
            borderWidth: 4,
        },
    };

    // Success dialog style
    const iconClass = mergeStyles({
        fontSize: 120,
        height: 120,
        width: 120,
        margin: "0 60px",
        //alignContent: 'center'
    });

    const dropdownStyles = {
        dropdown: { width: 150 },
    };

    const calGrandTotal = (_price) => {
        console.log("Price: ", _price);
        let vat = 0.07;
        let _vatTotal = Math.round(vat * _price * 100) / 100;
        setVatTotal(_vatTotal);
        setGrandTotal(_price + _vatTotal);
    };

    const getErrorMessage = (value) => {
        return value && value !== "0" ? "" : "กรุณากรอกข้อมูล";
    };

    const getTaxIdErrorMessage = (value) => {
        if (
            (taxNumberType == "TXID" || taxNumberType == "NIDN") &&
            taxId.length !== 13
        ) {
            return "กรอกเลข 13 หลัก";
        } else if (value === "" || value === null || value === undefined) {
            return "กรุณากรอกข้อมูล";
        } else {
            return "";
        }
    };

    const getBranchNumberErrorMessage = (value) => {
        if (branchNumber.length !== 5) {
            return "กรอกเลข 5 หลัก";
        } else if (value === "" || value === null || value === undefined) {
            return "กรุณากรอกข้อมูล";
        } else {
            return "";
        }
    };

    const getPhoneNumberErrorMessage = (value) => {
        setPhoneNumberError(false);
        if (value === null || value === undefined) {
            setPhoneNumberError(true);
            return "กรุณากรอกข้อมูล";
        } else if (phoneRegEx.test(value) !== true) {
            setPhoneNumberError(true);
            return "รูปแบบไม่ถูกต้อง ตัวอย่าง: 0812345678";
        }
    };

    const setRoadPrefix = (_streetName) => {
        if (_streetName === "") {
            setStreetPrefix("");
        } else {
            if (language === "th") {
                setStreetPrefix("ถ.");
            } else {
                setStreetPrefix("");
            }
        }
    };

    const setAreaPrefix = (_provinceCode) => {
        if (_provinceCode === "10") {
            if (language === "th") {
                setSubDistrictPrefix("แขวง");
                setDistrictPrefix("เขต");
                setProvincePrefix("");
            } else {
                setSubDistrictPrefix("");
                setDistrictPrefix("");
                setProvincePrefix("");
            }
        } else {
            if (language === "en") {
                setSubDistrictPrefix("");
                setDistrictPrefix("");
                setProvincePrefix("");
            } else {
                setSubDistrictPrefix("ต.");
                setDistrictPrefix("อ.");
                setProvincePrefix("จ.");
            }
        }
    };

    const handleTaxType = (selectedOption) => {
        console.log("Change tax type:", selectedOption.key);

        setTaxNumberType(selectedOption.key);

        if (selectedOption.key === "TXID") {
            setShowBranch(true);
            setBranchNumber("00000");

            if (language === "en") {
                setBranchText("Head office");
            } else {
                setBranchText("สำนักงานใหญ่");
            }
        } else {
            setShowBranch(false);
            setBranchText("");
            setBranchNumber("");
        }

        if (selectedOption.key === "OTHR") {
            setShowTaxId(false);
            setTaxId("N/A");
        } else {
            setTaxId("");
            setShowTaxId(true);
        }
    };

    const handleBranch = (branchNum, lang) => {
        console.log("Change branch:", branchNum);
        if (branchNum === "00000") {
            setShowBranchNumber(false);
            setBranch("00000");
            setBranchNumber("00000");

            if (lang === "en") {
                setBranchText("Head office");
            } else {
                setBranchText("สำนักงานใหญ่");
            }
        } else {
            setBranch("branchNumber");
            if (branchNum === "branchNumber") {
                setBranchNumber("00000");

                if (lang === "en") {
                    setBranchText("Branch 00000");
                } else {
                    setBranchText("สาขา 00000");
                }
            } else {
                setBranchNumber(branchNum);

                if (lang === "en") {
                    setBranchText("Branch " + branchNum);
                } else {
                    setBranchText("สาขา " + branchNum);
                }
            }
            setShowBranchNumber(true);
        }

        if (lang === "en") {
            setBranchDisplay([
                { key: "00000", text: "Head office" },
                { key: "branchNumber", text: "Branch" },
            ]);
        } else {
            setBranchDisplay([
                { key: "00000", text: "สำนักงานใหญ่" },
                { key: "branchNumber", text: "สาขาเลขที่" },
            ]);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        //setProcessing(true);
        // setChargeStatus('');
        setIsLoading(true);
        window.scrollTo(0, 0);

        console.log("Submit!");

        console.log("Credit: ", credit);
        console.log("Price: ", price);
        console.log("Vat Total: ", vatTotal);
        console.log("Grand Total: ", grandTotal);
        /* 
        let formData = new FormData();
        formData.append('token', 'QKl7y6O/Eev9LSiUlWo86yQOLjfNWKT1mjvDiI4ZOSEtdunRb9X0HGjXory393FGF6UfvHGq8c+UhaVC8Z5jfPxveTPzeOT2FSxavSdCuvrGtgX/CEPnlkp23HT7IX6zG9A+xedJ4mq79qXS8J90ITaTIB8=');
        formData.append('referenceNo', '20220326001');
        formData.append('amount', '1');
        formData.append('detail', 'ซื้อเอกสาร');
        formData.append('customerName', 'นายค้าขาย ร่ำรวย');
        formData.append('customerEmail', 'krit@frevation.com');
        formData.append('merchantDefined1', 'merchantDefined1');
        formData.append('merchantDefined2', 'merchantDefined2');
        formData.append('customerTelephone', '0825798555');
        formData.append('customerAddress', '123 หมู่ 1');

            await axios.post('https://api.gbprimepay.com/gbp/gateway/qrcode', 
          
            
            
            formData,
            {         
                // data: formData,      
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },                 
                responseType: 'arraybuffer',
            })
 */
        instance
            .acquireTokenSilent({ ...silentRequest, account: account })
            .then((tokenResponse) => {
                axios
                    .post(
                        API_URL + "/gbprimepay/qrcode",
                        {
                            creditType: "documents",
                            creditAmount: credit,
                            customer: company,
                        },
                        {
                            headers: {
                                Authorization:
                                    "Bearer " + tokenResponse.accessToken,
                            },
                            responseType: "arraybuffer",
                        }
                    )
                    .then((response) => {
                        console.log("GB Prime Pay data: ", response);

                        if (response.data) {
                            /* 
                    console.log(response.data);

                    if (response.data && response.data.customer.id) {
                       
                       
                    }
  */
                            setBase64QrCode(
                                "data:image/png;base64," +
                                    Buffer.from(
                                        response.data,
                                        "binary"
                                    ).toString("base64")
                            );
                        }

                        setIsLoading(false);
                    });
            })
            .catch((error) => {
                //Acquire token silent failure, and send an interactive request
                console.log(error);
                instance.acquireTokenRedirect(silentRequest);

                setIsLoading(false);
            });
    };

    useEffect(() => {
        console.log('Company state: ', company);
    
        if (company.taxId) {
          console.log('Company useEffect call!');
          console.log(company);
          console.log(company.id);

          let _company = company;

          let _companyAllAddress = company.buildingNumber +
            ' ' +
            (!/^-*$/.test(company.address) &&
            company.address.trim() &&
            !/^-*$/.test(company.address.trim())
                ? company.address
                : '') +
            ' ' +
            (!/^-*$/.test(company.streetName) &&
            company.streetName.trim() &&
            !/^-*$/.test(company.streetName.trim())
                ? company.streetPrefix + company.streetName
                : '') +
            ' ' +
            company.subDistrictPrefix +
            company.subDistrictName +
            ' ' +
            company.districtPrefix +
            company.districtName +
            ' ' +
            company.provincePrefix +
            company.provinceName +
            ' ' +
            company.postcode;
            
          setCompanyAllAddress(_companyAllAddress);

          _company.allAddress = _companyAllAddress;

          setCompany(_company);

            setIsLoading(false);

        }


      }, [company]);

    useEffect(() => {
        //console.log('Add omise js');
        //history.push({ pathname: "/empty" });
        //history.replace({ pathname: "/billing/subscriptions" });

        console.log("userEffect initialize Call!");

        instance
            .acquireTokenSilent({ ...silentRequest, account: account })
            .then((tokenResponse) => {
                axios
                    .get(API_URL + '/etax/companies', {
                        headers: {
                        Authorization: 'Bearer ' + tokenResponse.accessToken,
                        },
                    })
                    .then(
                        (response) => {
                        console.log(response);

                        if (response.data[0]) {
                            console.log(response.data[0]);
                            
                            setCompany(response.data[0]);

                            setCompanyName(response.data[0].name);
                            setCompanyPhone(response.data[0].phone);
                            setCompanyEmail(response.data[0].email);
                            setCompanyTaxId(response.data[0].taxId);
                            setCompanyBranchText(response.data[0].branchText);
                        } else {
                            history.push({
                            pathname: '/etax/companies/profiles',
                            state: {},
                            });
                        }
                        },
                        (error) => {
                        console.log(error);
                        }
                    );
            })
            .catch((error) => {
                //Acquire token silent failure, and send an interactive request
                console.log(error);
                instance.acquireTokenRedirect(silentRequest);

                //setIsLoading(false);
            });

        calGrandTotal(price);

        // setIsLoading(false);
    }, []);

    return (
        <form onSubmit={handleSubmit}>
            {!base64QrCode && !isLoading && (
                <Stack>
                    {/* <h2 style={{ marginLeft: '0px', marginTop: '25px' }}>{`เติมเครดิตเอกสาร`}</h2> */}
                    {/*             <div className="ms-Grid" dir="ltr" style={{ paddingLeft: 30, paddingRight: 30, paddingTop: 30, background: '#ffffff', margin: '2%', boxShadow: theme.effects.elevation4 }}>  */}
                    <div
                        style={{
                            width: "850px",
                            paddingLeft: 30,
                            paddingRight: 30,
                            paddingTop: 30,
                            marginTop: 0,
                            background: "#ffffff",
                            marginLeft: "0px",
                            marginTop: "0px",
                            boxShadow: theme.effects.elevation4,
                        }}
                    >
                        <div className="ms-Grid-row">
                            <h2
                                style={{ margin: "0%" }}
                            >{`1. เลือกปริมาณเอกสาร`}</h2>
                            &nbsp;&nbsp;
                            {/* <br/> */}
                            <ChoiceGroup
                                name="plan"
                                label={`เติมเครดิตเอกสารจำนวน`}
                                selectedKey={credit}
                                required
                                //styles={{ root: { width: 850 } }}
                                options={[
                                    /* { key: 'FREE', text: 'แพ็กเกจ ฟรี' }, 
                            { key: 'STARTER', text: 'แพ็กเกจ เริ่มต้น 200 เอกสาร/เดือน', iconProps: { iconName: 'CalendarDay' }, imageSize: { width: 150, height: 50 } },
                            { key: 'BUSINESS', text: 'แพ็กเกจ ธุรกิจ 1,000 เอกสาร/เดือน', iconProps: { iconName: 'CalendarDay' }, imageSize: { width: 150, height: 50 } },
                            { key: 'ENTERPRISE', text: 'แพ็กเกจ องค์กร 3,000 เอกสาร/เดือน', iconProps: { iconName: 'CalendarDay' }, imageSize: { width: 150, height: 50 } },                                    
*/
                                    {
                                        key: 3000,
                                        text: "        3,000 เอกสาร (ราคา 8,999 บาท)        ",
                                        imageSrc: "/images/paper.png",
                                        selectedImageSrc: "/images/paper.png",
                                        imageSize: { height: 50 },
                                    },
                                    {
                                        key: 10000,
                                        text: "       10,000 เอกสาร (ราคา 19,999 บาท)            ",
                                        imageSrc: "/images/paper.png",
                                        selectedImageSrc: "/images/paper.png",
                                        imageSize: { height: 50 },
                                    },
                                    {
                                        key: 20000,
                                        text: "       20,000 เอกสาร (ราคา 39,999 บาท)        ",
                                        imageSrc: "/images/paper.png",
                                        selectedImageSrc: "/images/paper.png",
                                        imageSize: { height: 50 },
                                    },
/* 
                                    {
                                        key: 30000,
                                        text: "       30,000 เอกสาร (ราคา 29,999 บาท)        ",
                                        imageSrc: "/images/paper.png",
                                        selectedImageSrc: "/images/paper.png",
                                        imageSize: { height: 50 },
                                    },
                                     */
                                ]}
                                onChange={(e, selectedOption) => {
                                    console.log(selectedOption);
                                    setCredit(selectedOption.key);

                                    switch (selectedOption.key) {
                                        case 3000:
                                            setPrice(8999);
                                            calGrandTotal(8999);
                                            //setStartEnd(1);
                                            break;
                                        case 10000:
                                            setPrice(19999);
                                            calGrandTotal(19999);
                                            //setStartEnd(1);
                                            break;
                                        case 20000:
                                            setPrice(39999);
                                            calGrandTotal(39999);
                                            //setStartEnd(1);
                                            break; 
                                        case 30000:
                                            setPrice(29999);
                                            calGrandTotal(29999);
                                            //setStartEnd(1);
                                            break;                                       
                                        default:
                                            setPrice(0);
                                    }
                                }}
                            />
                            <br />
                            <br />
                        </div>
                    </div>

                    <div
                        style={{
                            width: "850px",
                            paddingLeft: 30,
                            paddingRight: 30,
                            paddingTop: 30,
                            background: "#ffffff",
                            marginLeft: "0px",
                            marginTop: "30px",
                            boxShadow: theme.effects.elevation4,
                        }}
                    >
                        {/* <div className="ms-Grid-row">       */}
                        <Stack horizontal>
                            <Stack vertical style={{ width: "570px"}}>
                            
                                <h2 style={{ margin: "0%" }}>
                                    2. ข้อมูลสำหรับออก ใบเสร็จรับเงิน/ใบกำกับภาษี
                                </h2>
                                <br/>
                            
                                <span style={{ color: "#106EBE", fontSize: "20px", fontWeight: "bold" }}>
                                    {companyName} ({companyBranchText})
                                </span>
                                <br/>
                                <span style={{ color: "#106EBE", fontSize: "18px" }}>
                                    {companyAllAddress}
                                </span>
                                <br/>
                                <span style={{ color: "#106EBE", fontSize: "18px" }}>
                                    เลขประจำตัวผู้เสียภาษี {companyTaxId}
                                </span>
                                
                                
                            
                            </Stack>
                            {/* <Stack vertical> */}
                                <Separator vertical />
                            {/* </Stack> */}
                            <Stack vertical style={{ marginLeft: "25px"}}>
                                <br/>
                                <br/>
                                {/* <br/>
                                <br/> */}

                                <span style={{ fontSize: "14px" }}>
                                    ใบเสร็จรับเงิน/ใบกำกับภาษี <br/>จัดส่งที่อีเมล {companyEmail} 
                                    <br/>(ภายใน 3 วันทำการ)
                                </span>
                                <br/>
                                <span style={{ fontSize: "14px" }}>
                                    *หากต้องการเปลี่ยนข้อมูล <br/>กรุณาติดต่อ Line: @leceipt <br/>หลังจากชำระเงินทันที
                                </span>
                            </Stack>
                        </Stack>
                        
                        {/* 
                        <Stack vertical>
                            <Stack horizontal tokens={{ childrenGap: 15 }}>
                                <div>
                                    <TextField
                                        name="name"
                                        label="ชื่อลูกค้า"
                                        autoComplete="true"
                                        styles={{ root: { width: 310 } }}
                                        onChange={(e) => {
                                            setName(e.target.value);
                                        }}
                                        value={name}
                                        onGetErrorMessage={getErrorMessage}
                                        validateOnLoad={false}
                                        validateOnFocusOut
                                        required
                                        autoFocus
                                    />
                                </div>
                            </Stack>

                            <Stack horizontal tokens={{ childrenGap: 15 }}>
                                <TextField
                                    name="buildingNumber"
                                    label="เลขที่"
                                    styles={{ root: { width: 100 } }}
                                    onChange={(e) => {
                                        setBuildingNumber(e.target.value);
                                    }}
                                    value={buildingNumber}
                                    onGetErrorMessage={getErrorMessage}
                                    validateOnLoad={false}
                                    validateOnFocusOut
                                    required
                                />
                                <TextField
                                    name="address"
                                    label="ที่อยู่"
                                    styles={{ root: { width: 350 } }}
                                    onChange={(e) => {
                                        setAddress(e.target.value);
                                    }}
                                    value={address}
                                />
                                <TextField
                                    name="streetName"
                                    label="ถนน"
                                    styles={{ root: { width: 135 } }}
                                    onChange={(e) => {
                                        setStreetName(e.target.value);

                                        setRoadPrefix(e.target.value);
                                    }}
                                    value={streetName}
                                />
                            </Stack>

                            <Stack horizontal tokens={{ childrenGap: 15 }}>
                                <Stack horizontal tokens={{ childrenGap: 15 }}>
                                    <Dropdown
                                        placeholder="กรุณาเลือก"
                                        label="จังหวัด"
                                        options={provinceList}
                                        styles={dropdownStyles}
                                        selectedKey={province.key}
                                        onChange={(e, selectedOption) => {
                                            setProvince(selectedOption);
                                            setDistrict({ key: "", text: "" });
                                            setSubDistrict({
                                                key: "",
                                                text: "",
                                            });
                                            setDistrictList(
                                                getDistrictList(
                                                    selectedOption.key,
                                                    language
                                                )
                                            );
                                            setSubDistrictList([]);

                                            setAreaPrefix(selectedOption.key);

                                            console.log(
                                                "Province changed",
                                                districtList
                                            );

                                            setProvinceErrMsg("");
                                            setDistrictErrMsg("");
                                            setSubDistrictErrMsg("");
                                        }}
                                        onBlur={(e) => {
                                            console.log(province.key);
                                            if (province.key === "") {
                                                setProvinceErrMsg(
                                                    DropdownErrMsg
                                                );
                                            } else {
                                                setProvinceErrMsg("");
                                            }
                                        }}
                                        errorMessage={provinceErrMsg}
                                        required
                                    />
                                    <Dropdown
                                        placeholder="กรุณาเลือก"
                                        label="อำเภอ"
                                        options={districtList}
                                        selectedKey={district.key}
                                        styles={dropdownStyles}
                                        onChange={(e, selectedOption) => {
                                            setDistrict(selectedOption);
                                            setSubDistrict({
                                                key: "",
                                                text: "",
                                            });
                                            setSubDistrictList(
                                                getSubDistrictList(
                                                    selectedOption.key,
                                                    language
                                                )
                                            );
                                            console.log(
                                                "District changed",
                                                subDistrictList
                                            );

                                            setDistrictErrMsg("");
                                            setSubDistrictErrMsg("");
                                        }}
                                        onBlur={(e) => {
                                            console.log(district.key);
                                            if (
                                                province.key !== "" &&
                                                district.key === ""
                                            ) {
                                                setDistrictErrMsg(
                                                    DropdownErrMsg
                                                );
                                            } else {
                                                setDistrictErrMsg("");
                                            }
                                        }}
                                        errorMessage={districtErrMsg}
                                        required
                                    />
                                    <Dropdown
                                        placeholder="กรุณาเลือก"
                                        label="ตำบล"
                                        options={subDistrictList}
                                        selectedKey={subDistrict.key}
                                        styles={dropdownStyles}
                                        onChange={(e, selectedOption) => {
                                            setSubDistrict(selectedOption);

                                            setSubDistrictErrMsg("");
                                        }}
                                        onBlur={(e) => {
                                            console.log(subDistrict.key);
                                            if (
                                                district.key !== "" &&
                                                subDistrict.key === ""
                                            ) {
                                                setSubDistrictErrMsg(
                                                    DropdownErrMsg
                                                );
                                            } else {
                                                setSubDistrictErrMsg("");
                                            }
                                        }}
                                        errorMessage={subDistrictErrMsg}
                                        required
                                    />
                                </Stack>

                                <TextField
                                    name="postcode"
                                    label="รหัสไปรษณีย์"
                                    styles={{ root: { width: 120 } }}
                                    onChange={(e) => {
                                        setPostCode(e.target.value);
                                    }}
                                    value={postcode}
                                    onGetErrorMessage={getErrorMessage}
                                    validateOnLoad={false}
                                    validateOnFocusOut
                                    required
                                />
                            </Stack>

                            <Stack horizontal tokens={{ childrenGap: 15 }}>
                                <Dropdown
                                    placeholder="กรุณาเลือก"
                                    label="ประเภทผู้เสียภาษี"
                                    options={[
                                        {
                                            key: "TXID",
                                            text: "เลขประจำตัวผู้เสียภาษี",
                                        },
                                        {
                                            key: "NIDN",
                                            text: "เลขประจำตัวประชาชน",
                                        },
                                        {
                                            key: "CCPT",
                                            text: "เลขที่หนังสือเดินทาง",
                                        },
                                        { key: "OTHR", text: "ไม่ระบุ" },
                                    ]}
                                    styles={{ root: { width: 180 } }}
                                    selectedKey={taxNumberType}
                                    onChange={(e, selectedOption) => {
                                        handleTaxType(selectedOption);

                                        setTaxNumberTypeErrMsg("");
                                    }}
                                    onBlur={(e) => {
                                        console.log(taxNumberType);
                                        if (
                                            taxNumberType === "" ||
                                            taxNumberType === undefined
                                        ) {
                                            setTaxNumberTypeErrMsg(
                                                DropdownErrMsg
                                            );
                                        } else {
                                            setTaxNumberTypeErrMsg("");
                                        }
                                    }}
                                    errorMessage={taxNumberTypeErrMsg}
                                    required
                                />
                                {showTaxId && (
                                    <div>
                                        <TextField
                                            name="taxId"
                                            label="เลขที่"
                                            styles={{ root: { width: 120 } }}
                                            autoComplete="true"
                                            value={taxId}
                                            onChange={(e) => {
                                                setTaxId(e.target.value);
                                            }}
                                            onGetErrorMessage={
                                                getTaxIdErrorMessage
                                            }
                                            validateOnLoad={false}
                                            validateOnFocusOut
                                            required
                                        />
                                    </div>
                                )}

                                {showBranch && (
                                    <Stack
                                        horizontal
                                        tokens={{ childrenGap: 15 }}
                                    >
                                        <Dropdown
                                            placeholder="กรุณาเลือก"
                                            label="สำนักงานใหญ่/สาขา"
                                            options={branchDisplay}
                                            selectedKey={branch}
                                            styles={{ root: { width: 160 } }}
                                            onChange={(e, selectedOption) =>
                                                handleBranch(
                                                    selectedOption.key,
                                                    language
                                                )
                                            }
                                        />
                                        {showBranchNumber && (
                                            <TextField
                                                name="branchNumber"
                                                label="เลขที่"
                                                description="เช่น 00001"
                                                styles={{
                                                    root: { width: 110 },
                                                }}
                                                value={branchNumber}
                                                required={showBranchNumber}
                                                onChange={(e) => {
                                                    setBranchNumber(
                                                        e.target.value
                                                    );

                                                    if (language === "en") {
                                                        setBranchText(
                                                            "Branch " +
                                                                e.target.value
                                                        );
                                                    } else {
                                                        setBranchText(
                                                            "สาขา " +
                                                                e.target.value
                                                        );
                                                    }
                                                }}
                                                onGetErrorMessage={
                                                    getBranchNumberErrorMessage
                                                }
                                                validateOnLoad={false}
                                                validateOnFocusOut
                                            />
                                        )}
                                    </Stack>
                                )}
                            </Stack>

                            <Stack horizontal tokens={{ childrenGap: 15 }}>
                                <TextField
                                    componentRef={(input) => (phoneRef = input)}
                                    name="phone"
                                    label="โทร."
                                    styles={{ root: { width: 200 } }}
                                    onGetErrorMessage={
                                        getPhoneNumberErrorMessage
                                    }
                                    onChange={(e) => {
                                        setPhone(e.target.value);
                                    }}
                                    value={phone}
                                />
                                <TextField
                                    name="email"
                                    label="อีเมล"
                                    styles={{ root: { width: 300 } }}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                    }}
                                    value={email}
                                />
                            </Stack>
                        </Stack>
 */}

                        <br />
                        <br />

                        {/* </div> */}
                    </div>

                    {/* <div className="ms-Grid" dir="ltr" style={{ paddingLeft: 30, paddingRight: 30, paddingTop: 30, background: '#ffffff', margin: '2%', boxShadow: theme.effects.elevation4 }}>  */}
                    <div
                        style={{
                            width: "850px",
                            paddingLeft: 30,
                            paddingRight: 30,
                            paddingTop: 30,
                            background: "#ffffff",
                            marginLeft: "0px",
                            marginTop: "30px",
                            boxShadow: theme.effects.elevation4,
                        }}
                    >
                        {/* <div className="ms-Grid-row">       */}

                        {/* <br/> */}
                        <Stack horizontal tokens={stackTokens}>
                            <Stack vertical tokens={stackTokens}>
                                <h2 style={{ margin: "0%" }}>
                                    3. เลือกวิธีการชำระเงิน
                                </h2>
                                &nbsp;&nbsp;
                                <h4 style={{ marginTop: "-5px" }}>
                                    เติมเครดิตอัตโนมัติ เร็วทันใจ ตลอด 24 ชม.
                                </h4>
                                <Stack horizontal tokens={stackTokens}>
                                    <img
                                        src={"/images/promtpay-logo.png"}
                                        width="260"
                                        border="0"
                                        style={{
                                            marginLeft: "0px",
                                            marginTop: "-20px",
                                        }}
                                    ></img>
                                </Stack>
                                {/* <br/> */}
                                <small>*ซื้อแล้ว ไม่คืนเงิน</small>
                            </Stack>

                            <Separator
                                vertical /* style={{ marginLeft: '15px' }} */
                            />

                            <Stack
                                vertical
                                tokens={stackTokens}
                                style={{
                                    marginLeft: "0px",
                                    paddingLeft:
                                        "15px" /* borderLeft: '2px solid #E8E9E9' */,
                                }}
                            >
                                <h1
                                    style={{
                                        marginTop: "5px",
                                        marginLeft: "365px",
                                    }}
                                >
                                    สรุปคำสั่งซื้อ
                                </h1>
                                {/* <hr style={{ marginLeft: '0' }} width="520px" color='#E8E9E9' /> */}
                                <Separator />
                                <h3 style={{ textAlign: "right" }}>
                                    เติมเครดิตเอกสาร จำนวน{" "}
                                    {credit.toLocaleString(undefined, {
                                        maximumFractionDigits: 0,
                                        minimumFractionDigits: 0,
                                    })}
                                    &nbsp;เอกสาร&nbsp;&nbsp;&nbsp;
                                    <span style={{ paddingLeft: 60 }}>
                                        {price.toLocaleString(undefined, {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2,
                                        })}{" "}
                                        บาท
                                    </span>
                                </h3>
                                {/* <small style={{ marginTop: '-10px', marginLeft: '70px' }}>(สมัครใช้งาน {planStart.toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' })} - {planEnd.toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' })})</small> */}
                                <h3 style={{ textAlign: "right" }}>
                                    ภาษีมูลค่าเพิ่ม 7%&nbsp;
                                    <span style={{ paddingLeft: 80 }}>
                                        {vatTotal.toLocaleString(undefined, {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2,
                                        })}{" "}
                                        บาท
                                    </span>
                                </h3>
                                <br />
                                {/* <hr style={{ marginLeft: '0' }} width="520px" color='#E8E9E9' /> */}
                                <Separator />
                                <h2
                                    style={{
                                        marginTop: "5px",
                                        textAlign: "right",
                                    }}
                                >
                                    ยอดรวม
                                    <span style={{ paddingLeft: 40 }}>
                                        {grandTotal.toLocaleString(undefined, {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2,
                                        })}{" "}
                                        บาท
                                    </span>
                                </h2>

                                <div></div>
                                <center>
                                    <br />
                                    <small>
                                        หากกดปุ่ม "ชำระเงิน" ถือว่าท่านยอมรับ{" "}
                                        <a
                                            href="https://www.leceipt.com/terms-conditions"
                                            target="_blank"
                                        >
                                            เงื่อนไขและข้อตกลงการใช้งาน
                                        </a>
                                    </small>
                                    <br />
                                    <br />
                                    <br />
                                    <Stack
                                        horizontal
                                        style={centerItem}
                                        tokens={{ childrenGap: 30 }}
                                    >
                                        {!isLoading ? (
                                            <PrimaryButton
                                                /* iconProps={{ iconName: 'QRCode' }} */ type="submit"
                                                text="ชำระเงินผ่าน QR Code"
                                                value="pay"
                                                style={{
                                                    width: 550,
                                                    height: 50,
                                                    fontSize: 25,
                                                    letterSpacing: "1.5px",
                                                }}
                                            />
                                        ) : (
                                            <Spinner
                                                label="กำลังดำเนินการ..."
                                                labelPosition="right"
                                            />
                                        )}
                                    </Stack>
                                </center>
                            </Stack>
                        </Stack>
                        <br />

                        <br />
                        <br />

                        {/* </div> */}
                    </div>
                    {/* <br/> */}

                    <br />
                    <br />
                </Stack>
            )}

            {base64QrCode && !isLoading && (
                <Stack vertical>
                    <center>
                        <h1 style={{ marginLeft: "0px", marginTop: "25px" }}>
                            {`เติมเครดิต: ` +
                                credit.toLocaleString(undefined, {
                                    maximumFractionDigits: 0,
                                    minimumFractionDigits: 0,
                                }) +
                                ` เอกสาร`}
                        </h1>
                        <h3>
                            ระบบจะเติมเครดิตให้อัตโนมัติหลังจากชำระเงิน ตลอด 24
                            ชม.
                        </h3>
                        <img src={base64QrCode} width="433px" />
                    </center>
                </Stack>
            )}

            {isLoading && (
                <Stack>
                    <br />
                    <br />
                    <br />
                    {/* <Stack horizontal style={{ marginLeft: '170px'}}>  */}
                    <Stack horizontal horizontalAlign="center">
                        <div>
                            {company.taxId
                            ?
                            <Spinner
                                /* size={SpinnerSize.large} */ label="กำลังดำเนินการ..."
                                labelPosition="down"
                                styles={spinnerStyles}
                            />
                            :
                            <Spinner
                                /* size={SpinnerSize.large} */ label="กำลังโหลด..."
                                labelPosition="down"
                                styles={spinnerStyles}
                            />
                            }
                        </div>
                        <br />
                    </Stack>
                </Stack>
            )}
        </form>
    );
};

export default DocumentsCheckout;
