// Config object to be passed to Msal on creation
export const msalConfig = {
    /* 
         auth: {
            clientId: "e3edaf4e-943f-42ef-9b29-005ca7a00078",
            authority: "https://leceipt.b2clogin.com/leceipt.onmicrosoft.com/B2C_1_SignUpAndSignIn",
            knownAuthorities: ["leceipt.b2clogin.com"],
            redirectUri: "http://localhost:3000/etax",
            postLogoutRedirectUri: "http://localhost:3000/etax",
            //validateAuthority: false
        },
    */ 
        auth: {
            clientId: process.env.REACT_APP_B2C_CLIENT_ID,
            //authority: process.env.REACT_APP_B2C_LOGIN_AUTHORITY,        
            //authority: "https://login.leceipt.com/17a27e41-ab59-4a43-9f71-2ea60904f55d/B2C_1_SignUpAndSignIn",        
            authority: "https://leceipt.b2clogin.com/leceipt.onmicrosoft.com/B2C_1_SignUpAndSignIn",
            knownAuthorities: [process.env.REACT_APP_B2C_KNOWN_AUTHORITIES_1, process.env.REACT_APP_B2C_KNOWN_AUTHORITIES_2, process.env.REACT_APP_B2C_KNOWN_AUTHORITIES_3],
            //knownAuthorities: ["leceipt.b2clogin.com", "login.leceipt.com", "account.leceipt.com", "leceipt.azurefd.net"],
            redirectUri: process.env.REACT_APP_B2C_REDIRECT_URI,
            postLogoutRedirectUri: process.env.REACT_APP_B2C_POST_LOGOUT_REDIRECT_URI,
            //redirectUri: "http://localhost:3000",
            //postLogoutRedirectUri: "http://localhost:3000",
            navigateToLoginRequestUrl: false
            //validateAuthority: false
        },
        
        cache: {
            cacheLocation: "sessionStorage"
            //storeAuthStateInCookie: true,
        }
        /*auth: {
            clientId: "9067c884-9fa6-414f-9aa4-a565b1cb46be",
            authority: "https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/b2c_1_susi",
            knownAuthorities: ["fabrikamb2c.b2clogin.com"],
            redirectUri: "http://localhost:4200",
            postLogoutRedirectUri: "http://localhost:4200"
        }*/
    };
    
    // Add here scopes for id token to be used at MS Identity Platform endpoints.
    export const loginRequest = {
        //scopes: ["https://leceipt.onmicrosoft.com/api/user_impersonation", "openid"]
        //scopes: ["https://leceipt.onmicrosoft.com/api/user_impersonation"],    
        //scopes: [process.env.REACT_APP_B2C_LOGIN_SCOPES]
        //scopes: ["openid", "profile"],
        //scopes: ['user.read', 'openid'],
        //scopes: ["openid"]
        //authority: process.env.REACT_APP_B2C_LOGIN_AUTHORITY,
        scopes: ["openid", "offline_access", "e3edaf4e-943f-42ef-9b29-005ca7a00078"]
    };
    
    export const signupRequest = {
        //scopes: ["https://leceipt.onmicrosoft.com/api/user_impersonation", "openid"]
        //scopes: ["https://leceipt.onmicrosoft.com/api/user_impersonation"]
        //scopes: [process.env.REACT_APP_B2C_LOGIN_SCOPES]
        //scopes: ["openid", "profile"],
        //scopes: ['user.read', 'openid'],
        //scopes: ["openid"]
        //authority: process.env.REACT_APP_B2C_SIGNUP_AUTHORITY,
        authority: 'https://leceipt.b2clogin.com/leceipt.onmicrosoft.com/B2C_1_SignUp',
        scopes: ["openid", "offline_access", "e3edaf4e-943f-42ef-9b29-005ca7a00078"]
    };
    
    export const silentRequest = {
        //scopes: ["openid"]    
        //scopes: [process.env.REACT_APP_B2C_SILENT_SCOPES]
        //authority: process.env.REACT_APP_B2C_SILENT_LOGIN_AUTHORITY,
        //authority: 'https://login.leceipt.com/17a27e41-ab59-4a43-9f71-2ea60904f55d/B2C_1_SignUpAndSignIn',
        authority: 'https://leceipt.b2clogin.com/leceipt.onmicrosoft.com/B2C_1_SignUpAndSignIn',
        //authority: process.env.REACT_APP_B2C_LOGIN_AUTHORITY,
        //scopes: ["https://leceipt.onmicrosoft.com/api/user_impersonation"]    
        scopes: ["openid", "offline_access", "e3edaf4e-943f-42ef-9b29-005ca7a00078"]
    }
    
    export const profileRequest = {
        //scopes: ["openid"]
        authority: process.env.REACT_APP_B2C_PROFILE_AUTHORITY,
        scopes: ["openid", "offline_access", "e3edaf4e-943f-42ef-9b29-005ca7a00078", "profile"],
        //scopes: [process.env.REACT_APP_B2C_SILENT_SCOPES]
        
    }
    
    export const passwordResetRequest = {
        //authority: "https://leceipt.b2clogin.com/leceipt.onmicrosoft.com/B2C_1_PasswordReset"
        authority: process.env.REACT_APP_B2C_PASSWORD_AUTHORITY,
        scopes: ["openid", "offline_access", "e3edaf4e-943f-42ef-9b29-005ca7a00078"]
    }
    
    export const profileEditingRequest = {
        //authority: "https://leceipt.b2clogin.com/leceipt.onmicrosoft.com/B2C_1_ProfileEditing",
        //authority: "https://login.leceipt.com/17a27e41-ab59-4a43-9f71-2ea60904f55d/B2C_1_ProfileEditing",
        authority: process.env.REACT_APP_B2C_PROFILE_AUTHORITY,
        scopes: ["openid", "profile", "offline_access", "e3edaf4e-943f-42ef-9b29-005ca7a00078"],
        //redirectUri: "http://localhost:3000/profiles/update",
        redirectUri: process.env.REACT_APP_B2C_POST_PROFILE_EDIT_REDIRECT_URI
        
    }
    
    