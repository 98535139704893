import React, { useState, useEffect } from 'react';
import { useMsal } from "@azure/msal-react";

const Maintenance = () => {
    // const { instance } = useMsal();

    // instance.logout();

    return (
        // <div>ออกจากระบบ เรียบร้อยแล้ว!</div>
        <div>
            <center>
                <br/>
                <br/>
                <h1>ระบบนี้เป็นเวอร์ชั่นเก่า ไม่สามารถใช้งานได้</h1>
                <br/>
                <h1>This is obsolete version. It is not working.</h1>
            </center>
        </div>
    );
}

export default Maintenance;