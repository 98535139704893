import React, { useState, useEffect } from 'react';
import { useMsal } from "@azure/msal-react";

const LogOut = () => {
    const { instance } = useMsal();

    instance.logout();

    return (
        <div>ออกจากระบบ เรียบร้อยแล้ว!</div>
    );
}

export default LogOut;