import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';

import PaymentHistory from './PaymentHistory';


import { useMsal, useAccount } from "@azure/msal-react";
import { silentRequest } from "../../authConfig";



import {     
    mergeStyles, 
    PrimaryButton,     
    Stack, 
    getTheme, 
    Shimmer,
    ShimmerElementType,  
    Icon,      
    Toggle,
    ProgressIndicator,
    TooltipHost,
    mergeStyleSets,
    ActionButton,
    SelectionMode,
    DetailsListLayoutMode,
    ConstrainMode,
    ShimmeredDetailsList,
    Dialog,
    DialogType,
    Spinner,
    Separator,
    Pivot, 
    PivotItem    
    
} from '@fluentui/react';

import DocumentsCheckout from './documents/checkout/DocumentsCheckout';
import SmsCheckout from './sms/checkout/SmsCheckout';

const API_URL = process.env.REACT_APP_API_URL;

const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;

const OMISE_PUBLIC_KEY = process.env.REACT_APP_OMISE_PUBLIC_KEY;

const theme = getTheme();

//Style

const centerItem = { display: "flex", alignItems: "center", justifyContent: "center" };

const stackTokens = { childrenGap: 10 };

//Credit Card Style

const iconClass = mergeStyles({
    fontSize: 50,
    height: 50,
    width: 50,
    margin: '0 25px',
});

const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
      padding: 0,
      fontSize: '16px',
    },
    fileIconCell: {
      textAlign: 'center',
      selectors: {
        '&:before': {
          content: '.',
          display: 'inline-block',
          verticalAlign: 'middle',
          height: '100%',
          width: '0px',
          visibility: 'hidden',
        },
      },
    },
    fileIconImg: {
      verticalAlign: 'middle',
      maxHeight: '16px',
      maxWidth: '16px',
    },
    controlWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    exampleToggle: {
      display: 'inline-block',
      marginBottom: '10px',
      marginRight: '30px',
    },
    selectionDetails: {
      marginBottom: '20px',
    },
});


// Processing dialog style
const spinnerStyles = {
    circle: {
      height: 56,
      width: 56,
      borderWidth: 4
    }
}

// Shimmer Plan

const shimmerPlanName = [    
    { type: ShimmerElementType.line, height: 32 },    
];

const shimmerSubscriptionTime = [    
    { type: ShimmerElementType.line, height: 26 },    
];

const shimmerRow = [
    { type: ShimmerElementType.line, height: 12 },    
];


const Billings = () => {
    const location = useLocation();
    const history = useHistory();
    //history.go(0);

    const [locationState] = useState(location.state);

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const [loadDataComplete, setLoadDataComplete] = useState(false);
    const [loadAccountsComplete, setLoadAccountsComplete] = useState(false);
   

    //Pivot menu index
    const [selectedKey, setSelectedKey] = useState('documents');

    const [smsCredit, setSmsCredit] = useState(0);

    const [frevationCoin, setFrevationCoin] = useState(0);
    const [coinExpireTime, setCoinExpireTime] = useState('');
    const [billingsType, setBillingsType] = useState('prepaid');

    /* 
    const [name, setName] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [code, setCode] = useState('');

     

    let logoUrl = PUBLIC_URL + '/logo512.png'

    var { OmiseCard } = window;
        
    OmiseCard.configure({
        publicKey: OMISE_PUBLIC_KEY,
        image: logoUrl,
        //locale: 'th'
    });
*/
    const getAccounts = (accessToken) => {

        axios.get(API_URL + '/accounts', {                
            headers: { 
                'Authorization': 'Bearer ' + accessToken
            } 
        })
        .then((response) => {
            console.log('Accounts data: ', response);

            if (response.data) {
                              
                setSmsCredit(response.data.balances.sms.credits);
                setFrevationCoin(response.data.balances.coins.credits);
                setCoinExpireTime(response.data.balances.coins.expireTime)

                if (response.data.billings && response.data.billings.type === 'subscription') {
                    setBillingsType('subscription');
                }
                    
            } 

            setLoadAccountsComplete(true);

        }, (error) => {
            console.log(error);

            setLoadAccountsComplete(true);
        });

    }

    useEffect(() => {
        console.log('userEffect initialize Call!');

        console.log('location state: ', locationState);

        if (locationState && locationState.pivot) {

            setSelectedKey(locationState.pivot);

        }

        instance.acquireTokenSilent({...silentRequest, account: account}).then(tokenResponse => {            
            
            getAccounts(tokenResponse.accessToken);            


        }).catch(error => {           

            //Acquire token silent failure, and send an interactive request
            console.log(error);
            instance.acquireTokenRedirect(silentRequest);

        });
        
    }, []);

    return (
        <div style={{width: '880px'}}>
            {/* <center> */}
            <h2 style={{ marginLeft: '0px', marginTop: '25px' }}><Icon iconName="QRCode" style={{ color: '#106ebe'}} /> เติมเครดิต</h2>
            {/* <Pivot selectedKey={selectedKey} onLinkClick={setSelectedKey}> */}
            <Pivot selectedKey={String(selectedKey)} /* linkSize="large" */ onLinkClick={(e) => {console.log('pivot click ', e); setSelectedKey(e.props.itemKey)}}>
            
                <PivotItem headerText="เติมเครดิตเอกสาร" itemKey="documents">
                    <br/>
                    
                    {loadAccountsComplete ?
                    
                        <DocumentsCheckout />
                        
                    : 
                    /* 
                        <div>
                            <Shimmer shimmerElements={shimmerPlanName} width="350px"/> 
                            <br/>
                            <Shimmer shimmerElements={shimmerSubscriptionTime} width="260px"/> 
                        </div>

 */
                        <Stack>
                            <br/>
                            <br/>
                            <br/>
                            <Stack horizontal horizontalAlign='center'> 

                                
                                <div>
                                { loadAccountsComplete
                                    ? <Spinner /* size={SpinnerSize.large} */ label="กำลังดำเนินการ..." labelPosition="down" styles={spinnerStyles} />
                                    : <Spinner /* size={SpinnerSize.large} */ label="กำลังโหลด..." labelPosition="down" styles={spinnerStyles} />
                                }
                                </div>
                                <br/>
                                
                            </Stack>

                        </Stack>
                    }
                </PivotItem>
                <PivotItem headerText="เติมเครดิตSMS" itemKey="sms">
                    <br/>
                    <SmsCheckout />
                </PivotItem>
                
                <PivotItem headerText="ประวัติการชำระเงิน" itemKey="history">
                    <br/>
                    <PaymentHistory />
                </PivotItem>
            </Pivot>

            {/* <DocumentsCheckout /> */}
            {/* </center> */}
        </div>

        
        
    );
}

export default Billings;