import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import axios from "axios";

import { useMsal, useAccount, AuthError } from "@azure/msal-react";
import { silentRequest } from "../../../authConfig";

// import * as signalR from "@microsoft/signalr";

import paginate from "jw-paginate";

import { Pagination } from "@uifabric/experiments/lib/Pagination";

import CountUp from "react-countup";

import {
    //TextField,
    //DetailsList,
    DetailsListLayoutMode,
    ShimmeredDetailsList,
    //Selection,
    SelectionMode,
    //IColumn,
    mergeStyleSets,
    TooltipHost,
    //Fabric,
    ActionButton,
    Icon,
    //ProgressIndicator,
    //Label,
    Spinner,
    SpinnerSize,
    CommandBar,
    Stack,
    DocumentCard,
    //DocumentCardActivity,
    DocumentCardTitle,
    DocumentCardDetails,
    DocumentCardLogo,
    //DocumentCardStatus,
    DocumentCardType,
    Text,
    DefaultButton,
    ContextualMenu,
    ContextualMenuItemType,
    SearchBox,
    PrimaryButton,
    //Pagination
    //initializeIcons
} from "@fluentui/react";

import {
    getFileTypeIconProps,
    //FileIconType
} from "@uifabric/file-type-icons";

const API_URL = process.env.REACT_APP_API_URL;

const homeStyles = {
    //width: '208',
    //height: '10vh',
    //width: 185,
    //height: 70,
    //boxSizing: 'border-box',
    //border: '1px solid #eee',
    //overflowY: 'auto',
    //paddingTop: '10vh',
    //background: '#FFFFFF',
    //background: '#106ebe',
    border: 0,
    /* paddingTop: '2%',
    paddingLeft: '2%',
    paddingRight: '2%', */
    margin: 0,
    //minHeight: '100vh',
    width: "100%",
    background: "#FAF9F8",
};

//ProgressIndicator
//const centerItem = { display: "flex", alignItems: "center", justifyContent: "center" };
//Progress Indicator Setting
//const intervalDelay = 100;
//const intervalIncrement = 0.01;

const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
        padding: 0,
        fontSize: "16px",
    },
    fileIconCell: {
        textAlign: "center",
        selectors: {
            "&:before": {
                content: ".",
                display: "inline-block",
                verticalAlign: "middle",
                height: "100%",
                width: "0px",
                visibility: "hidden",
            },
        },
    },
    fileIconImg: {
        verticalAlign: "middle",
        maxHeight: "16px",
        maxWidth: "16px",
    },
    controlWrapper: {
        display: "flex",
        flexWrap: "wrap",
    },
    exampleToggle: {
        display: "inline-block",
        marginBottom: "10px",
        marginRight: "30px",
    },
    selectionDetails: {
        marginBottom: "20px",
    },
});
/* 
const shimmeredDetailsListProps = {
renderedWindowsAhead: 0,
renderedWindowsBehind: 0,
};
*/

const cardStyles = {
    cardStyles: {
        root: {
            background: "white",
            //padding: 20,
            //marginRight: '50%',
            //marginRight: '40px',
            //borderTop: '5px solid #0078d4',
            borderLeft: "5px solid #0078d4",
            width: "30%",
            minWidth: "315px",
            //maxWidth: '415px',
            maxWidth: "424px",
            height: "130px",
            //margin: 'auto',
            //padding: '0 10% 0 10% ',
            //display: 'flex',
            //alignItems: 'center',
            //justifyContent: 'center',
        },
    },
    header: {
        root: {
            fontSize: 20,
            fontWeight: "bold",
            //paddingTop: '5%',
            paddingTop: "20px",
            textAlign: "center",
            //color: '#696969'
        },
    },
    amount: {
        root: {
            fontSize: 30,
            //paddingBottom: 20,
            //paddingBottom: '8%',
            paddingBottom: "25px",
            //paddingTop: '20%',
            //paddingRight: '20%',
            textAlign: "center",
        },
    },
};

function formatDateTime(date) {
    //let d = new Date(date).toLocaleString("en-GB", {timeZone: "Asia/Bangkok"}),
    let d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear(),
        hour = "" + d.getHours(),
        minute = "" + d.getMinutes(),
        second = "" + d.getSeconds();

    //console.log(d.toString());

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    if (hour.length < 2) hour = "0" + hour;
    if (minute.length < 2) minute = "0" + minute;
    if (second.length < 2) second = "0" + second;

    return [year, month, day, hour, minute, second].join("");
}

const PrepaidAccounts = (props) => {
    console.log("Home render!!!");

    const history = useHistory();

    const { instance, accounts } = useMsal();
    //const account = useAccount(accounts[0] || {});
    const account = instance.getAllAccounts()[0];

    //let token = b2cauth.getAccessToken();
    //console.log('Bearer', token);

    //const token = props.token;
    //const token = props.token;
    //

    //const api_key = props.api_key;
    //console.log('Api-Key:', props.api_key);

    //const items = [];
    const [subscriptions, setSubscriptions] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [items, setItems] = useState([]);
    const [loadDataComplete, setLoadDataComplete] = useState(false);
    const [getNewDocuments, setGetNewDocuments] = useState(true);
    const [totalProcessingDocuments, setTotalProcessingDocuments] = useState(0);
    //const [clickDelete, setClickDelete] = useState(false);
    const [pageData, setPageData] = useState({});
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    /* 
    const [pageData, setPageData] = useState({
      totalItems: '',
      currentPage: '',
      pageSize: '',
      totalPages: '',
      startPage: '',
      endPage: '',
      startIndex: '',
      endIndex: '',
      pages: [] 
    });
 */
    //const [percentComplete, setPercentComplete] = useState(0);

    //CommandBars Items
    const [accountType, setAccountType] = useState({
        key: "all",
        text: "บัญชีทั้งหมด",
    });

    //Display Summary
    const [totalDocuments, setTotalDocuments] = useState(0);
    const [totalPaidAccounts, setTotalPaidAccounts] = useState(0);
    // const [income, setIncome] = useState(0);
    const [topupCoinCredits, setTopupCoinCredits] = useState(0);
    const [search, setSearch] = useState("");
    const [filterDocuments, setFilterDocuments] = useState([]);

    const getMonth = (goBack) => {
        let monthNames = [
            "มกราคม",
            "กุมภาพันธ์",
            "มีนาคม",
            "เมษายน",
            "พฤษภาคม",
            "มิถุนายน",
            "กรกฎาคม",
            "สิงหาคม",
            "กันยายน",
            "ตุลาคม",
            "พฤศจิกายน",
            "ธันวาคม",
        ];

        let d = new Date();
        //console.log('new Date(): ', d);

        let adjustDate = new Date(d.getFullYear(), d.getMonth() - goBack, 1);

        //d.setMonth(d.getMonth() - goBack);
        //console.log(d.toLocaleDateString());
        //console.log(d.getMonth());

        let year = adjustDate.getFullYear() + 543;

        return {
            month: adjustDate.getMonth(),
            year: adjustDate.getFullYear(),
            key: adjustDate.getMonth() + 1,
            text: monthNames[adjustDate.getMonth()] + " " + year,
        };
        //return monthNames[d.getMonth()] + ' ' + year;
        //let m = d.getMonth();
        /* 
      let d;
      let year;
      let _month = [];
      

      for (let i = 0; i < 6; i++) {
        d = new Date();
        d.setMonth(d.getMonth() - i);
        year = d.getFullYear() + 543;
        _month.push(monthNames[d.getMonth()] + ' ' + year);
      }
*/

        //console.log(_month);

        //return _month;
    };
/* 
    const [queryMonth, setQueryMonth] = useState(
        (sessionStorage.getItem("accounts-queryMonth-key") && {
            key: sessionStorage.getItem("accounts-queryMonth-key"),
            text: sessionStorage.getItem("accounts-queryMonth-text")
        }) || { key: getMonth(0).year + "" + getMonth(0).key, text: "เดือนนี้" }
    );
 */
    
    const [queryMonth, setQueryMonth] = useState({ key: "all-month", text: "ทั้งหมด" });

    const getFrom = (month, year) => {
        //let date = new Date();
        let _firstDay = new Date(year, month, 1);
        //let _lastDay = new Date(year, month + 1, 1);
        console.log("First day: ", formatDate(_firstDay));

        return formatDate(_firstDay);
        //setLastDay(formatDate(_lastDay));

        //console.log('Last day: ', formatDate(_lastDay));
    };

    const getTo = (month, year) => {
        //let date = new Date();
        //let _firstDay = new Date(year, month, 1);
        let _lastDay = new Date(year, month + 1, 1);
        console.log("Last day: ", formatDate(_lastDay));

        return formatDate(_lastDay);
        //setLastDay(formatDate(_lastDay));

        //console.log('Fist day: ', formatDate(_firstDay));
    };

    const formatDate = (d) => {
        //let d = new Date(date),
        let month = "" + (d.getMonth() + 1);
        let day = "" + d.getDate();
        let year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    };

    function convertDateToUTC() {
        var date = new Date();
        var now_utc = Date.UTC(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds()
        );

        return new Date(now_utc).toISOString();
    }

    const [from, setFrom] = useState("2020-01-01");
    //const [from, setFrom] = useState('2021-07-01');
    const [to, setTo] = useState(getTo(getMonth(0).month, getMonth(0).year));
    //const [to, setTo] = useState('2021-08-01');

    const commandBarItems = [        
        {
            key: "refresh",
            text: "รีเฟรช",
            iconProps: { iconName: "Refresh" },
            disabled: !loadDataComplete,
            onClick: () => {
                console.log("Refresh");

                setLoadDataComplete(false);
                setSubscriptions([]);
                setCompanies([]);
                setDocuments([]);
                setItems([]);
                setGetNewDocuments(true);

                setTotalDocuments(0);
                setTotalPaidAccounts(0);
                setTopupCoinCredits(0);
            },
        },
    ];

    const toThaiDateString = (isoDateTime) => {
        let date = "";
        date = new Date(isoDateTime);

        //console.log(dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString());
        /* 
      let monthNames = [
        "มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน",
        "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม",
        "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"
      ];
 */
        let year = date.getFullYear() + 543;
        //let month = monthNames[date.getMonth()];
        let month = (date.getMonth() + 1).toString().padStart(2, "0");
        let numOfDay = date.getDate().toString().padStart(2, "0");

        let hour = date.getHours().toString().padStart(2, "0");
        let minutes = date.getMinutes().toString().padStart(2, "0");
        let second = date.getSeconds().toString().padStart(2, "0");

        return `${numOfDay}/${month}/${year} ${hour}:${minutes}:${second} น.`;
    };

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return (
            parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
        );
    };

    useEffect(() => {
        console.log("userEffect Call!");

        //console.log('UTC now: ', convertDateToUTC());
    }, []);

    useEffect(() => {
        console.log("userEffect getNewDocuments Call!");

        if (getNewDocuments) {
            instance
                .acquireTokenSilent({ ...silentRequest, account: account })
                .then(async (tokenResponse) => {
                    // Do something with the tokenResponse
                    console.log("Token response: ", tokenResponse);
                
                
                                await axios
                                    .get(
                                        API_URL +
                                            "/resellers/accounts?from=" +
                                            from +
                                            "&to=" +
                                            to,
                                        {
                                            headers: {
                                                Authorization:
                                                    "Bearer " +
                                                    tokenResponse.accessToken,
                                            },
                                        }
                                    )
                                    .then(
                                        (response) => {
                                            console.log(response);
                                            console.log(
                                                "Docs number: ",
                                                response.data.length
                                            );

                                            // setTotalDocuments(response.data.length);

                                            //set page
                                            // console.log('Page data: ', paginate(response.data.length, 1, 5, 10));
                                            // setPageData(paginate(response.data.length, currentPage, pageSize, 10));

                                            // setDocuments(response.data);

                                            let _docs = [];
                                            let _topupCoinCredits = 0;

                                            if (response.data.length > 0) {
                                                const _total = response.data.length;

                                                // for (let i = (currentPage-1)*pageSize; i < Math.min(currentPage*pageSize, response.data.length); i++) {
                                                for (
                                                    let i = 0;
                                                    i < response.data.length;
                                                    i++
                                                ) {
                                                    let createdTime =
                                                        toThaiDateString(
                                                            response.data[i]
                                                                .createdTime
                                                        );
                                                
                                                    let _companiesSelected = "";

                                                    if (response.data[i].companies && response.data[i].companies.id) {
                                                        _companiesSelected = response.data[i].companies;
                                                    }

                                                    console.log(
                                                        "Company selected: ",
                                                        _companiesSelected
                                                    );

                                                    let _coinLastTopupTime = "";
                                                    let _smsLastTopupTime = "";

                                                    if (
                                                        response.data[i].balances
                                                            .coins.lastTopupTime
                                                    ) {
                                                        _coinLastTopupTime =
                                                            toThaiDateString(
                                                                response.data[i]
                                                                    .balances.coins
                                                                    .lastTopupTime
                                                            );
                                                    }

                                                    if (
                                                        response.data[i].balances
                                                            .sms.lastTopupTime
                                                    ) {
                                                        _smsLastTopupTime =
                                                            toThaiDateString(
                                                                response.data[i]
                                                                    .balances.sms
                                                                    .lastTopupTime
                                                            );
                                                    }

                                                    if (
                                                        response.data[i].balances
                                                            .coins.lastTopup
                                                    ) {
                                                        _topupCoinCredits =
                                                            _topupCoinCredits +
                                                            response.data[i]
                                                                .balances.coins
                                                                .lastTopup;
                                                    }

                                                    _docs.push({
                                                        key: response.data[i].id,
                                                        number: _total - i,
                                                        createdTime: createdTime,
                                                        email: response.data[i]
                                                            .profiles.emails[0],
                                                        firstName:
                                                            response.data[i]
                                                                .profiles.firstName,
                                                        lastName:
                                                            response.data[i]
                                                                .profiles.lastName,
                                                        name: response.data[i]
                                                            .profiles.name,
                                                        mobile: response.data[i]
                                                            .profiles.mobile || '',
                                                        oid: response.data[i].oid,
                                                        coinCredits:
                                                            response.data[i]
                                                                .balances.coins
                                                                .credits,
                                                        coinLastTopup:
                                                            response.data[i]
                                                                .balances.coins
                                                                .lastTopup,
                                                        coinLastTopupTime:
                                                            _coinLastTopupTime,
                                                        smsCredits:
                                                            response.data[i]
                                                                .balances.sms
                                                                .credits,
                                                        smsLastTopup:
                                                            response.data[i]
                                                                .balances.sms
                                                                .lastTopup,
                                                        smsLastTopupTime:
                                                            _smsLastTopupTime,
                                                        companyName:
                                                            _companiesSelected.name || '',                                                    
                                                    });
                                                }
                                            }

                                            console.log("Docs data", _docs);

                                            if (accountType.key === "paid") {
                                                _docs = _docs.filter((_doc) => {
                                                    return (
                                                        _doc.coinLastTopup !== null
                                                    );
                                                });
                                            } else if (accountType.key === "free") {
                                                _docs = _docs.filter((_doc) => {
                                                    return (
                                                        _doc.coinLastTopup === null
                                                    );
                                                });
                                            }

                                            let _firstPageDocs = [];

                                            for (
                                                let i =
                                                    (currentPage - 1) * pageSize;
                                                i <
                                                Math.min(
                                                    currentPage * pageSize,
                                                    _docs.length
                                                );
                                                i++
                                            ) {
                                                _firstPageDocs.push(_docs[i]);
                                            }

                                            console.log(
                                                "Docs first page data",
                                                _firstPageDocs
                                            );

                                            setItems(_firstPageDocs);

                                            setTotalDocuments(_docs.length);
                                            setPageData(
                                                paginate(
                                                    _docs.length,
                                                    currentPage,
                                                    pageSize,
                                                    10
                                                )
                                            );
                                            setDocuments(_docs);

                                            setTopupCoinCredits(_topupCoinCredits);

                                            setLoadDataComplete(true);
                                            setGetNewDocuments(false);
                                        },
                                        (error) => {
                                            console.log(error);
                                        }
                                    );
                        
                    
            
                })
                .catch((error) => {
                    //Acquire token silent failure, and send an interactive request
                    console.log(error);
                    instance.acquireTokenRedirect(silentRequest);
                });
        }
    }, [getNewDocuments]);

    useEffect(() => {
        if (documents.length > 0) {
            console.log("documents current page: ", documents);

            if (search) {
                if (filterDocuments.length > 0) {
                  const _docs = [];
          
                  for (
                    let i = (currentPage - 1) * pageSize;
                    i < Math.min(currentPage * pageSize, filterDocuments.length);
                    i++
                  ) {
                    _docs.push(filterDocuments[i]);
                  }
                  console.log('Docs data', _docs);
          
                  setItems(_docs);
                  //setLoadDataComplete(true);
                  //setGetNewDocuments(false);
                }
              } else {

                //let _subscriptions = subscriptions || [];

                const _docs = [];

                const _total = documents.length;

                for (
                    let i = (currentPage - 1) * pageSize;
                    i < Math.min(currentPage * pageSize, documents.length);
                    i++
                ) {
                    let createdTime = toThaiDateString(documents[i].createdTime);
                    /* 
                    let _subscriptions = subscriptions.find((item) => {
                        return item.createdBy === documents[i].oid;
                    });
    */
                    let _companies =
                        companies.find((item) => {
                            return item.createdBy === documents[i].oid;
                        }) || "";
                    /* 
                _docs.push({
                    key: documents[i].id,     
                    number: _total - i,
                    createdTime: createdTime,           
                    email: documents[i].profiles.emails,
                    firstName: documents[i].profiles.firstName,
                    lastName: documents[i].profiles.lastName,
                    name: documents[i].profiles.name,
                    mobile: documents[i].profiles.mobile,                
                    oid: documents[i].oid,               
                    planId: _subscriptions.plan.id,
                    planDocuments: _subscriptions.plan.documents,
                    planType: _subscriptions.plan.type,
                    spent: _subscriptions.documents.spent,
                    limit: _subscriptions.documents.limit,
                    topup: _subscriptions.documents.topup,
                    subscriptionTo: toThaiDateString(_subscriptions.to),
                    nextResetDate: toThaiDateString(_subscriptions.resetDate.next),
                    companyName: _companies.name,            
                });
    */
                    _docs.push(documents[i]);
                }
                console.log("Docs data", _docs);

                setItems(_docs);
                //setLoadDataComplete(true);
                //setGetNewDocuments(false);
            }
        }
    }, [currentPage]);

    useEffect(() => {
        if (documents.length > 0) {
        let totalDocs = documents;
        console.log('totalDocs: ', totalDocs);

        let filterDocs = totalDocs.filter(
            (doc) =>
                doc.name.includes(search) ||
                doc.firstName.includes(search) ||
                doc.lastName.includes(search) ||
                doc.mobile.includes(search) ||
                doc.email.includes(search) ||
                doc.oid.includes(search) ||
                doc.companyName.includes(search)
        );

        setPageData(paginate(filterDocs.length, currentPage, pageSize, 10));
        setFilterDocuments(filterDocs);
        setCurrentPage(1);

        if (filterDocs.length > 0) {

            let _docs = [];
            /* 
            let _topupCoinCredits = 0;
            let _total = filterDocs.length;
 */
            for (
                let i = (currentPage - 1) * pageSize;
                i < Math.min(currentPage * pageSize, filterDocs.length);
                i++
            ) {
                /* 
                let _createdTime = toThaiDateString();
                let _coinLastTopupTime = "";
                let _smsLastTopupTime = "";

                if (filterDocs[i].balances.coins.lastTopupTime) {
                    _coinLastTopupTime = toThaiDateString(
                        filterDocs[i].balances.coins.lastTopupTime
                    );
                }

                if (filterDocs[i].balances.sms.lastTopupTime) {
                    _smsLastTopupTime = toThaiDateString(
                        filterDocs[i].balances.sms.lastTopupTime
                    );
                }

                if (filterDocs[i].balances.coins.lastTopup) {
                    _topupCoinCredits =
                        _topupCoinCredits +
                        filterDocs[i].balances.coins.lastTopup;
                }

                _docs.push({
                    key: filterDocs[i].id,
                    number: _total - i,
                    createdTime: _createdTime,
                    email: filterDocs[i].profiles.emails[0],
                    firstName: filterDocs[i].profiles.firstName,
                    lastName: filterDocs[i].profiles.lastName,
                    name: filterDocs[i].profiles.name,
                    mobile: filterDocs[i].profiles.mobile,
                    oid: filterDocs[i].oid,
                    coinCredits: filterDocs[i].balances.coins.credits,
                    coinLastTopup: filterDocs[i].balances.coins.lastTopup,
                    coinLastTopupTime: _coinLastTopupTime,
                    smsCredits: filterDocs[i].balances.sms.credits,
                    smsLastTopup: filterDocs[i].balances.sms.lastTopup,
                    smsLastTopupTime: _smsLastTopupTime,
                    companyName: _companiesSelected.name,
                });

                _docs.push({
                    key: filterDocs[i].id,
                    id: filterDocs[i].id,
                    name: filterDocs[i].name,
                    taxId: filterDocs[i].taxId,
                    address:
                        filterDocs[i].buildingNumber +
                        " " +
                        filterDocs[i].address +
                        " " +
                        filterDocs[i].streetPrefix +
                        filterDocs[i].streetName +
                        " " +
                        filterDocs[i].subDistrictPrefix +
                        filterDocs[i].subDistrictName +
                        " " +
                        filterDocs[i].districtPrefix +
                        filterDocs[i].districtName +
                        " " +
                        filterDocs[i].provincePrefix +
                        filterDocs[i].provinceName +
                        " " +
                        filterDocs[i].postcode,
                    contactName: filterDocs[i].contactName,
                    phone: filterDocs[i].phone,
                }); 
                */

                _docs.push(filterDocs[i]);

            }
            setItems(_docs);
        } else {
            setItems([]);
        }

    }
    }, [search]);

    const onPageChange = (selectedPageIndex) => {
        console.log("selectedPageIndex: ", selectedPageIndex);
        setCurrentPage(selectedPageIndex + 1);
    };

    const columns = [
        {
            key: "column1",
            name: "Number",
            className: classNames.fileIconCell,
            iconClassName: classNames.fileIconHeaderIcon,
            iconName: "Contact",
            isIconOnly: true,
            fieldName: "name",
            minWidth: 30,
            maxWidth: 30,
            onColumnClick: "",

            onRender: (item) => {
                return <span>{item.number}</span>;
            },
        },
        {
            key: "column2",
            name: "วันที่สร้าง",
            fieldName: "createdTime",
            minWidth: 60,
            maxWidth: 125,
            isResizable: true,
            isSorted: true,
            isSortedDescending: true,
            onColumnClick: "",
            data: "number",
            onRender: (item) => {
                return <span>{item.createdTime}</span>;
            },
        },
/* 
        {
            key: "column3",
            name: "oid",
            fieldName: "oid",
            minWidth: 50,
            maxWidth: 50,
            isResizable: true,
            isSorted: true,
            isSortedDescending: true,
            onColumnClick: "",
            data: "number",
            onRender: (item) => {
                //   return <span>{item.oid}</span>;
                return (
                    <TooltipHost content={item.oid}>
                        <span>{item.oid}</span>
                    </TooltipHost>
                );
            },
        },
         */
        {
            key: "column4",
            name: "อีเมล",
            fieldName: "email",
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            isSorted: true,
            isSortedDescending: true,
            onColumnClick: "",
            data: "number",
            onRender: (item) => {
                //   return <span>{item.email}</span>;
                return (
                    <TooltipHost content={item.email}>
                        <span>{item.email}</span>
                    </TooltipHost>
                );
            },
        },
        {
            key: "column5",
            name: "ชื่อ",
            fieldName: "firstName",
            minWidth: 60,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: "Sorted A to Z",
            sortDescendingAriaLabel: "Sorted Z to A",
            onColumnClick: "",
            data: "string",
            onRender: (item) => {
                // return <span>{item.firstName}</span>;
                return (
                    <TooltipHost content={item.mobile}>
                        <span>{item.firstName}</span>
                    </TooltipHost>
                );
            },
        },
        {
            key: "column6",
            name: "ชื่อที่แสดง",
            fieldName: "name",
            minWidth: 50,
            maxWidth: 150,
            isResizable: true,
            //isSorted: true,
            //isSortedDescending: true,
            isCollapsible: true,
            onColumnClick: "",
            data: "string",
            onRender: (item) => {
                //   return <span>{item.name}</span>;
                return (
                    <TooltipHost content={item.name}>
                        <span>{item.name}</span>
                    </TooltipHost>
                );
            },
        },
        {
            key: "column7",
            name: "เบอร์โทร",
            fieldName: "mobile",
            minWidth: 70,
            maxWidth: 70,
            isResizable: true,
            //isSorted: true,
            //isSortedDescending: true,
            isCollapsible: true,
            onColumnClick: "",
            data: "string",
            onRender: (item) => {
                return <span>{item.mobile}</span>;
            },
        },
        {
            key: "column8",
            name: "บริษัท",
            fieldName: "companyName",
            minWidth: 100,
            maxWidth: 500,
            isRowHeader: true,
            isResizable: true,
            isCollapsible: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: "Sorted A to Z",
            sortDescendingAriaLabel: "Sorted Z to A",
            onColumnClick: "",
            data: "string",
            onRender: (item) => {
                // return <span>{item.companyName}</span>;
                return (
                    <TooltipHost content={item.companyName}>
                        <span>{item.companyName}</span>
                    </TooltipHost>
                );
            },
        },
/* 
        {
            key: "column9",
            name: "เอกสาร",
            fieldName: "coinCredits",
            minWidth: 50,
            maxWidth: 50,
            isResizable: true,
            //isSorted: true,
            //isSortedDescending: true,
            onColumnClick: "",
            data: "number",
            onRender: (item) => {
                return <span>{item.coinCredits}</span>;
            },
        },
        {
            key: "column10",
            name: "เติมล่าสุด",
            fieldName: "coinLastTopup",
            minWidth: 30,
            maxWidth: 30,
            isResizable: true,
            //isSorted: true,
            //isSortedDescending: true,
            onColumnClick: "",
            data: "number",
            onRender: (item) => {
                return <span>{item.coinLastTopup}</span>;
            },
        },
        {
            key: "column11",
            name: "เวลา",
            fieldName: "coinLastTopupTime",
            minWidth: 70,
            maxWidth: 125,
            isResizable: true,
            //isSorted: true,
            //isSortedDescending: true,
            onColumnClick: "",
            data: "string",
            onRender: (item) => {
                return (
                    <TooltipHost content={item.coinLastTopupTime}>
                        <span>{item.coinLastTopupTime}</span>
                    </TooltipHost>
                );
            },
        },
        {
            key: "column12",
            name: "SMS",
            fieldName: "smsCredits",
            minWidth: 30,
            maxWidth: 30,
            isResizable: true,
            //isSorted: true,
            //isSortedDescending: true,
            onColumnClick: "",
            data: "number",
            onRender: (item) => {
                return <span>{item.smsCredits}</span>;
            },
        },
        {
            key: "column13",
            name: "เติมล่าสุด",
            fieldName: "smsLastTopup",
            minWidth: 30,
            maxWidth: 30,
            isResizable: true,
            //isSorted: true,
            //isSortedDescending: true,
            onColumnClick: "",
            data: "number",
            onRender: (item) => {
                return <span>{item.smsLastTopup}</span>;
            },
        },
        {
            key: "column14",
            name: "เวลา",
            fieldName: "smsLastTopupTime",
            minWidth: 70,
            maxWidth: 125,
            isResizable: true,
            //isSorted: true,
            //isSortedDescending: true,
            onColumnClick: "",
            data: "string",
            onRender: (item) => {
                return (
                    <TooltipHost content={item.smsLastTopupTime}>
                        <span>{item.smsLastTopupTime}</span>
                    </TooltipHost>
                );
            },
        },
         */
        {
            key: "column15",
            name: "",
            fieldName: "command",
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            //isCollapsible: true,
            data: "string",
            onColumnClick: "",
            onRender: (item) => {
                return (
                    <DefaultButton
                        text="เลือก"
                        //iconProps={{ iconName: 'Add' }}
                        menuProps={{
                            items: [
                                {
                                    key: "add-document-credits",
                                    text: "เพิ่มเครดิตเอกสาร",
                                    iconProps: { iconName: "PageAdd" },
                                    onClick: (event) => {
                                        history.push({
                                            pathname:
                                                "/accounts/credits/documents/add",
                                            state: item,
                                        });
                                    },
                                },
                                {
                                    key: "add-sms-credits",
                                    text: "เพิ่มเครดิต SMS",
                                    iconProps: { iconName: "CommentAdd" },
                                    onClick: (event) => {
                                        history.push({
                                            pathname:
                                                "/accounts/credits/sms/add",
                                            state: item,
                                        });
                                    },
                                },
                                
                            ],
                            shouldFocusOnMount: true,
                            directionalHintFixed: true,
                        }}
                        // Optional callback to customize menu rendering
                        menuAs={(props) => <ContextualMenu {...props} />}
                        // Optional callback to do other actions (besides opening the menu) on click
                        //onMenuClick={(ev) => { console.log(ev); }}
                        // By default, the ContextualMenu is re-created each time it's shown and destroyed when closed.
                        // Uncomment the next line to hide the ContextualMenu but persist it in the DOM instead.
                        // persistMenu={true}
                        //allowDisabledFocus
                        //disabled={(props) => props}
                        //checked={(props) => props}
                    />
                );
            },
        },
    ];

    const isCompactMode = false;
    //const isModealSelection = false;
    //const annoucedMessage = undefined;

    return (
        <div style={homeStyles}>
            <Stack>
                <center>
                    <h2 style={{ marginLeft: "0px", marginTop: "25px" }}>
                        บัญชีผู้ใช้งาน (Prepaid)
                    </h2>
                    {/* <h4>( สำหรับส่งให้กรมสรรพากร )</h4> */}
                    {/* <br/> */}
                </center>

                <Stack
                    horizontal
                    horizontalAlign="center"
                    style={{
                    // width: '98%',
                    // maxWidth: '1350px',
                    // marginRight: '-50px'
                    marginTop: '-15px',
                    }}
                >
                    <ActionButton
                    iconProps={{
                        iconName: 'QRCode',
                        style: {
                        fontSize: 22,
                        // color: '#FFFFFF',
                        marginTop: '-5px',
                        // marginRight: '-30px',
                        },
                    }}
                    onClick={() => {
                        history.push({
                        pathname: '/billings',
                        //search: '?update=true',  // query string
                        state: {},
                        });
                    }}
                    >
                    <span
                        style={{
                        fontSize: 16,
                        // color: '#FFFFFF',
                        // marginTop: '-50px',
                        // marginRight: '-30px',
                        // marginLeft: '50px'
                        }}
                    >
                        เติมเครดิต เร็วทันใจ 24 ชม.
                    </span>
                    </ActionButton>
                </Stack>
{/* 
                <Stack
                    horizontal
                    horizontalAlign="center"
                    tokens={{ childrenGap: "30px" }}
                >
                    <DocumentCard
                        aria-label="จำนวนบัญชี"
                        styles={cardStyles.cardStyles}
                        type={DocumentCardType.compact}
                        //onClickHref="http://bing.com"
                    >
                        <DocumentCardLogo
                            {...{ logoIcon: "Contact" }}
                            title="จำนวนบัญชี"
                        />
                        <DocumentCardDetails>
                            <DocumentCardTitle
                                title="จำนวนบัญชี"
                                //shouldTruncate
                                styles={cardStyles.header}
                            />
                            <Text styles={cardStyles.amount}>
                                <CountUp
                                    end={totalDocuments}
                                    separator=","
                                    duration={1}
                                />{" "}
                                บัญชี
                            </Text>
                        </DocumentCardDetails>
                    </DocumentCard>

                    <DocumentCard
                        aria-label="เติมเครดิตเอกสาร"
                        styles={cardStyles.cardStyles}
                        type={DocumentCardType.compact}
                        //onClickHref="http://bing.com"
                    >
                        <DocumentCardLogo
                            {...{ logoIcon: "Documentation" }}
                            title="เติมเครดิตเอกสาร"
                        />
                        <DocumentCardDetails>
                            <DocumentCardTitle
                                title="เติมเครดิตเอกสาร (ล่าสุด)"
                                //shouldTruncate
                                styles={cardStyles.header}
                            />
                            <Text styles={cardStyles.amount}>
                                <CountUp
                                    end={topupCoinCredits}
                                    separator=","
                                    duration={1}
                                />{" "}
                                เครดิต
                            </Text>
                        </DocumentCardDetails>
                    </DocumentCard>

                    <DocumentCard
                        aria-label="ประมาณการรายได้"
                        styles={cardStyles.cardStyles}
                        type={DocumentCardType.compact}
                        //onClickHref="http://bing.com"
                    >
                        <DocumentCardLogo
                            {...{ logoIcon: "Money" }}
                            title="ประมาณการรายได้"
                        />
                        <DocumentCardDetails>
                            <DocumentCardTitle
                                title="ประมาณการรายได้ (8 บาท/เครดิต)"
                                //shouldTruncate
                                styles={cardStyles.header}
                            />
                            <Text styles={cardStyles.amount}>
                                <CountUp
                                    end={topupCoinCredits * 8}
                                    separator=","
                                    decimals={0}
                                    decimal="."
                                    duration={1}
                                />{" "}
                                บาท
                            </Text>
                        </DocumentCardDetails>
                    </DocumentCard>
                </Stack>
                 */}
            </Stack>

            <br />

            <Stack horizontal horizontalAlign="center">
                <Stack
                    vertical
                    style={{
                        width: "98%",
                        //minWidth: '1070px',
                        maxWidth: "1350px",
                    }}
                >
                    <Stack
                        horizontal
                        className="ms-bgColor-white"
                        horizontalAlign="space-between"
                    >
                        <CommandBar
                            items={commandBarItems}
                            styles={{
                                root: {
                                width: 668,
                                },
                            }}
                            //overflowItems={_overflowItems}
                            //overflowButtonProps={overflowProps}
                            //farItems={_farItems}
                            //ariaLabel="Use left and right arrow keys to navigate between commands"
                        />
                        <Stack
                            horizontal
                            horizontalAlign='end'
                            styles={{
                                root: {
                                    height: 44,
                                    backgroundColor: '#FFFFFF',
                                    width: '100%'
                                },
                            }}
                            tokens={{ childrenGap: "10px" }}
                        >
                            <SearchBox
                                className="ms-borderColor-themePrimary"
                                styles={{
                                    root: {
                                        marginTop: 6,
                                        marginLeft: 6,
                                        width: 200,
                                        fontSize: 13,
                                        fontWeight: 0,
                                    },
                                }}
                                disabled={!loadDataComplete}
                                // placeholder="เลขที่ออเดอร์ Lazada"
                                onChange={(e) =>
                                    setSearch(e ? e.target.value : "")
                                }
                                onClear={() => {
                                    setLoadDataComplete(false);
                                    setDocuments([]);
                                    setItems([]);
                                    setGetNewDocuments(true);

                                    setCurrentPage(1);

                                    setTotalDocuments(0);
                                    setTotalPaidAccounts(0);
                                    setTopupCoinCredits(0);
                                }}
                                onSearch={() => {
                                    setLoadDataComplete(false);
                                    setDocuments([]);
                                    setItems([]);
                                    setGetNewDocuments(true);

                                    setCurrentPage(1);

                                    setTotalDocuments(0);
                                    setTotalPaidAccounts(0);
                                    setTopupCoinCredits(0);
                                }}
                                value={search}
                            />
                            <PrimaryButton
                                // iconProps={{ iconName: 'search' }}
                                disabled={!loadDataComplete || !search}
                                styles={{
                                    root: {
                                        marginTop: 6,
                                        marginRight: 8,
                                        width: 90,
                                    },
                                }}
                                text="ค้นหา"
                                onClick={() => {
                                    setLoadDataComplete(false);
                                    setDocuments([]);
                                    setItems([]);
                                    setGetNewDocuments(true);

                                    setCurrentPage(1);

                                    setTotalDocuments(0);
                                    setTotalPaidAccounts(0);
                                    setTopupCoinCredits(0);
                                }}
                            />
                        </Stack>
                        {/* </div> */}
                    </Stack>
                    <ShimmeredDetailsList
                        items={items || []}
                        enableShimmer={!loadDataComplete}
                        compact={isCompactMode}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                        //selectionMode={SelectionMode.multiple}
                        //getKey="1"
                        //setKey="set"
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                        //selection={Selection}
                        //selectionPreservedOnEmptyClick={true}
                        //onItemInvoked={download}
                        //enterModalSelectionOnTouch={true}
                        //ariaLabelForSelectionColumn="Toggle selection"
                        //ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                        //checkButtonAriaLabel="Row checkbox"
                        //onRenderItemColumn={ this.onRenderItemColumn }
                        //listProps={shimmeredDetailsListProps}
                    />
                </Stack>
            </Stack>
            {/* </Fabric> */}
            <br />
            <center>
                {!(documents.length === 0 || documents.length < pageSize) && (
                    <Pagination
                        //selectedPageIndex={page}
                        selectedPageIndex={currentPage - 1}
                        //pageCount={pageCount}
                        pageCount={pageData.totalPages}
                        onPageChange={onPageChange}
                        format
                        firstPageIconProps={{ iconName: "DoubleChevronLeft" }}
                        previousPageIconProps={{ iconName: "ChevronLeft" }}
                        nextPageIconProps={{ iconName: "ChevronRight" }}
                        lastPageIconProps={{ iconName: "DoubleChevronRight" }}
                    />
                )}

                <br />
                <br />
            </center>
        </div>
    );
};

export default PrepaidAccounts;
