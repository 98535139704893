import React, { useState, useEffect } from 'react';
import axios from 'axios';
//import { useHistory } from 'react-router-dom';


import { useMsal, useAccount } from "@azure/msal-react";
import { silentRequest } from "../../authConfig";

import paginate from 'jw-paginate';

import {     
    mergeStyles, 
    PrimaryButton,     
    Stack, 
    getTheme, 
    Shimmer,
    ShimmerElementType,  
    Icon,      
    Toggle,
    ProgressIndicator,
    TooltipHost,
    mergeStyleSets,
    ActionButton,
    SelectionMode,
    DetailsListLayoutMode,
    ConstrainMode,
    ShimmeredDetailsList,
    Dialog,
    DialogType,
    Spinner,
    Separator,
    Pivot, 
    PivotItem    
    
} from '@fluentui/react';

import { Pagination } from '@uifabric/experiments/lib/Pagination';

const API_URL = process.env.REACT_APP_API_URL;

const theme = getTheme();

const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
      padding: 0,
      fontSize: '16px',
    },
    fileIconCell: {
      textAlign: 'center',
      selectors: {
        '&:before': {
          content: '.',
          display: 'inline-block',
          verticalAlign: 'middle',
          height: '100%',
          width: '0px',
          visibility: 'hidden',
        },
      },
    },
    fileIconImg: {
      verticalAlign: 'middle',
      maxHeight: '16px',
      maxWidth: '16px',
    },
    controlWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    exampleToggle: {
      display: 'inline-block',
      marginBottom: '10px',
      marginRight: '30px',
    },
    selectionDetails: {
      marginBottom: '20px',
    },
});






const PaymentHistory = () => {

    const { instance, accounts } = useMsal();
    //const account = useAccount(accounts[0] || {});
    const account = instance.getAllAccounts()[0];

    const [documents, setDocuments] = useState([]);
    const [items, setItems] = useState([]);
    const [loadDataComplete, setLoadDataComplete] = useState(false);
    const [getNewDocuments, setGetNewDocuments] = useState(true);
    const [totalProcessingDocuments, setTotalProcessingDocuments] = useState(0);
    //const [clickDelete, setClickDelete] = useState(false);
    const [pageData, setPageData] = useState({});
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const toThaiDateString = (isoDateTime) => {
        let date = '';
        date = new Date(isoDateTime);
        let year = date.getFullYear() + 543;
        let month = (date.getMonth() + 1).toString().padStart(2, '0');
        let numOfDay = date.getDate().toString().padStart(2, '0');
    
        let hour = date.getHours().toString().padStart(2, '0');
        let minutes = date.getMinutes().toString().padStart(2, '0');
        let second = date.getSeconds().toString().padStart(2, '0');
    
        return `${numOfDay}/${month}/${year} ${hour}:${minutes}:${second} น.`;
      };

    const onPageChange = (selectedPageIndex) => {
        console.log('selectedPageIndex: ', selectedPageIndex);
        setCurrentPage(selectedPageIndex+1);
    }

    useEffect(() => {
        console.log('userEffect get orders Call!');

        instance.acquireTokenSilent({...silentRequest, account: account}).then(tokenResponse => {
            // Do something with the tokenResponse
            console.log('Token response: ', tokenResponse);       

                
            //axios.get('https://ws.leceipt.com/documents', 
            //axios.get(API_URL + '/documents?type=ETAX&subtype=' + documentType.key + '&fileextension=pdf&from=' + from + '&to=' + to, 
            axios.get(API_URL + '/billings/orders', 
            {
                headers: { 
                'Authorization': 'Bearer ' + tokenResponse.accessToken
                } 
            })
            .then((response) => {

                console.log(response);
                console.log('Docs number: ', response.data.length);

                //setTotalDocuments(response.data.length);

                //set page
                console.log('Page data: ', paginate(response.data.length, 1, 5, 10));
                setPageData(paginate(response.data.length, currentPage, pageSize, 10));

                setDocuments(response.data);

                const _docs = [];
                
                if (response.data.length > 0) {

                    
                
                    for (let i = (currentPage-1)*pageSize; i < Math.min(currentPage*pageSize, response.data.length); i++) {
                        //let documentName = '';
                        //let fileSize = formatBytes(response.data[i].fileSize);  
                        //let fileSize = formatBytes(response.data[i].file.size);  
                        let createdTime = toThaiDateString(response.data[i].createdTime);                       

                        //let _amountTotal = 0;
                        //let _vatTotal = 0;
                        let _grandTotal = 0;
                        let _description = ''

                        let _status = '';
                        let _paymentMethod = '';

                        if (response.data[i].description) {
                            _description = response.data[i].description;
                        }

                        if (response.data[i].data && response.data[i].data.grandTotal && (response.data[i].data.grandTotal !== undefined)) {
                            _grandTotal = response.data[i].data.grandTotal;
                        }

                        if (response.data[i].payment.status) {
                            if (response.data[i].payment.status === 'successful') {
                                _status = 'สำเร็จ';
                            } else if (response.data[i].payment.status === 'pending') {
                                _status = 'รอชำระ';
                            }
                            
                        }

                        if (response.data[i].payment.method) {
                            _paymentMethod = "QR Code เงินสด"
                        }
                        
                        //let certificateId = response.data[i].certificateId;
                        // if (response.data[i].payment.status === 'successful') {
                            _docs.push({
                                key: response.data[i].id,                            
                                createdBy: response.data[i].createdBy,
                                createdTime: createdTime,
                                createdTimeValue: response.data[i].createdTime,                            
                                description: _description,
                                paymentMethod: _paymentMethod,
                                paymentStatus: _status,                            
                                //amountTotal: _amountTotal,                
                                //vatTotal: _vatTotal,
                                grandTotal: _grandTotal,
                    
                            });

                        // }
                        
                    }

                }

                console.log('Docs data', _docs);
                setItems(_docs);

                setLoadDataComplete(true);        

            }, (error) => {
                console.log(error);
            });
                
                


        }).catch(error => {

            //Acquire token silent failure, and send an interactive request
            console.log(error);
            instance.acquireTokenRedirect(silentRequest);

        });

    

    }, []);

    useEffect(() => {

        if (documents.length > 0) {        
  
          const _docs = [];
          
          for (let i = (currentPage-1)*pageSize; i < Math.min(currentPage*pageSize, documents.length); i++) {

            let createdTime = toThaiDateString(documents[i].createdTime);                       

            //let _amountTotal = 0;
            //let _vatTotal = 0;
            let _grandTotal = 0;
            let _description = ''
            let _status = '';
            let _paymentMethod = '';

            if (documents[i].description) {
                _description = documents[i].description;
            }

            if (documents[i].data && documents[i].data.grandTotal && (documents[i].data.grandTotal !== undefined)) {
                _grandTotal = documents[i].data.grandTotal;
            }

            if (documents[i].payment.status) {
                if (documents[i].payment.status === 'successful') {
                    _status = 'สำเร็จ';
                } else if (documents[i].payment.status === 'pending') {
                    _status = 'รอชำระ';
                }
                
            }

            if (documents[i].payment.method) {
                _paymentMethod = "QR Code เงินสด"
            }
            
            // if (documents[i].payment.status === 'successful') {

                _docs.push({
                    key: documents[i].id,                            
                    createdBy: documents[i].createdBy,
                    createdTime: createdTime,
                    createdTimeValue: documents[i].createdTime,                            
                    description: documents[i].description,
                    paymentMethod: _paymentMethod,
                    paymentStatus: _status,                            
                    //amountTotal: _amountTotal,                
                    //vatTotal: _vatTotal,
                    grandTotal: _grandTotal,
        
                });
            // }
  
            
          }
          console.log('Docs data', _docs);
  
          
          
          
          setItems(_docs);
          //setLoadDataComplete(true);
          //setGetNewDocuments(false);
        }
  
      }, [currentPage]);

    const columns = [    
        {
          key: 'column1',
          name: 'วันที่',        
          fieldName: 'date',
          minWidth: 150,
          maxWidth: 150,
          isRowHeader: true,
          isResizable: true,
          isSorted: false,
          isSortedDescending: false,
          //sortAscendingAriaLabel: 'Sorted A to Z',
          //sortDescendingAriaLabel: 'Sorted Z to A',
          onColumnClick: '',
          data: 'string',
          onRender: (item) => {
            //return <ActionButton title="ดาวน์โหลด" className={classNames.fileIconImg} onClick={(event) => {download(event, item)}} >{item.name}</ActionButton>;
            //return <span>{"**** **** **** " + item.last_digits}</span>;
            return <span>{item.createdTime}</span>;
          },
          isPadded: true,        
          
        },  
        {
          key: 'column2',
          name: 'รายละเอียด',
          fieldName: 'description',
          minWidth: 180,
          maxWidth: 350,
          isResizable: true,
          //isCollapsible: true,
          data: 'string',
          onColumnClick: '',
          onRender: (item) => {
            //return <span>{item.expiration_month}/{item.expiration_year}</span>;
            return <span>{item.description}</span>;
          },
          isPadded: true,
        },
        {
            key: 'column3',
            name: 'วิธีชำระเงิน',
            fieldName: 'paymentMethod',
            minWidth: 100,
            maxWidth: 120,
            isResizable: true,
            //isCollapsible: true,
            data: 'string',
            onColumnClick: '',
            onRender: (item) => {            
                
                return <span>{item.paymentMethod}</span>;
            },
            isPadded: true,
          },
        
        {
          key: 'column4',
          name: 'จำนวนเงิน',
          fieldName: 'amount',
          minWidth: 70,
          maxWidth: 100,
          isResizable: true,
          //isCollapsible: true,
          data: 'string',
          onColumnClick: '',
          onRender: (item) => {
            
            return <span style={{ display: 'block', textAlign: 'right' }}>{item.grandTotal.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2})} บ.</span>;
          },
          isPadded: true,
        },
        {
            key: 'column5',
            name: 'สถานะ',
            fieldName: 'status',
            minWidth: 70,
            maxWidth: 100,
            isResizable: true,
            //isCollapsible: true,
            data: 'string',
            onColumnClick: '',
            onRender: (item) => {
              return <span>{item.paymentStatus}</span>;
            },
            // isPadded: true,
          },
      
    ];

    return (
        <div>
        
            <div style={{ width: '880px', paddingLeft: 15, paddingRight: 15, paddingTop: 0, paddingBottom: 10, background: '#ffffff', marginLeft: '0', marginTop: '0', boxShadow: theme.effects.elevation4 }}> 
                <ShimmeredDetailsList
                items={items || []}
                //items={[]}
                enableShimmer={!loadDataComplete}
                compact={false}
                columns={columns}
                selectionMode={SelectionMode.none}
                //selectionMode={SelectionMode.multiple}
                //getKey="1"
                //setKey="set"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                //selection={Selection}
                //selectionPreservedOnEmptyClick={true}
                //onItemInvoked={download}
                //enterModalSelectionOnTouch={true}
                //ariaLabelForSelectionColumn="Toggle selection"
                //ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                //checkButtonAriaLabel="Row checkbox"
                //onRenderItemColumn={ this.onRenderItemColumn }
                //listProps={shimmeredDetailsListProps}
                
                />            
            </div>

        <br/>
        <center>
            {!(documents.length===0 || documents.length < pageSize) && <Pagination
            //selectedPageIndex={page}
            selectedPageIndex={currentPage-1}
            //pageCount={pageCount}
            pageCount={pageData.totalPages}
            onPageChange={onPageChange}
            format
            firstPageIconProps={{ iconName: 'DoubleChevronLeft' }}
            previousPageIconProps={{ iconName: 'ChevronLeft' }}
            nextPageIconProps={{ iconName: 'ChevronRight' }}
            lastPageIconProps={{ iconName: 'DoubleChevronRight' }}
            />}
        </center>
        </div>

    );
}


export default PaymentHistory;