export const province = [
  {
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "provinceCode": "74",
    "provinceNameTh": "สมุทรสาคร",
    "provinceNameEn": "Samut Sakhon"
},
{
    "provinceCode": "75",
    "provinceNameTh": "สมุทรสงคราม",
    "provinceNameEn": "Samut Songkhram"
},
{
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "provinceCode": "83",
    "provinceNameTh": "ภูเก็ต",
    "provinceNameEn": "Phuket"
},
{
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "provinceCode": "85",
    "provinceNameTh": "ระนอง",
    "provinceNameEn": "Ranong"
},
{
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "provinceCode": "91",
    "provinceNameTh": "สตูล",
    "provinceNameEn": "Satun"
},
{
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
}  
];


export const district = [
  {
    "districtCode": "1001",
    "districtNameTh": "พระนคร",
    "districtNameEn": "Phra Nakhon",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1002",
    "districtNameTh": "ดุสิต",
    "districtNameEn": "Dusit",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1003",
    "districtNameTh": "หนองจอก",
    "districtNameEn": "Nong Chok",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1004",
    "districtNameTh": "บางรัก",
    "districtNameEn": "Bang Rak",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1005",
    "districtNameTh": "บางเขน",
    "districtNameEn": "Bang Khen",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1006",
    "districtNameTh": "บางกะปิ",
    "districtNameEn": "Bang Kapi",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1007",
    "districtNameTh": "ปทุมวัน",
    "districtNameEn": "Pathum Wan",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1008",
    "districtNameTh": "ป้อมปราบศัตรูพ่าย",
    "districtNameEn": "Pom Prap Sattru Phai",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1009",
    "districtNameTh": "พระโขนง",
    "districtNameEn": "Phra Khanong",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1010",
    "districtNameTh": "มีนบุรี",
    "districtNameEn": "Min Buri",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1011",
    "districtNameTh": "ลาดกระบัง",
    "districtNameEn": "Lat Krabang",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1012",
    "districtNameTh": "ยานนาวา",
    "districtNameEn": "Yan Nawa",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1013",
    "districtNameTh": "สัมพันธวงศ์",
    "districtNameEn": "Samphanthawong",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1014",
    "districtNameTh": "พญาไท",
    "districtNameEn": "Phaya Thai",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1015",
    "districtNameTh": "ธนบุรี",
    "districtNameEn": "Thon Buri",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1016",
    "districtNameTh": "บางกอกใหญ่",
    "districtNameEn": "Bangkok Yai",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1017",
    "districtNameTh": "ห้วยขวาง",
    "districtNameEn": "Huai Khwang",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1018",
    "districtNameTh": "คลองสาน",
    "districtNameEn": "Khlong San",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1019",
    "districtNameTh": "ตลิ่งชัน",
    "districtNameEn": "Taling Chan",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1020",
    "districtNameTh": "บางกอกน้อย",
    "districtNameEn": "Bangkok Noi",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1021",
    "districtNameTh": "บางขุนเทียน",
    "districtNameEn": "Bang Khun Thian",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1022",
    "districtNameTh": "ภาษีเจริญ",
    "districtNameEn": "Phasi Charoen",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1023",
    "districtNameTh": "หนองแขม",
    "districtNameEn": "Nong Khaem",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1024",
    "districtNameTh": "ราษฎร์บูรณะ",
    "districtNameEn": "Rat Burana",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1025",
    "districtNameTh": "บางพลัด",
    "districtNameEn": "Bang Phlat",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1026",
    "districtNameTh": "ดินแดง",
    "districtNameEn": "Din Daeng",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1027",
    "districtNameTh": "บึงกุ่ม",
    "districtNameEn": "Bueng Kum",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1028",
    "districtNameTh": "สาทร",
    "districtNameEn": "Sathon",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1029",
    "districtNameTh": "บางซื่อ",
    "districtNameEn": "Bang Sue",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1030",
    "districtNameTh": "จตุจักร",
    "districtNameEn": "Chatuchak",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1031",
    "districtNameTh": "บางคอแหลม",
    "districtNameEn": "Bang Kho Laem",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1032",
    "districtNameTh": "ประเวศ",
    "districtNameEn": "Prawet",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1033",
    "districtNameTh": "คลองเตย",
    "districtNameEn": "Khlong Toei",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1034",
    "districtNameTh": "สวนหลวง",
    "districtNameEn": "Suan Luang",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1035",
    "districtNameTh": "จอมทอง",
    "districtNameEn": "Chom Thong",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1036",
    "districtNameTh": "ดอนเมือง",
    "districtNameEn": "Don Mueang",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1037",
    "districtNameTh": "ราชเทวี",
    "districtNameEn": "Ratchathewi",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1038",
    "districtNameTh": "ลาดพร้าว",
    "districtNameEn": "Lat Phrao",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1039",
    "districtNameTh": "วัฒนา",
    "districtNameEn": "Vadhana",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1040",
    "districtNameTh": "บางแค",
    "districtNameEn": "Bang Khae",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1041",
    "districtNameTh": "หลักสี่",
    "districtNameEn": "Lak Si",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1042",
    "districtNameTh": "สายไหม",
    "districtNameEn": "Sai Mai",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1043",
    "districtNameTh": "คันนายาว",
    "districtNameEn": "Khan Na Yao",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1044",
    "districtNameTh": "สะพานสูง",
    "districtNameEn": "Saphan Sung",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1045",
    "districtNameTh": "วังทองหลาง",
    "districtNameEn": "Wang Thonglang",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1046",
    "districtNameTh": "คลองสามวา",
    "districtNameEn": "Khlong Sam Wa",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1047",
    "districtNameTh": "บางนา",
    "districtNameEn": "Bang Na",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1048",
    "districtNameTh": "ทวีวัฒนา",
    "districtNameEn": "Thawi Watthana",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1049",
    "districtNameTh": "ทุ่งครุ",
    "districtNameEn": "Thung Khru",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1050",
    "districtNameTh": "บางบอน",
    "districtNameEn": "Bang Bon",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "districtCode": "1101",
    "districtNameTh": "เมืองสมุทรปราการ",
    "districtNameEn": "Mueang Samut Prakan",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "districtCode": "1102",
    "districtNameTh": "บางบ่อ",
    "districtNameEn": "Bang Bo",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "districtCode": "1103",
    "districtNameTh": "บางพลี",
    "districtNameEn": "Bang Phli",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "districtCode": "1104",
    "districtNameTh": "พระประแดง",
    "districtNameEn": "Phra Pradaeng",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "districtCode": "1105",
    "districtNameTh": "พระสมุทรเจดีย์",
    "districtNameEn": "Phra Samut Chedi",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "districtCode": "1106",
    "districtNameTh": "บางเสาธง",
    "districtNameEn": "Bang Sao Thong",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "districtCode": "1201",
    "districtNameTh": "เมืองนนทบุรี",
    "districtNameEn": "Mueang Nonthaburi",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "districtCode": "1202",
    "districtNameTh": "บางกรวย",
    "districtNameEn": "Bang Kruai",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "districtCode": "1203",
    "districtNameTh": "บางใหญ่",
    "districtNameEn": "Bang Yai",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "districtCode": "1204",
    "districtNameTh": "บางบัวทอง",
    "districtNameEn": "Bang Bua Thong",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "districtCode": "1205",
    "districtNameTh": "ไทรน้อย",
    "districtNameEn": "Sai Noi",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "districtCode": "1206",
    "districtNameTh": "ปากเกร็ด",
    "districtNameEn": "Pak Kret",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "districtCode": "1301",
    "districtNameTh": "เมืองปทุมธานี",
    "districtNameEn": "Mueang Pathum Thani",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "districtCode": "1302",
    "districtNameTh": "คลองหลวง",
    "districtNameEn": "Khlong Luang",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "districtCode": "1303",
    "districtNameTh": "ธัญบุรี",
    "districtNameEn": "Thanyaburi",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "districtCode": "1304",
    "districtNameTh": "หนองเสือ",
    "districtNameEn": "Nong Suea",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "districtCode": "1305",
    "districtNameTh": "ลาดหลุมแก้ว",
    "districtNameEn": "Lat Lum Kaeo",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "districtCode": "1306",
    "districtNameTh": "ลำลูกกา",
    "districtNameEn": "Lam Luk Ka",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "districtCode": "1307",
    "districtNameTh": "สามโคก",
    "districtNameEn": "Sam Khok",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "districtCode": "1401",
    "districtNameTh": "พระนครศรีอยุธยา",
    "districtNameEn": "Phra Nakhon Si Ayutthaya",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "districtCode": "1402",
    "districtNameTh": "ท่าเรือ",
    "districtNameEn": "Tha Ruea",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "districtCode": "1403",
    "districtNameTh": "นครหลวง",
    "districtNameEn": "Nakhon Luang",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "districtCode": "1404",
    "districtNameTh": "บางไทร",
    "districtNameEn": "Bang Sai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "districtCode": "1405",
    "districtNameTh": "บางบาล",
    "districtNameEn": "Bang Ban",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "districtCode": "1406",
    "districtNameTh": "บางปะอิน",
    "districtNameEn": "Bang Pa-in",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "districtCode": "1407",
    "districtNameTh": "บางปะหัน",
    "districtNameEn": "Bang Pahan",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "districtCode": "1408",
    "districtNameTh": "ผักไห่",
    "districtNameEn": "Phak Hai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "districtCode": "1409",
    "districtNameTh": "ภาชี",
    "districtNameEn": "Phachi",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "districtCode": "1410",
    "districtNameTh": "ลาดบัวหลวง",
    "districtNameEn": "Lat Bua Luang",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "districtCode": "1411",
    "districtNameTh": "วังน้อย",
    "districtNameEn": "Wang Noi",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "districtCode": "1412",
    "districtNameTh": "เสนา",
    "districtNameEn": "Sena",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "districtCode": "1413",
    "districtNameTh": "บางซ้าย",
    "districtNameEn": "Bang Sai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "districtCode": "1414",
    "districtNameTh": "อุทัย",
    "districtNameEn": "Uthai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "districtCode": "1415",
    "districtNameTh": "มหาราช",
    "districtNameEn": "Maha Rat",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "districtCode": "1416",
    "districtNameTh": "บ้านแพรก",
    "districtNameEn": "Ban Phraek",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "districtCode": "1501",
    "districtNameTh": "เมืองอ่างทอง",
    "districtNameEn": "Mueang Ang Thong",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "districtCode": "1502",
    "districtNameTh": "ไชโย",
    "districtNameEn": "Chaiyo",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "districtCode": "1503",
    "districtNameTh": "ป่าโมก",
    "districtNameEn": "Pa Mok",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "districtCode": "1504",
    "districtNameTh": "โพธิ์ทอง",
    "districtNameEn": "Pho Thong",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "districtCode": "1505",
    "districtNameTh": "แสวงหา",
    "districtNameEn": "Sawaeng Ha",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "districtCode": "1506",
    "districtNameTh": "วิเศษชัยชาญ",
    "districtNameEn": "Wiset Chai Chan",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "districtCode": "1507",
    "districtNameTh": "สามโก้",
    "districtNameEn": "Samko",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "districtCode": "1601",
    "districtNameTh": "เมืองลพบุรี",
    "districtNameEn": "Mueang Lop Buri",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "districtCode": "1602",
    "districtNameTh": "พัฒนานิคม",
    "districtNameEn": "Phatthana Nikhom",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "districtCode": "1603",
    "districtNameTh": "โคกสำโรง",
    "districtNameEn": "Khok Samrong",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "districtCode": "1604",
    "districtNameTh": "ชัยบาดาล",
    "districtNameEn": "Chai Badan",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "districtCode": "1605",
    "districtNameTh": "ท่าวุ้ง",
    "districtNameEn": "Tha Wung",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "districtCode": "1606",
    "districtNameTh": "บ้านหมี่",
    "districtNameEn": "Ban Mi",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "districtCode": "1607",
    "districtNameTh": "ท่าหลวง",
    "districtNameEn": "Tha Luang",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "districtCode": "1608",
    "districtNameTh": "สระโบสถ์",
    "districtNameEn": "Sa Bot",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "districtCode": "1609",
    "districtNameTh": "โคกเจริญ",
    "districtNameEn": "Khok Charoen",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "districtCode": "1610",
    "districtNameTh": "ลำสนธิ",
    "districtNameEn": "Lam Sonthi",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "districtCode": "1611",
    "districtNameTh": "หนองม่วง",
    "districtNameEn": "Nong Muang",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "districtCode": "1701",
    "districtNameTh": "เมืองสิงห์บุรี",
    "districtNameEn": "Mueang Sing Buri",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "districtCode": "1702",
    "districtNameTh": "บางระจัน",
    "districtNameEn": "Bang Rachan",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "districtCode": "1703",
    "districtNameTh": "ค่ายบางระจัน",
    "districtNameEn": "Khai Bang Rachan",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "districtCode": "1704",
    "districtNameTh": "พรหมบุรี",
    "districtNameEn": "Phrom Buri",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "districtCode": "1705",
    "districtNameTh": "ท่าช้าง",
    "districtNameEn": "Tha Chang",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "districtCode": "1706",
    "districtNameTh": "อินทร์บุรี",
    "districtNameEn": "In Buri",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "districtCode": "1801",
    "districtNameTh": "เมืองชัยนาท",
    "districtNameEn": "Mueang Chai Nat",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "districtCode": "1802",
    "districtNameTh": "มโนรมย์",
    "districtNameEn": "Manorom",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "districtCode": "1803",
    "districtNameTh": "วัดสิงห์",
    "districtNameEn": "Wat Sing",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "districtCode": "1804",
    "districtNameTh": "สรรพยา",
    "districtNameEn": "Sapphaya",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "districtCode": "1805",
    "districtNameTh": "สรรคบุรี",
    "districtNameEn": "Sankhaburi",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "districtCode": "1806",
    "districtNameTh": "หันคา",
    "districtNameEn": "Hankha",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "districtCode": "1807",
    "districtNameTh": "หนองมะโมง",
    "districtNameEn": "Nong Mamong",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "districtCode": "1808",
    "districtNameTh": "เนินขาม",
    "districtNameEn": "Noen Kham",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "districtCode": "1901",
    "districtNameTh": "เมืองสระบุรี",
    "districtNameEn": "Mueang Saraburi",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "districtCode": "1902",
    "districtNameTh": "แก่งคอย",
    "districtNameEn": "Kaeng Khoi",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "districtCode": "1903",
    "districtNameTh": "หนองแค",
    "districtNameEn": "Nong Khae",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "districtCode": "1904",
    "districtNameTh": "วิหารแดง",
    "districtNameEn": "Wihan Daeng",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "districtCode": "1905",
    "districtNameTh": "หนองแซง",
    "districtNameEn": "Nong Saeng",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "districtCode": "1906",
    "districtNameTh": "บ้านหมอ",
    "districtNameEn": "Ban Mo",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "districtCode": "1907",
    "districtNameTh": "ดอนพุด",
    "districtNameEn": "Don Phut",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "districtCode": "1908",
    "districtNameTh": "หนองโดน",
    "districtNameEn": "Nong Don",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "districtCode": "1909",
    "districtNameTh": "พระพุทธบาท",
    "districtNameEn": "Phra Phutthabat",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "districtCode": "1910",
    "districtNameTh": "เสาไห้",
    "districtNameEn": "Sao Hai",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "districtCode": "1911",
    "districtNameTh": "มวกเหล็ก",
    "districtNameEn": "Muak Lek",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "districtCode": "1912",
    "districtNameTh": "วังม่วง",
    "districtNameEn": "Wang Muang",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "districtCode": "1913",
    "districtNameTh": "เฉลิมพระเกียรติ",
    "districtNameEn": "Chaloem Phra Kiat",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "districtCode": "2001",
    "districtNameTh": "เมืองชลบุรี",
    "districtNameEn": "Mueang Chon Buri",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "districtCode": "2002",
    "districtNameTh": "บ้านบึง",
    "districtNameEn": "Ban Bueng",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "districtCode": "2003",
    "districtNameTh": "หนองใหญ่",
    "districtNameEn": "Nong Yai",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "districtCode": "2004",
    "districtNameTh": "บางละมุง",
    "districtNameEn": "Bang Lamung",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "districtCode": "2005",
    "districtNameTh": "พานทอง",
    "districtNameEn": "Phan Thong",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "districtCode": "2006",
    "districtNameTh": "พนัสนิคม",
    "districtNameEn": "Phanat Nikhom",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "districtCode": "2007",
    "districtNameTh": "ศรีราชา",
    "districtNameEn": "Si Racha",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "districtCode": "2008",
    "districtNameTh": "เกาะสีชัง",
    "districtNameEn": "Ko Sichang",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "districtCode": "2009",
    "districtNameTh": "สัตหีบ",
    "districtNameEn": "Sattahip",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "districtCode": "2010",
    "districtNameTh": "บ่อทอง",
    "districtNameEn": "Bo Thong",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "districtCode": "2011",
    "districtNameTh": "เกาะจันทร์",
    "districtNameEn": "Ko Chan",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "districtCode": "2101",
    "districtNameTh": "เมืองระยอง",
    "districtNameEn": "Mueang Rayong",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "districtCode": "2102",
    "districtNameTh": "บ้านฉาง",
    "districtNameEn": "Ban Chang",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "districtCode": "2103",
    "districtNameTh": "แกลง",
    "districtNameEn": "Klaeng",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "districtCode": "2104",
    "districtNameTh": "วังจันทร์",
    "districtNameEn": "Wang Chan",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "districtCode": "2105",
    "districtNameTh": "บ้านค่าย",
    "districtNameEn": "Ban Khai",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "districtCode": "2106",
    "districtNameTh": "ปลวกแดง",
    "districtNameEn": "Pluak Daeng",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "districtCode": "2107",
    "districtNameTh": "เขาชะเมา",
    "districtNameEn": "Khao Chamao",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "districtCode": "2108",
    "districtNameTh": "นิคมพัฒนา",
    "districtNameEn": "Nikhom Phatthana",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "districtCode": "2201",
    "districtNameTh": "เมืองจันทบุรี",
    "districtNameEn": "Mueang Chanthaburi",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "districtCode": "2202",
    "districtNameTh": "ขลุง",
    "districtNameEn": "Khlung",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "districtCode": "2203",
    "districtNameTh": "ท่าใหม่",
    "districtNameEn": "Tha Mai",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "districtCode": "2204",
    "districtNameTh": "โป่งน้ำร้อน",
    "districtNameEn": "Pong Nam Ron",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "districtCode": "2205",
    "districtNameTh": "มะขาม",
    "districtNameEn": "Makham",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "districtCode": "2206",
    "districtNameTh": "แหลมสิงห์",
    "districtNameEn": "Laem Sing",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "districtCode": "2207",
    "districtNameTh": "สอยดาว",
    "districtNameEn": "Soi Dao",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "districtCode": "2208",
    "districtNameTh": "แก่งหางแมว",
    "districtNameEn": "Kaeng Hang Maeo",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "districtCode": "2209",
    "districtNameTh": "นายายอาม",
    "districtNameEn": "Na Yai Am",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "districtCode": "2210",
    "districtNameTh": "เขาคิชฌกูฏ",
    "districtNameEn": "Khao Khitchakut",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "districtCode": "2301",
    "districtNameTh": "เมืองตราด",
    "districtNameEn": "Mueang Trat",
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "districtCode": "2302",
    "districtNameTh": "คลองใหญ่",
    "districtNameEn": "Khlong Yai",
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "districtCode": "2303",
    "districtNameTh": "เขาสมิง",
    "districtNameEn": "Khao Saming",
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "districtCode": "2304",
    "districtNameTh": "บ่อไร่",
    "districtNameEn": "Bo Rai",
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "districtCode": "2305",
    "districtNameTh": "แหลมงอบ",
    "districtNameEn": "Laem Ngop",
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "districtCode": "2306",
    "districtNameTh": "เกาะกูด",
    "districtNameEn": "Ko Kut",
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "districtCode": "2307",
    "districtNameTh": "เกาะช้าง",
    "districtNameEn": "Ko Chang",
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "districtCode": "2401",
    "districtNameTh": "เมืองฉะเชิงเทรา",
    "districtNameEn": "Mueang Chachoengsao",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "districtCode": "2402",
    "districtNameTh": "บางคล้า",
    "districtNameEn": "Bang Khla",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "districtCode": "2403",
    "districtNameTh": "บางน้ำเปรี้ยว",
    "districtNameEn": "Bang Nam Priao",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "districtCode": "2404",
    "districtNameTh": "บางปะกง",
    "districtNameEn": "Bang Pakong",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "districtCode": "2405",
    "districtNameTh": "บ้านโพธิ์",
    "districtNameEn": "Ban Pho",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "districtCode": "2406",
    "districtNameTh": "พนมสารคาม",
    "districtNameEn": "Phanom Sarakham",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "districtCode": "2407",
    "districtNameTh": "ราชสาส์น",
    "districtNameEn": "Ratchasan",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "districtCode": "2408",
    "districtNameTh": "สนามชัยเขต",
    "districtNameEn": "Sanam Chai Khet",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "districtCode": "2409",
    "districtNameTh": "แปลงยาว",
    "districtNameEn": "Plaeng Yao",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "districtCode": "2410",
    "districtNameTh": "ท่าตะเกียบ",
    "districtNameEn": "Tha Takiap",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "districtCode": "2411",
    "districtNameTh": "คลองเขื่อน",
    "districtNameEn": "Khlong Khuean",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "districtCode": "2501",
    "districtNameTh": "เมืองปราจีนบุรี",
    "districtNameEn": "Mueang Prachin Buri",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "districtCode": "2502",
    "districtNameTh": "กบินทร์บุรี",
    "districtNameEn": "Kabin Buri",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "districtCode": "2503",
    "districtNameTh": "นาดี",
    "districtNameEn": "Na Di",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "districtCode": "2506",
    "districtNameTh": "บ้านสร้าง",
    "districtNameEn": "Ban Sang",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "districtCode": "2507",
    "districtNameTh": "ประจันตคาม",
    "districtNameEn": "Prachantakham",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "districtCode": "2508",
    "districtNameTh": "ศรีมหาโพธิ",
    "districtNameEn": "Si Maha Phot",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "districtCode": "2509",
    "districtNameTh": "ศรีมโหสถ",
    "districtNameEn": "Si Mahosot",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "districtCode": "2601",
    "districtNameTh": "เมืองนครนายก",
    "districtNameEn": "Mueang Nakhon Nayok",
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "districtCode": "2602",
    "districtNameTh": "ปากพลี",
    "districtNameEn": "Pak Phli",
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "districtCode": "2603",
    "districtNameTh": "บ้านนา",
    "districtNameEn": "Ban Na",
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "districtCode": "2604",
    "districtNameTh": "องครักษ์",
    "districtNameEn": "Ongkharak",
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "districtCode": "2701",
    "districtNameTh": "เมืองสระแก้ว",
    "districtNameEn": "Mueang Sa Kaeo",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "districtCode": "2702",
    "districtNameTh": "คลองหาด",
    "districtNameEn": "Khlong Hat",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "districtCode": "2703",
    "districtNameTh": "ตาพระยา",
    "districtNameEn": "Ta Phraya",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "districtCode": "2704",
    "districtNameTh": "วังน้ำเย็น",
    "districtNameEn": "Wang Nam Yen",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "districtCode": "2705",
    "districtNameTh": "วัฒนานคร",
    "districtNameEn": "Watthana Nakhon",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "districtCode": "2706",
    "districtNameTh": "อรัญประเทศ",
    "districtNameEn": "Aranyaprathet",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "districtCode": "2707",
    "districtNameTh": "เขาฉกรรจ์",
    "districtNameEn": "Khao Chakan",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "districtCode": "2708",
    "districtNameTh": "โคกสูง",
    "districtNameEn": "Khok Sung",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "districtCode": "2709",
    "districtNameTh": "วังสมบูรณ์",
    "districtNameEn": "Wang Sombun",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "districtCode": "3001",
    "districtNameTh": "เมืองนครราชสีมา",
    "districtNameEn": "Mueang Nakhon Ratchasima",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "districtCode": "3002",
    "districtNameTh": "ครบุรี",
    "districtNameEn": "Khon Buri",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "districtCode": "3003",
    "districtNameTh": "เสิงสาง",
    "districtNameEn": "Soeng Sang",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "districtCode": "3004",
    "districtNameTh": "คง",
    "districtNameEn": "Khong",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "districtCode": "3005",
    "districtNameTh": "บ้านเหลื่อม",
    "districtNameEn": "Ban Lueam",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "districtCode": "3006",
    "districtNameTh": "จักราช",
    "districtNameEn": "Chakkarat",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "districtCode": "3007",
    "districtNameTh": "โชคชัย",
    "districtNameEn": "Chok Chai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "districtCode": "3008",
    "districtNameTh": "ด่านขุนทด",
    "districtNameEn": "Dan Khun Thot",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "districtCode": "3009",
    "districtNameTh": "โนนไทย",
    "districtNameEn": "Non Thai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "districtCode": "3010",
    "districtNameTh": "โนนสูง",
    "districtNameEn": "Non Sung",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "districtCode": "3011",
    "districtNameTh": "ขามสะแกแสง",
    "districtNameEn": "Kham Sakaesaeng",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "districtCode": "3012",
    "districtNameTh": "บัวใหญ่",
    "districtNameEn": "Bua Yai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "districtCode": "3013",
    "districtNameTh": "ประทาย",
    "districtNameEn": "Prathai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "districtCode": "3014",
    "districtNameTh": "ปักธงชัย",
    "districtNameEn": "Pak Thong Chai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "districtCode": "3015",
    "districtNameTh": "พิมาย",
    "districtNameEn": "Phimai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "districtCode": "3016",
    "districtNameTh": "ห้วยแถลง",
    "districtNameEn": "Huai Thalaeng",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "districtCode": "3017",
    "districtNameTh": "ชุมพวง",
    "districtNameEn": "Chum Phuang",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "districtCode": "3018",
    "districtNameTh": "สูงเนิน",
    "districtNameEn": "Sung Noen",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "districtCode": "3019",
    "districtNameTh": "ขามทะเลสอ",
    "districtNameEn": "Kham Thale So",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "districtCode": "3020",
    "districtNameTh": "สีคิ้ว",
    "districtNameEn": "Sikhio",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "districtCode": "3021",
    "districtNameTh": "ปากช่อง",
    "districtNameEn": "Pak Chong",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "districtCode": "3022",
    "districtNameTh": "หนองบุญมาก",
    "districtNameEn": "Nong Bunmak",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "districtCode": "3023",
    "districtNameTh": "แก้งสนามนาง",
    "districtNameEn": "Kaeng Sanam Nang",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "districtCode": "3024",
    "districtNameTh": "โนนแดง",
    "districtNameEn": "Non Daeng",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "districtCode": "3025",
    "districtNameTh": "วังน้ำเขียว",
    "districtNameEn": "Wang Nam Khiao",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "districtCode": "3026",
    "districtNameTh": "เทพารักษ์",
    "districtNameEn": "Thepharak",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "districtCode": "3027",
    "districtNameTh": "เมืองยาง",
    "districtNameEn": "Mueang Yang",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "districtCode": "3028",
    "districtNameTh": "พระทองคำ",
    "districtNameEn": "Phra Thong Kham",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "districtCode": "3029",
    "districtNameTh": "ลำทะเมนชัย",
    "districtNameEn": "Lam Thamenchai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "districtCode": "3030",
    "districtNameTh": "บัวลาย",
    "districtNameEn": "Bua Lai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "districtCode": "3031",
    "districtNameTh": "สีดา",
    "districtNameEn": "Sida",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "districtCode": "3032",
    "districtNameTh": "เฉลิมพระเกียรติ",
    "districtNameEn": "Chaloem Phra Kiat",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "districtCode": "3101",
    "districtNameTh": "เมืองบุรีรัมย์",
    "districtNameEn": "Mueang Buri Ram",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "districtCode": "3102",
    "districtNameTh": "คูเมือง",
    "districtNameEn": "Khu Mueang",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "districtCode": "3103",
    "districtNameTh": "กระสัง",
    "districtNameEn": "Krasang",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "districtCode": "3104",
    "districtNameTh": "นางรอง",
    "districtNameEn": "Nang Rong",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "districtCode": "3105",
    "districtNameTh": "หนองกี่",
    "districtNameEn": "Nong Ki",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "districtCode": "3106",
    "districtNameTh": "ละหานทราย",
    "districtNameEn": "Lahan Sai",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "districtCode": "3107",
    "districtNameTh": "ประโคนชัย",
    "districtNameEn": "Prakhon Chai",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "districtCode": "3108",
    "districtNameTh": "บ้านกรวด",
    "districtNameEn": "Ban Kruat",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "districtCode": "3109",
    "districtNameTh": "พุทไธสง",
    "districtNameEn": "Phutthaisong",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "districtCode": "3110",
    "districtNameTh": "ลำปลายมาศ",
    "districtNameEn": "Lam Plai Mat",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "districtCode": "3111",
    "districtNameTh": "สตึก",
    "districtNameEn": "Satuek",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "districtCode": "3112",
    "districtNameTh": "ปะคำ",
    "districtNameEn": "Pakham",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "districtCode": "3113",
    "districtNameTh": "นาโพธิ์",
    "districtNameEn": "Na Pho",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "districtCode": "3114",
    "districtNameTh": "หนองหงส์",
    "districtNameEn": "Nong Hong",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "districtCode": "3115",
    "districtNameTh": "พลับพลาชัย",
    "districtNameEn": "Phlapphla Chai",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "districtCode": "3116",
    "districtNameTh": "ห้วยราช",
    "districtNameEn": "Huai Rat",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "districtCode": "3117",
    "districtNameTh": "โนนสุวรรณ",
    "districtNameEn": "Non Suwan",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "districtCode": "3118",
    "districtNameTh": "ชำนิ",
    "districtNameEn": "Chamni",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "districtCode": "3119",
    "districtNameTh": "บ้านใหม่ไชยพจน์",
    "districtNameEn": "Ban Mai Chaiyaphot",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "districtCode": "3120",
    "districtNameTh": "โนนดินแดง",
    "districtNameEn": "Non Din Daeng",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "districtCode": "3121",
    "districtNameTh": "บ้านด่าน",
    "districtNameEn": "Ban Dan",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "districtCode": "3122",
    "districtNameTh": "แคนดง",
    "districtNameEn": "Khaen Dong",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "districtCode": "3123",
    "districtNameTh": "เฉลิมพระเกียรติ",
    "districtNameEn": "Chaloem Phra Kiat",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "districtCode": "3201",
    "districtNameTh": "เมืองสุรินทร์",
    "districtNameEn": "Mueang Surin",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "districtCode": "3202",
    "districtNameTh": "ชุมพลบุรี",
    "districtNameEn": "Chumphon Buri",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "districtCode": "3203",
    "districtNameTh": "ท่าตูม",
    "districtNameEn": "Tha Tum",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "districtCode": "3204",
    "districtNameTh": "จอมพระ",
    "districtNameEn": "Chom Phra",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "districtCode": "3205",
    "districtNameTh": "ปราสาท",
    "districtNameEn": "Prasat",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "districtCode": "3206",
    "districtNameTh": "กาบเชิง",
    "districtNameEn": "Kap Choeng",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "districtCode": "3207",
    "districtNameTh": "รัตนบุรี",
    "districtNameEn": "Rattanaburi",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "districtCode": "3208",
    "districtNameTh": "สนม",
    "districtNameEn": "Sanom",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "districtCode": "3209",
    "districtNameTh": "ศีขรภูมิ",
    "districtNameEn": "Sikhoraphum",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "districtCode": "3210",
    "districtNameTh": "สังขะ",
    "districtNameEn": "Sangkha",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "districtCode": "3211",
    "districtNameTh": "ลำดวน",
    "districtNameEn": "Lamduan",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "districtCode": "3212",
    "districtNameTh": "สำโรงทาบ",
    "districtNameEn": "Samrong Thap",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "districtCode": "3213",
    "districtNameTh": "บัวเชด",
    "districtNameEn": "Buachet",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "districtCode": "3214",
    "districtNameTh": "พนมดงรัก",
    "districtNameEn": "Phanom Dong Rak",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "districtCode": "3215",
    "districtNameTh": "ศรีณรงค์",
    "districtNameEn": "Si Narong",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "districtCode": "3216",
    "districtNameTh": "เขวาสินรินทร์",
    "districtNameEn": "Khwao Sinrin",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "districtCode": "3217",
    "districtNameTh": "โนนนารายณ์",
    "districtNameEn": "Non Narai",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "districtCode": "3301",
    "districtNameTh": "เมืองศรีสะเกษ",
    "districtNameEn": "Mueang Si Sa Ket",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "districtCode": "3302",
    "districtNameTh": "ยางชุมน้อย",
    "districtNameEn": "Yang Chum Noi",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "districtCode": "3303",
    "districtNameTh": "กันทรารมย์",
    "districtNameEn": "Kanthararom",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "districtCode": "3304",
    "districtNameTh": "กันทรลักษ์",
    "districtNameEn": "Kantharalak",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "districtCode": "3305",
    "districtNameTh": "ขุขันธ์",
    "districtNameEn": "Khukhan",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "districtCode": "3306",
    "districtNameTh": "ไพรบึง",
    "districtNameEn": "Phrai Bueng",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "districtCode": "3307",
    "districtNameTh": "ปรางค์กู่",
    "districtNameEn": "Prang Ku",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "districtCode": "3308",
    "districtNameTh": "ขุนหาญ",
    "districtNameEn": "Khun Han",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "districtCode": "3309",
    "districtNameTh": "ราษีไศล",
    "districtNameEn": "Rasi Salai",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "districtCode": "3310",
    "districtNameTh": "อุทุมพรพิสัย",
    "districtNameEn": "Uthumphon Phisai",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "districtCode": "3311",
    "districtNameTh": "บึงบูรพ์",
    "districtNameEn": "Bueng Bun",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "districtCode": "3312",
    "districtNameTh": "ห้วยทับทัน",
    "districtNameEn": "Huai Thap Than",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "districtCode": "3313",
    "districtNameTh": "โนนคูณ",
    "districtNameEn": "Non Khun",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "districtCode": "3314",
    "districtNameTh": "ศรีรัตนะ",
    "districtNameEn": "Si Rattana",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "districtCode": "3315",
    "districtNameTh": "น้ำเกลี้ยง",
    "districtNameEn": "Nam Kliang",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "districtCode": "3316",
    "districtNameTh": "วังหิน",
    "districtNameEn": "Wang Hin",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "districtCode": "3317",
    "districtNameTh": "ภูสิงห์",
    "districtNameEn": "Phu Sing",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "districtCode": "3318",
    "districtNameTh": "เมืองจันทร์",
    "districtNameEn": "Mueang Chan",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "districtCode": "3319",
    "districtNameTh": "เบญจลักษ์",
    "districtNameEn": "Benchalak",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "districtCode": "3320",
    "districtNameTh": "พยุห์",
    "districtNameEn": "Phayu",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "districtCode": "3321",
    "districtNameTh": "โพธิ์ศรีสุวรรณ",
    "districtNameEn": "Pho Si Suwan",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "districtCode": "3322",
    "districtNameTh": "ศิลาลาด",
    "districtNameEn": "Sila Lat",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "districtCode": "3401",
    "districtNameTh": "เมืองอุบลราชธานี",
    "districtNameEn": "Mueang Ubon Ratchathani",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "districtCode": "3402",
    "districtNameTh": "ศรีเมืองใหม่",
    "districtNameEn": "Si Mueang Mai",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "districtCode": "3403",
    "districtNameTh": "โขงเจียม",
    "districtNameEn": "Khong Chiam",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "districtCode": "3404",
    "districtNameTh": "เขื่องใน",
    "districtNameEn": "Khueang Nai",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "districtCode": "3405",
    "districtNameTh": "เขมราฐ",
    "districtNameEn": "Khemarat",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "districtCode": "3407",
    "districtNameTh": "เดชอุดม",
    "districtNameEn": "Det Udom",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "districtCode": "3408",
    "districtNameTh": "นาจะหลวย",
    "districtNameEn": "Na Chaluai",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "districtCode": "3409",
    "districtNameTh": "น้ำยืน",
    "districtNameEn": "Nam Yuen",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "districtCode": "3410",
    "districtNameTh": "บุณฑริก",
    "districtNameEn": "Buntharik",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "districtCode": "3411",
    "districtNameTh": "ตระการพืชผล",
    "districtNameEn": "Trakan Phuet Phon",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "districtCode": "3412",
    "districtNameTh": "กุดข้าวปุ้น",
    "districtNameEn": "Kut Khaopun",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "districtCode": "3414",
    "districtNameTh": "ม่วงสามสิบ",
    "districtNameEn": "Muang Sam Sip",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "districtCode": "3415",
    "districtNameTh": "วารินชำราบ",
    "districtNameEn": "Warin Chamrap",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "districtCode": "3419",
    "districtNameTh": "พิบูลมังสาหาร",
    "districtNameEn": "Phibun Mangsahan",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "districtCode": "3420",
    "districtNameTh": "ตาลสุม",
    "districtNameEn": "Tan Sum",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "districtCode": "3421",
    "districtNameTh": "โพธิ์ไทร",
    "districtNameEn": "Pho Sai",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "districtCode": "3422",
    "districtNameTh": "สำโรง",
    "districtNameEn": "Samrong",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "districtCode": "3424",
    "districtNameTh": "ดอนมดแดง",
    "districtNameEn": "Don Mot Daeng",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "districtCode": "3425",
    "districtNameTh": "สิรินธร",
    "districtNameEn": "Sirindhorn",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "districtCode": "3426",
    "districtNameTh": "ทุ่งศรีอุดม",
    "districtNameEn": "Thung Si Udom",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "districtCode": "3429",
    "districtNameTh": "นาเยีย",
    "districtNameEn": "Na Yia",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "districtCode": "3430",
    "districtNameTh": "นาตาล",
    "districtNameEn": "Na Tan",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "districtCode": "3431",
    "districtNameTh": "เหล่าเสือโก้ก",
    "districtNameEn": "Fang Kham",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "districtCode": "3432",
    "districtNameTh": "สว่างวีระวงศ์",
    "districtNameEn": "Sawang Wirawong",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "districtCode": "3433",
    "districtNameTh": "น้ำขุ่น",
    "districtNameEn": "Nam Khun",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "districtCode": "3501",
    "districtNameTh": "เมืองยโสธร",
    "districtNameEn": "Mueang Yasothon",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "districtCode": "3502",
    "districtNameTh": "ทรายมูล",
    "districtNameEn": "Sai Mun",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "districtCode": "3503",
    "districtNameTh": "กุดชุม",
    "districtNameEn": "Kut Chum",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "districtCode": "3504",
    "districtNameTh": "คำเขื่อนแก้ว",
    "districtNameEn": "Kham Khuean Kaeo",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "districtCode": "3505",
    "districtNameTh": "ป่าติ้ว",
    "districtNameEn": "Pa Tio",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "districtCode": "3506",
    "districtNameTh": "มหาชนะชัย",
    "districtNameEn": "Maha Chana Chai",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "districtCode": "3507",
    "districtNameTh": "ค้อวัง",
    "districtNameEn": "Kho Wang",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "districtCode": "3508",
    "districtNameTh": "เลิงนกทา",
    "districtNameEn": "Loeng Nok Tha",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "districtCode": "3509",
    "districtNameTh": "ไทยเจริญ",
    "districtNameEn": "Thai Charoen",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "districtCode": "3601",
    "districtNameTh": "เมืองชัยภูมิ",
    "districtNameEn": "Mueang Chaiyaphum",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "districtCode": "3602",
    "districtNameTh": "บ้านเขว้า",
    "districtNameEn": "Ban Khwao",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "districtCode": "3603",
    "districtNameTh": "คอนสวรรค์",
    "districtNameEn": "Khon Sawan",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "districtCode": "3604",
    "districtNameTh": "เกษตรสมบูรณ์",
    "districtNameEn": "Kaset Sombun",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "districtCode": "3605",
    "districtNameTh": "หนองบัวแดง",
    "districtNameEn": "Nong Bua Daeng",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "districtCode": "3606",
    "districtNameTh": "จัตุรัส",
    "districtNameEn": "Chatturat",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "districtCode": "3607",
    "districtNameTh": "บำเหน็จณรงค์",
    "districtNameEn": "Bamnet Narong",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "districtCode": "3608",
    "districtNameTh": "หนองบัวระเหว",
    "districtNameEn": "Nong Bua Rawe",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "districtCode": "3609",
    "districtNameTh": "เทพสถิต",
    "districtNameEn": "Thep Sathit",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "districtCode": "3610",
    "districtNameTh": "ภูเขียว",
    "districtNameEn": "Phu Khiao",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "districtCode": "3611",
    "districtNameTh": "บ้านแท่น",
    "districtNameEn": "Ban Thaen",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "districtCode": "3612",
    "districtNameTh": "แก้งคร้อ",
    "districtNameEn": "Kaeng Khro",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "districtCode": "3613",
    "districtNameTh": "คอนสาร",
    "districtNameEn": "Khon San",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "districtCode": "3614",
    "districtNameTh": "ภักดีชุมพล",
    "districtNameEn": "Phakdi Chumphon",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "districtCode": "3615",
    "districtNameTh": "เนินสง่า",
    "districtNameEn": "Noen Sa-nga",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "districtCode": "3616",
    "districtNameTh": "ซับใหญ่",
    "districtNameEn": "Sap Yai",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "districtCode": "3701",
    "districtNameTh": "เมืองอำนาจเจริญ",
    "districtNameEn": "Mueang Amnat Charoen",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "districtCode": "3702",
    "districtNameTh": "ชานุมาน",
    "districtNameEn": "Chanuman",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "districtCode": "3703",
    "districtNameTh": "ปทุมราชวงศา",
    "districtNameEn": "Pathum Ratchawongsa",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "districtCode": "3704",
    "districtNameTh": "พนา",
    "districtNameEn": "Phana",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "districtCode": "3705",
    "districtNameTh": "เสนางคนิคม",
    "districtNameEn": "Senangkhanikhom",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "districtCode": "3706",
    "districtNameTh": "หัวตะพาน",
    "districtNameEn": "Hua Taphan",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "districtCode": "3707",
    "districtNameTh": "ลืออำนาจ",
    "districtNameEn": "Lue Amnat",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "districtCode": "3801",
    "districtNameTh": "เมืองบึงกาฬ",
    "districtNameEn": "Mueang Bueng Kan",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "districtCode": "3802",
    "districtNameTh": "พรเจริญ",
    "districtNameEn": "Phon Charoen",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "districtCode": "3803",
    "districtNameTh": "โซ่พิสัย",
    "districtNameEn": "So Phisai",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "districtCode": "3804",
    "districtNameTh": "เซกา",
    "districtNameEn": "Seka",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "districtCode": "3805",
    "districtNameTh": "ปากคาด",
    "districtNameEn": "Pak Khat",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "districtCode": "3806",
    "districtNameTh": "บึงโขงหลง",
    "districtNameEn": "Bueng Khong Long",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "districtCode": "3807",
    "districtNameTh": "ศรีวิไล",
    "districtNameEn": "Si Wilai",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "districtCode": "3808",
    "districtNameTh": "บุ่งคล้า",
    "districtNameEn": "Bung Khla",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "districtCode": "3901",
    "districtNameTh": "เมืองหนองบัวลำภู",
    "districtNameEn": "Mueang Nong Bua Lam Phu",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "districtCode": "3902",
    "districtNameTh": "นากลาง",
    "districtNameEn": "Na Klang",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "districtCode": "3903",
    "districtNameTh": "โนนสัง",
    "districtNameEn": "Non Sang",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "districtCode": "3904",
    "districtNameTh": "ศรีบุญเรือง",
    "districtNameEn": "Si Bun Rueang",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "districtCode": "3905",
    "districtNameTh": "สุวรรณคูหา",
    "districtNameEn": "Suwannakhuha",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "districtCode": "3906",
    "districtNameTh": "นาวัง",
    "districtNameEn": "Na Wang",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "districtCode": "4001",
    "districtNameTh": "เมืองขอนแก่น",
    "districtNameEn": "Mueang Khon Kaen",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "districtCode": "4002",
    "districtNameTh": "บ้านฝาง",
    "districtNameEn": "Ban Fang",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "districtCode": "4003",
    "districtNameTh": "พระยืน",
    "districtNameEn": "Phra Yuen",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "districtCode": "4004",
    "districtNameTh": "หนองเรือ",
    "districtNameEn": "Nong Ruea",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "districtCode": "4005",
    "districtNameTh": "ชุมแพ",
    "districtNameEn": "Chum Phae",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "districtCode": "4006",
    "districtNameTh": "สีชมพู",
    "districtNameEn": "Si Chomphu",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "districtCode": "4007",
    "districtNameTh": "น้ำพอง",
    "districtNameEn": "Nam Phong",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "districtCode": "4008",
    "districtNameTh": "อุบลรัตน์",
    "districtNameEn": "Ubolratana",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "districtCode": "4009",
    "districtNameTh": "กระนวน",
    "districtNameEn": "Kranuan",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "districtCode": "4010",
    "districtNameTh": "บ้านไผ่",
    "districtNameEn": "Ban Phai",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "districtCode": "4011",
    "districtNameTh": "เปือยน้อย",
    "districtNameEn": "Pueai Noi",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "districtCode": "4012",
    "districtNameTh": "พล",
    "districtNameEn": "Phon",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "districtCode": "4013",
    "districtNameTh": "แวงใหญ่",
    "districtNameEn": "Waeng Yai",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "districtCode": "4014",
    "districtNameTh": "แวงน้อย",
    "districtNameEn": "Waeng Noi",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "districtCode": "4015",
    "districtNameTh": "หนองสองห้อง",
    "districtNameEn": "Nong Song Hong",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "districtCode": "4016",
    "districtNameTh": "ภูเวียง",
    "districtNameEn": "Phu Wiang",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "districtCode": "4017",
    "districtNameTh": "มัญจาคีรี",
    "districtNameEn": "Mancha Khiri",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "districtCode": "4018",
    "districtNameTh": "ชนบท",
    "districtNameEn": "Chonnabot",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "districtCode": "4019",
    "districtNameTh": "เขาสวนกวาง",
    "districtNameEn": "Khao Suan Kwang",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "districtCode": "4020",
    "districtNameTh": "ภูผาม่าน",
    "districtNameEn": "Phu Pha Man",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "districtCode": "4021",
    "districtNameTh": "ซำสูง",
    "districtNameEn": "Sam Sung",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "districtCode": "4022",
    "districtNameTh": "โคกโพธิ์ไชย",
    "districtNameEn": "Khok Pho Chai",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "districtCode": "4023",
    "districtNameTh": "หนองนาคำ",
    "districtNameEn": "Nong Na Kham",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "districtCode": "4024",
    "districtNameTh": "บ้านแฮด",
    "districtNameEn": "Ban Haet",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "districtCode": "4025",
    "districtNameTh": "โนนศิลา",
    "districtNameEn": "Non Sila",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "districtCode": "4026",
    "districtNameTh": "เวียงเก่า",
    "districtNameEn": "Wiang Kao",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "districtCode": "4101",
    "districtNameTh": "เมืองอุดรธานี",
    "districtNameEn": "Mueang Udon Thani",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "districtCode": "4102",
    "districtNameTh": "กุดจับ",
    "districtNameEn": "Kut Chap",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "districtCode": "4103",
    "districtNameTh": "หนองวัวซอ",
    "districtNameEn": "Nong Wua So",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "districtCode": "4104",
    "districtNameTh": "กุมภวาปี",
    "districtNameEn": "Kumphawapi",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "districtCode": "4105",
    "districtNameTh": "โนนสะอาด",
    "districtNameEn": "Non Sa-at",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "districtCode": "4106",
    "districtNameTh": "หนองหาน",
    "districtNameEn": "Nong Han",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "districtCode": "4107",
    "districtNameTh": "ทุ่งฝน",
    "districtNameEn": "Thung Fon",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "districtCode": "4108",
    "districtNameTh": "ไชยวาน",
    "districtNameEn": "Chai Wan",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "districtCode": "4109",
    "districtNameTh": "ศรีธาตุ",
    "districtNameEn": "Si That",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "districtCode": "4110",
    "districtNameTh": "วังสามหมอ",
    "districtNameEn": "Wang Sam Mo",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "districtCode": "4111",
    "districtNameTh": "บ้านดุง",
    "districtNameEn": "Ban Dung",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "districtCode": "4117",
    "districtNameTh": "บ้านผือ",
    "districtNameEn": "Ban Phue",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "districtCode": "4118",
    "districtNameTh": "น้ำโสม",
    "districtNameEn": "Nam Som",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "districtCode": "4119",
    "districtNameTh": "เพ็ญ",
    "districtNameEn": "Phen",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "districtCode": "4120",
    "districtNameTh": "สร้างคอม",
    "districtNameEn": "Sang Khom",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "districtCode": "4121",
    "districtNameTh": "หนองแสง",
    "districtNameEn": "Nong Saeng",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "districtCode": "4122",
    "districtNameTh": "นายูง",
    "districtNameEn": "Na Yung",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "districtCode": "4123",
    "districtNameTh": "พิบูลย์รักษ์",
    "districtNameEn": "Phibun Rak",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "districtCode": "4124",
    "districtNameTh": "กู่แก้ว",
    "districtNameEn": "Ku Kaeo",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "districtCode": "4125",
    "districtNameTh": "ประจักษ์ศิลปาคม",
    "districtNameEn": "Prachak Sinlapakhom",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "districtCode": "4201",
    "districtNameTh": "เมืองเลย",
    "districtNameEn": "Mueang Loei",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "districtCode": "4202",
    "districtNameTh": "นาด้วง",
    "districtNameEn": "Na Duang",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "districtCode": "4203",
    "districtNameTh": "เชียงคาน",
    "districtNameEn": "Chiang Khan",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "districtCode": "4204",
    "districtNameTh": "ปากชม",
    "districtNameEn": "Pak Chom",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "districtCode": "4205",
    "districtNameTh": "ด่านซ้าย",
    "districtNameEn": "Dan Sai",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "districtCode": "4206",
    "districtNameTh": "นาแห้ว",
    "districtNameEn": "Na Haeo",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "districtCode": "4207",
    "districtNameTh": "ภูเรือ",
    "districtNameEn": "Phu Ruea",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "districtCode": "4208",
    "districtNameTh": "ท่าลี่",
    "districtNameEn": "Tha Li",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "districtCode": "4209",
    "districtNameTh": "วังสะพุง",
    "districtNameEn": "Wang Saphung",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "districtCode": "4210",
    "districtNameTh": "ภูกระดึง",
    "districtNameEn": "Phu Kradueng",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "districtCode": "4211",
    "districtNameTh": "ภูหลวง",
    "districtNameEn": "Phu Luang",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "districtCode": "4212",
    "districtNameTh": "ผาขาว",
    "districtNameEn": "Pha Khao",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "districtCode": "4213",
    "districtNameTh": "เอราวัณ",
    "districtNameEn": "Erawan",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "districtCode": "4214",
    "districtNameTh": "หนองหิน",
    "districtNameEn": "Nong Hin",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "districtCode": "4301",
    "districtNameTh": "เมืองหนองคาย",
    "districtNameEn": "Mueang Nong Khai",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "districtCode": "4302",
    "districtNameTh": "ท่าบ่อ",
    "districtNameEn": "Tha Bo",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "districtCode": "4305",
    "districtNameTh": "โพนพิสัย",
    "districtNameEn": "Phon Phisai",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "districtCode": "4307",
    "districtNameTh": "ศรีเชียงใหม่",
    "districtNameEn": "Si Chiang Mai",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "districtCode": "4308",
    "districtNameTh": "สังคม",
    "districtNameEn": "Sangkhom",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "districtCode": "4314",
    "districtNameTh": "สระใคร",
    "districtNameEn": "Sakhrai",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "districtCode": "4315",
    "districtNameTh": "เฝ้าไร่",
    "districtNameEn": "Fao Rai",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "districtCode": "4316",
    "districtNameTh": "รัตนวาปี",
    "districtNameEn": "Rattanawapi",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "districtCode": "4317",
    "districtNameTh": "โพธิ์ตาก",
    "districtNameEn": "Pho Tak",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "districtCode": "4401",
    "districtNameTh": "เมืองมหาสารคาม",
    "districtNameEn": "Mueang Mahasarakham",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "districtCode": "4402",
    "districtNameTh": "แกดำ",
    "districtNameEn": "Kae Dam",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "districtCode": "4403",
    "districtNameTh": "โกสุมพิสัย",
    "districtNameEn": "Kosum Phisai",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "districtCode": "4404",
    "districtNameTh": "กันทรวิชัย",
    "districtNameEn": "Kantharawichai",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "districtCode": "4405",
    "districtNameTh": "เชียงยืน",
    "districtNameEn": "Chiang Yuen",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "districtCode": "4406",
    "districtNameTh": "บรบือ",
    "districtNameEn": "Borabue",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "districtCode": "4407",
    "districtNameTh": "นาเชือก",
    "districtNameEn": "Na Chueak",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "districtCode": "4408",
    "districtNameTh": "พยัคฆภูมิพิสัย",
    "districtNameEn": "Phayakkhaphum Phisai",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "districtCode": "4409",
    "districtNameTh": "วาปีปทุม",
    "districtNameEn": "Wapi Pathum",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "districtCode": "4410",
    "districtNameTh": "นาดูน",
    "districtNameEn": "Na Dun",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "districtCode": "4411",
    "districtNameTh": "ยางสีสุราช",
    "districtNameEn": "Yang Si Surat",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "districtCode": "4412",
    "districtNameTh": "กุดรัง",
    "districtNameEn": "Kut Rang",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "districtCode": "4413",
    "districtNameTh": "ชื่นชม",
    "districtNameEn": "Chuen Chom",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "districtCode": "4501",
    "districtNameTh": "เมืองร้อยเอ็ด",
    "districtNameEn": "Mueang Roi Et",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "districtCode": "4502",
    "districtNameTh": "เกษตรวิสัย",
    "districtNameEn": "Kaset Wisai",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "districtCode": "4503",
    "districtNameTh": "ปทุมรัตต์",
    "districtNameEn": "Pathum Rat",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "districtCode": "4504",
    "districtNameTh": "จตุรพักตรพิมาน",
    "districtNameEn": "Chaturaphak Phiman",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "districtCode": "4505",
    "districtNameTh": "ธวัชบุรี",
    "districtNameEn": "Thawat Buri",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "districtCode": "4506",
    "districtNameTh": "พนมไพร",
    "districtNameEn": "Phanom Phrai",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "districtCode": "4507",
    "districtNameTh": "โพนทอง",
    "districtNameEn": "Phon Thong",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "districtCode": "4508",
    "districtNameTh": "โพธิ์ชัย",
    "districtNameEn": "Pho Chai",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "districtCode": "4509",
    "districtNameTh": "หนองพอก",
    "districtNameEn": "Nong Phok",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "districtCode": "4510",
    "districtNameTh": "เสลภูมิ",
    "districtNameEn": "Selaphum",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "districtCode": "4511",
    "districtNameTh": "สุวรรณภูมิ",
    "districtNameEn": "Suwannaphum",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "districtCode": "4512",
    "districtNameTh": "เมืองสรวง",
    "districtNameEn": "Mueang Suang",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "districtCode": "4513",
    "districtNameTh": "โพนทราย",
    "districtNameEn": "Phon Sai",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "districtCode": "4514",
    "districtNameTh": "อาจสามารถ",
    "districtNameEn": "At Samat",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "districtCode": "4515",
    "districtNameTh": "เมยวดี",
    "districtNameEn": "Moei Wadi",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "districtCode": "4516",
    "districtNameTh": "ศรีสมเด็จ",
    "districtNameEn": "Si Somdet",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "districtCode": "4517",
    "districtNameTh": "จังหาร",
    "districtNameEn": "Changhan",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "districtCode": "4518",
    "districtNameTh": "เชียงขวัญ",
    "districtNameEn": "Chiang Khwan",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "districtCode": "4519",
    "districtNameTh": "หนองฮี",
    "districtNameEn": "Nong Hi",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "districtCode": "4520",
    "districtNameTh": "ทุ่งเขาหลวง",
    "districtNameEn": "Thung Khao Luang",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "districtCode": "4601",
    "districtNameTh": "เมืองกาฬสินธุ์",
    "districtNameEn": "Mueang Kalasin",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "districtCode": "4602",
    "districtNameTh": "นามน",
    "districtNameEn": "Na Mon",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "districtCode": "4603",
    "districtNameTh": "กมลาไสย",
    "districtNameEn": "Kamalasai",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "districtCode": "4604",
    "districtNameTh": "ร่องคำ",
    "districtNameEn": "Rong Kham",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "districtCode": "4605",
    "districtNameTh": "กุฉินารายณ์",
    "districtNameEn": "Kuchinarai",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "districtCode": "4606",
    "districtNameTh": "เขาวง",
    "districtNameEn": "Khao Wong",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "districtCode": "4607",
    "districtNameTh": "ยางตลาด",
    "districtNameEn": "Yang Talat",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "districtCode": "4608",
    "districtNameTh": "ห้วยเม็ก",
    "districtNameEn": "Huai Mek",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "districtCode": "4609",
    "districtNameTh": "สหัสขันธ์",
    "districtNameEn": "Sahatsakhan",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "districtCode": "4610",
    "districtNameTh": "คำม่วง",
    "districtNameEn": "Kham Muang",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "districtCode": "4611",
    "districtNameTh": "ท่าคันโท",
    "districtNameEn": "Tha Khantho",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "districtCode": "4612",
    "districtNameTh": "หนองกุงศรี",
    "districtNameEn": "Nong Kung Si",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "districtCode": "4613",
    "districtNameTh": "สมเด็จ",
    "districtNameEn": "Somdet",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "districtCode": "4614",
    "districtNameTh": "ห้วยผึ้ง",
    "districtNameEn": "Huai Phueng",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "districtCode": "4615",
    "districtNameTh": "สามชัย",
    "districtNameEn": "Sam Chai",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "districtCode": "4616",
    "districtNameTh": "นาคู",
    "districtNameEn": "Na Khu",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "districtCode": "4617",
    "districtNameTh": "ดอนจาน",
    "districtNameEn": "Don Chan",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "districtCode": "4618",
    "districtNameTh": "ฆ้องชัย",
    "districtNameEn": "Khong Chai",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "districtCode": "4701",
    "districtNameTh": "เมืองสกลนคร",
    "districtNameEn": "Mueang Sakon Nakhon",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "districtCode": "4702",
    "districtNameTh": "กุสุมาลย์",
    "districtNameEn": "Kusuman",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "districtCode": "4703",
    "districtNameTh": "กุดบาก",
    "districtNameEn": "Kut Bak",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "districtCode": "4704",
    "districtNameTh": "พรรณานิคม",
    "districtNameEn": "Phanna Nikhom",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "districtCode": "4705",
    "districtNameTh": "พังโคน",
    "districtNameEn": "Phang Khon",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "districtCode": "4706",
    "districtNameTh": "วาริชภูมิ",
    "districtNameEn": "Waritchaphum",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "districtCode": "4707",
    "districtNameTh": "นิคมน้ำอูน",
    "districtNameEn": "Nikhom Nam Un",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "districtCode": "4708",
    "districtNameTh": "วานรนิวาส",
    "districtNameEn": "Wanon Niwat",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "districtCode": "4709",
    "districtNameTh": "คำตากล้า",
    "districtNameEn": "Kham Ta Kla",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "districtCode": "4710",
    "districtNameTh": "บ้านม่วง",
    "districtNameEn": "Ban Muang",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "districtCode": "4711",
    "districtNameTh": "อากาศอำนวย",
    "districtNameEn": "Akat Amnuai",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "districtCode": "4712",
    "districtNameTh": "สว่างแดนดิน",
    "districtNameEn": "Sawang Daen Din",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "districtCode": "4713",
    "districtNameTh": "ส่องดาว",
    "districtNameEn": "Song Dao",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "districtCode": "4714",
    "districtNameTh": "เต่างอย",
    "districtNameEn": "Tao Ngoi",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "districtCode": "4715",
    "districtNameTh": "โคกศรีสุพรรณ",
    "districtNameEn": "Khok Si Suphan",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "districtCode": "4716",
    "districtNameTh": "เจริญศิลป์",
    "districtNameEn": "Charoen Sin",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "districtCode": "4717",
    "districtNameTh": "โพนนาแก้ว",
    "districtNameEn": "Phon Na Kaeo",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "districtCode": "4718",
    "districtNameTh": "ภูพาน",
    "districtNameEn": "Phu Phan",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "districtCode": "4801",
    "districtNameTh": "เมืองนครพนม",
    "districtNameEn": "Mueang Nakhon Phanom",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "districtCode": "4802",
    "districtNameTh": "ปลาปาก",
    "districtNameEn": "Pla Pak",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "districtCode": "4803",
    "districtNameTh": "ท่าอุเทน",
    "districtNameEn": "Tha Uthen",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "districtCode": "4804",
    "districtNameTh": "บ้านแพง",
    "districtNameEn": "Ban Phaeng",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "districtCode": "4805",
    "districtNameTh": "ธาตุพนม",
    "districtNameEn": "That Phanom",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "districtCode": "4806",
    "districtNameTh": "เรณูนคร",
    "districtNameEn": "Renu Nakhon",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "districtCode": "4807",
    "districtNameTh": "นาแก",
    "districtNameEn": "Na Kae",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "districtCode": "4808",
    "districtNameTh": "ศรีสงคราม",
    "districtNameEn": "Si Songkhram",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "districtCode": "4809",
    "districtNameTh": "นาหว้า",
    "districtNameEn": "Na Wa",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "districtCode": "4810",
    "districtNameTh": "โพนสวรรค์",
    "districtNameEn": "Phon Sawan",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "districtCode": "4811",
    "districtNameTh": "นาทม",
    "districtNameEn": "Na Thom",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "districtCode": "4812",
    "districtNameTh": "วังยาง",
    "districtNameEn": "Wang Yang",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "districtCode": "4901",
    "districtNameTh": "เมืองมุกดาหาร",
    "districtNameEn": "Mueang Mukdahan",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "districtCode": "4902",
    "districtNameTh": "นิคมคำสร้อย",
    "districtNameEn": "Nikhom Kham Soi",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "districtCode": "4903",
    "districtNameTh": "ดอนตาล",
    "districtNameEn": "Don Tan",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "districtCode": "4904",
    "districtNameTh": "ดงหลวง",
    "districtNameEn": "Dong Luang",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "districtCode": "4905",
    "districtNameTh": "คำชะอี",
    "districtNameEn": "Khamcha-i",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "districtCode": "4906",
    "districtNameTh": "หว้านใหญ่",
    "districtNameEn": "Wan Yai",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "districtCode": "4907",
    "districtNameTh": "หนองสูง",
    "districtNameEn": "Nong Sung",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "districtCode": "5001",
    "districtNameTh": "เมืองเชียงใหม่",
    "districtNameEn": "Mueang Chiang Mai",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "districtCode": "5002",
    "districtNameTh": "จอมทอง",
    "districtNameEn": "Chom Thong",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "districtCode": "5003",
    "districtNameTh": "แม่แจ่ม",
    "districtNameEn": "Mae Chaem",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "districtCode": "5004",
    "districtNameTh": "เชียงดาว",
    "districtNameEn": "Chiang Dao",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "districtCode": "5005",
    "districtNameTh": "ดอยสะเก็ด",
    "districtNameEn": "Doi Saket",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "districtCode": "5006",
    "districtNameTh": "แม่แตง",
    "districtNameEn": "Mae Taeng",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "districtCode": "5007",
    "districtNameTh": "แม่ริม",
    "districtNameEn": "Mae Rim",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "districtCode": "5008",
    "districtNameTh": "สะเมิง",
    "districtNameEn": "Samoeng",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "districtCode": "5009",
    "districtNameTh": "ฝาง",
    "districtNameEn": "Fang",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "districtCode": "5010",
    "districtNameTh": "แม่อาย",
    "districtNameEn": "Mae Ai",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "districtCode": "5011",
    "districtNameTh": "พร้าว",
    "districtNameEn": "Phrao",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "districtCode": "5012",
    "districtNameTh": "สันป่าตอง",
    "districtNameEn": "San Pa Tong",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "districtCode": "5013",
    "districtNameTh": "สันกำแพง",
    "districtNameEn": "San Kamphaeng",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "districtCode": "5014",
    "districtNameTh": "สันทราย",
    "districtNameEn": "San Sai",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "districtCode": "5015",
    "districtNameTh": "หางดง",
    "districtNameEn": "Hang Dong",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "districtCode": "5016",
    "districtNameTh": "ฮอด",
    "districtNameEn": "Hot",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "districtCode": "5017",
    "districtNameTh": "ดอยเต่า",
    "districtNameEn": "Doi Tao",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "districtCode": "5018",
    "districtNameTh": "อมก๋อย",
    "districtNameEn": "Omkoi",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "districtCode": "5019",
    "districtNameTh": "สารภี",
    "districtNameEn": "Saraphi",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "districtCode": "5020",
    "districtNameTh": "เวียงแหง",
    "districtNameEn": "Wiang Haeng",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "districtCode": "5021",
    "districtNameTh": "ไชยปราการ",
    "districtNameEn": "Chai Prakan",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "districtCode": "5022",
    "districtNameTh": "แม่วาง",
    "districtNameEn": "Mae Wang",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "districtCode": "5023",
    "districtNameTh": "แม่ออน",
    "districtNameEn": "Mae On",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "districtCode": "5024",
    "districtNameTh": "ดอยหล่อ",
    "districtNameEn": "Doi Lo",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "districtCode": "5025",
    "districtNameTh": "กัลยาณิวัฒนา",
    "districtNameEn": "Galayani Vadhana",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "districtCode": "5101",
    "districtNameTh": "เมืองลำพูน",
    "districtNameEn": "Mueang Lamphun",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "districtCode": "5102",
    "districtNameTh": "แม่ทา",
    "districtNameEn": "Mae Tha",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "districtCode": "5103",
    "districtNameTh": "บ้านโฮ่ง",
    "districtNameEn": "Ban Hong",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "districtCode": "5104",
    "districtNameTh": "ลี้",
    "districtNameEn": "Li",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "districtCode": "5105",
    "districtNameTh": "ทุ่งหัวช้าง",
    "districtNameEn": "Thung Hua Chang",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "districtCode": "5106",
    "districtNameTh": "ป่าซาง",
    "districtNameEn": "Pa Sang",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "districtCode": "5107",
    "districtNameTh": "บ้านธิ",
    "districtNameEn": "Ban Thi",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "districtCode": "5108",
    "districtNameTh": "เวียงหนองล่อง",
    "districtNameEn": "Wiang Nong Long",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "districtCode": "5201",
    "districtNameTh": "เมืองลำปาง",
    "districtNameEn": "Mueang Lampang",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "districtCode": "5202",
    "districtNameTh": "แม่เมาะ",
    "districtNameEn": "Mae Mo",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "districtCode": "5203",
    "districtNameTh": "เกาะคา",
    "districtNameEn": "Ko Kha",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "districtCode": "5204",
    "districtNameTh": "เสริมงาม",
    "districtNameEn": "Soem Ngam",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "districtCode": "5205",
    "districtNameTh": "งาว",
    "districtNameEn": "Ngao",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "districtCode": "5206",
    "districtNameTh": "แจ้ห่ม",
    "districtNameEn": "Chae Hom",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "districtCode": "5207",
    "districtNameTh": "วังเหนือ",
    "districtNameEn": "Wang Nuea",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "districtCode": "5208",
    "districtNameTh": "เถิน",
    "districtNameEn": "Thoen",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "districtCode": "5209",
    "districtNameTh": "แม่พริก",
    "districtNameEn": "Mae Phrik",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "districtCode": "5210",
    "districtNameTh": "แม่ทะ",
    "districtNameEn": "Mae Tha",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "districtCode": "5211",
    "districtNameTh": "สบปราบ",
    "districtNameEn": "Sop Prap",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "districtCode": "5212",
    "districtNameTh": "ห้างฉัตร",
    "districtNameEn": "Hang Chat",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "districtCode": "5213",
    "districtNameTh": "เมืองปาน",
    "districtNameEn": "Mueang Pan",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "districtCode": "5301",
    "districtNameTh": "เมืองอุตรดิตถ์",
    "districtNameEn": "Mueang Uttaradit",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "districtCode": "5302",
    "districtNameTh": "ตรอน",
    "districtNameEn": "Tron",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "districtCode": "5303",
    "districtNameTh": "ท่าปลา",
    "districtNameEn": "Tha Pla",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "districtCode": "5304",
    "districtNameTh": "น้ำปาด",
    "districtNameEn": "Nam Pat",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "districtCode": "5305",
    "districtNameTh": "ฟากท่า",
    "districtNameEn": "Fak Tha",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "districtCode": "5306",
    "districtNameTh": "บ้านโคก",
    "districtNameEn": "Ban Khok",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "districtCode": "5307",
    "districtNameTh": "พิชัย",
    "districtNameEn": "Phichai",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "districtCode": "5308",
    "districtNameTh": "ลับแล",
    "districtNameEn": "Laplae",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "districtCode": "5309",
    "districtNameTh": "ทองแสนขัน",
    "districtNameEn": "Thong Saen Khan",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "districtCode": "5401",
    "districtNameTh": "เมืองแพร่",
    "districtNameEn": "Mueang Phrae",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "districtCode": "5402",
    "districtNameTh": "ร้องกวาง",
    "districtNameEn": "Rong Kwang",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "districtCode": "5403",
    "districtNameTh": "ลอง",
    "districtNameEn": "Long",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "districtCode": "5404",
    "districtNameTh": "สูงเม่น",
    "districtNameEn": "Sung Men",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "districtCode": "5405",
    "districtNameTh": "เด่นชัย",
    "districtNameEn": "Den Chai",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "districtCode": "5406",
    "districtNameTh": "สอง",
    "districtNameEn": "Song",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "districtCode": "5407",
    "districtNameTh": "วังชิ้น",
    "districtNameEn": "Wang Chin",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "districtCode": "5408",
    "districtNameTh": "หนองม่วงไข่",
    "districtNameEn": "Nong Muang Khai",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "districtCode": "5501",
    "districtNameTh": "เมืองน่าน",
    "districtNameEn": "Mueang Nan",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "districtCode": "5502",
    "districtNameTh": "แม่จริม",
    "districtNameEn": "Mae Charim",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "districtCode": "5503",
    "districtNameTh": "บ้านหลวง",
    "districtNameEn": "Ban Luang",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "districtCode": "5504",
    "districtNameTh": "นาน้อย",
    "districtNameEn": "Na Noi",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "districtCode": "5505",
    "districtNameTh": "ปัว",
    "districtNameEn": "Pua",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "districtCode": "5506",
    "districtNameTh": "ท่าวังผา",
    "districtNameEn": "Tha Wang Pha",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "districtCode": "5507",
    "districtNameTh": "เวียงสา",
    "districtNameEn": "Wiang Sa",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "districtCode": "5508",
    "districtNameTh": "ทุ่งช้าง",
    "districtNameEn": "Thung Chang",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "districtCode": "5509",
    "districtNameTh": "เชียงกลาง",
    "districtNameEn": "Chiang Klang",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "districtCode": "5510",
    "districtNameTh": "นาหมื่น",
    "districtNameEn": "Na Muen",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "districtCode": "5511",
    "districtNameTh": "สันติสุข",
    "districtNameEn": "Santi Suk",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "districtCode": "5512",
    "districtNameTh": "บ่อเกลือ",
    "districtNameEn": "Bo Kluea",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "districtCode": "5513",
    "districtNameTh": "สองแคว",
    "districtNameEn": "Song Khwae",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "districtCode": "5514",
    "districtNameTh": "ภูเพียง",
    "districtNameEn": "Phu Phiang",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "districtCode": "5515",
    "districtNameTh": "เฉลิมพระเกียรติ",
    "districtNameEn": "Chaloem Phra Kiat",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "districtCode": "5601",
    "districtNameTh": "เมืองพะเยา",
    "districtNameEn": "Mueang Phayao",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "districtCode": "5602",
    "districtNameTh": "จุน",
    "districtNameEn": "Chun",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "districtCode": "5603",
    "districtNameTh": "เชียงคำ",
    "districtNameEn": "Chiang Kham",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "districtCode": "5604",
    "districtNameTh": "เชียงม่วน",
    "districtNameEn": "Chiang Muan",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "districtCode": "5605",
    "districtNameTh": "ดอกคำใต้",
    "districtNameEn": "Dok Khamtai",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "districtCode": "5606",
    "districtNameTh": "ปง",
    "districtNameEn": "Pong",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "districtCode": "5607",
    "districtNameTh": "แม่ใจ",
    "districtNameEn": "Mae Chai",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "districtCode": "5608",
    "districtNameTh": "ภูซาง",
    "districtNameEn": "Phu Sang",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "districtCode": "5609",
    "districtNameTh": "ภูกามยาว",
    "districtNameEn": "Phu Kamyao",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "districtCode": "5701",
    "districtNameTh": "เมืองเชียงราย",
    "districtNameEn": "Mueang Chiang Rai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "districtCode": "5702",
    "districtNameTh": "เวียงชัย",
    "districtNameEn": "Wiang Chai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "districtCode": "5703",
    "districtNameTh": "เชียงของ",
    "districtNameEn": "Chiang Khong",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "districtCode": "5704",
    "districtNameTh": "เทิง",
    "districtNameEn": "Thoeng",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "districtCode": "5705",
    "districtNameTh": "พาน",
    "districtNameEn": "Phan",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "districtCode": "5706",
    "districtNameTh": "ป่าแดด",
    "districtNameEn": "Pa Daet",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "districtCode": "5707",
    "districtNameTh": "แม่จัน",
    "districtNameEn": "Mae Chan",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "districtCode": "5708",
    "districtNameTh": "เชียงแสน",
    "districtNameEn": "Chiang Saen",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "districtCode": "5709",
    "districtNameTh": "แม่สาย",
    "districtNameEn": "Mae Sai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "districtCode": "5710",
    "districtNameTh": "แม่สรวย",
    "districtNameEn": "Mae Suai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "districtCode": "5711",
    "districtNameTh": "เวียงป่าเป้า",
    "districtNameEn": "Wiang Pa Pao",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "districtCode": "5712",
    "districtNameTh": "พญาเม็งราย",
    "districtNameEn": "Phaya Mengrai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "districtCode": "5713",
    "districtNameTh": "เวียงแก่น",
    "districtNameEn": "Wiang Kaen",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "districtCode": "5714",
    "districtNameTh": "ขุนตาล",
    "districtNameEn": "Khun Tan",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "districtCode": "5715",
    "districtNameTh": "แม่ฟ้าหลวง",
    "districtNameEn": "Mae Fa Luang",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "districtCode": "5716",
    "districtNameTh": "แม่ลาว",
    "districtNameEn": "Mae Lao",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "districtCode": "5717",
    "districtNameTh": "เวียงเชียงรุ้ง",
    "districtNameEn": "Wiang Chiang Rung",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "districtCode": "5718",
    "districtNameTh": "ดอยหลวง",
    "districtNameEn": "Doi Luang",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "districtCode": "5801",
    "districtNameTh": "เมืองแม่ฮ่องสอน",
    "districtNameEn": "Mueang Mae Hong Son",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "districtCode": "5802",
    "districtNameTh": "ขุนยวม",
    "districtNameEn": "Khun Yuam",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "districtCode": "5803",
    "districtNameTh": "ปาย",
    "districtNameEn": "Pai",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "districtCode": "5804",
    "districtNameTh": "แม่สะเรียง",
    "districtNameEn": "Mae Sariang",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "districtCode": "5805",
    "districtNameTh": "แม่ลาน้อย",
    "districtNameEn": "Mae La Noi",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "districtCode": "5806",
    "districtNameTh": "สบเมย",
    "districtNameEn": "Sop Moei",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "districtCode": "5807",
    "districtNameTh": "ปางมะผ้า",
    "districtNameEn": "Pang Mapha",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "districtCode": "6001",
    "districtNameTh": "เมืองนครสวรรค์",
    "districtNameEn": "Mueang Nakhon Sawan",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "districtCode": "6002",
    "districtNameTh": "โกรกพระ",
    "districtNameEn": "Krok Phra",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "districtCode": "6003",
    "districtNameTh": "ชุมแสง",
    "districtNameEn": "Chum Saeng",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "districtCode": "6004",
    "districtNameTh": "หนองบัว",
    "districtNameEn": "Nong Bua",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "districtCode": "6005",
    "districtNameTh": "บรรพตพิสัย",
    "districtNameEn": "Banphot Phisai",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "districtCode": "6006",
    "districtNameTh": "เก้าเลี้ยว",
    "districtNameEn": "Kao Liao",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "districtCode": "6007",
    "districtNameTh": "ตาคลี",
    "districtNameEn": "Takhli",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "districtCode": "6008",
    "districtNameTh": "ท่าตะโก",
    "districtNameEn": "Tha Tako",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "districtCode": "6009",
    "districtNameTh": "ไพศาลี",
    "districtNameEn": "Phaisali",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "districtCode": "6010",
    "districtNameTh": "พยุหะคีรี",
    "districtNameEn": "Phayuha Khiri",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "districtCode": "6011",
    "districtNameTh": "ลาดยาว",
    "districtNameEn": "Lat Yao",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "districtCode": "6012",
    "districtNameTh": "ตากฟ้า",
    "districtNameEn": "Tak Fa",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "districtCode": "6013",
    "districtNameTh": "แม่วงก์",
    "districtNameEn": "Mae Wong",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "districtCode": "6014",
    "districtNameTh": "แม่เปิน",
    "districtNameEn": "Mae Poen",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "districtCode": "6015",
    "districtNameTh": "ชุมตาบง",
    "districtNameEn": "Chum Ta Bong",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "districtCode": "6101",
    "districtNameTh": "เมืองอุทัยธานี",
    "districtNameEn": "Mueang Uthai Thani",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "districtCode": "6102",
    "districtNameTh": "ทัพทัน",
    "districtNameEn": "Thap Than",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "districtCode": "6103",
    "districtNameTh": "สว่างอารมณ์",
    "districtNameEn": "Sawang Arom",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "districtCode": "6104",
    "districtNameTh": "หนองฉาง",
    "districtNameEn": "Nong Chang",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "districtCode": "6105",
    "districtNameTh": "หนองขาหย่าง",
    "districtNameEn": "Nong Khayang",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "districtCode": "6106",
    "districtNameTh": "บ้านไร่",
    "districtNameEn": "Ban Rai",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "districtCode": "6107",
    "districtNameTh": "ลานสัก",
    "districtNameEn": "Lan Sak",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "districtCode": "6108",
    "districtNameTh": "ห้วยคต",
    "districtNameEn": "Huai Khot",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "districtCode": "6201",
    "districtNameTh": "เมืองกำแพงเพชร",
    "districtNameEn": "Mueang Kamphaeng Phet",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "districtCode": "6202",
    "districtNameTh": "ไทรงาม",
    "districtNameEn": "Sai Ngam",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "districtCode": "6203",
    "districtNameTh": "คลองลาน",
    "districtNameEn": "Khlong Lan",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "districtCode": "6204",
    "districtNameTh": "ขาณุวรลักษบุรี",
    "districtNameEn": "Khanu Woralaksaburi",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "districtCode": "6205",
    "districtNameTh": "คลองขลุง",
    "districtNameEn": "Khlong Khlung",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "districtCode": "6206",
    "districtNameTh": "พรานกระต่าย",
    "districtNameEn": "Phran Kratai",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "districtCode": "6207",
    "districtNameTh": "ลานกระบือ",
    "districtNameEn": "Lan Krabue",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "districtCode": "6208",
    "districtNameTh": "ทรายทองวัฒนา",
    "districtNameEn": "Sai Thong Watthana",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "districtCode": "6209",
    "districtNameTh": "ปางศิลาทอง",
    "districtNameEn": "Pang Sila Thong",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "districtCode": "6210",
    "districtNameTh": "บึงสามัคคี",
    "districtNameEn": "Bueng Samakkhi",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "districtCode": "6211",
    "districtNameTh": "โกสัมพีนคร",
    "districtNameEn": "Kosamphi Nakhon",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "districtCode": "6301",
    "districtNameTh": "เมืองตาก",
    "districtNameEn": "Mueang Tak",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "districtCode": "6302",
    "districtNameTh": "บ้านตาก",
    "districtNameEn": "Ban Tak",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "districtCode": "6303",
    "districtNameTh": "สามเงา",
    "districtNameEn": "Sam Ngao",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "districtCode": "6304",
    "districtNameTh": "แม่ระมาด",
    "districtNameEn": "Mae Ramat",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "districtCode": "6305",
    "districtNameTh": "ท่าสองยาง",
    "districtNameEn": "Tha Song Yang",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "districtCode": "6306",
    "districtNameTh": "แม่สอด",
    "districtNameEn": "Mae Sot",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "districtCode": "6307",
    "districtNameTh": "พบพระ",
    "districtNameEn": "Phop Phra",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "districtCode": "6308",
    "districtNameTh": "อุ้มผาง",
    "districtNameEn": "Umphang",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "districtCode": "6309",
    "districtNameTh": "วังเจ้า",
    "districtNameEn": "Wang Chao",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "districtCode": "6401",
    "districtNameTh": "เมืองสุโขทัย",
    "districtNameEn": "Mueang Sukhothai",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "districtCode": "6402",
    "districtNameTh": "บ้านด่านลานหอย",
    "districtNameEn": "Ban Dan Lan Hoi",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "districtCode": "6403",
    "districtNameTh": "คีรีมาศ",
    "districtNameEn": "Khiri Mat",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "districtCode": "6404",
    "districtNameTh": "กงไกรลาศ",
    "districtNameEn": "Kong Krailat",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "districtCode": "6405",
    "districtNameTh": "ศรีสัชนาลัย",
    "districtNameEn": "Si Satchanalai",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "districtCode": "6406",
    "districtNameTh": "ศรีสำโรง",
    "districtNameEn": "Si Samrong",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "districtCode": "6407",
    "districtNameTh": "สวรรคโลก",
    "districtNameEn": "Sawankhalok",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "districtCode": "6408",
    "districtNameTh": "ศรีนคร",
    "districtNameEn": "Si Nakhon",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "districtCode": "6409",
    "districtNameTh": "ทุ่งเสลี่ยม",
    "districtNameEn": "Thung Saliam",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "districtCode": "6501",
    "districtNameTh": "เมืองพิษณุโลก",
    "districtNameEn": "Mueang Phitsanulok",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "districtCode": "6502",
    "districtNameTh": "นครไทย",
    "districtNameEn": "Nakhon Thai",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "districtCode": "6503",
    "districtNameTh": "ชาติตระการ",
    "districtNameEn": "Chat Trakan",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "districtCode": "6504",
    "districtNameTh": "บางระกำ",
    "districtNameEn": "Bang Rakam",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "districtCode": "6505",
    "districtNameTh": "บางกระทุ่ม",
    "districtNameEn": "Bang Krathum",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "districtCode": "6506",
    "districtNameTh": "พรหมพิราม",
    "districtNameEn": "Phrom Phiram",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "districtCode": "6507",
    "districtNameTh": "วัดโบสถ์",
    "districtNameEn": "Wat Bot",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "districtCode": "6508",
    "districtNameTh": "วังทอง",
    "districtNameEn": "Wang Thong",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "districtCode": "6509",
    "districtNameTh": "เนินมะปราง",
    "districtNameEn": "Noen Maprang",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "districtCode": "6601",
    "districtNameTh": "เมืองพิจิตร",
    "districtNameEn": "Mueang Phichit",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "districtCode": "6602",
    "districtNameTh": "วังทรายพูน",
    "districtNameEn": "Wang Sai Phun",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "districtCode": "6603",
    "districtNameTh": "โพธิ์ประทับช้าง",
    "districtNameEn": "Pho Prathap Chang",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "districtCode": "6604",
    "districtNameTh": "ตะพานหิน",
    "districtNameEn": "Taphan Hin",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "districtCode": "6605",
    "districtNameTh": "บางมูลนาก",
    "districtNameEn": "Bang Mun Nak",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "districtCode": "6606",
    "districtNameTh": "โพทะเล",
    "districtNameEn": "Pho Thale",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "districtCode": "6607",
    "districtNameTh": "สามง่าม",
    "districtNameEn": "Sam Ngam",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "districtCode": "6608",
    "districtNameTh": "ทับคล้อ",
    "districtNameEn": "Thap Khlo",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "districtCode": "6609",
    "districtNameTh": "สากเหล็ก",
    "districtNameEn": "Sak Lek",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "districtCode": "6610",
    "districtNameTh": "บึงนาราง",
    "districtNameEn": "Bueng Na Rang",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "districtCode": "6611",
    "districtNameTh": "ดงเจริญ",
    "districtNameEn": "Dong Charoen",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "districtCode": "6612",
    "districtNameTh": "วชิรบารมี",
    "districtNameEn": "Wachirabarami",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "districtCode": "6701",
    "districtNameTh": "เมืองเพชรบูรณ์",
    "districtNameEn": "Mueang Phetchabun",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "districtCode": "6702",
    "districtNameTh": "ชนแดน",
    "districtNameEn": "Chon Daen",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "districtCode": "6703",
    "districtNameTh": "หล่มสัก",
    "districtNameEn": "Lom Sak",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "districtCode": "6704",
    "districtNameTh": "หล่มเก่า",
    "districtNameEn": "Lom Kao",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "districtCode": "6705",
    "districtNameTh": "วิเชียรบุรี",
    "districtNameEn": "Wichian Buri",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "districtCode": "6706",
    "districtNameTh": "ศรีเทพ",
    "districtNameEn": "Si Thep",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "districtCode": "6707",
    "districtNameTh": "หนองไผ่",
    "districtNameEn": "Nong Phai",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "districtCode": "6708",
    "districtNameTh": "บึงสามพัน",
    "districtNameEn": "Bueng Sam Phan",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "districtCode": "6709",
    "districtNameTh": "น้ำหนาว",
    "districtNameEn": "Nam Nao",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "districtCode": "6710",
    "districtNameTh": "วังโป่ง",
    "districtNameEn": "Wang Pong",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "districtCode": "6711",
    "districtNameTh": "เขาค้อ",
    "districtNameEn": "Khao Kho",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "districtCode": "7001",
    "districtNameTh": "เมืองราชบุรี",
    "districtNameEn": "Mueang Ratchaburi",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "districtCode": "7002",
    "districtNameTh": "จอมบึง",
    "districtNameEn": "Chom Bueng",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "districtCode": "7003",
    "districtNameTh": "สวนผึ้ง",
    "districtNameEn": "Suan Phueng",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "districtCode": "7004",
    "districtNameTh": "ดำเนินสะดวก",
    "districtNameEn": "Damnoen Saduak",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "districtCode": "7005",
    "districtNameTh": "บ้านโป่ง",
    "districtNameEn": "Ban Pong",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "districtCode": "7006",
    "districtNameTh": "บางแพ",
    "districtNameEn": "Bang Phae",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "districtCode": "7007",
    "districtNameTh": "โพธาราม",
    "districtNameEn": "Photharam",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "districtCode": "7008",
    "districtNameTh": "ปากท่อ",
    "districtNameEn": "Pak Tho",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "districtCode": "7009",
    "districtNameTh": "วัดเพลง",
    "districtNameEn": "Wat Phleng",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "districtCode": "7010",
    "districtNameTh": "บ้านคา",
    "districtNameEn": "Ban Kha",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "districtCode": "7101",
    "districtNameTh": "เมืองกาญจนบุรี",
    "districtNameEn": "Mueang Kanchanaburi",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "districtCode": "7102",
    "districtNameTh": "ไทรโยค",
    "districtNameEn": "Sai Yok",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "districtCode": "7103",
    "districtNameTh": "บ่อพลอย",
    "districtNameEn": "Bo Phloi",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "districtCode": "7104",
    "districtNameTh": "ศรีสวัสดิ์",
    "districtNameEn": "Si Sawat",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "districtCode": "7105",
    "districtNameTh": "ท่ามะกา",
    "districtNameEn": "Tha Maka",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "districtCode": "7106",
    "districtNameTh": "ท่าม่วง",
    "districtNameEn": "Tha Muang",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "districtCode": "7107",
    "districtNameTh": "ทองผาภูมิ",
    "districtNameEn": "Thong Pha Phum",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "districtCode": "7108",
    "districtNameTh": "สังขละบุรี",
    "districtNameEn": "Sangkhla Buri",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "districtCode": "7109",
    "districtNameTh": "พนมทวน",
    "districtNameEn": "Phanom Thuan",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "districtCode": "7110",
    "districtNameTh": "เลาขวัญ",
    "districtNameEn": "Lao Khwan",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "districtCode": "7111",
    "districtNameTh": "ด่านมะขามเตี้ย",
    "districtNameEn": "Dan Makham Tia",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "districtCode": "7112",
    "districtNameTh": "หนองปรือ",
    "districtNameEn": "Nong Prue",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "districtCode": "7113",
    "districtNameTh": "ห้วยกระเจา",
    "districtNameEn": "Huai Krachao",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "districtCode": "7201",
    "districtNameTh": "เมืองสุพรรณบุรี",
    "districtNameEn": "Mueang Suphan Buri",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "districtCode": "7202",
    "districtNameTh": "เดิมบางนางบวช",
    "districtNameEn": "Doem Bang Nang Buat",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "districtCode": "7203",
    "districtNameTh": "ด่านช้าง",
    "districtNameEn": "Dan Chang",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "districtCode": "7204",
    "districtNameTh": "บางปลาม้า",
    "districtNameEn": "Bang Pla Ma",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "districtCode": "7205",
    "districtNameTh": "ศรีประจันต์",
    "districtNameEn": "Si Prachan",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "districtCode": "7206",
    "districtNameTh": "ดอนเจดีย์",
    "districtNameEn": "Don Chedi",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "districtCode": "7207",
    "districtNameTh": "สองพี่น้อง",
    "districtNameEn": "Song Phi Nong",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "districtCode": "7208",
    "districtNameTh": "สามชุก",
    "districtNameEn": "Sam Chuk",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "districtCode": "7209",
    "districtNameTh": "อู่ทอง",
    "districtNameEn": "U Thong",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "districtCode": "7210",
    "districtNameTh": "หนองหญ้าไซ",
    "districtNameEn": "Nong Ya Sai",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "districtCode": "7301",
    "districtNameTh": "เมืองนครปฐม",
    "districtNameEn": "Mueang Nakhon Pathom",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "districtCode": "7302",
    "districtNameTh": "กำแพงแสน",
    "districtNameEn": "Kamphaeng Saen",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "districtCode": "7303",
    "districtNameTh": "นครชัยศรี",
    "districtNameEn": "Nakhon Chai Si",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "districtCode": "7304",
    "districtNameTh": "ดอนตูม",
    "districtNameEn": "Don Tum",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "districtCode": "7305",
    "districtNameTh": "บางเลน",
    "districtNameEn": "Bang Len",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "districtCode": "7306",
    "districtNameTh": "สามพราน",
    "districtNameEn": "Sam Phran",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "districtCode": "7307",
    "districtNameTh": "พุทธมณฑล",
    "districtNameEn": "Phutthamonthon",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "districtCode": "7401",
    "districtNameTh": "เมืองสมุทรสาคร",
    "districtNameEn": "Mueang Samut Sakhon",
    "provinceCode": "74",
    "provinceNameTh": "สมุทรสาคร",
    "provinceNameEn": "Samut Sakhon"
},
{
    "districtCode": "7402",
    "districtNameTh": "กระทุ่มแบน",
    "districtNameEn": "Krathum Baen",
    "provinceCode": "74",
    "provinceNameTh": "สมุทรสาคร",
    "provinceNameEn": "Samut Sakhon"
},
{
    "districtCode": "7403",
    "districtNameTh": "บ้านแพ้ว",
    "districtNameEn": "Ban Phaeo",
    "provinceCode": "74",
    "provinceNameTh": "สมุทรสาคร",
    "provinceNameEn": "Samut Sakhon"
},
{
    "districtCode": "7501",
    "districtNameTh": "เมืองสมุทรสงคราม",
    "districtNameEn": "Mueang Samut Songkhram",
    "provinceCode": "75",
    "provinceNameTh": "สมุทรสงคราม",
    "provinceNameEn": "Samut Songkhram"
},
{
    "districtCode": "7502",
    "districtNameTh": "บางคนที",
    "districtNameEn": "Bang Khonthi",
    "provinceCode": "75",
    "provinceNameTh": "สมุทรสงคราม",
    "provinceNameEn": "Samut Songkhram"
},
{
    "districtCode": "7503",
    "districtNameTh": "อัมพวา",
    "districtNameEn": "Amphawa",
    "provinceCode": "75",
    "provinceNameTh": "สมุทรสงคราม",
    "provinceNameEn": "Samut Songkhram"
},
{
    "districtCode": "7601",
    "districtNameTh": "เมืองเพชรบุรี",
    "districtNameEn": "Mueang Phetchaburi",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "districtCode": "7602",
    "districtNameTh": "เขาย้อย",
    "districtNameEn": "Khao Yoi",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "districtCode": "7603",
    "districtNameTh": "หนองหญ้าปล้อง",
    "districtNameEn": "Nong Ya Plong",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "districtCode": "7604",
    "districtNameTh": "ชะอำ",
    "districtNameEn": "Cha-am",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "districtCode": "7605",
    "districtNameTh": "ท่ายาง",
    "districtNameEn": "Tha Yang",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "districtCode": "7606",
    "districtNameTh": "บ้านลาด",
    "districtNameEn": "Ban Lat",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "districtCode": "7607",
    "districtNameTh": "บ้านแหลม",
    "districtNameEn": "Ban Laem",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "districtCode": "7608",
    "districtNameTh": "แก่งกระจาน",
    "districtNameEn": "Kaeng Krachan",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "districtCode": "7701",
    "districtNameTh": "เมืองประจวบคีรีขันธ์",
    "districtNameEn": "Mueang Prachuap Khiri Khan",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "districtCode": "7702",
    "districtNameTh": "กุยบุรี",
    "districtNameEn": "Kui Buri",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "districtCode": "7703",
    "districtNameTh": "ทับสะแก",
    "districtNameEn": "Thap Sakae",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "districtCode": "7704",
    "districtNameTh": "บางสะพาน",
    "districtNameEn": "Bang Saphan",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "districtCode": "7705",
    "districtNameTh": "บางสะพานน้อย",
    "districtNameEn": "Bang Saphan Noi",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "districtCode": "7706",
    "districtNameTh": "ปราณบุรี",
    "districtNameEn": "Pran Buri",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "districtCode": "7707",
    "districtNameTh": "หัวหิน",
    "districtNameEn": "Hua Hin",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "districtCode": "7708",
    "districtNameTh": "สามร้อยยอด",
    "districtNameEn": "Sam Roi Yot",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "districtCode": "8001",
    "districtNameTh": "เมืองนครศรีธรรมราช",
    "districtNameEn": "Mueang Nakhon Si Thammarat",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "districtCode": "8002",
    "districtNameTh": "พรหมคีรี",
    "districtNameEn": "Phrom Khiri",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "districtCode": "8003",
    "districtNameTh": "ลานสกา",
    "districtNameEn": "Lan Saka",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "districtCode": "8004",
    "districtNameTh": "ฉวาง",
    "districtNameEn": "Chawang",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "districtCode": "8005",
    "districtNameTh": "พิปูน",
    "districtNameEn": "Phipun",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "districtCode": "8006",
    "districtNameTh": "เชียรใหญ่",
    "districtNameEn": "Chian Yai",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "districtCode": "8007",
    "districtNameTh": "ชะอวด",
    "districtNameEn": "Cha-uat",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "districtCode": "8008",
    "districtNameTh": "ท่าศาลา",
    "districtNameEn": "Tha Sala",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "districtCode": "8009",
    "districtNameTh": "ทุ่งสง",
    "districtNameEn": "Thung Song",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "districtCode": "8010",
    "districtNameTh": "นาบอน",
    "districtNameEn": "Na Bon",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "districtCode": "8011",
    "districtNameTh": "ทุ่งใหญ่",
    "districtNameEn": "Thung Yai",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "districtCode": "8012",
    "districtNameTh": "ปากพนัง",
    "districtNameEn": "Pak Phanang",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "districtCode": "8013",
    "districtNameTh": "ร่อนพิบูลย์",
    "districtNameEn": "Ron Phibun",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "districtCode": "8014",
    "districtNameTh": "สิชล",
    "districtNameEn": "Sichon",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "districtCode": "8015",
    "districtNameTh": "ขนอม",
    "districtNameEn": "Khanom",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "districtCode": "8016",
    "districtNameTh": "หัวไทร",
    "districtNameEn": "Hua Sai",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "districtCode": "8017",
    "districtNameTh": "บางขัน",
    "districtNameEn": "Bang Khan",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "districtCode": "8018",
    "districtNameTh": "ถ้ำพรรณรา",
    "districtNameEn": "Tham Phannara",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "districtCode": "8019",
    "districtNameTh": "จุฬาภรณ์",
    "districtNameEn": "Chulabhorn",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "districtCode": "8020",
    "districtNameTh": "พระพรหม",
    "districtNameEn": "Phra Phrom",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "districtCode": "8021",
    "districtNameTh": "นบพิตำ",
    "districtNameEn": "Nopphitam",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "districtCode": "8022",
    "districtNameTh": "ช้างกลาง",
    "districtNameEn": "Chang Klang",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "districtCode": "8023",
    "districtNameTh": "เฉลิมพระเกียรติ",
    "districtNameEn": "Chaloem Phra Kiat",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "districtCode": "8101",
    "districtNameTh": "เมืองกระบี่",
    "districtNameEn": "Mueang Krabi",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "districtCode": "8102",
    "districtNameTh": "เขาพนม",
    "districtNameEn": "Khao Phanom",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "districtCode": "8103",
    "districtNameTh": "เกาะลันตา",
    "districtNameEn": "Ko Lanta",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "districtCode": "8104",
    "districtNameTh": "คลองท่อม",
    "districtNameEn": "Khlong Thom",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "districtCode": "8105",
    "districtNameTh": "อ่าวลึก",
    "districtNameEn": "Ao Luek",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "districtCode": "8106",
    "districtNameTh": "ปลายพระยา",
    "districtNameEn": "Plai Phraya",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "districtCode": "8107",
    "districtNameTh": "ลำทับ",
    "districtNameEn": "Lam Thap",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "districtCode": "8108",
    "districtNameTh": "เหนือคลอง",
    "districtNameEn": "Nuea Khlong",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "districtCode": "8201",
    "districtNameTh": "เมืองพังงา",
    "districtNameEn": "Mueang Phang-nga",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "districtCode": "8202",
    "districtNameTh": "เกาะยาว",
    "districtNameEn": "Ko Yao",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "districtCode": "8203",
    "districtNameTh": "กะปง",
    "districtNameEn": "Kapong",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "districtCode": "8204",
    "districtNameTh": "ตะกั่วทุ่ง",
    "districtNameEn": "Takua Thung",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "districtCode": "8205",
    "districtNameTh": "ตะกั่วป่า",
    "districtNameEn": "Takua Pa",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "districtCode": "8206",
    "districtNameTh": "คุระบุรี",
    "districtNameEn": "Khura Buri",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "districtCode": "8207",
    "districtNameTh": "ทับปุด",
    "districtNameEn": "Thap Put",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "districtCode": "8208",
    "districtNameTh": "ท้ายเหมือง",
    "districtNameEn": "Thai Mueang",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "districtCode": "8301",
    "districtNameTh": "เมืองภูเก็ต",
    "districtNameEn": "Mueang Phuket",
    "provinceCode": "83",
    "provinceNameTh": "ภูเก็ต",
    "provinceNameEn": "Phuket"
},
{
    "districtCode": "8302",
    "districtNameTh": "กะทู้",
    "districtNameEn": "Kathu",
    "provinceCode": "83",
    "provinceNameTh": "ภูเก็ต",
    "provinceNameEn": "Phuket"
},
{
    "districtCode": "8303",
    "districtNameTh": "ถลาง",
    "districtNameEn": "Thalang",
    "provinceCode": "83",
    "provinceNameTh": "ภูเก็ต",
    "provinceNameEn": "Phuket"
},
{
    "districtCode": "8401",
    "districtNameTh": "เมืองสุราษฎร์ธานี",
    "districtNameEn": "Mueang Surat Thani",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "districtCode": "8402",
    "districtNameTh": "กาญจนดิษฐ์",
    "districtNameEn": "Kanchanadit",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "districtCode": "8403",
    "districtNameTh": "ดอนสัก",
    "districtNameEn": "Don Sak",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "districtCode": "8404",
    "districtNameTh": "เกาะสมุย",
    "districtNameEn": "Ko Samui",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "districtCode": "8405",
    "districtNameTh": "เกาะพะงัน",
    "districtNameEn": "Ko Pha-ngan",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "districtCode": "8406",
    "districtNameTh": "ไชยา",
    "districtNameEn": "Chaiya",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "districtCode": "8407",
    "districtNameTh": "ท่าชนะ",
    "districtNameEn": "Tha Chana",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "districtCode": "8408",
    "districtNameTh": "คีรีรัฐนิคม",
    "districtNameEn": "Khiri Rat Nikhom",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "districtCode": "8409",
    "districtNameTh": "บ้านตาขุน",
    "districtNameEn": "Ban Ta Khun",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "districtCode": "8410",
    "districtNameTh": "พนม",
    "districtNameEn": "Phanom",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "districtCode": "8411",
    "districtNameTh": "ท่าฉาง",
    "districtNameEn": "Tha Chang",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "districtCode": "8412",
    "districtNameTh": "บ้านนาสาร",
    "districtNameEn": "Ban Na San",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "districtCode": "8413",
    "districtNameTh": "บ้านนาเดิม",
    "districtNameEn": "Ban Na Doem",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "districtCode": "8414",
    "districtNameTh": "เคียนซา",
    "districtNameEn": "Khian Sa",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "districtCode": "8415",
    "districtNameTh": "เวียงสระ",
    "districtNameEn": "Wiang Sa",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "districtCode": "8416",
    "districtNameTh": "พระแสง",
    "districtNameEn": "Phrasaeng",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "districtCode": "8417",
    "districtNameTh": "พุนพิน",
    "districtNameEn": "Phunphin",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "districtCode": "8418",
    "districtNameTh": "ชัยบุรี",
    "districtNameEn": "Chai Buri",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "districtCode": "8419",
    "districtNameTh": "วิภาวดี",
    "districtNameEn": "Vibhavadi",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "districtCode": "8501",
    "districtNameTh": "เมืองระนอง",
    "districtNameEn": "Mueang Ranong",
    "provinceCode": "85",
    "provinceNameTh": "ระนอง",
    "provinceNameEn": "Ranong"
},
{
    "districtCode": "8502",
    "districtNameTh": "ละอุ่น",
    "districtNameEn": "La-un",
    "provinceCode": "85",
    "provinceNameTh": "ระนอง",
    "provinceNameEn": "Ranong"
},
{
    "districtCode": "8503",
    "districtNameTh": "กะเปอร์",
    "districtNameEn": "Kapoe",
    "provinceCode": "85",
    "provinceNameTh": "ระนอง",
    "provinceNameEn": "Ranong"
},
{
    "districtCode": "8504",
    "districtNameTh": "กระบุรี",
    "districtNameEn": "Kra Buri",
    "provinceCode": "85",
    "provinceNameTh": "ระนอง",
    "provinceNameEn": "Ranong"
},
{
    "districtCode": "8505",
    "districtNameTh": "สุขสำราญ",
    "districtNameEn": "Suk Samran",
    "provinceCode": "85",
    "provinceNameTh": "ระนอง",
    "provinceNameEn": "Ranong"
},
{
    "districtCode": "8601",
    "districtNameTh": "เมืองชุมพร",
    "districtNameEn": "Mueang Chumphon",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "districtCode": "8602",
    "districtNameTh": "ท่าแซะ",
    "districtNameEn": "Tha Sae",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "districtCode": "8603",
    "districtNameTh": "ปะทิว",
    "districtNameEn": "Pathio",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "districtCode": "8604",
    "districtNameTh": "หลังสวน",
    "districtNameEn": "Lang Suan",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "districtCode": "8605",
    "districtNameTh": "ละแม",
    "districtNameEn": "Lamae",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "districtCode": "8606",
    "districtNameTh": "พะโต๊ะ",
    "districtNameEn": "Phato",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "districtCode": "8607",
    "districtNameTh": "สวี",
    "districtNameEn": "Sawi",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "districtCode": "8608",
    "districtNameTh": "ทุ่งตะโก",
    "districtNameEn": "Thung Tako",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "districtCode": "9001",
    "districtNameTh": "เมืองสงขลา",
    "districtNameEn": "Mueang Songkhla",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "districtCode": "9002",
    "districtNameTh": "สทิงพระ",
    "districtNameEn": "Sathing Phra",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "districtCode": "9003",
    "districtNameTh": "จะนะ",
    "districtNameEn": "Chana",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "districtCode": "9004",
    "districtNameTh": "นาทวี",
    "districtNameEn": "Na Thawi",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "districtCode": "9005",
    "districtNameTh": "เทพา",
    "districtNameEn": "Thepha",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "districtCode": "9006",
    "districtNameTh": "สะบ้าย้อย",
    "districtNameEn": "Saba Yoi",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "districtCode": "9007",
    "districtNameTh": "ระโนด",
    "districtNameEn": "Ranot",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "districtCode": "9008",
    "districtNameTh": "กระแสสินธุ์",
    "districtNameEn": "Krasae Sin",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "districtCode": "9009",
    "districtNameTh": "รัตภูมิ",
    "districtNameEn": "Rattaphum",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "districtCode": "9010",
    "districtNameTh": "สะเดา",
    "districtNameEn": "Sadao",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "districtCode": "9011",
    "districtNameTh": "หาดใหญ่",
    "districtNameEn": "Hat Yai",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "districtCode": "9012",
    "districtNameTh": "นาหม่อม",
    "districtNameEn": "Na Mom",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "districtCode": "9013",
    "districtNameTh": "ควนเนียง",
    "districtNameEn": "Khuan Niang",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "districtCode": "9014",
    "districtNameTh": "บางกล่ำ",
    "districtNameEn": "Bang Klam",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "districtCode": "9015",
    "districtNameTh": "สิงหนคร",
    "districtNameEn": "Singhanakhon",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "districtCode": "9016",
    "districtNameTh": "คลองหอยโข่ง",
    "districtNameEn": "Khlong Hoi Khong",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "districtCode": "9101",
    "districtNameTh": "เมืองสตูล",
    "districtNameEn": "Mueang Satun",
    "provinceCode": "91",
    "provinceNameTh": "สตูล",
    "provinceNameEn": "Satun"
},
{
    "districtCode": "9102",
    "districtNameTh": "ควนโดน",
    "districtNameEn": "Khuan Don",
    "provinceCode": "91",
    "provinceNameTh": "สตูล",
    "provinceNameEn": "Satun"
},
{
    "districtCode": "9103",
    "districtNameTh": "ควนกาหลง",
    "districtNameEn": "Khuan Kalong",
    "provinceCode": "91",
    "provinceNameTh": "สตูล",
    "provinceNameEn": "Satun"
},
{
    "districtCode": "9104",
    "districtNameTh": "ท่าแพ",
    "districtNameEn": "Tha Phae",
    "provinceCode": "91",
    "provinceNameTh": "สตูล",
    "provinceNameEn": "Satun"
},
{
    "districtCode": "9105",
    "districtNameTh": "ละงู",
    "districtNameEn": "La-ngu",
    "provinceCode": "91",
    "provinceNameTh": "สตูล",
    "provinceNameEn": "Satun"
},
{
    "districtCode": "9106",
    "districtNameTh": "ทุ่งหว้า",
    "districtNameEn": "Thung Wa",
    "provinceCode": "91",
    "provinceNameTh": "สตูล",
    "provinceNameEn": "Satun"
},
{
    "districtCode": "9107",
    "districtNameTh": "มะนัง",
    "districtNameEn": "Manang",
    "provinceCode": "91",
    "provinceNameTh": "สตูล",
    "provinceNameEn": "Satun"
},
{
    "districtCode": "9201",
    "districtNameTh": "เมืองตรัง",
    "districtNameEn": "Mueang Trang",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "districtCode": "9202",
    "districtNameTh": "กันตัง",
    "districtNameEn": "Kantang",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "districtCode": "9203",
    "districtNameTh": "ย่านตาขาว",
    "districtNameEn": "Yan Ta Khao",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "districtCode": "9204",
    "districtNameTh": "ปะเหลียน",
    "districtNameEn": "Palian",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "districtCode": "9205",
    "districtNameTh": "สิเกา",
    "districtNameEn": "Sikao",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "districtCode": "9206",
    "districtNameTh": "ห้วยยอด",
    "districtNameEn": "Huai Yot",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "districtCode": "9207",
    "districtNameTh": "วังวิเศษ",
    "districtNameEn": "Wang Wiset",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "districtCode": "9208",
    "districtNameTh": "นาโยง",
    "districtNameEn": "Na Yong",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "districtCode": "9209",
    "districtNameTh": "รัษฎา",
    "districtNameEn": "Ratsada",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "districtCode": "9210",
    "districtNameTh": "หาดสำราญ",
    "districtNameEn": "Hat Samran",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "districtCode": "9301",
    "districtNameTh": "เมืองพัทลุง",
    "districtNameEn": "Mueang Phatthalung",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "districtCode": "9302",
    "districtNameTh": "กงหรา",
    "districtNameEn": "Kong Ra",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "districtCode": "9303",
    "districtNameTh": "เขาชัยสน",
    "districtNameEn": "Khao Chaison",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "districtCode": "9304",
    "districtNameTh": "ตะโหมด",
    "districtNameEn": "Tamot",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "districtCode": "9305",
    "districtNameTh": "ควนขนุน",
    "districtNameEn": "Khuan Khanun",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "districtCode": "9306",
    "districtNameTh": "ปากพะยูน",
    "districtNameEn": "Pak Phayun",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "districtCode": "9307",
    "districtNameTh": "ศรีบรรพต",
    "districtNameEn": "Si Banphot",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "districtCode": "9308",
    "districtNameTh": "ป่าบอน",
    "districtNameEn": "Pa Bon",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "districtCode": "9309",
    "districtNameTh": "บางแก้ว",
    "districtNameEn": "Bang Kaeo",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "districtCode": "9310",
    "districtNameTh": "ป่าพะยอม",
    "districtNameEn": "Pa Phayom",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "districtCode": "9311",
    "districtNameTh": "ศรีนครินทร์",
    "districtNameEn": "Srinagarindra",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "districtCode": "9401",
    "districtNameTh": "เมืองปัตตานี",
    "districtNameEn": "Mueang Pattani",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "districtCode": "9402",
    "districtNameTh": "โคกโพธิ์",
    "districtNameEn": "Khok Pho",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "districtCode": "9403",
    "districtNameTh": "หนองจิก",
    "districtNameEn": "Nong Chik",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "districtCode": "9404",
    "districtNameTh": "ปะนาเระ",
    "districtNameEn": "Panare",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "districtCode": "9405",
    "districtNameTh": "มายอ",
    "districtNameEn": "Mayo",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "districtCode": "9406",
    "districtNameTh": "ทุ่งยางแดง",
    "districtNameEn": "Thung Yang Daeng",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "districtCode": "9407",
    "districtNameTh": "สายบุรี",
    "districtNameEn": "Sai Buri",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "districtCode": "9408",
    "districtNameTh": "ไม้แก่น",
    "districtNameEn": "Mai Kaen",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "districtCode": "9409",
    "districtNameTh": "ยะหริ่ง",
    "districtNameEn": "Yaring",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "districtCode": "9410",
    "districtNameTh": "ยะรัง",
    "districtNameEn": "Yarang",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "districtCode": "9411",
    "districtNameTh": "กะพ้อ",
    "districtNameEn": "Kapho",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "districtCode": "9412",
    "districtNameTh": "แม่ลาน",
    "districtNameEn": "Mae Lan",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "districtCode": "9501",
    "districtNameTh": "เมืองยะลา",
    "districtNameEn": "Mueang Yala",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "districtCode": "9502",
    "districtNameTh": "เบตง",
    "districtNameEn": "Betong",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "districtCode": "9503",
    "districtNameTh": "บันนังสตา",
    "districtNameEn": "Bannang Sata",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "districtCode": "9504",
    "districtNameTh": "ธารโต",
    "districtNameEn": "Than To",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "districtCode": "9505",
    "districtNameTh": "ยะหา",
    "districtNameEn": "Yaha",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "districtCode": "9506",
    "districtNameTh": "รามัน",
    "districtNameEn": "Raman",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "districtCode": "9507",
    "districtNameTh": "กาบัง",
    "districtNameEn": "Kabang",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "districtCode": "9508",
    "districtNameTh": "กรงปินัง",
    "districtNameEn": "Krong Pinang",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "districtCode": "9601",
    "districtNameTh": "เมืองนราธิวาส",
    "districtNameEn": "Mueang Narathiwat",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "districtCode": "9602",
    "districtNameTh": "ตากใบ",
    "districtNameEn": "Tak Bai",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "districtCode": "9603",
    "districtNameTh": "บาเจาะ",
    "districtNameEn": "Bacho",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "districtCode": "9604",
    "districtNameTh": "ยี่งอ",
    "districtNameEn": "Yi-ngo",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "districtCode": "9605",
    "districtNameTh": "ระแงะ",
    "districtNameEn": "Ra-ngae",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "districtCode": "9606",
    "districtNameTh": "รือเสาะ",
    "districtNameEn": "Rueso",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "districtCode": "9607",
    "districtNameTh": "ศรีสาคร",
    "districtNameEn": "Si Sakhon",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "districtCode": "9608",
    "districtNameTh": "แว้ง",
    "districtNameEn": "Waeng",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "districtCode": "9609",
    "districtNameTh": "สุคิริน",
    "districtNameEn": "Sukhirin",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "districtCode": "9610",
    "districtNameTh": "สุไหงโก-ลก",
    "districtNameEn": "Su-ngai Kolok",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "districtCode": "9611",
    "districtNameTh": "สุไหงปาดี",
    "districtNameEn": "Su-ngai Padi",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "districtCode": "9612",
    "districtNameTh": "จะแนะ",
    "districtNameEn": "Chanae",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "districtCode": "9613",
    "districtNameTh": "เจาะไอร้อง",
    "districtNameEn": "Cho-airong",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
}

];

export const subDistrict = [
  {
    "subDistrictCode": "100101",
    "subDistrictNameTh": "พระบรมมหาราชวัง",
    "subDistrictNameEn": "Phra Borom Maha Ratchawang",
    "postcode": "10200",
    "districtCode": "1001",
    "districtNameTh": "พระนคร",
    "districtNameEn": "Phra Nakhon",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "100102",
    "subDistrictNameTh": "วังบูรพาภิรมย์",
    "subDistrictNameEn": "Wang Burapha Phirom",
    "postcode": "10200",
    "districtCode": "1001",
    "districtNameTh": "พระนคร",
    "districtNameEn": "Phra Nakhon",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "100103",
    "subDistrictNameTh": "วัดราชบพิธ",
    "subDistrictNameEn": "Wat Ratchabophit",
    "postcode": "10200",
    "districtCode": "1001",
    "districtNameTh": "พระนคร",
    "districtNameEn": "Phra Nakhon",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "100104",
    "subDistrictNameTh": "สำราญราษฎร์",
    "subDistrictNameEn": "Samran Rat",
    "postcode": "10200",
    "districtCode": "1001",
    "districtNameTh": "พระนคร",
    "districtNameEn": "Phra Nakhon",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "100105",
    "subDistrictNameTh": "ศาลเจ้าพ่อเสือ",
    "subDistrictNameEn": "San Chao Pho Suea",
    "postcode": "10200",
    "districtCode": "1001",
    "districtNameTh": "พระนคร",
    "districtNameEn": "Phra Nakhon",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "100106",
    "subDistrictNameTh": "เสาชิงช้า",
    "subDistrictNameEn": "Sao Chingcha",
    "postcode": "10200",
    "districtCode": "1001",
    "districtNameTh": "พระนคร",
    "districtNameEn": "Phra Nakhon",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "100107",
    "subDistrictNameTh": "บวรนิเวศ",
    "subDistrictNameEn": "Bowon Niwet",
    "postcode": "10200",
    "districtCode": "1001",
    "districtNameTh": "พระนคร",
    "districtNameEn": "Phra Nakhon",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "100108",
    "subDistrictNameTh": "ตลาดยอด",
    "subDistrictNameEn": "Talat Yot",
    "postcode": "10200",
    "districtCode": "1001",
    "districtNameTh": "พระนคร",
    "districtNameEn": "Phra Nakhon",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "100109",
    "subDistrictNameTh": "ชนะสงคราม",
    "subDistrictNameEn": "Chana Songkhram",
    "postcode": "10200",
    "districtCode": "1001",
    "districtNameTh": "พระนคร",
    "districtNameEn": "Phra Nakhon",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "100110",
    "subDistrictNameTh": "บ้านพานถม",
    "subDistrictNameEn": "Ban Phan Thom",
    "postcode": "10200",
    "districtCode": "1001",
    "districtNameTh": "พระนคร",
    "districtNameEn": "Phra Nakhon",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "100111",
    "subDistrictNameTh": "บางขุนพรหม",
    "subDistrictNameEn": "Bang Khun Phrom",
    "postcode": "10200",
    "districtCode": "1001",
    "districtNameTh": "พระนคร",
    "districtNameEn": "Phra Nakhon",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "100112",
    "subDistrictNameTh": "วัดสามพระยา",
    "subDistrictNameEn": "Wat Sam Phraya",
    "postcode": "10200",
    "districtCode": "1001",
    "districtNameTh": "พระนคร",
    "districtNameEn": "Phra Nakhon",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "100201",
    "subDistrictNameTh": "ดุสิต",
    "subDistrictNameEn": "Dusit",
    "postcode": "10300",
    "districtCode": "1002",
    "districtNameTh": "ดุสิต",
    "districtNameEn": "Dusit",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "100202",
    "subDistrictNameTh": "วชิรพยาบาล",
    "subDistrictNameEn": "Wachira Phayaban",
    "postcode": "10300",
    "districtCode": "1002",
    "districtNameTh": "ดุสิต",
    "districtNameEn": "Dusit",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "100203",
    "subDistrictNameTh": "สวนจิตรลดา",
    "subDistrictNameEn": "Suan Chitlada",
    "postcode": "10300",
    "districtCode": "1002",
    "districtNameTh": "ดุสิต",
    "districtNameEn": "Dusit",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "100204",
    "subDistrictNameTh": "สี่แยกมหานาค",
    "subDistrictNameEn": "Si Yaek Mahanak",
    "postcode": "10300",
    "districtCode": "1002",
    "districtNameTh": "ดุสิต",
    "districtNameEn": "Dusit",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "100206",
    "subDistrictNameTh": "ถนนนครไชยศรี",
    "subDistrictNameEn": "Thanon Nakhon Chai Si",
    "postcode": "10300",
    "districtCode": "1002",
    "districtNameTh": "ดุสิต",
    "districtNameEn": "Dusit",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "100301",
    "subDistrictNameTh": "กระทุ่มราย",
    "subDistrictNameEn": "Krathum Rai",
    "postcode": "10530",
    "districtCode": "1003",
    "districtNameTh": "หนองจอก",
    "districtNameEn": "Nong Chok",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "100302",
    "subDistrictNameTh": "หนองจอก",
    "subDistrictNameEn": "Nong Chok",
    "postcode": "10530",
    "districtCode": "1003",
    "districtNameTh": "หนองจอก",
    "districtNameEn": "Nong Chok",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "100303",
    "subDistrictNameTh": "คลองสิบ",
    "subDistrictNameEn": "Khlong Sip",
    "postcode": "10530",
    "districtCode": "1003",
    "districtNameTh": "หนองจอก",
    "districtNameEn": "Nong Chok",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "100304",
    "subDistrictNameTh": "คลองสิบสอง",
    "subDistrictNameEn": "Khlong Sip Song",
    "postcode": "10530",
    "districtCode": "1003",
    "districtNameTh": "หนองจอก",
    "districtNameEn": "Nong Chok",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "100305",
    "subDistrictNameTh": "โคกแฝด",
    "subDistrictNameEn": "Khok Faet",
    "postcode": "10530",
    "districtCode": "1003",
    "districtNameTh": "หนองจอก",
    "districtNameEn": "Nong Chok",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "100306",
    "subDistrictNameTh": "คู้ฝั่งเหนือ",
    "subDistrictNameEn": "Khu Fang Nuea",
    "postcode": "10530",
    "districtCode": "1003",
    "districtNameTh": "หนองจอก",
    "districtNameEn": "Nong Chok",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "100307",
    "subDistrictNameTh": "ลำผักชี",
    "subDistrictNameEn": "Lam Phak Chi",
    "postcode": "10530",
    "districtCode": "1003",
    "districtNameTh": "หนองจอก",
    "districtNameEn": "Nong Chok",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "100308",
    "subDistrictNameTh": "ลำต้อยติ่ง",
    "subDistrictNameEn": "Lam Toiting",
    "postcode": "10530",
    "districtCode": "1003",
    "districtNameTh": "หนองจอก",
    "districtNameEn": "Nong Chok",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "100401",
    "subDistrictNameTh": "มหาพฤฒาราม",
    "subDistrictNameEn": "Maha Phruettharam",
    "postcode": "10500",
    "districtCode": "1004",
    "districtNameTh": "บางรัก",
    "districtNameEn": "Bang Rak",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "100402",
    "subDistrictNameTh": "สีลม",
    "subDistrictNameEn": "Si Lom",
    "postcode": "10500",
    "districtCode": "1004",
    "districtNameTh": "บางรัก",
    "districtNameEn": "Bang Rak",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "100403",
    "subDistrictNameTh": "สุริยวงศ์",
    "subDistrictNameEn": "Suriyawong",
    "postcode": "10500",
    "districtCode": "1004",
    "districtNameTh": "บางรัก",
    "districtNameEn": "Bang Rak",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "100404",
    "subDistrictNameTh": "บางรัก",
    "subDistrictNameEn": "Bang Rak",
    "postcode": "10500",
    "districtCode": "1004",
    "districtNameTh": "บางรัก",
    "districtNameEn": "Bang Rak",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "100405",
    "subDistrictNameTh": "สี่พระยา",
    "subDistrictNameEn": "Si Phraya",
    "postcode": "10500",
    "districtCode": "1004",
    "districtNameTh": "บางรัก",
    "districtNameEn": "Bang Rak",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "100502",
    "subDistrictNameTh": "อนุสาวรีย์",
    "subDistrictNameEn": "Anusawari",
    "postcode": "10220",
    "districtCode": "1005",
    "districtNameTh": "บางเขน",
    "districtNameEn": "Bang Khen",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "100508",
    "subDistrictNameTh": "ท่าแร้ง",
    "subDistrictNameEn": "Tha Raeng",
    "postcode": "10220",
    "districtCode": "1005",
    "districtNameTh": "บางเขน",
    "districtNameEn": "Bang Khen",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "100601",
    "subDistrictNameTh": "คลองจั่น",
    "subDistrictNameEn": "Khlong Chan",
    "postcode": "10240",
    "districtCode": "1006",
    "districtNameTh": "บางกะปิ",
    "districtNameEn": "Bang Kapi",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "100608",
    "subDistrictNameTh": "หัวหมาก",
    "subDistrictNameEn": "Hua Mak",
    "postcode": "10240",
    "districtCode": "1006",
    "districtNameTh": "บางกะปิ",
    "districtNameEn": "Bang Kapi",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "100701",
    "subDistrictNameTh": "รองเมือง",
    "subDistrictNameEn": "Rong Mueang",
    "postcode": "10330",
    "districtCode": "1007",
    "districtNameTh": "ปทุมวัน",
    "districtNameEn": "Pathum Wan",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "100702",
    "subDistrictNameTh": "วังใหม่",
    "subDistrictNameEn": "Wang Mai",
    "postcode": "10330",
    "districtCode": "1007",
    "districtNameTh": "ปทุมวัน",
    "districtNameEn": "Pathum Wan",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "100703",
    "subDistrictNameTh": "ปทุมวัน",
    "subDistrictNameEn": "Pathum Wan",
    "postcode": "10330",
    "districtCode": "1007",
    "districtNameTh": "ปทุมวัน",
    "districtNameEn": "Pathum Wan",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "100704",
    "subDistrictNameTh": "ลุมพินี",
    "subDistrictNameEn": "Lumphini",
    "postcode": "10330",
    "districtCode": "1007",
    "districtNameTh": "ปทุมวัน",
    "districtNameEn": "Pathum Wan",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "100801",
    "subDistrictNameTh": "ป้อมปราบ",
    "subDistrictNameEn": "Pom Prap",
    "postcode": "10100",
    "districtCode": "1008",
    "districtNameTh": "ป้อมปราบศัตรูพ่าย",
    "districtNameEn": "Pom Prap Sattru Phai",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "100802",
    "subDistrictNameTh": "วัดเทพศิรินทร์",
    "subDistrictNameEn": "Wat Thep Sirin",
    "postcode": "10100",
    "districtCode": "1008",
    "districtNameTh": "ป้อมปราบศัตรูพ่าย",
    "districtNameEn": "Pom Prap Sattru Phai",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "100803",
    "subDistrictNameTh": "คลองมหานาค",
    "subDistrictNameEn": "Khlong Maha Nak",
    "postcode": "10100",
    "districtCode": "1008",
    "districtNameTh": "ป้อมปราบศัตรูพ่าย",
    "districtNameEn": "Pom Prap Sattru Phai",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "100804",
    "subDistrictNameTh": "บ้านบาตร",
    "subDistrictNameEn": "Ban Bat",
    "postcode": "10100",
    "districtCode": "1008",
    "districtNameTh": "ป้อมปราบศัตรูพ่าย",
    "districtNameEn": "Pom Prap Sattru Phai",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "100805",
    "subDistrictNameTh": "วัดโสมนัส",
    "subDistrictNameEn": "Wat Sommanat",
    "postcode": "10100",
    "districtCode": "1008",
    "districtNameTh": "ป้อมปราบศัตรูพ่าย",
    "districtNameEn": "Pom Prap Sattru Phai",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "100905",
    "subDistrictNameTh": "บางจาก",
    "subDistrictNameEn": "Bang Chak",
    "postcode": "10260",
    "districtCode": "1009",
    "districtNameTh": "พระโขนง",
    "districtNameEn": "Phra Khanong",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "100910",
    "subDistrictNameTh": "พระโขนงใต้",
    "subDistrictNameEn": "Phra Khanong Tai",
    "postcode": "10260",
    "districtCode": "1009",
    "districtNameTh": "พระโขนง",
    "districtNameEn": "Phra Khanong",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "101001",
    "subDistrictNameTh": "มีนบุรี",
    "subDistrictNameEn": "Min Buri",
    "postcode": "10510",
    "districtCode": "1010",
    "districtNameTh": "มีนบุรี",
    "districtNameEn": "Min Buri",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "101002",
    "subDistrictNameTh": "แสนแสบ",
    "subDistrictNameEn": "Saen Saep",
    "postcode": "10510",
    "districtCode": "1010",
    "districtNameTh": "มีนบุรี",
    "districtNameEn": "Min Buri",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "101101",
    "subDistrictNameTh": "ลาดกระบัง",
    "subDistrictNameEn": "Lat Krabang",
    "postcode": "10520",
    "districtCode": "1011",
    "districtNameTh": "ลาดกระบัง",
    "districtNameEn": "Lat Krabang",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "101102",
    "subDistrictNameTh": "คลองสองต้นนุ่น",
    "subDistrictNameEn": "Khlong Song Ton Nun",
    "postcode": "10520",
    "districtCode": "1011",
    "districtNameTh": "ลาดกระบัง",
    "districtNameEn": "Lat Krabang",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "101103",
    "subDistrictNameTh": "คลองสามประเวศ",
    "subDistrictNameEn": "Khlong Sam Prawet",
    "postcode": "10520",
    "districtCode": "1011",
    "districtNameTh": "ลาดกระบัง",
    "districtNameEn": "Lat Krabang",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "101104",
    "subDistrictNameTh": "ลำปลาทิว",
    "subDistrictNameEn": "Lam Pla Thio",
    "postcode": "10520",
    "districtCode": "1011",
    "districtNameTh": "ลาดกระบัง",
    "districtNameEn": "Lat Krabang",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "101105",
    "subDistrictNameTh": "ทับยาว",
    "subDistrictNameEn": "Thap Yao",
    "postcode": "10520",
    "districtCode": "1011",
    "districtNameTh": "ลาดกระบัง",
    "districtNameEn": "Lat Krabang",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "101106",
    "subDistrictNameTh": "ขุมทอง",
    "subDistrictNameEn": "Khum Thong",
    "postcode": "10520",
    "districtCode": "1011",
    "districtNameTh": "ลาดกระบัง",
    "districtNameEn": "Lat Krabang",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "101203",
    "subDistrictNameTh": "ช่องนนทรี",
    "subDistrictNameEn": "Chong Nonsi",
    "postcode": "10120",
    "districtCode": "1012",
    "districtNameTh": "ยานนาวา",
    "districtNameEn": "Yan Nawa",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "101204",
    "subDistrictNameTh": "บางโพงพาง",
    "subDistrictNameEn": "Bang Phongphang",
    "postcode": "10120",
    "districtCode": "1012",
    "districtNameTh": "ยานนาวา",
    "districtNameEn": "Yan Nawa",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "101301",
    "subDistrictNameTh": "จักรวรรดิ",
    "subDistrictNameEn": "Chakkrawat",
    "postcode": "10100",
    "districtCode": "1013",
    "districtNameTh": "สัมพันธวงศ์",
    "districtNameEn": "Samphanthawong",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "101302",
    "subDistrictNameTh": "สัมพันธวงศ์",
    "subDistrictNameEn": "Samphanthawong",
    "postcode": "10100",
    "districtCode": "1013",
    "districtNameTh": "สัมพันธวงศ์",
    "districtNameEn": "Samphanthawong",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "101303",
    "subDistrictNameTh": "ตลาดน้อย",
    "subDistrictNameEn": "Talat Noi",
    "postcode": "10100",
    "districtCode": "1013",
    "districtNameTh": "สัมพันธวงศ์",
    "districtNameEn": "Samphanthawong",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "101401",
    "subDistrictNameTh": "สามเสนใน",
    "subDistrictNameEn": "Sam Sen Nai",
    "postcode": "10400",
    "districtCode": "1014",
    "districtNameTh": "พญาไท",
    "districtNameEn": "Phaya Thai",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "101406",
    "subDistrictNameTh": "พญาไท",
    "subDistrictNameEn": "Phaya Thai",
    "postcode": "10400",
    "districtCode": "1014",
    "districtNameTh": "พญาไท",
    "districtNameEn": "Phaya Thai",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "101501",
    "subDistrictNameTh": "วัดกัลยาณ์",
    "subDistrictNameEn": "Wat Kanlaya",
    "postcode": "10600",
    "districtCode": "1015",
    "districtNameTh": "ธนบุรี",
    "districtNameEn": "Thon Buri",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "101502",
    "subDistrictNameTh": "หิรัญรูจี",
    "subDistrictNameEn": "Hiran Ruchi",
    "postcode": "10600",
    "districtCode": "1015",
    "districtNameTh": "ธนบุรี",
    "districtNameEn": "Thon Buri",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "101503",
    "subDistrictNameTh": "บางยี่เรือ",
    "subDistrictNameEn": "Bang Yi Ruea",
    "postcode": "10600",
    "districtCode": "1015",
    "districtNameTh": "ธนบุรี",
    "districtNameEn": "Thon Buri",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "101504",
    "subDistrictNameTh": "บุคคโล",
    "subDistrictNameEn": "Bukkhalo",
    "postcode": "10600",
    "districtCode": "1015",
    "districtNameTh": "ธนบุรี",
    "districtNameEn": "Thon Buri",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "101505",
    "subDistrictNameTh": "ตลาดพลู",
    "subDistrictNameEn": "Talat Phlu",
    "postcode": "10600",
    "districtCode": "1015",
    "districtNameTh": "ธนบุรี",
    "districtNameEn": "Thon Buri",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "101506",
    "subDistrictNameTh": "ดาวคะนอง",
    "subDistrictNameEn": "Dao Khanong",
    "postcode": "10600",
    "districtCode": "1015",
    "districtNameTh": "ธนบุรี",
    "districtNameEn": "Thon Buri",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "101507",
    "subDistrictNameTh": "สำเหร่",
    "subDistrictNameEn": "Samre",
    "postcode": "10600",
    "districtCode": "1015",
    "districtNameTh": "ธนบุรี",
    "districtNameEn": "Thon Buri",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "101601",
    "subDistrictNameTh": "วัดอรุณ",
    "subDistrictNameEn": "Wat Arun",
    "postcode": "10600",
    "districtCode": "1016",
    "districtNameTh": "บางกอกใหญ่",
    "districtNameEn": "Bangkok Yai",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "101602",
    "subDistrictNameTh": "วัดท่าพระ",
    "subDistrictNameEn": "Wat Tha Phra",
    "postcode": "10600",
    "districtCode": "1016",
    "districtNameTh": "บางกอกใหญ่",
    "districtNameEn": "Bangkok Yai",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "101701",
    "subDistrictNameTh": "ห้วยขวาง",
    "subDistrictNameEn": "Huai Khwang",
    "postcode": "10310",
    "districtCode": "1017",
    "districtNameTh": "ห้วยขวาง",
    "districtNameEn": "Huai Khwang",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "101702",
    "subDistrictNameTh": "บางกะปิ",
    "subDistrictNameEn": "Bang Kapi",
    "postcode": "10310",
    "districtCode": "1017",
    "districtNameTh": "ห้วยขวาง",
    "districtNameEn": "Huai Khwang",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "101704",
    "subDistrictNameTh": "สามเสนนอก",
    "subDistrictNameEn": "Sam Sen Nok",
    "postcode": "10310",
    "districtCode": "1017",
    "districtNameTh": "ห้วยขวาง",
    "districtNameEn": "Huai Khwang",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "101801",
    "subDistrictNameTh": "สมเด็จเจ้าพระยา",
    "subDistrictNameEn": "Somdet Chao Phraya",
    "postcode": "10600",
    "districtCode": "1018",
    "districtNameTh": "คลองสาน",
    "districtNameEn": "Khlong San",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "101802",
    "subDistrictNameTh": "คลองสาน",
    "subDistrictNameEn": "Khlong San",
    "postcode": "10600",
    "districtCode": "1018",
    "districtNameTh": "คลองสาน",
    "districtNameEn": "Khlong San",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "101803",
    "subDistrictNameTh": "บางลำภูล่าง",
    "subDistrictNameEn": "Bang Lamphu Lang",
    "postcode": "10600",
    "districtCode": "1018",
    "districtNameTh": "คลองสาน",
    "districtNameEn": "Khlong San",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "101804",
    "subDistrictNameTh": "คลองต้นไทร",
    "subDistrictNameEn": "Khlong Ton Sai",
    "postcode": "10600",
    "districtCode": "1018",
    "districtNameTh": "คลองสาน",
    "districtNameEn": "Khlong San",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "101901",
    "subDistrictNameTh": "คลองชักพระ",
    "subDistrictNameEn": "Khlong Chak Phra",
    "postcode": "10170",
    "districtCode": "1019",
    "districtNameTh": "ตลิ่งชัน",
    "districtNameEn": "Taling Chan",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "101902",
    "subDistrictNameTh": "ตลิ่งชัน",
    "subDistrictNameEn": "Taling Chan",
    "postcode": "10170",
    "districtCode": "1019",
    "districtNameTh": "ตลิ่งชัน",
    "districtNameEn": "Taling Chan",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "101903",
    "subDistrictNameTh": "ฉิมพลี",
    "subDistrictNameEn": "Chimphli",
    "postcode": "10170",
    "districtCode": "1019",
    "districtNameTh": "ตลิ่งชัน",
    "districtNameEn": "Taling Chan",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "101904",
    "subDistrictNameTh": "บางพรม",
    "subDistrictNameEn": "Bang Phrom",
    "postcode": "10170",
    "districtCode": "1019",
    "districtNameTh": "ตลิ่งชัน",
    "districtNameEn": "Taling Chan",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "101905",
    "subDistrictNameTh": "บางระมาด",
    "subDistrictNameEn": "Bang Ramat",
    "postcode": "10170",
    "districtCode": "1019",
    "districtNameTh": "ตลิ่งชัน",
    "districtNameEn": "Taling Chan",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "101907",
    "subDistrictNameTh": "บางเชือกหนัง",
    "subDistrictNameEn": "Bang Chueak Nang",
    "postcode": "10170",
    "districtCode": "1019",
    "districtNameTh": "ตลิ่งชัน",
    "districtNameEn": "Taling Chan",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "102004",
    "subDistrictNameTh": "ศิริราช",
    "subDistrictNameEn": "Sirirat",
    "postcode": "10700",
    "districtCode": "1020",
    "districtNameTh": "บางกอกน้อย",
    "districtNameEn": "Bangkok Noi",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "102005",
    "subDistrictNameTh": "บ้านช่างหล่อ",
    "subDistrictNameEn": "Ban Chang Lo",
    "postcode": "10700",
    "districtCode": "1020",
    "districtNameTh": "บางกอกน้อย",
    "districtNameEn": "Bangkok Noi",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "102006",
    "subDistrictNameTh": "บางขุนนนท์",
    "subDistrictNameEn": "Bang Khun Non",
    "postcode": "10700",
    "districtCode": "1020",
    "districtNameTh": "บางกอกน้อย",
    "districtNameEn": "Bangkok Noi",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "102007",
    "subDistrictNameTh": "บางขุนศรี",
    "subDistrictNameEn": "Bang Khun Si",
    "postcode": "10700",
    "districtCode": "1020",
    "districtNameTh": "บางกอกน้อย",
    "districtNameEn": "Bangkok Noi",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "102009",
    "subDistrictNameTh": "อรุณอมรินทร์",
    "subDistrictNameEn": "Arun Ammarin",
    "postcode": "10700",
    "districtCode": "1020",
    "districtNameTh": "บางกอกน้อย",
    "districtNameEn": "Bangkok Noi",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "102105",
    "subDistrictNameTh": "ท่าข้าม",
    "subDistrictNameEn": "Tha Kham",
    "postcode": "10150",
    "districtCode": "1021",
    "districtNameTh": "บางขุนเทียน",
    "districtNameEn": "Bang Khun Thian",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "102107",
    "subDistrictNameTh": "แสมดำ",
    "subDistrictNameEn": "Samae Dam",
    "postcode": "10150",
    "districtCode": "1021",
    "districtNameTh": "บางขุนเทียน",
    "districtNameEn": "Bang Khun Thian",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "102201",
    "subDistrictNameTh": "บางหว้า",
    "subDistrictNameEn": "Bang Wa",
    "postcode": "10160",
    "districtCode": "1022",
    "districtNameTh": "ภาษีเจริญ",
    "districtNameEn": "Phasi Charoen",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "102202",
    "subDistrictNameTh": "บางด้วน",
    "subDistrictNameEn": "Bang Duan",
    "postcode": "10160",
    "districtCode": "1022",
    "districtNameTh": "ภาษีเจริญ",
    "districtNameEn": "Phasi Charoen",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "102206",
    "subDistrictNameTh": "บางจาก",
    "subDistrictNameEn": "Bang Chak",
    "postcode": "10160",
    "districtCode": "1022",
    "districtNameTh": "ภาษีเจริญ",
    "districtNameEn": "Phasi Charoen",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "102207",
    "subDistrictNameTh": "บางแวก",
    "subDistrictNameEn": "Bang Waek",
    "postcode": "10160",
    "districtCode": "1022",
    "districtNameTh": "ภาษีเจริญ",
    "districtNameEn": "Phasi Charoen",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "102208",
    "subDistrictNameTh": "คลองขวาง",
    "subDistrictNameEn": "Khlong Khwang",
    "postcode": "10160",
    "districtCode": "1022",
    "districtNameTh": "ภาษีเจริญ",
    "districtNameEn": "Phasi Charoen",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "102209",
    "subDistrictNameTh": "ปากคลองภาษีเจริญ",
    "subDistrictNameEn": "Pak Khlong Phasi Charoen",
    "postcode": "10160",
    "districtCode": "1022",
    "districtNameTh": "ภาษีเจริญ",
    "districtNameEn": "Phasi Charoen",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "102210",
    "subDistrictNameTh": "คูหาสวรรค์",
    "subDistrictNameEn": "Khuha Sawan",
    "postcode": "10160",
    "districtCode": "1022",
    "districtNameTh": "ภาษีเจริญ",
    "districtNameEn": "Phasi Charoen",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "102302",
    "subDistrictNameTh": "หนองแขม",
    "subDistrictNameEn": "Nong Khaem",
    "postcode": "10160",
    "districtCode": "1023",
    "districtNameTh": "หนองแขม",
    "districtNameEn": "Nong Khaem",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "102303",
    "subDistrictNameTh": "หนองค้างพลู",
    "subDistrictNameEn": "Nong Khang Phlu",
    "postcode": "10160",
    "districtCode": "1023",
    "districtNameTh": "หนองแขม",
    "districtNameEn": "Nong Khaem",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "102401",
    "subDistrictNameTh": "ราษฎร์บูรณะ",
    "subDistrictNameEn": "Rat Burana",
    "postcode": "10140",
    "districtCode": "1024",
    "districtNameTh": "ราษฎร์บูรณะ",
    "districtNameEn": "Rat Burana",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "102402",
    "subDistrictNameTh": "บางปะกอก",
    "subDistrictNameEn": "Bang Pa Kok",
    "postcode": "10140",
    "districtCode": "1024",
    "districtNameTh": "ราษฎร์บูรณะ",
    "districtNameEn": "Rat Burana",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "102501",
    "subDistrictNameTh": "บางพลัด",
    "subDistrictNameEn": "Bang Phlat",
    "postcode": "10700",
    "districtCode": "1025",
    "districtNameTh": "บางพลัด",
    "districtNameEn": "Bang Phlat",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "102502",
    "subDistrictNameTh": "บางอ้อ",
    "subDistrictNameEn": "Bang O",
    "postcode": "10700",
    "districtCode": "1025",
    "districtNameTh": "บางพลัด",
    "districtNameEn": "Bang Phlat",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "102503",
    "subDistrictNameTh": "บางบำหรุ",
    "subDistrictNameEn": "Bang Bamru",
    "postcode": "10700",
    "districtCode": "1025",
    "districtNameTh": "บางพลัด",
    "districtNameEn": "Bang Phlat",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "102504",
    "subDistrictNameTh": "บางยี่ขัน",
    "subDistrictNameEn": "Bang Yi Khan",
    "postcode": "10700",
    "districtCode": "1025",
    "districtNameTh": "บางพลัด",
    "districtNameEn": "Bang Phlat",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "102601",
    "subDistrictNameTh": "ดินแดง",
    "subDistrictNameEn": "Din Daeng",
    "postcode": "10400",
    "districtCode": "1026",
    "districtNameTh": "ดินแดง",
    "districtNameEn": "Din Daeng",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "102602",
    "subDistrictNameTh": "รัชดาภิเษก",
    "subDistrictNameEn": "Ratchadaphisek",
    "postcode": "10400",
    "districtCode": "1026",
    "districtNameTh": "ดินแดง",
    "districtNameEn": "Din Daeng",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "102701",
    "subDistrictNameTh": "คลองกุ่ม",
    "subDistrictNameEn": "Khlong Kum",
    "postcode": "10240",
    "districtCode": "1027",
    "districtNameTh": "บึงกุ่ม",
    "districtNameEn": "Bueng Kum",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "102704",
    "subDistrictNameTh": "นวมินทร์",
    "subDistrictNameEn": "Nawamin",
    "postcode": "10240",
    "districtCode": "1027",
    "districtNameTh": "บึงกุ่ม",
    "districtNameEn": "Bueng Kum",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "102705",
    "subDistrictNameTh": "นวลจันทร์",
    "subDistrictNameEn": "Nuan Chan",
    "postcode": "10230",
    "districtCode": "1027",
    "districtNameTh": "บึงกุ่ม",
    "districtNameEn": "Bueng Kum",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "102801",
    "subDistrictNameTh": "ทุ่งวัดดอน",
    "subDistrictNameEn": "Thung Wat Don",
    "postcode": "10120",
    "districtCode": "1028",
    "districtNameTh": "สาทร",
    "districtNameEn": "Sathon",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "102802",
    "subDistrictNameTh": "ยานนาวา",
    "subDistrictNameEn": "Yan Nawa",
    "postcode": "10120",
    "districtCode": "1028",
    "districtNameTh": "สาทร",
    "districtNameEn": "Sathon",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "102803",
    "subDistrictNameTh": "ทุ่งมหาเมฆ",
    "subDistrictNameEn": "Thung Maha Mek",
    "postcode": "10120",
    "districtCode": "1028",
    "districtNameTh": "สาทร",
    "districtNameEn": "Sathon",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "102901",
    "subDistrictNameTh": "บางซื่อ",
    "subDistrictNameEn": "Bang Sue",
    "postcode": "10800",
    "districtCode": "1029",
    "districtNameTh": "บางซื่อ",
    "districtNameEn": "Bang Sue",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "102902",
    "subDistrictNameTh": "วงศ์สว่าง",
    "subDistrictNameEn": "Wong Sawang",
    "postcode": "10800",
    "districtCode": "1029",
    "districtNameTh": "บางซื่อ",
    "districtNameEn": "Bang Sue",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "103001",
    "subDistrictNameTh": "ลาดยาว",
    "subDistrictNameEn": "Lat Yao",
    "postcode": "10900",
    "districtCode": "1030",
    "districtNameTh": "จตุจักร",
    "districtNameEn": "Chatuchak",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "103002",
    "subDistrictNameTh": "เสนานิคม",
    "subDistrictNameEn": "Sena Nikhom",
    "postcode": "10900",
    "districtCode": "1030",
    "districtNameTh": "จตุจักร",
    "districtNameEn": "Chatuchak",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "103003",
    "subDistrictNameTh": "จันทรเกษม",
    "subDistrictNameEn": "Chan Kasem",
    "postcode": "10900",
    "districtCode": "1030",
    "districtNameTh": "จตุจักร",
    "districtNameEn": "Chatuchak",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "103004",
    "subDistrictNameTh": "จอมพล",
    "subDistrictNameEn": "Chom Phon",
    "postcode": "10900",
    "districtCode": "1030",
    "districtNameTh": "จตุจักร",
    "districtNameEn": "Chatuchak",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "103005",
    "subDistrictNameTh": "จตุจักร",
    "subDistrictNameEn": "Chatuchak",
    "postcode": "10900",
    "districtCode": "1030",
    "districtNameTh": "จตุจักร",
    "districtNameEn": "Chatuchak",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "103101",
    "subDistrictNameTh": "บางคอแหลม",
    "subDistrictNameEn": "Bang Kho Laem",
    "postcode": "10120",
    "districtCode": "1031",
    "districtNameTh": "บางคอแหลม",
    "districtNameEn": "Bang Kho Laem",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "103102",
    "subDistrictNameTh": "วัดพระยาไกร",
    "subDistrictNameEn": "Wat Phraya Krai",
    "postcode": "10120",
    "districtCode": "1031",
    "districtNameTh": "บางคอแหลม",
    "districtNameEn": "Bang Kho Laem",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "103103",
    "subDistrictNameTh": "บางโคล่",
    "subDistrictNameEn": "Bang Khlo",
    "postcode": "10120",
    "districtCode": "1031",
    "districtNameTh": "บางคอแหลม",
    "districtNameEn": "Bang Kho Laem",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "103201",
    "subDistrictNameTh": "ประเวศ",
    "subDistrictNameEn": "Prawet",
    "postcode": "10250",
    "districtCode": "1032",
    "districtNameTh": "ประเวศ",
    "districtNameEn": "Prawet",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "103202",
    "subDistrictNameTh": "หนองบอน",
    "subDistrictNameEn": "Nong Bon",
    "postcode": "10250",
    "districtCode": "1032",
    "districtNameTh": "ประเวศ",
    "districtNameEn": "Prawet",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "103203",
    "subDistrictNameTh": "ดอกไม้",
    "subDistrictNameEn": "Dok Mai",
    "postcode": "10250",
    "districtCode": "1032",
    "districtNameTh": "ประเวศ",
    "districtNameEn": "Prawet",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "103301",
    "subDistrictNameTh": "คลองเตย",
    "subDistrictNameEn": "Khlong Toei",
    "postcode": "10110",
    "districtCode": "1033",
    "districtNameTh": "คลองเตย",
    "districtNameEn": "Khlong Toei",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "103302",
    "subDistrictNameTh": "คลองตัน",
    "subDistrictNameEn": "Khlong Tan",
    "postcode": "10110",
    "districtCode": "1033",
    "districtNameTh": "คลองเตย",
    "districtNameEn": "Khlong Toei",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "103303",
    "subDistrictNameTh": "พระโขนง",
    "subDistrictNameEn": "Phra Khanong",
    "postcode": "10110",
    "districtCode": "1033",
    "districtNameTh": "คลองเตย",
    "districtNameEn": "Khlong Toei",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "103401",
    "subDistrictNameTh": "สวนหลวง",
    "subDistrictNameEn": "Suan Luang",
    "postcode": "10250",
    "districtCode": "1034",
    "districtNameTh": "สวนหลวง",
    "districtNameEn": "Suan Luang",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "103402",
    "subDistrictNameTh": "อ่อนนุช",
    "subDistrictNameEn": "On Nut",
    "postcode": "10250",
    "districtCode": "1034",
    "districtNameTh": "สวนหลวง",
    "districtNameEn": "Suan Luang",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "103403",
    "subDistrictNameTh": "พัฒนาการ",
    "subDistrictNameEn": "Phatthanakan",
    "postcode": "10250",
    "districtCode": "1034",
    "districtNameTh": "สวนหลวง",
    "districtNameEn": "Suan Luang",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "103501",
    "subDistrictNameTh": "บางขุนเทียน",
    "subDistrictNameEn": "Bang Khun Thian",
    "postcode": "10150",
    "districtCode": "1035",
    "districtNameTh": "จอมทอง",
    "districtNameEn": "Chom Thong",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "103502",
    "subDistrictNameTh": "บางค้อ",
    "subDistrictNameEn": "Bang Kho",
    "postcode": "10150",
    "districtCode": "1035",
    "districtNameTh": "จอมทอง",
    "districtNameEn": "Chom Thong",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "103503",
    "subDistrictNameTh": "บางมด",
    "subDistrictNameEn": "Bang Mot",
    "postcode": "10150",
    "districtCode": "1035",
    "districtNameTh": "จอมทอง",
    "districtNameEn": "Chom Thong",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "103504",
    "subDistrictNameTh": "จอมทอง",
    "subDistrictNameEn": "Chom Thong",
    "postcode": "10150",
    "districtCode": "1035",
    "districtNameTh": "จอมทอง",
    "districtNameEn": "Chom Thong",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "103602",
    "subDistrictNameTh": "สีกัน",
    "subDistrictNameEn": "Si Kan",
    "postcode": "10210",
    "districtCode": "1036",
    "districtNameTh": "ดอนเมือง",
    "districtNameEn": "Don Mueang",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "103604",
    "subDistrictNameTh": "ดอนเมือง",
    "subDistrictNameEn": "Don Mueang",
    "postcode": "10210",
    "districtCode": "1036",
    "districtNameTh": "ดอนเมือง",
    "districtNameEn": "Don Mueang",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "103605",
    "subDistrictNameTh": "สนามบิน",
    "subDistrictNameEn": "Sanam Bin",
    "postcode": "10210",
    "districtCode": "1036",
    "districtNameTh": "ดอนเมือง",
    "districtNameEn": "Don Mueang",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "103701",
    "subDistrictNameTh": "ทุ่งพญาไท",
    "subDistrictNameEn": "Thung Phaya Thai",
    "postcode": "10400",
    "districtCode": "1037",
    "districtNameTh": "ราชเทวี",
    "districtNameEn": "Ratchathewi",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "103702",
    "subDistrictNameTh": "ถนนพญาไท",
    "subDistrictNameEn": "Thanon Phaya Thai",
    "postcode": "10400",
    "districtCode": "1037",
    "districtNameTh": "ราชเทวี",
    "districtNameEn": "Ratchathewi",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "103703",
    "subDistrictNameTh": "ถนนเพชรบุรี",
    "subDistrictNameEn": "Thanon Phetchaburi",
    "postcode": "10400",
    "districtCode": "1037",
    "districtNameTh": "ราชเทวี",
    "districtNameEn": "Ratchathewi",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "103704",
    "subDistrictNameTh": "มักกะสัน",
    "subDistrictNameEn": "Makkasan",
    "postcode": "10400",
    "districtCode": "1037",
    "districtNameTh": "ราชเทวี",
    "districtNameEn": "Ratchathewi",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "103801",
    "subDistrictNameTh": "ลาดพร้าว",
    "subDistrictNameEn": "Lat Phrao",
    "postcode": "10230",
    "districtCode": "1038",
    "districtNameTh": "ลาดพร้าว",
    "districtNameEn": "Lat Phrao",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "103802",
    "subDistrictNameTh": "จรเข้บัว",
    "subDistrictNameEn": "Chorakhe Bua",
    "postcode": "10230",
    "districtCode": "1038",
    "districtNameTh": "ลาดพร้าว",
    "districtNameEn": "Lat Phrao",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "103901",
    "subDistrictNameTh": "คลองเตยเหนือ",
    "subDistrictNameEn": "Khlong Toei Nuea",
    "postcode": "10110",
    "districtCode": "1039",
    "districtNameTh": "วัฒนา",
    "districtNameEn": "Vadhana",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "103902",
    "subDistrictNameTh": "คลองตันเหนือ",
    "subDistrictNameEn": "Khlong Tan Nuea",
    "postcode": "10110",
    "districtCode": "1039",
    "districtNameTh": "วัฒนา",
    "districtNameEn": "Vadhana",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "103903",
    "subDistrictNameTh": "พระโขนงเหนือ",
    "subDistrictNameEn": "Phra Khanong Nuea",
    "postcode": "10110",
    "districtCode": "1039",
    "districtNameTh": "วัฒนา",
    "districtNameEn": "Vadhana",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "104001",
    "subDistrictNameTh": "บางแค",
    "subDistrictNameEn": "Bang Khae",
    "postcode": "10160",
    "districtCode": "1040",
    "districtNameTh": "บางแค",
    "districtNameEn": "Bang Khae",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "104002",
    "subDistrictNameTh": "บางแคเหนือ",
    "subDistrictNameEn": "Bang Khae Nuea",
    "postcode": "10160",
    "districtCode": "1040",
    "districtNameTh": "บางแค",
    "districtNameEn": "Bang Khae",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "104003",
    "subDistrictNameTh": "บางไผ่",
    "subDistrictNameEn": "Bang Phai",
    "postcode": "10160",
    "districtCode": "1040",
    "districtNameTh": "บางแค",
    "districtNameEn": "Bang Khae",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "104004",
    "subDistrictNameTh": "หลักสอง",
    "subDistrictNameEn": "Lak Song",
    "postcode": "10160",
    "districtCode": "1040",
    "districtNameTh": "บางแค",
    "districtNameEn": "Bang Khae",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "104101",
    "subDistrictNameTh": "ทุ่งสองห้อง",
    "subDistrictNameEn": "Thung Song Hong",
    "postcode": "10210",
    "districtCode": "1041",
    "districtNameTh": "หลักสี่",
    "districtNameEn": "Lak Si",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "104102",
    "subDistrictNameTh": "ตลาดบางเขน",
    "subDistrictNameEn": "Talat Bang Khen",
    "postcode": "10210",
    "districtCode": "1041",
    "districtNameTh": "หลักสี่",
    "districtNameEn": "Lak Si",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "104201",
    "subDistrictNameTh": "สายไหม",
    "subDistrictNameEn": "Sai Mai",
    "postcode": "10220",
    "districtCode": "1042",
    "districtNameTh": "สายไหม",
    "districtNameEn": "Sai Mai",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "104202",
    "subDistrictNameTh": "ออเงิน",
    "subDistrictNameEn": "O Ngoen",
    "postcode": "10220",
    "districtCode": "1042",
    "districtNameTh": "สายไหม",
    "districtNameEn": "Sai Mai",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "104203",
    "subDistrictNameTh": "คลองถนน",
    "subDistrictNameEn": "Khlong Thanon",
    "postcode": "10220",
    "districtCode": "1042",
    "districtNameTh": "สายไหม",
    "districtNameEn": "Sai Mai",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "104301",
    "subDistrictNameTh": "คันนายาว",
    "subDistrictNameEn": "Khan Na Yao",
    "postcode": "10230",
    "districtCode": "1043",
    "districtNameTh": "คันนายาว",
    "districtNameEn": "Khan Na Yao",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "104302",
    "subDistrictNameTh": "รามอินทรา",
    "subDistrictNameEn": "Ram Inthra",
    "postcode": "10230",
    "districtCode": "1043",
    "districtNameTh": "คันนายาว",
    "districtNameEn": "Khan Na Yao",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "104401",
    "subDistrictNameTh": "สะพานสูง",
    "subDistrictNameEn": "Saphan Sung",
    "postcode": "10240",
    "districtCode": "1044",
    "districtNameTh": "สะพานสูง",
    "districtNameEn": "Saphan Sung",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "104402",
    "subDistrictNameTh": "ราษฎร์พัฒนา",
    "subDistrictNameEn": "Rat Phatthana",
    "postcode": "10240",
    "districtCode": "1044",
    "districtNameTh": "สะพานสูง",
    "districtNameEn": "Saphan Sung",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "104403",
    "subDistrictNameTh": "ทับช้าง",
    "subDistrictNameEn": "Thap Chang",
    "postcode": "10250",
    "districtCode": "1044",
    "districtNameTh": "สะพานสูง",
    "districtNameEn": "Saphan Sung",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "104501",
    "subDistrictNameTh": "วังทองหลาง",
    "subDistrictNameEn": "Wang Thonglang",
    "postcode": "10310",
    "districtCode": "1045",
    "districtNameTh": "วังทองหลาง",
    "districtNameEn": "Wang Thonglang",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "104502",
    "subDistrictNameTh": "สะพานสอง",
    "subDistrictNameEn": "Saphan Song",
    "postcode": "10310",
    "districtCode": "1045",
    "districtNameTh": "วังทองหลาง",
    "districtNameEn": "Wang Thonglang",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "104503",
    "subDistrictNameTh": "คลองเจ้าคุณสิงห์",
    "subDistrictNameEn": "Khlong Chao Khun Sing",
    "postcode": "10310",
    "districtCode": "1045",
    "districtNameTh": "วังทองหลาง",
    "districtNameEn": "Wang Thonglang",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "104504",
    "subDistrictNameTh": "พลับพลา",
    "subDistrictNameEn": "Phlapphla",
    "postcode": "10310",
    "districtCode": "1045",
    "districtNameTh": "วังทองหลาง",
    "districtNameEn": "Wang Thonglang",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "104601",
    "subDistrictNameTh": "สามวาตะวันตก",
    "subDistrictNameEn": "Sam Wa Tawan Tok",
    "postcode": "10510",
    "districtCode": "1046",
    "districtNameTh": "คลองสามวา",
    "districtNameEn": "Khlong Sam Wa",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "104602",
    "subDistrictNameTh": "สามวาตะวันออก",
    "subDistrictNameEn": "Sam Wa Tawan Ok",
    "postcode": "10510",
    "districtCode": "1046",
    "districtNameTh": "คลองสามวา",
    "districtNameEn": "Khlong Sam Wa",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "104603",
    "subDistrictNameTh": "บางชัน",
    "subDistrictNameEn": "Bang Chan",
    "postcode": "10510",
    "districtCode": "1046",
    "districtNameTh": "คลองสามวา",
    "districtNameEn": "Khlong Sam Wa",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "104604",
    "subDistrictNameTh": "ทรายกองดิน",
    "subDistrictNameEn": "Sai Kong Din",
    "postcode": "10510",
    "districtCode": "1046",
    "districtNameTh": "คลองสามวา",
    "districtNameEn": "Khlong Sam Wa",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "104605",
    "subDistrictNameTh": "ทรายกองดินใต้",
    "subDistrictNameEn": "Sai Kong Din Tai",
    "postcode": "10510",
    "districtCode": "1046",
    "districtNameTh": "คลองสามวา",
    "districtNameEn": "Khlong Sam Wa",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "104702",
    "subDistrictNameTh": "บางนาเหนือ",
    "subDistrictNameEn": "Bang Na Nuea",
    "postcode": "10260",
    "districtCode": "1047",
    "districtNameTh": "บางนา",
    "districtNameEn": "Bang Na",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "104703",
    "subDistrictNameTh": "บางนาใต้",
    "subDistrictNameEn": "Bang Na Tai",
    "postcode": "10260",
    "districtCode": "1047",
    "districtNameTh": "บางนา",
    "districtNameEn": "Bang Na",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "104801",
    "subDistrictNameTh": "ทวีวัฒนา",
    "subDistrictNameEn": "Thawi Watthana",
    "postcode": "10170",
    "districtCode": "1048",
    "districtNameTh": "ทวีวัฒนา",
    "districtNameEn": "Thawi Watthana",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "104802",
    "subDistrictNameTh": "ศาลาธรรมสพน์",
    "subDistrictNameEn": "Sala Thammasop",
    "postcode": "10170",
    "districtCode": "1048",
    "districtNameTh": "ทวีวัฒนา",
    "districtNameEn": "Thawi Watthana",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "104901",
    "subDistrictNameTh": "บางมด",
    "subDistrictNameEn": "Bang Mot",
    "postcode": "10140",
    "districtCode": "1049",
    "districtNameTh": "ทุ่งครุ",
    "districtNameEn": "Thung Khru",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "104902",
    "subDistrictNameTh": "ทุ่งครุ",
    "subDistrictNameEn": "Thung Khru",
    "postcode": "10140",
    "districtCode": "1049",
    "districtNameTh": "ทุ่งครุ",
    "districtNameEn": "Thung Khru",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "105002",
    "subDistrictNameTh": "บางบอนเหนือ",
    "subDistrictNameEn": "Bang Bon Nuea",
    "postcode": "10150",
    "districtCode": "1050",
    "districtNameTh": "บางบอน",
    "districtNameEn": "Bang Bon",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "105003",
    "subDistrictNameTh": "บางบอนใต้",
    "subDistrictNameEn": "Bang Bon Tai",
    "postcode": "10150",
    "districtCode": "1050",
    "districtNameTh": "บางบอน",
    "districtNameEn": "Bang Bon",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "105004",
    "subDistrictNameTh": "คลองบางพราน",
    "subDistrictNameEn": "Khlong Bang Phran",
    "postcode": "10150",
    "districtCode": "1050",
    "districtNameTh": "บางบอน",
    "districtNameEn": "Bang Bon",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "105005",
    "subDistrictNameTh": "คลองบางบอน",
    "subDistrictNameEn": "Khlong Bang Bon",
    "postcode": "10150",
    "districtCode": "1050",
    "districtNameTh": "บางบอน",
    "districtNameEn": "Bang Bon",
    "provinceCode": "10",
    "provinceNameTh": "กรุงเทพมหานคร",
    "provinceNameEn": "Bangkok"
},
{
    "subDistrictCode": "110101",
    "subDistrictNameTh": "ปากน้ำ",
    "subDistrictNameEn": "Pak Nam",
    "postcode": "10270",
    "districtCode": "1101",
    "districtNameTh": "เมืองสมุทรปราการ",
    "districtNameEn": "Mueang Samut Prakan",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110102",
    "subDistrictNameTh": "สำโรงเหนือ",
    "subDistrictNameEn": "Samrong Nuea",
    "postcode": "10270",
    "districtCode": "1101",
    "districtNameTh": "เมืองสมุทรปราการ",
    "districtNameEn": "Mueang Samut Prakan",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110103",
    "subDistrictNameTh": "บางเมือง",
    "subDistrictNameEn": "Bang Mueang",
    "postcode": "10270",
    "districtCode": "1101",
    "districtNameTh": "เมืองสมุทรปราการ",
    "districtNameEn": "Mueang Samut Prakan",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110104",
    "subDistrictNameTh": "ท้ายบ้าน",
    "subDistrictNameEn": "Thai Ban",
    "postcode": "10280",
    "districtCode": "1101",
    "districtNameTh": "เมืองสมุทรปราการ",
    "districtNameEn": "Mueang Samut Prakan",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110108",
    "subDistrictNameTh": "บางปูใหม่",
    "subDistrictNameEn": "Bang Pu Mai",
    "postcode": "10280",
    "districtCode": "1101",
    "districtNameTh": "เมืองสมุทรปราการ",
    "districtNameEn": "Mueang Samut Prakan",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110110",
    "subDistrictNameTh": "แพรกษา",
    "subDistrictNameEn": "Phraek Sa",
    "postcode": "10280",
    "districtCode": "1101",
    "districtNameTh": "เมืองสมุทรปราการ",
    "districtNameEn": "Mueang Samut Prakan",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110111",
    "subDistrictNameTh": "บางโปรง",
    "subDistrictNameEn": "Bang Prong",
    "postcode": "10270",
    "districtCode": "1101",
    "districtNameTh": "เมืองสมุทรปราการ",
    "districtNameEn": "Mueang Samut Prakan",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110112",
    "subDistrictNameTh": "บางปู",
    "subDistrictNameEn": "Bang Pu",
    "postcode": "10280",
    "districtCode": "1101",
    "districtNameTh": "เมืองสมุทรปราการ",
    "districtNameEn": "Mueang Samut Prakan",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110113",
    "subDistrictNameTh": "บางด้วน",
    "subDistrictNameEn": "Bang Duan",
    "postcode": "10270",
    "districtCode": "1101",
    "districtNameTh": "เมืองสมุทรปราการ",
    "districtNameEn": "Mueang Samut Prakan",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110114",
    "subDistrictNameTh": "บางเมืองใหม่",
    "subDistrictNameEn": "Bang Mueang Mai",
    "postcode": "10270",
    "districtCode": "1101",
    "districtNameTh": "เมืองสมุทรปราการ",
    "districtNameEn": "Mueang Samut Prakan",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110115",
    "subDistrictNameTh": "เทพารักษ์",
    "subDistrictNameEn": "Thepharak",
    "postcode": "10270",
    "districtCode": "1101",
    "districtNameTh": "เมืองสมุทรปราการ",
    "districtNameEn": "Mueang Samut Prakan",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110116",
    "subDistrictNameTh": "ท้ายบ้านใหม่",
    "subDistrictNameEn": "Thai Ban Mai",
    "postcode": "10280",
    "districtCode": "1101",
    "districtNameTh": "เมืองสมุทรปราการ",
    "districtNameEn": "Mueang Samut Prakan",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110117",
    "subDistrictNameTh": "แพรกษาใหม่",
    "subDistrictNameEn": "Phraek Sa Mai",
    "postcode": "10280",
    "districtCode": "1101",
    "districtNameTh": "เมืองสมุทรปราการ",
    "districtNameEn": "Mueang Samut Prakan",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110201",
    "subDistrictNameTh": "บางบ่อ",
    "subDistrictNameEn": "Bang Bo",
    "postcode": "10560",
    "districtCode": "1102",
    "districtNameTh": "บางบ่อ",
    "districtNameEn": "Bang Bo",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110202",
    "subDistrictNameTh": "บ้านระกาศ",
    "subDistrictNameEn": "Ban Rakat",
    "postcode": "10560",
    "districtCode": "1102",
    "districtNameTh": "บางบ่อ",
    "districtNameEn": "Bang Bo",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110203",
    "subDistrictNameTh": "บางพลีน้อย",
    "subDistrictNameEn": "Bang Phli Noi",
    "postcode": "10560",
    "districtCode": "1102",
    "districtNameTh": "บางบ่อ",
    "districtNameEn": "Bang Bo",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110204",
    "subDistrictNameTh": "บางเพรียง",
    "subDistrictNameEn": "Bang Phriang",
    "postcode": "10560",
    "districtCode": "1102",
    "districtNameTh": "บางบ่อ",
    "districtNameEn": "Bang Bo",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110205",
    "subDistrictNameTh": "คลองด่าน",
    "subDistrictNameEn": "Khlong Dan",
    "postcode": "10550",
    "districtCode": "1102",
    "districtNameTh": "บางบ่อ",
    "districtNameEn": "Bang Bo",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110206",
    "subDistrictNameTh": "คลองสวน",
    "subDistrictNameEn": "Khlong Suan",
    "postcode": "10560",
    "districtCode": "1102",
    "districtNameTh": "บางบ่อ",
    "districtNameEn": "Bang Bo",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110207",
    "subDistrictNameTh": "เปร็ง",
    "subDistrictNameEn": "Preng",
    "postcode": "10560",
    "districtCode": "1102",
    "districtNameTh": "บางบ่อ",
    "districtNameEn": "Bang Bo",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110208",
    "subDistrictNameTh": "คลองนิยมยาตรา",
    "subDistrictNameEn": "Khlong Niyom Yattra",
    "postcode": "10560",
    "districtCode": "1102",
    "districtNameTh": "บางบ่อ",
    "districtNameEn": "Bang Bo",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110301",
    "subDistrictNameTh": "บางพลีใหญ่",
    "subDistrictNameEn": "Blang Phli Yai",
    "postcode": "10540",
    "districtCode": "1103",
    "districtNameTh": "บางพลี",
    "districtNameEn": "Bang Phli",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110302",
    "subDistrictNameTh": "บางแก้ว",
    "subDistrictNameEn": "Bang Kaeo",
    "postcode": "10540",
    "districtCode": "1103",
    "districtNameTh": "บางพลี",
    "districtNameEn": "Bang Phli",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110303",
    "subDistrictNameTh": "บางปลา",
    "subDistrictNameEn": "Bang Pla",
    "postcode": "10540",
    "districtCode": "1103",
    "districtNameTh": "บางพลี",
    "districtNameEn": "Bang Phli",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110304",
    "subDistrictNameTh": "บางโฉลง",
    "subDistrictNameEn": "Bang Chalong",
    "postcode": "10540",
    "districtCode": "1103",
    "districtNameTh": "บางพลี",
    "districtNameEn": "Bang Phli",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110308",
    "subDistrictNameTh": "ราชาเทวะ",
    "subDistrictNameEn": "Racha Thewa",
    "postcode": "10540",
    "districtCode": "1103",
    "districtNameTh": "บางพลี",
    "districtNameEn": "Bang Phli",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110309",
    "subDistrictNameTh": "หนองปรือ",
    "subDistrictNameEn": "Nong Prue",
    "postcode": "10540",
    "districtCode": "1103",
    "districtNameTh": "บางพลี",
    "districtNameEn": "Bang Phli",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110401",
    "subDistrictNameTh": "ตลาด",
    "subDistrictNameEn": "Talat",
    "postcode": "10130",
    "districtCode": "1104",
    "districtNameTh": "พระประแดง",
    "districtNameEn": "Phra Pradaeng",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110402",
    "subDistrictNameTh": "บางพึ่ง",
    "subDistrictNameEn": "Bang Phueng",
    "postcode": "10130",
    "districtCode": "1104",
    "districtNameTh": "พระประแดง",
    "districtNameEn": "Phra Pradaeng",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110403",
    "subDistrictNameTh": "บางจาก",
    "subDistrictNameEn": "Bang Chak",
    "postcode": "10130",
    "districtCode": "1104",
    "districtNameTh": "พระประแดง",
    "districtNameEn": "Phra Pradaeng",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110404",
    "subDistrictNameTh": "บางครุ",
    "subDistrictNameEn": "Bang Khru",
    "postcode": "10130",
    "districtCode": "1104",
    "districtNameTh": "พระประแดง",
    "districtNameEn": "Phra Pradaeng",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110405",
    "subDistrictNameTh": "บางหญ้าแพรก",
    "subDistrictNameEn": "Bang Ya Phraek",
    "postcode": "10130",
    "districtCode": "1104",
    "districtNameTh": "พระประแดง",
    "districtNameEn": "Phra Pradaeng",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110406",
    "subDistrictNameTh": "บางหัวเสือ",
    "subDistrictNameEn": "Bang Hua Suea",
    "postcode": "10130",
    "districtCode": "1104",
    "districtNameTh": "พระประแดง",
    "districtNameEn": "Phra Pradaeng",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110407",
    "subDistrictNameTh": "สำโรงใต้",
    "subDistrictNameEn": "Samrong Tai",
    "postcode": "10130",
    "districtCode": "1104",
    "districtNameTh": "พระประแดง",
    "districtNameEn": "Phra Pradaeng",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110408",
    "subDistrictNameTh": "บางยอ",
    "subDistrictNameEn": "Bang Yo",
    "postcode": "10130",
    "districtCode": "1104",
    "districtNameTh": "พระประแดง",
    "districtNameEn": "Phra Pradaeng",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110409",
    "subDistrictNameTh": "บางกะเจ้า",
    "subDistrictNameEn": "Bang Kachao",
    "postcode": "10130",
    "districtCode": "1104",
    "districtNameTh": "พระประแดง",
    "districtNameEn": "Phra Pradaeng",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110410",
    "subDistrictNameTh": "บางน้ำผึ้ง",
    "subDistrictNameEn": "Bang Nam Phueng",
    "postcode": "10130",
    "districtCode": "1104",
    "districtNameTh": "พระประแดง",
    "districtNameEn": "Phra Pradaeng",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110411",
    "subDistrictNameTh": "บางกระสอบ",
    "subDistrictNameEn": "Bang Krasop",
    "postcode": "10130",
    "districtCode": "1104",
    "districtNameTh": "พระประแดง",
    "districtNameEn": "Phra Pradaeng",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110412",
    "subDistrictNameTh": "บางกอบัว",
    "subDistrictNameEn": "Bang Ko Bua",
    "postcode": "10130",
    "districtCode": "1104",
    "districtNameTh": "พระประแดง",
    "districtNameEn": "Phra Pradaeng",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110413",
    "subDistrictNameTh": "ทรงคนอง",
    "subDistrictNameEn": "Song Khanong",
    "postcode": "10130",
    "districtCode": "1104",
    "districtNameTh": "พระประแดง",
    "districtNameEn": "Phra Pradaeng",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110414",
    "subDistrictNameTh": "สำโรง",
    "subDistrictNameEn": "Samrong",
    "postcode": "10130",
    "districtCode": "1104",
    "districtNameTh": "พระประแดง",
    "districtNameEn": "Phra Pradaeng",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110415",
    "subDistrictNameTh": "สำโรงกลาง",
    "subDistrictNameEn": "Samrong Klang",
    "postcode": "10130",
    "districtCode": "1104",
    "districtNameTh": "พระประแดง",
    "districtNameEn": "Phra Pradaeng",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110501",
    "subDistrictNameTh": "นาเกลือ",
    "subDistrictNameEn": "Na Kluea",
    "postcode": "10290",
    "districtCode": "1105",
    "districtNameTh": "พระสมุทรเจดีย์",
    "districtNameEn": "Phra Samut Chedi",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110502",
    "subDistrictNameTh": "บ้านคลองสวน",
    "subDistrictNameEn": "Ban Khlong Suan",
    "postcode": "10290",
    "districtCode": "1105",
    "districtNameTh": "พระสมุทรเจดีย์",
    "districtNameEn": "Phra Samut Chedi",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110503",
    "subDistrictNameTh": "แหลมฟ้าผ่า",
    "subDistrictNameEn": "Laem Fa Pha",
    "postcode": "10290",
    "districtCode": "1105",
    "districtNameTh": "พระสมุทรเจดีย์",
    "districtNameEn": "Phra Samut Chedi",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110504",
    "subDistrictNameTh": "ปากคลองบางปลากด",
    "subDistrictNameEn": "Pak Khlong Bang Pla Kot",
    "postcode": "10290",
    "districtCode": "1105",
    "districtNameTh": "พระสมุทรเจดีย์",
    "districtNameEn": "Phra Samut Chedi",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110505",
    "subDistrictNameTh": "ในคลองบางปลากด",
    "subDistrictNameEn": "Nai Khlong Bang Pla Kot",
    "postcode": "10290",
    "districtCode": "1105",
    "districtNameTh": "พระสมุทรเจดีย์",
    "districtNameEn": "Phra Samut Chedi",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110601",
    "subDistrictNameTh": "บางเสาธง",
    "subDistrictNameEn": "Bang Sao Thong",
    "postcode": "10570",
    "districtCode": "1106",
    "districtNameTh": "บางเสาธง",
    "districtNameEn": "Bang Sao Thong",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110602",
    "subDistrictNameTh": "ศีรษะจรเข้น้อย",
    "subDistrictNameEn": "Sisa Chorakhe Noi",
    "postcode": "10570",
    "districtCode": "1106",
    "districtNameTh": "บางเสาธง",
    "districtNameEn": "Bang Sao Thong",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "110603",
    "subDistrictNameTh": "ศีรษะจรเข้ใหญ่",
    "subDistrictNameEn": "Sisa Chorakhe Yai",
    "postcode": "10570",
    "districtCode": "1106",
    "districtNameTh": "บางเสาธง",
    "districtNameEn": "Bang Sao Thong",
    "provinceCode": "11",
    "provinceNameTh": "สมุทรปราการ",
    "provinceNameEn": "Samut Prakarn"
},
{
    "subDistrictCode": "120101",
    "subDistrictNameTh": "สวนใหญ่",
    "subDistrictNameEn": "Suan Yai",
    "postcode": "11000",
    "districtCode": "1201",
    "districtNameTh": "เมืองนนทบุรี",
    "districtNameEn": "Mueang Nonthaburi",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120102",
    "subDistrictNameTh": "ตลาดขวัญ",
    "subDistrictNameEn": "Talat Khwan",
    "postcode": "11000",
    "districtCode": "1201",
    "districtNameTh": "เมืองนนทบุรี",
    "districtNameEn": "Mueang Nonthaburi",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120103",
    "subDistrictNameTh": "บางเขน",
    "subDistrictNameEn": "Bang Khen",
    "postcode": "11000",
    "districtCode": "1201",
    "districtNameTh": "เมืองนนทบุรี",
    "districtNameEn": "Mueang Nonthaburi",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120104",
    "subDistrictNameTh": "บางกระสอ",
    "subDistrictNameEn": "Bang Kraso",
    "postcode": "11000",
    "districtCode": "1201",
    "districtNameTh": "เมืองนนทบุรี",
    "districtNameEn": "Mueang Nonthaburi",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120105",
    "subDistrictNameTh": "ท่าทราย",
    "subDistrictNameEn": "Tha Sai",
    "postcode": "11000",
    "districtCode": "1201",
    "districtNameTh": "เมืองนนทบุรี",
    "districtNameEn": "Mueang Nonthaburi",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120106",
    "subDistrictNameTh": "บางไผ่",
    "subDistrictNameEn": "Bang Phai",
    "postcode": "11000",
    "districtCode": "1201",
    "districtNameTh": "เมืองนนทบุรี",
    "districtNameEn": "Mueang Nonthaburi",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120107",
    "subDistrictNameTh": "บางศรีเมือง",
    "subDistrictNameEn": "Bang Si Mueang",
    "postcode": "11000",
    "districtCode": "1201",
    "districtNameTh": "เมืองนนทบุรี",
    "districtNameEn": "Mueang Nonthaburi",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120108",
    "subDistrictNameTh": "บางกร่าง",
    "subDistrictNameEn": "Bang Krang",
    "postcode": "11000",
    "districtCode": "1201",
    "districtNameTh": "เมืองนนทบุรี",
    "districtNameEn": "Mueang Nonthaburi",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120109",
    "subDistrictNameTh": "ไทรม้า",
    "subDistrictNameEn": "Sai Ma",
    "postcode": "11000",
    "districtCode": "1201",
    "districtNameTh": "เมืองนนทบุรี",
    "districtNameEn": "Mueang Nonthaburi",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120110",
    "subDistrictNameTh": "บางรักน้อย",
    "subDistrictNameEn": "Bang Rak Noi",
    "postcode": "11000",
    "districtCode": "1201",
    "districtNameTh": "เมืองนนทบุรี",
    "districtNameEn": "Mueang Nonthaburi",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120201",
    "subDistrictNameTh": "วัดชลอ",
    "subDistrictNameEn": "Wat Chalo",
    "postcode": "11130",
    "districtCode": "1202",
    "districtNameTh": "บางกรวย",
    "districtNameEn": "Bang Kruai",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120202",
    "subDistrictNameTh": "บางกรวย",
    "subDistrictNameEn": "Bang Kruai",
    "postcode": "11130",
    "districtCode": "1202",
    "districtNameTh": "บางกรวย",
    "districtNameEn": "Bang Kruai",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120203",
    "subDistrictNameTh": "บางสีทอง",
    "subDistrictNameEn": "Bang Si Thong",
    "postcode": "11130",
    "districtCode": "1202",
    "districtNameTh": "บางกรวย",
    "districtNameEn": "Bang Kruai",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120204",
    "subDistrictNameTh": "บางขนุน",
    "subDistrictNameEn": "Bang Khanun",
    "postcode": "11130",
    "districtCode": "1202",
    "districtNameTh": "บางกรวย",
    "districtNameEn": "Bang Kruai",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120205",
    "subDistrictNameTh": "บางขุนกอง",
    "subDistrictNameEn": "Bang Khun Kong",
    "postcode": "11130",
    "districtCode": "1202",
    "districtNameTh": "บางกรวย",
    "districtNameEn": "Bang Kruai",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120206",
    "subDistrictNameTh": "บางคูเวียง",
    "subDistrictNameEn": "Bang Khu Wiang",
    "postcode": "11130",
    "districtCode": "1202",
    "districtNameTh": "บางกรวย",
    "districtNameEn": "Bang Kruai",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120207",
    "subDistrictNameTh": "มหาสวัสดิ์",
    "subDistrictNameEn": "Maha Sawat",
    "postcode": "11130",
    "districtCode": "1202",
    "districtNameTh": "บางกรวย",
    "districtNameEn": "Bang Kruai",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120208",
    "subDistrictNameTh": "ปลายบาง",
    "subDistrictNameEn": "Plai Bang",
    "postcode": "11130",
    "districtCode": "1202",
    "districtNameTh": "บางกรวย",
    "districtNameEn": "Bang Kruai",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120209",
    "subDistrictNameTh": "ศาลากลาง",
    "subDistrictNameEn": "Sala Klang",
    "postcode": "11130",
    "districtCode": "1202",
    "districtNameTh": "บางกรวย",
    "districtNameEn": "Bang Kruai",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120301",
    "subDistrictNameTh": "บางม่วง",
    "subDistrictNameEn": "Bang Muang",
    "postcode": "11140",
    "districtCode": "1203",
    "districtNameTh": "บางใหญ่",
    "districtNameEn": "Bang Yai",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120302",
    "subDistrictNameTh": "บางแม่นาง",
    "subDistrictNameEn": "Bang Mae Nang",
    "postcode": "11140",
    "districtCode": "1203",
    "districtNameTh": "บางใหญ่",
    "districtNameEn": "Bang Yai",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120303",
    "subDistrictNameTh": "บางเลน",
    "subDistrictNameEn": "Bang Len",
    "postcode": "11140",
    "districtCode": "1203",
    "districtNameTh": "บางใหญ่",
    "districtNameEn": "Bang Yai",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120304",
    "subDistrictNameTh": "เสาธงหิน",
    "subDistrictNameEn": "Sao Thong Hin",
    "postcode": "11140",
    "districtCode": "1203",
    "districtNameTh": "บางใหญ่",
    "districtNameEn": "Bang Yai",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120305",
    "subDistrictNameTh": "บางใหญ่",
    "subDistrictNameEn": "Bang Yai",
    "postcode": "11140",
    "districtCode": "1203",
    "districtNameTh": "บางใหญ่",
    "districtNameEn": "Bang Yai",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120306",
    "subDistrictNameTh": "บ้านใหม่",
    "subDistrictNameEn": "Ban Mai",
    "postcode": "11140",
    "districtCode": "1203",
    "districtNameTh": "บางใหญ่",
    "districtNameEn": "Bang Yai",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120401",
    "subDistrictNameTh": "โสนลอย",
    "subDistrictNameEn": "Sano Loi",
    "postcode": "11110",
    "districtCode": "1204",
    "districtNameTh": "บางบัวทอง",
    "districtNameEn": "Bang Bua Thong",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120402",
    "subDistrictNameTh": "บางบัวทอง",
    "subDistrictNameEn": "Bang Bua Thong",
    "postcode": "11110",
    "districtCode": "1204",
    "districtNameTh": "บางบัวทอง",
    "districtNameEn": "Bang Bua Thong",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120403",
    "subDistrictNameTh": "บางรักใหญ่",
    "subDistrictNameEn": "Bang Rak Yai",
    "postcode": "11110",
    "districtCode": "1204",
    "districtNameTh": "บางบัวทอง",
    "districtNameEn": "Bang Bua Thong",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120404",
    "subDistrictNameTh": "บางคูรัด",
    "subDistrictNameEn": "Bang Khu Rat",
    "postcode": "11110",
    "districtCode": "1204",
    "districtNameTh": "บางบัวทอง",
    "districtNameEn": "Bang Bua Thong",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120405",
    "subDistrictNameTh": "ละหาร",
    "subDistrictNameEn": "Lahan",
    "postcode": "11110",
    "districtCode": "1204",
    "districtNameTh": "บางบัวทอง",
    "districtNameEn": "Bang Bua Thong",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120406",
    "subDistrictNameTh": "ลำโพ",
    "subDistrictNameEn": "Lam Pho",
    "postcode": "11110",
    "districtCode": "1204",
    "districtNameTh": "บางบัวทอง",
    "districtNameEn": "Bang Bua Thong",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120407",
    "subDistrictNameTh": "พิมลราช",
    "subDistrictNameEn": "Phimon Rat",
    "postcode": "11110",
    "districtCode": "1204",
    "districtNameTh": "บางบัวทอง",
    "districtNameEn": "Bang Bua Thong",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120408",
    "subDistrictNameTh": "บางรักพัฒนา",
    "subDistrictNameEn": "Bang Rak Phatthana",
    "postcode": "11110",
    "districtCode": "1204",
    "districtNameTh": "บางบัวทอง",
    "districtNameEn": "Bang Bua Thong",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120501",
    "subDistrictNameTh": "ไทรน้อย",
    "subDistrictNameEn": "Sai Noi",
    "postcode": "11150",
    "districtCode": "1205",
    "districtNameTh": "ไทรน้อย",
    "districtNameEn": "Sai Noi",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120502",
    "subDistrictNameTh": "ราษฎร์นิยม",
    "subDistrictNameEn": "Rat Niyom",
    "postcode": "11150",
    "districtCode": "1205",
    "districtNameTh": "ไทรน้อย",
    "districtNameEn": "Sai Noi",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120503",
    "subDistrictNameTh": "หนองเพรางาย",
    "subDistrictNameEn": "Nong Phrao Ngai",
    "postcode": "11150",
    "districtCode": "1205",
    "districtNameTh": "ไทรน้อย",
    "districtNameEn": "Sai Noi",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120504",
    "subDistrictNameTh": "ไทรใหญ่",
    "subDistrictNameEn": "Sai Yai",
    "postcode": "11150",
    "districtCode": "1205",
    "districtNameTh": "ไทรน้อย",
    "districtNameEn": "Sai Noi",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120505",
    "subDistrictNameTh": "ขุนศรี",
    "subDistrictNameEn": "Khun Si",
    "postcode": "11150",
    "districtCode": "1205",
    "districtNameTh": "ไทรน้อย",
    "districtNameEn": "Sai Noi",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120506",
    "subDistrictNameTh": "คลองขวาง",
    "subDistrictNameEn": "Khlong Khwang",
    "postcode": "11150",
    "districtCode": "1205",
    "districtNameTh": "ไทรน้อย",
    "districtNameEn": "Sai Noi",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120507",
    "subDistrictNameTh": "ทวีวัฒนา",
    "subDistrictNameEn": "Thawi Watthana",
    "postcode": "11150",
    "districtCode": "1205",
    "districtNameTh": "ไทรน้อย",
    "districtNameEn": "Sai Noi",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120601",
    "subDistrictNameTh": "ปากเกร็ด",
    "subDistrictNameEn": "Pak Kret",
    "postcode": "11120",
    "districtCode": "1206",
    "districtNameTh": "ปากเกร็ด",
    "districtNameEn": "Pak Kret",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120602",
    "subDistrictNameTh": "บางตลาด",
    "subDistrictNameEn": "Bang Talat",
    "postcode": "11120",
    "districtCode": "1206",
    "districtNameTh": "ปากเกร็ด",
    "districtNameEn": "Pak Kret",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120603",
    "subDistrictNameTh": "บ้านใหม่",
    "subDistrictNameEn": "Ban Mai",
    "postcode": "11120",
    "districtCode": "1206",
    "districtNameTh": "ปากเกร็ด",
    "districtNameEn": "Pak Kret",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120604",
    "subDistrictNameTh": "บางพูด",
    "subDistrictNameEn": "Bang Phut",
    "postcode": "11120",
    "districtCode": "1206",
    "districtNameTh": "ปากเกร็ด",
    "districtNameEn": "Pak Kret",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120605",
    "subDistrictNameTh": "บางตะไนย์",
    "subDistrictNameEn": "Bang Tanai",
    "postcode": "11120",
    "districtCode": "1206",
    "districtNameTh": "ปากเกร็ด",
    "districtNameEn": "Pak Kret",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120606",
    "subDistrictNameTh": "คลองพระอุดม",
    "subDistrictNameEn": "Khlong Phra Udom",
    "postcode": "11120",
    "districtCode": "1206",
    "districtNameTh": "ปากเกร็ด",
    "districtNameEn": "Pak Kret",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120607",
    "subDistrictNameTh": "ท่าอิฐ",
    "subDistrictNameEn": "Tha It",
    "postcode": "11120",
    "districtCode": "1206",
    "districtNameTh": "ปากเกร็ด",
    "districtNameEn": "Pak Kret",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120608",
    "subDistrictNameTh": "เกาะเกร็ด",
    "subDistrictNameEn": "Ko Kret",
    "postcode": "11120",
    "districtCode": "1206",
    "districtNameTh": "ปากเกร็ด",
    "districtNameEn": "Pak Kret",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120609",
    "subDistrictNameTh": "อ้อมเกร็ด",
    "subDistrictNameEn": "Om Kret",
    "postcode": "11120",
    "districtCode": "1206",
    "districtNameTh": "ปากเกร็ด",
    "districtNameEn": "Pak Kret",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120610",
    "subDistrictNameTh": "คลองข่อย",
    "subDistrictNameEn": "Khlong Khoi",
    "postcode": "11120",
    "districtCode": "1206",
    "districtNameTh": "ปากเกร็ด",
    "districtNameEn": "Pak Kret",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120611",
    "subDistrictNameTh": "บางพลับ",
    "subDistrictNameEn": "Bang Phlap",
    "postcode": "11120",
    "districtCode": "1206",
    "districtNameTh": "ปากเกร็ด",
    "districtNameEn": "Pak Kret",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "120612",
    "subDistrictNameTh": "คลองเกลือ",
    "subDistrictNameEn": "Khlong Kluea",
    "postcode": "11120",
    "districtCode": "1206",
    "districtNameTh": "ปากเกร็ด",
    "districtNameEn": "Pak Kret",
    "provinceCode": "12",
    "provinceNameTh": "นนทบุรี",
    "provinceNameEn": "Nonthaburi"
},
{
    "subDistrictCode": "130101",
    "subDistrictNameTh": "บางปรอก",
    "subDistrictNameEn": "Bang Prok",
    "postcode": "12000",
    "districtCode": "1301",
    "districtNameTh": "เมืองปทุมธานี",
    "districtNameEn": "Mueang Pathum Thani",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130102",
    "subDistrictNameTh": "บ้านใหม่",
    "subDistrictNameEn": "Ban Mai",
    "postcode": "12000",
    "districtCode": "1301",
    "districtNameTh": "เมืองปทุมธานี",
    "districtNameEn": "Mueang Pathum Thani",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130103",
    "subDistrictNameTh": "บ้านกลาง",
    "subDistrictNameEn": "Ban Klang",
    "postcode": "12000",
    "districtCode": "1301",
    "districtNameTh": "เมืองปทุมธานี",
    "districtNameEn": "Mueang Pathum Thani",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130104",
    "subDistrictNameTh": "บ้านฉาง",
    "subDistrictNameEn": "Ban Chang",
    "postcode": "12000",
    "districtCode": "1301",
    "districtNameTh": "เมืองปทุมธานี",
    "districtNameEn": "Mueang Pathum Thani",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130105",
    "subDistrictNameTh": "บ้านกระแชง",
    "subDistrictNameEn": "Ban Krachaeng",
    "postcode": "12000",
    "districtCode": "1301",
    "districtNameTh": "เมืองปทุมธานี",
    "districtNameEn": "Mueang Pathum Thani",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130106",
    "subDistrictNameTh": "บางขะแยง",
    "subDistrictNameEn": "Bang Khayaeng",
    "postcode": "12000",
    "districtCode": "1301",
    "districtNameTh": "เมืองปทุมธานี",
    "districtNameEn": "Mueang Pathum Thani",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130107",
    "subDistrictNameTh": "บางคูวัด",
    "subDistrictNameEn": "Bang Khu Wat",
    "postcode": "12000",
    "districtCode": "1301",
    "districtNameTh": "เมืองปทุมธานี",
    "districtNameEn": "Mueang Pathum Thani",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130108",
    "subDistrictNameTh": "บางหลวง",
    "subDistrictNameEn": "Bang Luang",
    "postcode": "12000",
    "districtCode": "1301",
    "districtNameTh": "เมืองปทุมธานี",
    "districtNameEn": "Mueang Pathum Thani",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130109",
    "subDistrictNameTh": "บางเดื่อ",
    "subDistrictNameEn": "Bang Duea",
    "postcode": "12000",
    "districtCode": "1301",
    "districtNameTh": "เมืองปทุมธานี",
    "districtNameEn": "Mueang Pathum Thani",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130110",
    "subDistrictNameTh": "บางพูด",
    "subDistrictNameEn": "Bang Phut",
    "postcode": "12000",
    "districtCode": "1301",
    "districtNameTh": "เมืองปทุมธานี",
    "districtNameEn": "Mueang Pathum Thani",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130111",
    "subDistrictNameTh": "บางพูน",
    "subDistrictNameEn": "Bang Phun",
    "postcode": "12000",
    "districtCode": "1301",
    "districtNameTh": "เมืองปทุมธานี",
    "districtNameEn": "Mueang Pathum Thani",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130112",
    "subDistrictNameTh": "บางกะดี",
    "subDistrictNameEn": "Bang Kadi",
    "postcode": "12000",
    "districtCode": "1301",
    "districtNameTh": "เมืองปทุมธานี",
    "districtNameEn": "Mueang Pathum Thani",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130113",
    "subDistrictNameTh": "สวนพริกไทย",
    "subDistrictNameEn": "Suan Phrik Thai",
    "postcode": "12000",
    "districtCode": "1301",
    "districtNameTh": "เมืองปทุมธานี",
    "districtNameEn": "Mueang Pathum Thani",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130114",
    "subDistrictNameTh": "หลักหก",
    "subDistrictNameEn": "Lak Hok",
    "postcode": "12000",
    "districtCode": "1301",
    "districtNameTh": "เมืองปทุมธานี",
    "districtNameEn": "Mueang Pathum Thani",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130201",
    "subDistrictNameTh": "คลองหนึ่ง",
    "subDistrictNameEn": "Khlong Nueng",
    "postcode": "12120",
    "districtCode": "1302",
    "districtNameTh": "คลองหลวง",
    "districtNameEn": "Khlong Luang",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130202",
    "subDistrictNameTh": "คลองสอง",
    "subDistrictNameEn": "Khlong Song",
    "postcode": "12120",
    "districtCode": "1302",
    "districtNameTh": "คลองหลวง",
    "districtNameEn": "Khlong Luang",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130203",
    "subDistrictNameTh": "คลองสาม",
    "subDistrictNameEn": "Khlong Sam",
    "postcode": "12120",
    "districtCode": "1302",
    "districtNameTh": "คลองหลวง",
    "districtNameEn": "Khlong Luang",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130204",
    "subDistrictNameTh": "คลองสี่",
    "subDistrictNameEn": "Khlong Si",
    "postcode": "12120",
    "districtCode": "1302",
    "districtNameTh": "คลองหลวง",
    "districtNameEn": "Khlong Luang",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130205",
    "subDistrictNameTh": "คลองห้า",
    "subDistrictNameEn": "Khlong Ha",
    "postcode": "12120",
    "districtCode": "1302",
    "districtNameTh": "คลองหลวง",
    "districtNameEn": "Khlong Luang",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130206",
    "subDistrictNameTh": "คลองหก",
    "subDistrictNameEn": "Khlong Hok",
    "postcode": "12120",
    "districtCode": "1302",
    "districtNameTh": "คลองหลวง",
    "districtNameEn": "Khlong Luang",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130207",
    "subDistrictNameTh": "คลองเจ็ด",
    "subDistrictNameEn": "Khlong Chet",
    "postcode": "12120",
    "districtCode": "1302",
    "districtNameTh": "คลองหลวง",
    "districtNameEn": "Khlong Luang",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130301",
    "subDistrictNameTh": "ประชาธิปัตย์",
    "subDistrictNameEn": "Prachathipat",
    "postcode": "12130",
    "districtCode": "1303",
    "districtNameTh": "ธัญบุรี",
    "districtNameEn": "Thanyaburi",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130302",
    "subDistrictNameTh": "บึงยี่โถ",
    "subDistrictNameEn": "Bueng Yitho",
    "postcode": "12130",
    "districtCode": "1303",
    "districtNameTh": "ธัญบุรี",
    "districtNameEn": "Thanyaburi",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130303",
    "subDistrictNameTh": "รังสิต",
    "subDistrictNameEn": "Rangsit",
    "postcode": "12110",
    "districtCode": "1303",
    "districtNameTh": "ธัญบุรี",
    "districtNameEn": "Thanyaburi",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130304",
    "subDistrictNameTh": "ลำผักกูด",
    "subDistrictNameEn": "Lam Phak Kut",
    "postcode": "12110",
    "districtCode": "1303",
    "districtNameTh": "ธัญบุรี",
    "districtNameEn": "Thanyaburi",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130305",
    "subDistrictNameTh": "บึงสนั่น",
    "subDistrictNameEn": "Bueng Sanan",
    "postcode": "12110",
    "districtCode": "1303",
    "districtNameTh": "ธัญบุรี",
    "districtNameEn": "Thanyaburi",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130306",
    "subDistrictNameTh": "บึงน้ำรักษ์",
    "subDistrictNameEn": "Bueng Nam Rak",
    "postcode": "12110",
    "districtCode": "1303",
    "districtNameTh": "ธัญบุรี",
    "districtNameEn": "Thanyaburi",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130401",
    "subDistrictNameTh": "บึงบา",
    "subDistrictNameEn": "Bueng Ba",
    "postcode": "12170",
    "districtCode": "1304",
    "districtNameTh": "หนองเสือ",
    "districtNameEn": "Nong Suea",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130402",
    "subDistrictNameTh": "บึงบอน",
    "subDistrictNameEn": "Bueng Bon",
    "postcode": "12170",
    "districtCode": "1304",
    "districtNameTh": "หนองเสือ",
    "districtNameEn": "Nong Suea",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130403",
    "subDistrictNameTh": "บึงกาสาม",
    "subDistrictNameEn": "Bueng Ka Sam",
    "postcode": "12170",
    "districtCode": "1304",
    "districtNameTh": "หนองเสือ",
    "districtNameEn": "Nong Suea",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130404",
    "subDistrictNameTh": "บึงชำอ้อ",
    "subDistrictNameEn": "Bueng Cham O",
    "postcode": "12170",
    "districtCode": "1304",
    "districtNameTh": "หนองเสือ",
    "districtNameEn": "Nong Suea",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130405",
    "subDistrictNameTh": "หนองสามวัง",
    "subDistrictNameEn": "Nong Sam Wang",
    "postcode": "12170",
    "districtCode": "1304",
    "districtNameTh": "หนองเสือ",
    "districtNameEn": "Nong Suea",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130406",
    "subDistrictNameTh": "ศาลาครุ",
    "subDistrictNameEn": "Sala Khru",
    "postcode": "12170",
    "districtCode": "1304",
    "districtNameTh": "หนองเสือ",
    "districtNameEn": "Nong Suea",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130407",
    "subDistrictNameTh": "นพรัตน์",
    "subDistrictNameEn": "Noppharat",
    "postcode": "12170",
    "districtCode": "1304",
    "districtNameTh": "หนองเสือ",
    "districtNameEn": "Nong Suea",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130501",
    "subDistrictNameTh": "ระแหง",
    "subDistrictNameEn": "Rahaeng",
    "postcode": "12140",
    "districtCode": "1305",
    "districtNameTh": "ลาดหลุมแก้ว",
    "districtNameEn": "Lat Lum Kaeo",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130502",
    "subDistrictNameTh": "ลาดหลุมแก้ว",
    "subDistrictNameEn": "Lat Lum Kaeo",
    "postcode": "12140",
    "districtCode": "1305",
    "districtNameTh": "ลาดหลุมแก้ว",
    "districtNameEn": "Lat Lum Kaeo",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130503",
    "subDistrictNameTh": "คูบางหลวง",
    "subDistrictNameEn": "Khu Bang Luang",
    "postcode": "12140",
    "districtCode": "1305",
    "districtNameTh": "ลาดหลุมแก้ว",
    "districtNameEn": "Lat Lum Kaeo",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130504",
    "subDistrictNameTh": "คูขวาง",
    "subDistrictNameEn": "Khu Khwang",
    "postcode": "12140",
    "districtCode": "1305",
    "districtNameTh": "ลาดหลุมแก้ว",
    "districtNameEn": "Lat Lum Kaeo",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130505",
    "subDistrictNameTh": "คลองพระอุดม",
    "subDistrictNameEn": "Khlong Phra Udom",
    "postcode": "12140",
    "districtCode": "1305",
    "districtNameTh": "ลาดหลุมแก้ว",
    "districtNameEn": "Lat Lum Kaeo",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130506",
    "subDistrictNameTh": "บ่อเงิน",
    "subDistrictNameEn": "Bo Ngoen",
    "postcode": "12140",
    "districtCode": "1305",
    "districtNameTh": "ลาดหลุมแก้ว",
    "districtNameEn": "Lat Lum Kaeo",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130507",
    "subDistrictNameTh": "หน้าไม้",
    "subDistrictNameEn": "Na Mai",
    "postcode": "12140",
    "districtCode": "1305",
    "districtNameTh": "ลาดหลุมแก้ว",
    "districtNameEn": "Lat Lum Kaeo",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130601",
    "subDistrictNameTh": "คูคต",
    "subDistrictNameEn": "Khu Khot",
    "postcode": "12130",
    "districtCode": "1306",
    "districtNameTh": "ลำลูกกา",
    "districtNameEn": "Lam Luk Ka",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130602",
    "subDistrictNameTh": "ลาดสวาย",
    "subDistrictNameEn": "Lat Sawai",
    "postcode": "12150",
    "districtCode": "1306",
    "districtNameTh": "ลำลูกกา",
    "districtNameEn": "Lam Luk Ka",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130603",
    "subDistrictNameTh": "บึงคำพร้อย",
    "subDistrictNameEn": "Bueng Kham Phroi",
    "postcode": "12150",
    "districtCode": "1306",
    "districtNameTh": "ลำลูกกา",
    "districtNameEn": "Lam Luk Ka",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130604",
    "subDistrictNameTh": "ลำลูกกา",
    "subDistrictNameEn": "Lum Luk Ka",
    "postcode": "12150",
    "districtCode": "1306",
    "districtNameTh": "ลำลูกกา",
    "districtNameEn": "Lam Luk Ka",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130605",
    "subDistrictNameTh": "บึงทองหลาง",
    "subDistrictNameEn": "Bueng Thonglang",
    "postcode": "12150",
    "districtCode": "1306",
    "districtNameTh": "ลำลูกกา",
    "districtNameEn": "Lam Luk Ka",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130606",
    "subDistrictNameTh": "ลำไทร",
    "subDistrictNameEn": "Lam Sai",
    "postcode": "12150",
    "districtCode": "1306",
    "districtNameTh": "ลำลูกกา",
    "districtNameEn": "Lam Luk Ka",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130607",
    "subDistrictNameTh": "บึงคอไห",
    "subDistrictNameEn": "Bueng Kho Hai",
    "postcode": "12150",
    "districtCode": "1306",
    "districtNameTh": "ลำลูกกา",
    "districtNameEn": "Lam Luk Ka",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130608",
    "subDistrictNameTh": "พืชอุดม",
    "subDistrictNameEn": "Phuet Udom",
    "postcode": "12150",
    "districtCode": "1306",
    "districtNameTh": "ลำลูกกา",
    "districtNameEn": "Lam Luk Ka",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130701",
    "subDistrictNameTh": "บางเตย",
    "subDistrictNameEn": "Bang Toei",
    "postcode": "12160",
    "districtCode": "1307",
    "districtNameTh": "สามโคก",
    "districtNameEn": "Sam Khok",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130702",
    "subDistrictNameTh": "คลองควาย",
    "subDistrictNameEn": "Khlong Khwai",
    "postcode": "12160",
    "districtCode": "1307",
    "districtNameTh": "สามโคก",
    "districtNameEn": "Sam Khok",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130703",
    "subDistrictNameTh": "สามโคก",
    "subDistrictNameEn": "Sam Khok",
    "postcode": "12160",
    "districtCode": "1307",
    "districtNameTh": "สามโคก",
    "districtNameEn": "Sam Khok",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130704",
    "subDistrictNameTh": "กระแชง",
    "subDistrictNameEn": "Krachaeng",
    "postcode": "12160",
    "districtCode": "1307",
    "districtNameTh": "สามโคก",
    "districtNameEn": "Sam Khok",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130705",
    "subDistrictNameTh": "บางโพธิ์เหนือ",
    "subDistrictNameEn": "Bang Pho Nuea",
    "postcode": "12160",
    "districtCode": "1307",
    "districtNameTh": "สามโคก",
    "districtNameEn": "Sam Khok",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130706",
    "subDistrictNameTh": "เชียงรากใหญ่",
    "subDistrictNameEn": "Chiang Rak Yai",
    "postcode": "12160",
    "districtCode": "1307",
    "districtNameTh": "สามโคก",
    "districtNameEn": "Sam Khok",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130707",
    "subDistrictNameTh": "บ้านปทุม",
    "subDistrictNameEn": "Ban Pathum",
    "postcode": "12160",
    "districtCode": "1307",
    "districtNameTh": "สามโคก",
    "districtNameEn": "Sam Khok",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130708",
    "subDistrictNameTh": "บ้านงิ้ว",
    "subDistrictNameEn": "Ban Ngio",
    "postcode": "12160",
    "districtCode": "1307",
    "districtNameTh": "สามโคก",
    "districtNameEn": "Sam Khok",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130709",
    "subDistrictNameTh": "เชียงรากน้อย",
    "subDistrictNameEn": "Chiang Rak Noi",
    "postcode": "12160",
    "districtCode": "1307",
    "districtNameTh": "สามโคก",
    "districtNameEn": "Sam Khok",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130710",
    "subDistrictNameTh": "บางกระบือ",
    "subDistrictNameEn": "Bang Krabue",
    "postcode": "12160",
    "districtCode": "1307",
    "districtNameTh": "สามโคก",
    "districtNameEn": "Sam Khok",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "130711",
    "subDistrictNameTh": "ท้ายเกาะ",
    "subDistrictNameEn": "Thai Ko",
    "postcode": "12160",
    "districtCode": "1307",
    "districtNameTh": "สามโคก",
    "districtNameEn": "Sam Khok",
    "provinceCode": "13",
    "provinceNameTh": "ปทุมธานี",
    "provinceNameEn": "Pathum Thani"
},
{
    "subDistrictCode": "140101",
    "subDistrictNameTh": "ประตูชัย",
    "subDistrictNameEn": "Pratu Chai",
    "postcode": "13000",
    "districtCode": "1401",
    "districtNameTh": "พระนครศรีอยุธยา",
    "districtNameEn": "Phra Nakhon Si Ayutthaya",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140102",
    "subDistrictNameTh": "กะมัง",
    "subDistrictNameEn": "Kamang",
    "postcode": "13000",
    "districtCode": "1401",
    "districtNameTh": "พระนครศรีอยุธยา",
    "districtNameEn": "Phra Nakhon Si Ayutthaya",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140103",
    "subDistrictNameTh": "หอรัตนไชย",
    "subDistrictNameEn": "Ho Rattanachai",
    "postcode": "13000",
    "districtCode": "1401",
    "districtNameTh": "พระนครศรีอยุธยา",
    "districtNameEn": "Phra Nakhon Si Ayutthaya",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140104",
    "subDistrictNameTh": "หัวรอ",
    "subDistrictNameEn": "Hua Ro",
    "postcode": "13000",
    "districtCode": "1401",
    "districtNameTh": "พระนครศรีอยุธยา",
    "districtNameEn": "Phra Nakhon Si Ayutthaya",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140105",
    "subDistrictNameTh": "ท่าวาสุกรี",
    "subDistrictNameEn": "Tha Wasukri",
    "postcode": "13000",
    "districtCode": "1401",
    "districtNameTh": "พระนครศรีอยุธยา",
    "districtNameEn": "Phra Nakhon Si Ayutthaya",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140106",
    "subDistrictNameTh": "ไผ่ลิง",
    "subDistrictNameEn": "Phai Ling",
    "postcode": "13000",
    "districtCode": "1401",
    "districtNameTh": "พระนครศรีอยุธยา",
    "districtNameEn": "Phra Nakhon Si Ayutthaya",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140107",
    "subDistrictNameTh": "ปากกราน",
    "subDistrictNameEn": "Pak Kran",
    "postcode": "13000",
    "districtCode": "1401",
    "districtNameTh": "พระนครศรีอยุธยา",
    "districtNameEn": "Phra Nakhon Si Ayutthaya",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140108",
    "subDistrictNameTh": "ภูเขาทอง",
    "subDistrictNameEn": "Phukhao Thong",
    "postcode": "13000",
    "districtCode": "1401",
    "districtNameTh": "พระนครศรีอยุธยา",
    "districtNameEn": "Phra Nakhon Si Ayutthaya",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140109",
    "subDistrictNameTh": "สำเภาล่ม",
    "subDistrictNameEn": "Samphao Lom",
    "postcode": "13000",
    "districtCode": "1401",
    "districtNameTh": "พระนครศรีอยุธยา",
    "districtNameEn": "Phra Nakhon Si Ayutthaya",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140110",
    "subDistrictNameTh": "สวนพริก",
    "subDistrictNameEn": "Suan Phrik",
    "postcode": "13000",
    "districtCode": "1401",
    "districtNameTh": "พระนครศรีอยุธยา",
    "districtNameEn": "Phra Nakhon Si Ayutthaya",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140111",
    "subDistrictNameTh": "คลองตะเคียน",
    "subDistrictNameEn": "Khlong Takhian",
    "postcode": "13000",
    "districtCode": "1401",
    "districtNameTh": "พระนครศรีอยุธยา",
    "districtNameEn": "Phra Nakhon Si Ayutthaya",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140112",
    "subDistrictNameTh": "วัดตูม",
    "subDistrictNameEn": "Wat Tum",
    "postcode": "13000",
    "districtCode": "1401",
    "districtNameTh": "พระนครศรีอยุธยา",
    "districtNameEn": "Phra Nakhon Si Ayutthaya",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140113",
    "subDistrictNameTh": "หันตรา",
    "subDistrictNameEn": "Hantra",
    "postcode": "13000",
    "districtCode": "1401",
    "districtNameTh": "พระนครศรีอยุธยา",
    "districtNameEn": "Phra Nakhon Si Ayutthaya",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140114",
    "subDistrictNameTh": "ลุมพลี",
    "subDistrictNameEn": "Lumphli",
    "postcode": "13000",
    "districtCode": "1401",
    "districtNameTh": "พระนครศรีอยุธยา",
    "districtNameEn": "Phra Nakhon Si Ayutthaya",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140115",
    "subDistrictNameTh": "บ้านใหม่",
    "subDistrictNameEn": "Ban Mai",
    "postcode": "13000",
    "districtCode": "1401",
    "districtNameTh": "พระนครศรีอยุธยา",
    "districtNameEn": "Phra Nakhon Si Ayutthaya",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140116",
    "subDistrictNameTh": "บ้านเกาะ",
    "subDistrictNameEn": "Ban Ko",
    "postcode": "13000",
    "districtCode": "1401",
    "districtNameTh": "พระนครศรีอยุธยา",
    "districtNameEn": "Phra Nakhon Si Ayutthaya",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140117",
    "subDistrictNameTh": "คลองสวนพลู",
    "subDistrictNameEn": "Khlong Suan Phlu",
    "postcode": "13000",
    "districtCode": "1401",
    "districtNameTh": "พระนครศรีอยุธยา",
    "districtNameEn": "Phra Nakhon Si Ayutthaya",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140118",
    "subDistrictNameTh": "คลองสระบัว",
    "subDistrictNameEn": "Khlong Sa Bua",
    "postcode": "13000",
    "districtCode": "1401",
    "districtNameTh": "พระนครศรีอยุธยา",
    "districtNameEn": "Phra Nakhon Si Ayutthaya",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140119",
    "subDistrictNameTh": "เกาะเรียน",
    "subDistrictNameEn": "Ko Rian",
    "postcode": "13000",
    "districtCode": "1401",
    "districtNameTh": "พระนครศรีอยุธยา",
    "districtNameEn": "Phra Nakhon Si Ayutthaya",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140120",
    "subDistrictNameTh": "บ้านป้อม",
    "subDistrictNameEn": "Ban Pom",
    "postcode": "13000",
    "districtCode": "1401",
    "districtNameTh": "พระนครศรีอยุธยา",
    "districtNameEn": "Phra Nakhon Si Ayutthaya",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140121",
    "subDistrictNameTh": "บ้านรุน",
    "subDistrictNameEn": "Ban Run",
    "postcode": "13000",
    "districtCode": "1401",
    "districtNameTh": "พระนครศรีอยุธยา",
    "districtNameEn": "Phra Nakhon Si Ayutthaya",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140201",
    "subDistrictNameTh": "ท่าเรือ",
    "subDistrictNameEn": "Tha Ruea",
    "postcode": "13130",
    "districtCode": "1402",
    "districtNameTh": "ท่าเรือ",
    "districtNameEn": "Tha Ruea",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140202",
    "subDistrictNameTh": "จำปา",
    "subDistrictNameEn": "Champa",
    "postcode": "13130",
    "districtCode": "1402",
    "districtNameTh": "ท่าเรือ",
    "districtNameEn": "Tha Ruea",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140203",
    "subDistrictNameTh": "ท่าหลวง",
    "subDistrictNameEn": "Tha Luang",
    "postcode": "18270",
    "districtCode": "1402",
    "districtNameTh": "ท่าเรือ",
    "districtNameEn": "Tha Ruea",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140204",
    "subDistrictNameTh": "บ้านร่อม",
    "subDistrictNameEn": "Ban Rom",
    "postcode": "13130",
    "districtCode": "1402",
    "districtNameTh": "ท่าเรือ",
    "districtNameEn": "Tha Ruea",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140205",
    "subDistrictNameTh": "ศาลาลอย",
    "subDistrictNameEn": "Sala Loi",
    "postcode": "13130",
    "districtCode": "1402",
    "districtNameTh": "ท่าเรือ",
    "districtNameEn": "Tha Ruea",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140206",
    "subDistrictNameTh": "วังแดง",
    "subDistrictNameEn": "Wang Daeng",
    "postcode": "13130",
    "districtCode": "1402",
    "districtNameTh": "ท่าเรือ",
    "districtNameEn": "Tha Ruea",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140207",
    "subDistrictNameTh": "โพธิ์เอน",
    "subDistrictNameEn": "Pho En",
    "postcode": "13130",
    "districtCode": "1402",
    "districtNameTh": "ท่าเรือ",
    "districtNameEn": "Tha Ruea",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140208",
    "subDistrictNameTh": "ปากท่า",
    "subDistrictNameEn": "Pak Tha",
    "postcode": "13130",
    "districtCode": "1402",
    "districtNameTh": "ท่าเรือ",
    "districtNameEn": "Tha Ruea",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140209",
    "subDistrictNameTh": "หนองขนาก",
    "subDistrictNameEn": "Nong Khanak",
    "postcode": "13130",
    "districtCode": "1402",
    "districtNameTh": "ท่าเรือ",
    "districtNameEn": "Tha Ruea",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140210",
    "subDistrictNameTh": "ท่าเจ้าสนุก",
    "subDistrictNameEn": "Tha Chao Sanuk",
    "postcode": "13130",
    "districtCode": "1402",
    "districtNameTh": "ท่าเรือ",
    "districtNameEn": "Tha Ruea",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140301",
    "subDistrictNameTh": "นครหลวง",
    "subDistrictNameEn": "Nakhon Luang",
    "postcode": "13260",
    "districtCode": "1403",
    "districtNameTh": "นครหลวง",
    "districtNameEn": "Nakhon Luang",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140302",
    "subDistrictNameTh": "ท่าช้าง",
    "subDistrictNameEn": "Tha Chang",
    "postcode": "13260",
    "districtCode": "1403",
    "districtNameTh": "นครหลวง",
    "districtNameEn": "Nakhon Luang",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140303",
    "subDistrictNameTh": "บ่อโพง",
    "subDistrictNameEn": "Bo Phong",
    "postcode": "13260",
    "districtCode": "1403",
    "districtNameTh": "นครหลวง",
    "districtNameEn": "Nakhon Luang",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140304",
    "subDistrictNameTh": "บ้านชุ้ง",
    "subDistrictNameEn": "Ban Chung",
    "postcode": "13260",
    "districtCode": "1403",
    "districtNameTh": "นครหลวง",
    "districtNameEn": "Nakhon Luang",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140305",
    "subDistrictNameTh": "ปากจั่น",
    "subDistrictNameEn": "Pak Chan",
    "postcode": "13260",
    "districtCode": "1403",
    "districtNameTh": "นครหลวง",
    "districtNameEn": "Nakhon Luang",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140306",
    "subDistrictNameTh": "บางระกำ",
    "subDistrictNameEn": "Bang Rakam",
    "postcode": "13260",
    "districtCode": "1403",
    "districtNameTh": "นครหลวง",
    "districtNameEn": "Nakhon Luang",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140307",
    "subDistrictNameTh": "บางพระครู",
    "subDistrictNameEn": "Bang Phra Khru",
    "postcode": "13260",
    "districtCode": "1403",
    "districtNameTh": "นครหลวง",
    "districtNameEn": "Nakhon Luang",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140308",
    "subDistrictNameTh": "แม่ลา",
    "subDistrictNameEn": "Mae La",
    "postcode": "13260",
    "districtCode": "1403",
    "districtNameTh": "นครหลวง",
    "districtNameEn": "Nakhon Luang",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140309",
    "subDistrictNameTh": "หนองปลิง",
    "subDistrictNameEn": "Nong Pling",
    "postcode": "13260",
    "districtCode": "1403",
    "districtNameTh": "นครหลวง",
    "districtNameEn": "Nakhon Luang",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140310",
    "subDistrictNameTh": "คลองสะแก",
    "subDistrictNameEn": "Khlong Sakae",
    "postcode": "13260",
    "districtCode": "1403",
    "districtNameTh": "นครหลวง",
    "districtNameEn": "Nakhon Luang",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140311",
    "subDistrictNameTh": "สามไถ",
    "subDistrictNameEn": "Sam Thai",
    "postcode": "13260",
    "districtCode": "1403",
    "districtNameTh": "นครหลวง",
    "districtNameEn": "Nakhon Luang",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140312",
    "subDistrictNameTh": "พระนอน",
    "subDistrictNameEn": "Phra Non",
    "postcode": "13260",
    "districtCode": "1403",
    "districtNameTh": "นครหลวง",
    "districtNameEn": "Nakhon Luang",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140401",
    "subDistrictNameTh": "บางไทร",
    "subDistrictNameEn": "Bang Sai",
    "postcode": "13190",
    "districtCode": "1404",
    "districtNameTh": "บางไทร",
    "districtNameEn": "Bang Sai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140402",
    "subDistrictNameTh": "บางพลี",
    "subDistrictNameEn": "Bang Phli",
    "postcode": "13190",
    "districtCode": "1404",
    "districtNameTh": "บางไทร",
    "districtNameEn": "Bang Sai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140403",
    "subDistrictNameTh": "สนามชัย",
    "subDistrictNameEn": "Sanam Chai",
    "postcode": "13190",
    "districtCode": "1404",
    "districtNameTh": "บางไทร",
    "districtNameEn": "Bang Sai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140404",
    "subDistrictNameTh": "บ้านแป้ง",
    "subDistrictNameEn": "Ban Paeng",
    "postcode": "13190",
    "districtCode": "1404",
    "districtNameTh": "บางไทร",
    "districtNameEn": "Bang Sai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140405",
    "subDistrictNameTh": "หน้าไม้",
    "subDistrictNameEn": "Na Mai",
    "postcode": "13190",
    "districtCode": "1404",
    "districtNameTh": "บางไทร",
    "districtNameEn": "Bang Sai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140406",
    "subDistrictNameTh": "บางยี่โท",
    "subDistrictNameEn": "Bang Yi Tho",
    "postcode": "13190",
    "districtCode": "1404",
    "districtNameTh": "บางไทร",
    "districtNameEn": "Bang Sai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140407",
    "subDistrictNameTh": "แคออก",
    "subDistrictNameEn": "Khae Ok",
    "postcode": "13190",
    "districtCode": "1404",
    "districtNameTh": "บางไทร",
    "districtNameEn": "Bang Sai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140408",
    "subDistrictNameTh": "แคตก",
    "subDistrictNameEn": "Khae Tok",
    "postcode": "13190",
    "districtCode": "1404",
    "districtNameTh": "บางไทร",
    "districtNameEn": "Bang Sai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140409",
    "subDistrictNameTh": "ช่างเหล็ก",
    "subDistrictNameEn": "Chang Lek",
    "postcode": "13190",
    "districtCode": "1404",
    "districtNameTh": "บางไทร",
    "districtNameEn": "Bang Sai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140410",
    "subDistrictNameTh": "กระแชง",
    "subDistrictNameEn": "Krachaeng",
    "postcode": "13190",
    "districtCode": "1404",
    "districtNameTh": "บางไทร",
    "districtNameEn": "Bang Sai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140411",
    "subDistrictNameTh": "บ้านกลึง",
    "subDistrictNameEn": "Ban Klueng",
    "postcode": "13190",
    "districtCode": "1404",
    "districtNameTh": "บางไทร",
    "districtNameEn": "Bang Sai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140412",
    "subDistrictNameTh": "ช้างน้อย",
    "subDistrictNameEn": "Chang Noi",
    "postcode": "13190",
    "districtCode": "1404",
    "districtNameTh": "บางไทร",
    "districtNameEn": "Bang Sai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140413",
    "subDistrictNameTh": "ห่อหมก",
    "subDistrictNameEn": "Homok",
    "postcode": "13190",
    "districtCode": "1404",
    "districtNameTh": "บางไทร",
    "districtNameEn": "Bang Sai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140414",
    "subDistrictNameTh": "ไผ่พระ",
    "subDistrictNameEn": "Phai Phra",
    "postcode": "13190",
    "districtCode": "1404",
    "districtNameTh": "บางไทร",
    "districtNameEn": "Bang Sai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140415",
    "subDistrictNameTh": "กกแก้วบูรพา",
    "subDistrictNameEn": "Kok Kaeo Burapha",
    "postcode": "13190",
    "districtCode": "1404",
    "districtNameTh": "บางไทร",
    "districtNameEn": "Bang Sai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140416",
    "subDistrictNameTh": "ไม้ตรา",
    "subDistrictNameEn": "Mai Tra",
    "postcode": "13190",
    "districtCode": "1404",
    "districtNameTh": "บางไทร",
    "districtNameEn": "Bang Sai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140417",
    "subDistrictNameTh": "บ้านม้า",
    "subDistrictNameEn": "Ban Ma",
    "postcode": "13190",
    "districtCode": "1404",
    "districtNameTh": "บางไทร",
    "districtNameEn": "Bang Sai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140418",
    "subDistrictNameTh": "บ้านเกาะ",
    "subDistrictNameEn": "Ban Ko",
    "postcode": "13190",
    "districtCode": "1404",
    "districtNameTh": "บางไทร",
    "districtNameEn": "Bang Sai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140419",
    "subDistrictNameTh": "ราชคราม",
    "subDistrictNameEn": "Ratchakhram",
    "postcode": "13290",
    "districtCode": "1404",
    "districtNameTh": "บางไทร",
    "districtNameEn": "Bang Sai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140420",
    "subDistrictNameTh": "ช้างใหญ่",
    "subDistrictNameEn": "Chang Yai",
    "postcode": "13290",
    "districtCode": "1404",
    "districtNameTh": "บางไทร",
    "districtNameEn": "Bang Sai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140421",
    "subDistrictNameTh": "โพแตง",
    "subDistrictNameEn": "Pho Taeng",
    "postcode": "13290",
    "districtCode": "1404",
    "districtNameTh": "บางไทร",
    "districtNameEn": "Bang Sai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140422",
    "subDistrictNameTh": "เชียงรากน้อย",
    "subDistrictNameEn": "Chiang Rak Noi",
    "postcode": "13290",
    "districtCode": "1404",
    "districtNameTh": "บางไทร",
    "districtNameEn": "Bang Sai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140423",
    "subDistrictNameTh": "โคกช้าง",
    "subDistrictNameEn": "Khok Chang",
    "postcode": "13190",
    "districtCode": "1404",
    "districtNameTh": "บางไทร",
    "districtNameEn": "Bang Sai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140501",
    "subDistrictNameTh": "บางบาล",
    "subDistrictNameEn": "Bang Ban",
    "postcode": "13250",
    "districtCode": "1405",
    "districtNameTh": "บางบาล",
    "districtNameEn": "Bang Ban",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140502",
    "subDistrictNameTh": "วัดยม",
    "subDistrictNameEn": "Wat Yom",
    "postcode": "13250",
    "districtCode": "1405",
    "districtNameTh": "บางบาล",
    "districtNameEn": "Bang Ban",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140503",
    "subDistrictNameTh": "ไทรน้อย",
    "subDistrictNameEn": "Sai Noi",
    "postcode": "13250",
    "districtCode": "1405",
    "districtNameTh": "บางบาล",
    "districtNameEn": "Bang Ban",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140504",
    "subDistrictNameTh": "สะพานไทย",
    "subDistrictNameEn": "Saphan Thai",
    "postcode": "13250",
    "districtCode": "1405",
    "districtNameTh": "บางบาล",
    "districtNameEn": "Bang Ban",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140505",
    "subDistrictNameTh": "มหาพราหมณ์",
    "subDistrictNameEn": "Maha Phram",
    "postcode": "13250",
    "districtCode": "1405",
    "districtNameTh": "บางบาล",
    "districtNameEn": "Bang Ban",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140506",
    "subDistrictNameTh": "กบเจา",
    "subDistrictNameEn": "Kop Chao",
    "postcode": "13250",
    "districtCode": "1405",
    "districtNameTh": "บางบาล",
    "districtNameEn": "Bang Ban",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140507",
    "subDistrictNameTh": "บ้านคลัง",
    "subDistrictNameEn": "Ban Khlang",
    "postcode": "13250",
    "districtCode": "1405",
    "districtNameTh": "บางบาล",
    "districtNameEn": "Bang Ban",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140508",
    "subDistrictNameTh": "พระขาว",
    "subDistrictNameEn": "Phra Khao",
    "postcode": "13250",
    "districtCode": "1405",
    "districtNameTh": "บางบาล",
    "districtNameEn": "Bang Ban",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140509",
    "subDistrictNameTh": "น้ำเต้า",
    "subDistrictNameEn": "Nam Tao",
    "postcode": "13250",
    "districtCode": "1405",
    "districtNameTh": "บางบาล",
    "districtNameEn": "Bang Ban",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140510",
    "subDistrictNameTh": "ทางช้าง",
    "subDistrictNameEn": "Thang Chang",
    "postcode": "13250",
    "districtCode": "1405",
    "districtNameTh": "บางบาล",
    "districtNameEn": "Bang Ban",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140511",
    "subDistrictNameTh": "วัดตะกู",
    "subDistrictNameEn": "Wat Taku",
    "postcode": "13250",
    "districtCode": "1405",
    "districtNameTh": "บางบาล",
    "districtNameEn": "Bang Ban",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140512",
    "subDistrictNameTh": "บางหลวง",
    "subDistrictNameEn": "Bang Luang",
    "postcode": "13250",
    "districtCode": "1405",
    "districtNameTh": "บางบาล",
    "districtNameEn": "Bang Ban",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140513",
    "subDistrictNameTh": "บางหลวงโดด",
    "subDistrictNameEn": "Bang Luang Dot",
    "postcode": "13250",
    "districtCode": "1405",
    "districtNameTh": "บางบาล",
    "districtNameEn": "Bang Ban",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140514",
    "subDistrictNameTh": "บางหัก",
    "subDistrictNameEn": "Bang Hak",
    "postcode": "13250",
    "districtCode": "1405",
    "districtNameTh": "บางบาล",
    "districtNameEn": "Bang Ban",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140515",
    "subDistrictNameTh": "บางชะนี",
    "subDistrictNameEn": "Bang Chani",
    "postcode": "13250",
    "districtCode": "1405",
    "districtNameTh": "บางบาล",
    "districtNameEn": "Bang Ban",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140516",
    "subDistrictNameTh": "บ้านกุ่ม",
    "subDistrictNameEn": "Ban Kum",
    "postcode": "13250",
    "districtCode": "1405",
    "districtNameTh": "บางบาล",
    "districtNameEn": "Bang Ban",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140601",
    "subDistrictNameTh": "บ้านเลน",
    "subDistrictNameEn": "Ban Len",
    "postcode": "13160",
    "districtCode": "1406",
    "districtNameTh": "บางปะอิน",
    "districtNameEn": "Bang Pa-in",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140602",
    "subDistrictNameTh": "เชียงรากน้อย",
    "subDistrictNameEn": "Chiang Rak Noi",
    "postcode": "13180",
    "districtCode": "1406",
    "districtNameTh": "บางปะอิน",
    "districtNameEn": "Bang Pa-in",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140603",
    "subDistrictNameTh": "บ้านโพ",
    "subDistrictNameEn": "Ban Pho",
    "postcode": "13160",
    "districtCode": "1406",
    "districtNameTh": "บางปะอิน",
    "districtNameEn": "Bang Pa-in",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140604",
    "subDistrictNameTh": "บ้านกรด",
    "subDistrictNameEn": "Ban Krot",
    "postcode": "13160",
    "districtCode": "1406",
    "districtNameTh": "บางปะอิน",
    "districtNameEn": "Bang Pa-in",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140605",
    "subDistrictNameTh": "บางกระสั้น",
    "subDistrictNameEn": "Bang Krasan",
    "postcode": "13160",
    "districtCode": "1406",
    "districtNameTh": "บางปะอิน",
    "districtNameEn": "Bang Pa-in",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140606",
    "subDistrictNameTh": "คลองจิก",
    "subDistrictNameEn": "Khlong Chik",
    "postcode": "13160",
    "districtCode": "1406",
    "districtNameTh": "บางปะอิน",
    "districtNameEn": "Bang Pa-in",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140607",
    "subDistrictNameTh": "บ้านหว้า",
    "subDistrictNameEn": "Ban Wa",
    "postcode": "13160",
    "districtCode": "1406",
    "districtNameTh": "บางปะอิน",
    "districtNameEn": "Bang Pa-in",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140608",
    "subDistrictNameTh": "วัดยม",
    "subDistrictNameEn": "Wat Yom",
    "postcode": "13160",
    "districtCode": "1406",
    "districtNameTh": "บางปะอิน",
    "districtNameEn": "Bang Pa-in",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140609",
    "subDistrictNameTh": "บางประแดง",
    "subDistrictNameEn": "Bang Pradaeng",
    "postcode": "13160",
    "districtCode": "1406",
    "districtNameTh": "บางปะอิน",
    "districtNameEn": "Bang Pa-in",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140610",
    "subDistrictNameTh": "สามเรือน",
    "subDistrictNameEn": "Sam Ruean",
    "postcode": "13160",
    "districtCode": "1406",
    "districtNameTh": "บางปะอิน",
    "districtNameEn": "Bang Pa-in",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140611",
    "subDistrictNameTh": "เกาะเกิด",
    "subDistrictNameEn": "Ko Koet",
    "postcode": "13160",
    "districtCode": "1406",
    "districtNameTh": "บางปะอิน",
    "districtNameEn": "Bang Pa-in",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140612",
    "subDistrictNameTh": "บ้านพลับ",
    "subDistrictNameEn": "Ban Phlap",
    "postcode": "13160",
    "districtCode": "1406",
    "districtNameTh": "บางปะอิน",
    "districtNameEn": "Bang Pa-in",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140613",
    "subDistrictNameTh": "บ้านแป้ง",
    "subDistrictNameEn": "Ban Paeng",
    "postcode": "13160",
    "districtCode": "1406",
    "districtNameTh": "บางปะอิน",
    "districtNameEn": "Bang Pa-in",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140614",
    "subDistrictNameTh": "คุ้งลาน",
    "subDistrictNameEn": "Khung Lan",
    "postcode": "13160",
    "districtCode": "1406",
    "districtNameTh": "บางปะอิน",
    "districtNameEn": "Bang Pa-in",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140615",
    "subDistrictNameTh": "ตลิ่งชัน",
    "subDistrictNameEn": "Taling Chan",
    "postcode": "13160",
    "districtCode": "1406",
    "districtNameTh": "บางปะอิน",
    "districtNameEn": "Bang Pa-in",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140616",
    "subDistrictNameTh": "บ้านสร้าง",
    "subDistrictNameEn": "Ban Sang",
    "postcode": "13170",
    "districtCode": "1406",
    "districtNameTh": "บางปะอิน",
    "districtNameEn": "Bang Pa-in",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140617",
    "subDistrictNameTh": "ตลาดเกรียบ",
    "subDistrictNameEn": "Talat Kriap",
    "postcode": "13160",
    "districtCode": "1406",
    "districtNameTh": "บางปะอิน",
    "districtNameEn": "Bang Pa-in",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140618",
    "subDistrictNameTh": "ขนอนหลวง",
    "subDistrictNameEn": "Khanon Luang",
    "postcode": "13160",
    "districtCode": "1406",
    "districtNameTh": "บางปะอิน",
    "districtNameEn": "Bang Pa-in",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140701",
    "subDistrictNameTh": "บางปะหัน",
    "subDistrictNameEn": "Bang Pahan",
    "postcode": "13220",
    "districtCode": "1407",
    "districtNameTh": "บางปะหัน",
    "districtNameEn": "Bang Pahan",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140702",
    "subDistrictNameTh": "ขยาย",
    "subDistrictNameEn": "Khayai",
    "postcode": "13220",
    "districtCode": "1407",
    "districtNameTh": "บางปะหัน",
    "districtNameEn": "Bang Pahan",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140703",
    "subDistrictNameTh": "บางเดื่อ",
    "subDistrictNameEn": "Bang Duea",
    "postcode": "13220",
    "districtCode": "1407",
    "districtNameTh": "บางปะหัน",
    "districtNameEn": "Bang Pahan",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140704",
    "subDistrictNameTh": "เสาธง",
    "subDistrictNameEn": "Sao Thong",
    "postcode": "13220",
    "districtCode": "1407",
    "districtNameTh": "บางปะหัน",
    "districtNameEn": "Bang Pahan",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140705",
    "subDistrictNameTh": "ทางกลาง",
    "subDistrictNameEn": "Thang Klang",
    "postcode": "13220",
    "districtCode": "1407",
    "districtNameTh": "บางปะหัน",
    "districtNameEn": "Bang Pahan",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140706",
    "subDistrictNameTh": "บางเพลิง",
    "subDistrictNameEn": "Bang Phloeng",
    "postcode": "13220",
    "districtCode": "1407",
    "districtNameTh": "บางปะหัน",
    "districtNameEn": "Bang Pahan",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140707",
    "subDistrictNameTh": "หันสัง",
    "subDistrictNameEn": "Han Sang",
    "postcode": "13220",
    "districtCode": "1407",
    "districtNameTh": "บางปะหัน",
    "districtNameEn": "Bang Pahan",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140708",
    "subDistrictNameTh": "บางนางร้า",
    "subDistrictNameEn": "Bang Nang Ra",
    "postcode": "13220",
    "districtCode": "1407",
    "districtNameTh": "บางปะหัน",
    "districtNameEn": "Bang Pahan",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140709",
    "subDistrictNameTh": "ตานิม",
    "subDistrictNameEn": "Ta Nim",
    "postcode": "13220",
    "districtCode": "1407",
    "districtNameTh": "บางปะหัน",
    "districtNameEn": "Bang Pahan",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140710",
    "subDistrictNameTh": "ทับน้ำ",
    "subDistrictNameEn": "Thap Nam",
    "postcode": "13220",
    "districtCode": "1407",
    "districtNameTh": "บางปะหัน",
    "districtNameEn": "Bang Pahan",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140711",
    "subDistrictNameTh": "บ้านม้า",
    "subDistrictNameEn": "Ban Ma",
    "postcode": "13220",
    "districtCode": "1407",
    "districtNameTh": "บางปะหัน",
    "districtNameEn": "Bang Pahan",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140712",
    "subDistrictNameTh": "ขวัญเมือง",
    "subDistrictNameEn": "Khwan Mueang",
    "postcode": "13220",
    "districtCode": "1407",
    "districtNameTh": "บางปะหัน",
    "districtNameEn": "Bang Pahan",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140713",
    "subDistrictNameTh": "บ้านลี่",
    "subDistrictNameEn": "Ban Li",
    "postcode": "13220",
    "districtCode": "1407",
    "districtNameTh": "บางปะหัน",
    "districtNameEn": "Bang Pahan",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140714",
    "subDistrictNameTh": "โพธิ์สามต้น",
    "subDistrictNameEn": "Pho Sam Ton",
    "postcode": "13220",
    "districtCode": "1407",
    "districtNameTh": "บางปะหัน",
    "districtNameEn": "Bang Pahan",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140715",
    "subDistrictNameTh": "พุทเลา",
    "subDistrictNameEn": "Phut Lao",
    "postcode": "13220",
    "districtCode": "1407",
    "districtNameTh": "บางปะหัน",
    "districtNameEn": "Bang Pahan",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140716",
    "subDistrictNameTh": "ตาลเอน",
    "subDistrictNameEn": "Tan En",
    "postcode": "13220",
    "districtCode": "1407",
    "districtNameTh": "บางปะหัน",
    "districtNameEn": "Bang Pahan",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140717",
    "subDistrictNameTh": "บ้านขล้อ",
    "subDistrictNameEn": "Ban Khlo",
    "postcode": "13220",
    "districtCode": "1407",
    "districtNameTh": "บางปะหัน",
    "districtNameEn": "Bang Pahan",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140801",
    "subDistrictNameTh": "ผักไห่",
    "subDistrictNameEn": "Phak Hai",
    "postcode": "13120",
    "districtCode": "1408",
    "districtNameTh": "ผักไห่",
    "districtNameEn": "Phak Hai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140802",
    "subDistrictNameTh": "อมฤต",
    "subDistrictNameEn": "Ammarit",
    "postcode": "13120",
    "districtCode": "1408",
    "districtNameTh": "ผักไห่",
    "districtNameEn": "Phak Hai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140803",
    "subDistrictNameTh": "บ้านแค",
    "subDistrictNameEn": "Ban Khae",
    "postcode": "13120",
    "districtCode": "1408",
    "districtNameTh": "ผักไห่",
    "districtNameEn": "Phak Hai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140804",
    "subDistrictNameTh": "ลาดน้ำเค็ม",
    "subDistrictNameEn": "Lat Nam Khem",
    "postcode": "13120",
    "districtCode": "1408",
    "districtNameTh": "ผักไห่",
    "districtNameEn": "Phak Hai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140805",
    "subDistrictNameTh": "ตาลาน",
    "subDistrictNameEn": "Ta Lan",
    "postcode": "13120",
    "districtCode": "1408",
    "districtNameTh": "ผักไห่",
    "districtNameEn": "Phak Hai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140806",
    "subDistrictNameTh": "ท่าดินแดง",
    "subDistrictNameEn": "Tha Din Daeng",
    "postcode": "13120",
    "districtCode": "1408",
    "districtNameTh": "ผักไห่",
    "districtNameEn": "Phak Hai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140807",
    "subDistrictNameTh": "ดอนลาน",
    "subDistrictNameEn": "Don Lan",
    "postcode": "13280",
    "districtCode": "1408",
    "districtNameTh": "ผักไห่",
    "districtNameEn": "Phak Hai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140808",
    "subDistrictNameTh": "นาคู",
    "subDistrictNameEn": "Na Khu",
    "postcode": "13280",
    "districtCode": "1408",
    "districtNameTh": "ผักไห่",
    "districtNameEn": "Phak Hai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140809",
    "subDistrictNameTh": "กุฎี",
    "subDistrictNameEn": "Kudi",
    "postcode": "13120",
    "districtCode": "1408",
    "districtNameTh": "ผักไห่",
    "districtNameEn": "Phak Hai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140810",
    "subDistrictNameTh": "ลำตะเคียน",
    "subDistrictNameEn": "Lam Takhian",
    "postcode": "13280",
    "districtCode": "1408",
    "districtNameTh": "ผักไห่",
    "districtNameEn": "Phak Hai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140811",
    "subDistrictNameTh": "โคกช้าง",
    "subDistrictNameEn": "Khok Chang",
    "postcode": "13120",
    "districtCode": "1408",
    "districtNameTh": "ผักไห่",
    "districtNameEn": "Phak Hai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140812",
    "subDistrictNameTh": "จักราช",
    "subDistrictNameEn": "Chakkarat",
    "postcode": "13280",
    "districtCode": "1408",
    "districtNameTh": "ผักไห่",
    "districtNameEn": "Phak Hai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140813",
    "subDistrictNameTh": "หนองน้ำใหญ่",
    "subDistrictNameEn": "Nong Nam Yai",
    "postcode": "13280",
    "districtCode": "1408",
    "districtNameTh": "ผักไห่",
    "districtNameEn": "Phak Hai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140814",
    "subDistrictNameTh": "ลาดชิด",
    "subDistrictNameEn": "Lat Chit",
    "postcode": "13120",
    "districtCode": "1408",
    "districtNameTh": "ผักไห่",
    "districtNameEn": "Phak Hai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140815",
    "subDistrictNameTh": "หน้าโคก",
    "subDistrictNameEn": "Na Khok",
    "postcode": "13120",
    "districtCode": "1408",
    "districtNameTh": "ผักไห่",
    "districtNameEn": "Phak Hai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140816",
    "subDistrictNameTh": "บ้านใหญ่",
    "subDistrictNameEn": "Ban Yai",
    "postcode": "13120",
    "districtCode": "1408",
    "districtNameTh": "ผักไห่",
    "districtNameEn": "Phak Hai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140901",
    "subDistrictNameTh": "ภาชี",
    "subDistrictNameEn": "Phachi",
    "postcode": "13140",
    "districtCode": "1409",
    "districtNameTh": "ภาชี",
    "districtNameEn": "Phachi",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140902",
    "subDistrictNameTh": "โคกม่วง",
    "subDistrictNameEn": "Khok Muang",
    "postcode": "13140",
    "districtCode": "1409",
    "districtNameTh": "ภาชี",
    "districtNameEn": "Phachi",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140903",
    "subDistrictNameTh": "ระโสม",
    "subDistrictNameEn": "Rasom",
    "postcode": "13140",
    "districtCode": "1409",
    "districtNameTh": "ภาชี",
    "districtNameEn": "Phachi",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140904",
    "subDistrictNameTh": "หนองน้ำใส",
    "subDistrictNameEn": "Nong Nam Sai",
    "postcode": "13140",
    "districtCode": "1409",
    "districtNameTh": "ภาชี",
    "districtNameEn": "Phachi",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140905",
    "subDistrictNameTh": "ดอนหญ้านาง",
    "subDistrictNameEn": "Don Ya Nang",
    "postcode": "13140",
    "districtCode": "1409",
    "districtNameTh": "ภาชี",
    "districtNameEn": "Phachi",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140906",
    "subDistrictNameTh": "ไผ่ล้อม",
    "subDistrictNameEn": "Phai Lom",
    "postcode": "13140",
    "districtCode": "1409",
    "districtNameTh": "ภาชี",
    "districtNameEn": "Phachi",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140907",
    "subDistrictNameTh": "กระจิว",
    "subDistrictNameEn": "Krachio",
    "postcode": "13140",
    "districtCode": "1409",
    "districtNameTh": "ภาชี",
    "districtNameEn": "Phachi",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "140908",
    "subDistrictNameTh": "พระแก้ว",
    "subDistrictNameEn": "Phra Kaeo",
    "postcode": "13140",
    "districtCode": "1409",
    "districtNameTh": "ภาชี",
    "districtNameEn": "Phachi",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141001",
    "subDistrictNameTh": "ลาดบัวหลวง",
    "subDistrictNameEn": "Lat Bua Luang",
    "postcode": "13230",
    "districtCode": "1410",
    "districtNameTh": "ลาดบัวหลวง",
    "districtNameEn": "Lat Bua Luang",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141002",
    "subDistrictNameTh": "หลักชัย",
    "subDistrictNameEn": "Lak Chai",
    "postcode": "13230",
    "districtCode": "1410",
    "districtNameTh": "ลาดบัวหลวง",
    "districtNameEn": "Lat Bua Luang",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141003",
    "subDistrictNameTh": "สามเมือง",
    "subDistrictNameEn": "Sam Mueang",
    "postcode": "13230",
    "districtCode": "1410",
    "districtNameTh": "ลาดบัวหลวง",
    "districtNameEn": "Lat Bua Luang",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141004",
    "subDistrictNameTh": "พระยาบันลือ",
    "subDistrictNameEn": "Phraya Banlue",
    "postcode": "13230",
    "districtCode": "1410",
    "districtNameTh": "ลาดบัวหลวง",
    "districtNameEn": "Lat Bua Luang",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141005",
    "subDistrictNameTh": "สิงหนาท",
    "subDistrictNameEn": "Singhanat",
    "postcode": "13230",
    "districtCode": "1410",
    "districtNameTh": "ลาดบัวหลวง",
    "districtNameEn": "Lat Bua Luang",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141006",
    "subDistrictNameTh": "คู้สลอด",
    "subDistrictNameEn": "Khu Salot",
    "postcode": "13230",
    "districtCode": "1410",
    "districtNameTh": "ลาดบัวหลวง",
    "districtNameEn": "Lat Bua Luang",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141007",
    "subDistrictNameTh": "คลองพระยาบันลือ",
    "subDistrictNameEn": "Khlong Phraya Banlue",
    "postcode": "13230",
    "districtCode": "1410",
    "districtNameTh": "ลาดบัวหลวง",
    "districtNameEn": "Lat Bua Luang",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141101",
    "subDistrictNameTh": "ลำตาเสา",
    "subDistrictNameEn": "Lam Ta Sao",
    "postcode": "13170",
    "districtCode": "1411",
    "districtNameTh": "วังน้อย",
    "districtNameEn": "Wang Noi",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141102",
    "subDistrictNameTh": "บ่อตาโล่",
    "subDistrictNameEn": "Bo ta Lo",
    "postcode": "13170",
    "districtCode": "1411",
    "districtNameTh": "วังน้อย",
    "districtNameEn": "Wang Noi",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141103",
    "subDistrictNameTh": "วังน้อย",
    "subDistrictNameEn": "Wang Noi",
    "postcode": "13170",
    "districtCode": "1411",
    "districtNameTh": "วังน้อย",
    "districtNameEn": "Wang Noi",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141104",
    "subDistrictNameTh": "ลำไทร",
    "subDistrictNameEn": "Lam Sai",
    "postcode": "13170",
    "districtCode": "1411",
    "districtNameTh": "วังน้อย",
    "districtNameEn": "Wang Noi",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141105",
    "subDistrictNameTh": "สนับทึบ",
    "subDistrictNameEn": "Sanap Thuep",
    "postcode": "13170",
    "districtCode": "1411",
    "districtNameTh": "วังน้อย",
    "districtNameEn": "Wang Noi",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141106",
    "subDistrictNameTh": "พยอม",
    "subDistrictNameEn": "Phayom",
    "postcode": "13170",
    "districtCode": "1411",
    "districtNameTh": "วังน้อย",
    "districtNameEn": "Wang Noi",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141107",
    "subDistrictNameTh": "หันตะเภา",
    "subDistrictNameEn": "Han Taphao",
    "postcode": "13170",
    "districtCode": "1411",
    "districtNameTh": "วังน้อย",
    "districtNameEn": "Wang Noi",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141108",
    "subDistrictNameTh": "วังจุฬา",
    "subDistrictNameEn": "Wang Chula",
    "postcode": "13170",
    "districtCode": "1411",
    "districtNameTh": "วังน้อย",
    "districtNameEn": "Wang Noi",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141109",
    "subDistrictNameTh": "ข้าวงาม",
    "subDistrictNameEn": "Khao Ngam",
    "postcode": "13170",
    "districtCode": "1411",
    "districtNameTh": "วังน้อย",
    "districtNameEn": "Wang Noi",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141110",
    "subDistrictNameTh": "ชะแมบ",
    "subDistrictNameEn": "Chamaep",
    "postcode": "13170",
    "districtCode": "1411",
    "districtNameTh": "วังน้อย",
    "districtNameEn": "Wang Noi",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141201",
    "subDistrictNameTh": "เสนา",
    "subDistrictNameEn": "Sena",
    "postcode": "13110",
    "districtCode": "1412",
    "districtNameTh": "เสนา",
    "districtNameEn": "Sena",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141202",
    "subDistrictNameTh": "บ้านแพน",
    "subDistrictNameEn": "Ban Phaen",
    "postcode": "13110",
    "districtCode": "1412",
    "districtNameTh": "เสนา",
    "districtNameEn": "Sena",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141203",
    "subDistrictNameTh": "เจ้าเจ็ด",
    "subDistrictNameEn": "Chao Chet",
    "postcode": "13110",
    "districtCode": "1412",
    "districtNameTh": "เสนา",
    "districtNameEn": "Sena",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141204",
    "subDistrictNameTh": "สามกอ",
    "subDistrictNameEn": "Sam Ko",
    "postcode": "13110",
    "districtCode": "1412",
    "districtNameTh": "เสนา",
    "districtNameEn": "Sena",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141205",
    "subDistrictNameTh": "บางนมโค",
    "subDistrictNameEn": "Bang Nom Kho",
    "postcode": "13110",
    "districtCode": "1412",
    "districtNameTh": "เสนา",
    "districtNameEn": "Sena",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141206",
    "subDistrictNameTh": "หัวเวียง",
    "subDistrictNameEn": "Hua Wiang",
    "postcode": "13110",
    "districtCode": "1412",
    "districtNameTh": "เสนา",
    "districtNameEn": "Sena",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141207",
    "subDistrictNameTh": "มารวิชัย",
    "subDistrictNameEn": "Manwichai",
    "postcode": "13110",
    "districtCode": "1412",
    "districtNameTh": "เสนา",
    "districtNameEn": "Sena",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141208",
    "subDistrictNameTh": "บ้านโพธิ์",
    "subDistrictNameEn": "Ban Pho",
    "postcode": "13110",
    "districtCode": "1412",
    "districtNameTh": "เสนา",
    "districtNameEn": "Sena",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141209",
    "subDistrictNameTh": "รางจรเข้",
    "subDistrictNameEn": "Rang Chorakhe",
    "postcode": "13110",
    "districtCode": "1412",
    "districtNameTh": "เสนา",
    "districtNameEn": "Sena",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141210",
    "subDistrictNameTh": "บ้านกระทุ่ม",
    "subDistrictNameEn": "Ban Krathum",
    "postcode": "13110",
    "districtCode": "1412",
    "districtNameTh": "เสนา",
    "districtNameEn": "Sena",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141211",
    "subDistrictNameTh": "บ้านแถว",
    "subDistrictNameEn": "Ban Thaeo",
    "postcode": "13110",
    "districtCode": "1412",
    "districtNameTh": "เสนา",
    "districtNameEn": "Sena",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141212",
    "subDistrictNameTh": "ชายนา",
    "subDistrictNameEn": "Chai Na",
    "postcode": "13110",
    "districtCode": "1412",
    "districtNameTh": "เสนา",
    "districtNameEn": "Sena",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141213",
    "subDistrictNameTh": "สามตุ่ม",
    "subDistrictNameEn": "Sam Tum",
    "postcode": "13110",
    "districtCode": "1412",
    "districtNameTh": "เสนา",
    "districtNameEn": "Sena",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141214",
    "subDistrictNameTh": "ลาดงา",
    "subDistrictNameEn": "Lat Nga",
    "postcode": "13110",
    "districtCode": "1412",
    "districtNameTh": "เสนา",
    "districtNameEn": "Sena",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141215",
    "subDistrictNameTh": "ดอนทอง",
    "subDistrictNameEn": "Don Thong",
    "postcode": "13110",
    "districtCode": "1412",
    "districtNameTh": "เสนา",
    "districtNameEn": "Sena",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141216",
    "subDistrictNameTh": "บ้านหลวง",
    "subDistrictNameEn": "Ban Luang",
    "postcode": "13110",
    "districtCode": "1412",
    "districtNameTh": "เสนา",
    "districtNameEn": "Sena",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141217",
    "subDistrictNameTh": "เจ้าเสด็จ",
    "subDistrictNameEn": "Chao Sadet",
    "postcode": "13110",
    "districtCode": "1412",
    "districtNameTh": "เสนา",
    "districtNameEn": "Sena",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141301",
    "subDistrictNameTh": "บางซ้าย",
    "subDistrictNameEn": "Bang Sai",
    "postcode": "13270",
    "districtCode": "1413",
    "districtNameTh": "บางซ้าย",
    "districtNameEn": "Bang Sai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141302",
    "subDistrictNameTh": "แก้วฟ้า",
    "subDistrictNameEn": "Kaeo Fa",
    "postcode": "13270",
    "districtCode": "1413",
    "districtNameTh": "บางซ้าย",
    "districtNameEn": "Bang Sai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141303",
    "subDistrictNameTh": "เต่าเล่า",
    "subDistrictNameEn": "Tao Lao",
    "postcode": "13270",
    "districtCode": "1413",
    "districtNameTh": "บางซ้าย",
    "districtNameEn": "Bang Sai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141304",
    "subDistrictNameTh": "ปลายกลัด",
    "subDistrictNameEn": "Plai Klat",
    "postcode": "13270",
    "districtCode": "1413",
    "districtNameTh": "บางซ้าย",
    "districtNameEn": "Bang Sai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141305",
    "subDistrictNameTh": "เทพมงคล",
    "subDistrictNameEn": "Thep Mongkhon",
    "postcode": "13270",
    "districtCode": "1413",
    "districtNameTh": "บางซ้าย",
    "districtNameEn": "Bang Sai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141306",
    "subDistrictNameTh": "วังพัฒนา",
    "subDistrictNameEn": "Wang Phatthana",
    "postcode": "13270",
    "districtCode": "1413",
    "districtNameTh": "บางซ้าย",
    "districtNameEn": "Bang Sai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141401",
    "subDistrictNameTh": "คานหาม",
    "subDistrictNameEn": "Khan Ham",
    "postcode": "13210",
    "districtCode": "1414",
    "districtNameTh": "อุทัย",
    "districtNameEn": "Uthai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141402",
    "subDistrictNameTh": "บ้านช้าง",
    "subDistrictNameEn": "Ban Chang",
    "postcode": "13210",
    "districtCode": "1414",
    "districtNameTh": "อุทัย",
    "districtNameEn": "Uthai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141403",
    "subDistrictNameTh": "สามบัณฑิต",
    "subDistrictNameEn": "Sam Bandit",
    "postcode": "13210",
    "districtCode": "1414",
    "districtNameTh": "อุทัย",
    "districtNameEn": "Uthai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141404",
    "subDistrictNameTh": "บ้านหีบ",
    "subDistrictNameEn": "Ban Hip",
    "postcode": "13210",
    "districtCode": "1414",
    "districtNameTh": "อุทัย",
    "districtNameEn": "Uthai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141405",
    "subDistrictNameTh": "หนองไม้ซุง",
    "subDistrictNameEn": "Nong Mai Sung",
    "postcode": "13210",
    "districtCode": "1414",
    "districtNameTh": "อุทัย",
    "districtNameEn": "Uthai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141406",
    "subDistrictNameTh": "อุทัย",
    "subDistrictNameEn": "Uthai",
    "postcode": "13210",
    "districtCode": "1414",
    "districtNameTh": "อุทัย",
    "districtNameEn": "Uthai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141407",
    "subDistrictNameTh": "เสนา",
    "subDistrictNameEn": "Sena",
    "postcode": "13210",
    "districtCode": "1414",
    "districtNameTh": "อุทัย",
    "districtNameEn": "Uthai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141408",
    "subDistrictNameTh": "หนองน้ำส้ม",
    "subDistrictNameEn": "Nong Nam Som",
    "postcode": "13210",
    "districtCode": "1414",
    "districtNameTh": "อุทัย",
    "districtNameEn": "Uthai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141409",
    "subDistrictNameTh": "โพสาวหาญ",
    "subDistrictNameEn": "Pho Sao Han",
    "postcode": "13210",
    "districtCode": "1414",
    "districtNameTh": "อุทัย",
    "districtNameEn": "Uthai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141410",
    "subDistrictNameTh": "ธนู",
    "subDistrictNameEn": "Thanu",
    "postcode": "13210",
    "districtCode": "1414",
    "districtNameTh": "อุทัย",
    "districtNameEn": "Uthai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141411",
    "subDistrictNameTh": "ข้าวเม่า",
    "subDistrictNameEn": "Khao Mao",
    "postcode": "13210",
    "districtCode": "1414",
    "districtNameTh": "อุทัย",
    "districtNameEn": "Uthai",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141501",
    "subDistrictNameTh": "หัวไผ่",
    "subDistrictNameEn": "Hua Phai",
    "postcode": "13150",
    "districtCode": "1415",
    "districtNameTh": "มหาราช",
    "districtNameEn": "Maha Rat",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141502",
    "subDistrictNameTh": "กะทุ่ม",
    "subDistrictNameEn": "Kathum",
    "postcode": "13150",
    "districtCode": "1415",
    "districtNameTh": "มหาราช",
    "districtNameEn": "Maha Rat",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141503",
    "subDistrictNameTh": "มหาราช",
    "subDistrictNameEn": "Maha Rat",
    "postcode": "13150",
    "districtCode": "1415",
    "districtNameTh": "มหาราช",
    "districtNameEn": "Maha Rat",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141504",
    "subDistrictNameTh": "น้ำเต้า",
    "subDistrictNameEn": "Nam Tao",
    "postcode": "13150",
    "districtCode": "1415",
    "districtNameTh": "มหาราช",
    "districtNameEn": "Maha Rat",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141505",
    "subDistrictNameTh": "บางนา",
    "subDistrictNameEn": "Bang Na",
    "postcode": "13150",
    "districtCode": "1415",
    "districtNameTh": "มหาราช",
    "districtNameEn": "Maha Rat",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141506",
    "subDistrictNameTh": "โรงช้าง",
    "subDistrictNameEn": "Rong Chang",
    "postcode": "13150",
    "districtCode": "1415",
    "districtNameTh": "มหาราช",
    "districtNameEn": "Maha Rat",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141507",
    "subDistrictNameTh": "เจ้าปลุก",
    "subDistrictNameEn": "Chao Pluk",
    "postcode": "13150",
    "districtCode": "1415",
    "districtNameTh": "มหาราช",
    "districtNameEn": "Maha Rat",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141508",
    "subDistrictNameTh": "พิตเพียน",
    "subDistrictNameEn": "Phit Phian",
    "postcode": "13150",
    "districtCode": "1415",
    "districtNameTh": "มหาราช",
    "districtNameEn": "Maha Rat",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141509",
    "subDistrictNameTh": "บ้านนา",
    "subDistrictNameEn": "Ban Na",
    "postcode": "13150",
    "districtCode": "1415",
    "districtNameTh": "มหาราช",
    "districtNameEn": "Maha Rat",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141510",
    "subDistrictNameTh": "บ้านขวาง",
    "subDistrictNameEn": "Ban Khwang",
    "postcode": "13150",
    "districtCode": "1415",
    "districtNameTh": "มหาราช",
    "districtNameEn": "Maha Rat",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141511",
    "subDistrictNameTh": "ท่าตอ",
    "subDistrictNameEn": "Tha To",
    "postcode": "13150",
    "districtCode": "1415",
    "districtNameTh": "มหาราช",
    "districtNameEn": "Maha Rat",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141512",
    "subDistrictNameTh": "บ้านใหม่",
    "subDistrictNameEn": "Ban Mai",
    "postcode": "13150",
    "districtCode": "1415",
    "districtNameTh": "มหาราช",
    "districtNameEn": "Maha Rat",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141601",
    "subDistrictNameTh": "บ้านแพรก",
    "subDistrictNameEn": "Ban Phraek",
    "postcode": "13240",
    "districtCode": "1416",
    "districtNameTh": "บ้านแพรก",
    "districtNameEn": "Ban Phraek",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141602",
    "subDistrictNameTh": "บ้านใหม่",
    "subDistrictNameEn": "Ban Mai",
    "postcode": "13240",
    "districtCode": "1416",
    "districtNameTh": "บ้านแพรก",
    "districtNameEn": "Ban Phraek",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141603",
    "subDistrictNameTh": "สำพะเนียง",
    "subDistrictNameEn": "Sam Phaniang",
    "postcode": "13240",
    "districtCode": "1416",
    "districtNameTh": "บ้านแพรก",
    "districtNameEn": "Ban Phraek",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141604",
    "subDistrictNameTh": "คลองน้อย",
    "subDistrictNameEn": "Khlong Noi",
    "postcode": "13240",
    "districtCode": "1416",
    "districtNameTh": "บ้านแพรก",
    "districtNameEn": "Ban Phraek",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "141605",
    "subDistrictNameTh": "สองห้อง",
    "subDistrictNameEn": "Song Hong",
    "postcode": "13240",
    "districtCode": "1416",
    "districtNameTh": "บ้านแพรก",
    "districtNameEn": "Ban Phraek",
    "provinceCode": "14",
    "provinceNameTh": "พระนครศรีอยุธยา",
    "provinceNameEn": "Phra Nakhon Si Ayutthaya"
},
{
    "subDistrictCode": "150101",
    "subDistrictNameTh": "ตลาดหลวง",
    "subDistrictNameEn": "Talat Luang",
    "postcode": "14000",
    "districtCode": "1501",
    "districtNameTh": "เมืองอ่างทอง",
    "districtNameEn": "Mueang Ang Thong",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150102",
    "subDistrictNameTh": "บางแก้ว",
    "subDistrictNameEn": "Bang Kaeo",
    "postcode": "14000",
    "districtCode": "1501",
    "districtNameTh": "เมืองอ่างทอง",
    "districtNameEn": "Mueang Ang Thong",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150103",
    "subDistrictNameTh": "ศาลาแดง",
    "subDistrictNameEn": "Sala Daeng",
    "postcode": "14000",
    "districtCode": "1501",
    "districtNameTh": "เมืองอ่างทอง",
    "districtNameEn": "Mueang Ang Thong",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150104",
    "subDistrictNameTh": "ป่างิ้ว",
    "subDistrictNameEn": "Pa Ngio",
    "postcode": "14000",
    "districtCode": "1501",
    "districtNameTh": "เมืองอ่างทอง",
    "districtNameEn": "Mueang Ang Thong",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150105",
    "subDistrictNameTh": "บ้านแห",
    "subDistrictNameEn": "Ban Hae",
    "postcode": "14000",
    "districtCode": "1501",
    "districtNameTh": "เมืองอ่างทอง",
    "districtNameEn": "Mueang Ang Thong",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150106",
    "subDistrictNameTh": "ตลาดกรวด",
    "subDistrictNameEn": "Talat Kruat",
    "postcode": "14000",
    "districtCode": "1501",
    "districtNameTh": "เมืองอ่างทอง",
    "districtNameEn": "Mueang Ang Thong",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150107",
    "subDistrictNameTh": "มหาดไทย",
    "subDistrictNameEn": "Mahat Thai",
    "postcode": "14000",
    "districtCode": "1501",
    "districtNameTh": "เมืองอ่างทอง",
    "districtNameEn": "Mueang Ang Thong",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150108",
    "subDistrictNameTh": "บ้านอิฐ",
    "subDistrictNameEn": "Ban It",
    "postcode": "14000",
    "districtCode": "1501",
    "districtNameTh": "เมืองอ่างทอง",
    "districtNameEn": "Mueang Ang Thong",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150109",
    "subDistrictNameTh": "หัวไผ่",
    "subDistrictNameEn": "Hua Phai",
    "postcode": "14000",
    "districtCode": "1501",
    "districtNameTh": "เมืองอ่างทอง",
    "districtNameEn": "Mueang Ang Thong",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150110",
    "subDistrictNameTh": "จำปาหล่อ",
    "subDistrictNameEn": "Champa Lo",
    "postcode": "14000",
    "districtCode": "1501",
    "districtNameTh": "เมืองอ่างทอง",
    "districtNameEn": "Mueang Ang Thong",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150111",
    "subDistrictNameTh": "โพสะ",
    "subDistrictNameEn": "Phosa",
    "postcode": "14000",
    "districtCode": "1501",
    "districtNameTh": "เมืองอ่างทอง",
    "districtNameEn": "Mueang Ang Thong",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150112",
    "subDistrictNameTh": "บ้านรี",
    "subDistrictNameEn": "Ban Ri",
    "postcode": "14000",
    "districtCode": "1501",
    "districtNameTh": "เมืองอ่างทอง",
    "districtNameEn": "Mueang Ang Thong",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150113",
    "subDistrictNameTh": "คลองวัว",
    "subDistrictNameEn": "Khlong Wua",
    "postcode": "14000",
    "districtCode": "1501",
    "districtNameTh": "เมืองอ่างทอง",
    "districtNameEn": "Mueang Ang Thong",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150114",
    "subDistrictNameTh": "ย่านซื่อ",
    "subDistrictNameEn": "Yan Sue",
    "postcode": "14000",
    "districtCode": "1501",
    "districtNameTh": "เมืองอ่างทอง",
    "districtNameEn": "Mueang Ang Thong",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150201",
    "subDistrictNameTh": "จรเข้ร้อง",
    "subDistrictNameEn": "Chorakhe Rong",
    "postcode": "14140",
    "districtCode": "1502",
    "districtNameTh": "ไชโย",
    "districtNameEn": "Chaiyo",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150202",
    "subDistrictNameTh": "ไชยภูมิ",
    "subDistrictNameEn": "Chaiyaphum",
    "postcode": "14140",
    "districtCode": "1502",
    "districtNameTh": "ไชโย",
    "districtNameEn": "Chaiyo",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150203",
    "subDistrictNameTh": "ชัยฤทธิ์",
    "subDistrictNameEn": "Chai Rit",
    "postcode": "14140",
    "districtCode": "1502",
    "districtNameTh": "ไชโย",
    "districtNameEn": "Chaiyo",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150204",
    "subDistrictNameTh": "เทวราช",
    "subDistrictNameEn": "Thewarat",
    "postcode": "14140",
    "districtCode": "1502",
    "districtNameTh": "ไชโย",
    "districtNameEn": "Chaiyo",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150205",
    "subDistrictNameTh": "ราชสถิตย์",
    "subDistrictNameEn": "Rat Sathit",
    "postcode": "14140",
    "districtCode": "1502",
    "districtNameTh": "ไชโย",
    "districtNameEn": "Chaiyo",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150206",
    "subDistrictNameTh": "ไชโย",
    "subDistrictNameEn": "Chaiyo",
    "postcode": "14140",
    "districtCode": "1502",
    "districtNameTh": "ไชโย",
    "districtNameEn": "Chaiyo",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150207",
    "subDistrictNameTh": "หลักฟ้า",
    "subDistrictNameEn": "Lak Fa",
    "postcode": "14140",
    "districtCode": "1502",
    "districtNameTh": "ไชโย",
    "districtNameEn": "Chaiyo",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150208",
    "subDistrictNameTh": "ชะไว",
    "subDistrictNameEn": "Chawai",
    "postcode": "14140",
    "districtCode": "1502",
    "districtNameTh": "ไชโย",
    "districtNameEn": "Chaiyo",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150209",
    "subDistrictNameTh": "ตรีณรงค์",
    "subDistrictNameEn": "Tri Narong",
    "postcode": "14140",
    "districtCode": "1502",
    "districtNameTh": "ไชโย",
    "districtNameEn": "Chaiyo",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150301",
    "subDistrictNameTh": "บางปลากด",
    "subDistrictNameEn": "Bang Pla Kot",
    "postcode": "14130",
    "districtCode": "1503",
    "districtNameTh": "ป่าโมก",
    "districtNameEn": "Pa Mok",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150302",
    "subDistrictNameTh": "ป่าโมก",
    "subDistrictNameEn": "Pa Mok",
    "postcode": "14130",
    "districtCode": "1503",
    "districtNameTh": "ป่าโมก",
    "districtNameEn": "Pa Mok",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150303",
    "subDistrictNameTh": "สายทอง",
    "subDistrictNameEn": "Sai Thong",
    "postcode": "14130",
    "districtCode": "1503",
    "districtNameTh": "ป่าโมก",
    "districtNameEn": "Pa Mok",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150304",
    "subDistrictNameTh": "โรงช้าง",
    "subDistrictNameEn": "Rong Chang",
    "postcode": "14130",
    "districtCode": "1503",
    "districtNameTh": "ป่าโมก",
    "districtNameEn": "Pa Mok",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150305",
    "subDistrictNameTh": "บางเสด็จ",
    "subDistrictNameEn": "Bang Sadet",
    "postcode": "14130",
    "districtCode": "1503",
    "districtNameTh": "ป่าโมก",
    "districtNameEn": "Pa Mok",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150306",
    "subDistrictNameTh": "นรสิงห์",
    "subDistrictNameEn": "Norasing",
    "postcode": "14130",
    "districtCode": "1503",
    "districtNameTh": "ป่าโมก",
    "districtNameEn": "Pa Mok",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150307",
    "subDistrictNameTh": "เอกราช",
    "subDistrictNameEn": "Ekkarat",
    "postcode": "14130",
    "districtCode": "1503",
    "districtNameTh": "ป่าโมก",
    "districtNameEn": "Pa Mok",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150308",
    "subDistrictNameTh": "โผงเผง",
    "subDistrictNameEn": "Phongpheng",
    "postcode": "14130",
    "districtCode": "1503",
    "districtNameTh": "ป่าโมก",
    "districtNameEn": "Pa Mok",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150401",
    "subDistrictNameTh": "อ่างแก้ว",
    "subDistrictNameEn": "Ang Kaeo",
    "postcode": "14120",
    "districtCode": "1504",
    "districtNameTh": "โพธิ์ทอง",
    "districtNameEn": "Pho Thong",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150402",
    "subDistrictNameTh": "อินทประมูล",
    "subDistrictNameEn": "Inthapramun",
    "postcode": "14120",
    "districtCode": "1504",
    "districtNameTh": "โพธิ์ทอง",
    "districtNameEn": "Pho Thong",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150403",
    "subDistrictNameTh": "บางพลับ",
    "subDistrictNameEn": "Bang Phlap",
    "postcode": "14120",
    "districtCode": "1504",
    "districtNameTh": "โพธิ์ทอง",
    "districtNameEn": "Pho Thong",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150404",
    "subDistrictNameTh": "หนองแม่ไก่",
    "subDistrictNameEn": "Nong Mae Kai",
    "postcode": "14120",
    "districtCode": "1504",
    "districtNameTh": "โพธิ์ทอง",
    "districtNameEn": "Pho Thong",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150405",
    "subDistrictNameTh": "รำมะสัก",
    "subDistrictNameEn": "Ram Masak",
    "postcode": "14120",
    "districtCode": "1504",
    "districtNameTh": "โพธิ์ทอง",
    "districtNameEn": "Pho Thong",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150406",
    "subDistrictNameTh": "บางระกำ",
    "subDistrictNameEn": "Bang Rakam",
    "postcode": "14120",
    "districtCode": "1504",
    "districtNameTh": "โพธิ์ทอง",
    "districtNameEn": "Pho Thong",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150407",
    "subDistrictNameTh": "โพธิ์รังนก",
    "subDistrictNameEn": "Pho Rang Nok",
    "postcode": "14120",
    "districtCode": "1504",
    "districtNameTh": "โพธิ์ทอง",
    "districtNameEn": "Pho Thong",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150408",
    "subDistrictNameTh": "องครักษ์",
    "subDistrictNameEn": "Ongkharak",
    "postcode": "14120",
    "districtCode": "1504",
    "districtNameTh": "โพธิ์ทอง",
    "districtNameEn": "Pho Thong",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150409",
    "subDistrictNameTh": "โคกพุทรา",
    "subDistrictNameEn": "Khok Phutsa",
    "postcode": "14120",
    "districtCode": "1504",
    "districtNameTh": "โพธิ์ทอง",
    "districtNameEn": "Pho Thong",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150410",
    "subDistrictNameTh": "ยางช้าย",
    "subDistrictNameEn": "Yang Chai",
    "postcode": "14120",
    "districtCode": "1504",
    "districtNameTh": "โพธิ์ทอง",
    "districtNameEn": "Pho Thong",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150411",
    "subDistrictNameTh": "บ่อแร่",
    "subDistrictNameEn": "Bo Rae",
    "postcode": "14120",
    "districtCode": "1504",
    "districtNameTh": "โพธิ์ทอง",
    "districtNameEn": "Pho Thong",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150412",
    "subDistrictNameTh": "ทางพระ",
    "subDistrictNameEn": "Thang Phra",
    "postcode": "14120",
    "districtCode": "1504",
    "districtNameTh": "โพธิ์ทอง",
    "districtNameEn": "Pho Thong",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150413",
    "subDistrictNameTh": "สามง่าม",
    "subDistrictNameEn": "Sam Ngam",
    "postcode": "14120",
    "districtCode": "1504",
    "districtNameTh": "โพธิ์ทอง",
    "districtNameEn": "Pho Thong",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150414",
    "subDistrictNameTh": "บางเจ้าฉ่า",
    "subDistrictNameEn": "Bang Chao Cha",
    "postcode": "14120",
    "districtCode": "1504",
    "districtNameTh": "โพธิ์ทอง",
    "districtNameEn": "Pho Thong",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150415",
    "subDistrictNameTh": "คำหยาด",
    "subDistrictNameEn": "Kham Yat",
    "postcode": "14120",
    "districtCode": "1504",
    "districtNameTh": "โพธิ์ทอง",
    "districtNameEn": "Pho Thong",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150501",
    "subDistrictNameTh": "แสวงหา",
    "subDistrictNameEn": "Sawaeng Ha",
    "postcode": "14150",
    "districtCode": "1505",
    "districtNameTh": "แสวงหา",
    "districtNameEn": "Sawaeng Ha",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150502",
    "subDistrictNameTh": "ศรีพราน",
    "subDistrictNameEn": "Si Phran",
    "postcode": "14150",
    "districtCode": "1505",
    "districtNameTh": "แสวงหา",
    "districtNameEn": "Sawaeng Ha",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150503",
    "subDistrictNameTh": "บ้านพราน",
    "subDistrictNameEn": "Ban Phran",
    "postcode": "14150",
    "districtCode": "1505",
    "districtNameTh": "แสวงหา",
    "districtNameEn": "Sawaeng Ha",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150504",
    "subDistrictNameTh": "วังน้ำเย็น",
    "subDistrictNameEn": "Wang Nam Yen",
    "postcode": "14150",
    "districtCode": "1505",
    "districtNameTh": "แสวงหา",
    "districtNameEn": "Sawaeng Ha",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150505",
    "subDistrictNameTh": "สีบัวทอง",
    "subDistrictNameEn": "Si Bua Thong",
    "postcode": "14150",
    "districtCode": "1505",
    "districtNameTh": "แสวงหา",
    "districtNameEn": "Sawaeng Ha",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150506",
    "subDistrictNameTh": "ห้วยไผ่",
    "subDistrictNameEn": "Huai Phai",
    "postcode": "14150",
    "districtCode": "1505",
    "districtNameTh": "แสวงหา",
    "districtNameEn": "Sawaeng Ha",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150507",
    "subDistrictNameTh": "จำลอง",
    "subDistrictNameEn": "Chamlong",
    "postcode": "14150",
    "districtCode": "1505",
    "districtNameTh": "แสวงหา",
    "districtNameEn": "Sawaeng Ha",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150601",
    "subDistrictNameTh": "ไผ่จำศีล",
    "subDistrictNameEn": "Phai Cham Sin",
    "postcode": "14110",
    "districtCode": "1506",
    "districtNameTh": "วิเศษชัยชาญ",
    "districtNameEn": "Wiset Chai Chan",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150602",
    "subDistrictNameTh": "ศาลเจ้าโรงทอง",
    "subDistrictNameEn": "San Chao Rong Thong",
    "postcode": "14110",
    "districtCode": "1506",
    "districtNameTh": "วิเศษชัยชาญ",
    "districtNameEn": "Wiset Chai Chan",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150603",
    "subDistrictNameTh": "ไผ่ดำพัฒนา",
    "subDistrictNameEn": "Phai Dam Phatthana",
    "postcode": "14110",
    "districtCode": "1506",
    "districtNameTh": "วิเศษชัยชาญ",
    "districtNameEn": "Wiset Chai Chan",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150604",
    "subDistrictNameTh": "สาวร้องไห้",
    "subDistrictNameEn": "Sao Rong Hai",
    "postcode": "14110",
    "districtCode": "1506",
    "districtNameTh": "วิเศษชัยชาญ",
    "districtNameEn": "Wiset Chai Chan",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150605",
    "subDistrictNameTh": "ท่าช้าง",
    "subDistrictNameEn": "Tha Chang",
    "postcode": "14110",
    "districtCode": "1506",
    "districtNameTh": "วิเศษชัยชาญ",
    "districtNameEn": "Wiset Chai Chan",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150606",
    "subDistrictNameTh": "ยี่ล้น",
    "subDistrictNameEn": "Yi Lon",
    "postcode": "14110",
    "districtCode": "1506",
    "districtNameTh": "วิเศษชัยชาญ",
    "districtNameEn": "Wiset Chai Chan",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150607",
    "subDistrictNameTh": "บางจัก",
    "subDistrictNameEn": "Bang Chak",
    "postcode": "14110",
    "districtCode": "1506",
    "districtNameTh": "วิเศษชัยชาญ",
    "districtNameEn": "Wiset Chai Chan",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150608",
    "subDistrictNameTh": "ห้วยคันแหลน",
    "subDistrictNameEn": "Huai Khan Laen",
    "postcode": "14110",
    "districtCode": "1506",
    "districtNameTh": "วิเศษชัยชาญ",
    "districtNameEn": "Wiset Chai Chan",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150609",
    "subDistrictNameTh": "คลองขนาก",
    "subDistrictNameEn": "Khlong Khanak",
    "postcode": "14110",
    "districtCode": "1506",
    "districtNameTh": "วิเศษชัยชาญ",
    "districtNameEn": "Wiset Chai Chan",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150610",
    "subDistrictNameTh": "ไผ่วง",
    "subDistrictNameEn": "Phai Wong",
    "postcode": "14110",
    "districtCode": "1506",
    "districtNameTh": "วิเศษชัยชาญ",
    "districtNameEn": "Wiset Chai Chan",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150611",
    "subDistrictNameTh": "สี่ร้อย",
    "subDistrictNameEn": "Si Roi",
    "postcode": "14110",
    "districtCode": "1506",
    "districtNameTh": "วิเศษชัยชาญ",
    "districtNameEn": "Wiset Chai Chan",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150612",
    "subDistrictNameTh": "ม่วงเตี้ย",
    "subDistrictNameEn": "Muang Tia",
    "postcode": "14110",
    "districtCode": "1506",
    "districtNameTh": "วิเศษชัยชาญ",
    "districtNameEn": "Wiset Chai Chan",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150613",
    "subDistrictNameTh": "หัวตะพาน",
    "subDistrictNameEn": "Hua Taphan",
    "postcode": "14110",
    "districtCode": "1506",
    "districtNameTh": "วิเศษชัยชาญ",
    "districtNameEn": "Wiset Chai Chan",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150614",
    "subDistrictNameTh": "หลักแก้ว",
    "subDistrictNameEn": "Lak Kaeo",
    "postcode": "14110",
    "districtCode": "1506",
    "districtNameTh": "วิเศษชัยชาญ",
    "districtNameEn": "Wiset Chai Chan",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150615",
    "subDistrictNameTh": "ตลาดใหม่",
    "subDistrictNameEn": "Talat Mai",
    "postcode": "14110",
    "districtCode": "1506",
    "districtNameTh": "วิเศษชัยชาญ",
    "districtNameEn": "Wiset Chai Chan",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150701",
    "subDistrictNameTh": "สามโก้",
    "subDistrictNameEn": "Samko",
    "postcode": "14160",
    "districtCode": "1507",
    "districtNameTh": "สามโก้",
    "districtNameEn": "Samko",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150702",
    "subDistrictNameTh": "ราษฎรพัฒนา",
    "subDistrictNameEn": "Ratsadon Phatthana",
    "postcode": "14160",
    "districtCode": "1507",
    "districtNameTh": "สามโก้",
    "districtNameEn": "Samko",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150703",
    "subDistrictNameTh": "อบทม",
    "subDistrictNameEn": "Op Thom",
    "postcode": "14160",
    "districtCode": "1507",
    "districtNameTh": "สามโก้",
    "districtNameEn": "Samko",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150704",
    "subDistrictNameTh": "โพธิ์ม่วงพันธ์",
    "subDistrictNameEn": "Pho Muang Phan",
    "postcode": "14160",
    "districtCode": "1507",
    "districtNameTh": "สามโก้",
    "districtNameEn": "Samko",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "150705",
    "subDistrictNameTh": "มงคลธรรมนิมิต",
    "subDistrictNameEn": "Mongkhon Tham Nimit",
    "postcode": "14160",
    "districtCode": "1507",
    "districtNameTh": "สามโก้",
    "districtNameEn": "Samko",
    "provinceCode": "15",
    "provinceNameTh": "อ่างทอง",
    "provinceNameEn": "Ang Thong"
},
{
    "subDistrictCode": "160101",
    "subDistrictNameTh": "ทะเลชุบศร",
    "subDistrictNameEn": "Thale Chup Son",
    "postcode": "15000",
    "districtCode": "1601",
    "districtNameTh": "เมืองลพบุรี",
    "districtNameEn": "Mueang Lop Buri",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160102",
    "subDistrictNameTh": "ท่าหิน",
    "subDistrictNameEn": "Tha Hin",
    "postcode": "15000",
    "districtCode": "1601",
    "districtNameTh": "เมืองลพบุรี",
    "districtNameEn": "Mueang Lop Buri",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160103",
    "subDistrictNameTh": "กกโก",
    "subDistrictNameEn": "Kok Ko",
    "postcode": "15000",
    "districtCode": "1601",
    "districtNameTh": "เมืองลพบุรี",
    "districtNameEn": "Mueang Lop Buri",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160104",
    "subDistrictNameTh": "โก่งธนู",
    "subDistrictNameEn": "Kong Thanu",
    "postcode": "13240",
    "districtCode": "1601",
    "districtNameTh": "เมืองลพบุรี",
    "districtNameEn": "Mueang Lop Buri",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160105",
    "subDistrictNameTh": "เขาพระงาม",
    "subDistrictNameEn": "Khao Phra Ngam",
    "postcode": "15160",
    "districtCode": "1601",
    "districtNameTh": "เมืองลพบุรี",
    "districtNameEn": "Mueang Lop Buri",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160106",
    "subDistrictNameTh": "เขาสามยอด",
    "subDistrictNameEn": "Khao Sam Yot",
    "postcode": "15000",
    "districtCode": "1601",
    "districtNameTh": "เมืองลพบุรี",
    "districtNameEn": "Mueang Lop Buri",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160107",
    "subDistrictNameTh": "โคกกะเทียม",
    "subDistrictNameEn": "Khok Kathiam",
    "postcode": "15160",
    "districtCode": "1601",
    "districtNameTh": "เมืองลพบุรี",
    "districtNameEn": "Mueang Lop Buri",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160108",
    "subDistrictNameTh": "โคกลำพาน",
    "subDistrictNameEn": "Khok Lam Phan",
    "postcode": "15000",
    "districtCode": "1601",
    "districtNameTh": "เมืองลพบุรี",
    "districtNameEn": "Mueang Lop Buri",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160109",
    "subDistrictNameTh": "โคกตูม",
    "subDistrictNameEn": "Khok Tum",
    "postcode": "15210",
    "districtCode": "1601",
    "districtNameTh": "เมืองลพบุรี",
    "districtNameEn": "Mueang Lop Buri",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160110",
    "subDistrictNameTh": "งิ้วราย",
    "subDistrictNameEn": "Ngio Rai",
    "postcode": "15000",
    "districtCode": "1601",
    "districtNameTh": "เมืองลพบุรี",
    "districtNameEn": "Mueang Lop Buri",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160111",
    "subDistrictNameTh": "ดอนโพธิ์",
    "subDistrictNameEn": "Don Pho",
    "postcode": "15000",
    "districtCode": "1601",
    "districtNameTh": "เมืองลพบุรี",
    "districtNameEn": "Mueang Lop Buri",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160112",
    "subDistrictNameTh": "ตะลุง",
    "subDistrictNameEn": "Talung",
    "postcode": "15000",
    "districtCode": "1601",
    "districtNameTh": "เมืองลพบุรี",
    "districtNameEn": "Mueang Lop Buri",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160114",
    "subDistrictNameTh": "ท่าแค",
    "subDistrictNameEn": "Tha Khae",
    "postcode": "15000",
    "districtCode": "1601",
    "districtNameTh": "เมืองลพบุรี",
    "districtNameEn": "Mueang Lop Buri",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160115",
    "subDistrictNameTh": "ท่าศาลา",
    "subDistrictNameEn": "Tha Sala",
    "postcode": "15000",
    "districtCode": "1601",
    "districtNameTh": "เมืองลพบุรี",
    "districtNameEn": "Mueang Lop Buri",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160116",
    "subDistrictNameTh": "นิคมสร้างตนเอง",
    "subDistrictNameEn": "Nikhom Sang Ton Eng",
    "postcode": "15000",
    "districtCode": "1601",
    "districtNameTh": "เมืองลพบุรี",
    "districtNameEn": "Mueang Lop Buri",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160117",
    "subDistrictNameTh": "บางขันหมาก",
    "subDistrictNameEn": "Bang Khan Mak",
    "postcode": "15000",
    "districtCode": "1601",
    "districtNameTh": "เมืองลพบุรี",
    "districtNameEn": "Mueang Lop Buri",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160118",
    "subDistrictNameTh": "บ้านข่อย",
    "subDistrictNameEn": "Ban Khoi",
    "postcode": "15000",
    "districtCode": "1601",
    "districtNameTh": "เมืองลพบุรี",
    "districtNameEn": "Mueang Lop Buri",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160119",
    "subDistrictNameTh": "ท้ายตลาด",
    "subDistrictNameEn": "Thai Talat",
    "postcode": "15000",
    "districtCode": "1601",
    "districtNameTh": "เมืองลพบุรี",
    "districtNameEn": "Mueang Lop Buri",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160120",
    "subDistrictNameTh": "ป่าตาล",
    "subDistrictNameEn": "Pa Tan",
    "postcode": "15000",
    "districtCode": "1601",
    "districtNameTh": "เมืองลพบุรี",
    "districtNameEn": "Mueang Lop Buri",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160121",
    "subDistrictNameTh": "พรหมมาสตร์",
    "subDistrictNameEn": "Phrommat",
    "postcode": "15000",
    "districtCode": "1601",
    "districtNameTh": "เมืองลพบุรี",
    "districtNameEn": "Mueang Lop Buri",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160122",
    "subDistrictNameTh": "โพธิ์เก้าต้น",
    "subDistrictNameEn": "Pho Kao Ton",
    "postcode": "15000",
    "districtCode": "1601",
    "districtNameTh": "เมืองลพบุรี",
    "districtNameEn": "Mueang Lop Buri",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160123",
    "subDistrictNameTh": "โพธิ์ตรุ",
    "subDistrictNameEn": "Pho Tru",
    "postcode": "15000",
    "districtCode": "1601",
    "districtNameTh": "เมืองลพบุรี",
    "districtNameEn": "Mueang Lop Buri",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160124",
    "subDistrictNameTh": "สี่คลอง",
    "subDistrictNameEn": "Si Khlong",
    "postcode": "15000",
    "districtCode": "1601",
    "districtNameTh": "เมืองลพบุรี",
    "districtNameEn": "Mueang Lop Buri",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160125",
    "subDistrictNameTh": "ถนนใหญ่",
    "subDistrictNameEn": "Thanon Yai",
    "postcode": "15000",
    "districtCode": "1601",
    "districtNameTh": "เมืองลพบุรี",
    "districtNameEn": "Mueang Lop Buri",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160201",
    "subDistrictNameTh": "พัฒนานิคม",
    "subDistrictNameEn": "Phatthana Nikhom",
    "postcode": "15140",
    "districtCode": "1602",
    "districtNameTh": "พัฒนานิคม",
    "districtNameEn": "Phatthana Nikhom",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160202",
    "subDistrictNameTh": "ช่องสาริกา",
    "subDistrictNameEn": "Chong Sarika",
    "postcode": "15220",
    "districtCode": "1602",
    "districtNameTh": "พัฒนานิคม",
    "districtNameEn": "Phatthana Nikhom",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160203",
    "subDistrictNameTh": "มะนาวหวาน",
    "subDistrictNameEn": "Manao Wan",
    "postcode": "15140",
    "districtCode": "1602",
    "districtNameTh": "พัฒนานิคม",
    "districtNameEn": "Phatthana Nikhom",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160204",
    "subDistrictNameTh": "ดีลัง",
    "subDistrictNameEn": "Di Lang",
    "postcode": "15220",
    "districtCode": "1602",
    "districtNameTh": "พัฒนานิคม",
    "districtNameEn": "Phatthana Nikhom",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160205",
    "subDistrictNameTh": "โคกสลุง",
    "subDistrictNameEn": "Khok Salung",
    "postcode": "15140",
    "districtCode": "1602",
    "districtNameTh": "พัฒนานิคม",
    "districtNameEn": "Phatthana Nikhom",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160206",
    "subDistrictNameTh": "ชอนน้อย",
    "subDistrictNameEn": "Chon Noi",
    "postcode": "15140",
    "districtCode": "1602",
    "districtNameTh": "พัฒนานิคม",
    "districtNameEn": "Phatthana Nikhom",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160207",
    "subDistrictNameTh": "หนองบัว",
    "subDistrictNameEn": "Nong Bua",
    "postcode": "15140",
    "districtCode": "1602",
    "districtNameTh": "พัฒนานิคม",
    "districtNameEn": "Phatthana Nikhom",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160208",
    "subDistrictNameTh": "ห้วยขุนราม",
    "subDistrictNameEn": "Huai Khun Ram",
    "postcode": "18220",
    "districtCode": "1602",
    "districtNameTh": "พัฒนานิคม",
    "districtNameEn": "Phatthana Nikhom",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160209",
    "subDistrictNameTh": "น้ำสุด",
    "subDistrictNameEn": "Nam Sut",
    "postcode": "15140",
    "districtCode": "1602",
    "districtNameTh": "พัฒนานิคม",
    "districtNameEn": "Phatthana Nikhom",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160301",
    "subDistrictNameTh": "โคกสำโรง",
    "subDistrictNameEn": "Khok Samrong",
    "postcode": "15120",
    "districtCode": "1603",
    "districtNameTh": "โคกสำโรง",
    "districtNameEn": "Khok Samrong",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160302",
    "subDistrictNameTh": "เกาะแก้ว",
    "subDistrictNameEn": "Ko Kaeo",
    "postcode": "15120",
    "districtCode": "1603",
    "districtNameTh": "โคกสำโรง",
    "districtNameEn": "Khok Samrong",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160303",
    "subDistrictNameTh": "ถลุงเหล็ก",
    "subDistrictNameEn": "Thalung Lek",
    "postcode": "15120",
    "districtCode": "1603",
    "districtNameTh": "โคกสำโรง",
    "districtNameEn": "Khok Samrong",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160304",
    "subDistrictNameTh": "หลุมข้าว",
    "subDistrictNameEn": "Lum Khao",
    "postcode": "15120",
    "districtCode": "1603",
    "districtNameTh": "โคกสำโรง",
    "districtNameEn": "Khok Samrong",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160305",
    "subDistrictNameTh": "ห้วยโป่ง",
    "subDistrictNameEn": "Huai Pong",
    "postcode": "15120",
    "districtCode": "1603",
    "districtNameTh": "โคกสำโรง",
    "districtNameEn": "Khok Samrong",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160306",
    "subDistrictNameTh": "คลองเกตุ",
    "subDistrictNameEn": "Khlong Ket",
    "postcode": "15120",
    "districtCode": "1603",
    "districtNameTh": "โคกสำโรง",
    "districtNameEn": "Khok Samrong",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160307",
    "subDistrictNameTh": "สะแกราบ",
    "subDistrictNameEn": "Sakae Rap",
    "postcode": "15120",
    "districtCode": "1603",
    "districtNameTh": "โคกสำโรง",
    "districtNameEn": "Khok Samrong",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160308",
    "subDistrictNameTh": "เพนียด",
    "subDistrictNameEn": "Phaniat",
    "postcode": "15120",
    "districtCode": "1603",
    "districtNameTh": "โคกสำโรง",
    "districtNameEn": "Khok Samrong",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160309",
    "subDistrictNameTh": "วังเพลิง",
    "subDistrictNameEn": "Wang Phloeng",
    "postcode": "15120",
    "districtCode": "1603",
    "districtNameTh": "โคกสำโรง",
    "districtNameEn": "Khok Samrong",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160310",
    "subDistrictNameTh": "ดงมะรุม",
    "subDistrictNameEn": "Dong Marum",
    "postcode": "15120",
    "districtCode": "1603",
    "districtNameTh": "โคกสำโรง",
    "districtNameEn": "Khok Samrong",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160318",
    "subDistrictNameTh": "วังขอนขว้าง",
    "subDistrictNameEn": "Wang Khon Khwang",
    "postcode": "15120",
    "districtCode": "1603",
    "districtNameTh": "โคกสำโรง",
    "districtNameEn": "Khok Samrong",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160320",
    "subDistrictNameTh": "วังจั่น",
    "subDistrictNameEn": "Wang Chan",
    "postcode": "15120",
    "districtCode": "1603",
    "districtNameTh": "โคกสำโรง",
    "districtNameEn": "Khok Samrong",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160322",
    "subDistrictNameTh": "หนองแขม",
    "subDistrictNameEn": "Nong Khaem",
    "postcode": "15120",
    "districtCode": "1603",
    "districtNameTh": "โคกสำโรง",
    "districtNameEn": "Khok Samrong",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160401",
    "subDistrictNameTh": "ลำนารายณ์",
    "subDistrictNameEn": "Lam Narai",
    "postcode": "15130",
    "districtCode": "1604",
    "districtNameTh": "ชัยบาดาล",
    "districtNameEn": "Chai Badan",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160402",
    "subDistrictNameTh": "ชัยนารายณ์",
    "subDistrictNameEn": "Chai Narai",
    "postcode": "15130",
    "districtCode": "1604",
    "districtNameTh": "ชัยบาดาล",
    "districtNameEn": "Chai Badan",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160403",
    "subDistrictNameTh": "ศิลาทิพย์",
    "subDistrictNameEn": "Sila Thip",
    "postcode": "15130",
    "districtCode": "1604",
    "districtNameTh": "ชัยบาดาล",
    "districtNameEn": "Chai Badan",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160404",
    "subDistrictNameTh": "ห้วยหิน",
    "subDistrictNameEn": "Huai Hin",
    "postcode": "15130",
    "districtCode": "1604",
    "districtNameTh": "ชัยบาดาล",
    "districtNameEn": "Chai Badan",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160405",
    "subDistrictNameTh": "ม่วงค่อม",
    "subDistrictNameEn": "Muang Khom",
    "postcode": "15230",
    "districtCode": "1604",
    "districtNameTh": "ชัยบาดาล",
    "districtNameEn": "Chai Badan",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160406",
    "subDistrictNameTh": "บัวชุม",
    "subDistrictNameEn": "Bua Chum",
    "postcode": "15130",
    "districtCode": "1604",
    "districtNameTh": "ชัยบาดาล",
    "districtNameEn": "Chai Badan",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160407",
    "subDistrictNameTh": "ท่าดินดำ",
    "subDistrictNameEn": "Tha Din Dam",
    "postcode": "15130",
    "districtCode": "1604",
    "districtNameTh": "ชัยบาดาล",
    "districtNameEn": "Chai Badan",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160408",
    "subDistrictNameTh": "มะกอกหวาน",
    "subDistrictNameEn": "Makok Wan",
    "postcode": "15230",
    "districtCode": "1604",
    "districtNameTh": "ชัยบาดาล",
    "districtNameEn": "Chai Badan",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160409",
    "subDistrictNameTh": "ซับตะเคียน",
    "subDistrictNameEn": "Sap Takhian",
    "postcode": "15130",
    "districtCode": "1604",
    "districtNameTh": "ชัยบาดาล",
    "districtNameEn": "Chai Badan",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160410",
    "subDistrictNameTh": "นาโสม",
    "subDistrictNameEn": "Na Som",
    "postcode": "15190",
    "districtCode": "1604",
    "districtNameTh": "ชัยบาดาล",
    "districtNameEn": "Chai Badan",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160411",
    "subDistrictNameTh": "หนองยายโต๊ะ",
    "subDistrictNameEn": "Nong Yai To",
    "postcode": "15130",
    "districtCode": "1604",
    "districtNameTh": "ชัยบาดาล",
    "districtNameEn": "Chai Badan",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160412",
    "subDistrictNameTh": "เกาะรัง",
    "subDistrictNameEn": "Ko Rang",
    "postcode": "15130",
    "districtCode": "1604",
    "districtNameTh": "ชัยบาดาล",
    "districtNameEn": "Chai Badan",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160414",
    "subDistrictNameTh": "ท่ามะนาว",
    "subDistrictNameEn": "Tha Manao",
    "postcode": "15130",
    "districtCode": "1604",
    "districtNameTh": "ชัยบาดาล",
    "districtNameEn": "Chai Badan",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160417",
    "subDistrictNameTh": "นิคมลำนารายณ์",
    "subDistrictNameEn": "Nikhom Lam Narai",
    "postcode": "15130",
    "districtCode": "1604",
    "districtNameTh": "ชัยบาดาล",
    "districtNameEn": "Chai Badan",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160418",
    "subDistrictNameTh": "ชัยบาดาล",
    "subDistrictNameEn": "Chai Badan",
    "postcode": "15230",
    "districtCode": "1604",
    "districtNameTh": "ชัยบาดาล",
    "districtNameEn": "Chai Badan",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160419",
    "subDistrictNameTh": "บ้านใหม่สามัคคี",
    "subDistrictNameEn": "Ban Mai Samakkhi",
    "postcode": "15130",
    "districtCode": "1604",
    "districtNameTh": "ชัยบาดาล",
    "districtNameEn": "Chai Badan",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160422",
    "subDistrictNameTh": "เขาแหลม",
    "subDistrictNameEn": "Khao Laem",
    "postcode": "15130",
    "districtCode": "1604",
    "districtNameTh": "ชัยบาดาล",
    "districtNameEn": "Chai Badan",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160501",
    "subDistrictNameTh": "ท่าวุ้ง",
    "subDistrictNameEn": "Tha Wung",
    "postcode": "15150",
    "districtCode": "1605",
    "districtNameTh": "ท่าวุ้ง",
    "districtNameEn": "Tha Wung",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160502",
    "subDistrictNameTh": "บางคู้",
    "subDistrictNameEn": "Bang Khu",
    "postcode": "15150",
    "districtCode": "1605",
    "districtNameTh": "ท่าวุ้ง",
    "districtNameEn": "Tha Wung",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160503",
    "subDistrictNameTh": "โพตลาดแก้ว",
    "subDistrictNameEn": "Pho Talat Kaeo",
    "postcode": "15150",
    "districtCode": "1605",
    "districtNameTh": "ท่าวุ้ง",
    "districtNameEn": "Tha Wung",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160504",
    "subDistrictNameTh": "บางลี่",
    "subDistrictNameEn": "Bang Li",
    "postcode": "15150",
    "districtCode": "1605",
    "districtNameTh": "ท่าวุ้ง",
    "districtNameEn": "Tha Wung",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160505",
    "subDistrictNameTh": "บางงา",
    "subDistrictNameEn": "Bang Nga",
    "postcode": "15150",
    "districtCode": "1605",
    "districtNameTh": "ท่าวุ้ง",
    "districtNameEn": "Tha Wung",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160506",
    "subDistrictNameTh": "โคกสลุด",
    "subDistrictNameEn": "Khok Salut",
    "postcode": "15150",
    "districtCode": "1605",
    "districtNameTh": "ท่าวุ้ง",
    "districtNameEn": "Tha Wung",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160507",
    "subDistrictNameTh": "เขาสมอคอน",
    "subDistrictNameEn": "Khao Samo Khon",
    "postcode": "15180",
    "districtCode": "1605",
    "districtNameTh": "ท่าวุ้ง",
    "districtNameEn": "Tha Wung",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160508",
    "subDistrictNameTh": "หัวสำโรง",
    "subDistrictNameEn": "Hua Samrong",
    "postcode": "15150",
    "districtCode": "1605",
    "districtNameTh": "ท่าวุ้ง",
    "districtNameEn": "Tha Wung",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160509",
    "subDistrictNameTh": "ลาดสาลี่",
    "subDistrictNameEn": "Lat Sali",
    "postcode": "15150",
    "districtCode": "1605",
    "districtNameTh": "ท่าวุ้ง",
    "districtNameEn": "Tha Wung",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160510",
    "subDistrictNameTh": "บ้านเบิก",
    "subDistrictNameEn": "Ban Boek",
    "postcode": "15150",
    "districtCode": "1605",
    "districtNameTh": "ท่าวุ้ง",
    "districtNameEn": "Tha Wung",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160511",
    "subDistrictNameTh": "มุจลินท์",
    "subDistrictNameEn": "Mutchalin",
    "postcode": "15150",
    "districtCode": "1605",
    "districtNameTh": "ท่าวุ้ง",
    "districtNameEn": "Tha Wung",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160601",
    "subDistrictNameTh": "ไผ่ใหญ่",
    "subDistrictNameEn": "Phai Yai",
    "postcode": "15110",
    "districtCode": "1606",
    "districtNameTh": "บ้านหมี่",
    "districtNameEn": "Ban Mi",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160602",
    "subDistrictNameTh": "บ้านทราย",
    "subDistrictNameEn": "Ban Sai",
    "postcode": "15110",
    "districtCode": "1606",
    "districtNameTh": "บ้านหมี่",
    "districtNameEn": "Ban Mi",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160603",
    "subDistrictNameTh": "บ้านกล้วย",
    "subDistrictNameEn": "Ban Kluai",
    "postcode": "15110",
    "districtCode": "1606",
    "districtNameTh": "บ้านหมี่",
    "districtNameEn": "Ban Mi",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160604",
    "subDistrictNameTh": "ดงพลับ",
    "subDistrictNameEn": "Dong Phlap",
    "postcode": "15110",
    "districtCode": "1606",
    "districtNameTh": "บ้านหมี่",
    "districtNameEn": "Ban Mi",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160605",
    "subDistrictNameTh": "บ้านชี",
    "subDistrictNameEn": "Ban Chi",
    "postcode": "15180",
    "districtCode": "1606",
    "districtNameTh": "บ้านหมี่",
    "districtNameEn": "Ban Mi",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160606",
    "subDistrictNameTh": "พุคา",
    "subDistrictNameEn": "Phu Kha",
    "postcode": "15110",
    "districtCode": "1606",
    "districtNameTh": "บ้านหมี่",
    "districtNameEn": "Ban Mi",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160607",
    "subDistrictNameTh": "หินปัก",
    "subDistrictNameEn": "Hin Pak",
    "postcode": "15110",
    "districtCode": "1606",
    "districtNameTh": "บ้านหมี่",
    "districtNameEn": "Ban Mi",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160608",
    "subDistrictNameTh": "บางพึ่ง",
    "subDistrictNameEn": "Bang Phueng",
    "postcode": "15110",
    "districtCode": "1606",
    "districtNameTh": "บ้านหมี่",
    "districtNameEn": "Ban Mi",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160609",
    "subDistrictNameTh": "หนองทรายขาว",
    "subDistrictNameEn": "Nong Sai Khao",
    "postcode": "15110",
    "districtCode": "1606",
    "districtNameTh": "บ้านหมี่",
    "districtNameEn": "Ban Mi",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160610",
    "subDistrictNameTh": "บางกะพี้",
    "subDistrictNameEn": "Bang Kaphi",
    "postcode": "15110",
    "districtCode": "1606",
    "districtNameTh": "บ้านหมี่",
    "districtNameEn": "Ban Mi",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160611",
    "subDistrictNameTh": "หนองเต่า",
    "subDistrictNameEn": "Nong Tao",
    "postcode": "15110",
    "districtCode": "1606",
    "districtNameTh": "บ้านหมี่",
    "districtNameEn": "Ban Mi",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160612",
    "subDistrictNameTh": "โพนทอง",
    "subDistrictNameEn": "Phon Thong",
    "postcode": "15110",
    "districtCode": "1606",
    "districtNameTh": "บ้านหมี่",
    "districtNameEn": "Ban Mi",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160613",
    "subDistrictNameTh": "บางขาม",
    "subDistrictNameEn": "Bang Kham",
    "postcode": "15180",
    "districtCode": "1606",
    "districtNameTh": "บ้านหมี่",
    "districtNameEn": "Ban Mi",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160614",
    "subDistrictNameTh": "ดอนดึง",
    "subDistrictNameEn": "Don Dueng",
    "postcode": "15110",
    "districtCode": "1606",
    "districtNameTh": "บ้านหมี่",
    "districtNameEn": "Ban Mi",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160615",
    "subDistrictNameTh": "ชอนม่วง",
    "subDistrictNameEn": "Chon Muang",
    "postcode": "15110",
    "districtCode": "1606",
    "districtNameTh": "บ้านหมี่",
    "districtNameEn": "Ban Mi",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160616",
    "subDistrictNameTh": "หนองกระเบียน",
    "subDistrictNameEn": "Nong Krabian",
    "postcode": "15110",
    "districtCode": "1606",
    "districtNameTh": "บ้านหมี่",
    "districtNameEn": "Ban Mi",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160617",
    "subDistrictNameTh": "สายห้วยแก้ว",
    "subDistrictNameEn": "Sai Huai Kaeo",
    "postcode": "15110",
    "districtCode": "1606",
    "districtNameTh": "บ้านหมี่",
    "districtNameEn": "Ban Mi",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160618",
    "subDistrictNameTh": "มหาสอน",
    "subDistrictNameEn": "Maha Son",
    "postcode": "15110",
    "districtCode": "1606",
    "districtNameTh": "บ้านหมี่",
    "districtNameEn": "Ban Mi",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160619",
    "subDistrictNameTh": "บ้านหมี่",
    "subDistrictNameEn": "Ban Mi",
    "postcode": "15110",
    "districtCode": "1606",
    "districtNameTh": "บ้านหมี่",
    "districtNameEn": "Ban Mi",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160620",
    "subDistrictNameTh": "เชียงงา",
    "subDistrictNameEn": "Chiang Nga",
    "postcode": "15110",
    "districtCode": "1606",
    "districtNameTh": "บ้านหมี่",
    "districtNameEn": "Ban Mi",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160621",
    "subDistrictNameTh": "หนองเมือง",
    "subDistrictNameEn": "Nong Mueang",
    "postcode": "15110",
    "districtCode": "1606",
    "districtNameTh": "บ้านหมี่",
    "districtNameEn": "Ban Mi",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160622",
    "subDistrictNameTh": "สนามแจง",
    "subDistrictNameEn": "Sanam Chaeng",
    "postcode": "15110",
    "districtCode": "1606",
    "districtNameTh": "บ้านหมี่",
    "districtNameEn": "Ban Mi",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160701",
    "subDistrictNameTh": "ท่าหลวง",
    "subDistrictNameEn": "Tha Luang",
    "postcode": "15230",
    "districtCode": "1607",
    "districtNameTh": "ท่าหลวง",
    "districtNameEn": "Tha Luang",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160702",
    "subDistrictNameTh": "แก่งผักกูด",
    "subDistrictNameEn": "Kaeng Phak Kut",
    "postcode": "15230",
    "districtCode": "1607",
    "districtNameTh": "ท่าหลวง",
    "districtNameEn": "Tha Luang",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160703",
    "subDistrictNameTh": "ซับจำปา",
    "subDistrictNameEn": "Sap Champa",
    "postcode": "15230",
    "districtCode": "1607",
    "districtNameTh": "ท่าหลวง",
    "districtNameEn": "Tha Luang",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160704",
    "subDistrictNameTh": "หนองผักแว่น",
    "subDistrictNameEn": "Nong Phak Waen",
    "postcode": "15230",
    "districtCode": "1607",
    "districtNameTh": "ท่าหลวง",
    "districtNameEn": "Tha Luang",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160705",
    "subDistrictNameTh": "ทะเลวังวัด",
    "subDistrictNameEn": "Thale Wang Wat",
    "postcode": "15230",
    "districtCode": "1607",
    "districtNameTh": "ท่าหลวง",
    "districtNameEn": "Tha Luang",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160706",
    "subDistrictNameTh": "หัวลำ",
    "subDistrictNameEn": "Hua Lam",
    "postcode": "15230",
    "districtCode": "1607",
    "districtNameTh": "ท่าหลวง",
    "districtNameEn": "Tha Luang",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160801",
    "subDistrictNameTh": "สระโบสถ์",
    "subDistrictNameEn": "Sa Bot",
    "postcode": "15240",
    "districtCode": "1608",
    "districtNameTh": "สระโบสถ์",
    "districtNameEn": "Sa Bot",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160802",
    "subDistrictNameTh": "มหาโพธิ",
    "subDistrictNameEn": "Maha Phot",
    "postcode": "15240",
    "districtCode": "1608",
    "districtNameTh": "สระโบสถ์",
    "districtNameEn": "Sa Bot",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160803",
    "subDistrictNameTh": "ทุ่งท่าช้าง",
    "subDistrictNameEn": "Thung Tha Chang",
    "postcode": "15240",
    "districtCode": "1608",
    "districtNameTh": "สระโบสถ์",
    "districtNameEn": "Sa Bot",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160804",
    "subDistrictNameTh": "ห้วยใหญ่",
    "subDistrictNameEn": "Huai Yai",
    "postcode": "15240",
    "districtCode": "1608",
    "districtNameTh": "สระโบสถ์",
    "districtNameEn": "Sa Bot",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160805",
    "subDistrictNameTh": "นิยมชัย",
    "subDistrictNameEn": "Niyom Chai",
    "postcode": "15240",
    "districtCode": "1608",
    "districtNameTh": "สระโบสถ์",
    "districtNameEn": "Sa Bot",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160901",
    "subDistrictNameTh": "โคกเจริญ",
    "subDistrictNameEn": "Khok Charoen",
    "postcode": "15250",
    "districtCode": "1609",
    "districtNameTh": "โคกเจริญ",
    "districtNameEn": "Khok Charoen",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160902",
    "subDistrictNameTh": "ยางราก",
    "subDistrictNameEn": "Yang Rak",
    "postcode": "15250",
    "districtCode": "1609",
    "districtNameTh": "โคกเจริญ",
    "districtNameEn": "Khok Charoen",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160903",
    "subDistrictNameTh": "หนองมะค่า",
    "subDistrictNameEn": "Nong Makha",
    "postcode": "15250",
    "districtCode": "1609",
    "districtNameTh": "โคกเจริญ",
    "districtNameEn": "Khok Charoen",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160904",
    "subDistrictNameTh": "วังทอง",
    "subDistrictNameEn": "Wang Thong",
    "postcode": "15250",
    "districtCode": "1609",
    "districtNameTh": "โคกเจริญ",
    "districtNameEn": "Khok Charoen",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "160905",
    "subDistrictNameTh": "โคกแสมสาร",
    "subDistrictNameEn": "Khok Samae San",
    "postcode": "15250",
    "districtCode": "1609",
    "districtNameTh": "โคกเจริญ",
    "districtNameEn": "Khok Charoen",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "161001",
    "subDistrictNameTh": "ลำสนธิ",
    "subDistrictNameEn": "Lam Sonthi",
    "postcode": "15190",
    "districtCode": "1610",
    "districtNameTh": "ลำสนธิ",
    "districtNameEn": "Lam Sonthi",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "161002",
    "subDistrictNameTh": "ซับสมบูรณ์",
    "subDistrictNameEn": "Sap Sombun",
    "postcode": "15190",
    "districtCode": "1610",
    "districtNameTh": "ลำสนธิ",
    "districtNameEn": "Lam Sonthi",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "161003",
    "subDistrictNameTh": "หนองรี",
    "subDistrictNameEn": "Nong Ri",
    "postcode": "15190",
    "districtCode": "1610",
    "districtNameTh": "ลำสนธิ",
    "districtNameEn": "Lam Sonthi",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "161004",
    "subDistrictNameTh": "กุดตาเพชร",
    "subDistrictNameEn": "Kut Ta Phet",
    "postcode": "15190",
    "districtCode": "1610",
    "districtNameTh": "ลำสนธิ",
    "districtNameEn": "Lam Sonthi",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "161005",
    "subDistrictNameTh": "เขารวก",
    "subDistrictNameEn": "Khao Ruak",
    "postcode": "15190",
    "districtCode": "1610",
    "districtNameTh": "ลำสนธิ",
    "districtNameEn": "Lam Sonthi",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "161006",
    "subDistrictNameTh": "เขาน้อย",
    "subDistrictNameEn": "Khao Noi",
    "postcode": "15130",
    "districtCode": "1610",
    "districtNameTh": "ลำสนธิ",
    "districtNameEn": "Lam Sonthi",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "161101",
    "subDistrictNameTh": "หนองม่วง",
    "subDistrictNameEn": "Nong Muang",
    "postcode": "15170",
    "districtCode": "1611",
    "districtNameTh": "หนองม่วง",
    "districtNameEn": "Nong Muang",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "161102",
    "subDistrictNameTh": "บ่อทอง",
    "subDistrictNameEn": "Bo Thong",
    "postcode": "15170",
    "districtCode": "1611",
    "districtNameTh": "หนองม่วง",
    "districtNameEn": "Nong Muang",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "161103",
    "subDistrictNameTh": "ดงดินแดง",
    "subDistrictNameEn": "Dong Din Daeng",
    "postcode": "15170",
    "districtCode": "1611",
    "districtNameTh": "หนองม่วง",
    "districtNameEn": "Nong Muang",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "161104",
    "subDistrictNameTh": "ชอนสมบูรณ์",
    "subDistrictNameEn": "Chon Sombun",
    "postcode": "15170",
    "districtCode": "1611",
    "districtNameTh": "หนองม่วง",
    "districtNameEn": "Nong Muang",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "161105",
    "subDistrictNameTh": "ยางโทน",
    "subDistrictNameEn": "Yang Thon",
    "postcode": "15170",
    "districtCode": "1611",
    "districtNameTh": "หนองม่วง",
    "districtNameEn": "Nong Muang",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "161106",
    "subDistrictNameTh": "ชอนสารเดช",
    "subDistrictNameEn": "Chon Saradet",
    "postcode": "15170",
    "districtCode": "1611",
    "districtNameTh": "หนองม่วง",
    "districtNameEn": "Nong Muang",
    "provinceCode": "16",
    "provinceNameTh": "ลพบุรี",
    "provinceNameEn": "Lop Buri"
},
{
    "subDistrictCode": "170101",
    "subDistrictNameTh": "บางพุทรา",
    "subDistrictNameEn": "Bang Phutsa",
    "postcode": "16000",
    "districtCode": "1701",
    "districtNameTh": "เมืองสิงห์บุรี",
    "districtNameEn": "Mueang Sing Buri",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "subDistrictCode": "170102",
    "subDistrictNameTh": "บางมัญ",
    "subDistrictNameEn": "Bang Man",
    "postcode": "16000",
    "districtCode": "1701",
    "districtNameTh": "เมืองสิงห์บุรี",
    "districtNameEn": "Mueang Sing Buri",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "subDistrictCode": "170103",
    "subDistrictNameTh": "โพกรวม",
    "subDistrictNameEn": "Phok Ruam",
    "postcode": "16000",
    "districtCode": "1701",
    "districtNameTh": "เมืองสิงห์บุรี",
    "districtNameEn": "Mueang Sing Buri",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "subDistrictCode": "170104",
    "subDistrictNameTh": "ม่วงหมู่",
    "subDistrictNameEn": "Muang Mu",
    "postcode": "16000",
    "districtCode": "1701",
    "districtNameTh": "เมืองสิงห์บุรี",
    "districtNameEn": "Mueang Sing Buri",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "subDistrictCode": "170105",
    "subDistrictNameTh": "หัวไผ่",
    "subDistrictNameEn": "Hua Phai",
    "postcode": "16000",
    "districtCode": "1701",
    "districtNameTh": "เมืองสิงห์บุรี",
    "districtNameEn": "Mueang Sing Buri",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "subDistrictCode": "170106",
    "subDistrictNameTh": "ต้นโพธิ์",
    "subDistrictNameEn": "Ton Pho",
    "postcode": "16000",
    "districtCode": "1701",
    "districtNameTh": "เมืองสิงห์บุรี",
    "districtNameEn": "Mueang Sing Buri",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "subDistrictCode": "170107",
    "subDistrictNameTh": "จักรสีห์",
    "subDistrictNameEn": "Chaksi",
    "postcode": "16000",
    "districtCode": "1701",
    "districtNameTh": "เมืองสิงห์บุรี",
    "districtNameEn": "Mueang Sing Buri",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "subDistrictCode": "170108",
    "subDistrictNameTh": "บางกระบือ",
    "subDistrictNameEn": "Bang Krabue",
    "postcode": "16000",
    "districtCode": "1701",
    "districtNameTh": "เมืองสิงห์บุรี",
    "districtNameEn": "Mueang Sing Buri",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "subDistrictCode": "170201",
    "subDistrictNameTh": "สิงห์",
    "subDistrictNameEn": "Sing",
    "postcode": "16130",
    "districtCode": "1702",
    "districtNameTh": "บางระจัน",
    "districtNameEn": "Bang Rachan",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "subDistrictCode": "170202",
    "subDistrictNameTh": "ไม้ดัด",
    "subDistrictNameEn": "Mai Dat",
    "postcode": "16130",
    "districtCode": "1702",
    "districtNameTh": "บางระจัน",
    "districtNameEn": "Bang Rachan",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "subDistrictCode": "170203",
    "subDistrictNameTh": "เชิงกลัด",
    "subDistrictNameEn": "Choeng Klat",
    "postcode": "16130",
    "districtCode": "1702",
    "districtNameTh": "บางระจัน",
    "districtNameEn": "Bang Rachan",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "subDistrictCode": "170204",
    "subDistrictNameTh": "โพชนไก่",
    "subDistrictNameEn": "Pho Chon Kai",
    "postcode": "16130",
    "districtCode": "1702",
    "districtNameTh": "บางระจัน",
    "districtNameEn": "Bang Rachan",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "subDistrictCode": "170205",
    "subDistrictNameTh": "แม่ลา",
    "subDistrictNameEn": "Mae La",
    "postcode": "16130",
    "districtCode": "1702",
    "districtNameTh": "บางระจัน",
    "districtNameEn": "Bang Rachan",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "subDistrictCode": "170206",
    "subDistrictNameTh": "บ้านจ่า",
    "subDistrictNameEn": "Ban Cha",
    "postcode": "16130",
    "districtCode": "1702",
    "districtNameTh": "บางระจัน",
    "districtNameEn": "Bang Rachan",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "subDistrictCode": "170207",
    "subDistrictNameTh": "พักทัน",
    "subDistrictNameEn": "Phak Than",
    "postcode": "16130",
    "districtCode": "1702",
    "districtNameTh": "บางระจัน",
    "districtNameEn": "Bang Rachan",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "subDistrictCode": "170208",
    "subDistrictNameTh": "สระแจง",
    "subDistrictNameEn": "Sa Chaeng",
    "postcode": "16130",
    "districtCode": "1702",
    "districtNameTh": "บางระจัน",
    "districtNameEn": "Bang Rachan",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "subDistrictCode": "170301",
    "subDistrictNameTh": "โพทะเล",
    "subDistrictNameEn": "Pho Thale",
    "postcode": "16150",
    "districtCode": "1703",
    "districtNameTh": "ค่ายบางระจัน",
    "districtNameEn": "Khai Bang Rachan",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "subDistrictCode": "170302",
    "subDistrictNameTh": "บางระจัน",
    "subDistrictNameEn": "Bang Rachan",
    "postcode": "16150",
    "districtCode": "1703",
    "districtNameTh": "ค่ายบางระจัน",
    "districtNameEn": "Khai Bang Rachan",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "subDistrictCode": "170303",
    "subDistrictNameTh": "โพสังโฆ",
    "subDistrictNameEn": "Pho Sangkho",
    "postcode": "16150",
    "districtCode": "1703",
    "districtNameTh": "ค่ายบางระจัน",
    "districtNameEn": "Khai Bang Rachan",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "subDistrictCode": "170304",
    "subDistrictNameTh": "ท่าข้าม",
    "subDistrictNameEn": "Tha Kham",
    "postcode": "16150",
    "districtCode": "1703",
    "districtNameTh": "ค่ายบางระจัน",
    "districtNameEn": "Khai Bang Rachan",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "subDistrictCode": "170305",
    "subDistrictNameTh": "คอทราย",
    "subDistrictNameEn": "Kho Sai",
    "postcode": "16150",
    "districtCode": "1703",
    "districtNameTh": "ค่ายบางระจัน",
    "districtNameEn": "Khai Bang Rachan",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "subDistrictCode": "170306",
    "subDistrictNameTh": "หนองกระทุ่ม",
    "subDistrictNameEn": "Nong Krathum",
    "postcode": "16150",
    "districtCode": "1703",
    "districtNameTh": "ค่ายบางระจัน",
    "districtNameEn": "Khai Bang Rachan",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "subDistrictCode": "170401",
    "subDistrictNameTh": "พระงาม",
    "subDistrictNameEn": "Phra Ngam",
    "postcode": "16120",
    "districtCode": "1704",
    "districtNameTh": "พรหมบุรี",
    "districtNameEn": "Phrom Buri",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "subDistrictCode": "170402",
    "subDistrictNameTh": "พรหมบุรี",
    "subDistrictNameEn": "Phrom Buri",
    "postcode": "16160",
    "districtCode": "1704",
    "districtNameTh": "พรหมบุรี",
    "districtNameEn": "Phrom Buri",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "subDistrictCode": "170403",
    "subDistrictNameTh": "บางน้ำเชี่ยว",
    "subDistrictNameEn": "Bang Nam Chiao",
    "postcode": "16120",
    "districtCode": "1704",
    "districtNameTh": "พรหมบุรี",
    "districtNameEn": "Phrom Buri",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "subDistrictCode": "170404",
    "subDistrictNameTh": "บ้านหม้อ",
    "subDistrictNameEn": "Ban Mo",
    "postcode": "16120",
    "districtCode": "1704",
    "districtNameTh": "พรหมบุรี",
    "districtNameEn": "Phrom Buri",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "subDistrictCode": "170405",
    "subDistrictNameTh": "บ้านแป้ง",
    "subDistrictNameEn": "Ban Paeng",
    "postcode": "16120",
    "districtCode": "1704",
    "districtNameTh": "พรหมบุรี",
    "districtNameEn": "Phrom Buri",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "subDistrictCode": "170406",
    "subDistrictNameTh": "หัวป่า",
    "subDistrictNameEn": "Hua Pa",
    "postcode": "16120",
    "districtCode": "1704",
    "districtNameTh": "พรหมบุรี",
    "districtNameEn": "Phrom Buri",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "subDistrictCode": "170407",
    "subDistrictNameTh": "โรงช้าง",
    "subDistrictNameEn": "Rong Chang",
    "postcode": "16120",
    "districtCode": "1704",
    "districtNameTh": "พรหมบุรี",
    "districtNameEn": "Phrom Buri",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "subDistrictCode": "170501",
    "subDistrictNameTh": "ถอนสมอ",
    "subDistrictNameEn": "Thon Samo",
    "postcode": "16140",
    "districtCode": "1705",
    "districtNameTh": "ท่าช้าง",
    "districtNameEn": "Tha Chang",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "subDistrictCode": "170502",
    "subDistrictNameTh": "โพประจักษ์",
    "subDistrictNameEn": "Pho Prachak",
    "postcode": "16140",
    "districtCode": "1705",
    "districtNameTh": "ท่าช้าง",
    "districtNameEn": "Tha Chang",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "subDistrictCode": "170503",
    "subDistrictNameTh": "วิหารขาว",
    "subDistrictNameEn": "Wihan Khao",
    "postcode": "16140",
    "districtCode": "1705",
    "districtNameTh": "ท่าช้าง",
    "districtNameEn": "Tha Chang",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "subDistrictCode": "170504",
    "subDistrictNameTh": "พิกุลทอง",
    "subDistrictNameEn": "Phikun Thong",
    "postcode": "16140",
    "districtCode": "1705",
    "districtNameTh": "ท่าช้าง",
    "districtNameEn": "Tha Chang",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "subDistrictCode": "170601",
    "subDistrictNameTh": "อินทร์บุรี",
    "subDistrictNameEn": "In Buri",
    "postcode": "16110",
    "districtCode": "1706",
    "districtNameTh": "อินทร์บุรี",
    "districtNameEn": "In Buri",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "subDistrictCode": "170602",
    "subDistrictNameTh": "ประศุก",
    "subDistrictNameEn": "Prasuk",
    "postcode": "16110",
    "districtCode": "1706",
    "districtNameTh": "อินทร์บุรี",
    "districtNameEn": "In Buri",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "subDistrictCode": "170603",
    "subDistrictNameTh": "ทับยา",
    "subDistrictNameEn": "Thap Ya",
    "postcode": "16110",
    "districtCode": "1706",
    "districtNameTh": "อินทร์บุรี",
    "districtNameEn": "In Buri",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "subDistrictCode": "170604",
    "subDistrictNameTh": "งิ้วราย",
    "subDistrictNameEn": "Ngio Rai",
    "postcode": "16110",
    "districtCode": "1706",
    "districtNameTh": "อินทร์บุรี",
    "districtNameEn": "In Buri",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "subDistrictCode": "170605",
    "subDistrictNameTh": "ชีน้ำร้าย",
    "subDistrictNameEn": "Chi Nam Rai",
    "postcode": "16110",
    "districtCode": "1706",
    "districtNameTh": "อินทร์บุรี",
    "districtNameEn": "In Buri",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "subDistrictCode": "170606",
    "subDistrictNameTh": "ท่างาม",
    "subDistrictNameEn": "Tha Ngam",
    "postcode": "16110",
    "districtCode": "1706",
    "districtNameTh": "อินทร์บุรี",
    "districtNameEn": "In Buri",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "subDistrictCode": "170607",
    "subDistrictNameTh": "น้ำตาล",
    "subDistrictNameEn": "Nam Tan",
    "postcode": "16110",
    "districtCode": "1706",
    "districtNameTh": "อินทร์บุรี",
    "districtNameEn": "In Buri",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "subDistrictCode": "170608",
    "subDistrictNameTh": "ทองเอน",
    "subDistrictNameEn": "Thong En",
    "postcode": "16110",
    "districtCode": "1706",
    "districtNameTh": "อินทร์บุรี",
    "districtNameEn": "In Buri",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "subDistrictCode": "170609",
    "subDistrictNameTh": "ห้วยชัน",
    "subDistrictNameEn": "Huai Chan",
    "postcode": "16110",
    "districtCode": "1706",
    "districtNameTh": "อินทร์บุรี",
    "districtNameEn": "In Buri",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "subDistrictCode": "170610",
    "subDistrictNameTh": "โพธิ์ชัย",
    "subDistrictNameEn": "Pho Chai",
    "postcode": "16110",
    "districtCode": "1706",
    "districtNameTh": "อินทร์บุรี",
    "districtNameEn": "In Buri",
    "provinceCode": "17",
    "provinceNameTh": "สิงห์บุรี",
    "provinceNameEn": "Sing Buri"
},
{
    "subDistrictCode": "180101",
    "subDistrictNameTh": "ในเมือง",
    "subDistrictNameEn": "Nai Mueang",
    "postcode": "17000",
    "districtCode": "1801",
    "districtNameTh": "เมืองชัยนาท",
    "districtNameEn": "Mueang Chai Nat",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180102",
    "subDistrictNameTh": "บ้านกล้วย",
    "subDistrictNameEn": "Ban Kuai",
    "postcode": "17000",
    "districtCode": "1801",
    "districtNameTh": "เมืองชัยนาท",
    "districtNameEn": "Mueang Chai Nat",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180103",
    "subDistrictNameTh": "ท่าชัย",
    "subDistrictNameEn": "Tha Chai",
    "postcode": "17000",
    "districtCode": "1801",
    "districtNameTh": "เมืองชัยนาท",
    "districtNameEn": "Mueang Chai Nat",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180104",
    "subDistrictNameTh": "ชัยนาท",
    "subDistrictNameEn": "Chai Nat",
    "postcode": "17000",
    "districtCode": "1801",
    "districtNameTh": "เมืองชัยนาท",
    "districtNameEn": "Mueang Chai Nat",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180105",
    "subDistrictNameTh": "เขาท่าพระ",
    "subDistrictNameEn": "Khao Tha Phra",
    "postcode": "17000",
    "districtCode": "1801",
    "districtNameTh": "เมืองชัยนาท",
    "districtNameEn": "Mueang Chai Nat",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180106",
    "subDistrictNameTh": "หาดท่าเสา",
    "subDistrictNameEn": "Hat Tha Sao",
    "postcode": "17000",
    "districtCode": "1801",
    "districtNameTh": "เมืองชัยนาท",
    "districtNameEn": "Mueang Chai Nat",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180107",
    "subDistrictNameTh": "ธรรมามูล",
    "subDistrictNameEn": "Thammamun",
    "postcode": "17000",
    "districtCode": "1801",
    "districtNameTh": "เมืองชัยนาท",
    "districtNameEn": "Mueang Chai Nat",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180108",
    "subDistrictNameTh": "เสือโฮก",
    "subDistrictNameEn": "Suea Hok",
    "postcode": "17000",
    "districtCode": "1801",
    "districtNameTh": "เมืองชัยนาท",
    "districtNameEn": "Mueang Chai Nat",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180109",
    "subDistrictNameTh": "นางลือ",
    "subDistrictNameEn": "Nang Lue",
    "postcode": "17000",
    "districtCode": "1801",
    "districtNameTh": "เมืองชัยนาท",
    "districtNameEn": "Mueang Chai Nat",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180201",
    "subDistrictNameTh": "คุ้งสำเภา",
    "subDistrictNameEn": "Khung Samphao",
    "postcode": "17110",
    "districtCode": "1802",
    "districtNameTh": "มโนรมย์",
    "districtNameEn": "Manorom",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180202",
    "subDistrictNameTh": "วัดโคก",
    "subDistrictNameEn": "Wat Khok",
    "postcode": "17110",
    "districtCode": "1802",
    "districtNameTh": "มโนรมย์",
    "districtNameEn": "Manorom",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180203",
    "subDistrictNameTh": "ศิลาดาน",
    "subDistrictNameEn": "Sila Dan",
    "postcode": "17110",
    "districtCode": "1802",
    "districtNameTh": "มโนรมย์",
    "districtNameEn": "Manorom",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180204",
    "subDistrictNameTh": "ท่าฉนวน",
    "subDistrictNameEn": "Tha Chanuan",
    "postcode": "17110",
    "districtCode": "1802",
    "districtNameTh": "มโนรมย์",
    "districtNameEn": "Manorom",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180205",
    "subDistrictNameTh": "หางน้ำสาคร",
    "subDistrictNameEn": "Hang Nam Sakhon",
    "postcode": "17170",
    "districtCode": "1802",
    "districtNameTh": "มโนรมย์",
    "districtNameEn": "Manorom",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180206",
    "subDistrictNameTh": "ไร่พัฒนา",
    "subDistrictNameEn": "Rai Phatthana",
    "postcode": "17170",
    "districtCode": "1802",
    "districtNameTh": "มโนรมย์",
    "districtNameEn": "Manorom",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180207",
    "subDistrictNameTh": "อู่ตะเภา",
    "subDistrictNameEn": "U Taphao",
    "postcode": "17170",
    "districtCode": "1802",
    "districtNameTh": "มโนรมย์",
    "districtNameEn": "Manorom",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180301",
    "subDistrictNameTh": "วัดสิงห์",
    "subDistrictNameEn": "Wat Sing",
    "postcode": "17120",
    "districtCode": "1803",
    "districtNameTh": "วัดสิงห์",
    "districtNameEn": "Wat Sing",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180302",
    "subDistrictNameTh": "มะขามเฒ่า",
    "subDistrictNameEn": "Makham Thao",
    "postcode": "17120",
    "districtCode": "1803",
    "districtNameTh": "วัดสิงห์",
    "districtNameEn": "Wat Sing",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180303",
    "subDistrictNameTh": "หนองน้อย",
    "subDistrictNameEn": "Nong Noi",
    "postcode": "17120",
    "districtCode": "1803",
    "districtNameTh": "วัดสิงห์",
    "districtNameEn": "Wat Sing",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180304",
    "subDistrictNameTh": "หนองบัว",
    "subDistrictNameEn": "Nong Bua",
    "postcode": "17120",
    "districtCode": "1803",
    "districtNameTh": "วัดสิงห์",
    "districtNameEn": "Wat Sing",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180306",
    "subDistrictNameTh": "หนองขุ่น",
    "subDistrictNameEn": "Nong Khun",
    "postcode": "17120",
    "districtCode": "1803",
    "districtNameTh": "วัดสิงห์",
    "districtNameEn": "Wat Sing",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180307",
    "subDistrictNameTh": "บ่อแร่",
    "subDistrictNameEn": "Bo Rae",
    "postcode": "17120",
    "districtCode": "1803",
    "districtNameTh": "วัดสิงห์",
    "districtNameEn": "Wat Sing",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180311",
    "subDistrictNameTh": "วังหมัน",
    "subDistrictNameEn": "Wang Man",
    "postcode": "17120",
    "districtCode": "1803",
    "districtNameTh": "วัดสิงห์",
    "districtNameEn": "Wat Sing",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180401",
    "subDistrictNameTh": "สรรพยา",
    "subDistrictNameEn": "Sapphaya",
    "postcode": "17150",
    "districtCode": "1804",
    "districtNameTh": "สรรพยา",
    "districtNameEn": "Sapphaya",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180402",
    "subDistrictNameTh": "ตลุก",
    "subDistrictNameEn": "Taluk",
    "postcode": "17150",
    "districtCode": "1804",
    "districtNameTh": "สรรพยา",
    "districtNameEn": "Sapphaya",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180403",
    "subDistrictNameTh": "เขาแก้ว",
    "subDistrictNameEn": "Khao Kaeo",
    "postcode": "17150",
    "districtCode": "1804",
    "districtNameTh": "สรรพยา",
    "districtNameEn": "Sapphaya",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180404",
    "subDistrictNameTh": "โพนางดำตก",
    "subDistrictNameEn": "Pho Nang Dam Tok",
    "postcode": "17150",
    "districtCode": "1804",
    "districtNameTh": "สรรพยา",
    "districtNameEn": "Sapphaya",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180405",
    "subDistrictNameTh": "โพนางดำออก",
    "subDistrictNameEn": "Pho Nang Dam Ok",
    "postcode": "17150",
    "districtCode": "1804",
    "districtNameTh": "สรรพยา",
    "districtNameEn": "Sapphaya",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180406",
    "subDistrictNameTh": "บางหลวง",
    "subDistrictNameEn": "Bang Luang",
    "postcode": "17150",
    "districtCode": "1804",
    "districtNameTh": "สรรพยา",
    "districtNameEn": "Sapphaya",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180407",
    "subDistrictNameTh": "หาดอาษา",
    "subDistrictNameEn": "Hat Asa",
    "postcode": "17150",
    "districtCode": "1804",
    "districtNameTh": "สรรพยา",
    "districtNameEn": "Sapphaya",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180501",
    "subDistrictNameTh": "แพรกศรีราชา",
    "subDistrictNameEn": "Phraek Si Racha",
    "postcode": "17140",
    "districtCode": "1805",
    "districtNameTh": "สรรคบุรี",
    "districtNameEn": "Sankhaburi",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180502",
    "subDistrictNameTh": "เที่ยงแท้",
    "subDistrictNameEn": "Thiang Thae",
    "postcode": "17140",
    "districtCode": "1805",
    "districtNameTh": "สรรคบุรี",
    "districtNameEn": "Sankhaburi",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180503",
    "subDistrictNameTh": "ห้วยกรด",
    "subDistrictNameEn": "Huai Krot",
    "postcode": "17140",
    "districtCode": "1805",
    "districtNameTh": "สรรคบุรี",
    "districtNameEn": "Sankhaburi",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180504",
    "subDistrictNameTh": "โพงาม",
    "subDistrictNameEn": "Pho Ngam",
    "postcode": "17140",
    "districtCode": "1805",
    "districtNameTh": "สรรคบุรี",
    "districtNameEn": "Sankhaburi",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180505",
    "subDistrictNameTh": "บางขุด",
    "subDistrictNameEn": "Bang Khut",
    "postcode": "17140",
    "districtCode": "1805",
    "districtNameTh": "สรรคบุรี",
    "districtNameEn": "Sankhaburi",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180506",
    "subDistrictNameTh": "ดงคอน",
    "subDistrictNameEn": "Dong Khon",
    "postcode": "17140",
    "districtCode": "1805",
    "districtNameTh": "สรรคบุรี",
    "districtNameEn": "Sankhaburi",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180507",
    "subDistrictNameTh": "ดอนกำ",
    "subDistrictNameEn": "Don Kam",
    "postcode": "17140",
    "districtCode": "1805",
    "districtNameTh": "สรรคบุรี",
    "districtNameEn": "Sankhaburi",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180508",
    "subDistrictNameTh": "ห้วยกรดพัฒนา",
    "subDistrictNameEn": "Huai Krot Phatthana",
    "postcode": "17140",
    "districtCode": "1805",
    "districtNameTh": "สรรคบุรี",
    "districtNameEn": "Sankhaburi",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180601",
    "subDistrictNameTh": "หันคา",
    "subDistrictNameEn": "Hankha",
    "postcode": "17130",
    "districtCode": "1806",
    "districtNameTh": "หันคา",
    "districtNameEn": "Hankha",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180602",
    "subDistrictNameTh": "บ้านเชี่ยน",
    "subDistrictNameEn": "Ban Chian",
    "postcode": "17130",
    "districtCode": "1806",
    "districtNameTh": "หันคา",
    "districtNameEn": "Hankha",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180605",
    "subDistrictNameTh": "ไพรนกยูง",
    "subDistrictNameEn": "Phrai Nok Yung",
    "postcode": "17130",
    "districtCode": "1806",
    "districtNameTh": "หันคา",
    "districtNameEn": "Hankha",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180606",
    "subDistrictNameTh": "หนองแซง",
    "subDistrictNameEn": "Nong Saeng",
    "postcode": "17160",
    "districtCode": "1806",
    "districtNameTh": "หันคา",
    "districtNameEn": "Hankha",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180607",
    "subDistrictNameTh": "ห้วยงู",
    "subDistrictNameEn": "Huai Ngu",
    "postcode": "17160",
    "districtCode": "1806",
    "districtNameTh": "หันคา",
    "districtNameEn": "Hankha",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180608",
    "subDistrictNameTh": "วังไก่เถื่อน",
    "subDistrictNameEn": "Wang Kai Thuean",
    "postcode": "17130",
    "districtCode": "1806",
    "districtNameTh": "หันคา",
    "districtNameEn": "Hankha",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180609",
    "subDistrictNameTh": "เด่นใหญ่",
    "subDistrictNameEn": "Den Yai",
    "postcode": "17130",
    "districtCode": "1806",
    "districtNameTh": "หันคา",
    "districtNameEn": "Hankha",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180611",
    "subDistrictNameTh": "สามง่ามท่าโบสถ์",
    "subDistrictNameEn": "Sam Ngam Tha Bot",
    "postcode": "17160",
    "districtCode": "1806",
    "districtNameTh": "หันคา",
    "districtNameEn": "Hankha",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180701",
    "subDistrictNameTh": "หนองมะโมง",
    "subDistrictNameEn": "Nong Mamong",
    "postcode": "17120",
    "districtCode": "1807",
    "districtNameTh": "หนองมะโมง",
    "districtNameEn": "Nong Mamong",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180702",
    "subDistrictNameTh": "วังตะเคียน",
    "subDistrictNameEn": "Wang Takhian",
    "postcode": "17120",
    "districtCode": "1807",
    "districtNameTh": "หนองมะโมง",
    "districtNameEn": "Nong Mamong",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180703",
    "subDistrictNameTh": "สะพานหิน",
    "subDistrictNameEn": "Saphan Hin",
    "postcode": "17120",
    "districtCode": "1807",
    "districtNameTh": "หนองมะโมง",
    "districtNameEn": "Nong Mamong",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180704",
    "subDistrictNameTh": "กุดจอก",
    "subDistrictNameEn": "Kut Chok",
    "postcode": "17120",
    "districtCode": "1807",
    "districtNameTh": "หนองมะโมง",
    "districtNameEn": "Nong Mamong",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180801",
    "subDistrictNameTh": "เนินขาม",
    "subDistrictNameEn": "Noen Kham",
    "postcode": "17130",
    "districtCode": "1808",
    "districtNameTh": "เนินขาม",
    "districtNameEn": "Noen Kham",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180802",
    "subDistrictNameTh": "กะบกเตี้ย",
    "subDistrictNameEn": "Kabok Tia",
    "postcode": "17130",
    "districtCode": "1808",
    "districtNameTh": "เนินขาม",
    "districtNameEn": "Noen Kham",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "180803",
    "subDistrictNameTh": "สุขเดือนห้า",
    "subDistrictNameEn": "Suk Duean Ha",
    "postcode": "17130",
    "districtCode": "1808",
    "districtNameTh": "เนินขาม",
    "districtNameEn": "Noen Kham",
    "provinceCode": "18",
    "provinceNameTh": "ชัยนาท",
    "provinceNameEn": "Chai Nat"
},
{
    "subDistrictCode": "190101",
    "subDistrictNameTh": "ปากเพรียว",
    "subDistrictNameEn": "Pak Phriao",
    "postcode": "18000",
    "districtCode": "1901",
    "districtNameTh": "เมืองสระบุรี",
    "districtNameEn": "Mueang Saraburi",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190105",
    "subDistrictNameTh": "ดาวเรือง",
    "subDistrictNameEn": "Dao Rueang",
    "postcode": "18000",
    "districtCode": "1901",
    "districtNameTh": "เมืองสระบุรี",
    "districtNameEn": "Mueang Saraburi",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190106",
    "subDistrictNameTh": "นาโฉง",
    "subDistrictNameEn": "Na Chong",
    "postcode": "18000",
    "districtCode": "1901",
    "districtNameTh": "เมืองสระบุรี",
    "districtNameEn": "Mueang Saraburi",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190107",
    "subDistrictNameTh": "โคกสว่าง",
    "subDistrictNameEn": "Khok Sawang",
    "postcode": "18000",
    "districtCode": "1901",
    "districtNameTh": "เมืองสระบุรี",
    "districtNameEn": "Mueang Saraburi",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190108",
    "subDistrictNameTh": "หนองโน",
    "subDistrictNameEn": "Nong No",
    "postcode": "18000",
    "districtCode": "1901",
    "districtNameTh": "เมืองสระบุรี",
    "districtNameEn": "Mueang Saraburi",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190109",
    "subDistrictNameTh": "หนองยาว",
    "subDistrictNameEn": "Nong Yao",
    "postcode": "18000",
    "districtCode": "1901",
    "districtNameTh": "เมืองสระบุรี",
    "districtNameEn": "Mueang Saraburi",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190110",
    "subDistrictNameTh": "ปากข้าวสาร",
    "subDistrictNameEn": "Pak Khao San",
    "postcode": "18000",
    "districtCode": "1901",
    "districtNameTh": "เมืองสระบุรี",
    "districtNameEn": "Mueang Saraburi",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190111",
    "subDistrictNameTh": "หนองปลาไหล",
    "subDistrictNameEn": "Nong Pla Lai",
    "postcode": "18000",
    "districtCode": "1901",
    "districtNameTh": "เมืองสระบุรี",
    "districtNameEn": "Mueang Saraburi",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190112",
    "subDistrictNameTh": "กุดนกเปล้า",
    "subDistrictNameEn": "Kut Nok Plao",
    "postcode": "18000",
    "districtCode": "1901",
    "districtNameTh": "เมืองสระบุรี",
    "districtNameEn": "Mueang Saraburi",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190113",
    "subDistrictNameTh": "ตลิ่งชัน",
    "subDistrictNameEn": "Taling Chan",
    "postcode": "18000",
    "districtCode": "1901",
    "districtNameTh": "เมืองสระบุรี",
    "districtNameEn": "Mueang Saraburi",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190114",
    "subDistrictNameTh": "ตะกุด",
    "subDistrictNameEn": "Takut",
    "postcode": "18000",
    "districtCode": "1901",
    "districtNameTh": "เมืองสระบุรี",
    "districtNameEn": "Mueang Saraburi",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190201",
    "subDistrictNameTh": "แก่งคอย",
    "subDistrictNameEn": "Kaeng Khoi",
    "postcode": "18110",
    "districtCode": "1902",
    "districtNameTh": "แก่งคอย",
    "districtNameEn": "Kaeng Khoi",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190202",
    "subDistrictNameTh": "ทับกวาง",
    "subDistrictNameEn": "Thap Kwang",
    "postcode": "18260",
    "districtCode": "1902",
    "districtNameTh": "แก่งคอย",
    "districtNameEn": "Kaeng Khoi",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190203",
    "subDistrictNameTh": "ตาลเดี่ยว",
    "subDistrictNameEn": "Tan Diao",
    "postcode": "18110",
    "districtCode": "1902",
    "districtNameTh": "แก่งคอย",
    "districtNameEn": "Kaeng Khoi",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190204",
    "subDistrictNameTh": "ห้วยแห้ง",
    "subDistrictNameEn": "Huai Haeng",
    "postcode": "18110",
    "districtCode": "1902",
    "districtNameTh": "แก่งคอย",
    "districtNameEn": "Kaeng Khoi",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190205",
    "subDistrictNameTh": "ท่าคล้อ",
    "subDistrictNameEn": "Tha Khlo",
    "postcode": "18110",
    "districtCode": "1902",
    "districtNameTh": "แก่งคอย",
    "districtNameEn": "Kaeng Khoi",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190206",
    "subDistrictNameTh": "หินซ้อน",
    "subDistrictNameEn": "Hin Son",
    "postcode": "18110",
    "districtCode": "1902",
    "districtNameTh": "แก่งคอย",
    "districtNameEn": "Kaeng Khoi",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190207",
    "subDistrictNameTh": "บ้านธาตุ",
    "subDistrictNameEn": "Ban That",
    "postcode": "18110",
    "districtCode": "1902",
    "districtNameTh": "แก่งคอย",
    "districtNameEn": "Kaeng Khoi",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190208",
    "subDistrictNameTh": "บ้านป่า",
    "subDistrictNameEn": "Ban Pa",
    "postcode": "18110",
    "districtCode": "1902",
    "districtNameTh": "แก่งคอย",
    "districtNameEn": "Kaeng Khoi",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190209",
    "subDistrictNameTh": "ท่าตูม",
    "subDistrictNameEn": "Tha Tum",
    "postcode": "18110",
    "districtCode": "1902",
    "districtNameTh": "แก่งคอย",
    "districtNameEn": "Kaeng Khoi",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190210",
    "subDistrictNameTh": "ชะอม",
    "subDistrictNameEn": "Cha-om",
    "postcode": "18110",
    "districtCode": "1902",
    "districtNameTh": "แก่งคอย",
    "districtNameEn": "Kaeng Khoi",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190211",
    "subDistrictNameTh": "สองคอน",
    "subDistrictNameEn": "Song Khon",
    "postcode": "18110",
    "districtCode": "1902",
    "districtNameTh": "แก่งคอย",
    "districtNameEn": "Kaeng Khoi",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190212",
    "subDistrictNameTh": "เตาปูน",
    "subDistrictNameEn": "Tao Pun",
    "postcode": "18110",
    "districtCode": "1902",
    "districtNameTh": "แก่งคอย",
    "districtNameEn": "Kaeng Khoi",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190213",
    "subDistrictNameTh": "ชำผักแพว",
    "subDistrictNameEn": "Cham Phak Phaeo",
    "postcode": "18110",
    "districtCode": "1902",
    "districtNameTh": "แก่งคอย",
    "districtNameEn": "Kaeng Khoi",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190215",
    "subDistrictNameTh": "ท่ามะปราง",
    "subDistrictNameEn": "Tha Maprang",
    "postcode": "18110",
    "districtCode": "1902",
    "districtNameTh": "แก่งคอย",
    "districtNameEn": "Kaeng Khoi",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190301",
    "subDistrictNameTh": "หนองแค",
    "subDistrictNameEn": "Nong Khae",
    "postcode": "18140",
    "districtCode": "1903",
    "districtNameTh": "หนองแค",
    "districtNameEn": "Nong Khae",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190302",
    "subDistrictNameTh": "กุ่มหัก",
    "subDistrictNameEn": "Kum Hak",
    "postcode": "18140",
    "districtCode": "1903",
    "districtNameTh": "หนองแค",
    "districtNameEn": "Nong Khae",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190303",
    "subDistrictNameTh": "คชสิทธิ์",
    "subDistrictNameEn": "Khotchasit",
    "postcode": "18250",
    "districtCode": "1903",
    "districtNameTh": "หนองแค",
    "districtNameEn": "Nong Khae",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190304",
    "subDistrictNameTh": "โคกตูม",
    "subDistrictNameEn": "Khok Tum",
    "postcode": "18250",
    "districtCode": "1903",
    "districtNameTh": "หนองแค",
    "districtNameEn": "Nong Khae",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190305",
    "subDistrictNameTh": "โคกแย้",
    "subDistrictNameEn": "Khok Yae",
    "postcode": "18230",
    "districtCode": "1903",
    "districtNameTh": "หนองแค",
    "districtNameEn": "Nong Khae",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190306",
    "subDistrictNameTh": "บัวลอย",
    "subDistrictNameEn": "Bua Loi",
    "postcode": "18230",
    "districtCode": "1903",
    "districtNameTh": "หนองแค",
    "districtNameEn": "Nong Khae",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190307",
    "subDistrictNameTh": "ไผ่ต่ำ",
    "subDistrictNameEn": "Phai Tam",
    "postcode": "18140",
    "districtCode": "1903",
    "districtNameTh": "หนองแค",
    "districtNameEn": "Nong Khae",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190308",
    "subDistrictNameTh": "โพนทอง",
    "subDistrictNameEn": "Phon Thong",
    "postcode": "18250",
    "districtCode": "1903",
    "districtNameTh": "หนองแค",
    "districtNameEn": "Nong Khae",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190309",
    "subDistrictNameTh": "ห้วยขมิ้น",
    "subDistrictNameEn": "Huai Khamin",
    "postcode": "18230",
    "districtCode": "1903",
    "districtNameTh": "หนองแค",
    "districtNameEn": "Nong Khae",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190310",
    "subDistrictNameTh": "ห้วยทราย",
    "subDistrictNameEn": "Huai Sai",
    "postcode": "18230",
    "districtCode": "1903",
    "districtNameTh": "หนองแค",
    "districtNameEn": "Nong Khae",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190311",
    "subDistrictNameTh": "หนองไข่น้ำ",
    "subDistrictNameEn": "Nong Khai Nam",
    "postcode": "18140",
    "districtCode": "1903",
    "districtNameTh": "หนองแค",
    "districtNameEn": "Nong Khae",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190312",
    "subDistrictNameTh": "หนองแขม",
    "subDistrictNameEn": "Nong Khaem",
    "postcode": "18140",
    "districtCode": "1903",
    "districtNameTh": "หนองแค",
    "districtNameEn": "Nong Khae",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190313",
    "subDistrictNameTh": "หนองจิก",
    "subDistrictNameEn": "Nong Chik",
    "postcode": "18230",
    "districtCode": "1903",
    "districtNameTh": "หนองแค",
    "districtNameEn": "Nong Khae",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190314",
    "subDistrictNameTh": "หนองจรเข้",
    "subDistrictNameEn": "Nong Chorakhe",
    "postcode": "18140",
    "districtCode": "1903",
    "districtNameTh": "หนองแค",
    "districtNameEn": "Nong Khae",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190315",
    "subDistrictNameTh": "หนองนาก",
    "subDistrictNameEn": "Nong Nak",
    "postcode": "18230",
    "districtCode": "1903",
    "districtNameTh": "หนองแค",
    "districtNameEn": "Nong Khae",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190316",
    "subDistrictNameTh": "หนองปลาหมอ",
    "subDistrictNameEn": "Nong Pla Mo",
    "postcode": "18140",
    "districtCode": "1903",
    "districtNameTh": "หนองแค",
    "districtNameEn": "Nong Khae",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190317",
    "subDistrictNameTh": "หนองปลิง",
    "subDistrictNameEn": "Nong Pling",
    "postcode": "18140",
    "districtCode": "1903",
    "districtNameTh": "หนองแค",
    "districtNameEn": "Nong Khae",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190318",
    "subDistrictNameTh": "หนองโรง",
    "subDistrictNameEn": "Nong Rong",
    "postcode": "18140",
    "districtCode": "1903",
    "districtNameTh": "หนองแค",
    "districtNameEn": "Nong Khae",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190401",
    "subDistrictNameTh": "หนองหมู",
    "subDistrictNameEn": "Nong Mu",
    "postcode": "18150",
    "districtCode": "1904",
    "districtNameTh": "วิหารแดง",
    "districtNameEn": "Wihan Daeng",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190402",
    "subDistrictNameTh": "บ้านลำ",
    "subDistrictNameEn": "Ban Lam",
    "postcode": "18150",
    "districtCode": "1904",
    "districtNameTh": "วิหารแดง",
    "districtNameEn": "Wihan Daeng",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190403",
    "subDistrictNameTh": "คลองเรือ",
    "subDistrictNameEn": "Khlong Ruea",
    "postcode": "18150",
    "districtCode": "1904",
    "districtNameTh": "วิหารแดง",
    "districtNameEn": "Wihan Daeng",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190404",
    "subDistrictNameTh": "วิหารแดง",
    "subDistrictNameEn": "Wihan Daeng",
    "postcode": "18150",
    "districtCode": "1904",
    "districtNameTh": "วิหารแดง",
    "districtNameEn": "Wihan Daeng",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190405",
    "subDistrictNameTh": "หนองสรวง",
    "subDistrictNameEn": "Nong Suang",
    "postcode": "18150",
    "districtCode": "1904",
    "districtNameTh": "วิหารแดง",
    "districtNameEn": "Wihan Daeng",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190406",
    "subDistrictNameTh": "เจริญธรรม",
    "subDistrictNameEn": "Charoen Tham",
    "postcode": "18150",
    "districtCode": "1904",
    "districtNameTh": "วิหารแดง",
    "districtNameEn": "Wihan Daeng",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190501",
    "subDistrictNameTh": "หนองแซง",
    "subDistrictNameEn": "Nong Saeng",
    "postcode": "18170",
    "districtCode": "1905",
    "districtNameTh": "หนองแซง",
    "districtNameEn": "Nong Saeng",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190502",
    "subDistrictNameTh": "หนองควายโซ",
    "subDistrictNameEn": "Nong Khwai So",
    "postcode": "18170",
    "districtCode": "1905",
    "districtNameTh": "หนองแซง",
    "districtNameEn": "Nong Saeng",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190503",
    "subDistrictNameTh": "หนองหัวโพ",
    "subDistrictNameEn": "Nong Hua Pho",
    "postcode": "18170",
    "districtCode": "1905",
    "districtNameTh": "หนองแซง",
    "districtNameEn": "Nong Saeng",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190504",
    "subDistrictNameTh": "หนองสีดา",
    "subDistrictNameEn": "Nong Sida",
    "postcode": "18170",
    "districtCode": "1905",
    "districtNameTh": "หนองแซง",
    "districtNameEn": "Nong Saeng",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190505",
    "subDistrictNameTh": "หนองกบ",
    "subDistrictNameEn": "Nong Kop",
    "postcode": "18170",
    "districtCode": "1905",
    "districtNameTh": "หนองแซง",
    "districtNameEn": "Nong Saeng",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190506",
    "subDistrictNameTh": "ไก่เส่า",
    "subDistrictNameEn": "Kai Sao",
    "postcode": "18170",
    "districtCode": "1905",
    "districtNameTh": "หนองแซง",
    "districtNameEn": "Nong Saeng",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190507",
    "subDistrictNameTh": "โคกสะอาด",
    "subDistrictNameEn": "Khok Sa-at",
    "postcode": "18170",
    "districtCode": "1905",
    "districtNameTh": "หนองแซง",
    "districtNameEn": "Nong Saeng",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190508",
    "subDistrictNameTh": "ม่วงหวาน",
    "subDistrictNameEn": "Muang Wan",
    "postcode": "18170",
    "districtCode": "1905",
    "districtNameTh": "หนองแซง",
    "districtNameEn": "Nong Saeng",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190509",
    "subDistrictNameTh": "เขาดิน",
    "subDistrictNameEn": "Khao Din",
    "postcode": "18170",
    "districtCode": "1905",
    "districtNameTh": "หนองแซง",
    "districtNameEn": "Nong Saeng",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190601",
    "subDistrictNameTh": "บ้านหมอ",
    "subDistrictNameEn": "Ban Mo",
    "postcode": "18130",
    "districtCode": "1906",
    "districtNameTh": "บ้านหมอ",
    "districtNameEn": "Ban Mo",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190602",
    "subDistrictNameTh": "บางโขมด",
    "subDistrictNameEn": "Bang Khamot",
    "postcode": "18130",
    "districtCode": "1906",
    "districtNameTh": "บ้านหมอ",
    "districtNameEn": "Ban Mo",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190603",
    "subDistrictNameTh": "สร่างโศก",
    "subDistrictNameEn": "Sang Sok",
    "postcode": "18130",
    "districtCode": "1906",
    "districtNameTh": "บ้านหมอ",
    "districtNameEn": "Ban Mo",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190604",
    "subDistrictNameTh": "ตลาดน้อย",
    "subDistrictNameEn": "Talat Noi",
    "postcode": "18130",
    "districtCode": "1906",
    "districtNameTh": "บ้านหมอ",
    "districtNameEn": "Ban Mo",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190605",
    "subDistrictNameTh": "หรเทพ",
    "subDistrictNameEn": "Horathep",
    "postcode": "18130",
    "districtCode": "1906",
    "districtNameTh": "บ้านหมอ",
    "districtNameEn": "Ban Mo",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190606",
    "subDistrictNameTh": "โคกใหญ่",
    "subDistrictNameEn": "Khok Yai",
    "postcode": "18130",
    "districtCode": "1906",
    "districtNameTh": "บ้านหมอ",
    "districtNameEn": "Ban Mo",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190607",
    "subDistrictNameTh": "ไผ่ขวาง",
    "subDistrictNameEn": "Phai Khwang",
    "postcode": "18130",
    "districtCode": "1906",
    "districtNameTh": "บ้านหมอ",
    "districtNameEn": "Ban Mo",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190608",
    "subDistrictNameTh": "บ้านครัว",
    "subDistrictNameEn": "Ban Khrua",
    "postcode": "18270",
    "districtCode": "1906",
    "districtNameTh": "บ้านหมอ",
    "districtNameEn": "Ban Mo",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190609",
    "subDistrictNameTh": "หนองบัว",
    "subDistrictNameEn": "Nong Bua",
    "postcode": "18130",
    "districtCode": "1906",
    "districtNameTh": "บ้านหมอ",
    "districtNameEn": "Ban Mo",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190701",
    "subDistrictNameTh": "ดอนพุด",
    "subDistrictNameEn": "Don Phut",
    "postcode": "18210",
    "districtCode": "1907",
    "districtNameTh": "ดอนพุด",
    "districtNameEn": "Don Phut",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190702",
    "subDistrictNameTh": "ไผ่หลิ่ว",
    "subDistrictNameEn": "Phai Lio",
    "postcode": "18210",
    "districtCode": "1907",
    "districtNameTh": "ดอนพุด",
    "districtNameEn": "Don Phut",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190703",
    "subDistrictNameTh": "บ้านหลวง",
    "subDistrictNameEn": "Ban Luang",
    "postcode": "18210",
    "districtCode": "1907",
    "districtNameTh": "ดอนพุด",
    "districtNameEn": "Don Phut",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190704",
    "subDistrictNameTh": "ดงตะงาว",
    "subDistrictNameEn": "Dong Ta-ngao",
    "postcode": "18210",
    "districtCode": "1907",
    "districtNameTh": "ดอนพุด",
    "districtNameEn": "Don Phut",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190801",
    "subDistrictNameTh": "หนองโดน",
    "subDistrictNameEn": "Nong Don",
    "postcode": "18190",
    "districtCode": "1908",
    "districtNameTh": "หนองโดน",
    "districtNameEn": "Nong Don",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190802",
    "subDistrictNameTh": "บ้านกลับ",
    "subDistrictNameEn": "Ban Klap",
    "postcode": "18190",
    "districtCode": "1908",
    "districtNameTh": "หนองโดน",
    "districtNameEn": "Nong Don",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190803",
    "subDistrictNameTh": "ดอนทอง",
    "subDistrictNameEn": "Don Thong",
    "postcode": "18190",
    "districtCode": "1908",
    "districtNameTh": "หนองโดน",
    "districtNameEn": "Nong Don",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190804",
    "subDistrictNameTh": "บ้านโปร่ง",
    "subDistrictNameEn": "Ban Prong",
    "postcode": "18190",
    "districtCode": "1908",
    "districtNameTh": "หนองโดน",
    "districtNameEn": "Nong Don",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190901",
    "subDistrictNameTh": "พระพุทธบาท",
    "subDistrictNameEn": "Phra Phutthabat",
    "postcode": "18120",
    "districtCode": "1909",
    "districtNameTh": "พระพุทธบาท",
    "districtNameEn": "Phra Phutthabat",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190902",
    "subDistrictNameTh": "ขุนโขลน",
    "subDistrictNameEn": "Khun Khlon",
    "postcode": "18120",
    "districtCode": "1909",
    "districtNameTh": "พระพุทธบาท",
    "districtNameEn": "Phra Phutthabat",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190903",
    "subDistrictNameTh": "ธารเกษม",
    "subDistrictNameEn": "Than Kasem",
    "postcode": "18120",
    "districtCode": "1909",
    "districtNameTh": "พระพุทธบาท",
    "districtNameEn": "Phra Phutthabat",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190904",
    "subDistrictNameTh": "นายาว",
    "subDistrictNameEn": "Na Yao",
    "postcode": "18120",
    "districtCode": "1909",
    "districtNameTh": "พระพุทธบาท",
    "districtNameEn": "Phra Phutthabat",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190905",
    "subDistrictNameTh": "พุคำจาน",
    "subDistrictNameEn": "Phu Kham Chan",
    "postcode": "18120",
    "districtCode": "1909",
    "districtNameTh": "พระพุทธบาท",
    "districtNameEn": "Phra Phutthabat",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190906",
    "subDistrictNameTh": "เขาวง",
    "subDistrictNameEn": "Khao Wong",
    "postcode": "18120",
    "districtCode": "1909",
    "districtNameTh": "พระพุทธบาท",
    "districtNameEn": "Phra Phutthabat",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190907",
    "subDistrictNameTh": "ห้วยป่าหวาย",
    "subDistrictNameEn": "Huai Pa Wai",
    "postcode": "18120",
    "districtCode": "1909",
    "districtNameTh": "พระพุทธบาท",
    "districtNameEn": "Phra Phutthabat",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190908",
    "subDistrictNameTh": "พุกร่าง",
    "subDistrictNameEn": "Phu Krang",
    "postcode": "18120",
    "districtCode": "1909",
    "districtNameTh": "พระพุทธบาท",
    "districtNameEn": "Phra Phutthabat",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "190909",
    "subDistrictNameTh": "หนองแก",
    "subDistrictNameEn": "Nong Kae",
    "postcode": "18120",
    "districtCode": "1909",
    "districtNameTh": "พระพุทธบาท",
    "districtNameEn": "Phra Phutthabat",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "191001",
    "subDistrictNameTh": "เสาไห้",
    "subDistrictNameEn": "Sao Hai",
    "postcode": "18160",
    "districtCode": "1910",
    "districtNameTh": "เสาไห้",
    "districtNameEn": "Sao Hai",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "191002",
    "subDistrictNameTh": "บ้านยาง",
    "subDistrictNameEn": "Ban Yang",
    "postcode": "18160",
    "districtCode": "1910",
    "districtNameTh": "เสาไห้",
    "districtNameEn": "Sao Hai",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "191003",
    "subDistrictNameTh": "หัวปลวก",
    "subDistrictNameEn": "Hua Pluak",
    "postcode": "18160",
    "districtCode": "1910",
    "districtNameTh": "เสาไห้",
    "districtNameEn": "Sao Hai",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "191004",
    "subDistrictNameTh": "งิ้วงาม",
    "subDistrictNameEn": "Ngio Ngam",
    "postcode": "18160",
    "districtCode": "1910",
    "districtNameTh": "เสาไห้",
    "districtNameEn": "Sao Hai",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "191005",
    "subDistrictNameTh": "ศาลารีไทย",
    "subDistrictNameEn": "Sala Ri Thai",
    "postcode": "18160",
    "districtCode": "1910",
    "districtNameTh": "เสาไห้",
    "districtNameEn": "Sao Hai",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "191006",
    "subDistrictNameTh": "ต้นตาล",
    "subDistrictNameEn": "Ton Tan",
    "postcode": "18160",
    "districtCode": "1910",
    "districtNameTh": "เสาไห้",
    "districtNameEn": "Sao Hai",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "191007",
    "subDistrictNameTh": "ท่าช้าง",
    "subDistrictNameEn": "Tha Chang",
    "postcode": "18160",
    "districtCode": "1910",
    "districtNameTh": "เสาไห้",
    "districtNameEn": "Sao Hai",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "191008",
    "subDistrictNameTh": "พระยาทด",
    "subDistrictNameEn": "Phraya Thot",
    "postcode": "18160",
    "districtCode": "1910",
    "districtNameTh": "เสาไห้",
    "districtNameEn": "Sao Hai",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "191009",
    "subDistrictNameTh": "ม่วงงาม",
    "subDistrictNameEn": "Muang Ngam",
    "postcode": "18160",
    "districtCode": "1910",
    "districtNameTh": "เสาไห้",
    "districtNameEn": "Sao Hai",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "191010",
    "subDistrictNameTh": "เริงราง",
    "subDistrictNameEn": "Roeng Rang",
    "postcode": "18160",
    "districtCode": "1910",
    "districtNameTh": "เสาไห้",
    "districtNameEn": "Sao Hai",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "191011",
    "subDistrictNameTh": "เมืองเก่า",
    "subDistrictNameEn": "Mueang Kao",
    "postcode": "18160",
    "districtCode": "1910",
    "districtNameTh": "เสาไห้",
    "districtNameEn": "Sao Hai",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "191012",
    "subDistrictNameTh": "สวนดอกไม้",
    "subDistrictNameEn": "Suan Dok Mai",
    "postcode": "18160",
    "districtCode": "1910",
    "districtNameTh": "เสาไห้",
    "districtNameEn": "Sao Hai",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "191101",
    "subDistrictNameTh": "มวกเหล็ก",
    "subDistrictNameEn": "Muak Lek",
    "postcode": "18180",
    "districtCode": "1911",
    "districtNameTh": "มวกเหล็ก",
    "districtNameEn": "Muak Lek",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "191102",
    "subDistrictNameTh": "มิตรภาพ",
    "subDistrictNameEn": "Mittraphap",
    "postcode": "18180",
    "districtCode": "1911",
    "districtNameTh": "มวกเหล็ก",
    "districtNameEn": "Muak Lek",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "191104",
    "subDistrictNameTh": "หนองย่างเสือ",
    "subDistrictNameEn": "Nong Yang Suea",
    "postcode": "18180",
    "districtCode": "1911",
    "districtNameTh": "มวกเหล็ก",
    "districtNameEn": "Muak Lek",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "191105",
    "subDistrictNameTh": "ลำสมพุง",
    "subDistrictNameEn": "Lam Somphung",
    "postcode": "30130",
    "districtCode": "1911",
    "districtNameTh": "มวกเหล็ก",
    "districtNameEn": "Muak Lek",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "191107",
    "subDistrictNameTh": "ลำพญากลาง",
    "subDistrictNameEn": "Lam Phaya Klang",
    "postcode": "30130",
    "districtCode": "1911",
    "districtNameTh": "มวกเหล็ก",
    "districtNameEn": "Muak Lek",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "191109",
    "subDistrictNameTh": "ซับสนุ่น",
    "subDistrictNameEn": "Sap Sanun",
    "postcode": "18220",
    "districtCode": "1911",
    "districtNameTh": "มวกเหล็ก",
    "districtNameEn": "Muak Lek",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "191201",
    "subDistrictNameTh": "แสลงพัน",
    "subDistrictNameEn": "Salaeng Phan",
    "postcode": "18220",
    "districtCode": "1912",
    "districtNameTh": "วังม่วง",
    "districtNameEn": "Wang Muang",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "191202",
    "subDistrictNameTh": "คำพราน",
    "subDistrictNameEn": "Kham Phran",
    "postcode": "18220",
    "districtCode": "1912",
    "districtNameTh": "วังม่วง",
    "districtNameEn": "Wang Muang",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "191203",
    "subDistrictNameTh": "วังม่วง",
    "subDistrictNameEn": "Wang Muang",
    "postcode": "18220",
    "districtCode": "1912",
    "districtNameTh": "วังม่วง",
    "districtNameEn": "Wang Muang",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "191301",
    "subDistrictNameTh": "เขาดินพัฒนา",
    "subDistrictNameEn": "Khao Din Phatthana",
    "postcode": "18000",
    "districtCode": "1913",
    "districtNameTh": "เฉลิมพระเกียรติ",
    "districtNameEn": "Chaloem Phra Kiat",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "191302",
    "subDistrictNameTh": "บ้านแก้ง",
    "subDistrictNameEn": "Ban Kaeng",
    "postcode": "18000",
    "districtCode": "1913",
    "districtNameTh": "เฉลิมพระเกียรติ",
    "districtNameEn": "Chaloem Phra Kiat",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "191303",
    "subDistrictNameTh": "ผึ้งรวง",
    "subDistrictNameEn": "Phueng Ruang",
    "postcode": "18000",
    "districtCode": "1913",
    "districtNameTh": "เฉลิมพระเกียรติ",
    "districtNameEn": "Chaloem Phra Kiat",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "191304",
    "subDistrictNameTh": "พุแค",
    "subDistrictNameEn": "Phu Khae",
    "postcode": "18240",
    "districtCode": "1913",
    "districtNameTh": "เฉลิมพระเกียรติ",
    "districtNameEn": "Chaloem Phra Kiat",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "191305",
    "subDistrictNameTh": "ห้วยบง",
    "subDistrictNameEn": "Huai Bong",
    "postcode": "18000",
    "districtCode": "1913",
    "districtNameTh": "เฉลิมพระเกียรติ",
    "districtNameEn": "Chaloem Phra Kiat",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "191306",
    "subDistrictNameTh": "หน้าพระลาน",
    "subDistrictNameEn": "Na Phra Lan",
    "postcode": "18240",
    "districtCode": "1913",
    "districtNameTh": "เฉลิมพระเกียรติ",
    "districtNameEn": "Chaloem Phra Kiat",
    "provinceCode": "19",
    "provinceNameTh": "สระบุรี",
    "provinceNameEn": "Saraburi"
},
{
    "subDistrictCode": "200101",
    "subDistrictNameTh": "บางปลาสร้อย",
    "subDistrictNameEn": "Bang Pla Soi",
    "postcode": "20000",
    "districtCode": "2001",
    "districtNameTh": "เมืองชลบุรี",
    "districtNameEn": "Mueang Chon Buri",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200102",
    "subDistrictNameTh": "มะขามหย่ง",
    "subDistrictNameEn": "Makham Yong",
    "postcode": "20000",
    "districtCode": "2001",
    "districtNameTh": "เมืองชลบุรี",
    "districtNameEn": "Mueang Chon Buri",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200103",
    "subDistrictNameTh": "บ้านโขด",
    "subDistrictNameEn": "Ban Khot",
    "postcode": "20000",
    "districtCode": "2001",
    "districtNameTh": "เมืองชลบุรี",
    "districtNameEn": "Mueang Chon Buri",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200104",
    "subDistrictNameTh": "แสนสุข",
    "subDistrictNameEn": "Saen Suk",
    "postcode": "20130",
    "districtCode": "2001",
    "districtNameTh": "เมืองชลบุรี",
    "districtNameEn": "Mueang Chon Buri",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200105",
    "subDistrictNameTh": "บ้านสวน",
    "subDistrictNameEn": "Ban Suan",
    "postcode": "20000",
    "districtCode": "2001",
    "districtNameTh": "เมืองชลบุรี",
    "districtNameEn": "Mueang Chon Buri",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200106",
    "subDistrictNameTh": "หนองรี",
    "subDistrictNameEn": "Nong Ri",
    "postcode": "20000",
    "districtCode": "2001",
    "districtNameTh": "เมืองชลบุรี",
    "districtNameEn": "Mueang Chon Buri",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200107",
    "subDistrictNameTh": "นาป่า",
    "subDistrictNameEn": "Na Pa",
    "postcode": "20000",
    "districtCode": "2001",
    "districtNameTh": "เมืองชลบุรี",
    "districtNameEn": "Mueang Chon Buri",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200108",
    "subDistrictNameTh": "หนองข้างคอก",
    "subDistrictNameEn": "Nong Khang Khok",
    "postcode": "20000",
    "districtCode": "2001",
    "districtNameTh": "เมืองชลบุรี",
    "districtNameEn": "Mueang Chon Buri",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200109",
    "subDistrictNameTh": "ดอนหัวฬ่อ",
    "subDistrictNameEn": "Don Hua Lo",
    "postcode": "20000",
    "districtCode": "2001",
    "districtNameTh": "เมืองชลบุรี",
    "districtNameEn": "Mueang Chon Buri",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200110",
    "subDistrictNameTh": "หนองไม้แดง",
    "subDistrictNameEn": "Nong Mai Daeng",
    "postcode": "20000",
    "districtCode": "2001",
    "districtNameTh": "เมืองชลบุรี",
    "districtNameEn": "Mueang Chon Buri",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200111",
    "subDistrictNameTh": "บางทราย",
    "subDistrictNameEn": "Bang Sai",
    "postcode": "20000",
    "districtCode": "2001",
    "districtNameTh": "เมืองชลบุรี",
    "districtNameEn": "Mueang Chon Buri",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200112",
    "subDistrictNameTh": "คลองตำหรุ",
    "subDistrictNameEn": "Khlong Tamru",
    "postcode": "20000",
    "districtCode": "2001",
    "districtNameTh": "เมืองชลบุรี",
    "districtNameEn": "Mueang Chon Buri",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200113",
    "subDistrictNameTh": "เหมือง",
    "subDistrictNameEn": "Mueang",
    "postcode": "20130",
    "districtCode": "2001",
    "districtNameTh": "เมืองชลบุรี",
    "districtNameEn": "Mueang Chon Buri",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200114",
    "subDistrictNameTh": "บ้านปึก",
    "subDistrictNameEn": "Ban Puek",
    "postcode": "20130",
    "districtCode": "2001",
    "districtNameTh": "เมืองชลบุรี",
    "districtNameEn": "Mueang Chon Buri",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200115",
    "subDistrictNameTh": "ห้วยกะปิ",
    "subDistrictNameEn": "Huai Kapi",
    "postcode": "20000",
    "districtCode": "2001",
    "districtNameTh": "เมืองชลบุรี",
    "districtNameEn": "Mueang Chon Buri",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200116",
    "subDistrictNameTh": "เสม็ด",
    "subDistrictNameEn": "Samet",
    "postcode": "20000",
    "districtCode": "2001",
    "districtNameTh": "เมืองชลบุรี",
    "districtNameEn": "Mueang Chon Buri",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200117",
    "subDistrictNameTh": "อ่างศิลา",
    "subDistrictNameEn": "Ang Sila",
    "postcode": "20000",
    "districtCode": "2001",
    "districtNameTh": "เมืองชลบุรี",
    "districtNameEn": "Mueang Chon Buri",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200118",
    "subDistrictNameTh": "สำนักบก",
    "subDistrictNameEn": "Samnak Bok",
    "postcode": "20000",
    "districtCode": "2001",
    "districtNameTh": "เมืองชลบุรี",
    "districtNameEn": "Mueang Chon Buri",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200201",
    "subDistrictNameTh": "บ้านบึง",
    "subDistrictNameEn": "Ban Bueng",
    "postcode": "20170",
    "districtCode": "2002",
    "districtNameTh": "บ้านบึง",
    "districtNameEn": "Ban Bueng",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200202",
    "subDistrictNameTh": "คลองกิ่ว",
    "subDistrictNameEn": "Khlong Kio",
    "postcode": "20220",
    "districtCode": "2002",
    "districtNameTh": "บ้านบึง",
    "districtNameEn": "Ban Bueng",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200203",
    "subDistrictNameTh": "มาบไผ่",
    "subDistrictNameEn": "Map Phai",
    "postcode": "20170",
    "districtCode": "2002",
    "districtNameTh": "บ้านบึง",
    "districtNameEn": "Ban Bueng",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200204",
    "subDistrictNameTh": "หนองซ้ำซาก",
    "subDistrictNameEn": "Nong Samsak",
    "postcode": "20170",
    "districtCode": "2002",
    "districtNameTh": "บ้านบึง",
    "districtNameEn": "Ban Bueng",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200205",
    "subDistrictNameTh": "หนองบอนแดง",
    "subDistrictNameEn": "Nong Bon Daeng",
    "postcode": "20170",
    "districtCode": "2002",
    "districtNameTh": "บ้านบึง",
    "districtNameEn": "Ban Bueng",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200206",
    "subDistrictNameTh": "หนองชาก",
    "subDistrictNameEn": "Nong Chak",
    "postcode": "20170",
    "districtCode": "2002",
    "districtNameTh": "บ้านบึง",
    "districtNameEn": "Ban Bueng",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200207",
    "subDistrictNameTh": "หนองอิรุณ",
    "subDistrictNameEn": "Nong Irun",
    "postcode": "20220",
    "districtCode": "2002",
    "districtNameTh": "บ้านบึง",
    "districtNameEn": "Ban Bueng",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200208",
    "subDistrictNameTh": "หนองไผ่แก้ว",
    "subDistrictNameEn": "Nong Phai Kaeo",
    "postcode": "20220",
    "districtCode": "2002",
    "districtNameTh": "บ้านบึง",
    "districtNameEn": "Ban Bueng",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200301",
    "subDistrictNameTh": "หนองใหญ่",
    "subDistrictNameEn": "Nong Yai",
    "postcode": "20190",
    "districtCode": "2003",
    "districtNameTh": "หนองใหญ่",
    "districtNameEn": "Nong Yai",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200302",
    "subDistrictNameTh": "คลองพลู",
    "subDistrictNameEn": "Khlong Phlu",
    "postcode": "20190",
    "districtCode": "2003",
    "districtNameTh": "หนองใหญ่",
    "districtNameEn": "Nong Yai",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200303",
    "subDistrictNameTh": "หนองเสือช้าง",
    "subDistrictNameEn": "Nong Suea Chang",
    "postcode": "20190",
    "districtCode": "2003",
    "districtNameTh": "หนองใหญ่",
    "districtNameEn": "Nong Yai",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200304",
    "subDistrictNameTh": "ห้างสูง",
    "subDistrictNameEn": "Hang Sung",
    "postcode": "20190",
    "districtCode": "2003",
    "districtNameTh": "หนองใหญ่",
    "districtNameEn": "Nong Yai",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200305",
    "subDistrictNameTh": "เขาซก",
    "subDistrictNameEn": "Khao Sok",
    "postcode": "20190",
    "districtCode": "2003",
    "districtNameTh": "หนองใหญ่",
    "districtNameEn": "Nong Yai",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200401",
    "subDistrictNameTh": "บางละมุง",
    "subDistrictNameEn": "Bang Lamung",
    "postcode": "20150",
    "districtCode": "2004",
    "districtNameTh": "บางละมุง",
    "districtNameEn": "Bang Lamung",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200402",
    "subDistrictNameTh": "หนองปรือ",
    "subDistrictNameEn": "Nong Prue",
    "postcode": "20150",
    "districtCode": "2004",
    "districtNameTh": "บางละมุง",
    "districtNameEn": "Bang Lamung",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200403",
    "subDistrictNameTh": "หนองปลาไหล",
    "subDistrictNameEn": "Nong Pla Lai",
    "postcode": "20150",
    "districtCode": "2004",
    "districtNameTh": "บางละมุง",
    "districtNameEn": "Bang Lamung",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200404",
    "subDistrictNameTh": "โป่ง",
    "subDistrictNameEn": "Pong",
    "postcode": "20150",
    "districtCode": "2004",
    "districtNameTh": "บางละมุง",
    "districtNameEn": "Bang Lamung",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200405",
    "subDistrictNameTh": "เขาไม้แก้ว",
    "subDistrictNameEn": "Khao Mai Kaeo",
    "postcode": "20150",
    "districtCode": "2004",
    "districtNameTh": "บางละมุง",
    "districtNameEn": "Bang Lamung",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200406",
    "subDistrictNameTh": "ห้วยใหญ่",
    "subDistrictNameEn": "Huai Yai",
    "postcode": "20150",
    "districtCode": "2004",
    "districtNameTh": "บางละมุง",
    "districtNameEn": "Bang Lamung",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200407",
    "subDistrictNameTh": "ตะเคียนเตี้ย",
    "subDistrictNameEn": "Takhian Tia",
    "postcode": "20150",
    "districtCode": "2004",
    "districtNameTh": "บางละมุง",
    "districtNameEn": "Bang Lamung",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200408",
    "subDistrictNameTh": "นาเกลือ",
    "subDistrictNameEn": "Na Kluea",
    "postcode": "20150",
    "districtCode": "2004",
    "districtNameTh": "บางละมุง",
    "districtNameEn": "Bang Lamung",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200501",
    "subDistrictNameTh": "พานทอง",
    "subDistrictNameEn": "Phan Thong",
    "postcode": "20160",
    "districtCode": "2005",
    "districtNameTh": "พานทอง",
    "districtNameEn": "Phan Thong",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200502",
    "subDistrictNameTh": "หนองตำลึง",
    "subDistrictNameEn": "Nong Tamlueng",
    "postcode": "20160",
    "districtCode": "2005",
    "districtNameTh": "พานทอง",
    "districtNameEn": "Phan Thong",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200503",
    "subDistrictNameTh": "มาบโป่ง",
    "subDistrictNameEn": "Map Pong",
    "postcode": "20160",
    "districtCode": "2005",
    "districtNameTh": "พานทอง",
    "districtNameEn": "Phan Thong",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200504",
    "subDistrictNameTh": "หนองกะขะ",
    "subDistrictNameEn": "Nong Kakha",
    "postcode": "20160",
    "districtCode": "2005",
    "districtNameTh": "พานทอง",
    "districtNameEn": "Phan Thong",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200505",
    "subDistrictNameTh": "หนองหงษ์",
    "subDistrictNameEn": "Nong Hong",
    "postcode": "20160",
    "districtCode": "2005",
    "districtNameTh": "พานทอง",
    "districtNameEn": "Phan Thong",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200506",
    "subDistrictNameTh": "โคกขี้หนอน",
    "subDistrictNameEn": "Khok Khi Non",
    "postcode": "20160",
    "districtCode": "2005",
    "districtNameTh": "พานทอง",
    "districtNameEn": "Phan Thong",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200507",
    "subDistrictNameTh": "บ้านเก่า",
    "subDistrictNameEn": "Ban Kao",
    "postcode": "20160",
    "districtCode": "2005",
    "districtNameTh": "พานทอง",
    "districtNameEn": "Phan Thong",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200508",
    "subDistrictNameTh": "หน้าประดู่",
    "subDistrictNameEn": "Na Pradu",
    "postcode": "20160",
    "districtCode": "2005",
    "districtNameTh": "พานทอง",
    "districtNameEn": "Phan Thong",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200509",
    "subDistrictNameTh": "บางนาง",
    "subDistrictNameEn": "Bang Nang",
    "postcode": "20160",
    "districtCode": "2005",
    "districtNameTh": "พานทอง",
    "districtNameEn": "Phan Thong",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200510",
    "subDistrictNameTh": "เกาะลอย",
    "subDistrictNameEn": "Ko Loi",
    "postcode": "20160",
    "districtCode": "2005",
    "districtNameTh": "พานทอง",
    "districtNameEn": "Phan Thong",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200511",
    "subDistrictNameTh": "บางหัก",
    "subDistrictNameEn": "Bang Hak",
    "postcode": "20160",
    "districtCode": "2005",
    "districtNameTh": "พานทอง",
    "districtNameEn": "Phan Thong",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200601",
    "subDistrictNameTh": "พนัสนิคม",
    "subDistrictNameEn": "Phanat Nikhom",
    "postcode": "20140",
    "districtCode": "2006",
    "districtNameTh": "พนัสนิคม",
    "districtNameEn": "Phanat Nikhom",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200602",
    "subDistrictNameTh": "หน้าพระธาตุ",
    "subDistrictNameEn": "Na Phra That",
    "postcode": "20140",
    "districtCode": "2006",
    "districtNameTh": "พนัสนิคม",
    "districtNameEn": "Phanat Nikhom",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200603",
    "subDistrictNameTh": "วัดหลวง",
    "subDistrictNameEn": "Wat Luang",
    "postcode": "20140",
    "districtCode": "2006",
    "districtNameTh": "พนัสนิคม",
    "districtNameEn": "Phanat Nikhom",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200604",
    "subDistrictNameTh": "บ้านเซิด",
    "subDistrictNameEn": "Ban Soet",
    "postcode": "20140",
    "districtCode": "2006",
    "districtNameTh": "พนัสนิคม",
    "districtNameEn": "Phanat Nikhom",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200605",
    "subDistrictNameTh": "นาเริก",
    "subDistrictNameEn": "Na Roek",
    "postcode": "20140",
    "districtCode": "2006",
    "districtNameTh": "พนัสนิคม",
    "districtNameEn": "Phanat Nikhom",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200606",
    "subDistrictNameTh": "หมอนนาง",
    "subDistrictNameEn": "Mon Nang",
    "postcode": "20140",
    "districtCode": "2006",
    "districtNameTh": "พนัสนิคม",
    "districtNameEn": "Phanat Nikhom",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200607",
    "subDistrictNameTh": "สระสี่เหลี่ยม",
    "subDistrictNameEn": "Sa Si Liam",
    "postcode": "20140",
    "districtCode": "2006",
    "districtNameTh": "พนัสนิคม",
    "districtNameEn": "Phanat Nikhom",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200608",
    "subDistrictNameTh": "วัดโบสถ์",
    "subDistrictNameEn": "Wat Bot",
    "postcode": "20140",
    "districtCode": "2006",
    "districtNameTh": "พนัสนิคม",
    "districtNameEn": "Phanat Nikhom",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200609",
    "subDistrictNameTh": "กุฎโง้ง",
    "subDistrictNameEn": "Kut Ngong",
    "postcode": "20140",
    "districtCode": "2006",
    "districtNameTh": "พนัสนิคม",
    "districtNameEn": "Phanat Nikhom",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200610",
    "subDistrictNameTh": "หัวถนน",
    "subDistrictNameEn": "Hua Thanon",
    "postcode": "20140",
    "districtCode": "2006",
    "districtNameTh": "พนัสนิคม",
    "districtNameEn": "Phanat Nikhom",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200611",
    "subDistrictNameTh": "ท่าข้าม",
    "subDistrictNameEn": "Tha Kham",
    "postcode": "20140",
    "districtCode": "2006",
    "districtNameTh": "พนัสนิคม",
    "districtNameEn": "Phanat Nikhom",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200613",
    "subDistrictNameTh": "หนองปรือ",
    "subDistrictNameEn": "Nong Prue",
    "postcode": "20140",
    "districtCode": "2006",
    "districtNameTh": "พนัสนิคม",
    "districtNameEn": "Phanat Nikhom",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200614",
    "subDistrictNameTh": "หนองขยาด",
    "subDistrictNameEn": "Nong Khayat",
    "postcode": "20140",
    "districtCode": "2006",
    "districtNameTh": "พนัสนิคม",
    "districtNameEn": "Phanat Nikhom",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200615",
    "subDistrictNameTh": "ทุ่งขวาง",
    "subDistrictNameEn": "Thung Khwang",
    "postcode": "20140",
    "districtCode": "2006",
    "districtNameTh": "พนัสนิคม",
    "districtNameEn": "Phanat Nikhom",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200616",
    "subDistrictNameTh": "หนองเหียง",
    "subDistrictNameEn": "Nong Hiang",
    "postcode": "20140",
    "districtCode": "2006",
    "districtNameTh": "พนัสนิคม",
    "districtNameEn": "Phanat Nikhom",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200617",
    "subDistrictNameTh": "นาวังหิน",
    "subDistrictNameEn": "Na Wang Hin",
    "postcode": "20140",
    "districtCode": "2006",
    "districtNameTh": "พนัสนิคม",
    "districtNameEn": "Phanat Nikhom",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200618",
    "subDistrictNameTh": "บ้านช้าง",
    "subDistrictNameEn": "Ban Chang",
    "postcode": "20140",
    "districtCode": "2006",
    "districtNameTh": "พนัสนิคม",
    "districtNameEn": "Phanat Nikhom",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200620",
    "subDistrictNameTh": "โคกเพลาะ",
    "subDistrictNameEn": "Khok Phlo",
    "postcode": "20140",
    "districtCode": "2006",
    "districtNameTh": "พนัสนิคม",
    "districtNameEn": "Phanat Nikhom",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200621",
    "subDistrictNameTh": "ไร่หลักทอง",
    "subDistrictNameEn": "Rai Lak Thong",
    "postcode": "20140",
    "districtCode": "2006",
    "districtNameTh": "พนัสนิคม",
    "districtNameEn": "Phanat Nikhom",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200622",
    "subDistrictNameTh": "นามะตูม",
    "subDistrictNameEn": "Na Matum",
    "postcode": "20140",
    "districtCode": "2006",
    "districtNameTh": "พนัสนิคม",
    "districtNameEn": "Phanat Nikhom",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200701",
    "subDistrictNameTh": "ศรีราชา",
    "subDistrictNameEn": "Si Racha",
    "postcode": "20110",
    "districtCode": "2007",
    "districtNameTh": "ศรีราชา",
    "districtNameEn": "Si Racha",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200702",
    "subDistrictNameTh": "สุรศักดิ์",
    "subDistrictNameEn": "Surasak",
    "postcode": "20110",
    "districtCode": "2007",
    "districtNameTh": "ศรีราชา",
    "districtNameEn": "Si Racha",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200703",
    "subDistrictNameTh": "ทุ่งสุขลา",
    "subDistrictNameEn": "Thung Sukhla",
    "postcode": "20230",
    "districtCode": "2007",
    "districtNameTh": "ศรีราชา",
    "districtNameEn": "Si Racha",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200704",
    "subDistrictNameTh": "บึง",
    "subDistrictNameEn": "Bueng",
    "postcode": "20230",
    "districtCode": "2007",
    "districtNameTh": "ศรีราชา",
    "districtNameEn": "Si Racha",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200705",
    "subDistrictNameTh": "หนองขาม",
    "subDistrictNameEn": "Nong Kham",
    "postcode": "20110",
    "districtCode": "2007",
    "districtNameTh": "ศรีราชา",
    "districtNameEn": "Si Racha",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200706",
    "subDistrictNameTh": "เขาคันทรง",
    "subDistrictNameEn": "Khao Khansong",
    "postcode": "20110",
    "districtCode": "2007",
    "districtNameTh": "ศรีราชา",
    "districtNameEn": "Si Racha",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200707",
    "subDistrictNameTh": "บางพระ",
    "subDistrictNameEn": "Bang Phra",
    "postcode": "20110",
    "districtCode": "2007",
    "districtNameTh": "ศรีราชา",
    "districtNameEn": "Si Racha",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200708",
    "subDistrictNameTh": "บ่อวิน",
    "subDistrictNameEn": "Bo Win",
    "postcode": "20230",
    "districtCode": "2007",
    "districtNameTh": "ศรีราชา",
    "districtNameEn": "Si Racha",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200801",
    "subDistrictNameTh": "ท่าเทววงษ์",
    "subDistrictNameEn": "Tha Thewawong",
    "postcode": "20120",
    "districtCode": "2008",
    "districtNameTh": "เกาะสีชัง",
    "districtNameEn": "Ko Sichang",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200901",
    "subDistrictNameTh": "สัตหีบ",
    "subDistrictNameEn": "Sattahip",
    "postcode": "20180",
    "districtCode": "2009",
    "districtNameTh": "สัตหีบ",
    "districtNameEn": "Sattahip",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200902",
    "subDistrictNameTh": "นาจอมเทียน",
    "subDistrictNameEn": "Na Chom Thian",
    "postcode": "20250",
    "districtCode": "2009",
    "districtNameTh": "สัตหีบ",
    "districtNameEn": "Sattahip",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200903",
    "subDistrictNameTh": "พลูตาหลวง",
    "subDistrictNameEn": "Phlu Ta Luang",
    "postcode": "20180",
    "districtCode": "2009",
    "districtNameTh": "สัตหีบ",
    "districtNameEn": "Sattahip",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200904",
    "subDistrictNameTh": "บางเสร่",
    "subDistrictNameEn": "Bang Sare",
    "postcode": "20250",
    "districtCode": "2009",
    "districtNameTh": "สัตหีบ",
    "districtNameEn": "Sattahip",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "200905",
    "subDistrictNameTh": "แสมสาร",
    "subDistrictNameEn": "Samaesan",
    "postcode": "20180",
    "districtCode": "2009",
    "districtNameTh": "สัตหีบ",
    "districtNameEn": "Sattahip",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "201001",
    "subDistrictNameTh": "บ่อทอง",
    "subDistrictNameEn": "Bo Thong",
    "postcode": "20270",
    "districtCode": "2010",
    "districtNameTh": "บ่อทอง",
    "districtNameEn": "Bo Thong",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "201002",
    "subDistrictNameTh": "วัดสุวรรณ",
    "subDistrictNameEn": "Wat Suwan",
    "postcode": "20270",
    "districtCode": "2010",
    "districtNameTh": "บ่อทอง",
    "districtNameEn": "Bo Thong",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "201003",
    "subDistrictNameTh": "บ่อกวางทอง",
    "subDistrictNameEn": "Bo Kwang Thong",
    "postcode": "20270",
    "districtCode": "2010",
    "districtNameTh": "บ่อทอง",
    "districtNameEn": "Bo Thong",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "201004",
    "subDistrictNameTh": "ธาตุทอง",
    "subDistrictNameEn": "That Thong",
    "postcode": "20270",
    "districtCode": "2010",
    "districtNameTh": "บ่อทอง",
    "districtNameEn": "Bo Thong",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "201005",
    "subDistrictNameTh": "เกษตรสุวรรณ",
    "subDistrictNameEn": "Kaset Suwan",
    "postcode": "20270",
    "districtCode": "2010",
    "districtNameTh": "บ่อทอง",
    "districtNameEn": "Bo Thong",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "201006",
    "subDistrictNameTh": "พลวงทอง",
    "subDistrictNameEn": "Phluang Thong",
    "postcode": "20270",
    "districtCode": "2010",
    "districtNameTh": "บ่อทอง",
    "districtNameEn": "Bo Thong",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "201101",
    "subDistrictNameTh": "เกาะจันทร์",
    "subDistrictNameEn": "Ko Chan",
    "postcode": "20240",
    "districtCode": "2011",
    "districtNameTh": "เกาะจันทร์",
    "districtNameEn": "Ko Chan",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "201102",
    "subDistrictNameTh": "ท่าบุญมี",
    "subDistrictNameEn": "Tha Bun Mi",
    "postcode": "20240",
    "districtCode": "2011",
    "districtNameTh": "เกาะจันทร์",
    "districtNameEn": "Ko Chan",
    "provinceCode": "20",
    "provinceNameTh": "ชลบุรี",
    "provinceNameEn": "Chon Buri"
},
{
    "subDistrictCode": "210101",
    "subDistrictNameTh": "ท่าประดู่",
    "subDistrictNameEn": "Tha Pradu",
    "postcode": "21000",
    "districtCode": "2101",
    "districtNameTh": "เมืองระยอง",
    "districtNameEn": "Mueang Rayong",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210102",
    "subDistrictNameTh": "เชิงเนิน",
    "subDistrictNameEn": "Choeng Noen",
    "postcode": "21000",
    "districtCode": "2101",
    "districtNameTh": "เมืองระยอง",
    "districtNameEn": "Mueang Rayong",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210103",
    "subDistrictNameTh": "ตะพง",
    "subDistrictNameEn": "Taphong",
    "postcode": "21000",
    "districtCode": "2101",
    "districtNameTh": "เมืองระยอง",
    "districtNameEn": "Mueang Rayong",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210104",
    "subDistrictNameTh": "ปากน้ำ",
    "subDistrictNameEn": "Pak Nam",
    "postcode": "21000",
    "districtCode": "2101",
    "districtNameTh": "เมืองระยอง",
    "districtNameEn": "Mueang Rayong",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210105",
    "subDistrictNameTh": "เพ",
    "subDistrictNameEn": "Phe",
    "postcode": "21160",
    "districtCode": "2101",
    "districtNameTh": "เมืองระยอง",
    "districtNameEn": "Mueang Rayong",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210106",
    "subDistrictNameTh": "แกลง",
    "subDistrictNameEn": "Klaeng",
    "postcode": "21160",
    "districtCode": "2101",
    "districtNameTh": "เมืองระยอง",
    "districtNameEn": "Mueang Rayong",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210107",
    "subDistrictNameTh": "บ้านแลง",
    "subDistrictNameEn": "Ban Laeng",
    "postcode": "21000",
    "districtCode": "2101",
    "districtNameTh": "เมืองระยอง",
    "districtNameEn": "Mueang Rayong",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210108",
    "subDistrictNameTh": "นาตาขวัญ",
    "subDistrictNameEn": "Na Ta Khwan",
    "postcode": "21000",
    "districtCode": "2101",
    "districtNameTh": "เมืองระยอง",
    "districtNameEn": "Mueang Rayong",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210109",
    "subDistrictNameTh": "เนินพระ",
    "subDistrictNameEn": "Noen Phra",
    "postcode": "21000",
    "districtCode": "2101",
    "districtNameTh": "เมืองระยอง",
    "districtNameEn": "Mueang Rayong",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210110",
    "subDistrictNameTh": "กะเฉด",
    "subDistrictNameEn": "Kachet",
    "postcode": "21100",
    "districtCode": "2101",
    "districtNameTh": "เมืองระยอง",
    "districtNameEn": "Mueang Rayong",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210111",
    "subDistrictNameTh": "ทับมา",
    "subDistrictNameEn": "Thap Ma",
    "postcode": "21000",
    "districtCode": "2101",
    "districtNameTh": "เมืองระยอง",
    "districtNameEn": "Mueang Rayong",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210112",
    "subDistrictNameTh": "น้ำคอก",
    "subDistrictNameEn": "Nam Khok",
    "postcode": "21000",
    "districtCode": "2101",
    "districtNameTh": "เมืองระยอง",
    "districtNameEn": "Mueang Rayong",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210113",
    "subDistrictNameTh": "ห้วยโป่ง",
    "subDistrictNameEn": "Huai Pong",
    "postcode": "21150",
    "districtCode": "2101",
    "districtNameTh": "เมืองระยอง",
    "districtNameEn": "Mueang Rayong",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210114",
    "subDistrictNameTh": "มาบตาพุด",
    "subDistrictNameEn": "Map Ta Phut",
    "postcode": "21150",
    "districtCode": "2101",
    "districtNameTh": "เมืองระยอง",
    "districtNameEn": "Mueang Rayong",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210115",
    "subDistrictNameTh": "สำนักทอง",
    "subDistrictNameEn": "Samnak Thong",
    "postcode": "21100",
    "districtCode": "2101",
    "districtNameTh": "เมืองระยอง",
    "districtNameEn": "Mueang Rayong",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210201",
    "subDistrictNameTh": "สำนักท้อน",
    "subDistrictNameEn": "Samnak Thon",
    "postcode": "21130",
    "districtCode": "2102",
    "districtNameTh": "บ้านฉาง",
    "districtNameEn": "Ban Chang",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210202",
    "subDistrictNameTh": "พลา",
    "subDistrictNameEn": "Phala",
    "postcode": "21130",
    "districtCode": "2102",
    "districtNameTh": "บ้านฉาง",
    "districtNameEn": "Ban Chang",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210203",
    "subDistrictNameTh": "บ้านฉาง",
    "subDistrictNameEn": "Ban Chang",
    "postcode": "21130",
    "districtCode": "2102",
    "districtNameTh": "บ้านฉาง",
    "districtNameEn": "Ban Chang",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210301",
    "subDistrictNameTh": "ทางเกวียน",
    "subDistrictNameEn": "Thang Kwian",
    "postcode": "21110",
    "districtCode": "2103",
    "districtNameTh": "แกลง",
    "districtNameEn": "Klaeng",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210302",
    "subDistrictNameTh": "วังหว้า",
    "subDistrictNameEn": "Wang Wa",
    "postcode": "21110",
    "districtCode": "2103",
    "districtNameTh": "แกลง",
    "districtNameEn": "Klaeng",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210303",
    "subDistrictNameTh": "ชากโดน",
    "subDistrictNameEn": "Chak Don",
    "postcode": "21110",
    "districtCode": "2103",
    "districtNameTh": "แกลง",
    "districtNameEn": "Klaeng",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210304",
    "subDistrictNameTh": "เนินฆ้อ",
    "subDistrictNameEn": "Noen Kho",
    "postcode": "21110",
    "districtCode": "2103",
    "districtNameTh": "แกลง",
    "districtNameEn": "Klaeng",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210305",
    "subDistrictNameTh": "กร่ำ",
    "subDistrictNameEn": "Kram",
    "postcode": "21190",
    "districtCode": "2103",
    "districtNameTh": "แกลง",
    "districtNameEn": "Klaeng",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210306",
    "subDistrictNameTh": "ชากพง",
    "subDistrictNameEn": "Chak Phong",
    "postcode": "21190",
    "districtCode": "2103",
    "districtNameTh": "แกลง",
    "districtNameEn": "Klaeng",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210307",
    "subDistrictNameTh": "กระแสบน",
    "subDistrictNameEn": "Krasae Bon",
    "postcode": "21110",
    "districtCode": "2103",
    "districtNameTh": "แกลง",
    "districtNameEn": "Klaeng",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210308",
    "subDistrictNameTh": "บ้านนา",
    "subDistrictNameEn": "Ban Na",
    "postcode": "21110",
    "districtCode": "2103",
    "districtNameTh": "แกลง",
    "districtNameEn": "Klaeng",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210309",
    "subDistrictNameTh": "ทุ่งควายกิน",
    "subDistrictNameEn": "Thung Khwai Kin",
    "postcode": "21110",
    "districtCode": "2103",
    "districtNameTh": "แกลง",
    "districtNameEn": "Klaeng",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210310",
    "subDistrictNameTh": "กองดิน",
    "subDistrictNameEn": "Kong Din",
    "postcode": "22160",
    "districtCode": "2103",
    "districtNameTh": "แกลง",
    "districtNameEn": "Klaeng",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210311",
    "subDistrictNameTh": "คลองปูน",
    "subDistrictNameEn": "Khlong Pun",
    "postcode": "21170",
    "districtCode": "2103",
    "districtNameTh": "แกลง",
    "districtNameEn": "Klaeng",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210312",
    "subDistrictNameTh": "พังราด",
    "subDistrictNameEn": "Phang Rat",
    "postcode": "21170",
    "districtCode": "2103",
    "districtNameTh": "แกลง",
    "districtNameEn": "Klaeng",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210313",
    "subDistrictNameTh": "ปากน้ำกระแส",
    "subDistrictNameEn": "Pak Nam Krasae",
    "postcode": "21170",
    "districtCode": "2103",
    "districtNameTh": "แกลง",
    "districtNameEn": "Klaeng",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210317",
    "subDistrictNameTh": "ห้วยยาง",
    "subDistrictNameEn": "Huai Yang",
    "postcode": "21110",
    "districtCode": "2103",
    "districtNameTh": "แกลง",
    "districtNameEn": "Klaeng",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210318",
    "subDistrictNameTh": "สองสลึง",
    "subDistrictNameEn": "Song Salueng",
    "postcode": "21110",
    "districtCode": "2103",
    "districtNameTh": "แกลง",
    "districtNameEn": "Klaeng",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210401",
    "subDistrictNameTh": "วังจันทร์",
    "subDistrictNameEn": "Wang Chan",
    "postcode": "21210",
    "districtCode": "2104",
    "districtNameTh": "วังจันทร์",
    "districtNameEn": "Wang Chan",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210402",
    "subDistrictNameTh": "ชุมแสง",
    "subDistrictNameEn": "Chum Saeng",
    "postcode": "21210",
    "districtCode": "2104",
    "districtNameTh": "วังจันทร์",
    "districtNameEn": "Wang Chan",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210403",
    "subDistrictNameTh": "ป่ายุบใน",
    "subDistrictNameEn": "Pa Yup Nai",
    "postcode": "21210",
    "districtCode": "2104",
    "districtNameTh": "วังจันทร์",
    "districtNameEn": "Wang Chan",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210404",
    "subDistrictNameTh": "พลงตาเอี่ยม",
    "subDistrictNameEn": "Phlong Ta Iam",
    "postcode": "21210",
    "districtCode": "2104",
    "districtNameTh": "วังจันทร์",
    "districtNameEn": "Wang Chan",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210501",
    "subDistrictNameTh": "บ้านค่าย",
    "subDistrictNameEn": "Ban Khai",
    "postcode": "21120",
    "districtCode": "2105",
    "districtNameTh": "บ้านค่าย",
    "districtNameEn": "Ban Khai",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210502",
    "subDistrictNameTh": "หนองละลอก",
    "subDistrictNameEn": "Nong Lalok",
    "postcode": "21120",
    "districtCode": "2105",
    "districtNameTh": "บ้านค่าย",
    "districtNameEn": "Ban Khai",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210503",
    "subDistrictNameTh": "หนองตะพาน",
    "subDistrictNameEn": "Nong Taphan",
    "postcode": "21120",
    "districtCode": "2105",
    "districtNameTh": "บ้านค่าย",
    "districtNameEn": "Ban Khai",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210504",
    "subDistrictNameTh": "ตาขัน",
    "subDistrictNameEn": "Ta Khan",
    "postcode": "21120",
    "districtCode": "2105",
    "districtNameTh": "บ้านค่าย",
    "districtNameEn": "Ban Khai",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210505",
    "subDistrictNameTh": "บางบุตร",
    "subDistrictNameEn": "Bang But",
    "postcode": "21120",
    "districtCode": "2105",
    "districtNameTh": "บ้านค่าย",
    "districtNameEn": "Ban Khai",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210506",
    "subDistrictNameTh": "หนองบัว",
    "subDistrictNameEn": "Nong Bua",
    "postcode": "21120",
    "districtCode": "2105",
    "districtNameTh": "บ้านค่าย",
    "districtNameEn": "Ban Khai",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210507",
    "subDistrictNameTh": "ชากบก",
    "subDistrictNameEn": "Chak Bok",
    "postcode": "21120",
    "districtCode": "2105",
    "districtNameTh": "บ้านค่าย",
    "districtNameEn": "Ban Khai",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210601",
    "subDistrictNameTh": "ปลวกแดง",
    "subDistrictNameEn": "Pluak Daeng",
    "postcode": "21140",
    "districtCode": "2106",
    "districtNameTh": "ปลวกแดง",
    "districtNameEn": "Pluak Daeng",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210602",
    "subDistrictNameTh": "ตาสิทธิ์",
    "subDistrictNameEn": "Ta Sit",
    "postcode": "21140",
    "districtCode": "2106",
    "districtNameTh": "ปลวกแดง",
    "districtNameEn": "Pluak Daeng",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210603",
    "subDistrictNameTh": "ละหาร",
    "subDistrictNameEn": "Lahan",
    "postcode": "21140",
    "districtCode": "2106",
    "districtNameTh": "ปลวกแดง",
    "districtNameEn": "Pluak Daeng",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210604",
    "subDistrictNameTh": "แม่น้ำคู้",
    "subDistrictNameEn": "Maenam Khu",
    "postcode": "21140",
    "districtCode": "2106",
    "districtNameTh": "ปลวกแดง",
    "districtNameEn": "Pluak Daeng",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210605",
    "subDistrictNameTh": "มาบยางพร",
    "subDistrictNameEn": "Map Yang Phon",
    "postcode": "21140",
    "districtCode": "2106",
    "districtNameTh": "ปลวกแดง",
    "districtNameEn": "Pluak Daeng",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210606",
    "subDistrictNameTh": "หนองไร่",
    "subDistrictNameEn": "Nong Rai",
    "postcode": "21140",
    "districtCode": "2106",
    "districtNameTh": "ปลวกแดง",
    "districtNameEn": "Pluak Daeng",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210701",
    "subDistrictNameTh": "น้ำเป็น",
    "subDistrictNameEn": "Nam Pen",
    "postcode": "21110",
    "districtCode": "2107",
    "districtNameTh": "เขาชะเมา",
    "districtNameEn": "Khao Chamao",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210702",
    "subDistrictNameTh": "ห้วยทับมอญ",
    "subDistrictNameEn": "Huai Thap Mon",
    "postcode": "21110",
    "districtCode": "2107",
    "districtNameTh": "เขาชะเมา",
    "districtNameEn": "Khao Chamao",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210703",
    "subDistrictNameTh": "ชำฆ้อ",
    "subDistrictNameEn": "Cham Kho",
    "postcode": "21110",
    "districtCode": "2107",
    "districtNameTh": "เขาชะเมา",
    "districtNameEn": "Khao Chamao",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210704",
    "subDistrictNameTh": "เขาน้อย",
    "subDistrictNameEn": "Khao Noi",
    "postcode": "21110",
    "districtCode": "2107",
    "districtNameTh": "เขาชะเมา",
    "districtNameEn": "Khao Chamao",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210801",
    "subDistrictNameTh": "นิคมพัฒนา",
    "subDistrictNameEn": "Nikhom Phatthana",
    "postcode": "21180",
    "districtCode": "2108",
    "districtNameTh": "นิคมพัฒนา",
    "districtNameEn": "Nikhom Phatthana",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210802",
    "subDistrictNameTh": "มาบข่า",
    "subDistrictNameEn": "Map Kha",
    "postcode": "21180",
    "districtCode": "2108",
    "districtNameTh": "นิคมพัฒนา",
    "districtNameEn": "Nikhom Phatthana",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210803",
    "subDistrictNameTh": "พนานิคม",
    "subDistrictNameEn": "Phana Nikhom",
    "postcode": "21180",
    "districtCode": "2108",
    "districtNameTh": "นิคมพัฒนา",
    "districtNameEn": "Nikhom Phatthana",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "210804",
    "subDistrictNameTh": "มะขามคู่",
    "subDistrictNameEn": "Makham Khu",
    "postcode": "21180",
    "districtCode": "2108",
    "districtNameTh": "นิคมพัฒนา",
    "districtNameEn": "Nikhom Phatthana",
    "provinceCode": "21",
    "provinceNameTh": "ระยอง",
    "provinceNameEn": "Rayong"
},
{
    "subDistrictCode": "220101",
    "subDistrictNameTh": "ตลาด",
    "subDistrictNameEn": "Talat",
    "postcode": "22000",
    "districtCode": "2201",
    "districtNameTh": "เมืองจันทบุรี",
    "districtNameEn": "Mueang Chanthaburi",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220102",
    "subDistrictNameTh": "วัดใหม่",
    "subDistrictNameEn": "Wat Mai",
    "postcode": "22000",
    "districtCode": "2201",
    "districtNameTh": "เมืองจันทบุรี",
    "districtNameEn": "Mueang Chanthaburi",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220103",
    "subDistrictNameTh": "คลองนารายณ์",
    "subDistrictNameEn": "Khlong Narai",
    "postcode": "22000",
    "districtCode": "2201",
    "districtNameTh": "เมืองจันทบุรี",
    "districtNameEn": "Mueang Chanthaburi",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220104",
    "subDistrictNameTh": "เกาะขวาง",
    "subDistrictNameEn": "Ko Khwang",
    "postcode": "22000",
    "districtCode": "2201",
    "districtNameTh": "เมืองจันทบุรี",
    "districtNameEn": "Mueang Chanthaburi",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220105",
    "subDistrictNameTh": "คมบาง",
    "subDistrictNameEn": "Khom Bang",
    "postcode": "22000",
    "districtCode": "2201",
    "districtNameTh": "เมืองจันทบุรี",
    "districtNameEn": "Mueang Chanthaburi",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220106",
    "subDistrictNameTh": "ท่าช้าง",
    "subDistrictNameEn": "Tha Chang",
    "postcode": "22000",
    "districtCode": "2201",
    "districtNameTh": "เมืองจันทบุรี",
    "districtNameEn": "Mueang Chanthaburi",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220107",
    "subDistrictNameTh": "จันทนิมิต",
    "subDistrictNameEn": "Chanthanimit",
    "postcode": "22000",
    "districtCode": "2201",
    "districtNameTh": "เมืองจันทบุรี",
    "districtNameEn": "Mueang Chanthaburi",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220108",
    "subDistrictNameTh": "บางกะจะ",
    "subDistrictNameEn": "Bang Kacha",
    "postcode": "22000",
    "districtCode": "2201",
    "districtNameTh": "เมืองจันทบุรี",
    "districtNameEn": "Mueang Chanthaburi",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220109",
    "subDistrictNameTh": "แสลง",
    "subDistrictNameEn": "Salaeng",
    "postcode": "22000",
    "districtCode": "2201",
    "districtNameTh": "เมืองจันทบุรี",
    "districtNameEn": "Mueang Chanthaburi",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220110",
    "subDistrictNameTh": "หนองบัว",
    "subDistrictNameEn": "Nong Bua",
    "postcode": "22000",
    "districtCode": "2201",
    "districtNameTh": "เมืองจันทบุรี",
    "districtNameEn": "Mueang Chanthaburi",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220111",
    "subDistrictNameTh": "พลับพลา",
    "subDistrictNameEn": "Phlapphla",
    "postcode": "22000",
    "districtCode": "2201",
    "districtNameTh": "เมืองจันทบุรี",
    "districtNameEn": "Mueang Chanthaburi",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220201",
    "subDistrictNameTh": "ขลุง",
    "subDistrictNameEn": "Khlung",
    "postcode": "22110",
    "districtCode": "2202",
    "districtNameTh": "ขลุง",
    "districtNameEn": "Khlung",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220202",
    "subDistrictNameTh": "บ่อ",
    "subDistrictNameEn": "Bo",
    "postcode": "22110",
    "districtCode": "2202",
    "districtNameTh": "ขลุง",
    "districtNameEn": "Khlung",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220203",
    "subDistrictNameTh": "เกวียนหัก",
    "subDistrictNameEn": "Kwian Hak",
    "postcode": "22110",
    "districtCode": "2202",
    "districtNameTh": "ขลุง",
    "districtNameEn": "Khlung",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220204",
    "subDistrictNameTh": "ตะปอน",
    "subDistrictNameEn": "Tapon",
    "postcode": "22110",
    "districtCode": "2202",
    "districtNameTh": "ขลุง",
    "districtNameEn": "Khlung",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220205",
    "subDistrictNameTh": "บางชัน",
    "subDistrictNameEn": "Bang Chan",
    "postcode": "22110",
    "districtCode": "2202",
    "districtNameTh": "ขลุง",
    "districtNameEn": "Khlung",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220206",
    "subDistrictNameTh": "วันยาว",
    "subDistrictNameEn": "Wan Yao",
    "postcode": "22110",
    "districtCode": "2202",
    "districtNameTh": "ขลุง",
    "districtNameEn": "Khlung",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220207",
    "subDistrictNameTh": "ซึ้ง",
    "subDistrictNameEn": "Sueng",
    "postcode": "22110",
    "districtCode": "2202",
    "districtNameTh": "ขลุง",
    "districtNameEn": "Khlung",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220208",
    "subDistrictNameTh": "มาบไพ",
    "subDistrictNameEn": "Map Phai",
    "postcode": "22110",
    "districtCode": "2202",
    "districtNameTh": "ขลุง",
    "districtNameEn": "Khlung",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220209",
    "subDistrictNameTh": "วังสรรพรส",
    "subDistrictNameEn": "Wang Sappharot",
    "postcode": "22110",
    "districtCode": "2202",
    "districtNameTh": "ขลุง",
    "districtNameEn": "Khlung",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220210",
    "subDistrictNameTh": "ตรอกนอง",
    "subDistrictNameEn": "Trok Nong",
    "postcode": "22110",
    "districtCode": "2202",
    "districtNameTh": "ขลุง",
    "districtNameEn": "Khlung",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220211",
    "subDistrictNameTh": "ตกพรม",
    "subDistrictNameEn": "Tok Phom",
    "postcode": "22110",
    "districtCode": "2202",
    "districtNameTh": "ขลุง",
    "districtNameEn": "Khlung",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220212",
    "subDistrictNameTh": "บ่อเวฬุ",
    "subDistrictNameEn": "Bo Weru",
    "postcode": "22150",
    "districtCode": "2202",
    "districtNameTh": "ขลุง",
    "districtNameEn": "Khlung",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220301",
    "subDistrictNameTh": "ท่าใหม่",
    "subDistrictNameEn": "Tha Mai",
    "postcode": "22120",
    "districtCode": "2203",
    "districtNameTh": "ท่าใหม่",
    "districtNameEn": "Tha Mai",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220302",
    "subDistrictNameTh": "ยายร้า",
    "subDistrictNameEn": "Yai Ra",
    "postcode": "22120",
    "districtCode": "2203",
    "districtNameTh": "ท่าใหม่",
    "districtNameEn": "Tha Mai",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220303",
    "subDistrictNameTh": "สีพยา",
    "subDistrictNameEn": "Si Phaya",
    "postcode": "22120",
    "districtCode": "2203",
    "districtNameTh": "ท่าใหม่",
    "districtNameEn": "Tha Mai",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220304",
    "subDistrictNameTh": "บ่อพุ",
    "subDistrictNameEn": "Bo Phu",
    "postcode": "22120",
    "districtCode": "2203",
    "districtNameTh": "ท่าใหม่",
    "districtNameEn": "Tha Mai",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220305",
    "subDistrictNameTh": "พลอยแหวน",
    "subDistrictNameEn": "Phloi Waen",
    "postcode": "22120",
    "districtCode": "2203",
    "districtNameTh": "ท่าใหม่",
    "districtNameEn": "Tha Mai",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220306",
    "subDistrictNameTh": "เขาวัว",
    "subDistrictNameEn": "Khao Wua",
    "postcode": "22120",
    "districtCode": "2203",
    "districtNameTh": "ท่าใหม่",
    "districtNameEn": "Tha Mai",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220307",
    "subDistrictNameTh": "เขาบายศรี",
    "subDistrictNameEn": "Khao Baisi",
    "postcode": "22120",
    "districtCode": "2203",
    "districtNameTh": "ท่าใหม่",
    "districtNameEn": "Tha Mai",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220308",
    "subDistrictNameTh": "สองพี่น้อง",
    "subDistrictNameEn": "Song Phi Nong",
    "postcode": "22120",
    "districtCode": "2203",
    "districtNameTh": "ท่าใหม่",
    "districtNameEn": "Tha Mai",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220309",
    "subDistrictNameTh": "ทุ่งเบญจา",
    "subDistrictNameEn": "Thung Bencha",
    "postcode": "22170",
    "districtCode": "2203",
    "districtNameTh": "ท่าใหม่",
    "districtNameEn": "Tha Mai",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220311",
    "subDistrictNameTh": "รำพัน",
    "subDistrictNameEn": "Ramphan",
    "postcode": "22170",
    "districtCode": "2203",
    "districtNameTh": "ท่าใหม่",
    "districtNameEn": "Tha Mai",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220312",
    "subDistrictNameTh": "โขมง",
    "subDistrictNameEn": "Khamong",
    "postcode": "22170",
    "districtCode": "2203",
    "districtNameTh": "ท่าใหม่",
    "districtNameEn": "Tha Mai",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220313",
    "subDistrictNameTh": "ตะกาดเง้า",
    "subDistrictNameEn": "Takat Ngao",
    "postcode": "22120",
    "districtCode": "2203",
    "districtNameTh": "ท่าใหม่",
    "districtNameEn": "Tha Mai",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220314",
    "subDistrictNameTh": "คลองขุด",
    "subDistrictNameEn": "Khlong Khut",
    "postcode": "22120",
    "districtCode": "2203",
    "districtNameTh": "ท่าใหม่",
    "districtNameEn": "Tha Mai",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220324",
    "subDistrictNameTh": "เขาแก้ว",
    "subDistrictNameEn": "Khao Kaeo",
    "postcode": "22170",
    "districtCode": "2203",
    "districtNameTh": "ท่าใหม่",
    "districtNameEn": "Tha Mai",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220401",
    "subDistrictNameTh": "ทับไทร",
    "subDistrictNameEn": "Thap Sai",
    "postcode": "22140",
    "districtCode": "2204",
    "districtNameTh": "โป่งน้ำร้อน",
    "districtNameEn": "Pong Nam Ron",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220402",
    "subDistrictNameTh": "โป่งน้ำร้อน",
    "subDistrictNameEn": "Pong Nam Ron",
    "postcode": "22140",
    "districtCode": "2204",
    "districtNameTh": "โป่งน้ำร้อน",
    "districtNameEn": "Pong Nam Ron",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220404",
    "subDistrictNameTh": "หนองตาคง",
    "subDistrictNameEn": "Nong Ta Khong",
    "postcode": "22140",
    "districtCode": "2204",
    "districtNameTh": "โป่งน้ำร้อน",
    "districtNameEn": "Pong Nam Ron",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220409",
    "subDistrictNameTh": "เทพนิมิต",
    "subDistrictNameEn": "Thep Nimit",
    "postcode": "22140",
    "districtCode": "2204",
    "districtNameTh": "โป่งน้ำร้อน",
    "districtNameEn": "Pong Nam Ron",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220410",
    "subDistrictNameTh": "คลองใหญ่",
    "subDistrictNameEn": "Khlong Yai",
    "postcode": "22140",
    "districtCode": "2204",
    "districtNameTh": "โป่งน้ำร้อน",
    "districtNameEn": "Pong Nam Ron",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220501",
    "subDistrictNameTh": "มะขาม",
    "subDistrictNameEn": "Makham",
    "postcode": "22150",
    "districtCode": "2205",
    "districtNameTh": "มะขาม",
    "districtNameEn": "Makham",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220502",
    "subDistrictNameTh": "ท่าหลวง",
    "subDistrictNameEn": "Tha Luang",
    "postcode": "22150",
    "districtCode": "2205",
    "districtNameTh": "มะขาม",
    "districtNameEn": "Makham",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220503",
    "subDistrictNameTh": "ปัถวี",
    "subDistrictNameEn": "Patthawi",
    "postcode": "22150",
    "districtCode": "2205",
    "districtNameTh": "มะขาม",
    "districtNameEn": "Makham",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220504",
    "subDistrictNameTh": "วังแซ้ม",
    "subDistrictNameEn": "Wang Saem",
    "postcode": "22150",
    "districtCode": "2205",
    "districtNameTh": "มะขาม",
    "districtNameEn": "Makham",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220506",
    "subDistrictNameTh": "ฉมัน",
    "subDistrictNameEn": "Chaman",
    "postcode": "22150",
    "districtCode": "2205",
    "districtNameTh": "มะขาม",
    "districtNameEn": "Makham",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220508",
    "subDistrictNameTh": "อ่างคีรี",
    "subDistrictNameEn": "Ang Khiri",
    "postcode": "22150",
    "districtCode": "2205",
    "districtNameTh": "มะขาม",
    "districtNameEn": "Makham",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220601",
    "subDistrictNameTh": "ปากน้ำแหลมสิงห์",
    "subDistrictNameEn": "Pak Nam Laem Sing",
    "postcode": "22130",
    "districtCode": "2206",
    "districtNameTh": "แหลมสิงห์",
    "districtNameEn": "Laem Sing",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220602",
    "subDistrictNameTh": "เกาะเปริด",
    "subDistrictNameEn": "Ko Proet",
    "postcode": "22130",
    "districtCode": "2206",
    "districtNameTh": "แหลมสิงห์",
    "districtNameEn": "Laem Sing",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220603",
    "subDistrictNameTh": "หนองชิ่ม",
    "subDistrictNameEn": "Nong Chim",
    "postcode": "22130",
    "districtCode": "2206",
    "districtNameTh": "แหลมสิงห์",
    "districtNameEn": "Laem Sing",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220604",
    "subDistrictNameTh": "พลิ้ว",
    "subDistrictNameEn": "Phlio",
    "postcode": "22190",
    "districtCode": "2206",
    "districtNameTh": "แหลมสิงห์",
    "districtNameEn": "Laem Sing",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220605",
    "subDistrictNameTh": "คลองน้ำเค็ม",
    "subDistrictNameEn": "Khlong Nam Khem",
    "postcode": "22190",
    "districtCode": "2206",
    "districtNameTh": "แหลมสิงห์",
    "districtNameEn": "Laem Sing",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220606",
    "subDistrictNameTh": "บางสระเก้า",
    "subDistrictNameEn": "Bang Sa Kao",
    "postcode": "22190",
    "districtCode": "2206",
    "districtNameTh": "แหลมสิงห์",
    "districtNameEn": "Laem Sing",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220607",
    "subDistrictNameTh": "บางกะไชย",
    "subDistrictNameEn": "Bang Kachai",
    "postcode": "22120",
    "districtCode": "2206",
    "districtNameTh": "แหลมสิงห์",
    "districtNameEn": "Laem Sing",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220701",
    "subDistrictNameTh": "ปะตง",
    "subDistrictNameEn": "Patong",
    "postcode": "22180",
    "districtCode": "2207",
    "districtNameTh": "สอยดาว",
    "districtNameEn": "Soi Dao",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220702",
    "subDistrictNameTh": "ทุ่งขนาน",
    "subDistrictNameEn": "Thung Khanan",
    "postcode": "22180",
    "districtCode": "2207",
    "districtNameTh": "สอยดาว",
    "districtNameEn": "Soi Dao",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220703",
    "subDistrictNameTh": "ทับช้าง",
    "subDistrictNameEn": "Thap Chang",
    "postcode": "22180",
    "districtCode": "2207",
    "districtNameTh": "สอยดาว",
    "districtNameEn": "Soi Dao",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220704",
    "subDistrictNameTh": "ทรายขาว",
    "subDistrictNameEn": "Sai Khao",
    "postcode": "22180",
    "districtCode": "2207",
    "districtNameTh": "สอยดาว",
    "districtNameEn": "Soi Dao",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220705",
    "subDistrictNameTh": "สะตอน",
    "subDistrictNameEn": "Saton",
    "postcode": "22180",
    "districtCode": "2207",
    "districtNameTh": "สอยดาว",
    "districtNameEn": "Soi Dao",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220801",
    "subDistrictNameTh": "แก่งหางแมว",
    "subDistrictNameEn": "Kaeng Hang Maeo",
    "postcode": "22160",
    "districtCode": "2208",
    "districtNameTh": "แก่งหางแมว",
    "districtNameEn": "Kaeng Hang Maeo",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220802",
    "subDistrictNameTh": "ขุนซ่อง",
    "subDistrictNameEn": "Khun Song",
    "postcode": "22160",
    "districtCode": "2208",
    "districtNameTh": "แก่งหางแมว",
    "districtNameEn": "Kaeng Hang Maeo",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220803",
    "subDistrictNameTh": "สามพี่น้อง",
    "subDistrictNameEn": "Sam Phi Nong",
    "postcode": "22160",
    "districtCode": "2208",
    "districtNameTh": "แก่งหางแมว",
    "districtNameEn": "Kaeng Hang Maeo",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220804",
    "subDistrictNameTh": "พวา",
    "subDistrictNameEn": "Phawa",
    "postcode": "22160",
    "districtCode": "2208",
    "districtNameTh": "แก่งหางแมว",
    "districtNameEn": "Kaeng Hang Maeo",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220805",
    "subDistrictNameTh": "เขาวงกต",
    "subDistrictNameEn": "Khao Wongkot",
    "postcode": "22160",
    "districtCode": "2208",
    "districtNameTh": "แก่งหางแมว",
    "districtNameEn": "Kaeng Hang Maeo",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220901",
    "subDistrictNameTh": "นายายอาม",
    "subDistrictNameEn": "Na Yai Am",
    "postcode": "22160",
    "districtCode": "2209",
    "districtNameTh": "นายายอาม",
    "districtNameEn": "Na Yai Am",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220902",
    "subDistrictNameTh": "วังโตนด",
    "subDistrictNameEn": "Wang Tanot",
    "postcode": "22170",
    "districtCode": "2209",
    "districtNameTh": "นายายอาม",
    "districtNameEn": "Na Yai Am",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220903",
    "subDistrictNameTh": "กระแจะ",
    "subDistrictNameEn": "Krachae",
    "postcode": "22170",
    "districtCode": "2209",
    "districtNameTh": "นายายอาม",
    "districtNameEn": "Na Yai Am",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220904",
    "subDistrictNameTh": "สนามไชย",
    "subDistrictNameEn": "Sanam Chai",
    "postcode": "22170",
    "districtCode": "2209",
    "districtNameTh": "นายายอาม",
    "districtNameEn": "Na Yai Am",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220905",
    "subDistrictNameTh": "ช้างข้าม",
    "subDistrictNameEn": "Chang Kham",
    "postcode": "22160",
    "districtCode": "2209",
    "districtNameTh": "นายายอาม",
    "districtNameEn": "Na Yai Am",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "220906",
    "subDistrictNameTh": "วังใหม่",
    "subDistrictNameEn": "Wang Mai",
    "postcode": "22170",
    "districtCode": "2209",
    "districtNameTh": "นายายอาม",
    "districtNameEn": "Na Yai Am",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "221001",
    "subDistrictNameTh": "ชากไทย",
    "subDistrictNameEn": "Chak Thai",
    "postcode": "22210",
    "districtCode": "2210",
    "districtNameTh": "เขาคิชฌกูฏ",
    "districtNameEn": "Khao Khitchakut",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "221002",
    "subDistrictNameTh": "พลวง",
    "subDistrictNameEn": "Phluang",
    "postcode": "22210",
    "districtCode": "2210",
    "districtNameTh": "เขาคิชฌกูฏ",
    "districtNameEn": "Khao Khitchakut",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "221003",
    "subDistrictNameTh": "ตะเคียนทอง",
    "subDistrictNameEn": "Takhian Thong",
    "postcode": "22210",
    "districtCode": "2210",
    "districtNameTh": "เขาคิชฌกูฏ",
    "districtNameEn": "Khao Khitchakut",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "221004",
    "subDistrictNameTh": "คลองพลู",
    "subDistrictNameEn": "Khlong Phlu",
    "postcode": "22210",
    "districtCode": "2210",
    "districtNameTh": "เขาคิชฌกูฏ",
    "districtNameEn": "Khao Khitchakut",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "221005",
    "subDistrictNameTh": "จันทเขลม",
    "subDistrictNameEn": "Chanthakhlem",
    "postcode": "22210",
    "districtCode": "2210",
    "districtNameTh": "เขาคิชฌกูฏ",
    "districtNameEn": "Khao Khitchakut",
    "provinceCode": "22",
    "provinceNameTh": "จันทบุรี",
    "provinceNameEn": "Chanthaburi"
},
{
    "subDistrictCode": "230101",
    "subDistrictNameTh": "บางพระ",
    "subDistrictNameEn": "Bang Phra",
    "postcode": "23000",
    "districtCode": "2301",
    "districtNameTh": "เมืองตราด",
    "districtNameEn": "Mueang Trat",
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "subDistrictCode": "230102",
    "subDistrictNameTh": "หนองเสม็ด",
    "subDistrictNameEn": "Nong Samet",
    "postcode": "23000",
    "districtCode": "2301",
    "districtNameTh": "เมืองตราด",
    "districtNameEn": "Mueang Trat",
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "subDistrictCode": "230103",
    "subDistrictNameTh": "หนองโสน",
    "subDistrictNameEn": "Nong Sano",
    "postcode": "23000",
    "districtCode": "2301",
    "districtNameTh": "เมืองตราด",
    "districtNameEn": "Mueang Trat",
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "subDistrictCode": "230104",
    "subDistrictNameTh": "หนองคันทรง",
    "subDistrictNameEn": "Nong Khan Song",
    "postcode": "23000",
    "districtCode": "2301",
    "districtNameTh": "เมืองตราด",
    "districtNameEn": "Mueang Trat",
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "subDistrictCode": "230105",
    "subDistrictNameTh": "ห้วงน้ำขาว",
    "subDistrictNameEn": "Huang Nam Khao",
    "postcode": "23000",
    "districtCode": "2301",
    "districtNameTh": "เมืองตราด",
    "districtNameEn": "Mueang Trat",
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "subDistrictCode": "230106",
    "subDistrictNameTh": "อ่าวใหญ่",
    "subDistrictNameEn": "Ao Yai",
    "postcode": "23000",
    "districtCode": "2301",
    "districtNameTh": "เมืองตราด",
    "districtNameEn": "Mueang Trat",
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "subDistrictCode": "230107",
    "subDistrictNameTh": "วังกระแจะ",
    "subDistrictNameEn": "Wang Krachae",
    "postcode": "23000",
    "districtCode": "2301",
    "districtNameTh": "เมืองตราด",
    "districtNameEn": "Mueang Trat",
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "subDistrictCode": "230108",
    "subDistrictNameTh": "ห้วยแร้ง",
    "subDistrictNameEn": "Huai Raeng",
    "postcode": "23000",
    "districtCode": "2301",
    "districtNameTh": "เมืองตราด",
    "districtNameEn": "Mueang Trat",
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "subDistrictCode": "230109",
    "subDistrictNameTh": "เนินทราย",
    "subDistrictNameEn": "Noen Sai",
    "postcode": "23000",
    "districtCode": "2301",
    "districtNameTh": "เมืองตราด",
    "districtNameEn": "Mueang Trat",
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "subDistrictCode": "230110",
    "subDistrictNameTh": "ท่าพริก",
    "subDistrictNameEn": "Tha Phrik",
    "postcode": "23000",
    "districtCode": "2301",
    "districtNameTh": "เมืองตราด",
    "districtNameEn": "Mueang Trat",
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "subDistrictCode": "230111",
    "subDistrictNameTh": "ท่ากุ่ม",
    "subDistrictNameEn": "Tha Kum",
    "postcode": "23000",
    "districtCode": "2301",
    "districtNameTh": "เมืองตราด",
    "districtNameEn": "Mueang Trat",
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "subDistrictCode": "230112",
    "subDistrictNameTh": "ตะกาง",
    "subDistrictNameEn": "Takang",
    "postcode": "23000",
    "districtCode": "2301",
    "districtNameTh": "เมืองตราด",
    "districtNameEn": "Mueang Trat",
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "subDistrictCode": "230113",
    "subDistrictNameTh": "ชำราก",
    "subDistrictNameEn": "Chamrak",
    "postcode": "23000",
    "districtCode": "2301",
    "districtNameTh": "เมืองตราด",
    "districtNameEn": "Mueang Trat",
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "subDistrictCode": "230114",
    "subDistrictNameTh": "แหลมกลัด",
    "subDistrictNameEn": "Laem Klat",
    "postcode": "23000",
    "districtCode": "2301",
    "districtNameTh": "เมืองตราด",
    "districtNameEn": "Mueang Trat",
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "subDistrictCode": "230201",
    "subDistrictNameTh": "คลองใหญ่",
    "subDistrictNameEn": "Khlong Yai",
    "postcode": "23110",
    "districtCode": "2302",
    "districtNameTh": "คลองใหญ่",
    "districtNameEn": "Khlong Yai",
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "subDistrictCode": "230202",
    "subDistrictNameTh": "ไม้รูด",
    "subDistrictNameEn": "Mai Rut",
    "postcode": "23110",
    "districtCode": "2302",
    "districtNameTh": "คลองใหญ่",
    "districtNameEn": "Khlong Yai",
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "subDistrictCode": "230203",
    "subDistrictNameTh": "หาดเล็ก",
    "subDistrictNameEn": "Hat Lek",
    "postcode": "23110",
    "districtCode": "2302",
    "districtNameTh": "คลองใหญ่",
    "districtNameEn": "Khlong Yai",
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "subDistrictCode": "230301",
    "subDistrictNameTh": "เขาสมิง",
    "subDistrictNameEn": "Khao Saming",
    "postcode": "23130",
    "districtCode": "2303",
    "districtNameTh": "เขาสมิง",
    "districtNameEn": "Khao Saming",
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "subDistrictCode": "230302",
    "subDistrictNameTh": "แสนตุ้ง",
    "subDistrictNameEn": "Saen Tung",
    "postcode": "23150",
    "districtCode": "2303",
    "districtNameTh": "เขาสมิง",
    "districtNameEn": "Khao Saming",
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "subDistrictCode": "230303",
    "subDistrictNameTh": "วังตะเคียน",
    "subDistrictNameEn": "Wang Takhian",
    "postcode": "23130",
    "districtCode": "2303",
    "districtNameTh": "เขาสมิง",
    "districtNameEn": "Khao Saming",
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "subDistrictCode": "230304",
    "subDistrictNameTh": "ท่าโสม",
    "subDistrictNameEn": "Tha Som",
    "postcode": "23150",
    "districtCode": "2303",
    "districtNameTh": "เขาสมิง",
    "districtNameEn": "Khao Saming",
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "subDistrictCode": "230305",
    "subDistrictNameTh": "สะตอ",
    "subDistrictNameEn": "Sato",
    "postcode": "23150",
    "districtCode": "2303",
    "districtNameTh": "เขาสมิง",
    "districtNameEn": "Khao Saming",
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "subDistrictCode": "230306",
    "subDistrictNameTh": "ประณีต",
    "subDistrictNameEn": "Pranit",
    "postcode": "23150",
    "districtCode": "2303",
    "districtNameTh": "เขาสมิง",
    "districtNameEn": "Khao Saming",
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "subDistrictCode": "230307",
    "subDistrictNameTh": "เทพนิมิต",
    "subDistrictNameEn": "Thep Nimit",
    "postcode": "23150",
    "districtCode": "2303",
    "districtNameTh": "เขาสมิง",
    "districtNameEn": "Khao Saming",
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "subDistrictCode": "230308",
    "subDistrictNameTh": "ทุ่งนนทรี",
    "subDistrictNameEn": "Thung Nonsi",
    "postcode": "23130",
    "districtCode": "2303",
    "districtNameTh": "เขาสมิง",
    "districtNameEn": "Khao Saming",
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "subDistrictCode": "230401",
    "subDistrictNameTh": "บ่อพลอย",
    "subDistrictNameEn": "Bo Phloi",
    "postcode": "23140",
    "districtCode": "2304",
    "districtNameTh": "บ่อไร่",
    "districtNameEn": "Bo Rai",
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "subDistrictCode": "230402",
    "subDistrictNameTh": "ช้างทูน",
    "subDistrictNameEn": "Chang Thun",
    "postcode": "23140",
    "districtCode": "2304",
    "districtNameTh": "บ่อไร่",
    "districtNameEn": "Bo Rai",
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "subDistrictCode": "230403",
    "subDistrictNameTh": "ด่านชุมพล",
    "subDistrictNameEn": "Dan Chumphon",
    "postcode": "23140",
    "districtCode": "2304",
    "districtNameTh": "บ่อไร่",
    "districtNameEn": "Bo Rai",
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "subDistrictCode": "230404",
    "subDistrictNameTh": "หนองบอน",
    "subDistrictNameEn": "Nong Bon",
    "postcode": "23140",
    "districtCode": "2304",
    "districtNameTh": "บ่อไร่",
    "districtNameEn": "Bo Rai",
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "subDistrictCode": "230405",
    "subDistrictNameTh": "นนทรีย์",
    "subDistrictNameEn": "Nonsi",
    "postcode": "23140",
    "districtCode": "2304",
    "districtNameTh": "บ่อไร่",
    "districtNameEn": "Bo Rai",
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "subDistrictCode": "230501",
    "subDistrictNameTh": "แหลมงอบ",
    "subDistrictNameEn": "Laem Ngop",
    "postcode": "23120",
    "districtCode": "2305",
    "districtNameTh": "แหลมงอบ",
    "districtNameEn": "Laem Ngop",
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "subDistrictCode": "230502",
    "subDistrictNameTh": "น้ำเชี่ยว",
    "subDistrictNameEn": "Nam Chiao",
    "postcode": "23120",
    "districtCode": "2305",
    "districtNameTh": "แหลมงอบ",
    "districtNameEn": "Laem Ngop",
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "subDistrictCode": "230503",
    "subDistrictNameTh": "บางปิด",
    "subDistrictNameEn": "Bang Pit",
    "postcode": "23120",
    "districtCode": "2305",
    "districtNameTh": "แหลมงอบ",
    "districtNameEn": "Laem Ngop",
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "subDistrictCode": "230507",
    "subDistrictNameTh": "คลองใหญ่",
    "subDistrictNameEn": "Khlong Yai",
    "postcode": "23120",
    "districtCode": "2305",
    "districtNameTh": "แหลมงอบ",
    "districtNameEn": "Laem Ngop",
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "subDistrictCode": "230601",
    "subDistrictNameTh": "เกาะหมาก",
    "subDistrictNameEn": "Ko Mak",
    "postcode": "23120",
    "districtCode": "2306",
    "districtNameTh": "เกาะกูด",
    "districtNameEn": "Ko Kut",
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "subDistrictCode": "230602",
    "subDistrictNameTh": "เกาะกูด",
    "subDistrictNameEn": "Ko Kut",
    "postcode": "23000",
    "districtCode": "2306",
    "districtNameTh": "เกาะกูด",
    "districtNameEn": "Ko Kut",
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "subDistrictCode": "230701",
    "subDistrictNameTh": "เกาะช้าง",
    "subDistrictNameEn": "Ko Chang",
    "postcode": "23170",
    "districtCode": "2307",
    "districtNameTh": "เกาะช้าง",
    "districtNameEn": "Ko Chang",
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "subDistrictCode": "230702",
    "subDistrictNameTh": "เกาะช้างใต้",
    "subDistrictNameEn": "Ko Chang Tai",
    "postcode": "23170",
    "districtCode": "2307",
    "districtNameTh": "เกาะช้าง",
    "districtNameEn": "Ko Chang",
    "provinceCode": "23",
    "provinceNameTh": "ตราด",
    "provinceNameEn": "Trat"
},
{
    "subDistrictCode": "240101",
    "subDistrictNameTh": "หน้าเมือง",
    "subDistrictNameEn": "Na Mueang",
    "postcode": "24000",
    "districtCode": "2401",
    "districtNameTh": "เมืองฉะเชิงเทรา",
    "districtNameEn": "Mueang Chachoengsao",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240102",
    "subDistrictNameTh": "ท่าไข่",
    "subDistrictNameEn": "Tha Khai",
    "postcode": "24000",
    "districtCode": "2401",
    "districtNameTh": "เมืองฉะเชิงเทรา",
    "districtNameEn": "Mueang Chachoengsao",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240103",
    "subDistrictNameTh": "บ้านใหม่",
    "subDistrictNameEn": "Ban Mai",
    "postcode": "24000",
    "districtCode": "2401",
    "districtNameTh": "เมืองฉะเชิงเทรา",
    "districtNameEn": "Mueang Chachoengsao",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240104",
    "subDistrictNameTh": "คลองนา",
    "subDistrictNameEn": "Khlong Na",
    "postcode": "24000",
    "districtCode": "2401",
    "districtNameTh": "เมืองฉะเชิงเทรา",
    "districtNameEn": "Mueang Chachoengsao",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240105",
    "subDistrictNameTh": "บางตีนเป็ด",
    "subDistrictNameEn": "Bang Tin Pet",
    "postcode": "24000",
    "districtCode": "2401",
    "districtNameTh": "เมืองฉะเชิงเทรา",
    "districtNameEn": "Mueang Chachoengsao",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240106",
    "subDistrictNameTh": "บางไผ่",
    "subDistrictNameEn": "Bang Phai",
    "postcode": "24000",
    "districtCode": "2401",
    "districtNameTh": "เมืองฉะเชิงเทรา",
    "districtNameEn": "Mueang Chachoengsao",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240107",
    "subDistrictNameTh": "คลองจุกกระเฌอ",
    "subDistrictNameEn": "Khlong Chuk Krachoe",
    "postcode": "24000",
    "districtCode": "2401",
    "districtNameTh": "เมืองฉะเชิงเทรา",
    "districtNameEn": "Mueang Chachoengsao",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240108",
    "subDistrictNameTh": "บางแก้ว",
    "subDistrictNameEn": "Bang Kaeo",
    "postcode": "24000",
    "districtCode": "2401",
    "districtNameTh": "เมืองฉะเชิงเทรา",
    "districtNameEn": "Mueang Chachoengsao",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240109",
    "subDistrictNameTh": "บางขวัญ",
    "subDistrictNameEn": "Bang Khwan",
    "postcode": "24000",
    "districtCode": "2401",
    "districtNameTh": "เมืองฉะเชิงเทรา",
    "districtNameEn": "Mueang Chachoengsao",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240110",
    "subDistrictNameTh": "คลองนครเนื่องเขต",
    "subDistrictNameEn": "Khlong Nakhon Nueang Khet",
    "postcode": "24000",
    "districtCode": "2401",
    "districtNameTh": "เมืองฉะเชิงเทรา",
    "districtNameEn": "Mueang Chachoengsao",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240111",
    "subDistrictNameTh": "วังตะเคียน",
    "subDistrictNameEn": "Wang Takhian",
    "postcode": "24000",
    "districtCode": "2401",
    "districtNameTh": "เมืองฉะเชิงเทรา",
    "districtNameEn": "Mueang Chachoengsao",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240112",
    "subDistrictNameTh": "โสธร",
    "subDistrictNameEn": "Sothon",
    "postcode": "24000",
    "districtCode": "2401",
    "districtNameTh": "เมืองฉะเชิงเทรา",
    "districtNameEn": "Mueang Chachoengsao",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240113",
    "subDistrictNameTh": "บางพระ",
    "subDistrictNameEn": "Bang Phra",
    "postcode": "24000",
    "districtCode": "2401",
    "districtNameTh": "เมืองฉะเชิงเทรา",
    "districtNameEn": "Mueang Chachoengsao",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240114",
    "subDistrictNameTh": "บางกะไห",
    "subDistrictNameEn": "Bang Kahai",
    "postcode": "24000",
    "districtCode": "2401",
    "districtNameTh": "เมืองฉะเชิงเทรา",
    "districtNameEn": "Mueang Chachoengsao",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240115",
    "subDistrictNameTh": "หนามแดง",
    "subDistrictNameEn": "Nam Daeng",
    "postcode": "24000",
    "districtCode": "2401",
    "districtNameTh": "เมืองฉะเชิงเทรา",
    "districtNameEn": "Mueang Chachoengsao",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240116",
    "subDistrictNameTh": "คลองเปรง",
    "subDistrictNameEn": "Khlong Preng",
    "postcode": "24000",
    "districtCode": "2401",
    "districtNameTh": "เมืองฉะเชิงเทรา",
    "districtNameEn": "Mueang Chachoengsao",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240117",
    "subDistrictNameTh": "คลองอุดมชลจร",
    "subDistrictNameEn": "Khlong Udom Chonlachon",
    "postcode": "24000",
    "districtCode": "2401",
    "districtNameTh": "เมืองฉะเชิงเทรา",
    "districtNameEn": "Mueang Chachoengsao",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240118",
    "subDistrictNameTh": "คลองหลวงแพ่ง",
    "subDistrictNameEn": "Khlong Luang Phaeng",
    "postcode": "24000",
    "districtCode": "2401",
    "districtNameTh": "เมืองฉะเชิงเทรา",
    "districtNameEn": "Mueang Chachoengsao",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240119",
    "subDistrictNameTh": "บางเตย",
    "subDistrictNameEn": "Bang Toei",
    "postcode": "24000",
    "districtCode": "2401",
    "districtNameTh": "เมืองฉะเชิงเทรา",
    "districtNameEn": "Mueang Chachoengsao",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240201",
    "subDistrictNameTh": "บางคล้า",
    "subDistrictNameEn": "Bang Khla",
    "postcode": "24110",
    "districtCode": "2402",
    "districtNameTh": "บางคล้า",
    "districtNameEn": "Bang Khla",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240204",
    "subDistrictNameTh": "บางสวน",
    "subDistrictNameEn": "Bang Suan",
    "postcode": "24110",
    "districtCode": "2402",
    "districtNameTh": "บางคล้า",
    "districtNameEn": "Bang Khla",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240208",
    "subDistrictNameTh": "บางกระเจ็ด",
    "subDistrictNameEn": "Bang Krachet",
    "postcode": "24110",
    "districtCode": "2402",
    "districtNameTh": "บางคล้า",
    "districtNameEn": "Bang Khla",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240209",
    "subDistrictNameTh": "ปากน้ำ",
    "subDistrictNameEn": "Pak Nam",
    "postcode": "24110",
    "districtCode": "2402",
    "districtNameTh": "บางคล้า",
    "districtNameEn": "Bang Khla",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240210",
    "subDistrictNameTh": "ท่าทองหลาง",
    "subDistrictNameEn": "Tha Thonglang",
    "postcode": "24110",
    "districtCode": "2402",
    "districtNameTh": "บางคล้า",
    "districtNameEn": "Bang Khla",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240211",
    "subDistrictNameTh": "สาวชะโงก",
    "subDistrictNameEn": "Sao Cha-ngok",
    "postcode": "24110",
    "districtCode": "2402",
    "districtNameTh": "บางคล้า",
    "districtNameEn": "Bang Khla",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240212",
    "subDistrictNameTh": "เสม็ดเหนือ",
    "subDistrictNameEn": "Samet Nuea",
    "postcode": "24110",
    "districtCode": "2402",
    "districtNameTh": "บางคล้า",
    "districtNameEn": "Bang Khla",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240213",
    "subDistrictNameTh": "เสม็ดใต้",
    "subDistrictNameEn": "Samet Tai",
    "postcode": "24110",
    "districtCode": "2402",
    "districtNameTh": "บางคล้า",
    "districtNameEn": "Bang Khla",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240214",
    "subDistrictNameTh": "หัวไทร",
    "subDistrictNameEn": "Hua Sai",
    "postcode": "24110",
    "districtCode": "2402",
    "districtNameTh": "บางคล้า",
    "districtNameEn": "Bang Khla",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240301",
    "subDistrictNameTh": "บางน้ำเปรี้ยว",
    "subDistrictNameEn": "Bang Nam Priao",
    "postcode": "24150",
    "districtCode": "2403",
    "districtNameTh": "บางน้ำเปรี้ยว",
    "districtNameEn": "Bang Nam Priao",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240302",
    "subDistrictNameTh": "บางขนาก",
    "subDistrictNameEn": "Bang Khanak",
    "postcode": "24150",
    "districtCode": "2403",
    "districtNameTh": "บางน้ำเปรี้ยว",
    "districtNameEn": "Bang Nam Priao",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240303",
    "subDistrictNameTh": "สิงโตทอง",
    "subDistrictNameEn": "Singto Thong",
    "postcode": "24150",
    "districtCode": "2403",
    "districtNameTh": "บางน้ำเปรี้ยว",
    "districtNameEn": "Bang Nam Priao",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240304",
    "subDistrictNameTh": "หมอนทอง",
    "subDistrictNameEn": "Mon Thong",
    "postcode": "24150",
    "districtCode": "2403",
    "districtNameTh": "บางน้ำเปรี้ยว",
    "districtNameEn": "Bang Nam Priao",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240305",
    "subDistrictNameTh": "บึงน้ำรักษ์",
    "subDistrictNameEn": "Bueng Nam Rak",
    "postcode": "24170",
    "districtCode": "2403",
    "districtNameTh": "บางน้ำเปรี้ยว",
    "districtNameEn": "Bang Nam Priao",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240306",
    "subDistrictNameTh": "ดอนเกาะกา",
    "subDistrictNameEn": "Don Ko Ka",
    "postcode": "24170",
    "districtCode": "2403",
    "districtNameTh": "บางน้ำเปรี้ยว",
    "districtNameEn": "Bang Nam Priao",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240307",
    "subDistrictNameTh": "โยธะกา",
    "subDistrictNameEn": "Yothaka",
    "postcode": "24150",
    "districtCode": "2403",
    "districtNameTh": "บางน้ำเปรี้ยว",
    "districtNameEn": "Bang Nam Priao",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240308",
    "subDistrictNameTh": "ดอนฉิมพลี",
    "subDistrictNameEn": "Don Chimphli",
    "postcode": "24170",
    "districtCode": "2403",
    "districtNameTh": "บางน้ำเปรี้ยว",
    "districtNameEn": "Bang Nam Priao",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240309",
    "subDistrictNameTh": "ศาลาแดง",
    "subDistrictNameEn": "Sala Daeng",
    "postcode": "24000",
    "districtCode": "2403",
    "districtNameTh": "บางน้ำเปรี้ยว",
    "districtNameEn": "Bang Nam Priao",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240310",
    "subDistrictNameTh": "โพรงอากาศ",
    "subDistrictNameEn": "Phrong Akat",
    "postcode": "24150",
    "districtCode": "2403",
    "districtNameTh": "บางน้ำเปรี้ยว",
    "districtNameEn": "Bang Nam Priao",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240401",
    "subDistrictNameTh": "บางปะกง",
    "subDistrictNameEn": "Bang Pakong",
    "postcode": "24130",
    "districtCode": "2404",
    "districtNameTh": "บางปะกง",
    "districtNameEn": "Bang Pakong",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240402",
    "subDistrictNameTh": "ท่าสะอ้าน",
    "subDistrictNameEn": "Tha Sa-an",
    "postcode": "24130",
    "districtCode": "2404",
    "districtNameTh": "บางปะกง",
    "districtNameEn": "Bang Pakong",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240403",
    "subDistrictNameTh": "บางวัว",
    "subDistrictNameEn": "Bang Wua",
    "postcode": "24180",
    "districtCode": "2404",
    "districtNameTh": "บางปะกง",
    "districtNameEn": "Bang Pakong",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240404",
    "subDistrictNameTh": "บางสมัคร",
    "subDistrictNameEn": "Bang Samak",
    "postcode": "24180",
    "districtCode": "2404",
    "districtNameTh": "บางปะกง",
    "districtNameEn": "Bang Pakong",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240405",
    "subDistrictNameTh": "บางผึ้ง",
    "subDistrictNameEn": "Bang Phueng",
    "postcode": "24130",
    "districtCode": "2404",
    "districtNameTh": "บางปะกง",
    "districtNameEn": "Bang Pakong",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240406",
    "subDistrictNameTh": "บางเกลือ",
    "subDistrictNameEn": "Bang Kluea",
    "postcode": "24180",
    "districtCode": "2404",
    "districtNameTh": "บางปะกง",
    "districtNameEn": "Bang Pakong",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240407",
    "subDistrictNameTh": "สองคลอง",
    "subDistrictNameEn": "Song Khlong",
    "postcode": "24130",
    "districtCode": "2404",
    "districtNameTh": "บางปะกง",
    "districtNameEn": "Bang Pakong",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240408",
    "subDistrictNameTh": "หนองจอก",
    "subDistrictNameEn": "Nong Chok",
    "postcode": "24130",
    "districtCode": "2404",
    "districtNameTh": "บางปะกง",
    "districtNameEn": "Bang Pakong",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240409",
    "subDistrictNameTh": "พิมพา",
    "subDistrictNameEn": "Phimpha",
    "postcode": "24180",
    "districtCode": "2404",
    "districtNameTh": "บางปะกง",
    "districtNameEn": "Bang Pakong",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240410",
    "subDistrictNameTh": "ท่าข้าม",
    "subDistrictNameEn": "Tha Kham",
    "postcode": "24130",
    "districtCode": "2404",
    "districtNameTh": "บางปะกง",
    "districtNameEn": "Bang Pakong",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240411",
    "subDistrictNameTh": "หอมศีล",
    "subDistrictNameEn": "Hom Sin",
    "postcode": "24180",
    "districtCode": "2404",
    "districtNameTh": "บางปะกง",
    "districtNameEn": "Bang Pakong",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240412",
    "subDistrictNameTh": "เขาดิน",
    "subDistrictNameEn": "Khao Din",
    "postcode": "24130",
    "districtCode": "2404",
    "districtNameTh": "บางปะกง",
    "districtNameEn": "Bang Pakong",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240501",
    "subDistrictNameTh": "บ้านโพธิ์",
    "subDistrictNameEn": "Ban Pho",
    "postcode": "24140",
    "districtCode": "2405",
    "districtNameTh": "บ้านโพธิ์",
    "districtNameEn": "Ban Pho",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240502",
    "subDistrictNameTh": "เกาะไร่",
    "subDistrictNameEn": "Ko Rai",
    "postcode": "24140",
    "districtCode": "2405",
    "districtNameTh": "บ้านโพธิ์",
    "districtNameEn": "Ban Pho",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240503",
    "subDistrictNameTh": "คลองขุด",
    "subDistrictNameEn": "Khlong Khut",
    "postcode": "24140",
    "districtCode": "2405",
    "districtNameTh": "บ้านโพธิ์",
    "districtNameEn": "Ban Pho",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240504",
    "subDistrictNameTh": "คลองบ้านโพธิ์",
    "subDistrictNameEn": "Khlong Ban Pho",
    "postcode": "24140",
    "districtCode": "2405",
    "districtNameTh": "บ้านโพธิ์",
    "districtNameEn": "Ban Pho",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240505",
    "subDistrictNameTh": "คลองประเวศ",
    "subDistrictNameEn": "Khlong Prawet",
    "postcode": "24140",
    "districtCode": "2405",
    "districtNameTh": "บ้านโพธิ์",
    "districtNameEn": "Ban Pho",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240506",
    "subDistrictNameTh": "ดอนทราย",
    "subDistrictNameEn": "Don Sai",
    "postcode": "24140",
    "districtCode": "2405",
    "districtNameTh": "บ้านโพธิ์",
    "districtNameEn": "Ban Pho",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240507",
    "subDistrictNameTh": "เทพราช",
    "subDistrictNameEn": "Theppharat",
    "postcode": "24140",
    "districtCode": "2405",
    "districtNameTh": "บ้านโพธิ์",
    "districtNameEn": "Ban Pho",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240508",
    "subDistrictNameTh": "ท่าพลับ",
    "subDistrictNameEn": "Tha Phlap",
    "postcode": "24140",
    "districtCode": "2405",
    "districtNameTh": "บ้านโพธิ์",
    "districtNameEn": "Ban Pho",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240509",
    "subDistrictNameTh": "หนองตีนนก",
    "subDistrictNameEn": "Nong Tin Nok",
    "postcode": "24140",
    "districtCode": "2405",
    "districtNameTh": "บ้านโพธิ์",
    "districtNameEn": "Ban Pho",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240510",
    "subDistrictNameTh": "หนองบัว",
    "subDistrictNameEn": "Nong Bua",
    "postcode": "24140",
    "districtCode": "2405",
    "districtNameTh": "บ้านโพธิ์",
    "districtNameEn": "Ban Pho",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240511",
    "subDistrictNameTh": "บางซ่อน",
    "subDistrictNameEn": "Bang Son",
    "postcode": "24140",
    "districtCode": "2405",
    "districtNameTh": "บ้านโพธิ์",
    "districtNameEn": "Ban Pho",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240512",
    "subDistrictNameTh": "บางกรูด",
    "subDistrictNameEn": "Bang Krut",
    "postcode": "24140",
    "districtCode": "2405",
    "districtNameTh": "บ้านโพธิ์",
    "districtNameEn": "Ban Pho",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240513",
    "subDistrictNameTh": "แหลมประดู่",
    "subDistrictNameEn": "Laem Pradu",
    "postcode": "24140",
    "districtCode": "2405",
    "districtNameTh": "บ้านโพธิ์",
    "districtNameEn": "Ban Pho",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240514",
    "subDistrictNameTh": "ลาดขวาง",
    "subDistrictNameEn": "Lat Khwang",
    "postcode": "24140",
    "districtCode": "2405",
    "districtNameTh": "บ้านโพธิ์",
    "districtNameEn": "Ban Pho",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240515",
    "subDistrictNameTh": "สนามจันทร์",
    "subDistrictNameEn": "Sanam Chan",
    "postcode": "24140",
    "districtCode": "2405",
    "districtNameTh": "บ้านโพธิ์",
    "districtNameEn": "Ban Pho",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240516",
    "subDistrictNameTh": "แสนภูดาษ",
    "subDistrictNameEn": "Saen Phu Dat",
    "postcode": "24140",
    "districtCode": "2405",
    "districtNameTh": "บ้านโพธิ์",
    "districtNameEn": "Ban Pho",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240517",
    "subDistrictNameTh": "สิบเอ็ดศอก",
    "subDistrictNameEn": "Sip Et Sok",
    "postcode": "24140",
    "districtCode": "2405",
    "districtNameTh": "บ้านโพธิ์",
    "districtNameEn": "Ban Pho",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240601",
    "subDistrictNameTh": "เกาะขนุน",
    "subDistrictNameEn": "Ko Khanun",
    "postcode": "24120",
    "districtCode": "2406",
    "districtNameTh": "พนมสารคาม",
    "districtNameEn": "Phanom Sarakham",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240602",
    "subDistrictNameTh": "บ้านซ่อง",
    "subDistrictNameEn": "Ban Song",
    "postcode": "24120",
    "districtCode": "2406",
    "districtNameTh": "พนมสารคาม",
    "districtNameEn": "Phanom Sarakham",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240603",
    "subDistrictNameTh": "พนมสารคาม",
    "subDistrictNameEn": "Phanom Sarakham",
    "postcode": "24120",
    "districtCode": "2406",
    "districtNameTh": "พนมสารคาม",
    "districtNameEn": "Phanom Sarakham",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240604",
    "subDistrictNameTh": "เมืองเก่า",
    "subDistrictNameEn": "Mueang Kao",
    "postcode": "24120",
    "districtCode": "2406",
    "districtNameTh": "พนมสารคาม",
    "districtNameEn": "Phanom Sarakham",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240605",
    "subDistrictNameTh": "หนองยาว",
    "subDistrictNameEn": "Nong Yao",
    "postcode": "24120",
    "districtCode": "2406",
    "districtNameTh": "พนมสารคาม",
    "districtNameEn": "Phanom Sarakham",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240606",
    "subDistrictNameTh": "ท่าถ่าน",
    "subDistrictNameEn": "Tha Than",
    "postcode": "24120",
    "districtCode": "2406",
    "districtNameTh": "พนมสารคาม",
    "districtNameEn": "Phanom Sarakham",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240607",
    "subDistrictNameTh": "หนองแหน",
    "subDistrictNameEn": "Nong Nae",
    "postcode": "24120",
    "districtCode": "2406",
    "districtNameTh": "พนมสารคาม",
    "districtNameEn": "Phanom Sarakham",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240608",
    "subDistrictNameTh": "เขาหินซ้อน",
    "subDistrictNameEn": "Khao Hin Son",
    "postcode": "24120",
    "districtCode": "2406",
    "districtNameTh": "พนมสารคาม",
    "districtNameEn": "Phanom Sarakham",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240701",
    "subDistrictNameTh": "บางคา",
    "subDistrictNameEn": "Bang Kha",
    "postcode": "24120",
    "districtCode": "2407",
    "districtNameTh": "ราชสาส์น",
    "districtNameEn": "Ratchasan",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240702",
    "subDistrictNameTh": "เมืองใหม่",
    "subDistrictNameEn": "Mueang Mai",
    "postcode": "24120",
    "districtCode": "2407",
    "districtNameTh": "ราชสาส์น",
    "districtNameEn": "Ratchasan",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240703",
    "subDistrictNameTh": "ดงน้อย",
    "subDistrictNameEn": "Dong Noi",
    "postcode": "24120",
    "districtCode": "2407",
    "districtNameTh": "ราชสาส์น",
    "districtNameEn": "Ratchasan",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240801",
    "subDistrictNameTh": "คู้ยายหมี",
    "subDistrictNameEn": "Ku Yai Mi",
    "postcode": "24160",
    "districtCode": "2408",
    "districtNameTh": "สนามชัยเขต",
    "districtNameEn": "Sanam Chai Khet",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240802",
    "subDistrictNameTh": "ท่ากระดาน",
    "subDistrictNameEn": "Tha Kradan",
    "postcode": "24160",
    "districtCode": "2408",
    "districtNameTh": "สนามชัยเขต",
    "districtNameEn": "Sanam Chai Khet",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240803",
    "subDistrictNameTh": "ทุ่งพระยา",
    "subDistrictNameEn": "Thung Phraya",
    "postcode": "24160",
    "districtCode": "2408",
    "districtNameTh": "สนามชัยเขต",
    "districtNameEn": "Sanam Chai Khet",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240805",
    "subDistrictNameTh": "ลาดกระทิง",
    "subDistrictNameEn": "Lat Krathing",
    "postcode": "24160",
    "districtCode": "2408",
    "districtNameTh": "สนามชัยเขต",
    "districtNameEn": "Sanam Chai Khet",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240901",
    "subDistrictNameTh": "แปลงยาว",
    "subDistrictNameEn": "Plaeng Yao",
    "postcode": "24190",
    "districtCode": "2409",
    "districtNameTh": "แปลงยาว",
    "districtNameEn": "Plaeng Yao",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240902",
    "subDistrictNameTh": "วังเย็น",
    "subDistrictNameEn": "Wang Yen",
    "postcode": "24190",
    "districtCode": "2409",
    "districtNameTh": "แปลงยาว",
    "districtNameEn": "Plaeng Yao",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240903",
    "subDistrictNameTh": "หัวสำโรง",
    "subDistrictNameEn": "Hua Samrong",
    "postcode": "24190",
    "districtCode": "2409",
    "districtNameTh": "แปลงยาว",
    "districtNameEn": "Plaeng Yao",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "240904",
    "subDistrictNameTh": "หนองไม้แก่น",
    "subDistrictNameEn": "Nong Mai Kaen",
    "postcode": "24190",
    "districtCode": "2409",
    "districtNameTh": "แปลงยาว",
    "districtNameEn": "Plaeng Yao",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "241001",
    "subDistrictNameTh": "ท่าตะเกียบ",
    "subDistrictNameEn": "Tha Takiap",
    "postcode": "24160",
    "districtCode": "2410",
    "districtNameTh": "ท่าตะเกียบ",
    "districtNameEn": "Tha Takiap",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "241002",
    "subDistrictNameTh": "คลองตะเกรา",
    "subDistrictNameEn": "Khlong Takrao",
    "postcode": "24160",
    "districtCode": "2410",
    "districtNameTh": "ท่าตะเกียบ",
    "districtNameEn": "Tha Takiap",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "241101",
    "subDistrictNameTh": "ก้อนแก้ว",
    "subDistrictNameEn": "Kon Kaeo",
    "postcode": "24000",
    "districtCode": "2411",
    "districtNameTh": "คลองเขื่อน",
    "districtNameEn": "Khlong Khuean",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "241102",
    "subDistrictNameTh": "คลองเขื่อน",
    "subDistrictNameEn": "Khlong Khuean",
    "postcode": "24000",
    "districtCode": "2411",
    "districtNameTh": "คลองเขื่อน",
    "districtNameEn": "Khlong Khuean",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "241103",
    "subDistrictNameTh": "บางเล่า",
    "subDistrictNameEn": "Bang Lao",
    "postcode": "24000",
    "districtCode": "2411",
    "districtNameTh": "คลองเขื่อน",
    "districtNameEn": "Khlong Khuean",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "241104",
    "subDistrictNameTh": "บางโรง",
    "subDistrictNameEn": "Bang Rong",
    "postcode": "24000",
    "districtCode": "2411",
    "districtNameTh": "คลองเขื่อน",
    "districtNameEn": "Khlong Khuean",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "241105",
    "subDistrictNameTh": "บางตลาด",
    "subDistrictNameEn": "Bang Talat",
    "postcode": "24110",
    "districtCode": "2411",
    "districtNameTh": "คลองเขื่อน",
    "districtNameEn": "Khlong Khuean",
    "provinceCode": "24",
    "provinceNameTh": "ฉะเชิงเทรา",
    "provinceNameEn": "Chachoengsao"
},
{
    "subDistrictCode": "250101",
    "subDistrictNameTh": "หน้าเมือง",
    "subDistrictNameEn": "Na Mueang",
    "postcode": "25000",
    "districtCode": "2501",
    "districtNameTh": "เมืองปราจีนบุรี",
    "districtNameEn": "Mueang Prachin Buri",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250102",
    "subDistrictNameTh": "รอบเมือง",
    "subDistrictNameEn": "Rop Mueang",
    "postcode": "25000",
    "districtCode": "2501",
    "districtNameTh": "เมืองปราจีนบุรี",
    "districtNameEn": "Mueang Prachin Buri",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250103",
    "subDistrictNameTh": "วัดโบสถ์",
    "subDistrictNameEn": "Wat Bot",
    "postcode": "25000",
    "districtCode": "2501",
    "districtNameTh": "เมืองปราจีนบุรี",
    "districtNameEn": "Mueang Prachin Buri",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250104",
    "subDistrictNameTh": "บางเดชะ",
    "subDistrictNameEn": "Bang Decha",
    "postcode": "25000",
    "districtCode": "2501",
    "districtNameTh": "เมืองปราจีนบุรี",
    "districtNameEn": "Mueang Prachin Buri",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250105",
    "subDistrictNameTh": "ท่างาม",
    "subDistrictNameEn": "Tha Ngam",
    "postcode": "25000",
    "districtCode": "2501",
    "districtNameTh": "เมืองปราจีนบุรี",
    "districtNameEn": "Mueang Prachin Buri",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250106",
    "subDistrictNameTh": "บางบริบูรณ์",
    "subDistrictNameEn": "Bang Boribun",
    "postcode": "25000",
    "districtCode": "2501",
    "districtNameTh": "เมืองปราจีนบุรี",
    "districtNameEn": "Mueang Prachin Buri",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250107",
    "subDistrictNameTh": "ดงพระราม",
    "subDistrictNameEn": "Dong Phra Ram",
    "postcode": "25000",
    "districtCode": "2501",
    "districtNameTh": "เมืองปราจีนบุรี",
    "districtNameEn": "Mueang Prachin Buri",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250108",
    "subDistrictNameTh": "บ้านพระ",
    "subDistrictNameEn": "Ban Phra",
    "postcode": "25230",
    "districtCode": "2501",
    "districtNameTh": "เมืองปราจีนบุรี",
    "districtNameEn": "Mueang Prachin Buri",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250109",
    "subDistrictNameTh": "โคกไม้ลาย",
    "subDistrictNameEn": "Khok Mai Lai",
    "postcode": "25230",
    "districtCode": "2501",
    "districtNameTh": "เมืองปราจีนบุรี",
    "districtNameEn": "Mueang Prachin Buri",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250110",
    "subDistrictNameTh": "ไม้เค็ด",
    "subDistrictNameEn": "Mai Khet",
    "postcode": "25230",
    "districtCode": "2501",
    "districtNameTh": "เมืองปราจีนบุรี",
    "districtNameEn": "Mueang Prachin Buri",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250111",
    "subDistrictNameTh": "ดงขี้เหล็ก",
    "subDistrictNameEn": "Dong Khilek",
    "postcode": "25000",
    "districtCode": "2501",
    "districtNameTh": "เมืองปราจีนบุรี",
    "districtNameEn": "Mueang Prachin Buri",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250112",
    "subDistrictNameTh": "เนินหอม",
    "subDistrictNameEn": "Noen Hom",
    "postcode": "25230",
    "districtCode": "2501",
    "districtNameTh": "เมืองปราจีนบุรี",
    "districtNameEn": "Mueang Prachin Buri",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250113",
    "subDistrictNameTh": "โนนห้อม",
    "subDistrictNameEn": "Non Hom",
    "postcode": "25000",
    "districtCode": "2501",
    "districtNameTh": "เมืองปราจีนบุรี",
    "districtNameEn": "Mueang Prachin Buri",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250201",
    "subDistrictNameTh": "กบินทร์",
    "subDistrictNameEn": "Kabin",
    "postcode": "25110",
    "districtCode": "2502",
    "districtNameTh": "กบินทร์บุรี",
    "districtNameEn": "Kabin Buri",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250202",
    "subDistrictNameTh": "เมืองเก่า",
    "subDistrictNameEn": "Mueang Kao",
    "postcode": "25240",
    "districtCode": "2502",
    "districtNameTh": "กบินทร์บุรี",
    "districtNameEn": "Kabin Buri",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250203",
    "subDistrictNameTh": "วังดาล",
    "subDistrictNameEn": "Wang Dan",
    "postcode": "25110",
    "districtCode": "2502",
    "districtNameTh": "กบินทร์บุรี",
    "districtNameEn": "Kabin Buri",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250204",
    "subDistrictNameTh": "นนทรี",
    "subDistrictNameEn": "Nonsi",
    "postcode": "25110",
    "districtCode": "2502",
    "districtNameTh": "กบินทร์บุรี",
    "districtNameEn": "Kabin Buri",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250205",
    "subDistrictNameTh": "ย่านรี",
    "subDistrictNameEn": "Yan Ri",
    "postcode": "25110",
    "districtCode": "2502",
    "districtNameTh": "กบินทร์บุรี",
    "districtNameEn": "Kabin Buri",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250206",
    "subDistrictNameTh": "วังตะเคียน",
    "subDistrictNameEn": "Wang Takhian",
    "postcode": "25110",
    "districtCode": "2502",
    "districtNameTh": "กบินทร์บุรี",
    "districtNameEn": "Kabin Buri",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250207",
    "subDistrictNameTh": "หาดนางแก้ว",
    "subDistrictNameEn": "Hat Nang Kaeo",
    "postcode": "25110",
    "districtCode": "2502",
    "districtNameTh": "กบินทร์บุรี",
    "districtNameEn": "Kabin Buri",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250208",
    "subDistrictNameTh": "ลาดตะเคียน",
    "subDistrictNameEn": "Lat Takhian",
    "postcode": "25110",
    "districtCode": "2502",
    "districtNameTh": "กบินทร์บุรี",
    "districtNameEn": "Kabin Buri",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250209",
    "subDistrictNameTh": "บ้านนา",
    "subDistrictNameEn": "Ban Na",
    "postcode": "25110",
    "districtCode": "2502",
    "districtNameTh": "กบินทร์บุรี",
    "districtNameEn": "Kabin Buri",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250210",
    "subDistrictNameTh": "บ่อทอง",
    "subDistrictNameEn": "Bo Thong",
    "postcode": "25110",
    "districtCode": "2502",
    "districtNameTh": "กบินทร์บุรี",
    "districtNameEn": "Kabin Buri",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250211",
    "subDistrictNameTh": "หนองกี่",
    "subDistrictNameEn": "Nong Ki",
    "postcode": "25110",
    "districtCode": "2502",
    "districtNameTh": "กบินทร์บุรี",
    "districtNameEn": "Kabin Buri",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250212",
    "subDistrictNameTh": "นาแขม",
    "subDistrictNameEn": "Na Khaem",
    "postcode": "25110",
    "districtCode": "2502",
    "districtNameTh": "กบินทร์บุรี",
    "districtNameEn": "Kabin Buri",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250213",
    "subDistrictNameTh": "เขาไม้แก้ว",
    "subDistrictNameEn": "Khao Mai Kaeo",
    "postcode": "25110",
    "districtCode": "2502",
    "districtNameTh": "กบินทร์บุรี",
    "districtNameEn": "Kabin Buri",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250214",
    "subDistrictNameTh": "วังท่าช้าง",
    "subDistrictNameEn": "Wang Tha Chang",
    "postcode": "25110",
    "districtCode": "2502",
    "districtNameTh": "กบินทร์บุรี",
    "districtNameEn": "Kabin Buri",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250301",
    "subDistrictNameTh": "นาดี",
    "subDistrictNameEn": "Na Di",
    "postcode": "25220",
    "districtCode": "2503",
    "districtNameTh": "นาดี",
    "districtNameEn": "Na Di",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250302",
    "subDistrictNameTh": "สำพันตา",
    "subDistrictNameEn": "Sam Phan Ta",
    "postcode": "25220",
    "districtCode": "2503",
    "districtNameTh": "นาดี",
    "districtNameEn": "Na Di",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250303",
    "subDistrictNameTh": "สะพานหิน",
    "subDistrictNameEn": "Saphan Hin",
    "postcode": "25220",
    "districtCode": "2503",
    "districtNameTh": "นาดี",
    "districtNameEn": "Na Di",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250304",
    "subDistrictNameTh": "ทุ่งโพธิ์",
    "subDistrictNameEn": "Thung Pho",
    "postcode": "25220",
    "districtCode": "2503",
    "districtNameTh": "นาดี",
    "districtNameEn": "Na Di",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250305",
    "subDistrictNameTh": "แก่งดินสอ",
    "subDistrictNameEn": "Kaeng Din So",
    "postcode": "25220",
    "districtCode": "2503",
    "districtNameTh": "นาดี",
    "districtNameEn": "Na Di",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250306",
    "subDistrictNameTh": "บุพราหมณ์",
    "subDistrictNameEn": "Bu Phram",
    "postcode": "25220",
    "districtCode": "2503",
    "districtNameTh": "นาดี",
    "districtNameEn": "Na Di",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250601",
    "subDistrictNameTh": "บ้านสร้าง",
    "subDistrictNameEn": "Ban Sang",
    "postcode": "25150",
    "districtCode": "2506",
    "districtNameTh": "บ้านสร้าง",
    "districtNameEn": "Ban Sang",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250602",
    "subDistrictNameTh": "บางกระเบา",
    "subDistrictNameEn": "Bang Krabao",
    "postcode": "25150",
    "districtCode": "2506",
    "districtNameTh": "บ้านสร้าง",
    "districtNameEn": "Ban Sang",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250603",
    "subDistrictNameTh": "บางเตย",
    "subDistrictNameEn": "Bang Toei",
    "postcode": "25150",
    "districtCode": "2506",
    "districtNameTh": "บ้านสร้าง",
    "districtNameEn": "Ban Sang",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250604",
    "subDistrictNameTh": "บางยาง",
    "subDistrictNameEn": "Bang Yang",
    "postcode": "25150",
    "districtCode": "2506",
    "districtNameTh": "บ้านสร้าง",
    "districtNameEn": "Ban Sang",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250605",
    "subDistrictNameTh": "บางแตน",
    "subDistrictNameEn": "Bang Taen",
    "postcode": "25150",
    "districtCode": "2506",
    "districtNameTh": "บ้านสร้าง",
    "districtNameEn": "Ban Sang",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250606",
    "subDistrictNameTh": "บางพลวง",
    "subDistrictNameEn": "Bang Phluang",
    "postcode": "25150",
    "districtCode": "2506",
    "districtNameTh": "บ้านสร้าง",
    "districtNameEn": "Ban Sang",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250607",
    "subDistrictNameTh": "บางปลาร้า",
    "subDistrictNameEn": "Bang Pla Ra",
    "postcode": "25150",
    "districtCode": "2506",
    "districtNameTh": "บ้านสร้าง",
    "districtNameEn": "Ban Sang",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250608",
    "subDistrictNameTh": "บางขาม",
    "subDistrictNameEn": "Bang Kham",
    "postcode": "25150",
    "districtCode": "2506",
    "districtNameTh": "บ้านสร้าง",
    "districtNameEn": "Ban Sang",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250609",
    "subDistrictNameTh": "กระทุ่มแพ้ว",
    "subDistrictNameEn": "Krathum Phaeo",
    "postcode": "25150",
    "districtCode": "2506",
    "districtNameTh": "บ้านสร้าง",
    "districtNameEn": "Ban Sang",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250701",
    "subDistrictNameTh": "ประจันตคาม",
    "subDistrictNameEn": "Prachantakham",
    "postcode": "25130",
    "districtCode": "2507",
    "districtNameTh": "ประจันตคาม",
    "districtNameEn": "Prachantakham",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250702",
    "subDistrictNameTh": "เกาะลอย",
    "subDistrictNameEn": "Ko Loi",
    "postcode": "25130",
    "districtCode": "2507",
    "districtNameTh": "ประจันตคาม",
    "districtNameEn": "Prachantakham",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250703",
    "subDistrictNameTh": "บ้านหอย",
    "subDistrictNameEn": "Ban Hoi",
    "postcode": "25130",
    "districtCode": "2507",
    "districtNameTh": "ประจันตคาม",
    "districtNameEn": "Prachantakham",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250704",
    "subDistrictNameTh": "หนองแสง",
    "subDistrictNameEn": "Nong Saeng",
    "postcode": "25130",
    "districtCode": "2507",
    "districtNameTh": "ประจันตคาม",
    "districtNameEn": "Prachantakham",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250705",
    "subDistrictNameTh": "ดงบัง",
    "subDistrictNameEn": "Dong Bang",
    "postcode": "25130",
    "districtCode": "2507",
    "districtNameTh": "ประจันตคาม",
    "districtNameEn": "Prachantakham",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250706",
    "subDistrictNameTh": "คำโตนด",
    "subDistrictNameEn": "Kham Tanot",
    "postcode": "25130",
    "districtCode": "2507",
    "districtNameTh": "ประจันตคาม",
    "districtNameEn": "Prachantakham",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250707",
    "subDistrictNameTh": "บุฝ้าย",
    "subDistrictNameEn": "Bu Fai",
    "postcode": "25130",
    "districtCode": "2507",
    "districtNameTh": "ประจันตคาม",
    "districtNameEn": "Prachantakham",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250708",
    "subDistrictNameTh": "หนองแก้ว",
    "subDistrictNameEn": "Nong Kaeo",
    "postcode": "25130",
    "districtCode": "2507",
    "districtNameTh": "ประจันตคาม",
    "districtNameEn": "Prachantakham",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250709",
    "subDistrictNameTh": "โพธิ์งาม",
    "subDistrictNameEn": "Pho Ngam",
    "postcode": "25130",
    "districtCode": "2507",
    "districtNameTh": "ประจันตคาม",
    "districtNameEn": "Prachantakham",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250801",
    "subDistrictNameTh": "ศรีมหาโพธิ",
    "subDistrictNameEn": "Si Maha Phot",
    "postcode": "25140",
    "districtCode": "2508",
    "districtNameTh": "ศรีมหาโพธิ",
    "districtNameEn": "Si Maha Phot",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250802",
    "subDistrictNameTh": "สัมพันธ์",
    "subDistrictNameEn": "Samphan",
    "postcode": "25140",
    "districtCode": "2508",
    "districtNameTh": "ศรีมหาโพธิ",
    "districtNameEn": "Si Maha Phot",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250803",
    "subDistrictNameTh": "บ้านทาม",
    "subDistrictNameEn": "Ban Tham",
    "postcode": "25140",
    "districtCode": "2508",
    "districtNameTh": "ศรีมหาโพธิ",
    "districtNameEn": "Si Maha Phot",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250804",
    "subDistrictNameTh": "ท่าตูม",
    "subDistrictNameEn": "Tha Tum",
    "postcode": "25140",
    "districtCode": "2508",
    "districtNameTh": "ศรีมหาโพธิ",
    "districtNameEn": "Si Maha Phot",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250805",
    "subDistrictNameTh": "บางกุ้ง",
    "subDistrictNameEn": "Bang Kung",
    "postcode": "25140",
    "districtCode": "2508",
    "districtNameTh": "ศรีมหาโพธิ",
    "districtNameEn": "Si Maha Phot",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250806",
    "subDistrictNameTh": "ดงกระทงยาม",
    "subDistrictNameEn": "Dong Krathong Yam",
    "postcode": "25140",
    "districtCode": "2508",
    "districtNameTh": "ศรีมหาโพธิ",
    "districtNameEn": "Si Maha Phot",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250807",
    "subDistrictNameTh": "หนองโพรง",
    "subDistrictNameEn": "Nong Phrong",
    "postcode": "25140",
    "districtCode": "2508",
    "districtNameTh": "ศรีมหาโพธิ",
    "districtNameEn": "Si Maha Phot",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250808",
    "subDistrictNameTh": "หัวหว้า",
    "subDistrictNameEn": "Hua Wa",
    "postcode": "25140",
    "districtCode": "2508",
    "districtNameTh": "ศรีมหาโพธิ",
    "districtNameEn": "Si Maha Phot",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250809",
    "subDistrictNameTh": "หาดยาง",
    "subDistrictNameEn": "Hat Yang",
    "postcode": "25140",
    "districtCode": "2508",
    "districtNameTh": "ศรีมหาโพธิ",
    "districtNameEn": "Si Maha Phot",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250810",
    "subDistrictNameTh": "กรอกสมบูรณ์",
    "subDistrictNameEn": "Krok Sombun",
    "postcode": "25140",
    "districtCode": "2508",
    "districtNameTh": "ศรีมหาโพธิ",
    "districtNameEn": "Si Maha Phot",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250901",
    "subDistrictNameTh": "โคกปีบ",
    "subDistrictNameEn": "Khok Pip",
    "postcode": "25190",
    "districtCode": "2509",
    "districtNameTh": "ศรีมโหสถ",
    "districtNameEn": "Si Mahosot",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250902",
    "subDistrictNameTh": "โคกไทย",
    "subDistrictNameEn": "Khok Thai",
    "postcode": "25190",
    "districtCode": "2509",
    "districtNameTh": "ศรีมโหสถ",
    "districtNameEn": "Si Mahosot",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250903",
    "subDistrictNameTh": "คู้ลำพัน",
    "subDistrictNameEn": "Khu Lam Phan",
    "postcode": "25190",
    "districtCode": "2509",
    "districtNameTh": "ศรีมโหสถ",
    "districtNameEn": "Si Mahosot",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "250904",
    "subDistrictNameTh": "ไผ่ชะเลือด",
    "subDistrictNameEn": "Phai Cha Lueat",
    "postcode": "25190",
    "districtCode": "2509",
    "districtNameTh": "ศรีมโหสถ",
    "districtNameEn": "Si Mahosot",
    "provinceCode": "25",
    "provinceNameTh": "ปราจีนบุรี",
    "provinceNameEn": "Prachin Buri"
},
{
    "subDistrictCode": "260101",
    "subDistrictNameTh": "นครนายก",
    "subDistrictNameEn": "Nakhon Nayok",
    "postcode": "26000",
    "districtCode": "2601",
    "districtNameTh": "เมืองนครนายก",
    "districtNameEn": "Mueang Nakhon Nayok",
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "subDistrictCode": "260102",
    "subDistrictNameTh": "ท่าช้าง",
    "subDistrictNameEn": "Tha Chang",
    "postcode": "26000",
    "districtCode": "2601",
    "districtNameTh": "เมืองนครนายก",
    "districtNameEn": "Mueang Nakhon Nayok",
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "subDistrictCode": "260103",
    "subDistrictNameTh": "บ้านใหญ่",
    "subDistrictNameEn": "Ban Yai",
    "postcode": "26000",
    "districtCode": "2601",
    "districtNameTh": "เมืองนครนายก",
    "districtNameEn": "Mueang Nakhon Nayok",
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "subDistrictCode": "260104",
    "subDistrictNameTh": "วังกระโจม",
    "subDistrictNameEn": "Wang Krachom",
    "postcode": "26000",
    "districtCode": "2601",
    "districtNameTh": "เมืองนครนายก",
    "districtNameEn": "Mueang Nakhon Nayok",
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "subDistrictCode": "260105",
    "subDistrictNameTh": "ท่าทราย",
    "subDistrictNameEn": "Tha Sai",
    "postcode": "26000",
    "districtCode": "2601",
    "districtNameTh": "เมืองนครนายก",
    "districtNameEn": "Mueang Nakhon Nayok",
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "subDistrictCode": "260106",
    "subDistrictNameTh": "ดอนยอ",
    "subDistrictNameEn": "Don Yo",
    "postcode": "26000",
    "districtCode": "2601",
    "districtNameTh": "เมืองนครนายก",
    "districtNameEn": "Mueang Nakhon Nayok",
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "subDistrictCode": "260107",
    "subDistrictNameTh": "ศรีจุฬา",
    "subDistrictNameEn": "Si Chula",
    "postcode": "26000",
    "districtCode": "2601",
    "districtNameTh": "เมืองนครนายก",
    "districtNameEn": "Mueang Nakhon Nayok",
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "subDistrictCode": "260108",
    "subDistrictNameTh": "ดงละคร",
    "subDistrictNameEn": "Dong Lakhon",
    "postcode": "26000",
    "districtCode": "2601",
    "districtNameTh": "เมืองนครนายก",
    "districtNameEn": "Mueang Nakhon Nayok",
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "subDistrictCode": "260109",
    "subDistrictNameTh": "ศรีนาวา",
    "subDistrictNameEn": "Si Nawa",
    "postcode": "26000",
    "districtCode": "2601",
    "districtNameTh": "เมืองนครนายก",
    "districtNameEn": "Mueang Nakhon Nayok",
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "subDistrictCode": "260110",
    "subDistrictNameTh": "สาริกา",
    "subDistrictNameEn": "Sarika",
    "postcode": "26000",
    "districtCode": "2601",
    "districtNameTh": "เมืองนครนายก",
    "districtNameEn": "Mueang Nakhon Nayok",
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "subDistrictCode": "260111",
    "subDistrictNameTh": "หินตั้ง",
    "subDistrictNameEn": "Hin Tang",
    "postcode": "26000",
    "districtCode": "2601",
    "districtNameTh": "เมืองนครนายก",
    "districtNameEn": "Mueang Nakhon Nayok",
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "subDistrictCode": "260112",
    "subDistrictNameTh": "เขาพระ",
    "subDistrictNameEn": "Khao Phra",
    "postcode": "26000",
    "districtCode": "2601",
    "districtNameTh": "เมืองนครนายก",
    "districtNameEn": "Mueang Nakhon Nayok",
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "subDistrictCode": "260113",
    "subDistrictNameTh": "พรหมณี",
    "subDistrictNameEn": "Phrommani",
    "postcode": "26000",
    "districtCode": "2601",
    "districtNameTh": "เมืองนครนายก",
    "districtNameEn": "Mueang Nakhon Nayok",
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "subDistrictCode": "260201",
    "subDistrictNameTh": "เกาะหวาย",
    "subDistrictNameEn": "Ko Wai",
    "postcode": "26130",
    "districtCode": "2602",
    "districtNameTh": "ปากพลี",
    "districtNameEn": "Pak Phli",
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "subDistrictCode": "260202",
    "subDistrictNameTh": "เกาะโพธิ์",
    "subDistrictNameEn": "Ko Pho",
    "postcode": "26130",
    "districtCode": "2602",
    "districtNameTh": "ปากพลี",
    "districtNameEn": "Pak Phli",
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "subDistrictCode": "260203",
    "subDistrictNameTh": "ปากพลี",
    "subDistrictNameEn": "Pak Phli",
    "postcode": "26130",
    "districtCode": "2602",
    "districtNameTh": "ปากพลี",
    "districtNameEn": "Pak Phli",
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "subDistrictCode": "260204",
    "subDistrictNameTh": "โคกกรวด",
    "subDistrictNameEn": "Khok Kruat",
    "postcode": "26130",
    "districtCode": "2602",
    "districtNameTh": "ปากพลี",
    "districtNameEn": "Pak Phli",
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "subDistrictCode": "260205",
    "subDistrictNameTh": "ท่าเรือ",
    "subDistrictNameEn": "Tha Ruea",
    "postcode": "26130",
    "districtCode": "2602",
    "districtNameTh": "ปากพลี",
    "districtNameEn": "Pak Phli",
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "subDistrictCode": "260206",
    "subDistrictNameTh": "หนองแสง",
    "subDistrictNameEn": "Nong Saeng",
    "postcode": "26130",
    "districtCode": "2602",
    "districtNameTh": "ปากพลี",
    "districtNameEn": "Pak Phli",
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "subDistrictCode": "260207",
    "subDistrictNameTh": "นาหินลาด",
    "subDistrictNameEn": "Na Hin Lat",
    "postcode": "26130",
    "districtCode": "2602",
    "districtNameTh": "ปากพลี",
    "districtNameEn": "Pak Phli",
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "subDistrictCode": "260301",
    "subDistrictNameTh": "บ้านนา",
    "subDistrictNameEn": "Ban Na",
    "postcode": "26110",
    "districtCode": "2603",
    "districtNameTh": "บ้านนา",
    "districtNameEn": "Ban Na",
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "subDistrictCode": "260302",
    "subDistrictNameTh": "บ้านพร้าว",
    "subDistrictNameEn": "Ban Phrao",
    "postcode": "26110",
    "districtCode": "2603",
    "districtNameTh": "บ้านนา",
    "districtNameEn": "Ban Na",
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "subDistrictCode": "260303",
    "subDistrictNameTh": "บ้านพริก",
    "subDistrictNameEn": "Ban Phrik",
    "postcode": "26110",
    "districtCode": "2603",
    "districtNameTh": "บ้านนา",
    "districtNameEn": "Ban Na",
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "subDistrictCode": "260304",
    "subDistrictNameTh": "อาษา",
    "subDistrictNameEn": "Asa",
    "postcode": "26110",
    "districtCode": "2603",
    "districtNameTh": "บ้านนา",
    "districtNameEn": "Ban Na",
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "subDistrictCode": "260305",
    "subDistrictNameTh": "ทองหลาง",
    "subDistrictNameEn": "Thonglang",
    "postcode": "26110",
    "districtCode": "2603",
    "districtNameTh": "บ้านนา",
    "districtNameEn": "Ban Na",
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "subDistrictCode": "260306",
    "subDistrictNameTh": "บางอ้อ",
    "subDistrictNameEn": "Bang O",
    "postcode": "26110",
    "districtCode": "2603",
    "districtNameTh": "บ้านนา",
    "districtNameEn": "Ban Na",
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "subDistrictCode": "260307",
    "subDistrictNameTh": "พิกุลออก",
    "subDistrictNameEn": "Phikun Ok",
    "postcode": "26110",
    "districtCode": "2603",
    "districtNameTh": "บ้านนา",
    "districtNameEn": "Ban Na",
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "subDistrictCode": "260308",
    "subDistrictNameTh": "ป่าขะ",
    "subDistrictNameEn": "Pa Kha",
    "postcode": "26110",
    "districtCode": "2603",
    "districtNameTh": "บ้านนา",
    "districtNameEn": "Ban Na",
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "subDistrictCode": "260309",
    "subDistrictNameTh": "เขาเพิ่ม",
    "subDistrictNameEn": "Khao Phoem",
    "postcode": "26110",
    "districtCode": "2603",
    "districtNameTh": "บ้านนา",
    "districtNameEn": "Ban Na",
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "subDistrictCode": "260310",
    "subDistrictNameTh": "ศรีกะอาง",
    "subDistrictNameEn": "Si Ka-ang",
    "postcode": "26110",
    "districtCode": "2603",
    "districtNameTh": "บ้านนา",
    "districtNameEn": "Ban Na",
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "subDistrictCode": "260401",
    "subDistrictNameTh": "พระอาจารย์",
    "subDistrictNameEn": "Phra Achan",
    "postcode": "26120",
    "districtCode": "2604",
    "districtNameTh": "องครักษ์",
    "districtNameEn": "Ongkharak",
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "subDistrictCode": "260402",
    "subDistrictNameTh": "บึงศาล",
    "subDistrictNameEn": "Bueng San",
    "postcode": "26120",
    "districtCode": "2604",
    "districtNameTh": "องครักษ์",
    "districtNameEn": "Ongkharak",
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "subDistrictCode": "260403",
    "subDistrictNameTh": "ศีรษะกระบือ",
    "subDistrictNameEn": "Sisa Krabue",
    "postcode": "26120",
    "districtCode": "2604",
    "districtNameTh": "องครักษ์",
    "districtNameEn": "Ongkharak",
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "subDistrictCode": "260404",
    "subDistrictNameTh": "โพธิ์แทน",
    "subDistrictNameEn": "Pho Thaen",
    "postcode": "26120",
    "districtCode": "2604",
    "districtNameTh": "องครักษ์",
    "districtNameEn": "Ongkharak",
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "subDistrictCode": "260405",
    "subDistrictNameTh": "บางสมบูรณ์",
    "subDistrictNameEn": "Bang Sombun",
    "postcode": "26120",
    "districtCode": "2604",
    "districtNameTh": "องครักษ์",
    "districtNameEn": "Ongkharak",
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "subDistrictCode": "260406",
    "subDistrictNameTh": "ทรายมูล",
    "subDistrictNameEn": "Sai Mun",
    "postcode": "26120",
    "districtCode": "2604",
    "districtNameTh": "องครักษ์",
    "districtNameEn": "Ongkharak",
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "subDistrictCode": "260407",
    "subDistrictNameTh": "บางปลากด",
    "subDistrictNameEn": "Bang Pla Kot",
    "postcode": "26120",
    "districtCode": "2604",
    "districtNameTh": "องครักษ์",
    "districtNameEn": "Ongkharak",
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "subDistrictCode": "260408",
    "subDistrictNameTh": "บางลูกเสือ",
    "subDistrictNameEn": "Bang Luk Suea",
    "postcode": "26120",
    "districtCode": "2604",
    "districtNameTh": "องครักษ์",
    "districtNameEn": "Ongkharak",
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "subDistrictCode": "260409",
    "subDistrictNameTh": "องครักษ์",
    "subDistrictNameEn": "Ongkharak",
    "postcode": "26120",
    "districtCode": "2604",
    "districtNameTh": "องครักษ์",
    "districtNameEn": "Ongkharak",
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "subDistrictCode": "260410",
    "subDistrictNameTh": "ชุมพล",
    "subDistrictNameEn": "Chumphon",
    "postcode": "26120",
    "districtCode": "2604",
    "districtNameTh": "องครักษ์",
    "districtNameEn": "Ongkharak",
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "subDistrictCode": "260411",
    "subDistrictNameTh": "คลองใหญ่",
    "subDistrictNameEn": "Khlong Yai",
    "postcode": "26120",
    "districtCode": "2604",
    "districtNameTh": "องครักษ์",
    "districtNameEn": "Ongkharak",
    "provinceCode": "26",
    "provinceNameTh": "นครนายก",
    "provinceNameEn": "Nakhon Nayok"
},
{
    "subDistrictCode": "270101",
    "subDistrictNameTh": "สระแก้ว",
    "subDistrictNameEn": "Sa Kaeo",
    "postcode": "27000",
    "districtCode": "2701",
    "districtNameTh": "เมืองสระแก้ว",
    "districtNameEn": "Mueang Sa Kaeo",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270102",
    "subDistrictNameTh": "บ้านแก้ง",
    "subDistrictNameEn": "Ban Kaeng",
    "postcode": "27000",
    "districtCode": "2701",
    "districtNameTh": "เมืองสระแก้ว",
    "districtNameEn": "Mueang Sa Kaeo",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270103",
    "subDistrictNameTh": "ศาลาลำดวน",
    "subDistrictNameEn": "Sala Lamduan",
    "postcode": "27000",
    "districtCode": "2701",
    "districtNameTh": "เมืองสระแก้ว",
    "districtNameEn": "Mueang Sa Kaeo",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270104",
    "subDistrictNameTh": "โคกปี่ฆ้อง",
    "subDistrictNameEn": "Khok Pi Khong",
    "postcode": "27000",
    "districtCode": "2701",
    "districtNameTh": "เมืองสระแก้ว",
    "districtNameEn": "Mueang Sa Kaeo",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270105",
    "subDistrictNameTh": "ท่าแยก",
    "subDistrictNameEn": "Tha Yaek",
    "postcode": "27000",
    "districtCode": "2701",
    "districtNameTh": "เมืองสระแก้ว",
    "districtNameEn": "Mueang Sa Kaeo",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270106",
    "subDistrictNameTh": "ท่าเกษม",
    "subDistrictNameEn": "Tha Kasem",
    "postcode": "27000",
    "districtCode": "2701",
    "districtNameTh": "เมืองสระแก้ว",
    "districtNameEn": "Mueang Sa Kaeo",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270108",
    "subDistrictNameTh": "สระขวัญ",
    "subDistrictNameEn": "Sa Khwan",
    "postcode": "27000",
    "districtCode": "2701",
    "districtNameTh": "เมืองสระแก้ว",
    "districtNameEn": "Mueang Sa Kaeo",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270111",
    "subDistrictNameTh": "หนองบอน",
    "subDistrictNameEn": "Nong Bon",
    "postcode": "27000",
    "districtCode": "2701",
    "districtNameTh": "เมืองสระแก้ว",
    "districtNameEn": "Mueang Sa Kaeo",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270201",
    "subDistrictNameTh": "คลองหาด",
    "subDistrictNameEn": "Khlong Hat",
    "postcode": "27260",
    "districtCode": "2702",
    "districtNameTh": "คลองหาด",
    "districtNameEn": "Khlong Hat",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270202",
    "subDistrictNameTh": "ไทยอุดม",
    "subDistrictNameEn": "Thai Udom",
    "postcode": "27260",
    "districtCode": "2702",
    "districtNameTh": "คลองหาด",
    "districtNameEn": "Khlong Hat",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270203",
    "subDistrictNameTh": "ซับมะกรูด",
    "subDistrictNameEn": "Sap Makrut",
    "postcode": "27260",
    "districtCode": "2702",
    "districtNameTh": "คลองหาด",
    "districtNameEn": "Khlong Hat",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270204",
    "subDistrictNameTh": "ไทรเดี่ยว",
    "subDistrictNameEn": "Sai Diao",
    "postcode": "27260",
    "districtCode": "2702",
    "districtNameTh": "คลองหาด",
    "districtNameEn": "Khlong Hat",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270205",
    "subDistrictNameTh": "คลองไก่เถื่อน",
    "subDistrictNameEn": "Khlogn Kai Thuean",
    "postcode": "27260",
    "districtCode": "2702",
    "districtNameTh": "คลองหาด",
    "districtNameEn": "Khlong Hat",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270206",
    "subDistrictNameTh": "เบญจขร",
    "subDistrictNameEn": "Benchakhon",
    "postcode": "27260",
    "districtCode": "2702",
    "districtNameTh": "คลองหาด",
    "districtNameEn": "Khlong Hat",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270207",
    "subDistrictNameTh": "ไทรทอง",
    "subDistrictNameEn": "Sai Thong",
    "postcode": "27260",
    "districtCode": "2702",
    "districtNameTh": "คลองหาด",
    "districtNameEn": "Khlong Hat",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270301",
    "subDistrictNameTh": "ตาพระยา",
    "subDistrictNameEn": "Ta Phraya",
    "postcode": "27180",
    "districtCode": "2703",
    "districtNameTh": "ตาพระยา",
    "districtNameEn": "Ta Phraya",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270302",
    "subDistrictNameTh": "ทัพเสด็จ",
    "subDistrictNameEn": "Thap Sadet",
    "postcode": "27180",
    "districtCode": "2703",
    "districtNameTh": "ตาพระยา",
    "districtNameEn": "Ta Phraya",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270306",
    "subDistrictNameTh": "ทัพราช",
    "subDistrictNameEn": "Thap Rat",
    "postcode": "27180",
    "districtCode": "2703",
    "districtNameTh": "ตาพระยา",
    "districtNameEn": "Ta Phraya",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270307",
    "subDistrictNameTh": "ทัพไทย",
    "subDistrictNameEn": "Thap Thai",
    "postcode": "27180",
    "districtCode": "2703",
    "districtNameTh": "ตาพระยา",
    "districtNameEn": "Ta Phraya",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270309",
    "subDistrictNameTh": "โคคลาน",
    "subDistrictNameEn": "Kho Khlan",
    "postcode": "27180",
    "districtCode": "2703",
    "districtNameTh": "ตาพระยา",
    "districtNameEn": "Ta Phraya",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270401",
    "subDistrictNameTh": "วังน้ำเย็น",
    "subDistrictNameEn": "Wang Nam Yen",
    "postcode": "27210",
    "districtCode": "2704",
    "districtNameTh": "วังน้ำเย็น",
    "districtNameEn": "Wang Nam Yen",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270403",
    "subDistrictNameTh": "ตาหลังใน",
    "subDistrictNameEn": "Ta Lang Nai",
    "postcode": "27210",
    "districtCode": "2704",
    "districtNameTh": "วังน้ำเย็น",
    "districtNameEn": "Wang Nam Yen",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270405",
    "subDistrictNameTh": "คลองหินปูน",
    "subDistrictNameEn": "Khlong Hin Pun",
    "postcode": "27210",
    "districtCode": "2704",
    "districtNameTh": "วังน้ำเย็น",
    "districtNameEn": "Wang Nam Yen",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270406",
    "subDistrictNameTh": "ทุ่งมหาเจริญ",
    "subDistrictNameEn": "Thung Maha Charoen",
    "postcode": "27210",
    "districtCode": "2704",
    "districtNameTh": "วังน้ำเย็น",
    "districtNameEn": "Wang Nam Yen",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270501",
    "subDistrictNameTh": "วัฒนานคร",
    "subDistrictNameEn": "Watthana Nakhon",
    "postcode": "27160",
    "districtCode": "2705",
    "districtNameTh": "วัฒนานคร",
    "districtNameEn": "Watthana Nakhon",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270502",
    "subDistrictNameTh": "ท่าเกวียน",
    "subDistrictNameEn": "Tha Kwian",
    "postcode": "27160",
    "districtCode": "2705",
    "districtNameTh": "วัฒนานคร",
    "districtNameEn": "Watthana Nakhon",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270503",
    "subDistrictNameTh": "ผักขะ",
    "subDistrictNameEn": "Phak Kha",
    "postcode": "27160",
    "districtCode": "2705",
    "districtNameTh": "วัฒนานคร",
    "districtNameEn": "Watthana Nakhon",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270504",
    "subDistrictNameTh": "โนนหมากเค็ง",
    "subDistrictNameEn": "Non Mak Kheng",
    "postcode": "27160",
    "districtCode": "2705",
    "districtNameTh": "วัฒนานคร",
    "districtNameEn": "Watthana Nakhon",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270505",
    "subDistrictNameTh": "หนองน้ำใส",
    "subDistrictNameEn": "Nong Nam Sai",
    "postcode": "27160",
    "districtCode": "2705",
    "districtNameTh": "วัฒนานคร",
    "districtNameEn": "Watthana Nakhon",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270506",
    "subDistrictNameTh": "ช่องกุ่ม",
    "subDistrictNameEn": "Chong Kum",
    "postcode": "27160",
    "districtCode": "2705",
    "districtNameTh": "วัฒนานคร",
    "districtNameEn": "Watthana Nakhon",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270507",
    "subDistrictNameTh": "หนองแวง",
    "subDistrictNameEn": "Nong Waeng",
    "postcode": "27160",
    "districtCode": "2705",
    "districtNameTh": "วัฒนานคร",
    "districtNameEn": "Watthana Nakhon",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270508",
    "subDistrictNameTh": "แซร์ออ",
    "subDistrictNameEn": "Sae-o",
    "postcode": "27160",
    "districtCode": "2705",
    "districtNameTh": "วัฒนานคร",
    "districtNameEn": "Watthana Nakhon",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270509",
    "subDistrictNameTh": "หนองหมากฝ้าย",
    "subDistrictNameEn": "Nong Mak Fai",
    "postcode": "27160",
    "districtCode": "2705",
    "districtNameTh": "วัฒนานคร",
    "districtNameEn": "Watthana Nakhon",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270510",
    "subDistrictNameTh": "หนองตะเคียนบอน",
    "subDistrictNameEn": "Nong Takian Bon",
    "postcode": "27160",
    "districtCode": "2705",
    "districtNameTh": "วัฒนานคร",
    "districtNameEn": "Watthana Nakhon",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270511",
    "subDistrictNameTh": "ห้วยโจด",
    "subDistrictNameEn": "Huai Chot",
    "postcode": "27160",
    "districtCode": "2705",
    "districtNameTh": "วัฒนานคร",
    "districtNameEn": "Watthana Nakhon",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270601",
    "subDistrictNameTh": "อรัญประเทศ",
    "subDistrictNameEn": "Aranyaprathet",
    "postcode": "27120",
    "districtCode": "2706",
    "districtNameTh": "อรัญประเทศ",
    "districtNameEn": "Aranyaprathet",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270602",
    "subDistrictNameTh": "เมืองไผ่",
    "subDistrictNameEn": "Mueang Phai",
    "postcode": "27120",
    "districtCode": "2706",
    "districtNameTh": "อรัญประเทศ",
    "districtNameEn": "Aranyaprathet",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270603",
    "subDistrictNameTh": "หันทราย",
    "subDistrictNameEn": "Han Sai",
    "postcode": "27120",
    "districtCode": "2706",
    "districtNameTh": "อรัญประเทศ",
    "districtNameEn": "Aranyaprathet",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270604",
    "subDistrictNameTh": "คลองน้ำใส",
    "subDistrictNameEn": "Khlong Nam Sai",
    "postcode": "27120",
    "districtCode": "2706",
    "districtNameTh": "อรัญประเทศ",
    "districtNameEn": "Aranyaprathet",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270605",
    "subDistrictNameTh": "ท่าข้าม",
    "subDistrictNameEn": "Tha Kham",
    "postcode": "27120",
    "districtCode": "2706",
    "districtNameTh": "อรัญประเทศ",
    "districtNameEn": "Aranyaprathet",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270606",
    "subDistrictNameTh": "ป่าไร่",
    "subDistrictNameEn": "Pa Rai",
    "postcode": "27120",
    "districtCode": "2706",
    "districtNameTh": "อรัญประเทศ",
    "districtNameEn": "Aranyaprathet",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270607",
    "subDistrictNameTh": "ทับพริก",
    "subDistrictNameEn": "Thap Phrik",
    "postcode": "27120",
    "districtCode": "2706",
    "districtNameTh": "อรัญประเทศ",
    "districtNameEn": "Aranyaprathet",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270608",
    "subDistrictNameTh": "บ้านใหม่หนองไทร",
    "subDistrictNameEn": "Ban Mai Nong Sai",
    "postcode": "27120",
    "districtCode": "2706",
    "districtNameTh": "อรัญประเทศ",
    "districtNameEn": "Aranyaprathet",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270609",
    "subDistrictNameTh": "ผ่านศึก",
    "subDistrictNameEn": "Phan Suek",
    "postcode": "27120",
    "districtCode": "2706",
    "districtNameTh": "อรัญประเทศ",
    "districtNameEn": "Aranyaprathet",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270610",
    "subDistrictNameTh": "หนองสังข์",
    "subDistrictNameEn": "Nong Sang",
    "postcode": "27120",
    "districtCode": "2706",
    "districtNameTh": "อรัญประเทศ",
    "districtNameEn": "Aranyaprathet",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270611",
    "subDistrictNameTh": "คลองทับจันทร์",
    "subDistrictNameEn": "Khlong Thap Chan",
    "postcode": "27120",
    "districtCode": "2706",
    "districtNameTh": "อรัญประเทศ",
    "districtNameEn": "Aranyaprathet",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270612",
    "subDistrictNameTh": "ฟากห้วย",
    "subDistrictNameEn": "Fak Huai",
    "postcode": "27120",
    "districtCode": "2706",
    "districtNameTh": "อรัญประเทศ",
    "districtNameEn": "Aranyaprathet",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270613",
    "subDistrictNameTh": "บ้านด่าน",
    "subDistrictNameEn": "Ban Dan",
    "postcode": "27120",
    "districtCode": "2706",
    "districtNameTh": "อรัญประเทศ",
    "districtNameEn": "Aranyaprathet",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270701",
    "subDistrictNameTh": "เขาฉกรรจ์",
    "subDistrictNameEn": "Khao Chakan",
    "postcode": "27000",
    "districtCode": "2707",
    "districtNameTh": "เขาฉกรรจ์",
    "districtNameEn": "Khao Chakan",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270702",
    "subDistrictNameTh": "หนองหว้า",
    "subDistrictNameEn": "Nong Wa",
    "postcode": "27000",
    "districtCode": "2707",
    "districtNameTh": "เขาฉกรรจ์",
    "districtNameEn": "Khao Chakan",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270703",
    "subDistrictNameTh": "พระเพลิง",
    "subDistrictNameEn": "Phra Phloeng",
    "postcode": "27000",
    "districtCode": "2707",
    "districtNameTh": "เขาฉกรรจ์",
    "districtNameEn": "Khao Chakan",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270704",
    "subDistrictNameTh": "เขาสามสิบ",
    "subDistrictNameEn": "Khao Sam Sip",
    "postcode": "27000",
    "districtCode": "2707",
    "districtNameTh": "เขาฉกรรจ์",
    "districtNameEn": "Khao Chakan",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270801",
    "subDistrictNameTh": "โคกสูง",
    "subDistrictNameEn": "Khok Sung",
    "postcode": "27120",
    "districtCode": "2708",
    "districtNameTh": "โคกสูง",
    "districtNameEn": "Khok Sung",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270802",
    "subDistrictNameTh": "หนองม่วง",
    "subDistrictNameEn": "Nong Muang",
    "postcode": "27120",
    "districtCode": "2708",
    "districtNameTh": "โคกสูง",
    "districtNameEn": "Khok Sung",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270803",
    "subDistrictNameTh": "หนองแวง",
    "subDistrictNameEn": "Nong Waeng",
    "postcode": "27120",
    "districtCode": "2708",
    "districtNameTh": "โคกสูง",
    "districtNameEn": "Khok Sung",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270804",
    "subDistrictNameTh": "โนนหมากมุ่น",
    "subDistrictNameEn": "Non Mak Mun",
    "postcode": "27120",
    "districtCode": "2708",
    "districtNameTh": "โคกสูง",
    "districtNameEn": "Khok Sung",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270901",
    "subDistrictNameTh": "วังสมบูรณ์",
    "subDistrictNameEn": "Wang Sombun",
    "postcode": "27250",
    "districtCode": "2709",
    "districtNameTh": "วังสมบูรณ์",
    "districtNameEn": "Wang Sombun",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270902",
    "subDistrictNameTh": "วังใหม่",
    "subDistrictNameEn": "Wang Mai",
    "postcode": "27250",
    "districtCode": "2709",
    "districtNameTh": "วังสมบูรณ์",
    "districtNameEn": "Wang Sombun",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "270903",
    "subDistrictNameTh": "วังทอง",
    "subDistrictNameEn": "Wang Thong",
    "postcode": "27250",
    "districtCode": "2709",
    "districtNameTh": "วังสมบูรณ์",
    "districtNameEn": "Wang Sombun",
    "provinceCode": "27",
    "provinceNameTh": "สระแก้ว",
    "provinceNameEn": "Sa kaeo"
},
{
    "subDistrictCode": "300101",
    "subDistrictNameTh": "ในเมือง",
    "subDistrictNameEn": "Nai Mueang",
    "postcode": "30000",
    "districtCode": "3001",
    "districtNameTh": "เมืองนครราชสีมา",
    "districtNameEn": "Mueang Nakhon Ratchasima",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300102",
    "subDistrictNameTh": "โพธิ์กลาง",
    "subDistrictNameEn": "Pho Klang",
    "postcode": "30000",
    "districtCode": "3001",
    "districtNameTh": "เมืองนครราชสีมา",
    "districtNameEn": "Mueang Nakhon Ratchasima",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300103",
    "subDistrictNameTh": "หนองจะบก",
    "subDistrictNameEn": "Nong Chabok",
    "postcode": "30000",
    "districtCode": "3001",
    "districtNameTh": "เมืองนครราชสีมา",
    "districtNameEn": "Mueang Nakhon Ratchasima",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300104",
    "subDistrictNameTh": "โคกสูง",
    "subDistrictNameEn": "Khok Sung",
    "postcode": "30310",
    "districtCode": "3001",
    "districtNameTh": "เมืองนครราชสีมา",
    "districtNameEn": "Mueang Nakhon Ratchasima",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300105",
    "subDistrictNameTh": "มะเริง",
    "subDistrictNameEn": "Maroeng",
    "postcode": "30000",
    "districtCode": "3001",
    "districtNameTh": "เมืองนครราชสีมา",
    "districtNameEn": "Mueang Nakhon Ratchasima",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300106",
    "subDistrictNameTh": "หนองระเวียง",
    "subDistrictNameEn": "Nong Rawiang",
    "postcode": "30000",
    "districtCode": "3001",
    "districtNameTh": "เมืองนครราชสีมา",
    "districtNameEn": "Mueang Nakhon Ratchasima",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300107",
    "subDistrictNameTh": "ปรุใหญ่",
    "subDistrictNameEn": "Pru Yai",
    "postcode": "30000",
    "districtCode": "3001",
    "districtNameTh": "เมืองนครราชสีมา",
    "districtNameEn": "Mueang Nakhon Ratchasima",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300108",
    "subDistrictNameTh": "หมื่นไวย",
    "subDistrictNameEn": "Muen Wai",
    "postcode": "30000",
    "districtCode": "3001",
    "districtNameTh": "เมืองนครราชสีมา",
    "districtNameEn": "Mueang Nakhon Ratchasima",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300109",
    "subDistrictNameTh": "พลกรัง",
    "subDistrictNameEn": "Phon Krang",
    "postcode": "30000",
    "districtCode": "3001",
    "districtNameTh": "เมืองนครราชสีมา",
    "districtNameEn": "Mueang Nakhon Ratchasima",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300110",
    "subDistrictNameTh": "หนองไผ่ล้อม",
    "subDistrictNameEn": "Nong Phai Lom",
    "postcode": "30000",
    "districtCode": "3001",
    "districtNameTh": "เมืองนครราชสีมา",
    "districtNameEn": "Mueang Nakhon Ratchasima",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300111",
    "subDistrictNameTh": "หัวทะเล",
    "subDistrictNameEn": "Hua Thale",
    "postcode": "30000",
    "districtCode": "3001",
    "districtNameTh": "เมืองนครราชสีมา",
    "districtNameEn": "Mueang Nakhon Ratchasima",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300112",
    "subDistrictNameTh": "บ้านเกาะ",
    "subDistrictNameEn": "Ban Ko",
    "postcode": "30000",
    "districtCode": "3001",
    "districtNameTh": "เมืองนครราชสีมา",
    "districtNameEn": "Mueang Nakhon Ratchasima",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300113",
    "subDistrictNameTh": "บ้านใหม่",
    "subDistrictNameEn": "Ban Mai",
    "postcode": "30000",
    "districtCode": "3001",
    "districtNameTh": "เมืองนครราชสีมา",
    "districtNameEn": "Mueang Nakhon Ratchasima",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300114",
    "subDistrictNameTh": "พุดซา",
    "subDistrictNameEn": "Phutsa",
    "postcode": "30000",
    "districtCode": "3001",
    "districtNameTh": "เมืองนครราชสีมา",
    "districtNameEn": "Mueang Nakhon Ratchasima",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300115",
    "subDistrictNameTh": "บ้านโพธิ์",
    "subDistrictNameEn": "Ban Pho",
    "postcode": "30310",
    "districtCode": "3001",
    "districtNameTh": "เมืองนครราชสีมา",
    "districtNameEn": "Mueang Nakhon Ratchasima",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300116",
    "subDistrictNameTh": "จอหอ",
    "subDistrictNameEn": "Cho Ho",
    "postcode": "30310",
    "districtCode": "3001",
    "districtNameTh": "เมืองนครราชสีมา",
    "districtNameEn": "Mueang Nakhon Ratchasima",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300117",
    "subDistrictNameTh": "โคกกรวด",
    "subDistrictNameEn": "Khok Kruat",
    "postcode": "30280",
    "districtCode": "3001",
    "districtNameTh": "เมืองนครราชสีมา",
    "districtNameEn": "Mueang Nakhon Ratchasima",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300118",
    "subDistrictNameTh": "ไชยมงคล",
    "subDistrictNameEn": "Chai Mongkhon",
    "postcode": "30000",
    "districtCode": "3001",
    "districtNameTh": "เมืองนครราชสีมา",
    "districtNameEn": "Mueang Nakhon Ratchasima",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300119",
    "subDistrictNameTh": "หนองบัวศาลา",
    "subDistrictNameEn": "Nong Bua Sala",
    "postcode": "30000",
    "districtCode": "3001",
    "districtNameTh": "เมืองนครราชสีมา",
    "districtNameEn": "Mueang Nakhon Ratchasima",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300120",
    "subDistrictNameTh": "สุรนารี",
    "subDistrictNameEn": "Suranari",
    "postcode": "30000",
    "districtCode": "3001",
    "districtNameTh": "เมืองนครราชสีมา",
    "districtNameEn": "Mueang Nakhon Ratchasima",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300121",
    "subDistrictNameTh": "สีมุม",
    "subDistrictNameEn": "Si Mum",
    "postcode": "30000",
    "districtCode": "3001",
    "districtNameTh": "เมืองนครราชสีมา",
    "districtNameEn": "Mueang Nakhon Ratchasima",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300122",
    "subDistrictNameTh": "ตลาด",
    "subDistrictNameEn": "Talat",
    "postcode": "30310",
    "districtCode": "3001",
    "districtNameTh": "เมืองนครราชสีมา",
    "districtNameEn": "Mueang Nakhon Ratchasima",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300123",
    "subDistrictNameTh": "พะเนา",
    "subDistrictNameEn": "Phanao",
    "postcode": "30000",
    "districtCode": "3001",
    "districtNameTh": "เมืองนครราชสีมา",
    "districtNameEn": "Mueang Nakhon Ratchasima",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300124",
    "subDistrictNameTh": "หนองกระทุ่ม",
    "subDistrictNameEn": "Nong Krathum",
    "postcode": "30000",
    "districtCode": "3001",
    "districtNameTh": "เมืองนครราชสีมา",
    "districtNameEn": "Mueang Nakhon Ratchasima",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300125",
    "subDistrictNameTh": "หนองไข่น้ำ",
    "subDistrictNameEn": "Nong Khai Nam",
    "postcode": "30310",
    "districtCode": "3001",
    "districtNameTh": "เมืองนครราชสีมา",
    "districtNameEn": "Mueang Nakhon Ratchasima",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300201",
    "subDistrictNameTh": "แชะ",
    "subDistrictNameEn": "Chae",
    "postcode": "30250",
    "districtCode": "3002",
    "districtNameTh": "ครบุรี",
    "districtNameEn": "Khon Buri",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300202",
    "subDistrictNameTh": "เฉลียง",
    "subDistrictNameEn": "Chaliang",
    "postcode": "30250",
    "districtCode": "3002",
    "districtNameTh": "ครบุรี",
    "districtNameEn": "Khon Buri",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300203",
    "subDistrictNameTh": "ครบุรี",
    "subDistrictNameEn": "Khon Buri",
    "postcode": "30250",
    "districtCode": "3002",
    "districtNameTh": "ครบุรี",
    "districtNameEn": "Khon Buri",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300204",
    "subDistrictNameTh": "โคกกระชาย",
    "subDistrictNameEn": "Khok Krachai",
    "postcode": "30250",
    "districtCode": "3002",
    "districtNameTh": "ครบุรี",
    "districtNameEn": "Khon Buri",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300205",
    "subDistrictNameTh": "จระเข้หิน",
    "subDistrictNameEn": "Chorakhe Hin",
    "postcode": "30250",
    "districtCode": "3002",
    "districtNameTh": "ครบุรี",
    "districtNameEn": "Khon Buri",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300206",
    "subDistrictNameTh": "มาบตะโกเอน",
    "subDistrictNameEn": "Map Tako En",
    "postcode": "30250",
    "districtCode": "3002",
    "districtNameTh": "ครบุรี",
    "districtNameEn": "Khon Buri",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300207",
    "subDistrictNameTh": "อรพิมพ์",
    "subDistrictNameEn": "Oraphim",
    "postcode": "30250",
    "districtCode": "3002",
    "districtNameTh": "ครบุรี",
    "districtNameEn": "Khon Buri",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300208",
    "subDistrictNameTh": "บ้านใหม่",
    "subDistrictNameEn": "Ban Mai",
    "postcode": "30250",
    "districtCode": "3002",
    "districtNameTh": "ครบุรี",
    "districtNameEn": "Khon Buri",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300209",
    "subDistrictNameTh": "ลำเพียก",
    "subDistrictNameEn": "Lam Phiak",
    "postcode": "30250",
    "districtCode": "3002",
    "districtNameTh": "ครบุรี",
    "districtNameEn": "Khon Buri",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300210",
    "subDistrictNameTh": "ครบุรีใต้",
    "subDistrictNameEn": "Khon Buri Tai",
    "postcode": "30250",
    "districtCode": "3002",
    "districtNameTh": "ครบุรี",
    "districtNameEn": "Khon Buri",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300211",
    "subDistrictNameTh": "ตะแบกบาน",
    "subDistrictNameEn": "Tabaek Ban",
    "postcode": "30250",
    "districtCode": "3002",
    "districtNameTh": "ครบุรี",
    "districtNameEn": "Khon Buri",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300212",
    "subDistrictNameTh": "สระว่านพระยา",
    "subDistrictNameEn": "Sa Wan Phraya",
    "postcode": "30250",
    "districtCode": "3002",
    "districtNameTh": "ครบุรี",
    "districtNameEn": "Khon Buri",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300301",
    "subDistrictNameTh": "เสิงสาง",
    "subDistrictNameEn": "Soeng Sang",
    "postcode": "30330",
    "districtCode": "3003",
    "districtNameTh": "เสิงสาง",
    "districtNameEn": "Soeng Sang",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300302",
    "subDistrictNameTh": "สระตะเคียน",
    "subDistrictNameEn": "Sa Takhian",
    "postcode": "30330",
    "districtCode": "3003",
    "districtNameTh": "เสิงสาง",
    "districtNameEn": "Soeng Sang",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300303",
    "subDistrictNameTh": "โนนสมบูรณ์",
    "subDistrictNameEn": "Non Sombun",
    "postcode": "30330",
    "districtCode": "3003",
    "districtNameTh": "เสิงสาง",
    "districtNameEn": "Soeng Sang",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300304",
    "subDistrictNameTh": "กุดโบสถ์",
    "subDistrictNameEn": "Kut Bot",
    "postcode": "30330",
    "districtCode": "3003",
    "districtNameTh": "เสิงสาง",
    "districtNameEn": "Soeng Sang",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300305",
    "subDistrictNameTh": "สุขไพบูลย์",
    "subDistrictNameEn": "Suk Phaibun",
    "postcode": "30330",
    "districtCode": "3003",
    "districtNameTh": "เสิงสาง",
    "districtNameEn": "Soeng Sang",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300306",
    "subDistrictNameTh": "บ้านราษฎร์",
    "subDistrictNameEn": "Ban Rat",
    "postcode": "30330",
    "districtCode": "3003",
    "districtNameTh": "เสิงสาง",
    "districtNameEn": "Soeng Sang",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300401",
    "subDistrictNameTh": "เมืองคง",
    "subDistrictNameEn": "Mueang Khong",
    "postcode": "30260",
    "districtCode": "3004",
    "districtNameTh": "คง",
    "districtNameEn": "Khong",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300402",
    "subDistrictNameTh": "คูขาด",
    "subDistrictNameEn": "Khu Khat",
    "postcode": "30260",
    "districtCode": "3004",
    "districtNameTh": "คง",
    "districtNameEn": "Khong",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300403",
    "subDistrictNameTh": "เทพาลัย",
    "subDistrictNameEn": "Thephalai",
    "postcode": "30260",
    "districtCode": "3004",
    "districtNameTh": "คง",
    "districtNameEn": "Khong",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300404",
    "subDistrictNameTh": "ตาจั่น",
    "subDistrictNameEn": "Ta Chan",
    "postcode": "30260",
    "districtCode": "3004",
    "districtNameTh": "คง",
    "districtNameEn": "Khong",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300405",
    "subDistrictNameTh": "บ้านปรางค์",
    "subDistrictNameEn": "Ban Prang",
    "postcode": "30260",
    "districtCode": "3004",
    "districtNameTh": "คง",
    "districtNameEn": "Khong",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300406",
    "subDistrictNameTh": "หนองมะนาว",
    "subDistrictNameEn": "Nong Manao",
    "postcode": "30260",
    "districtCode": "3004",
    "districtNameTh": "คง",
    "districtNameEn": "Khong",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300407",
    "subDistrictNameTh": "หนองบัว",
    "subDistrictNameEn": "Nong Bua",
    "postcode": "30260",
    "districtCode": "3004",
    "districtNameTh": "คง",
    "districtNameEn": "Khong",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300408",
    "subDistrictNameTh": "โนนเต็ง",
    "subDistrictNameEn": "Non Teng",
    "postcode": "30260",
    "districtCode": "3004",
    "districtNameTh": "คง",
    "districtNameEn": "Khong",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300409",
    "subDistrictNameTh": "ดอนใหญ่",
    "subDistrictNameEn": "Don Yai",
    "postcode": "30260",
    "districtCode": "3004",
    "districtNameTh": "คง",
    "districtNameEn": "Khong",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300410",
    "subDistrictNameTh": "ขามสมบูรณ์",
    "subDistrictNameEn": "Kham Sombun",
    "postcode": "30260",
    "districtCode": "3004",
    "districtNameTh": "คง",
    "districtNameEn": "Khong",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300501",
    "subDistrictNameTh": "บ้านเหลื่อม",
    "subDistrictNameEn": "Ban Lueam",
    "postcode": "30350",
    "districtCode": "3005",
    "districtNameTh": "บ้านเหลื่อม",
    "districtNameEn": "Ban Lueam",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300502",
    "subDistrictNameTh": "วังโพธิ์",
    "subDistrictNameEn": "Wang Pho",
    "postcode": "30350",
    "districtCode": "3005",
    "districtNameTh": "บ้านเหลื่อม",
    "districtNameEn": "Ban Lueam",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300503",
    "subDistrictNameTh": "โคกกระเบื้อง",
    "subDistrictNameEn": "Khok Krabueang",
    "postcode": "30350",
    "districtCode": "3005",
    "districtNameTh": "บ้านเหลื่อม",
    "districtNameEn": "Ban Lueam",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300504",
    "subDistrictNameTh": "ช่อระกา",
    "subDistrictNameEn": "Cho Raka",
    "postcode": "30350",
    "districtCode": "3005",
    "districtNameTh": "บ้านเหลื่อม",
    "districtNameEn": "Ban Lueam",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300601",
    "subDistrictNameTh": "จักราช",
    "subDistrictNameEn": "Chakkarat",
    "postcode": "30230",
    "districtCode": "3006",
    "districtNameTh": "จักราช",
    "districtNameEn": "Chakkarat",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300603",
    "subDistrictNameTh": "ทองหลาง",
    "subDistrictNameEn": "Thonglang",
    "postcode": "30230",
    "districtCode": "3006",
    "districtNameTh": "จักราช",
    "districtNameEn": "Chakkarat",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300604",
    "subDistrictNameTh": "สีสุก",
    "subDistrictNameEn": "Si Suk",
    "postcode": "30230",
    "districtCode": "3006",
    "districtNameTh": "จักราช",
    "districtNameEn": "Chakkarat",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300605",
    "subDistrictNameTh": "หนองขาม",
    "subDistrictNameEn": "Nong Kham",
    "postcode": "30230",
    "districtCode": "3006",
    "districtNameTh": "จักราช",
    "districtNameEn": "Chakkarat",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300607",
    "subDistrictNameTh": "หนองพลวง",
    "subDistrictNameEn": "Nong Phluang",
    "postcode": "30230",
    "districtCode": "3006",
    "districtNameTh": "จักราช",
    "districtNameEn": "Chakkarat",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300610",
    "subDistrictNameTh": "ศรีละกอ",
    "subDistrictNameEn": "Si Lako",
    "postcode": "30230",
    "districtCode": "3006",
    "districtNameTh": "จักราช",
    "districtNameEn": "Chakkarat",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300611",
    "subDistrictNameTh": "คลองเมือง",
    "subDistrictNameEn": "Khlong Mueang",
    "postcode": "30230",
    "districtCode": "3006",
    "districtNameTh": "จักราช",
    "districtNameEn": "Chakkarat",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300613",
    "subDistrictNameTh": "หินโคน",
    "subDistrictNameEn": "Hin Khon",
    "postcode": "30230",
    "districtCode": "3006",
    "districtNameTh": "จักราช",
    "districtNameEn": "Chakkarat",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300701",
    "subDistrictNameTh": "กระโทก",
    "subDistrictNameEn": "Krathok",
    "postcode": "30190",
    "districtCode": "3007",
    "districtNameTh": "โชคชัย",
    "districtNameEn": "Chok Chai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300702",
    "subDistrictNameTh": "พลับพลา",
    "subDistrictNameEn": "Phlapphla",
    "postcode": "30190",
    "districtCode": "3007",
    "districtNameTh": "โชคชัย",
    "districtNameEn": "Chok Chai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300703",
    "subDistrictNameTh": "ท่าอ่าง",
    "subDistrictNameEn": "Tha Ang",
    "postcode": "30190",
    "districtCode": "3007",
    "districtNameTh": "โชคชัย",
    "districtNameEn": "Chok Chai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300704",
    "subDistrictNameTh": "ทุ่งอรุณ",
    "subDistrictNameEn": "Thung Arun",
    "postcode": "30190",
    "districtCode": "3007",
    "districtNameTh": "โชคชัย",
    "districtNameEn": "Chok Chai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300705",
    "subDistrictNameTh": "ท่าลาดขาว",
    "subDistrictNameEn": "Tha Lat Khao",
    "postcode": "30190",
    "districtCode": "3007",
    "districtNameTh": "โชคชัย",
    "districtNameEn": "Chok Chai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300706",
    "subDistrictNameTh": "ท่าจะหลุง",
    "subDistrictNameEn": "Tha Chalung",
    "postcode": "30190",
    "districtCode": "3007",
    "districtNameTh": "โชคชัย",
    "districtNameEn": "Chok Chai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300707",
    "subDistrictNameTh": "ท่าเยี่ยม",
    "subDistrictNameEn": "Tha Yiam",
    "postcode": "30190",
    "districtCode": "3007",
    "districtNameTh": "โชคชัย",
    "districtNameEn": "Chok Chai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300708",
    "subDistrictNameTh": "โชคชัย",
    "subDistrictNameEn": "Chok Chai",
    "postcode": "30190",
    "districtCode": "3007",
    "districtNameTh": "โชคชัย",
    "districtNameEn": "Chok Chai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300709",
    "subDistrictNameTh": "ละลมใหม่พัฒนา",
    "subDistrictNameEn": "Lalom Mai Phatthana",
    "postcode": "30190",
    "districtCode": "3007",
    "districtNameTh": "โชคชัย",
    "districtNameEn": "Chok Chai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300710",
    "subDistrictNameTh": "ด่านเกวียน",
    "subDistrictNameEn": "Dan Kwian",
    "postcode": "30190",
    "districtCode": "3007",
    "districtNameTh": "โชคชัย",
    "districtNameEn": "Chok Chai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300801",
    "subDistrictNameTh": "กุดพิมาน",
    "subDistrictNameEn": "Kut Phiman",
    "postcode": "30210",
    "districtCode": "3008",
    "districtNameTh": "ด่านขุนทด",
    "districtNameEn": "Dan Khun Thot",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300802",
    "subDistrictNameTh": "ด่านขุนทด",
    "subDistrictNameEn": "Dan Khun Thot",
    "postcode": "30210",
    "districtCode": "3008",
    "districtNameTh": "ด่านขุนทด",
    "districtNameEn": "Dan Khun Thot",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300803",
    "subDistrictNameTh": "ด่านนอก",
    "subDistrictNameEn": "Dan Nok",
    "postcode": "30210",
    "districtCode": "3008",
    "districtNameTh": "ด่านขุนทด",
    "districtNameEn": "Dan Khun Thot",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300804",
    "subDistrictNameTh": "ด่านใน",
    "subDistrictNameEn": "Dan Nai",
    "postcode": "30210",
    "districtCode": "3008",
    "districtNameTh": "ด่านขุนทด",
    "districtNameEn": "Dan Khun Thot",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300805",
    "subDistrictNameTh": "ตะเคียน",
    "subDistrictNameEn": "Takhian",
    "postcode": "30210",
    "districtCode": "3008",
    "districtNameTh": "ด่านขุนทด",
    "districtNameEn": "Dan Khun Thot",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300806",
    "subDistrictNameTh": "บ้านเก่า",
    "subDistrictNameEn": "Ban Kao",
    "postcode": "30210",
    "districtCode": "3008",
    "districtNameTh": "ด่านขุนทด",
    "districtNameEn": "Dan Khun Thot",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300807",
    "subDistrictNameTh": "บ้านแปรง",
    "subDistrictNameEn": "Ban Praeng",
    "postcode": "36220",
    "districtCode": "3008",
    "districtNameTh": "ด่านขุนทด",
    "districtNameEn": "Dan Khun Thot",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300808",
    "subDistrictNameTh": "พันชนะ",
    "subDistrictNameEn": "Phan Chana",
    "postcode": "30210",
    "districtCode": "3008",
    "districtNameTh": "ด่านขุนทด",
    "districtNameEn": "Dan Khun Thot",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300809",
    "subDistrictNameTh": "สระจรเข้",
    "subDistrictNameEn": "Sa Chorakhe",
    "postcode": "30210",
    "districtCode": "3008",
    "districtNameTh": "ด่านขุนทด",
    "districtNameEn": "Dan Khun Thot",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300810",
    "subDistrictNameTh": "หนองกราด",
    "subDistrictNameEn": "Nong Krat",
    "postcode": "30210",
    "districtCode": "3008",
    "districtNameTh": "ด่านขุนทด",
    "districtNameEn": "Dan Khun Thot",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300811",
    "subDistrictNameTh": "หนองบัวตะเกียด",
    "subDistrictNameEn": "Nong Bua Takiat",
    "postcode": "30210",
    "districtCode": "3008",
    "districtNameTh": "ด่านขุนทด",
    "districtNameEn": "Dan Khun Thot",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300812",
    "subDistrictNameTh": "หนองบัวละคร",
    "subDistrictNameEn": "Nong Bua Lakhon",
    "postcode": "30210",
    "districtCode": "3008",
    "districtNameTh": "ด่านขุนทด",
    "districtNameEn": "Dan Khun Thot",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300813",
    "subDistrictNameTh": "หินดาด",
    "subDistrictNameEn": "Hin Dat",
    "postcode": "30210",
    "districtCode": "3008",
    "districtNameTh": "ด่านขุนทด",
    "districtNameEn": "Dan Khun Thot",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300815",
    "subDistrictNameTh": "ห้วยบง",
    "subDistrictNameEn": "Huai Bong",
    "postcode": "30210",
    "districtCode": "3008",
    "districtNameTh": "ด่านขุนทด",
    "districtNameEn": "Dan Khun Thot",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300817",
    "subDistrictNameTh": "โนนเมืองพัฒนา",
    "subDistrictNameEn": "Non Mueang Phatthana",
    "postcode": "30210",
    "districtCode": "3008",
    "districtNameTh": "ด่านขุนทด",
    "districtNameEn": "Dan Khun Thot",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300818",
    "subDistrictNameTh": "หนองไทร",
    "subDistrictNameEn": "Nong Sai",
    "postcode": "36220",
    "districtCode": "3008",
    "districtNameTh": "ด่านขุนทด",
    "districtNameEn": "Dan Khun Thot",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300901",
    "subDistrictNameTh": "โนนไทย",
    "subDistrictNameEn": "Non Thai",
    "postcode": "30220",
    "districtCode": "3009",
    "districtNameTh": "โนนไทย",
    "districtNameEn": "Non Thai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300902",
    "subDistrictNameTh": "ด่านจาก",
    "subDistrictNameEn": "Dan Chak",
    "postcode": "30220",
    "districtCode": "3009",
    "districtNameTh": "โนนไทย",
    "districtNameEn": "Non Thai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300903",
    "subDistrictNameTh": "กำปัง",
    "subDistrictNameEn": "Kampang",
    "postcode": "30220",
    "districtCode": "3009",
    "districtNameTh": "โนนไทย",
    "districtNameEn": "Non Thai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300904",
    "subDistrictNameTh": "สำโรง",
    "subDistrictNameEn": "Samrong",
    "postcode": "30220",
    "districtCode": "3009",
    "districtNameTh": "โนนไทย",
    "districtNameEn": "Non Thai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300905",
    "subDistrictNameTh": "ค้างพลู",
    "subDistrictNameEn": "Khang Phu",
    "postcode": "30220",
    "districtCode": "3009",
    "districtNameTh": "โนนไทย",
    "districtNameEn": "Non Thai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300906",
    "subDistrictNameTh": "บ้านวัง",
    "subDistrictNameEn": "Ban Wang",
    "postcode": "30220",
    "districtCode": "3009",
    "districtNameTh": "โนนไทย",
    "districtNameEn": "Non Thai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300907",
    "subDistrictNameTh": "บัลลังก์",
    "subDistrictNameEn": "Banlang",
    "postcode": "30220",
    "districtCode": "3009",
    "districtNameTh": "โนนไทย",
    "districtNameEn": "Non Thai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300908",
    "subDistrictNameTh": "สายออ",
    "subDistrictNameEn": "Sai O",
    "postcode": "30220",
    "districtCode": "3009",
    "districtNameTh": "โนนไทย",
    "districtNameEn": "Non Thai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300909",
    "subDistrictNameTh": "ถนนโพธิ์",
    "subDistrictNameEn": "Thanon Pho",
    "postcode": "30220",
    "districtCode": "3009",
    "districtNameTh": "โนนไทย",
    "districtNameEn": "Non Thai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "300914",
    "subDistrictNameTh": "มะค่า",
    "subDistrictNameEn": "Makha",
    "postcode": "30220",
    "districtCode": "3009",
    "districtNameTh": "โนนไทย",
    "districtNameEn": "Non Thai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301001",
    "subDistrictNameTh": "โนนสูง",
    "subDistrictNameEn": "Non Sung",
    "postcode": "30160",
    "districtCode": "3010",
    "districtNameTh": "โนนสูง",
    "districtNameEn": "Non Sung",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301002",
    "subDistrictNameTh": "ใหม่",
    "subDistrictNameEn": "Mai",
    "postcode": "30160",
    "districtCode": "3010",
    "districtNameTh": "โนนสูง",
    "districtNameEn": "Non Sung",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301003",
    "subDistrictNameTh": "โตนด",
    "subDistrictNameEn": "Tanot",
    "postcode": "30160",
    "districtCode": "3010",
    "districtNameTh": "โนนสูง",
    "districtNameEn": "Non Sung",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301004",
    "subDistrictNameTh": "บิง",
    "subDistrictNameEn": "Bing",
    "postcode": "30160",
    "districtCode": "3010",
    "districtNameTh": "โนนสูง",
    "districtNameEn": "Non Sung",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301005",
    "subDistrictNameTh": "ดอนชมพู",
    "subDistrictNameEn": "Don Chomphu",
    "postcode": "30160",
    "districtCode": "3010",
    "districtNameTh": "โนนสูง",
    "districtNameEn": "Non Sung",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301006",
    "subDistrictNameTh": "ธารปราสาท",
    "subDistrictNameEn": "Than Prasat",
    "postcode": "30420",
    "districtCode": "3010",
    "districtNameTh": "โนนสูง",
    "districtNameEn": "Non Sung",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301007",
    "subDistrictNameTh": "หลุมข้าว",
    "subDistrictNameEn": "Lum Khao",
    "postcode": "30160",
    "districtCode": "3010",
    "districtNameTh": "โนนสูง",
    "districtNameEn": "Non Sung",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301008",
    "subDistrictNameTh": "มะค่า",
    "subDistrictNameEn": "Makha",
    "postcode": "30160",
    "districtCode": "3010",
    "districtNameTh": "โนนสูง",
    "districtNameEn": "Non Sung",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301009",
    "subDistrictNameTh": "พลสงคราม",
    "subDistrictNameEn": "Phon Songkhram",
    "postcode": "30160",
    "districtCode": "3010",
    "districtNameTh": "โนนสูง",
    "districtNameEn": "Non Sung",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301010",
    "subDistrictNameTh": "จันอัด",
    "subDistrictNameEn": "Chan At",
    "postcode": "30160",
    "districtCode": "3010",
    "districtNameTh": "โนนสูง",
    "districtNameEn": "Non Sung",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301011",
    "subDistrictNameTh": "ขามเฒ่า",
    "subDistrictNameEn": "Kham Thao",
    "postcode": "30160",
    "districtCode": "3010",
    "districtNameTh": "โนนสูง",
    "districtNameEn": "Non Sung",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301012",
    "subDistrictNameTh": "ด่านคล้า",
    "subDistrictNameEn": "Dan Khla",
    "postcode": "30160",
    "districtCode": "3010",
    "districtNameTh": "โนนสูง",
    "districtNameEn": "Non Sung",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301013",
    "subDistrictNameTh": "ลำคอหงษ์",
    "subDistrictNameEn": "Lam Kho Hong",
    "postcode": "30160",
    "districtCode": "3010",
    "districtNameTh": "โนนสูง",
    "districtNameEn": "Non Sung",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301014",
    "subDistrictNameTh": "เมืองปราสาท",
    "subDistrictNameEn": "Mueang Prasat",
    "postcode": "30160",
    "districtCode": "3010",
    "districtNameTh": "โนนสูง",
    "districtNameEn": "Non Sung",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301015",
    "subDistrictNameTh": "ดอนหวาย",
    "subDistrictNameEn": "Don Wai",
    "postcode": "30160",
    "districtCode": "3010",
    "districtNameTh": "โนนสูง",
    "districtNameEn": "Non Sung",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301016",
    "subDistrictNameTh": "ลำมูล",
    "subDistrictNameEn": "Lam Mun",
    "postcode": "30160",
    "districtCode": "3010",
    "districtNameTh": "โนนสูง",
    "districtNameEn": "Non Sung",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301101",
    "subDistrictNameTh": "ขามสะแกแสง",
    "subDistrictNameEn": "Kham Sakaesaeng",
    "postcode": "30290",
    "districtCode": "3011",
    "districtNameTh": "ขามสะแกแสง",
    "districtNameEn": "Kham Sakaesaeng",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301102",
    "subDistrictNameTh": "โนนเมือง",
    "subDistrictNameEn": "Non Mueang",
    "postcode": "30290",
    "districtCode": "3011",
    "districtNameTh": "ขามสะแกแสง",
    "districtNameEn": "Kham Sakaesaeng",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301103",
    "subDistrictNameTh": "เมืองนาท",
    "subDistrictNameEn": "Mueang Nat",
    "postcode": "30290",
    "districtCode": "3011",
    "districtNameTh": "ขามสะแกแสง",
    "districtNameEn": "Kham Sakaesaeng",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301104",
    "subDistrictNameTh": "ชีวึก",
    "subDistrictNameEn": "Chiwuek",
    "postcode": "30290",
    "districtCode": "3011",
    "districtNameTh": "ขามสะแกแสง",
    "districtNameEn": "Kham Sakaesaeng",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301105",
    "subDistrictNameTh": "พะงาด",
    "subDistrictNameEn": "Pha-ngat",
    "postcode": "30290",
    "districtCode": "3011",
    "districtNameTh": "ขามสะแกแสง",
    "districtNameEn": "Kham Sakaesaeng",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301106",
    "subDistrictNameTh": "หนองหัวฟาน",
    "subDistrictNameEn": "Nong Hua Fan",
    "postcode": "30290",
    "districtCode": "3011",
    "districtNameTh": "ขามสะแกแสง",
    "districtNameEn": "Kham Sakaesaeng",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301107",
    "subDistrictNameTh": "เมืองเกษตร",
    "subDistrictNameEn": "Mueang Kaset",
    "postcode": "30290",
    "districtCode": "3011",
    "districtNameTh": "ขามสะแกแสง",
    "districtNameEn": "Kham Sakaesaeng",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301201",
    "subDistrictNameTh": "บัวใหญ่",
    "subDistrictNameEn": "Bua Yai",
    "postcode": "30120",
    "districtCode": "3012",
    "districtNameTh": "บัวใหญ่",
    "districtNameEn": "Bua Yai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301203",
    "subDistrictNameTh": "ห้วยยาง",
    "subDistrictNameEn": "Huai Yang",
    "postcode": "30120",
    "districtCode": "3012",
    "districtNameTh": "บัวใหญ่",
    "districtNameEn": "Bua Yai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301204",
    "subDistrictNameTh": "เสมาใหญ่",
    "subDistrictNameEn": "Sema Yai",
    "postcode": "30120",
    "districtCode": "3012",
    "districtNameTh": "บัวใหญ่",
    "districtNameEn": "Bua Yai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301206",
    "subDistrictNameTh": "ดอนตะหนิน",
    "subDistrictNameEn": "Don Tanin",
    "postcode": "30120",
    "districtCode": "3012",
    "districtNameTh": "บัวใหญ่",
    "districtNameEn": "Bua Yai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301207",
    "subDistrictNameTh": "หนองบัวสะอาด",
    "subDistrictNameEn": "Nong Bua Sa-at",
    "postcode": "30120",
    "districtCode": "3012",
    "districtNameTh": "บัวใหญ่",
    "districtNameEn": "Bua Yai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301208",
    "subDistrictNameTh": "โนนทองหลาง",
    "subDistrictNameEn": "Non Thonglang",
    "postcode": "30120",
    "districtCode": "3012",
    "districtNameTh": "บัวใหญ่",
    "districtNameEn": "Bua Yai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301214",
    "subDistrictNameTh": "กุดจอก",
    "subDistrictNameEn": "Kut Chok",
    "postcode": "30120",
    "districtCode": "3012",
    "districtNameTh": "บัวใหญ่",
    "districtNameEn": "Bua Yai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301215",
    "subDistrictNameTh": "ด่านช้าง",
    "subDistrictNameEn": "Dan Chang",
    "postcode": "30120",
    "districtCode": "3012",
    "districtNameTh": "บัวใหญ่",
    "districtNameEn": "Bua Yai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301220",
    "subDistrictNameTh": "ขุนทอง",
    "subDistrictNameEn": "Khun Thong",
    "postcode": "30120",
    "districtCode": "3012",
    "districtNameTh": "บัวใหญ่",
    "districtNameEn": "Bua Yai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301224",
    "subDistrictNameTh": "หนองแจ้งใหญ่",
    "subDistrictNameEn": "Nong Chaeng Yai",
    "postcode": "30120",
    "districtCode": "3012",
    "districtNameTh": "บัวใหญ่",
    "districtNameEn": "Bua Yai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301301",
    "subDistrictNameTh": "ประทาย",
    "subDistrictNameEn": "Prathai",
    "postcode": "30180",
    "districtCode": "3013",
    "districtNameTh": "ประทาย",
    "districtNameEn": "Prathai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301303",
    "subDistrictNameTh": "กระทุ่มราย",
    "subDistrictNameEn": "Krathum Rai",
    "postcode": "30180",
    "districtCode": "3013",
    "districtNameTh": "ประทาย",
    "districtNameEn": "Prathai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301304",
    "subDistrictNameTh": "วังไม้แดง",
    "subDistrictNameEn": "Wang Mai Daeng",
    "postcode": "30180",
    "districtCode": "3013",
    "districtNameTh": "ประทาย",
    "districtNameEn": "Prathai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301306",
    "subDistrictNameTh": "ตลาดไทร",
    "subDistrictNameEn": "Talat Sai",
    "postcode": "30180",
    "districtCode": "3013",
    "districtNameTh": "ประทาย",
    "districtNameEn": "Prathai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301307",
    "subDistrictNameTh": "หนองพลวง",
    "subDistrictNameEn": "Nong Phluang",
    "postcode": "30180",
    "districtCode": "3013",
    "districtNameTh": "ประทาย",
    "districtNameEn": "Prathai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301308",
    "subDistrictNameTh": "หนองค่าย",
    "subDistrictNameEn": "Nong Khai",
    "postcode": "30180",
    "districtCode": "3013",
    "districtNameTh": "ประทาย",
    "districtNameEn": "Prathai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301309",
    "subDistrictNameTh": "หันห้วยทราย",
    "subDistrictNameEn": "Han Huai Sai",
    "postcode": "30180",
    "districtCode": "3013",
    "districtNameTh": "ประทาย",
    "districtNameEn": "Prathai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301310",
    "subDistrictNameTh": "ดอนมัน",
    "subDistrictNameEn": "Don Man",
    "postcode": "30180",
    "districtCode": "3013",
    "districtNameTh": "ประทาย",
    "districtNameEn": "Prathai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301313",
    "subDistrictNameTh": "นางรำ",
    "subDistrictNameEn": "Nang Ram",
    "postcode": "30180",
    "districtCode": "3013",
    "districtNameTh": "ประทาย",
    "districtNameEn": "Prathai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301314",
    "subDistrictNameTh": "โนนเพ็ด",
    "subDistrictNameEn": "Non Phet",
    "postcode": "30180",
    "districtCode": "3013",
    "districtNameTh": "ประทาย",
    "districtNameEn": "Prathai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301315",
    "subDistrictNameTh": "ทุ่งสว่าง",
    "subDistrictNameEn": "Thung Sawang",
    "postcode": "30180",
    "districtCode": "3013",
    "districtNameTh": "ประทาย",
    "districtNameEn": "Prathai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301317",
    "subDistrictNameTh": "โคกกลาง",
    "subDistrictNameEn": "Khok Klang",
    "postcode": "30180",
    "districtCode": "3013",
    "districtNameTh": "ประทาย",
    "districtNameEn": "Prathai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301318",
    "subDistrictNameTh": "เมืองโดน",
    "subDistrictNameEn": "Mueang Don",
    "postcode": "30180",
    "districtCode": "3013",
    "districtNameTh": "ประทาย",
    "districtNameEn": "Prathai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301401",
    "subDistrictNameTh": "เมืองปัก",
    "subDistrictNameEn": "Mueang Pak",
    "postcode": "30150",
    "districtCode": "3014",
    "districtNameTh": "ปักธงชัย",
    "districtNameEn": "Pak Thong Chai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301402",
    "subDistrictNameTh": "ตะคุ",
    "subDistrictNameEn": "Takhu",
    "postcode": "30150",
    "districtCode": "3014",
    "districtNameTh": "ปักธงชัย",
    "districtNameEn": "Pak Thong Chai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301403",
    "subDistrictNameTh": "โคกไทย",
    "subDistrictNameEn": "Khok Thai",
    "postcode": "30150",
    "districtCode": "3014",
    "districtNameTh": "ปักธงชัย",
    "districtNameEn": "Pak Thong Chai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301404",
    "subDistrictNameTh": "สำโรง",
    "subDistrictNameEn": "Samrong",
    "postcode": "30150",
    "districtCode": "3014",
    "districtNameTh": "ปักธงชัย",
    "districtNameEn": "Pak Thong Chai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301405",
    "subDistrictNameTh": "ตะขบ",
    "subDistrictNameEn": "Takhop",
    "postcode": "30150",
    "districtCode": "3014",
    "districtNameTh": "ปักธงชัย",
    "districtNameEn": "Pak Thong Chai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301406",
    "subDistrictNameTh": "นกออก",
    "subDistrictNameEn": "Nok Ok",
    "postcode": "30150",
    "districtCode": "3014",
    "districtNameTh": "ปักธงชัย",
    "districtNameEn": "Pak Thong Chai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301407",
    "subDistrictNameTh": "ดอน",
    "subDistrictNameEn": "Don",
    "postcode": "30150",
    "districtCode": "3014",
    "districtNameTh": "ปักธงชัย",
    "districtNameEn": "Pak Thong Chai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301409",
    "subDistrictNameTh": "ตูม",
    "subDistrictNameEn": "Tum",
    "postcode": "30150",
    "districtCode": "3014",
    "districtNameTh": "ปักธงชัย",
    "districtNameEn": "Pak Thong Chai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301410",
    "subDistrictNameTh": "งิ้ว",
    "subDistrictNameEn": "Ngio",
    "postcode": "30150",
    "districtCode": "3014",
    "districtNameTh": "ปักธงชัย",
    "districtNameEn": "Pak Thong Chai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301411",
    "subDistrictNameTh": "สะแกราช",
    "subDistrictNameEn": "Sakae Rat",
    "postcode": "30150",
    "districtCode": "3014",
    "districtNameTh": "ปักธงชัย",
    "districtNameEn": "Pak Thong Chai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301412",
    "subDistrictNameTh": "ลำนางแก้ว",
    "subDistrictNameEn": "Lam Nang Kaeo",
    "postcode": "30150",
    "districtCode": "3014",
    "districtNameTh": "ปักธงชัย",
    "districtNameEn": "Pak Thong Chai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301416",
    "subDistrictNameTh": "ภูหลวง",
    "subDistrictNameEn": "Phu Luang",
    "postcode": "30150",
    "districtCode": "3014",
    "districtNameTh": "ปักธงชัย",
    "districtNameEn": "Pak Thong Chai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301417",
    "subDistrictNameTh": "ธงชัยเหนือ",
    "subDistrictNameEn": "Thong Chai Nuea",
    "postcode": "30150",
    "districtCode": "3014",
    "districtNameTh": "ปักธงชัย",
    "districtNameEn": "Pak Thong Chai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301418",
    "subDistrictNameTh": "สุขเกษม",
    "subDistrictNameEn": "Suk Kasem",
    "postcode": "30150",
    "districtCode": "3014",
    "districtNameTh": "ปักธงชัย",
    "districtNameEn": "Pak Thong Chai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301419",
    "subDistrictNameTh": "เกษมทรัพย์",
    "subDistrictNameEn": "Kasem Sap",
    "postcode": "30150",
    "districtCode": "3014",
    "districtNameTh": "ปักธงชัย",
    "districtNameEn": "Pak Thong Chai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301420",
    "subDistrictNameTh": "บ่อปลาทอง",
    "subDistrictNameEn": "Bo Pla Thong",
    "postcode": "30150",
    "districtCode": "3014",
    "districtNameTh": "ปักธงชัย",
    "districtNameEn": "Pak Thong Chai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301501",
    "subDistrictNameTh": "ในเมือง",
    "subDistrictNameEn": "Nai Mueang",
    "postcode": "30110",
    "districtCode": "3015",
    "districtNameTh": "พิมาย",
    "districtNameEn": "Phimai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301502",
    "subDistrictNameTh": "สัมฤทธิ์",
    "subDistrictNameEn": "Samrit",
    "postcode": "30110",
    "districtCode": "3015",
    "districtNameTh": "พิมาย",
    "districtNameEn": "Phimai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301503",
    "subDistrictNameTh": "โบสถ์",
    "subDistrictNameEn": "Bot",
    "postcode": "30110",
    "districtCode": "3015",
    "districtNameTh": "พิมาย",
    "districtNameEn": "Phimai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301504",
    "subDistrictNameTh": "กระเบื้องใหญ่",
    "subDistrictNameEn": "Krabueang Yai",
    "postcode": "30110",
    "districtCode": "3015",
    "districtNameTh": "พิมาย",
    "districtNameEn": "Phimai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301505",
    "subDistrictNameTh": "ท่าหลวง",
    "subDistrictNameEn": "Tha Luang",
    "postcode": "30110",
    "districtCode": "3015",
    "districtNameTh": "พิมาย",
    "districtNameEn": "Phimai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301506",
    "subDistrictNameTh": "รังกาใหญ่",
    "subDistrictNameEn": "Rang Ka Yai",
    "postcode": "30110",
    "districtCode": "3015",
    "districtNameTh": "พิมาย",
    "districtNameEn": "Phimai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301507",
    "subDistrictNameTh": "ชีวาน",
    "subDistrictNameEn": "Chiwan",
    "postcode": "30110",
    "districtCode": "3015",
    "districtNameTh": "พิมาย",
    "districtNameEn": "Phimai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301508",
    "subDistrictNameTh": "นิคมสร้างตนเอง",
    "subDistrictNameEn": "Nikhom Sang Ton-eng Phimai",
    "postcode": "30110",
    "districtCode": "3015",
    "districtNameTh": "พิมาย",
    "districtNameEn": "Phimai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301509",
    "subDistrictNameTh": "กระชอน",
    "subDistrictNameEn": "Krachon",
    "postcode": "30110",
    "districtCode": "3015",
    "districtNameTh": "พิมาย",
    "districtNameEn": "Phimai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301510",
    "subDistrictNameTh": "ดงใหญ่",
    "subDistrictNameEn": "Dong Yai",
    "postcode": "30110",
    "districtCode": "3015",
    "districtNameTh": "พิมาย",
    "districtNameEn": "Phimai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301511",
    "subDistrictNameTh": "ธารละหลอด",
    "subDistrictNameEn": "Than Lalot",
    "postcode": "30110",
    "districtCode": "3015",
    "districtNameTh": "พิมาย",
    "districtNameEn": "Phimai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301512",
    "subDistrictNameTh": "หนองระเวียง",
    "subDistrictNameEn": "Nong Rawiang",
    "postcode": "30110",
    "districtCode": "3015",
    "districtNameTh": "พิมาย",
    "districtNameEn": "Phimai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301601",
    "subDistrictNameTh": "ห้วยแถลง",
    "subDistrictNameEn": "Huai Thalaeng",
    "postcode": "30240",
    "districtCode": "3016",
    "districtNameTh": "ห้วยแถลง",
    "districtNameEn": "Huai Thalaeng",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301602",
    "subDistrictNameTh": "ทับสวาย",
    "subDistrictNameEn": "Thap Sawai",
    "postcode": "30240",
    "districtCode": "3016",
    "districtNameTh": "ห้วยแถลง",
    "districtNameEn": "Huai Thalaeng",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301603",
    "subDistrictNameTh": "เมืองพลับพลา",
    "subDistrictNameEn": "Mueang Phlapphla",
    "postcode": "30240",
    "districtCode": "3016",
    "districtNameTh": "ห้วยแถลง",
    "districtNameEn": "Huai Thalaeng",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301604",
    "subDistrictNameTh": "หลุ่งตะเคียน",
    "subDistrictNameEn": "Lung Takhian",
    "postcode": "30240",
    "districtCode": "3016",
    "districtNameTh": "ห้วยแถลง",
    "districtNameEn": "Huai Thalaeng",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301605",
    "subDistrictNameTh": "หินดาด",
    "subDistrictNameEn": "Hin Dat",
    "postcode": "30240",
    "districtCode": "3016",
    "districtNameTh": "ห้วยแถลง",
    "districtNameEn": "Huai Thalaeng",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301606",
    "subDistrictNameTh": "งิ้ว",
    "subDistrictNameEn": "Ngio",
    "postcode": "30240",
    "districtCode": "3016",
    "districtNameTh": "ห้วยแถลง",
    "districtNameEn": "Huai Thalaeng",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301607",
    "subDistrictNameTh": "กงรถ",
    "subDistrictNameEn": "Kong Rot",
    "postcode": "30240",
    "districtCode": "3016",
    "districtNameTh": "ห้วยแถลง",
    "districtNameEn": "Huai Thalaeng",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301608",
    "subDistrictNameTh": "หลุ่งประดู่",
    "subDistrictNameEn": "Lung Pradu",
    "postcode": "30240",
    "districtCode": "3016",
    "districtNameTh": "ห้วยแถลง",
    "districtNameEn": "Huai Thalaeng",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301609",
    "subDistrictNameTh": "ตะโก",
    "subDistrictNameEn": "Tako",
    "postcode": "30240",
    "districtCode": "3016",
    "districtNameTh": "ห้วยแถลง",
    "districtNameEn": "Huai Thalaeng",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301610",
    "subDistrictNameTh": "ห้วยแคน",
    "subDistrictNameEn": "Huai Khaen",
    "postcode": "30240",
    "districtCode": "3016",
    "districtNameTh": "ห้วยแถลง",
    "districtNameEn": "Huai Thalaeng",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301701",
    "subDistrictNameTh": "ชุมพวง",
    "subDistrictNameEn": "Chum Phuang",
    "postcode": "30270",
    "districtCode": "3017",
    "districtNameTh": "ชุมพวง",
    "districtNameEn": "Chum Phuang",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301702",
    "subDistrictNameTh": "ประสุข",
    "subDistrictNameEn": "Prasuk",
    "postcode": "30270",
    "districtCode": "3017",
    "districtNameTh": "ชุมพวง",
    "districtNameEn": "Chum Phuang",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301703",
    "subDistrictNameTh": "ท่าลาด",
    "subDistrictNameEn": "Tha Lat",
    "postcode": "30270",
    "districtCode": "3017",
    "districtNameTh": "ชุมพวง",
    "districtNameEn": "Chum Phuang",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301704",
    "subDistrictNameTh": "สาหร่าย",
    "subDistrictNameEn": "Sarai",
    "postcode": "30270",
    "districtCode": "3017",
    "districtNameTh": "ชุมพวง",
    "districtNameEn": "Chum Phuang",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301705",
    "subDistrictNameTh": "ตลาดไทร",
    "subDistrictNameEn": "Talat Sai",
    "postcode": "30270",
    "districtCode": "3017",
    "districtNameTh": "ชุมพวง",
    "districtNameEn": "Chum Phuang",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301710",
    "subDistrictNameTh": "โนนรัง",
    "subDistrictNameEn": "Non Rang",
    "postcode": "30270",
    "districtCode": "3017",
    "districtNameTh": "ชุมพวง",
    "districtNameEn": "Chum Phuang",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301714",
    "subDistrictNameTh": "หนองหลัก",
    "subDistrictNameEn": "Nong Lak",
    "postcode": "30270",
    "districtCode": "3017",
    "districtNameTh": "ชุมพวง",
    "districtNameEn": "Chum Phuang",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301716",
    "subDistrictNameTh": "โนนตูม",
    "subDistrictNameEn": "Non Tum",
    "postcode": "30270",
    "districtCode": "3017",
    "districtNameTh": "ชุมพวง",
    "districtNameEn": "Chum Phuang",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301717",
    "subDistrictNameTh": "โนนยอ",
    "subDistrictNameEn": "Non Yo",
    "postcode": "30270",
    "districtCode": "3017",
    "districtNameTh": "ชุมพวง",
    "districtNameEn": "Chum Phuang",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301801",
    "subDistrictNameTh": "สูงเนิน",
    "subDistrictNameEn": "Sung Noen",
    "postcode": "30170",
    "districtCode": "3018",
    "districtNameTh": "สูงเนิน",
    "districtNameEn": "Sung Noen",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301802",
    "subDistrictNameTh": "เสมา",
    "subDistrictNameEn": "Sema",
    "postcode": "30170",
    "districtCode": "3018",
    "districtNameTh": "สูงเนิน",
    "districtNameEn": "Sung Noen",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301803",
    "subDistrictNameTh": "โคราช",
    "subDistrictNameEn": "Khorat",
    "postcode": "30170",
    "districtCode": "3018",
    "districtNameTh": "สูงเนิน",
    "districtNameEn": "Sung Noen",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301804",
    "subDistrictNameTh": "บุ่งขี้เหล็ก",
    "subDistrictNameEn": "Bung Khilek",
    "postcode": "30170",
    "districtCode": "3018",
    "districtNameTh": "สูงเนิน",
    "districtNameEn": "Sung Noen",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301805",
    "subDistrictNameTh": "โนนค่า",
    "subDistrictNameEn": "Non Kha",
    "postcode": "30170",
    "districtCode": "3018",
    "districtNameTh": "สูงเนิน",
    "districtNameEn": "Sung Noen",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301806",
    "subDistrictNameTh": "โค้งยาง",
    "subDistrictNameEn": "Khong Yang",
    "postcode": "30170",
    "districtCode": "3018",
    "districtNameTh": "สูงเนิน",
    "districtNameEn": "Sung Noen",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301807",
    "subDistrictNameTh": "มะเกลือเก่า",
    "subDistrictNameEn": "Makluea Kao",
    "postcode": "30170",
    "districtCode": "3018",
    "districtNameTh": "สูงเนิน",
    "districtNameEn": "Sung Noen",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301808",
    "subDistrictNameTh": "มะเกลือใหม่",
    "subDistrictNameEn": "Makluea Mai",
    "postcode": "30170",
    "districtCode": "3018",
    "districtNameTh": "สูงเนิน",
    "districtNameEn": "Sung Noen",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301809",
    "subDistrictNameTh": "นากลาง",
    "subDistrictNameEn": "Na Klang",
    "postcode": "30380",
    "districtCode": "3018",
    "districtNameTh": "สูงเนิน",
    "districtNameEn": "Sung Noen",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301810",
    "subDistrictNameTh": "หนองตะไก้",
    "subDistrictNameEn": "Nong Takai",
    "postcode": "30380",
    "districtCode": "3018",
    "districtNameTh": "สูงเนิน",
    "districtNameEn": "Sung Noen",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301811",
    "subDistrictNameTh": "กุดจิก",
    "subDistrictNameEn": "Kut Chik",
    "postcode": "30380",
    "districtCode": "3018",
    "districtNameTh": "สูงเนิน",
    "districtNameEn": "Sung Noen",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301901",
    "subDistrictNameTh": "ขามทะเลสอ",
    "subDistrictNameEn": "Kham Thale So",
    "postcode": "30280",
    "districtCode": "3019",
    "districtNameTh": "ขามทะเลสอ",
    "districtNameEn": "Kham Thale So",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301902",
    "subDistrictNameTh": "โป่งแดง",
    "subDistrictNameEn": "Pong Daeng",
    "postcode": "30280",
    "districtCode": "3019",
    "districtNameTh": "ขามทะเลสอ",
    "districtNameEn": "Kham Thale So",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301903",
    "subDistrictNameTh": "พันดุง",
    "subDistrictNameEn": "Phan Dung",
    "postcode": "30280",
    "districtCode": "3019",
    "districtNameTh": "ขามทะเลสอ",
    "districtNameEn": "Kham Thale So",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301904",
    "subDistrictNameTh": "หนองสรวง",
    "subDistrictNameEn": "Nong Suang",
    "postcode": "30280",
    "districtCode": "3019",
    "districtNameTh": "ขามทะเลสอ",
    "districtNameEn": "Kham Thale So",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "301905",
    "subDistrictNameTh": "บึงอ้อ",
    "subDistrictNameEn": "Bueng O",
    "postcode": "30280",
    "districtCode": "3019",
    "districtNameTh": "ขามทะเลสอ",
    "districtNameEn": "Kham Thale So",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302001",
    "subDistrictNameTh": "สีคิ้ว",
    "subDistrictNameEn": "Sikhio",
    "postcode": "30140",
    "districtCode": "3020",
    "districtNameTh": "สีคิ้ว",
    "districtNameEn": "Sikhio",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302002",
    "subDistrictNameTh": "บ้านหัน",
    "subDistrictNameEn": "Ban Han",
    "postcode": "30140",
    "districtCode": "3020",
    "districtNameTh": "สีคิ้ว",
    "districtNameEn": "Sikhio",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302003",
    "subDistrictNameTh": "กฤษณา",
    "subDistrictNameEn": "Kritsana",
    "postcode": "30140",
    "districtCode": "3020",
    "districtNameTh": "สีคิ้ว",
    "districtNameEn": "Sikhio",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302004",
    "subDistrictNameTh": "ลาดบัวขาว",
    "subDistrictNameEn": "Lat Bua Khao",
    "postcode": "30340",
    "districtCode": "3020",
    "districtNameTh": "สีคิ้ว",
    "districtNameEn": "Sikhio",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302005",
    "subDistrictNameTh": "หนองหญ้าขาว",
    "subDistrictNameEn": "Nong Ya Khao",
    "postcode": "30140",
    "districtCode": "3020",
    "districtNameTh": "สีคิ้ว",
    "districtNameEn": "Sikhio",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302006",
    "subDistrictNameTh": "กุดน้อย",
    "subDistrictNameEn": "Kut Noi",
    "postcode": "30140",
    "districtCode": "3020",
    "districtNameTh": "สีคิ้ว",
    "districtNameEn": "Sikhio",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302007",
    "subDistrictNameTh": "หนองน้ำใส",
    "subDistrictNameEn": "Nong Nam Sai",
    "postcode": "30140",
    "districtCode": "3020",
    "districtNameTh": "สีคิ้ว",
    "districtNameEn": "Sikhio",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302008",
    "subDistrictNameTh": "วังโรงใหญ่",
    "subDistrictNameEn": "Wang Rong Yai",
    "postcode": "30140",
    "districtCode": "3020",
    "districtNameTh": "สีคิ้ว",
    "districtNameEn": "Sikhio",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302009",
    "subDistrictNameTh": "มิตรภาพ",
    "subDistrictNameEn": "Mittraphap",
    "postcode": "30140",
    "districtCode": "3020",
    "districtNameTh": "สีคิ้ว",
    "districtNameEn": "Sikhio",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302010",
    "subDistrictNameTh": "คลองไผ่",
    "subDistrictNameEn": "Khlong Phai",
    "postcode": "30340",
    "districtCode": "3020",
    "districtNameTh": "สีคิ้ว",
    "districtNameEn": "Sikhio",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302011",
    "subDistrictNameTh": "ดอนเมือง",
    "subDistrictNameEn": "Don Mueang",
    "postcode": "30140",
    "districtCode": "3020",
    "districtNameTh": "สีคิ้ว",
    "districtNameEn": "Sikhio",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302012",
    "subDistrictNameTh": "หนองบัวน้อย",
    "subDistrictNameEn": "Nong Bua Noi",
    "postcode": "30140",
    "districtCode": "3020",
    "districtNameTh": "สีคิ้ว",
    "districtNameEn": "Sikhio",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302101",
    "subDistrictNameTh": "ปากช่อง",
    "subDistrictNameEn": "Pak Chong",
    "postcode": "30130",
    "districtCode": "3021",
    "districtNameTh": "ปากช่อง",
    "districtNameEn": "Pak Chong",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302102",
    "subDistrictNameTh": "กลางดง",
    "subDistrictNameEn": "Klang Dong",
    "postcode": "30320",
    "districtCode": "3021",
    "districtNameTh": "ปากช่อง",
    "districtNameEn": "Pak Chong",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302103",
    "subDistrictNameTh": "จันทึก",
    "subDistrictNameEn": "Chanthuek",
    "postcode": "30130",
    "districtCode": "3021",
    "districtNameTh": "ปากช่อง",
    "districtNameEn": "Pak Chong",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302104",
    "subDistrictNameTh": "วังกะทะ",
    "subDistrictNameEn": "Wang Katha",
    "postcode": "30130",
    "districtCode": "3021",
    "districtNameTh": "ปากช่อง",
    "districtNameEn": "Pak Chong",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302105",
    "subDistrictNameTh": "หมูสี",
    "subDistrictNameEn": "Mu Si",
    "postcode": "30130",
    "districtCode": "3021",
    "districtNameTh": "ปากช่อง",
    "districtNameEn": "Pak Chong",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302106",
    "subDistrictNameTh": "หนองสาหร่าย",
    "subDistrictNameEn": "Nong Sarai",
    "postcode": "30130",
    "districtCode": "3021",
    "districtNameTh": "ปากช่อง",
    "districtNameEn": "Pak Chong",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302107",
    "subDistrictNameTh": "ขนงพระ",
    "subDistrictNameEn": "Khanong Phra",
    "postcode": "30130",
    "districtCode": "3021",
    "districtNameTh": "ปากช่อง",
    "districtNameEn": "Pak Chong",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302108",
    "subDistrictNameTh": "โป่งตาลอง",
    "subDistrictNameEn": "Pong Talong",
    "postcode": "30130",
    "districtCode": "3021",
    "districtNameTh": "ปากช่อง",
    "districtNameEn": "Pak Chong",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302109",
    "subDistrictNameTh": "คลองม่วง",
    "subDistrictNameEn": "Khlong Muang",
    "postcode": "30130",
    "districtCode": "3021",
    "districtNameTh": "ปากช่อง",
    "districtNameEn": "Pak Chong",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302110",
    "subDistrictNameTh": "หนองน้ำแดง",
    "subDistrictNameEn": "Nong Nam Daeng",
    "postcode": "30130",
    "districtCode": "3021",
    "districtNameTh": "ปากช่อง",
    "districtNameEn": "Pak Chong",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302111",
    "subDistrictNameTh": "วังไทร",
    "subDistrictNameEn": "Wang Sai",
    "postcode": "30130",
    "districtCode": "3021",
    "districtNameTh": "ปากช่อง",
    "districtNameEn": "Pak Chong",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302112",
    "subDistrictNameTh": "พญาเย็น",
    "subDistrictNameEn": "Phaya Yen",
    "postcode": "30320",
    "districtCode": "3021",
    "districtNameTh": "ปากช่อง",
    "districtNameEn": "Pak Chong",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302201",
    "subDistrictNameTh": "หนองบุนนาก",
    "subDistrictNameEn": "Nong Bunnak",
    "postcode": "30410",
    "districtCode": "3022",
    "districtNameTh": "หนองบุญมาก",
    "districtNameEn": "Nong Bunmak",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302202",
    "subDistrictNameTh": "สารภี",
    "subDistrictNameEn": "Saraphi",
    "postcode": "30410",
    "districtCode": "3022",
    "districtNameTh": "หนองบุญมาก",
    "districtNameEn": "Nong Bunmak",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302203",
    "subDistrictNameTh": "ไทยเจริญ",
    "subDistrictNameEn": "Thai Charoen",
    "postcode": "30410",
    "districtCode": "3022",
    "districtNameTh": "หนองบุญมาก",
    "districtNameEn": "Nong Bunmak",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302204",
    "subDistrictNameTh": "หนองหัวแรต",
    "subDistrictNameEn": "Nong Hua Raet",
    "postcode": "30410",
    "districtCode": "3022",
    "districtNameTh": "หนองบุญมาก",
    "districtNameEn": "Nong Bunmak",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302205",
    "subDistrictNameTh": "แหลมทอง",
    "subDistrictNameEn": "Laem Thong",
    "postcode": "30410",
    "districtCode": "3022",
    "districtNameTh": "หนองบุญมาก",
    "districtNameEn": "Nong Bunmak",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302206",
    "subDistrictNameTh": "หนองตะไก้",
    "subDistrictNameEn": "Nong Takai",
    "postcode": "30410",
    "districtCode": "3022",
    "districtNameTh": "หนองบุญมาก",
    "districtNameEn": "Nong Bunmak",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302207",
    "subDistrictNameTh": "ลุงเขว้า",
    "subDistrictNameEn": "Lung Khwao",
    "postcode": "30410",
    "districtCode": "3022",
    "districtNameTh": "หนองบุญมาก",
    "districtNameEn": "Nong Bunmak",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302208",
    "subDistrictNameTh": "หนองไม้ไผ่",
    "subDistrictNameEn": "Nong Mai Phai",
    "postcode": "30410",
    "districtCode": "3022",
    "districtNameTh": "หนองบุญมาก",
    "districtNameEn": "Nong Bunmak",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302209",
    "subDistrictNameTh": "บ้านใหม่",
    "subDistrictNameEn": "Ban Mai",
    "postcode": "30410",
    "districtCode": "3022",
    "districtNameTh": "หนองบุญมาก",
    "districtNameEn": "Nong Bunmak",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302301",
    "subDistrictNameTh": "แก้งสนามนาง",
    "subDistrictNameEn": "Kaeng Sanam Nang",
    "postcode": "30440",
    "districtCode": "3023",
    "districtNameTh": "แก้งสนามนาง",
    "districtNameEn": "Kaeng Sanam Nang",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302302",
    "subDistrictNameTh": "โนนสำราญ",
    "subDistrictNameEn": "Non Samran",
    "postcode": "30440",
    "districtCode": "3023",
    "districtNameTh": "แก้งสนามนาง",
    "districtNameEn": "Kaeng Sanam Nang",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302303",
    "subDistrictNameTh": "บึงพะไล",
    "subDistrictNameEn": "Bueng Phalai",
    "postcode": "30440",
    "districtCode": "3023",
    "districtNameTh": "แก้งสนามนาง",
    "districtNameEn": "Kaeng Sanam Nang",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302304",
    "subDistrictNameTh": "สีสุก",
    "subDistrictNameEn": "Si Suk",
    "postcode": "30440",
    "districtCode": "3023",
    "districtNameTh": "แก้งสนามนาง",
    "districtNameEn": "Kaeng Sanam Nang",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302305",
    "subDistrictNameTh": "บึงสำโรง",
    "subDistrictNameEn": "Bueng Samrong",
    "postcode": "30440",
    "districtCode": "3023",
    "districtNameTh": "แก้งสนามนาง",
    "districtNameEn": "Kaeng Sanam Nang",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302401",
    "subDistrictNameTh": "โนนแดง",
    "subDistrictNameEn": "Non Daeng",
    "postcode": "30360",
    "districtCode": "3024",
    "districtNameTh": "โนนแดง",
    "districtNameEn": "Non Daeng",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302402",
    "subDistrictNameTh": "โนนตาเถร",
    "subDistrictNameEn": "Non Ta Then",
    "postcode": "30360",
    "districtCode": "3024",
    "districtNameTh": "โนนแดง",
    "districtNameEn": "Non Daeng",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302403",
    "subDistrictNameTh": "สำพะเนียง",
    "subDistrictNameEn": "Lam Phaniang",
    "postcode": "30360",
    "districtCode": "3024",
    "districtNameTh": "โนนแดง",
    "districtNameEn": "Non Daeng",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302404",
    "subDistrictNameTh": "วังหิน",
    "subDistrictNameEn": "Wang Hin",
    "postcode": "30360",
    "districtCode": "3024",
    "districtNameTh": "โนนแดง",
    "districtNameEn": "Non Daeng",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302405",
    "subDistrictNameTh": "ดอนยาวใหญ่",
    "subDistrictNameEn": "Don Yao Yai",
    "postcode": "30360",
    "districtCode": "3024",
    "districtNameTh": "โนนแดง",
    "districtNameEn": "Non Daeng",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302501",
    "subDistrictNameTh": "วังน้ำเขียว",
    "subDistrictNameEn": "Wang Nam Khiao",
    "postcode": "30370",
    "districtCode": "3025",
    "districtNameTh": "วังน้ำเขียว",
    "districtNameEn": "Wang Nam Khiao",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302502",
    "subDistrictNameTh": "วังหมี",
    "subDistrictNameEn": "Wang Mi",
    "postcode": "30370",
    "districtCode": "3025",
    "districtNameTh": "วังน้ำเขียว",
    "districtNameEn": "Wang Nam Khiao",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302503",
    "subDistrictNameTh": "ระเริง",
    "subDistrictNameEn": "Raroeng",
    "postcode": "30150",
    "districtCode": "3025",
    "districtNameTh": "วังน้ำเขียว",
    "districtNameEn": "Wang Nam Khiao",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302504",
    "subDistrictNameTh": "อุดมทรัพย์",
    "subDistrictNameEn": "Udom Sap",
    "postcode": "30370",
    "districtCode": "3025",
    "districtNameTh": "วังน้ำเขียว",
    "districtNameEn": "Wang Nam Khiao",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302505",
    "subDistrictNameTh": "ไทยสามัคคี",
    "subDistrictNameEn": "Thai Samakkhi",
    "postcode": "30370",
    "districtCode": "3025",
    "districtNameTh": "วังน้ำเขียว",
    "districtNameEn": "Wang Nam Khiao",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302601",
    "subDistrictNameTh": "สำนักตะคร้อ",
    "subDistrictNameEn": "Samnak Takhro",
    "postcode": "30210",
    "districtCode": "3026",
    "districtNameTh": "เทพารักษ์",
    "districtNameEn": "Thepharak",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302602",
    "subDistrictNameTh": "หนองแวง",
    "subDistrictNameEn": "Nong Waeng",
    "postcode": "30210",
    "districtCode": "3026",
    "districtNameTh": "เทพารักษ์",
    "districtNameEn": "Thepharak",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302603",
    "subDistrictNameTh": "บึงปรือ",
    "subDistrictNameEn": "Bueng Prue",
    "postcode": "30210",
    "districtCode": "3026",
    "districtNameTh": "เทพารักษ์",
    "districtNameEn": "Thepharak",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302604",
    "subDistrictNameTh": "วังยายทอง",
    "subDistrictNameEn": "Wang Yai Thong",
    "postcode": "30210",
    "districtCode": "3026",
    "districtNameTh": "เทพารักษ์",
    "districtNameEn": "Thepharak",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302701",
    "subDistrictNameTh": "เมืองยาง",
    "subDistrictNameEn": "Mueang Yang",
    "postcode": "30270",
    "districtCode": "3027",
    "districtNameTh": "เมืองยาง",
    "districtNameEn": "Mueang Yang",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302702",
    "subDistrictNameTh": "กระเบื้องนอก",
    "subDistrictNameEn": "Krabueang Nok",
    "postcode": "30270",
    "districtCode": "3027",
    "districtNameTh": "เมืองยาง",
    "districtNameEn": "Mueang Yang",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302703",
    "subDistrictNameTh": "ละหานปลาค้าว",
    "subDistrictNameEn": "Lahan Pla Khao",
    "postcode": "30270",
    "districtCode": "3027",
    "districtNameTh": "เมืองยาง",
    "districtNameEn": "Mueang Yang",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302704",
    "subDistrictNameTh": "โนนอุดม",
    "subDistrictNameEn": "Non Udom",
    "postcode": "30270",
    "districtCode": "3027",
    "districtNameTh": "เมืองยาง",
    "districtNameEn": "Mueang Yang",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302801",
    "subDistrictNameTh": "สระพระ",
    "subDistrictNameEn": "Sa Phra",
    "postcode": "30220",
    "districtCode": "3028",
    "districtNameTh": "พระทองคำ",
    "districtNameEn": "Phra Thong Kham",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302802",
    "subDistrictNameTh": "มาบกราด",
    "subDistrictNameEn": "Map Krat",
    "postcode": "30220",
    "districtCode": "3028",
    "districtNameTh": "พระทองคำ",
    "districtNameEn": "Phra Thong Kham",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302803",
    "subDistrictNameTh": "พังเทียม",
    "subDistrictNameEn": "Phang Thiam",
    "postcode": "30220",
    "districtCode": "3028",
    "districtNameTh": "พระทองคำ",
    "districtNameEn": "Phra Thong Kham",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302804",
    "subDistrictNameTh": "ทัพรั้ง",
    "subDistrictNameEn": "Thap Rang",
    "postcode": "30220",
    "districtCode": "3028",
    "districtNameTh": "พระทองคำ",
    "districtNameEn": "Phra Thong Kham",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302805",
    "subDistrictNameTh": "หนองหอย",
    "subDistrictNameEn": "Nong Hoi",
    "postcode": "30220",
    "districtCode": "3028",
    "districtNameTh": "พระทองคำ",
    "districtNameEn": "Phra Thong Kham",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302901",
    "subDistrictNameTh": "ขุย",
    "subDistrictNameEn": "Khui",
    "postcode": "30270",
    "districtCode": "3029",
    "districtNameTh": "ลำทะเมนชัย",
    "districtNameEn": "Lam Thamenchai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302902",
    "subDistrictNameTh": "บ้านยาง",
    "subDistrictNameEn": "Ban Yang",
    "postcode": "30270",
    "districtCode": "3029",
    "districtNameTh": "ลำทะเมนชัย",
    "districtNameEn": "Lam Thamenchai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302903",
    "subDistrictNameTh": "ช่องแมว",
    "subDistrictNameEn": "Chong Maeo",
    "postcode": "30270",
    "districtCode": "3029",
    "districtNameTh": "ลำทะเมนชัย",
    "districtNameEn": "Lam Thamenchai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "302904",
    "subDistrictNameTh": "ไพล",
    "subDistrictNameEn": "Phlai",
    "postcode": "30270",
    "districtCode": "3029",
    "districtNameTh": "ลำทะเมนชัย",
    "districtNameEn": "Lam Thamenchai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "303001",
    "subDistrictNameTh": "เมืองพะไล",
    "subDistrictNameEn": "Mueang Phalai",
    "postcode": "30120",
    "districtCode": "3030",
    "districtNameTh": "บัวลาย",
    "districtNameEn": "Bua Lai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "303002",
    "subDistrictNameTh": "โนนจาน",
    "subDistrictNameEn": "Non Chan",
    "postcode": "30120",
    "districtCode": "3030",
    "districtNameTh": "บัวลาย",
    "districtNameEn": "Bua Lai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "303003",
    "subDistrictNameTh": "บัวลาย",
    "subDistrictNameEn": "Bua Lai",
    "postcode": "30120",
    "districtCode": "3030",
    "districtNameTh": "บัวลาย",
    "districtNameEn": "Bua Lai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "303004",
    "subDistrictNameTh": "หนองหว้า",
    "subDistrictNameEn": "Nong Wa",
    "postcode": "30120",
    "districtCode": "3030",
    "districtNameTh": "บัวลาย",
    "districtNameEn": "Bua Lai",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "303101",
    "subDistrictNameTh": "สีดา",
    "subDistrictNameEn": "Sida",
    "postcode": "30430",
    "districtCode": "3031",
    "districtNameTh": "สีดา",
    "districtNameEn": "Sida",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "303102",
    "subDistrictNameTh": "โพนทอง",
    "subDistrictNameEn": "Phon Thong",
    "postcode": "30430",
    "districtCode": "3031",
    "districtNameTh": "สีดา",
    "districtNameEn": "Sida",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "303103",
    "subDistrictNameTh": "โนนประดู่",
    "subDistrictNameEn": "Non Pladu",
    "postcode": "30430",
    "districtCode": "3031",
    "districtNameTh": "สีดา",
    "districtNameEn": "Sida",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "303104",
    "subDistrictNameTh": "สามเมือง",
    "subDistrictNameEn": "Sam Mueang",
    "postcode": "30430",
    "districtCode": "3031",
    "districtNameTh": "สีดา",
    "districtNameEn": "Sida",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "303105",
    "subDistrictNameTh": "หนองตาดใหญ่",
    "subDistrictNameEn": "Nong Tat Yai",
    "postcode": "30430",
    "districtCode": "3031",
    "districtNameTh": "สีดา",
    "districtNameEn": "Sida",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "303201",
    "subDistrictNameTh": "ช้างทอง",
    "subDistrictNameEn": "Chang Thong",
    "postcode": "30230",
    "districtCode": "3032",
    "districtNameTh": "เฉลิมพระเกียรติ",
    "districtNameEn": "Chaloem Phra Kiat",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "303202",
    "subDistrictNameTh": "ท่าช้าง",
    "subDistrictNameEn": "Tha Chang",
    "postcode": "30230",
    "districtCode": "3032",
    "districtNameTh": "เฉลิมพระเกียรติ",
    "districtNameEn": "Chaloem Phra Kiat",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "303203",
    "subDistrictNameTh": "พระพุทธ",
    "subDistrictNameEn": "Phra Phut",
    "postcode": "30230",
    "districtCode": "3032",
    "districtNameTh": "เฉลิมพระเกียรติ",
    "districtNameEn": "Chaloem Phra Kiat",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "303204",
    "subDistrictNameTh": "หนองงูเหลือม",
    "subDistrictNameEn": "Nong Ngu Lueam",
    "postcode": "30000",
    "districtCode": "3032",
    "districtNameTh": "เฉลิมพระเกียรติ",
    "districtNameEn": "Chaloem Phra Kiat",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "303205",
    "subDistrictNameTh": "หนองยาง",
    "subDistrictNameEn": "Nong Yang",
    "postcode": "30230",
    "districtCode": "3032",
    "districtNameTh": "เฉลิมพระเกียรติ",
    "districtNameEn": "Chaloem Phra Kiat",
    "provinceCode": "30",
    "provinceNameTh": "นครราชสีมา",
    "provinceNameEn": "Nakhon Ratchasima"
},
{
    "subDistrictCode": "310101",
    "subDistrictNameTh": "ในเมือง",
    "subDistrictNameEn": "Nai Mueang",
    "postcode": "31000",
    "districtCode": "3101",
    "districtNameTh": "เมืองบุรีรัมย์",
    "districtNameEn": "Mueang Buri Ram",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310102",
    "subDistrictNameTh": "อิสาณ",
    "subDistrictNameEn": "Isan",
    "postcode": "31000",
    "districtCode": "3101",
    "districtNameTh": "เมืองบุรีรัมย์",
    "districtNameEn": "Mueang Buri Ram",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310103",
    "subDistrictNameTh": "เสม็ด",
    "subDistrictNameEn": "Samet",
    "postcode": "31000",
    "districtCode": "3101",
    "districtNameTh": "เมืองบุรีรัมย์",
    "districtNameEn": "Mueang Buri Ram",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310104",
    "subDistrictNameTh": "บ้านบัว",
    "subDistrictNameEn": "Ban Bua",
    "postcode": "31000",
    "districtCode": "3101",
    "districtNameTh": "เมืองบุรีรัมย์",
    "districtNameEn": "Mueang Buri Ram",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310105",
    "subDistrictNameTh": "สะแกโพรง",
    "subDistrictNameEn": "Sakae Phrong",
    "postcode": "31000",
    "districtCode": "3101",
    "districtNameTh": "เมืองบุรีรัมย์",
    "districtNameEn": "Mueang Buri Ram",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310106",
    "subDistrictNameTh": "สวายจีก",
    "subDistrictNameEn": "Sawai Chik",
    "postcode": "31000",
    "districtCode": "3101",
    "districtNameTh": "เมืองบุรีรัมย์",
    "districtNameEn": "Mueang Buri Ram",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310108",
    "subDistrictNameTh": "บ้านยาง",
    "subDistrictNameEn": "Ban Yang",
    "postcode": "31000",
    "districtCode": "3101",
    "districtNameTh": "เมืองบุรีรัมย์",
    "districtNameEn": "Mueang Buri Ram",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310112",
    "subDistrictNameTh": "พระครู",
    "subDistrictNameEn": "Phra Khru",
    "postcode": "31000",
    "districtCode": "3101",
    "districtNameTh": "เมืองบุรีรัมย์",
    "districtNameEn": "Mueang Buri Ram",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310113",
    "subDistrictNameTh": "ถลุงเหล็ก",
    "subDistrictNameEn": "Thalung Lek",
    "postcode": "31000",
    "districtCode": "3101",
    "districtNameTh": "เมืองบุรีรัมย์",
    "districtNameEn": "Mueang Buri Ram",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310114",
    "subDistrictNameTh": "หนองตาด",
    "subDistrictNameEn": "Nong Tat",
    "postcode": "31000",
    "districtCode": "3101",
    "districtNameTh": "เมืองบุรีรัมย์",
    "districtNameEn": "Mueang Buri Ram",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310117",
    "subDistrictNameTh": "ลุมปุ๊ก",
    "subDistrictNameEn": "Lumpuk",
    "postcode": "31000",
    "districtCode": "3101",
    "districtNameTh": "เมืองบุรีรัมย์",
    "districtNameEn": "Mueang Buri Ram",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310118",
    "subDistrictNameTh": "สองห้อง",
    "subDistrictNameEn": "Song Hong",
    "postcode": "31000",
    "districtCode": "3101",
    "districtNameTh": "เมืองบุรีรัมย์",
    "districtNameEn": "Mueang Buri Ram",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310119",
    "subDistrictNameTh": "บัวทอง",
    "subDistrictNameEn": "Bua Thong",
    "postcode": "31000",
    "districtCode": "3101",
    "districtNameTh": "เมืองบุรีรัมย์",
    "districtNameEn": "Mueang Buri Ram",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310120",
    "subDistrictNameTh": "ชุมเห็ด",
    "subDistrictNameEn": "Chum Het",
    "postcode": "31000",
    "districtCode": "3101",
    "districtNameTh": "เมืองบุรีรัมย์",
    "districtNameEn": "Mueang Buri Ram",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310122",
    "subDistrictNameTh": "หลักเขต",
    "subDistrictNameEn": "Lak Khet",
    "postcode": "31000",
    "districtCode": "3101",
    "districtNameTh": "เมืองบุรีรัมย์",
    "districtNameEn": "Mueang Buri Ram",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310125",
    "subDistrictNameTh": "สะแกซำ",
    "subDistrictNameEn": "Sakae Sam",
    "postcode": "31000",
    "districtCode": "3101",
    "districtNameTh": "เมืองบุรีรัมย์",
    "districtNameEn": "Mueang Buri Ram",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310126",
    "subDistrictNameTh": "กลันทา",
    "subDistrictNameEn": "Kalantha",
    "postcode": "31000",
    "districtCode": "3101",
    "districtNameTh": "เมืองบุรีรัมย์",
    "districtNameEn": "Mueang Buri Ram",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310127",
    "subDistrictNameTh": "กระสัง",
    "subDistrictNameEn": "Krasang",
    "postcode": "31000",
    "districtCode": "3101",
    "districtNameTh": "เมืองบุรีรัมย์",
    "districtNameEn": "Mueang Buri Ram",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310128",
    "subDistrictNameTh": "เมืองฝาง",
    "subDistrictNameEn": "Mueang Fang",
    "postcode": "31000",
    "districtCode": "3101",
    "districtNameTh": "เมืองบุรีรัมย์",
    "districtNameEn": "Mueang Buri Ram",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310201",
    "subDistrictNameTh": "คูเมือง",
    "subDistrictNameEn": "Khu Mueang",
    "postcode": "31190",
    "districtCode": "3102",
    "districtNameTh": "คูเมือง",
    "districtNameEn": "Khu Mueang",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310202",
    "subDistrictNameTh": "ปะเคียบ",
    "subDistrictNameEn": "Pakhiap",
    "postcode": "31190",
    "districtCode": "3102",
    "districtNameTh": "คูเมือง",
    "districtNameEn": "Khu Mueang",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310203",
    "subDistrictNameTh": "บ้านแพ",
    "subDistrictNameEn": "Ban Phae",
    "postcode": "31190",
    "districtCode": "3102",
    "districtNameTh": "คูเมือง",
    "districtNameEn": "Khu Mueang",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310204",
    "subDistrictNameTh": "พรสำราญ",
    "subDistrictNameEn": "Phon Samran",
    "postcode": "31190",
    "districtCode": "3102",
    "districtNameTh": "คูเมือง",
    "districtNameEn": "Khu Mueang",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310205",
    "subDistrictNameTh": "หินเหล็กไฟ",
    "subDistrictNameEn": "Hin Lek Fai",
    "postcode": "31190",
    "districtCode": "3102",
    "districtNameTh": "คูเมือง",
    "districtNameEn": "Khu Mueang",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310206",
    "subDistrictNameTh": "ตูมใหญ่",
    "subDistrictNameEn": "Tum Yai",
    "postcode": "31190",
    "districtCode": "3102",
    "districtNameTh": "คูเมือง",
    "districtNameEn": "Khu Mueang",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310207",
    "subDistrictNameTh": "หนองขมาร",
    "subDistrictNameEn": "Nong Khaman",
    "postcode": "31190",
    "districtCode": "3102",
    "districtNameTh": "คูเมือง",
    "districtNameEn": "Khu Mueang",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310301",
    "subDistrictNameTh": "กระสัง",
    "subDistrictNameEn": "Krasang",
    "postcode": "31160",
    "districtCode": "3103",
    "districtNameTh": "กระสัง",
    "districtNameEn": "Krasang",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310302",
    "subDistrictNameTh": "ลำดวน",
    "subDistrictNameEn": "Lamduan",
    "postcode": "31160",
    "districtCode": "3103",
    "districtNameTh": "กระสัง",
    "districtNameEn": "Krasang",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310303",
    "subDistrictNameTh": "สองชั้น",
    "subDistrictNameEn": "Song Chan",
    "postcode": "31160",
    "districtCode": "3103",
    "districtNameTh": "กระสัง",
    "districtNameEn": "Krasang",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310304",
    "subDistrictNameTh": "สูงเนิน",
    "subDistrictNameEn": "Sung Noen",
    "postcode": "31160",
    "districtCode": "3103",
    "districtNameTh": "กระสัง",
    "districtNameEn": "Krasang",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310305",
    "subDistrictNameTh": "หนองเต็ง",
    "subDistrictNameEn": "Nong Teng",
    "postcode": "31160",
    "districtCode": "3103",
    "districtNameTh": "กระสัง",
    "districtNameEn": "Krasang",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310306",
    "subDistrictNameTh": "เมืองไผ่",
    "subDistrictNameEn": "Mueang Phai",
    "postcode": "31160",
    "districtCode": "3103",
    "districtNameTh": "กระสัง",
    "districtNameEn": "Krasang",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310307",
    "subDistrictNameTh": "ชุมแสง",
    "subDistrictNameEn": "Chum Saeng",
    "postcode": "31160",
    "districtCode": "3103",
    "districtNameTh": "กระสัง",
    "districtNameEn": "Krasang",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310308",
    "subDistrictNameTh": "บ้านปรือ",
    "subDistrictNameEn": "Ban Prue",
    "postcode": "31160",
    "districtCode": "3103",
    "districtNameTh": "กระสัง",
    "districtNameEn": "Krasang",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310309",
    "subDistrictNameTh": "ห้วยสำราญ",
    "subDistrictNameEn": "Huai Samran",
    "postcode": "31160",
    "districtCode": "3103",
    "districtNameTh": "กระสัง",
    "districtNameEn": "Krasang",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310310",
    "subDistrictNameTh": "กันทรารมย์",
    "subDistrictNameEn": "Kanthararom",
    "postcode": "31160",
    "districtCode": "3103",
    "districtNameTh": "กระสัง",
    "districtNameEn": "Krasang",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310311",
    "subDistrictNameTh": "ศรีภูมิ",
    "subDistrictNameEn": "Si Phum",
    "postcode": "31160",
    "districtCode": "3103",
    "districtNameTh": "กระสัง",
    "districtNameEn": "Krasang",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310401",
    "subDistrictNameTh": "นางรอง",
    "subDistrictNameEn": "Nang Rong",
    "postcode": "31110",
    "districtCode": "3104",
    "districtNameTh": "นางรอง",
    "districtNameEn": "Nang Rong",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310403",
    "subDistrictNameTh": "สะเดา",
    "subDistrictNameEn": "Sadao",
    "postcode": "31110",
    "districtCode": "3104",
    "districtNameTh": "นางรอง",
    "districtNameEn": "Nang Rong",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310405",
    "subDistrictNameTh": "ชุมแสง",
    "subDistrictNameEn": "Chum Saeng",
    "postcode": "31110",
    "districtCode": "3104",
    "districtNameTh": "นางรอง",
    "districtNameEn": "Nang Rong",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310406",
    "subDistrictNameTh": "หนองโบสถ์",
    "subDistrictNameEn": "Nong Bot",
    "postcode": "31110",
    "districtCode": "3104",
    "districtNameTh": "นางรอง",
    "districtNameEn": "Nang Rong",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310408",
    "subDistrictNameTh": "หนองกง",
    "subDistrictNameEn": "Nong Kong",
    "postcode": "31110",
    "districtCode": "3104",
    "districtNameTh": "นางรอง",
    "districtNameEn": "Nang Rong",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310413",
    "subDistrictNameTh": "ถนนหัก",
    "subDistrictNameEn": "Thanon Hak",
    "postcode": "31110",
    "districtCode": "3104",
    "districtNameTh": "นางรอง",
    "districtNameEn": "Nang Rong",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310414",
    "subDistrictNameTh": "หนองไทร",
    "subDistrictNameEn": "Nong Sai",
    "postcode": "31110",
    "districtCode": "3104",
    "districtNameTh": "นางรอง",
    "districtNameEn": "Nang Rong",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310415",
    "subDistrictNameTh": "ก้านเหลือง",
    "subDistrictNameEn": "Kan Lueang",
    "postcode": "31110",
    "districtCode": "3104",
    "districtNameTh": "นางรอง",
    "districtNameEn": "Nang Rong",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310416",
    "subDistrictNameTh": "บ้านสิงห์",
    "subDistrictNameEn": "Ban Sing",
    "postcode": "31110",
    "districtCode": "3104",
    "districtNameTh": "นางรอง",
    "districtNameEn": "Nang Rong",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310417",
    "subDistrictNameTh": "ลำไทรโยง",
    "subDistrictNameEn": "Lam Sai Yong",
    "postcode": "31110",
    "districtCode": "3104",
    "districtNameTh": "นางรอง",
    "districtNameEn": "Nang Rong",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310418",
    "subDistrictNameTh": "ทรัพย์พระยา",
    "subDistrictNameEn": "Sap Phraya",
    "postcode": "31110",
    "districtCode": "3104",
    "districtNameTh": "นางรอง",
    "districtNameEn": "Nang Rong",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310424",
    "subDistrictNameTh": "หนองยายพิมพ์",
    "subDistrictNameEn": "Nong Yai Phim",
    "postcode": "31110",
    "districtCode": "3104",
    "districtNameTh": "นางรอง",
    "districtNameEn": "Nang Rong",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310425",
    "subDistrictNameTh": "หัวถนน",
    "subDistrictNameEn": "Hua Thanon",
    "postcode": "31110",
    "districtCode": "3104",
    "districtNameTh": "นางรอง",
    "districtNameEn": "Nang Rong",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310426",
    "subDistrictNameTh": "ทุ่งแสงทอง",
    "subDistrictNameEn": "Thung Saeng Thong",
    "postcode": "31110",
    "districtCode": "3104",
    "districtNameTh": "นางรอง",
    "districtNameEn": "Nang Rong",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310427",
    "subDistrictNameTh": "หนองโสน",
    "subDistrictNameEn": "Nong Sano",
    "postcode": "31110",
    "districtCode": "3104",
    "districtNameTh": "นางรอง",
    "districtNameEn": "Nang Rong",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310501",
    "subDistrictNameTh": "หนองกี่",
    "subDistrictNameEn": "Nong Ki",
    "postcode": "31210",
    "districtCode": "3105",
    "districtNameTh": "หนองกี่",
    "districtNameEn": "Nong Ki",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310502",
    "subDistrictNameTh": "เย้ยปราสาท",
    "subDistrictNameEn": "Yoei Prasat",
    "postcode": "31210",
    "districtCode": "3105",
    "districtNameTh": "หนองกี่",
    "districtNameEn": "Nong Ki",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310503",
    "subDistrictNameTh": "เมืองไผ่",
    "subDistrictNameEn": "Mueang Phai",
    "postcode": "31210",
    "districtCode": "3105",
    "districtNameTh": "หนองกี่",
    "districtNameEn": "Nong Ki",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310504",
    "subDistrictNameTh": "ดอนอะราง",
    "subDistrictNameEn": "Don Arang",
    "postcode": "31210",
    "districtCode": "3105",
    "districtNameTh": "หนองกี่",
    "districtNameEn": "Nong Ki",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310505",
    "subDistrictNameTh": "โคกสว่าง",
    "subDistrictNameEn": "Khok Sawang",
    "postcode": "31210",
    "districtCode": "3105",
    "districtNameTh": "หนองกี่",
    "districtNameEn": "Nong Ki",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310506",
    "subDistrictNameTh": "ทุ่งกระตาดพัฒนา",
    "subDistrictNameEn": "Thung Kratat Phatthana",
    "postcode": "31210",
    "districtCode": "3105",
    "districtNameTh": "หนองกี่",
    "districtNameEn": "Nong Ki",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310507",
    "subDistrictNameTh": "ทุ่งกระเต็น",
    "subDistrictNameEn": "Thung Kraten",
    "postcode": "31210",
    "districtCode": "3105",
    "districtNameTh": "หนองกี่",
    "districtNameEn": "Nong Ki",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310508",
    "subDistrictNameTh": "ท่าโพธิ์ชัย",
    "subDistrictNameEn": "Tha Pho Chai",
    "postcode": "31210",
    "districtCode": "3105",
    "districtNameTh": "หนองกี่",
    "districtNameEn": "Nong Ki",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310509",
    "subDistrictNameTh": "โคกสูง",
    "subDistrictNameEn": "Khok Sung",
    "postcode": "31210",
    "districtCode": "3105",
    "districtNameTh": "หนองกี่",
    "districtNameEn": "Nong Ki",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310510",
    "subDistrictNameTh": "บุกระสัง",
    "subDistrictNameEn": "Bu Krasang",
    "postcode": "31210",
    "districtCode": "3105",
    "districtNameTh": "หนองกี่",
    "districtNameEn": "Nong Ki",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310601",
    "subDistrictNameTh": "ละหานทราย",
    "subDistrictNameEn": "Lahan Sai",
    "postcode": "31170",
    "districtCode": "3106",
    "districtNameTh": "ละหานทราย",
    "districtNameEn": "Lahan Sai",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310603",
    "subDistrictNameTh": "ตาจง",
    "subDistrictNameEn": "Ta Chong",
    "postcode": "31170",
    "districtCode": "3106",
    "districtNameTh": "ละหานทราย",
    "districtNameEn": "Lahan Sai",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310604",
    "subDistrictNameTh": "สำโรงใหม่",
    "subDistrictNameEn": "Samrong Mai",
    "postcode": "31170",
    "districtCode": "3106",
    "districtNameTh": "ละหานทราย",
    "districtNameEn": "Lahan Sai",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310607",
    "subDistrictNameTh": "หนองแวง",
    "subDistrictNameEn": "Nong Waeng",
    "postcode": "31170",
    "districtCode": "3106",
    "districtNameTh": "ละหานทราย",
    "districtNameEn": "Lahan Sai",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310610",
    "subDistrictNameTh": "หนองตะครอง",
    "subDistrictNameEn": "Nong Takhrong",
    "postcode": "31170",
    "districtCode": "3106",
    "districtNameTh": "ละหานทราย",
    "districtNameEn": "Lahan Sai",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310611",
    "subDistrictNameTh": "โคกว่าน",
    "subDistrictNameEn": "Khok Wan",
    "postcode": "31170",
    "districtCode": "3106",
    "districtNameTh": "ละหานทราย",
    "districtNameEn": "Lahan Sai",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310701",
    "subDistrictNameTh": "ประโคนชัย",
    "subDistrictNameEn": "Prakhon Chai",
    "postcode": "31140",
    "districtCode": "3107",
    "districtNameTh": "ประโคนชัย",
    "districtNameEn": "Prakhon Chai",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310702",
    "subDistrictNameTh": "แสลงโทน",
    "subDistrictNameEn": "Salaeng Thon",
    "postcode": "31140",
    "districtCode": "3107",
    "districtNameTh": "ประโคนชัย",
    "districtNameEn": "Prakhon Chai",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310703",
    "subDistrictNameTh": "บ้านไทร",
    "subDistrictNameEn": "Ban Sai",
    "postcode": "31140",
    "districtCode": "3107",
    "districtNameTh": "ประโคนชัย",
    "districtNameEn": "Prakhon Chai",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310705",
    "subDistrictNameTh": "ละเวี้ย",
    "subDistrictNameEn": "Lawia",
    "postcode": "31140",
    "districtCode": "3107",
    "districtNameTh": "ประโคนชัย",
    "districtNameEn": "Prakhon Chai",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310706",
    "subDistrictNameTh": "จรเข้มาก",
    "subDistrictNameEn": "Chorakhe Mak",
    "postcode": "31140",
    "districtCode": "3107",
    "districtNameTh": "ประโคนชัย",
    "districtNameEn": "Prakhon Chai",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310707",
    "subDistrictNameTh": "ปังกู",
    "subDistrictNameEn": "Pang Ku",
    "postcode": "31140",
    "districtCode": "3107",
    "districtNameTh": "ประโคนชัย",
    "districtNameEn": "Prakhon Chai",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310708",
    "subDistrictNameTh": "โคกย่าง",
    "subDistrictNameEn": "Khok Yang",
    "postcode": "31140",
    "districtCode": "3107",
    "districtNameTh": "ประโคนชัย",
    "districtNameEn": "Prakhon Chai",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310710",
    "subDistrictNameTh": "โคกม้า",
    "subDistrictNameEn": "Khok Ma",
    "postcode": "31140",
    "districtCode": "3107",
    "districtNameTh": "ประโคนชัย",
    "districtNameEn": "Prakhon Chai",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310713",
    "subDistrictNameTh": "ไพศาล",
    "subDistrictNameEn": "Phaisan",
    "postcode": "31140",
    "districtCode": "3107",
    "districtNameTh": "ประโคนชัย",
    "districtNameEn": "Prakhon Chai",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310714",
    "subDistrictNameTh": "ตะโกตาพิ",
    "subDistrictNameEn": "Tako Taphi",
    "postcode": "31140",
    "districtCode": "3107",
    "districtNameTh": "ประโคนชัย",
    "districtNameEn": "Prakhon Chai",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310715",
    "subDistrictNameTh": "เขาคอก",
    "subDistrictNameEn": "Khao Khok",
    "postcode": "31140",
    "districtCode": "3107",
    "districtNameTh": "ประโคนชัย",
    "districtNameEn": "Prakhon Chai",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310716",
    "subDistrictNameTh": "หนองบอน",
    "subDistrictNameEn": "Bong Bon",
    "postcode": "31140",
    "districtCode": "3107",
    "districtNameTh": "ประโคนชัย",
    "districtNameEn": "Prakhon Chai",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310718",
    "subDistrictNameTh": "โคกมะขาม",
    "subDistrictNameEn": "Khok Makham",
    "postcode": "31140",
    "districtCode": "3107",
    "districtNameTh": "ประโคนชัย",
    "districtNameEn": "Prakhon Chai",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310719",
    "subDistrictNameTh": "โคกตูม",
    "subDistrictNameEn": "Khok Tum",
    "postcode": "31140",
    "districtCode": "3107",
    "districtNameTh": "ประโคนชัย",
    "districtNameEn": "Prakhon Chai",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310720",
    "subDistrictNameTh": "ประทัดบุ",
    "subDistrictNameEn": "Prathat Bu",
    "postcode": "31140",
    "districtCode": "3107",
    "districtNameTh": "ประโคนชัย",
    "districtNameEn": "Prakhon Chai",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310721",
    "subDistrictNameTh": "สี่เหลี่ยม",
    "subDistrictNameEn": "Si Liam",
    "postcode": "31140",
    "districtCode": "3107",
    "districtNameTh": "ประโคนชัย",
    "districtNameEn": "Prakhon Chai",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310801",
    "subDistrictNameTh": "บ้านกรวด",
    "subDistrictNameEn": "Ban Kruat",
    "postcode": "31180",
    "districtCode": "3108",
    "districtNameTh": "บ้านกรวด",
    "districtNameEn": "Ban Kruat",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310802",
    "subDistrictNameTh": "โนนเจริญ",
    "subDistrictNameEn": "Non Charoen",
    "postcode": "31180",
    "districtCode": "3108",
    "districtNameTh": "บ้านกรวด",
    "districtNameEn": "Ban Kruat",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310803",
    "subDistrictNameTh": "หนองไม้งาม",
    "subDistrictNameEn": "Nong Mai Ngam",
    "postcode": "31180",
    "districtCode": "3108",
    "districtNameTh": "บ้านกรวด",
    "districtNameEn": "Ban Kruat",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310804",
    "subDistrictNameTh": "ปราสาท",
    "subDistrictNameEn": "Prasat",
    "postcode": "31180",
    "districtCode": "3108",
    "districtNameTh": "บ้านกรวด",
    "districtNameEn": "Ban Kruat",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310805",
    "subDistrictNameTh": "สายตะกู",
    "subDistrictNameEn": "Sai Taku",
    "postcode": "31180",
    "districtCode": "3108",
    "districtNameTh": "บ้านกรวด",
    "districtNameEn": "Ban Kruat",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310806",
    "subDistrictNameTh": "หินลาด",
    "subDistrictNameEn": "Hin Lat",
    "postcode": "31180",
    "districtCode": "3108",
    "districtNameTh": "บ้านกรวด",
    "districtNameEn": "Ban Kruat",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310807",
    "subDistrictNameTh": "บึงเจริญ",
    "subDistrictNameEn": "Bueng Charoen",
    "postcode": "31180",
    "districtCode": "3108",
    "districtNameTh": "บ้านกรวด",
    "districtNameEn": "Ban Kruat",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310808",
    "subDistrictNameTh": "จันทบเพชร",
    "subDistrictNameEn": "Chanthop Phet",
    "postcode": "31180",
    "districtCode": "3108",
    "districtNameTh": "บ้านกรวด",
    "districtNameEn": "Ban Kruat",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310809",
    "subDistrictNameTh": "เขาดินเหนือ",
    "subDistrictNameEn": "Khao Din Nuea",
    "postcode": "31180",
    "districtCode": "3108",
    "districtNameTh": "บ้านกรวด",
    "districtNameEn": "Ban Kruat",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310901",
    "subDistrictNameTh": "พุทไธสง",
    "subDistrictNameEn": "Phutthaisong",
    "postcode": "31120",
    "districtCode": "3109",
    "districtNameTh": "พุทไธสง",
    "districtNameEn": "Phutthaisong",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310902",
    "subDistrictNameTh": "มะเฟือง",
    "subDistrictNameEn": "Mafueang",
    "postcode": "31120",
    "districtCode": "3109",
    "districtNameTh": "พุทไธสง",
    "districtNameEn": "Phutthaisong",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310903",
    "subDistrictNameTh": "บ้านจาน",
    "subDistrictNameEn": "Ban Chan",
    "postcode": "31120",
    "districtCode": "3109",
    "districtNameTh": "พุทไธสง",
    "districtNameEn": "Phutthaisong",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310906",
    "subDistrictNameTh": "บ้านเป้า",
    "subDistrictNameEn": "Ban Pao",
    "postcode": "31120",
    "districtCode": "3109",
    "districtNameTh": "พุทไธสง",
    "districtNameEn": "Phutthaisong",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310907",
    "subDistrictNameTh": "บ้านแวง",
    "subDistrictNameEn": "Ban Waeng",
    "postcode": "31120",
    "districtCode": "3109",
    "districtNameTh": "พุทไธสง",
    "districtNameEn": "Phutthaisong",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310909",
    "subDistrictNameTh": "บ้านยาง",
    "subDistrictNameEn": "Ban Yang",
    "postcode": "31120",
    "districtCode": "3109",
    "districtNameTh": "พุทไธสง",
    "districtNameEn": "Phutthaisong",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "310910",
    "subDistrictNameTh": "หายโศก",
    "subDistrictNameEn": "Hai Sok",
    "postcode": "31120",
    "districtCode": "3109",
    "districtNameTh": "พุทไธสง",
    "districtNameEn": "Phutthaisong",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311001",
    "subDistrictNameTh": "ลำปลายมาศ",
    "subDistrictNameEn": "Lam Plai Mat",
    "postcode": "31130",
    "districtCode": "3110",
    "districtNameTh": "ลำปลายมาศ",
    "districtNameEn": "Lam Plai Mat",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311002",
    "subDistrictNameTh": "หนองคู",
    "subDistrictNameEn": "Nong Khu",
    "postcode": "31130",
    "districtCode": "3110",
    "districtNameTh": "ลำปลายมาศ",
    "districtNameEn": "Lam Plai Mat",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311003",
    "subDistrictNameTh": "แสลงพัน",
    "subDistrictNameEn": "Salaeng Phan",
    "postcode": "31130",
    "districtCode": "3110",
    "districtNameTh": "ลำปลายมาศ",
    "districtNameEn": "Lam Plai Mat",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311004",
    "subDistrictNameTh": "ทะเมนชัย",
    "subDistrictNameEn": "Thamen Chai",
    "postcode": "31130",
    "districtCode": "3110",
    "districtNameTh": "ลำปลายมาศ",
    "districtNameEn": "Lam Plai Mat",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311005",
    "subDistrictNameTh": "ตลาดโพธิ์",
    "subDistrictNameEn": "Talat Pho",
    "postcode": "31130",
    "districtCode": "3110",
    "districtNameTh": "ลำปลายมาศ",
    "districtNameEn": "Lam Plai Mat",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311006",
    "subDistrictNameTh": "หนองกะทิง",
    "subDistrictNameEn": "Nong Kathing",
    "postcode": "31130",
    "districtCode": "3110",
    "districtNameTh": "ลำปลายมาศ",
    "districtNameEn": "Lam Plai Mat",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311007",
    "subDistrictNameTh": "โคกกลาง",
    "subDistrictNameEn": "Khok Klang",
    "postcode": "31130",
    "districtCode": "3110",
    "districtNameTh": "ลำปลายมาศ",
    "districtNameEn": "Lam Plai Mat",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311008",
    "subDistrictNameTh": "โคกสะอาด",
    "subDistrictNameEn": "Khok Sa-at",
    "postcode": "31130",
    "districtCode": "3110",
    "districtNameTh": "ลำปลายมาศ",
    "districtNameEn": "Lam Plai Mat",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311009",
    "subDistrictNameTh": "เมืองแฝก",
    "subDistrictNameEn": "Mueang Faek",
    "postcode": "31130",
    "districtCode": "3110",
    "districtNameTh": "ลำปลายมาศ",
    "districtNameEn": "Lam Plai Mat",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311010",
    "subDistrictNameTh": "บ้านยาง",
    "subDistrictNameEn": "Ban Yang",
    "postcode": "31130",
    "districtCode": "3110",
    "districtNameTh": "ลำปลายมาศ",
    "districtNameEn": "Lam Plai Mat",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311011",
    "subDistrictNameTh": "ผไทรินทร์",
    "subDistrictNameEn": "Phathairin",
    "postcode": "31130",
    "districtCode": "3110",
    "districtNameTh": "ลำปลายมาศ",
    "districtNameEn": "Lam Plai Mat",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311012",
    "subDistrictNameTh": "โคกล่าม",
    "subDistrictNameEn": "Khok Lam",
    "postcode": "31130",
    "districtCode": "3110",
    "districtNameTh": "ลำปลายมาศ",
    "districtNameEn": "Lam Plai Mat",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311013",
    "subDistrictNameTh": "หินโคน",
    "subDistrictNameEn": "Hin Khon",
    "postcode": "31130",
    "districtCode": "3110",
    "districtNameTh": "ลำปลายมาศ",
    "districtNameEn": "Lam Plai Mat",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311014",
    "subDistrictNameTh": "หนองบัวโคก",
    "subDistrictNameEn": "Nong Bua Khok",
    "postcode": "31130",
    "districtCode": "3110",
    "districtNameTh": "ลำปลายมาศ",
    "districtNameEn": "Lam Plai Mat",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311015",
    "subDistrictNameTh": "บุโพธิ์",
    "subDistrictNameEn": "Bu Pho",
    "postcode": "31130",
    "districtCode": "3110",
    "districtNameTh": "ลำปลายมาศ",
    "districtNameEn": "Lam Plai Mat",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311016",
    "subDistrictNameTh": "หนองโดน",
    "subDistrictNameEn": "Nong Don",
    "postcode": "31130",
    "districtCode": "3110",
    "districtNameTh": "ลำปลายมาศ",
    "districtNameEn": "Lam Plai Mat",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311101",
    "subDistrictNameTh": "สตึก",
    "subDistrictNameEn": "Satuek",
    "postcode": "31150",
    "districtCode": "3111",
    "districtNameTh": "สตึก",
    "districtNameEn": "Satuek",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311102",
    "subDistrictNameTh": "นิคม",
    "subDistrictNameEn": "Nikhom",
    "postcode": "31150",
    "districtCode": "3111",
    "districtNameTh": "สตึก",
    "districtNameEn": "Satuek",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311103",
    "subDistrictNameTh": "ทุ่งวัง",
    "subDistrictNameEn": "Thung Wang",
    "postcode": "31150",
    "districtCode": "3111",
    "districtNameTh": "สตึก",
    "districtNameEn": "Satuek",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311104",
    "subDistrictNameTh": "เมืองแก",
    "subDistrictNameEn": "Mueang Kae",
    "postcode": "31150",
    "districtCode": "3111",
    "districtNameTh": "สตึก",
    "districtNameEn": "Satuek",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311105",
    "subDistrictNameTh": "หนองใหญ่",
    "subDistrictNameEn": "Nong Yai",
    "postcode": "31150",
    "districtCode": "3111",
    "districtNameTh": "สตึก",
    "districtNameEn": "Satuek",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311106",
    "subDistrictNameTh": "ร่อนทอง",
    "subDistrictNameEn": "Ron Thong",
    "postcode": "31150",
    "districtCode": "3111",
    "districtNameTh": "สตึก",
    "districtNameEn": "Satuek",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311109",
    "subDistrictNameTh": "ดอนมนต์",
    "subDistrictNameEn": "Don Mon",
    "postcode": "31150",
    "districtCode": "3111",
    "districtNameTh": "สตึก",
    "districtNameEn": "Satuek",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311110",
    "subDistrictNameTh": "ชุมแสง",
    "subDistrictNameEn": "Chum Saeng",
    "postcode": "31150",
    "districtCode": "3111",
    "districtNameTh": "สตึก",
    "districtNameEn": "Satuek",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311111",
    "subDistrictNameTh": "ท่าม่วง",
    "subDistrictNameEn": "Tha Muang",
    "postcode": "31150",
    "districtCode": "3111",
    "districtNameTh": "สตึก",
    "districtNameEn": "Satuek",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311112",
    "subDistrictNameTh": "สะแก",
    "subDistrictNameEn": "Sakae",
    "postcode": "31150",
    "districtCode": "3111",
    "districtNameTh": "สตึก",
    "districtNameEn": "Satuek",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311114",
    "subDistrictNameTh": "สนามชัย",
    "subDistrictNameEn": "Sanam Chai",
    "postcode": "31150",
    "districtCode": "3111",
    "districtNameTh": "สตึก",
    "districtNameEn": "Satuek",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311115",
    "subDistrictNameTh": "กระสัง",
    "subDistrictNameEn": "Krasang",
    "postcode": "31150",
    "districtCode": "3111",
    "districtNameTh": "สตึก",
    "districtNameEn": "Satuek",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311201",
    "subDistrictNameTh": "ปะคำ",
    "subDistrictNameEn": "Pakham",
    "postcode": "31220",
    "districtCode": "3112",
    "districtNameTh": "ปะคำ",
    "districtNameEn": "Pakham",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311202",
    "subDistrictNameTh": "ไทยเจริญ",
    "subDistrictNameEn": "Thai Charoen",
    "postcode": "31220",
    "districtCode": "3112",
    "districtNameTh": "ปะคำ",
    "districtNameEn": "Pakham",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311203",
    "subDistrictNameTh": "หนองบัว",
    "subDistrictNameEn": "Nong Bua",
    "postcode": "31220",
    "districtCode": "3112",
    "districtNameTh": "ปะคำ",
    "districtNameEn": "Pakham",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311204",
    "subDistrictNameTh": "โคกมะม่วง",
    "subDistrictNameEn": "Khok Mamuang",
    "postcode": "31220",
    "districtCode": "3112",
    "districtNameTh": "ปะคำ",
    "districtNameEn": "Pakham",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311205",
    "subDistrictNameTh": "หูทำนบ",
    "subDistrictNameEn": "Hu Thamnop",
    "postcode": "31220",
    "districtCode": "3112",
    "districtNameTh": "ปะคำ",
    "districtNameEn": "Pakham",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311301",
    "subDistrictNameTh": "นาโพธิ์",
    "subDistrictNameEn": "Na Pho",
    "postcode": "31230",
    "districtCode": "3113",
    "districtNameTh": "นาโพธิ์",
    "districtNameEn": "Na Pho",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311302",
    "subDistrictNameTh": "บ้านคู",
    "subDistrictNameEn": "Ban Khu",
    "postcode": "31230",
    "districtCode": "3113",
    "districtNameTh": "นาโพธิ์",
    "districtNameEn": "Na Pho",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311303",
    "subDistrictNameTh": "บ้านดู่",
    "subDistrictNameEn": "Ban Du",
    "postcode": "31230",
    "districtCode": "3113",
    "districtNameTh": "นาโพธิ์",
    "districtNameEn": "Na Pho",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311304",
    "subDistrictNameTh": "ดอนกอก",
    "subDistrictNameEn": "Don Kok",
    "postcode": "31230",
    "districtCode": "3113",
    "districtNameTh": "นาโพธิ์",
    "districtNameEn": "Na Pho",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311305",
    "subDistrictNameTh": "ศรีสว่าง",
    "subDistrictNameEn": "Si Sawang",
    "postcode": "31230",
    "districtCode": "3113",
    "districtNameTh": "นาโพธิ์",
    "districtNameEn": "Na Pho",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311401",
    "subDistrictNameTh": "สระแก้ว",
    "subDistrictNameEn": "Sa Kaeo",
    "postcode": "31240",
    "districtCode": "3114",
    "districtNameTh": "หนองหงส์",
    "districtNameEn": "Nong Hong",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311402",
    "subDistrictNameTh": "ห้วยหิน",
    "subDistrictNameEn": "Huai Hin",
    "postcode": "31240",
    "districtCode": "3114",
    "districtNameTh": "หนองหงส์",
    "districtNameEn": "Nong Hong",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311403",
    "subDistrictNameTh": "ไทยสามัคคี",
    "subDistrictNameEn": "Thai Samakkhi",
    "postcode": "31240",
    "districtCode": "3114",
    "districtNameTh": "หนองหงส์",
    "districtNameEn": "Nong Hong",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311404",
    "subDistrictNameTh": "หนองชัยศรี",
    "subDistrictNameEn": "Nong Chai Si",
    "postcode": "31240",
    "districtCode": "3114",
    "districtNameTh": "หนองหงส์",
    "districtNameEn": "Nong Hong",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311405",
    "subDistrictNameTh": "เสาเดียว",
    "subDistrictNameEn": "Sao Diao",
    "postcode": "31240",
    "districtCode": "3114",
    "districtNameTh": "หนองหงส์",
    "districtNameEn": "Nong Hong",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311406",
    "subDistrictNameTh": "เมืองฝ้าย",
    "subDistrictNameEn": "Mueang Fai",
    "postcode": "31240",
    "districtCode": "3114",
    "districtNameTh": "หนองหงส์",
    "districtNameEn": "Nong Hong",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311407",
    "subDistrictNameTh": "สระทอง",
    "subDistrictNameEn": "Sa Thong",
    "postcode": "31240",
    "districtCode": "3114",
    "districtNameTh": "หนองหงส์",
    "districtNameEn": "Nong Hong",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311501",
    "subDistrictNameTh": "จันดุม",
    "subDistrictNameEn": "Chan Dum",
    "postcode": "31250",
    "districtCode": "3115",
    "districtNameTh": "พลับพลาชัย",
    "districtNameEn": "Phlapphla Chai",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311502",
    "subDistrictNameTh": "โคกขมิ้น",
    "subDistrictNameEn": "Khok Khamin",
    "postcode": "31250",
    "districtCode": "3115",
    "districtNameTh": "พลับพลาชัย",
    "districtNameEn": "Phlapphla Chai",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311503",
    "subDistrictNameTh": "ป่าชัน",
    "subDistrictNameEn": "Pa Chan",
    "postcode": "31250",
    "districtCode": "3115",
    "districtNameTh": "พลับพลาชัย",
    "districtNameEn": "Phlapphla Chai",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311504",
    "subDistrictNameTh": "สะเดา",
    "subDistrictNameEn": "Sadao",
    "postcode": "31250",
    "districtCode": "3115",
    "districtNameTh": "พลับพลาชัย",
    "districtNameEn": "Phlapphla Chai",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311505",
    "subDistrictNameTh": "สำโรง",
    "subDistrictNameEn": "Samrong",
    "postcode": "31250",
    "districtCode": "3115",
    "districtNameTh": "พลับพลาชัย",
    "districtNameEn": "Phlapphla Chai",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311601",
    "subDistrictNameTh": "ห้วยราช",
    "subDistrictNameEn": "Huai Rat",
    "postcode": "31000",
    "districtCode": "3116",
    "districtNameTh": "ห้วยราช",
    "districtNameEn": "Huai Rat",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311602",
    "subDistrictNameTh": "สามแวง",
    "subDistrictNameEn": "Sam Waeng",
    "postcode": "31000",
    "districtCode": "3116",
    "districtNameTh": "ห้วยราช",
    "districtNameEn": "Huai Rat",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311603",
    "subDistrictNameTh": "ตาเสา",
    "subDistrictNameEn": "Ta Sao",
    "postcode": "31000",
    "districtCode": "3116",
    "districtNameTh": "ห้วยราช",
    "districtNameEn": "Huai Rat",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311604",
    "subDistrictNameTh": "บ้านตะโก",
    "subDistrictNameEn": "Ban Tako",
    "postcode": "31000",
    "districtCode": "3116",
    "districtNameTh": "ห้วยราช",
    "districtNameEn": "Huai Rat",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311605",
    "subDistrictNameTh": "สนวน",
    "subDistrictNameEn": "Sanuan",
    "postcode": "31000",
    "districtCode": "3116",
    "districtNameTh": "ห้วยราช",
    "districtNameEn": "Huai Rat",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311606",
    "subDistrictNameTh": "โคกเหล็ก",
    "subDistrictNameEn": "Khok Lek",
    "postcode": "31000",
    "districtCode": "3116",
    "districtNameTh": "ห้วยราช",
    "districtNameEn": "Huai Rat",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311607",
    "subDistrictNameTh": "เมืองโพธิ์",
    "subDistrictNameEn": "Mueang Pho",
    "postcode": "31000",
    "districtCode": "3116",
    "districtNameTh": "ห้วยราช",
    "districtNameEn": "Huai Rat",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311608",
    "subDistrictNameTh": "ห้วยราชา",
    "subDistrictNameEn": "Huai Racha",
    "postcode": "31000",
    "districtCode": "3116",
    "districtNameTh": "ห้วยราช",
    "districtNameEn": "Huai Rat",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311701",
    "subDistrictNameTh": "โนนสุวรรณ",
    "subDistrictNameEn": "Non Suwan",
    "postcode": "31110",
    "districtCode": "3117",
    "districtNameTh": "โนนสุวรรณ",
    "districtNameEn": "Non Suwan",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311702",
    "subDistrictNameTh": "ทุ่งจังหัน",
    "subDistrictNameEn": "Thung Changhan",
    "postcode": "31110",
    "districtCode": "3117",
    "districtNameTh": "โนนสุวรรณ",
    "districtNameEn": "Non Suwan",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311703",
    "subDistrictNameTh": "โกรกแก้ว",
    "subDistrictNameEn": "Krok Kaeo",
    "postcode": "31110",
    "districtCode": "3117",
    "districtNameTh": "โนนสุวรรณ",
    "districtNameEn": "Non Suwan",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311704",
    "subDistrictNameTh": "ดงอีจาน",
    "subDistrictNameEn": "Dong I Chan",
    "postcode": "31110",
    "districtCode": "3117",
    "districtNameTh": "โนนสุวรรณ",
    "districtNameEn": "Non Suwan",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311801",
    "subDistrictNameTh": "ชำนิ",
    "subDistrictNameEn": "Chamni",
    "postcode": "31110",
    "districtCode": "3118",
    "districtNameTh": "ชำนิ",
    "districtNameEn": "Chamni",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311802",
    "subDistrictNameTh": "หนองปล่อง",
    "subDistrictNameEn": "Nong Plong",
    "postcode": "31110",
    "districtCode": "3118",
    "districtNameTh": "ชำนิ",
    "districtNameEn": "Chamni",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311803",
    "subDistrictNameTh": "เมืองยาง",
    "subDistrictNameEn": "Mueang Yang",
    "postcode": "31110",
    "districtCode": "3118",
    "districtNameTh": "ชำนิ",
    "districtNameEn": "Chamni",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311804",
    "subDistrictNameTh": "ช่อผกา",
    "subDistrictNameEn": "Cho Phaka",
    "postcode": "31110",
    "districtCode": "3118",
    "districtNameTh": "ชำนิ",
    "districtNameEn": "Chamni",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311805",
    "subDistrictNameTh": "ละลวด",
    "subDistrictNameEn": "Laluat",
    "postcode": "31110",
    "districtCode": "3118",
    "districtNameTh": "ชำนิ",
    "districtNameEn": "Chamni",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311806",
    "subDistrictNameTh": "โคกสนวน",
    "subDistrictNameEn": "Khok Sanuan",
    "postcode": "31110",
    "districtCode": "3118",
    "districtNameTh": "ชำนิ",
    "districtNameEn": "Chamni",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311901",
    "subDistrictNameTh": "หนองแวง",
    "subDistrictNameEn": "Nong Waeng",
    "postcode": "31120",
    "districtCode": "3119",
    "districtNameTh": "บ้านใหม่ไชยพจน์",
    "districtNameEn": "Ban Mai Chaiyaphot",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311902",
    "subDistrictNameTh": "ทองหลาง",
    "subDistrictNameEn": "Thonglang",
    "postcode": "31120",
    "districtCode": "3119",
    "districtNameTh": "บ้านใหม่ไชยพจน์",
    "districtNameEn": "Ban Mai Chaiyaphot",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311903",
    "subDistrictNameTh": "แดงใหญ่",
    "subDistrictNameEn": "Daeng Yai",
    "postcode": "31120",
    "districtCode": "3119",
    "districtNameTh": "บ้านใหม่ไชยพจน์",
    "districtNameEn": "Ban Mai Chaiyaphot",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311904",
    "subDistrictNameTh": "กู่สวนแตง",
    "subDistrictNameEn": "Ku Suan Taeng",
    "postcode": "31120",
    "districtCode": "3119",
    "districtNameTh": "บ้านใหม่ไชยพจน์",
    "districtNameEn": "Ban Mai Chaiyaphot",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "311905",
    "subDistrictNameTh": "หนองเยือง",
    "subDistrictNameEn": "Nong Yueang",
    "postcode": "31120",
    "districtCode": "3119",
    "districtNameTh": "บ้านใหม่ไชยพจน์",
    "districtNameEn": "Ban Mai Chaiyaphot",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "312001",
    "subDistrictNameTh": "โนนดินแดง",
    "subDistrictNameEn": "Non Din Daeng",
    "postcode": "31260",
    "districtCode": "3120",
    "districtNameTh": "โนนดินแดง",
    "districtNameEn": "Non Din Daeng",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "312002",
    "subDistrictNameTh": "ส้มป่อย",
    "subDistrictNameEn": "Som Poi",
    "postcode": "31260",
    "districtCode": "3120",
    "districtNameTh": "โนนดินแดง",
    "districtNameEn": "Non Din Daeng",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "312003",
    "subDistrictNameTh": "ลำนางรอง",
    "subDistrictNameEn": "Lam Nang Rong",
    "postcode": "31260",
    "districtCode": "3120",
    "districtNameTh": "โนนดินแดง",
    "districtNameEn": "Non Din Daeng",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "312101",
    "subDistrictNameTh": "บ้านด่าน",
    "subDistrictNameEn": "Ban Dan",
    "postcode": "31000",
    "districtCode": "3121",
    "districtNameTh": "บ้านด่าน",
    "districtNameEn": "Ban Dan",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "312102",
    "subDistrictNameTh": "ปราสาท",
    "subDistrictNameEn": "Prasat",
    "postcode": "31000",
    "districtCode": "3121",
    "districtNameTh": "บ้านด่าน",
    "districtNameEn": "Ban Dan",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "312103",
    "subDistrictNameTh": "วังเหนือ",
    "subDistrictNameEn": "Wang Nuea",
    "postcode": "31000",
    "districtCode": "3121",
    "districtNameTh": "บ้านด่าน",
    "districtNameEn": "Ban Dan",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "312104",
    "subDistrictNameTh": "โนนขวาง",
    "subDistrictNameEn": "Non Khwang",
    "postcode": "31000",
    "districtCode": "3121",
    "districtNameTh": "บ้านด่าน",
    "districtNameEn": "Ban Dan",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "312201",
    "subDistrictNameTh": "แคนดง",
    "subDistrictNameEn": "Khaen Dong",
    "postcode": "31150",
    "districtCode": "3122",
    "districtNameTh": "แคนดง",
    "districtNameEn": "Khaen Dong",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "312202",
    "subDistrictNameTh": "ดงพลอง",
    "subDistrictNameEn": "Dong Phlong",
    "postcode": "31150",
    "districtCode": "3122",
    "districtNameTh": "แคนดง",
    "districtNameEn": "Khaen Dong",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "312203",
    "subDistrictNameTh": "สระบัว",
    "subDistrictNameEn": "Sa Bua",
    "postcode": "31150",
    "districtCode": "3122",
    "districtNameTh": "แคนดง",
    "districtNameEn": "Khaen Dong",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "312204",
    "subDistrictNameTh": "หัวฝาย",
    "subDistrictNameEn": "Hua Fai",
    "postcode": "31150",
    "districtCode": "3122",
    "districtNameTh": "แคนดง",
    "districtNameEn": "Khaen Dong",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "312301",
    "subDistrictNameTh": "เจริญสุข",
    "subDistrictNameEn": "Charoen Suk",
    "postcode": "31110",
    "districtCode": "3123",
    "districtNameTh": "เฉลิมพระเกียรติ",
    "districtNameEn": "Chaloem Phra Kiat",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "312302",
    "subDistrictNameTh": "ตาเป๊ก",
    "subDistrictNameEn": "Ta Pek",
    "postcode": "31110",
    "districtCode": "3123",
    "districtNameTh": "เฉลิมพระเกียรติ",
    "districtNameEn": "Chaloem Phra Kiat",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "312303",
    "subDistrictNameTh": "อีสานเขต",
    "subDistrictNameEn": "Isan Khet",
    "postcode": "31110",
    "districtCode": "3123",
    "districtNameTh": "เฉลิมพระเกียรติ",
    "districtNameEn": "Chaloem Phra Kiat",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "312304",
    "subDistrictNameTh": "ถาวร",
    "subDistrictNameEn": "Thawon",
    "postcode": "31170",
    "districtCode": "3123",
    "districtNameTh": "เฉลิมพระเกียรติ",
    "districtNameEn": "Chaloem Phra Kiat",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "312305",
    "subDistrictNameTh": "ยายแย้มวัฒนา",
    "subDistrictNameEn": "Yai Yaem Watthana",
    "postcode": "31170",
    "districtCode": "3123",
    "districtNameTh": "เฉลิมพระเกียรติ",
    "districtNameEn": "Chaloem Phra Kiat",
    "provinceCode": "31",
    "provinceNameTh": "บุรีรัมย์",
    "provinceNameEn": "Buri Ram"
},
{
    "subDistrictCode": "320101",
    "subDistrictNameTh": "ในเมือง",
    "subDistrictNameEn": "Nai Mueang",
    "postcode": "32000",
    "districtCode": "3201",
    "districtNameTh": "เมืองสุรินทร์",
    "districtNameEn": "Mueang Surin",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320102",
    "subDistrictNameTh": "ตั้งใจ",
    "subDistrictNameEn": "Tang Chai",
    "postcode": "32000",
    "districtCode": "3201",
    "districtNameTh": "เมืองสุรินทร์",
    "districtNameEn": "Mueang Surin",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320103",
    "subDistrictNameTh": "เพี้ยราม",
    "subDistrictNameEn": "Phia Ram",
    "postcode": "32000",
    "districtCode": "3201",
    "districtNameTh": "เมืองสุรินทร์",
    "districtNameEn": "Mueang Surin",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320104",
    "subDistrictNameTh": "นาดี",
    "subDistrictNameEn": "Na Di",
    "postcode": "32000",
    "districtCode": "3201",
    "districtNameTh": "เมืองสุรินทร์",
    "districtNameEn": "Mueang Surin",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320105",
    "subDistrictNameTh": "ท่าสว่าง",
    "subDistrictNameEn": "Tha Sawang",
    "postcode": "32000",
    "districtCode": "3201",
    "districtNameTh": "เมืองสุรินทร์",
    "districtNameEn": "Mueang Surin",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320106",
    "subDistrictNameTh": "สลักได",
    "subDistrictNameEn": "Salakdai",
    "postcode": "32000",
    "districtCode": "3201",
    "districtNameTh": "เมืองสุรินทร์",
    "districtNameEn": "Mueang Surin",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320107",
    "subDistrictNameTh": "ตาอ็อง",
    "subDistrictNameEn": "Ta Ong",
    "postcode": "32000",
    "districtCode": "3201",
    "districtNameTh": "เมืองสุรินทร์",
    "districtNameEn": "Mueang Surin",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320109",
    "subDistrictNameTh": "สำโรง",
    "subDistrictNameEn": "Samrong",
    "postcode": "32000",
    "districtCode": "3201",
    "districtNameTh": "เมืองสุรินทร์",
    "districtNameEn": "Mueang Surin",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320110",
    "subDistrictNameTh": "แกใหญ่",
    "subDistrictNameEn": "Kae Yai",
    "postcode": "32000",
    "districtCode": "3201",
    "districtNameTh": "เมืองสุรินทร์",
    "districtNameEn": "Mueang Surin",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320111",
    "subDistrictNameTh": "นอกเมือง",
    "subDistrictNameEn": "Nok Mueang",
    "postcode": "32000",
    "districtCode": "3201",
    "districtNameTh": "เมืองสุรินทร์",
    "districtNameEn": "Mueang Surin",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320112",
    "subDistrictNameTh": "คอโค",
    "subDistrictNameEn": "Kho Kho",
    "postcode": "32000",
    "districtCode": "3201",
    "districtNameTh": "เมืองสุรินทร์",
    "districtNameEn": "Mueang Surin",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320113",
    "subDistrictNameTh": "สวาย",
    "subDistrictNameEn": "Sawai",
    "postcode": "32000",
    "districtCode": "3201",
    "districtNameTh": "เมืองสุรินทร์",
    "districtNameEn": "Mueang Surin",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320114",
    "subDistrictNameTh": "เฉนียง",
    "subDistrictNameEn": "Chaniang",
    "postcode": "32000",
    "districtCode": "3201",
    "districtNameTh": "เมืองสุรินทร์",
    "districtNameEn": "Mueang Surin",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320116",
    "subDistrictNameTh": "เทนมีย์",
    "subDistrictNameEn": "Thenmi",
    "postcode": "32000",
    "districtCode": "3201",
    "districtNameTh": "เมืองสุรินทร์",
    "districtNameEn": "Mueang Surin",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320118",
    "subDistrictNameTh": "นาบัว",
    "subDistrictNameEn": "Na Bua",
    "postcode": "32000",
    "districtCode": "3201",
    "districtNameTh": "เมืองสุรินทร์",
    "districtNameEn": "Mueang Surin",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320119",
    "subDistrictNameTh": "เมืองที",
    "subDistrictNameEn": "Mueang Thi",
    "postcode": "32000",
    "districtCode": "3201",
    "districtNameTh": "เมืองสุรินทร์",
    "districtNameEn": "Mueang Surin",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320120",
    "subDistrictNameTh": "ราม",
    "subDistrictNameEn": "Ram",
    "postcode": "32000",
    "districtCode": "3201",
    "districtNameTh": "เมืองสุรินทร์",
    "districtNameEn": "Mueang Surin",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320121",
    "subDistrictNameTh": "บุฤาษี",
    "subDistrictNameEn": "Bu Ruesi",
    "postcode": "32000",
    "districtCode": "3201",
    "districtNameTh": "เมืองสุรินทร์",
    "districtNameEn": "Mueang Surin",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320122",
    "subDistrictNameTh": "ตระแสง",
    "subDistrictNameEn": "Trasaeng",
    "postcode": "32000",
    "districtCode": "3201",
    "districtNameTh": "เมืองสุรินทร์",
    "districtNameEn": "Mueang Surin",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320125",
    "subDistrictNameTh": "แสลงพันธ์",
    "subDistrictNameEn": "Salaeng Phan",
    "postcode": "32000",
    "districtCode": "3201",
    "districtNameTh": "เมืองสุรินทร์",
    "districtNameEn": "Mueang Surin",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320126",
    "subDistrictNameTh": "กาเกาะ",
    "subDistrictNameEn": "Ka Ko",
    "postcode": "32000",
    "districtCode": "3201",
    "districtNameTh": "เมืองสุรินทร์",
    "districtNameEn": "Mueang Surin",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320201",
    "subDistrictNameTh": "ชุมพลบุรี",
    "subDistrictNameEn": "Chumphon Buri",
    "postcode": "32190",
    "districtCode": "3202",
    "districtNameTh": "ชุมพลบุรี",
    "districtNameEn": "Chumphon Buri",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320202",
    "subDistrictNameTh": "นาหนองไผ่",
    "subDistrictNameEn": "Na Nong Phai",
    "postcode": "32190",
    "districtCode": "3202",
    "districtNameTh": "ชุมพลบุรี",
    "districtNameEn": "Chumphon Buri",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320203",
    "subDistrictNameTh": "ไพรขลา",
    "subDistrictNameEn": "Phrai Khla",
    "postcode": "32190",
    "districtCode": "3202",
    "districtNameTh": "ชุมพลบุรี",
    "districtNameEn": "Chumphon Buri",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320204",
    "subDistrictNameTh": "ศรีณรงค์",
    "subDistrictNameEn": "Si Narong",
    "postcode": "32190",
    "districtCode": "3202",
    "districtNameTh": "ชุมพลบุรี",
    "districtNameEn": "Chumphon Buri",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320205",
    "subDistrictNameTh": "ยะวึก",
    "subDistrictNameEn": "Yawuek",
    "postcode": "32190",
    "districtCode": "3202",
    "districtNameTh": "ชุมพลบุรี",
    "districtNameEn": "Chumphon Buri",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320206",
    "subDistrictNameTh": "เมืองบัว",
    "subDistrictNameEn": "Mueang Bua",
    "postcode": "32190",
    "districtCode": "3202",
    "districtNameTh": "ชุมพลบุรี",
    "districtNameEn": "Chumphon Buri",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320207",
    "subDistrictNameTh": "สระขุด",
    "subDistrictNameEn": "Sa Khut",
    "postcode": "32190",
    "districtCode": "3202",
    "districtNameTh": "ชุมพลบุรี",
    "districtNameEn": "Chumphon Buri",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320208",
    "subDistrictNameTh": "กระเบื้อง",
    "subDistrictNameEn": "Krabueang",
    "postcode": "32190",
    "districtCode": "3202",
    "districtNameTh": "ชุมพลบุรี",
    "districtNameEn": "Chumphon Buri",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320209",
    "subDistrictNameTh": "หนองเรือ",
    "subDistrictNameEn": "Nong Ruea",
    "postcode": "32190",
    "districtCode": "3202",
    "districtNameTh": "ชุมพลบุรี",
    "districtNameEn": "Chumphon Buri",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320301",
    "subDistrictNameTh": "ท่าตูม",
    "subDistrictNameEn": "Tha Tum",
    "postcode": "32120",
    "districtCode": "3203",
    "districtNameTh": "ท่าตูม",
    "districtNameEn": "Tha Tum",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320302",
    "subDistrictNameTh": "กระโพ",
    "subDistrictNameEn": "Krapho",
    "postcode": "32120",
    "districtCode": "3203",
    "districtNameTh": "ท่าตูม",
    "districtNameEn": "Tha Tum",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320303",
    "subDistrictNameTh": "พรมเทพ",
    "subDistrictNameEn": "Phrom Thep",
    "postcode": "32120",
    "districtCode": "3203",
    "districtNameTh": "ท่าตูม",
    "districtNameEn": "Tha Tum",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320304",
    "subDistrictNameTh": "โพนครก",
    "subDistrictNameEn": "Phon Khrok",
    "postcode": "32120",
    "districtCode": "3203",
    "districtNameTh": "ท่าตูม",
    "districtNameEn": "Tha Tum",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320305",
    "subDistrictNameTh": "เมืองแก",
    "subDistrictNameEn": "Mueang Kae",
    "postcode": "32120",
    "districtCode": "3203",
    "districtNameTh": "ท่าตูม",
    "districtNameEn": "Tha Tum",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320306",
    "subDistrictNameTh": "บะ",
    "subDistrictNameEn": "Ba",
    "postcode": "32120",
    "districtCode": "3203",
    "districtNameTh": "ท่าตูม",
    "districtNameEn": "Tha Tum",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320307",
    "subDistrictNameTh": "หนองบัว",
    "subDistrictNameEn": "Nong Bua",
    "postcode": "32120",
    "districtCode": "3203",
    "districtNameTh": "ท่าตูม",
    "districtNameEn": "Tha Tum",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320308",
    "subDistrictNameTh": "บัวโคก",
    "subDistrictNameEn": "Bua Khok",
    "postcode": "32120",
    "districtCode": "3203",
    "districtNameTh": "ท่าตูม",
    "districtNameEn": "Tha Tum",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320309",
    "subDistrictNameTh": "หนองเมธี",
    "subDistrictNameEn": "Nong Methi",
    "postcode": "32120",
    "districtCode": "3203",
    "districtNameTh": "ท่าตูม",
    "districtNameEn": "Tha Tum",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320310",
    "subDistrictNameTh": "ทุ่งกุลา",
    "subDistrictNameEn": "Thung Kula",
    "postcode": "32120",
    "districtCode": "3203",
    "districtNameTh": "ท่าตูม",
    "districtNameEn": "Tha Tum",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320401",
    "subDistrictNameTh": "จอมพระ",
    "subDistrictNameEn": "Chom Phra",
    "postcode": "32180",
    "districtCode": "3204",
    "districtNameTh": "จอมพระ",
    "districtNameEn": "Chom Phra",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320402",
    "subDistrictNameTh": "เมืองลีง",
    "subDistrictNameEn": "Mueang Ling",
    "postcode": "32180",
    "districtCode": "3204",
    "districtNameTh": "จอมพระ",
    "districtNameEn": "Chom Phra",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320403",
    "subDistrictNameTh": "กระหาด",
    "subDistrictNameEn": "Krahat",
    "postcode": "32180",
    "districtCode": "3204",
    "districtNameTh": "จอมพระ",
    "districtNameEn": "Chom Phra",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320404",
    "subDistrictNameTh": "บุแกรง",
    "subDistrictNameEn": "Bu Kraeng",
    "postcode": "32180",
    "districtCode": "3204",
    "districtNameTh": "จอมพระ",
    "districtNameEn": "Chom Phra",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320405",
    "subDistrictNameTh": "หนองสนิท",
    "subDistrictNameEn": "Nong Sanit",
    "postcode": "32180",
    "districtCode": "3204",
    "districtNameTh": "จอมพระ",
    "districtNameEn": "Chom Phra",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320406",
    "subDistrictNameTh": "บ้านผือ",
    "subDistrictNameEn": "Ban Phue",
    "postcode": "32180",
    "districtCode": "3204",
    "districtNameTh": "จอมพระ",
    "districtNameEn": "Chom Phra",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320407",
    "subDistrictNameTh": "ลุ่มระวี",
    "subDistrictNameEn": "Lum Rawi",
    "postcode": "32180",
    "districtCode": "3204",
    "districtNameTh": "จอมพระ",
    "districtNameEn": "Chom Phra",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320408",
    "subDistrictNameTh": "ชุมแสง",
    "subDistrictNameEn": "Chum Saeng",
    "postcode": "32180",
    "districtCode": "3204",
    "districtNameTh": "จอมพระ",
    "districtNameEn": "Chom Phra",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320409",
    "subDistrictNameTh": "เป็นสุข",
    "subDistrictNameEn": "Pen Suk",
    "postcode": "32180",
    "districtCode": "3204",
    "districtNameTh": "จอมพระ",
    "districtNameEn": "Chom Phra",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320501",
    "subDistrictNameTh": "กังแอน",
    "subDistrictNameEn": "Kang Aen",
    "postcode": "32140",
    "districtCode": "3205",
    "districtNameTh": "ปราสาท",
    "districtNameEn": "Prasat",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320502",
    "subDistrictNameTh": "ทมอ",
    "subDistrictNameEn": "Thamo",
    "postcode": "32140",
    "districtCode": "3205",
    "districtNameTh": "ปราสาท",
    "districtNameEn": "Prasat",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320503",
    "subDistrictNameTh": "ไพล",
    "subDistrictNameEn": "Phlai",
    "postcode": "32140",
    "districtCode": "3205",
    "districtNameTh": "ปราสาท",
    "districtNameEn": "Prasat",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320504",
    "subDistrictNameTh": "ปรือ",
    "subDistrictNameEn": "Prue",
    "postcode": "32140",
    "districtCode": "3205",
    "districtNameTh": "ปราสาท",
    "districtNameEn": "Prasat",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320505",
    "subDistrictNameTh": "ทุ่งมน",
    "subDistrictNameEn": "Thung Mon",
    "postcode": "32140",
    "districtCode": "3205",
    "districtNameTh": "ปราสาท",
    "districtNameEn": "Prasat",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320506",
    "subDistrictNameTh": "ตาเบา",
    "subDistrictNameEn": "Ta Bao",
    "postcode": "32140",
    "districtCode": "3205",
    "districtNameTh": "ปราสาท",
    "districtNameEn": "Prasat",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320507",
    "subDistrictNameTh": "หนองใหญ่",
    "subDistrictNameEn": "Nong Yai",
    "postcode": "32140",
    "districtCode": "3205",
    "districtNameTh": "ปราสาท",
    "districtNameEn": "Prasat",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320508",
    "subDistrictNameTh": "โคกยาง",
    "subDistrictNameEn": "Khok Yang",
    "postcode": "32140",
    "districtCode": "3205",
    "districtNameTh": "ปราสาท",
    "districtNameEn": "Prasat",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320509",
    "subDistrictNameTh": "โคกสะอาด",
    "subDistrictNameEn": "Khok Sa-at",
    "postcode": "32140",
    "districtCode": "3205",
    "districtNameTh": "ปราสาท",
    "districtNameEn": "Prasat",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320510",
    "subDistrictNameTh": "บ้านไทร",
    "subDistrictNameEn": "Ban Sai",
    "postcode": "32140",
    "districtCode": "3205",
    "districtNameTh": "ปราสาท",
    "districtNameEn": "Prasat",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320511",
    "subDistrictNameTh": "โชคนาสาม",
    "subDistrictNameEn": "Chok Na Sam",
    "postcode": "32140",
    "districtCode": "3205",
    "districtNameTh": "ปราสาท",
    "districtNameEn": "Prasat",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320512",
    "subDistrictNameTh": "เชื้อเพลิง",
    "subDistrictNameEn": "Chuea Phloeng",
    "postcode": "32140",
    "districtCode": "3205",
    "districtNameTh": "ปราสาท",
    "districtNameEn": "Prasat",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320513",
    "subDistrictNameTh": "ปราสาททนง",
    "subDistrictNameEn": "Prasat Thanong",
    "postcode": "32140",
    "districtCode": "3205",
    "districtNameTh": "ปราสาท",
    "districtNameEn": "Prasat",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320514",
    "subDistrictNameTh": "ตานี",
    "subDistrictNameEn": "Tani",
    "postcode": "32140",
    "districtCode": "3205",
    "districtNameTh": "ปราสาท",
    "districtNameEn": "Prasat",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320515",
    "subDistrictNameTh": "บ้านพลวง",
    "subDistrictNameEn": "Ban Phluang",
    "postcode": "32140",
    "districtCode": "3205",
    "districtNameTh": "ปราสาท",
    "districtNameEn": "Prasat",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320516",
    "subDistrictNameTh": "กันตวจระมวล",
    "subDistrictNameEn": "Kantuat Ramuan",
    "postcode": "32140",
    "districtCode": "3205",
    "districtNameTh": "ปราสาท",
    "districtNameEn": "Prasat",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320517",
    "subDistrictNameTh": "สมุด",
    "subDistrictNameEn": "Samut",
    "postcode": "32140",
    "districtCode": "3205",
    "districtNameTh": "ปราสาท",
    "districtNameEn": "Prasat",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320518",
    "subDistrictNameTh": "ประทัดบุ",
    "subDistrictNameEn": "Prathat Bu",
    "postcode": "32140",
    "districtCode": "3205",
    "districtNameTh": "ปราสาท",
    "districtNameEn": "Prasat",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320601",
    "subDistrictNameTh": "กาบเชิง",
    "subDistrictNameEn": "Kap Choeng",
    "postcode": "32210",
    "districtCode": "3206",
    "districtNameTh": "กาบเชิง",
    "districtNameEn": "Kap Choeng",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320604",
    "subDistrictNameTh": "คูตัน",
    "subDistrictNameEn": "Khu Tan",
    "postcode": "32210",
    "districtCode": "3206",
    "districtNameTh": "กาบเชิง",
    "districtNameEn": "Kap Choeng",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320605",
    "subDistrictNameTh": "ด่าน",
    "subDistrictNameEn": "Dan",
    "postcode": "32210",
    "districtCode": "3206",
    "districtNameTh": "กาบเชิง",
    "districtNameEn": "Kap Choeng",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320606",
    "subDistrictNameTh": "แนงมุด",
    "subDistrictNameEn": "Naeng Mut",
    "postcode": "32210",
    "districtCode": "3206",
    "districtNameTh": "กาบเชิง",
    "districtNameEn": "Kap Choeng",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320607",
    "subDistrictNameTh": "โคกตะเคียน",
    "subDistrictNameEn": "Khok Takhian",
    "postcode": "32210",
    "districtCode": "3206",
    "districtNameTh": "กาบเชิง",
    "districtNameEn": "Kap Choeng",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320610",
    "subDistrictNameTh": "ตะเคียน",
    "subDistrictNameEn": "Takhian",
    "postcode": "32210",
    "districtCode": "3206",
    "districtNameTh": "กาบเชิง",
    "districtNameEn": "Kap Choeng",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320701",
    "subDistrictNameTh": "รัตนบุรี",
    "subDistrictNameEn": "Rattanaburi",
    "postcode": "32130",
    "districtCode": "3207",
    "districtNameTh": "รัตนบุรี",
    "districtNameEn": "Rattanaburi",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320702",
    "subDistrictNameTh": "ธาตุ",
    "subDistrictNameEn": "That",
    "postcode": "32130",
    "districtCode": "3207",
    "districtNameTh": "รัตนบุรี",
    "districtNameEn": "Rattanaburi",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320703",
    "subDistrictNameTh": "แก",
    "subDistrictNameEn": "Kae",
    "postcode": "32130",
    "districtCode": "3207",
    "districtNameTh": "รัตนบุรี",
    "districtNameEn": "Rattanaburi",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320704",
    "subDistrictNameTh": "ดอนแรด",
    "subDistrictNameEn": "Don Raet",
    "postcode": "32130",
    "districtCode": "3207",
    "districtNameTh": "รัตนบุรี",
    "districtNameEn": "Rattanaburi",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320705",
    "subDistrictNameTh": "หนองบัวทอง",
    "subDistrictNameEn": "Nong Bua Thong",
    "postcode": "32130",
    "districtCode": "3207",
    "districtNameTh": "รัตนบุรี",
    "districtNameEn": "Rattanaburi",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320706",
    "subDistrictNameTh": "หนองบัวบาน",
    "subDistrictNameEn": "Nong Bua Ban",
    "postcode": "32130",
    "districtCode": "3207",
    "districtNameTh": "รัตนบุรี",
    "districtNameEn": "Rattanaburi",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320709",
    "subDistrictNameTh": "ไผ่",
    "subDistrictNameEn": "Phai",
    "postcode": "32130",
    "districtCode": "3207",
    "districtNameTh": "รัตนบุรี",
    "districtNameEn": "Rattanaburi",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320711",
    "subDistrictNameTh": "เบิด",
    "subDistrictNameEn": "Boet",
    "postcode": "32130",
    "districtCode": "3207",
    "districtNameTh": "รัตนบุรี",
    "districtNameEn": "Rattanaburi",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320713",
    "subDistrictNameTh": "น้ำเขียว",
    "subDistrictNameEn": "Nam Khiao",
    "postcode": "32130",
    "districtCode": "3207",
    "districtNameTh": "รัตนบุรี",
    "districtNameEn": "Rattanaburi",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320714",
    "subDistrictNameTh": "กุดขาคีม",
    "subDistrictNameEn": "Kut Kha Khim",
    "postcode": "32130",
    "districtCode": "3207",
    "districtNameTh": "รัตนบุรี",
    "districtNameEn": "Rattanaburi",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320715",
    "subDistrictNameTh": "ยางสว่าง",
    "subDistrictNameEn": "Yang Sawang",
    "postcode": "32130",
    "districtCode": "3207",
    "districtNameTh": "รัตนบุรี",
    "districtNameEn": "Rattanaburi",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320716",
    "subDistrictNameTh": "ทับใหญ่",
    "subDistrictNameEn": "Thap Yai",
    "postcode": "32130",
    "districtCode": "3207",
    "districtNameTh": "รัตนบุรี",
    "districtNameEn": "Rattanaburi",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320801",
    "subDistrictNameTh": "สนม",
    "subDistrictNameEn": "Sanom",
    "postcode": "32160",
    "districtCode": "3208",
    "districtNameTh": "สนม",
    "districtNameEn": "Sanom",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320802",
    "subDistrictNameTh": "โพนโก",
    "subDistrictNameEn": "Phon Ko",
    "postcode": "32160",
    "districtCode": "3208",
    "districtNameTh": "สนม",
    "districtNameEn": "Sanom",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320803",
    "subDistrictNameTh": "หนองระฆัง",
    "subDistrictNameEn": "Nong Rakhang",
    "postcode": "32160",
    "districtCode": "3208",
    "districtNameTh": "สนม",
    "districtNameEn": "Sanom",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320804",
    "subDistrictNameTh": "นานวน",
    "subDistrictNameEn": "Na Nuan",
    "postcode": "32160",
    "districtCode": "3208",
    "districtNameTh": "สนม",
    "districtNameEn": "Sanom",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320805",
    "subDistrictNameTh": "แคน",
    "subDistrictNameEn": "Khaen",
    "postcode": "32160",
    "districtCode": "3208",
    "districtNameTh": "สนม",
    "districtNameEn": "Sanom",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320806",
    "subDistrictNameTh": "หัวงัว",
    "subDistrictNameEn": "Hua Ngua",
    "postcode": "32160",
    "districtCode": "3208",
    "districtNameTh": "สนม",
    "districtNameEn": "Sanom",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320807",
    "subDistrictNameTh": "หนองอียอ",
    "subDistrictNameEn": "Nong I Yo",
    "postcode": "32160",
    "districtCode": "3208",
    "districtNameTh": "สนม",
    "districtNameEn": "Sanom",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320901",
    "subDistrictNameTh": "ระแงง",
    "subDistrictNameEn": "Ra-ngaeng",
    "postcode": "32110",
    "districtCode": "3209",
    "districtNameTh": "ศีขรภูมิ",
    "districtNameEn": "Sikhoraphum",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320902",
    "subDistrictNameTh": "ตรึม",
    "subDistrictNameEn": "Truem",
    "postcode": "32110",
    "districtCode": "3209",
    "districtNameTh": "ศีขรภูมิ",
    "districtNameEn": "Sikhoraphum",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320903",
    "subDistrictNameTh": "จารพัต",
    "subDistrictNameEn": "Charaphat",
    "postcode": "32110",
    "districtCode": "3209",
    "districtNameTh": "ศีขรภูมิ",
    "districtNameEn": "Sikhoraphum",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320904",
    "subDistrictNameTh": "ยาง",
    "subDistrictNameEn": "Yang",
    "postcode": "32110",
    "districtCode": "3209",
    "districtNameTh": "ศีขรภูมิ",
    "districtNameEn": "Sikhoraphum",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320905",
    "subDistrictNameTh": "แตล",
    "subDistrictNameEn": "Taen",
    "postcode": "32110",
    "districtCode": "3209",
    "districtNameTh": "ศีขรภูมิ",
    "districtNameEn": "Sikhoraphum",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320906",
    "subDistrictNameTh": "หนองบัว",
    "subDistrictNameEn": "Nong Bua",
    "postcode": "32110",
    "districtCode": "3209",
    "districtNameTh": "ศีขรภูมิ",
    "districtNameEn": "Sikhoraphum",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320907",
    "subDistrictNameTh": "คาละแมะ",
    "subDistrictNameEn": "Khalamae",
    "postcode": "32110",
    "districtCode": "3209",
    "districtNameTh": "ศีขรภูมิ",
    "districtNameEn": "Sikhoraphum",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320908",
    "subDistrictNameTh": "หนองเหล็ก",
    "subDistrictNameEn": "Nong Lek",
    "postcode": "32110",
    "districtCode": "3209",
    "districtNameTh": "ศีขรภูมิ",
    "districtNameEn": "Sikhoraphum",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320909",
    "subDistrictNameTh": "หนองขวาว",
    "subDistrictNameEn": "Nong Khwao",
    "postcode": "32110",
    "districtCode": "3209",
    "districtNameTh": "ศีขรภูมิ",
    "districtNameEn": "Sikhoraphum",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320910",
    "subDistrictNameTh": "ช่างปี่",
    "subDistrictNameEn": "Chang Pi",
    "postcode": "32110",
    "districtCode": "3209",
    "districtNameTh": "ศีขรภูมิ",
    "districtNameEn": "Sikhoraphum",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320911",
    "subDistrictNameTh": "กุดหวาย",
    "subDistrictNameEn": "Kut Wai",
    "postcode": "32110",
    "districtCode": "3209",
    "districtNameTh": "ศีขรภูมิ",
    "districtNameEn": "Sikhoraphum",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320912",
    "subDistrictNameTh": "ขวาวใหญ่",
    "subDistrictNameEn": "Khwao Yai",
    "postcode": "32110",
    "districtCode": "3209",
    "districtNameTh": "ศีขรภูมิ",
    "districtNameEn": "Sikhoraphum",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320913",
    "subDistrictNameTh": "นารุ่ง",
    "subDistrictNameEn": "Na Rung",
    "postcode": "32110",
    "districtCode": "3209",
    "districtNameTh": "ศีขรภูมิ",
    "districtNameEn": "Sikhoraphum",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320914",
    "subDistrictNameTh": "ตรมไพร",
    "subDistrictNameEn": "Trom Phrai",
    "postcode": "32110",
    "districtCode": "3209",
    "districtNameTh": "ศีขรภูมิ",
    "districtNameEn": "Sikhoraphum",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "320915",
    "subDistrictNameTh": "ผักไหม",
    "subDistrictNameEn": "Phak Mai",
    "postcode": "32110",
    "districtCode": "3209",
    "districtNameTh": "ศีขรภูมิ",
    "districtNameEn": "Sikhoraphum",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321001",
    "subDistrictNameTh": "สังขะ",
    "subDistrictNameEn": "Sangkha",
    "postcode": "32150",
    "districtCode": "3210",
    "districtNameTh": "สังขะ",
    "districtNameEn": "Sangkha",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321002",
    "subDistrictNameTh": "ขอนแตก",
    "subDistrictNameEn": "Khon Taek",
    "postcode": "32150",
    "districtCode": "3210",
    "districtNameTh": "สังขะ",
    "districtNameEn": "Sangkha",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321006",
    "subDistrictNameTh": "ดม",
    "subDistrictNameEn": "Dom",
    "postcode": "32150",
    "districtCode": "3210",
    "districtNameTh": "สังขะ",
    "districtNameEn": "Sangkha",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321007",
    "subDistrictNameTh": "พระแก้ว",
    "subDistrictNameEn": "Phra Kaeo",
    "postcode": "32150",
    "districtCode": "3210",
    "districtNameTh": "สังขะ",
    "districtNameEn": "Sangkha",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321008",
    "subDistrictNameTh": "บ้านจารย์",
    "subDistrictNameEn": "Ban Chan",
    "postcode": "32150",
    "districtCode": "3210",
    "districtNameTh": "สังขะ",
    "districtNameEn": "Sangkha",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321009",
    "subDistrictNameTh": "กระเทียม",
    "subDistrictNameEn": "Krathiam",
    "postcode": "32150",
    "districtCode": "3210",
    "districtNameTh": "สังขะ",
    "districtNameEn": "Sangkha",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321010",
    "subDistrictNameTh": "สะกาด",
    "subDistrictNameEn": "Sakat",
    "postcode": "32150",
    "districtCode": "3210",
    "districtNameTh": "สังขะ",
    "districtNameEn": "Sangkha",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321011",
    "subDistrictNameTh": "ตาตุม",
    "subDistrictNameEn": "Ta Tum",
    "postcode": "32150",
    "districtCode": "3210",
    "districtNameTh": "สังขะ",
    "districtNameEn": "Sangkha",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321012",
    "subDistrictNameTh": "ทับทัน",
    "subDistrictNameEn": "Thap Than",
    "postcode": "32150",
    "districtCode": "3210",
    "districtNameTh": "สังขะ",
    "districtNameEn": "Sangkha",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321013",
    "subDistrictNameTh": "ตาคง",
    "subDistrictNameEn": "Ta Khong",
    "postcode": "32150",
    "districtCode": "3210",
    "districtNameTh": "สังขะ",
    "districtNameEn": "Sangkha",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321015",
    "subDistrictNameTh": "บ้านชบ",
    "subDistrictNameEn": "Ban Chop",
    "postcode": "32150",
    "districtCode": "3210",
    "districtNameTh": "สังขะ",
    "districtNameEn": "Sangkha",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321017",
    "subDistrictNameTh": "เทพรักษา",
    "subDistrictNameEn": "Thep Raksa",
    "postcode": "32150",
    "districtCode": "3210",
    "districtNameTh": "สังขะ",
    "districtNameEn": "Sangkha",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321101",
    "subDistrictNameTh": "ลำดวน",
    "subDistrictNameEn": "Lamduan",
    "postcode": "32220",
    "districtCode": "3211",
    "districtNameTh": "ลำดวน",
    "districtNameEn": "Lamduan",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321102",
    "subDistrictNameTh": "โชคเหนือ",
    "subDistrictNameEn": "Chok Nuea",
    "postcode": "32220",
    "districtCode": "3211",
    "districtNameTh": "ลำดวน",
    "districtNameEn": "Lamduan",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321103",
    "subDistrictNameTh": "อู่โลก",
    "subDistrictNameEn": "U Lok",
    "postcode": "32220",
    "districtCode": "3211",
    "districtNameTh": "ลำดวน",
    "districtNameEn": "Lamduan",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321104",
    "subDistrictNameTh": "ตรำดม",
    "subDistrictNameEn": "Tram Dom",
    "postcode": "32220",
    "districtCode": "3211",
    "districtNameTh": "ลำดวน",
    "districtNameEn": "Lamduan",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321105",
    "subDistrictNameTh": "ตระเปียงเตีย",
    "subDistrictNameEn": "Tra Priang Tia",
    "postcode": "32220",
    "districtCode": "3211",
    "districtNameTh": "ลำดวน",
    "districtNameEn": "Lamduan",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321201",
    "subDistrictNameTh": "สำโรงทาบ",
    "subDistrictNameEn": "Samrong Thap",
    "postcode": "32170",
    "districtCode": "3212",
    "districtNameTh": "สำโรงทาบ",
    "districtNameEn": "Samrong Thap",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321202",
    "subDistrictNameTh": "หนองไผ่ล้อม",
    "subDistrictNameEn": "Nong Phai Lom",
    "postcode": "32170",
    "districtCode": "3212",
    "districtNameTh": "สำโรงทาบ",
    "districtNameEn": "Samrong Thap",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321203",
    "subDistrictNameTh": "กระออม",
    "subDistrictNameEn": "Kra-om",
    "postcode": "32170",
    "districtCode": "3212",
    "districtNameTh": "สำโรงทาบ",
    "districtNameEn": "Samrong Thap",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321204",
    "subDistrictNameTh": "หนองฮะ",
    "subDistrictNameEn": "Nong Ha",
    "postcode": "32170",
    "districtCode": "3212",
    "districtNameTh": "สำโรงทาบ",
    "districtNameEn": "Samrong Thap",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321205",
    "subDistrictNameTh": "ศรีสุข",
    "subDistrictNameEn": "Si Suk",
    "postcode": "32170",
    "districtCode": "3212",
    "districtNameTh": "สำโรงทาบ",
    "districtNameEn": "Samrong Thap",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321206",
    "subDistrictNameTh": "เกาะแก้ว",
    "subDistrictNameEn": "Ko Kaeo",
    "postcode": "32170",
    "districtCode": "3212",
    "districtNameTh": "สำโรงทาบ",
    "districtNameEn": "Samrong Thap",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321207",
    "subDistrictNameTh": "หมื่นศรี",
    "subDistrictNameEn": "Muen Si",
    "postcode": "32170",
    "districtCode": "3212",
    "districtNameTh": "สำโรงทาบ",
    "districtNameEn": "Samrong Thap",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321208",
    "subDistrictNameTh": "เสม็จ",
    "subDistrictNameEn": "Samet",
    "postcode": "32170",
    "districtCode": "3212",
    "districtNameTh": "สำโรงทาบ",
    "districtNameEn": "Samrong Thap",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321209",
    "subDistrictNameTh": "สะโน",
    "subDistrictNameEn": "Sano",
    "postcode": "32170",
    "districtCode": "3212",
    "districtNameTh": "สำโรงทาบ",
    "districtNameEn": "Samrong Thap",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321210",
    "subDistrictNameTh": "ประดู่",
    "subDistrictNameEn": "Pradu",
    "postcode": "32170",
    "districtCode": "3212",
    "districtNameTh": "สำโรงทาบ",
    "districtNameEn": "Samrong Thap",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321301",
    "subDistrictNameTh": "บัวเชด",
    "subDistrictNameEn": "Buachet",
    "postcode": "32230",
    "districtCode": "3213",
    "districtNameTh": "บัวเชด",
    "districtNameEn": "Buachet",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321302",
    "subDistrictNameTh": "สะเดา",
    "subDistrictNameEn": "Sadao",
    "postcode": "32230",
    "districtCode": "3213",
    "districtNameTh": "บัวเชด",
    "districtNameEn": "Buachet",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321303",
    "subDistrictNameTh": "จรัส",
    "subDistrictNameEn": "Charat",
    "postcode": "32230",
    "districtCode": "3213",
    "districtNameTh": "บัวเชด",
    "districtNameEn": "Buachet",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321304",
    "subDistrictNameTh": "ตาวัง",
    "subDistrictNameEn": "Ta Wang",
    "postcode": "32230",
    "districtCode": "3213",
    "districtNameTh": "บัวเชด",
    "districtNameEn": "Buachet",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321305",
    "subDistrictNameTh": "อาโพน",
    "subDistrictNameEn": "A Phon",
    "postcode": "32230",
    "districtCode": "3213",
    "districtNameTh": "บัวเชด",
    "districtNameEn": "Buachet",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321306",
    "subDistrictNameTh": "สำเภาลูน",
    "subDistrictNameEn": "Samphao Lun",
    "postcode": "32230",
    "districtCode": "3213",
    "districtNameTh": "บัวเชด",
    "districtNameEn": "Buachet",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321401",
    "subDistrictNameTh": "บักได",
    "subDistrictNameEn": "Bak Dai",
    "postcode": "32140",
    "districtCode": "3214",
    "districtNameTh": "พนมดงรัก",
    "districtNameEn": "Phanom Dong Rak",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321402",
    "subDistrictNameTh": "โคกกลาง",
    "subDistrictNameEn": "Khok Klang",
    "postcode": "32140",
    "districtCode": "3214",
    "districtNameTh": "พนมดงรัก",
    "districtNameEn": "Phanom Dong Rak",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321403",
    "subDistrictNameTh": "จีกแดก",
    "subDistrictNameEn": "Chik Daek",
    "postcode": "32140",
    "districtCode": "3214",
    "districtNameTh": "พนมดงรัก",
    "districtNameEn": "Phanom Dong Rak",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321404",
    "subDistrictNameTh": "ตาเมียง",
    "subDistrictNameEn": "Ta Miang",
    "postcode": "32140",
    "districtCode": "3214",
    "districtNameTh": "พนมดงรัก",
    "districtNameEn": "Phanom Dong Rak",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321501",
    "subDistrictNameTh": "ณรงค์",
    "subDistrictNameEn": "Narong",
    "postcode": "32150",
    "districtCode": "3215",
    "districtNameTh": "ศรีณรงค์",
    "districtNameEn": "Si Narong",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321502",
    "subDistrictNameTh": "แจนแวน",
    "subDistrictNameEn": "Chaenwaen",
    "postcode": "32150",
    "districtCode": "3215",
    "districtNameTh": "ศรีณรงค์",
    "districtNameEn": "Si Narong",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321503",
    "subDistrictNameTh": "ตรวจ",
    "subDistrictNameEn": "Truat",
    "postcode": "32150",
    "districtCode": "3215",
    "districtNameTh": "ศรีณรงค์",
    "districtNameEn": "Si Narong",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321504",
    "subDistrictNameTh": "หนองแวง",
    "subDistrictNameEn": "Nong Waeng",
    "postcode": "32150",
    "districtCode": "3215",
    "districtNameTh": "ศรีณรงค์",
    "districtNameEn": "Si Narong",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321505",
    "subDistrictNameTh": "ศรีสุข",
    "subDistrictNameEn": "Si Suk",
    "postcode": "32150",
    "districtCode": "3215",
    "districtNameTh": "ศรีณรงค์",
    "districtNameEn": "Si Narong",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321601",
    "subDistrictNameTh": "เขวาสินรินทร์",
    "subDistrictNameEn": "Khwao Sinrin",
    "postcode": "32000",
    "districtCode": "3216",
    "districtNameTh": "เขวาสินรินทร์",
    "districtNameEn": "Khwao Sinrin",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321602",
    "subDistrictNameTh": "บึง",
    "subDistrictNameEn": "Bueng",
    "postcode": "32000",
    "districtCode": "3216",
    "districtNameTh": "เขวาสินรินทร์",
    "districtNameEn": "Khwao Sinrin",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321603",
    "subDistrictNameTh": "ตากูก",
    "subDistrictNameEn": "Ta Kuk",
    "postcode": "32000",
    "districtCode": "3216",
    "districtNameTh": "เขวาสินรินทร์",
    "districtNameEn": "Khwao Sinrin",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321604",
    "subDistrictNameTh": "ปราสาททอง",
    "subDistrictNameEn": "Prasat Thong",
    "postcode": "32000",
    "districtCode": "3216",
    "districtNameTh": "เขวาสินรินทร์",
    "districtNameEn": "Khwao Sinrin",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321605",
    "subDistrictNameTh": "บ้านแร่",
    "subDistrictNameEn": "Ban Rae",
    "postcode": "32000",
    "districtCode": "3216",
    "districtNameTh": "เขวาสินรินทร์",
    "districtNameEn": "Khwao Sinrin",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321701",
    "subDistrictNameTh": "หนองหลวง",
    "subDistrictNameEn": "Nong Luang",
    "postcode": "32130",
    "districtCode": "3217",
    "districtNameTh": "โนนนารายณ์",
    "districtNameEn": "Non Narai",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321702",
    "subDistrictNameTh": "คำผง",
    "subDistrictNameEn": "Kham Phong",
    "postcode": "32130",
    "districtCode": "3217",
    "districtNameTh": "โนนนารายณ์",
    "districtNameEn": "Non Narai",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321703",
    "subDistrictNameTh": "โนน",
    "subDistrictNameEn": "Non",
    "postcode": "32130",
    "districtCode": "3217",
    "districtNameTh": "โนนนารายณ์",
    "districtNameEn": "Non Narai",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321704",
    "subDistrictNameTh": "ระเวียง",
    "subDistrictNameEn": "Rawiang",
    "postcode": "32130",
    "districtCode": "3217",
    "districtNameTh": "โนนนารายณ์",
    "districtNameEn": "Non Narai",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "321705",
    "subDistrictNameTh": "หนองเทพ",
    "subDistrictNameEn": "Nong Thep",
    "postcode": "32130",
    "districtCode": "3217",
    "districtNameTh": "โนนนารายณ์",
    "districtNameEn": "Non Narai",
    "provinceCode": "32",
    "provinceNameTh": "สุรินทร์",
    "provinceNameEn": "Surin"
},
{
    "subDistrictCode": "330101",
    "subDistrictNameTh": "เมืองเหนือ",
    "subDistrictNameEn": "Mueang Nuea",
    "postcode": "33000",
    "districtCode": "3301",
    "districtNameTh": "เมืองศรีสะเกษ",
    "districtNameEn": "Mueang Si Sa Ket",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330102",
    "subDistrictNameTh": "เมืองใต้",
    "subDistrictNameEn": "Mueang Tai",
    "postcode": "33000",
    "districtCode": "3301",
    "districtNameTh": "เมืองศรีสะเกษ",
    "districtNameEn": "Mueang Si Sa Ket",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330103",
    "subDistrictNameTh": "คูซอด",
    "subDistrictNameEn": "Khu Sot",
    "postcode": "33000",
    "districtCode": "3301",
    "districtNameTh": "เมืองศรีสะเกษ",
    "districtNameEn": "Mueang Si Sa Ket",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330104",
    "subDistrictNameTh": "ซำ",
    "subDistrictNameEn": "Sam",
    "postcode": "33000",
    "districtCode": "3301",
    "districtNameTh": "เมืองศรีสะเกษ",
    "districtNameEn": "Mueang Si Sa Ket",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330105",
    "subDistrictNameTh": "จาน",
    "subDistrictNameEn": "Chan",
    "postcode": "33000",
    "districtCode": "3301",
    "districtNameTh": "เมืองศรีสะเกษ",
    "districtNameEn": "Mueang Si Sa Ket",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330106",
    "subDistrictNameTh": "ตะดอบ",
    "subDistrictNameEn": "Tadop",
    "postcode": "33000",
    "districtCode": "3301",
    "districtNameTh": "เมืองศรีสะเกษ",
    "districtNameEn": "Mueang Si Sa Ket",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330107",
    "subDistrictNameTh": "หนองครก",
    "subDistrictNameEn": "Nong Khrok",
    "postcode": "33000",
    "districtCode": "3301",
    "districtNameTh": "เมืองศรีสะเกษ",
    "districtNameEn": "Mueang Si Sa Ket",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330111",
    "subDistrictNameTh": "โพนข่า",
    "subDistrictNameEn": "Phon Kha",
    "postcode": "33000",
    "districtCode": "3301",
    "districtNameTh": "เมืองศรีสะเกษ",
    "districtNameEn": "Mueang Si Sa Ket",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330112",
    "subDistrictNameTh": "โพนค้อ",
    "subDistrictNameEn": "Phon Kho",
    "postcode": "33000",
    "districtCode": "3301",
    "districtNameTh": "เมืองศรีสะเกษ",
    "districtNameEn": "Mueang Si Sa Ket",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330115",
    "subDistrictNameTh": "โพนเขวา",
    "subDistrictNameEn": "Phon Khwao",
    "postcode": "33000",
    "districtCode": "3301",
    "districtNameTh": "เมืองศรีสะเกษ",
    "districtNameEn": "Mueang Si Sa Ket",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330116",
    "subDistrictNameTh": "หญ้าปล้อง",
    "subDistrictNameEn": "Ya Plong",
    "postcode": "33000",
    "districtCode": "3301",
    "districtNameTh": "เมืองศรีสะเกษ",
    "districtNameEn": "Mueang Si Sa Ket",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330118",
    "subDistrictNameTh": "ทุ่ม",
    "subDistrictNameEn": "Thum",
    "postcode": "33000",
    "districtCode": "3301",
    "districtNameTh": "เมืองศรีสะเกษ",
    "districtNameEn": "Mueang Si Sa Ket",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330119",
    "subDistrictNameTh": "หนองไฮ",
    "subDistrictNameEn": "Nong Hai",
    "postcode": "33000",
    "districtCode": "3301",
    "districtNameTh": "เมืองศรีสะเกษ",
    "districtNameEn": "Mueang Si Sa Ket",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330121",
    "subDistrictNameTh": "หนองแก้ว",
    "subDistrictNameEn": "Nong Kaeo",
    "postcode": "33000",
    "districtCode": "3301",
    "districtNameTh": "เมืองศรีสะเกษ",
    "districtNameEn": "Mueang Si Sa Ket",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330122",
    "subDistrictNameTh": "น้ำคำ",
    "subDistrictNameEn": "Nam Kham",
    "postcode": "33000",
    "districtCode": "3301",
    "districtNameTh": "เมืองศรีสะเกษ",
    "districtNameEn": "Mueang Si Sa Ket",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330123",
    "subDistrictNameTh": "โพธิ์",
    "subDistrictNameEn": "Pho",
    "postcode": "33000",
    "districtCode": "3301",
    "districtNameTh": "เมืองศรีสะเกษ",
    "districtNameEn": "Mueang Si Sa Ket",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330124",
    "subDistrictNameTh": "หมากเขียบ",
    "subDistrictNameEn": "Mak Khiap",
    "postcode": "33000",
    "districtCode": "3301",
    "districtNameTh": "เมืองศรีสะเกษ",
    "districtNameEn": "Mueang Si Sa Ket",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330127",
    "subDistrictNameTh": "หนองไผ่",
    "subDistrictNameEn": "Nong Phai",
    "postcode": "33000",
    "districtCode": "3301",
    "districtNameTh": "เมืองศรีสะเกษ",
    "districtNameEn": "Mueang Si Sa Ket",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330201",
    "subDistrictNameTh": "ยางชุมน้อย",
    "subDistrictNameEn": "Yang Chum Noi",
    "postcode": "33190",
    "districtCode": "3302",
    "districtNameTh": "ยางชุมน้อย",
    "districtNameEn": "Yang Chum Noi",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330202",
    "subDistrictNameTh": "ลิ้นฟ้า",
    "subDistrictNameEn": "Lin Fa",
    "postcode": "33190",
    "districtCode": "3302",
    "districtNameTh": "ยางชุมน้อย",
    "districtNameEn": "Yang Chum Noi",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330203",
    "subDistrictNameTh": "คอนกาม",
    "subDistrictNameEn": "Khon Kam",
    "postcode": "33190",
    "districtCode": "3302",
    "districtNameTh": "ยางชุมน้อย",
    "districtNameEn": "Yang Chum Noi",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330204",
    "subDistrictNameTh": "โนนคูณ",
    "subDistrictNameEn": "Non Khun",
    "postcode": "33190",
    "districtCode": "3302",
    "districtNameTh": "ยางชุมน้อย",
    "districtNameEn": "Yang Chum Noi",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330205",
    "subDistrictNameTh": "กุดเมืองฮาม",
    "subDistrictNameEn": "Kut Mueang Ham",
    "postcode": "33190",
    "districtCode": "3302",
    "districtNameTh": "ยางชุมน้อย",
    "districtNameEn": "Yang Chum Noi",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330206",
    "subDistrictNameTh": "บึงบอน",
    "subDistrictNameEn": "Bueng Bon",
    "postcode": "33190",
    "districtCode": "3302",
    "districtNameTh": "ยางชุมน้อย",
    "districtNameEn": "Yang Chum Noi",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330207",
    "subDistrictNameTh": "ยางชุมใหญ่",
    "subDistrictNameEn": "Yang Chum Yai",
    "postcode": "33190",
    "districtCode": "3302",
    "districtNameTh": "ยางชุมน้อย",
    "districtNameEn": "Yang Chum Noi",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330301",
    "subDistrictNameTh": "ดูน",
    "subDistrictNameEn": "Dun",
    "postcode": "33130",
    "districtCode": "3303",
    "districtNameTh": "กันทรารมย์",
    "districtNameEn": "Kanthararom",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330302",
    "subDistrictNameTh": "โนนสัง",
    "subDistrictNameEn": "Non Sang",
    "postcode": "33130",
    "districtCode": "3303",
    "districtNameTh": "กันทรารมย์",
    "districtNameEn": "Kanthararom",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330303",
    "subDistrictNameTh": "หนองหัวช้าง",
    "subDistrictNameEn": "Nong Hua Chang",
    "postcode": "33130",
    "districtCode": "3303",
    "districtNameTh": "กันทรารมย์",
    "districtNameEn": "Kanthararom",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330304",
    "subDistrictNameTh": "ยาง",
    "subDistrictNameEn": "Yang",
    "postcode": "33130",
    "districtCode": "3303",
    "districtNameTh": "กันทรารมย์",
    "districtNameEn": "Kanthararom",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330305",
    "subDistrictNameTh": "หนองแวง",
    "subDistrictNameEn": "Nong Waeng",
    "postcode": "33130",
    "districtCode": "3303",
    "districtNameTh": "กันทรารมย์",
    "districtNameEn": "Kanthararom",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330306",
    "subDistrictNameTh": "หนองแก้ว",
    "subDistrictNameEn": "Nong Kaeo",
    "postcode": "33130",
    "districtCode": "3303",
    "districtNameTh": "กันทรารมย์",
    "districtNameEn": "Kanthararom",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330307",
    "subDistrictNameTh": "ทาม",
    "subDistrictNameEn": "Tham",
    "postcode": "33130",
    "districtCode": "3303",
    "districtNameTh": "กันทรารมย์",
    "districtNameEn": "Kanthararom",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330308",
    "subDistrictNameTh": "ละทาย",
    "subDistrictNameEn": "Lathai",
    "postcode": "33130",
    "districtCode": "3303",
    "districtNameTh": "กันทรารมย์",
    "districtNameEn": "Kanthararom",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330309",
    "subDistrictNameTh": "เมืองน้อย",
    "subDistrictNameEn": "Mueang Noi",
    "postcode": "33130",
    "districtCode": "3303",
    "districtNameTh": "กันทรารมย์",
    "districtNameEn": "Kanthararom",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330310",
    "subDistrictNameTh": "อีปาด",
    "subDistrictNameEn": "I Pat",
    "postcode": "33130",
    "districtCode": "3303",
    "districtNameTh": "กันทรารมย์",
    "districtNameEn": "Kanthararom",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330311",
    "subDistrictNameTh": "บัวน้อย",
    "subDistrictNameEn": "Bua Noi",
    "postcode": "33130",
    "districtCode": "3303",
    "districtNameTh": "กันทรารมย์",
    "districtNameEn": "Kanthararom",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330312",
    "subDistrictNameTh": "หนองบัว",
    "subDistrictNameEn": "Nong Bua",
    "postcode": "33130",
    "districtCode": "3303",
    "districtNameTh": "กันทรารมย์",
    "districtNameEn": "Kanthararom",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330313",
    "subDistrictNameTh": "ดู่",
    "subDistrictNameEn": "Du",
    "postcode": "33130",
    "districtCode": "3303",
    "districtNameTh": "กันทรารมย์",
    "districtNameEn": "Kanthararom",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330314",
    "subDistrictNameTh": "ผักแพว",
    "subDistrictNameEn": "Phak Phaeo",
    "postcode": "33130",
    "districtCode": "3303",
    "districtNameTh": "กันทรารมย์",
    "districtNameEn": "Kanthararom",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330315",
    "subDistrictNameTh": "จาน",
    "subDistrictNameEn": "Chan",
    "postcode": "33130",
    "districtCode": "3303",
    "districtNameTh": "กันทรารมย์",
    "districtNameEn": "Kanthararom",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330320",
    "subDistrictNameTh": "คำเนียม",
    "subDistrictNameEn": "Kham Niam",
    "postcode": "33130",
    "districtCode": "3303",
    "districtNameTh": "กันทรารมย์",
    "districtNameEn": "Kanthararom",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330401",
    "subDistrictNameTh": "บึงมะลู",
    "subDistrictNameEn": "Bueng Malu",
    "postcode": "33110",
    "districtCode": "3304",
    "districtNameTh": "กันทรลักษ์",
    "districtNameEn": "Kantharalak",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330402",
    "subDistrictNameTh": "กุดเสลา",
    "subDistrictNameEn": "Kut Salao",
    "postcode": "33110",
    "districtCode": "3304",
    "districtNameTh": "กันทรลักษ์",
    "districtNameEn": "Kantharalak",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330403",
    "subDistrictNameTh": "เมือง",
    "subDistrictNameEn": "Mueang",
    "postcode": "33110",
    "districtCode": "3304",
    "districtNameTh": "กันทรลักษ์",
    "districtNameEn": "Kantharalak",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330405",
    "subDistrictNameTh": "สังเม็ก",
    "subDistrictNameEn": "Sang Mek",
    "postcode": "33110",
    "districtCode": "3304",
    "districtNameTh": "กันทรลักษ์",
    "districtNameEn": "Kantharalak",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330406",
    "subDistrictNameTh": "น้ำอ้อม",
    "subDistrictNameEn": "Nam Om",
    "postcode": "33110",
    "districtCode": "3304",
    "districtNameTh": "กันทรลักษ์",
    "districtNameEn": "Kantharalak",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330407",
    "subDistrictNameTh": "ละลาย",
    "subDistrictNameEn": "Lalai",
    "postcode": "33110",
    "districtCode": "3304",
    "districtNameTh": "กันทรลักษ์",
    "districtNameEn": "Kantharalak",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330408",
    "subDistrictNameTh": "รุง",
    "subDistrictNameEn": "Rung",
    "postcode": "33110",
    "districtCode": "3304",
    "districtNameTh": "กันทรลักษ์",
    "districtNameEn": "Kantharalak",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330409",
    "subDistrictNameTh": "ตระกาจ",
    "subDistrictNameEn": "Trakat",
    "postcode": "33110",
    "districtCode": "3304",
    "districtNameTh": "กันทรลักษ์",
    "districtNameEn": "Kantharalak",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330411",
    "subDistrictNameTh": "จานใหญ่",
    "subDistrictNameEn": "Chan Yai",
    "postcode": "33110",
    "districtCode": "3304",
    "districtNameTh": "กันทรลักษ์",
    "districtNameEn": "Kantharalak",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330412",
    "subDistrictNameTh": "ภูเงิน",
    "subDistrictNameEn": "Phu Ngoen",
    "postcode": "33110",
    "districtCode": "3304",
    "districtNameTh": "กันทรลักษ์",
    "districtNameEn": "Kantharalak",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330413",
    "subDistrictNameTh": "ชำ",
    "subDistrictNameEn": "Cham",
    "postcode": "33110",
    "districtCode": "3304",
    "districtNameTh": "กันทรลักษ์",
    "districtNameEn": "Kantharalak",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330414",
    "subDistrictNameTh": "กระแชง",
    "subDistrictNameEn": "Krachaeng",
    "postcode": "33110",
    "districtCode": "3304",
    "districtNameTh": "กันทรลักษ์",
    "districtNameEn": "Kantharalak",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330415",
    "subDistrictNameTh": "โนนสำราญ",
    "subDistrictNameEn": "Non Samran",
    "postcode": "33110",
    "districtCode": "3304",
    "districtNameTh": "กันทรลักษ์",
    "districtNameEn": "Kantharalak",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330416",
    "subDistrictNameTh": "หนองหญ้าลาด",
    "subDistrictNameEn": "Nong Ya Lat",
    "postcode": "33110",
    "districtCode": "3304",
    "districtNameTh": "กันทรลักษ์",
    "districtNameEn": "Kantharalak",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330419",
    "subDistrictNameTh": "เสาธงชัย",
    "subDistrictNameEn": "Sao Thong Chai",
    "postcode": "33110",
    "districtCode": "3304",
    "districtNameTh": "กันทรลักษ์",
    "districtNameEn": "Kantharalak",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330420",
    "subDistrictNameTh": "ขนุน",
    "subDistrictNameEn": "Khanun",
    "postcode": "33110",
    "districtCode": "3304",
    "districtNameTh": "กันทรลักษ์",
    "districtNameEn": "Kantharalak",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330421",
    "subDistrictNameTh": "สวนกล้วย",
    "subDistrictNameEn": "Suan Kluai",
    "postcode": "33110",
    "districtCode": "3304",
    "districtNameTh": "กันทรลักษ์",
    "districtNameEn": "Kantharalak",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330423",
    "subDistrictNameTh": "เวียงเหนือ",
    "subDistrictNameEn": "Wiang Nuea",
    "postcode": "33110",
    "districtCode": "3304",
    "districtNameTh": "กันทรลักษ์",
    "districtNameEn": "Kantharalak",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330424",
    "subDistrictNameTh": "ทุ่งใหญ่",
    "subDistrictNameEn": "Thung Yai",
    "postcode": "33110",
    "districtCode": "3304",
    "districtNameTh": "กันทรลักษ์",
    "districtNameEn": "Kantharalak",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330425",
    "subDistrictNameTh": "ภูผาหมอก",
    "subDistrictNameEn": "Phu Pha Mok",
    "postcode": "33110",
    "districtCode": "3304",
    "districtNameTh": "กันทรลักษ์",
    "districtNameEn": "Kantharalak",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330501",
    "subDistrictNameTh": "กันทรารมย์",
    "subDistrictNameEn": "Kanthararom",
    "postcode": "33140",
    "districtCode": "3305",
    "districtNameTh": "ขุขันธ์",
    "districtNameEn": "Khukhan",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330502",
    "subDistrictNameTh": "จะกง",
    "subDistrictNameEn": "Chakong",
    "postcode": "33140",
    "districtCode": "3305",
    "districtNameTh": "ขุขันธ์",
    "districtNameEn": "Khukhan",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330503",
    "subDistrictNameTh": "ใจดี",
    "subDistrictNameEn": "Chai Di",
    "postcode": "33140",
    "districtCode": "3305",
    "districtNameTh": "ขุขันธ์",
    "districtNameEn": "Khukhan",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330504",
    "subDistrictNameTh": "ดองกำเม็ด",
    "subDistrictNameEn": "Dong Kam Met",
    "postcode": "33140",
    "districtCode": "3305",
    "districtNameTh": "ขุขันธ์",
    "districtNameEn": "Khukhan",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330505",
    "subDistrictNameTh": "โสน",
    "subDistrictNameEn": "Sano",
    "postcode": "33140",
    "districtCode": "3305",
    "districtNameTh": "ขุขันธ์",
    "districtNameEn": "Khukhan",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330506",
    "subDistrictNameTh": "ปรือใหญ่",
    "subDistrictNameEn": "Prue Yai",
    "postcode": "33140",
    "districtCode": "3305",
    "districtNameTh": "ขุขันธ์",
    "districtNameEn": "Khukhan",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330507",
    "subDistrictNameTh": "สะเดาใหญ่",
    "subDistrictNameEn": "Sadao Yai",
    "postcode": "33140",
    "districtCode": "3305",
    "districtNameTh": "ขุขันธ์",
    "districtNameEn": "Khukhan",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330508",
    "subDistrictNameTh": "ตาอุด",
    "subDistrictNameEn": "Ta Ut",
    "postcode": "33140",
    "districtCode": "3305",
    "districtNameTh": "ขุขันธ์",
    "districtNameEn": "Khukhan",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330509",
    "subDistrictNameTh": "ห้วยเหนือ",
    "subDistrictNameEn": "Huai Nuea",
    "postcode": "33140",
    "districtCode": "3305",
    "districtNameTh": "ขุขันธ์",
    "districtNameEn": "Khukhan",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330510",
    "subDistrictNameTh": "ห้วยใต้",
    "subDistrictNameEn": "Huai Tai",
    "postcode": "33140",
    "districtCode": "3305",
    "districtNameTh": "ขุขันธ์",
    "districtNameEn": "Khukhan",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330511",
    "subDistrictNameTh": "หัวเสือ",
    "subDistrictNameEn": "Hua Suea",
    "postcode": "33140",
    "districtCode": "3305",
    "districtNameTh": "ขุขันธ์",
    "districtNameEn": "Khukhan",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330513",
    "subDistrictNameTh": "ตะเคียน",
    "subDistrictNameEn": "Takhian",
    "postcode": "33140",
    "districtCode": "3305",
    "districtNameTh": "ขุขันธ์",
    "districtNameEn": "Khukhan",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330515",
    "subDistrictNameTh": "นิคมพัฒนา",
    "subDistrictNameEn": "Nikhom Phatthana",
    "postcode": "33140",
    "districtCode": "3305",
    "districtNameTh": "ขุขันธ์",
    "districtNameEn": "Khukhan",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330517",
    "subDistrictNameTh": "โคกเพชร",
    "subDistrictNameEn": "Khok Phet",
    "postcode": "33140",
    "districtCode": "3305",
    "districtNameTh": "ขุขันธ์",
    "districtNameEn": "Khukhan",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330518",
    "subDistrictNameTh": "ปราสาท",
    "subDistrictNameEn": "Prasat",
    "postcode": "33140",
    "districtCode": "3305",
    "districtNameTh": "ขุขันธ์",
    "districtNameEn": "Khukhan",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330521",
    "subDistrictNameTh": "สำโรงตาเจ็น",
    "subDistrictNameEn": "Samrong Ta Chen",
    "postcode": "33140",
    "districtCode": "3305",
    "districtNameTh": "ขุขันธ์",
    "districtNameEn": "Khukhan",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330522",
    "subDistrictNameTh": "ห้วยสำราญ",
    "subDistrictNameEn": "Huai Samran",
    "postcode": "33140",
    "districtCode": "3305",
    "districtNameTh": "ขุขันธ์",
    "districtNameEn": "Khukhan",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330524",
    "subDistrictNameTh": "กฤษณา",
    "subDistrictNameEn": "Kritsana",
    "postcode": "33140",
    "districtCode": "3305",
    "districtNameTh": "ขุขันธ์",
    "districtNameEn": "Khukhan",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330525",
    "subDistrictNameTh": "ลมศักดิ์",
    "subDistrictNameEn": "Lom Sak",
    "postcode": "33140",
    "districtCode": "3305",
    "districtNameTh": "ขุขันธ์",
    "districtNameEn": "Khukhan",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330526",
    "subDistrictNameTh": "หนองฉลอง",
    "subDistrictNameEn": "Nong Chalong",
    "postcode": "33140",
    "districtCode": "3305",
    "districtNameTh": "ขุขันธ์",
    "districtNameEn": "Khukhan",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330527",
    "subDistrictNameTh": "ศรีตระกูล",
    "subDistrictNameEn": "Si Trakun",
    "postcode": "33140",
    "districtCode": "3305",
    "districtNameTh": "ขุขันธ์",
    "districtNameEn": "Khukhan",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330528",
    "subDistrictNameTh": "ศรีสะอาด",
    "subDistrictNameEn": "Si Sa-at",
    "postcode": "33140",
    "districtCode": "3305",
    "districtNameTh": "ขุขันธ์",
    "districtNameEn": "Khukhan",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330601",
    "subDistrictNameTh": "ไพรบึง",
    "subDistrictNameEn": "Phrai Bueng",
    "postcode": "33180",
    "districtCode": "3306",
    "districtNameTh": "ไพรบึง",
    "districtNameEn": "Phrai Bueng",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330602",
    "subDistrictNameTh": "ดินแดง",
    "subDistrictNameEn": "Din Daeng",
    "postcode": "33180",
    "districtCode": "3306",
    "districtNameTh": "ไพรบึง",
    "districtNameEn": "Phrai Bueng",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330603",
    "subDistrictNameTh": "ปราสาทเยอ",
    "subDistrictNameEn": "Prasat Yoe",
    "postcode": "33180",
    "districtCode": "3306",
    "districtNameTh": "ไพรบึง",
    "districtNameEn": "Phrai Bueng",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330604",
    "subDistrictNameTh": "สำโรงพลัน",
    "subDistrictNameEn": "Samrong Phlan",
    "postcode": "33180",
    "districtCode": "3306",
    "districtNameTh": "ไพรบึง",
    "districtNameEn": "Phrai Bueng",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330605",
    "subDistrictNameTh": "สุขสวัสดิ์",
    "subDistrictNameEn": "Suk Sawat",
    "postcode": "33180",
    "districtCode": "3306",
    "districtNameTh": "ไพรบึง",
    "districtNameEn": "Phrai Bueng",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330606",
    "subDistrictNameTh": "โนนปูน",
    "subDistrictNameEn": "Non Pun",
    "postcode": "33180",
    "districtCode": "3306",
    "districtNameTh": "ไพรบึง",
    "districtNameEn": "Phrai Bueng",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330701",
    "subDistrictNameTh": "พิมาย",
    "subDistrictNameEn": "Phimai",
    "postcode": "33170",
    "districtCode": "3307",
    "districtNameTh": "ปรางค์กู่",
    "districtNameEn": "Prang Ku",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330702",
    "subDistrictNameTh": "กู่",
    "subDistrictNameEn": "Ku",
    "postcode": "33170",
    "districtCode": "3307",
    "districtNameTh": "ปรางค์กู่",
    "districtNameEn": "Prang Ku",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330703",
    "subDistrictNameTh": "หนองเชียงทูน",
    "subDistrictNameEn": "Nong Chiang Thun",
    "postcode": "33170",
    "districtCode": "3307",
    "districtNameTh": "ปรางค์กู่",
    "districtNameEn": "Prang Ku",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330704",
    "subDistrictNameTh": "ตูม",
    "subDistrictNameEn": "Tum",
    "postcode": "33170",
    "districtCode": "3307",
    "districtNameTh": "ปรางค์กู่",
    "districtNameEn": "Prang Ku",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330705",
    "subDistrictNameTh": "สมอ",
    "subDistrictNameEn": "Samo",
    "postcode": "33170",
    "districtCode": "3307",
    "districtNameTh": "ปรางค์กู่",
    "districtNameEn": "Prang Ku",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330706",
    "subDistrictNameTh": "โพธิ์ศรี",
    "subDistrictNameEn": "Pho Si",
    "postcode": "33170",
    "districtCode": "3307",
    "districtNameTh": "ปรางค์กู่",
    "districtNameEn": "Prang Ku",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330707",
    "subDistrictNameTh": "สำโรงปราสาท",
    "subDistrictNameEn": "Samrong Prasat",
    "postcode": "33170",
    "districtCode": "3307",
    "districtNameTh": "ปรางค์กู่",
    "districtNameEn": "Prang Ku",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330708",
    "subDistrictNameTh": "ดู่",
    "subDistrictNameEn": "Du",
    "postcode": "33170",
    "districtCode": "3307",
    "districtNameTh": "ปรางค์กู่",
    "districtNameEn": "Prang Ku",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330709",
    "subDistrictNameTh": "สวาย",
    "subDistrictNameEn": "Sawai",
    "postcode": "33170",
    "districtCode": "3307",
    "districtNameTh": "ปรางค์กู่",
    "districtNameEn": "Prang Ku",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330710",
    "subDistrictNameTh": "พิมายเหนือ",
    "subDistrictNameEn": "Phimai Nuea",
    "postcode": "33170",
    "districtCode": "3307",
    "districtNameTh": "ปรางค์กู่",
    "districtNameEn": "Prang Ku",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330801",
    "subDistrictNameTh": "สิ",
    "subDistrictNameEn": "Si",
    "postcode": "33150",
    "districtCode": "3308",
    "districtNameTh": "ขุนหาญ",
    "districtNameEn": "Khun Han",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330802",
    "subDistrictNameTh": "บักดอง",
    "subDistrictNameEn": "Bak Dong",
    "postcode": "33150",
    "districtCode": "3308",
    "districtNameTh": "ขุนหาญ",
    "districtNameEn": "Khun Han",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330803",
    "subDistrictNameTh": "พราน",
    "subDistrictNameEn": "Phran",
    "postcode": "33150",
    "districtCode": "3308",
    "districtNameTh": "ขุนหาญ",
    "districtNameEn": "Khun Han",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330804",
    "subDistrictNameTh": "โพธิ์วงศ์",
    "subDistrictNameEn": "Pho Wong",
    "postcode": "33150",
    "districtCode": "3308",
    "districtNameTh": "ขุนหาญ",
    "districtNameEn": "Khun Han",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330805",
    "subDistrictNameTh": "ไพร",
    "subDistrictNameEn": "Phrai",
    "postcode": "33150",
    "districtCode": "3308",
    "districtNameTh": "ขุนหาญ",
    "districtNameEn": "Khun Han",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330806",
    "subDistrictNameTh": "กระหวัน",
    "subDistrictNameEn": "Krawan",
    "postcode": "33150",
    "districtCode": "3308",
    "districtNameTh": "ขุนหาญ",
    "districtNameEn": "Khun Han",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330807",
    "subDistrictNameTh": "ขุนหาญ",
    "subDistrictNameEn": "Khun Han",
    "postcode": "33150",
    "districtCode": "3308",
    "districtNameTh": "ขุนหาญ",
    "districtNameEn": "Khun Han",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330808",
    "subDistrictNameTh": "โนนสูง",
    "subDistrictNameEn": "Non Sung",
    "postcode": "33150",
    "districtCode": "3308",
    "districtNameTh": "ขุนหาญ",
    "districtNameEn": "Khun Han",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330809",
    "subDistrictNameTh": "กันทรอม",
    "subDistrictNameEn": "Kanthrom",
    "postcode": "33150",
    "districtCode": "3308",
    "districtNameTh": "ขุนหาญ",
    "districtNameEn": "Khun Han",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330810",
    "subDistrictNameTh": "ภูฝ้าย",
    "subDistrictNameEn": "Phu Fai",
    "postcode": "33150",
    "districtCode": "3308",
    "districtNameTh": "ขุนหาญ",
    "districtNameEn": "Khun Han",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330811",
    "subDistrictNameTh": "โพธิ์กระสังข์",
    "subDistrictNameEn": "Pho Krasang",
    "postcode": "33150",
    "districtCode": "3308",
    "districtNameTh": "ขุนหาญ",
    "districtNameEn": "Khun Han",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330812",
    "subDistrictNameTh": "ห้วยจันทร์",
    "subDistrictNameEn": "Huai Chan",
    "postcode": "33150",
    "districtCode": "3308",
    "districtNameTh": "ขุนหาญ",
    "districtNameEn": "Khun Han",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330901",
    "subDistrictNameTh": "เมืองคง",
    "subDistrictNameEn": "Mueang Khong",
    "postcode": "33160",
    "districtCode": "3309",
    "districtNameTh": "ราษีไศล",
    "districtNameEn": "Rasi Salai",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330902",
    "subDistrictNameTh": "เมืองแคน",
    "subDistrictNameEn": "Mueang Khaen",
    "postcode": "33160",
    "districtCode": "3309",
    "districtNameTh": "ราษีไศล",
    "districtNameEn": "Rasi Salai",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330903",
    "subDistrictNameTh": "หนองแค",
    "subDistrictNameEn": "Nong Khae",
    "postcode": "33160",
    "districtCode": "3309",
    "districtNameTh": "ราษีไศล",
    "districtNameEn": "Rasi Salai",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330906",
    "subDistrictNameTh": "จิกสังข์ทอง",
    "subDistrictNameEn": "Chik Sang Thong",
    "postcode": "33160",
    "districtCode": "3309",
    "districtNameTh": "ราษีไศล",
    "districtNameEn": "Rasi Salai",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330907",
    "subDistrictNameTh": "ด่าน",
    "subDistrictNameEn": "Dan",
    "postcode": "33160",
    "districtCode": "3309",
    "districtNameTh": "ราษีไศล",
    "districtNameEn": "Rasi Salai",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330908",
    "subDistrictNameTh": "ดู่",
    "subDistrictNameEn": "Du",
    "postcode": "33160",
    "districtCode": "3309",
    "districtNameTh": "ราษีไศล",
    "districtNameEn": "Rasi Salai",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330909",
    "subDistrictNameTh": "หนองอึ่ง",
    "subDistrictNameEn": "Nong Ueng",
    "postcode": "33160",
    "districtCode": "3309",
    "districtNameTh": "ราษีไศล",
    "districtNameEn": "Rasi Salai",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330910",
    "subDistrictNameTh": "บัวหุ่ง",
    "subDistrictNameEn": "Bua Hung",
    "postcode": "33160",
    "districtCode": "3309",
    "districtNameTh": "ราษีไศล",
    "districtNameEn": "Rasi Salai",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330911",
    "subDistrictNameTh": "ไผ่",
    "subDistrictNameEn": "Phai",
    "postcode": "33160",
    "districtCode": "3309",
    "districtNameTh": "ราษีไศล",
    "districtNameEn": "Rasi Salai",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330912",
    "subDistrictNameTh": "ส้มป่อย",
    "subDistrictNameEn": "Som Poi",
    "postcode": "33160",
    "districtCode": "3309",
    "districtNameTh": "ราษีไศล",
    "districtNameEn": "Rasi Salai",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330913",
    "subDistrictNameTh": "หนองหมี",
    "subDistrictNameEn": "Nong Mi",
    "postcode": "33160",
    "districtCode": "3309",
    "districtNameTh": "ราษีไศล",
    "districtNameEn": "Rasi Salai",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330914",
    "subDistrictNameTh": "หว้านคำ",
    "subDistrictNameEn": "Wan Kham",
    "postcode": "33160",
    "districtCode": "3309",
    "districtNameTh": "ราษีไศล",
    "districtNameEn": "Rasi Salai",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "330915",
    "subDistrictNameTh": "สร้างปี่",
    "subDistrictNameEn": "Sang Pi",
    "postcode": "33160",
    "districtCode": "3309",
    "districtNameTh": "ราษีไศล",
    "districtNameEn": "Rasi Salai",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331001",
    "subDistrictNameTh": "กำแพง",
    "subDistrictNameEn": "Kamphaeng",
    "postcode": "33120",
    "districtCode": "3310",
    "districtNameTh": "อุทุมพรพิสัย",
    "districtNameEn": "Uthumphon Phisai",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331002",
    "subDistrictNameTh": "อี่หล่ำ",
    "subDistrictNameEn": "I Lam",
    "postcode": "33120",
    "districtCode": "3310",
    "districtNameTh": "อุทุมพรพิสัย",
    "districtNameEn": "Uthumphon Phisai",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331003",
    "subDistrictNameTh": "ก้านเหลือง",
    "subDistrictNameEn": "Kan Lueang",
    "postcode": "33120",
    "districtCode": "3310",
    "districtNameTh": "อุทุมพรพิสัย",
    "districtNameEn": "Uthumphon Phisai",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331004",
    "subDistrictNameTh": "ทุ่งไชย",
    "subDistrictNameEn": "Thung Chai",
    "postcode": "33120",
    "districtCode": "3310",
    "districtNameTh": "อุทุมพรพิสัย",
    "districtNameEn": "Uthumphon Phisai",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331005",
    "subDistrictNameTh": "สำโรง",
    "subDistrictNameEn": "Samrong",
    "postcode": "33120",
    "districtCode": "3310",
    "districtNameTh": "อุทุมพรพิสัย",
    "districtNameEn": "Uthumphon Phisai",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331006",
    "subDistrictNameTh": "แขม",
    "subDistrictNameEn": "Khaem",
    "postcode": "33120",
    "districtCode": "3310",
    "districtNameTh": "อุทุมพรพิสัย",
    "districtNameEn": "Uthumphon Phisai",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331007",
    "subDistrictNameTh": "หนองไฮ",
    "subDistrictNameEn": "Nong Hai",
    "postcode": "33120",
    "districtCode": "3310",
    "districtNameTh": "อุทุมพรพิสัย",
    "districtNameEn": "Uthumphon Phisai",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331008",
    "subDistrictNameTh": "ขะยูง",
    "subDistrictNameEn": "Khayung",
    "postcode": "33120",
    "districtCode": "3310",
    "districtNameTh": "อุทุมพรพิสัย",
    "districtNameEn": "Uthumphon Phisai",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331010",
    "subDistrictNameTh": "ตาเกษ",
    "subDistrictNameEn": "Ta Ket",
    "postcode": "33120",
    "districtCode": "3310",
    "districtNameTh": "อุทุมพรพิสัย",
    "districtNameEn": "Uthumphon Phisai",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331011",
    "subDistrictNameTh": "หัวช้าง",
    "subDistrictNameEn": "Hua Chang",
    "postcode": "33120",
    "districtCode": "3310",
    "districtNameTh": "อุทุมพรพิสัย",
    "districtNameEn": "Uthumphon Phisai",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331012",
    "subDistrictNameTh": "รังแร้ง",
    "subDistrictNameEn": "Rang Raeng",
    "postcode": "33120",
    "districtCode": "3310",
    "districtNameTh": "อุทุมพรพิสัย",
    "districtNameEn": "Uthumphon Phisai",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331014",
    "subDistrictNameTh": "แต้",
    "subDistrictNameEn": "Tae",
    "postcode": "33120",
    "districtCode": "3310",
    "districtNameTh": "อุทุมพรพิสัย",
    "districtNameEn": "Uthumphon Phisai",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331015",
    "subDistrictNameTh": "แข้",
    "subDistrictNameEn": "Khae",
    "postcode": "33120",
    "districtCode": "3310",
    "districtNameTh": "อุทุมพรพิสัย",
    "districtNameEn": "Uthumphon Phisai",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331016",
    "subDistrictNameTh": "โพธิ์ชัย",
    "subDistrictNameEn": "Pho Chai",
    "postcode": "33120",
    "districtCode": "3310",
    "districtNameTh": "อุทุมพรพิสัย",
    "districtNameEn": "Uthumphon Phisai",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331017",
    "subDistrictNameTh": "ปะอาว",
    "subDistrictNameEn": "Pa Ao",
    "postcode": "33120",
    "districtCode": "3310",
    "districtNameTh": "อุทุมพรพิสัย",
    "districtNameEn": "Uthumphon Phisai",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331018",
    "subDistrictNameTh": "หนองห้าง",
    "subDistrictNameEn": "Nong Hang",
    "postcode": "33120",
    "districtCode": "3310",
    "districtNameTh": "อุทุมพรพิสัย",
    "districtNameEn": "Uthumphon Phisai",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331022",
    "subDistrictNameTh": "สระกำแพงใหญ่",
    "subDistrictNameEn": "Sa Kamphaeng Yai",
    "postcode": "33120",
    "districtCode": "3310",
    "districtNameTh": "อุทุมพรพิสัย",
    "districtNameEn": "Uthumphon Phisai",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331024",
    "subDistrictNameTh": "โคกหล่าม",
    "subDistrictNameEn": "Khok Lam",
    "postcode": "33120",
    "districtCode": "3310",
    "districtNameTh": "อุทุมพรพิสัย",
    "districtNameEn": "Uthumphon Phisai",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331025",
    "subDistrictNameTh": "โคกจาน",
    "subDistrictNameEn": "Khok Chan",
    "postcode": "33120",
    "districtCode": "3310",
    "districtNameTh": "อุทุมพรพิสัย",
    "districtNameEn": "Uthumphon Phisai",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331101",
    "subDistrictNameTh": "เป๊าะ",
    "subDistrictNameEn": "Po",
    "postcode": "33220",
    "districtCode": "3311",
    "districtNameTh": "บึงบูรพ์",
    "districtNameEn": "Bueng Bun",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331102",
    "subDistrictNameTh": "บึงบูรพ์",
    "subDistrictNameEn": "Bueng Bun",
    "postcode": "33220",
    "districtCode": "3311",
    "districtNameTh": "บึงบูรพ์",
    "districtNameEn": "Bueng Bun",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331201",
    "subDistrictNameTh": "ห้วยทับทัน",
    "subDistrictNameEn": "Huai Thap Than",
    "postcode": "33210",
    "districtCode": "3312",
    "districtNameTh": "ห้วยทับทัน",
    "districtNameEn": "Huai Thap Than",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331202",
    "subDistrictNameTh": "เมืองหลวง",
    "subDistrictNameEn": "Mueang Luang",
    "postcode": "33210",
    "districtCode": "3312",
    "districtNameTh": "ห้วยทับทัน",
    "districtNameEn": "Huai Thap Than",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331203",
    "subDistrictNameTh": "กล้วยกว้าง",
    "subDistrictNameEn": "Kluai Kwang",
    "postcode": "33210",
    "districtCode": "3312",
    "districtNameTh": "ห้วยทับทัน",
    "districtNameEn": "Huai Thap Than",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331204",
    "subDistrictNameTh": "ผักไหม",
    "subDistrictNameEn": "Phak Mai",
    "postcode": "33210",
    "districtCode": "3312",
    "districtNameTh": "ห้วยทับทัน",
    "districtNameEn": "Huai Thap Than",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331205",
    "subDistrictNameTh": "จานแสนไชย",
    "subDistrictNameEn": "Chan Saen Chai",
    "postcode": "33210",
    "districtCode": "3312",
    "districtNameTh": "ห้วยทับทัน",
    "districtNameEn": "Huai Thap Than",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331206",
    "subDistrictNameTh": "ปราสาท",
    "subDistrictNameEn": "Prasat",
    "postcode": "33210",
    "districtCode": "3312",
    "districtNameTh": "ห้วยทับทัน",
    "districtNameEn": "Huai Thap Than",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331301",
    "subDistrictNameTh": "โนนค้อ",
    "subDistrictNameEn": "Non Kho",
    "postcode": "33250",
    "districtCode": "3313",
    "districtNameTh": "โนนคูณ",
    "districtNameEn": "Non Khun",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331302",
    "subDistrictNameTh": "บก",
    "subDistrictNameEn": "Bok",
    "postcode": "33250",
    "districtCode": "3313",
    "districtNameTh": "โนนคูณ",
    "districtNameEn": "Non Khun",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331303",
    "subDistrictNameTh": "โพธิ์",
    "subDistrictNameEn": "Pho",
    "postcode": "33250",
    "districtCode": "3313",
    "districtNameTh": "โนนคูณ",
    "districtNameEn": "Non Khun",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331304",
    "subDistrictNameTh": "หนองกุง",
    "subDistrictNameEn": "Nong Kung",
    "postcode": "33250",
    "districtCode": "3313",
    "districtNameTh": "โนนคูณ",
    "districtNameEn": "Non Khun",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331305",
    "subDistrictNameTh": "เหล่ากวาง",
    "subDistrictNameEn": "Lao Kwang",
    "postcode": "33250",
    "districtCode": "3313",
    "districtNameTh": "โนนคูณ",
    "districtNameEn": "Non Khun",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331401",
    "subDistrictNameTh": "ศรีแก้ว",
    "subDistrictNameEn": "Si Kaeo",
    "postcode": "33240",
    "districtCode": "3314",
    "districtNameTh": "ศรีรัตนะ",
    "districtNameEn": "Si Rattana",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331402",
    "subDistrictNameTh": "พิงพวย",
    "subDistrictNameEn": "Phing Phuai",
    "postcode": "33240",
    "districtCode": "3314",
    "districtNameTh": "ศรีรัตนะ",
    "districtNameEn": "Si Rattana",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331403",
    "subDistrictNameTh": "สระเยาว์",
    "subDistrictNameEn": "Sa Yao",
    "postcode": "33240",
    "districtCode": "3314",
    "districtNameTh": "ศรีรัตนะ",
    "districtNameEn": "Si Rattana",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331404",
    "subDistrictNameTh": "ตูม",
    "subDistrictNameEn": "Tum",
    "postcode": "33240",
    "districtCode": "3314",
    "districtNameTh": "ศรีรัตนะ",
    "districtNameEn": "Si Rattana",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331405",
    "subDistrictNameTh": "เสื่องข้าว",
    "subDistrictNameEn": "Sueang Khao",
    "postcode": "33240",
    "districtCode": "3314",
    "districtNameTh": "ศรีรัตนะ",
    "districtNameEn": "Si Rattana",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331406",
    "subDistrictNameTh": "ศรีโนนงาม",
    "subDistrictNameEn": "Si Non Ngam",
    "postcode": "33240",
    "districtCode": "3314",
    "districtNameTh": "ศรีรัตนะ",
    "districtNameEn": "Si Rattana",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331407",
    "subDistrictNameTh": "สะพุง",
    "subDistrictNameEn": "Saphung",
    "postcode": "33240",
    "districtCode": "3314",
    "districtNameTh": "ศรีรัตนะ",
    "districtNameEn": "Si Rattana",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331501",
    "subDistrictNameTh": "น้ำเกลี้ยง",
    "subDistrictNameEn": "Nam Kliang",
    "postcode": "33130",
    "districtCode": "3315",
    "districtNameTh": "น้ำเกลี้ยง",
    "districtNameEn": "Nam Kliang",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331502",
    "subDistrictNameTh": "ละเอาะ",
    "subDistrictNameEn": "La-o",
    "postcode": "33130",
    "districtCode": "3315",
    "districtNameTh": "น้ำเกลี้ยง",
    "districtNameEn": "Nam Kliang",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331503",
    "subDistrictNameTh": "ตองปิด",
    "subDistrictNameEn": "Tong Pit",
    "postcode": "33130",
    "districtCode": "3315",
    "districtNameTh": "น้ำเกลี้ยง",
    "districtNameEn": "Nam Kliang",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331504",
    "subDistrictNameTh": "เขิน",
    "subDistrictNameEn": "Khoen",
    "postcode": "33130",
    "districtCode": "3315",
    "districtNameTh": "น้ำเกลี้ยง",
    "districtNameEn": "Nam Kliang",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331505",
    "subDistrictNameTh": "รุ่งระวี",
    "subDistrictNameEn": "Rung Rawi",
    "postcode": "33130",
    "districtCode": "3315",
    "districtNameTh": "น้ำเกลี้ยง",
    "districtNameEn": "Nam Kliang",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331506",
    "subDistrictNameTh": "คูบ",
    "subDistrictNameEn": "Khup",
    "postcode": "33130",
    "districtCode": "3315",
    "districtNameTh": "น้ำเกลี้ยง",
    "districtNameEn": "Nam Kliang",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331601",
    "subDistrictNameTh": "บุสูง",
    "subDistrictNameEn": "Bu Sung",
    "postcode": "33270",
    "districtCode": "3316",
    "districtNameTh": "วังหิน",
    "districtNameEn": "Wang Hin",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331602",
    "subDistrictNameTh": "ธาตุ",
    "subDistrictNameEn": "That",
    "postcode": "33270",
    "districtCode": "3316",
    "districtNameTh": "วังหิน",
    "districtNameEn": "Wang Hin",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331603",
    "subDistrictNameTh": "ดวนใหญ่",
    "subDistrictNameEn": "Duan Yai",
    "postcode": "33270",
    "districtCode": "3316",
    "districtNameTh": "วังหิน",
    "districtNameEn": "Wang Hin",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331604",
    "subDistrictNameTh": "บ่อแก้ว",
    "subDistrictNameEn": "Bo Kaeo",
    "postcode": "33270",
    "districtCode": "3316",
    "districtNameTh": "วังหิน",
    "districtNameEn": "Wang Hin",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331605",
    "subDistrictNameTh": "ศรีสำราญ",
    "subDistrictNameEn": "Si Samran",
    "postcode": "33270",
    "districtCode": "3316",
    "districtNameTh": "วังหิน",
    "districtNameEn": "Wang Hin",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331606",
    "subDistrictNameTh": "ทุ่งสว่าง",
    "subDistrictNameEn": "Thung Sawang",
    "postcode": "33270",
    "districtCode": "3316",
    "districtNameTh": "วังหิน",
    "districtNameEn": "Wang Hin",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331607",
    "subDistrictNameTh": "วังหิน",
    "subDistrictNameEn": "Wang Hin",
    "postcode": "33270",
    "districtCode": "3316",
    "districtNameTh": "วังหิน",
    "districtNameEn": "Wang Hin",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331608",
    "subDistrictNameTh": "โพนยาง",
    "subDistrictNameEn": "Phon Yang",
    "postcode": "33270",
    "districtCode": "3316",
    "districtNameTh": "วังหิน",
    "districtNameEn": "Wang Hin",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331701",
    "subDistrictNameTh": "โคกตาล",
    "subDistrictNameEn": "Khok Tan",
    "postcode": "33140",
    "districtCode": "3317",
    "districtNameTh": "ภูสิงห์",
    "districtNameEn": "Phu Sing",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331702",
    "subDistrictNameTh": "ห้วยตามอญ",
    "subDistrictNameEn": "Huai Ta Mon",
    "postcode": "33140",
    "districtCode": "3317",
    "districtNameTh": "ภูสิงห์",
    "districtNameEn": "Phu Sing",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331703",
    "subDistrictNameTh": "ห้วยตึ๊กชู",
    "subDistrictNameEn": "Huai Tuekchu",
    "postcode": "33140",
    "districtCode": "3317",
    "districtNameTh": "ภูสิงห์",
    "districtNameEn": "Phu Sing",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331704",
    "subDistrictNameTh": "ละลม",
    "subDistrictNameEn": "Lalom",
    "postcode": "33140",
    "districtCode": "3317",
    "districtNameTh": "ภูสิงห์",
    "districtNameEn": "Phu Sing",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331705",
    "subDistrictNameTh": "ตะเคียนราม",
    "subDistrictNameEn": "Takhian Ram",
    "postcode": "33140",
    "districtCode": "3317",
    "districtNameTh": "ภูสิงห์",
    "districtNameEn": "Phu Sing",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331706",
    "subDistrictNameTh": "ดงรัก",
    "subDistrictNameEn": "Dong Rak",
    "postcode": "33140",
    "districtCode": "3317",
    "districtNameTh": "ภูสิงห์",
    "districtNameEn": "Phu Sing",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331707",
    "subDistrictNameTh": "ไพรพัฒนา",
    "subDistrictNameEn": "Phrai Phatthana",
    "postcode": "33140",
    "districtCode": "3317",
    "districtNameTh": "ภูสิงห์",
    "districtNameEn": "Phu Sing",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331801",
    "subDistrictNameTh": "เมืองจันทร์",
    "subDistrictNameEn": "Mueang Chan",
    "postcode": "33120",
    "districtCode": "3318",
    "districtNameTh": "เมืองจันทร์",
    "districtNameEn": "Mueang Chan",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331802",
    "subDistrictNameTh": "ตาโกน",
    "subDistrictNameEn": "Ta Kon",
    "postcode": "33120",
    "districtCode": "3318",
    "districtNameTh": "เมืองจันทร์",
    "districtNameEn": "Mueang Chan",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331803",
    "subDistrictNameTh": "หนองใหญ่",
    "subDistrictNameEn": "Nong Yai",
    "postcode": "33120",
    "districtCode": "3318",
    "districtNameTh": "เมืองจันทร์",
    "districtNameEn": "Mueang Chan",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331901",
    "subDistrictNameTh": "เสียว",
    "subDistrictNameEn": "Siao",
    "postcode": "33110",
    "districtCode": "3319",
    "districtNameTh": "เบญจลักษ์",
    "districtNameEn": "Benchalak",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331902",
    "subDistrictNameTh": "หนองหว้า",
    "subDistrictNameEn": "Nong Wa",
    "postcode": "33110",
    "districtCode": "3319",
    "districtNameTh": "เบญจลักษ์",
    "districtNameEn": "Benchalak",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331903",
    "subDistrictNameTh": "หนองงูเหลือม",
    "subDistrictNameEn": "Nong Ngu Lueam",
    "postcode": "33110",
    "districtCode": "3319",
    "districtNameTh": "เบญจลักษ์",
    "districtNameEn": "Benchalak",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331904",
    "subDistrictNameTh": "หนองฮาง",
    "subDistrictNameEn": "Nong Hang",
    "postcode": "33110",
    "districtCode": "3319",
    "districtNameTh": "เบญจลักษ์",
    "districtNameEn": "Benchalak",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "331905",
    "subDistrictNameTh": "ท่าคล้อ",
    "subDistrictNameEn": "Tha Khlo",
    "postcode": "33110",
    "districtCode": "3319",
    "districtNameTh": "เบญจลักษ์",
    "districtNameEn": "Benchalak",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "332001",
    "subDistrictNameTh": "พยุห์",
    "subDistrictNameEn": "Phayu",
    "postcode": "33230",
    "districtCode": "3320",
    "districtNameTh": "พยุห์",
    "districtNameEn": "Phayu",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "332002",
    "subDistrictNameTh": "พรหมสวัสดิ์",
    "subDistrictNameEn": "Phrom Sawat",
    "postcode": "33230",
    "districtCode": "3320",
    "districtNameTh": "พยุห์",
    "districtNameEn": "Phayu",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "332003",
    "subDistrictNameTh": "ตำแย",
    "subDistrictNameEn": "Tamyae",
    "postcode": "33230",
    "districtCode": "3320",
    "districtNameTh": "พยุห์",
    "districtNameEn": "Phayu",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "332004",
    "subDistrictNameTh": "โนนเพ็ก",
    "subDistrictNameEn": "Nong Phek",
    "postcode": "33230",
    "districtCode": "3320",
    "districtNameTh": "พยุห์",
    "districtNameEn": "Phayu",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "332005",
    "subDistrictNameTh": "หนองค้า",
    "subDistrictNameEn": "Nong Kha",
    "postcode": "33230",
    "districtCode": "3320",
    "districtNameTh": "พยุห์",
    "districtNameEn": "Phayu",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "332101",
    "subDistrictNameTh": "โดด",
    "subDistrictNameEn": "Dot",
    "postcode": "33120",
    "districtCode": "3321",
    "districtNameTh": "โพธิ์ศรีสุวรรณ",
    "districtNameEn": "Pho Si Suwan",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "332102",
    "subDistrictNameTh": "เสียว",
    "subDistrictNameEn": "Siao",
    "postcode": "33120",
    "districtCode": "3321",
    "districtNameTh": "โพธิ์ศรีสุวรรณ",
    "districtNameEn": "Pho Si Suwan",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "332103",
    "subDistrictNameTh": "หนองม้า",
    "subDistrictNameEn": "Nong Ma",
    "postcode": "33120",
    "districtCode": "3321",
    "districtNameTh": "โพธิ์ศรีสุวรรณ",
    "districtNameEn": "Pho Si Suwan",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "332104",
    "subDistrictNameTh": "ผือใหญ่",
    "subDistrictNameEn": "Phue Yai",
    "postcode": "33120",
    "districtCode": "3321",
    "districtNameTh": "โพธิ์ศรีสุวรรณ",
    "districtNameEn": "Pho Si Suwan",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "332105",
    "subDistrictNameTh": "อีเซ",
    "subDistrictNameEn": "I Se",
    "postcode": "33120",
    "districtCode": "3321",
    "districtNameTh": "โพธิ์ศรีสุวรรณ",
    "districtNameEn": "Pho Si Suwan",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "332201",
    "subDistrictNameTh": "กุง",
    "subDistrictNameEn": "Kung",
    "postcode": "33160",
    "districtCode": "3322",
    "districtNameTh": "ศิลาลาด",
    "districtNameEn": "Sila Lat",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "332202",
    "subDistrictNameTh": "คลีกลิ้ง",
    "subDistrictNameEn": "Khli Kling",
    "postcode": "33160",
    "districtCode": "3322",
    "districtNameTh": "ศิลาลาด",
    "districtNameEn": "Sila Lat",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "332203",
    "subDistrictNameTh": "หนองบัวดง",
    "subDistrictNameEn": "Nong Bua Dong",
    "postcode": "33160",
    "districtCode": "3322",
    "districtNameTh": "ศิลาลาด",
    "districtNameEn": "Sila Lat",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "332204",
    "subDistrictNameTh": "โจดม่วง",
    "subDistrictNameEn": "Chot Muang",
    "postcode": "33160",
    "districtCode": "3322",
    "districtNameTh": "ศิลาลาด",
    "districtNameEn": "Sila Lat",
    "provinceCode": "33",
    "provinceNameTh": "ศรีสะเกษ",
    "provinceNameEn": "Si Sa Ket"
},
{
    "subDistrictCode": "340101",
    "subDistrictNameTh": "ในเมือง",
    "subDistrictNameEn": "Nai Mueang",
    "postcode": "34000",
    "districtCode": "3401",
    "districtNameTh": "เมืองอุบลราชธานี",
    "districtNameEn": "Mueang Ubon Ratchathani",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340104",
    "subDistrictNameTh": "หัวเรือ",
    "subDistrictNameEn": "Hua Ruea",
    "postcode": "34000",
    "districtCode": "3401",
    "districtNameTh": "เมืองอุบลราชธานี",
    "districtNameEn": "Mueang Ubon Ratchathani",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340105",
    "subDistrictNameTh": "หนองขอน",
    "subDistrictNameEn": "Nong Khon",
    "postcode": "34000",
    "districtCode": "3401",
    "districtNameTh": "เมืองอุบลราชธานี",
    "districtNameEn": "Mueang Ubon Ratchathani",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340107",
    "subDistrictNameTh": "ปทุม",
    "subDistrictNameEn": "Pathum",
    "postcode": "34000",
    "districtCode": "3401",
    "districtNameTh": "เมืองอุบลราชธานี",
    "districtNameEn": "Mueang Ubon Ratchathani",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340108",
    "subDistrictNameTh": "ขามใหญ่",
    "subDistrictNameEn": "Kham Yai",
    "postcode": "34000",
    "districtCode": "3401",
    "districtNameTh": "เมืองอุบลราชธานี",
    "districtNameEn": "Mueang Ubon Ratchathani",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340109",
    "subDistrictNameTh": "แจระแม",
    "subDistrictNameEn": "Chaeramae",
    "postcode": "34000",
    "districtCode": "3401",
    "districtNameTh": "เมืองอุบลราชธานี",
    "districtNameEn": "Mueang Ubon Ratchathani",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340111",
    "subDistrictNameTh": "หนองบ่อ",
    "subDistrictNameEn": "Nong Bo",
    "postcode": "34000",
    "districtCode": "3401",
    "districtNameTh": "เมืองอุบลราชธานี",
    "districtNameEn": "Mueang Ubon Ratchathani",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340112",
    "subDistrictNameTh": "ไร่น้อย",
    "subDistrictNameEn": "Rai Noi",
    "postcode": "34000",
    "districtCode": "3401",
    "districtNameTh": "เมืองอุบลราชธานี",
    "districtNameEn": "Mueang Ubon Ratchathani",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340113",
    "subDistrictNameTh": "กระโสบ",
    "subDistrictNameEn": "Krasop",
    "postcode": "34000",
    "districtCode": "3401",
    "districtNameTh": "เมืองอุบลราชธานี",
    "districtNameEn": "Mueang Ubon Ratchathani",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340116",
    "subDistrictNameTh": "กุดลาด",
    "subDistrictNameEn": "Kut Lat",
    "postcode": "34000",
    "districtCode": "3401",
    "districtNameTh": "เมืองอุบลราชธานี",
    "districtNameEn": "Mueang Ubon Ratchathani",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340119",
    "subDistrictNameTh": "ขี้เหล็ก",
    "subDistrictNameEn": "Khilek",
    "postcode": "34000",
    "districtCode": "3401",
    "districtNameTh": "เมืองอุบลราชธานี",
    "districtNameEn": "Mueang Ubon Ratchathani",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340120",
    "subDistrictNameTh": "ปะอาว",
    "subDistrictNameEn": "Pa-ao",
    "postcode": "34000",
    "districtCode": "3401",
    "districtNameTh": "เมืองอุบลราชธานี",
    "districtNameEn": "Mueang Ubon Ratchathani",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340201",
    "subDistrictNameTh": "นาคำ",
    "subDistrictNameEn": "Na Kham",
    "postcode": "34250",
    "districtCode": "3402",
    "districtNameTh": "ศรีเมืองใหม่",
    "districtNameEn": "Si Mueang Mai",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340202",
    "subDistrictNameTh": "แก้งกอก",
    "subDistrictNameEn": "Kaeng Kok",
    "postcode": "34250",
    "districtCode": "3402",
    "districtNameTh": "ศรีเมืองใหม่",
    "districtNameEn": "Si Mueang Mai",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340203",
    "subDistrictNameTh": "เอือดใหญ่",
    "subDistrictNameEn": "Ueat Yai",
    "postcode": "34250",
    "districtCode": "3402",
    "districtNameTh": "ศรีเมืองใหม่",
    "districtNameEn": "Si Mueang Mai",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340204",
    "subDistrictNameTh": "วาริน",
    "subDistrictNameEn": "Warin",
    "postcode": "34250",
    "districtCode": "3402",
    "districtNameTh": "ศรีเมืองใหม่",
    "districtNameEn": "Si Mueang Mai",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340205",
    "subDistrictNameTh": "ลาดควาย",
    "subDistrictNameEn": "Lat Khwai",
    "postcode": "34250",
    "districtCode": "3402",
    "districtNameTh": "ศรีเมืองใหม่",
    "districtNameEn": "Si Mueang Mai",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340206",
    "subDistrictNameTh": "สงยาง",
    "subDistrictNameEn": "Song Yang",
    "postcode": "34250",
    "districtCode": "3402",
    "districtNameTh": "ศรีเมืองใหม่",
    "districtNameEn": "Si Mueang Mai",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340207",
    "subDistrictNameTh": "ตะบ่าย",
    "subDistrictNameEn": "Tabai",
    "postcode": "34250",
    "districtCode": "3402",
    "districtNameTh": "ศรีเมืองใหม่",
    "districtNameEn": "Si Mueang Mai",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340208",
    "subDistrictNameTh": "คำไหล",
    "subDistrictNameEn": "Kham Lai",
    "postcode": "34250",
    "districtCode": "3402",
    "districtNameTh": "ศรีเมืองใหม่",
    "districtNameEn": "Si Mueang Mai",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340209",
    "subDistrictNameTh": "หนามแท่ง",
    "subDistrictNameEn": "Nam Thaeng",
    "postcode": "34250",
    "districtCode": "3402",
    "districtNameTh": "ศรีเมืองใหม่",
    "districtNameEn": "Si Mueang Mai",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340210",
    "subDistrictNameTh": "นาเลิน",
    "subDistrictNameEn": "Na Loen",
    "postcode": "34250",
    "districtCode": "3402",
    "districtNameTh": "ศรีเมืองใหม่",
    "districtNameEn": "Si Mueang Mai",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340211",
    "subDistrictNameTh": "ดอนใหญ่",
    "subDistrictNameEn": "Don Yai",
    "postcode": "34250",
    "districtCode": "3402",
    "districtNameTh": "ศรีเมืองใหม่",
    "districtNameEn": "Si Mueang Mai",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340301",
    "subDistrictNameTh": "โขงเจียม",
    "subDistrictNameEn": "Khong Chiam",
    "postcode": "34220",
    "districtCode": "3403",
    "districtNameTh": "โขงเจียม",
    "districtNameEn": "Khong Chiam",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340302",
    "subDistrictNameTh": "ห้วยยาง",
    "subDistrictNameEn": "Huai Yang",
    "postcode": "34220",
    "districtCode": "3403",
    "districtNameTh": "โขงเจียม",
    "districtNameEn": "Khong Chiam",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340303",
    "subDistrictNameTh": "นาโพธิ์กลาง",
    "subDistrictNameEn": "Na Pho Klang",
    "postcode": "34220",
    "districtCode": "3403",
    "districtNameTh": "โขงเจียม",
    "districtNameEn": "Khong Chiam",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340304",
    "subDistrictNameTh": "หนองแสงใหญ่",
    "subDistrictNameEn": "Nong Saeng Yai",
    "postcode": "34220",
    "districtCode": "3403",
    "districtNameTh": "โขงเจียม",
    "districtNameEn": "Khong Chiam",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340305",
    "subDistrictNameTh": "ห้วยไผ่",
    "subDistrictNameEn": "Huai Phai",
    "postcode": "34220",
    "districtCode": "3403",
    "districtNameTh": "โขงเจียม",
    "districtNameEn": "Khong Chiam",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340401",
    "subDistrictNameTh": "เขื่องใน",
    "subDistrictNameEn": "Khueang Nai",
    "postcode": "34150",
    "districtCode": "3404",
    "districtNameTh": "เขื่องใน",
    "districtNameEn": "Khueang Nai",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340402",
    "subDistrictNameTh": "สร้างถ่อ",
    "subDistrictNameEn": "Sang Tho",
    "postcode": "34150",
    "districtCode": "3404",
    "districtNameTh": "เขื่องใน",
    "districtNameEn": "Khueang Nai",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340403",
    "subDistrictNameTh": "ค้อทอง",
    "subDistrictNameEn": "Kho Thong",
    "postcode": "34150",
    "districtCode": "3404",
    "districtNameTh": "เขื่องใน",
    "districtNameEn": "Khueang Nai",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340404",
    "subDistrictNameTh": "ก่อเอ้",
    "subDistrictNameEn": "Ko E",
    "postcode": "34150",
    "districtCode": "3404",
    "districtNameTh": "เขื่องใน",
    "districtNameEn": "Khueang Nai",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340405",
    "subDistrictNameTh": "หัวดอน",
    "subDistrictNameEn": "Hua Don",
    "postcode": "34150",
    "districtCode": "3404",
    "districtNameTh": "เขื่องใน",
    "districtNameEn": "Khueang Nai",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340406",
    "subDistrictNameTh": "ชีทวน",
    "subDistrictNameEn": "Chi Thuan",
    "postcode": "34150",
    "districtCode": "3404",
    "districtNameTh": "เขื่องใน",
    "districtNameEn": "Khueang Nai",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340407",
    "subDistrictNameTh": "ท่าไห",
    "subDistrictNameEn": "Tha Hai",
    "postcode": "34150",
    "districtCode": "3404",
    "districtNameTh": "เขื่องใน",
    "districtNameEn": "Khueang Nai",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340408",
    "subDistrictNameTh": "นาคำใหญ่",
    "subDistrictNameEn": "Na Kham Yai",
    "postcode": "34150",
    "districtCode": "3404",
    "districtNameTh": "เขื่องใน",
    "districtNameEn": "Khueang Nai",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340409",
    "subDistrictNameTh": "แดงหม้อ",
    "subDistrictNameEn": "Daeng Mo",
    "postcode": "34150",
    "districtCode": "3404",
    "districtNameTh": "เขื่องใน",
    "districtNameEn": "Khueang Nai",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340410",
    "subDistrictNameTh": "ธาตุน้อย",
    "subDistrictNameEn": "That Noi",
    "postcode": "34150",
    "districtCode": "3404",
    "districtNameTh": "เขื่องใน",
    "districtNameEn": "Khueang Nai",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340411",
    "subDistrictNameTh": "บ้านไทย",
    "subDistrictNameEn": "Ban Thai",
    "postcode": "34320",
    "districtCode": "3404",
    "districtNameTh": "เขื่องใน",
    "districtNameEn": "Khueang Nai",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340412",
    "subDistrictNameTh": "บ้านกอก",
    "subDistrictNameEn": "Ban Kok",
    "postcode": "34320",
    "districtCode": "3404",
    "districtNameTh": "เขื่องใน",
    "districtNameEn": "Khueang Nai",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340413",
    "subDistrictNameTh": "กลางใหญ่",
    "subDistrictNameEn": "Klang Yai",
    "postcode": "34320",
    "districtCode": "3404",
    "districtNameTh": "เขื่องใน",
    "districtNameEn": "Khueang Nai",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340414",
    "subDistrictNameTh": "โนนรัง",
    "subDistrictNameEn": "Non Rang",
    "postcode": "34320",
    "districtCode": "3404",
    "districtNameTh": "เขื่องใน",
    "districtNameEn": "Khueang Nai",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340415",
    "subDistrictNameTh": "ยางขี้นก",
    "subDistrictNameEn": "Yang Khi Nok",
    "postcode": "34150",
    "districtCode": "3404",
    "districtNameTh": "เขื่องใน",
    "districtNameEn": "Khueang Nai",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340416",
    "subDistrictNameTh": "ศรีสุข",
    "subDistrictNameEn": "Si Suk",
    "postcode": "34150",
    "districtCode": "3404",
    "districtNameTh": "เขื่องใน",
    "districtNameEn": "Khueang Nai",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340417",
    "subDistrictNameTh": "สหธาตุ",
    "subDistrictNameEn": "Sahathat",
    "postcode": "34150",
    "districtCode": "3404",
    "districtNameTh": "เขื่องใน",
    "districtNameEn": "Khueang Nai",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340418",
    "subDistrictNameTh": "หนองเหล่า",
    "subDistrictNameEn": "Nong Lao",
    "postcode": "34150",
    "districtCode": "3404",
    "districtNameTh": "เขื่องใน",
    "districtNameEn": "Khueang Nai",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340501",
    "subDistrictNameTh": "เขมราฐ",
    "subDistrictNameEn": "Khemarat",
    "postcode": "34170",
    "districtCode": "3405",
    "districtNameTh": "เขมราฐ",
    "districtNameEn": "Khemarat",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340503",
    "subDistrictNameTh": "ขามป้อม",
    "subDistrictNameEn": "Kham Pom",
    "postcode": "34170",
    "districtCode": "3405",
    "districtNameTh": "เขมราฐ",
    "districtNameEn": "Khemarat",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340504",
    "subDistrictNameTh": "เจียด",
    "subDistrictNameEn": "Chiat",
    "postcode": "34170",
    "districtCode": "3405",
    "districtNameTh": "เขมราฐ",
    "districtNameEn": "Khemarat",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340507",
    "subDistrictNameTh": "หนองผือ",
    "subDistrictNameEn": "Nong Phue",
    "postcode": "34170",
    "districtCode": "3405",
    "districtNameTh": "เขมราฐ",
    "districtNameEn": "Khemarat",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340508",
    "subDistrictNameTh": "นาแวง",
    "subDistrictNameEn": "Na Waeng",
    "postcode": "34170",
    "districtCode": "3405",
    "districtNameTh": "เขมราฐ",
    "districtNameEn": "Khemarat",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340510",
    "subDistrictNameTh": "แก้งเหนือ",
    "subDistrictNameEn": "Kaeng Nuea",
    "postcode": "34170",
    "districtCode": "3405",
    "districtNameTh": "เขมราฐ",
    "districtNameEn": "Khemarat",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340511",
    "subDistrictNameTh": "หนองนกทา",
    "subDistrictNameEn": "Nong Nok Tha",
    "postcode": "34170",
    "districtCode": "3405",
    "districtNameTh": "เขมราฐ",
    "districtNameEn": "Khemarat",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340512",
    "subDistrictNameTh": "หนองสิม",
    "subDistrictNameEn": "Nong Sim",
    "postcode": "34170",
    "districtCode": "3405",
    "districtNameTh": "เขมราฐ",
    "districtNameEn": "Khemarat",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340513",
    "subDistrictNameTh": "หัวนา",
    "subDistrictNameEn": "Hua Na",
    "postcode": "34170",
    "districtCode": "3405",
    "districtNameTh": "เขมราฐ",
    "districtNameEn": "Khemarat",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340701",
    "subDistrictNameTh": "เมืองเดช",
    "subDistrictNameEn": "Mueang Det",
    "postcode": "34160",
    "districtCode": "3407",
    "districtNameTh": "เดชอุดม",
    "districtNameEn": "Det Udom",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340702",
    "subDistrictNameTh": "นาส่วง",
    "subDistrictNameEn": "Na Suang",
    "postcode": "34160",
    "districtCode": "3407",
    "districtNameTh": "เดชอุดม",
    "districtNameEn": "Det Udom",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340704",
    "subDistrictNameTh": "นาเจริญ",
    "subDistrictNameEn": "Na Charoen",
    "postcode": "34160",
    "districtCode": "3407",
    "districtNameTh": "เดชอุดม",
    "districtNameEn": "Det Udom",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340706",
    "subDistrictNameTh": "ทุ่งเทิง",
    "subDistrictNameEn": "Thung Thoeng",
    "postcode": "34160",
    "districtCode": "3407",
    "districtNameTh": "เดชอุดม",
    "districtNameEn": "Det Udom",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340708",
    "subDistrictNameTh": "สมสะอาด",
    "subDistrictNameEn": "Som Sa-at",
    "postcode": "34160",
    "districtCode": "3407",
    "districtNameTh": "เดชอุดม",
    "districtNameEn": "Det Udom",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340709",
    "subDistrictNameTh": "กุดประทาย",
    "subDistrictNameEn": "Kut Prathai",
    "postcode": "34160",
    "districtCode": "3407",
    "districtNameTh": "เดชอุดม",
    "districtNameEn": "Det Udom",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340710",
    "subDistrictNameTh": "ตบหู",
    "subDistrictNameEn": "Top Hu",
    "postcode": "34160",
    "districtCode": "3407",
    "districtNameTh": "เดชอุดม",
    "districtNameEn": "Det Udom",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340711",
    "subDistrictNameTh": "กลาง",
    "subDistrictNameEn": "Klang",
    "postcode": "34160",
    "districtCode": "3407",
    "districtNameTh": "เดชอุดม",
    "districtNameEn": "Det Udom",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340712",
    "subDistrictNameTh": "แก้ง",
    "subDistrictNameEn": "Kaeng",
    "postcode": "34160",
    "districtCode": "3407",
    "districtNameTh": "เดชอุดม",
    "districtNameEn": "Det Udom",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340713",
    "subDistrictNameTh": "ท่าโพธิ์ศรี",
    "subDistrictNameEn": "Tha Pho Si",
    "postcode": "34160",
    "districtCode": "3407",
    "districtNameTh": "เดชอุดม",
    "districtNameEn": "Det Udom",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340715",
    "subDistrictNameTh": "บัวงาม",
    "subDistrictNameEn": "Bua Ngam",
    "postcode": "34160",
    "districtCode": "3407",
    "districtNameTh": "เดชอุดม",
    "districtNameEn": "Det Udom",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340716",
    "subDistrictNameTh": "คำครั่ง",
    "subDistrictNameEn": "Kham Khrang",
    "postcode": "34160",
    "districtCode": "3407",
    "districtNameTh": "เดชอุดม",
    "districtNameEn": "Det Udom",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340717",
    "subDistrictNameTh": "นากระแซง",
    "subDistrictNameEn": "Na Krasaeng",
    "postcode": "34160",
    "districtCode": "3407",
    "districtNameTh": "เดชอุดม",
    "districtNameEn": "Det Udom",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340720",
    "subDistrictNameTh": "โพนงาม",
    "subDistrictNameEn": "Phon Ngam",
    "postcode": "34160",
    "districtCode": "3407",
    "districtNameTh": "เดชอุดม",
    "districtNameEn": "Det Udom",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340721",
    "subDistrictNameTh": "ป่าโมง",
    "subDistrictNameEn": "Pa Mong",
    "postcode": "34160",
    "districtCode": "3407",
    "districtNameTh": "เดชอุดม",
    "districtNameEn": "Det Udom",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340723",
    "subDistrictNameTh": "โนนสมบูรณ์",
    "subDistrictNameEn": "Non Sombun",
    "postcode": "34160",
    "districtCode": "3407",
    "districtNameTh": "เดชอุดม",
    "districtNameEn": "Det Udom",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340801",
    "subDistrictNameTh": "นาจะหลวย",
    "subDistrictNameEn": "Na Chaluai",
    "postcode": "34280",
    "districtCode": "3408",
    "districtNameTh": "นาจะหลวย",
    "districtNameEn": "Na Chaluai",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340802",
    "subDistrictNameTh": "โนนสมบูรณ์",
    "subDistrictNameEn": "Non Sombun",
    "postcode": "34280",
    "districtCode": "3408",
    "districtNameTh": "นาจะหลวย",
    "districtNameEn": "Na Chaluai",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340803",
    "subDistrictNameTh": "พรสวรรค์",
    "subDistrictNameEn": "Phon Sawan",
    "postcode": "34280",
    "districtCode": "3408",
    "districtNameTh": "นาจะหลวย",
    "districtNameEn": "Na Chaluai",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340804",
    "subDistrictNameTh": "บ้านตูม",
    "subDistrictNameEn": "Ban Tum",
    "postcode": "34280",
    "districtCode": "3408",
    "districtNameTh": "นาจะหลวย",
    "districtNameEn": "Na Chaluai",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340805",
    "subDistrictNameTh": "โสกแสง",
    "subDistrictNameEn": "Sok Saeng",
    "postcode": "34280",
    "districtCode": "3408",
    "districtNameTh": "นาจะหลวย",
    "districtNameEn": "Na Chaluai",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340806",
    "subDistrictNameTh": "โนนสวรรค์",
    "subDistrictNameEn": "Non Sawan",
    "postcode": "34280",
    "districtCode": "3408",
    "districtNameTh": "นาจะหลวย",
    "districtNameEn": "Na Chaluai",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340901",
    "subDistrictNameTh": "โซง",
    "subDistrictNameEn": "Song",
    "postcode": "34260",
    "districtCode": "3409",
    "districtNameTh": "น้ำยืน",
    "districtNameEn": "Nam Yuen",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340903",
    "subDistrictNameTh": "ยาง",
    "subDistrictNameEn": "Yang",
    "postcode": "34260",
    "districtCode": "3409",
    "districtNameTh": "น้ำยืน",
    "districtNameEn": "Nam Yuen",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340904",
    "subDistrictNameTh": "โดมประดิษฐ์",
    "subDistrictNameEn": "Dom Pradit",
    "postcode": "34260",
    "districtCode": "3409",
    "districtNameTh": "น้ำยืน",
    "districtNameEn": "Nam Yuen",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340906",
    "subDistrictNameTh": "บุเปือย",
    "subDistrictNameEn": "Bu Pueai",
    "postcode": "34260",
    "districtCode": "3409",
    "districtNameTh": "น้ำยืน",
    "districtNameEn": "Nam Yuen",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340907",
    "subDistrictNameTh": "สีวิเชียร",
    "subDistrictNameEn": "Si Wichian",
    "postcode": "34260",
    "districtCode": "3409",
    "districtNameTh": "น้ำยืน",
    "districtNameEn": "Nam Yuen",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340909",
    "subDistrictNameTh": "ยางใหญ่",
    "subDistrictNameEn": "Yang Yai",
    "postcode": "34260",
    "districtCode": "3409",
    "districtNameTh": "น้ำยืน",
    "districtNameEn": "Nam Yuen",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "340911",
    "subDistrictNameTh": "เก่าขาม",
    "subDistrictNameEn": "Kao Kham",
    "postcode": "34260",
    "districtCode": "3409",
    "districtNameTh": "น้ำยืน",
    "districtNameEn": "Nam Yuen",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341001",
    "subDistrictNameTh": "โพนงาม",
    "subDistrictNameEn": "Phon Ngam",
    "postcode": "34230",
    "districtCode": "3410",
    "districtNameTh": "บุณฑริก",
    "districtNameEn": "Buntharik",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341002",
    "subDistrictNameTh": "ห้วยข่า",
    "subDistrictNameEn": "Huai Kha",
    "postcode": "34230",
    "districtCode": "3410",
    "districtNameTh": "บุณฑริก",
    "districtNameEn": "Buntharik",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341003",
    "subDistrictNameTh": "คอแลน",
    "subDistrictNameEn": "Kho Laen",
    "postcode": "34230",
    "districtCode": "3410",
    "districtNameTh": "บุณฑริก",
    "districtNameEn": "Buntharik",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341004",
    "subDistrictNameTh": "นาโพธิ์",
    "subDistrictNameEn": "Na Pho",
    "postcode": "34230",
    "districtCode": "3410",
    "districtNameTh": "บุณฑริก",
    "districtNameEn": "Buntharik",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341005",
    "subDistrictNameTh": "หนองสะโน",
    "subDistrictNameEn": "Nong Sano",
    "postcode": "34230",
    "districtCode": "3410",
    "districtNameTh": "บุณฑริก",
    "districtNameEn": "Buntharik",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341006",
    "subDistrictNameTh": "โนนค้อ",
    "subDistrictNameEn": "Non Kho",
    "postcode": "34230",
    "districtCode": "3410",
    "districtNameTh": "บุณฑริก",
    "districtNameEn": "Buntharik",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341007",
    "subDistrictNameTh": "บัวงาม",
    "subDistrictNameEn": "Bua Ngam",
    "postcode": "34230",
    "districtCode": "3410",
    "districtNameTh": "บุณฑริก",
    "districtNameEn": "Buntharik",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341008",
    "subDistrictNameTh": "บ้านแมด",
    "subDistrictNameEn": "Ban Maet",
    "postcode": "34230",
    "districtCode": "3410",
    "districtNameTh": "บุณฑริก",
    "districtNameEn": "Buntharik",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341101",
    "subDistrictNameTh": "ขุหลุ",
    "subDistrictNameEn": "Khulu",
    "postcode": "34130",
    "districtCode": "3411",
    "districtNameTh": "ตระการพืชผล",
    "districtNameEn": "Trakan Phuet Phon",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341102",
    "subDistrictNameTh": "กระเดียน",
    "subDistrictNameEn": "Kradian",
    "postcode": "34130",
    "districtCode": "3411",
    "districtNameTh": "ตระการพืชผล",
    "districtNameEn": "Trakan Phuet Phon",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341103",
    "subDistrictNameTh": "เกษม",
    "subDistrictNameEn": "Kasem",
    "postcode": "34130",
    "districtCode": "3411",
    "districtNameTh": "ตระการพืชผล",
    "districtNameEn": "Trakan Phuet Phon",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341104",
    "subDistrictNameTh": "กุศกร",
    "subDistrictNameEn": "Kutsakon",
    "postcode": "34130",
    "districtCode": "3411",
    "districtNameTh": "ตระการพืชผล",
    "districtNameEn": "Trakan Phuet Phon",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341105",
    "subDistrictNameTh": "ขามเปี้ย",
    "subDistrictNameEn": "Kham Pia",
    "postcode": "34130",
    "districtCode": "3411",
    "districtNameTh": "ตระการพืชผล",
    "districtNameEn": "Trakan Phuet Phon",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341106",
    "subDistrictNameTh": "คอนสาย",
    "subDistrictNameEn": "Khon Sai",
    "postcode": "34130",
    "districtCode": "3411",
    "districtNameTh": "ตระการพืชผล",
    "districtNameEn": "Trakan Phuet Phon",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341107",
    "subDistrictNameTh": "โคกจาน",
    "subDistrictNameEn": "Khok Chan",
    "postcode": "34130",
    "districtCode": "3411",
    "districtNameTh": "ตระการพืชผล",
    "districtNameEn": "Trakan Phuet Phon",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341108",
    "subDistrictNameTh": "นาพิน",
    "subDistrictNameEn": "Na Phin",
    "postcode": "34130",
    "districtCode": "3411",
    "districtNameTh": "ตระการพืชผล",
    "districtNameEn": "Trakan Phuet Phon",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341109",
    "subDistrictNameTh": "นาสะไม",
    "subDistrictNameEn": "Na Samai",
    "postcode": "34130",
    "districtCode": "3411",
    "districtNameTh": "ตระการพืชผล",
    "districtNameEn": "Trakan Phuet Phon",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341110",
    "subDistrictNameTh": "โนนกุง",
    "subDistrictNameEn": "Non Kung",
    "postcode": "34130",
    "districtCode": "3411",
    "districtNameTh": "ตระการพืชผล",
    "districtNameEn": "Trakan Phuet Phon",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341111",
    "subDistrictNameTh": "ตระการ",
    "subDistrictNameEn": "Trakan",
    "postcode": "34130",
    "districtCode": "3411",
    "districtNameTh": "ตระการพืชผล",
    "districtNameEn": "Trakan Phuet Phon",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341112",
    "subDistrictNameTh": "ตากแดด",
    "subDistrictNameEn": "Tak Daet",
    "postcode": "34130",
    "districtCode": "3411",
    "districtNameTh": "ตระการพืชผล",
    "districtNameEn": "Trakan Phuet Phon",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341113",
    "subDistrictNameTh": "ไหล่ทุ่ง",
    "subDistrictNameEn": "Lai Thung",
    "postcode": "34130",
    "districtCode": "3411",
    "districtNameTh": "ตระการพืชผล",
    "districtNameEn": "Trakan Phuet Phon",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341114",
    "subDistrictNameTh": "เป้า",
    "subDistrictNameEn": "Pao",
    "postcode": "34130",
    "districtCode": "3411",
    "districtNameTh": "ตระการพืชผล",
    "districtNameEn": "Trakan Phuet Phon",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341115",
    "subDistrictNameTh": "เซเป็ด",
    "subDistrictNameEn": "Se Pet",
    "postcode": "34130",
    "districtCode": "3411",
    "districtNameTh": "ตระการพืชผล",
    "districtNameEn": "Trakan Phuet Phon",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341116",
    "subDistrictNameTh": "สะพือ",
    "subDistrictNameEn": "Saphue",
    "postcode": "34130",
    "districtCode": "3411",
    "districtNameTh": "ตระการพืชผล",
    "districtNameEn": "Trakan Phuet Phon",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341117",
    "subDistrictNameTh": "หนองเต่า",
    "subDistrictNameEn": "Nong Tao",
    "postcode": "34130",
    "districtCode": "3411",
    "districtNameTh": "ตระการพืชผล",
    "districtNameEn": "Trakan Phuet Phon",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341118",
    "subDistrictNameTh": "ถ้ำแข้",
    "subDistrictNameEn": "Tham Khae",
    "postcode": "34130",
    "districtCode": "3411",
    "districtNameTh": "ตระการพืชผล",
    "districtNameEn": "Trakan Phuet Phon",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341119",
    "subDistrictNameTh": "ท่าหลวง",
    "subDistrictNameEn": "Tha Luang",
    "postcode": "34130",
    "districtCode": "3411",
    "districtNameTh": "ตระการพืชผล",
    "districtNameEn": "Trakan Phuet Phon",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341120",
    "subDistrictNameTh": "ห้วยฝ้ายพัฒนา",
    "subDistrictNameEn": "Huai Fai Phatthana",
    "postcode": "34130",
    "districtCode": "3411",
    "districtNameTh": "ตระการพืชผล",
    "districtNameEn": "Trakan Phuet Phon",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341121",
    "subDistrictNameTh": "กุดยาลวน",
    "subDistrictNameEn": "Kut Ya Luan",
    "postcode": "34130",
    "districtCode": "3411",
    "districtNameTh": "ตระการพืชผล",
    "districtNameEn": "Trakan Phuet Phon",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341122",
    "subDistrictNameTh": "บ้านแดง",
    "subDistrictNameEn": "Ban Daeng",
    "postcode": "34130",
    "districtCode": "3411",
    "districtNameTh": "ตระการพืชผล",
    "districtNameEn": "Trakan Phuet Phon",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341123",
    "subDistrictNameTh": "คำเจริญ",
    "subDistrictNameEn": "Kham Charoen",
    "postcode": "34130",
    "districtCode": "3411",
    "districtNameTh": "ตระการพืชผล",
    "districtNameEn": "Trakan Phuet Phon",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341201",
    "subDistrictNameTh": "ข้าวปุ้น",
    "subDistrictNameEn": "Khao Pun",
    "postcode": "34270",
    "districtCode": "3412",
    "districtNameTh": "กุดข้าวปุ้น",
    "districtNameEn": "Kut Khaopun",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341202",
    "subDistrictNameTh": "โนนสวาง",
    "subDistrictNameEn": "Non Sawang",
    "postcode": "34270",
    "districtCode": "3412",
    "districtNameTh": "กุดข้าวปุ้น",
    "districtNameEn": "Kut Khaopun",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341203",
    "subDistrictNameTh": "แก่งเค็ง",
    "subDistrictNameEn": "Kaeng Kheng",
    "postcode": "34270",
    "districtCode": "3412",
    "districtNameTh": "กุดข้าวปุ้น",
    "districtNameEn": "Kut Khaopun",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341204",
    "subDistrictNameTh": "กาบิน",
    "subDistrictNameEn": "Ka Bin",
    "postcode": "34270",
    "districtCode": "3412",
    "districtNameTh": "กุดข้าวปุ้น",
    "districtNameEn": "Kut Khaopun",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341205",
    "subDistrictNameTh": "หนองทันน้ำ",
    "subDistrictNameEn": "Nong Than Nam",
    "postcode": "34270",
    "districtCode": "3412",
    "districtNameTh": "กุดข้าวปุ้น",
    "districtNameEn": "Kut Khaopun",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341401",
    "subDistrictNameTh": "ม่วงสามสิบ",
    "subDistrictNameEn": "Muang Sam Sip",
    "postcode": "34140",
    "districtCode": "3414",
    "districtNameTh": "ม่วงสามสิบ",
    "districtNameEn": "Muang Sam Sip",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341402",
    "subDistrictNameTh": "เหล่าบก",
    "subDistrictNameEn": "Lao Bok",
    "postcode": "34140",
    "districtCode": "3414",
    "districtNameTh": "ม่วงสามสิบ",
    "districtNameEn": "Muang Sam Sip",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341403",
    "subDistrictNameTh": "ดุมใหญ่",
    "subDistrictNameEn": "Dum Yai",
    "postcode": "34140",
    "districtCode": "3414",
    "districtNameTh": "ม่วงสามสิบ",
    "districtNameEn": "Muang Sam Sip",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341404",
    "subDistrictNameTh": "หนองช้างใหญ่",
    "subDistrictNameEn": "Nong Chang Yai",
    "postcode": "34140",
    "districtCode": "3414",
    "districtNameTh": "ม่วงสามสิบ",
    "districtNameEn": "Muang Sam Sip",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341405",
    "subDistrictNameTh": "หนองเมือง",
    "subDistrictNameEn": "Nong Mueang",
    "postcode": "34140",
    "districtCode": "3414",
    "districtNameTh": "ม่วงสามสิบ",
    "districtNameEn": "Muang Sam Sip",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341406",
    "subDistrictNameTh": "เตย",
    "subDistrictNameEn": "Toei",
    "postcode": "34140",
    "districtCode": "3414",
    "districtNameTh": "ม่วงสามสิบ",
    "districtNameEn": "Muang Sam Sip",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341407",
    "subDistrictNameTh": "ยางสักกระโพหลุ่ม",
    "subDistrictNameEn": "Yang Sak Krapho Lum",
    "postcode": "34140",
    "districtCode": "3414",
    "districtNameTh": "ม่วงสามสิบ",
    "districtNameEn": "Muang Sam Sip",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341408",
    "subDistrictNameTh": "หนองไข่นก",
    "subDistrictNameEn": "Nong Khai Nok",
    "postcode": "34140",
    "districtCode": "3414",
    "districtNameTh": "ม่วงสามสิบ",
    "districtNameEn": "Muang Sam Sip",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341409",
    "subDistrictNameTh": "หนองเหล่า",
    "subDistrictNameEn": "Nong Lao",
    "postcode": "34140",
    "districtCode": "3414",
    "districtNameTh": "ม่วงสามสิบ",
    "districtNameEn": "Muang Sam Sip",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341410",
    "subDistrictNameTh": "หนองฮาง",
    "subDistrictNameEn": "Nong Hang",
    "postcode": "34140",
    "districtCode": "3414",
    "districtNameTh": "ม่วงสามสิบ",
    "districtNameEn": "Muang Sam Sip",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341411",
    "subDistrictNameTh": "ยางโยภาพ",
    "subDistrictNameEn": "Yang Yo Phap",
    "postcode": "34140",
    "districtCode": "3414",
    "districtNameTh": "ม่วงสามสิบ",
    "districtNameEn": "Muang Sam Sip",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341412",
    "subDistrictNameTh": "ไผ่ใหญ่",
    "subDistrictNameEn": "Phai Yai",
    "postcode": "34140",
    "districtCode": "3414",
    "districtNameTh": "ม่วงสามสิบ",
    "districtNameEn": "Muang Sam Sip",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341413",
    "subDistrictNameTh": "นาเลิง",
    "subDistrictNameEn": "Na Loeng",
    "postcode": "34140",
    "districtCode": "3414",
    "districtNameTh": "ม่วงสามสิบ",
    "districtNameEn": "Muang Sam Sip",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341414",
    "subDistrictNameTh": "โพนแพง",
    "subDistrictNameEn": "Phon Phaeng",
    "postcode": "34140",
    "districtCode": "3414",
    "districtNameTh": "ม่วงสามสิบ",
    "districtNameEn": "Muang Sam Sip",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341501",
    "subDistrictNameTh": "วารินชำราบ",
    "subDistrictNameEn": "Warin Chamrap",
    "postcode": "34190",
    "districtCode": "3415",
    "districtNameTh": "วารินชำราบ",
    "districtNameEn": "Warin Chamrap",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341502",
    "subDistrictNameTh": "ธาตุ",
    "subDistrictNameEn": "That",
    "postcode": "34190",
    "districtCode": "3415",
    "districtNameTh": "วารินชำราบ",
    "districtNameEn": "Warin Chamrap",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341504",
    "subDistrictNameTh": "ท่าลาด",
    "subDistrictNameEn": "Tha Lat",
    "postcode": "34310",
    "districtCode": "3415",
    "districtNameTh": "วารินชำราบ",
    "districtNameEn": "Warin Chamrap",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341505",
    "subDistrictNameTh": "โนนโหนน",
    "subDistrictNameEn": "Non Non",
    "postcode": "34190",
    "districtCode": "3415",
    "districtNameTh": "วารินชำราบ",
    "districtNameEn": "Warin Chamrap",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341507",
    "subDistrictNameTh": "คูเมือง",
    "subDistrictNameEn": "Khu Mueang",
    "postcode": "34190",
    "districtCode": "3415",
    "districtNameTh": "วารินชำราบ",
    "districtNameEn": "Warin Chamrap",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341508",
    "subDistrictNameTh": "สระสมิง",
    "subDistrictNameEn": "Sa Saming",
    "postcode": "34190",
    "districtCode": "3415",
    "districtNameTh": "วารินชำราบ",
    "districtNameEn": "Warin Chamrap",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341510",
    "subDistrictNameTh": "คำน้ำแซบ",
    "subDistrictNameEn": "Kham Nam Saep",
    "postcode": "34190",
    "districtCode": "3415",
    "districtNameTh": "วารินชำราบ",
    "districtNameEn": "Warin Chamrap",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341511",
    "subDistrictNameTh": "บุ่งหวาย",
    "subDistrictNameEn": "Bung Wai",
    "postcode": "34310",
    "districtCode": "3415",
    "districtNameTh": "วารินชำราบ",
    "districtNameEn": "Warin Chamrap",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341515",
    "subDistrictNameTh": "คำขวาง",
    "subDistrictNameEn": "Kham Khwang",
    "postcode": "34190",
    "districtCode": "3415",
    "districtNameTh": "วารินชำราบ",
    "districtNameEn": "Warin Chamrap",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341516",
    "subDistrictNameTh": "โพธิ์ใหญ่",
    "subDistrictNameEn": "Pho Yai",
    "postcode": "34190",
    "districtCode": "3415",
    "districtNameTh": "วารินชำราบ",
    "districtNameEn": "Warin Chamrap",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341518",
    "subDistrictNameTh": "แสนสุข",
    "subDistrictNameEn": "Saen Suk",
    "postcode": "34190",
    "districtCode": "3415",
    "districtNameTh": "วารินชำราบ",
    "districtNameEn": "Warin Chamrap",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341520",
    "subDistrictNameTh": "หนองกินเพล",
    "subDistrictNameEn": "Nong Kin Phen",
    "postcode": "34190",
    "districtCode": "3415",
    "districtNameTh": "วารินชำราบ",
    "districtNameEn": "Warin Chamrap",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341521",
    "subDistrictNameTh": "โนนผึ้ง",
    "subDistrictNameEn": "Non Phueng",
    "postcode": "34190",
    "districtCode": "3415",
    "districtNameTh": "วารินชำราบ",
    "districtNameEn": "Warin Chamrap",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341522",
    "subDistrictNameTh": "เมืองศรีไค",
    "subDistrictNameEn": "Mueang Si Khai",
    "postcode": "34190",
    "districtCode": "3415",
    "districtNameTh": "วารินชำราบ",
    "districtNameEn": "Warin Chamrap",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341524",
    "subDistrictNameTh": "ห้วยขะยุง",
    "subDistrictNameEn": "Huai Khayung",
    "postcode": "34310",
    "districtCode": "3415",
    "districtNameTh": "วารินชำราบ",
    "districtNameEn": "Warin Chamrap",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341526",
    "subDistrictNameTh": "บุ่งไหม",
    "subDistrictNameEn": "Bung Mai",
    "postcode": "34190",
    "districtCode": "3415",
    "districtNameTh": "วารินชำราบ",
    "districtNameEn": "Warin Chamrap",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341901",
    "subDistrictNameTh": "พิบูล",
    "subDistrictNameEn": "Phibun",
    "postcode": "34110",
    "districtCode": "3419",
    "districtNameTh": "พิบูลมังสาหาร",
    "districtNameEn": "Phibun Mangsahan",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341902",
    "subDistrictNameTh": "กุดชมภู",
    "subDistrictNameEn": "Kut Chomphu",
    "postcode": "34110",
    "districtCode": "3419",
    "districtNameTh": "พิบูลมังสาหาร",
    "districtNameEn": "Phibun Mangsahan",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341904",
    "subDistrictNameTh": "ดอนจิก",
    "subDistrictNameEn": "Don Chik",
    "postcode": "34110",
    "districtCode": "3419",
    "districtNameTh": "พิบูลมังสาหาร",
    "districtNameEn": "Phibun Mangsahan",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341905",
    "subDistrictNameTh": "ทรายมูล",
    "subDistrictNameEn": "Sai Mun",
    "postcode": "34110",
    "districtCode": "3419",
    "districtNameTh": "พิบูลมังสาหาร",
    "districtNameEn": "Phibun Mangsahan",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341906",
    "subDistrictNameTh": "นาโพธิ์",
    "subDistrictNameEn": "Na Pho",
    "postcode": "34110",
    "districtCode": "3419",
    "districtNameTh": "พิบูลมังสาหาร",
    "districtNameEn": "Phibun Mangsahan",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341907",
    "subDistrictNameTh": "โนนกลาง",
    "subDistrictNameEn": "Non Klang",
    "postcode": "34110",
    "districtCode": "3419",
    "districtNameTh": "พิบูลมังสาหาร",
    "districtNameEn": "Phibun Mangsahan",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341909",
    "subDistrictNameTh": "โพธิ์ไทร",
    "subDistrictNameEn": "Pho Sai",
    "postcode": "34110",
    "districtCode": "3419",
    "districtNameTh": "พิบูลมังสาหาร",
    "districtNameEn": "Phibun Mangsahan",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341910",
    "subDistrictNameTh": "โพธิ์ศรี",
    "subDistrictNameEn": "Pho Si",
    "postcode": "34110",
    "districtCode": "3419",
    "districtNameTh": "พิบูลมังสาหาร",
    "districtNameEn": "Phibun Mangsahan",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341911",
    "subDistrictNameTh": "ระเว",
    "subDistrictNameEn": "Rawe",
    "postcode": "34110",
    "districtCode": "3419",
    "districtNameTh": "พิบูลมังสาหาร",
    "districtNameEn": "Phibun Mangsahan",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341912",
    "subDistrictNameTh": "ไร่ใต้",
    "subDistrictNameEn": "Rai Tai",
    "postcode": "34110",
    "districtCode": "3419",
    "districtNameTh": "พิบูลมังสาหาร",
    "districtNameEn": "Phibun Mangsahan",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341913",
    "subDistrictNameTh": "หนองบัวฮี",
    "subDistrictNameEn": "Nong Bua Hi",
    "postcode": "34110",
    "districtCode": "3419",
    "districtNameTh": "พิบูลมังสาหาร",
    "districtNameEn": "Phibun Mangsahan",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341914",
    "subDistrictNameTh": "อ่างศิลา",
    "subDistrictNameEn": "Ang Sila",
    "postcode": "34110",
    "districtCode": "3419",
    "districtNameTh": "พิบูลมังสาหาร",
    "districtNameEn": "Phibun Mangsahan",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341918",
    "subDistrictNameTh": "โนนกาหลง",
    "subDistrictNameEn": "Non Kalong",
    "postcode": "34110",
    "districtCode": "3419",
    "districtNameTh": "พิบูลมังสาหาร",
    "districtNameEn": "Phibun Mangsahan",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "341919",
    "subDistrictNameTh": "บ้านแขม",
    "subDistrictNameEn": "Ban Khaem",
    "postcode": "34110",
    "districtCode": "3419",
    "districtNameTh": "พิบูลมังสาหาร",
    "districtNameEn": "Phibun Mangsahan",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "342001",
    "subDistrictNameTh": "ตาลสุม",
    "subDistrictNameEn": "Tan Sum",
    "postcode": "34330",
    "districtCode": "3420",
    "districtNameTh": "ตาลสุม",
    "districtNameEn": "Tan Sum",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "342002",
    "subDistrictNameTh": "สำโรง",
    "subDistrictNameEn": "Samrong",
    "postcode": "34330",
    "districtCode": "3420",
    "districtNameTh": "ตาลสุม",
    "districtNameEn": "Tan Sum",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "342003",
    "subDistrictNameTh": "จิกเทิง",
    "subDistrictNameEn": "Chik Thoeng",
    "postcode": "34330",
    "districtCode": "3420",
    "districtNameTh": "ตาลสุม",
    "districtNameEn": "Tan Sum",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "342004",
    "subDistrictNameTh": "หนองกุง",
    "subDistrictNameEn": "Nong Kung",
    "postcode": "34330",
    "districtCode": "3420",
    "districtNameTh": "ตาลสุม",
    "districtNameEn": "Tan Sum",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "342005",
    "subDistrictNameTh": "นาคาย",
    "subDistrictNameEn": "Na Khai",
    "postcode": "34330",
    "districtCode": "3420",
    "districtNameTh": "ตาลสุม",
    "districtNameEn": "Tan Sum",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "342006",
    "subDistrictNameTh": "คำหว้า",
    "subDistrictNameEn": "Kham Wa",
    "postcode": "34330",
    "districtCode": "3420",
    "districtNameTh": "ตาลสุม",
    "districtNameEn": "Tan Sum",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "342101",
    "subDistrictNameTh": "โพธิ์ไทร",
    "subDistrictNameEn": "Pho Sai",
    "postcode": "34340",
    "districtCode": "3421",
    "districtNameTh": "โพธิ์ไทร",
    "districtNameEn": "Pho Sai",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "342102",
    "subDistrictNameTh": "ม่วงใหญ่",
    "subDistrictNameEn": "Muang Yai",
    "postcode": "34340",
    "districtCode": "3421",
    "districtNameTh": "โพธิ์ไทร",
    "districtNameEn": "Pho Sai",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "342103",
    "subDistrictNameTh": "สำโรง",
    "subDistrictNameEn": "Samrong",
    "postcode": "34340",
    "districtCode": "3421",
    "districtNameTh": "โพธิ์ไทร",
    "districtNameEn": "Pho Sai",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "342104",
    "subDistrictNameTh": "สองคอน",
    "subDistrictNameEn": "Song Khon",
    "postcode": "34340",
    "districtCode": "3421",
    "districtNameTh": "โพธิ์ไทร",
    "districtNameEn": "Pho Sai",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "342105",
    "subDistrictNameTh": "สารภี",
    "subDistrictNameEn": "Saraphi",
    "postcode": "34340",
    "districtCode": "3421",
    "districtNameTh": "โพธิ์ไทร",
    "districtNameEn": "Pho Sai",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "342106",
    "subDistrictNameTh": "เหล่างาม",
    "subDistrictNameEn": "Lao Ngam",
    "postcode": "34340",
    "districtCode": "3421",
    "districtNameTh": "โพธิ์ไทร",
    "districtNameEn": "Pho Sai",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "342201",
    "subDistrictNameTh": "สำโรง",
    "subDistrictNameEn": "Samrong",
    "postcode": "34360",
    "districtCode": "3422",
    "districtNameTh": "สำโรง",
    "districtNameEn": "Samrong",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "342202",
    "subDistrictNameTh": "โคกก่อง",
    "subDistrictNameEn": "Khok Kong",
    "postcode": "34360",
    "districtCode": "3422",
    "districtNameTh": "สำโรง",
    "districtNameEn": "Samrong",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "342203",
    "subDistrictNameTh": "หนองไฮ",
    "subDistrictNameEn": "Nong Hai",
    "postcode": "34360",
    "districtCode": "3422",
    "districtNameTh": "สำโรง",
    "districtNameEn": "Samrong",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "342204",
    "subDistrictNameTh": "ค้อน้อย",
    "subDistrictNameEn": "Kho Noi",
    "postcode": "34360",
    "districtCode": "3422",
    "districtNameTh": "สำโรง",
    "districtNameEn": "Samrong",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "342205",
    "subDistrictNameTh": "โนนกาเล็น",
    "subDistrictNameEn": "Non Ka Len",
    "postcode": "34360",
    "districtCode": "3422",
    "districtNameTh": "สำโรง",
    "districtNameEn": "Samrong",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "342206",
    "subDistrictNameTh": "โคกสว่าง",
    "subDistrictNameEn": "Khok Sawang",
    "postcode": "34360",
    "districtCode": "3422",
    "districtNameTh": "สำโรง",
    "districtNameEn": "Samrong",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "342207",
    "subDistrictNameTh": "โนนกลาง",
    "subDistrictNameEn": "Non Klang",
    "postcode": "34360",
    "districtCode": "3422",
    "districtNameTh": "สำโรง",
    "districtNameEn": "Samrong",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "342208",
    "subDistrictNameTh": "บอน",
    "subDistrictNameEn": "Bon",
    "postcode": "34360",
    "districtCode": "3422",
    "districtNameTh": "สำโรง",
    "districtNameEn": "Samrong",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "342209",
    "subDistrictNameTh": "ขามป้อม",
    "subDistrictNameEn": "Kham Pom",
    "postcode": "34360",
    "districtCode": "3422",
    "districtNameTh": "สำโรง",
    "districtNameEn": "Samrong",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "342401",
    "subDistrictNameTh": "ดอนมดแดง",
    "subDistrictNameEn": "Don Mot Daeng",
    "postcode": "34000",
    "districtCode": "3424",
    "districtNameTh": "ดอนมดแดง",
    "districtNameEn": "Don Mot Daeng",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "342402",
    "subDistrictNameTh": "เหล่าแดง",
    "subDistrictNameEn": "Lao Daeng",
    "postcode": "34000",
    "districtCode": "3424",
    "districtNameTh": "ดอนมดแดง",
    "districtNameEn": "Don Mot Daeng",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "342403",
    "subDistrictNameTh": "ท่าเมือง",
    "subDistrictNameEn": "Tha Muaeng",
    "postcode": "34000",
    "districtCode": "3424",
    "districtNameTh": "ดอนมดแดง",
    "districtNameEn": "Don Mot Daeng",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "342404",
    "subDistrictNameTh": "คำไฮใหญ่",
    "subDistrictNameEn": "Kham Hai Yai",
    "postcode": "34000",
    "districtCode": "3424",
    "districtNameTh": "ดอนมดแดง",
    "districtNameEn": "Don Mot Daeng",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "342501",
    "subDistrictNameTh": "คันไร่",
    "subDistrictNameEn": "Khan Rai",
    "postcode": "34350",
    "districtCode": "3425",
    "districtNameTh": "สิรินธร",
    "districtNameEn": "Sirindhorn",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "342502",
    "subDistrictNameTh": "ช่องเม็ก",
    "subDistrictNameEn": "Chong Mek",
    "postcode": "34350",
    "districtCode": "3425",
    "districtNameTh": "สิรินธร",
    "districtNameEn": "Sirindhorn",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "342503",
    "subDistrictNameTh": "โนนก่อ",
    "subDistrictNameEn": "Lam Dom Noi",
    "postcode": "34350",
    "districtCode": "3425",
    "districtNameTh": "สิรินธร",
    "districtNameEn": "Sirindhorn",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "342504",
    "subDistrictNameTh": "นิคมสร้างตนเองลำโดมน้อย",
    "subDistrictNameEn": "Nikhom Sang Ton-eng",
    "postcode": "34350",
    "districtCode": "3425",
    "districtNameTh": "สิรินธร",
    "districtNameEn": "Sirindhorn",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "342505",
    "subDistrictNameTh": "ฝางคำ",
    "subDistrictNameEn": "Non Ko",
    "postcode": "34350",
    "districtCode": "3425",
    "districtNameTh": "สิรินธร",
    "districtNameEn": "Sirindhorn",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "342506",
    "subDistrictNameTh": "คำเขื่อนแก้ว",
    "subDistrictNameEn": "Kham Khuean Kaeo",
    "postcode": "34350",
    "districtCode": "3425",
    "districtNameTh": "สิรินธร",
    "districtNameEn": "Sirindhorn",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "342602",
    "subDistrictNameTh": "หนองอ้ม",
    "subDistrictNameEn": "Nong Om",
    "postcode": "34160",
    "districtCode": "3426",
    "districtNameTh": "ทุ่งศรีอุดม",
    "districtNameEn": "Thung Si Udom",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "342603",
    "subDistrictNameTh": "นาเกษม",
    "subDistrictNameEn": "Na Kasem",
    "postcode": "34160",
    "districtCode": "3426",
    "districtNameTh": "ทุ่งศรีอุดม",
    "districtNameEn": "Thung Si Udom",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "342604",
    "subDistrictNameTh": "กุดเรือ",
    "subDistrictNameEn": "Kut Ruea",
    "postcode": "34160",
    "districtCode": "3426",
    "districtNameTh": "ทุ่งศรีอุดม",
    "districtNameEn": "Thung Si Udom",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "342605",
    "subDistrictNameTh": "โคกชำแระ",
    "subDistrictNameEn": "Khok Chamrae",
    "postcode": "34160",
    "districtCode": "3426",
    "districtNameTh": "ทุ่งศรีอุดม",
    "districtNameEn": "Thung Si Udom",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "342606",
    "subDistrictNameTh": "นาห่อม",
    "subDistrictNameEn": "Na Hom",
    "postcode": "34160",
    "districtCode": "3426",
    "districtNameTh": "ทุ่งศรีอุดม",
    "districtNameEn": "Thung Si Udom",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "342901",
    "subDistrictNameTh": "นาเยีย",
    "subDistrictNameEn": "Na Yia",
    "postcode": "34160",
    "districtCode": "3429",
    "districtNameTh": "นาเยีย",
    "districtNameEn": "Na Yia",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "342902",
    "subDistrictNameTh": "นาดี",
    "subDistrictNameEn": "Na Di",
    "postcode": "34160",
    "districtCode": "3429",
    "districtNameTh": "นาเยีย",
    "districtNameEn": "Na Yia",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "342903",
    "subDistrictNameTh": "นาเรือง",
    "subDistrictNameEn": "Na Rueang",
    "postcode": "34160",
    "districtCode": "3429",
    "districtNameTh": "นาเยีย",
    "districtNameEn": "Na Yia",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "343001",
    "subDistrictNameTh": "นาตาล",
    "subDistrictNameEn": "Na Tan",
    "postcode": "34170",
    "districtCode": "3430",
    "districtNameTh": "นาตาล",
    "districtNameEn": "Na Tan",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "343002",
    "subDistrictNameTh": "พะลาน",
    "subDistrictNameEn": "Phalan",
    "postcode": "34170",
    "districtCode": "3430",
    "districtNameTh": "นาตาล",
    "districtNameEn": "Na Tan",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "343003",
    "subDistrictNameTh": "กองโพน",
    "subDistrictNameEn": "Kong Phon",
    "postcode": "34170",
    "districtCode": "3430",
    "districtNameTh": "นาตาล",
    "districtNameEn": "Na Tan",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "343004",
    "subDistrictNameTh": "พังเคน",
    "subDistrictNameEn": "Phang Khen",
    "postcode": "34170",
    "districtCode": "3430",
    "districtNameTh": "นาตาล",
    "districtNameEn": "Na Tan",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "343101",
    "subDistrictNameTh": "เหล่าเสือโก้ก",
    "subDistrictNameEn": "Nong Bok",
    "postcode": "34000",
    "districtCode": "3431",
    "districtNameTh": "เหล่าเสือโก้ก",
    "districtNameEn": "Fang Kham",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "343102",
    "subDistrictNameTh": "โพนเมือง",
    "subDistrictNameEn": "Phaeng Yai",
    "postcode": "34000",
    "districtCode": "3431",
    "districtNameTh": "เหล่าเสือโก้ก",
    "districtNameEn": "Fang Kham",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "343103",
    "subDistrictNameTh": "แพงใหญ่",
    "subDistrictNameEn": "Amphoe Lao Suea Kok",
    "postcode": "34000",
    "districtCode": "3431",
    "districtNameTh": "เหล่าเสือโก้ก",
    "districtNameEn": "Fang Kham",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "343104",
    "subDistrictNameTh": "หนองบก",
    "subDistrictNameEn": "Phon Mueang",
    "postcode": "34000",
    "districtCode": "3431",
    "districtNameTh": "เหล่าเสือโก้ก",
    "districtNameEn": "Fang Kham",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "343201",
    "subDistrictNameTh": "แก่งโดม",
    "subDistrictNameEn": "Kaeng Dom",
    "postcode": "34190",
    "districtCode": "3432",
    "districtNameTh": "สว่างวีระวงศ์",
    "districtNameEn": "Sawang Wirawong",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "343202",
    "subDistrictNameTh": "ท่าช้าง",
    "subDistrictNameEn": "Tha Chang",
    "postcode": "34190",
    "districtCode": "3432",
    "districtNameTh": "สว่างวีระวงศ์",
    "districtNameEn": "Sawang Wirawong",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "343203",
    "subDistrictNameTh": "บุ่งมะแลง",
    "subDistrictNameEn": "Bung Malaeng",
    "postcode": "34190",
    "districtCode": "3432",
    "districtNameTh": "สว่างวีระวงศ์",
    "districtNameEn": "Sawang Wirawong",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "343204",
    "subDistrictNameTh": "สว่าง",
    "subDistrictNameEn": "Sawang",
    "postcode": "34190",
    "districtCode": "3432",
    "districtNameTh": "สว่างวีระวงศ์",
    "districtNameEn": "Sawang Wirawong",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "343301",
    "subDistrictNameTh": "ตาเกา",
    "subDistrictNameEn": "Ta Kao",
    "postcode": "34260",
    "districtCode": "3433",
    "districtNameTh": "น้ำขุ่น",
    "districtNameEn": "Nam Khun",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "343302",
    "subDistrictNameTh": "ไพบูลย์",
    "subDistrictNameEn": "Phaibun",
    "postcode": "34260",
    "districtCode": "3433",
    "districtNameTh": "น้ำขุ่น",
    "districtNameEn": "Nam Khun",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "343303",
    "subDistrictNameTh": "ขี้เหล็ก",
    "subDistrictNameEn": "Khilek",
    "postcode": "34260",
    "districtCode": "3433",
    "districtNameTh": "น้ำขุ่น",
    "districtNameEn": "Nam Khun",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "343304",
    "subDistrictNameTh": "โคกสะอาด",
    "subDistrictNameEn": "Khok Sa-at",
    "postcode": "34260",
    "districtCode": "3433",
    "districtNameTh": "น้ำขุ่น",
    "districtNameEn": "Nam Khun",
    "provinceCode": "34",
    "provinceNameTh": "อุบลราชธานี",
    "provinceNameEn": "Ubon Ratchathani"
},
{
    "subDistrictCode": "350101",
    "subDistrictNameTh": "ในเมือง",
    "subDistrictNameEn": "Nai Mueang",
    "postcode": "35000",
    "districtCode": "3501",
    "districtNameTh": "เมืองยโสธร",
    "districtNameEn": "Mueang Yasothon",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350102",
    "subDistrictNameTh": "น้ำคำใหญ่",
    "subDistrictNameEn": "Nam Kham Yai",
    "postcode": "35000",
    "districtCode": "3501",
    "districtNameTh": "เมืองยโสธร",
    "districtNameEn": "Mueang Yasothon",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350103",
    "subDistrictNameTh": "ตาดทอง",
    "subDistrictNameEn": "Tat Thong",
    "postcode": "35000",
    "districtCode": "3501",
    "districtNameTh": "เมืองยโสธร",
    "districtNameEn": "Mueang Yasothon",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350104",
    "subDistrictNameTh": "สำราญ",
    "subDistrictNameEn": "Samran",
    "postcode": "35000",
    "districtCode": "3501",
    "districtNameTh": "เมืองยโสธร",
    "districtNameEn": "Mueang Yasothon",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350105",
    "subDistrictNameTh": "ค้อเหนือ",
    "subDistrictNameEn": "Kho Nuea",
    "postcode": "35000",
    "districtCode": "3501",
    "districtNameTh": "เมืองยโสธร",
    "districtNameEn": "Mueang Yasothon",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350106",
    "subDistrictNameTh": "ดู่ทุ่ง",
    "subDistrictNameEn": "Du Thung",
    "postcode": "35000",
    "districtCode": "3501",
    "districtNameTh": "เมืองยโสธร",
    "districtNameEn": "Mueang Yasothon",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350107",
    "subDistrictNameTh": "เดิด",
    "subDistrictNameEn": "Doet",
    "postcode": "35000",
    "districtCode": "3501",
    "districtNameTh": "เมืองยโสธร",
    "districtNameEn": "Mueang Yasothon",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350108",
    "subDistrictNameTh": "ขั้นไดใหญ่",
    "subDistrictNameEn": "Khan Dai Yai",
    "postcode": "35000",
    "districtCode": "3501",
    "districtNameTh": "เมืองยโสธร",
    "districtNameEn": "Mueang Yasothon",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350109",
    "subDistrictNameTh": "ทุ่งแต้",
    "subDistrictNameEn": "Thung Tae",
    "postcode": "35000",
    "districtCode": "3501",
    "districtNameTh": "เมืองยโสธร",
    "districtNameEn": "Mueang Yasothon",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350110",
    "subDistrictNameTh": "สิงห์",
    "subDistrictNameEn": "Sing",
    "postcode": "35000",
    "districtCode": "3501",
    "districtNameTh": "เมืองยโสธร",
    "districtNameEn": "Mueang Yasothon",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350111",
    "subDistrictNameTh": "นาสะไมย์",
    "subDistrictNameEn": "Na Samai",
    "postcode": "35000",
    "districtCode": "3501",
    "districtNameTh": "เมืองยโสธร",
    "districtNameEn": "Mueang Yasothon",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350112",
    "subDistrictNameTh": "เขื่องคำ",
    "subDistrictNameEn": "Khueang Kham",
    "postcode": "35000",
    "districtCode": "3501",
    "districtNameTh": "เมืองยโสธร",
    "districtNameEn": "Mueang Yasothon",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350113",
    "subDistrictNameTh": "หนองหิน",
    "subDistrictNameEn": "Nong Hin",
    "postcode": "35000",
    "districtCode": "3501",
    "districtNameTh": "เมืองยโสธร",
    "districtNameEn": "Mueang Yasothon",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350114",
    "subDistrictNameTh": "หนองคู",
    "subDistrictNameEn": "Nong Khu",
    "postcode": "35000",
    "districtCode": "3501",
    "districtNameTh": "เมืองยโสธร",
    "districtNameEn": "Mueang Yasothon",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350115",
    "subDistrictNameTh": "ขุมเงิน",
    "subDistrictNameEn": "Khum Ngoen",
    "postcode": "35000",
    "districtCode": "3501",
    "districtNameTh": "เมืองยโสธร",
    "districtNameEn": "Mueang Yasothon",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350116",
    "subDistrictNameTh": "ทุ่งนางโอก",
    "subDistrictNameEn": "Thung Nang Ok",
    "postcode": "35000",
    "districtCode": "3501",
    "districtNameTh": "เมืองยโสธร",
    "districtNameEn": "Mueang Yasothon",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350117",
    "subDistrictNameTh": "หนองเรือ",
    "subDistrictNameEn": "Nong Ruea",
    "postcode": "35000",
    "districtCode": "3501",
    "districtNameTh": "เมืองยโสธร",
    "districtNameEn": "Mueang Yasothon",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350118",
    "subDistrictNameTh": "หนองเป็ด",
    "subDistrictNameEn": "Nong Pet",
    "postcode": "35000",
    "districtCode": "3501",
    "districtNameTh": "เมืองยโสธร",
    "districtNameEn": "Mueang Yasothon",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350201",
    "subDistrictNameTh": "ทรายมูล",
    "subDistrictNameEn": "Sai Mun",
    "postcode": "35170",
    "districtCode": "3502",
    "districtNameTh": "ทรายมูล",
    "districtNameEn": "Sai Mun",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350202",
    "subDistrictNameTh": "ดู่ลาด",
    "subDistrictNameEn": "Du Lat",
    "postcode": "35170",
    "districtCode": "3502",
    "districtNameTh": "ทรายมูล",
    "districtNameEn": "Sai Mun",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350203",
    "subDistrictNameTh": "ดงมะไฟ",
    "subDistrictNameEn": "Dong Mafai",
    "postcode": "35170",
    "districtCode": "3502",
    "districtNameTh": "ทรายมูล",
    "districtNameEn": "Sai Mun",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350204",
    "subDistrictNameTh": "นาเวียง",
    "subDistrictNameEn": "Na Wiang",
    "postcode": "35170",
    "districtCode": "3502",
    "districtNameTh": "ทรายมูล",
    "districtNameEn": "Sai Mun",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350205",
    "subDistrictNameTh": "ไผ่",
    "subDistrictNameEn": "Phai",
    "postcode": "35170",
    "districtCode": "3502",
    "districtNameTh": "ทรายมูล",
    "districtNameEn": "Sai Mun",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350301",
    "subDistrictNameTh": "กุดชุม",
    "subDistrictNameEn": "Kut Chum",
    "postcode": "35140",
    "districtCode": "3503",
    "districtNameTh": "กุดชุม",
    "districtNameEn": "Kut Chum",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350302",
    "subDistrictNameTh": "โนนเปือย",
    "subDistrictNameEn": "Non Pueai",
    "postcode": "35140",
    "districtCode": "3503",
    "districtNameTh": "กุดชุม",
    "districtNameEn": "Kut Chum",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350303",
    "subDistrictNameTh": "กำแมด",
    "subDistrictNameEn": "Kammaet",
    "postcode": "35140",
    "districtCode": "3503",
    "districtNameTh": "กุดชุม",
    "districtNameEn": "Kut Chum",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350304",
    "subDistrictNameTh": "นาโส่",
    "subDistrictNameEn": "Na So",
    "postcode": "35140",
    "districtCode": "3503",
    "districtNameTh": "กุดชุม",
    "districtNameEn": "Kut Chum",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350305",
    "subDistrictNameTh": "ห้วยแก้ง",
    "subDistrictNameEn": "Huai Kaeng",
    "postcode": "35140",
    "districtCode": "3503",
    "districtNameTh": "กุดชุม",
    "districtNameEn": "Kut Chum",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350306",
    "subDistrictNameTh": "หนองหมี",
    "subDistrictNameEn": "Nong Mi",
    "postcode": "35140",
    "districtCode": "3503",
    "districtNameTh": "กุดชุม",
    "districtNameEn": "Kut Chum",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350307",
    "subDistrictNameTh": "โพนงาม",
    "subDistrictNameEn": "Phon Ngam",
    "postcode": "35140",
    "districtCode": "3503",
    "districtNameTh": "กุดชุม",
    "districtNameEn": "Kut Chum",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350308",
    "subDistrictNameTh": "คำน้ำสร้าง",
    "subDistrictNameEn": "Kham Nam Sang",
    "postcode": "35140",
    "districtCode": "3503",
    "districtNameTh": "กุดชุม",
    "districtNameEn": "Kut Chum",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350309",
    "subDistrictNameTh": "หนองแหน",
    "subDistrictNameEn": "Nong Nae",
    "postcode": "35140",
    "districtCode": "3503",
    "districtNameTh": "กุดชุม",
    "districtNameEn": "Kut Chum",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350401",
    "subDistrictNameTh": "ลุมพุก",
    "subDistrictNameEn": "Lumphuk",
    "postcode": "35110",
    "districtCode": "3504",
    "districtNameTh": "คำเขื่อนแก้ว",
    "districtNameEn": "Kham Khuean Kaeo",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350402",
    "subDistrictNameTh": "ย่อ",
    "subDistrictNameEn": "Yo",
    "postcode": "35110",
    "districtCode": "3504",
    "districtNameTh": "คำเขื่อนแก้ว",
    "districtNameEn": "Kham Khuean Kaeo",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350403",
    "subDistrictNameTh": "สงเปือย",
    "subDistrictNameEn": "Song Pueai",
    "postcode": "35110",
    "districtCode": "3504",
    "districtNameTh": "คำเขื่อนแก้ว",
    "districtNameEn": "Kham Khuean Kaeo",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350404",
    "subDistrictNameTh": "โพนทัน",
    "subDistrictNameEn": "Phon Than",
    "postcode": "35110",
    "districtCode": "3504",
    "districtNameTh": "คำเขื่อนแก้ว",
    "districtNameEn": "Kham Khuean Kaeo",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350405",
    "subDistrictNameTh": "ทุ่งมน",
    "subDistrictNameEn": "Thung Mon",
    "postcode": "35110",
    "districtCode": "3504",
    "districtNameTh": "คำเขื่อนแก้ว",
    "districtNameEn": "Kham Khuean Kaeo",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350406",
    "subDistrictNameTh": "นาคำ",
    "subDistrictNameEn": "Na Kham",
    "postcode": "35180",
    "districtCode": "3504",
    "districtNameTh": "คำเขื่อนแก้ว",
    "districtNameEn": "Kham Khuean Kaeo",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350407",
    "subDistrictNameTh": "ดงแคนใหญ่",
    "subDistrictNameEn": "Dong Khaen Yai",
    "postcode": "35180",
    "districtCode": "3504",
    "districtNameTh": "คำเขื่อนแก้ว",
    "districtNameEn": "Kham Khuean Kaeo",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350408",
    "subDistrictNameTh": "กู่จาน",
    "subDistrictNameEn": "Ku Chan",
    "postcode": "35110",
    "districtCode": "3504",
    "districtNameTh": "คำเขื่อนแก้ว",
    "districtNameEn": "Kham Khuean Kaeo",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350409",
    "subDistrictNameTh": "นาแก",
    "subDistrictNameEn": "Na Kae",
    "postcode": "35180",
    "districtCode": "3504",
    "districtNameTh": "คำเขื่อนแก้ว",
    "districtNameEn": "Kham Khuean Kaeo",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350410",
    "subDistrictNameTh": "กุดกุง",
    "subDistrictNameEn": "Kut Kung",
    "postcode": "35110",
    "districtCode": "3504",
    "districtNameTh": "คำเขื่อนแก้ว",
    "districtNameEn": "Kham Khuean Kaeo",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350411",
    "subDistrictNameTh": "เหล่าไฮ",
    "subDistrictNameEn": "Lao Hai",
    "postcode": "35110",
    "districtCode": "3504",
    "districtNameTh": "คำเขื่อนแก้ว",
    "districtNameEn": "Kham Khuean Kaeo",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350412",
    "subDistrictNameTh": "แคนน้อย",
    "subDistrictNameEn": "Khaen Noi",
    "postcode": "35180",
    "districtCode": "3504",
    "districtNameTh": "คำเขื่อนแก้ว",
    "districtNameEn": "Kham Khuean Kaeo",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350413",
    "subDistrictNameTh": "ดงเจริญ",
    "subDistrictNameEn": "Dong Charoen",
    "postcode": "35110",
    "districtCode": "3504",
    "districtNameTh": "คำเขื่อนแก้ว",
    "districtNameEn": "Kham Khuean Kaeo",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350501",
    "subDistrictNameTh": "โพธิ์ไทร",
    "subDistrictNameEn": "Pho Sai",
    "postcode": "35150",
    "districtCode": "3505",
    "districtNameTh": "ป่าติ้ว",
    "districtNameEn": "Pa Tio",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350502",
    "subDistrictNameTh": "กระจาย",
    "subDistrictNameEn": "Krachai",
    "postcode": "35150",
    "districtCode": "3505",
    "districtNameTh": "ป่าติ้ว",
    "districtNameEn": "Pa Tio",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350503",
    "subDistrictNameTh": "โคกนาโก",
    "subDistrictNameEn": "Khok Na Ko",
    "postcode": "35150",
    "districtCode": "3505",
    "districtNameTh": "ป่าติ้ว",
    "districtNameEn": "Pa Tio",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350504",
    "subDistrictNameTh": "เชียงเพ็ง",
    "subDistrictNameEn": "Chiang Pheng",
    "postcode": "35150",
    "districtCode": "3505",
    "districtNameTh": "ป่าติ้ว",
    "districtNameEn": "Pa Tio",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350505",
    "subDistrictNameTh": "ศรีฐาน",
    "subDistrictNameEn": "Si Than",
    "postcode": "35150",
    "districtCode": "3505",
    "districtNameTh": "ป่าติ้ว",
    "districtNameEn": "Pa Tio",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350601",
    "subDistrictNameTh": "ฟ้าหยาด",
    "subDistrictNameEn": "Fa Yat",
    "postcode": "35130",
    "districtCode": "3506",
    "districtNameTh": "มหาชนะชัย",
    "districtNameEn": "Maha Chana Chai",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350602",
    "subDistrictNameTh": "หัวเมือง",
    "subDistrictNameEn": "Hua Mueang",
    "postcode": "35130",
    "districtCode": "3506",
    "districtNameTh": "มหาชนะชัย",
    "districtNameEn": "Maha Chana Chai",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350603",
    "subDistrictNameTh": "คูเมือง",
    "subDistrictNameEn": "Khu Mueang",
    "postcode": "35130",
    "districtCode": "3506",
    "districtNameTh": "มหาชนะชัย",
    "districtNameEn": "Maha Chana Chai",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350604",
    "subDistrictNameTh": "ผือฮี",
    "subDistrictNameEn": "Phue Hi",
    "postcode": "35130",
    "districtCode": "3506",
    "districtNameTh": "มหาชนะชัย",
    "districtNameEn": "Maha Chana Chai",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350605",
    "subDistrictNameTh": "บากเรือ",
    "subDistrictNameEn": "Bak Ruea",
    "postcode": "35130",
    "districtCode": "3506",
    "districtNameTh": "มหาชนะชัย",
    "districtNameEn": "Maha Chana Chai",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350606",
    "subDistrictNameTh": "ม่วง",
    "subDistrictNameEn": "Muang",
    "postcode": "35130",
    "districtCode": "3506",
    "districtNameTh": "มหาชนะชัย",
    "districtNameEn": "Maha Chana Chai",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350607",
    "subDistrictNameTh": "โนนทราย",
    "subDistrictNameEn": "Non Sai",
    "postcode": "35130",
    "districtCode": "3506",
    "districtNameTh": "มหาชนะชัย",
    "districtNameEn": "Maha Chana Chai",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350608",
    "subDistrictNameTh": "บึงแก",
    "subDistrictNameEn": "Bueng Kae",
    "postcode": "35130",
    "districtCode": "3506",
    "districtNameTh": "มหาชนะชัย",
    "districtNameEn": "Maha Chana Chai",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350609",
    "subDistrictNameTh": "พระเสาร์",
    "subDistrictNameEn": "Phra Sao",
    "postcode": "35130",
    "districtCode": "3506",
    "districtNameTh": "มหาชนะชัย",
    "districtNameEn": "Maha Chana Chai",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350610",
    "subDistrictNameTh": "สงยาง",
    "subDistrictNameEn": "Song Yang",
    "postcode": "35130",
    "districtCode": "3506",
    "districtNameTh": "มหาชนะชัย",
    "districtNameEn": "Maha Chana Chai",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350701",
    "subDistrictNameTh": "ฟ้าห่วน",
    "subDistrictNameEn": "Fa Huan",
    "postcode": "35160",
    "districtCode": "3507",
    "districtNameTh": "ค้อวัง",
    "districtNameEn": "Kho Wang",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350702",
    "subDistrictNameTh": "กุดน้ำใส",
    "subDistrictNameEn": "Kut Nam Sai",
    "postcode": "35160",
    "districtCode": "3507",
    "districtNameTh": "ค้อวัง",
    "districtNameEn": "Kho Wang",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350703",
    "subDistrictNameTh": "น้ำอ้อม",
    "subDistrictNameEn": "Nam Om",
    "postcode": "35160",
    "districtCode": "3507",
    "districtNameTh": "ค้อวัง",
    "districtNameEn": "Kho Wang",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350704",
    "subDistrictNameTh": "ค้อวัง",
    "subDistrictNameEn": "Kho Wang",
    "postcode": "35160",
    "districtCode": "3507",
    "districtNameTh": "ค้อวัง",
    "districtNameEn": "Kho Wang",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350802",
    "subDistrictNameTh": "บุ่งค้า",
    "subDistrictNameEn": "Bung Kha",
    "postcode": "35120",
    "districtCode": "3508",
    "districtNameTh": "เลิงนกทา",
    "districtNameEn": "Loeng Nok Tha",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350803",
    "subDistrictNameTh": "สวาท",
    "subDistrictNameEn": "Sawat",
    "postcode": "35120",
    "districtCode": "3508",
    "districtNameTh": "เลิงนกทา",
    "districtNameEn": "Loeng Nok Tha",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350805",
    "subDistrictNameTh": "ห้องแซง",
    "subDistrictNameEn": "Hong Saeng",
    "postcode": "35120",
    "districtCode": "3508",
    "districtNameTh": "เลิงนกทา",
    "districtNameEn": "Loeng Nok Tha",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350806",
    "subDistrictNameTh": "สามัคคี",
    "subDistrictNameEn": "Samakkhi",
    "postcode": "35120",
    "districtCode": "3508",
    "districtNameTh": "เลิงนกทา",
    "districtNameEn": "Loeng Nok Tha",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350807",
    "subDistrictNameTh": "กุดเชียงหมี",
    "subDistrictNameEn": "Kut Chiang Mi",
    "postcode": "35120",
    "districtCode": "3508",
    "districtNameTh": "เลิงนกทา",
    "districtNameEn": "Loeng Nok Tha",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350810",
    "subDistrictNameTh": "สามแยก",
    "subDistrictNameEn": "Sam Yaek",
    "postcode": "35120",
    "districtCode": "3508",
    "districtNameTh": "เลิงนกทา",
    "districtNameEn": "Loeng Nok Tha",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350811",
    "subDistrictNameTh": "กุดแห่",
    "subDistrictNameEn": "Kut Hae",
    "postcode": "35120",
    "districtCode": "3508",
    "districtNameTh": "เลิงนกทา",
    "districtNameEn": "Loeng Nok Tha",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350812",
    "subDistrictNameTh": "โคกสำราญ",
    "subDistrictNameEn": "Khok Samran",
    "postcode": "35120",
    "districtCode": "3508",
    "districtNameTh": "เลิงนกทา",
    "districtNameEn": "Loeng Nok Tha",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350813",
    "subDistrictNameTh": "สร้างมิ่ง",
    "subDistrictNameEn": "Sang Ming",
    "postcode": "35120",
    "districtCode": "3508",
    "districtNameTh": "เลิงนกทา",
    "districtNameEn": "Loeng Nok Tha",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350814",
    "subDistrictNameTh": "ศรีแก้ว",
    "subDistrictNameEn": "Si Kaeo",
    "postcode": "35120",
    "districtCode": "3508",
    "districtNameTh": "เลิงนกทา",
    "districtNameEn": "Loeng Nok Tha",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350901",
    "subDistrictNameTh": "ไทยเจริญ",
    "subDistrictNameEn": "Thai Charoen",
    "postcode": "35120",
    "districtCode": "3509",
    "districtNameTh": "ไทยเจริญ",
    "districtNameEn": "Thai Charoen",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350902",
    "subDistrictNameTh": "น้ำคำ",
    "subDistrictNameEn": "Nam Kham",
    "postcode": "35120",
    "districtCode": "3509",
    "districtNameTh": "ไทยเจริญ",
    "districtNameEn": "Thai Charoen",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350903",
    "subDistrictNameTh": "ส้มผ่อ",
    "subDistrictNameEn": "Som Pho",
    "postcode": "35120",
    "districtCode": "3509",
    "districtNameTh": "ไทยเจริญ",
    "districtNameEn": "Thai Charoen",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350904",
    "subDistrictNameTh": "คำเตย",
    "subDistrictNameEn": "Kham Toei",
    "postcode": "35120",
    "districtCode": "3509",
    "districtNameTh": "ไทยเจริญ",
    "districtNameEn": "Thai Charoen",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "350905",
    "subDistrictNameTh": "คำไผ่",
    "subDistrictNameEn": "Kham Phai",
    "postcode": "35120",
    "districtCode": "3509",
    "districtNameTh": "ไทยเจริญ",
    "districtNameEn": "Thai Charoen",
    "provinceCode": "35",
    "provinceNameTh": "ยโสธร",
    "provinceNameEn": "Yasothon"
},
{
    "subDistrictCode": "360101",
    "subDistrictNameTh": "ในเมือง",
    "subDistrictNameEn": "Nai Mueang",
    "postcode": "36000",
    "districtCode": "3601",
    "districtNameTh": "เมืองชัยภูมิ",
    "districtNameEn": "Mueang Chaiyaphum",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360102",
    "subDistrictNameTh": "รอบเมือง",
    "subDistrictNameEn": "Rop Mueang",
    "postcode": "36000",
    "districtCode": "3601",
    "districtNameTh": "เมืองชัยภูมิ",
    "districtNameEn": "Mueang Chaiyaphum",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360103",
    "subDistrictNameTh": "โพนทอง",
    "subDistrictNameEn": "Phon Thong",
    "postcode": "36000",
    "districtCode": "3601",
    "districtNameTh": "เมืองชัยภูมิ",
    "districtNameEn": "Mueang Chaiyaphum",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360104",
    "subDistrictNameTh": "นาฝาย",
    "subDistrictNameEn": "Na Fai",
    "postcode": "36000",
    "districtCode": "3601",
    "districtNameTh": "เมืองชัยภูมิ",
    "districtNameEn": "Mueang Chaiyaphum",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360105",
    "subDistrictNameTh": "บ้านค่าย",
    "subDistrictNameEn": "Ban Khai",
    "postcode": "36240",
    "districtCode": "3601",
    "districtNameTh": "เมืองชัยภูมิ",
    "districtNameEn": "Mueang Chaiyaphum",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360106",
    "subDistrictNameTh": "กุดตุ้ม",
    "subDistrictNameEn": "Kut Tum",
    "postcode": "36000",
    "districtCode": "3601",
    "districtNameTh": "เมืองชัยภูมิ",
    "districtNameEn": "Mueang Chaiyaphum",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360107",
    "subDistrictNameTh": "ชีลอง",
    "subDistrictNameEn": "Chi Long",
    "postcode": "36000",
    "districtCode": "3601",
    "districtNameTh": "เมืองชัยภูมิ",
    "districtNameEn": "Mueang Chaiyaphum",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360108",
    "subDistrictNameTh": "บ้านเล่า",
    "subDistrictNameEn": "Ban Lao",
    "postcode": "36000",
    "districtCode": "3601",
    "districtNameTh": "เมืองชัยภูมิ",
    "districtNameEn": "Mueang Chaiyaphum",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360109",
    "subDistrictNameTh": "นาเสียว",
    "subDistrictNameEn": "Na Siao",
    "postcode": "36000",
    "districtCode": "3601",
    "districtNameTh": "เมืองชัยภูมิ",
    "districtNameEn": "Mueang Chaiyaphum",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360110",
    "subDistrictNameTh": "หนองนาแซง",
    "subDistrictNameEn": "Nong Na Saeng",
    "postcode": "36000",
    "districtCode": "3601",
    "districtNameTh": "เมืองชัยภูมิ",
    "districtNameEn": "Mueang Chaiyaphum",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360111",
    "subDistrictNameTh": "ลาดใหญ่",
    "subDistrictNameEn": "Lat Yai",
    "postcode": "36000",
    "districtCode": "3601",
    "districtNameTh": "เมืองชัยภูมิ",
    "districtNameEn": "Mueang Chaiyaphum",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360112",
    "subDistrictNameTh": "หนองไผ่",
    "subDistrictNameEn": "Nong Phai",
    "postcode": "36240",
    "districtCode": "3601",
    "districtNameTh": "เมืองชัยภูมิ",
    "districtNameEn": "Mueang Chaiyaphum",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360113",
    "subDistrictNameTh": "ท่าหินโงม",
    "subDistrictNameEn": "Tha Hin Ngom",
    "postcode": "36000",
    "districtCode": "3601",
    "districtNameTh": "เมืองชัยภูมิ",
    "districtNameEn": "Mueang Chaiyaphum",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360114",
    "subDistrictNameTh": "ห้วยต้อน",
    "subDistrictNameEn": "Huai Ton",
    "postcode": "36000",
    "districtCode": "3601",
    "districtNameTh": "เมืองชัยภูมิ",
    "districtNameEn": "Mueang Chaiyaphum",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360115",
    "subDistrictNameTh": "ห้วยบง",
    "subDistrictNameEn": "Huai Bong",
    "postcode": "36000",
    "districtCode": "3601",
    "districtNameTh": "เมืองชัยภูมิ",
    "districtNameEn": "Mueang Chaiyaphum",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360116",
    "subDistrictNameTh": "โนนสำราญ",
    "subDistrictNameEn": "Non Samran",
    "postcode": "36240",
    "districtCode": "3601",
    "districtNameTh": "เมืองชัยภูมิ",
    "districtNameEn": "Mueang Chaiyaphum",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360117",
    "subDistrictNameTh": "โคกสูง",
    "subDistrictNameEn": "Khok Sung",
    "postcode": "36000",
    "districtCode": "3601",
    "districtNameTh": "เมืองชัยภูมิ",
    "districtNameEn": "Mueang Chaiyaphum",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360118",
    "subDistrictNameTh": "บุ่งคล้า",
    "subDistrictNameEn": "Bung Khla",
    "postcode": "36000",
    "districtCode": "3601",
    "districtNameTh": "เมืองชัยภูมิ",
    "districtNameEn": "Mueang Chaiyaphum",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360119",
    "subDistrictNameTh": "ซับสีทอง",
    "subDistrictNameEn": "Sap Si Thong",
    "postcode": "36000",
    "districtCode": "3601",
    "districtNameTh": "เมืองชัยภูมิ",
    "districtNameEn": "Mueang Chaiyaphum",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360201",
    "subDistrictNameTh": "บ้านเขว้า",
    "subDistrictNameEn": "Ban Khwao",
    "postcode": "36170",
    "districtCode": "3602",
    "districtNameTh": "บ้านเขว้า",
    "districtNameEn": "Ban Khwao",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360202",
    "subDistrictNameTh": "ตลาดแร้ง",
    "subDistrictNameEn": "Talat Raeng",
    "postcode": "36170",
    "districtCode": "3602",
    "districtNameTh": "บ้านเขว้า",
    "districtNameEn": "Ban Khwao",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360203",
    "subDistrictNameTh": "ลุ่มลำชี",
    "subDistrictNameEn": "Lum Lam Chi",
    "postcode": "36170",
    "districtCode": "3602",
    "districtNameTh": "บ้านเขว้า",
    "districtNameEn": "Ban Khwao",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360204",
    "subDistrictNameTh": "ชีบน",
    "subDistrictNameEn": "Chi Bon",
    "postcode": "36170",
    "districtCode": "3602",
    "districtNameTh": "บ้านเขว้า",
    "districtNameEn": "Ban Khwao",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360205",
    "subDistrictNameTh": "ภูแลนคา",
    "subDistrictNameEn": "Phu Laen Kha",
    "postcode": "36170",
    "districtCode": "3602",
    "districtNameTh": "บ้านเขว้า",
    "districtNameEn": "Ban Khwao",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360206",
    "subDistrictNameTh": "โนนแดง",
    "subDistrictNameEn": "Non Daeng",
    "postcode": "36170",
    "districtCode": "3602",
    "districtNameTh": "บ้านเขว้า",
    "districtNameEn": "Ban Khwao",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360301",
    "subDistrictNameTh": "คอนสวรรค์",
    "subDistrictNameEn": "Khon Sawan",
    "postcode": "36140",
    "districtCode": "3603",
    "districtNameTh": "คอนสวรรค์",
    "districtNameEn": "Khon Sawan",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360302",
    "subDistrictNameTh": "ยางหวาย",
    "subDistrictNameEn": "Yang Wai",
    "postcode": "36140",
    "districtCode": "3603",
    "districtNameTh": "คอนสวรรค์",
    "districtNameEn": "Khon Sawan",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360303",
    "subDistrictNameTh": "ช่องสามหมอ",
    "subDistrictNameEn": "Chong Sam Mo",
    "postcode": "36140",
    "districtCode": "3603",
    "districtNameTh": "คอนสวรรค์",
    "districtNameEn": "Khon Sawan",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360304",
    "subDistrictNameTh": "โนนสะอาด",
    "subDistrictNameEn": "Non Sa-at",
    "postcode": "36140",
    "districtCode": "3603",
    "districtNameTh": "คอนสวรรค์",
    "districtNameEn": "Khon Sawan",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360305",
    "subDistrictNameTh": "ห้วยไร่",
    "subDistrictNameEn": "Huai Rai",
    "postcode": "36140",
    "districtCode": "3603",
    "districtNameTh": "คอนสวรรค์",
    "districtNameEn": "Khon Sawan",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360306",
    "subDistrictNameTh": "บ้านโสก",
    "subDistrictNameEn": "Ban Sok",
    "postcode": "36140",
    "districtCode": "3603",
    "districtNameTh": "คอนสวรรค์",
    "districtNameEn": "Khon Sawan",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360307",
    "subDistrictNameTh": "โคกมั่งงอย",
    "subDistrictNameEn": "Khok Mang Ngoi",
    "postcode": "36140",
    "districtCode": "3603",
    "districtNameTh": "คอนสวรรค์",
    "districtNameEn": "Khon Sawan",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360308",
    "subDistrictNameTh": "หนองขาม",
    "subDistrictNameEn": "Nong Kham",
    "postcode": "36140",
    "districtCode": "3603",
    "districtNameTh": "คอนสวรรค์",
    "districtNameEn": "Khon Sawan",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360309",
    "subDistrictNameTh": "ศรีสำราญ",
    "subDistrictNameEn": "Si Samran",
    "postcode": "36140",
    "districtCode": "3603",
    "districtNameTh": "คอนสวรรค์",
    "districtNameEn": "Khon Sawan",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360401",
    "subDistrictNameTh": "บ้านยาง",
    "subDistrictNameEn": "Ban Yang",
    "postcode": "36120",
    "districtCode": "3604",
    "districtNameTh": "เกษตรสมบูรณ์",
    "districtNameEn": "Kaset Sombun",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360402",
    "subDistrictNameTh": "บ้านหัน",
    "subDistrictNameEn": "Ban Han",
    "postcode": "36120",
    "districtCode": "3604",
    "districtNameTh": "เกษตรสมบูรณ์",
    "districtNameEn": "Kaset Sombun",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360403",
    "subDistrictNameTh": "บ้านเดื่อ",
    "subDistrictNameEn": "Ban Duea",
    "postcode": "36120",
    "districtCode": "3604",
    "districtNameTh": "เกษตรสมบูรณ์",
    "districtNameEn": "Kaset Sombun",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360404",
    "subDistrictNameTh": "บ้านเป้า",
    "subDistrictNameEn": "Ban Pao",
    "postcode": "36120",
    "districtCode": "3604",
    "districtNameTh": "เกษตรสมบูรณ์",
    "districtNameEn": "Kaset Sombun",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360405",
    "subDistrictNameTh": "กุดเลาะ",
    "subDistrictNameEn": "Kut Lo",
    "postcode": "36120",
    "districtCode": "3604",
    "districtNameTh": "เกษตรสมบูรณ์",
    "districtNameEn": "Kaset Sombun",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360406",
    "subDistrictNameTh": "โนนกอก",
    "subDistrictNameEn": "Non Kok",
    "postcode": "36120",
    "districtCode": "3604",
    "districtNameTh": "เกษตรสมบูรณ์",
    "districtNameEn": "Kaset Sombun",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360407",
    "subDistrictNameTh": "สระโพนทอง",
    "subDistrictNameEn": "Sa Phon Thong",
    "postcode": "36120",
    "districtCode": "3604",
    "districtNameTh": "เกษตรสมบูรณ์",
    "districtNameEn": "Kaset Sombun",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360408",
    "subDistrictNameTh": "หนองข่า",
    "subDistrictNameEn": "Nong Kha",
    "postcode": "36120",
    "districtCode": "3604",
    "districtNameTh": "เกษตรสมบูรณ์",
    "districtNameEn": "Kaset Sombun",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360409",
    "subDistrictNameTh": "หนองโพนงาม",
    "subDistrictNameEn": "Nong Phon Ngam",
    "postcode": "36120",
    "districtCode": "3604",
    "districtNameTh": "เกษตรสมบูรณ์",
    "districtNameEn": "Kaset Sombun",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360410",
    "subDistrictNameTh": "บ้านบัว",
    "subDistrictNameEn": "Ban Bua",
    "postcode": "36120",
    "districtCode": "3604",
    "districtNameTh": "เกษตรสมบูรณ์",
    "districtNameEn": "Kaset Sombun",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360412",
    "subDistrictNameTh": "โนนทอง",
    "subDistrictNameEn": "Non Thong",
    "postcode": "36120",
    "districtCode": "3604",
    "districtNameTh": "เกษตรสมบูรณ์",
    "districtNameEn": "Kaset Sombun",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360501",
    "subDistrictNameTh": "หนองบัวแดง",
    "subDistrictNameEn": "Nong Bua Daeng",
    "postcode": "36210",
    "districtCode": "3605",
    "districtNameTh": "หนองบัวแดง",
    "districtNameEn": "Nong Bua Daeng",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360502",
    "subDistrictNameTh": "กุดชุมแสง",
    "subDistrictNameEn": "Kut Chum Saeng",
    "postcode": "36210",
    "districtCode": "3605",
    "districtNameTh": "หนองบัวแดง",
    "districtNameEn": "Nong Bua Daeng",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360503",
    "subDistrictNameTh": "ถ้ำวัวแดง",
    "subDistrictNameEn": "Tham Wua Daeng",
    "postcode": "36210",
    "districtCode": "3605",
    "districtNameTh": "หนองบัวแดง",
    "districtNameEn": "Nong Bua Daeng",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360504",
    "subDistrictNameTh": "นางแดด",
    "subDistrictNameEn": "Nang Daet",
    "postcode": "36210",
    "districtCode": "3605",
    "districtNameTh": "หนองบัวแดง",
    "districtNameEn": "Nong Bua Daeng",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360507",
    "subDistrictNameTh": "หนองแวง",
    "subDistrictNameEn": "Nong Waeng",
    "postcode": "36210",
    "districtCode": "3605",
    "districtNameTh": "หนองบัวแดง",
    "districtNameEn": "Nong Bua Daeng",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360508",
    "subDistrictNameTh": "คูเมือง",
    "subDistrictNameEn": "Khu Mueang",
    "postcode": "36210",
    "districtCode": "3605",
    "districtNameTh": "หนองบัวแดง",
    "districtNameEn": "Nong Bua Daeng",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360509",
    "subDistrictNameTh": "ท่าใหญ่",
    "subDistrictNameEn": "Tha Yai",
    "postcode": "36210",
    "districtCode": "3605",
    "districtNameTh": "หนองบัวแดง",
    "districtNameEn": "Nong Bua Daeng",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360511",
    "subDistrictNameTh": "วังชมภู",
    "subDistrictNameEn": "Wang Chomphu",
    "postcode": "36210",
    "districtCode": "3605",
    "districtNameTh": "หนองบัวแดง",
    "districtNameEn": "Nong Bua Daeng",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360601",
    "subDistrictNameTh": "บ้านกอก",
    "subDistrictNameEn": "Ban Kok",
    "postcode": "36130",
    "districtCode": "3606",
    "districtNameTh": "จัตุรัส",
    "districtNameEn": "Chatturat",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360602",
    "subDistrictNameTh": "หนองบัวบาน",
    "subDistrictNameEn": "Nong Bua Ban",
    "postcode": "36130",
    "districtCode": "3606",
    "districtNameTh": "จัตุรัส",
    "districtNameEn": "Chatturat",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360603",
    "subDistrictNameTh": "บ้านขาม",
    "subDistrictNameEn": "Ban Kham",
    "postcode": "36130",
    "districtCode": "3606",
    "districtNameTh": "จัตุรัส",
    "districtNameEn": "Chatturat",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360605",
    "subDistrictNameTh": "กุดน้ำใส",
    "subDistrictNameEn": "Kut Nam Sai",
    "postcode": "36130",
    "districtCode": "3606",
    "districtNameTh": "จัตุรัส",
    "districtNameEn": "Chatturat",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360606",
    "subDistrictNameTh": "หนองโดน",
    "subDistrictNameEn": "Nong Don",
    "postcode": "36130",
    "districtCode": "3606",
    "districtNameTh": "จัตุรัส",
    "districtNameEn": "Chatturat",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360607",
    "subDistrictNameTh": "ละหาน",
    "subDistrictNameEn": "Lahan",
    "postcode": "36130",
    "districtCode": "3606",
    "districtNameTh": "จัตุรัส",
    "districtNameEn": "Chatturat",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360610",
    "subDistrictNameTh": "หนองบัวใหญ่",
    "subDistrictNameEn": "Nong Bua Yai",
    "postcode": "36130",
    "districtCode": "3606",
    "districtNameTh": "จัตุรัส",
    "districtNameEn": "Chatturat",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360611",
    "subDistrictNameTh": "หนองบัวโคก",
    "subDistrictNameEn": "Nong Bua Khok",
    "postcode": "36220",
    "districtCode": "3606",
    "districtNameTh": "จัตุรัส",
    "districtNameEn": "Chatturat",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360613",
    "subDistrictNameTh": "ส้มป่อย",
    "subDistrictNameEn": "Som Poi",
    "postcode": "36130",
    "districtCode": "3606",
    "districtNameTh": "จัตุรัส",
    "districtNameEn": "Chatturat",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360701",
    "subDistrictNameTh": "บ้านชวน",
    "subDistrictNameEn": "Ban Chuan",
    "postcode": "36160",
    "districtCode": "3607",
    "districtNameTh": "บำเหน็จณรงค์",
    "districtNameEn": "Bamnet Narong",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360702",
    "subDistrictNameTh": "บ้านเพชร",
    "subDistrictNameEn": "Ban Phet",
    "postcode": "36160",
    "districtCode": "3607",
    "districtNameTh": "บำเหน็จณรงค์",
    "districtNameEn": "Bamnet Narong",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360703",
    "subDistrictNameTh": "บ้านตาล",
    "subDistrictNameEn": "Ban Tan",
    "postcode": "36220",
    "districtCode": "3607",
    "districtNameTh": "บำเหน็จณรงค์",
    "districtNameEn": "Bamnet Narong",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360704",
    "subDistrictNameTh": "หัวทะเล",
    "subDistrictNameEn": "Hua Thale",
    "postcode": "36220",
    "districtCode": "3607",
    "districtNameTh": "บำเหน็จณรงค์",
    "districtNameEn": "Bamnet Narong",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360705",
    "subDistrictNameTh": "โคกเริงรมย์",
    "subDistrictNameEn": "Khok Roeng Rom",
    "postcode": "36160",
    "districtCode": "3607",
    "districtNameTh": "บำเหน็จณรงค์",
    "districtNameEn": "Bamnet Narong",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360706",
    "subDistrictNameTh": "เกาะมะนาว",
    "subDistrictNameEn": "Ko Manao",
    "postcode": "36160",
    "districtCode": "3607",
    "districtNameTh": "บำเหน็จณรงค์",
    "districtNameEn": "Bamnet Narong",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360707",
    "subDistrictNameTh": "โคกเพชรพัฒนา",
    "subDistrictNameEn": "Khok Phet Phatthana",
    "postcode": "36160",
    "districtCode": "3607",
    "districtNameTh": "บำเหน็จณรงค์",
    "districtNameEn": "Bamnet Narong",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360801",
    "subDistrictNameTh": "หนองบัวระเหว",
    "subDistrictNameEn": "Nong Bua Rawe",
    "postcode": "36250",
    "districtCode": "3608",
    "districtNameTh": "หนองบัวระเหว",
    "districtNameEn": "Nong Bua Rawe",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360802",
    "subDistrictNameTh": "วังตะเฆ่",
    "subDistrictNameEn": "Wang Takhe",
    "postcode": "36250",
    "districtCode": "3608",
    "districtNameTh": "หนองบัวระเหว",
    "districtNameEn": "Nong Bua Rawe",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360803",
    "subDistrictNameTh": "ห้วยแย้",
    "subDistrictNameEn": "Huai Yae",
    "postcode": "36250",
    "districtCode": "3608",
    "districtNameTh": "หนองบัวระเหว",
    "districtNameEn": "Nong Bua Rawe",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360804",
    "subDistrictNameTh": "โคกสะอาด",
    "subDistrictNameEn": "Khok Sa-at",
    "postcode": "36250",
    "districtCode": "3608",
    "districtNameTh": "หนองบัวระเหว",
    "districtNameEn": "Nong Bua Rawe",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360805",
    "subDistrictNameTh": "โสกปลาดุก",
    "subDistrictNameEn": "Sok Pla Duk",
    "postcode": "36250",
    "districtCode": "3608",
    "districtNameTh": "หนองบัวระเหว",
    "districtNameEn": "Nong Bua Rawe",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360901",
    "subDistrictNameTh": "วะตะแบก",
    "subDistrictNameEn": "Wa Tabaek",
    "postcode": "36230",
    "districtCode": "3609",
    "districtNameTh": "เทพสถิต",
    "districtNameEn": "Thep Sathit",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360902",
    "subDistrictNameTh": "ห้วยยายจิ๋ว",
    "subDistrictNameEn": "Huai Yai Chio",
    "postcode": "36230",
    "districtCode": "3609",
    "districtNameTh": "เทพสถิต",
    "districtNameEn": "Thep Sathit",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360903",
    "subDistrictNameTh": "นายางกลัก",
    "subDistrictNameEn": "Na yang Klak",
    "postcode": "36230",
    "districtCode": "3609",
    "districtNameTh": "เทพสถิต",
    "districtNameEn": "Thep Sathit",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360904",
    "subDistrictNameTh": "บ้านไร่",
    "subDistrictNameEn": "Ban Rai",
    "postcode": "36230",
    "districtCode": "3609",
    "districtNameTh": "เทพสถิต",
    "districtNameEn": "Thep Sathit",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "360905",
    "subDistrictNameTh": "โป่งนก",
    "subDistrictNameEn": "Pong Nok",
    "postcode": "36230",
    "districtCode": "3609",
    "districtNameTh": "เทพสถิต",
    "districtNameEn": "Thep Sathit",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "361001",
    "subDistrictNameTh": "ผักปัง",
    "subDistrictNameEn": "Phak Pang",
    "postcode": "36110",
    "districtCode": "3610",
    "districtNameTh": "ภูเขียว",
    "districtNameEn": "Phu Khiao",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "361002",
    "subDistrictNameTh": "กวางโจน",
    "subDistrictNameEn": "Kwang Chon",
    "postcode": "36110",
    "districtCode": "3610",
    "districtNameTh": "ภูเขียว",
    "districtNameEn": "Phu Khiao",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "361003",
    "subDistrictNameTh": "หนองคอนไทย",
    "subDistrictNameEn": "Nong Khon Thai",
    "postcode": "36110",
    "districtCode": "3610",
    "districtNameTh": "ภูเขียว",
    "districtNameEn": "Phu Khiao",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "361004",
    "subDistrictNameTh": "บ้านแก้ง",
    "subDistrictNameEn": "Ban Kaeng",
    "postcode": "36110",
    "districtCode": "3610",
    "districtNameTh": "ภูเขียว",
    "districtNameEn": "Phu Khiao",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "361005",
    "subDistrictNameTh": "กุดยม",
    "subDistrictNameEn": "Kut Yom",
    "postcode": "36110",
    "districtCode": "3610",
    "districtNameTh": "ภูเขียว",
    "districtNameEn": "Phu Khiao",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "361006",
    "subDistrictNameTh": "บ้านเพชร",
    "subDistrictNameEn": "Ban Phet",
    "postcode": "36110",
    "districtCode": "3610",
    "districtNameTh": "ภูเขียว",
    "districtNameEn": "Phu Khiao",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "361007",
    "subDistrictNameTh": "โคกสะอาด",
    "subDistrictNameEn": "Khok Sa-at",
    "postcode": "36110",
    "districtCode": "3610",
    "districtNameTh": "ภูเขียว",
    "districtNameEn": "Phu Khiao",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "361008",
    "subDistrictNameTh": "หนองตูม",
    "subDistrictNameEn": "Nong Tum",
    "postcode": "36110",
    "districtCode": "3610",
    "districtNameTh": "ภูเขียว",
    "districtNameEn": "Phu Khiao",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "361009",
    "subDistrictNameTh": "โอโล",
    "subDistrictNameEn": "Olo",
    "postcode": "36110",
    "districtCode": "3610",
    "districtNameTh": "ภูเขียว",
    "districtNameEn": "Phu Khiao",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "361010",
    "subDistrictNameTh": "ธาตุทอง",
    "subDistrictNameEn": "That Thong",
    "postcode": "36110",
    "districtCode": "3610",
    "districtNameTh": "ภูเขียว",
    "districtNameEn": "Phu Khiao",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "361011",
    "subDistrictNameTh": "บ้านดอน",
    "subDistrictNameEn": "Ban Don",
    "postcode": "36110",
    "districtCode": "3610",
    "districtNameTh": "ภูเขียว",
    "districtNameEn": "Phu Khiao",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "361101",
    "subDistrictNameTh": "บ้านแท่น",
    "subDistrictNameEn": "Ban Thaen",
    "postcode": "36190",
    "districtCode": "3611",
    "districtNameTh": "บ้านแท่น",
    "districtNameEn": "Ban Thaen",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "361102",
    "subDistrictNameTh": "สามสวน",
    "subDistrictNameEn": "Sam Suan",
    "postcode": "36190",
    "districtCode": "3611",
    "districtNameTh": "บ้านแท่น",
    "districtNameEn": "Ban Thaen",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "361103",
    "subDistrictNameTh": "สระพัง",
    "subDistrictNameEn": "Sa Phang",
    "postcode": "36190",
    "districtCode": "3611",
    "districtNameTh": "บ้านแท่น",
    "districtNameEn": "Ban Thaen",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "361104",
    "subDistrictNameTh": "บ้านเต่า",
    "subDistrictNameEn": "Ban Tao",
    "postcode": "36190",
    "districtCode": "3611",
    "districtNameTh": "บ้านแท่น",
    "districtNameEn": "Ban Thaen",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "361105",
    "subDistrictNameTh": "หนองคู",
    "subDistrictNameEn": "Nong Khu",
    "postcode": "36190",
    "districtCode": "3611",
    "districtNameTh": "บ้านแท่น",
    "districtNameEn": "Ban Thaen",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "361201",
    "subDistrictNameTh": "ช่องสามหมอ",
    "subDistrictNameEn": "Chong Sam Mo",
    "postcode": "36150",
    "districtCode": "3612",
    "districtNameTh": "แก้งคร้อ",
    "districtNameEn": "Kaeng Khro",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "361202",
    "subDistrictNameTh": "หนองขาม",
    "subDistrictNameEn": "Nong Kham",
    "postcode": "36150",
    "districtCode": "3612",
    "districtNameTh": "แก้งคร้อ",
    "districtNameEn": "Kaeng Khro",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "361203",
    "subDistrictNameTh": "นาหนองทุ่ม",
    "subDistrictNameEn": "Na Nong Thum",
    "postcode": "36150",
    "districtCode": "3612",
    "districtNameTh": "แก้งคร้อ",
    "districtNameEn": "Kaeng Khro",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "361204",
    "subDistrictNameTh": "บ้านแก้ง",
    "subDistrictNameEn": "Ban Kaeng",
    "postcode": "36150",
    "districtCode": "3612",
    "districtNameTh": "แก้งคร้อ",
    "districtNameEn": "Kaeng Khro",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "361205",
    "subDistrictNameTh": "หนองสังข์",
    "subDistrictNameEn": "Nong Sang",
    "postcode": "36150",
    "districtCode": "3612",
    "districtNameTh": "แก้งคร้อ",
    "districtNameEn": "Kaeng Khro",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "361206",
    "subDistrictNameTh": "หลุบคา",
    "subDistrictNameEn": "Lup Kha",
    "postcode": "36150",
    "districtCode": "3612",
    "districtNameTh": "แก้งคร้อ",
    "districtNameEn": "Kaeng Khro",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "361207",
    "subDistrictNameTh": "โคกกุง",
    "subDistrictNameEn": "Khok Kung",
    "postcode": "36150",
    "districtCode": "3612",
    "districtNameTh": "แก้งคร้อ",
    "districtNameEn": "Kaeng Khro",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "361208",
    "subDistrictNameTh": "เก่าย่าดี",
    "subDistrictNameEn": "Kao Ya Di",
    "postcode": "36150",
    "districtCode": "3612",
    "districtNameTh": "แก้งคร้อ",
    "districtNameEn": "Kaeng Khro",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "361209",
    "subDistrictNameTh": "ท่ามะไฟหวาน",
    "subDistrictNameEn": "Tha Mafai Wan",
    "postcode": "36150",
    "districtCode": "3612",
    "districtNameTh": "แก้งคร้อ",
    "districtNameEn": "Kaeng Khro",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "361210",
    "subDistrictNameTh": "หนองไผ่",
    "subDistrictNameEn": "Nong Phai",
    "postcode": "36150",
    "districtCode": "3612",
    "districtNameTh": "แก้งคร้อ",
    "districtNameEn": "Kaeng Khro",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "361301",
    "subDistrictNameTh": "คอนสาร",
    "subDistrictNameEn": "Khon San",
    "postcode": "36180",
    "districtCode": "3613",
    "districtNameTh": "คอนสาร",
    "districtNameEn": "Khon San",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "361302",
    "subDistrictNameTh": "ทุ่งพระ",
    "subDistrictNameEn": "Thung Phra",
    "postcode": "36180",
    "districtCode": "3613",
    "districtNameTh": "คอนสาร",
    "districtNameEn": "Khon San",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "361303",
    "subDistrictNameTh": "โนนคูณ",
    "subDistrictNameEn": "Non Khun",
    "postcode": "36180",
    "districtCode": "3613",
    "districtNameTh": "คอนสาร",
    "districtNameEn": "Khon San",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "361304",
    "subDistrictNameTh": "ห้วยยาง",
    "subDistrictNameEn": "Huai Yang",
    "postcode": "36180",
    "districtCode": "3613",
    "districtNameTh": "คอนสาร",
    "districtNameEn": "Khon San",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "361305",
    "subDistrictNameTh": "ทุ่งลุยลาย",
    "subDistrictNameEn": "Thung Luilai",
    "postcode": "36180",
    "districtCode": "3613",
    "districtNameTh": "คอนสาร",
    "districtNameEn": "Khon San",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "361306",
    "subDistrictNameTh": "ดงบัง",
    "subDistrictNameEn": "Dong Bang",
    "postcode": "36180",
    "districtCode": "3613",
    "districtNameTh": "คอนสาร",
    "districtNameEn": "Khon San",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "361307",
    "subDistrictNameTh": "ทุ่งนาเลา",
    "subDistrictNameEn": "Thung Na Lao",
    "postcode": "36180",
    "districtCode": "3613",
    "districtNameTh": "คอนสาร",
    "districtNameEn": "Khon San",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "361308",
    "subDistrictNameTh": "ดงกลาง",
    "subDistrictNameEn": "Dong Klang",
    "postcode": "36180",
    "districtCode": "3613",
    "districtNameTh": "คอนสาร",
    "districtNameEn": "Khon San",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "361401",
    "subDistrictNameTh": "บ้านเจียง",
    "subDistrictNameEn": "Ban Chiang",
    "postcode": "36260",
    "districtCode": "3614",
    "districtNameTh": "ภักดีชุมพล",
    "districtNameEn": "Phakdi Chumphon",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "361402",
    "subDistrictNameTh": "เจาทอง",
    "subDistrictNameEn": "Chao Thong",
    "postcode": "36260",
    "districtCode": "3614",
    "districtNameTh": "ภักดีชุมพล",
    "districtNameEn": "Phakdi Chumphon",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "361403",
    "subDistrictNameTh": "วังทอง",
    "subDistrictNameEn": "Wang Thong",
    "postcode": "36260",
    "districtCode": "3614",
    "districtNameTh": "ภักดีชุมพล",
    "districtNameEn": "Phakdi Chumphon",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "361404",
    "subDistrictNameTh": "แหลมทอง",
    "subDistrictNameEn": "Laem Thong",
    "postcode": "36260",
    "districtCode": "3614",
    "districtNameTh": "ภักดีชุมพล",
    "districtNameEn": "Phakdi Chumphon",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "361501",
    "subDistrictNameTh": "หนองฉิม",
    "subDistrictNameEn": "Nong Chim",
    "postcode": "36130",
    "districtCode": "3615",
    "districtNameTh": "เนินสง่า",
    "districtNameEn": "Noen Sa-nga",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "361502",
    "subDistrictNameTh": "ตาเนิน",
    "subDistrictNameEn": "Ta Noen",
    "postcode": "36130",
    "districtCode": "3615",
    "districtNameTh": "เนินสง่า",
    "districtNameEn": "Noen Sa-nga",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "361503",
    "subDistrictNameTh": "กะฮาด",
    "subDistrictNameEn": "Kahat",
    "postcode": "36130",
    "districtCode": "3615",
    "districtNameTh": "เนินสง่า",
    "districtNameEn": "Noen Sa-nga",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "361504",
    "subDistrictNameTh": "รังงาม",
    "subDistrictNameEn": "Rang Ngam",
    "postcode": "36130",
    "districtCode": "3615",
    "districtNameTh": "เนินสง่า",
    "districtNameEn": "Noen Sa-nga",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "361601",
    "subDistrictNameTh": "ซับใหญ่",
    "subDistrictNameEn": "Sap Yai",
    "postcode": "36130",
    "districtCode": "3616",
    "districtNameTh": "ซับใหญ่",
    "districtNameEn": "Sap Yai",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "361602",
    "subDistrictNameTh": "ท่ากูบ",
    "subDistrictNameEn": "Tha Kup",
    "postcode": "36130",
    "districtCode": "3616",
    "districtNameTh": "ซับใหญ่",
    "districtNameEn": "Sap Yai",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "361603",
    "subDistrictNameTh": "ตะโกทอง",
    "subDistrictNameEn": "Tako Thong",
    "postcode": "36130",
    "districtCode": "3616",
    "districtNameTh": "ซับใหญ่",
    "districtNameEn": "Sap Yai",
    "provinceCode": "36",
    "provinceNameTh": "ชัยภูมิ",
    "provinceNameEn": "Chaiyaphum"
},
{
    "subDistrictCode": "370101",
    "subDistrictNameTh": "บุ่ง",
    "subDistrictNameEn": "Bung",
    "postcode": "37000",
    "districtCode": "3701",
    "districtNameTh": "เมืองอำนาจเจริญ",
    "districtNameEn": "Mueang Amnat Charoen",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370102",
    "subDistrictNameTh": "ไก่คำ",
    "subDistrictNameEn": "Kai Kham",
    "postcode": "37000",
    "districtCode": "3701",
    "districtNameTh": "เมืองอำนาจเจริญ",
    "districtNameEn": "Mueang Amnat Charoen",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370103",
    "subDistrictNameTh": "นาจิก",
    "subDistrictNameEn": "Na Chik",
    "postcode": "37000",
    "districtCode": "3701",
    "districtNameTh": "เมืองอำนาจเจริญ",
    "districtNameEn": "Mueang Amnat Charoen",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370104",
    "subDistrictNameTh": "ปลาค้าว",
    "subDistrictNameEn": "Pla Khao",
    "postcode": "37000",
    "districtCode": "3701",
    "districtNameTh": "เมืองอำนาจเจริญ",
    "districtNameEn": "Mueang Amnat Charoen",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370105",
    "subDistrictNameTh": "เหล่าพรวน",
    "subDistrictNameEn": "Lao Phruan",
    "postcode": "37000",
    "districtCode": "3701",
    "districtNameTh": "เมืองอำนาจเจริญ",
    "districtNameEn": "Mueang Amnat Charoen",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370106",
    "subDistrictNameTh": "สร้างนกทา",
    "subDistrictNameEn": "Sang Nok Tha",
    "postcode": "37000",
    "districtCode": "3701",
    "districtNameTh": "เมืองอำนาจเจริญ",
    "districtNameEn": "Mueang Amnat Charoen",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370107",
    "subDistrictNameTh": "คึมใหญ่",
    "subDistrictNameEn": "Khuem Yai",
    "postcode": "37000",
    "districtCode": "3701",
    "districtNameTh": "เมืองอำนาจเจริญ",
    "districtNameEn": "Mueang Amnat Charoen",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370108",
    "subDistrictNameTh": "นาผือ",
    "subDistrictNameEn": "Na Phue",
    "postcode": "37000",
    "districtCode": "3701",
    "districtNameTh": "เมืองอำนาจเจริญ",
    "districtNameEn": "Mueang Amnat Charoen",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370109",
    "subDistrictNameTh": "น้ำปลีก",
    "subDistrictNameEn": "Nam Plik",
    "postcode": "37000",
    "districtCode": "3701",
    "districtNameTh": "เมืองอำนาจเจริญ",
    "districtNameEn": "Mueang Amnat Charoen",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370110",
    "subDistrictNameTh": "นาวัง",
    "subDistrictNameEn": "Na Wang",
    "postcode": "37000",
    "districtCode": "3701",
    "districtNameTh": "เมืองอำนาจเจริญ",
    "districtNameEn": "Mueang Amnat Charoen",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370111",
    "subDistrictNameTh": "นาหมอม้า",
    "subDistrictNameEn": "Na Mo Ma",
    "postcode": "37000",
    "districtCode": "3701",
    "districtNameTh": "เมืองอำนาจเจริญ",
    "districtNameEn": "Mueang Amnat Charoen",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370112",
    "subDistrictNameTh": "โนนโพธิ์",
    "subDistrictNameEn": "Non Pho",
    "postcode": "37000",
    "districtCode": "3701",
    "districtNameTh": "เมืองอำนาจเจริญ",
    "districtNameEn": "Mueang Amnat Charoen",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370113",
    "subDistrictNameTh": "โนนหนามแท่ง",
    "subDistrictNameEn": "Non Nam Thaeng",
    "postcode": "37000",
    "districtCode": "3701",
    "districtNameTh": "เมืองอำนาจเจริญ",
    "districtNameEn": "Mueang Amnat Charoen",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370114",
    "subDistrictNameTh": "ห้วยไร่",
    "subDistrictNameEn": "Huai Rai",
    "postcode": "37000",
    "districtCode": "3701",
    "districtNameTh": "เมืองอำนาจเจริญ",
    "districtNameEn": "Mueang Amnat Charoen",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370115",
    "subDistrictNameTh": "หนองมะแซว",
    "subDistrictNameEn": "Nong Masaeo",
    "postcode": "37000",
    "districtCode": "3701",
    "districtNameTh": "เมืองอำนาจเจริญ",
    "districtNameEn": "Mueang Amnat Charoen",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370116",
    "subDistrictNameTh": "กุดปลาดุก",
    "subDistrictNameEn": "Kut Pla Duk",
    "postcode": "37000",
    "districtCode": "3701",
    "districtNameTh": "เมืองอำนาจเจริญ",
    "districtNameEn": "Mueang Amnat Charoen",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370117",
    "subDistrictNameTh": "ดอนเมย",
    "subDistrictNameEn": "Don Moei",
    "postcode": "37000",
    "districtCode": "3701",
    "districtNameTh": "เมืองอำนาจเจริญ",
    "districtNameEn": "Mueang Amnat Charoen",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370118",
    "subDistrictNameTh": "นายม",
    "subDistrictNameEn": "Na Yom",
    "postcode": "37000",
    "districtCode": "3701",
    "districtNameTh": "เมืองอำนาจเจริญ",
    "districtNameEn": "Mueang Amnat Charoen",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370119",
    "subDistrictNameTh": "นาแต้",
    "subDistrictNameEn": "Na Tae",
    "postcode": "37000",
    "districtCode": "3701",
    "districtNameTh": "เมืองอำนาจเจริญ",
    "districtNameEn": "Mueang Amnat Charoen",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370201",
    "subDistrictNameTh": "ชานุมาน",
    "subDistrictNameEn": "Chanuman",
    "postcode": "37210",
    "districtCode": "3702",
    "districtNameTh": "ชานุมาน",
    "districtNameEn": "Chanuman",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370202",
    "subDistrictNameTh": "โคกสาร",
    "subDistrictNameEn": "Khok San",
    "postcode": "37210",
    "districtCode": "3702",
    "districtNameTh": "ชานุมาน",
    "districtNameEn": "Chanuman",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370203",
    "subDistrictNameTh": "คำเขื่อนแก้ว",
    "subDistrictNameEn": "Kham Khuean Kaeo",
    "postcode": "37210",
    "districtCode": "3702",
    "districtNameTh": "ชานุมาน",
    "districtNameEn": "Chanuman",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370204",
    "subDistrictNameTh": "โคกก่ง",
    "subDistrictNameEn": "Khok Kong",
    "postcode": "37210",
    "districtCode": "3702",
    "districtNameTh": "ชานุมาน",
    "districtNameEn": "Chanuman",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370205",
    "subDistrictNameTh": "ป่าก่อ",
    "subDistrictNameEn": "Pa Ko",
    "postcode": "37210",
    "districtCode": "3702",
    "districtNameTh": "ชานุมาน",
    "districtNameEn": "Chanuman",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370301",
    "subDistrictNameTh": "หนองข่า",
    "subDistrictNameEn": "Nong Kha",
    "postcode": "37110",
    "districtCode": "3703",
    "districtNameTh": "ปทุมราชวงศา",
    "districtNameEn": "Pathum Ratchawongsa",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370302",
    "subDistrictNameTh": "คำโพน",
    "subDistrictNameEn": "Kham Phon",
    "postcode": "37110",
    "districtCode": "3703",
    "districtNameTh": "ปทุมราชวงศา",
    "districtNameEn": "Pathum Ratchawongsa",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370303",
    "subDistrictNameTh": "นาหว้า",
    "subDistrictNameEn": "Na Wa",
    "postcode": "37110",
    "districtCode": "3703",
    "districtNameTh": "ปทุมราชวงศา",
    "districtNameEn": "Pathum Ratchawongsa",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370304",
    "subDistrictNameTh": "ลือ",
    "subDistrictNameEn": "Lue",
    "postcode": "37110",
    "districtCode": "3703",
    "districtNameTh": "ปทุมราชวงศา",
    "districtNameEn": "Pathum Ratchawongsa",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370305",
    "subDistrictNameTh": "ห้วย",
    "subDistrictNameEn": "Huai",
    "postcode": "37110",
    "districtCode": "3703",
    "districtNameTh": "ปทุมราชวงศา",
    "districtNameEn": "Pathum Ratchawongsa",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370306",
    "subDistrictNameTh": "โนนงาม",
    "subDistrictNameEn": "Non Ngam",
    "postcode": "37110",
    "districtCode": "3703",
    "districtNameTh": "ปทุมราชวงศา",
    "districtNameEn": "Pathum Ratchawongsa",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370307",
    "subDistrictNameTh": "นาป่าแซง",
    "subDistrictNameEn": "Na Pa Saeng",
    "postcode": "37110",
    "districtCode": "3703",
    "districtNameTh": "ปทุมราชวงศา",
    "districtNameEn": "Pathum Ratchawongsa",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370401",
    "subDistrictNameTh": "พนา",
    "subDistrictNameEn": "Phana",
    "postcode": "37180",
    "districtCode": "3704",
    "districtNameTh": "พนา",
    "districtNameEn": "Phana",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370402",
    "subDistrictNameTh": "จานลาน",
    "subDistrictNameEn": "Chan Lan",
    "postcode": "37180",
    "districtCode": "3704",
    "districtNameTh": "พนา",
    "districtNameEn": "Phana",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370403",
    "subDistrictNameTh": "ไม้กลอน",
    "subDistrictNameEn": "Mai Klon",
    "postcode": "37180",
    "districtCode": "3704",
    "districtNameTh": "พนา",
    "districtNameEn": "Phana",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370404",
    "subDistrictNameTh": "พระเหลา",
    "subDistrictNameEn": "Phra Lao",
    "postcode": "37180",
    "districtCode": "3704",
    "districtNameTh": "พนา",
    "districtNameEn": "Phana",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370501",
    "subDistrictNameTh": "เสนางคนิคม",
    "subDistrictNameEn": "Senangkhanikhom",
    "postcode": "37290",
    "districtCode": "3705",
    "districtNameTh": "เสนางคนิคม",
    "districtNameEn": "Senangkhanikhom",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370502",
    "subDistrictNameTh": "โพนทอง",
    "subDistrictNameEn": "Phon Thong",
    "postcode": "37290",
    "districtCode": "3705",
    "districtNameTh": "เสนางคนิคม",
    "districtNameEn": "Senangkhanikhom",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370503",
    "subDistrictNameTh": "ไร่สีสุก",
    "subDistrictNameEn": "Rai Si Suk",
    "postcode": "37290",
    "districtCode": "3705",
    "districtNameTh": "เสนางคนิคม",
    "districtNameEn": "Senangkhanikhom",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370504",
    "subDistrictNameTh": "นาเวียง",
    "subDistrictNameEn": "Na Wiang",
    "postcode": "37290",
    "districtCode": "3705",
    "districtNameTh": "เสนางคนิคม",
    "districtNameEn": "Senangkhanikhom",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370505",
    "subDistrictNameTh": "หนองไฮ",
    "subDistrictNameEn": "Nong Hai",
    "postcode": "37290",
    "districtCode": "3705",
    "districtNameTh": "เสนางคนิคม",
    "districtNameEn": "Senangkhanikhom",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370506",
    "subDistrictNameTh": "หนองสามสี",
    "subDistrictNameEn": "Nong Sam Si",
    "postcode": "37290",
    "districtCode": "3705",
    "districtNameTh": "เสนางคนิคม",
    "districtNameEn": "Senangkhanikhom",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370601",
    "subDistrictNameTh": "หัวตะพาน",
    "subDistrictNameEn": "Hua Taphan",
    "postcode": "37240",
    "districtCode": "3706",
    "districtNameTh": "หัวตะพาน",
    "districtNameEn": "Hua Taphan",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370602",
    "subDistrictNameTh": "คำพระ",
    "subDistrictNameEn": "Kham Phra",
    "postcode": "37240",
    "districtCode": "3706",
    "districtNameTh": "หัวตะพาน",
    "districtNameEn": "Hua Taphan",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370603",
    "subDistrictNameTh": "เค็งใหญ่",
    "subDistrictNameEn": "Kheng Yai",
    "postcode": "37240",
    "districtCode": "3706",
    "districtNameTh": "หัวตะพาน",
    "districtNameEn": "Hua Taphan",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370604",
    "subDistrictNameTh": "หนองแก้ว",
    "subDistrictNameEn": "Nong Kaeo",
    "postcode": "37240",
    "districtCode": "3706",
    "districtNameTh": "หัวตะพาน",
    "districtNameEn": "Hua Taphan",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370605",
    "subDistrictNameTh": "โพนเมืองน้อย",
    "subDistrictNameEn": "Phon Mueang Noi",
    "postcode": "37240",
    "districtCode": "3706",
    "districtNameTh": "หัวตะพาน",
    "districtNameEn": "Hua Taphan",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370606",
    "subDistrictNameTh": "สร้างถ่อน้อย",
    "subDistrictNameEn": "Sang Tho Noi",
    "postcode": "37240",
    "districtCode": "3706",
    "districtNameTh": "หัวตะพาน",
    "districtNameEn": "Hua Taphan",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370607",
    "subDistrictNameTh": "จิกดู่",
    "subDistrictNameEn": "Chik Du",
    "postcode": "37240",
    "districtCode": "3706",
    "districtNameTh": "หัวตะพาน",
    "districtNameEn": "Hua Taphan",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370608",
    "subDistrictNameTh": "รัตนวารี",
    "subDistrictNameEn": "Rattanawari",
    "postcode": "37240",
    "districtCode": "3706",
    "districtNameTh": "หัวตะพาน",
    "districtNameEn": "Hua Taphan",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370701",
    "subDistrictNameTh": "อำนาจ",
    "subDistrictNameEn": "amnat",
    "postcode": "37120",
    "districtCode": "3707",
    "districtNameTh": "ลืออำนาจ",
    "districtNameEn": "Lue Amnat",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370702",
    "subDistrictNameTh": "ดงมะยาง",
    "subDistrictNameEn": "Dong Mayang",
    "postcode": "37120",
    "districtCode": "3707",
    "districtNameTh": "ลืออำนาจ",
    "districtNameEn": "Lue Amnat",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370703",
    "subDistrictNameTh": "เปือย",
    "subDistrictNameEn": "Pueai",
    "postcode": "37120",
    "districtCode": "3707",
    "districtNameTh": "ลืออำนาจ",
    "districtNameEn": "Lue Amnat",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370704",
    "subDistrictNameTh": "ดงบัง",
    "subDistrictNameEn": "Dong Bang",
    "postcode": "37120",
    "districtCode": "3707",
    "districtNameTh": "ลืออำนาจ",
    "districtNameEn": "Lue Amnat",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370705",
    "subDistrictNameTh": "ไร่ขี",
    "subDistrictNameEn": "Rai Khi",
    "postcode": "37120",
    "districtCode": "3707",
    "districtNameTh": "ลืออำนาจ",
    "districtNameEn": "Lue Amnat",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370706",
    "subDistrictNameTh": "แมด",
    "subDistrictNameEn": "Maet",
    "postcode": "37120",
    "districtCode": "3707",
    "districtNameTh": "ลืออำนาจ",
    "districtNameEn": "Lue Amnat",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "370707",
    "subDistrictNameTh": "โคกกลาง",
    "subDistrictNameEn": "Khok Klang",
    "postcode": "37120",
    "districtCode": "3707",
    "districtNameTh": "ลืออำนาจ",
    "districtNameEn": "Lue Amnat",
    "provinceCode": "37",
    "provinceNameTh": "อำนาจเจริญ",
    "provinceNameEn": "Amnat Charoen"
},
{
    "subDistrictCode": "380101",
    "subDistrictNameTh": "บึงกาฬ",
    "subDistrictNameEn": "Bueng Kan",
    "postcode": "38000",
    "districtCode": "3801",
    "districtNameTh": "เมืองบึงกาฬ",
    "districtNameEn": "Mueang Bueng Kan",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380102",
    "subDistrictNameTh": "โนนสมบูรณ์",
    "subDistrictNameEn": "Non Sombun",
    "postcode": "38000",
    "districtCode": "3801",
    "districtNameTh": "เมืองบึงกาฬ",
    "districtNameEn": "Mueang Bueng Kan",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380103",
    "subDistrictNameTh": "โนนสว่าง",
    "subDistrictNameEn": "Non Sawang",
    "postcode": "38000",
    "districtCode": "3801",
    "districtNameTh": "เมืองบึงกาฬ",
    "districtNameEn": "Mueang Bueng Kan",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380104",
    "subDistrictNameTh": "หอคำ",
    "subDistrictNameEn": "Ho Kham",
    "postcode": "38000",
    "districtCode": "3801",
    "districtNameTh": "เมืองบึงกาฬ",
    "districtNameEn": "Mueang Bueng Kan",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380105",
    "subDistrictNameTh": "หนองเลิง",
    "subDistrictNameEn": "Nong Loeng",
    "postcode": "38000",
    "districtCode": "3801",
    "districtNameTh": "เมืองบึงกาฬ",
    "districtNameEn": "Mueang Bueng Kan",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380106",
    "subDistrictNameTh": "โคกก่อง",
    "subDistrictNameEn": "Khok Kong",
    "postcode": "38000",
    "districtCode": "3801",
    "districtNameTh": "เมืองบึงกาฬ",
    "districtNameEn": "Mueang Bueng Kan",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380107",
    "subDistrictNameTh": "นาสวรรค์",
    "subDistrictNameEn": "Na Sawan",
    "postcode": "38000",
    "districtCode": "3801",
    "districtNameTh": "เมืองบึงกาฬ",
    "districtNameEn": "Mueang Bueng Kan",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380108",
    "subDistrictNameTh": "ไคสี",
    "subDistrictNameEn": "Khai Si",
    "postcode": "38000",
    "districtCode": "3801",
    "districtNameTh": "เมืองบึงกาฬ",
    "districtNameEn": "Mueang Bueng Kan",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380109",
    "subDistrictNameTh": "ชัยพร",
    "subDistrictNameEn": "Chaiyaphon",
    "postcode": "38000",
    "districtCode": "3801",
    "districtNameTh": "เมืองบึงกาฬ",
    "districtNameEn": "Mueang Bueng Kan",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380110",
    "subDistrictNameTh": "วิศิษฐ์",
    "subDistrictNameEn": "Wisit",
    "postcode": "38000",
    "districtCode": "3801",
    "districtNameTh": "เมืองบึงกาฬ",
    "districtNameEn": "Mueang Bueng Kan",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380111",
    "subDistrictNameTh": "คำนาดี",
    "subDistrictNameEn": "Kham Na Di",
    "postcode": "38000",
    "districtCode": "3801",
    "districtNameTh": "เมืองบึงกาฬ",
    "districtNameEn": "Mueang Bueng Kan",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380112",
    "subDistrictNameTh": "โป่งเปือย",
    "subDistrictNameEn": "Pong Pueai",
    "postcode": "38000",
    "districtCode": "3801",
    "districtNameTh": "เมืองบึงกาฬ",
    "districtNameEn": "Mueang Bueng Kan",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380201",
    "subDistrictNameTh": "ศรีชมภู",
    "subDistrictNameEn": "Si Chomphu",
    "postcode": "38180",
    "districtCode": "3802",
    "districtNameTh": "พรเจริญ",
    "districtNameEn": "Phon Charoen",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380202",
    "subDistrictNameTh": "ดอนหญ้านาง",
    "subDistrictNameEn": "Don Ya Nang",
    "postcode": "38180",
    "districtCode": "3802",
    "districtNameTh": "พรเจริญ",
    "districtNameEn": "Phon Charoen",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380203",
    "subDistrictNameTh": "พรเจริญ",
    "subDistrictNameEn": "Phon Charoen",
    "postcode": "38180",
    "districtCode": "3802",
    "districtNameTh": "พรเจริญ",
    "districtNameEn": "Phon Charoen",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380204",
    "subDistrictNameTh": "หนองหัวช้าง",
    "subDistrictNameEn": "Nong Hua Chang",
    "postcode": "38180",
    "districtCode": "3802",
    "districtNameTh": "พรเจริญ",
    "districtNameEn": "Phon Charoen",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380205",
    "subDistrictNameTh": "วังชมภู",
    "subDistrictNameEn": "Wang Chomphu",
    "postcode": "38180",
    "districtCode": "3802",
    "districtNameTh": "พรเจริญ",
    "districtNameEn": "Phon Charoen",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380206",
    "subDistrictNameTh": "ป่าแฝก",
    "subDistrictNameEn": "Pa Faek",
    "postcode": "38180",
    "districtCode": "3802",
    "districtNameTh": "พรเจริญ",
    "districtNameEn": "Phon Charoen",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380207",
    "subDistrictNameTh": "ศรีสำราญ",
    "subDistrictNameEn": "Si Samran",
    "postcode": "38180",
    "districtCode": "3802",
    "districtNameTh": "พรเจริญ",
    "districtNameEn": "Phon Charoen",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380301",
    "subDistrictNameTh": "โซ่",
    "subDistrictNameEn": "So",
    "postcode": "38170",
    "districtCode": "3803",
    "districtNameTh": "โซ่พิสัย",
    "districtNameEn": "So Phisai",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380302",
    "subDistrictNameTh": "หนองพันทา",
    "subDistrictNameEn": "Nong Phan Tha",
    "postcode": "38170",
    "districtCode": "3803",
    "districtNameTh": "โซ่พิสัย",
    "districtNameEn": "So Phisai",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380303",
    "subDistrictNameTh": "ศรีชมภู",
    "subDistrictNameEn": "Si Chomphu",
    "postcode": "38170",
    "districtCode": "3803",
    "districtNameTh": "โซ่พิสัย",
    "districtNameEn": "So Phisai",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380304",
    "subDistrictNameTh": "คำแก้ว",
    "subDistrictNameEn": "Kham Kaeo",
    "postcode": "38170",
    "districtCode": "3803",
    "districtNameTh": "โซ่พิสัย",
    "districtNameEn": "So Phisai",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380305",
    "subDistrictNameTh": "บัวตูม",
    "subDistrictNameEn": "Bua Tum",
    "postcode": "38170",
    "districtCode": "3803",
    "districtNameTh": "โซ่พิสัย",
    "districtNameEn": "So Phisai",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380306",
    "subDistrictNameTh": "ถ้ำเจริญ",
    "subDistrictNameEn": "Tham Charoen",
    "postcode": "38170",
    "districtCode": "3803",
    "districtNameTh": "โซ่พิสัย",
    "districtNameEn": "So Phisai",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380307",
    "subDistrictNameTh": "เหล่าทอง",
    "subDistrictNameEn": "Lao Thong",
    "postcode": "38170",
    "districtCode": "3803",
    "districtNameTh": "โซ่พิสัย",
    "districtNameEn": "So Phisai",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380401",
    "subDistrictNameTh": "เซกา",
    "subDistrictNameEn": "Seka",
    "postcode": "38150",
    "districtCode": "3804",
    "districtNameTh": "เซกา",
    "districtNameEn": "Seka",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380402",
    "subDistrictNameTh": "ซาง",
    "subDistrictNameEn": "Sang",
    "postcode": "38150",
    "districtCode": "3804",
    "districtNameTh": "เซกา",
    "districtNameEn": "Seka",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380403",
    "subDistrictNameTh": "ท่ากกแดง",
    "subDistrictNameEn": "Tha Kok Daeng",
    "postcode": "38150",
    "districtCode": "3804",
    "districtNameTh": "เซกา",
    "districtNameEn": "Seka",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380404",
    "subDistrictNameTh": "บ้านต้อง",
    "subDistrictNameEn": "Ban Tong",
    "postcode": "38150",
    "districtCode": "3804",
    "districtNameTh": "เซกา",
    "districtNameEn": "Seka",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380405",
    "subDistrictNameTh": "ป่งไฮ",
    "subDistrictNameEn": "Pong Hai",
    "postcode": "38150",
    "districtCode": "3804",
    "districtNameTh": "เซกา",
    "districtNameEn": "Seka",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380406",
    "subDistrictNameTh": "น้ำจั้น",
    "subDistrictNameEn": "Nam Chan",
    "postcode": "38150",
    "districtCode": "3804",
    "districtNameTh": "เซกา",
    "districtNameEn": "Seka",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380407",
    "subDistrictNameTh": "ท่าสะอาด",
    "subDistrictNameEn": "Tha Sa-at",
    "postcode": "38150",
    "districtCode": "3804",
    "districtNameTh": "เซกา",
    "districtNameEn": "Seka",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380408",
    "subDistrictNameTh": "หนองทุ่ม",
    "subDistrictNameEn": "Nong Thum",
    "postcode": "38150",
    "districtCode": "3804",
    "districtNameTh": "เซกา",
    "districtNameEn": "Seka",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380409",
    "subDistrictNameTh": "โสกก่าม",
    "subDistrictNameEn": "Sok Kam",
    "postcode": "38150",
    "districtCode": "3804",
    "districtNameTh": "เซกา",
    "districtNameEn": "Seka",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380501",
    "subDistrictNameTh": "ปากคาด",
    "subDistrictNameEn": "Pak Khat",
    "postcode": "38190",
    "districtCode": "3805",
    "districtNameTh": "ปากคาด",
    "districtNameEn": "Pak Khat",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380502",
    "subDistrictNameTh": "หนองยอง",
    "subDistrictNameEn": "Nong Yong",
    "postcode": "38190",
    "districtCode": "3805",
    "districtNameTh": "ปากคาด",
    "districtNameEn": "Pak Khat",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380503",
    "subDistrictNameTh": "นากั้ง",
    "subDistrictNameEn": "Na Kang",
    "postcode": "38190",
    "districtCode": "3805",
    "districtNameTh": "ปากคาด",
    "districtNameEn": "Pak Khat",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380504",
    "subDistrictNameTh": "โนนศิลา",
    "subDistrictNameEn": "Non Sila",
    "postcode": "38190",
    "districtCode": "3805",
    "districtNameTh": "ปากคาด",
    "districtNameEn": "Pak Khat",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380505",
    "subDistrictNameTh": "สมสนุก",
    "subDistrictNameEn": "Som Sanuk",
    "postcode": "38190",
    "districtCode": "3805",
    "districtNameTh": "ปากคาด",
    "districtNameEn": "Pak Khat",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380506",
    "subDistrictNameTh": "นาดง",
    "subDistrictNameEn": "Na Dong",
    "postcode": "38190",
    "districtCode": "3805",
    "districtNameTh": "ปากคาด",
    "districtNameEn": "Pak Khat",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380601",
    "subDistrictNameTh": "บึงโขงหลง",
    "subDistrictNameEn": "Bueng Khong Long",
    "postcode": "38220",
    "districtCode": "3806",
    "districtNameTh": "บึงโขงหลง",
    "districtNameEn": "Bueng Khong Long",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380602",
    "subDistrictNameTh": "โพธิ์หมากแข้ง",
    "subDistrictNameEn": "Pho Mak Khaeng",
    "postcode": "38220",
    "districtCode": "3806",
    "districtNameTh": "บึงโขงหลง",
    "districtNameEn": "Bueng Khong Long",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380603",
    "subDistrictNameTh": "ดงบัง",
    "subDistrictNameEn": "Dong Bang",
    "postcode": "38220",
    "districtCode": "3806",
    "districtNameTh": "บึงโขงหลง",
    "districtNameEn": "Bueng Khong Long",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380604",
    "subDistrictNameTh": "ท่าดอกคำ",
    "subDistrictNameEn": "Tha Dok Kham",
    "postcode": "38220",
    "districtCode": "3806",
    "districtNameTh": "บึงโขงหลง",
    "districtNameEn": "Bueng Khong Long",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380701",
    "subDistrictNameTh": "ศรีวิไล",
    "subDistrictNameEn": "Si Wilai",
    "postcode": "38210",
    "districtCode": "3807",
    "districtNameTh": "ศรีวิไล",
    "districtNameEn": "Si Wilai",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380702",
    "subDistrictNameTh": "ชุมภูพร",
    "subDistrictNameEn": "Chumphu Phon",
    "postcode": "38210",
    "districtCode": "3807",
    "districtNameTh": "ศรีวิไล",
    "districtNameEn": "Si Wilai",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380703",
    "subDistrictNameTh": "นาแสง",
    "subDistrictNameEn": "Na Saeng",
    "postcode": "38210",
    "districtCode": "3807",
    "districtNameTh": "ศรีวิไล",
    "districtNameEn": "Si Wilai",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380704",
    "subDistrictNameTh": "นาสะแบง",
    "subDistrictNameEn": "Na Sabaeng",
    "postcode": "38210",
    "districtCode": "3807",
    "districtNameTh": "ศรีวิไล",
    "districtNameEn": "Si Wilai",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380705",
    "subDistrictNameTh": "นาสิงห์",
    "subDistrictNameEn": "Na Sing",
    "postcode": "38210",
    "districtCode": "3807",
    "districtNameTh": "ศรีวิไล",
    "districtNameEn": "Si Wilai",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380801",
    "subDistrictNameTh": "บุ่งคล้า",
    "subDistrictNameEn": "Bung Khla",
    "postcode": "38000",
    "districtCode": "3808",
    "districtNameTh": "บุ่งคล้า",
    "districtNameEn": "Bung Khla",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380802",
    "subDistrictNameTh": "หนองเดิ่น",
    "subDistrictNameEn": "Nong Doen",
    "postcode": "38000",
    "districtCode": "3808",
    "districtNameTh": "บุ่งคล้า",
    "districtNameEn": "Bung Khla",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "380803",
    "subDistrictNameTh": "โคกกว้าง",
    "subDistrictNameEn": "Khok Kwang",
    "postcode": "38000",
    "districtCode": "3808",
    "districtNameTh": "บุ่งคล้า",
    "districtNameEn": "Bung Khla",
    "provinceCode": "38",
    "provinceNameTh": "บึงกาฬ",
    "provinceNameEn": "Bueng Kan"
},
{
    "subDistrictCode": "390101",
    "subDistrictNameTh": "หนองบัว",
    "subDistrictNameEn": "Nong Bua",
    "postcode": "39000",
    "districtCode": "3901",
    "districtNameTh": "เมืองหนองบัวลำภู",
    "districtNameEn": "Mueang Nong Bua Lam Phu",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390102",
    "subDistrictNameTh": "หนองภัยศูนย์",
    "subDistrictNameEn": "Nong Phai Sun",
    "postcode": "39000",
    "districtCode": "3901",
    "districtNameTh": "เมืองหนองบัวลำภู",
    "districtNameEn": "Mueang Nong Bua Lam Phu",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390103",
    "subDistrictNameTh": "โพธิ์ชัย",
    "subDistrictNameEn": "Pho Chai",
    "postcode": "39000",
    "districtCode": "3901",
    "districtNameTh": "เมืองหนองบัวลำภู",
    "districtNameEn": "Mueang Nong Bua Lam Phu",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390104",
    "subDistrictNameTh": "หนองสวรรค์",
    "subDistrictNameEn": "Nong Sawan",
    "postcode": "39000",
    "districtCode": "3901",
    "districtNameTh": "เมืองหนองบัวลำภู",
    "districtNameEn": "Mueang Nong Bua Lam Phu",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390105",
    "subDistrictNameTh": "หัวนา",
    "subDistrictNameEn": "Hua Na",
    "postcode": "39000",
    "districtCode": "3901",
    "districtNameTh": "เมืองหนองบัวลำภู",
    "districtNameEn": "Mueang Nong Bua Lam Phu",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390106",
    "subDistrictNameTh": "บ้านขาม",
    "subDistrictNameEn": "Ban Kham",
    "postcode": "39000",
    "districtCode": "3901",
    "districtNameTh": "เมืองหนองบัวลำภู",
    "districtNameEn": "Mueang Nong Bua Lam Phu",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390107",
    "subDistrictNameTh": "นามะเฟือง",
    "subDistrictNameEn": "Na Mafueang",
    "postcode": "39000",
    "districtCode": "3901",
    "districtNameTh": "เมืองหนองบัวลำภู",
    "districtNameEn": "Mueang Nong Bua Lam Phu",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390108",
    "subDistrictNameTh": "บ้านพร้าว",
    "subDistrictNameEn": "Ban Phrao",
    "postcode": "39000",
    "districtCode": "3901",
    "districtNameTh": "เมืองหนองบัวลำภู",
    "districtNameEn": "Mueang Nong Bua Lam Phu",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390109",
    "subDistrictNameTh": "โนนขมิ้น",
    "subDistrictNameEn": "Non Khamin",
    "postcode": "39000",
    "districtCode": "3901",
    "districtNameTh": "เมืองหนองบัวลำภู",
    "districtNameEn": "Mueang Nong Bua Lam Phu",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390110",
    "subDistrictNameTh": "ลำภู",
    "subDistrictNameEn": "Lumphu",
    "postcode": "39000",
    "districtCode": "3901",
    "districtNameTh": "เมืองหนองบัวลำภู",
    "districtNameEn": "Mueang Nong Bua Lam Phu",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390111",
    "subDistrictNameTh": "กุดจิก",
    "subDistrictNameEn": "Kut Chik",
    "postcode": "39000",
    "districtCode": "3901",
    "districtNameTh": "เมืองหนองบัวลำภู",
    "districtNameEn": "Mueang Nong Bua Lam Phu",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390112",
    "subDistrictNameTh": "โนนทัน",
    "subDistrictNameEn": "Non Than",
    "postcode": "39000",
    "districtCode": "3901",
    "districtNameTh": "เมืองหนองบัวลำภู",
    "districtNameEn": "Mueang Nong Bua Lam Phu",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390113",
    "subDistrictNameTh": "นาคำไฮ",
    "subDistrictNameEn": "Na Kham Hai",
    "postcode": "39000",
    "districtCode": "3901",
    "districtNameTh": "เมืองหนองบัวลำภู",
    "districtNameEn": "Mueang Nong Bua Lam Phu",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390114",
    "subDistrictNameTh": "ป่าไม้งาม",
    "subDistrictNameEn": "Pa Mai Ngam",
    "postcode": "39000",
    "districtCode": "3901",
    "districtNameTh": "เมืองหนองบัวลำภู",
    "districtNameEn": "Mueang Nong Bua Lam Phu",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390115",
    "subDistrictNameTh": "หนองหว้า",
    "subDistrictNameEn": "Nong Wa",
    "postcode": "39000",
    "districtCode": "3901",
    "districtNameTh": "เมืองหนองบัวลำภู",
    "districtNameEn": "Mueang Nong Bua Lam Phu",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390201",
    "subDistrictNameTh": "นากลาง",
    "subDistrictNameEn": "Na Klang",
    "postcode": "39170",
    "districtCode": "3902",
    "districtNameTh": "นากลาง",
    "districtNameEn": "Na Klang",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390202",
    "subDistrictNameTh": "ด่านช้าง",
    "subDistrictNameEn": "Dan Chang",
    "postcode": "39170",
    "districtCode": "3902",
    "districtNameTh": "นากลาง",
    "districtNameEn": "Na Klang",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390205",
    "subDistrictNameTh": "กุดดินจี่",
    "subDistrictNameEn": "Kut Din Chi",
    "postcode": "39350",
    "districtCode": "3902",
    "districtNameTh": "นากลาง",
    "districtNameEn": "Na Klang",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390206",
    "subDistrictNameTh": "ฝั่งแดง",
    "subDistrictNameEn": "Fang Daeng",
    "postcode": "39170",
    "districtCode": "3902",
    "districtNameTh": "นากลาง",
    "districtNameEn": "Na Klang",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390207",
    "subDistrictNameTh": "เก่ากลอย",
    "subDistrictNameEn": "Kao Kloi",
    "postcode": "39350",
    "districtCode": "3902",
    "districtNameTh": "นากลาง",
    "districtNameEn": "Na Klang",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390209",
    "subDistrictNameTh": "โนนเมือง",
    "subDistrictNameEn": "Non Mueang",
    "postcode": "39170",
    "districtCode": "3902",
    "districtNameTh": "นากลาง",
    "districtNameEn": "Na Klang",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390210",
    "subDistrictNameTh": "อุทัยสวรรค์",
    "subDistrictNameEn": "Uthai Sawan",
    "postcode": "39170",
    "districtCode": "3902",
    "districtNameTh": "นากลาง",
    "districtNameEn": "Na Klang",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390211",
    "subDistrictNameTh": "ดงสวรรค์",
    "subDistrictNameEn": "Dong Sawan",
    "postcode": "39350",
    "districtCode": "3902",
    "districtNameTh": "นากลาง",
    "districtNameEn": "Na Klang",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390213",
    "subDistrictNameTh": "กุดแห่",
    "subDistrictNameEn": "Kut Hae",
    "postcode": "39170",
    "districtCode": "3902",
    "districtNameTh": "นากลาง",
    "districtNameEn": "Na Klang",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390301",
    "subDistrictNameTh": "โนนสัง",
    "subDistrictNameEn": "Non Sang",
    "postcode": "39140",
    "districtCode": "3903",
    "districtNameTh": "โนนสัง",
    "districtNameEn": "Non Sang",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390302",
    "subDistrictNameTh": "บ้านถิ่น",
    "subDistrictNameEn": "Ban Thin",
    "postcode": "39140",
    "districtCode": "3903",
    "districtNameTh": "โนนสัง",
    "districtNameEn": "Non Sang",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390303",
    "subDistrictNameTh": "หนองเรือ",
    "subDistrictNameEn": "Nong Ruea",
    "postcode": "39140",
    "districtCode": "3903",
    "districtNameTh": "โนนสัง",
    "districtNameEn": "Non Sang",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390304",
    "subDistrictNameTh": "กุดดู่",
    "subDistrictNameEn": "Kut Du",
    "postcode": "39140",
    "districtCode": "3903",
    "districtNameTh": "โนนสัง",
    "districtNameEn": "Non Sang",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390305",
    "subDistrictNameTh": "บ้านค้อ",
    "subDistrictNameEn": "Ban Kho",
    "postcode": "39140",
    "districtCode": "3903",
    "districtNameTh": "โนนสัง",
    "districtNameEn": "Non Sang",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390306",
    "subDistrictNameTh": "โนนเมือง",
    "subDistrictNameEn": "Non Mueang",
    "postcode": "39140",
    "districtCode": "3903",
    "districtNameTh": "โนนสัง",
    "districtNameEn": "Non Sang",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390307",
    "subDistrictNameTh": "โคกใหญ่",
    "subDistrictNameEn": "Khok Yai",
    "postcode": "39140",
    "districtCode": "3903",
    "districtNameTh": "โนนสัง",
    "districtNameEn": "Non Sang",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390308",
    "subDistrictNameTh": "โคกม่วง",
    "subDistrictNameEn": "Khok Muang",
    "postcode": "39140",
    "districtCode": "3903",
    "districtNameTh": "โนนสัง",
    "districtNameEn": "Non Sang",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390309",
    "subDistrictNameTh": "นิคมพัฒนา",
    "subDistrictNameEn": "Nikhom Phatthana",
    "postcode": "39140",
    "districtCode": "3903",
    "districtNameTh": "โนนสัง",
    "districtNameEn": "Non Sang",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390310",
    "subDistrictNameTh": "ปางกู่",
    "subDistrictNameEn": "Pang Ku",
    "postcode": "39140",
    "districtCode": "3903",
    "districtNameTh": "โนนสัง",
    "districtNameEn": "Non Sang",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390401",
    "subDistrictNameTh": "เมืองใหม่",
    "subDistrictNameEn": "Mueang Mai",
    "postcode": "39180",
    "districtCode": "3904",
    "districtNameTh": "ศรีบุญเรือง",
    "districtNameEn": "Si Bun Rueang",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390402",
    "subDistrictNameTh": "ศรีบุญเรือง",
    "subDistrictNameEn": "Si Bun Rueang",
    "postcode": "39180",
    "districtCode": "3904",
    "districtNameTh": "ศรีบุญเรือง",
    "districtNameEn": "Si Bun Rueang",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390403",
    "subDistrictNameTh": "หนองบัวใต้",
    "subDistrictNameEn": "Nong Bua Tai",
    "postcode": "39180",
    "districtCode": "3904",
    "districtNameTh": "ศรีบุญเรือง",
    "districtNameEn": "Si Bun Rueang",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390404",
    "subDistrictNameTh": "กุดสะเทียน",
    "subDistrictNameEn": "Kut Sathian",
    "postcode": "39180",
    "districtCode": "3904",
    "districtNameTh": "ศรีบุญเรือง",
    "districtNameEn": "Si Bun Rueang",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390405",
    "subDistrictNameTh": "นากอก",
    "subDistrictNameEn": "Na Kok",
    "postcode": "39180",
    "districtCode": "3904",
    "districtNameTh": "ศรีบุญเรือง",
    "districtNameEn": "Si Bun Rueang",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390406",
    "subDistrictNameTh": "โนนสะอาด",
    "subDistrictNameEn": "Non Sa-at",
    "postcode": "39180",
    "districtCode": "3904",
    "districtNameTh": "ศรีบุญเรือง",
    "districtNameEn": "Si Bun Rueang",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390407",
    "subDistrictNameTh": "ยางหล่อ",
    "subDistrictNameEn": "Yang Lo",
    "postcode": "39180",
    "districtCode": "3904",
    "districtNameTh": "ศรีบุญเรือง",
    "districtNameEn": "Si Bun Rueang",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390408",
    "subDistrictNameTh": "โนนม่วง",
    "subDistrictNameEn": "Non Muang",
    "postcode": "39180",
    "districtCode": "3904",
    "districtNameTh": "ศรีบุญเรือง",
    "districtNameEn": "Si Bun Rueang",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390409",
    "subDistrictNameTh": "หนองกุงแก้ว",
    "subDistrictNameEn": "Nong Kung Kaeo",
    "postcode": "39180",
    "districtCode": "3904",
    "districtNameTh": "ศรีบุญเรือง",
    "districtNameEn": "Si Bun Rueang",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390410",
    "subDistrictNameTh": "หนองแก",
    "subDistrictNameEn": "Nong Kae",
    "postcode": "39180",
    "districtCode": "3904",
    "districtNameTh": "ศรีบุญเรือง",
    "districtNameEn": "Si Bun Rueang",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390411",
    "subDistrictNameTh": "ทรายทอง",
    "subDistrictNameEn": "Sai Thong",
    "postcode": "39180",
    "districtCode": "3904",
    "districtNameTh": "ศรีบุญเรือง",
    "districtNameEn": "Si Bun Rueang",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390412",
    "subDistrictNameTh": "หันนางาม",
    "subDistrictNameEn": "Han Na Ngam",
    "postcode": "39180",
    "districtCode": "3904",
    "districtNameTh": "ศรีบุญเรือง",
    "districtNameEn": "Si Bun Rueang",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390501",
    "subDistrictNameTh": "นาสี",
    "subDistrictNameEn": "Na Si",
    "postcode": "39270",
    "districtCode": "3905",
    "districtNameTh": "สุวรรณคูหา",
    "districtNameEn": "Suwannakhuha",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390502",
    "subDistrictNameTh": "บ้านโคก",
    "subDistrictNameEn": "Ban Khok",
    "postcode": "39270",
    "districtCode": "3905",
    "districtNameTh": "สุวรรณคูหา",
    "districtNameEn": "Suwannakhuha",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390503",
    "subDistrictNameTh": "นาดี",
    "subDistrictNameEn": "Na Di",
    "postcode": "39270",
    "districtCode": "3905",
    "districtNameTh": "สุวรรณคูหา",
    "districtNameEn": "Suwannakhuha",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390504",
    "subDistrictNameTh": "นาด่าน",
    "subDistrictNameEn": "Na Dan",
    "postcode": "39270",
    "districtCode": "3905",
    "districtNameTh": "สุวรรณคูหา",
    "districtNameEn": "Suwannakhuha",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390505",
    "subDistrictNameTh": "ดงมะไฟ",
    "subDistrictNameEn": "Dong Mafai",
    "postcode": "39270",
    "districtCode": "3905",
    "districtNameTh": "สุวรรณคูหา",
    "districtNameEn": "Suwannakhuha",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390506",
    "subDistrictNameTh": "สุวรรณคูหา",
    "subDistrictNameEn": "Suwannakhuha",
    "postcode": "39270",
    "districtCode": "3905",
    "districtNameTh": "สุวรรณคูหา",
    "districtNameEn": "Suwannakhuha",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390507",
    "subDistrictNameTh": "บุญทัน",
    "subDistrictNameEn": "Bun Than",
    "postcode": "39270",
    "districtCode": "3905",
    "districtNameTh": "สุวรรณคูหา",
    "districtNameEn": "Suwannakhuha",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390508",
    "subDistrictNameTh": "กุดผึ้ง",
    "subDistrictNameEn": "Kut Phueng",
    "postcode": "39270",
    "districtCode": "3905",
    "districtNameTh": "สุวรรณคูหา",
    "districtNameEn": "Suwannakhuha",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390601",
    "subDistrictNameTh": "นาเหล่า",
    "subDistrictNameEn": "Na Lao",
    "postcode": "39170",
    "districtCode": "3906",
    "districtNameTh": "นาวัง",
    "districtNameEn": "Na Wang",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390602",
    "subDistrictNameTh": "นาแก",
    "subDistrictNameEn": "Na Kae",
    "postcode": "39170",
    "districtCode": "3906",
    "districtNameTh": "นาวัง",
    "districtNameEn": "Na Wang",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390603",
    "subDistrictNameTh": "วังทอง",
    "subDistrictNameEn": "Wang Thong",
    "postcode": "39170",
    "districtCode": "3906",
    "districtNameTh": "นาวัง",
    "districtNameEn": "Na Wang",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390604",
    "subDistrictNameTh": "วังปลาป้อม",
    "subDistrictNameEn": "Wang Pla Pom",
    "postcode": "39170",
    "districtCode": "3906",
    "districtNameTh": "นาวัง",
    "districtNameEn": "Na Wang",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "390605",
    "subDistrictNameTh": "เทพคีรี",
    "subDistrictNameEn": "Thep Khiri",
    "postcode": "39170",
    "districtCode": "3906",
    "districtNameTh": "นาวัง",
    "districtNameEn": "Na Wang",
    "provinceCode": "39",
    "provinceNameTh": "หนองบัวลำภู",
    "provinceNameEn": "Nong Bua Lam Phu"
},
{
    "subDistrictCode": "400101",
    "subDistrictNameTh": "ในเมือง",
    "subDistrictNameEn": "Nai Mueang",
    "postcode": "40000",
    "districtCode": "4001",
    "districtNameTh": "เมืองขอนแก่น",
    "districtNameEn": "Mueang Khon Kaen",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400102",
    "subDistrictNameTh": "สำราญ",
    "subDistrictNameEn": "Samran",
    "postcode": "40000",
    "districtCode": "4001",
    "districtNameTh": "เมืองขอนแก่น",
    "districtNameEn": "Mueang Khon Kaen",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400103",
    "subDistrictNameTh": "โคกสี",
    "subDistrictNameEn": "Khok Si",
    "postcode": "40000",
    "districtCode": "4001",
    "districtNameTh": "เมืองขอนแก่น",
    "districtNameEn": "Mueang Khon Kaen",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400104",
    "subDistrictNameTh": "ท่าพระ",
    "subDistrictNameEn": "Tha Phra",
    "postcode": "40260",
    "districtCode": "4001",
    "districtNameTh": "เมืองขอนแก่น",
    "districtNameEn": "Mueang Khon Kaen",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400105",
    "subDistrictNameTh": "บ้านทุ่ม",
    "subDistrictNameEn": "Ban Thum",
    "postcode": "40000",
    "districtCode": "4001",
    "districtNameTh": "เมืองขอนแก่น",
    "districtNameEn": "Mueang Khon Kaen",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400106",
    "subDistrictNameTh": "เมืองเก่า",
    "subDistrictNameEn": "Mueang Kao",
    "postcode": "40000",
    "districtCode": "4001",
    "districtNameTh": "เมืองขอนแก่น",
    "districtNameEn": "Mueang Khon Kaen",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400107",
    "subDistrictNameTh": "พระลับ",
    "subDistrictNameEn": "Phra Lap",
    "postcode": "40000",
    "districtCode": "4001",
    "districtNameTh": "เมืองขอนแก่น",
    "districtNameEn": "Mueang Khon Kaen",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400108",
    "subDistrictNameTh": "สาวะถี",
    "subDistrictNameEn": "Sawathi",
    "postcode": "40000",
    "districtCode": "4001",
    "districtNameTh": "เมืองขอนแก่น",
    "districtNameEn": "Mueang Khon Kaen",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400109",
    "subDistrictNameTh": "บ้านหว้า",
    "subDistrictNameEn": "Ban Wa",
    "postcode": "40000",
    "districtCode": "4001",
    "districtNameTh": "เมืองขอนแก่น",
    "districtNameEn": "Mueang Khon Kaen",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400110",
    "subDistrictNameTh": "บ้านค้อ",
    "subDistrictNameEn": "Ban Kho",
    "postcode": "40000",
    "districtCode": "4001",
    "districtNameTh": "เมืองขอนแก่น",
    "districtNameEn": "Mueang Khon Kaen",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400111",
    "subDistrictNameTh": "แดงใหญ่",
    "subDistrictNameEn": "Daeng Yai",
    "postcode": "40000",
    "districtCode": "4001",
    "districtNameTh": "เมืองขอนแก่น",
    "districtNameEn": "Mueang Khon Kaen",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400112",
    "subDistrictNameTh": "ดอนช้าง",
    "subDistrictNameEn": "Don Chang",
    "postcode": "40000",
    "districtCode": "4001",
    "districtNameTh": "เมืองขอนแก่น",
    "districtNameEn": "Mueang Khon Kaen",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400113",
    "subDistrictNameTh": "ดอนหัน",
    "subDistrictNameEn": "Don Han",
    "postcode": "40260",
    "districtCode": "4001",
    "districtNameTh": "เมืองขอนแก่น",
    "districtNameEn": "Mueang Khon Kaen",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400114",
    "subDistrictNameTh": "ศิลา",
    "subDistrictNameEn": "Sila",
    "postcode": "40000",
    "districtCode": "4001",
    "districtNameTh": "เมืองขอนแก่น",
    "districtNameEn": "Mueang Khon Kaen",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400115",
    "subDistrictNameTh": "บ้านเป็ด",
    "subDistrictNameEn": "Ban Pet",
    "postcode": "40000",
    "districtCode": "4001",
    "districtNameTh": "เมืองขอนแก่น",
    "districtNameEn": "Mueang Khon Kaen",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400116",
    "subDistrictNameTh": "หนองตูม",
    "subDistrictNameEn": "Nong Tum",
    "postcode": "40000",
    "districtCode": "4001",
    "districtNameTh": "เมืองขอนแก่น",
    "districtNameEn": "Mueang Khon Kaen",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400117",
    "subDistrictNameTh": "บึงเนียม",
    "subDistrictNameEn": "Bueng Niam",
    "postcode": "40000",
    "districtCode": "4001",
    "districtNameTh": "เมืองขอนแก่น",
    "districtNameEn": "Mueang Khon Kaen",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400118",
    "subDistrictNameTh": "โนนท่อน",
    "subDistrictNameEn": "Non Thon",
    "postcode": "40000",
    "districtCode": "4001",
    "districtNameTh": "เมืองขอนแก่น",
    "districtNameEn": "Mueang Khon Kaen",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400201",
    "subDistrictNameTh": "หนองบัว",
    "subDistrictNameEn": "Nong Bua",
    "postcode": "40270",
    "districtCode": "4002",
    "districtNameTh": "บ้านฝาง",
    "districtNameEn": "Ban Fang",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400202",
    "subDistrictNameTh": "ป่าหวายนั่ง",
    "subDistrictNameEn": "Pa Wai Nang",
    "postcode": "40270",
    "districtCode": "4002",
    "districtNameTh": "บ้านฝาง",
    "districtNameEn": "Ban Fang",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400203",
    "subDistrictNameTh": "โนนฆ้อง",
    "subDistrictNameEn": "Non Khong",
    "postcode": "40270",
    "districtCode": "4002",
    "districtNameTh": "บ้านฝาง",
    "districtNameEn": "Ban Fang",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400204",
    "subDistrictNameTh": "บ้านเหล่า",
    "subDistrictNameEn": "Ban Lao",
    "postcode": "40270",
    "districtCode": "4002",
    "districtNameTh": "บ้านฝาง",
    "districtNameEn": "Ban Fang",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400205",
    "subDistrictNameTh": "ป่ามะนาว",
    "subDistrictNameEn": "Pa Manao",
    "postcode": "40270",
    "districtCode": "4002",
    "districtNameTh": "บ้านฝาง",
    "districtNameEn": "Ban Fang",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400206",
    "subDistrictNameTh": "บ้านฝาง",
    "subDistrictNameEn": "Ban Fang",
    "postcode": "40270",
    "districtCode": "4002",
    "districtNameTh": "บ้านฝาง",
    "districtNameEn": "Ban Fang",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400207",
    "subDistrictNameTh": "โคกงาม",
    "subDistrictNameEn": "Khok Ngam",
    "postcode": "40270",
    "districtCode": "4002",
    "districtNameTh": "บ้านฝาง",
    "districtNameEn": "Ban Fang",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400301",
    "subDistrictNameTh": "พระยืน",
    "subDistrictNameEn": "Phra Yuen",
    "postcode": "40320",
    "districtCode": "4003",
    "districtNameTh": "พระยืน",
    "districtNameEn": "Phra Yuen",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400302",
    "subDistrictNameTh": "พระบุ",
    "subDistrictNameEn": "Phra Bu",
    "postcode": "40320",
    "districtCode": "4003",
    "districtNameTh": "พระยืน",
    "districtNameEn": "Phra Yuen",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400303",
    "subDistrictNameTh": "บ้านโต้น",
    "subDistrictNameEn": "Ban Ton",
    "postcode": "40320",
    "districtCode": "4003",
    "districtNameTh": "พระยืน",
    "districtNameEn": "Phra Yuen",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400304",
    "subDistrictNameTh": "หนองแวง",
    "subDistrictNameEn": "Nong Waeng",
    "postcode": "40320",
    "districtCode": "4003",
    "districtNameTh": "พระยืน",
    "districtNameEn": "Phra Yuen",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400305",
    "subDistrictNameTh": "ขามป้อม",
    "subDistrictNameEn": "Kham Pom",
    "postcode": "40320",
    "districtCode": "4003",
    "districtNameTh": "พระยืน",
    "districtNameEn": "Phra Yuen",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400401",
    "subDistrictNameTh": "หนองเรือ",
    "subDistrictNameEn": "Nong Ruea",
    "postcode": "40210",
    "districtCode": "4004",
    "districtNameTh": "หนองเรือ",
    "districtNameEn": "Nong Ruea",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400402",
    "subDistrictNameTh": "บ้านเม็ง",
    "subDistrictNameEn": "Ban Meng",
    "postcode": "40210",
    "districtCode": "4004",
    "districtNameTh": "หนองเรือ",
    "districtNameEn": "Nong Ruea",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400403",
    "subDistrictNameTh": "บ้านกง",
    "subDistrictNameEn": "Ban Kong",
    "postcode": "40240",
    "districtCode": "4004",
    "districtNameTh": "หนองเรือ",
    "districtNameEn": "Nong Ruea",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400404",
    "subDistrictNameTh": "ยางคำ",
    "subDistrictNameEn": "Yang Kham",
    "postcode": "40240",
    "districtCode": "4004",
    "districtNameTh": "หนองเรือ",
    "districtNameEn": "Nong Ruea",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400405",
    "subDistrictNameTh": "จระเข้",
    "subDistrictNameEn": "Chorakhe",
    "postcode": "40240",
    "districtCode": "4004",
    "districtNameTh": "หนองเรือ",
    "districtNameEn": "Nong Ruea",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400406",
    "subDistrictNameTh": "โนนทอง",
    "subDistrictNameEn": "Non Thong",
    "postcode": "40210",
    "districtCode": "4004",
    "districtNameTh": "หนองเรือ",
    "districtNameEn": "Nong Ruea",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400407",
    "subDistrictNameTh": "กุดกว้าง",
    "subDistrictNameEn": "Kut Kwang",
    "postcode": "40210",
    "districtCode": "4004",
    "districtNameTh": "หนองเรือ",
    "districtNameEn": "Nong Ruea",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400408",
    "subDistrictNameTh": "โนนทัน",
    "subDistrictNameEn": "Non Than",
    "postcode": "40210",
    "districtCode": "4004",
    "districtNameTh": "หนองเรือ",
    "districtNameEn": "Nong Ruea",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400409",
    "subDistrictNameTh": "โนนสะอาด",
    "subDistrictNameEn": "Non Sa-at",
    "postcode": "40210",
    "districtCode": "4004",
    "districtNameTh": "หนองเรือ",
    "districtNameEn": "Nong Ruea",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400410",
    "subDistrictNameTh": "บ้านผือ",
    "subDistrictNameEn": "Ban Phue",
    "postcode": "40240",
    "districtCode": "4004",
    "districtNameTh": "หนองเรือ",
    "districtNameEn": "Nong Ruea",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400501",
    "subDistrictNameTh": "ชุมแพ",
    "subDistrictNameEn": "Chum Phae",
    "postcode": "40130",
    "districtCode": "4005",
    "districtNameTh": "ชุมแพ",
    "districtNameEn": "Chum Phae",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400502",
    "subDistrictNameTh": "โนนหัน",
    "subDistrictNameEn": "Non Han",
    "postcode": "40290",
    "districtCode": "4005",
    "districtNameTh": "ชุมแพ",
    "districtNameEn": "Chum Phae",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400503",
    "subDistrictNameTh": "นาหนองทุ่ม",
    "subDistrictNameEn": "Na Nong Thum",
    "postcode": "40290",
    "districtCode": "4005",
    "districtNameTh": "ชุมแพ",
    "districtNameEn": "Chum Phae",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400504",
    "subDistrictNameTh": "โนนอุดม",
    "subDistrictNameEn": "Non Udom",
    "postcode": "40130",
    "districtCode": "4005",
    "districtNameTh": "ชุมแพ",
    "districtNameEn": "Chum Phae",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400505",
    "subDistrictNameTh": "ขัวเรียง",
    "subDistrictNameEn": "Khua Riang",
    "postcode": "40130",
    "districtCode": "4005",
    "districtNameTh": "ชุมแพ",
    "districtNameEn": "Chum Phae",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400506",
    "subDistrictNameTh": "หนองไผ่",
    "subDistrictNameEn": "Nong Phai",
    "postcode": "40130",
    "districtCode": "4005",
    "districtNameTh": "ชุมแพ",
    "districtNameEn": "Chum Phae",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400507",
    "subDistrictNameTh": "ไชยสอ",
    "subDistrictNameEn": "Chai So",
    "postcode": "40130",
    "districtCode": "4005",
    "districtNameTh": "ชุมแพ",
    "districtNameEn": "Chum Phae",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400508",
    "subDistrictNameTh": "วังหินลาด",
    "subDistrictNameEn": "Wang Hin Lat",
    "postcode": "40130",
    "districtCode": "4005",
    "districtNameTh": "ชุมแพ",
    "districtNameEn": "Chum Phae",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400509",
    "subDistrictNameTh": "นาเพียง",
    "subDistrictNameEn": "Na Phiang",
    "postcode": "40130",
    "districtCode": "4005",
    "districtNameTh": "ชุมแพ",
    "districtNameEn": "Chum Phae",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400510",
    "subDistrictNameTh": "หนองเขียด",
    "subDistrictNameEn": "Nong Khiat",
    "postcode": "40290",
    "districtCode": "4005",
    "districtNameTh": "ชุมแพ",
    "districtNameEn": "Chum Phae",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400511",
    "subDistrictNameTh": "หนองเสาเล้า",
    "subDistrictNameEn": "Nong Sao Lao",
    "postcode": "40130",
    "districtCode": "4005",
    "districtNameTh": "ชุมแพ",
    "districtNameEn": "Chum Phae",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400512",
    "subDistrictNameTh": "โนนสะอาด",
    "subDistrictNameEn": "Non Sa-at",
    "postcode": "40290",
    "districtCode": "4005",
    "districtNameTh": "ชุมแพ",
    "districtNameEn": "Chum Phae",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400601",
    "subDistrictNameTh": "สีชมพู",
    "subDistrictNameEn": "Si Chomphu",
    "postcode": "40220",
    "districtCode": "4006",
    "districtNameTh": "สีชมพู",
    "districtNameEn": "Si Chomphu",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400602",
    "subDistrictNameTh": "ศรีสุข",
    "subDistrictNameEn": "Si Suk",
    "postcode": "40220",
    "districtCode": "4006",
    "districtNameTh": "สีชมพู",
    "districtNameEn": "Si Chomphu",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400603",
    "subDistrictNameTh": "นาจาน",
    "subDistrictNameEn": "Na Chan",
    "postcode": "40220",
    "districtCode": "4006",
    "districtNameTh": "สีชมพู",
    "districtNameEn": "Si Chomphu",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400604",
    "subDistrictNameTh": "วังเพิ่ม",
    "subDistrictNameEn": "Wang Phoem",
    "postcode": "40220",
    "districtCode": "4006",
    "districtNameTh": "สีชมพู",
    "districtNameEn": "Si Chomphu",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400605",
    "subDistrictNameTh": "ซำยาง",
    "subDistrictNameEn": "Sam Yang",
    "postcode": "40220",
    "districtCode": "4006",
    "districtNameTh": "สีชมพู",
    "districtNameEn": "Si Chomphu",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400606",
    "subDistrictNameTh": "หนองแดง",
    "subDistrictNameEn": "Nong Daeng",
    "postcode": "40220",
    "districtCode": "4006",
    "districtNameTh": "สีชมพู",
    "districtNameEn": "Si Chomphu",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400607",
    "subDistrictNameTh": "ดงลาน",
    "subDistrictNameEn": "Dong Lan",
    "postcode": "40220",
    "districtCode": "4006",
    "districtNameTh": "สีชมพู",
    "districtNameEn": "Si Chomphu",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400608",
    "subDistrictNameTh": "บริบูรณ์",
    "subDistrictNameEn": "Boribun",
    "postcode": "40220",
    "districtCode": "4006",
    "districtNameTh": "สีชมพู",
    "districtNameEn": "Si Chomphu",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400609",
    "subDistrictNameTh": "บ้านใหม่",
    "subDistrictNameEn": "Ban Mai",
    "postcode": "40220",
    "districtCode": "4006",
    "districtNameTh": "สีชมพู",
    "districtNameEn": "Si Chomphu",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400610",
    "subDistrictNameTh": "ภูห่าน",
    "subDistrictNameEn": "Phu Han",
    "postcode": "40220",
    "districtCode": "4006",
    "districtNameTh": "สีชมพู",
    "districtNameEn": "Si Chomphu",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400701",
    "subDistrictNameTh": "น้ำพอง",
    "subDistrictNameEn": "Nam Phong",
    "postcode": "40140",
    "districtCode": "4007",
    "districtNameTh": "น้ำพอง",
    "districtNameEn": "Nam Phong",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400702",
    "subDistrictNameTh": "วังชัย",
    "subDistrictNameEn": "Wang Chai",
    "postcode": "40140",
    "districtCode": "4007",
    "districtNameTh": "น้ำพอง",
    "districtNameEn": "Nam Phong",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400703",
    "subDistrictNameTh": "หนองกุง",
    "subDistrictNameEn": "Nong Kung",
    "postcode": "40140",
    "districtCode": "4007",
    "districtNameTh": "น้ำพอง",
    "districtNameEn": "Nam Phong",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400704",
    "subDistrictNameTh": "บัวใหญ่",
    "subDistrictNameEn": "Bua Yai",
    "postcode": "40140",
    "districtCode": "4007",
    "districtNameTh": "น้ำพอง",
    "districtNameEn": "Nam Phong",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400705",
    "subDistrictNameTh": "สะอาด",
    "subDistrictNameEn": "Sa-at",
    "postcode": "40310",
    "districtCode": "4007",
    "districtNameTh": "น้ำพอง",
    "districtNameEn": "Nam Phong",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400706",
    "subDistrictNameTh": "ม่วงหวาน",
    "subDistrictNameEn": "Muang Wan",
    "postcode": "40310",
    "districtCode": "4007",
    "districtNameTh": "น้ำพอง",
    "districtNameEn": "Nam Phong",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400707",
    "subDistrictNameTh": "บ้านขาม",
    "subDistrictNameEn": "Ban Kham",
    "postcode": "40140",
    "districtCode": "4007",
    "districtNameTh": "น้ำพอง",
    "districtNameEn": "Nam Phong",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400708",
    "subDistrictNameTh": "บัวเงิน",
    "subDistrictNameEn": "Bua Ngoen",
    "postcode": "40140",
    "districtCode": "4007",
    "districtNameTh": "น้ำพอง",
    "districtNameEn": "Nam Phong",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400709",
    "subDistrictNameTh": "ทรายมูล",
    "subDistrictNameEn": "Sai Mun",
    "postcode": "40140",
    "districtCode": "4007",
    "districtNameTh": "น้ำพอง",
    "districtNameEn": "Nam Phong",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400710",
    "subDistrictNameTh": "ท่ากระเสริม",
    "subDistrictNameEn": "Tha Krasoem",
    "postcode": "40140",
    "districtCode": "4007",
    "districtNameTh": "น้ำพอง",
    "districtNameEn": "Nam Phong",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400711",
    "subDistrictNameTh": "พังทุย",
    "subDistrictNameEn": "Phang Thui",
    "postcode": "40140",
    "districtCode": "4007",
    "districtNameTh": "น้ำพอง",
    "districtNameEn": "Nam Phong",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400712",
    "subDistrictNameTh": "กุดน้ำใส",
    "subDistrictNameEn": "Kut Nam Sai",
    "postcode": "40310",
    "districtCode": "4007",
    "districtNameTh": "น้ำพอง",
    "districtNameEn": "Nam Phong",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400801",
    "subDistrictNameTh": "โคกสูง",
    "subDistrictNameEn": "Khok Sung",
    "postcode": "40250",
    "districtCode": "4008",
    "districtNameTh": "อุบลรัตน์",
    "districtNameEn": "Ubolratana",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400802",
    "subDistrictNameTh": "บ้านดง",
    "subDistrictNameEn": "Ban Dong",
    "postcode": "40250",
    "districtCode": "4008",
    "districtNameTh": "อุบลรัตน์",
    "districtNameEn": "Ubolratana",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400803",
    "subDistrictNameTh": "เขื่อนอุบลรัตน์",
    "subDistrictNameEn": "Khuean Ubolratana",
    "postcode": "40250",
    "districtCode": "4008",
    "districtNameTh": "อุบลรัตน์",
    "districtNameEn": "Ubolratana",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400804",
    "subDistrictNameTh": "นาคำ",
    "subDistrictNameEn": "Na Kham",
    "postcode": "40250",
    "districtCode": "4008",
    "districtNameTh": "อุบลรัตน์",
    "districtNameEn": "Ubolratana",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400805",
    "subDistrictNameTh": "ศรีสุขสำราญ",
    "subDistrictNameEn": "Si Suk Samran",
    "postcode": "40250",
    "districtCode": "4008",
    "districtNameTh": "อุบลรัตน์",
    "districtNameEn": "Ubolratana",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400806",
    "subDistrictNameTh": "ทุ่งโป่ง",
    "subDistrictNameEn": "Thung Pong",
    "postcode": "40250",
    "districtCode": "4008",
    "districtNameTh": "อุบลรัตน์",
    "districtNameEn": "Ubolratana",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400901",
    "subDistrictNameTh": "หนองโก",
    "subDistrictNameEn": "Nong Ko",
    "postcode": "40170",
    "districtCode": "4009",
    "districtNameTh": "กระนวน",
    "districtNameEn": "Kranuan",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400902",
    "subDistrictNameTh": "หนองกุงใหญ่",
    "subDistrictNameEn": "Nong Kung Yai",
    "postcode": "40170",
    "districtCode": "4009",
    "districtNameTh": "กระนวน",
    "districtNameEn": "Kranuan",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400905",
    "subDistrictNameTh": "ห้วยโจด",
    "subDistrictNameEn": "Huai Chot",
    "postcode": "40170",
    "districtCode": "4009",
    "districtNameTh": "กระนวน",
    "districtNameEn": "Kranuan",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400906",
    "subDistrictNameTh": "ห้วยยาง",
    "subDistrictNameEn": "Huai Yang",
    "postcode": "40170",
    "districtCode": "4009",
    "districtNameTh": "กระนวน",
    "districtNameEn": "Kranuan",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400907",
    "subDistrictNameTh": "บ้านฝาง",
    "subDistrictNameEn": "Ban Fang",
    "postcode": "40170",
    "districtCode": "4009",
    "districtNameTh": "กระนวน",
    "districtNameEn": "Kranuan",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400909",
    "subDistrictNameTh": "ดูนสาด",
    "subDistrictNameEn": "Dun Sat",
    "postcode": "40170",
    "districtCode": "4009",
    "districtNameTh": "กระนวน",
    "districtNameEn": "Kranuan",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400910",
    "subDistrictNameTh": "หนองโน",
    "subDistrictNameEn": "Nong No",
    "postcode": "40170",
    "districtCode": "4009",
    "districtNameTh": "กระนวน",
    "districtNameEn": "Kranuan",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400911",
    "subDistrictNameTh": "น้ำอ้อม",
    "subDistrictNameEn": "Nam Om",
    "postcode": "40170",
    "districtCode": "4009",
    "districtNameTh": "กระนวน",
    "districtNameEn": "Kranuan",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "400912",
    "subDistrictNameTh": "หัวนาคำ",
    "subDistrictNameEn": "Hua Na Kham",
    "postcode": "40170",
    "districtCode": "4009",
    "districtNameTh": "กระนวน",
    "districtNameEn": "Kranuan",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401001",
    "subDistrictNameTh": "บ้านไผ่",
    "subDistrictNameEn": "Ban Phai",
    "postcode": "40110",
    "districtCode": "4010",
    "districtNameTh": "บ้านไผ่",
    "districtNameEn": "Ban Phai",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401002",
    "subDistrictNameTh": "ในเมือง",
    "subDistrictNameEn": "Nai Mueang",
    "postcode": "40110",
    "districtCode": "4010",
    "districtNameTh": "บ้านไผ่",
    "districtNameEn": "Ban Phai",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401005",
    "subDistrictNameTh": "เมืองเพีย",
    "subDistrictNameEn": "Mueang Phia",
    "postcode": "40110",
    "districtCode": "4010",
    "districtNameTh": "บ้านไผ่",
    "districtNameEn": "Ban Phai",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401009",
    "subDistrictNameTh": "บ้านลาน",
    "subDistrictNameEn": "Ban Lan",
    "postcode": "40110",
    "districtCode": "4010",
    "districtNameTh": "บ้านไผ่",
    "districtNameEn": "Ban Phai",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401010",
    "subDistrictNameTh": "แคนเหนือ",
    "subDistrictNameEn": "Khaen Nuea",
    "postcode": "40110",
    "districtCode": "4010",
    "districtNameTh": "บ้านไผ่",
    "districtNameEn": "Ban Phai",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401011",
    "subDistrictNameTh": "ภูเหล็ก",
    "subDistrictNameEn": "Phu Lek",
    "postcode": "40110",
    "districtCode": "4010",
    "districtNameTh": "บ้านไผ่",
    "districtNameEn": "Ban Phai",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401013",
    "subDistrictNameTh": "ป่าปอ",
    "subDistrictNameEn": "Pa Po",
    "postcode": "40110",
    "districtCode": "4010",
    "districtNameTh": "บ้านไผ่",
    "districtNameEn": "Ban Phai",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401014",
    "subDistrictNameTh": "หินตั้ง",
    "subDistrictNameEn": "Hin Tang",
    "postcode": "40110",
    "districtCode": "4010",
    "districtNameTh": "บ้านไผ่",
    "districtNameEn": "Ban Phai",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401016",
    "subDistrictNameTh": "หนองน้ำใส",
    "subDistrictNameEn": "Nong Nam Sai",
    "postcode": "40110",
    "districtCode": "4010",
    "districtNameTh": "บ้านไผ่",
    "districtNameEn": "Ban Phai",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401017",
    "subDistrictNameTh": "หัวหนอง",
    "subDistrictNameEn": "Hua Nong",
    "postcode": "40110",
    "districtCode": "4010",
    "districtNameTh": "บ้านไผ่",
    "districtNameEn": "Ban Phai",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401101",
    "subDistrictNameTh": "เปือยน้อย",
    "subDistrictNameEn": "Pueai Noi",
    "postcode": "40340",
    "districtCode": "4011",
    "districtNameTh": "เปือยน้อย",
    "districtNameEn": "Pueai Noi",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401102",
    "subDistrictNameTh": "วังม่วง",
    "subDistrictNameEn": "Wang Muang",
    "postcode": "40340",
    "districtCode": "4011",
    "districtNameTh": "เปือยน้อย",
    "districtNameEn": "Pueai Noi",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401103",
    "subDistrictNameTh": "ขามป้อม",
    "subDistrictNameEn": "Kham Pom",
    "postcode": "40340",
    "districtCode": "4011",
    "districtNameTh": "เปือยน้อย",
    "districtNameEn": "Pueai Noi",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401104",
    "subDistrictNameTh": "สระแก้ว",
    "subDistrictNameEn": "Sa Kaeo",
    "postcode": "40340",
    "districtCode": "4011",
    "districtNameTh": "เปือยน้อย",
    "districtNameEn": "Pueai Noi",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401201",
    "subDistrictNameTh": "เมืองพล",
    "subDistrictNameEn": "Mueang Phon",
    "postcode": "40120",
    "districtCode": "4012",
    "districtNameTh": "พล",
    "districtNameEn": "Phon",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401203",
    "subDistrictNameTh": "โจดหนองแก",
    "subDistrictNameEn": "Chot Nong Kae",
    "postcode": "40120",
    "districtCode": "4012",
    "districtNameTh": "พล",
    "districtNameEn": "Phon",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401204",
    "subDistrictNameTh": "เก่างิ้ว",
    "subDistrictNameEn": "Kao Ngio",
    "postcode": "40120",
    "districtCode": "4012",
    "districtNameTh": "พล",
    "districtNameEn": "Phon",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401205",
    "subDistrictNameTh": "หนองมะเขือ",
    "subDistrictNameEn": "Nong Makhuea",
    "postcode": "40120",
    "districtCode": "4012",
    "districtNameTh": "พล",
    "districtNameEn": "Phon",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401206",
    "subDistrictNameTh": "หนองแวงโสกพระ",
    "subDistrictNameEn": "Nong Waeng Sok Phra",
    "postcode": "40120",
    "districtCode": "4012",
    "districtNameTh": "พล",
    "districtNameEn": "Phon",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401207",
    "subDistrictNameTh": "เพ็กใหญ่",
    "subDistrictNameEn": "Phek Yai",
    "postcode": "40120",
    "districtCode": "4012",
    "districtNameTh": "พล",
    "districtNameEn": "Phon",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401208",
    "subDistrictNameTh": "โคกสง่า",
    "subDistrictNameEn": "Khok Sa-nga",
    "postcode": "40120",
    "districtCode": "4012",
    "districtNameTh": "พล",
    "districtNameEn": "Phon",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401209",
    "subDistrictNameTh": "หนองแวงนางเบ้า",
    "subDistrictNameEn": "Nong Waeng Nang Bao",
    "postcode": "40120",
    "districtCode": "4012",
    "districtNameTh": "พล",
    "districtNameEn": "Phon",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401210",
    "subDistrictNameTh": "ลอมคอม",
    "subDistrictNameEn": "Lom Khom",
    "postcode": "40120",
    "districtCode": "4012",
    "districtNameTh": "พล",
    "districtNameEn": "Phon",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401211",
    "subDistrictNameTh": "โนนข่า",
    "subDistrictNameEn": "Non Kha",
    "postcode": "40120",
    "districtCode": "4012",
    "districtNameTh": "พล",
    "districtNameEn": "Phon",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401212",
    "subDistrictNameTh": "โสกนกเต็น",
    "subDistrictNameEn": "Sok Nok Ten",
    "postcode": "40120",
    "districtCode": "4012",
    "districtNameTh": "พล",
    "districtNameEn": "Phon",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401213",
    "subDistrictNameTh": "หัวทุ่ง",
    "subDistrictNameEn": "Hua Thung",
    "postcode": "40120",
    "districtCode": "4012",
    "districtNameTh": "พล",
    "districtNameEn": "Phon",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401301",
    "subDistrictNameTh": "คอนฉิม",
    "subDistrictNameEn": "Khon Chim",
    "postcode": "40330",
    "districtCode": "4013",
    "districtNameTh": "แวงใหญ่",
    "districtNameEn": "Waeng Yai",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401302",
    "subDistrictNameTh": "ใหม่นาเพียง",
    "subDistrictNameEn": "Mai Na Phiang",
    "postcode": "40330",
    "districtCode": "4013",
    "districtNameTh": "แวงใหญ่",
    "districtNameEn": "Waeng Yai",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401303",
    "subDistrictNameTh": "โนนทอง",
    "subDistrictNameEn": "Non Thong",
    "postcode": "40330",
    "districtCode": "4013",
    "districtNameTh": "แวงใหญ่",
    "districtNameEn": "Waeng Yai",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401304",
    "subDistrictNameTh": "แวงใหญ่",
    "subDistrictNameEn": "Waeng Yai",
    "postcode": "40330",
    "districtCode": "4013",
    "districtNameTh": "แวงใหญ่",
    "districtNameEn": "Waeng Yai",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401305",
    "subDistrictNameTh": "โนนสะอาด",
    "subDistrictNameEn": "Non Sa-at",
    "postcode": "40330",
    "districtCode": "4013",
    "districtNameTh": "แวงใหญ่",
    "districtNameEn": "Waeng Yai",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401401",
    "subDistrictNameTh": "แวงน้อย",
    "subDistrictNameEn": "Waeng Noi",
    "postcode": "40230",
    "districtCode": "4014",
    "districtNameTh": "แวงน้อย",
    "districtNameEn": "Waeng Noi",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401402",
    "subDistrictNameTh": "ก้านเหลือง",
    "subDistrictNameEn": "Kan Lueang",
    "postcode": "40230",
    "districtCode": "4014",
    "districtNameTh": "แวงน้อย",
    "districtNameEn": "Waeng Noi",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401403",
    "subDistrictNameTh": "ท่านางแนว",
    "subDistrictNameEn": "Tha Nang Naeo",
    "postcode": "40230",
    "districtCode": "4014",
    "districtNameTh": "แวงน้อย",
    "districtNameEn": "Waeng Noi",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401404",
    "subDistrictNameTh": "ละหานนา",
    "subDistrictNameEn": "Lahan Na",
    "postcode": "40230",
    "districtCode": "4014",
    "districtNameTh": "แวงน้อย",
    "districtNameEn": "Waeng Noi",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401405",
    "subDistrictNameTh": "ท่าวัด",
    "subDistrictNameEn": "Tha Wat",
    "postcode": "40230",
    "districtCode": "4014",
    "districtNameTh": "แวงน้อย",
    "districtNameEn": "Waeng Noi",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401406",
    "subDistrictNameTh": "ทางขวาง",
    "subDistrictNameEn": "Thang Khwang",
    "postcode": "40230",
    "districtCode": "4014",
    "districtNameTh": "แวงน้อย",
    "districtNameEn": "Waeng Noi",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401501",
    "subDistrictNameTh": "หนองสองห้อง",
    "subDistrictNameEn": "Nong Song Hong",
    "postcode": "40190",
    "districtCode": "4015",
    "districtNameTh": "หนองสองห้อง",
    "districtNameEn": "Nong Song Hong",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401502",
    "subDistrictNameTh": "คึมชาด",
    "subDistrictNameEn": "Khuemchat",
    "postcode": "40190",
    "districtCode": "4015",
    "districtNameTh": "หนองสองห้อง",
    "districtNameEn": "Nong Song Hong",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401503",
    "subDistrictNameTh": "โนนธาตุ",
    "subDistrictNameEn": "Non That",
    "postcode": "40190",
    "districtCode": "4015",
    "districtNameTh": "หนองสองห้อง",
    "districtNameEn": "Nong Song Hong",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401504",
    "subDistrictNameTh": "ตะกั่วป่า",
    "subDistrictNameEn": "Takua Pa",
    "postcode": "40190",
    "districtCode": "4015",
    "districtNameTh": "หนองสองห้อง",
    "districtNameEn": "Nong Song Hong",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401505",
    "subDistrictNameTh": "สำโรง",
    "subDistrictNameEn": "Samrong",
    "postcode": "40190",
    "districtCode": "4015",
    "districtNameTh": "หนองสองห้อง",
    "districtNameEn": "Nong Song Hong",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401506",
    "subDistrictNameTh": "หนองเม็ก",
    "subDistrictNameEn": "Nong Mek",
    "postcode": "40190",
    "districtCode": "4015",
    "districtNameTh": "หนองสองห้อง",
    "districtNameEn": "Nong Song Hong",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401507",
    "subDistrictNameTh": "ดอนดู่",
    "subDistrictNameEn": "Don Du",
    "postcode": "40190",
    "districtCode": "4015",
    "districtNameTh": "หนองสองห้อง",
    "districtNameEn": "Nong Song Hong",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401508",
    "subDistrictNameTh": "ดงเค็ง",
    "subDistrictNameEn": "Dong Kheng",
    "postcode": "40190",
    "districtCode": "4015",
    "districtNameTh": "หนองสองห้อง",
    "districtNameEn": "Nong Song Hong",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401509",
    "subDistrictNameTh": "หันโจด",
    "subDistrictNameEn": "Han Chot",
    "postcode": "40190",
    "districtCode": "4015",
    "districtNameTh": "หนองสองห้อง",
    "districtNameEn": "Nong Song Hong",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401510",
    "subDistrictNameTh": "ดอนดั่ง",
    "subDistrictNameEn": "Don Dang",
    "postcode": "40190",
    "districtCode": "4015",
    "districtNameTh": "หนองสองห้อง",
    "districtNameEn": "Nong Song Hong",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401511",
    "subDistrictNameTh": "วังหิน",
    "subDistrictNameEn": "Wang Hin",
    "postcode": "40190",
    "districtCode": "4015",
    "districtNameTh": "หนองสองห้อง",
    "districtNameEn": "Nong Song Hong",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401512",
    "subDistrictNameTh": "หนองไผ่ล้อม",
    "subDistrictNameEn": "Nong Phai Lom",
    "postcode": "40190",
    "districtCode": "4015",
    "districtNameTh": "หนองสองห้อง",
    "districtNameEn": "Nong Song Hong",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401601",
    "subDistrictNameTh": "บ้านเรือ",
    "subDistrictNameEn": "Ban Ruea",
    "postcode": "40150",
    "districtCode": "4016",
    "districtNameTh": "ภูเวียง",
    "districtNameEn": "Phu Wiang",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401604",
    "subDistrictNameTh": "หว้าทอง",
    "subDistrictNameEn": "Wa Thong",
    "postcode": "40150",
    "districtCode": "4016",
    "districtNameTh": "ภูเวียง",
    "districtNameEn": "Phu Wiang",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401605",
    "subDistrictNameTh": "กุดขอนแก่น",
    "subDistrictNameEn": "Kut Khon Kaen",
    "postcode": "40150",
    "districtCode": "4016",
    "districtNameTh": "ภูเวียง",
    "districtNameEn": "Phu Wiang",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401606",
    "subDistrictNameTh": "นาชุมแสง",
    "subDistrictNameEn": "Na Chum Saeng",
    "postcode": "40150",
    "districtCode": "4016",
    "districtNameTh": "ภูเวียง",
    "districtNameEn": "Phu Wiang",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401607",
    "subDistrictNameTh": "นาหว้า",
    "subDistrictNameEn": "Na Wa",
    "postcode": "40150",
    "districtCode": "4016",
    "districtNameTh": "ภูเวียง",
    "districtNameEn": "Phu Wiang",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401610",
    "subDistrictNameTh": "หนองกุงธนสาร",
    "subDistrictNameEn": "Nong Kung Thanasan",
    "postcode": "40150",
    "districtCode": "4016",
    "districtNameTh": "ภูเวียง",
    "districtNameEn": "Phu Wiang",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401612",
    "subDistrictNameTh": "หนองกุงเซิน",
    "subDistrictNameEn": "Nong Kung Soen",
    "postcode": "40150",
    "districtCode": "4016",
    "districtNameTh": "ภูเวียง",
    "districtNameEn": "Phu Wiang",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401613",
    "subDistrictNameTh": "สงเปือย",
    "subDistrictNameEn": "Song Pueai",
    "postcode": "40150",
    "districtCode": "4016",
    "districtNameTh": "ภูเวียง",
    "districtNameEn": "Phu Wiang",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401614",
    "subDistrictNameTh": "ทุ่งชมพู",
    "subDistrictNameEn": "Thung Chomphu",
    "postcode": "40150",
    "districtCode": "4016",
    "districtNameTh": "ภูเวียง",
    "districtNameEn": "Phu Wiang",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401616",
    "subDistrictNameTh": "ดินดำ",
    "subDistrictNameEn": "Din Dam",
    "postcode": "40150",
    "districtCode": "4016",
    "districtNameTh": "ภูเวียง",
    "districtNameEn": "Phu Wiang",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401617",
    "subDistrictNameTh": "ภูเวียง",
    "subDistrictNameEn": "Phu Wiang",
    "postcode": "40150",
    "districtCode": "4016",
    "districtNameTh": "ภูเวียง",
    "districtNameEn": "Phu Wiang",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401701",
    "subDistrictNameTh": "กุดเค้า",
    "subDistrictNameEn": "Kut Khao",
    "postcode": "40160",
    "districtCode": "4017",
    "districtNameTh": "มัญจาคีรี",
    "districtNameEn": "Mancha Khiri",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401702",
    "subDistrictNameTh": "สวนหม่อน",
    "subDistrictNameEn": "Suan Mon",
    "postcode": "40160",
    "districtCode": "4017",
    "districtNameTh": "มัญจาคีรี",
    "districtNameEn": "Mancha Khiri",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401703",
    "subDistrictNameTh": "หนองแปน",
    "subDistrictNameEn": "Nong Paen",
    "postcode": "40160",
    "districtCode": "4017",
    "districtNameTh": "มัญจาคีรี",
    "districtNameEn": "Mancha Khiri",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401704",
    "subDistrictNameTh": "โพนเพ็ก",
    "subDistrictNameEn": "Phon Phek",
    "postcode": "40160",
    "districtCode": "4017",
    "districtNameTh": "มัญจาคีรี",
    "districtNameEn": "Mancha Khiri",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401705",
    "subDistrictNameTh": "คำแคน",
    "subDistrictNameEn": "Kham Khaen",
    "postcode": "40160",
    "districtCode": "4017",
    "districtNameTh": "มัญจาคีรี",
    "districtNameEn": "Mancha Khiri",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401706",
    "subDistrictNameTh": "นาข่า",
    "subDistrictNameEn": "Na Kha",
    "postcode": "40160",
    "districtCode": "4017",
    "districtNameTh": "มัญจาคีรี",
    "districtNameEn": "Mancha Khiri",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401707",
    "subDistrictNameTh": "นางาม",
    "subDistrictNameEn": "Na Ngam",
    "postcode": "40160",
    "districtCode": "4017",
    "districtNameTh": "มัญจาคีรี",
    "districtNameEn": "Mancha Khiri",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401710",
    "subDistrictNameTh": "ท่าศาลา",
    "subDistrictNameEn": "Tha Sala",
    "postcode": "40160",
    "districtCode": "4017",
    "districtNameTh": "มัญจาคีรี",
    "districtNameEn": "Mancha Khiri",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401801",
    "subDistrictNameTh": "ชนบท",
    "subDistrictNameEn": "Chonnabot",
    "postcode": "40180",
    "districtCode": "4018",
    "districtNameTh": "ชนบท",
    "districtNameEn": "Chonnabot",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401802",
    "subDistrictNameTh": "กุดเพียขอม",
    "subDistrictNameEn": "Kut Phia Khom",
    "postcode": "40180",
    "districtCode": "4018",
    "districtNameTh": "ชนบท",
    "districtNameEn": "Chonnabot",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401803",
    "subDistrictNameTh": "วังแสง",
    "subDistrictNameEn": "Wang Saeng",
    "postcode": "40180",
    "districtCode": "4018",
    "districtNameTh": "ชนบท",
    "districtNameEn": "Chonnabot",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401804",
    "subDistrictNameTh": "ห้วยแก",
    "subDistrictNameEn": "Huai Kae",
    "postcode": "40180",
    "districtCode": "4018",
    "districtNameTh": "ชนบท",
    "districtNameEn": "Chonnabot",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401805",
    "subDistrictNameTh": "บ้านแท่น",
    "subDistrictNameEn": "Ban Thaen",
    "postcode": "40180",
    "districtCode": "4018",
    "districtNameTh": "ชนบท",
    "districtNameEn": "Chonnabot",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401806",
    "subDistrictNameTh": "ศรีบุญเรือง",
    "subDistrictNameEn": "Si Bun Rueang",
    "postcode": "40180",
    "districtCode": "4018",
    "districtNameTh": "ชนบท",
    "districtNameEn": "Chonnabot",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401807",
    "subDistrictNameTh": "โนนพะยอม",
    "subDistrictNameEn": "Non Phayom",
    "postcode": "40180",
    "districtCode": "4018",
    "districtNameTh": "ชนบท",
    "districtNameEn": "Chonnabot",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401808",
    "subDistrictNameTh": "ปอแดง",
    "subDistrictNameEn": "Po Daeng",
    "postcode": "40180",
    "districtCode": "4018",
    "districtNameTh": "ชนบท",
    "districtNameEn": "Chonnabot",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401901",
    "subDistrictNameTh": "เขาสวนกวาง",
    "subDistrictNameEn": "Khao Suan Kwang",
    "postcode": "40280",
    "districtCode": "4019",
    "districtNameTh": "เขาสวนกวาง",
    "districtNameEn": "Khao Suan Kwang",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401902",
    "subDistrictNameTh": "ดงเมืองแอม",
    "subDistrictNameEn": "Dong Mueang Aem",
    "postcode": "40280",
    "districtCode": "4019",
    "districtNameTh": "เขาสวนกวาง",
    "districtNameEn": "Khao Suan Kwang",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401903",
    "subDistrictNameTh": "นางิ้ว",
    "subDistrictNameEn": "Na Ngio",
    "postcode": "40280",
    "districtCode": "4019",
    "districtNameTh": "เขาสวนกวาง",
    "districtNameEn": "Khao Suan Kwang",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401904",
    "subDistrictNameTh": "โนนสมบูรณ์",
    "subDistrictNameEn": "Non Sombun",
    "postcode": "40280",
    "districtCode": "4019",
    "districtNameTh": "เขาสวนกวาง",
    "districtNameEn": "Khao Suan Kwang",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "401905",
    "subDistrictNameTh": "คำม่วง",
    "subDistrictNameEn": "Kham Muang",
    "postcode": "40280",
    "districtCode": "4019",
    "districtNameTh": "เขาสวนกวาง",
    "districtNameEn": "Khao Suan Kwang",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "402001",
    "subDistrictNameTh": "โนนคอม",
    "subDistrictNameEn": "Non Khom",
    "postcode": "40350",
    "districtCode": "4020",
    "districtNameTh": "ภูผาม่าน",
    "districtNameEn": "Phu Pha Man",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "402002",
    "subDistrictNameTh": "นาฝาย",
    "subDistrictNameEn": "Na Fai",
    "postcode": "40350",
    "districtCode": "4020",
    "districtNameTh": "ภูผาม่าน",
    "districtNameEn": "Phu Pha Man",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "402003",
    "subDistrictNameTh": "ภูผาม่าน",
    "subDistrictNameEn": "Phu Pha Man",
    "postcode": "40350",
    "districtCode": "4020",
    "districtNameTh": "ภูผาม่าน",
    "districtNameEn": "Phu Pha Man",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "402004",
    "subDistrictNameTh": "วังสวาบ",
    "subDistrictNameEn": "Wang Sawap",
    "postcode": "40350",
    "districtCode": "4020",
    "districtNameTh": "ภูผาม่าน",
    "districtNameEn": "Phu Pha Man",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "402005",
    "subDistrictNameTh": "ห้วยม่วง",
    "subDistrictNameEn": "huai Muang",
    "postcode": "40350",
    "districtCode": "4020",
    "districtNameTh": "ภูผาม่าน",
    "districtNameEn": "Phu Pha Man",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "402101",
    "subDistrictNameTh": "กระนวน",
    "subDistrictNameEn": "Kranuan",
    "postcode": "40170",
    "districtCode": "4021",
    "districtNameTh": "ซำสูง",
    "districtNameEn": "Sam Sung",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "402102",
    "subDistrictNameTh": "คำแมด",
    "subDistrictNameEn": "Kham Maet",
    "postcode": "40170",
    "districtCode": "4021",
    "districtNameTh": "ซำสูง",
    "districtNameEn": "Sam Sung",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "402103",
    "subDistrictNameTh": "บ้านโนน",
    "subDistrictNameEn": "Ban Non",
    "postcode": "40170",
    "districtCode": "4021",
    "districtNameTh": "ซำสูง",
    "districtNameEn": "Sam Sung",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "402104",
    "subDistrictNameTh": "คูคำ",
    "subDistrictNameEn": "Khu Kham",
    "postcode": "40170",
    "districtCode": "4021",
    "districtNameTh": "ซำสูง",
    "districtNameEn": "Sam Sung",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "402105",
    "subDistrictNameTh": "ห้วยเตย",
    "subDistrictNameEn": "Huai Toei",
    "postcode": "40170",
    "districtCode": "4021",
    "districtNameTh": "ซำสูง",
    "districtNameEn": "Sam Sung",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "402201",
    "subDistrictNameTh": "บ้านโคก",
    "subDistrictNameEn": "Ban Khok",
    "postcode": "40160",
    "districtCode": "4022",
    "districtNameTh": "โคกโพธิ์ไชย",
    "districtNameEn": "Khok Pho Chai",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "402202",
    "subDistrictNameTh": "โพธิ์ไชย",
    "subDistrictNameEn": "Pho Chai",
    "postcode": "40160",
    "districtCode": "4022",
    "districtNameTh": "โคกโพธิ์ไชย",
    "districtNameEn": "Khok Pho Chai",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "402203",
    "subDistrictNameTh": "ซับสมบูรณ์",
    "subDistrictNameEn": "Sap Sombun",
    "postcode": "40160",
    "districtCode": "4022",
    "districtNameTh": "โคกโพธิ์ไชย",
    "districtNameEn": "Khok Pho Chai",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "402204",
    "subDistrictNameTh": "นาแพง",
    "subDistrictNameEn": "Na Phaeng",
    "postcode": "40160",
    "districtCode": "4022",
    "districtNameTh": "โคกโพธิ์ไชย",
    "districtNameEn": "Khok Pho Chai",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "402301",
    "subDistrictNameTh": "กุดธาตุ",
    "subDistrictNameEn": "Kut That",
    "postcode": "40150",
    "districtCode": "4023",
    "districtNameTh": "หนองนาคำ",
    "districtNameEn": "Nong Na Kham",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "402302",
    "subDistrictNameTh": "บ้านโคก",
    "subDistrictNameEn": "Ban Khok",
    "postcode": "40150",
    "districtCode": "4023",
    "districtNameTh": "หนองนาคำ",
    "districtNameEn": "Nong Na Kham",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "402303",
    "subDistrictNameTh": "ขนวน",
    "subDistrictNameEn": "Khanuan",
    "postcode": "40150",
    "districtCode": "4023",
    "districtNameTh": "หนองนาคำ",
    "districtNameEn": "Nong Na Kham",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "402401",
    "subDistrictNameTh": "บ้านแฮด",
    "subDistrictNameEn": "Ban Haet",
    "postcode": "40110",
    "districtCode": "4024",
    "districtNameTh": "บ้านแฮด",
    "districtNameEn": "Ban Haet",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "402402",
    "subDistrictNameTh": "โคกสำราญ",
    "subDistrictNameEn": "Khok Samran",
    "postcode": "40110",
    "districtCode": "4024",
    "districtNameTh": "บ้านแฮด",
    "districtNameEn": "Ban Haet",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "402403",
    "subDistrictNameTh": "โนนสมบูรณ์",
    "subDistrictNameEn": "Non Sombun",
    "postcode": "40110",
    "districtCode": "4024",
    "districtNameTh": "บ้านแฮด",
    "districtNameEn": "Ban Haet",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "402404",
    "subDistrictNameTh": "หนองแซง",
    "subDistrictNameEn": "Nong Saeng",
    "postcode": "40110",
    "districtCode": "4024",
    "districtNameTh": "บ้านแฮด",
    "districtNameEn": "Ban Haet",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "402501",
    "subDistrictNameTh": "โนนศิลา",
    "subDistrictNameEn": "Non Sila",
    "postcode": "40110",
    "districtCode": "4025",
    "districtNameTh": "โนนศิลา",
    "districtNameEn": "Non Sila",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "402502",
    "subDistrictNameTh": "หนองปลาหมอ",
    "subDistrictNameEn": "Nong Pla Mo",
    "postcode": "40110",
    "districtCode": "4025",
    "districtNameTh": "โนนศิลา",
    "districtNameEn": "Non Sila",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "402503",
    "subDistrictNameTh": "บ้านหัน",
    "subDistrictNameEn": "Ban Han",
    "postcode": "40110",
    "districtCode": "4025",
    "districtNameTh": "โนนศิลา",
    "districtNameEn": "Non Sila",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "402504",
    "subDistrictNameTh": "เปือยใหญ่",
    "subDistrictNameEn": "Pueai Yai",
    "postcode": "40110",
    "districtCode": "4025",
    "districtNameTh": "โนนศิลา",
    "districtNameEn": "Non Sila",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "402505",
    "subDistrictNameTh": "โนนแดง",
    "subDistrictNameEn": "Non Daeng",
    "postcode": "40110",
    "districtCode": "4025",
    "districtNameTh": "โนนศิลา",
    "districtNameEn": "Non Sila",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "402901",
    "subDistrictNameTh": "ในเมือง",
    "subDistrictNameEn": "Nai Mueang",
    "postcode": "40150",
    "districtCode": "4026",
    "districtNameTh": "เวียงเก่า",
    "districtNameEn": "Wiang Kao",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "402902",
    "subDistrictNameTh": "เมืองเก่าพัฒนา",
    "subDistrictNameEn": "Mueang Kao Phatthana",
    "postcode": "40150",
    "districtCode": "4026",
    "districtNameTh": "เวียงเก่า",
    "districtNameEn": "Wiang Kao",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "402903",
    "subDistrictNameTh": "เขาน้อย",
    "subDistrictNameEn": "Khao Noi",
    "postcode": "40150",
    "districtCode": "4026",
    "districtNameTh": "เวียงเก่า",
    "districtNameEn": "Wiang Kao",
    "provinceCode": "40",
    "provinceNameTh": "ขอนแก่น",
    "provinceNameEn": "Khon Kaen"
},
{
    "subDistrictCode": "410101",
    "subDistrictNameTh": "หมากแข้ง",
    "subDistrictNameEn": "Mak Khaeng",
    "postcode": "41000",
    "districtCode": "4101",
    "districtNameTh": "เมืองอุดรธานี",
    "districtNameEn": "Mueang Udon Thani",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410102",
    "subDistrictNameTh": "นิคมสงเคราะห์",
    "subDistrictNameEn": "Nikhom Songkhro",
    "postcode": "41000",
    "districtCode": "4101",
    "districtNameTh": "เมืองอุดรธานี",
    "districtNameEn": "Mueang Udon Thani",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410103",
    "subDistrictNameTh": "บ้านขาว",
    "subDistrictNameEn": "Ban Khao",
    "postcode": "41000",
    "districtCode": "4101",
    "districtNameTh": "เมืองอุดรธานี",
    "districtNameEn": "Mueang Udon Thani",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410104",
    "subDistrictNameTh": "หนองบัว",
    "subDistrictNameEn": "Nong Bua",
    "postcode": "41000",
    "districtCode": "4101",
    "districtNameTh": "เมืองอุดรธานี",
    "districtNameEn": "Mueang Udon Thani",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410105",
    "subDistrictNameTh": "บ้านตาด",
    "subDistrictNameEn": "Ban Tat",
    "postcode": "41000",
    "districtCode": "4101",
    "districtNameTh": "เมืองอุดรธานี",
    "districtNameEn": "Mueang Udon Thani",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410106",
    "subDistrictNameTh": "โนนสูง",
    "subDistrictNameEn": "Non Sung",
    "postcode": "41330",
    "districtCode": "4101",
    "districtNameTh": "เมืองอุดรธานี",
    "districtNameEn": "Mueang Udon Thani",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410107",
    "subDistrictNameTh": "หมูม่น",
    "subDistrictNameEn": "Mu Mon",
    "postcode": "41000",
    "districtCode": "4101",
    "districtNameTh": "เมืองอุดรธานี",
    "districtNameEn": "Mueang Udon Thani",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410108",
    "subDistrictNameTh": "เชียงยืน",
    "subDistrictNameEn": "Chiang Yuen",
    "postcode": "41000",
    "districtCode": "4101",
    "districtNameTh": "เมืองอุดรธานี",
    "districtNameEn": "Mueang Udon Thani",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410109",
    "subDistrictNameTh": "หนองนาคำ",
    "subDistrictNameEn": "Nong Na Kham",
    "postcode": "41000",
    "districtCode": "4101",
    "districtNameTh": "เมืองอุดรธานี",
    "districtNameEn": "Mueang Udon Thani",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410110",
    "subDistrictNameTh": "กุดสระ",
    "subDistrictNameEn": "Kut Sa",
    "postcode": "41000",
    "districtCode": "4101",
    "districtNameTh": "เมืองอุดรธานี",
    "districtNameEn": "Mueang Udon Thani",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410111",
    "subDistrictNameTh": "นาดี",
    "subDistrictNameEn": "Na Di",
    "postcode": "41000",
    "districtCode": "4101",
    "districtNameTh": "เมืองอุดรธานี",
    "districtNameEn": "Mueang Udon Thani",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410112",
    "subDistrictNameTh": "บ้านเลื่อม",
    "subDistrictNameEn": "Ban Lueam",
    "postcode": "41000",
    "districtCode": "4101",
    "districtNameTh": "เมืองอุดรธานี",
    "districtNameEn": "Mueang Udon Thani",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410113",
    "subDistrictNameTh": "เชียงพิณ",
    "subDistrictNameEn": "Chiang Phin",
    "postcode": "41000",
    "districtCode": "4101",
    "districtNameTh": "เมืองอุดรธานี",
    "districtNameEn": "Mueang Udon Thani",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410114",
    "subDistrictNameTh": "สามพร้าว",
    "subDistrictNameEn": "Sam Phrao",
    "postcode": "41000",
    "districtCode": "4101",
    "districtNameTh": "เมืองอุดรธานี",
    "districtNameEn": "Mueang Udon Thani",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410115",
    "subDistrictNameTh": "หนองไฮ",
    "subDistrictNameEn": "Nong Hai",
    "postcode": "41000",
    "districtCode": "4101",
    "districtNameTh": "เมืองอุดรธานี",
    "districtNameEn": "Mueang Udon Thani",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410116",
    "subDistrictNameTh": "นาข่า",
    "subDistrictNameEn": "Na Kha",
    "postcode": "41000",
    "districtCode": "4101",
    "districtNameTh": "เมืองอุดรธานี",
    "districtNameEn": "Mueang Udon Thani",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410117",
    "subDistrictNameTh": "บ้านจั่น",
    "subDistrictNameEn": "Ban Chan",
    "postcode": "41000",
    "districtCode": "4101",
    "districtNameTh": "เมืองอุดรธานี",
    "districtNameEn": "Mueang Udon Thani",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410118",
    "subDistrictNameTh": "หนองขอนกว้าง",
    "subDistrictNameEn": "Nong Khon Kwang",
    "postcode": "41000",
    "districtCode": "4101",
    "districtNameTh": "เมืองอุดรธานี",
    "districtNameEn": "Mueang Udon Thani",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410119",
    "subDistrictNameTh": "โคกสะอาด",
    "subDistrictNameEn": "Khok Sa-at",
    "postcode": "41000",
    "districtCode": "4101",
    "districtNameTh": "เมืองอุดรธานี",
    "districtNameEn": "Mueang Udon Thani",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410120",
    "subDistrictNameTh": "นากว้าง",
    "subDistrictNameEn": "Na Kwang",
    "postcode": "41000",
    "districtCode": "4101",
    "districtNameTh": "เมืองอุดรธานี",
    "districtNameEn": "Mueang Udon Thani",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410121",
    "subDistrictNameTh": "หนองไผ่",
    "subDistrictNameEn": "Nong Phai",
    "postcode": "41330",
    "districtCode": "4101",
    "districtNameTh": "เมืองอุดรธานี",
    "districtNameEn": "Mueang Udon Thani",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410201",
    "subDistrictNameTh": "กุดจับ",
    "subDistrictNameEn": "Kut Chap",
    "postcode": "41250",
    "districtCode": "4102",
    "districtNameTh": "กุดจับ",
    "districtNameEn": "Kut Chap",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410202",
    "subDistrictNameTh": "ปะโค",
    "subDistrictNameEn": "Pa Kho",
    "postcode": "41250",
    "districtCode": "4102",
    "districtNameTh": "กุดจับ",
    "districtNameEn": "Kut Chap",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410203",
    "subDistrictNameTh": "ขอนยูง",
    "subDistrictNameEn": "Khon Yung",
    "postcode": "41250",
    "districtCode": "4102",
    "districtNameTh": "กุดจับ",
    "districtNameEn": "Kut Chap",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410204",
    "subDistrictNameTh": "เชียงเพ็ง",
    "subDistrictNameEn": "Chiang Pheng",
    "postcode": "41250",
    "districtCode": "4102",
    "districtNameTh": "กุดจับ",
    "districtNameEn": "Kut Chap",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410205",
    "subDistrictNameTh": "สร้างก่อ",
    "subDistrictNameEn": "Sang Ko",
    "postcode": "41250",
    "districtCode": "4102",
    "districtNameTh": "กุดจับ",
    "districtNameEn": "Kut Chap",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410206",
    "subDistrictNameTh": "เมืองเพีย",
    "subDistrictNameEn": "Mueang Phia",
    "postcode": "41250",
    "districtCode": "4102",
    "districtNameTh": "กุดจับ",
    "districtNameEn": "Kut Chap",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410207",
    "subDistrictNameTh": "ตาลเลียน",
    "subDistrictNameEn": "Tan Lian",
    "postcode": "41250",
    "districtCode": "4102",
    "districtNameTh": "กุดจับ",
    "districtNameEn": "Kut Chap",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410301",
    "subDistrictNameTh": "หมากหญ้า",
    "subDistrictNameEn": "Mak Ya",
    "postcode": "41360",
    "districtCode": "4103",
    "districtNameTh": "หนองวัวซอ",
    "districtNameEn": "Nong Wua So",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410302",
    "subDistrictNameTh": "หนองอ้อ",
    "subDistrictNameEn": "Nong O",
    "postcode": "41220",
    "districtCode": "4103",
    "districtNameTh": "หนองวัวซอ",
    "districtNameEn": "Nong Wua So",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410303",
    "subDistrictNameTh": "อูบมุง",
    "subDistrictNameEn": "Up Mung",
    "postcode": "41220",
    "districtCode": "4103",
    "districtNameTh": "หนองวัวซอ",
    "districtNameEn": "Nong Wua So",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410304",
    "subDistrictNameTh": "กุดหมากไฟ",
    "subDistrictNameEn": "Kut Mak Fai",
    "postcode": "41220",
    "districtCode": "4103",
    "districtNameTh": "หนองวัวซอ",
    "districtNameEn": "Nong Wua So",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410305",
    "subDistrictNameTh": "น้ำพ่น",
    "subDistrictNameEn": "Nam Phon",
    "postcode": "41360",
    "districtCode": "4103",
    "districtNameTh": "หนองวัวซอ",
    "districtNameEn": "Nong Wua So",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410306",
    "subDistrictNameTh": "หนองบัวบาน",
    "subDistrictNameEn": "Nong Bua Ban",
    "postcode": "41360",
    "districtCode": "4103",
    "districtNameTh": "หนองวัวซอ",
    "districtNameEn": "Nong Wua So",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410307",
    "subDistrictNameTh": "โนนหวาย",
    "subDistrictNameEn": "Non Wai",
    "postcode": "41220",
    "districtCode": "4103",
    "districtNameTh": "หนองวัวซอ",
    "districtNameEn": "Nong Wua So",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410308",
    "subDistrictNameTh": "หนองวัวซอ",
    "subDistrictNameEn": "Nong Wua So",
    "postcode": "41360",
    "districtCode": "4103",
    "districtNameTh": "หนองวัวซอ",
    "districtNameEn": "Nong Wua So",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410401",
    "subDistrictNameTh": "ตูมใต้",
    "subDistrictNameEn": "Tum Tai",
    "postcode": "41110",
    "districtCode": "4104",
    "districtNameTh": "กุมภวาปี",
    "districtNameEn": "Kumphawapi",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410402",
    "subDistrictNameTh": "พันดอน",
    "subDistrictNameEn": "Phan Don",
    "postcode": "41370",
    "districtCode": "4104",
    "districtNameTh": "กุมภวาปี",
    "districtNameEn": "Kumphawapi",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410403",
    "subDistrictNameTh": "เวียงคำ",
    "subDistrictNameEn": "Wiang Kham",
    "postcode": "41110",
    "districtCode": "4104",
    "districtNameTh": "กุมภวาปี",
    "districtNameEn": "Kumphawapi",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410404",
    "subDistrictNameTh": "แชแล",
    "subDistrictNameEn": "Chaelae",
    "postcode": "41110",
    "districtCode": "4104",
    "districtNameTh": "กุมภวาปี",
    "districtNameEn": "Kumphawapi",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410406",
    "subDistrictNameTh": "เชียงแหว",
    "subDistrictNameEn": "Chiang Wae",
    "postcode": "41110",
    "districtCode": "4104",
    "districtNameTh": "กุมภวาปี",
    "districtNameEn": "Kumphawapi",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410407",
    "subDistrictNameTh": "ห้วยเกิ้ง",
    "subDistrictNameEn": "Huai Koeng",
    "postcode": "41110",
    "districtCode": "4104",
    "districtNameTh": "กุมภวาปี",
    "districtNameEn": "Kumphawapi",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410409",
    "subDistrictNameTh": "เสอเพลอ",
    "subDistrictNameEn": "Soephloe",
    "postcode": "41370",
    "districtCode": "4104",
    "districtNameTh": "กุมภวาปี",
    "districtNameEn": "Kumphawapi",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410410",
    "subDistrictNameTh": "สีออ",
    "subDistrictNameEn": "Si O",
    "postcode": "41110",
    "districtCode": "4104",
    "districtNameTh": "กุมภวาปี",
    "districtNameEn": "Kumphawapi",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410411",
    "subDistrictNameTh": "ปะโค",
    "subDistrictNameEn": "Pakho",
    "postcode": "41370",
    "districtCode": "4104",
    "districtNameTh": "กุมภวาปี",
    "districtNameEn": "Kumphawapi",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410413",
    "subDistrictNameTh": "ผาสุก",
    "subDistrictNameEn": "Pha Suk",
    "postcode": "41370",
    "districtCode": "4104",
    "districtNameTh": "กุมภวาปี",
    "districtNameEn": "Kumphawapi",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410414",
    "subDistrictNameTh": "ท่าลี่",
    "subDistrictNameEn": "Tha Li",
    "postcode": "41110",
    "districtCode": "4104",
    "districtNameTh": "กุมภวาปี",
    "districtNameEn": "Kumphawapi",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410415",
    "subDistrictNameTh": "กุมภวาปี",
    "subDistrictNameEn": "Kumphawapi",
    "postcode": "41110",
    "districtCode": "4104",
    "districtNameTh": "กุมภวาปี",
    "districtNameEn": "Kumphawapi",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410416",
    "subDistrictNameTh": "หนองหว้า",
    "subDistrictNameEn": "Nong Wa",
    "postcode": "41110",
    "districtCode": "4104",
    "districtNameTh": "กุมภวาปี",
    "districtNameEn": "Kumphawapi",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410501",
    "subDistrictNameTh": "โนนสะอาด",
    "subDistrictNameEn": "Non Sa-at",
    "postcode": "41240",
    "districtCode": "4105",
    "districtNameTh": "โนนสะอาด",
    "districtNameEn": "Non Sa-at",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410502",
    "subDistrictNameTh": "บุ่งแก้ว",
    "subDistrictNameEn": "Bung Kaeo",
    "postcode": "41240",
    "districtCode": "4105",
    "districtNameTh": "โนนสะอาด",
    "districtNameEn": "Non Sa-at",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410503",
    "subDistrictNameTh": "โพธิ์ศรีสำราญ",
    "subDistrictNameEn": "Pho Si Samran",
    "postcode": "41240",
    "districtCode": "4105",
    "districtNameTh": "โนนสะอาด",
    "districtNameEn": "Non Sa-at",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410504",
    "subDistrictNameTh": "ทมนางาม",
    "subDistrictNameEn": "Thom Na Ngam",
    "postcode": "41240",
    "districtCode": "4105",
    "districtNameTh": "โนนสะอาด",
    "districtNameEn": "Non Sa-at",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410505",
    "subDistrictNameTh": "หนองกุงศรี",
    "subDistrictNameEn": "Nong Kung Si",
    "postcode": "41240",
    "districtCode": "4105",
    "districtNameTh": "โนนสะอาด",
    "districtNameEn": "Non Sa-at",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410506",
    "subDistrictNameTh": "โคกกลาง",
    "subDistrictNameEn": "Khok Klang",
    "postcode": "41240",
    "districtCode": "4105",
    "districtNameTh": "โนนสะอาด",
    "districtNameEn": "Non Sa-at",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410601",
    "subDistrictNameTh": "หนองหาน",
    "subDistrictNameEn": "Nong Han",
    "postcode": "41130",
    "districtCode": "4106",
    "districtNameTh": "หนองหาน",
    "districtNameEn": "Nong Han",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410602",
    "subDistrictNameTh": "หนองเม็ก",
    "subDistrictNameEn": "Nong Mek",
    "postcode": "41130",
    "districtCode": "4106",
    "districtNameTh": "หนองหาน",
    "districtNameEn": "Nong Han",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410605",
    "subDistrictNameTh": "พังงู",
    "subDistrictNameEn": "Phang Ngu",
    "postcode": "41130",
    "districtCode": "4106",
    "districtNameTh": "หนองหาน",
    "districtNameEn": "Nong Han",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410606",
    "subDistrictNameTh": "สะแบง",
    "subDistrictNameEn": "Sabaeng",
    "postcode": "41130",
    "districtCode": "4106",
    "districtNameTh": "หนองหาน",
    "districtNameEn": "Nong Han",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410607",
    "subDistrictNameTh": "สร้อยพร้าว",
    "subDistrictNameEn": "Soi Phrao",
    "postcode": "41130",
    "districtCode": "4106",
    "districtNameTh": "หนองหาน",
    "districtNameEn": "Nong Han",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410609",
    "subDistrictNameTh": "บ้านเชียง",
    "subDistrictNameEn": "Ban Chiang",
    "postcode": "41320",
    "districtCode": "4106",
    "districtNameTh": "หนองหาน",
    "districtNameEn": "Nong Han",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410610",
    "subDistrictNameTh": "บ้านยา",
    "subDistrictNameEn": "Ban Ya",
    "postcode": "41320",
    "districtCode": "4106",
    "districtNameTh": "หนองหาน",
    "districtNameEn": "Nong Han",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410611",
    "subDistrictNameTh": "โพนงาม",
    "subDistrictNameEn": "Phon Ngam",
    "postcode": "41130",
    "districtCode": "4106",
    "districtNameTh": "หนองหาน",
    "districtNameEn": "Nong Han",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410612",
    "subDistrictNameTh": "ผักตบ",
    "subDistrictNameEn": "Phak Top",
    "postcode": "41130",
    "districtCode": "4106",
    "districtNameTh": "หนองหาน",
    "districtNameEn": "Nong Han",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410614",
    "subDistrictNameTh": "หนองไผ่",
    "subDistrictNameEn": "Nong Phai",
    "postcode": "41130",
    "districtCode": "4106",
    "districtNameTh": "หนองหาน",
    "districtNameEn": "Nong Han",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410617",
    "subDistrictNameTh": "ดอนหายโศก",
    "subDistrictNameEn": "Don Hai Sok",
    "postcode": "41130",
    "districtCode": "4106",
    "districtNameTh": "หนองหาน",
    "districtNameEn": "Nong Han",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410618",
    "subDistrictNameTh": "หนองสระปลา",
    "subDistrictNameEn": "Nong Sa Pla",
    "postcode": "41320",
    "districtCode": "4106",
    "districtNameTh": "หนองหาน",
    "districtNameEn": "Nong Han",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410701",
    "subDistrictNameTh": "ทุ่งฝน",
    "subDistrictNameEn": "Thung Fon",
    "postcode": "41310",
    "districtCode": "4107",
    "districtNameTh": "ทุ่งฝน",
    "districtNameEn": "Thung Fon",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410702",
    "subDistrictNameTh": "ทุ่งใหญ่",
    "subDistrictNameEn": "Thung Yai",
    "postcode": "41310",
    "districtCode": "4107",
    "districtNameTh": "ทุ่งฝน",
    "districtNameEn": "Thung Fon",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410703",
    "subDistrictNameTh": "นาชุมแสง",
    "subDistrictNameEn": "Na Chum Saeng",
    "postcode": "41310",
    "districtCode": "4107",
    "districtNameTh": "ทุ่งฝน",
    "districtNameEn": "Thung Fon",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410704",
    "subDistrictNameTh": "นาทม",
    "subDistrictNameEn": "Na Thom",
    "postcode": "41310",
    "districtCode": "4107",
    "districtNameTh": "ทุ่งฝน",
    "districtNameEn": "Thung Fon",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410801",
    "subDistrictNameTh": "ไชยวาน",
    "subDistrictNameEn": "Chai Wan",
    "postcode": "41290",
    "districtCode": "4108",
    "districtNameTh": "ไชยวาน",
    "districtNameEn": "Chai Wan",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410802",
    "subDistrictNameTh": "หนองหลัก",
    "subDistrictNameEn": "Nong Lak",
    "postcode": "41290",
    "districtCode": "4108",
    "districtNameTh": "ไชยวาน",
    "districtNameEn": "Chai Wan",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410803",
    "subDistrictNameTh": "คำเลาะ",
    "subDistrictNameEn": "Kham Lo",
    "postcode": "41290",
    "districtCode": "4108",
    "districtNameTh": "ไชยวาน",
    "districtNameEn": "Chai Wan",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410804",
    "subDistrictNameTh": "โพนสูง",
    "subDistrictNameEn": "Phon Sung",
    "postcode": "41290",
    "districtCode": "4108",
    "districtNameTh": "ไชยวาน",
    "districtNameEn": "Chai Wan",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410901",
    "subDistrictNameTh": "ศรีธาตุ",
    "subDistrictNameEn": "Si That",
    "postcode": "41230",
    "districtCode": "4109",
    "districtNameTh": "ศรีธาตุ",
    "districtNameEn": "Si That",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410902",
    "subDistrictNameTh": "จำปี",
    "subDistrictNameEn": "Champi",
    "postcode": "41230",
    "districtCode": "4109",
    "districtNameTh": "ศรีธาตุ",
    "districtNameEn": "Si That",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410903",
    "subDistrictNameTh": "บ้านโปร่ง",
    "subDistrictNameEn": "Ban Prong",
    "postcode": "41230",
    "districtCode": "4109",
    "districtNameTh": "ศรีธาตุ",
    "districtNameEn": "Si That",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410904",
    "subDistrictNameTh": "หัวนาคำ",
    "subDistrictNameEn": "Hua Na Kham",
    "postcode": "41230",
    "districtCode": "4109",
    "districtNameTh": "ศรีธาตุ",
    "districtNameEn": "Si That",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410905",
    "subDistrictNameTh": "หนองนกเขียน",
    "subDistrictNameEn": "Nong Nok Khian",
    "postcode": "41230",
    "districtCode": "4109",
    "districtNameTh": "ศรีธาตุ",
    "districtNameEn": "Si That",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410906",
    "subDistrictNameTh": "นายูง",
    "subDistrictNameEn": "Na Yung",
    "postcode": "41230",
    "districtCode": "4109",
    "districtNameTh": "ศรีธาตุ",
    "districtNameEn": "Si That",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "410907",
    "subDistrictNameTh": "ตาดทอง",
    "subDistrictNameEn": "Tat Thong",
    "postcode": "41230",
    "districtCode": "4109",
    "districtNameTh": "ศรีธาตุ",
    "districtNameEn": "Si That",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411001",
    "subDistrictNameTh": "หนองกุงทับม้า",
    "subDistrictNameEn": "Nong Kung Thap Ma",
    "postcode": "41280",
    "districtCode": "4110",
    "districtNameTh": "วังสามหมอ",
    "districtNameEn": "Wang Sam Mo",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411002",
    "subDistrictNameTh": "หนองหญ้าไซ",
    "subDistrictNameEn": "Nong Ya Sai",
    "postcode": "41280",
    "districtCode": "4110",
    "districtNameTh": "วังสามหมอ",
    "districtNameEn": "Wang Sam Mo",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411003",
    "subDistrictNameTh": "บะยาว",
    "subDistrictNameEn": "Ba Yao",
    "postcode": "41280",
    "districtCode": "4110",
    "districtNameTh": "วังสามหมอ",
    "districtNameEn": "Wang Sam Mo",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411004",
    "subDistrictNameTh": "ผาสุก",
    "subDistrictNameEn": "Phasuk",
    "postcode": "41280",
    "districtCode": "4110",
    "districtNameTh": "วังสามหมอ",
    "districtNameEn": "Wang Sam Mo",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411005",
    "subDistrictNameTh": "คำโคกสูง",
    "subDistrictNameEn": "Kham Khok Sung",
    "postcode": "41280",
    "districtCode": "4110",
    "districtNameTh": "วังสามหมอ",
    "districtNameEn": "Wang Sam Mo",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411006",
    "subDistrictNameTh": "วังสามหมอ",
    "subDistrictNameEn": "Wang Sam Mo",
    "postcode": "41280",
    "districtCode": "4110",
    "districtNameTh": "วังสามหมอ",
    "districtNameEn": "Wang Sam Mo",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411101",
    "subDistrictNameTh": "ศรีสุทโธ",
    "subDistrictNameEn": "Si Suttho",
    "postcode": "41190",
    "districtCode": "4111",
    "districtNameTh": "บ้านดุง",
    "districtNameEn": "Ban Dung",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411102",
    "subDistrictNameTh": "บ้านดุง",
    "subDistrictNameEn": "Ban Dung",
    "postcode": "41190",
    "districtCode": "4111",
    "districtNameTh": "บ้านดุง",
    "districtNameEn": "Ban Dung",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411103",
    "subDistrictNameTh": "ดงเย็น",
    "subDistrictNameEn": "Dong Yen",
    "postcode": "41190",
    "districtCode": "4111",
    "districtNameTh": "บ้านดุง",
    "districtNameEn": "Ban Dung",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411104",
    "subDistrictNameTh": "โพนสูง",
    "subDistrictNameEn": "Phon Sung",
    "postcode": "41190",
    "districtCode": "4111",
    "districtNameTh": "บ้านดุง",
    "districtNameEn": "Ban Dung",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411105",
    "subDistrictNameTh": "อ้อมกอ",
    "subDistrictNameEn": "Om Ko",
    "postcode": "41190",
    "districtCode": "4111",
    "districtNameTh": "บ้านดุง",
    "districtNameEn": "Ban Dung",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411106",
    "subDistrictNameTh": "บ้านจันทน์",
    "subDistrictNameEn": "Ban Chan",
    "postcode": "41190",
    "districtCode": "4111",
    "districtNameTh": "บ้านดุง",
    "districtNameEn": "Ban Dung",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411107",
    "subDistrictNameTh": "บ้านชัย",
    "subDistrictNameEn": "Ban Chai",
    "postcode": "41190",
    "districtCode": "4111",
    "districtNameTh": "บ้านดุง",
    "districtNameEn": "Ban Dung",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411108",
    "subDistrictNameTh": "นาไหม",
    "subDistrictNameEn": "Na Mai",
    "postcode": "41190",
    "districtCode": "4111",
    "districtNameTh": "บ้านดุง",
    "districtNameEn": "Ban Dung",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411109",
    "subDistrictNameTh": "ถ่อนนาลับ",
    "subDistrictNameEn": "Thon Na Lap",
    "postcode": "41190",
    "districtCode": "4111",
    "districtNameTh": "บ้านดุง",
    "districtNameEn": "Ban Dung",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411110",
    "subDistrictNameTh": "วังทอง",
    "subDistrictNameEn": "Wang Thong",
    "postcode": "41190",
    "districtCode": "4111",
    "districtNameTh": "บ้านดุง",
    "districtNameEn": "Ban Dung",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411111",
    "subDistrictNameTh": "บ้านม่วง",
    "subDistrictNameEn": "Ban Muang",
    "postcode": "41190",
    "districtCode": "4111",
    "districtNameTh": "บ้านดุง",
    "districtNameEn": "Ban Dung",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411112",
    "subDistrictNameTh": "บ้านตาด",
    "subDistrictNameEn": "Ban Tat",
    "postcode": "41190",
    "districtCode": "4111",
    "districtNameTh": "บ้านดุง",
    "districtNameEn": "Ban Dung",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411113",
    "subDistrictNameTh": "นาคำ",
    "subDistrictNameEn": "Na Kham",
    "postcode": "41190",
    "districtCode": "4111",
    "districtNameTh": "บ้านดุง",
    "districtNameEn": "Ban Dung",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411701",
    "subDistrictNameTh": "บ้านผือ",
    "subDistrictNameEn": "Ban Phue",
    "postcode": "41160",
    "districtCode": "4117",
    "districtNameTh": "บ้านผือ",
    "districtNameEn": "Ban Phue",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411702",
    "subDistrictNameTh": "หายโศก",
    "subDistrictNameEn": "Hai Sok",
    "postcode": "41160",
    "districtCode": "4117",
    "districtNameTh": "บ้านผือ",
    "districtNameEn": "Ban Phue",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411703",
    "subDistrictNameTh": "เขือน้ำ",
    "subDistrictNameEn": "Khuea Nam",
    "postcode": "41160",
    "districtCode": "4117",
    "districtNameTh": "บ้านผือ",
    "districtNameEn": "Ban Phue",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411704",
    "subDistrictNameTh": "คำบง",
    "subDistrictNameEn": "Kham Bong",
    "postcode": "41160",
    "districtCode": "4117",
    "districtNameTh": "บ้านผือ",
    "districtNameEn": "Ban Phue",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411705",
    "subDistrictNameTh": "โนนทอง",
    "subDistrictNameEn": "Non Thong",
    "postcode": "41160",
    "districtCode": "4117",
    "districtNameTh": "บ้านผือ",
    "districtNameEn": "Ban Phue",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411706",
    "subDistrictNameTh": "ข้าวสาร",
    "subDistrictNameEn": "Khao San",
    "postcode": "41160",
    "districtCode": "4117",
    "districtNameTh": "บ้านผือ",
    "districtNameEn": "Ban Phue",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411707",
    "subDistrictNameTh": "จำปาโมง",
    "subDistrictNameEn": "Champa Mong",
    "postcode": "41160",
    "districtCode": "4117",
    "districtNameTh": "บ้านผือ",
    "districtNameEn": "Ban Phue",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411708",
    "subDistrictNameTh": "กลางใหญ่",
    "subDistrictNameEn": "Klang Yai",
    "postcode": "41160",
    "districtCode": "4117",
    "districtNameTh": "บ้านผือ",
    "districtNameEn": "Ban Phue",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411709",
    "subDistrictNameTh": "เมืองพาน",
    "subDistrictNameEn": "Mueang Phan",
    "postcode": "41160",
    "districtCode": "4117",
    "districtNameTh": "บ้านผือ",
    "districtNameEn": "Ban Phue",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411710",
    "subDistrictNameTh": "คำด้วง",
    "subDistrictNameEn": "Kham Duang",
    "postcode": "41160",
    "districtCode": "4117",
    "districtNameTh": "บ้านผือ",
    "districtNameEn": "Ban Phue",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411711",
    "subDistrictNameTh": "หนองหัวคู",
    "subDistrictNameEn": "Nong Hua Khu",
    "postcode": "41160",
    "districtCode": "4117",
    "districtNameTh": "บ้านผือ",
    "districtNameEn": "Ban Phue",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411712",
    "subDistrictNameTh": "บ้านค้อ",
    "subDistrictNameEn": "Ban Kho",
    "postcode": "41160",
    "districtCode": "4117",
    "districtNameTh": "บ้านผือ",
    "districtNameEn": "Ban Phue",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411713",
    "subDistrictNameTh": "หนองแวง",
    "subDistrictNameEn": "Nong Waeng",
    "postcode": "41160",
    "districtCode": "4117",
    "districtNameTh": "บ้านผือ",
    "districtNameEn": "Ban Phue",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411801",
    "subDistrictNameTh": "นางัว",
    "subDistrictNameEn": "Na Ngua",
    "postcode": "41210",
    "districtCode": "4118",
    "districtNameTh": "น้ำโสม",
    "districtNameEn": "Nam Som",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411802",
    "subDistrictNameTh": "น้ำโสม",
    "subDistrictNameEn": "Nam Som",
    "postcode": "41210",
    "districtCode": "4118",
    "districtNameTh": "น้ำโสม",
    "districtNameEn": "Nam Som",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411805",
    "subDistrictNameTh": "หนองแวง",
    "subDistrictNameEn": "Nong Waeng",
    "postcode": "41210",
    "districtCode": "4118",
    "districtNameTh": "น้ำโสม",
    "districtNameEn": "Nam Som",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411806",
    "subDistrictNameTh": "บ้านหยวก",
    "subDistrictNameEn": "Ban Yuak",
    "postcode": "41210",
    "districtCode": "4118",
    "districtNameTh": "น้ำโสม",
    "districtNameEn": "Nam Som",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411807",
    "subDistrictNameTh": "โสมเยี่ยม",
    "subDistrictNameEn": "Som Yiam",
    "postcode": "41210",
    "districtCode": "4118",
    "districtNameTh": "น้ำโสม",
    "districtNameEn": "Nam Som",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411810",
    "subDistrictNameTh": "ศรีสำราญ",
    "subDistrictNameEn": "Si Samran",
    "postcode": "41210",
    "districtCode": "4118",
    "districtNameTh": "น้ำโสม",
    "districtNameEn": "Nam Som",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411812",
    "subDistrictNameTh": "สามัคคี",
    "subDistrictNameEn": "Samakkhi",
    "postcode": "41210",
    "districtCode": "4118",
    "districtNameTh": "น้ำโสม",
    "districtNameEn": "Nam Som",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411901",
    "subDistrictNameTh": "เพ็ญ",
    "subDistrictNameEn": "Phen",
    "postcode": "41150",
    "districtCode": "4119",
    "districtNameTh": "เพ็ญ",
    "districtNameEn": "Phen",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411902",
    "subDistrictNameTh": "บ้านธาตุ",
    "subDistrictNameEn": "Ban That",
    "postcode": "41150",
    "districtCode": "4119",
    "districtNameTh": "เพ็ญ",
    "districtNameEn": "Phen",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411903",
    "subDistrictNameTh": "นาพู่",
    "subDistrictNameEn": "Na Phu",
    "postcode": "41150",
    "districtCode": "4119",
    "districtNameTh": "เพ็ญ",
    "districtNameEn": "Phen",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411904",
    "subDistrictNameTh": "เชียงหวาง",
    "subDistrictNameEn": "Chiang Wang",
    "postcode": "41150",
    "districtCode": "4119",
    "districtNameTh": "เพ็ญ",
    "districtNameEn": "Phen",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411905",
    "subDistrictNameTh": "สุมเส้า",
    "subDistrictNameEn": "Sum Sao",
    "postcode": "41150",
    "districtCode": "4119",
    "districtNameTh": "เพ็ญ",
    "districtNameEn": "Phen",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411906",
    "subDistrictNameTh": "นาบัว",
    "subDistrictNameEn": "Na Bua",
    "postcode": "41150",
    "districtCode": "4119",
    "districtNameTh": "เพ็ญ",
    "districtNameEn": "Phen",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411907",
    "subDistrictNameTh": "บ้านเหล่า",
    "subDistrictNameEn": "Ban Lao",
    "postcode": "41150",
    "districtCode": "4119",
    "districtNameTh": "เพ็ญ",
    "districtNameEn": "Phen",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411908",
    "subDistrictNameTh": "จอมศรี",
    "subDistrictNameEn": "Chom Si",
    "postcode": "41150",
    "districtCode": "4119",
    "districtNameTh": "เพ็ญ",
    "districtNameEn": "Phen",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411909",
    "subDistrictNameTh": "เตาไห",
    "subDistrictNameEn": "Tao Hai",
    "postcode": "41150",
    "districtCode": "4119",
    "districtNameTh": "เพ็ญ",
    "districtNameEn": "Phen",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411910",
    "subDistrictNameTh": "โคกกลาง",
    "subDistrictNameEn": "Khok Klang",
    "postcode": "41150",
    "districtCode": "4119",
    "districtNameTh": "เพ็ญ",
    "districtNameEn": "Phen",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "411911",
    "subDistrictNameTh": "สร้างแป้น",
    "subDistrictNameEn": "Sang Paen",
    "postcode": "41150",
    "districtCode": "4119",
    "districtNameTh": "เพ็ญ",
    "districtNameEn": "Phen",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "412001",
    "subDistrictNameTh": "สร้างคอม",
    "subDistrictNameEn": "Sang Khom",
    "postcode": "41260",
    "districtCode": "4120",
    "districtNameTh": "สร้างคอม",
    "districtNameEn": "Sang Khom",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "412002",
    "subDistrictNameTh": "เชียงดา",
    "subDistrictNameEn": "Chiang Da",
    "postcode": "41260",
    "districtCode": "4120",
    "districtNameTh": "สร้างคอม",
    "districtNameEn": "Sang Khom",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "412003",
    "subDistrictNameTh": "บ้านยวด",
    "subDistrictNameEn": "Ban Yuat",
    "postcode": "41260",
    "districtCode": "4120",
    "districtNameTh": "สร้างคอม",
    "districtNameEn": "Sang Khom",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "412004",
    "subDistrictNameTh": "บ้านโคก",
    "subDistrictNameEn": "Ban Khok",
    "postcode": "41260",
    "districtCode": "4120",
    "districtNameTh": "สร้างคอม",
    "districtNameEn": "Sang Khom",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "412005",
    "subDistrictNameTh": "นาสะอาด",
    "subDistrictNameEn": "Na Sa-at",
    "postcode": "41260",
    "districtCode": "4120",
    "districtNameTh": "สร้างคอม",
    "districtNameEn": "Sang Khom",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "412006",
    "subDistrictNameTh": "บ้านหินโงม",
    "subDistrictNameEn": "Bang Hin Ngom",
    "postcode": "41260",
    "districtCode": "4120",
    "districtNameTh": "สร้างคอม",
    "districtNameEn": "Sang Khom",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "412101",
    "subDistrictNameTh": "หนองแสง",
    "subDistrictNameEn": "Nong Saeng",
    "postcode": "41340",
    "districtCode": "4121",
    "districtNameTh": "หนองแสง",
    "districtNameEn": "Nong Saeng",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "412102",
    "subDistrictNameTh": "แสงสว่าง",
    "subDistrictNameEn": "Saeng Sawang",
    "postcode": "41340",
    "districtCode": "4121",
    "districtNameTh": "หนองแสง",
    "districtNameEn": "Nong Saeng",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "412103",
    "subDistrictNameTh": "นาดี",
    "subDistrictNameEn": "Na Di",
    "postcode": "41340",
    "districtCode": "4121",
    "districtNameTh": "หนองแสง",
    "districtNameEn": "Nong Saeng",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "412104",
    "subDistrictNameTh": "ทับกุง",
    "subDistrictNameEn": "Thap Kung",
    "postcode": "41340",
    "districtCode": "4121",
    "districtNameTh": "หนองแสง",
    "districtNameEn": "Nong Saeng",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "412201",
    "subDistrictNameTh": "นายูง",
    "subDistrictNameEn": "Na Yung",
    "postcode": "41380",
    "districtCode": "4122",
    "districtNameTh": "นายูง",
    "districtNameEn": "Na Yung",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "412202",
    "subDistrictNameTh": "บ้านก้อง",
    "subDistrictNameEn": "Ban Kong",
    "postcode": "41380",
    "districtCode": "4122",
    "districtNameTh": "นายูง",
    "districtNameEn": "Na Yung",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "412203",
    "subDistrictNameTh": "นาแค",
    "subDistrictNameEn": "Na Khae",
    "postcode": "41380",
    "districtCode": "4122",
    "districtNameTh": "นายูง",
    "districtNameEn": "Na Yung",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "412204",
    "subDistrictNameTh": "โนนทอง",
    "subDistrictNameEn": "Non Thong",
    "postcode": "41380",
    "districtCode": "4122",
    "districtNameTh": "นายูง",
    "districtNameEn": "Na Yung",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "412301",
    "subDistrictNameTh": "บ้านแดง",
    "subDistrictNameEn": "Ban Daeng",
    "postcode": "41130",
    "districtCode": "4123",
    "districtNameTh": "พิบูลย์รักษ์",
    "districtNameEn": "Phibun Rak",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "412302",
    "subDistrictNameTh": "นาทราย",
    "subDistrictNameEn": "Na Sai",
    "postcode": "41130",
    "districtCode": "4123",
    "districtNameTh": "พิบูลย์รักษ์",
    "districtNameEn": "Phibun Rak",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "412303",
    "subDistrictNameTh": "ดอนกลอย",
    "subDistrictNameEn": "Don Kloi",
    "postcode": "41130",
    "districtCode": "4123",
    "districtNameTh": "พิบูลย์รักษ์",
    "districtNameEn": "Phibun Rak",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "412401",
    "subDistrictNameTh": "บ้านจีต",
    "subDistrictNameEn": "Ban Chit",
    "postcode": "41130",
    "districtCode": "4124",
    "districtNameTh": "กู่แก้ว",
    "districtNameEn": "Ku Kaeo",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "412402",
    "subDistrictNameTh": "โนนทองอินทร์",
    "subDistrictNameEn": "Non Thong In",
    "postcode": "41130",
    "districtCode": "4124",
    "districtNameTh": "กู่แก้ว",
    "districtNameEn": "Ku Kaeo",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "412403",
    "subDistrictNameTh": "ค้อใหญ่",
    "subDistrictNameEn": "Kho Yai",
    "postcode": "41130",
    "districtCode": "4124",
    "districtNameTh": "กู่แก้ว",
    "districtNameEn": "Ku Kaeo",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "412404",
    "subDistrictNameTh": "คอนสาย",
    "subDistrictNameEn": "Khon Sai",
    "postcode": "41130",
    "districtCode": "4124",
    "districtNameTh": "กู่แก้ว",
    "districtNameEn": "Ku Kaeo",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "412501",
    "subDistrictNameTh": "นาม่วง",
    "subDistrictNameEn": "Na Muang",
    "postcode": "41110",
    "districtCode": "4125",
    "districtNameTh": "ประจักษ์ศิลปาคม",
    "districtNameEn": "Prachak Sinlapakhom",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "412502",
    "subDistrictNameTh": "ห้วยสามพาด",
    "subDistrictNameEn": "Huai Sam Phat",
    "postcode": "41110",
    "districtCode": "4125",
    "districtNameTh": "ประจักษ์ศิลปาคม",
    "districtNameEn": "Prachak Sinlapakhom",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "412503",
    "subDistrictNameTh": "อุ่มจาน",
    "subDistrictNameEn": "Um Chan",
    "postcode": "41110",
    "districtCode": "4125",
    "districtNameTh": "ประจักษ์ศิลปาคม",
    "districtNameEn": "Prachak Sinlapakhom",
    "provinceCode": "41",
    "provinceNameTh": "อุดรธานี",
    "provinceNameEn": "Udon Thani"
},
{
    "subDistrictCode": "420101",
    "subDistrictNameTh": "กุดป่อง",
    "subDistrictNameEn": "Kut Pong",
    "postcode": "42000",
    "districtCode": "4201",
    "districtNameTh": "เมืองเลย",
    "districtNameEn": "Mueang Loei",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420102",
    "subDistrictNameTh": "เมือง",
    "subDistrictNameEn": "Mueang",
    "postcode": "42000",
    "districtCode": "4201",
    "districtNameTh": "เมืองเลย",
    "districtNameEn": "Mueang Loei",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420103",
    "subDistrictNameTh": "นาอ้อ",
    "subDistrictNameEn": "Na O",
    "postcode": "42100",
    "districtCode": "4201",
    "districtNameTh": "เมืองเลย",
    "districtNameEn": "Mueang Loei",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420104",
    "subDistrictNameTh": "กกดู่",
    "subDistrictNameEn": "Kok Du",
    "postcode": "42000",
    "districtCode": "4201",
    "districtNameTh": "เมืองเลย",
    "districtNameEn": "Mueang Loei",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420105",
    "subDistrictNameTh": "น้ำหมาน",
    "subDistrictNameEn": "Nam Man",
    "postcode": "42000",
    "districtCode": "4201",
    "districtNameTh": "เมืองเลย",
    "districtNameEn": "Mueang Loei",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420106",
    "subDistrictNameTh": "เสี้ยว",
    "subDistrictNameEn": "Siao",
    "postcode": "42000",
    "districtCode": "4201",
    "districtNameTh": "เมืองเลย",
    "districtNameEn": "Mueang Loei",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420107",
    "subDistrictNameTh": "นาอาน",
    "subDistrictNameEn": "Na An",
    "postcode": "42000",
    "districtCode": "4201",
    "districtNameTh": "เมืองเลย",
    "districtNameEn": "Mueang Loei",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420108",
    "subDistrictNameTh": "นาโป่ง",
    "subDistrictNameEn": "Na Pong",
    "postcode": "42000",
    "districtCode": "4201",
    "districtNameTh": "เมืองเลย",
    "districtNameEn": "Mueang Loei",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420109",
    "subDistrictNameTh": "นาดินดำ",
    "subDistrictNameEn": "Na Din Dam",
    "postcode": "42000",
    "districtCode": "4201",
    "districtNameTh": "เมืองเลย",
    "districtNameEn": "Mueang Loei",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420110",
    "subDistrictNameTh": "น้ำสวย",
    "subDistrictNameEn": "Nam Suai",
    "postcode": "42000",
    "districtCode": "4201",
    "districtNameTh": "เมืองเลย",
    "districtNameEn": "Mueang Loei",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420111",
    "subDistrictNameTh": "ชัยพฤกษ์",
    "subDistrictNameEn": "Chaiyaphruek",
    "postcode": "42000",
    "districtCode": "4201",
    "districtNameTh": "เมืองเลย",
    "districtNameEn": "Mueang Loei",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420112",
    "subDistrictNameTh": "นาแขม",
    "subDistrictNameEn": "Na Khaem",
    "postcode": "42000",
    "districtCode": "4201",
    "districtNameTh": "เมืองเลย",
    "districtNameEn": "Mueang Loei",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420113",
    "subDistrictNameTh": "ศรีสองรัก",
    "subDistrictNameEn": "Si Song Rak",
    "postcode": "42100",
    "districtCode": "4201",
    "districtNameTh": "เมืองเลย",
    "districtNameEn": "Mueang Loei",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420114",
    "subDistrictNameTh": "กกทอง",
    "subDistrictNameEn": "Kok Thong",
    "postcode": "42000",
    "districtCode": "4201",
    "districtNameTh": "เมืองเลย",
    "districtNameEn": "Mueang Loei",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420201",
    "subDistrictNameTh": "นาด้วง",
    "subDistrictNameEn": "Na Duang",
    "postcode": "42210",
    "districtCode": "4202",
    "districtNameTh": "นาด้วง",
    "districtNameEn": "Na Duang",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420202",
    "subDistrictNameTh": "นาดอกคำ",
    "subDistrictNameEn": "Na Dok Kham",
    "postcode": "42210",
    "districtCode": "4202",
    "districtNameTh": "นาด้วง",
    "districtNameEn": "Na Duang",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420203",
    "subDistrictNameTh": "ท่าสะอาด",
    "subDistrictNameEn": "Tha Sa-at",
    "postcode": "42210",
    "districtCode": "4202",
    "districtNameTh": "นาด้วง",
    "districtNameEn": "Na Duang",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420204",
    "subDistrictNameTh": "ท่าสวรรค์",
    "subDistrictNameEn": "Tha Sawan",
    "postcode": "42210",
    "districtCode": "4202",
    "districtNameTh": "นาด้วง",
    "districtNameEn": "Na Duang",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420301",
    "subDistrictNameTh": "เชียงคาน",
    "subDistrictNameEn": "Chiang Khan",
    "postcode": "42110",
    "districtCode": "4203",
    "districtNameTh": "เชียงคาน",
    "districtNameEn": "Chiang Khan",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420302",
    "subDistrictNameTh": "ธาตุ",
    "subDistrictNameEn": "That",
    "postcode": "42110",
    "districtCode": "4203",
    "districtNameTh": "เชียงคาน",
    "districtNameEn": "Chiang Khan",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420303",
    "subDistrictNameTh": "นาซ่าว",
    "subDistrictNameEn": "Na Sao",
    "postcode": "42110",
    "districtCode": "4203",
    "districtNameTh": "เชียงคาน",
    "districtNameEn": "Chiang Khan",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420304",
    "subDistrictNameTh": "เขาแก้ว",
    "subDistrictNameEn": "Khao Kaeo",
    "postcode": "42110",
    "districtCode": "4203",
    "districtNameTh": "เชียงคาน",
    "districtNameEn": "Chiang Khan",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420305",
    "subDistrictNameTh": "ปากตม",
    "subDistrictNameEn": "Pak Tom",
    "postcode": "42110",
    "districtCode": "4203",
    "districtNameTh": "เชียงคาน",
    "districtNameEn": "Chiang Khan",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420306",
    "subDistrictNameTh": "บุฮม",
    "subDistrictNameEn": "Bu Hom",
    "postcode": "42110",
    "districtCode": "4203",
    "districtNameTh": "เชียงคาน",
    "districtNameEn": "Chiang Khan",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420307",
    "subDistrictNameTh": "จอมศรี",
    "subDistrictNameEn": "Chom Si",
    "postcode": "42110",
    "districtCode": "4203",
    "districtNameTh": "เชียงคาน",
    "districtNameEn": "Chiang Khan",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420308",
    "subDistrictNameTh": "หาดทรายขาว",
    "subDistrictNameEn": "Hat Sai Khao",
    "postcode": "42110",
    "districtCode": "4203",
    "districtNameTh": "เชียงคาน",
    "districtNameEn": "Chiang Khan",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420401",
    "subDistrictNameTh": "ปากชม",
    "subDistrictNameEn": "Pak Chom",
    "postcode": "42150",
    "districtCode": "4204",
    "districtNameTh": "ปากชม",
    "districtNameEn": "Pak Chom",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420402",
    "subDistrictNameTh": "เชียงกลม",
    "subDistrictNameEn": "Chiang Klom",
    "postcode": "42150",
    "districtCode": "4204",
    "districtNameTh": "ปากชม",
    "districtNameEn": "Pak Chom",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420403",
    "subDistrictNameTh": "หาดคัมภีร์",
    "subDistrictNameEn": "Hat Khamphi",
    "postcode": "42150",
    "districtCode": "4204",
    "districtNameTh": "ปากชม",
    "districtNameEn": "Pak Chom",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420404",
    "subDistrictNameTh": "ห้วยบ่อซืน",
    "subDistrictNameEn": "Huai Bo Suen",
    "postcode": "42150",
    "districtCode": "4204",
    "districtNameTh": "ปากชม",
    "districtNameEn": "Pak Chom",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420405",
    "subDistrictNameTh": "ห้วยพิชัย",
    "subDistrictNameEn": "Huai Phichai",
    "postcode": "42150",
    "districtCode": "4204",
    "districtNameTh": "ปากชม",
    "districtNameEn": "Pak Chom",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420406",
    "subDistrictNameTh": "ชมเจริญ",
    "subDistrictNameEn": "Chom Charoen",
    "postcode": "42150",
    "districtCode": "4204",
    "districtNameTh": "ปากชม",
    "districtNameEn": "Pak Chom",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420501",
    "subDistrictNameTh": "ด่านซ้าย",
    "subDistrictNameEn": "Dan Sai",
    "postcode": "42120",
    "districtCode": "4205",
    "districtNameTh": "ด่านซ้าย",
    "districtNameEn": "Dan Sai",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420502",
    "subDistrictNameTh": "ปากหมัน",
    "subDistrictNameEn": "Pak Man",
    "postcode": "42120",
    "districtCode": "4205",
    "districtNameTh": "ด่านซ้าย",
    "districtNameEn": "Dan Sai",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420503",
    "subDistrictNameTh": "นาดี",
    "subDistrictNameEn": "Na Di",
    "postcode": "42120",
    "districtCode": "4205",
    "districtNameTh": "ด่านซ้าย",
    "districtNameEn": "Dan Sai",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420504",
    "subDistrictNameTh": "โคกงาม",
    "subDistrictNameEn": "Khok Ngam",
    "postcode": "42120",
    "districtCode": "4205",
    "districtNameTh": "ด่านซ้าย",
    "districtNameEn": "Dan Sai",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420505",
    "subDistrictNameTh": "โพนสูง",
    "subDistrictNameEn": "Phon Sung",
    "postcode": "42120",
    "districtCode": "4205",
    "districtNameTh": "ด่านซ้าย",
    "districtNameEn": "Dan Sai",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420506",
    "subDistrictNameTh": "อิปุ่ม",
    "subDistrictNameEn": "Ipum",
    "postcode": "42120",
    "districtCode": "4205",
    "districtNameTh": "ด่านซ้าย",
    "districtNameEn": "Dan Sai",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420507",
    "subDistrictNameTh": "กกสะทอน",
    "subDistrictNameEn": "Kok Sathon",
    "postcode": "42120",
    "districtCode": "4205",
    "districtNameTh": "ด่านซ้าย",
    "districtNameEn": "Dan Sai",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420508",
    "subDistrictNameTh": "โป่ง",
    "subDistrictNameEn": "Pong",
    "postcode": "42120",
    "districtCode": "4205",
    "districtNameTh": "ด่านซ้าย",
    "districtNameEn": "Dan Sai",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420509",
    "subDistrictNameTh": "วังยาว",
    "subDistrictNameEn": "Wang Yao",
    "postcode": "42120",
    "districtCode": "4205",
    "districtNameTh": "ด่านซ้าย",
    "districtNameEn": "Dan Sai",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420510",
    "subDistrictNameTh": "นาหอ",
    "subDistrictNameEn": "Na Ho",
    "postcode": "42120",
    "districtCode": "4205",
    "districtNameTh": "ด่านซ้าย",
    "districtNameEn": "Dan Sai",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420601",
    "subDistrictNameTh": "นาแห้ว",
    "subDistrictNameEn": "Na Haeo",
    "postcode": "42170",
    "districtCode": "4206",
    "districtNameTh": "นาแห้ว",
    "districtNameEn": "Na Haeo",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420602",
    "subDistrictNameTh": "แสงภา",
    "subDistrictNameEn": "Saeng Pha",
    "postcode": "42170",
    "districtCode": "4206",
    "districtNameTh": "นาแห้ว",
    "districtNameEn": "Na Haeo",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420603",
    "subDistrictNameTh": "นาพึง",
    "subDistrictNameEn": "Na Phueng",
    "postcode": "42170",
    "districtCode": "4206",
    "districtNameTh": "นาแห้ว",
    "districtNameEn": "Na Haeo",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420604",
    "subDistrictNameTh": "นามาลา",
    "subDistrictNameEn": "Na Ma La",
    "postcode": "42170",
    "districtCode": "4206",
    "districtNameTh": "นาแห้ว",
    "districtNameEn": "Na Haeo",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420605",
    "subDistrictNameTh": "เหล่ากอหก",
    "subDistrictNameEn": "Lao Ko Hok",
    "postcode": "42170",
    "districtCode": "4206",
    "districtNameTh": "นาแห้ว",
    "districtNameEn": "Na Haeo",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420701",
    "subDistrictNameTh": "หนองบัว",
    "subDistrictNameEn": "Nong Bua",
    "postcode": "42160",
    "districtCode": "4207",
    "districtNameTh": "ภูเรือ",
    "districtNameEn": "Phu Ruea",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420702",
    "subDistrictNameTh": "ท่าศาลา",
    "subDistrictNameEn": "Tha Sala",
    "postcode": "42160",
    "districtCode": "4207",
    "districtNameTh": "ภูเรือ",
    "districtNameEn": "Phu Ruea",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420703",
    "subDistrictNameTh": "ร่องจิก",
    "subDistrictNameEn": "Rong Chik",
    "postcode": "42160",
    "districtCode": "4207",
    "districtNameTh": "ภูเรือ",
    "districtNameEn": "Phu Ruea",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420704",
    "subDistrictNameTh": "ปลาบ่า",
    "subDistrictNameEn": "Pla Ba",
    "postcode": "42160",
    "districtCode": "4207",
    "districtNameTh": "ภูเรือ",
    "districtNameEn": "Phu Ruea",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420705",
    "subDistrictNameTh": "ลาดค่าง",
    "subDistrictNameEn": "Lat Khang",
    "postcode": "42160",
    "districtCode": "4207",
    "districtNameTh": "ภูเรือ",
    "districtNameEn": "Phu Ruea",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420706",
    "subDistrictNameTh": "สานตม",
    "subDistrictNameEn": "San Tom",
    "postcode": "42160",
    "districtCode": "4207",
    "districtNameTh": "ภูเรือ",
    "districtNameEn": "Phu Ruea",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420801",
    "subDistrictNameTh": "ท่าลี่",
    "subDistrictNameEn": "Tha Li",
    "postcode": "42140",
    "districtCode": "4208",
    "districtNameTh": "ท่าลี่",
    "districtNameEn": "Tha Li",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420802",
    "subDistrictNameTh": "หนองผือ",
    "subDistrictNameEn": "Nong Phue",
    "postcode": "42140",
    "districtCode": "4208",
    "districtNameTh": "ท่าลี่",
    "districtNameEn": "Tha Li",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420803",
    "subDistrictNameTh": "อาฮี",
    "subDistrictNameEn": "Ahi",
    "postcode": "42140",
    "districtCode": "4208",
    "districtNameTh": "ท่าลี่",
    "districtNameEn": "Tha Li",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420804",
    "subDistrictNameTh": "น้ำแคม",
    "subDistrictNameEn": "Nam Khaem",
    "postcode": "42140",
    "districtCode": "4208",
    "districtNameTh": "ท่าลี่",
    "districtNameEn": "Tha Li",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420805",
    "subDistrictNameTh": "โคกใหญ่",
    "subDistrictNameEn": "Khok Yai",
    "postcode": "42140",
    "districtCode": "4208",
    "districtNameTh": "ท่าลี่",
    "districtNameEn": "Tha Li",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420806",
    "subDistrictNameTh": "น้ำทูน",
    "subDistrictNameEn": "Nam Thun",
    "postcode": "42140",
    "districtCode": "4208",
    "districtNameTh": "ท่าลี่",
    "districtNameEn": "Tha Li",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420901",
    "subDistrictNameTh": "วังสะพุง",
    "subDistrictNameEn": "Wang Saphung",
    "postcode": "42130",
    "districtCode": "4209",
    "districtNameTh": "วังสะพุง",
    "districtNameEn": "Wang Saphung",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420902",
    "subDistrictNameTh": "ทรายขาว",
    "subDistrictNameEn": "Sai Khao",
    "postcode": "42130",
    "districtCode": "4209",
    "districtNameTh": "วังสะพุง",
    "districtNameEn": "Wang Saphung",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420903",
    "subDistrictNameTh": "หนองหญ้าปล้อง",
    "subDistrictNameEn": "Nong Ya Plong",
    "postcode": "42130",
    "districtCode": "4209",
    "districtNameTh": "วังสะพุง",
    "districtNameEn": "Wang Saphung",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420904",
    "subDistrictNameTh": "หนองงิ้ว",
    "subDistrictNameEn": "Nong Ngio",
    "postcode": "42130",
    "districtCode": "4209",
    "districtNameTh": "วังสะพุง",
    "districtNameEn": "Wang Saphung",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420905",
    "subDistrictNameTh": "ปากปวน",
    "subDistrictNameEn": "Pak Puan",
    "postcode": "42130",
    "districtCode": "4209",
    "districtNameTh": "วังสะพุง",
    "districtNameEn": "Wang Saphung",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420906",
    "subDistrictNameTh": "ผาน้อย",
    "subDistrictNameEn": "Pha Noi",
    "postcode": "42130",
    "districtCode": "4209",
    "districtNameTh": "วังสะพุง",
    "districtNameEn": "Wang Saphung",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420910",
    "subDistrictNameTh": "ผาบิ้ง",
    "subDistrictNameEn": "Pha Bing",
    "postcode": "42130",
    "districtCode": "4209",
    "districtNameTh": "วังสะพุง",
    "districtNameEn": "Wang Saphung",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420911",
    "subDistrictNameTh": "เขาหลวง",
    "subDistrictNameEn": "Khao Luang",
    "postcode": "42130",
    "districtCode": "4209",
    "districtNameTh": "วังสะพุง",
    "districtNameEn": "Wang Saphung",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420912",
    "subDistrictNameTh": "โคกขมิ้น",
    "subDistrictNameEn": "Khok Khamin",
    "postcode": "42130",
    "districtCode": "4209",
    "districtNameTh": "วังสะพุง",
    "districtNameEn": "Wang Saphung",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "420913",
    "subDistrictNameTh": "ศรีสงคราม",
    "subDistrictNameEn": "Si Songkhram",
    "postcode": "42130",
    "districtCode": "4209",
    "districtNameTh": "วังสะพุง",
    "districtNameEn": "Wang Saphung",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "421001",
    "subDistrictNameTh": "ศรีฐาน",
    "subDistrictNameEn": "Si Than",
    "postcode": "42180",
    "districtCode": "4210",
    "districtNameTh": "ภูกระดึง",
    "districtNameEn": "Phu Kradueng",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "421005",
    "subDistrictNameTh": "ผานกเค้า",
    "subDistrictNameEn": "Pha Nok Khao",
    "postcode": "42180",
    "districtCode": "4210",
    "districtNameTh": "ภูกระดึง",
    "districtNameEn": "Phu Kradueng",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "421007",
    "subDistrictNameTh": "ภูกระดึง",
    "subDistrictNameEn": "Phu Kradueng",
    "postcode": "42180",
    "districtCode": "4210",
    "districtNameTh": "ภูกระดึง",
    "districtNameEn": "Phu Kradueng",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "421010",
    "subDistrictNameTh": "ห้วยส้ม",
    "subDistrictNameEn": "Huai Som",
    "postcode": "42180",
    "districtCode": "4210",
    "districtNameTh": "ภูกระดึง",
    "districtNameEn": "Phu Kradueng",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "421101",
    "subDistrictNameTh": "ภูหอ",
    "subDistrictNameEn": "Phu Ho",
    "postcode": "42230",
    "districtCode": "4211",
    "districtNameTh": "ภูหลวง",
    "districtNameEn": "Phu Luang",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "421102",
    "subDistrictNameTh": "หนองคัน",
    "subDistrictNameEn": "Nong Khan",
    "postcode": "42230",
    "districtCode": "4211",
    "districtNameTh": "ภูหลวง",
    "districtNameEn": "Phu Luang",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "421104",
    "subDistrictNameTh": "ห้วยสีเสียด",
    "subDistrictNameEn": "Huai Sisiat",
    "postcode": "42230",
    "districtCode": "4211",
    "districtNameTh": "ภูหลวง",
    "districtNameEn": "Phu Luang",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "421105",
    "subDistrictNameTh": "เลยวังไสย์",
    "subDistrictNameEn": "Loei Wang Sai",
    "postcode": "42230",
    "districtCode": "4211",
    "districtNameTh": "ภูหลวง",
    "districtNameEn": "Phu Luang",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "421106",
    "subDistrictNameTh": "แก่งศรีภูมิ",
    "subDistrictNameEn": "Kaeng Si Phum",
    "postcode": "42230",
    "districtCode": "4211",
    "districtNameTh": "ภูหลวง",
    "districtNameEn": "Phu Luang",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "421201",
    "subDistrictNameTh": "ผาขาว",
    "subDistrictNameEn": "Pha Khao",
    "postcode": "42240",
    "districtCode": "4212",
    "districtNameTh": "ผาขาว",
    "districtNameEn": "Pha Khao",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "421202",
    "subDistrictNameTh": "ท่าช้างคล้อง",
    "subDistrictNameEn": "Tha Chang Khlong",
    "postcode": "42240",
    "districtCode": "4212",
    "districtNameTh": "ผาขาว",
    "districtNameEn": "Pha Khao",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "421203",
    "subDistrictNameTh": "โนนปอแดง",
    "subDistrictNameEn": "Non Po Daeng",
    "postcode": "42240",
    "districtCode": "4212",
    "districtNameTh": "ผาขาว",
    "districtNameEn": "Pha Khao",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "421204",
    "subDistrictNameTh": "โนนป่าซาง",
    "subDistrictNameEn": "Non Pa Sang",
    "postcode": "42240",
    "districtCode": "4212",
    "districtNameTh": "ผาขาว",
    "districtNameEn": "Pha Khao",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "421205",
    "subDistrictNameTh": "บ้านเพิ่ม",
    "subDistrictNameEn": "Ban Phoem",
    "postcode": "42240",
    "districtCode": "4212",
    "districtNameTh": "ผาขาว",
    "districtNameEn": "Pha Khao",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "421301",
    "subDistrictNameTh": "เอราวัณ",
    "subDistrictNameEn": "Erawan",
    "postcode": "42220",
    "districtCode": "4213",
    "districtNameTh": "เอราวัณ",
    "districtNameEn": "Erawan",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "421302",
    "subDistrictNameTh": "ผาอินทร์แปลง",
    "subDistrictNameEn": "Pha In Plaeng",
    "postcode": "42220",
    "districtCode": "4213",
    "districtNameTh": "เอราวัณ",
    "districtNameEn": "Erawan",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "421303",
    "subDistrictNameTh": "ผาสามยอด",
    "subDistrictNameEn": "Pha Sam Yot",
    "postcode": "42220",
    "districtCode": "4213",
    "districtNameTh": "เอราวัณ",
    "districtNameEn": "Erawan",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "421304",
    "subDistrictNameTh": "ทรัพย์ไพวัลย์",
    "subDistrictNameEn": "Sap Phaiwan",
    "postcode": "42220",
    "districtCode": "4213",
    "districtNameTh": "เอราวัณ",
    "districtNameEn": "Erawan",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "421401",
    "subDistrictNameTh": "หนองหิน",
    "subDistrictNameEn": "Nong Hin",
    "postcode": "42190",
    "districtCode": "4214",
    "districtNameTh": "หนองหิน",
    "districtNameEn": "Nong Hin",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "421402",
    "subDistrictNameTh": "ตาดข่า",
    "subDistrictNameEn": "Tat Kha",
    "postcode": "42190",
    "districtCode": "4214",
    "districtNameTh": "หนองหิน",
    "districtNameEn": "Nong Hin",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "421403",
    "subDistrictNameTh": "ปวนพุ",
    "subDistrictNameEn": "Puan Phu",
    "postcode": "42190",
    "districtCode": "4214",
    "districtNameTh": "หนองหิน",
    "districtNameEn": "Nong Hin",
    "provinceCode": "42",
    "provinceNameTh": "เลย",
    "provinceNameEn": "Loei"
},
{
    "subDistrictCode": "430101",
    "subDistrictNameTh": "ในเมือง",
    "subDistrictNameEn": "Nai Mueang",
    "postcode": "43000",
    "districtCode": "4301",
    "districtNameTh": "เมืองหนองคาย",
    "districtNameEn": "Mueang Nong Khai",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "430102",
    "subDistrictNameTh": "มีชัย",
    "subDistrictNameEn": "Mi Chai",
    "postcode": "43000",
    "districtCode": "4301",
    "districtNameTh": "เมืองหนองคาย",
    "districtNameEn": "Mueang Nong Khai",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "430103",
    "subDistrictNameTh": "โพธิ์ชัย",
    "subDistrictNameEn": "Pho Chai",
    "postcode": "43000",
    "districtCode": "4301",
    "districtNameTh": "เมืองหนองคาย",
    "districtNameEn": "Mueang Nong Khai",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "430104",
    "subDistrictNameTh": "กวนวัน",
    "subDistrictNameEn": "Kuan Wan",
    "postcode": "43000",
    "districtCode": "4301",
    "districtNameTh": "เมืองหนองคาย",
    "districtNameEn": "Mueang Nong Khai",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "430105",
    "subDistrictNameTh": "เวียงคุก",
    "subDistrictNameEn": "Wiang Khuk",
    "postcode": "43000",
    "districtCode": "4301",
    "districtNameTh": "เมืองหนองคาย",
    "districtNameEn": "Mueang Nong Khai",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "430106",
    "subDistrictNameTh": "วัดธาตุ",
    "subDistrictNameEn": "Wat That",
    "postcode": "43000",
    "districtCode": "4301",
    "districtNameTh": "เมืองหนองคาย",
    "districtNameEn": "Mueang Nong Khai",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "430107",
    "subDistrictNameTh": "หาดคำ",
    "subDistrictNameEn": "Hat Kham",
    "postcode": "43000",
    "districtCode": "4301",
    "districtNameTh": "เมืองหนองคาย",
    "districtNameEn": "Mueang Nong Khai",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "430108",
    "subDistrictNameTh": "หินโงม",
    "subDistrictNameEn": "Hin Ngom",
    "postcode": "43000",
    "districtCode": "4301",
    "districtNameTh": "เมืองหนองคาย",
    "districtNameEn": "Mueang Nong Khai",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "430109",
    "subDistrictNameTh": "บ้านเดื่อ",
    "subDistrictNameEn": "Ban Duea",
    "postcode": "43000",
    "districtCode": "4301",
    "districtNameTh": "เมืองหนองคาย",
    "districtNameEn": "Mueang Nong Khai",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "430110",
    "subDistrictNameTh": "ค่ายบกหวาน",
    "subDistrictNameEn": "Khai Bok Wan",
    "postcode": "43100",
    "districtCode": "4301",
    "districtNameTh": "เมืองหนองคาย",
    "districtNameEn": "Mueang Nong Khai",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "430111",
    "subDistrictNameTh": "โพนสว่าง",
    "subDistrictNameEn": "Phon Sawang",
    "postcode": "43100",
    "districtCode": "4301",
    "districtNameTh": "เมืองหนองคาย",
    "districtNameEn": "Mueang Nong Khai",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "430113",
    "subDistrictNameTh": "พระธาตุบังพวน",
    "subDistrictNameEn": "Phra That Bang Phuan",
    "postcode": "43100",
    "districtCode": "4301",
    "districtNameTh": "เมืองหนองคาย",
    "districtNameEn": "Mueang Nong Khai",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "430116",
    "subDistrictNameTh": "หนองกอมเกาะ",
    "subDistrictNameEn": "Nong Kom Ko",
    "postcode": "43000",
    "districtCode": "4301",
    "districtNameTh": "เมืองหนองคาย",
    "districtNameEn": "Mueang Nong Khai",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "430117",
    "subDistrictNameTh": "ปะโค",
    "subDistrictNameEn": "Pa Kho",
    "postcode": "43000",
    "districtCode": "4301",
    "districtNameTh": "เมืองหนองคาย",
    "districtNameEn": "Mueang Nong Khai",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "430118",
    "subDistrictNameTh": "เมืองหมี",
    "subDistrictNameEn": "Mueang Mi",
    "postcode": "43000",
    "districtCode": "4301",
    "districtNameTh": "เมืองหนองคาย",
    "districtNameEn": "Mueang Nong Khai",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "430119",
    "subDistrictNameTh": "สีกาย",
    "subDistrictNameEn": "Si Kai",
    "postcode": "43000",
    "districtCode": "4301",
    "districtNameTh": "เมืองหนองคาย",
    "districtNameEn": "Mueang Nong Khai",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "430201",
    "subDistrictNameTh": "ท่าบ่อ",
    "subDistrictNameEn": "Tha Bo",
    "postcode": "43110",
    "districtCode": "4302",
    "districtNameTh": "ท่าบ่อ",
    "districtNameEn": "Tha Bo",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "430202",
    "subDistrictNameTh": "น้ำโมง",
    "subDistrictNameEn": "Nam Mong",
    "postcode": "43110",
    "districtCode": "4302",
    "districtNameTh": "ท่าบ่อ",
    "districtNameEn": "Tha Bo",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "430203",
    "subDistrictNameTh": "กองนาง",
    "subDistrictNameEn": "Kong Nang",
    "postcode": "43110",
    "districtCode": "4302",
    "districtNameTh": "ท่าบ่อ",
    "districtNameEn": "Tha Bo",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "430204",
    "subDistrictNameTh": "โคกคอน",
    "subDistrictNameEn": "Khok Khon",
    "postcode": "43110",
    "districtCode": "4302",
    "districtNameTh": "ท่าบ่อ",
    "districtNameEn": "Tha Bo",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "430205",
    "subDistrictNameTh": "บ้านเดื่อ",
    "subDistrictNameEn": "Ban Duea",
    "postcode": "43110",
    "districtCode": "4302",
    "districtNameTh": "ท่าบ่อ",
    "districtNameEn": "Tha Bo",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "430206",
    "subDistrictNameTh": "บ้านถ่อน",
    "subDistrictNameEn": "Ban Thon",
    "postcode": "43110",
    "districtCode": "4302",
    "districtNameTh": "ท่าบ่อ",
    "districtNameEn": "Tha Bo",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "430207",
    "subDistrictNameTh": "บ้านว่าน",
    "subDistrictNameEn": "Ban Wan",
    "postcode": "43110",
    "districtCode": "4302",
    "districtNameTh": "ท่าบ่อ",
    "districtNameEn": "Tha Bo",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "430208",
    "subDistrictNameTh": "นาข่า",
    "subDistrictNameEn": "Na Kha",
    "postcode": "43110",
    "districtCode": "4302",
    "districtNameTh": "ท่าบ่อ",
    "districtNameEn": "Tha Bo",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "430209",
    "subDistrictNameTh": "โพนสา",
    "subDistrictNameEn": "Phon Sa",
    "postcode": "43110",
    "districtCode": "4302",
    "districtNameTh": "ท่าบ่อ",
    "districtNameEn": "Tha Bo",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "430210",
    "subDistrictNameTh": "หนองนาง",
    "subDistrictNameEn": "Nong Nang",
    "postcode": "43110",
    "districtCode": "4302",
    "districtNameTh": "ท่าบ่อ",
    "districtNameEn": "Tha Bo",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "430501",
    "subDistrictNameTh": "จุมพล",
    "subDistrictNameEn": "Chumphon",
    "postcode": "43120",
    "districtCode": "4305",
    "districtNameTh": "โพนพิสัย",
    "districtNameEn": "Phon Phisai",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "430502",
    "subDistrictNameTh": "วัดหลวง",
    "subDistrictNameEn": "Wat Luang",
    "postcode": "43120",
    "districtCode": "4305",
    "districtNameTh": "โพนพิสัย",
    "districtNameEn": "Phon Phisai",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "430503",
    "subDistrictNameTh": "กุดบง",
    "subDistrictNameEn": "Kut Bong",
    "postcode": "43120",
    "districtCode": "4305",
    "districtNameTh": "โพนพิสัย",
    "districtNameEn": "Phon Phisai",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "430504",
    "subDistrictNameTh": "ชุมช้าง",
    "subDistrictNameEn": "Chum Chang",
    "postcode": "43120",
    "districtCode": "4305",
    "districtNameTh": "โพนพิสัย",
    "districtNameEn": "Phon Phisai",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "430506",
    "subDistrictNameTh": "ทุ่งหลวง",
    "subDistrictNameEn": "Thung Luang",
    "postcode": "43120",
    "districtCode": "4305",
    "districtNameTh": "โพนพิสัย",
    "districtNameEn": "Phon Phisai",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "430507",
    "subDistrictNameTh": "เหล่าต่างคำ",
    "subDistrictNameEn": "Lao Tang Kham",
    "postcode": "43120",
    "districtCode": "4305",
    "districtNameTh": "โพนพิสัย",
    "districtNameEn": "Phon Phisai",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "430508",
    "subDistrictNameTh": "นาหนัง",
    "subDistrictNameEn": "Na Nang",
    "postcode": "43120",
    "districtCode": "4305",
    "districtNameTh": "โพนพิสัย",
    "districtNameEn": "Phon Phisai",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "430509",
    "subDistrictNameTh": "เซิม",
    "subDistrictNameEn": "Soem",
    "postcode": "43120",
    "districtCode": "4305",
    "districtNameTh": "โพนพิสัย",
    "districtNameEn": "Phon Phisai",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "430513",
    "subDistrictNameTh": "บ้านโพธิ์",
    "subDistrictNameEn": "Ban Pho",
    "postcode": "43120",
    "districtCode": "4305",
    "districtNameTh": "โพนพิสัย",
    "districtNameEn": "Phon Phisai",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "430521",
    "subDistrictNameTh": "บ้านผือ",
    "subDistrictNameEn": "Ban Phue",
    "postcode": "43120",
    "districtCode": "4305",
    "districtNameTh": "โพนพิสัย",
    "districtNameEn": "Phon Phisai",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "430522",
    "subDistrictNameTh": "สร้างนางขาว",
    "subDistrictNameEn": "Sang Nang Khao",
    "postcode": "43120",
    "districtCode": "4305",
    "districtNameTh": "โพนพิสัย",
    "districtNameEn": "Phon Phisai",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "430701",
    "subDistrictNameTh": "พานพร้าว",
    "subDistrictNameEn": "Phan Phrao",
    "postcode": "43130",
    "districtCode": "4307",
    "districtNameTh": "ศรีเชียงใหม่",
    "districtNameEn": "Si Chiang Mai",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "430703",
    "subDistrictNameTh": "บ้านหม้อ",
    "subDistrictNameEn": "Ban Mo",
    "postcode": "43130",
    "districtCode": "4307",
    "districtNameTh": "ศรีเชียงใหม่",
    "districtNameEn": "Si Chiang Mai",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "430704",
    "subDistrictNameTh": "พระพุทธบาท",
    "subDistrictNameEn": "Phra Phutthabat",
    "postcode": "43130",
    "districtCode": "4307",
    "districtNameTh": "ศรีเชียงใหม่",
    "districtNameEn": "Si Chiang Mai",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "430705",
    "subDistrictNameTh": "หนองปลาปาก",
    "subDistrictNameEn": "Nong Pla Pak",
    "postcode": "43130",
    "districtCode": "4307",
    "districtNameTh": "ศรีเชียงใหม่",
    "districtNameEn": "Si Chiang Mai",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "430801",
    "subDistrictNameTh": "แก้งไก่",
    "subDistrictNameEn": "Kaeng Kai",
    "postcode": "43160",
    "districtCode": "4308",
    "districtNameTh": "สังคม",
    "districtNameEn": "Sangkhom",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "430802",
    "subDistrictNameTh": "ผาตั้ง",
    "subDistrictNameEn": "Pha Tang",
    "postcode": "43160",
    "districtCode": "4308",
    "districtNameTh": "สังคม",
    "districtNameEn": "Sangkhom",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "430803",
    "subDistrictNameTh": "บ้านม่วง",
    "subDistrictNameEn": "Ban Muang",
    "postcode": "43160",
    "districtCode": "4308",
    "districtNameTh": "สังคม",
    "districtNameEn": "Sangkhom",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "430804",
    "subDistrictNameTh": "นางิ้ว",
    "subDistrictNameEn": "Na Ngio",
    "postcode": "43160",
    "districtCode": "4308",
    "districtNameTh": "สังคม",
    "districtNameEn": "Sangkhom",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "430805",
    "subDistrictNameTh": "สังคม",
    "subDistrictNameEn": "Sangkhom",
    "postcode": "43160",
    "districtCode": "4308",
    "districtNameTh": "สังคม",
    "districtNameEn": "Sangkhom",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "431401",
    "subDistrictNameTh": "สระใคร",
    "subDistrictNameEn": "Sakhrai",
    "postcode": "43100",
    "districtCode": "4314",
    "districtNameTh": "สระใคร",
    "districtNameEn": "Sakhrai",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "431402",
    "subDistrictNameTh": "คอกช้าง",
    "subDistrictNameEn": "Khok Chang",
    "postcode": "43100",
    "districtCode": "4314",
    "districtNameTh": "สระใคร",
    "districtNameEn": "Sakhrai",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "431403",
    "subDistrictNameTh": "บ้านฝาง",
    "subDistrictNameEn": "Ban Fang",
    "postcode": "43100",
    "districtCode": "4314",
    "districtNameTh": "สระใคร",
    "districtNameEn": "Sakhrai",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "431501",
    "subDistrictNameTh": "เฝ้าไร่",
    "subDistrictNameEn": "Fao Rai",
    "postcode": "43120",
    "districtCode": "4315",
    "districtNameTh": "เฝ้าไร่",
    "districtNameEn": "Fao Rai",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "431502",
    "subDistrictNameTh": "นาดี",
    "subDistrictNameEn": "Na Di",
    "postcode": "43120",
    "districtCode": "4315",
    "districtNameTh": "เฝ้าไร่",
    "districtNameEn": "Fao Rai",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "431503",
    "subDistrictNameTh": "หนองหลวง",
    "subDistrictNameEn": "Nong Luang",
    "postcode": "43120",
    "districtCode": "4315",
    "districtNameTh": "เฝ้าไร่",
    "districtNameEn": "Fao Rai",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "431504",
    "subDistrictNameTh": "วังหลวง",
    "subDistrictNameEn": "wang Luang",
    "postcode": "43120",
    "districtCode": "4315",
    "districtNameTh": "เฝ้าไร่",
    "districtNameEn": "Fao Rai",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "431505",
    "subDistrictNameTh": "อุดมพร",
    "subDistrictNameEn": "Udom Phon",
    "postcode": "43120",
    "districtCode": "4315",
    "districtNameTh": "เฝ้าไร่",
    "districtNameEn": "Fao Rai",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "431601",
    "subDistrictNameTh": "รัตนวาปี",
    "subDistrictNameEn": "Rattanawapi",
    "postcode": "43120",
    "districtCode": "4316",
    "districtNameTh": "รัตนวาปี",
    "districtNameEn": "Rattanawapi",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "431602",
    "subDistrictNameTh": "นาทับไฮ",
    "subDistrictNameEn": "Na Thap Hai",
    "postcode": "43120",
    "districtCode": "4316",
    "districtNameTh": "รัตนวาปี",
    "districtNameEn": "Rattanawapi",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "431603",
    "subDistrictNameTh": "บ้านต้อน",
    "subDistrictNameEn": "Ban Ton",
    "postcode": "43120",
    "districtCode": "4316",
    "districtNameTh": "รัตนวาปี",
    "districtNameEn": "Rattanawapi",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "431604",
    "subDistrictNameTh": "พระบาทนาสิงห์",
    "subDistrictNameEn": "Phra Bat Na Sing",
    "postcode": "43120",
    "districtCode": "4316",
    "districtNameTh": "รัตนวาปี",
    "districtNameEn": "Rattanawapi",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "431605",
    "subDistrictNameTh": "โพนแพง",
    "subDistrictNameEn": "Phon Phaeng",
    "postcode": "43120",
    "districtCode": "4316",
    "districtNameTh": "รัตนวาปี",
    "districtNameEn": "Rattanawapi",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "431701",
    "subDistrictNameTh": "โพธิ์ตาก",
    "subDistrictNameEn": "Pho Tak",
    "postcode": "43130",
    "districtCode": "4317",
    "districtNameTh": "โพธิ์ตาก",
    "districtNameEn": "Pho Tak",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "431702",
    "subDistrictNameTh": "โพนทอง",
    "subDistrictNameEn": "Phon Thong",
    "postcode": "43130",
    "districtCode": "4317",
    "districtNameTh": "โพธิ์ตาก",
    "districtNameEn": "Pho Tak",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "431703",
    "subDistrictNameTh": "ด่านศรีสุข",
    "subDistrictNameEn": "Dan Si Suk",
    "postcode": "43130",
    "districtCode": "4317",
    "districtNameTh": "โพธิ์ตาก",
    "districtNameEn": "Pho Tak",
    "provinceCode": "43",
    "provinceNameTh": "หนองคาย",
    "provinceNameEn": "Nong Khai"
},
{
    "subDistrictCode": "440101",
    "subDistrictNameTh": "ตลาด",
    "subDistrictNameEn": "Talat",
    "postcode": "44000",
    "districtCode": "4401",
    "districtNameTh": "เมืองมหาสารคาม",
    "districtNameEn": "Mueang Mahasarakham",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440102",
    "subDistrictNameTh": "เขวา",
    "subDistrictNameEn": "Khwao",
    "postcode": "44000",
    "districtCode": "4401",
    "districtNameTh": "เมืองมหาสารคาม",
    "districtNameEn": "Mueang Mahasarakham",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440103",
    "subDistrictNameTh": "ท่าตูม",
    "subDistrictNameEn": "Tha Tum",
    "postcode": "44000",
    "districtCode": "4401",
    "districtNameTh": "เมืองมหาสารคาม",
    "districtNameEn": "Mueang Mahasarakham",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440104",
    "subDistrictNameTh": "แวงน่าง",
    "subDistrictNameEn": "Waeng Nang",
    "postcode": "44000",
    "districtCode": "4401",
    "districtNameTh": "เมืองมหาสารคาม",
    "districtNameEn": "Mueang Mahasarakham",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440105",
    "subDistrictNameTh": "โคกก่อ",
    "subDistrictNameEn": "Khok Ko",
    "postcode": "44000",
    "districtCode": "4401",
    "districtNameTh": "เมืองมหาสารคาม",
    "districtNameEn": "Mueang Mahasarakham",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440106",
    "subDistrictNameTh": "ดอนหว่าน",
    "subDistrictNameEn": "Don Wan",
    "postcode": "44000",
    "districtCode": "4401",
    "districtNameTh": "เมืองมหาสารคาม",
    "districtNameEn": "Mueang Mahasarakham",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440107",
    "subDistrictNameTh": "เกิ้ง",
    "subDistrictNameEn": "Koeng",
    "postcode": "44000",
    "districtCode": "4401",
    "districtNameTh": "เมืองมหาสารคาม",
    "districtNameEn": "Mueang Mahasarakham",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440108",
    "subDistrictNameTh": "แก่งเลิงจาน",
    "subDistrictNameEn": "Kaeng Loeng Chan",
    "postcode": "44000",
    "districtCode": "4401",
    "districtNameTh": "เมืองมหาสารคาม",
    "districtNameEn": "Mueang Mahasarakham",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440109",
    "subDistrictNameTh": "ท่าสองคอน",
    "subDistrictNameEn": "Tha Song Khon",
    "postcode": "44000",
    "districtCode": "4401",
    "districtNameTh": "เมืองมหาสารคาม",
    "districtNameEn": "Mueang Mahasarakham",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440110",
    "subDistrictNameTh": "ลาดพัฒนา",
    "subDistrictNameEn": "Lat Phatthana",
    "postcode": "44000",
    "districtCode": "4401",
    "districtNameTh": "เมืองมหาสารคาม",
    "districtNameEn": "Mueang Mahasarakham",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440111",
    "subDistrictNameTh": "หนองปลิง",
    "subDistrictNameEn": "Nong Pling",
    "postcode": "44000",
    "districtCode": "4401",
    "districtNameTh": "เมืองมหาสารคาม",
    "districtNameEn": "Mueang Mahasarakham",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440112",
    "subDistrictNameTh": "ห้วยแอ่ง",
    "subDistrictNameEn": "Huai Aeng",
    "postcode": "44000",
    "districtCode": "4401",
    "districtNameTh": "เมืองมหาสารคาม",
    "districtNameEn": "Mueang Mahasarakham",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440113",
    "subDistrictNameTh": "หนองโน",
    "subDistrictNameEn": "Nong No",
    "postcode": "44000",
    "districtCode": "4401",
    "districtNameTh": "เมืองมหาสารคาม",
    "districtNameEn": "Mueang Mahasarakham",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440114",
    "subDistrictNameTh": "บัวค้อ",
    "subDistrictNameEn": "Bua Kho",
    "postcode": "44000",
    "districtCode": "4401",
    "districtNameTh": "เมืองมหาสารคาม",
    "districtNameEn": "Mueang Mahasarakham",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440201",
    "subDistrictNameTh": "แกดำ",
    "subDistrictNameEn": "Kae Dam",
    "postcode": "44190",
    "districtCode": "4402",
    "districtNameTh": "แกดำ",
    "districtNameEn": "Kae Dam",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440202",
    "subDistrictNameTh": "วังแสง",
    "subDistrictNameEn": "Wang Saeng",
    "postcode": "44190",
    "districtCode": "4402",
    "districtNameTh": "แกดำ",
    "districtNameEn": "Kae Dam",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440203",
    "subDistrictNameTh": "มิตรภาพ",
    "subDistrictNameEn": "Mittraphap",
    "postcode": "44190",
    "districtCode": "4402",
    "districtNameTh": "แกดำ",
    "districtNameEn": "Kae Dam",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440204",
    "subDistrictNameTh": "หนองกุง",
    "subDistrictNameEn": "Nong Kung",
    "postcode": "44190",
    "districtCode": "4402",
    "districtNameTh": "แกดำ",
    "districtNameEn": "Kae Dam",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440205",
    "subDistrictNameTh": "โนนภิบาล",
    "subDistrictNameEn": "Non Phiban",
    "postcode": "44190",
    "districtCode": "4402",
    "districtNameTh": "แกดำ",
    "districtNameEn": "Kae Dam",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440301",
    "subDistrictNameTh": "หัวขวาง",
    "subDistrictNameEn": "Hua Khwang",
    "postcode": "44140",
    "districtCode": "4403",
    "districtNameTh": "โกสุมพิสัย",
    "districtNameEn": "Kosum Phisai",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440302",
    "subDistrictNameTh": "ยางน้อย",
    "subDistrictNameEn": "Yang Noi",
    "postcode": "44140",
    "districtCode": "4403",
    "districtNameTh": "โกสุมพิสัย",
    "districtNameEn": "Kosum Phisai",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440303",
    "subDistrictNameTh": "วังยาว",
    "subDistrictNameEn": "Wang Yao",
    "postcode": "44140",
    "districtCode": "4403",
    "districtNameTh": "โกสุมพิสัย",
    "districtNameEn": "Kosum Phisai",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440304",
    "subDistrictNameTh": "เขวาไร่",
    "subDistrictNameEn": "Khwao Rai",
    "postcode": "44140",
    "districtCode": "4403",
    "districtNameTh": "โกสุมพิสัย",
    "districtNameEn": "Kosum Phisai",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440305",
    "subDistrictNameTh": "แพง",
    "subDistrictNameEn": "Phaeng",
    "postcode": "44140",
    "districtCode": "4403",
    "districtNameTh": "โกสุมพิสัย",
    "districtNameEn": "Kosum Phisai",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440306",
    "subDistrictNameTh": "แก้งแก",
    "subDistrictNameEn": "Kaeng Kae",
    "postcode": "44140",
    "districtCode": "4403",
    "districtNameTh": "โกสุมพิสัย",
    "districtNameEn": "Kosum Phisai",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440307",
    "subDistrictNameTh": "หนองเหล็ก",
    "subDistrictNameEn": "Nong Lek",
    "postcode": "44140",
    "districtCode": "4403",
    "districtNameTh": "โกสุมพิสัย",
    "districtNameEn": "Kosum Phisai",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440308",
    "subDistrictNameTh": "หนองบัว",
    "subDistrictNameEn": "Nong Bua",
    "postcode": "44140",
    "districtCode": "4403",
    "districtNameTh": "โกสุมพิสัย",
    "districtNameEn": "Kosum Phisai",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440309",
    "subDistrictNameTh": "เหล่า",
    "subDistrictNameEn": "Lao",
    "postcode": "44140",
    "districtCode": "4403",
    "districtNameTh": "โกสุมพิสัย",
    "districtNameEn": "Kosum Phisai",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440310",
    "subDistrictNameTh": "เขื่อน",
    "subDistrictNameEn": "Khuean",
    "postcode": "44140",
    "districtCode": "4403",
    "districtNameTh": "โกสุมพิสัย",
    "districtNameEn": "Kosum Phisai",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440311",
    "subDistrictNameTh": "หนองบอน",
    "subDistrictNameEn": "Nong Bon",
    "postcode": "44140",
    "districtCode": "4403",
    "districtNameTh": "โกสุมพิสัย",
    "districtNameEn": "Kosum Phisai",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440312",
    "subDistrictNameTh": "โพนงาม",
    "subDistrictNameEn": "Phon Ngam",
    "postcode": "44140",
    "districtCode": "4403",
    "districtNameTh": "โกสุมพิสัย",
    "districtNameEn": "Kosum Phisai",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440313",
    "subDistrictNameTh": "ยางท่าแจ้ง",
    "subDistrictNameEn": "Yang Tha Chaeng",
    "postcode": "44140",
    "districtCode": "4403",
    "districtNameTh": "โกสุมพิสัย",
    "districtNameEn": "Kosum Phisai",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440314",
    "subDistrictNameTh": "แห่ใต้",
    "subDistrictNameEn": "Hae Tai",
    "postcode": "44140",
    "districtCode": "4403",
    "districtNameTh": "โกสุมพิสัย",
    "districtNameEn": "Kosum Phisai",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440315",
    "subDistrictNameTh": "หนองกุงสวรรค์",
    "subDistrictNameEn": "Nong Kung Sawan",
    "postcode": "44140",
    "districtCode": "4403",
    "districtNameTh": "โกสุมพิสัย",
    "districtNameEn": "Kosum Phisai",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440316",
    "subDistrictNameTh": "เลิงใต้",
    "subDistrictNameEn": "Loeng Tai",
    "postcode": "44140",
    "districtCode": "4403",
    "districtNameTh": "โกสุมพิสัย",
    "districtNameEn": "Kosum Phisai",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440317",
    "subDistrictNameTh": "ดอนกลาง",
    "subDistrictNameEn": "Don Klang",
    "postcode": "44140",
    "districtCode": "4403",
    "districtNameTh": "โกสุมพิสัย",
    "districtNameEn": "Kosum Phisai",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440401",
    "subDistrictNameTh": "โคกพระ",
    "subDistrictNameEn": "Khok Phra",
    "postcode": "44150",
    "districtCode": "4404",
    "districtNameTh": "กันทรวิชัย",
    "districtNameEn": "Kantharawichai",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440402",
    "subDistrictNameTh": "คันธารราษฎร์",
    "subDistrictNameEn": "Khanthararat",
    "postcode": "44150",
    "districtCode": "4404",
    "districtNameTh": "กันทรวิชัย",
    "districtNameEn": "Kantharawichai",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440403",
    "subDistrictNameTh": "มะค่า",
    "subDistrictNameEn": "Makha",
    "postcode": "44150",
    "districtCode": "4404",
    "districtNameTh": "กันทรวิชัย",
    "districtNameEn": "Kantharawichai",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440404",
    "subDistrictNameTh": "ท่าขอนยาง",
    "subDistrictNameEn": "Tha Khon Yang",
    "postcode": "44150",
    "districtCode": "4404",
    "districtNameTh": "กันทรวิชัย",
    "districtNameEn": "Kantharawichai",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440405",
    "subDistrictNameTh": "นาสีนวน",
    "subDistrictNameEn": "Na Si Nuan",
    "postcode": "44150",
    "districtCode": "4404",
    "districtNameTh": "กันทรวิชัย",
    "districtNameEn": "Kantharawichai",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440406",
    "subDistrictNameTh": "ขามเรียง",
    "subDistrictNameEn": "Kham Riang",
    "postcode": "44150",
    "districtCode": "4404",
    "districtNameTh": "กันทรวิชัย",
    "districtNameEn": "Kantharawichai",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440407",
    "subDistrictNameTh": "เขวาใหญ่",
    "subDistrictNameEn": "Khwao Yai",
    "postcode": "44150",
    "districtCode": "4404",
    "districtNameTh": "กันทรวิชัย",
    "districtNameEn": "Kantharawichai",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440408",
    "subDistrictNameTh": "ศรีสุข",
    "subDistrictNameEn": "Si Suk",
    "postcode": "44150",
    "districtCode": "4404",
    "districtNameTh": "กันทรวิชัย",
    "districtNameEn": "Kantharawichai",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440409",
    "subDistrictNameTh": "กุดใส้จ่อ",
    "subDistrictNameEn": "Kut Sai Cho",
    "postcode": "44150",
    "districtCode": "4404",
    "districtNameTh": "กันทรวิชัย",
    "districtNameEn": "Kantharawichai",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440410",
    "subDistrictNameTh": "ขามเฒ่าพัฒนา",
    "subDistrictNameEn": "Kham Thao Phatthana",
    "postcode": "44150",
    "districtCode": "4404",
    "districtNameTh": "กันทรวิชัย",
    "districtNameEn": "Kantharawichai",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440501",
    "subDistrictNameTh": "เชียงยืน",
    "subDistrictNameEn": "Chiang Yuen",
    "postcode": "44160",
    "districtCode": "4405",
    "districtNameTh": "เชียงยืน",
    "districtNameEn": "Chiang Yuen",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440503",
    "subDistrictNameTh": "หนองซอน",
    "subDistrictNameEn": "Nong Son",
    "postcode": "44160",
    "districtCode": "4405",
    "districtNameTh": "เชียงยืน",
    "districtNameEn": "Chiang Yuen",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440505",
    "subDistrictNameTh": "ดอนเงิน",
    "subDistrictNameEn": "Don Ngoen",
    "postcode": "44160",
    "districtCode": "4405",
    "districtNameTh": "เชียงยืน",
    "districtNameEn": "Chiang Yuen",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440506",
    "subDistrictNameTh": "กู่ทอง",
    "subDistrictNameEn": "Ku Thong",
    "postcode": "44160",
    "districtCode": "4405",
    "districtNameTh": "เชียงยืน",
    "districtNameEn": "Chiang Yuen",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440507",
    "subDistrictNameTh": "นาทอง",
    "subDistrictNameEn": "Na Thong",
    "postcode": "44160",
    "districtCode": "4405",
    "districtNameTh": "เชียงยืน",
    "districtNameEn": "Chiang Yuen",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440508",
    "subDistrictNameTh": "เสือเฒ่า",
    "subDistrictNameEn": "Suea Thao",
    "postcode": "44160",
    "districtCode": "4405",
    "districtNameTh": "เชียงยืน",
    "districtNameEn": "Chiang Yuen",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440511",
    "subDistrictNameTh": "โพนทอง",
    "subDistrictNameEn": "Phon Thong",
    "postcode": "44160",
    "districtCode": "4405",
    "districtNameTh": "เชียงยืน",
    "districtNameEn": "Chiang Yuen",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440512",
    "subDistrictNameTh": "เหล่าบัวบาน",
    "subDistrictNameEn": "Lao Bua Ban",
    "postcode": "44160",
    "districtCode": "4405",
    "districtNameTh": "เชียงยืน",
    "districtNameEn": "Chiang Yuen",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440601",
    "subDistrictNameTh": "บรบือ",
    "subDistrictNameEn": "Borabue",
    "postcode": "44130",
    "districtCode": "4406",
    "districtNameTh": "บรบือ",
    "districtNameEn": "Borabue",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440602",
    "subDistrictNameTh": "บ่อใหญ่",
    "subDistrictNameEn": "Bo Yai",
    "postcode": "44130",
    "districtCode": "4406",
    "districtNameTh": "บรบือ",
    "districtNameEn": "Borabue",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440604",
    "subDistrictNameTh": "วังไชย",
    "subDistrictNameEn": "Wang Chai",
    "postcode": "44130",
    "districtCode": "4406",
    "districtNameTh": "บรบือ",
    "districtNameEn": "Borabue",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440605",
    "subDistrictNameTh": "หนองม่วง",
    "subDistrictNameEn": "Nong Muang",
    "postcode": "44130",
    "districtCode": "4406",
    "districtNameTh": "บรบือ",
    "districtNameEn": "Borabue",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440606",
    "subDistrictNameTh": "กำพี้",
    "subDistrictNameEn": "Kam Phi",
    "postcode": "44130",
    "districtCode": "4406",
    "districtNameTh": "บรบือ",
    "districtNameEn": "Borabue",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440607",
    "subDistrictNameTh": "โนนราษี",
    "subDistrictNameEn": "Non Rasi",
    "postcode": "44130",
    "districtCode": "4406",
    "districtNameTh": "บรบือ",
    "districtNameEn": "Borabue",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440608",
    "subDistrictNameTh": "โนนแดง",
    "subDistrictNameEn": "Non Daeng",
    "postcode": "44130",
    "districtCode": "4406",
    "districtNameTh": "บรบือ",
    "districtNameEn": "Borabue",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440610",
    "subDistrictNameTh": "หนองจิก",
    "subDistrictNameEn": "Nong Chik",
    "postcode": "44130",
    "districtCode": "4406",
    "districtNameTh": "บรบือ",
    "districtNameEn": "Borabue",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440611",
    "subDistrictNameTh": "บัวมาศ",
    "subDistrictNameEn": "Bua Mat",
    "postcode": "44130",
    "districtCode": "4406",
    "districtNameTh": "บรบือ",
    "districtNameEn": "Borabue",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440613",
    "subDistrictNameTh": "หนองคูขาด",
    "subDistrictNameEn": "Nong Khu Khat",
    "postcode": "44130",
    "districtCode": "4406",
    "districtNameTh": "บรบือ",
    "districtNameEn": "Borabue",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440615",
    "subDistrictNameTh": "วังใหม่",
    "subDistrictNameEn": "Wang Mai",
    "postcode": "44130",
    "districtCode": "4406",
    "districtNameTh": "บรบือ",
    "districtNameEn": "Borabue",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440616",
    "subDistrictNameTh": "ยาง",
    "subDistrictNameEn": "Yang",
    "postcode": "44130",
    "districtCode": "4406",
    "districtNameTh": "บรบือ",
    "districtNameEn": "Borabue",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440618",
    "subDistrictNameTh": "หนองสิม",
    "subDistrictNameEn": "Nong Sim",
    "postcode": "44130",
    "districtCode": "4406",
    "districtNameTh": "บรบือ",
    "districtNameEn": "Borabue",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440619",
    "subDistrictNameTh": "หนองโก",
    "subDistrictNameEn": "Nong Ko",
    "postcode": "44130",
    "districtCode": "4406",
    "districtNameTh": "บรบือ",
    "districtNameEn": "Borabue",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440620",
    "subDistrictNameTh": "ดอนงัว",
    "subDistrictNameEn": "Don Ngua",
    "postcode": "44130",
    "districtCode": "4406",
    "districtNameTh": "บรบือ",
    "districtNameEn": "Borabue",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440701",
    "subDistrictNameTh": "นาเชือก",
    "subDistrictNameEn": "Na Chueak",
    "postcode": "44170",
    "districtCode": "4407",
    "districtNameTh": "นาเชือก",
    "districtNameEn": "Na Chueak",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440702",
    "subDistrictNameTh": "สำโรง",
    "subDistrictNameEn": "Samrong",
    "postcode": "44170",
    "districtCode": "4407",
    "districtNameTh": "นาเชือก",
    "districtNameEn": "Na Chueak",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440703",
    "subDistrictNameTh": "หนองแดง",
    "subDistrictNameEn": "Nong Daeng",
    "postcode": "44170",
    "districtCode": "4407",
    "districtNameTh": "นาเชือก",
    "districtNameEn": "Na Chueak",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440704",
    "subDistrictNameTh": "เขวาไร่",
    "subDistrictNameEn": "Khwao Rai",
    "postcode": "44170",
    "districtCode": "4407",
    "districtNameTh": "นาเชือก",
    "districtNameEn": "Na Chueak",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440705",
    "subDistrictNameTh": "หนองโพธิ์",
    "subDistrictNameEn": "Nong Pho",
    "postcode": "44170",
    "districtCode": "4407",
    "districtNameTh": "นาเชือก",
    "districtNameEn": "Na Chueak",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440706",
    "subDistrictNameTh": "ปอพาน",
    "subDistrictNameEn": "Po Phan",
    "postcode": "44170",
    "districtCode": "4407",
    "districtNameTh": "นาเชือก",
    "districtNameEn": "Na Chueak",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440707",
    "subDistrictNameTh": "หนองเม็ก",
    "subDistrictNameEn": "Nong Mek",
    "postcode": "44170",
    "districtCode": "4407",
    "districtNameTh": "นาเชือก",
    "districtNameEn": "Na Chueak",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440708",
    "subDistrictNameTh": "หนองเรือ",
    "subDistrictNameEn": "Nong Ruea",
    "postcode": "44170",
    "districtCode": "4407",
    "districtNameTh": "นาเชือก",
    "districtNameEn": "Na Chueak",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440709",
    "subDistrictNameTh": "หนองกุง",
    "subDistrictNameEn": "Nong Kung",
    "postcode": "44170",
    "districtCode": "4407",
    "districtNameTh": "นาเชือก",
    "districtNameEn": "Na Chueak",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440710",
    "subDistrictNameTh": "สันป่าตอง",
    "subDistrictNameEn": "San Pa Tong",
    "postcode": "44170",
    "districtCode": "4407",
    "districtNameTh": "นาเชือก",
    "districtNameEn": "Na Chueak",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440801",
    "subDistrictNameTh": "ปะหลาน",
    "subDistrictNameEn": "Palan",
    "postcode": "44110",
    "districtCode": "4408",
    "districtNameTh": "พยัคฆภูมิพิสัย",
    "districtNameEn": "Phayakkhaphum Phisai",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440802",
    "subDistrictNameTh": "ก้ามปู",
    "subDistrictNameEn": "Kham Pu",
    "postcode": "44110",
    "districtCode": "4408",
    "districtNameTh": "พยัคฆภูมิพิสัย",
    "districtNameEn": "Phayakkhaphum Phisai",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440803",
    "subDistrictNameTh": "เวียงสะอาด",
    "subDistrictNameEn": "Wiang Sa-at",
    "postcode": "44110",
    "districtCode": "4408",
    "districtNameTh": "พยัคฆภูมิพิสัย",
    "districtNameEn": "Phayakkhaphum Phisai",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440804",
    "subDistrictNameTh": "เม็กดำ",
    "subDistrictNameEn": "Mek Dam",
    "postcode": "44110",
    "districtCode": "4408",
    "districtNameTh": "พยัคฆภูมิพิสัย",
    "districtNameEn": "Phayakkhaphum Phisai",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440805",
    "subDistrictNameTh": "นาสีนวล",
    "subDistrictNameEn": "Na Si Nuan",
    "postcode": "44110",
    "districtCode": "4408",
    "districtNameTh": "พยัคฆภูมิพิสัย",
    "districtNameEn": "Phayakkhaphum Phisai",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440809",
    "subDistrictNameTh": "ราษฎร์เจริญ",
    "subDistrictNameEn": "Rat Charoen",
    "postcode": "44110",
    "districtCode": "4408",
    "districtNameTh": "พยัคฆภูมิพิสัย",
    "districtNameEn": "Phayakkhaphum Phisai",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440810",
    "subDistrictNameTh": "หนองบัวแก้ว",
    "subDistrictNameEn": "Nong Bua Kaeo",
    "postcode": "44110",
    "districtCode": "4408",
    "districtNameTh": "พยัคฆภูมิพิสัย",
    "districtNameEn": "Phayakkhaphum Phisai",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440812",
    "subDistrictNameTh": "เมืองเตา",
    "subDistrictNameEn": "Mueang Tao",
    "postcode": "44110",
    "districtCode": "4408",
    "districtNameTh": "พยัคฆภูมิพิสัย",
    "districtNameEn": "Phayakkhaphum Phisai",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440815",
    "subDistrictNameTh": "ลานสะแก",
    "subDistrictNameEn": "Lan Sakae",
    "postcode": "44110",
    "districtCode": "4408",
    "districtNameTh": "พยัคฆภูมิพิสัย",
    "districtNameEn": "Phayakkhaphum Phisai",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440816",
    "subDistrictNameTh": "เวียงชัย",
    "subDistrictNameEn": "Wiang Chai",
    "postcode": "44110",
    "districtCode": "4408",
    "districtNameTh": "พยัคฆภูมิพิสัย",
    "districtNameEn": "Phayakkhaphum Phisai",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440817",
    "subDistrictNameTh": "หนองบัว",
    "subDistrictNameEn": "Nong Bua",
    "postcode": "44110",
    "districtCode": "4408",
    "districtNameTh": "พยัคฆภูมิพิสัย",
    "districtNameEn": "Phayakkhaphum Phisai",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440818",
    "subDistrictNameTh": "ราษฎร์พัฒนา",
    "subDistrictNameEn": "Rat Phatthana",
    "postcode": "44110",
    "districtCode": "4408",
    "districtNameTh": "พยัคฆภูมิพิสัย",
    "districtNameEn": "Phayakkhaphum Phisai",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440819",
    "subDistrictNameTh": "เมืองเสือ",
    "subDistrictNameEn": "Mueang Suea",
    "postcode": "44110",
    "districtCode": "4408",
    "districtNameTh": "พยัคฆภูมิพิสัย",
    "districtNameEn": "Phayakkhaphum Phisai",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440820",
    "subDistrictNameTh": "ภารแอ่น",
    "subDistrictNameEn": "Phan Aen",
    "postcode": "44110",
    "districtCode": "4408",
    "districtNameTh": "พยัคฆภูมิพิสัย",
    "districtNameEn": "Phayakkhaphum Phisai",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440901",
    "subDistrictNameTh": "หนองแสง",
    "subDistrictNameEn": "Nong Saeng",
    "postcode": "44120",
    "districtCode": "4409",
    "districtNameTh": "วาปีปทุม",
    "districtNameEn": "Wapi Pathum",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440902",
    "subDistrictNameTh": "ขามป้อม",
    "subDistrictNameEn": "Kham Pom",
    "postcode": "44120",
    "districtCode": "4409",
    "districtNameTh": "วาปีปทุม",
    "districtNameEn": "Wapi Pathum",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440903",
    "subDistrictNameTh": "เสือโก้ก",
    "subDistrictNameEn": "Suea Kok",
    "postcode": "44120",
    "districtCode": "4409",
    "districtNameTh": "วาปีปทุม",
    "districtNameEn": "Wapi Pathum",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440904",
    "subDistrictNameTh": "ดงใหญ่",
    "subDistrictNameEn": "Dong Yai",
    "postcode": "44120",
    "districtCode": "4409",
    "districtNameTh": "วาปีปทุม",
    "districtNameEn": "Wapi Pathum",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440905",
    "subDistrictNameTh": "โพธิ์ชัย",
    "subDistrictNameEn": "Pho Chai",
    "postcode": "44120",
    "districtCode": "4409",
    "districtNameTh": "วาปีปทุม",
    "districtNameEn": "Wapi Pathum",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440906",
    "subDistrictNameTh": "หัวเรือ",
    "subDistrictNameEn": "Hua Ruea",
    "postcode": "44120",
    "districtCode": "4409",
    "districtNameTh": "วาปีปทุม",
    "districtNameEn": "Wapi Pathum",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440907",
    "subDistrictNameTh": "แคน",
    "subDistrictNameEn": "Khaen",
    "postcode": "44120",
    "districtCode": "4409",
    "districtNameTh": "วาปีปทุม",
    "districtNameEn": "Wapi Pathum",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440908",
    "subDistrictNameTh": "งัวบา",
    "subDistrictNameEn": "Ngua Ba",
    "postcode": "44120",
    "districtCode": "4409",
    "districtNameTh": "วาปีปทุม",
    "districtNameEn": "Wapi Pathum",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440909",
    "subDistrictNameTh": "นาข่า",
    "subDistrictNameEn": "Na Kha",
    "postcode": "44120",
    "districtCode": "4409",
    "districtNameTh": "วาปีปทุม",
    "districtNameEn": "Wapi Pathum",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440910",
    "subDistrictNameTh": "บ้านหวาย",
    "subDistrictNameEn": "Ban Wai",
    "postcode": "44120",
    "districtCode": "4409",
    "districtNameTh": "วาปีปทุม",
    "districtNameEn": "Wapi Pathum",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440911",
    "subDistrictNameTh": "หนองไฮ",
    "subDistrictNameEn": "Nong Hai",
    "postcode": "44120",
    "districtCode": "4409",
    "districtNameTh": "วาปีปทุม",
    "districtNameEn": "Wapi Pathum",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440912",
    "subDistrictNameTh": "ประชาพัฒนา",
    "subDistrictNameEn": "Pracha Phatthana",
    "postcode": "44120",
    "districtCode": "4409",
    "districtNameTh": "วาปีปทุม",
    "districtNameEn": "Wapi Pathum",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440913",
    "subDistrictNameTh": "หนองทุ่ม",
    "subDistrictNameEn": "Nong Thum",
    "postcode": "44120",
    "districtCode": "4409",
    "districtNameTh": "วาปีปทุม",
    "districtNameEn": "Wapi Pathum",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440914",
    "subDistrictNameTh": "หนองแสน",
    "subDistrictNameEn": "Nong Saen",
    "postcode": "44120",
    "districtCode": "4409",
    "districtNameTh": "วาปีปทุม",
    "districtNameEn": "Wapi Pathum",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "440915",
    "subDistrictNameTh": "โคกสีทองหลาง",
    "subDistrictNameEn": "Khok Si Thonglang",
    "postcode": "44120",
    "districtCode": "4409",
    "districtNameTh": "วาปีปทุม",
    "districtNameEn": "Wapi Pathum",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "441001",
    "subDistrictNameTh": "นาดูน",
    "subDistrictNameEn": "Na Dun",
    "postcode": "44180",
    "districtCode": "4410",
    "districtNameTh": "นาดูน",
    "districtNameEn": "Na Dun",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "441002",
    "subDistrictNameTh": "หนองไผ่",
    "subDistrictNameEn": "Nong Phai",
    "postcode": "44180",
    "districtCode": "4410",
    "districtNameTh": "นาดูน",
    "districtNameEn": "Na Dun",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "441003",
    "subDistrictNameTh": "หนองคู",
    "subDistrictNameEn": "Nong Khu",
    "postcode": "44180",
    "districtCode": "4410",
    "districtNameTh": "นาดูน",
    "districtNameEn": "Na Dun",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "441004",
    "subDistrictNameTh": "ดงบัง",
    "subDistrictNameEn": "Dong Bang",
    "postcode": "44180",
    "districtCode": "4410",
    "districtNameTh": "นาดูน",
    "districtNameEn": "Na Dun",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "441005",
    "subDistrictNameTh": "ดงดวน",
    "subDistrictNameEn": "Dong Duan",
    "postcode": "44180",
    "districtCode": "4410",
    "districtNameTh": "นาดูน",
    "districtNameEn": "Na Dun",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "441006",
    "subDistrictNameTh": "หัวดง",
    "subDistrictNameEn": "Hua Dong",
    "postcode": "44180",
    "districtCode": "4410",
    "districtNameTh": "นาดูน",
    "districtNameEn": "Na Dun",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "441007",
    "subDistrictNameTh": "ดงยาง",
    "subDistrictNameEn": "Dong Yang",
    "postcode": "44180",
    "districtCode": "4410",
    "districtNameTh": "นาดูน",
    "districtNameEn": "Na Dun",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "441008",
    "subDistrictNameTh": "กู่สันตรัตน์",
    "subDistrictNameEn": "Ku Santarat",
    "postcode": "44180",
    "districtCode": "4410",
    "districtNameTh": "นาดูน",
    "districtNameEn": "Na Dun",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "441009",
    "subDistrictNameTh": "พระธาตุ",
    "subDistrictNameEn": "Phra That",
    "postcode": "44180",
    "districtCode": "4410",
    "districtNameTh": "นาดูน",
    "districtNameEn": "Na Dun",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "441101",
    "subDistrictNameTh": "ยางสีสุราช",
    "subDistrictNameEn": "Yang Si Surat",
    "postcode": "44210",
    "districtCode": "4411",
    "districtNameTh": "ยางสีสุราช",
    "districtNameEn": "Yang Si Surat",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "441102",
    "subDistrictNameTh": "นาภู",
    "subDistrictNameEn": "Na Phu",
    "postcode": "44210",
    "districtCode": "4411",
    "districtNameTh": "ยางสีสุราช",
    "districtNameEn": "Yang Si Surat",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "441103",
    "subDistrictNameTh": "แวงดง",
    "subDistrictNameEn": "Waeng Dong",
    "postcode": "44210",
    "districtCode": "4411",
    "districtNameTh": "ยางสีสุราช",
    "districtNameEn": "Yang Si Surat",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "441104",
    "subDistrictNameTh": "บ้านกู่",
    "subDistrictNameEn": "Ban Ku",
    "postcode": "44210",
    "districtCode": "4411",
    "districtNameTh": "ยางสีสุราช",
    "districtNameEn": "Yang Si Surat",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "441105",
    "subDistrictNameTh": "ดงเมือง",
    "subDistrictNameEn": "Dong Mueang",
    "postcode": "44210",
    "districtCode": "4411",
    "districtNameTh": "ยางสีสุราช",
    "districtNameEn": "Yang Si Surat",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "441106",
    "subDistrictNameTh": "สร้างแซ่ง",
    "subDistrictNameEn": "Sang Saeng",
    "postcode": "44210",
    "districtCode": "4411",
    "districtNameTh": "ยางสีสุราช",
    "districtNameEn": "Yang Si Surat",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "441107",
    "subDistrictNameTh": "หนองบัวสันตุ",
    "subDistrictNameEn": "Nong Bua Santu",
    "postcode": "44210",
    "districtCode": "4411",
    "districtNameTh": "ยางสีสุราช",
    "districtNameEn": "Yang Si Surat",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "441201",
    "subDistrictNameTh": "กุดรัง",
    "subDistrictNameEn": "Kut Rang",
    "postcode": "44130",
    "districtCode": "4412",
    "districtNameTh": "กุดรัง",
    "districtNameEn": "Kut Rang",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "441202",
    "subDistrictNameTh": "นาโพธิ์",
    "subDistrictNameEn": "Na Pho",
    "postcode": "44130",
    "districtCode": "4412",
    "districtNameTh": "กุดรัง",
    "districtNameEn": "Kut Rang",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "441203",
    "subDistrictNameTh": "เลิงแฝก",
    "subDistrictNameEn": "Loeng Faek",
    "postcode": "44130",
    "districtCode": "4412",
    "districtNameTh": "กุดรัง",
    "districtNameEn": "Kut Rang",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "441204",
    "subDistrictNameTh": "หนองแวง",
    "subDistrictNameEn": "Nong Waeng",
    "postcode": "44130",
    "districtCode": "4412",
    "districtNameTh": "กุดรัง",
    "districtNameEn": "Kut Rang",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "441205",
    "subDistrictNameTh": "ห้วยเตย",
    "subDistrictNameEn": "Huai Toei",
    "postcode": "44130",
    "districtCode": "4412",
    "districtNameTh": "กุดรัง",
    "districtNameEn": "Kut Rang",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "441301",
    "subDistrictNameTh": "ชื่นชม",
    "subDistrictNameEn": "Chuen Chom",
    "postcode": "44160",
    "districtCode": "4413",
    "districtNameTh": "ชื่นชม",
    "districtNameEn": "Chuen Chom",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "441302",
    "subDistrictNameTh": "กุดปลาดุก",
    "subDistrictNameEn": "Kut Pla Duk",
    "postcode": "44160",
    "districtCode": "4413",
    "districtNameTh": "ชื่นชม",
    "districtNameEn": "Chuen Chom",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "441303",
    "subDistrictNameTh": "เหล่าดอกไม้",
    "subDistrictNameEn": "Lao Dok Mai",
    "postcode": "44160",
    "districtCode": "4413",
    "districtNameTh": "ชื่นชม",
    "districtNameEn": "Chuen Chom",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "441304",
    "subDistrictNameTh": "หนองกุง",
    "subDistrictNameEn": "Nong Kung",
    "postcode": "44160",
    "districtCode": "4413",
    "districtNameTh": "ชื่นชม",
    "districtNameEn": "Chuen Chom",
    "provinceCode": "44",
    "provinceNameTh": "มหาสารคาม",
    "provinceNameEn": "Mahasarakham"
},
{
    "subDistrictCode": "450101",
    "subDistrictNameTh": "ในเมือง",
    "subDistrictNameEn": "Nai Mueang",
    "postcode": "45000",
    "districtCode": "4501",
    "districtNameTh": "เมืองร้อยเอ็ด",
    "districtNameEn": "Mueang Roi Et",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450102",
    "subDistrictNameTh": "รอบเมือง",
    "subDistrictNameEn": "Rop Mueang",
    "postcode": "45000",
    "districtCode": "4501",
    "districtNameTh": "เมืองร้อยเอ็ด",
    "districtNameEn": "Mueang Roi Et",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450103",
    "subDistrictNameTh": "เหนือเมือง",
    "subDistrictNameEn": "Nuea Mueang",
    "postcode": "45000",
    "districtCode": "4501",
    "districtNameTh": "เมืองร้อยเอ็ด",
    "districtNameEn": "Mueang Roi Et",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450104",
    "subDistrictNameTh": "ขอนแก่น",
    "subDistrictNameEn": "Khon Kaen",
    "postcode": "45000",
    "districtCode": "4501",
    "districtNameTh": "เมืองร้อยเอ็ด",
    "districtNameEn": "Mueang Roi Et",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450105",
    "subDistrictNameTh": "นาโพธิ์",
    "subDistrictNameEn": "Na Pho",
    "postcode": "45000",
    "districtCode": "4501",
    "districtNameTh": "เมืองร้อยเอ็ด",
    "districtNameEn": "Mueang Roi Et",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450106",
    "subDistrictNameTh": "สะอาดสมบูรณ์",
    "subDistrictNameEn": "Sa-at Sombun",
    "postcode": "45000",
    "districtCode": "4501",
    "districtNameTh": "เมืองร้อยเอ็ด",
    "districtNameEn": "Mueang Roi Et",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450108",
    "subDistrictNameTh": "สีแก้ว",
    "subDistrictNameEn": "Si Kaeo",
    "postcode": "45000",
    "districtCode": "4501",
    "districtNameTh": "เมืองร้อยเอ็ด",
    "districtNameEn": "Mueang Roi Et",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450109",
    "subDistrictNameTh": "ปอภาร",
    "subDistrictNameEn": "Po Phan",
    "postcode": "45000",
    "districtCode": "4501",
    "districtNameTh": "เมืองร้อยเอ็ด",
    "districtNameEn": "Mueang Roi Et",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450110",
    "subDistrictNameTh": "โนนรัง",
    "subDistrictNameEn": "Non Rang",
    "postcode": "45000",
    "districtCode": "4501",
    "districtNameTh": "เมืองร้อยเอ็ด",
    "districtNameEn": "Mueang Roi Et",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450117",
    "subDistrictNameTh": "หนองแก้ว",
    "subDistrictNameEn": "Nong Kaeo",
    "postcode": "45000",
    "districtCode": "4501",
    "districtNameTh": "เมืองร้อยเอ็ด",
    "districtNameEn": "Mueang Roi Et",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450118",
    "subDistrictNameTh": "หนองแวง",
    "subDistrictNameEn": "Nong Waeng",
    "postcode": "45000",
    "districtCode": "4501",
    "districtNameTh": "เมืองร้อยเอ็ด",
    "districtNameEn": "Mueang Roi Et",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450120",
    "subDistrictNameTh": "ดงลาน",
    "subDistrictNameEn": "Dong Lan",
    "postcode": "45000",
    "districtCode": "4501",
    "districtNameTh": "เมืองร้อยเอ็ด",
    "districtNameEn": "Mueang Roi Et",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450123",
    "subDistrictNameTh": "แคนใหญ่",
    "subDistrictNameEn": "Khaen Yai",
    "postcode": "45000",
    "districtCode": "4501",
    "districtNameTh": "เมืองร้อยเอ็ด",
    "districtNameEn": "Mueang Roi Et",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450124",
    "subDistrictNameTh": "โนนตาล",
    "subDistrictNameEn": "Non Tan",
    "postcode": "45000",
    "districtCode": "4501",
    "districtNameTh": "เมืองร้อยเอ็ด",
    "districtNameEn": "Mueang Roi Et",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450125",
    "subDistrictNameTh": "เมืองทอง",
    "subDistrictNameEn": "Mueang Thong",
    "postcode": "45000",
    "districtCode": "4501",
    "districtNameTh": "เมืองร้อยเอ็ด",
    "districtNameEn": "Mueang Roi Et",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450201",
    "subDistrictNameTh": "เกษตรวิสัย",
    "subDistrictNameEn": "Kaset Wisai",
    "postcode": "45150",
    "districtCode": "4502",
    "districtNameTh": "เกษตรวิสัย",
    "districtNameEn": "Kaset Wisai",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450202",
    "subDistrictNameTh": "เมืองบัว",
    "subDistrictNameEn": "Mueang Bua",
    "postcode": "45150",
    "districtCode": "4502",
    "districtNameTh": "เกษตรวิสัย",
    "districtNameEn": "Kaset Wisai",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450203",
    "subDistrictNameTh": "เหล่าหลวง",
    "subDistrictNameEn": "Lao Luang",
    "postcode": "45150",
    "districtCode": "4502",
    "districtNameTh": "เกษตรวิสัย",
    "districtNameEn": "Kaset Wisai",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450204",
    "subDistrictNameTh": "สิงห์โคก",
    "subDistrictNameEn": "Sing Khok",
    "postcode": "45150",
    "districtCode": "4502",
    "districtNameTh": "เกษตรวิสัย",
    "districtNameEn": "Kaset Wisai",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450205",
    "subDistrictNameTh": "ดงครั่งใหญ่",
    "subDistrictNameEn": "Dong Khrang Yai",
    "postcode": "45150",
    "districtCode": "4502",
    "districtNameTh": "เกษตรวิสัย",
    "districtNameEn": "Kaset Wisai",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450206",
    "subDistrictNameTh": "บ้านฝาง",
    "subDistrictNameEn": "Ban Fang",
    "postcode": "45150",
    "districtCode": "4502",
    "districtNameTh": "เกษตรวิสัย",
    "districtNameEn": "Kaset Wisai",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450207",
    "subDistrictNameTh": "หนองแวง",
    "subDistrictNameEn": "Nong Waeng",
    "postcode": "45150",
    "districtCode": "4502",
    "districtNameTh": "เกษตรวิสัย",
    "districtNameEn": "Kaset Wisai",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450208",
    "subDistrictNameTh": "กำแพง",
    "subDistrictNameEn": "Kamphaeng",
    "postcode": "45150",
    "districtCode": "4502",
    "districtNameTh": "เกษตรวิสัย",
    "districtNameEn": "Kaset Wisai",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450209",
    "subDistrictNameTh": "กู่กาสิงห์",
    "subDistrictNameEn": "Ku Ka Sing",
    "postcode": "45150",
    "districtCode": "4502",
    "districtNameTh": "เกษตรวิสัย",
    "districtNameEn": "Kaset Wisai",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450210",
    "subDistrictNameTh": "น้ำอ้อม",
    "subDistrictNameEn": "Nam Om",
    "postcode": "45150",
    "districtCode": "4502",
    "districtNameTh": "เกษตรวิสัย",
    "districtNameEn": "Kaset Wisai",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450211",
    "subDistrictNameTh": "โนนสว่าง",
    "subDistrictNameEn": "Non Sawang",
    "postcode": "45150",
    "districtCode": "4502",
    "districtNameTh": "เกษตรวิสัย",
    "districtNameEn": "Kaset Wisai",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450212",
    "subDistrictNameTh": "ทุ่งทอง",
    "subDistrictNameEn": "Thung Thong",
    "postcode": "45150",
    "districtCode": "4502",
    "districtNameTh": "เกษตรวิสัย",
    "districtNameEn": "Kaset Wisai",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450213",
    "subDistrictNameTh": "ดงครั่งน้อย",
    "subDistrictNameEn": "Dong Khrang Noi",
    "postcode": "45150",
    "districtCode": "4502",
    "districtNameTh": "เกษตรวิสัย",
    "districtNameEn": "Kaset Wisai",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450301",
    "subDistrictNameTh": "บัวแดง",
    "subDistrictNameEn": "Bua Daeng",
    "postcode": "45190",
    "districtCode": "4503",
    "districtNameTh": "ปทุมรัตต์",
    "districtNameEn": "Pathum Rat",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450302",
    "subDistrictNameTh": "ดอกล้ำ",
    "subDistrictNameEn": "Dok Lam",
    "postcode": "45190",
    "districtCode": "4503",
    "districtNameTh": "ปทุมรัตต์",
    "districtNameEn": "Pathum Rat",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450303",
    "subDistrictNameTh": "หนองแคน",
    "subDistrictNameEn": "Nong Khaen",
    "postcode": "45190",
    "districtCode": "4503",
    "districtNameTh": "ปทุมรัตต์",
    "districtNameEn": "Pathum Rat",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450304",
    "subDistrictNameTh": "โพนสูง",
    "subDistrictNameEn": "Phon Sung",
    "postcode": "45190",
    "districtCode": "4503",
    "districtNameTh": "ปทุมรัตต์",
    "districtNameEn": "Pathum Rat",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450305",
    "subDistrictNameTh": "โนนสวรรค์",
    "subDistrictNameEn": "Non Sawan",
    "postcode": "45190",
    "districtCode": "4503",
    "districtNameTh": "ปทุมรัตต์",
    "districtNameEn": "Pathum Rat",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450306",
    "subDistrictNameTh": "สระบัว",
    "subDistrictNameEn": "Sa Bua",
    "postcode": "45190",
    "districtCode": "4503",
    "districtNameTh": "ปทุมรัตต์",
    "districtNameEn": "Pathum Rat",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450307",
    "subDistrictNameTh": "โนนสง่า",
    "subDistrictNameEn": "Non Sa-nga",
    "postcode": "45190",
    "districtCode": "4503",
    "districtNameTh": "ปทุมรัตต์",
    "districtNameEn": "Pathum Rat",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450308",
    "subDistrictNameTh": "ขี้เหล็ก",
    "subDistrictNameEn": "Khilek",
    "postcode": "45190",
    "districtCode": "4503",
    "districtNameTh": "ปทุมรัตต์",
    "districtNameEn": "Pathum Rat",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450401",
    "subDistrictNameTh": "หัวช้าง",
    "subDistrictNameEn": "Hua Chang",
    "postcode": "45180",
    "districtCode": "4504",
    "districtNameTh": "จตุรพักตรพิมาน",
    "districtNameEn": "Chaturaphak Phiman",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450402",
    "subDistrictNameTh": "หนองผือ",
    "subDistrictNameEn": "Nong Phue",
    "postcode": "45180",
    "districtCode": "4504",
    "districtNameTh": "จตุรพักตรพิมาน",
    "districtNameEn": "Chaturaphak Phiman",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450403",
    "subDistrictNameTh": "เมืองหงส์",
    "subDistrictNameEn": "Mueang Hong",
    "postcode": "45180",
    "districtCode": "4504",
    "districtNameTh": "จตุรพักตรพิมาน",
    "districtNameEn": "Chaturaphak Phiman",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450404",
    "subDistrictNameTh": "โคกล่าม",
    "subDistrictNameEn": "Khok Lam",
    "postcode": "45180",
    "districtCode": "4504",
    "districtNameTh": "จตุรพักตรพิมาน",
    "districtNameEn": "Chaturaphak Phiman",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450405",
    "subDistrictNameTh": "น้ำใส",
    "subDistrictNameEn": "Nam Sai",
    "postcode": "45180",
    "districtCode": "4504",
    "districtNameTh": "จตุรพักตรพิมาน",
    "districtNameEn": "Chaturaphak Phiman",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450406",
    "subDistrictNameTh": "ดงแดง",
    "subDistrictNameEn": "Dong Daeng",
    "postcode": "45180",
    "districtCode": "4504",
    "districtNameTh": "จตุรพักตรพิมาน",
    "districtNameEn": "Chaturaphak Phiman",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450407",
    "subDistrictNameTh": "ดงกลาง",
    "subDistrictNameEn": "Dong Klang",
    "postcode": "45180",
    "districtCode": "4504",
    "districtNameTh": "จตุรพักตรพิมาน",
    "districtNameEn": "Chaturaphak Phiman",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450408",
    "subDistrictNameTh": "ป่าสังข์",
    "subDistrictNameEn": "Pa Sang",
    "postcode": "45180",
    "districtCode": "4504",
    "districtNameTh": "จตุรพักตรพิมาน",
    "districtNameEn": "Chaturaphak Phiman",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450409",
    "subDistrictNameTh": "อีง่อง",
    "subDistrictNameEn": "I Ngong",
    "postcode": "45180",
    "districtCode": "4504",
    "districtNameTh": "จตุรพักตรพิมาน",
    "districtNameEn": "Chaturaphak Phiman",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450410",
    "subDistrictNameTh": "ลิ้นฟ้า",
    "subDistrictNameEn": "Lin Fa",
    "postcode": "45180",
    "districtCode": "4504",
    "districtNameTh": "จตุรพักตรพิมาน",
    "districtNameEn": "Chaturaphak Phiman",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450411",
    "subDistrictNameTh": "ดู่น้อย",
    "subDistrictNameEn": "Du Noi",
    "postcode": "45180",
    "districtCode": "4504",
    "districtNameTh": "จตุรพักตรพิมาน",
    "districtNameEn": "Chaturaphak Phiman",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450412",
    "subDistrictNameTh": "ศรีโคตร",
    "subDistrictNameEn": "Si Khot",
    "postcode": "45180",
    "districtCode": "4504",
    "districtNameTh": "จตุรพักตรพิมาน",
    "districtNameEn": "Chaturaphak Phiman",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450501",
    "subDistrictNameTh": "นิเวศน์",
    "subDistrictNameEn": "Niwet",
    "postcode": "45170",
    "districtCode": "4505",
    "districtNameTh": "ธวัชบุรี",
    "districtNameEn": "Thawat Buri",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450502",
    "subDistrictNameTh": "ธงธานี",
    "subDistrictNameEn": "Thong Thani",
    "postcode": "45170",
    "districtCode": "4505",
    "districtNameTh": "ธวัชบุรี",
    "districtNameEn": "Thawat Buri",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450503",
    "subDistrictNameTh": "หนองไผ่",
    "subDistrictNameEn": "Nong Phai",
    "postcode": "45170",
    "districtCode": "4505",
    "districtNameTh": "ธวัชบุรี",
    "districtNameEn": "Thawat Buri",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450504",
    "subDistrictNameTh": "ธวัชบุรี",
    "subDistrictNameEn": "Thawat Buri",
    "postcode": "45170",
    "districtCode": "4505",
    "districtNameTh": "ธวัชบุรี",
    "districtNameEn": "Thawat Buri",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450506",
    "subDistrictNameTh": "อุ่มเม้า",
    "subDistrictNameEn": "Um Mao",
    "postcode": "45170",
    "districtCode": "4505",
    "districtNameTh": "ธวัชบุรี",
    "districtNameEn": "Thawat Buri",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450507",
    "subDistrictNameTh": "มะอึ",
    "subDistrictNameEn": "Ma-ue",
    "postcode": "45170",
    "districtCode": "4505",
    "districtNameTh": "ธวัชบุรี",
    "districtNameEn": "Thawat Buri",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450510",
    "subDistrictNameTh": "เขวาทุ่ง",
    "subDistrictNameEn": "Khwao Thung",
    "postcode": "45170",
    "districtCode": "4505",
    "districtNameTh": "ธวัชบุรี",
    "districtNameEn": "Thawat Buri",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450515",
    "subDistrictNameTh": "ไพศาล",
    "subDistrictNameEn": "Phaisan",
    "postcode": "45170",
    "districtCode": "4505",
    "districtNameTh": "ธวัชบุรี",
    "districtNameEn": "Thawat Buri",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450517",
    "subDistrictNameTh": "เมืองน้อย",
    "subDistrictNameEn": "Mueang Noi",
    "postcode": "45170",
    "districtCode": "4505",
    "districtNameTh": "ธวัชบุรี",
    "districtNameEn": "Thawat Buri",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450520",
    "subDistrictNameTh": "บึงนคร",
    "subDistrictNameEn": "Bueng Nakhon",
    "postcode": "45170",
    "districtCode": "4505",
    "districtNameTh": "ธวัชบุรี",
    "districtNameEn": "Thawat Buri",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450522",
    "subDistrictNameTh": "ราชธานี",
    "subDistrictNameEn": "Ratchathani",
    "postcode": "45170",
    "districtCode": "4505",
    "districtNameTh": "ธวัชบุรี",
    "districtNameEn": "Thawat Buri",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450524",
    "subDistrictNameTh": "หนองพอก",
    "subDistrictNameEn": "Nong Phok",
    "postcode": "45170",
    "districtCode": "4505",
    "districtNameTh": "ธวัชบุรี",
    "districtNameEn": "Thawat Buri",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450601",
    "subDistrictNameTh": "พนมไพร",
    "subDistrictNameEn": "Phanom Phrai",
    "postcode": "45140",
    "districtCode": "4506",
    "districtNameTh": "พนมไพร",
    "districtNameEn": "Phanom Phrai",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450602",
    "subDistrictNameTh": "แสนสุข",
    "subDistrictNameEn": "Saen Suk",
    "postcode": "45140",
    "districtCode": "4506",
    "districtNameTh": "พนมไพร",
    "districtNameEn": "Phanom Phrai",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450603",
    "subDistrictNameTh": "กุดน้ำใส",
    "subDistrictNameEn": "Kut Nam Sai",
    "postcode": "45140",
    "districtCode": "4506",
    "districtNameTh": "พนมไพร",
    "districtNameEn": "Phanom Phrai",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450604",
    "subDistrictNameTh": "หนองทัพไทย",
    "subDistrictNameEn": "Nong Thap Thai",
    "postcode": "45140",
    "districtCode": "4506",
    "districtNameTh": "พนมไพร",
    "districtNameEn": "Phanom Phrai",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450605",
    "subDistrictNameTh": "โพธิ์ใหญ่",
    "subDistrictNameEn": "Pho Yai",
    "postcode": "45140",
    "districtCode": "4506",
    "districtNameTh": "พนมไพร",
    "districtNameEn": "Phanom Phrai",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450606",
    "subDistrictNameTh": "วารีสวัสดิ์",
    "subDistrictNameEn": "Wari Sawat",
    "postcode": "45140",
    "districtCode": "4506",
    "districtNameTh": "พนมไพร",
    "districtNameEn": "Phanom Phrai",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450607",
    "subDistrictNameTh": "โคกสว่าง",
    "subDistrictNameEn": "Khok Sawang",
    "postcode": "45140",
    "districtCode": "4506",
    "districtNameTh": "พนมไพร",
    "districtNameEn": "Phanom Phrai",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450611",
    "subDistrictNameTh": "โพธิ์ชัย",
    "subDistrictNameEn": "Pho Chai",
    "postcode": "45140",
    "districtCode": "4506",
    "districtNameTh": "พนมไพร",
    "districtNameEn": "Phanom Phrai",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450612",
    "subDistrictNameTh": "นานวล",
    "subDistrictNameEn": "Na Nuan",
    "postcode": "45140",
    "districtCode": "4506",
    "districtNameTh": "พนมไพร",
    "districtNameEn": "Phanom Phrai",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450613",
    "subDistrictNameTh": "คำไฮ",
    "subDistrictNameEn": "Kham Hai",
    "postcode": "45140",
    "districtCode": "4506",
    "districtNameTh": "พนมไพร",
    "districtNameEn": "Phanom Phrai",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450614",
    "subDistrictNameTh": "สระแก้ว",
    "subDistrictNameEn": "Sa Kaeo",
    "postcode": "45140",
    "districtCode": "4506",
    "districtNameTh": "พนมไพร",
    "districtNameEn": "Phanom Phrai",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450615",
    "subDistrictNameTh": "ค้อใหญ่",
    "subDistrictNameEn": "Kho Yai",
    "postcode": "45140",
    "districtCode": "4506",
    "districtNameTh": "พนมไพร",
    "districtNameEn": "Phanom Phrai",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450617",
    "subDistrictNameTh": "ชานุวรรณ",
    "subDistrictNameEn": "Chanuwan",
    "postcode": "45140",
    "districtCode": "4506",
    "districtNameTh": "พนมไพร",
    "districtNameEn": "Phanom Phrai",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450701",
    "subDistrictNameTh": "แวง",
    "subDistrictNameEn": "Waeng",
    "postcode": "45110",
    "districtCode": "4507",
    "districtNameTh": "โพนทอง",
    "districtNameEn": "Phon Thong",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450702",
    "subDistrictNameTh": "โคกกกม่วง",
    "subDistrictNameEn": "Khok Kok Muang",
    "postcode": "45110",
    "districtCode": "4507",
    "districtNameTh": "โพนทอง",
    "districtNameEn": "Phon Thong",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450703",
    "subDistrictNameTh": "นาอุดม",
    "subDistrictNameEn": "Na Udom",
    "postcode": "45110",
    "districtCode": "4507",
    "districtNameTh": "โพนทอง",
    "districtNameEn": "Phon Thong",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450704",
    "subDistrictNameTh": "สว่าง",
    "subDistrictNameEn": "Sawang",
    "postcode": "45110",
    "districtCode": "4507",
    "districtNameTh": "โพนทอง",
    "districtNameEn": "Phon Thong",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450705",
    "subDistrictNameTh": "หนองใหญ่",
    "subDistrictNameEn": "Nong Yai",
    "postcode": "45110",
    "districtCode": "4507",
    "districtNameTh": "โพนทอง",
    "districtNameEn": "Phon Thong",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450706",
    "subDistrictNameTh": "โพธิ์ทอง",
    "subDistrictNameEn": "Pho Thong",
    "postcode": "45110",
    "districtCode": "4507",
    "districtNameTh": "โพนทอง",
    "districtNameEn": "Phon Thong",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450707",
    "subDistrictNameTh": "โนนชัยศรี",
    "subDistrictNameEn": "Non Chai Si",
    "postcode": "45110",
    "districtCode": "4507",
    "districtNameTh": "โพนทอง",
    "districtNameEn": "Phon Thong",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450708",
    "subDistrictNameTh": "โพธิ์ศรีสว่าง",
    "subDistrictNameEn": "Pho Si Sawang",
    "postcode": "45110",
    "districtCode": "4507",
    "districtNameTh": "โพนทอง",
    "districtNameEn": "Phon Thong",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450709",
    "subDistrictNameTh": "อุ่มเม่า",
    "subDistrictNameEn": "Um Mao",
    "postcode": "45110",
    "districtCode": "4507",
    "districtNameTh": "โพนทอง",
    "districtNameEn": "Phon Thong",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450710",
    "subDistrictNameTh": "คำนาดี",
    "subDistrictNameEn": "Na Di",
    "postcode": "45110",
    "districtCode": "4507",
    "districtNameTh": "โพนทอง",
    "districtNameEn": "Phon Thong",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450711",
    "subDistrictNameTh": "พรมสวรรค์",
    "subDistrictNameEn": "Phrom Sawan",
    "postcode": "45110",
    "districtCode": "4507",
    "districtNameTh": "โพนทอง",
    "districtNameEn": "Phon Thong",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450712",
    "subDistrictNameTh": "สระนกแก้ว",
    "subDistrictNameEn": "Sa Nok Kaeo",
    "postcode": "45110",
    "districtCode": "4507",
    "districtNameTh": "โพนทอง",
    "districtNameEn": "Phon Thong",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450713",
    "subDistrictNameTh": "วังสามัคคี",
    "subDistrictNameEn": "Wang Samakkhi",
    "postcode": "45110",
    "districtCode": "4507",
    "districtNameTh": "โพนทอง",
    "districtNameEn": "Phon Thong",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450714",
    "subDistrictNameTh": "โคกสูง",
    "subDistrictNameEn": "Khok Sung",
    "postcode": "45110",
    "districtCode": "4507",
    "districtNameTh": "โพนทอง",
    "districtNameEn": "Phon Thong",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450801",
    "subDistrictNameTh": "ขามเปี้ย",
    "subDistrictNameEn": "Kham Pia",
    "postcode": "45230",
    "districtCode": "4508",
    "districtNameTh": "โพธิ์ชัย",
    "districtNameEn": "Pho Chai",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450802",
    "subDistrictNameTh": "เชียงใหม่",
    "subDistrictNameEn": "Chiang Mai",
    "postcode": "45230",
    "districtCode": "4508",
    "districtNameTh": "โพธิ์ชัย",
    "districtNameEn": "Pho Chai",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450803",
    "subDistrictNameTh": "บัวคำ",
    "subDistrictNameEn": "Bua Kham",
    "postcode": "45230",
    "districtCode": "4508",
    "districtNameTh": "โพธิ์ชัย",
    "districtNameEn": "Pho Chai",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450804",
    "subDistrictNameTh": "อัคคะคำ",
    "subDistrictNameEn": "Akkha Kham",
    "postcode": "45230",
    "districtCode": "4508",
    "districtNameTh": "โพธิ์ชัย",
    "districtNameEn": "Pho Chai",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450805",
    "subDistrictNameTh": "สะอาด",
    "subDistrictNameEn": "Sa-at",
    "postcode": "45230",
    "districtCode": "4508",
    "districtNameTh": "โพธิ์ชัย",
    "districtNameEn": "Pho Chai",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450806",
    "subDistrictNameTh": "คำพอุง",
    "subDistrictNameEn": "Kham Pha-ung",
    "postcode": "45230",
    "districtCode": "4508",
    "districtNameTh": "โพธิ์ชัย",
    "districtNameEn": "Pho Chai",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450807",
    "subDistrictNameTh": "หนองตาไก้",
    "subDistrictNameEn": "Nong Ta Kai",
    "postcode": "45230",
    "districtCode": "4508",
    "districtNameTh": "โพธิ์ชัย",
    "districtNameEn": "Pho Chai",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450808",
    "subDistrictNameTh": "ดอนโอง",
    "subDistrictNameEn": "Don Ong",
    "postcode": "45230",
    "districtCode": "4508",
    "districtNameTh": "โพธิ์ชัย",
    "districtNameEn": "Pho Chai",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450809",
    "subDistrictNameTh": "โพธิ์ศรี",
    "subDistrictNameEn": "Pho Si",
    "postcode": "45230",
    "districtCode": "4508",
    "districtNameTh": "โพธิ์ชัย",
    "districtNameEn": "Pho Chai",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450901",
    "subDistrictNameTh": "หนองพอก",
    "subDistrictNameEn": "Nong Phok",
    "postcode": "45210",
    "districtCode": "4509",
    "districtNameTh": "หนองพอก",
    "districtNameEn": "Nong Phok",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450902",
    "subDistrictNameTh": "บึงงาม",
    "subDistrictNameEn": "Bueng Ngam",
    "postcode": "45210",
    "districtCode": "4509",
    "districtNameTh": "หนองพอก",
    "districtNameEn": "Nong Phok",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450903",
    "subDistrictNameTh": "ภูเขาทอง",
    "subDistrictNameEn": "Phukhao Thong",
    "postcode": "45210",
    "districtCode": "4509",
    "districtNameTh": "หนองพอก",
    "districtNameEn": "Nong Phok",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450904",
    "subDistrictNameTh": "กกโพธิ์",
    "subDistrictNameEn": "Kok Pho",
    "postcode": "45210",
    "districtCode": "4509",
    "districtNameTh": "หนองพอก",
    "districtNameEn": "Nong Phok",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450905",
    "subDistrictNameTh": "โคกสว่าง",
    "subDistrictNameEn": "Khok Sawang",
    "postcode": "45210",
    "districtCode": "4509",
    "districtNameTh": "หนองพอก",
    "districtNameEn": "Nong Phok",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450906",
    "subDistrictNameTh": "หนองขุ่นใหญ่",
    "subDistrictNameEn": "Nong Khun Yai",
    "postcode": "45210",
    "districtCode": "4509",
    "districtNameTh": "หนองพอก",
    "districtNameEn": "Nong Phok",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450907",
    "subDistrictNameTh": "รอบเมือง",
    "subDistrictNameEn": "Rop Mueang",
    "postcode": "45210",
    "districtCode": "4509",
    "districtNameTh": "หนองพอก",
    "districtNameEn": "Nong Phok",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450908",
    "subDistrictNameTh": "ผาน้ำย้อย",
    "subDistrictNameEn": "Pha Nam Yoi",
    "postcode": "45210",
    "districtCode": "4509",
    "districtNameTh": "หนองพอก",
    "districtNameEn": "Nong Phok",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "450909",
    "subDistrictNameTh": "ท่าสีดา",
    "subDistrictNameEn": "Tha Sida",
    "postcode": "45210",
    "districtCode": "4509",
    "districtNameTh": "หนองพอก",
    "districtNameEn": "Nong Phok",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451001",
    "subDistrictNameTh": "กลาง",
    "subDistrictNameEn": "Klang",
    "postcode": "45120",
    "districtCode": "4510",
    "districtNameTh": "เสลภูมิ",
    "districtNameEn": "Selaphum",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451002",
    "subDistrictNameTh": "นางาม",
    "subDistrictNameEn": "Na Ngam",
    "postcode": "45120",
    "districtCode": "4510",
    "districtNameTh": "เสลภูมิ",
    "districtNameEn": "Selaphum",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451003",
    "subDistrictNameTh": "เมืองไพร",
    "subDistrictNameEn": "Mueang Phrai",
    "postcode": "45120",
    "districtCode": "4510",
    "districtNameTh": "เสลภูมิ",
    "districtNameEn": "Selaphum",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451004",
    "subDistrictNameTh": "นาแซง",
    "subDistrictNameEn": "Na Saeng",
    "postcode": "45120",
    "districtCode": "4510",
    "districtNameTh": "เสลภูมิ",
    "districtNameEn": "Selaphum",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451005",
    "subDistrictNameTh": "นาเมือง",
    "subDistrictNameEn": "Na Mueang",
    "postcode": "45120",
    "districtCode": "4510",
    "districtNameTh": "เสลภูมิ",
    "districtNameEn": "Selaphum",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451006",
    "subDistrictNameTh": "วังหลวง",
    "subDistrictNameEn": "wang Luang",
    "postcode": "45120",
    "districtCode": "4510",
    "districtNameTh": "เสลภูมิ",
    "districtNameEn": "Selaphum",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451007",
    "subDistrictNameTh": "ท่าม่วง",
    "subDistrictNameEn": "Tha Muang",
    "postcode": "45120",
    "districtCode": "4510",
    "districtNameTh": "เสลภูมิ",
    "districtNameEn": "Selaphum",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451008",
    "subDistrictNameTh": "ขวาว",
    "subDistrictNameEn": "Khwao",
    "postcode": "45120",
    "districtCode": "4510",
    "districtNameTh": "เสลภูมิ",
    "districtNameEn": "Selaphum",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451009",
    "subDistrictNameTh": "โพธิ์ทอง",
    "subDistrictNameEn": "Pho Thong",
    "postcode": "45120",
    "districtCode": "4510",
    "districtNameTh": "เสลภูมิ",
    "districtNameEn": "Selaphum",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451010",
    "subDistrictNameTh": "ภูเงิน",
    "subDistrictNameEn": "Phu Ngoen",
    "postcode": "45120",
    "districtCode": "4510",
    "districtNameTh": "เสลภูมิ",
    "districtNameEn": "Selaphum",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451011",
    "subDistrictNameTh": "เกาะแก้ว",
    "subDistrictNameEn": "Ko Kaeo",
    "postcode": "45120",
    "districtCode": "4510",
    "districtNameTh": "เสลภูมิ",
    "districtNameEn": "Selaphum",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451012",
    "subDistrictNameTh": "นาเลิง",
    "subDistrictNameEn": "Na Loeng",
    "postcode": "45120",
    "districtCode": "4510",
    "districtNameTh": "เสลภูมิ",
    "districtNameEn": "Selaphum",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451013",
    "subDistrictNameTh": "เหล่าน้อย",
    "subDistrictNameEn": "Lao Noi",
    "postcode": "45120",
    "districtCode": "4510",
    "districtNameTh": "เสลภูมิ",
    "districtNameEn": "Selaphum",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451014",
    "subDistrictNameTh": "ศรีวิลัย",
    "subDistrictNameEn": "Si Wilai",
    "postcode": "45120",
    "districtCode": "4510",
    "districtNameTh": "เสลภูมิ",
    "districtNameEn": "Selaphum",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451015",
    "subDistrictNameTh": "หนองหลวง",
    "subDistrictNameEn": "Nong Luang",
    "postcode": "45120",
    "districtCode": "4510",
    "districtNameTh": "เสลภูมิ",
    "districtNameEn": "Selaphum",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451016",
    "subDistrictNameTh": "พรสวรรค์",
    "subDistrictNameEn": "Phon Sawan",
    "postcode": "45120",
    "districtCode": "4510",
    "districtNameTh": "เสลภูมิ",
    "districtNameEn": "Selaphum",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451017",
    "subDistrictNameTh": "ขวัญเมือง",
    "subDistrictNameEn": "Khwan Mueang",
    "postcode": "45120",
    "districtCode": "4510",
    "districtNameTh": "เสลภูมิ",
    "districtNameEn": "Selaphum",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451018",
    "subDistrictNameTh": "บึงเกลือ",
    "subDistrictNameEn": "Bueng Kluea",
    "postcode": "45120",
    "districtCode": "4510",
    "districtNameTh": "เสลภูมิ",
    "districtNameEn": "Selaphum",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451101",
    "subDistrictNameTh": "สระคู",
    "subDistrictNameEn": "Sa Khu",
    "postcode": "45130",
    "districtCode": "4511",
    "districtNameTh": "สุวรรณภูมิ",
    "districtNameEn": "Suwannaphum",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451102",
    "subDistrictNameTh": "ดอกไม้",
    "subDistrictNameEn": "Dok Mai",
    "postcode": "45130",
    "districtCode": "4511",
    "districtNameTh": "สุวรรณภูมิ",
    "districtNameEn": "Suwannaphum",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451103",
    "subDistrictNameTh": "นาใหญ่",
    "subDistrictNameEn": "Na Yai",
    "postcode": "45130",
    "districtCode": "4511",
    "districtNameTh": "สุวรรณภูมิ",
    "districtNameEn": "Suwannaphum",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451104",
    "subDistrictNameTh": "หินกอง",
    "subDistrictNameEn": "Hin Kong",
    "postcode": "45130",
    "districtCode": "4511",
    "districtNameTh": "สุวรรณภูมิ",
    "districtNameEn": "Suwannaphum",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451105",
    "subDistrictNameTh": "เมืองทุ่ง",
    "subDistrictNameEn": "Mueang Thung",
    "postcode": "45130",
    "districtCode": "4511",
    "districtNameTh": "สุวรรณภูมิ",
    "districtNameEn": "Suwannaphum",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451106",
    "subDistrictNameTh": "หัวโทน",
    "subDistrictNameEn": "Hua Thon",
    "postcode": "45130",
    "districtCode": "4511",
    "districtNameTh": "สุวรรณภูมิ",
    "districtNameEn": "Suwannaphum",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451107",
    "subDistrictNameTh": "บ่อพันขัน",
    "subDistrictNameEn": "Bo Phan Khan",
    "postcode": "45130",
    "districtCode": "4511",
    "districtNameTh": "สุวรรณภูมิ",
    "districtNameEn": "Suwannaphum",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451108",
    "subDistrictNameTh": "ทุ่งหลวง",
    "subDistrictNameEn": "Thung Luang",
    "postcode": "45130",
    "districtCode": "4511",
    "districtNameTh": "สุวรรณภูมิ",
    "districtNameEn": "Suwannaphum",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451109",
    "subDistrictNameTh": "หัวช้าง",
    "subDistrictNameEn": "Hua Chang",
    "postcode": "45130",
    "districtCode": "4511",
    "districtNameTh": "สุวรรณภูมิ",
    "districtNameEn": "Suwannaphum",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451110",
    "subDistrictNameTh": "น้ำคำ",
    "subDistrictNameEn": "Nam Kham",
    "postcode": "45130",
    "districtCode": "4511",
    "districtNameTh": "สุวรรณภูมิ",
    "districtNameEn": "Suwannaphum",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451111",
    "subDistrictNameTh": "ห้วยหินลาด",
    "subDistrictNameEn": "Huai Hin Lat",
    "postcode": "45130",
    "districtCode": "4511",
    "districtNameTh": "สุวรรณภูมิ",
    "districtNameEn": "Suwannaphum",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451112",
    "subDistrictNameTh": "ช้างเผือก",
    "subDistrictNameEn": "Chang Phueak",
    "postcode": "45130",
    "districtCode": "4511",
    "districtNameTh": "สุวรรณภูมิ",
    "districtNameEn": "Suwannaphum",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451113",
    "subDistrictNameTh": "ทุ่งกุลา",
    "subDistrictNameEn": "Thung Kula",
    "postcode": "45130",
    "districtCode": "4511",
    "districtNameTh": "สุวรรณภูมิ",
    "districtNameEn": "Suwannaphum",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451114",
    "subDistrictNameTh": "ทุ่งศรีเมือง",
    "subDistrictNameEn": "Thung Si Mueang",
    "postcode": "45130",
    "districtCode": "4511",
    "districtNameTh": "สุวรรณภูมิ",
    "districtNameEn": "Suwannaphum",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451115",
    "subDistrictNameTh": "จำปาขัน",
    "subDistrictNameEn": "Champa Khan",
    "postcode": "45130",
    "districtCode": "4511",
    "districtNameTh": "สุวรรณภูมิ",
    "districtNameEn": "Suwannaphum",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451201",
    "subDistrictNameTh": "หนองผือ",
    "subDistrictNameEn": "Nong Phue",
    "postcode": "45220",
    "districtCode": "4512",
    "districtNameTh": "เมืองสรวง",
    "districtNameEn": "Mueang Suang",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451202",
    "subDistrictNameTh": "หนองหิน",
    "subDistrictNameEn": "Nong Hin",
    "postcode": "45220",
    "districtCode": "4512",
    "districtNameTh": "เมืองสรวง",
    "districtNameEn": "Mueang Suang",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451203",
    "subDistrictNameTh": "คูเมือง",
    "subDistrictNameEn": "Khu Mueang",
    "postcode": "45220",
    "districtCode": "4512",
    "districtNameTh": "เมืองสรวง",
    "districtNameEn": "Mueang Suang",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451204",
    "subDistrictNameTh": "กกกุง",
    "subDistrictNameEn": "Kok Kung",
    "postcode": "45220",
    "districtCode": "4512",
    "districtNameTh": "เมืองสรวง",
    "districtNameEn": "Mueang Suang",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451205",
    "subDistrictNameTh": "เมืองสรวง",
    "subDistrictNameEn": "Mueang Suang",
    "postcode": "45220",
    "districtCode": "4512",
    "districtNameTh": "เมืองสรวง",
    "districtNameEn": "Mueang Suang",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451301",
    "subDistrictNameTh": "โพนทราย",
    "subDistrictNameEn": "Phon Sai",
    "postcode": "45240",
    "districtCode": "4513",
    "districtNameTh": "โพนทราย",
    "districtNameEn": "Phon Sai",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451302",
    "subDistrictNameTh": "สามขา",
    "subDistrictNameEn": "Sam Kha",
    "postcode": "45240",
    "districtCode": "4513",
    "districtNameTh": "โพนทราย",
    "districtNameEn": "Phon Sai",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451303",
    "subDistrictNameTh": "ศรีสว่าง",
    "subDistrictNameEn": "Si Sawang",
    "postcode": "45240",
    "districtCode": "4513",
    "districtNameTh": "โพนทราย",
    "districtNameEn": "Phon Sai",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451304",
    "subDistrictNameTh": "ยางคำ",
    "subDistrictNameEn": "Yang Kham",
    "postcode": "45240",
    "districtCode": "4513",
    "districtNameTh": "โพนทราย",
    "districtNameEn": "Phon Sai",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451305",
    "subDistrictNameTh": "ท่าหาดยาว",
    "subDistrictNameEn": "Tha Hat Yao",
    "postcode": "45240",
    "districtCode": "4513",
    "districtNameTh": "โพนทราย",
    "districtNameEn": "Phon Sai",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451401",
    "subDistrictNameTh": "อาจสามารถ",
    "subDistrictNameEn": "At Samat",
    "postcode": "45160",
    "districtCode": "4514",
    "districtNameTh": "อาจสามารถ",
    "districtNameEn": "At Samat",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451402",
    "subDistrictNameTh": "โพนเมือง",
    "subDistrictNameEn": "Phon Mueang",
    "postcode": "45160",
    "districtCode": "4514",
    "districtNameTh": "อาจสามารถ",
    "districtNameEn": "At Samat",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451403",
    "subDistrictNameTh": "บ้านแจ้ง",
    "subDistrictNameEn": "Ban Chaeng",
    "postcode": "45160",
    "districtCode": "4514",
    "districtNameTh": "อาจสามารถ",
    "districtNameEn": "At Samat",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451404",
    "subDistrictNameTh": "หน่อม",
    "subDistrictNameEn": "Nom",
    "postcode": "45160",
    "districtCode": "4514",
    "districtNameTh": "อาจสามารถ",
    "districtNameEn": "At Samat",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451405",
    "subDistrictNameTh": "หนองหมื่นถ่าน",
    "subDistrictNameEn": "Nong Muen Than",
    "postcode": "45160",
    "districtCode": "4514",
    "districtNameTh": "อาจสามารถ",
    "districtNameEn": "At Samat",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451406",
    "subDistrictNameTh": "หนองขาม",
    "subDistrictNameEn": "Nong Kham",
    "postcode": "45160",
    "districtCode": "4514",
    "districtNameTh": "อาจสามารถ",
    "districtNameEn": "At Samat",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451407",
    "subDistrictNameTh": "โหรา",
    "subDistrictNameEn": "Hora",
    "postcode": "45160",
    "districtCode": "4514",
    "districtNameTh": "อาจสามารถ",
    "districtNameEn": "At Samat",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451408",
    "subDistrictNameTh": "หนองบัว",
    "subDistrictNameEn": "Nong Bua",
    "postcode": "45160",
    "districtCode": "4514",
    "districtNameTh": "อาจสามารถ",
    "districtNameEn": "At Samat",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451409",
    "subDistrictNameTh": "ขี้เหล็ก",
    "subDistrictNameEn": "Khilek",
    "postcode": "45160",
    "districtCode": "4514",
    "districtNameTh": "อาจสามารถ",
    "districtNameEn": "At Samat",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451410",
    "subDistrictNameTh": "บ้านดู่",
    "subDistrictNameEn": "Ban Du",
    "postcode": "45160",
    "districtCode": "4514",
    "districtNameTh": "อาจสามารถ",
    "districtNameEn": "At Samat",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451501",
    "subDistrictNameTh": "เมยวดี",
    "subDistrictNameEn": "Moei Wadi",
    "postcode": "45250",
    "districtCode": "4515",
    "districtNameTh": "เมยวดี",
    "districtNameEn": "Moei Wadi",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451502",
    "subDistrictNameTh": "ชุมพร",
    "subDistrictNameEn": "Chumphon",
    "postcode": "45250",
    "districtCode": "4515",
    "districtNameTh": "เมยวดี",
    "districtNameEn": "Moei Wadi",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451503",
    "subDistrictNameTh": "บุ่งเลิศ",
    "subDistrictNameEn": "Bung Loet",
    "postcode": "45250",
    "districtCode": "4515",
    "districtNameTh": "เมยวดี",
    "districtNameEn": "Moei Wadi",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451504",
    "subDistrictNameTh": "ชมสะอาด",
    "subDistrictNameEn": "Chom Sa-at",
    "postcode": "45250",
    "districtCode": "4515",
    "districtNameTh": "เมยวดี",
    "districtNameEn": "Moei Wadi",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451601",
    "subDistrictNameTh": "โพธิ์ทอง",
    "subDistrictNameEn": "Pho Thong",
    "postcode": "45000",
    "districtCode": "4516",
    "districtNameTh": "ศรีสมเด็จ",
    "districtNameEn": "Si Somdet",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451602",
    "subDistrictNameTh": "ศรีสมเด็จ",
    "subDistrictNameEn": "Si Somdet",
    "postcode": "45000",
    "districtCode": "4516",
    "districtNameTh": "ศรีสมเด็จ",
    "districtNameEn": "Si Somdet",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451603",
    "subDistrictNameTh": "เมืองเปลือย",
    "subDistrictNameEn": "Mueang Plueai",
    "postcode": "45000",
    "districtCode": "4516",
    "districtNameTh": "ศรีสมเด็จ",
    "districtNameEn": "Si Somdet",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451604",
    "subDistrictNameTh": "หนองใหญ่",
    "subDistrictNameEn": "Nong Yai",
    "postcode": "45000",
    "districtCode": "4516",
    "districtNameTh": "ศรีสมเด็จ",
    "districtNameEn": "Si Somdet",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451605",
    "subDistrictNameTh": "สวนจิก",
    "subDistrictNameEn": "Suan Chik",
    "postcode": "45280",
    "districtCode": "4516",
    "districtNameTh": "ศรีสมเด็จ",
    "districtNameEn": "Si Somdet",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451606",
    "subDistrictNameTh": "โพธิ์สัย",
    "subDistrictNameEn": "Pho Sai",
    "postcode": "45280",
    "districtCode": "4516",
    "districtNameTh": "ศรีสมเด็จ",
    "districtNameEn": "Si Somdet",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451607",
    "subDistrictNameTh": "หนองแวงควง",
    "subDistrictNameEn": "Nong Waeng Khuang",
    "postcode": "45000",
    "districtCode": "4516",
    "districtNameTh": "ศรีสมเด็จ",
    "districtNameEn": "Si Somdet",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451608",
    "subDistrictNameTh": "บ้านบาก",
    "subDistrictNameEn": "Ban Bak",
    "postcode": "45000",
    "districtCode": "4516",
    "districtNameTh": "ศรีสมเด็จ",
    "districtNameEn": "Si Somdet",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451701",
    "subDistrictNameTh": "ดินดำ",
    "subDistrictNameEn": "Din Dam",
    "postcode": "45000",
    "districtCode": "4517",
    "districtNameTh": "จังหาร",
    "districtNameEn": "Changhan",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451702",
    "subDistrictNameTh": "ปาฝา",
    "subDistrictNameEn": "Pa Fa",
    "postcode": "45000",
    "districtCode": "4517",
    "districtNameTh": "จังหาร",
    "districtNameEn": "Changhan",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451703",
    "subDistrictNameTh": "ม่วงลาด",
    "subDistrictNameEn": "Muang Lat",
    "postcode": "45000",
    "districtCode": "4517",
    "districtNameTh": "จังหาร",
    "districtNameEn": "Changhan",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451704",
    "subDistrictNameTh": "จังหาร",
    "subDistrictNameEn": "Changhan",
    "postcode": "45000",
    "districtCode": "4517",
    "districtNameTh": "จังหาร",
    "districtNameEn": "Changhan",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451705",
    "subDistrictNameTh": "ดงสิงห์",
    "subDistrictNameEn": "Dong Sing",
    "postcode": "45000",
    "districtCode": "4517",
    "districtNameTh": "จังหาร",
    "districtNameEn": "Changhan",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451706",
    "subDistrictNameTh": "ยางใหญ่",
    "subDistrictNameEn": "Yang Yai",
    "postcode": "45000",
    "districtCode": "4517",
    "districtNameTh": "จังหาร",
    "districtNameEn": "Changhan",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451707",
    "subDistrictNameTh": "ผักแว่น",
    "subDistrictNameEn": "Phak Waen",
    "postcode": "45000",
    "districtCode": "4517",
    "districtNameTh": "จังหาร",
    "districtNameEn": "Changhan",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451708",
    "subDistrictNameTh": "แสนชาติ",
    "subDistrictNameEn": "Saen Chat",
    "postcode": "45000",
    "districtCode": "4517",
    "districtNameTh": "จังหาร",
    "districtNameEn": "Changhan",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451801",
    "subDistrictNameTh": "เชียงขวัญ",
    "subDistrictNameEn": "Chiang Khwan",
    "postcode": "45000",
    "districtCode": "4518",
    "districtNameTh": "เชียงขวัญ",
    "districtNameEn": "Chiang Khwan",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451802",
    "subDistrictNameTh": "พลับพลา",
    "subDistrictNameEn": "Phlapphla",
    "postcode": "45170",
    "districtCode": "4518",
    "districtNameTh": "เชียงขวัญ",
    "districtNameEn": "Chiang Khwan",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451803",
    "subDistrictNameTh": "พระธาตุ",
    "subDistrictNameEn": "Phra That",
    "postcode": "45000",
    "districtCode": "4518",
    "districtNameTh": "เชียงขวัญ",
    "districtNameEn": "Chiang Khwan",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451804",
    "subDistrictNameTh": "พระเจ้า",
    "subDistrictNameEn": "Phra Chao",
    "postcode": "45000",
    "districtCode": "4518",
    "districtNameTh": "เชียงขวัญ",
    "districtNameEn": "Chiang Khwan",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451805",
    "subDistrictNameTh": "หมูม้น",
    "subDistrictNameEn": "Mu Mon",
    "postcode": "45170",
    "districtCode": "4518",
    "districtNameTh": "เชียงขวัญ",
    "districtNameEn": "Chiang Khwan",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451806",
    "subDistrictNameTh": "บ้านเขือง",
    "subDistrictNameEn": "Ban Khueang",
    "postcode": "45000",
    "districtCode": "4518",
    "districtNameTh": "เชียงขวัญ",
    "districtNameEn": "Chiang Khwan",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451901",
    "subDistrictNameTh": "หนองฮี",
    "subDistrictNameEn": "Nong Hi",
    "postcode": "45140",
    "districtCode": "4519",
    "districtNameTh": "หนองฮี",
    "districtNameEn": "Nong Hi",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451902",
    "subDistrictNameTh": "สาวแห",
    "subDistrictNameEn": "Sao Hae",
    "postcode": "45140",
    "districtCode": "4519",
    "districtNameTh": "หนองฮี",
    "districtNameEn": "Nong Hi",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451903",
    "subDistrictNameTh": "ดูกอึ่ง",
    "subDistrictNameEn": "Duk Ueng",
    "postcode": "45140",
    "districtCode": "4519",
    "districtNameTh": "หนองฮี",
    "districtNameEn": "Nong Hi",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "451904",
    "subDistrictNameTh": "เด่นราษฎร์",
    "subDistrictNameEn": "Den Rat",
    "postcode": "45140",
    "districtCode": "4519",
    "districtNameTh": "หนองฮี",
    "districtNameEn": "Nong Hi",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "452001",
    "subDistrictNameTh": "ทุ่งเขาหลวง",
    "subDistrictNameEn": "Thung Khao Luang",
    "postcode": "45170",
    "districtCode": "4520",
    "districtNameTh": "ทุ่งเขาหลวง",
    "districtNameEn": "Thung Khao Luang",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "452002",
    "subDistrictNameTh": "เทอดไทย",
    "subDistrictNameEn": "Thoet Thai",
    "postcode": "45170",
    "districtCode": "4520",
    "districtNameTh": "ทุ่งเขาหลวง",
    "districtNameEn": "Thung Khao Luang",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "452003",
    "subDistrictNameTh": "บึงงาม",
    "subDistrictNameEn": "Bueng Ngam",
    "postcode": "45170",
    "districtCode": "4520",
    "districtNameTh": "ทุ่งเขาหลวง",
    "districtNameEn": "Thung Khao Luang",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "452004",
    "subDistrictNameTh": "มะบ้า",
    "subDistrictNameEn": "Maba",
    "postcode": "45170",
    "districtCode": "4520",
    "districtNameTh": "ทุ่งเขาหลวง",
    "districtNameEn": "Thung Khao Luang",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "452005",
    "subDistrictNameTh": "เหล่า",
    "subDistrictNameEn": "Lao",
    "postcode": "45170",
    "districtCode": "4520",
    "districtNameTh": "ทุ่งเขาหลวง",
    "districtNameEn": "Thung Khao Luang",
    "provinceCode": "45",
    "provinceNameTh": "ร้อยเอ็ด",
    "provinceNameEn": "Roi Et"
},
{
    "subDistrictCode": "460101",
    "subDistrictNameTh": "กาฬสินธุ์",
    "subDistrictNameEn": "Kalasin",
    "postcode": "46000",
    "districtCode": "4601",
    "districtNameTh": "เมืองกาฬสินธุ์",
    "districtNameEn": "Mueang Kalasin",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460102",
    "subDistrictNameTh": "เหนือ",
    "subDistrictNameEn": "Nuea",
    "postcode": "46000",
    "districtCode": "4601",
    "districtNameTh": "เมืองกาฬสินธุ์",
    "districtNameEn": "Mueang Kalasin",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460103",
    "subDistrictNameTh": "หลุบ",
    "subDistrictNameEn": "Lup",
    "postcode": "46000",
    "districtCode": "4601",
    "districtNameTh": "เมืองกาฬสินธุ์",
    "districtNameEn": "Mueang Kalasin",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460104",
    "subDistrictNameTh": "ไผ่",
    "subDistrictNameEn": "Phai",
    "postcode": "46000",
    "districtCode": "4601",
    "districtNameTh": "เมืองกาฬสินธุ์",
    "districtNameEn": "Mueang Kalasin",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460105",
    "subDistrictNameTh": "ลำปาว",
    "subDistrictNameEn": "Lam Pao",
    "postcode": "46000",
    "districtCode": "4601",
    "districtNameTh": "เมืองกาฬสินธุ์",
    "districtNameEn": "Mueang Kalasin",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460106",
    "subDistrictNameTh": "ลำพาน",
    "subDistrictNameEn": "Lam Phan",
    "postcode": "46000",
    "districtCode": "4601",
    "districtNameTh": "เมืองกาฬสินธุ์",
    "districtNameEn": "Mueang Kalasin",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460107",
    "subDistrictNameTh": "เชียงเครือ",
    "subDistrictNameEn": "Chiang Khruea",
    "postcode": "46000",
    "districtCode": "4601",
    "districtNameTh": "เมืองกาฬสินธุ์",
    "districtNameEn": "Mueang Kalasin",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460108",
    "subDistrictNameTh": "บึงวิชัย",
    "subDistrictNameEn": "Bueng Wichai",
    "postcode": "46000",
    "districtCode": "4601",
    "districtNameTh": "เมืองกาฬสินธุ์",
    "districtNameEn": "Mueang Kalasin",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460109",
    "subDistrictNameTh": "ห้วยโพธิ์",
    "subDistrictNameEn": "Huai Pho",
    "postcode": "46000",
    "districtCode": "4601",
    "districtNameTh": "เมืองกาฬสินธุ์",
    "districtNameEn": "Mueang Kalasin",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460111",
    "subDistrictNameTh": "ภูปอ",
    "subDistrictNameEn": "Phu Po",
    "postcode": "46000",
    "districtCode": "4601",
    "districtNameTh": "เมืองกาฬสินธุ์",
    "districtNameEn": "Mueang Kalasin",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460113",
    "subDistrictNameTh": "ภูดิน",
    "subDistrictNameEn": "Phu Din",
    "postcode": "46000",
    "districtCode": "4601",
    "districtNameTh": "เมืองกาฬสินธุ์",
    "districtNameEn": "Mueang Kalasin",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460115",
    "subDistrictNameTh": "หนองกุง",
    "subDistrictNameEn": "Nong Kung",
    "postcode": "46000",
    "districtCode": "4601",
    "districtNameTh": "เมืองกาฬสินธุ์",
    "districtNameEn": "Mueang Kalasin",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460116",
    "subDistrictNameTh": "กลางหมื่น",
    "subDistrictNameEn": "Klang Muen",
    "postcode": "46000",
    "districtCode": "4601",
    "districtNameTh": "เมืองกาฬสินธุ์",
    "districtNameEn": "Mueang Kalasin",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460117",
    "subDistrictNameTh": "ขมิ้น",
    "subDistrictNameEn": "Khamin",
    "postcode": "46000",
    "districtCode": "4601",
    "districtNameTh": "เมืองกาฬสินธุ์",
    "districtNameEn": "Mueang Kalasin",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460119",
    "subDistrictNameTh": "โพนทอง",
    "subDistrictNameEn": "Phon Thong",
    "postcode": "46000",
    "districtCode": "4601",
    "districtNameTh": "เมืองกาฬสินธุ์",
    "districtNameEn": "Mueang Kalasin",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460120",
    "subDistrictNameTh": "นาจารย์",
    "subDistrictNameEn": "Na Chan",
    "postcode": "46000",
    "districtCode": "4601",
    "districtNameTh": "เมืองกาฬสินธุ์",
    "districtNameEn": "Mueang Kalasin",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460121",
    "subDistrictNameTh": "ลำคลอง",
    "subDistrictNameEn": "Lam Khlong",
    "postcode": "46000",
    "districtCode": "4601",
    "districtNameTh": "เมืองกาฬสินธุ์",
    "districtNameEn": "Mueang Kalasin",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460201",
    "subDistrictNameTh": "นามน",
    "subDistrictNameEn": "Na Mon",
    "postcode": "46230",
    "districtCode": "4602",
    "districtNameTh": "นามน",
    "districtNameEn": "Na Mon",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460202",
    "subDistrictNameTh": "ยอดแกง",
    "subDistrictNameEn": "Yot Kaeng",
    "postcode": "46230",
    "districtCode": "4602",
    "districtNameTh": "นามน",
    "districtNameEn": "Na Mon",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460203",
    "subDistrictNameTh": "สงเปลือย",
    "subDistrictNameEn": "Song Plueai",
    "postcode": "46230",
    "districtCode": "4602",
    "districtNameTh": "นามน",
    "districtNameEn": "Na Mon",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460204",
    "subDistrictNameTh": "หลักเหลี่ยม",
    "subDistrictNameEn": "Lak Liam",
    "postcode": "46230",
    "districtCode": "4602",
    "districtNameTh": "นามน",
    "districtNameEn": "Na Mon",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460205",
    "subDistrictNameTh": "หนองบัว",
    "subDistrictNameEn": "Nong Bua",
    "postcode": "46230",
    "districtCode": "4602",
    "districtNameTh": "นามน",
    "districtNameEn": "Na Mon",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460301",
    "subDistrictNameTh": "กมลาไสย",
    "subDistrictNameEn": "Kamalasai",
    "postcode": "46130",
    "districtCode": "4603",
    "districtNameTh": "กมลาไสย",
    "districtNameEn": "Kamalasai",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460302",
    "subDistrictNameTh": "หลักเมือง",
    "subDistrictNameEn": "Lak Mueang",
    "postcode": "46130",
    "districtCode": "4603",
    "districtNameTh": "กมลาไสย",
    "districtNameEn": "Kamalasai",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460303",
    "subDistrictNameTh": "โพนงาม",
    "subDistrictNameEn": "Phon Ngam",
    "postcode": "46130",
    "districtCode": "4603",
    "districtNameTh": "กมลาไสย",
    "districtNameEn": "Kamalasai",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460304",
    "subDistrictNameTh": "ดงลิง",
    "subDistrictNameEn": "Dong Ling",
    "postcode": "46130",
    "districtCode": "4603",
    "districtNameTh": "กมลาไสย",
    "districtNameEn": "Kamalasai",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460305",
    "subDistrictNameTh": "ธัญญา",
    "subDistrictNameEn": "Thanya",
    "postcode": "46130",
    "districtCode": "4603",
    "districtNameTh": "กมลาไสย",
    "districtNameEn": "Kamalasai",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460308",
    "subDistrictNameTh": "หนองแปน",
    "subDistrictNameEn": "Nong Paen",
    "postcode": "46130",
    "districtCode": "4603",
    "districtNameTh": "กมลาไสย",
    "districtNameEn": "Kamalasai",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460310",
    "subDistrictNameTh": "เจ้าท่า",
    "subDistrictNameEn": "Chao Tha",
    "postcode": "46130",
    "districtCode": "4603",
    "districtNameTh": "กมลาไสย",
    "districtNameEn": "Kamalasai",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460311",
    "subDistrictNameTh": "โคกสมบูรณ์",
    "subDistrictNameEn": "Khok Sombun",
    "postcode": "46130",
    "districtCode": "4603",
    "districtNameTh": "กมลาไสย",
    "districtNameEn": "Kamalasai",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460401",
    "subDistrictNameTh": "ร่องคำ",
    "subDistrictNameEn": "Rong Kham",
    "postcode": "46210",
    "districtCode": "4604",
    "districtNameTh": "ร่องคำ",
    "districtNameEn": "Rong Kham",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460402",
    "subDistrictNameTh": "สามัคคี",
    "subDistrictNameEn": "Samakkhi",
    "postcode": "46210",
    "districtCode": "4604",
    "districtNameTh": "ร่องคำ",
    "districtNameEn": "Rong Kham",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460403",
    "subDistrictNameTh": "เหล่าอ้อย",
    "subDistrictNameEn": "Lao Oi",
    "postcode": "46210",
    "districtCode": "4604",
    "districtNameTh": "ร่องคำ",
    "districtNameEn": "Rong Kham",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460501",
    "subDistrictNameTh": "บัวขาว",
    "subDistrictNameEn": "Bua Khao",
    "postcode": "46110",
    "districtCode": "4605",
    "districtNameTh": "กุฉินารายณ์",
    "districtNameEn": "Kuchinarai",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460502",
    "subDistrictNameTh": "แจนแลน",
    "subDistrictNameEn": "Chaen Laen",
    "postcode": "46110",
    "districtCode": "4605",
    "districtNameTh": "กุฉินารายณ์",
    "districtNameEn": "Kuchinarai",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460503",
    "subDistrictNameTh": "เหล่าใหญ่",
    "subDistrictNameEn": "Lao Yai",
    "postcode": "46110",
    "districtCode": "4605",
    "districtNameTh": "กุฉินารายณ์",
    "districtNameEn": "Kuchinarai",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460504",
    "subDistrictNameTh": "จุมจัง",
    "subDistrictNameEn": "Chum Chang",
    "postcode": "46110",
    "districtCode": "4605",
    "districtNameTh": "กุฉินารายณ์",
    "districtNameEn": "Kuchinarai",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460505",
    "subDistrictNameTh": "เหล่าไฮงาม",
    "subDistrictNameEn": "Lao Hai Ngam",
    "postcode": "46110",
    "districtCode": "4605",
    "districtNameTh": "กุฉินารายณ์",
    "districtNameEn": "Kuchinarai",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460506",
    "subDistrictNameTh": "กุดหว้า",
    "subDistrictNameEn": "Kut Wa",
    "postcode": "46110",
    "districtCode": "4605",
    "districtNameTh": "กุฉินารายณ์",
    "districtNameEn": "Kuchinarai",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460507",
    "subDistrictNameTh": "สามขา",
    "subDistrictNameEn": "Sam Kha",
    "postcode": "46110",
    "districtCode": "4605",
    "districtNameTh": "กุฉินารายณ์",
    "districtNameEn": "Kuchinarai",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460508",
    "subDistrictNameTh": "นาขาม",
    "subDistrictNameEn": "Na Kham",
    "postcode": "46110",
    "districtCode": "4605",
    "districtNameTh": "กุฉินารายณ์",
    "districtNameEn": "Kuchinarai",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460509",
    "subDistrictNameTh": "หนองห้าง",
    "subDistrictNameEn": "Nong Hang",
    "postcode": "46110",
    "districtCode": "4605",
    "districtNameTh": "กุฉินารายณ์",
    "districtNameEn": "Kuchinarai",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460510",
    "subDistrictNameTh": "นาโก",
    "subDistrictNameEn": "Na Ko",
    "postcode": "46110",
    "districtCode": "4605",
    "districtNameTh": "กุฉินารายณ์",
    "districtNameEn": "Kuchinarai",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460511",
    "subDistrictNameTh": "สมสะอาด",
    "subDistrictNameEn": "Som Sa-at",
    "postcode": "46110",
    "districtCode": "4605",
    "districtNameTh": "กุฉินารายณ์",
    "districtNameEn": "Kuchinarai",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460512",
    "subDistrictNameTh": "กุดค้าว",
    "subDistrictNameEn": "Kut Khao",
    "postcode": "46110",
    "districtCode": "4605",
    "districtNameTh": "กุฉินารายณ์",
    "districtNameEn": "Kuchinarai",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460601",
    "subDistrictNameTh": "คุ้มเก่า",
    "subDistrictNameEn": "Khum Kao",
    "postcode": "46160",
    "districtCode": "4606",
    "districtNameTh": "เขาวง",
    "districtNameEn": "Khao Wong",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460602",
    "subDistrictNameTh": "สงเปลือย",
    "subDistrictNameEn": "Song Plueai",
    "postcode": "46160",
    "districtCode": "4606",
    "districtNameTh": "เขาวง",
    "districtNameEn": "Khao Wong",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460603",
    "subDistrictNameTh": "หนองผือ",
    "subDistrictNameEn": "Nong Phue",
    "postcode": "46160",
    "districtCode": "4606",
    "districtNameTh": "เขาวง",
    "districtNameEn": "Khao Wong",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460606",
    "subDistrictNameTh": "กุดสิมคุ้มใหม่",
    "subDistrictNameEn": "Kut Sim Khum Mai",
    "postcode": "46160",
    "districtCode": "4606",
    "districtNameTh": "เขาวง",
    "districtNameEn": "Khao Wong",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460608",
    "subDistrictNameTh": "สระพังทอง",
    "subDistrictNameEn": "Sa Phang Thong",
    "postcode": "46160",
    "districtCode": "4606",
    "districtNameTh": "เขาวง",
    "districtNameEn": "Khao Wong",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460611",
    "subDistrictNameTh": "กุดปลาค้าว",
    "subDistrictNameEn": "Kut Pla Khao",
    "postcode": "46160",
    "districtCode": "4606",
    "districtNameTh": "เขาวง",
    "districtNameEn": "Khao Wong",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460701",
    "subDistrictNameTh": "ยางตลาด",
    "subDistrictNameEn": "Yang Talat",
    "postcode": "46120",
    "districtCode": "4607",
    "districtNameTh": "ยางตลาด",
    "districtNameEn": "Yang Talat",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460702",
    "subDistrictNameTh": "หัวงัว",
    "subDistrictNameEn": "Hua Ngua",
    "postcode": "46120",
    "districtCode": "4607",
    "districtNameTh": "ยางตลาด",
    "districtNameEn": "Yang Talat",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460703",
    "subDistrictNameTh": "อุ่มเม่า",
    "subDistrictNameEn": "Um Mao",
    "postcode": "46120",
    "districtCode": "4607",
    "districtNameTh": "ยางตลาด",
    "districtNameEn": "Yang Talat",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460704",
    "subDistrictNameTh": "บัวบาน",
    "subDistrictNameEn": "Bua Ban",
    "postcode": "46120",
    "districtCode": "4607",
    "districtNameTh": "ยางตลาด",
    "districtNameEn": "Yang Talat",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460705",
    "subDistrictNameTh": "เว่อ",
    "subDistrictNameEn": "Woe",
    "postcode": "46120",
    "districtCode": "4607",
    "districtNameTh": "ยางตลาด",
    "districtNameEn": "Yang Talat",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460706",
    "subDistrictNameTh": "อิตื้อ",
    "subDistrictNameEn": "Itue",
    "postcode": "46120",
    "districtCode": "4607",
    "districtNameTh": "ยางตลาด",
    "districtNameEn": "Yang Talat",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460707",
    "subDistrictNameTh": "หัวนาคำ",
    "subDistrictNameEn": "Hua Na Kham",
    "postcode": "46120",
    "districtCode": "4607",
    "districtNameTh": "ยางตลาด",
    "districtNameEn": "Yang Talat",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460708",
    "subDistrictNameTh": "หนองอิเฒ่า",
    "subDistrictNameEn": "Nong I Thao",
    "postcode": "46120",
    "districtCode": "4607",
    "districtNameTh": "ยางตลาด",
    "districtNameEn": "Yang Talat",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460709",
    "subDistrictNameTh": "ดอนสมบูรณ์",
    "subDistrictNameEn": "Don Sombun",
    "postcode": "46120",
    "districtCode": "4607",
    "districtNameTh": "ยางตลาด",
    "districtNameEn": "Yang Talat",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460710",
    "subDistrictNameTh": "นาเชือก",
    "subDistrictNameEn": "Na Chueak",
    "postcode": "46120",
    "districtCode": "4607",
    "districtNameTh": "ยางตลาด",
    "districtNameEn": "Yang Talat",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460711",
    "subDistrictNameTh": "คลองขาม",
    "subDistrictNameEn": "Khlong Kham",
    "postcode": "46120",
    "districtCode": "4607",
    "districtNameTh": "ยางตลาด",
    "districtNameEn": "Yang Talat",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460712",
    "subDistrictNameTh": "เขาพระนอน",
    "subDistrictNameEn": "Khao Phra Non",
    "postcode": "46120",
    "districtCode": "4607",
    "districtNameTh": "ยางตลาด",
    "districtNameEn": "Yang Talat",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460713",
    "subDistrictNameTh": "นาดี",
    "subDistrictNameEn": "Na Di",
    "postcode": "46120",
    "districtCode": "4607",
    "districtNameTh": "ยางตลาด",
    "districtNameEn": "Yang Talat",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460714",
    "subDistrictNameTh": "โนนสูง",
    "subDistrictNameEn": "Non Sung",
    "postcode": "46120",
    "districtCode": "4607",
    "districtNameTh": "ยางตลาด",
    "districtNameEn": "Yang Talat",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460715",
    "subDistrictNameTh": "หนองตอกแป้น",
    "subDistrictNameEn": "Nong Tok Paen",
    "postcode": "46120",
    "districtCode": "4607",
    "districtNameTh": "ยางตลาด",
    "districtNameEn": "Yang Talat",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460801",
    "subDistrictNameTh": "ห้วยเม็ก",
    "subDistrictNameEn": "Huai Mek",
    "postcode": "46170",
    "districtCode": "4608",
    "districtNameTh": "ห้วยเม็ก",
    "districtNameEn": "Huai Mek",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460802",
    "subDistrictNameTh": "คำใหญ่",
    "subDistrictNameEn": "Kham Yai",
    "postcode": "46170",
    "districtCode": "4608",
    "districtNameTh": "ห้วยเม็ก",
    "districtNameEn": "Huai Mek",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460803",
    "subDistrictNameTh": "กุดโดน",
    "subDistrictNameEn": "Kut Don",
    "postcode": "46170",
    "districtCode": "4608",
    "districtNameTh": "ห้วยเม็ก",
    "districtNameEn": "Huai Mek",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460804",
    "subDistrictNameTh": "บึงนาเรียง",
    "subDistrictNameEn": "Bueng Na Riang",
    "postcode": "46170",
    "districtCode": "4608",
    "districtNameTh": "ห้วยเม็ก",
    "districtNameEn": "Huai Mek",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460805",
    "subDistrictNameTh": "หัวหิน",
    "subDistrictNameEn": "Hua Hin",
    "postcode": "46170",
    "districtCode": "4608",
    "districtNameTh": "ห้วยเม็ก",
    "districtNameEn": "Huai Mek",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460806",
    "subDistrictNameTh": "พิมูล",
    "subDistrictNameEn": "Phimun",
    "postcode": "46170",
    "districtCode": "4608",
    "districtNameTh": "ห้วยเม็ก",
    "districtNameEn": "Huai Mek",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460807",
    "subDistrictNameTh": "คำเหมือดแก้ว",
    "subDistrictNameEn": "Kham Mueat Kaeo",
    "postcode": "46170",
    "districtCode": "4608",
    "districtNameTh": "ห้วยเม็ก",
    "districtNameEn": "Huai Mek",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460808",
    "subDistrictNameTh": "โนนสะอาด",
    "subDistrictNameEn": "Non Sa-at",
    "postcode": "46170",
    "districtCode": "4608",
    "districtNameTh": "ห้วยเม็ก",
    "districtNameEn": "Huai Mek",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460809",
    "subDistrictNameTh": "ทรายทอง",
    "subDistrictNameEn": "Sai Thong",
    "postcode": "46170",
    "districtCode": "4608",
    "districtNameTh": "ห้วยเม็ก",
    "districtNameEn": "Huai Mek",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460901",
    "subDistrictNameTh": "ภูสิงห์",
    "subDistrictNameEn": "Phu Sing",
    "postcode": "46140",
    "districtCode": "4609",
    "districtNameTh": "สหัสขันธ์",
    "districtNameEn": "Sahatsakhan",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460902",
    "subDistrictNameTh": "สหัสขันธ์",
    "subDistrictNameEn": "Sahatsakhan",
    "postcode": "46140",
    "districtCode": "4609",
    "districtNameTh": "สหัสขันธ์",
    "districtNameEn": "Sahatsakhan",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460903",
    "subDistrictNameTh": "นามะเขือ",
    "subDistrictNameEn": "Na Makhuea",
    "postcode": "46140",
    "districtCode": "4609",
    "districtNameTh": "สหัสขันธ์",
    "districtNameEn": "Sahatsakhan",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460904",
    "subDistrictNameTh": "โนนศิลา",
    "subDistrictNameEn": "Non Sila",
    "postcode": "46140",
    "districtCode": "4609",
    "districtNameTh": "สหัสขันธ์",
    "districtNameEn": "Sahatsakhan",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460905",
    "subDistrictNameTh": "นิคม",
    "subDistrictNameEn": "Nikhom",
    "postcode": "46140",
    "districtCode": "4609",
    "districtNameTh": "สหัสขันธ์",
    "districtNameEn": "Sahatsakhan",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460906",
    "subDistrictNameTh": "โนนแหลมทอง",
    "subDistrictNameEn": "Non Laem Thong",
    "postcode": "46140",
    "districtCode": "4609",
    "districtNameTh": "สหัสขันธ์",
    "districtNameEn": "Sahatsakhan",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460907",
    "subDistrictNameTh": "โนนบุรี",
    "subDistrictNameEn": "Non Buri",
    "postcode": "46140",
    "districtCode": "4609",
    "districtNameTh": "สหัสขันธ์",
    "districtNameEn": "Sahatsakhan",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "460908",
    "subDistrictNameTh": "โนนน้ำเกลี้ยง",
    "subDistrictNameEn": "Non Nam Kliang",
    "postcode": "46140",
    "districtCode": "4609",
    "districtNameTh": "สหัสขันธ์",
    "districtNameEn": "Sahatsakhan",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461001",
    "subDistrictNameTh": "ทุ่งคลอง",
    "subDistrictNameEn": "Thung Khlong",
    "postcode": "46180",
    "districtCode": "4610",
    "districtNameTh": "คำม่วง",
    "districtNameEn": "Kham Muang",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461002",
    "subDistrictNameTh": "โพน",
    "subDistrictNameEn": "Phon",
    "postcode": "46180",
    "districtCode": "4610",
    "districtNameTh": "คำม่วง",
    "districtNameEn": "Kham Muang",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461005",
    "subDistrictNameTh": "ดินจี่",
    "subDistrictNameEn": "Din Chi",
    "postcode": "46180",
    "districtCode": "4610",
    "districtNameTh": "คำม่วง",
    "districtNameEn": "Kham Muang",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461006",
    "subDistrictNameTh": "นาบอน",
    "subDistrictNameEn": "Na Bon",
    "postcode": "46180",
    "districtCode": "4610",
    "districtNameTh": "คำม่วง",
    "districtNameEn": "Kham Muang",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461007",
    "subDistrictNameTh": "นาทัน",
    "subDistrictNameEn": "Na Than",
    "postcode": "46180",
    "districtCode": "4610",
    "districtNameTh": "คำม่วง",
    "districtNameEn": "Kham Muang",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461009",
    "subDistrictNameTh": "เนินยาง",
    "subDistrictNameEn": "Noen Yang",
    "postcode": "46180",
    "districtCode": "4610",
    "districtNameTh": "คำม่วง",
    "districtNameEn": "Kham Muang",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461101",
    "subDistrictNameTh": "ท่าคันโท",
    "subDistrictNameEn": "Tha Khantho",
    "postcode": "46190",
    "districtCode": "4611",
    "districtNameTh": "ท่าคันโท",
    "districtNameEn": "Tha Khantho",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461102",
    "subDistrictNameTh": "กุงเก่า",
    "subDistrictNameEn": "Kung Kao",
    "postcode": "46190",
    "districtCode": "4611",
    "districtNameTh": "ท่าคันโท",
    "districtNameEn": "Tha Khantho",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461103",
    "subDistrictNameTh": "ยางอู้ม",
    "subDistrictNameEn": "Yang Um",
    "postcode": "46190",
    "districtCode": "4611",
    "districtNameTh": "ท่าคันโท",
    "districtNameEn": "Tha Khantho",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461104",
    "subDistrictNameTh": "กุดจิก",
    "subDistrictNameEn": "Kut Chik",
    "postcode": "46190",
    "districtCode": "4611",
    "districtNameTh": "ท่าคันโท",
    "districtNameEn": "Tha Khantho",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461105",
    "subDistrictNameTh": "นาตาล",
    "subDistrictNameEn": "Na Tan",
    "postcode": "46190",
    "districtCode": "4611",
    "districtNameTh": "ท่าคันโท",
    "districtNameEn": "Tha Khantho",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461106",
    "subDistrictNameTh": "ดงสมบูรณ์",
    "subDistrictNameEn": "Dong Sombun",
    "postcode": "46190",
    "districtCode": "4611",
    "districtNameTh": "ท่าคันโท",
    "districtNameEn": "Tha Khantho",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461201",
    "subDistrictNameTh": "หนองกุงศรี",
    "subDistrictNameEn": "Nong Kung Si",
    "postcode": "46220",
    "districtCode": "4612",
    "districtNameTh": "หนองกุงศรี",
    "districtNameEn": "Nong Kung Si",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461202",
    "subDistrictNameTh": "หนองบัว",
    "subDistrictNameEn": "Nong Bua",
    "postcode": "46220",
    "districtCode": "4612",
    "districtNameTh": "หนองกุงศรี",
    "districtNameEn": "Nong Kung Si",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461203",
    "subDistrictNameTh": "โคกเครือ",
    "subDistrictNameEn": "Khok Khruea",
    "postcode": "46220",
    "districtCode": "4612",
    "districtNameTh": "หนองกุงศรี",
    "districtNameEn": "Nong Kung Si",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461204",
    "subDistrictNameTh": "หนองสรวง",
    "subDistrictNameEn": "Nong Suang",
    "postcode": "46220",
    "districtCode": "4612",
    "districtNameTh": "หนองกุงศรี",
    "districtNameEn": "Nong Kung Si",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461205",
    "subDistrictNameTh": "เสาเล้า",
    "subDistrictNameEn": "Sao Lao",
    "postcode": "46220",
    "districtCode": "4612",
    "districtNameTh": "หนองกุงศรี",
    "districtNameEn": "Nong Kung Si",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461206",
    "subDistrictNameTh": "หนองใหญ่",
    "subDistrictNameEn": "Nong Yai",
    "postcode": "46220",
    "districtCode": "4612",
    "districtNameTh": "หนองกุงศรี",
    "districtNameEn": "Nong Kung Si",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461207",
    "subDistrictNameTh": "ดงมูล",
    "subDistrictNameEn": "Dong Mun",
    "postcode": "46220",
    "districtCode": "4612",
    "districtNameTh": "หนองกุงศรี",
    "districtNameEn": "Nong Kung Si",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461208",
    "subDistrictNameTh": "ลำหนองแสน",
    "subDistrictNameEn": "Lam Nong Saen",
    "postcode": "46220",
    "districtCode": "4612",
    "districtNameTh": "หนองกุงศรี",
    "districtNameEn": "Nong Kung Si",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461209",
    "subDistrictNameTh": "หนองหิน",
    "subDistrictNameEn": "Nong Hin",
    "postcode": "46220",
    "districtCode": "4612",
    "districtNameTh": "หนองกุงศรี",
    "districtNameEn": "Nong Kung Si",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461301",
    "subDistrictNameTh": "สมเด็จ",
    "subDistrictNameEn": "Somdet",
    "postcode": "46150",
    "districtCode": "4613",
    "districtNameTh": "สมเด็จ",
    "districtNameEn": "Somdet",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461302",
    "subDistrictNameTh": "หนองแวง",
    "subDistrictNameEn": "Nong Waeng",
    "postcode": "46150",
    "districtCode": "4613",
    "districtNameTh": "สมเด็จ",
    "districtNameEn": "Somdet",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461303",
    "subDistrictNameTh": "แซงบาดาล",
    "subDistrictNameEn": "Saeng Badan",
    "postcode": "46150",
    "districtCode": "4613",
    "districtNameTh": "สมเด็จ",
    "districtNameEn": "Somdet",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461304",
    "subDistrictNameTh": "มหาไชย",
    "subDistrictNameEn": "Maha Chai",
    "postcode": "46150",
    "districtCode": "4613",
    "districtNameTh": "สมเด็จ",
    "districtNameEn": "Somdet",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461305",
    "subDistrictNameTh": "หมูม่น",
    "subDistrictNameEn": "Mu Mon",
    "postcode": "46150",
    "districtCode": "4613",
    "districtNameTh": "สมเด็จ",
    "districtNameEn": "Somdet",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461306",
    "subDistrictNameTh": "ผาเสวย",
    "subDistrictNameEn": "Pha Sawoei",
    "postcode": "46150",
    "districtCode": "4613",
    "districtNameTh": "สมเด็จ",
    "districtNameEn": "Somdet",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461307",
    "subDistrictNameTh": "ศรีสมเด็จ",
    "subDistrictNameEn": "Si Somdet",
    "postcode": "46150",
    "districtCode": "4613",
    "districtNameTh": "สมเด็จ",
    "districtNameEn": "Somdet",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461308",
    "subDistrictNameTh": "ลำห้วยหลัว",
    "subDistrictNameEn": "Lam Huai Lua",
    "postcode": "46150",
    "districtCode": "4613",
    "districtNameTh": "สมเด็จ",
    "districtNameEn": "Somdet",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461401",
    "subDistrictNameTh": "คำบง",
    "subDistrictNameEn": "Kham Bong",
    "postcode": "46240",
    "districtCode": "4614",
    "districtNameTh": "ห้วยผึ้ง",
    "districtNameEn": "Huai Phueng",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461402",
    "subDistrictNameTh": "ไค้นุ่น",
    "subDistrictNameEn": "Khai Nun",
    "postcode": "46240",
    "districtCode": "4614",
    "districtNameTh": "ห้วยผึ้ง",
    "districtNameEn": "Huai Phueng",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461403",
    "subDistrictNameTh": "นิคมห้วยผึ้ง",
    "subDistrictNameEn": "Nikhom Huai Phueng",
    "postcode": "46240",
    "districtCode": "4614",
    "districtNameTh": "ห้วยผึ้ง",
    "districtNameEn": "Huai Phueng",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461404",
    "subDistrictNameTh": "หนองอีบุตร",
    "subDistrictNameEn": "Nong I But",
    "postcode": "46240",
    "districtCode": "4614",
    "districtNameTh": "ห้วยผึ้ง",
    "districtNameEn": "Huai Phueng",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461501",
    "subDistrictNameTh": "สำราญ",
    "subDistrictNameEn": "Samran",
    "postcode": "46180",
    "districtCode": "4615",
    "districtNameTh": "สามชัย",
    "districtNameEn": "Sam Chai",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461502",
    "subDistrictNameTh": "สำราญใต้",
    "subDistrictNameEn": "Samran Tai",
    "postcode": "46180",
    "districtCode": "4615",
    "districtNameTh": "สามชัย",
    "districtNameEn": "Sam Chai",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461503",
    "subDistrictNameTh": "คำสร้างเที่ยง",
    "subDistrictNameEn": "Kham Sang Thiang",
    "postcode": "46180",
    "districtCode": "4615",
    "districtNameTh": "สามชัย",
    "districtNameEn": "Sam Chai",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461504",
    "subDistrictNameTh": "หนองช้าง",
    "subDistrictNameEn": "Nong Chang",
    "postcode": "46180",
    "districtCode": "4615",
    "districtNameTh": "สามชัย",
    "districtNameEn": "Sam Chai",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461601",
    "subDistrictNameTh": "นาคู",
    "subDistrictNameEn": "Na Khu",
    "postcode": "46160",
    "districtCode": "4616",
    "districtNameTh": "นาคู",
    "districtNameEn": "Na Khu",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461602",
    "subDistrictNameTh": "สายนาวัง",
    "subDistrictNameEn": "Sai Na Wang",
    "postcode": "46160",
    "districtCode": "4616",
    "districtNameTh": "นาคู",
    "districtNameEn": "Na Khu",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461603",
    "subDistrictNameTh": "โนนนาจาน",
    "subDistrictNameEn": "Non Na Chan",
    "postcode": "46160",
    "districtCode": "4616",
    "districtNameTh": "นาคู",
    "districtNameEn": "Na Khu",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461604",
    "subDistrictNameTh": "บ่อแก้ว",
    "subDistrictNameEn": "Bo Kaeo",
    "postcode": "46160",
    "districtCode": "4616",
    "districtNameTh": "นาคู",
    "districtNameEn": "Na Khu",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461605",
    "subDistrictNameTh": "ภูแล่นช้าง",
    "subDistrictNameEn": "Phu Laen Chang",
    "postcode": "46160",
    "districtCode": "4616",
    "districtNameTh": "นาคู",
    "districtNameEn": "Na Khu",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461701",
    "subDistrictNameTh": "ดอนจาน",
    "subDistrictNameEn": "Don Chan",
    "postcode": "46000",
    "districtCode": "4617",
    "districtNameTh": "ดอนจาน",
    "districtNameEn": "Don Chan",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461702",
    "subDistrictNameTh": "สะอาดไชยศรี",
    "subDistrictNameEn": "Sa-at Chai Si",
    "postcode": "46000",
    "districtCode": "4617",
    "districtNameTh": "ดอนจาน",
    "districtNameEn": "Don Chan",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461703",
    "subDistrictNameTh": "ดงพยุง",
    "subDistrictNameEn": "Dong Phayung",
    "postcode": "46000",
    "districtCode": "4617",
    "districtNameTh": "ดอนจาน",
    "districtNameEn": "Don Chan",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461704",
    "subDistrictNameTh": "ม่วงนา",
    "subDistrictNameEn": "Muang Na",
    "postcode": "46000",
    "districtCode": "4617",
    "districtNameTh": "ดอนจาน",
    "districtNameEn": "Don Chan",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461705",
    "subDistrictNameTh": "นาจำปา",
    "subDistrictNameEn": "Na Champa",
    "postcode": "46000",
    "districtCode": "4617",
    "districtNameTh": "ดอนจาน",
    "districtNameEn": "Don Chan",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461801",
    "subDistrictNameTh": "ฆ้องชัยพัฒนา",
    "subDistrictNameEn": "Khong Chai Phatthana",
    "postcode": "46130",
    "districtCode": "4618",
    "districtNameTh": "ฆ้องชัย",
    "districtNameEn": "Khong Chai",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461802",
    "subDistrictNameTh": "เหล่ากลาง",
    "subDistrictNameEn": "Lao Klang",
    "postcode": "46130",
    "districtCode": "4618",
    "districtNameTh": "ฆ้องชัย",
    "districtNameEn": "Khong Chai",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461803",
    "subDistrictNameTh": "โคกสะอาด",
    "subDistrictNameEn": "Khok Sa-at",
    "postcode": "46130",
    "districtCode": "4618",
    "districtNameTh": "ฆ้องชัย",
    "districtNameEn": "Khong Chai",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461804",
    "subDistrictNameTh": "โนนศิลาเลิง",
    "subDistrictNameEn": "Non Sila Loeng",
    "postcode": "46130",
    "districtCode": "4618",
    "districtNameTh": "ฆ้องชัย",
    "districtNameEn": "Khong Chai",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "461805",
    "subDistrictNameTh": "ลำชี",
    "subDistrictNameEn": "Lam Chi",
    "postcode": "46130",
    "districtCode": "4618",
    "districtNameTh": "ฆ้องชัย",
    "districtNameEn": "Khong Chai",
    "provinceCode": "46",
    "provinceNameTh": "กาฬสินธุ์",
    "provinceNameEn": "Kalasin"
},
{
    "subDistrictCode": "470101",
    "subDistrictNameTh": "ธาตุเชิงชุม",
    "subDistrictNameEn": "That Choeng Chum",
    "postcode": "47000",
    "districtCode": "4701",
    "districtNameTh": "เมืองสกลนคร",
    "districtNameEn": "Mueang Sakon Nakhon",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470102",
    "subDistrictNameTh": "ขมิ้น",
    "subDistrictNameEn": "Khamin",
    "postcode": "47220",
    "districtCode": "4701",
    "districtNameTh": "เมืองสกลนคร",
    "districtNameEn": "Mueang Sakon Nakhon",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470103",
    "subDistrictNameTh": "งิ้วด่อน",
    "subDistrictNameEn": "Ngio Don",
    "postcode": "47000",
    "districtCode": "4701",
    "districtNameTh": "เมืองสกลนคร",
    "districtNameEn": "Mueang Sakon Nakhon",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470104",
    "subDistrictNameTh": "โนนหอม",
    "subDistrictNameEn": "Non Hom",
    "postcode": "47000",
    "districtCode": "4701",
    "districtNameTh": "เมืองสกลนคร",
    "districtNameEn": "Mueang Sakon Nakhon",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470106",
    "subDistrictNameTh": "เชียงเครือ",
    "subDistrictNameEn": "Chiang Khruea",
    "postcode": "47000",
    "districtCode": "4701",
    "districtNameTh": "เมืองสกลนคร",
    "districtNameEn": "Mueang Sakon Nakhon",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470107",
    "subDistrictNameTh": "ท่าแร่",
    "subDistrictNameEn": "Tha Rae",
    "postcode": "47230",
    "districtCode": "4701",
    "districtNameTh": "เมืองสกลนคร",
    "districtNameEn": "Mueang Sakon Nakhon",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470109",
    "subDistrictNameTh": "ม่วงลาย",
    "subDistrictNameEn": "Muang Lai",
    "postcode": "47000",
    "districtCode": "4701",
    "districtNameTh": "เมืองสกลนคร",
    "districtNameEn": "Mueang Sakon Nakhon",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470111",
    "subDistrictNameTh": "ดงชน",
    "subDistrictNameEn": "Dong Chon",
    "postcode": "47000",
    "districtCode": "4701",
    "districtNameTh": "เมืองสกลนคร",
    "districtNameEn": "Mueang Sakon Nakhon",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470112",
    "subDistrictNameTh": "ห้วยยาง",
    "subDistrictNameEn": "Huai Yang",
    "postcode": "47000",
    "districtCode": "4701",
    "districtNameTh": "เมืองสกลนคร",
    "districtNameEn": "Mueang Sakon Nakhon",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470113",
    "subDistrictNameTh": "พังขว้าง",
    "subDistrictNameEn": "Phang Khwang",
    "postcode": "47000",
    "districtCode": "4701",
    "districtNameTh": "เมืองสกลนคร",
    "districtNameEn": "Mueang Sakon Nakhon",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470115",
    "subDistrictNameTh": "ดงมะไฟ",
    "subDistrictNameEn": "Dong Mafai",
    "postcode": "47000",
    "districtCode": "4701",
    "districtNameTh": "เมืองสกลนคร",
    "districtNameEn": "Mueang Sakon Nakhon",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470116",
    "subDistrictNameTh": "ธาตุนาเวง",
    "subDistrictNameEn": "That Na Weng",
    "postcode": "47000",
    "districtCode": "4701",
    "districtNameTh": "เมืองสกลนคร",
    "districtNameEn": "Mueang Sakon Nakhon",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470117",
    "subDistrictNameTh": "เหล่าปอแดง",
    "subDistrictNameEn": "Lao Po Daeng",
    "postcode": "47000",
    "districtCode": "4701",
    "districtNameTh": "เมืองสกลนคร",
    "districtNameEn": "Mueang Sakon Nakhon",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470118",
    "subDistrictNameTh": "หนองลาด",
    "subDistrictNameEn": "Nong Lat",
    "postcode": "47220",
    "districtCode": "4701",
    "districtNameTh": "เมืองสกลนคร",
    "districtNameEn": "Mueang Sakon Nakhon",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470120",
    "subDistrictNameTh": "ฮางโฮง",
    "subDistrictNameEn": "Hang Hong",
    "postcode": "47000",
    "districtCode": "4701",
    "districtNameTh": "เมืองสกลนคร",
    "districtNameEn": "Mueang Sakon Nakhon",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470121",
    "subDistrictNameTh": "โคกก่อง",
    "subDistrictNameEn": "Khok Kong",
    "postcode": "47000",
    "districtCode": "4701",
    "districtNameTh": "เมืองสกลนคร",
    "districtNameEn": "Mueang Sakon Nakhon",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470201",
    "subDistrictNameTh": "กุสุมาลย์",
    "subDistrictNameEn": "Kusuman",
    "postcode": "47210",
    "districtCode": "4702",
    "districtNameTh": "กุสุมาลย์",
    "districtNameEn": "Kusuman",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470202",
    "subDistrictNameTh": "นาโพธิ์",
    "subDistrictNameEn": "Na Pho",
    "postcode": "47210",
    "districtCode": "4702",
    "districtNameTh": "กุสุมาลย์",
    "districtNameEn": "Kusuman",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470203",
    "subDistrictNameTh": "นาเพียง",
    "subDistrictNameEn": "Na Phiang",
    "postcode": "47230",
    "districtCode": "4702",
    "districtNameTh": "กุสุมาลย์",
    "districtNameEn": "Kusuman",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470204",
    "subDistrictNameTh": "โพธิไพศาล",
    "subDistrictNameEn": "Phothiphaisan",
    "postcode": "47210",
    "districtCode": "4702",
    "districtNameTh": "กุสุมาลย์",
    "districtNameEn": "Kusuman",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470205",
    "subDistrictNameTh": "อุ่มจาน",
    "subDistrictNameEn": "Um Chan",
    "postcode": "47230",
    "districtCode": "4702",
    "districtNameTh": "กุสุมาลย์",
    "districtNameEn": "Kusuman",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470301",
    "subDistrictNameTh": "กุดบาก",
    "subDistrictNameEn": "Kut Bak",
    "postcode": "47180",
    "districtCode": "4703",
    "districtNameTh": "กุดบาก",
    "districtNameEn": "Kut Bak",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470303",
    "subDistrictNameTh": "นาม่อง",
    "subDistrictNameEn": "Na Mong",
    "postcode": "47180",
    "districtCode": "4703",
    "districtNameTh": "กุดบาก",
    "districtNameEn": "Kut Bak",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470305",
    "subDistrictNameTh": "กุดไห",
    "subDistrictNameEn": "Kut Hai",
    "postcode": "47180",
    "districtCode": "4703",
    "districtNameTh": "กุดบาก",
    "districtNameEn": "Kut Bak",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470401",
    "subDistrictNameTh": "พรรณา",
    "subDistrictNameEn": "Phanna",
    "postcode": "47130",
    "districtCode": "4704",
    "districtNameTh": "พรรณานิคม",
    "districtNameEn": "Phanna Nikhom",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470402",
    "subDistrictNameTh": "วังยาง",
    "subDistrictNameEn": "Wang Yang",
    "postcode": "47130",
    "districtCode": "4704",
    "districtNameTh": "พรรณานิคม",
    "districtNameEn": "Phanna Nikhom",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470403",
    "subDistrictNameTh": "พอกน้อย",
    "subDistrictNameEn": "Phok Noi",
    "postcode": "47220",
    "districtCode": "4704",
    "districtNameTh": "พรรณานิคม",
    "districtNameEn": "Phanna Nikhom",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470404",
    "subDistrictNameTh": "นาหัวบ่อ",
    "subDistrictNameEn": "Na Hua Bo",
    "postcode": "47220",
    "districtCode": "4704",
    "districtNameTh": "พรรณานิคม",
    "districtNameEn": "Phanna Nikhom",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470405",
    "subDistrictNameTh": "ไร่",
    "subDistrictNameEn": "Rai",
    "postcode": "47130",
    "districtCode": "4704",
    "districtNameTh": "พรรณานิคม",
    "districtNameEn": "Phanna Nikhom",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470406",
    "subDistrictNameTh": "ช้างมิ่ง",
    "subDistrictNameEn": "Chang Ming",
    "postcode": "47130",
    "districtCode": "4704",
    "districtNameTh": "พรรณานิคม",
    "districtNameEn": "Phanna Nikhom",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470407",
    "subDistrictNameTh": "นาใน",
    "subDistrictNameEn": "Na Nai",
    "postcode": "47130",
    "districtCode": "4704",
    "districtNameTh": "พรรณานิคม",
    "districtNameEn": "Phanna Nikhom",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470408",
    "subDistrictNameTh": "สว่าง",
    "subDistrictNameEn": "Sawang",
    "postcode": "47130",
    "districtCode": "4704",
    "districtNameTh": "พรรณานิคม",
    "districtNameEn": "Phanna Nikhom",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470409",
    "subDistrictNameTh": "บะฮี",
    "subDistrictNameEn": "Ba Hi",
    "postcode": "47130",
    "districtCode": "4704",
    "districtNameTh": "พรรณานิคม",
    "districtNameEn": "Phanna Nikhom",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470410",
    "subDistrictNameTh": "เชิงชุม",
    "subDistrictNameEn": "Choeng Chum",
    "postcode": "47130",
    "districtCode": "4704",
    "districtNameTh": "พรรณานิคม",
    "districtNameEn": "Phanna Nikhom",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470501",
    "subDistrictNameTh": "พังโคน",
    "subDistrictNameEn": "Phang Khon",
    "postcode": "47160",
    "districtCode": "4705",
    "districtNameTh": "พังโคน",
    "districtNameEn": "Phang Khon",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470502",
    "subDistrictNameTh": "ม่วงไข่",
    "subDistrictNameEn": "Muang Khai",
    "postcode": "47160",
    "districtCode": "4705",
    "districtNameTh": "พังโคน",
    "districtNameEn": "Phang Khon",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470503",
    "subDistrictNameTh": "แร่",
    "subDistrictNameEn": "Rae",
    "postcode": "47160",
    "districtCode": "4705",
    "districtNameTh": "พังโคน",
    "districtNameEn": "Phang Khon",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470504",
    "subDistrictNameTh": "ไฮหย่อง",
    "subDistrictNameEn": "Hai Yong",
    "postcode": "47160",
    "districtCode": "4705",
    "districtNameTh": "พังโคน",
    "districtNameEn": "Phang Khon",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470505",
    "subDistrictNameTh": "ต้นผึ้ง",
    "subDistrictNameEn": "Ton Phueng",
    "postcode": "47160",
    "districtCode": "4705",
    "districtNameTh": "พังโคน",
    "districtNameEn": "Phang Khon",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470601",
    "subDistrictNameTh": "วาริชภูมิ",
    "subDistrictNameEn": "Waritchaphum",
    "postcode": "47150",
    "districtCode": "4706",
    "districtNameTh": "วาริชภูมิ",
    "districtNameEn": "Waritchaphum",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470602",
    "subDistrictNameTh": "ปลาโหล",
    "subDistrictNameEn": "Pla Lo",
    "postcode": "47150",
    "districtCode": "4706",
    "districtNameTh": "วาริชภูมิ",
    "districtNameEn": "Waritchaphum",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470603",
    "subDistrictNameTh": "หนองลาด",
    "subDistrictNameEn": "Nong Lat",
    "postcode": "47150",
    "districtCode": "4706",
    "districtNameTh": "วาริชภูมิ",
    "districtNameEn": "Waritchaphum",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470604",
    "subDistrictNameTh": "คำบ่อ",
    "subDistrictNameEn": "Kham Bo",
    "postcode": "47150",
    "districtCode": "4706",
    "districtNameTh": "วาริชภูมิ",
    "districtNameEn": "Waritchaphum",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470605",
    "subDistrictNameTh": "ค้อเขียว",
    "subDistrictNameEn": "Kho Khiao",
    "postcode": "47150",
    "districtCode": "4706",
    "districtNameTh": "วาริชภูมิ",
    "districtNameEn": "Waritchaphum",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470701",
    "subDistrictNameTh": "นิคมน้ำอูน",
    "subDistrictNameEn": "Nikhom Nam Un",
    "postcode": "47270",
    "districtCode": "4707",
    "districtNameTh": "นิคมน้ำอูน",
    "districtNameEn": "Nikhom Nam Un",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470702",
    "subDistrictNameTh": "หนองปลิง",
    "subDistrictNameEn": "Nong Pling",
    "postcode": "47270",
    "districtCode": "4707",
    "districtNameTh": "นิคมน้ำอูน",
    "districtNameEn": "Nikhom Nam Un",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470703",
    "subDistrictNameTh": "หนองบัว",
    "subDistrictNameEn": "Nong Bua",
    "postcode": "47270",
    "districtCode": "4707",
    "districtNameTh": "นิคมน้ำอูน",
    "districtNameEn": "Nikhom Nam Un",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470704",
    "subDistrictNameTh": "สุวรรณคาม",
    "subDistrictNameEn": "Suwannakham",
    "postcode": "47270",
    "districtCode": "4707",
    "districtNameTh": "นิคมน้ำอูน",
    "districtNameEn": "Nikhom Nam Un",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470801",
    "subDistrictNameTh": "วานรนิวาส",
    "subDistrictNameEn": "Wanon Niwat",
    "postcode": "47120",
    "districtCode": "4708",
    "districtNameTh": "วานรนิวาส",
    "districtNameEn": "Wanon Niwat",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470802",
    "subDistrictNameTh": "เดื่อศรีคันไชย",
    "subDistrictNameEn": "Duea Si Khan Chai",
    "postcode": "47120",
    "districtCode": "4708",
    "districtNameTh": "วานรนิวาส",
    "districtNameEn": "Wanon Niwat",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470803",
    "subDistrictNameTh": "ขัวก่าย",
    "subDistrictNameEn": "Khua Kai",
    "postcode": "47120",
    "districtCode": "4708",
    "districtNameTh": "วานรนิวาส",
    "districtNameEn": "Wanon Niwat",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470804",
    "subDistrictNameTh": "หนองสนม",
    "subDistrictNameEn": "Nong Sanom",
    "postcode": "47120",
    "districtCode": "4708",
    "districtNameTh": "วานรนิวาส",
    "districtNameEn": "Wanon Niwat",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470805",
    "subDistrictNameTh": "คูสะคาม",
    "subDistrictNameEn": "Khu Sakham",
    "postcode": "47120",
    "districtCode": "4708",
    "districtNameTh": "วานรนิวาส",
    "districtNameEn": "Wanon Niwat",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470806",
    "subDistrictNameTh": "ธาตุ",
    "subDistrictNameEn": "That",
    "postcode": "47120",
    "districtCode": "4708",
    "districtNameTh": "วานรนิวาส",
    "districtNameEn": "Wanon Niwat",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470807",
    "subDistrictNameTh": "หนองแวง",
    "subDistrictNameEn": "Nong Waeng",
    "postcode": "47120",
    "districtCode": "4708",
    "districtNameTh": "วานรนิวาส",
    "districtNameEn": "Wanon Niwat",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470808",
    "subDistrictNameTh": "ศรีวิชัย",
    "subDistrictNameEn": "Si Wichai",
    "postcode": "47120",
    "districtCode": "4708",
    "districtNameTh": "วานรนิวาส",
    "districtNameEn": "Wanon Niwat",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470809",
    "subDistrictNameTh": "นาซอ",
    "subDistrictNameEn": "Na So",
    "postcode": "47120",
    "districtCode": "4708",
    "districtNameTh": "วานรนิวาส",
    "districtNameEn": "Wanon Niwat",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470810",
    "subDistrictNameTh": "อินทร์แปลง",
    "subDistrictNameEn": "In Plaeng",
    "postcode": "47120",
    "districtCode": "4708",
    "districtNameTh": "วานรนิวาส",
    "districtNameEn": "Wanon Niwat",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470811",
    "subDistrictNameTh": "นาคำ",
    "subDistrictNameEn": "Na Kham",
    "postcode": "47120",
    "districtCode": "4708",
    "districtNameTh": "วานรนิวาส",
    "districtNameEn": "Wanon Niwat",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470812",
    "subDistrictNameTh": "คอนสวรรค์",
    "subDistrictNameEn": "Khon Sawan",
    "postcode": "47120",
    "districtCode": "4708",
    "districtNameTh": "วานรนิวาส",
    "districtNameEn": "Wanon Niwat",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470813",
    "subDistrictNameTh": "กุดเรือคำ",
    "subDistrictNameEn": "Kut Ruea Kham",
    "postcode": "47120",
    "districtCode": "4708",
    "districtNameTh": "วานรนิวาส",
    "districtNameEn": "Wanon Niwat",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470814",
    "subDistrictNameTh": "หนองแวงใต้",
    "subDistrictNameEn": "Nong Waeng Tai",
    "postcode": "47120",
    "districtCode": "4708",
    "districtNameTh": "วานรนิวาส",
    "districtNameEn": "Wanon Niwat",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470901",
    "subDistrictNameTh": "คำตากล้า",
    "subDistrictNameEn": "Kham Ta Kla",
    "postcode": "47250",
    "districtCode": "4709",
    "districtNameTh": "คำตากล้า",
    "districtNameEn": "Kham Ta Kla",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470902",
    "subDistrictNameTh": "หนองบัวสิม",
    "subDistrictNameEn": "Nong Bua Sim",
    "postcode": "47250",
    "districtCode": "4709",
    "districtNameTh": "คำตากล้า",
    "districtNameEn": "Kham Ta Kla",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470903",
    "subDistrictNameTh": "นาแต้",
    "subDistrictNameEn": "Na Tae",
    "postcode": "47250",
    "districtCode": "4709",
    "districtNameTh": "คำตากล้า",
    "districtNameEn": "Kham Ta Kla",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "470904",
    "subDistrictNameTh": "แพด",
    "subDistrictNameEn": "Phaet",
    "postcode": "47250",
    "districtCode": "4709",
    "districtNameTh": "คำตากล้า",
    "districtNameEn": "Kham Ta Kla",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471001",
    "subDistrictNameTh": "ม่วง",
    "subDistrictNameEn": "Muang",
    "postcode": "47140",
    "districtCode": "4710",
    "districtNameTh": "บ้านม่วง",
    "districtNameEn": "Ban Muang",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471002",
    "subDistrictNameTh": "มาย",
    "subDistrictNameEn": "Mai",
    "postcode": "47140",
    "districtCode": "4710",
    "districtNameTh": "บ้านม่วง",
    "districtNameEn": "Ban Muang",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471003",
    "subDistrictNameTh": "ดงหม้อทอง",
    "subDistrictNameEn": "Dong Mo Thong",
    "postcode": "47140",
    "districtCode": "4710",
    "districtNameTh": "บ้านม่วง",
    "districtNameEn": "Ban Muang",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471004",
    "subDistrictNameTh": "ดงเหนือ",
    "subDistrictNameEn": "Dong Nuea",
    "postcode": "47140",
    "districtCode": "4710",
    "districtNameTh": "บ้านม่วง",
    "districtNameEn": "Ban Muang",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471005",
    "subDistrictNameTh": "ดงหม้อทองใต้",
    "subDistrictNameEn": "Dong Mo Thong Tai",
    "postcode": "47140",
    "districtCode": "4710",
    "districtNameTh": "บ้านม่วง",
    "districtNameEn": "Ban Muang",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471006",
    "subDistrictNameTh": "ห้วยหลัว",
    "subDistrictNameEn": "Huai Lua",
    "postcode": "47140",
    "districtCode": "4710",
    "districtNameTh": "บ้านม่วง",
    "districtNameEn": "Ban Muang",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471007",
    "subDistrictNameTh": "โนนสะอาด",
    "subDistrictNameEn": "Non Sa-at",
    "postcode": "47140",
    "districtCode": "4710",
    "districtNameTh": "บ้านม่วง",
    "districtNameEn": "Ban Muang",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471008",
    "subDistrictNameTh": "หนองกวั่ง",
    "subDistrictNameEn": "Nong Kwang",
    "postcode": "47140",
    "districtCode": "4710",
    "districtNameTh": "บ้านม่วง",
    "districtNameEn": "Ban Muang",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471009",
    "subDistrictNameTh": "บ่อแก้ว",
    "subDistrictNameEn": "Bo Kaeo",
    "postcode": "47140",
    "districtCode": "4710",
    "districtNameTh": "บ้านม่วง",
    "districtNameEn": "Ban Muang",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471101",
    "subDistrictNameTh": "อากาศ",
    "subDistrictNameEn": "Akat",
    "postcode": "47170",
    "districtCode": "4711",
    "districtNameTh": "อากาศอำนวย",
    "districtNameEn": "Akat Amnuai",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471102",
    "subDistrictNameTh": "โพนแพง",
    "subDistrictNameEn": "Phon Phaeng",
    "postcode": "47170",
    "districtCode": "4711",
    "districtNameTh": "อากาศอำนวย",
    "districtNameEn": "Akat Amnuai",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471103",
    "subDistrictNameTh": "วาใหญ่",
    "subDistrictNameEn": "Wa Yai",
    "postcode": "47170",
    "districtCode": "4711",
    "districtNameTh": "อากาศอำนวย",
    "districtNameEn": "Akat Amnuai",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471104",
    "subDistrictNameTh": "โพนงาม",
    "subDistrictNameEn": "Phon Ngam",
    "postcode": "47170",
    "districtCode": "4711",
    "districtNameTh": "อากาศอำนวย",
    "districtNameEn": "Akat Amnuai",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471105",
    "subDistrictNameTh": "ท่าก้อน",
    "subDistrictNameEn": "Tha Kon",
    "postcode": "47170",
    "districtCode": "4711",
    "districtNameTh": "อากาศอำนวย",
    "districtNameEn": "Akat Amnuai",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471106",
    "subDistrictNameTh": "นาฮี",
    "subDistrictNameEn": "Na Hi",
    "postcode": "47170",
    "districtCode": "4711",
    "districtNameTh": "อากาศอำนวย",
    "districtNameEn": "Akat Amnuai",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471107",
    "subDistrictNameTh": "บะหว้า",
    "subDistrictNameEn": "Ba Wa",
    "postcode": "47170",
    "districtCode": "4711",
    "districtNameTh": "อากาศอำนวย",
    "districtNameEn": "Akat Amnuai",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471108",
    "subDistrictNameTh": "สามัคคีพัฒนา",
    "subDistrictNameEn": "Samakkhi Phatthana",
    "postcode": "47170",
    "districtCode": "4711",
    "districtNameTh": "อากาศอำนวย",
    "districtNameEn": "Akat Amnuai",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471201",
    "subDistrictNameTh": "สว่างแดนดิน",
    "subDistrictNameEn": "Sawang Daen Din",
    "postcode": "47110",
    "districtCode": "4712",
    "districtNameTh": "สว่างแดนดิน",
    "districtNameEn": "Sawang Daen Din",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471203",
    "subDistrictNameTh": "คำสะอาด",
    "subDistrictNameEn": "Kham Sa-at",
    "postcode": "47110",
    "districtCode": "4712",
    "districtNameTh": "สว่างแดนดิน",
    "districtNameEn": "Sawang Daen Din",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471204",
    "subDistrictNameTh": "บ้านต้าย",
    "subDistrictNameEn": "Ban Tai",
    "postcode": "47110",
    "districtCode": "4712",
    "districtNameTh": "สว่างแดนดิน",
    "districtNameEn": "Sawang Daen Din",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471206",
    "subDistrictNameTh": "บงเหนือ",
    "subDistrictNameEn": "Bong Nuea",
    "postcode": "47110",
    "districtCode": "4712",
    "districtNameTh": "สว่างแดนดิน",
    "districtNameEn": "Sawang Daen Din",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471207",
    "subDistrictNameTh": "โพนสูง",
    "subDistrictNameEn": "Phon Sung",
    "postcode": "47110",
    "districtCode": "4712",
    "districtNameTh": "สว่างแดนดิน",
    "districtNameEn": "Sawang Daen Din",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471208",
    "subDistrictNameTh": "โคกสี",
    "subDistrictNameEn": "Khok Si",
    "postcode": "47110",
    "districtCode": "4712",
    "districtNameTh": "สว่างแดนดิน",
    "districtNameEn": "Sawang Daen Din",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471210",
    "subDistrictNameTh": "หนองหลวง",
    "subDistrictNameEn": "Nong Luang",
    "postcode": "47110",
    "districtCode": "4712",
    "districtNameTh": "สว่างแดนดิน",
    "districtNameEn": "Sawang Daen Din",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471211",
    "subDistrictNameTh": "บงใต้",
    "subDistrictNameEn": "Bong Tai",
    "postcode": "47110",
    "districtCode": "4712",
    "districtNameTh": "สว่างแดนดิน",
    "districtNameEn": "Sawang Daen Din",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471212",
    "subDistrictNameTh": "ค้อใต้",
    "subDistrictNameEn": "Kho Tai",
    "postcode": "47110",
    "districtCode": "4712",
    "districtNameTh": "สว่างแดนดิน",
    "districtNameEn": "Sawang Daen Din",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471213",
    "subDistrictNameTh": "พันนา",
    "subDistrictNameEn": "Phan Na",
    "postcode": "47240",
    "districtCode": "4712",
    "districtNameTh": "สว่างแดนดิน",
    "districtNameEn": "Sawang Daen Din",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471214",
    "subDistrictNameTh": "แวง",
    "subDistrictNameEn": "Waeng",
    "postcode": "47240",
    "districtCode": "4712",
    "districtNameTh": "สว่างแดนดิน",
    "districtNameEn": "Sawang Daen Din",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471215",
    "subDistrictNameTh": "ทรายมูล",
    "subDistrictNameEn": "Sai Mun",
    "postcode": "47110",
    "districtCode": "4712",
    "districtNameTh": "สว่างแดนดิน",
    "districtNameEn": "Sawang Daen Din",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471216",
    "subDistrictNameTh": "ตาลโกน",
    "subDistrictNameEn": "Tan Kon",
    "postcode": "47240",
    "districtCode": "4712",
    "districtNameTh": "สว่างแดนดิน",
    "districtNameEn": "Sawang Daen Din",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471217",
    "subDistrictNameTh": "ตาลเนิ้ง",
    "subDistrictNameEn": "Tan Noeng",
    "postcode": "47240",
    "districtCode": "4712",
    "districtNameTh": "สว่างแดนดิน",
    "districtNameEn": "Sawang Daen Din",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471220",
    "subDistrictNameTh": "ธาตุทอง",
    "subDistrictNameEn": "That Thong",
    "postcode": "47240",
    "districtCode": "4712",
    "districtNameTh": "สว่างแดนดิน",
    "districtNameEn": "Sawang Daen Din",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471221",
    "subDistrictNameTh": "บ้านถ่อน",
    "subDistrictNameEn": "Ban Thon",
    "postcode": "47110",
    "districtCode": "4712",
    "districtNameTh": "สว่างแดนดิน",
    "districtNameEn": "Sawang Daen Din",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471301",
    "subDistrictNameTh": "ส่องดาว",
    "subDistrictNameEn": "Song Dao",
    "postcode": "47190",
    "districtCode": "4713",
    "districtNameTh": "ส่องดาว",
    "districtNameEn": "Song Dao",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471302",
    "subDistrictNameTh": "ท่าศิลา",
    "subDistrictNameEn": "Tha Sila",
    "postcode": "47190",
    "districtCode": "4713",
    "districtNameTh": "ส่องดาว",
    "districtNameEn": "Song Dao",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471303",
    "subDistrictNameTh": "วัฒนา",
    "subDistrictNameEn": "Watthana",
    "postcode": "47190",
    "districtCode": "4713",
    "districtNameTh": "ส่องดาว",
    "districtNameEn": "Song Dao",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471304",
    "subDistrictNameTh": "ปทุมวาปี",
    "subDistrictNameEn": "Pathum Wapi",
    "postcode": "47190",
    "districtCode": "4713",
    "districtNameTh": "ส่องดาว",
    "districtNameEn": "Song Dao",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471401",
    "subDistrictNameTh": "เต่างอย",
    "subDistrictNameEn": "Tao Ngoi",
    "postcode": "47260",
    "districtCode": "4714",
    "districtNameTh": "เต่างอย",
    "districtNameEn": "Tao Ngoi",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471402",
    "subDistrictNameTh": "บึงทวาย",
    "subDistrictNameEn": "Bueng Thawai",
    "postcode": "47260",
    "districtCode": "4714",
    "districtNameTh": "เต่างอย",
    "districtNameEn": "Tao Ngoi",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471403",
    "subDistrictNameTh": "นาตาล",
    "subDistrictNameEn": "Na Tan",
    "postcode": "47260",
    "districtCode": "4714",
    "districtNameTh": "เต่างอย",
    "districtNameEn": "Tao Ngoi",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471404",
    "subDistrictNameTh": "จันทร์เพ็ญ",
    "subDistrictNameEn": "Chan Phen",
    "postcode": "47260",
    "districtCode": "4714",
    "districtNameTh": "เต่างอย",
    "districtNameEn": "Tao Ngoi",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471501",
    "subDistrictNameTh": "ตองโขบ",
    "subDistrictNameEn": "Tong Khop",
    "postcode": "47280",
    "districtCode": "4715",
    "districtNameTh": "โคกศรีสุพรรณ",
    "districtNameEn": "Khok Si Suphan",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471502",
    "subDistrictNameTh": "เหล่าโพนค้อ",
    "subDistrictNameEn": "Lao Phon Kho",
    "postcode": "47280",
    "districtCode": "4715",
    "districtNameTh": "โคกศรีสุพรรณ",
    "districtNameEn": "Khok Si Suphan",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471503",
    "subDistrictNameTh": "ด่านม่วงคำ",
    "subDistrictNameEn": "Dan Muang Kham",
    "postcode": "47280",
    "districtCode": "4715",
    "districtNameTh": "โคกศรีสุพรรณ",
    "districtNameEn": "Khok Si Suphan",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471504",
    "subDistrictNameTh": "แมดนาท่ม",
    "subDistrictNameEn": "Maet Na Thom",
    "postcode": "47280",
    "districtCode": "4715",
    "districtNameTh": "โคกศรีสุพรรณ",
    "districtNameEn": "Khok Si Suphan",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471601",
    "subDistrictNameTh": "บ้านเหล่า",
    "subDistrictNameEn": "Ban Lao",
    "postcode": "47290",
    "districtCode": "4716",
    "districtNameTh": "เจริญศิลป์",
    "districtNameEn": "Charoen Sin",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471602",
    "subDistrictNameTh": "เจริญศิลป์",
    "subDistrictNameEn": "Charoen Sin",
    "postcode": "47290",
    "districtCode": "4716",
    "districtNameTh": "เจริญศิลป์",
    "districtNameEn": "Charoen Sin",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471603",
    "subDistrictNameTh": "ทุ่งแก",
    "subDistrictNameEn": "Thung Kae",
    "postcode": "47290",
    "districtCode": "4716",
    "districtNameTh": "เจริญศิลป์",
    "districtNameEn": "Charoen Sin",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471604",
    "subDistrictNameTh": "โคกศิลา",
    "subDistrictNameEn": "Khok Sila",
    "postcode": "47290",
    "districtCode": "4716",
    "districtNameTh": "เจริญศิลป์",
    "districtNameEn": "Charoen Sin",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471605",
    "subDistrictNameTh": "หนองแปน",
    "subDistrictNameEn": "Nong Paen",
    "postcode": "47290",
    "districtCode": "4716",
    "districtNameTh": "เจริญศิลป์",
    "districtNameEn": "Charoen Sin",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471701",
    "subDistrictNameTh": "บ้านโพน",
    "subDistrictNameEn": "Ban Phon",
    "postcode": "47230",
    "districtCode": "4717",
    "districtNameTh": "โพนนาแก้ว",
    "districtNameEn": "Phon Na Kaeo",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471702",
    "subDistrictNameTh": "นาแก้ว",
    "subDistrictNameEn": "Na Kaeo",
    "postcode": "47230",
    "districtCode": "4717",
    "districtNameTh": "โพนนาแก้ว",
    "districtNameEn": "Phon Na Kaeo",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471703",
    "subDistrictNameTh": "นาตงวัฒนา",
    "subDistrictNameEn": "Na Tong Watthana",
    "postcode": "47230",
    "districtCode": "4717",
    "districtNameTh": "โพนนาแก้ว",
    "districtNameEn": "Phon Na Kaeo",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471704",
    "subDistrictNameTh": "บ้านแป้น",
    "subDistrictNameEn": "Ban Paen",
    "postcode": "47230",
    "districtCode": "4717",
    "districtNameTh": "โพนนาแก้ว",
    "districtNameEn": "Phon Na Kaeo",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471705",
    "subDistrictNameTh": "เชียงสือ",
    "subDistrictNameEn": "Chiang Sue",
    "postcode": "47230",
    "districtCode": "4717",
    "districtNameTh": "โพนนาแก้ว",
    "districtNameEn": "Phon Na Kaeo",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471801",
    "subDistrictNameTh": "สร้างค้อ",
    "subDistrictNameEn": "Sang Kho",
    "postcode": "47180",
    "districtCode": "4718",
    "districtNameTh": "ภูพาน",
    "districtNameEn": "Phu Phan",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471802",
    "subDistrictNameTh": "หลุบเลา",
    "subDistrictNameEn": "Lup Lao",
    "postcode": "47180",
    "districtCode": "4718",
    "districtNameTh": "ภูพาน",
    "districtNameEn": "Phu Phan",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471803",
    "subDistrictNameTh": "โคกภู",
    "subDistrictNameEn": "Khok Phu",
    "postcode": "47180",
    "districtCode": "4718",
    "districtNameTh": "ภูพาน",
    "districtNameEn": "Phu Phan",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "471804",
    "subDistrictNameTh": "กกปลาซิว",
    "subDistrictNameEn": "Kok Pla Sio",
    "postcode": "47180",
    "districtCode": "4718",
    "districtNameTh": "ภูพาน",
    "districtNameEn": "Phu Phan",
    "provinceCode": "47",
    "provinceNameTh": "สกลนคร",
    "provinceNameEn": "Sakon Nakhon"
},
{
    "subDistrictCode": "480101",
    "subDistrictNameTh": "ในเมือง",
    "subDistrictNameEn": "Nai Mueang",
    "postcode": "48000",
    "districtCode": "4801",
    "districtNameTh": "เมืองนครพนม",
    "districtNameEn": "Mueang Nakhon Phanom",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480102",
    "subDistrictNameTh": "หนองแสง",
    "subDistrictNameEn": "Nong Saeng",
    "postcode": "48000",
    "districtCode": "4801",
    "districtNameTh": "เมืองนครพนม",
    "districtNameEn": "Mueang Nakhon Phanom",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480103",
    "subDistrictNameTh": "นาทราย",
    "subDistrictNameEn": "Na Sai",
    "postcode": "48000",
    "districtCode": "4801",
    "districtNameTh": "เมืองนครพนม",
    "districtNameEn": "Mueang Nakhon Phanom",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480104",
    "subDistrictNameTh": "นาราชควาย",
    "subDistrictNameEn": "Na Rat Khwai",
    "postcode": "48000",
    "districtCode": "4801",
    "districtNameTh": "เมืองนครพนม",
    "districtNameEn": "Mueang Nakhon Phanom",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480105",
    "subDistrictNameTh": "กุรุคุ",
    "subDistrictNameEn": "Kurukhu",
    "postcode": "48000",
    "districtCode": "4801",
    "districtNameTh": "เมืองนครพนม",
    "districtNameEn": "Mueang Nakhon Phanom",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480106",
    "subDistrictNameTh": "บ้านผึ้ง",
    "subDistrictNameEn": "Ban Phueng",
    "postcode": "48000",
    "districtCode": "4801",
    "districtNameTh": "เมืองนครพนม",
    "districtNameEn": "Mueang Nakhon Phanom",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480107",
    "subDistrictNameTh": "อาจสามารถ",
    "subDistrictNameEn": "At Samat",
    "postcode": "48000",
    "districtCode": "4801",
    "districtNameTh": "เมืองนครพนม",
    "districtNameEn": "Mueang Nakhon Phanom",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480108",
    "subDistrictNameTh": "ขามเฒ่า",
    "subDistrictNameEn": "Kham Thao",
    "postcode": "48000",
    "districtCode": "4801",
    "districtNameTh": "เมืองนครพนม",
    "districtNameEn": "Mueang Nakhon Phanom",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480109",
    "subDistrictNameTh": "บ้านกลาง",
    "subDistrictNameEn": "Ban Klang",
    "postcode": "48000",
    "districtCode": "4801",
    "districtNameTh": "เมืองนครพนม",
    "districtNameEn": "Mueang Nakhon Phanom",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480110",
    "subDistrictNameTh": "ท่าค้อ",
    "subDistrictNameEn": "Tha Kho",
    "postcode": "48000",
    "districtCode": "4801",
    "districtNameTh": "เมืองนครพนม",
    "districtNameEn": "Mueang Nakhon Phanom",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480111",
    "subDistrictNameTh": "คำเตย",
    "subDistrictNameEn": "Kham Toei",
    "postcode": "48000",
    "districtCode": "4801",
    "districtNameTh": "เมืองนครพนม",
    "districtNameEn": "Mueang Nakhon Phanom",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480112",
    "subDistrictNameTh": "หนองญาติ",
    "subDistrictNameEn": "Nong Yat",
    "postcode": "48000",
    "districtCode": "4801",
    "districtNameTh": "เมืองนครพนม",
    "districtNameEn": "Mueang Nakhon Phanom",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480113",
    "subDistrictNameTh": "ดงขวาง",
    "subDistrictNameEn": "Dong Khwang",
    "postcode": "48000",
    "districtCode": "4801",
    "districtNameTh": "เมืองนครพนม",
    "districtNameEn": "Mueang Nakhon Phanom",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480114",
    "subDistrictNameTh": "วังตามัว",
    "subDistrictNameEn": "Wang Ta Mua",
    "postcode": "48000",
    "districtCode": "4801",
    "districtNameTh": "เมืองนครพนม",
    "districtNameEn": "Mueang Nakhon Phanom",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480115",
    "subDistrictNameTh": "โพธิ์ตาก",
    "subDistrictNameEn": "Pho Tak",
    "postcode": "48000",
    "districtCode": "4801",
    "districtNameTh": "เมืองนครพนม",
    "districtNameEn": "Mueang Nakhon Phanom",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480201",
    "subDistrictNameTh": "ปลาปาก",
    "subDistrictNameEn": "Pla Pak",
    "postcode": "48160",
    "districtCode": "4802",
    "districtNameTh": "ปลาปาก",
    "districtNameEn": "Pla Pak",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480202",
    "subDistrictNameTh": "หนองฮี",
    "subDistrictNameEn": "Nong Hi",
    "postcode": "48160",
    "districtCode": "4802",
    "districtNameTh": "ปลาปาก",
    "districtNameEn": "Pla Pak",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480203",
    "subDistrictNameTh": "กุตาไก้",
    "subDistrictNameEn": "Kutakai",
    "postcode": "48160",
    "districtCode": "4802",
    "districtNameTh": "ปลาปาก",
    "districtNameEn": "Pla Pak",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480204",
    "subDistrictNameTh": "โคกสว่าง",
    "subDistrictNameEn": "Khok Sawang",
    "postcode": "48160",
    "districtCode": "4802",
    "districtNameTh": "ปลาปาก",
    "districtNameEn": "Pla Pak",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480205",
    "subDistrictNameTh": "โคกสูง",
    "subDistrictNameEn": "Khok Sung",
    "postcode": "48160",
    "districtCode": "4802",
    "districtNameTh": "ปลาปาก",
    "districtNameEn": "Pla Pak",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480206",
    "subDistrictNameTh": "มหาชัย",
    "subDistrictNameEn": "Maha Chai",
    "postcode": "48160",
    "districtCode": "4802",
    "districtNameTh": "ปลาปาก",
    "districtNameEn": "Pla Pak",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480207",
    "subDistrictNameTh": "นามะเขือ",
    "subDistrictNameEn": "Na Makhuea",
    "postcode": "48160",
    "districtCode": "4802",
    "districtNameTh": "ปลาปาก",
    "districtNameEn": "Pla Pak",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480208",
    "subDistrictNameTh": "หนองเทาใหญ่",
    "subDistrictNameEn": "Nong Thao Yai",
    "postcode": "48160",
    "districtCode": "4802",
    "districtNameTh": "ปลาปาก",
    "districtNameEn": "Pla Pak",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480301",
    "subDistrictNameTh": "ท่าอุเทน",
    "subDistrictNameEn": "Tha Uthen",
    "postcode": "48120",
    "districtCode": "4803",
    "districtNameTh": "ท่าอุเทน",
    "districtNameEn": "Tha Uthen",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480302",
    "subDistrictNameTh": "โนนตาล",
    "subDistrictNameEn": "Non Tan",
    "postcode": "48120",
    "districtCode": "4803",
    "districtNameTh": "ท่าอุเทน",
    "districtNameEn": "Tha Uthen",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480303",
    "subDistrictNameTh": "ท่าจำปา",
    "subDistrictNameEn": "Tha Champa",
    "postcode": "48120",
    "districtCode": "4803",
    "districtNameTh": "ท่าอุเทน",
    "districtNameEn": "Tha Uthen",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480304",
    "subDistrictNameTh": "ไชยบุรี",
    "subDistrictNameEn": "Chaiburi",
    "postcode": "48120",
    "districtCode": "4803",
    "districtNameTh": "ท่าอุเทน",
    "districtNameEn": "Tha Uthen",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480305",
    "subDistrictNameTh": "พนอม",
    "subDistrictNameEn": "Phanom",
    "postcode": "48120",
    "districtCode": "4803",
    "districtNameTh": "ท่าอุเทน",
    "districtNameEn": "Tha Uthen",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480306",
    "subDistrictNameTh": "พะทาย",
    "subDistrictNameEn": "Phathai",
    "postcode": "48120",
    "districtCode": "4803",
    "districtNameTh": "ท่าอุเทน",
    "districtNameEn": "Tha Uthen",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480311",
    "subDistrictNameTh": "เวินพระบาท",
    "subDistrictNameEn": "Woen Phrabat",
    "postcode": "48120",
    "districtCode": "4803",
    "districtNameTh": "ท่าอุเทน",
    "districtNameEn": "Tha Uthen",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480312",
    "subDistrictNameTh": "รามราช",
    "subDistrictNameEn": "Ram Rat",
    "postcode": "48120",
    "districtCode": "4803",
    "districtNameTh": "ท่าอุเทน",
    "districtNameEn": "Tha Uthen",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480314",
    "subDistrictNameTh": "หนองเทา",
    "subDistrictNameEn": "Nong Thao",
    "postcode": "48120",
    "districtCode": "4803",
    "districtNameTh": "ท่าอุเทน",
    "districtNameEn": "Tha Uthen",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480401",
    "subDistrictNameTh": "บ้านแพง",
    "subDistrictNameEn": "Ban Phaeng",
    "postcode": "48140",
    "districtCode": "4804",
    "districtNameTh": "บ้านแพง",
    "districtNameEn": "Ban Phaeng",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480402",
    "subDistrictNameTh": "ไผ่ล้อม",
    "subDistrictNameEn": "Phai Lom",
    "postcode": "48140",
    "districtCode": "4804",
    "districtNameTh": "บ้านแพง",
    "districtNameEn": "Ban Phaeng",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480403",
    "subDistrictNameTh": "โพนทอง",
    "subDistrictNameEn": "Phon Thong",
    "postcode": "48140",
    "districtCode": "4804",
    "districtNameTh": "บ้านแพง",
    "districtNameEn": "Ban Phaeng",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480404",
    "subDistrictNameTh": "หนองแวง",
    "subDistrictNameEn": "Nong Waeng",
    "postcode": "48140",
    "districtCode": "4804",
    "districtNameTh": "บ้านแพง",
    "districtNameEn": "Ban Phaeng",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480408",
    "subDistrictNameTh": "นางัว",
    "subDistrictNameEn": "Na Ngua",
    "postcode": "48140",
    "districtCode": "4804",
    "districtNameTh": "บ้านแพง",
    "districtNameEn": "Ban Phaeng",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480409",
    "subDistrictNameTh": "นาเข",
    "subDistrictNameEn": "Na Khe",
    "postcode": "48140",
    "districtCode": "4804",
    "districtNameTh": "บ้านแพง",
    "districtNameEn": "Ban Phaeng",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480501",
    "subDistrictNameTh": "ธาตุพนม",
    "subDistrictNameEn": "That Phanom",
    "postcode": "48110",
    "districtCode": "4805",
    "districtNameTh": "ธาตุพนม",
    "districtNameEn": "That Phanom",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480502",
    "subDistrictNameTh": "ฝั่งแดง",
    "subDistrictNameEn": "Fang Daeng",
    "postcode": "48110",
    "districtCode": "4805",
    "districtNameTh": "ธาตุพนม",
    "districtNameEn": "That Phanom",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480503",
    "subDistrictNameTh": "โพนแพง",
    "subDistrictNameEn": "Phon Phaeng",
    "postcode": "48110",
    "districtCode": "4805",
    "districtNameTh": "ธาตุพนม",
    "districtNameEn": "That Phanom",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480504",
    "subDistrictNameTh": "พระกลางทุ่ง",
    "subDistrictNameEn": "Phra Klang Thung",
    "postcode": "48110",
    "districtCode": "4805",
    "districtNameTh": "ธาตุพนม",
    "districtNameEn": "That Phanom",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480505",
    "subDistrictNameTh": "นาถ่อน",
    "subDistrictNameEn": "Na Thon",
    "postcode": "48110",
    "districtCode": "4805",
    "districtNameTh": "ธาตุพนม",
    "districtNameEn": "That Phanom",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480506",
    "subDistrictNameTh": "แสนพัน",
    "subDistrictNameEn": "Saen Phan",
    "postcode": "48110",
    "districtCode": "4805",
    "districtNameTh": "ธาตุพนม",
    "districtNameEn": "That Phanom",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480507",
    "subDistrictNameTh": "ดอนนางหงส์",
    "subDistrictNameEn": "Don Nang Hong",
    "postcode": "48110",
    "districtCode": "4805",
    "districtNameTh": "ธาตุพนม",
    "districtNameEn": "That Phanom",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480508",
    "subDistrictNameTh": "น้ำก่ำ",
    "subDistrictNameEn": "Nam Kam",
    "postcode": "48110",
    "districtCode": "4805",
    "districtNameTh": "ธาตุพนม",
    "districtNameEn": "That Phanom",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480509",
    "subDistrictNameTh": "อุ่มเหม้า",
    "subDistrictNameEn": "Um Mao",
    "postcode": "48110",
    "districtCode": "4805",
    "districtNameTh": "ธาตุพนม",
    "districtNameEn": "That Phanom",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480510",
    "subDistrictNameTh": "นาหนาด",
    "subDistrictNameEn": "Na Nat",
    "postcode": "48110",
    "districtCode": "4805",
    "districtNameTh": "ธาตุพนม",
    "districtNameEn": "That Phanom",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480511",
    "subDistrictNameTh": "กุดฉิม",
    "subDistrictNameEn": "Kut Chim",
    "postcode": "48110",
    "districtCode": "4805",
    "districtNameTh": "ธาตุพนม",
    "districtNameEn": "That Phanom",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480512",
    "subDistrictNameTh": "ธาตุพนมเหนือ",
    "subDistrictNameEn": "That Phanom Nuea",
    "postcode": "48110",
    "districtCode": "4805",
    "districtNameTh": "ธาตุพนม",
    "districtNameEn": "That Phanom",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480601",
    "subDistrictNameTh": "เรณู",
    "subDistrictNameEn": "Renu",
    "postcode": "48170",
    "districtCode": "4806",
    "districtNameTh": "เรณูนคร",
    "districtNameEn": "Renu Nakhon",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480602",
    "subDistrictNameTh": "โพนทอง",
    "subDistrictNameEn": "Phon Thong",
    "postcode": "48170",
    "districtCode": "4806",
    "districtNameTh": "เรณูนคร",
    "districtNameEn": "Renu Nakhon",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480603",
    "subDistrictNameTh": "ท่าลาด",
    "subDistrictNameEn": "Tha Lat",
    "postcode": "48170",
    "districtCode": "4806",
    "districtNameTh": "เรณูนคร",
    "districtNameEn": "Renu Nakhon",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480604",
    "subDistrictNameTh": "นางาม",
    "subDistrictNameEn": "Na Ngam",
    "postcode": "48170",
    "districtCode": "4806",
    "districtNameTh": "เรณูนคร",
    "districtNameEn": "Renu Nakhon",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480605",
    "subDistrictNameTh": "โคกหินแฮ่",
    "subDistrictNameEn": "Khok Hin Hae",
    "postcode": "48170",
    "districtCode": "4806",
    "districtNameTh": "เรณูนคร",
    "districtNameEn": "Renu Nakhon",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480607",
    "subDistrictNameTh": "หนองย่างชิ้น",
    "subDistrictNameEn": "Nong Yang Chin",
    "postcode": "48170",
    "districtCode": "4806",
    "districtNameTh": "เรณูนคร",
    "districtNameEn": "Renu Nakhon",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480608",
    "subDistrictNameTh": "เรณูใต้",
    "subDistrictNameEn": "Renu Tai",
    "postcode": "48170",
    "districtCode": "4806",
    "districtNameTh": "เรณูนคร",
    "districtNameEn": "Renu Nakhon",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480609",
    "subDistrictNameTh": "นาขาม",
    "subDistrictNameEn": "Na Kham",
    "postcode": "48170",
    "districtCode": "4806",
    "districtNameTh": "เรณูนคร",
    "districtNameEn": "Renu Nakhon",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480701",
    "subDistrictNameTh": "นาแก",
    "subDistrictNameEn": "Na Kae",
    "postcode": "48130",
    "districtCode": "4807",
    "districtNameTh": "นาแก",
    "districtNameEn": "Na Kae",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480702",
    "subDistrictNameTh": "พระซอง",
    "subDistrictNameEn": "Phra Song",
    "postcode": "48130",
    "districtCode": "4807",
    "districtNameTh": "นาแก",
    "districtNameEn": "Na Kae",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480703",
    "subDistrictNameTh": "หนองสังข์",
    "subDistrictNameEn": "Nong Sang",
    "postcode": "48130",
    "districtCode": "4807",
    "districtNameTh": "นาแก",
    "districtNameEn": "Na Kae",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480704",
    "subDistrictNameTh": "นาคู่",
    "subDistrictNameEn": "Na Khu",
    "postcode": "48130",
    "districtCode": "4807",
    "districtNameTh": "นาแก",
    "districtNameEn": "Na Kae",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480705",
    "subDistrictNameTh": "พิมาน",
    "subDistrictNameEn": "Phiman",
    "postcode": "48130",
    "districtCode": "4807",
    "districtNameTh": "นาแก",
    "districtNameEn": "Na Kae",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480706",
    "subDistrictNameTh": "พุ่มแก",
    "subDistrictNameEn": "Phum Kae",
    "postcode": "48130",
    "districtCode": "4807",
    "districtNameTh": "นาแก",
    "districtNameEn": "Na Kae",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480707",
    "subDistrictNameTh": "ก้านเหลือง",
    "subDistrictNameEn": "Kan Lueang",
    "postcode": "48130",
    "districtCode": "4807",
    "districtNameTh": "นาแก",
    "districtNameEn": "Na Kae",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480708",
    "subDistrictNameTh": "หนองบ่อ",
    "subDistrictNameEn": "Nong Bo",
    "postcode": "48130",
    "districtCode": "4807",
    "districtNameTh": "นาแก",
    "districtNameEn": "Na Kae",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480709",
    "subDistrictNameTh": "นาเลียง",
    "subDistrictNameEn": "Na Liang",
    "postcode": "48130",
    "districtCode": "4807",
    "districtNameTh": "นาแก",
    "districtNameEn": "Na Kae",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480712",
    "subDistrictNameTh": "บ้านแก้ง",
    "subDistrictNameEn": "Ban Kaeng",
    "postcode": "48130",
    "districtCode": "4807",
    "districtNameTh": "นาแก",
    "districtNameEn": "Na Kae",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480713",
    "subDistrictNameTh": "คำพี้",
    "subDistrictNameEn": "Ham Phi",
    "postcode": "48130",
    "districtCode": "4807",
    "districtNameTh": "นาแก",
    "districtNameEn": "Na Kae",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480715",
    "subDistrictNameTh": "สีชมพู",
    "subDistrictNameEn": "Si Chomphu",
    "postcode": "48130",
    "districtCode": "4807",
    "districtNameTh": "นาแก",
    "districtNameEn": "Na Kae",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480801",
    "subDistrictNameTh": "ศรีสงคราม",
    "subDistrictNameEn": "Si Songkhram",
    "postcode": "48150",
    "districtCode": "4808",
    "districtNameTh": "ศรีสงคราม",
    "districtNameEn": "Si Songkhram",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480802",
    "subDistrictNameTh": "นาเดื่อ",
    "subDistrictNameEn": "Na Duea",
    "postcode": "48150",
    "districtCode": "4808",
    "districtNameTh": "ศรีสงคราม",
    "districtNameEn": "Si Songkhram",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480803",
    "subDistrictNameTh": "บ้านเอื้อง",
    "subDistrictNameEn": "Ban Ueang",
    "postcode": "48150",
    "districtCode": "4808",
    "districtNameTh": "ศรีสงคราม",
    "districtNameEn": "Si Songkhram",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480804",
    "subDistrictNameTh": "สามผง",
    "subDistrictNameEn": "sam Phong",
    "postcode": "48150",
    "districtCode": "4808",
    "districtNameTh": "ศรีสงคราม",
    "districtNameEn": "Si Songkhram",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480805",
    "subDistrictNameTh": "ท่าบ่อสงคราม",
    "subDistrictNameEn": "Tha Bo Songkhram",
    "postcode": "48150",
    "districtCode": "4808",
    "districtNameTh": "ศรีสงคราม",
    "districtNameEn": "Si Songkhram",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480806",
    "subDistrictNameTh": "บ้านข่า",
    "subDistrictNameEn": "Ban Kha",
    "postcode": "48150",
    "districtCode": "4808",
    "districtNameTh": "ศรีสงคราม",
    "districtNameEn": "Si Songkhram",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480807",
    "subDistrictNameTh": "นาคำ",
    "subDistrictNameEn": "Na Kham",
    "postcode": "48150",
    "districtCode": "4808",
    "districtNameTh": "ศรีสงคราม",
    "districtNameEn": "Si Songkhram",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480808",
    "subDistrictNameTh": "โพนสว่าง",
    "subDistrictNameEn": "Phon Sawang",
    "postcode": "48150",
    "districtCode": "4808",
    "districtNameTh": "ศรีสงคราม",
    "districtNameEn": "Si Songkhram",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480809",
    "subDistrictNameTh": "หาดแพง",
    "subDistrictNameEn": "Hat Phaeng",
    "postcode": "48150",
    "districtCode": "4808",
    "districtNameTh": "ศรีสงคราม",
    "districtNameEn": "Si Songkhram",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480901",
    "subDistrictNameTh": "นาหว้า",
    "subDistrictNameEn": "Na Wa",
    "postcode": "48180",
    "districtCode": "4809",
    "districtNameTh": "นาหว้า",
    "districtNameEn": "Na Wa",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480902",
    "subDistrictNameTh": "นางัว",
    "subDistrictNameEn": "Na Ngua",
    "postcode": "48180",
    "districtCode": "4809",
    "districtNameTh": "นาหว้า",
    "districtNameEn": "Na Wa",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480903",
    "subDistrictNameTh": "บ้านเสียว",
    "subDistrictNameEn": "Ban Siao",
    "postcode": "48180",
    "districtCode": "4809",
    "districtNameTh": "นาหว้า",
    "districtNameEn": "Na Wa",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480904",
    "subDistrictNameTh": "นาคูณใหญ่",
    "subDistrictNameEn": "Na Khun Yai",
    "postcode": "48180",
    "districtCode": "4809",
    "districtNameTh": "นาหว้า",
    "districtNameEn": "Na Wa",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480905",
    "subDistrictNameTh": "เหล่าพัฒนา",
    "subDistrictNameEn": "Lao Phatthana",
    "postcode": "48180",
    "districtCode": "4809",
    "districtNameTh": "นาหว้า",
    "districtNameEn": "Na Wa",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "480906",
    "subDistrictNameTh": "ท่าเรือ",
    "subDistrictNameEn": "Tha Ruea",
    "postcode": "48180",
    "districtCode": "4809",
    "districtNameTh": "นาหว้า",
    "districtNameEn": "Na Wa",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "481001",
    "subDistrictNameTh": "โพนสวรรค์",
    "subDistrictNameEn": "Phon Sawan",
    "postcode": "48190",
    "districtCode": "4810",
    "districtNameTh": "โพนสวรรค์",
    "districtNameEn": "Phon Sawan",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "481002",
    "subDistrictNameTh": "นาหัวบ่อ",
    "subDistrictNameEn": "Na Hua Bo",
    "postcode": "48190",
    "districtCode": "4810",
    "districtNameTh": "โพนสวรรค์",
    "districtNameEn": "Phon Sawan",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "481003",
    "subDistrictNameTh": "นาขมิ้น",
    "subDistrictNameEn": "Na Khamin",
    "postcode": "48190",
    "districtCode": "4810",
    "districtNameTh": "โพนสวรรค์",
    "districtNameEn": "Phon Sawan",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "481004",
    "subDistrictNameTh": "โพนบก",
    "subDistrictNameEn": "Phon Bok",
    "postcode": "48190",
    "districtCode": "4810",
    "districtNameTh": "โพนสวรรค์",
    "districtNameEn": "Phon Sawan",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "481005",
    "subDistrictNameTh": "บ้านค้อ",
    "subDistrictNameEn": "Ban Kho",
    "postcode": "48190",
    "districtCode": "4810",
    "districtNameTh": "โพนสวรรค์",
    "districtNameEn": "Phon Sawan",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "481006",
    "subDistrictNameTh": "โพนจาน",
    "subDistrictNameEn": "Phon Chan",
    "postcode": "48190",
    "districtCode": "4810",
    "districtNameTh": "โพนสวรรค์",
    "districtNameEn": "Phon Sawan",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "481007",
    "subDistrictNameTh": "นาใน",
    "subDistrictNameEn": "Na Nai",
    "postcode": "48190",
    "districtCode": "4810",
    "districtNameTh": "โพนสวรรค์",
    "districtNameEn": "Phon Sawan",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "481101",
    "subDistrictNameTh": "นาทม",
    "subDistrictNameEn": "Na Thom",
    "postcode": "48140",
    "districtCode": "4811",
    "districtNameTh": "นาทม",
    "districtNameEn": "Na Thom",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "481102",
    "subDistrictNameTh": "หนองซน",
    "subDistrictNameEn": "Nong Son",
    "postcode": "48140",
    "districtCode": "4811",
    "districtNameTh": "นาทม",
    "districtNameEn": "Na Thom",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "481103",
    "subDistrictNameTh": "ดอนเตย",
    "subDistrictNameEn": "Don Toei",
    "postcode": "48140",
    "districtCode": "4811",
    "districtNameTh": "นาทม",
    "districtNameEn": "Na Thom",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "481201",
    "subDistrictNameTh": "วังยาง",
    "subDistrictNameEn": "Wang Yang",
    "postcode": "48130",
    "districtCode": "4812",
    "districtNameTh": "วังยาง",
    "districtNameEn": "Wang Yang",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "481202",
    "subDistrictNameTh": "โคกสี",
    "subDistrictNameEn": "Khok Si",
    "postcode": "48130",
    "districtCode": "4812",
    "districtNameTh": "วังยาง",
    "districtNameEn": "Wang Yang",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "481203",
    "subDistrictNameTh": "ยอดชาด",
    "subDistrictNameEn": "Yot Chat",
    "postcode": "48130",
    "districtCode": "4812",
    "districtNameTh": "วังยาง",
    "districtNameEn": "Wang Yang",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "481204",
    "subDistrictNameTh": "หนองโพธิ์",
    "subDistrictNameEn": "Nong Pho",
    "postcode": "48130",
    "districtCode": "4812",
    "districtNameTh": "วังยาง",
    "districtNameEn": "Wang Yang",
    "provinceCode": "48",
    "provinceNameTh": "นครพนม",
    "provinceNameEn": "Nakhon Phanom"
},
{
    "subDistrictCode": "490101",
    "subDistrictNameTh": "มุกดาหาร",
    "subDistrictNameEn": "Mukdahan",
    "postcode": "49000",
    "districtCode": "4901",
    "districtNameTh": "เมืองมุกดาหาร",
    "districtNameEn": "Mueang Mukdahan",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490102",
    "subDistrictNameTh": "ศรีบุญเรือง",
    "subDistrictNameEn": "Si Bun Rueang",
    "postcode": "49000",
    "districtCode": "4901",
    "districtNameTh": "เมืองมุกดาหาร",
    "districtNameEn": "Mueang Mukdahan",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490103",
    "subDistrictNameTh": "บ้านโคก",
    "subDistrictNameEn": "Ban Khok",
    "postcode": "49000",
    "districtCode": "4901",
    "districtNameTh": "เมืองมุกดาหาร",
    "districtNameEn": "Mueang Mukdahan",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490104",
    "subDistrictNameTh": "บางทรายใหญ่",
    "subDistrictNameEn": "Bang Sai Yai",
    "postcode": "49000",
    "districtCode": "4901",
    "districtNameTh": "เมืองมุกดาหาร",
    "districtNameEn": "Mueang Mukdahan",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490105",
    "subDistrictNameTh": "โพนทราย",
    "subDistrictNameEn": "Phon Sai",
    "postcode": "49000",
    "districtCode": "4901",
    "districtNameTh": "เมืองมุกดาหาร",
    "districtNameEn": "Mueang Mukdahan",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490106",
    "subDistrictNameTh": "ผึ่งแดด",
    "subDistrictNameEn": "Phueng Daet",
    "postcode": "49000",
    "districtCode": "4901",
    "districtNameTh": "เมืองมุกดาหาร",
    "districtNameEn": "Mueang Mukdahan",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490107",
    "subDistrictNameTh": "นาโสก",
    "subDistrictNameEn": "Na Sok",
    "postcode": "49000",
    "districtCode": "4901",
    "districtNameTh": "เมืองมุกดาหาร",
    "districtNameEn": "Mueang Mukdahan",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490108",
    "subDistrictNameTh": "นาสีนวน",
    "subDistrictNameEn": "Na Si Nuan",
    "postcode": "49000",
    "districtCode": "4901",
    "districtNameTh": "เมืองมุกดาหาร",
    "districtNameEn": "Mueang Mukdahan",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490109",
    "subDistrictNameTh": "คำป่าหลาย",
    "subDistrictNameEn": "Kham Pa Lai",
    "postcode": "49000",
    "districtCode": "4901",
    "districtNameTh": "เมืองมุกดาหาร",
    "districtNameEn": "Mueang Mukdahan",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490110",
    "subDistrictNameTh": "คำอาฮวน",
    "subDistrictNameEn": "Kham Ahuan",
    "postcode": "49000",
    "districtCode": "4901",
    "districtNameTh": "เมืองมุกดาหาร",
    "districtNameEn": "Mueang Mukdahan",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490111",
    "subDistrictNameTh": "ดงเย็น",
    "subDistrictNameEn": "Dong Yen",
    "postcode": "49000",
    "districtCode": "4901",
    "districtNameTh": "เมืองมุกดาหาร",
    "districtNameEn": "Mueang Mukdahan",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490112",
    "subDistrictNameTh": "ดงมอน",
    "subDistrictNameEn": "Dong Mon",
    "postcode": "49000",
    "districtCode": "4901",
    "districtNameTh": "เมืองมุกดาหาร",
    "districtNameEn": "Mueang Mukdahan",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490113",
    "subDistrictNameTh": "กุดแข้",
    "subDistrictNameEn": "Kut Khae",
    "postcode": "49000",
    "districtCode": "4901",
    "districtNameTh": "เมืองมุกดาหาร",
    "districtNameEn": "Mueang Mukdahan",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490201",
    "subDistrictNameTh": "นิคมคำสร้อย",
    "subDistrictNameEn": "Nikhom Kham Soi",
    "postcode": "49130",
    "districtCode": "4902",
    "districtNameTh": "นิคมคำสร้อย",
    "districtNameEn": "Nikhom Kham Soi",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490202",
    "subDistrictNameTh": "นากอก",
    "subDistrictNameEn": "Na Kok",
    "postcode": "49130",
    "districtCode": "4902",
    "districtNameTh": "นิคมคำสร้อย",
    "districtNameEn": "Nikhom Kham Soi",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490203",
    "subDistrictNameTh": "หนองแวง",
    "subDistrictNameEn": "Nong Waeng",
    "postcode": "49130",
    "districtCode": "4902",
    "districtNameTh": "นิคมคำสร้อย",
    "districtNameEn": "Nikhom Kham Soi",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490204",
    "subDistrictNameTh": "กกแดง",
    "subDistrictNameEn": "Kok Daeng",
    "postcode": "49130",
    "districtCode": "4902",
    "districtNameTh": "นิคมคำสร้อย",
    "districtNameEn": "Nikhom Kham Soi",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490205",
    "subDistrictNameTh": "นาอุดม",
    "subDistrictNameEn": "Na Udom",
    "postcode": "49130",
    "districtCode": "4902",
    "districtNameTh": "นิคมคำสร้อย",
    "districtNameEn": "Nikhom Kham Soi",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490206",
    "subDistrictNameTh": "โชคชัย",
    "subDistrictNameEn": "Chok Chai",
    "postcode": "49130",
    "districtCode": "4902",
    "districtNameTh": "นิคมคำสร้อย",
    "districtNameEn": "Nikhom Kham Soi",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490207",
    "subDistrictNameTh": "ร่มเกล้า",
    "subDistrictNameEn": "Rom Klao",
    "postcode": "49130",
    "districtCode": "4902",
    "districtNameTh": "นิคมคำสร้อย",
    "districtNameEn": "Nikhom Kham Soi",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490301",
    "subDistrictNameTh": "ดอนตาล",
    "subDistrictNameEn": "Don Tan",
    "postcode": "49120",
    "districtCode": "4903",
    "districtNameTh": "ดอนตาล",
    "districtNameEn": "Don Tan",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490302",
    "subDistrictNameTh": "โพธิ์ไทร",
    "subDistrictNameEn": "Pho Sai",
    "postcode": "49120",
    "districtCode": "4903",
    "districtNameTh": "ดอนตาล",
    "districtNameEn": "Don Tan",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490303",
    "subDistrictNameTh": "ป่าไร่",
    "subDistrictNameEn": "Pa Rai",
    "postcode": "49120",
    "districtCode": "4903",
    "districtNameTh": "ดอนตาล",
    "districtNameEn": "Don Tan",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490304",
    "subDistrictNameTh": "เหล่าหมี",
    "subDistrictNameEn": "Lao Mi",
    "postcode": "49120",
    "districtCode": "4903",
    "districtNameTh": "ดอนตาล",
    "districtNameEn": "Don Tan",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490305",
    "subDistrictNameTh": "บ้านบาก",
    "subDistrictNameEn": "Ban Bak",
    "postcode": "49120",
    "districtCode": "4903",
    "districtNameTh": "ดอนตาล",
    "districtNameEn": "Don Tan",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490306",
    "subDistrictNameTh": "นาสะเม็ง",
    "subDistrictNameEn": "Na Sameng",
    "postcode": "49120",
    "districtCode": "4903",
    "districtNameTh": "ดอนตาล",
    "districtNameEn": "Don Tan",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490307",
    "subDistrictNameTh": "บ้านแก้ง",
    "subDistrictNameEn": "Ban Kaeng",
    "postcode": "49120",
    "districtCode": "4903",
    "districtNameTh": "ดอนตาล",
    "districtNameEn": "Don Tan",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490401",
    "subDistrictNameTh": "ดงหลวง",
    "subDistrictNameEn": "Dong Luang",
    "postcode": "49140",
    "districtCode": "4904",
    "districtNameTh": "ดงหลวง",
    "districtNameEn": "Dong Luang",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490402",
    "subDistrictNameTh": "หนองบัว",
    "subDistrictNameEn": "Nong Bua",
    "postcode": "49140",
    "districtCode": "4904",
    "districtNameTh": "ดงหลวง",
    "districtNameEn": "Dong Luang",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490403",
    "subDistrictNameTh": "กกตูม",
    "subDistrictNameEn": "Kok Tum",
    "postcode": "49140",
    "districtCode": "4904",
    "districtNameTh": "ดงหลวง",
    "districtNameEn": "Dong Luang",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490404",
    "subDistrictNameTh": "หนองแคน",
    "subDistrictNameEn": "Nong Khaen",
    "postcode": "49140",
    "districtCode": "4904",
    "districtNameTh": "ดงหลวง",
    "districtNameEn": "Dong Luang",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490405",
    "subDistrictNameTh": "ชะโนดน้อย",
    "subDistrictNameEn": "Chanot Noi",
    "postcode": "49140",
    "districtCode": "4904",
    "districtNameTh": "ดงหลวง",
    "districtNameEn": "Dong Luang",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490406",
    "subDistrictNameTh": "พังแดง",
    "subDistrictNameEn": "Phang Daeng",
    "postcode": "49140",
    "districtCode": "4904",
    "districtNameTh": "ดงหลวง",
    "districtNameEn": "Dong Luang",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490503",
    "subDistrictNameTh": "บ้านซ่ง",
    "subDistrictNameEn": "Ban Song",
    "postcode": "49110",
    "districtCode": "4905",
    "districtNameTh": "คำชะอี",
    "districtNameEn": "Khamcha-i",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490504",
    "subDistrictNameTh": "คำชะอี",
    "subDistrictNameEn": "Kham Cha-i",
    "postcode": "49110",
    "districtCode": "4905",
    "districtNameTh": "คำชะอี",
    "districtNameEn": "Khamcha-i",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490505",
    "subDistrictNameTh": "หนองเอี่ยน",
    "subDistrictNameEn": "Nong Ian",
    "postcode": "49110",
    "districtCode": "4905",
    "districtNameTh": "คำชะอี",
    "districtNameEn": "Khamcha-i",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490506",
    "subDistrictNameTh": "บ้านค้อ",
    "subDistrictNameEn": "Ban Kho",
    "postcode": "49110",
    "districtCode": "4905",
    "districtNameTh": "คำชะอี",
    "districtNameEn": "Khamcha-i",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490507",
    "subDistrictNameTh": "บ้านเหล่า",
    "subDistrictNameEn": "Ban Lao",
    "postcode": "49110",
    "districtCode": "4905",
    "districtNameTh": "คำชะอี",
    "districtNameEn": "Khamcha-i",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490508",
    "subDistrictNameTh": "โพนงาม",
    "subDistrictNameEn": "Phon Ngam",
    "postcode": "49110",
    "districtCode": "4905",
    "districtNameTh": "คำชะอี",
    "districtNameEn": "Khamcha-i",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490511",
    "subDistrictNameTh": "เหล่าสร้างถ่อ",
    "subDistrictNameEn": "Lao Sang Tho",
    "postcode": "49110",
    "districtCode": "4905",
    "districtNameTh": "คำชะอี",
    "districtNameEn": "Khamcha-i",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490512",
    "subDistrictNameTh": "คำบก",
    "subDistrictNameEn": "Kham Bok",
    "postcode": "49110",
    "districtCode": "4905",
    "districtNameTh": "คำชะอี",
    "districtNameEn": "Khamcha-i",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490514",
    "subDistrictNameTh": "น้ำเที่ยง",
    "subDistrictNameEn": "Nam Thiang",
    "postcode": "49110",
    "districtCode": "4905",
    "districtNameTh": "คำชะอี",
    "districtNameEn": "Khamcha-i",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490601",
    "subDistrictNameTh": "หว้านใหญ่",
    "subDistrictNameEn": "Wan Yai",
    "postcode": "49150",
    "districtCode": "4906",
    "districtNameTh": "หว้านใหญ่",
    "districtNameEn": "Wan Yai",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490602",
    "subDistrictNameTh": "ป่งขาม",
    "subDistrictNameEn": "Pong Kham",
    "postcode": "49150",
    "districtCode": "4906",
    "districtNameTh": "หว้านใหญ่",
    "districtNameEn": "Wan Yai",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490603",
    "subDistrictNameTh": "บางทรายน้อย",
    "subDistrictNameEn": "Bang Sai Noi",
    "postcode": "49150",
    "districtCode": "4906",
    "districtNameTh": "หว้านใหญ่",
    "districtNameEn": "Wan Yai",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490604",
    "subDistrictNameTh": "ชะโนด",
    "subDistrictNameEn": "Chanot",
    "postcode": "49150",
    "districtCode": "4906",
    "districtNameTh": "หว้านใหญ่",
    "districtNameEn": "Wan Yai",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490605",
    "subDistrictNameTh": "ดงหมู",
    "subDistrictNameEn": "Dong Mu",
    "postcode": "49150",
    "districtCode": "4906",
    "districtNameTh": "หว้านใหญ่",
    "districtNameEn": "Wan Yai",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490701",
    "subDistrictNameTh": "หนองสูง",
    "subDistrictNameEn": "Nong Sung",
    "postcode": "49160",
    "districtCode": "4907",
    "districtNameTh": "หนองสูง",
    "districtNameEn": "Nong Sung",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490702",
    "subDistrictNameTh": "โนนยาง",
    "subDistrictNameEn": "Non Yang",
    "postcode": "49160",
    "districtCode": "4907",
    "districtNameTh": "หนองสูง",
    "districtNameEn": "Nong Sung",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490703",
    "subDistrictNameTh": "ภูวง",
    "subDistrictNameEn": "Phu Wong",
    "postcode": "49160",
    "districtCode": "4907",
    "districtNameTh": "หนองสูง",
    "districtNameEn": "Nong Sung",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490704",
    "subDistrictNameTh": "บ้านเป้า",
    "subDistrictNameEn": "Ban Pao",
    "postcode": "49160",
    "districtCode": "4907",
    "districtNameTh": "หนองสูง",
    "districtNameEn": "Nong Sung",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490705",
    "subDistrictNameTh": "หนองสูงใต้",
    "subDistrictNameEn": "Nong Sung Tai",
    "postcode": "49160",
    "districtCode": "4907",
    "districtNameTh": "หนองสูง",
    "districtNameEn": "Nong Sung",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "490706",
    "subDistrictNameTh": "หนองสูงเหนือ",
    "subDistrictNameEn": "Nong Sung Nuea",
    "postcode": "49160",
    "districtCode": "4907",
    "districtNameTh": "หนองสูง",
    "districtNameEn": "Nong Sung",
    "provinceCode": "49",
    "provinceNameTh": "มุกดาหาร",
    "provinceNameEn": "Mukdahan"
},
{
    "subDistrictCode": "500101",
    "subDistrictNameTh": "ศรีภูมิ",
    "subDistrictNameEn": "Si Phum",
    "postcode": "50200",
    "districtCode": "5001",
    "districtNameTh": "เมืองเชียงใหม่",
    "districtNameEn": "Mueang Chiang Mai",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500102",
    "subDistrictNameTh": "พระสิงห์",
    "subDistrictNameEn": "Phra Sing",
    "postcode": "50200",
    "districtCode": "5001",
    "districtNameTh": "เมืองเชียงใหม่",
    "districtNameEn": "Mueang Chiang Mai",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500103",
    "subDistrictNameTh": "หายยา",
    "subDistrictNameEn": "Hai Ya",
    "postcode": "50100",
    "districtCode": "5001",
    "districtNameTh": "เมืองเชียงใหม่",
    "districtNameEn": "Mueang Chiang Mai",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500104",
    "subDistrictNameTh": "ช้างม่อย",
    "subDistrictNameEn": "Chang Moi",
    "postcode": "50300",
    "districtCode": "5001",
    "districtNameTh": "เมืองเชียงใหม่",
    "districtNameEn": "Mueang Chiang Mai",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500105",
    "subDistrictNameTh": "ช้างคลาน",
    "subDistrictNameEn": "Chang Khlan",
    "postcode": "50100",
    "districtCode": "5001",
    "districtNameTh": "เมืองเชียงใหม่",
    "districtNameEn": "Mueang Chiang Mai",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500106",
    "subDistrictNameTh": "วัดเกต",
    "subDistrictNameEn": "Wat Ket",
    "postcode": "50000",
    "districtCode": "5001",
    "districtNameTh": "เมืองเชียงใหม่",
    "districtNameEn": "Mueang Chiang Mai",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500107",
    "subDistrictNameTh": "ช้างเผือก",
    "subDistrictNameEn": "Chang Phueak",
    "postcode": "50300",
    "districtCode": "5001",
    "districtNameTh": "เมืองเชียงใหม่",
    "districtNameEn": "Mueang Chiang Mai",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500108",
    "subDistrictNameTh": "สุเทพ",
    "subDistrictNameEn": "Suthep",
    "postcode": "50200",
    "districtCode": "5001",
    "districtNameTh": "เมืองเชียงใหม่",
    "districtNameEn": "Mueang Chiang Mai",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500109",
    "subDistrictNameTh": "แม่เหียะ",
    "subDistrictNameEn": "Mae Hia",
    "postcode": "50100",
    "districtCode": "5001",
    "districtNameTh": "เมืองเชียงใหม่",
    "districtNameEn": "Mueang Chiang Mai",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500110",
    "subDistrictNameTh": "ป่าแดด",
    "subDistrictNameEn": "Pa Daet",
    "postcode": "50100",
    "districtCode": "5001",
    "districtNameTh": "เมืองเชียงใหม่",
    "districtNameEn": "Mueang Chiang Mai",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500111",
    "subDistrictNameTh": "หนองหอย",
    "subDistrictNameEn": "Nong Hoi",
    "postcode": "50000",
    "districtCode": "5001",
    "districtNameTh": "เมืองเชียงใหม่",
    "districtNameEn": "Mueang Chiang Mai",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500112",
    "subDistrictNameTh": "ท่าศาลา",
    "subDistrictNameEn": "Tha Sala",
    "postcode": "50000",
    "districtCode": "5001",
    "districtNameTh": "เมืองเชียงใหม่",
    "districtNameEn": "Mueang Chiang Mai",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500113",
    "subDistrictNameTh": "หนองป่าครั่ง",
    "subDistrictNameEn": "Nong Pa Khrang",
    "postcode": "50000",
    "districtCode": "5001",
    "districtNameTh": "เมืองเชียงใหม่",
    "districtNameEn": "Mueang Chiang Mai",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500114",
    "subDistrictNameTh": "ฟ้าฮ่าม",
    "subDistrictNameEn": "Fa Ham",
    "postcode": "50000",
    "districtCode": "5001",
    "districtNameTh": "เมืองเชียงใหม่",
    "districtNameEn": "Mueang Chiang Mai",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500115",
    "subDistrictNameTh": "ป่าตัน",
    "subDistrictNameEn": "Pa Tan",
    "postcode": "50300",
    "districtCode": "5001",
    "districtNameTh": "เมืองเชียงใหม่",
    "districtNameEn": "Mueang Chiang Mai",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500116",
    "subDistrictNameTh": "สันผีเสื้อ",
    "subDistrictNameEn": "San Phi Suea",
    "postcode": "50300",
    "districtCode": "5001",
    "districtNameTh": "เมืองเชียงใหม่",
    "districtNameEn": "Mueang Chiang Mai",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500203",
    "subDistrictNameTh": "บ้านหลวง",
    "subDistrictNameEn": "Ban Luang",
    "postcode": "50160",
    "districtCode": "5002",
    "districtNameTh": "จอมทอง",
    "districtNameEn": "Chom Thong",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500204",
    "subDistrictNameTh": "ข่วงเปา",
    "subDistrictNameEn": "Khuang Pao",
    "postcode": "50160",
    "districtCode": "5002",
    "districtNameTh": "จอมทอง",
    "districtNameEn": "Chom Thong",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500205",
    "subDistrictNameTh": "สบเตี๊ยะ",
    "subDistrictNameEn": "Sop Tia",
    "postcode": "50160",
    "districtCode": "5002",
    "districtNameTh": "จอมทอง",
    "districtNameEn": "Chom Thong",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500206",
    "subDistrictNameTh": "บ้านแปะ",
    "subDistrictNameEn": "Ban Pae",
    "postcode": "50240",
    "districtCode": "5002",
    "districtNameTh": "จอมทอง",
    "districtNameEn": "Chom Thong",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500207",
    "subDistrictNameTh": "ดอยแก้ว",
    "subDistrictNameEn": "Doi Kaeo",
    "postcode": "50160",
    "districtCode": "5002",
    "districtNameTh": "จอมทอง",
    "districtNameEn": "Chom Thong",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500209",
    "subDistrictNameTh": "แม่สอย",
    "subDistrictNameEn": "Mae Soi",
    "postcode": "50240",
    "districtCode": "5002",
    "districtNameTh": "จอมทอง",
    "districtNameEn": "Chom Thong",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500301",
    "subDistrictNameTh": "ช่างเคิ่ง",
    "subDistrictNameEn": "Chang Khoeng",
    "postcode": "50270",
    "districtCode": "5003",
    "districtNameTh": "แม่แจ่ม",
    "districtNameEn": "Mae Chaem",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500302",
    "subDistrictNameTh": "ท่าผา",
    "subDistrictNameEn": "The Pha",
    "postcode": "50270",
    "districtCode": "5003",
    "districtNameTh": "แม่แจ่ม",
    "districtNameEn": "Mae Chaem",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500303",
    "subDistrictNameTh": "บ้านทับ",
    "subDistrictNameEn": "Ban Thap",
    "postcode": "50270",
    "districtCode": "5003",
    "districtNameTh": "แม่แจ่ม",
    "districtNameEn": "Mae Chaem",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500304",
    "subDistrictNameTh": "แม่ศึก",
    "subDistrictNameEn": "Mae Suek",
    "postcode": "50270",
    "districtCode": "5003",
    "districtNameTh": "แม่แจ่ม",
    "districtNameEn": "Mae Chaem",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500305",
    "subDistrictNameTh": "แม่นาจร",
    "subDistrictNameEn": "Mae Na Chon",
    "postcode": "50270",
    "districtCode": "5003",
    "districtNameTh": "แม่แจ่ม",
    "districtNameEn": "Mae Chaem",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500307",
    "subDistrictNameTh": "ปางหินฝน",
    "subDistrictNameEn": "Pang Hin Fon",
    "postcode": "50270",
    "districtCode": "5003",
    "districtNameTh": "แม่แจ่ม",
    "districtNameEn": "Mae Chaem",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500308",
    "subDistrictNameTh": "กองแขก",
    "subDistrictNameEn": "Kong Khaek",
    "postcode": "50270",
    "districtCode": "5003",
    "districtNameTh": "แม่แจ่ม",
    "districtNameEn": "Mae Chaem",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500401",
    "subDistrictNameTh": "เชียงดาว",
    "subDistrictNameEn": "Chiang Dao",
    "postcode": "50170",
    "districtCode": "5004",
    "districtNameTh": "เชียงดาว",
    "districtNameEn": "Chiang Dao",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500402",
    "subDistrictNameTh": "เมืองนะ",
    "subDistrictNameEn": "Mueang Na",
    "postcode": "50170",
    "districtCode": "5004",
    "districtNameTh": "เชียงดาว",
    "districtNameEn": "Chiang Dao",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500403",
    "subDistrictNameTh": "เมืองงาย",
    "subDistrictNameEn": "Mueang Ngai",
    "postcode": "50170",
    "districtCode": "5004",
    "districtNameTh": "เชียงดาว",
    "districtNameEn": "Chiang Dao",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500404",
    "subDistrictNameTh": "แม่นะ",
    "subDistrictNameEn": "Mae Na",
    "postcode": "50170",
    "districtCode": "5004",
    "districtNameTh": "เชียงดาว",
    "districtNameEn": "Chiang Dao",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500405",
    "subDistrictNameTh": "เมืองคอง",
    "subDistrictNameEn": "Mueang Khong",
    "postcode": "50170",
    "districtCode": "5004",
    "districtNameTh": "เชียงดาว",
    "districtNameEn": "Chiang Dao",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500406",
    "subDistrictNameTh": "ปิงโค้ง",
    "subDistrictNameEn": "Ping Khong",
    "postcode": "50170",
    "districtCode": "5004",
    "districtNameTh": "เชียงดาว",
    "districtNameEn": "Chiang Dao",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500407",
    "subDistrictNameTh": "ทุ่งข้าวพวง",
    "subDistrictNameEn": "Thung Khao Phuang",
    "postcode": "50170",
    "districtCode": "5004",
    "districtNameTh": "เชียงดาว",
    "districtNameEn": "Chiang Dao",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500501",
    "subDistrictNameTh": "เชิงดอย",
    "subDistrictNameEn": "Choeng Doi",
    "postcode": "50220",
    "districtCode": "5005",
    "districtNameTh": "ดอยสะเก็ด",
    "districtNameEn": "Doi Saket",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500502",
    "subDistrictNameTh": "สันปูเลย",
    "subDistrictNameEn": "San Pu Loei",
    "postcode": "50220",
    "districtCode": "5005",
    "districtNameTh": "ดอยสะเก็ด",
    "districtNameEn": "Doi Saket",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500503",
    "subDistrictNameTh": "ลวงเหนือ",
    "subDistrictNameEn": "Luang Nuea",
    "postcode": "50220",
    "districtCode": "5005",
    "districtNameTh": "ดอยสะเก็ด",
    "districtNameEn": "Doi Saket",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500504",
    "subDistrictNameTh": "ป่าป้อง",
    "subDistrictNameEn": "Pa Pong",
    "postcode": "50220",
    "districtCode": "5005",
    "districtNameTh": "ดอยสะเก็ด",
    "districtNameEn": "Doi Saket",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500505",
    "subDistrictNameTh": "สง่าบ้าน",
    "subDistrictNameEn": "Sa-nga Ban",
    "postcode": "50220",
    "districtCode": "5005",
    "districtNameTh": "ดอยสะเก็ด",
    "districtNameEn": "Doi Saket",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500506",
    "subDistrictNameTh": "ป่าลาน",
    "subDistrictNameEn": "Pa Lan",
    "postcode": "50220",
    "districtCode": "5005",
    "districtNameTh": "ดอยสะเก็ด",
    "districtNameEn": "Doi Saket",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500507",
    "subDistrictNameTh": "ตลาดขวัญ",
    "subDistrictNameEn": "Talat Khwan",
    "postcode": "50220",
    "districtCode": "5005",
    "districtNameTh": "ดอยสะเก็ด",
    "districtNameEn": "Doi Saket",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500508",
    "subDistrictNameTh": "สำราญราษฎร์",
    "subDistrictNameEn": "Samran Rat",
    "postcode": "50220",
    "districtCode": "5005",
    "districtNameTh": "ดอยสะเก็ด",
    "districtNameEn": "Doi Saket",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500509",
    "subDistrictNameTh": "แม่คือ",
    "subDistrictNameEn": "Mae Khue",
    "postcode": "50220",
    "districtCode": "5005",
    "districtNameTh": "ดอยสะเก็ด",
    "districtNameEn": "Doi Saket",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500510",
    "subDistrictNameTh": "ตลาดใหญ่",
    "subDistrictNameEn": "Talat Yai",
    "postcode": "50220",
    "districtCode": "5005",
    "districtNameTh": "ดอยสะเก็ด",
    "districtNameEn": "Doi Saket",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500511",
    "subDistrictNameTh": "แม่ฮ้อยเงิน",
    "subDistrictNameEn": "Mae Hoi Ngoen",
    "postcode": "50220",
    "districtCode": "5005",
    "districtNameTh": "ดอยสะเก็ด",
    "districtNameEn": "Doi Saket",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500512",
    "subDistrictNameTh": "แม่โป่ง",
    "subDistrictNameEn": "Mae Pong",
    "postcode": "50220",
    "districtCode": "5005",
    "districtNameTh": "ดอยสะเก็ด",
    "districtNameEn": "Doi Saket",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500513",
    "subDistrictNameTh": "ป่าเมี่ยง",
    "subDistrictNameEn": "Pa Miang",
    "postcode": "50220",
    "districtCode": "5005",
    "districtNameTh": "ดอยสะเก็ด",
    "districtNameEn": "Doi Saket",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500514",
    "subDistrictNameTh": "เทพเสด็จ",
    "subDistrictNameEn": "Thep Sadet",
    "postcode": "50220",
    "districtCode": "5005",
    "districtNameTh": "ดอยสะเก็ด",
    "districtNameEn": "Doi Saket",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500601",
    "subDistrictNameTh": "สันมหาพน",
    "subDistrictNameEn": "San Maha Phon",
    "postcode": "50150",
    "districtCode": "5006",
    "districtNameTh": "แม่แตง",
    "districtNameEn": "Mae Taeng",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500602",
    "subDistrictNameTh": "แม่แตง",
    "subDistrictNameEn": "Mae Taeng",
    "postcode": "50150",
    "districtCode": "5006",
    "districtNameTh": "แม่แตง",
    "districtNameEn": "Mae Taeng",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500603",
    "subDistrictNameTh": "ขี้เหล็ก",
    "subDistrictNameEn": "Khilek",
    "postcode": "50150",
    "districtCode": "5006",
    "districtNameTh": "แม่แตง",
    "districtNameEn": "Mae Taeng",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500604",
    "subDistrictNameTh": "ช่อแล",
    "subDistrictNameEn": "Cho Lae",
    "postcode": "50150",
    "districtCode": "5006",
    "districtNameTh": "แม่แตง",
    "districtNameEn": "Mae Taeng",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500605",
    "subDistrictNameTh": "แม่หอพระ",
    "subDistrictNameEn": "Mae Ho Phra",
    "postcode": "50150",
    "districtCode": "5006",
    "districtNameTh": "แม่แตง",
    "districtNameEn": "Mae Taeng",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500606",
    "subDistrictNameTh": "สบเปิง",
    "subDistrictNameEn": "Sop Poeng",
    "postcode": "50150",
    "districtCode": "5006",
    "districtNameTh": "แม่แตง",
    "districtNameEn": "Mae Taeng",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500607",
    "subDistrictNameTh": "บ้านเป้า",
    "subDistrictNameEn": "Ban Pao",
    "postcode": "50150",
    "districtCode": "5006",
    "districtNameTh": "แม่แตง",
    "districtNameEn": "Mae Taeng",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500608",
    "subDistrictNameTh": "สันป่ายาง",
    "subDistrictNameEn": "San Pa Yang",
    "postcode": "50330",
    "districtCode": "5006",
    "districtNameTh": "แม่แตง",
    "districtNameEn": "Mae Taeng",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500609",
    "subDistrictNameTh": "ป่าแป๋",
    "subDistrictNameEn": "Pa Pae",
    "postcode": "50150",
    "districtCode": "5006",
    "districtNameTh": "แม่แตง",
    "districtNameEn": "Mae Taeng",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500610",
    "subDistrictNameTh": "เมืองก๋าย",
    "subDistrictNameEn": "Mueang Kai",
    "postcode": "50150",
    "districtCode": "5006",
    "districtNameTh": "แม่แตง",
    "districtNameEn": "Mae Taeng",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500611",
    "subDistrictNameTh": "บ้านช้าง",
    "subDistrictNameEn": "Ban Chang",
    "postcode": "50150",
    "districtCode": "5006",
    "districtNameTh": "แม่แตง",
    "districtNameEn": "Mae Taeng",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500612",
    "subDistrictNameTh": "กื้ดช้าง",
    "subDistrictNameEn": "Kuet Chang",
    "postcode": "50150",
    "districtCode": "5006",
    "districtNameTh": "แม่แตง",
    "districtNameEn": "Mae Taeng",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500613",
    "subDistrictNameTh": "อินทขิล",
    "subDistrictNameEn": "Inthakhin",
    "postcode": "50150",
    "districtCode": "5006",
    "districtNameTh": "แม่แตง",
    "districtNameEn": "Mae Taeng",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500701",
    "subDistrictNameTh": "ริมใต้",
    "subDistrictNameEn": "Rim Tai",
    "postcode": "50180",
    "districtCode": "5007",
    "districtNameTh": "แม่ริม",
    "districtNameEn": "Mae Rim",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500702",
    "subDistrictNameTh": "ริมเหนือ",
    "subDistrictNameEn": "Rim Nuea",
    "postcode": "50180",
    "districtCode": "5007",
    "districtNameTh": "แม่ริม",
    "districtNameEn": "Mae Rim",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500703",
    "subDistrictNameTh": "สันโป่ง",
    "subDistrictNameEn": "San Pong",
    "postcode": "50180",
    "districtCode": "5007",
    "districtNameTh": "แม่ริม",
    "districtNameEn": "Mae Rim",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500704",
    "subDistrictNameTh": "ขี้เหล็ก",
    "subDistrictNameEn": "Khilek",
    "postcode": "50180",
    "districtCode": "5007",
    "districtNameTh": "แม่ริม",
    "districtNameEn": "Mae Rim",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500705",
    "subDistrictNameTh": "สะลวง",
    "subDistrictNameEn": "Saluang",
    "postcode": "50330",
    "districtCode": "5007",
    "districtNameTh": "แม่ริม",
    "districtNameEn": "Mae Rim",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500706",
    "subDistrictNameTh": "ห้วยทราย",
    "subDistrictNameEn": "Huai Sai",
    "postcode": "50180",
    "districtCode": "5007",
    "districtNameTh": "แม่ริม",
    "districtNameEn": "Mae Rim",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500707",
    "subDistrictNameTh": "แม่แรม",
    "subDistrictNameEn": "Mae Raem",
    "postcode": "50180",
    "districtCode": "5007",
    "districtNameTh": "แม่ริม",
    "districtNameEn": "Mae Rim",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500708",
    "subDistrictNameTh": "โป่งแยง",
    "subDistrictNameEn": "Pong Yaeng",
    "postcode": "50180",
    "districtCode": "5007",
    "districtNameTh": "แม่ริม",
    "districtNameEn": "Mae Rim",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500709",
    "subDistrictNameTh": "แม่สา",
    "subDistrictNameEn": "Mae Sa",
    "postcode": "50180",
    "districtCode": "5007",
    "districtNameTh": "แม่ริม",
    "districtNameEn": "Mae Rim",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500710",
    "subDistrictNameTh": "ดอนแก้ว",
    "subDistrictNameEn": "Don Kaeo",
    "postcode": "50180",
    "districtCode": "5007",
    "districtNameTh": "แม่ริม",
    "districtNameEn": "Mae Rim",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500711",
    "subDistrictNameTh": "เหมืองแก้ว",
    "subDistrictNameEn": "Mueang Kaeo",
    "postcode": "50180",
    "districtCode": "5007",
    "districtNameTh": "แม่ริม",
    "districtNameEn": "Mae Rim",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500801",
    "subDistrictNameTh": "สะเมิงใต้",
    "subDistrictNameEn": "Samoeng Tai",
    "postcode": "50250",
    "districtCode": "5008",
    "districtNameTh": "สะเมิง",
    "districtNameEn": "Samoeng",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500802",
    "subDistrictNameTh": "สะเมิงเหนือ",
    "subDistrictNameEn": "Samoeng Nuea",
    "postcode": "50250",
    "districtCode": "5008",
    "districtNameTh": "สะเมิง",
    "districtNameEn": "Samoeng",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500803",
    "subDistrictNameTh": "แม่สาบ",
    "subDistrictNameEn": "Mae Sap",
    "postcode": "50250",
    "districtCode": "5008",
    "districtNameTh": "สะเมิง",
    "districtNameEn": "Samoeng",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500804",
    "subDistrictNameTh": "บ่อแก้ว",
    "subDistrictNameEn": "Bo Kaeo",
    "postcode": "50250",
    "districtCode": "5008",
    "districtNameTh": "สะเมิง",
    "districtNameEn": "Samoeng",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500805",
    "subDistrictNameTh": "ยั้งเมิน",
    "subDistrictNameEn": "Yang Moen",
    "postcode": "50250",
    "districtCode": "5008",
    "districtNameTh": "สะเมิง",
    "districtNameEn": "Samoeng",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500901",
    "subDistrictNameTh": "เวียง",
    "subDistrictNameEn": "Wiang",
    "postcode": "50110",
    "districtCode": "5009",
    "districtNameTh": "ฝาง",
    "districtNameEn": "Fang",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500903",
    "subDistrictNameTh": "ม่อนปิ่น",
    "subDistrictNameEn": "Mon Pin",
    "postcode": "50110",
    "districtCode": "5009",
    "districtNameTh": "ฝาง",
    "districtNameEn": "Fang",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500904",
    "subDistrictNameTh": "แม่งอน",
    "subDistrictNameEn": "Mae Ngon",
    "postcode": "50320",
    "districtCode": "5009",
    "districtNameTh": "ฝาง",
    "districtNameEn": "Fang",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500905",
    "subDistrictNameTh": "แม่สูน",
    "subDistrictNameEn": "Mae Sun",
    "postcode": "50110",
    "districtCode": "5009",
    "districtNameTh": "ฝาง",
    "districtNameEn": "Fang",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500906",
    "subDistrictNameTh": "สันทราย",
    "subDistrictNameEn": "San Sai",
    "postcode": "50110",
    "districtCode": "5009",
    "districtNameTh": "ฝาง",
    "districtNameEn": "Fang",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500910",
    "subDistrictNameTh": "แม่คะ",
    "subDistrictNameEn": "Mae Kha",
    "postcode": "50110",
    "districtCode": "5009",
    "districtNameTh": "ฝาง",
    "districtNameEn": "Fang",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500911",
    "subDistrictNameTh": "แม่ข่า",
    "subDistrictNameEn": "Mae Kha",
    "postcode": "50320",
    "districtCode": "5009",
    "districtNameTh": "ฝาง",
    "districtNameEn": "Fang",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "500912",
    "subDistrictNameTh": "โป่งน้ำร้อน",
    "subDistrictNameEn": "Pong Nam Ron",
    "postcode": "50110",
    "districtCode": "5009",
    "districtNameTh": "ฝาง",
    "districtNameEn": "Fang",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501001",
    "subDistrictNameTh": "แม่อาย",
    "subDistrictNameEn": "Mae Ai",
    "postcode": "50280",
    "districtCode": "5010",
    "districtNameTh": "แม่อาย",
    "districtNameEn": "Mae Ai",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501002",
    "subDistrictNameTh": "แม่สาว",
    "subDistrictNameEn": "Mae Sao",
    "postcode": "50280",
    "districtCode": "5010",
    "districtNameTh": "แม่อาย",
    "districtNameEn": "Mae Ai",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501003",
    "subDistrictNameTh": "สันต้นหมื้อ",
    "subDistrictNameEn": "San Ton Mue",
    "postcode": "50280",
    "districtCode": "5010",
    "districtNameTh": "แม่อาย",
    "districtNameEn": "Mae Ai",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501004",
    "subDistrictNameTh": "แม่นาวาง",
    "subDistrictNameEn": "Mae Na Wang",
    "postcode": "50280",
    "districtCode": "5010",
    "districtNameTh": "แม่อาย",
    "districtNameEn": "Mae Ai",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501005",
    "subDistrictNameTh": "ท่าตอน",
    "subDistrictNameEn": "Tha Ton",
    "postcode": "50280",
    "districtCode": "5010",
    "districtNameTh": "แม่อาย",
    "districtNameEn": "Mae Ai",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501006",
    "subDistrictNameTh": "บ้านหลวง",
    "subDistrictNameEn": "Ban Luang",
    "postcode": "50280",
    "districtCode": "5010",
    "districtNameTh": "แม่อาย",
    "districtNameEn": "Mae Ai",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501007",
    "subDistrictNameTh": "มะลิกา",
    "subDistrictNameEn": "Malika",
    "postcode": "50280",
    "districtCode": "5010",
    "districtNameTh": "แม่อาย",
    "districtNameEn": "Mae Ai",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501101",
    "subDistrictNameTh": "เวียง",
    "subDistrictNameEn": "Wiang",
    "postcode": "50190",
    "districtCode": "5011",
    "districtNameTh": "พร้าว",
    "districtNameEn": "Phrao",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501102",
    "subDistrictNameTh": "ทุ่งหลวง",
    "subDistrictNameEn": "Thung Luang",
    "postcode": "50190",
    "districtCode": "5011",
    "districtNameTh": "พร้าว",
    "districtNameEn": "Phrao",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501103",
    "subDistrictNameTh": "ป่าตุ้ม",
    "subDistrictNameEn": "Pa Tum",
    "postcode": "50190",
    "districtCode": "5011",
    "districtNameTh": "พร้าว",
    "districtNameEn": "Phrao",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501104",
    "subDistrictNameTh": "ป่าไหน่",
    "subDistrictNameEn": "Pa Nai",
    "postcode": "50190",
    "districtCode": "5011",
    "districtNameTh": "พร้าว",
    "districtNameEn": "Phrao",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501105",
    "subDistrictNameTh": "สันทราย",
    "subDistrictNameEn": "San Sai",
    "postcode": "50190",
    "districtCode": "5011",
    "districtNameTh": "พร้าว",
    "districtNameEn": "Phrao",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501106",
    "subDistrictNameTh": "บ้านโป่ง",
    "subDistrictNameEn": "Ban Pong",
    "postcode": "50190",
    "districtCode": "5011",
    "districtNameTh": "พร้าว",
    "districtNameEn": "Phrao",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501107",
    "subDistrictNameTh": "น้ำแพร่",
    "subDistrictNameEn": "Nam Phrae",
    "postcode": "50190",
    "districtCode": "5011",
    "districtNameTh": "พร้าว",
    "districtNameEn": "Phrao",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501108",
    "subDistrictNameTh": "เขื่อนผาก",
    "subDistrictNameEn": "Khuean Phak",
    "postcode": "50190",
    "districtCode": "5011",
    "districtNameTh": "พร้าว",
    "districtNameEn": "Phrao",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501109",
    "subDistrictNameTh": "แม่แวน",
    "subDistrictNameEn": "Mae Waen",
    "postcode": "50190",
    "districtCode": "5011",
    "districtNameTh": "พร้าว",
    "districtNameEn": "Phrao",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501110",
    "subDistrictNameTh": "แม่ปั๋ง",
    "subDistrictNameEn": "Mae Pang",
    "postcode": "50190",
    "districtCode": "5011",
    "districtNameTh": "พร้าว",
    "districtNameEn": "Phrao",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501111",
    "subDistrictNameTh": "โหล่งขอด",
    "subDistrictNameEn": "Long Khot",
    "postcode": "50190",
    "districtCode": "5011",
    "districtNameTh": "พร้าว",
    "districtNameEn": "Phrao",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501201",
    "subDistrictNameTh": "ยุหว่า",
    "subDistrictNameEn": "Yu Wa",
    "postcode": "50120",
    "districtCode": "5012",
    "districtNameTh": "สันป่าตอง",
    "districtNameEn": "San Pa Tong",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501202",
    "subDistrictNameTh": "สันกลาง",
    "subDistrictNameEn": "San Klang",
    "postcode": "50120",
    "districtCode": "5012",
    "districtNameTh": "สันป่าตอง",
    "districtNameEn": "San Pa Tong",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501203",
    "subDistrictNameTh": "ท่าวังพร้าว",
    "subDistrictNameEn": "Tha Wang Phrao",
    "postcode": "50120",
    "districtCode": "5012",
    "districtNameTh": "สันป่าตอง",
    "districtNameEn": "San Pa Tong",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501204",
    "subDistrictNameTh": "มะขามหลวง",
    "subDistrictNameEn": "Makham Luang",
    "postcode": "50120",
    "districtCode": "5012",
    "districtNameTh": "สันป่าตอง",
    "districtNameEn": "San Pa Tong",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501205",
    "subDistrictNameTh": "แม่ก๊า",
    "subDistrictNameEn": "Mae Ka",
    "postcode": "50120",
    "districtCode": "5012",
    "districtNameTh": "สันป่าตอง",
    "districtNameEn": "San Pa Tong",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501206",
    "subDistrictNameTh": "บ้านแม",
    "subDistrictNameEn": "Ban Mae",
    "postcode": "50120",
    "districtCode": "5012",
    "districtNameTh": "สันป่าตอง",
    "districtNameEn": "San Pa Tong",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501207",
    "subDistrictNameTh": "บ้านกลาง",
    "subDistrictNameEn": "Ban Klang",
    "postcode": "50120",
    "districtCode": "5012",
    "districtNameTh": "สันป่าตอง",
    "districtNameEn": "San Pa Tong",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501208",
    "subDistrictNameTh": "ทุ่งสะโตก",
    "subDistrictNameEn": "Thung Satok",
    "postcode": "50120",
    "districtCode": "5012",
    "districtNameTh": "สันป่าตอง",
    "districtNameEn": "San Pa Tong",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501210",
    "subDistrictNameTh": "ทุ่งต้อม",
    "subDistrictNameEn": "Thung Tom",
    "postcode": "50120",
    "districtCode": "5012",
    "districtNameTh": "สันป่าตอง",
    "districtNameEn": "San Pa Tong",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501214",
    "subDistrictNameTh": "น้ำบ่อหลวง",
    "subDistrictNameEn": "Nam Bo Luang",
    "postcode": "50120",
    "districtCode": "5012",
    "districtNameTh": "สันป่าตอง",
    "districtNameEn": "San Pa Tong",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501215",
    "subDistrictNameTh": "มะขุนหวาน",
    "subDistrictNameEn": "Makhun Wan",
    "postcode": "50120",
    "districtCode": "5012",
    "districtNameTh": "สันป่าตอง",
    "districtNameEn": "San Pa Tong",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501301",
    "subDistrictNameTh": "สันกำแพง",
    "subDistrictNameEn": "San Kamphaeng",
    "postcode": "50130",
    "districtCode": "5013",
    "districtNameTh": "สันกำแพง",
    "districtNameEn": "San Kamphaeng",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501302",
    "subDistrictNameTh": "ทรายมูล",
    "subDistrictNameEn": "Sai Mun",
    "postcode": "50130",
    "districtCode": "5013",
    "districtNameTh": "สันกำแพง",
    "districtNameEn": "San Kamphaeng",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501303",
    "subDistrictNameTh": "ร้องวัวแดง",
    "subDistrictNameEn": "Rong Wua Daeng",
    "postcode": "50130",
    "districtCode": "5013",
    "districtNameTh": "สันกำแพง",
    "districtNameEn": "San Kamphaeng",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501304",
    "subDistrictNameTh": "บวกค้าง",
    "subDistrictNameEn": "Buak Khang",
    "postcode": "50130",
    "districtCode": "5013",
    "districtNameTh": "สันกำแพง",
    "districtNameEn": "San Kamphaeng",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501305",
    "subDistrictNameTh": "แช่ช้าง",
    "subDistrictNameEn": "Chae Chang",
    "postcode": "50130",
    "districtCode": "5013",
    "districtNameTh": "สันกำแพง",
    "districtNameEn": "San Kamphaeng",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501306",
    "subDistrictNameTh": "ออนใต้",
    "subDistrictNameEn": "On Tai",
    "postcode": "50130",
    "districtCode": "5013",
    "districtNameTh": "สันกำแพง",
    "districtNameEn": "San Kamphaeng",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501310",
    "subDistrictNameTh": "แม่ปูคา",
    "subDistrictNameEn": "Mae Pu Kha",
    "postcode": "50130",
    "districtCode": "5013",
    "districtNameTh": "สันกำแพง",
    "districtNameEn": "San Kamphaeng",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501311",
    "subDistrictNameTh": "ห้วยทราย",
    "subDistrictNameEn": "Huai Sai",
    "postcode": "50130",
    "districtCode": "5013",
    "districtNameTh": "สันกำแพง",
    "districtNameEn": "San Kamphaeng",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501312",
    "subDistrictNameTh": "ต้นเปา",
    "subDistrictNameEn": "Ton Pao",
    "postcode": "50130",
    "districtCode": "5013",
    "districtNameTh": "สันกำแพง",
    "districtNameEn": "San Kamphaeng",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501313",
    "subDistrictNameTh": "สันกลาง",
    "subDistrictNameEn": "San Klang",
    "postcode": "50130",
    "districtCode": "5013",
    "districtNameTh": "สันกำแพง",
    "districtNameEn": "San Kamphaeng",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501401",
    "subDistrictNameTh": "สันทรายหลวง",
    "subDistrictNameEn": "San Sai Luang",
    "postcode": "50210",
    "districtCode": "5014",
    "districtNameTh": "สันทราย",
    "districtNameEn": "San Sai",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501402",
    "subDistrictNameTh": "สันทรายน้อย",
    "subDistrictNameEn": "San Sai Noi",
    "postcode": "50210",
    "districtCode": "5014",
    "districtNameTh": "สันทราย",
    "districtNameEn": "San Sai",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501403",
    "subDistrictNameTh": "สันพระเนตร",
    "subDistrictNameEn": "San Phra Net",
    "postcode": "50210",
    "districtCode": "5014",
    "districtNameTh": "สันทราย",
    "districtNameEn": "San Sai",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501404",
    "subDistrictNameTh": "สันนาเม็ง",
    "subDistrictNameEn": "San Na Meng",
    "postcode": "50210",
    "districtCode": "5014",
    "districtNameTh": "สันทราย",
    "districtNameEn": "San Sai",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501405",
    "subDistrictNameTh": "สันป่าเปา",
    "subDistrictNameEn": "San Pa Pao",
    "postcode": "50210",
    "districtCode": "5014",
    "districtNameTh": "สันทราย",
    "districtNameEn": "San Sai",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501406",
    "subDistrictNameTh": "หนองแหย่ง",
    "subDistrictNameEn": "Nong Yaeng",
    "postcode": "50210",
    "districtCode": "5014",
    "districtNameTh": "สันทราย",
    "districtNameEn": "San Sai",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501407",
    "subDistrictNameTh": "หนองจ๊อม",
    "subDistrictNameEn": "Nong Chom",
    "postcode": "50210",
    "districtCode": "5014",
    "districtNameTh": "สันทราย",
    "districtNameEn": "San Sai",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501408",
    "subDistrictNameTh": "หนองหาร",
    "subDistrictNameEn": "Nong Han",
    "postcode": "50290",
    "districtCode": "5014",
    "districtNameTh": "สันทราย",
    "districtNameEn": "San Sai",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501409",
    "subDistrictNameTh": "แม่แฝก",
    "subDistrictNameEn": "Mae Faek",
    "postcode": "50290",
    "districtCode": "5014",
    "districtNameTh": "สันทราย",
    "districtNameEn": "San Sai",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501410",
    "subDistrictNameTh": "แม่แฝกใหม่",
    "subDistrictNameEn": "Mae Faek Mai",
    "postcode": "50290",
    "districtCode": "5014",
    "districtNameTh": "สันทราย",
    "districtNameEn": "San Sai",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501411",
    "subDistrictNameTh": "เมืองเล็น",
    "subDistrictNameEn": "Mueang Len",
    "postcode": "50210",
    "districtCode": "5014",
    "districtNameTh": "สันทราย",
    "districtNameEn": "San Sai",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501412",
    "subDistrictNameTh": "ป่าไผ่",
    "subDistrictNameEn": "Pa Phai",
    "postcode": "50210",
    "districtCode": "5014",
    "districtNameTh": "สันทราย",
    "districtNameEn": "San Sai",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501501",
    "subDistrictNameTh": "หางดง",
    "subDistrictNameEn": "Hang Dong",
    "postcode": "50230",
    "districtCode": "5015",
    "districtNameTh": "หางดง",
    "districtNameEn": "Hang Dong",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501502",
    "subDistrictNameTh": "หนองแก๋ว",
    "subDistrictNameEn": "Nong Kaeo",
    "postcode": "50230",
    "districtCode": "5015",
    "districtNameTh": "หางดง",
    "districtNameEn": "Hang Dong",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501503",
    "subDistrictNameTh": "หารแก้ว",
    "subDistrictNameEn": "Han Kaeo",
    "postcode": "50230",
    "districtCode": "5015",
    "districtNameTh": "หางดง",
    "districtNameEn": "Hang Dong",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501504",
    "subDistrictNameTh": "หนองตอง",
    "subDistrictNameEn": "Nong Tong",
    "postcode": "50340",
    "districtCode": "5015",
    "districtNameTh": "หางดง",
    "districtNameEn": "Hang Dong",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501505",
    "subDistrictNameTh": "ขุนคง",
    "subDistrictNameEn": "Khun Khong",
    "postcode": "50230",
    "districtCode": "5015",
    "districtNameTh": "หางดง",
    "districtNameEn": "Hang Dong",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501506",
    "subDistrictNameTh": "สบแม่ข่า",
    "subDistrictNameEn": "Sop Mae Kha",
    "postcode": "50230",
    "districtCode": "5015",
    "districtNameTh": "หางดง",
    "districtNameEn": "Hang Dong",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501507",
    "subDistrictNameTh": "บ้านแหวน",
    "subDistrictNameEn": "Ban Waen",
    "postcode": "50230",
    "districtCode": "5015",
    "districtNameTh": "หางดง",
    "districtNameEn": "Hang Dong",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501508",
    "subDistrictNameTh": "สันผักหวาน",
    "subDistrictNameEn": "San Phak Wan",
    "postcode": "50230",
    "districtCode": "5015",
    "districtNameTh": "หางดง",
    "districtNameEn": "Hang Dong",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501509",
    "subDistrictNameTh": "หนองควาย",
    "subDistrictNameEn": "Nong Khwai",
    "postcode": "50230",
    "districtCode": "5015",
    "districtNameTh": "หางดง",
    "districtNameEn": "Hang Dong",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501510",
    "subDistrictNameTh": "บ้านปง",
    "subDistrictNameEn": "Ban Pong",
    "postcode": "50230",
    "districtCode": "5015",
    "districtNameTh": "หางดง",
    "districtNameEn": "Hang Dong",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501511",
    "subDistrictNameTh": "น้ำแพร่",
    "subDistrictNameEn": "Nam Phrae",
    "postcode": "50230",
    "districtCode": "5015",
    "districtNameTh": "หางดง",
    "districtNameEn": "Hang Dong",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501601",
    "subDistrictNameTh": "หางดง",
    "subDistrictNameEn": "Hang Dong",
    "postcode": "50240",
    "districtCode": "5016",
    "districtNameTh": "ฮอด",
    "districtNameEn": "Hot",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501602",
    "subDistrictNameTh": "ฮอด",
    "subDistrictNameEn": "Hot",
    "postcode": "50240",
    "districtCode": "5016",
    "districtNameTh": "ฮอด",
    "districtNameEn": "Hot",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501603",
    "subDistrictNameTh": "บ้านตาล",
    "subDistrictNameEn": "Ban Tan",
    "postcode": "50240",
    "districtCode": "5016",
    "districtNameTh": "ฮอด",
    "districtNameEn": "Hot",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501604",
    "subDistrictNameTh": "บ่อหลวง",
    "subDistrictNameEn": "Bo Luang",
    "postcode": "50240",
    "districtCode": "5016",
    "districtNameTh": "ฮอด",
    "districtNameEn": "Hot",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501605",
    "subDistrictNameTh": "บ่อสลี",
    "subDistrictNameEn": "Bo Sali",
    "postcode": "50240",
    "districtCode": "5016",
    "districtNameTh": "ฮอด",
    "districtNameEn": "Hot",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501606",
    "subDistrictNameTh": "นาคอเรือ",
    "subDistrictNameEn": "Na Kho Ruea",
    "postcode": "50240",
    "districtCode": "5016",
    "districtNameTh": "ฮอด",
    "districtNameEn": "Hot",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501701",
    "subDistrictNameTh": "ดอยเต่า",
    "subDistrictNameEn": "Doi Tao",
    "postcode": "50260",
    "districtCode": "5017",
    "districtNameTh": "ดอยเต่า",
    "districtNameEn": "Doi Tao",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501702",
    "subDistrictNameTh": "ท่าเดื่อ",
    "subDistrictNameEn": "Tha Duea",
    "postcode": "50260",
    "districtCode": "5017",
    "districtNameTh": "ดอยเต่า",
    "districtNameEn": "Doi Tao",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501703",
    "subDistrictNameTh": "มืดกา",
    "subDistrictNameEn": "Muet Ka",
    "postcode": "50260",
    "districtCode": "5017",
    "districtNameTh": "ดอยเต่า",
    "districtNameEn": "Doi Tao",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501704",
    "subDistrictNameTh": "บ้านแอ่น",
    "subDistrictNameEn": "Ban Aen",
    "postcode": "50260",
    "districtCode": "5017",
    "districtNameTh": "ดอยเต่า",
    "districtNameEn": "Doi Tao",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501705",
    "subDistrictNameTh": "บงตัน",
    "subDistrictNameEn": "Bong Tan",
    "postcode": "50260",
    "districtCode": "5017",
    "districtNameTh": "ดอยเต่า",
    "districtNameEn": "Doi Tao",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501706",
    "subDistrictNameTh": "โปงทุ่ง",
    "subDistrictNameEn": "Pong Thung",
    "postcode": "50260",
    "districtCode": "5017",
    "districtNameTh": "ดอยเต่า",
    "districtNameEn": "Doi Tao",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501801",
    "subDistrictNameTh": "อมก๋อย",
    "subDistrictNameEn": "Omkoi",
    "postcode": "50310",
    "districtCode": "5018",
    "districtNameTh": "อมก๋อย",
    "districtNameEn": "Omkoi",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501802",
    "subDistrictNameTh": "ยางเปียง",
    "subDistrictNameEn": "Yang Piang",
    "postcode": "50310",
    "districtCode": "5018",
    "districtNameTh": "อมก๋อย",
    "districtNameEn": "Omkoi",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501803",
    "subDistrictNameTh": "แม่ตื่น",
    "subDistrictNameEn": "Mae Tuen",
    "postcode": "50310",
    "districtCode": "5018",
    "districtNameTh": "อมก๋อย",
    "districtNameEn": "Omkoi",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501804",
    "subDistrictNameTh": "ม่อนจอง",
    "subDistrictNameEn": "Mon Chong",
    "postcode": "50310",
    "districtCode": "5018",
    "districtNameTh": "อมก๋อย",
    "districtNameEn": "Omkoi",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501805",
    "subDistrictNameTh": "สบโขง",
    "subDistrictNameEn": "Sop Khong",
    "postcode": "50310",
    "districtCode": "5018",
    "districtNameTh": "อมก๋อย",
    "districtNameEn": "Omkoi",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501806",
    "subDistrictNameTh": "นาเกียน",
    "subDistrictNameEn": "Na Kian",
    "postcode": "50310",
    "districtCode": "5018",
    "districtNameTh": "อมก๋อย",
    "districtNameEn": "Omkoi",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501901",
    "subDistrictNameTh": "ยางเนิ้ง",
    "subDistrictNameEn": "Yang Noeng",
    "postcode": "50140",
    "districtCode": "5019",
    "districtNameTh": "สารภี",
    "districtNameEn": "Saraphi",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501902",
    "subDistrictNameTh": "สารภี",
    "subDistrictNameEn": "Saraphi",
    "postcode": "50140",
    "districtCode": "5019",
    "districtNameTh": "สารภี",
    "districtNameEn": "Saraphi",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501903",
    "subDistrictNameTh": "ชมภู",
    "subDistrictNameEn": "Chomphu",
    "postcode": "50140",
    "districtCode": "5019",
    "districtNameTh": "สารภี",
    "districtNameEn": "Saraphi",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501904",
    "subDistrictNameTh": "ไชยสถาน",
    "subDistrictNameEn": "Chai Sathan",
    "postcode": "50140",
    "districtCode": "5019",
    "districtNameTh": "สารภี",
    "districtNameEn": "Saraphi",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501905",
    "subDistrictNameTh": "ขัวมุง",
    "subDistrictNameEn": "Khua Mung",
    "postcode": "50140",
    "districtCode": "5019",
    "districtNameTh": "สารภี",
    "districtNameEn": "Saraphi",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501906",
    "subDistrictNameTh": "หนองแฝก",
    "subDistrictNameEn": "Nong Faek",
    "postcode": "50140",
    "districtCode": "5019",
    "districtNameTh": "สารภี",
    "districtNameEn": "Saraphi",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501907",
    "subDistrictNameTh": "หนองผึ้ง",
    "subDistrictNameEn": "Nong Phueng",
    "postcode": "50140",
    "districtCode": "5019",
    "districtNameTh": "สารภี",
    "districtNameEn": "Saraphi",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501908",
    "subDistrictNameTh": "ท่ากว้าง",
    "subDistrictNameEn": "Tha Kwang",
    "postcode": "50140",
    "districtCode": "5019",
    "districtNameTh": "สารภี",
    "districtNameEn": "Saraphi",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501909",
    "subDistrictNameTh": "ดอนแก้ว",
    "subDistrictNameEn": "Don Kaeo",
    "postcode": "50140",
    "districtCode": "5019",
    "districtNameTh": "สารภี",
    "districtNameEn": "Saraphi",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501910",
    "subDistrictNameTh": "ท่าวังตาล",
    "subDistrictNameEn": "Tha Wang Tan",
    "postcode": "50140",
    "districtCode": "5019",
    "districtNameTh": "สารภี",
    "districtNameEn": "Saraphi",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501911",
    "subDistrictNameTh": "สันทราย",
    "subDistrictNameEn": "San Sai",
    "postcode": "50140",
    "districtCode": "5019",
    "districtNameTh": "สารภี",
    "districtNameEn": "Saraphi",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "501912",
    "subDistrictNameTh": "ป่าบง",
    "subDistrictNameEn": "Pa Bong",
    "postcode": "50140",
    "districtCode": "5019",
    "districtNameTh": "สารภี",
    "districtNameEn": "Saraphi",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "502001",
    "subDistrictNameTh": "เมืองแหง",
    "subDistrictNameEn": "Mueang Haeng",
    "postcode": "50350",
    "districtCode": "5020",
    "districtNameTh": "เวียงแหง",
    "districtNameEn": "Wiang Haeng",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "502002",
    "subDistrictNameTh": "เปียงหลวง",
    "subDistrictNameEn": "Piang Luang",
    "postcode": "50350",
    "districtCode": "5020",
    "districtNameTh": "เวียงแหง",
    "districtNameEn": "Wiang Haeng",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "502003",
    "subDistrictNameTh": "แสนไห",
    "subDistrictNameEn": "Saen Hai",
    "postcode": "50350",
    "districtCode": "5020",
    "districtNameTh": "เวียงแหง",
    "districtNameEn": "Wiang Haeng",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "502101",
    "subDistrictNameTh": "ปงตำ",
    "subDistrictNameEn": "Pong Tam",
    "postcode": "50320",
    "districtCode": "5021",
    "districtNameTh": "ไชยปราการ",
    "districtNameEn": "Chai Prakan",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "502102",
    "subDistrictNameTh": "ศรีดงเย็น",
    "subDistrictNameEn": "Si Dong Yen",
    "postcode": "50320",
    "districtCode": "5021",
    "districtNameTh": "ไชยปราการ",
    "districtNameEn": "Chai Prakan",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "502103",
    "subDistrictNameTh": "แม่ทะลบ",
    "subDistrictNameEn": "Mae Thalop",
    "postcode": "50320",
    "districtCode": "5021",
    "districtNameTh": "ไชยปราการ",
    "districtNameEn": "Chai Prakan",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "502104",
    "subDistrictNameTh": "หนองบัว",
    "subDistrictNameEn": "Nong Bua",
    "postcode": "50320",
    "districtCode": "5021",
    "districtNameTh": "ไชยปราการ",
    "districtNameEn": "Chai Prakan",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "502201",
    "subDistrictNameTh": "บ้านกาด",
    "subDistrictNameEn": "Ban Kat",
    "postcode": "50360",
    "districtCode": "5022",
    "districtNameTh": "แม่วาง",
    "districtNameEn": "Mae Wang",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "502202",
    "subDistrictNameTh": "ทุ่งปี๊",
    "subDistrictNameEn": "Thung Pi",
    "postcode": "50360",
    "districtCode": "5022",
    "districtNameTh": "แม่วาง",
    "districtNameEn": "Mae Wang",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "502203",
    "subDistrictNameTh": "ทุ่งรวงทอง",
    "subDistrictNameEn": "Thung Ruang Thong",
    "postcode": "50360",
    "districtCode": "5022",
    "districtNameTh": "แม่วาง",
    "districtNameEn": "Mae Wang",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "502204",
    "subDistrictNameTh": "แม่วิน",
    "subDistrictNameEn": "Mae Win",
    "postcode": "50360",
    "districtCode": "5022",
    "districtNameTh": "แม่วาง",
    "districtNameEn": "Mae Wang",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "502205",
    "subDistrictNameTh": "ดอนเปา",
    "subDistrictNameEn": "Don Pao",
    "postcode": "50360",
    "districtCode": "5022",
    "districtNameTh": "แม่วาง",
    "districtNameEn": "Mae Wang",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "502301",
    "subDistrictNameTh": "ออนเหนือ",
    "subDistrictNameEn": "On Nuea",
    "postcode": "50130",
    "districtCode": "5023",
    "districtNameTh": "แม่ออน",
    "districtNameEn": "Mae On",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "502302",
    "subDistrictNameTh": "ออนกลาง",
    "subDistrictNameEn": "On Klang",
    "postcode": "50130",
    "districtCode": "5023",
    "districtNameTh": "แม่ออน",
    "districtNameEn": "Mae On",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "502303",
    "subDistrictNameTh": "บ้านสหกรณ์",
    "subDistrictNameEn": "Ban Sahakon",
    "postcode": "50130",
    "districtCode": "5023",
    "districtNameTh": "แม่ออน",
    "districtNameEn": "Mae On",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "502304",
    "subDistrictNameTh": "ห้วยแก้ว",
    "subDistrictNameEn": "Huai Kaeo",
    "postcode": "50130",
    "districtCode": "5023",
    "districtNameTh": "แม่ออน",
    "districtNameEn": "Mae On",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "502305",
    "subDistrictNameTh": "แม่ทา",
    "subDistrictNameEn": "Mae Tha",
    "postcode": "50130",
    "districtCode": "5023",
    "districtNameTh": "แม่ออน",
    "districtNameEn": "Mae On",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "502306",
    "subDistrictNameTh": "ทาเหนือ",
    "subDistrictNameEn": "Tha Nuea",
    "postcode": "50130",
    "districtCode": "5023",
    "districtNameTh": "แม่ออน",
    "districtNameEn": "Mae On",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "502401",
    "subDistrictNameTh": "ดอยหล่อ",
    "subDistrictNameEn": "Doi Lo",
    "postcode": "50160",
    "districtCode": "5024",
    "districtNameTh": "ดอยหล่อ",
    "districtNameEn": "Doi Lo",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "502402",
    "subDistrictNameTh": "สองแคว",
    "subDistrictNameEn": "Song Khwae",
    "postcode": "50160",
    "districtCode": "5024",
    "districtNameTh": "ดอยหล่อ",
    "districtNameEn": "Doi Lo",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "502403",
    "subDistrictNameTh": "ยางคราม",
    "subDistrictNameEn": "Yang Khram",
    "postcode": "50160",
    "districtCode": "5024",
    "districtNameTh": "ดอยหล่อ",
    "districtNameEn": "Doi Lo",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "502404",
    "subDistrictNameTh": "สันติสุข",
    "subDistrictNameEn": "Santi Suk",
    "postcode": "50160",
    "districtCode": "5024",
    "districtNameTh": "ดอยหล่อ",
    "districtNameEn": "Doi Lo",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "502501",
    "subDistrictNameTh": "บ้านจันทร์",
    "subDistrictNameEn": "Ban Chan",
    "postcode": "58130",
    "districtCode": "5025",
    "districtNameTh": "กัลยาณิวัฒนา",
    "districtNameEn": "Galayani Vadhana",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "502502",
    "subDistrictNameTh": "แม่แดด",
    "subDistrictNameEn": "Mae Daet",
    "postcode": "58130",
    "districtCode": "5025",
    "districtNameTh": "กัลยาณิวัฒนา",
    "districtNameEn": "Galayani Vadhana",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "502503",
    "subDistrictNameTh": "แจ่มหลวง",
    "subDistrictNameEn": "Chaem Luang",
    "postcode": "58130",
    "districtCode": "5025",
    "districtNameTh": "กัลยาณิวัฒนา",
    "districtNameEn": "Galayani Vadhana",
    "provinceCode": "50",
    "provinceNameTh": "เชียงใหม่",
    "provinceNameEn": "Chiang Mai"
},
{
    "subDistrictCode": "510101",
    "subDistrictNameTh": "ในเมือง",
    "subDistrictNameEn": "Nai Mueang",
    "postcode": "51000",
    "districtCode": "5101",
    "districtNameTh": "เมืองลำพูน",
    "districtNameEn": "Mueang Lamphun",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510102",
    "subDistrictNameTh": "เหมืองง่า",
    "subDistrictNameEn": "Mueang Nga",
    "postcode": "51000",
    "districtCode": "5101",
    "districtNameTh": "เมืองลำพูน",
    "districtNameEn": "Mueang Lamphun",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510103",
    "subDistrictNameTh": "อุโมงค์",
    "subDistrictNameEn": "Umong",
    "postcode": "51150",
    "districtCode": "5101",
    "districtNameTh": "เมืองลำพูน",
    "districtNameEn": "Mueang Lamphun",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510104",
    "subDistrictNameTh": "หนองช้างคืน",
    "subDistrictNameEn": "Nong Chang Khuen",
    "postcode": "51150",
    "districtCode": "5101",
    "districtNameTh": "เมืองลำพูน",
    "districtNameEn": "Mueang Lamphun",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510105",
    "subDistrictNameTh": "ประตูป่า",
    "subDistrictNameEn": "Pratu Pa",
    "postcode": "51000",
    "districtCode": "5101",
    "districtNameTh": "เมืองลำพูน",
    "districtNameEn": "Mueang Lamphun",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510106",
    "subDistrictNameTh": "ริมปิง",
    "subDistrictNameEn": "Rim Ping",
    "postcode": "51000",
    "districtCode": "5101",
    "districtNameTh": "เมืองลำพูน",
    "districtNameEn": "Mueang Lamphun",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510107",
    "subDistrictNameTh": "ต้นธง",
    "subDistrictNameEn": "Ton Thong",
    "postcode": "51000",
    "districtCode": "5101",
    "districtNameTh": "เมืองลำพูน",
    "districtNameEn": "Mueang Lamphun",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510108",
    "subDistrictNameTh": "บ้านแป้น",
    "subDistrictNameEn": "Ban Paen",
    "postcode": "51000",
    "districtCode": "5101",
    "districtNameTh": "เมืองลำพูน",
    "districtNameEn": "Mueang Lamphun",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510109",
    "subDistrictNameTh": "เหมืองจี้",
    "subDistrictNameEn": "Mueang Chi",
    "postcode": "51000",
    "districtCode": "5101",
    "districtNameTh": "เมืองลำพูน",
    "districtNameEn": "Mueang Lamphun",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510110",
    "subDistrictNameTh": "ป่าสัก",
    "subDistrictNameEn": "Pa Sak",
    "postcode": "51000",
    "districtCode": "5101",
    "districtNameTh": "เมืองลำพูน",
    "districtNameEn": "Mueang Lamphun",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510111",
    "subDistrictNameTh": "เวียงยอง",
    "subDistrictNameEn": "Wiang Yong",
    "postcode": "51000",
    "districtCode": "5101",
    "districtNameTh": "เมืองลำพูน",
    "districtNameEn": "Mueang Lamphun",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510112",
    "subDistrictNameTh": "บ้านกลาง",
    "subDistrictNameEn": "Ban Klang",
    "postcode": "51000",
    "districtCode": "5101",
    "districtNameTh": "เมืองลำพูน",
    "districtNameEn": "Mueang Lamphun",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510113",
    "subDistrictNameTh": "มะเขือแจ้",
    "subDistrictNameEn": "Makhuea Chae",
    "postcode": "51000",
    "districtCode": "5101",
    "districtNameTh": "เมืองลำพูน",
    "districtNameEn": "Mueang Lamphun",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510116",
    "subDistrictNameTh": "ศรีบัวบาน",
    "subDistrictNameEn": "Si Bua Ban",
    "postcode": "51000",
    "districtCode": "5101",
    "districtNameTh": "เมืองลำพูน",
    "districtNameEn": "Mueang Lamphun",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510117",
    "subDistrictNameTh": "หนองหนาม",
    "subDistrictNameEn": "Nong Nam",
    "postcode": "51000",
    "districtCode": "5101",
    "districtNameTh": "เมืองลำพูน",
    "districtNameEn": "Mueang Lamphun",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510201",
    "subDistrictNameTh": "ทาปลาดุก",
    "subDistrictNameEn": "Tha Pla Duk",
    "postcode": "51140",
    "districtCode": "5102",
    "districtNameTh": "แม่ทา",
    "districtNameEn": "Mae Tha",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510202",
    "subDistrictNameTh": "ทาสบเส้า",
    "subDistrictNameEn": "Tha Sop Sao",
    "postcode": "51140",
    "districtCode": "5102",
    "districtNameTh": "แม่ทา",
    "districtNameEn": "Mae Tha",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510203",
    "subDistrictNameTh": "ทากาศ",
    "subDistrictNameEn": "Tha Kat",
    "postcode": "51170",
    "districtCode": "5102",
    "districtNameTh": "แม่ทา",
    "districtNameEn": "Mae Tha",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510204",
    "subDistrictNameTh": "ทาขุมเงิน",
    "subDistrictNameEn": "Tha Khum Ngoen",
    "postcode": "51170",
    "districtCode": "5102",
    "districtNameTh": "แม่ทา",
    "districtNameEn": "Mae Tha",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510205",
    "subDistrictNameTh": "ทาทุ่งหลวง",
    "subDistrictNameEn": "Tha Thung Luang",
    "postcode": "51170",
    "districtCode": "5102",
    "districtNameTh": "แม่ทา",
    "districtNameEn": "Mae Tha",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510206",
    "subDistrictNameTh": "ทาแม่ลอบ",
    "subDistrictNameEn": "Tha Mae Lop",
    "postcode": "51170",
    "districtCode": "5102",
    "districtNameTh": "แม่ทา",
    "districtNameEn": "Mae Tha",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510301",
    "subDistrictNameTh": "บ้านโฮ่ง",
    "subDistrictNameEn": "Ban Hong",
    "postcode": "51130",
    "districtCode": "5103",
    "districtNameTh": "บ้านโฮ่ง",
    "districtNameEn": "Ban Hong",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510302",
    "subDistrictNameTh": "ป่าพลู",
    "subDistrictNameEn": "Pa Phlu",
    "postcode": "51130",
    "districtCode": "5103",
    "districtNameTh": "บ้านโฮ่ง",
    "districtNameEn": "Ban Hong",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510303",
    "subDistrictNameTh": "เหล่ายาว",
    "subDistrictNameEn": "Lao Yao",
    "postcode": "51130",
    "districtCode": "5103",
    "districtNameTh": "บ้านโฮ่ง",
    "districtNameEn": "Ban Hong",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510304",
    "subDistrictNameTh": "ศรีเตี้ย",
    "subDistrictNameEn": "Si Tia",
    "postcode": "51130",
    "districtCode": "5103",
    "districtNameTh": "บ้านโฮ่ง",
    "districtNameEn": "Ban Hong",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510305",
    "subDistrictNameTh": "หนองปลาสะวาย",
    "subDistrictNameEn": "Nong Pla Sawai",
    "postcode": "51130",
    "districtCode": "5103",
    "districtNameTh": "บ้านโฮ่ง",
    "districtNameEn": "Ban Hong",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510401",
    "subDistrictNameTh": "ลี้",
    "subDistrictNameEn": "Li",
    "postcode": "51110",
    "districtCode": "5104",
    "districtNameTh": "ลี้",
    "districtNameEn": "Li",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510402",
    "subDistrictNameTh": "แม่ตืน",
    "subDistrictNameEn": "Mae Tuen",
    "postcode": "51110",
    "districtCode": "5104",
    "districtNameTh": "ลี้",
    "districtNameEn": "Li",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510403",
    "subDistrictNameTh": "นาทราย",
    "subDistrictNameEn": "Na Sai",
    "postcode": "51110",
    "districtCode": "5104",
    "districtNameTh": "ลี้",
    "districtNameEn": "Li",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510404",
    "subDistrictNameTh": "ดงดำ",
    "subDistrictNameEn": "Dong Dam",
    "postcode": "51110",
    "districtCode": "5104",
    "districtNameTh": "ลี้",
    "districtNameEn": "Li",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510405",
    "subDistrictNameTh": "ก้อ",
    "subDistrictNameEn": "Ko",
    "postcode": "51110",
    "districtCode": "5104",
    "districtNameTh": "ลี้",
    "districtNameEn": "Li",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510406",
    "subDistrictNameTh": "แม่ลาน",
    "subDistrictNameEn": "Mae Lan",
    "postcode": "51110",
    "districtCode": "5104",
    "districtNameTh": "ลี้",
    "districtNameEn": "Li",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510408",
    "subDistrictNameTh": "ป่าไผ่",
    "subDistrictNameEn": "Pha Phai",
    "postcode": "51110",
    "districtCode": "5104",
    "districtNameTh": "ลี้",
    "districtNameEn": "Li",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510409",
    "subDistrictNameTh": "ศรีวิชัย",
    "subDistrictNameEn": "Si Wichai",
    "postcode": "51110",
    "districtCode": "5104",
    "districtNameTh": "ลี้",
    "districtNameEn": "Li",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510501",
    "subDistrictNameTh": "ทุ่งหัวช้าง",
    "subDistrictNameEn": "Thung Hua Chang",
    "postcode": "51160",
    "districtCode": "5105",
    "districtNameTh": "ทุ่งหัวช้าง",
    "districtNameEn": "Thung Hua Chang",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510502",
    "subDistrictNameTh": "บ้านปวง",
    "subDistrictNameEn": "Ban Puang",
    "postcode": "51160",
    "districtCode": "5105",
    "districtNameTh": "ทุ่งหัวช้าง",
    "districtNameEn": "Thung Hua Chang",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510503",
    "subDistrictNameTh": "ตะเคียนปม",
    "subDistrictNameEn": "Takhian Pom",
    "postcode": "51160",
    "districtCode": "5105",
    "districtNameTh": "ทุ่งหัวช้าง",
    "districtNameEn": "Thung Hua Chang",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510601",
    "subDistrictNameTh": "ปากบ่อง",
    "subDistrictNameEn": "Pak Bong",
    "postcode": "51120",
    "districtCode": "5106",
    "districtNameTh": "ป่าซาง",
    "districtNameEn": "Pa Sang",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510602",
    "subDistrictNameTh": "ป่าซาง",
    "subDistrictNameEn": "Pa Sang",
    "postcode": "51120",
    "districtCode": "5106",
    "districtNameTh": "ป่าซาง",
    "districtNameEn": "Pa Sang",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510603",
    "subDistrictNameTh": "แม่แรง",
    "subDistrictNameEn": "Mae Raeng",
    "postcode": "51120",
    "districtCode": "5106",
    "districtNameTh": "ป่าซาง",
    "districtNameEn": "Pa Sang",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510604",
    "subDistrictNameTh": "ม่วงน้อย",
    "subDistrictNameEn": "Muang Noi",
    "postcode": "51120",
    "districtCode": "5106",
    "districtNameTh": "ป่าซาง",
    "districtNameEn": "Pa Sang",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510605",
    "subDistrictNameTh": "บ้านเรือน",
    "subDistrictNameEn": "Ban Ruean",
    "postcode": "51120",
    "districtCode": "5106",
    "districtNameTh": "ป่าซาง",
    "districtNameEn": "Pa Sang",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510606",
    "subDistrictNameTh": "มะกอก",
    "subDistrictNameEn": "Makok",
    "postcode": "51120",
    "districtCode": "5106",
    "districtNameTh": "ป่าซาง",
    "districtNameEn": "Pa Sang",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510607",
    "subDistrictNameTh": "ท่าตุ้ม",
    "subDistrictNameEn": "Tha Tum",
    "postcode": "51120",
    "districtCode": "5106",
    "districtNameTh": "ป่าซาง",
    "districtNameEn": "Pa Sang",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510608",
    "subDistrictNameTh": "น้ำดิบ",
    "subDistrictNameEn": "Nam Dip",
    "postcode": "51120",
    "districtCode": "5106",
    "districtNameTh": "ป่าซาง",
    "districtNameEn": "Pa Sang",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510611",
    "subDistrictNameTh": "นครเจดีย์",
    "subDistrictNameEn": "Nakhon Chedi",
    "postcode": "51120",
    "districtCode": "5106",
    "districtNameTh": "ป่าซาง",
    "districtNameEn": "Pa Sang",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510701",
    "subDistrictNameTh": "บ้านธิ",
    "subDistrictNameEn": "Ban Thi",
    "postcode": "51180",
    "districtCode": "5107",
    "districtNameTh": "บ้านธิ",
    "districtNameEn": "Ban Thi",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510702",
    "subDistrictNameTh": "ห้วยยาบ",
    "subDistrictNameEn": "Huai Yap",
    "postcode": "51180",
    "districtCode": "5107",
    "districtNameTh": "บ้านธิ",
    "districtNameEn": "Ban Thi",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510801",
    "subDistrictNameTh": "หนองล่อง",
    "subDistrictNameEn": "Nong Long",
    "postcode": "51120",
    "districtCode": "5108",
    "districtNameTh": "เวียงหนองล่อง",
    "districtNameEn": "Wiang Nong Long",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510802",
    "subDistrictNameTh": "หนองยวง",
    "subDistrictNameEn": "Nong Yuang",
    "postcode": "51120",
    "districtCode": "5108",
    "districtNameTh": "เวียงหนองล่อง",
    "districtNameEn": "Wiang Nong Long",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "510803",
    "subDistrictNameTh": "วังผาง",
    "subDistrictNameEn": "Wang Phang",
    "postcode": "51120",
    "districtCode": "5108",
    "districtNameTh": "เวียงหนองล่อง",
    "districtNameEn": "Wiang Nong Long",
    "provinceCode": "51",
    "provinceNameTh": "ลำพูน",
    "provinceNameEn": "Lamphun"
},
{
    "subDistrictCode": "520101",
    "subDistrictNameTh": "เวียงเหนือ",
    "subDistrictNameEn": "Wiang Nuea",
    "postcode": "52000",
    "districtCode": "5201",
    "districtNameTh": "เมืองลำปาง",
    "districtNameEn": "Mueang Lampang",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520102",
    "subDistrictNameTh": "หัวเวียง",
    "subDistrictNameEn": "Hua Wiang",
    "postcode": "52000",
    "districtCode": "5201",
    "districtNameTh": "เมืองลำปาง",
    "districtNameEn": "Mueang Lampang",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520103",
    "subDistrictNameTh": "สวนดอก",
    "subDistrictNameEn": "Suan Dok",
    "postcode": "52100",
    "districtCode": "5201",
    "districtNameTh": "เมืองลำปาง",
    "districtNameEn": "Mueang Lampang",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520104",
    "subDistrictNameTh": "สบตุ๋ย",
    "subDistrictNameEn": "Sop Tui",
    "postcode": "52100",
    "districtCode": "5201",
    "districtNameTh": "เมืองลำปาง",
    "districtNameEn": "Mueang Lampang",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520105",
    "subDistrictNameTh": "พระบาท",
    "subDistrictNameEn": "Phra Bat",
    "postcode": "52000",
    "districtCode": "5201",
    "districtNameTh": "เมืองลำปาง",
    "districtNameEn": "Mueang Lampang",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520106",
    "subDistrictNameTh": "ชมพู",
    "subDistrictNameEn": "Chomphu",
    "postcode": "52100",
    "districtCode": "5201",
    "districtNameTh": "เมืองลำปาง",
    "districtNameEn": "Mueang Lampang",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520107",
    "subDistrictNameTh": "กล้วยแพะ",
    "subDistrictNameEn": "Kluai Phae",
    "postcode": "52000",
    "districtCode": "5201",
    "districtNameTh": "เมืองลำปาง",
    "districtNameEn": "Mueang Lampang",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520108",
    "subDistrictNameTh": "ปงแสนทอง",
    "subDistrictNameEn": "Pong Saen Thong",
    "postcode": "52100",
    "districtCode": "5201",
    "districtNameTh": "เมืองลำปาง",
    "districtNameEn": "Mueang Lampang",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520109",
    "subDistrictNameTh": "บ้านแลง",
    "subDistrictNameEn": "Ban Laeng",
    "postcode": "52000",
    "districtCode": "5201",
    "districtNameTh": "เมืองลำปาง",
    "districtNameEn": "Mueang Lampang",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520110",
    "subDistrictNameTh": "บ้านเสด็จ",
    "subDistrictNameEn": "Ban Sadet",
    "postcode": "52000",
    "districtCode": "5201",
    "districtNameTh": "เมืองลำปาง",
    "districtNameEn": "Mueang Lampang",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520111",
    "subDistrictNameTh": "พิชัย",
    "subDistrictNameEn": "Phichai",
    "postcode": "52000",
    "districtCode": "5201",
    "districtNameTh": "เมืองลำปาง",
    "districtNameEn": "Mueang Lampang",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520112",
    "subDistrictNameTh": "ทุ่งฝาย",
    "subDistrictNameEn": "Thung Fai",
    "postcode": "52000",
    "districtCode": "5201",
    "districtNameTh": "เมืองลำปาง",
    "districtNameEn": "Mueang Lampang",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520113",
    "subDistrictNameTh": "บ้านเอื้อม",
    "subDistrictNameEn": "Ban Ueam",
    "postcode": "52100",
    "districtCode": "5201",
    "districtNameTh": "เมืองลำปาง",
    "districtNameEn": "Mueang Lampang",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520114",
    "subDistrictNameTh": "บ้านเป้า",
    "subDistrictNameEn": "Ban Pao",
    "postcode": "52100",
    "districtCode": "5201",
    "districtNameTh": "เมืองลำปาง",
    "districtNameEn": "Mueang Lampang",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520115",
    "subDistrictNameTh": "บ้านค่า",
    "subDistrictNameEn": "Ban Kha",
    "postcode": "52100",
    "districtCode": "5201",
    "districtNameTh": "เมืองลำปาง",
    "districtNameEn": "Mueang Lampang",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520116",
    "subDistrictNameTh": "บ่อแฮ้ว",
    "subDistrictNameEn": "Bo Haeo",
    "postcode": "52100",
    "districtCode": "5201",
    "districtNameTh": "เมืองลำปาง",
    "districtNameEn": "Mueang Lampang",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520117",
    "subDistrictNameTh": "ต้นธงชัย",
    "subDistrictNameEn": "Ton Thong Chai",
    "postcode": "52000",
    "districtCode": "5201",
    "districtNameTh": "เมืองลำปาง",
    "districtNameEn": "Mueang Lampang",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520118",
    "subDistrictNameTh": "นิคมพัฒนา",
    "subDistrictNameEn": "Nikhom Phatthana",
    "postcode": "52000",
    "districtCode": "5201",
    "districtNameTh": "เมืองลำปาง",
    "districtNameEn": "Mueang Lampang",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520119",
    "subDistrictNameTh": "บุญนาคพัฒนา",
    "subDistrictNameEn": "Bunnak Phatthana",
    "postcode": "52000",
    "districtCode": "5201",
    "districtNameTh": "เมืองลำปาง",
    "districtNameEn": "Mueang Lampang",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520201",
    "subDistrictNameTh": "บ้านดง",
    "subDistrictNameEn": "Ban Dong",
    "postcode": "52220",
    "districtCode": "5202",
    "districtNameTh": "แม่เมาะ",
    "districtNameEn": "Mae Mo",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520202",
    "subDistrictNameTh": "นาสัก",
    "subDistrictNameEn": "Na Sak",
    "postcode": "52220",
    "districtCode": "5202",
    "districtNameTh": "แม่เมาะ",
    "districtNameEn": "Mae Mo",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520203",
    "subDistrictNameTh": "จางเหนือ",
    "subDistrictNameEn": "Chang Nuea",
    "postcode": "52220",
    "districtCode": "5202",
    "districtNameTh": "แม่เมาะ",
    "districtNameEn": "Mae Mo",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520204",
    "subDistrictNameTh": "แม่เมาะ",
    "subDistrictNameEn": "Mae Mo",
    "postcode": "52220",
    "districtCode": "5202",
    "districtNameTh": "แม่เมาะ",
    "districtNameEn": "Mae Mo",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520205",
    "subDistrictNameTh": "สบป้าด",
    "subDistrictNameEn": "Sop Pat",
    "postcode": "52220",
    "districtCode": "5202",
    "districtNameTh": "แม่เมาะ",
    "districtNameEn": "Mae Mo",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520301",
    "subDistrictNameTh": "ลำปางหลวง",
    "subDistrictNameEn": "Lampang Luang",
    "postcode": "52130",
    "districtCode": "5203",
    "districtNameTh": "เกาะคา",
    "districtNameEn": "Ko Kha",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520302",
    "subDistrictNameTh": "นาแก้ว",
    "subDistrictNameEn": "Na Kaeo",
    "postcode": "52130",
    "districtCode": "5203",
    "districtNameTh": "เกาะคา",
    "districtNameEn": "Ko Kha",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520303",
    "subDistrictNameTh": "ไหล่หิน",
    "subDistrictNameEn": "Lai Hin",
    "postcode": "52130",
    "districtCode": "5203",
    "districtNameTh": "เกาะคา",
    "districtNameEn": "Ko Kha",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520304",
    "subDistrictNameTh": "วังพร้าว",
    "subDistrictNameEn": "Wang Phrao",
    "postcode": "52130",
    "districtCode": "5203",
    "districtNameTh": "เกาะคา",
    "districtNameEn": "Ko Kha",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520305",
    "subDistrictNameTh": "ศาลา",
    "subDistrictNameEn": "Sala",
    "postcode": "52130",
    "districtCode": "5203",
    "districtNameTh": "เกาะคา",
    "districtNameEn": "Ko Kha",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520306",
    "subDistrictNameTh": "เกาะคา",
    "subDistrictNameEn": "Ko Kha",
    "postcode": "52130",
    "districtCode": "5203",
    "districtNameTh": "เกาะคา",
    "districtNameEn": "Ko Kha",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520307",
    "subDistrictNameTh": "นาแส่ง",
    "subDistrictNameEn": "Na Saeng",
    "postcode": "52130",
    "districtCode": "5203",
    "districtNameTh": "เกาะคา",
    "districtNameEn": "Ko Kha",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520308",
    "subDistrictNameTh": "ท่าผา",
    "subDistrictNameEn": "Tha Pha",
    "postcode": "52130",
    "districtCode": "5203",
    "districtNameTh": "เกาะคา",
    "districtNameEn": "Ko Kha",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520309",
    "subDistrictNameTh": "ใหม่พัฒนา",
    "subDistrictNameEn": "Mai Phatthana",
    "postcode": "52130",
    "districtCode": "5203",
    "districtNameTh": "เกาะคา",
    "districtNameEn": "Ko Kha",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520401",
    "subDistrictNameTh": "ทุ่งงาม",
    "subDistrictNameEn": "Thung Ngam",
    "postcode": "52210",
    "districtCode": "5204",
    "districtNameTh": "เสริมงาม",
    "districtNameEn": "Soem Ngam",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520402",
    "subDistrictNameTh": "เสริมขวา",
    "subDistrictNameEn": "Soem Khwa",
    "postcode": "52210",
    "districtCode": "5204",
    "districtNameTh": "เสริมงาม",
    "districtNameEn": "Soem Ngam",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520403",
    "subDistrictNameTh": "เสริมซ้าย",
    "subDistrictNameEn": "Soem Sai",
    "postcode": "52210",
    "districtCode": "5204",
    "districtNameTh": "เสริมงาม",
    "districtNameEn": "Soem Ngam",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520404",
    "subDistrictNameTh": "เสริมกลาง",
    "subDistrictNameEn": "Soem Klang",
    "postcode": "52210",
    "districtCode": "5204",
    "districtNameTh": "เสริมงาม",
    "districtNameEn": "Soem Ngam",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520501",
    "subDistrictNameTh": "หลวงเหนือ",
    "subDistrictNameEn": "Luang Nuea",
    "postcode": "52110",
    "districtCode": "5205",
    "districtNameTh": "งาว",
    "districtNameEn": "Ngao",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520502",
    "subDistrictNameTh": "หลวงใต้",
    "subDistrictNameEn": "Luang Tai",
    "postcode": "52110",
    "districtCode": "5205",
    "districtNameTh": "งาว",
    "districtNameEn": "Ngao",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520503",
    "subDistrictNameTh": "บ้านโป่ง",
    "subDistrictNameEn": "Ban Pong",
    "postcode": "52110",
    "districtCode": "5205",
    "districtNameTh": "งาว",
    "districtNameEn": "Ngao",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520504",
    "subDistrictNameTh": "บ้านร้อง",
    "subDistrictNameEn": "Ban Rong",
    "postcode": "52110",
    "districtCode": "5205",
    "districtNameTh": "งาว",
    "districtNameEn": "Ngao",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520505",
    "subDistrictNameTh": "ปงเตา",
    "subDistrictNameEn": "Pong Tao",
    "postcode": "52110",
    "districtCode": "5205",
    "districtNameTh": "งาว",
    "districtNameEn": "Ngao",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520506",
    "subDistrictNameTh": "นาแก",
    "subDistrictNameEn": "Na Kae",
    "postcode": "52110",
    "districtCode": "5205",
    "districtNameTh": "งาว",
    "districtNameEn": "Ngao",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520507",
    "subDistrictNameTh": "บ้านอ้อน",
    "subDistrictNameEn": "Ban On",
    "postcode": "52110",
    "districtCode": "5205",
    "districtNameTh": "งาว",
    "districtNameEn": "Ngao",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520508",
    "subDistrictNameTh": "บ้านแหง",
    "subDistrictNameEn": "Ban Haeng",
    "postcode": "52110",
    "districtCode": "5205",
    "districtNameTh": "งาว",
    "districtNameEn": "Ngao",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520509",
    "subDistrictNameTh": "บ้านหวด",
    "subDistrictNameEn": "Ban Huat",
    "postcode": "52110",
    "districtCode": "5205",
    "districtNameTh": "งาว",
    "districtNameEn": "Ngao",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520510",
    "subDistrictNameTh": "แม่ตีบ",
    "subDistrictNameEn": "Mae Tip",
    "postcode": "52110",
    "districtCode": "5205",
    "districtNameTh": "งาว",
    "districtNameEn": "Ngao",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520601",
    "subDistrictNameTh": "แจ้ห่ม",
    "subDistrictNameEn": "Chae Hom",
    "postcode": "52120",
    "districtCode": "5206",
    "districtNameTh": "แจ้ห่ม",
    "districtNameEn": "Chae Hom",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520602",
    "subDistrictNameTh": "บ้านสา",
    "subDistrictNameEn": "Ban Sa",
    "postcode": "52120",
    "districtCode": "5206",
    "districtNameTh": "แจ้ห่ม",
    "districtNameEn": "Chae Hom",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520603",
    "subDistrictNameTh": "ปงดอน",
    "subDistrictNameEn": "Pong Don",
    "postcode": "52120",
    "districtCode": "5206",
    "districtNameTh": "แจ้ห่ม",
    "districtNameEn": "Chae Hom",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520604",
    "subDistrictNameTh": "แม่สุก",
    "subDistrictNameEn": "Mae Suk",
    "postcode": "52120",
    "districtCode": "5206",
    "districtNameTh": "แจ้ห่ม",
    "districtNameEn": "Chae Hom",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520605",
    "subDistrictNameTh": "เมืองมาย",
    "subDistrictNameEn": "Mueang Mai",
    "postcode": "52120",
    "districtCode": "5206",
    "districtNameTh": "แจ้ห่ม",
    "districtNameEn": "Chae Hom",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520606",
    "subDistrictNameTh": "ทุ่งผึ้ง",
    "subDistrictNameEn": "Thung Phueng",
    "postcode": "52120",
    "districtCode": "5206",
    "districtNameTh": "แจ้ห่ม",
    "districtNameEn": "Chae Hom",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520607",
    "subDistrictNameTh": "วิเชตนคร",
    "subDistrictNameEn": "Wichet Nakhon",
    "postcode": "52120",
    "districtCode": "5206",
    "districtNameTh": "แจ้ห่ม",
    "districtNameEn": "Chae Hom",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520701",
    "subDistrictNameTh": "ทุ่งฮั้ว",
    "subDistrictNameEn": "Thung Hua",
    "postcode": "52140",
    "districtCode": "5207",
    "districtNameTh": "วังเหนือ",
    "districtNameEn": "Wang Nuea",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520702",
    "subDistrictNameTh": "วังเหนือ",
    "subDistrictNameEn": "Wang Nuea",
    "postcode": "52140",
    "districtCode": "5207",
    "districtNameTh": "วังเหนือ",
    "districtNameEn": "Wang Nuea",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520703",
    "subDistrictNameTh": "วังใต้",
    "subDistrictNameEn": "Wang Tai",
    "postcode": "52140",
    "districtCode": "5207",
    "districtNameTh": "วังเหนือ",
    "districtNameEn": "Wang Nuea",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520704",
    "subDistrictNameTh": "ร่องเคาะ",
    "subDistrictNameEn": "Rong Kho",
    "postcode": "52140",
    "districtCode": "5207",
    "districtNameTh": "วังเหนือ",
    "districtNameEn": "Wang Nuea",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520705",
    "subDistrictNameTh": "วังทอง",
    "subDistrictNameEn": "Wang Thong",
    "postcode": "52140",
    "districtCode": "5207",
    "districtNameTh": "วังเหนือ",
    "districtNameEn": "Wang Nuea",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520706",
    "subDistrictNameTh": "วังซ้าย",
    "subDistrictNameEn": "Wang Sai",
    "postcode": "52140",
    "districtCode": "5207",
    "districtNameTh": "วังเหนือ",
    "districtNameEn": "Wang Nuea",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520707",
    "subDistrictNameTh": "วังแก้ว",
    "subDistrictNameEn": "Wang Kaeo",
    "postcode": "52140",
    "districtCode": "5207",
    "districtNameTh": "วังเหนือ",
    "districtNameEn": "Wang Nuea",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520708",
    "subDistrictNameTh": "วังทรายคำ",
    "subDistrictNameEn": "Wang Sai Kham",
    "postcode": "52140",
    "districtCode": "5207",
    "districtNameTh": "วังเหนือ",
    "districtNameEn": "Wang Nuea",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520801",
    "subDistrictNameTh": "ล้อมแรด",
    "subDistrictNameEn": "Lom Raet",
    "postcode": "52160",
    "districtCode": "5208",
    "districtNameTh": "เถิน",
    "districtNameEn": "Thoen",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520802",
    "subDistrictNameTh": "แม่วะ",
    "subDistrictNameEn": "Mae Wa",
    "postcode": "52230",
    "districtCode": "5208",
    "districtNameTh": "เถิน",
    "districtNameEn": "Thoen",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520803",
    "subDistrictNameTh": "แม่ปะ",
    "subDistrictNameEn": "Mae Pa",
    "postcode": "52160",
    "districtCode": "5208",
    "districtNameTh": "เถิน",
    "districtNameEn": "Thoen",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520804",
    "subDistrictNameTh": "แม่มอก",
    "subDistrictNameEn": "Mae Mok",
    "postcode": "52160",
    "districtCode": "5208",
    "districtNameTh": "เถิน",
    "districtNameEn": "Thoen",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520805",
    "subDistrictNameTh": "เวียงมอก",
    "subDistrictNameEn": "Wiang Mok",
    "postcode": "52160",
    "districtCode": "5208",
    "districtNameTh": "เถิน",
    "districtNameEn": "Thoen",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520806",
    "subDistrictNameTh": "นาโป่ง",
    "subDistrictNameEn": "Na Pong",
    "postcode": "52160",
    "districtCode": "5208",
    "districtNameTh": "เถิน",
    "districtNameEn": "Thoen",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520807",
    "subDistrictNameTh": "แม่ถอด",
    "subDistrictNameEn": "Mae Thot",
    "postcode": "52160",
    "districtCode": "5208",
    "districtNameTh": "เถิน",
    "districtNameEn": "Thoen",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520808",
    "subDistrictNameTh": "เถินบุรี",
    "subDistrictNameEn": "Thoen Buri",
    "postcode": "52160",
    "districtCode": "5208",
    "districtNameTh": "เถิน",
    "districtNameEn": "Thoen",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520901",
    "subDistrictNameTh": "แม่พริก",
    "subDistrictNameEn": "Mae Phrik",
    "postcode": "52180",
    "districtCode": "5209",
    "districtNameTh": "แม่พริก",
    "districtNameEn": "Mae Phrik",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520902",
    "subDistrictNameTh": "ผาปัง",
    "subDistrictNameEn": "Pha Pang",
    "postcode": "52180",
    "districtCode": "5209",
    "districtNameTh": "แม่พริก",
    "districtNameEn": "Mae Phrik",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520903",
    "subDistrictNameTh": "แม่ปุ",
    "subDistrictNameEn": "Mae Pu",
    "postcode": "52180",
    "districtCode": "5209",
    "districtNameTh": "แม่พริก",
    "districtNameEn": "Mae Phrik",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "520904",
    "subDistrictNameTh": "พระบาทวังตวง",
    "subDistrictNameEn": "Phra Bat Wang Tuang",
    "postcode": "52230",
    "districtCode": "5209",
    "districtNameTh": "แม่พริก",
    "districtNameEn": "Mae Phrik",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "521001",
    "subDistrictNameTh": "แม่ทะ",
    "subDistrictNameEn": "Mae Tha",
    "postcode": "52150",
    "districtCode": "5210",
    "districtNameTh": "แม่ทะ",
    "districtNameEn": "Mae Tha",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "521002",
    "subDistrictNameTh": "นาครัว",
    "subDistrictNameEn": "Na Khrua",
    "postcode": "52150",
    "districtCode": "5210",
    "districtNameTh": "แม่ทะ",
    "districtNameEn": "Mae Tha",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "521003",
    "subDistrictNameTh": "ป่าตัน",
    "subDistrictNameEn": "Pa Tan",
    "postcode": "52150",
    "districtCode": "5210",
    "districtNameTh": "แม่ทะ",
    "districtNameEn": "Mae Tha",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "521004",
    "subDistrictNameTh": "บ้านกิ่ว",
    "subDistrictNameEn": "Ban Kio",
    "postcode": "52150",
    "districtCode": "5210",
    "districtNameTh": "แม่ทะ",
    "districtNameEn": "Mae Tha",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "521005",
    "subDistrictNameTh": "บ้านบอม",
    "subDistrictNameEn": "Ban Bom",
    "postcode": "52150",
    "districtCode": "5210",
    "districtNameTh": "แม่ทะ",
    "districtNameEn": "Mae Tha",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "521006",
    "subDistrictNameTh": "น้ำโจ้",
    "subDistrictNameEn": "Nam Cho",
    "postcode": "52150",
    "districtCode": "5210",
    "districtNameTh": "แม่ทะ",
    "districtNameEn": "Mae Tha",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "521007",
    "subDistrictNameTh": "ดอนไฟ",
    "subDistrictNameEn": "Don Fai",
    "postcode": "52150",
    "districtCode": "5210",
    "districtNameTh": "แม่ทะ",
    "districtNameEn": "Mae Tha",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "521008",
    "subDistrictNameTh": "หัวเสือ",
    "subDistrictNameEn": "Hua Suea",
    "postcode": "52150",
    "districtCode": "5210",
    "districtNameTh": "แม่ทะ",
    "districtNameEn": "Mae Tha",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "521010",
    "subDistrictNameTh": "วังเงิน",
    "subDistrictNameEn": "Wang Ngoen",
    "postcode": "52150",
    "districtCode": "5210",
    "districtNameTh": "แม่ทะ",
    "districtNameEn": "Mae Tha",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "521011",
    "subDistrictNameTh": "สันดอนแก้ว",
    "subDistrictNameEn": "San Don Kaeo",
    "postcode": "52150",
    "districtCode": "5210",
    "districtNameTh": "แม่ทะ",
    "districtNameEn": "Mae Tha",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "521101",
    "subDistrictNameTh": "สบปราบ",
    "subDistrictNameEn": "Sop Prap",
    "postcode": "52170",
    "districtCode": "5211",
    "districtNameTh": "สบปราบ",
    "districtNameEn": "Sop Prap",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "521102",
    "subDistrictNameTh": "สมัย",
    "subDistrictNameEn": "Samai",
    "postcode": "52170",
    "districtCode": "5211",
    "districtNameTh": "สบปราบ",
    "districtNameEn": "Sop Prap",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "521103",
    "subDistrictNameTh": "แม่กัวะ",
    "subDistrictNameEn": "Mae Kua",
    "postcode": "52170",
    "districtCode": "5211",
    "districtNameTh": "สบปราบ",
    "districtNameEn": "Sop Prap",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "521104",
    "subDistrictNameTh": "นายาง",
    "subDistrictNameEn": "Na Yang",
    "postcode": "52170",
    "districtCode": "5211",
    "districtNameTh": "สบปราบ",
    "districtNameEn": "Sop Prap",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "521201",
    "subDistrictNameTh": "ห้างฉัตร",
    "subDistrictNameEn": "Hang Chat",
    "postcode": "52190",
    "districtCode": "5212",
    "districtNameTh": "ห้างฉัตร",
    "districtNameEn": "Hang Chat",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "521202",
    "subDistrictNameTh": "หนองหล่ม",
    "subDistrictNameEn": "Nong Lom",
    "postcode": "52190",
    "districtCode": "5212",
    "districtNameTh": "ห้างฉัตร",
    "districtNameEn": "Hang Chat",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "521203",
    "subDistrictNameTh": "เมืองยาว",
    "subDistrictNameEn": "Mueang Yao",
    "postcode": "52190",
    "districtCode": "5212",
    "districtNameTh": "ห้างฉัตร",
    "districtNameEn": "Hang Chat",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "521204",
    "subDistrictNameTh": "ปงยางคก",
    "subDistrictNameEn": "Pong Yang Khok",
    "postcode": "52190",
    "districtCode": "5212",
    "districtNameTh": "ห้างฉัตร",
    "districtNameEn": "Hang Chat",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "521205",
    "subDistrictNameTh": "เวียงตาล",
    "subDistrictNameEn": "Wiang Tan",
    "postcode": "52190",
    "districtCode": "5212",
    "districtNameTh": "ห้างฉัตร",
    "districtNameEn": "Hang Chat",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "521206",
    "subDistrictNameTh": "แม่สัน",
    "subDistrictNameEn": "Mae San",
    "postcode": "52190",
    "districtCode": "5212",
    "districtNameTh": "ห้างฉัตร",
    "districtNameEn": "Hang Chat",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "521207",
    "subDistrictNameTh": "วอแก้ว",
    "subDistrictNameEn": "Wo Kaeo",
    "postcode": "52190",
    "districtCode": "5212",
    "districtNameTh": "ห้างฉัตร",
    "districtNameEn": "Hang Chat",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "521301",
    "subDistrictNameTh": "เมืองปาน",
    "subDistrictNameEn": "Mueang Pan",
    "postcode": "52240",
    "districtCode": "5213",
    "districtNameTh": "เมืองปาน",
    "districtNameEn": "Mueang Pan",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "521302",
    "subDistrictNameTh": "บ้านขอ",
    "subDistrictNameEn": "Ban Kho",
    "postcode": "52240",
    "districtCode": "5213",
    "districtNameTh": "เมืองปาน",
    "districtNameEn": "Mueang Pan",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "521303",
    "subDistrictNameTh": "ทุ่งกว๋าว",
    "subDistrictNameEn": "Thung Kwao",
    "postcode": "52240",
    "districtCode": "5213",
    "districtNameTh": "เมืองปาน",
    "districtNameEn": "Mueang Pan",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "521304",
    "subDistrictNameTh": "แจ้ซ้อน",
    "subDistrictNameEn": "Chae Son",
    "postcode": "52240",
    "districtCode": "5213",
    "districtNameTh": "เมืองปาน",
    "districtNameEn": "Mueang Pan",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "521305",
    "subDistrictNameTh": "หัวเมือง",
    "subDistrictNameEn": "Hua Mueang",
    "postcode": "52240",
    "districtCode": "5213",
    "districtNameTh": "เมืองปาน",
    "districtNameEn": "Mueang Pan",
    "provinceCode": "52",
    "provinceNameTh": "ลำปาง",
    "provinceNameEn": "Lampang"
},
{
    "subDistrictCode": "530101",
    "subDistrictNameTh": "ท่าอิฐ",
    "subDistrictNameEn": "Tha It",
    "postcode": "53000",
    "districtCode": "5301",
    "districtNameTh": "เมืองอุตรดิตถ์",
    "districtNameEn": "Mueang Uttaradit",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530102",
    "subDistrictNameTh": "ท่าเสา",
    "subDistrictNameEn": "Tha Sao",
    "postcode": "53000",
    "districtCode": "5301",
    "districtNameTh": "เมืองอุตรดิตถ์",
    "districtNameEn": "Mueang Uttaradit",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530103",
    "subDistrictNameTh": "บ้านเกาะ",
    "subDistrictNameEn": "Ban Ko",
    "postcode": "53000",
    "districtCode": "5301",
    "districtNameTh": "เมืองอุตรดิตถ์",
    "districtNameEn": "Mueang Uttaradit",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530104",
    "subDistrictNameTh": "ป่าเซ่า",
    "subDistrictNameEn": "Pa Sao",
    "postcode": "53000",
    "districtCode": "5301",
    "districtNameTh": "เมืองอุตรดิตถ์",
    "districtNameEn": "Mueang Uttaradit",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530105",
    "subDistrictNameTh": "คุ้งตะเภา",
    "subDistrictNameEn": "Khung Taphao",
    "postcode": "53000",
    "districtCode": "5301",
    "districtNameTh": "เมืองอุตรดิตถ์",
    "districtNameEn": "Mueang Uttaradit",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530106",
    "subDistrictNameTh": "วังกะพี้",
    "subDistrictNameEn": "Wang Kaphi",
    "postcode": "53170",
    "districtCode": "5301",
    "districtNameTh": "เมืองอุตรดิตถ์",
    "districtNameEn": "Mueang Uttaradit",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530107",
    "subDistrictNameTh": "หาดกรวด",
    "subDistrictNameEn": "Hat Kruat",
    "postcode": "53000",
    "districtCode": "5301",
    "districtNameTh": "เมืองอุตรดิตถ์",
    "districtNameEn": "Mueang Uttaradit",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530108",
    "subDistrictNameTh": "น้ำริด",
    "subDistrictNameEn": "Nam Rit",
    "postcode": "53000",
    "districtCode": "5301",
    "districtNameTh": "เมืองอุตรดิตถ์",
    "districtNameEn": "Mueang Uttaradit",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530109",
    "subDistrictNameTh": "งิ้วงาม",
    "subDistrictNameEn": "Ngio Ngam",
    "postcode": "53000",
    "districtCode": "5301",
    "districtNameTh": "เมืองอุตรดิตถ์",
    "districtNameEn": "Mueang Uttaradit",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530110",
    "subDistrictNameTh": "บ้านด่านนาขาม",
    "subDistrictNameEn": "Ban Dan Na Kham",
    "postcode": "53000",
    "districtCode": "5301",
    "districtNameTh": "เมืองอุตรดิตถ์",
    "districtNameEn": "Mueang Uttaradit",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530111",
    "subDistrictNameTh": "บ้านด่าน",
    "subDistrictNameEn": "Ban Dan",
    "postcode": "53000",
    "districtCode": "5301",
    "districtNameTh": "เมืองอุตรดิตถ์",
    "districtNameEn": "Mueang Uttaradit",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530112",
    "subDistrictNameTh": "ผาจุก",
    "subDistrictNameEn": "Pha Chuk",
    "postcode": "53000",
    "districtCode": "5301",
    "districtNameTh": "เมืองอุตรดิตถ์",
    "districtNameEn": "Mueang Uttaradit",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530113",
    "subDistrictNameTh": "วังดิน",
    "subDistrictNameEn": "Wang Din",
    "postcode": "53000",
    "districtCode": "5301",
    "districtNameTh": "เมืองอุตรดิตถ์",
    "districtNameEn": "Mueang Uttaradit",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530114",
    "subDistrictNameTh": "แสนตอ",
    "subDistrictNameEn": "Saen To",
    "postcode": "53000",
    "districtCode": "5301",
    "districtNameTh": "เมืองอุตรดิตถ์",
    "districtNameEn": "Mueang Uttaradit",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530115",
    "subDistrictNameTh": "หาดงิ้ว",
    "subDistrictNameEn": "Hat Ngio",
    "postcode": "53000",
    "districtCode": "5301",
    "districtNameTh": "เมืองอุตรดิตถ์",
    "districtNameEn": "Mueang Uttaradit",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530116",
    "subDistrictNameTh": "ขุนฝาง",
    "subDistrictNameEn": "Khun Fang",
    "postcode": "53000",
    "districtCode": "5301",
    "districtNameTh": "เมืองอุตรดิตถ์",
    "districtNameEn": "Mueang Uttaradit",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530117",
    "subDistrictNameTh": "ถ้ำฉลอง",
    "subDistrictNameEn": "Tham Chalong",
    "postcode": "53000",
    "districtCode": "5301",
    "districtNameTh": "เมืองอุตรดิตถ์",
    "districtNameEn": "Mueang Uttaradit",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530201",
    "subDistrictNameTh": "วังแดง",
    "subDistrictNameEn": "Wang Daeng",
    "postcode": "53140",
    "districtCode": "5302",
    "districtNameTh": "ตรอน",
    "districtNameEn": "Tron",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530202",
    "subDistrictNameTh": "บ้านแก่ง",
    "subDistrictNameEn": "Ban Kaeng",
    "postcode": "53140",
    "districtCode": "5302",
    "districtNameTh": "ตรอน",
    "districtNameEn": "Tron",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530203",
    "subDistrictNameTh": "หาดสองแคว",
    "subDistrictNameEn": "Hat Song Khwae",
    "postcode": "53140",
    "districtCode": "5302",
    "districtNameTh": "ตรอน",
    "districtNameEn": "Tron",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530204",
    "subDistrictNameTh": "น้ำอ่าง",
    "subDistrictNameEn": "Nam Ang",
    "postcode": "53140",
    "districtCode": "5302",
    "districtNameTh": "ตรอน",
    "districtNameEn": "Tron",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530205",
    "subDistrictNameTh": "ข่อยสูง",
    "subDistrictNameEn": "Khoi Sung",
    "postcode": "53140",
    "districtCode": "5302",
    "districtNameTh": "ตรอน",
    "districtNameEn": "Tron",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530301",
    "subDistrictNameTh": "ท่าปลา",
    "subDistrictNameEn": "Tha Pla",
    "postcode": "53150",
    "districtCode": "5303",
    "districtNameTh": "ท่าปลา",
    "districtNameEn": "Tha Pla",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530302",
    "subDistrictNameTh": "หาดล้า",
    "subDistrictNameEn": "Hat La",
    "postcode": "53190",
    "districtCode": "5303",
    "districtNameTh": "ท่าปลา",
    "districtNameEn": "Tha Pla",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530303",
    "subDistrictNameTh": "ผาเลือด",
    "subDistrictNameEn": "Pha Lueat",
    "postcode": "53190",
    "districtCode": "5303",
    "districtNameTh": "ท่าปลา",
    "districtNameEn": "Tha Pla",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530304",
    "subDistrictNameTh": "จริม",
    "subDistrictNameEn": "Chalim",
    "postcode": "53150",
    "districtCode": "5303",
    "districtNameTh": "ท่าปลา",
    "districtNameEn": "Tha Pla",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530305",
    "subDistrictNameTh": "น้ำหมัน",
    "subDistrictNameEn": "Nam Man",
    "postcode": "53150",
    "districtCode": "5303",
    "districtNameTh": "ท่าปลา",
    "districtNameEn": "Tha Pla",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530307",
    "subDistrictNameTh": "นางพญา",
    "subDistrictNameEn": "Nang Phaya",
    "postcode": "53150",
    "districtCode": "5303",
    "districtNameTh": "ท่าปลา",
    "districtNameEn": "Tha Pla",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530308",
    "subDistrictNameTh": "ร่วมจิต",
    "subDistrictNameEn": "Ruam Chit",
    "postcode": "53190",
    "districtCode": "5303",
    "districtNameTh": "ท่าปลา",
    "districtNameEn": "Tha Pla",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530401",
    "subDistrictNameTh": "แสนตอ",
    "subDistrictNameEn": "Saen To",
    "postcode": "53110",
    "districtCode": "5304",
    "districtNameTh": "น้ำปาด",
    "districtNameEn": "Nam Pat",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530402",
    "subDistrictNameTh": "บ้านฝาย",
    "subDistrictNameEn": "Ban Fai",
    "postcode": "53110",
    "districtCode": "5304",
    "districtNameTh": "น้ำปาด",
    "districtNameEn": "Nam Pat",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530403",
    "subDistrictNameTh": "เด่นเหล็ก",
    "subDistrictNameEn": "Den Lek",
    "postcode": "53110",
    "districtCode": "5304",
    "districtNameTh": "น้ำปาด",
    "districtNameEn": "Nam Pat",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530404",
    "subDistrictNameTh": "น้ำไคร้",
    "subDistrictNameEn": "Nam Khrai",
    "postcode": "53110",
    "districtCode": "5304",
    "districtNameTh": "น้ำปาด",
    "districtNameEn": "Nam Pat",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530405",
    "subDistrictNameTh": "น้ำไผ่",
    "subDistrictNameEn": "Nam Phai",
    "postcode": "53110",
    "districtCode": "5304",
    "districtNameTh": "น้ำปาด",
    "districtNameEn": "Nam Pat",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530406",
    "subDistrictNameTh": "ห้วยมุ่น",
    "subDistrictNameEn": "Huai Mun",
    "postcode": "53110",
    "districtCode": "5304",
    "districtNameTh": "น้ำปาด",
    "districtNameEn": "Nam Pat",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530407",
    "subDistrictNameTh": "ท่าแฝก",
    "subDistrictNameEn": "Tha Faek",
    "postcode": "53110",
    "districtCode": "5304",
    "districtNameTh": "น้ำปาด",
    "districtNameEn": "Nam Pat",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530501",
    "subDistrictNameTh": "ฟากท่า",
    "subDistrictNameEn": "Fak Tha",
    "postcode": "53160",
    "districtCode": "5305",
    "districtNameTh": "ฟากท่า",
    "districtNameEn": "Fak Tha",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530502",
    "subDistrictNameTh": "สองคอน",
    "subDistrictNameEn": "Song Khon",
    "postcode": "53160",
    "districtCode": "5305",
    "districtNameTh": "ฟากท่า",
    "districtNameEn": "Fak Tha",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530503",
    "subDistrictNameTh": "บ้านเสี้ยว",
    "subDistrictNameEn": "Ban Siao",
    "postcode": "53160",
    "districtCode": "5305",
    "districtNameTh": "ฟากท่า",
    "districtNameEn": "Fak Tha",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530504",
    "subDistrictNameTh": "สองห้อง",
    "subDistrictNameEn": "Song Hong",
    "postcode": "53160",
    "districtCode": "5305",
    "districtNameTh": "ฟากท่า",
    "districtNameEn": "Fak Tha",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530601",
    "subDistrictNameTh": "ม่วงเจ็ดต้น",
    "subDistrictNameEn": "Muang Chet Ton",
    "postcode": "53180",
    "districtCode": "5306",
    "districtNameTh": "บ้านโคก",
    "districtNameEn": "Ban Khok",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530602",
    "subDistrictNameTh": "บ้านโคก",
    "subDistrictNameEn": "Ban Khok",
    "postcode": "53180",
    "districtCode": "5306",
    "districtNameTh": "บ้านโคก",
    "districtNameEn": "Ban Khok",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530603",
    "subDistrictNameTh": "นาขุม",
    "subDistrictNameEn": "Na Khum",
    "postcode": "53180",
    "districtCode": "5306",
    "districtNameTh": "บ้านโคก",
    "districtNameEn": "Ban Khok",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530604",
    "subDistrictNameTh": "บ่อเบี้ย",
    "subDistrictNameEn": "Bo Bia",
    "postcode": "53180",
    "districtCode": "5306",
    "districtNameTh": "บ้านโคก",
    "districtNameEn": "Ban Khok",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530701",
    "subDistrictNameTh": "ในเมือง",
    "subDistrictNameEn": "Nai Mueang",
    "postcode": "53120",
    "districtCode": "5307",
    "districtNameTh": "พิชัย",
    "districtNameEn": "Phichai",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530702",
    "subDistrictNameTh": "บ้านดารา",
    "subDistrictNameEn": "Ban Dara",
    "postcode": "53220",
    "districtCode": "5307",
    "districtNameTh": "พิชัย",
    "districtNameEn": "Phichai",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530703",
    "subDistrictNameTh": "ไร่อ้อย",
    "subDistrictNameEn": "Rai Oi",
    "postcode": "53120",
    "districtCode": "5307",
    "districtNameTh": "พิชัย",
    "districtNameEn": "Phichai",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530704",
    "subDistrictNameTh": "ท่าสัก",
    "subDistrictNameEn": "Tha Sak",
    "postcode": "53220",
    "districtCode": "5307",
    "districtNameTh": "พิชัย",
    "districtNameEn": "Phichai",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530705",
    "subDistrictNameTh": "คอรุม",
    "subDistrictNameEn": "Kho Rum",
    "postcode": "53120",
    "districtCode": "5307",
    "districtNameTh": "พิชัย",
    "districtNameEn": "Phichai",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530706",
    "subDistrictNameTh": "บ้านหม้อ",
    "subDistrictNameEn": "Ban Mo",
    "postcode": "53120",
    "districtCode": "5307",
    "districtNameTh": "พิชัย",
    "districtNameEn": "Phichai",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530707",
    "subDistrictNameTh": "ท่ามะเฟือง",
    "subDistrictNameEn": "Tha Mafueang",
    "postcode": "53120",
    "districtCode": "5307",
    "districtNameTh": "พิชัย",
    "districtNameEn": "Phichai",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530708",
    "subDistrictNameTh": "บ้านโคน",
    "subDistrictNameEn": "Ban Khon",
    "postcode": "53120",
    "districtCode": "5307",
    "districtNameTh": "พิชัย",
    "districtNameEn": "Phichai",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530709",
    "subDistrictNameTh": "พญาแมน",
    "subDistrictNameEn": "Phaya Maen",
    "postcode": "53120",
    "districtCode": "5307",
    "districtNameTh": "พิชัย",
    "districtNameEn": "Phichai",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530710",
    "subDistrictNameTh": "นาอิน",
    "subDistrictNameEn": "Na In",
    "postcode": "53120",
    "districtCode": "5307",
    "districtNameTh": "พิชัย",
    "districtNameEn": "Phichai",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530711",
    "subDistrictNameTh": "นายาง",
    "subDistrictNameEn": "Na Yang",
    "postcode": "53120",
    "districtCode": "5307",
    "districtNameTh": "พิชัย",
    "districtNameEn": "Phichai",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530801",
    "subDistrictNameTh": "ศรีพนมมาศ",
    "subDistrictNameEn": "Si Phanom Mat",
    "postcode": "53130",
    "districtCode": "5308",
    "districtNameTh": "ลับแล",
    "districtNameEn": "Laplae",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530802",
    "subDistrictNameTh": "แม่พูล",
    "subDistrictNameEn": "Mae Phun",
    "postcode": "53130",
    "districtCode": "5308",
    "districtNameTh": "ลับแล",
    "districtNameEn": "Laplae",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530803",
    "subDistrictNameTh": "นานกกก",
    "subDistrictNameEn": "Na Nok Kok",
    "postcode": "53130",
    "districtCode": "5308",
    "districtNameTh": "ลับแล",
    "districtNameEn": "Laplae",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530804",
    "subDistrictNameTh": "ฝายหลวง",
    "subDistrictNameEn": "Fai Luang",
    "postcode": "53130",
    "districtCode": "5308",
    "districtNameTh": "ลับแล",
    "districtNameEn": "Laplae",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530805",
    "subDistrictNameTh": "ชัยจุมพล",
    "subDistrictNameEn": "Chai Chumphon",
    "postcode": "53130",
    "districtCode": "5308",
    "districtNameTh": "ลับแล",
    "districtNameEn": "Laplae",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530806",
    "subDistrictNameTh": "ไผ่ล้อม",
    "subDistrictNameEn": "Phai Lom",
    "postcode": "53210",
    "districtCode": "5308",
    "districtNameTh": "ลับแล",
    "districtNameEn": "Laplae",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530807",
    "subDistrictNameTh": "ทุ่งยั้ง",
    "subDistrictNameEn": "Thung Yang",
    "postcode": "53210",
    "districtCode": "5308",
    "districtNameTh": "ลับแล",
    "districtNameEn": "Laplae",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530808",
    "subDistrictNameTh": "ด่านแม่คำมัน",
    "subDistrictNameEn": "Dan Mae Kham Man",
    "postcode": "53210",
    "districtCode": "5308",
    "districtNameTh": "ลับแล",
    "districtNameEn": "Laplae",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530901",
    "subDistrictNameTh": "ผักขวง",
    "subDistrictNameEn": "Phak Khuang",
    "postcode": "53230",
    "districtCode": "5309",
    "districtNameTh": "ทองแสนขัน",
    "districtNameEn": "Thong Saen Khan",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530902",
    "subDistrictNameTh": "บ่อทอง",
    "subDistrictNameEn": "Bo Thong",
    "postcode": "53230",
    "districtCode": "5309",
    "districtNameTh": "ทองแสนขัน",
    "districtNameEn": "Thong Saen Khan",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530903",
    "subDistrictNameTh": "ป่าคาย",
    "subDistrictNameEn": "Pa Khai",
    "postcode": "53230",
    "districtCode": "5309",
    "districtNameTh": "ทองแสนขัน",
    "districtNameEn": "Thong Saen Khan",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "530904",
    "subDistrictNameTh": "น้ำพี้",
    "subDistrictNameEn": "Nam Phi",
    "postcode": "53230",
    "districtCode": "5309",
    "districtNameTh": "ทองแสนขัน",
    "districtNameEn": "Thong Saen Khan",
    "provinceCode": "53",
    "provinceNameTh": "อุตรดิตถ์",
    "provinceNameEn": "Uttaradit"
},
{
    "subDistrictCode": "540101",
    "subDistrictNameTh": "ในเวียง",
    "subDistrictNameEn": "Nai Wiang",
    "postcode": "54000",
    "districtCode": "5401",
    "districtNameTh": "เมืองแพร่",
    "districtNameEn": "Mueang Phrae",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540102",
    "subDistrictNameTh": "นาจักร",
    "subDistrictNameEn": "Na Chak",
    "postcode": "54000",
    "districtCode": "5401",
    "districtNameTh": "เมืองแพร่",
    "districtNameEn": "Mueang Phrae",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540103",
    "subDistrictNameTh": "น้ำชำ",
    "subDistrictNameEn": "Nam Cham",
    "postcode": "54000",
    "districtCode": "5401",
    "districtNameTh": "เมืองแพร่",
    "districtNameEn": "Mueang Phrae",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540104",
    "subDistrictNameTh": "ป่าแดง",
    "subDistrictNameEn": "Pa Daeng",
    "postcode": "54000",
    "districtCode": "5401",
    "districtNameTh": "เมืองแพร่",
    "districtNameEn": "Mueang Phrae",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540105",
    "subDistrictNameTh": "ทุ่งโฮ้ง",
    "subDistrictNameEn": "Thung Hong",
    "postcode": "54000",
    "districtCode": "5401",
    "districtNameTh": "เมืองแพร่",
    "districtNameEn": "Mueang Phrae",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540106",
    "subDistrictNameTh": "เหมืองหม้อ",
    "subDistrictNameEn": "Mueang Mo",
    "postcode": "54000",
    "districtCode": "5401",
    "districtNameTh": "เมืองแพร่",
    "districtNameEn": "Mueang Phrae",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540107",
    "subDistrictNameTh": "วังธง",
    "subDistrictNameEn": "Wang Thong",
    "postcode": "54000",
    "districtCode": "5401",
    "districtNameTh": "เมืองแพร่",
    "districtNameEn": "Mueang Phrae",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540108",
    "subDistrictNameTh": "แม่หล่าย",
    "subDistrictNameEn": "Mae Lai",
    "postcode": "54000",
    "districtCode": "5401",
    "districtNameTh": "เมืองแพร่",
    "districtNameEn": "Mueang Phrae",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540109",
    "subDistrictNameTh": "ห้วยม้า",
    "subDistrictNameEn": "Huai Ma",
    "postcode": "54000",
    "districtCode": "5401",
    "districtNameTh": "เมืองแพร่",
    "districtNameEn": "Mueang Phrae",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540110",
    "subDistrictNameTh": "ป่าแมต",
    "subDistrictNameEn": "Pa Maet",
    "postcode": "54000",
    "districtCode": "5401",
    "districtNameTh": "เมืองแพร่",
    "districtNameEn": "Mueang Phrae",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540111",
    "subDistrictNameTh": "บ้านถิ่น",
    "subDistrictNameEn": "Ban Thin",
    "postcode": "54000",
    "districtCode": "5401",
    "districtNameTh": "เมืองแพร่",
    "districtNameEn": "Mueang Phrae",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540112",
    "subDistrictNameTh": "สวนเขื่อน",
    "subDistrictNameEn": "Suan Khuean",
    "postcode": "54000",
    "districtCode": "5401",
    "districtNameTh": "เมืองแพร่",
    "districtNameEn": "Mueang Phrae",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540113",
    "subDistrictNameTh": "วังหงส์",
    "subDistrictNameEn": "Wang Hong",
    "postcode": "54000",
    "districtCode": "5401",
    "districtNameTh": "เมืองแพร่",
    "districtNameEn": "Mueang Phrae",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540114",
    "subDistrictNameTh": "แม่คำมี",
    "subDistrictNameEn": "Mae Kham Mi",
    "postcode": "54000",
    "districtCode": "5401",
    "districtNameTh": "เมืองแพร่",
    "districtNameEn": "Mueang Phrae",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540115",
    "subDistrictNameTh": "ทุ่งกวาว",
    "subDistrictNameEn": "Thung Kwao",
    "postcode": "54000",
    "districtCode": "5401",
    "districtNameTh": "เมืองแพร่",
    "districtNameEn": "Mueang Phrae",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540116",
    "subDistrictNameTh": "ท่าข้าม",
    "subDistrictNameEn": "Tha Kham",
    "postcode": "54000",
    "districtCode": "5401",
    "districtNameTh": "เมืองแพร่",
    "districtNameEn": "Mueang Phrae",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540117",
    "subDistrictNameTh": "แม่ยม",
    "subDistrictNameEn": "Mae Yom",
    "postcode": "54000",
    "districtCode": "5401",
    "districtNameTh": "เมืองแพร่",
    "districtNameEn": "Mueang Phrae",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540118",
    "subDistrictNameTh": "ช่อแฮ",
    "subDistrictNameEn": "Cho Hae",
    "postcode": "54000",
    "districtCode": "5401",
    "districtNameTh": "เมืองแพร่",
    "districtNameEn": "Mueang Phrae",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540119",
    "subDistrictNameTh": "ร่องฟอง",
    "subDistrictNameEn": "Rong Fong",
    "postcode": "54000",
    "districtCode": "5401",
    "districtNameTh": "เมืองแพร่",
    "districtNameEn": "Mueang Phrae",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540120",
    "subDistrictNameTh": "กาญจนา",
    "subDistrictNameEn": "Kanchana",
    "postcode": "54000",
    "districtCode": "5401",
    "districtNameTh": "เมืองแพร่",
    "districtNameEn": "Mueang Phrae",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540201",
    "subDistrictNameTh": "ร้องกวาง",
    "subDistrictNameEn": "Rong Kwang",
    "postcode": "54140",
    "districtCode": "5402",
    "districtNameTh": "ร้องกวาง",
    "districtNameEn": "Rong Kwang",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540204",
    "subDistrictNameTh": "ร้องเข็ม",
    "subDistrictNameEn": "Rong Khem",
    "postcode": "54140",
    "districtCode": "5402",
    "districtNameTh": "ร้องกวาง",
    "districtNameEn": "Rong Kwang",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540205",
    "subDistrictNameTh": "น้ำเลา",
    "subDistrictNameEn": "Nam Lao",
    "postcode": "54140",
    "districtCode": "5402",
    "districtNameTh": "ร้องกวาง",
    "districtNameEn": "Rong Kwang",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540206",
    "subDistrictNameTh": "บ้านเวียง",
    "subDistrictNameEn": "Ban Wiang",
    "postcode": "54140",
    "districtCode": "5402",
    "districtNameTh": "ร้องกวาง",
    "districtNameEn": "Rong Kwang",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540207",
    "subDistrictNameTh": "ทุ่งศรี",
    "subDistrictNameEn": "Thung Si",
    "postcode": "54140",
    "districtCode": "5402",
    "districtNameTh": "ร้องกวาง",
    "districtNameEn": "Rong Kwang",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540208",
    "subDistrictNameTh": "แม่ยางตาล",
    "subDistrictNameEn": "Mae Yang Tan",
    "postcode": "54140",
    "districtCode": "5402",
    "districtNameTh": "ร้องกวาง",
    "districtNameEn": "Rong Kwang",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540209",
    "subDistrictNameTh": "แม่ยางฮ่อ",
    "subDistrictNameEn": "Mae Yang Ho",
    "postcode": "54140",
    "districtCode": "5402",
    "districtNameTh": "ร้องกวาง",
    "districtNameEn": "Rong Kwang",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540210",
    "subDistrictNameTh": "ไผ่โทน",
    "subDistrictNameEn": "Phai Thon",
    "postcode": "54140",
    "districtCode": "5402",
    "districtNameTh": "ร้องกวาง",
    "districtNameEn": "Rong Kwang",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540213",
    "subDistrictNameTh": "ห้วยโรง",
    "subDistrictNameEn": "Huai Rong",
    "postcode": "54140",
    "districtCode": "5402",
    "districtNameTh": "ร้องกวาง",
    "districtNameEn": "Rong Kwang",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540214",
    "subDistrictNameTh": "แม่ทราย",
    "subDistrictNameEn": "Mae Sai",
    "postcode": "54140",
    "districtCode": "5402",
    "districtNameTh": "ร้องกวาง",
    "districtNameEn": "Rong Kwang",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540215",
    "subDistrictNameTh": "แม่ยางร้อง",
    "subDistrictNameEn": "Mae Yang Rong",
    "postcode": "54140",
    "districtCode": "5402",
    "districtNameTh": "ร้องกวาง",
    "districtNameEn": "Rong Kwang",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540301",
    "subDistrictNameTh": "ห้วยอ้อ",
    "subDistrictNameEn": "Huai O",
    "postcode": "54150",
    "districtCode": "5403",
    "districtNameTh": "ลอง",
    "districtNameEn": "Long",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540302",
    "subDistrictNameTh": "บ้านปิน",
    "subDistrictNameEn": "Ban Pin",
    "postcode": "54150",
    "districtCode": "5403",
    "districtNameTh": "ลอง",
    "districtNameEn": "Long",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540303",
    "subDistrictNameTh": "ต้าผามอก",
    "subDistrictNameEn": "Ta Pha Mok",
    "postcode": "54150",
    "districtCode": "5403",
    "districtNameTh": "ลอง",
    "districtNameEn": "Long",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540304",
    "subDistrictNameTh": "เวียงต้า",
    "subDistrictNameEn": "Wiang Ta",
    "postcode": "54150",
    "districtCode": "5403",
    "districtNameTh": "ลอง",
    "districtNameEn": "Long",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540305",
    "subDistrictNameTh": "ปากกาง",
    "subDistrictNameEn": "Pak Kang",
    "postcode": "54150",
    "districtCode": "5403",
    "districtNameTh": "ลอง",
    "districtNameEn": "Long",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540306",
    "subDistrictNameTh": "หัวทุ่ง",
    "subDistrictNameEn": "Hua Thung",
    "postcode": "54150",
    "districtCode": "5403",
    "districtNameTh": "ลอง",
    "districtNameEn": "Long",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540307",
    "subDistrictNameTh": "ทุ่งแล้ง",
    "subDistrictNameEn": "Thung Laeng",
    "postcode": "54150",
    "districtCode": "5403",
    "districtNameTh": "ลอง",
    "districtNameEn": "Long",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540308",
    "subDistrictNameTh": "บ่อเหล็กลอง",
    "subDistrictNameEn": "Bo Lek Long",
    "postcode": "54150",
    "districtCode": "5403",
    "districtNameTh": "ลอง",
    "districtNameEn": "Long",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540309",
    "subDistrictNameTh": "แม่ปาน",
    "subDistrictNameEn": "Mae Pan",
    "postcode": "54150",
    "districtCode": "5403",
    "districtNameTh": "ลอง",
    "districtNameEn": "Long",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540401",
    "subDistrictNameTh": "สูงเม่น",
    "subDistrictNameEn": "Sung Men",
    "postcode": "54130",
    "districtCode": "5404",
    "districtNameTh": "สูงเม่น",
    "districtNameEn": "Sung Men",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540402",
    "subDistrictNameTh": "น้ำชำ",
    "subDistrictNameEn": "Nam Cham",
    "postcode": "54130",
    "districtCode": "5404",
    "districtNameTh": "สูงเม่น",
    "districtNameEn": "Sung Men",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540403",
    "subDistrictNameTh": "หัวฝาย",
    "subDistrictNameEn": "Hua Fai",
    "postcode": "54130",
    "districtCode": "5404",
    "districtNameTh": "สูงเม่น",
    "districtNameEn": "Sung Men",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540404",
    "subDistrictNameTh": "ดอนมูล",
    "subDistrictNameEn": "Don Mun",
    "postcode": "54130",
    "districtCode": "5404",
    "districtNameTh": "สูงเม่น",
    "districtNameEn": "Sung Men",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540405",
    "subDistrictNameTh": "บ้านเหล่า",
    "subDistrictNameEn": "Ban Lao",
    "postcode": "54130",
    "districtCode": "5404",
    "districtNameTh": "สูงเม่น",
    "districtNameEn": "Sung Men",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540406",
    "subDistrictNameTh": "บ้านกวาง",
    "subDistrictNameEn": "Ban Kwang",
    "postcode": "54130",
    "districtCode": "5404",
    "districtNameTh": "สูงเม่น",
    "districtNameEn": "Sung Men",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540407",
    "subDistrictNameTh": "บ้านปง",
    "subDistrictNameEn": "Ban Pong",
    "postcode": "54130",
    "districtCode": "5404",
    "districtNameTh": "สูงเม่น",
    "districtNameEn": "Sung Men",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540408",
    "subDistrictNameTh": "บ้านกาศ",
    "subDistrictNameEn": "Ban Kat",
    "postcode": "54130",
    "districtCode": "5404",
    "districtNameTh": "สูงเม่น",
    "districtNameEn": "Sung Men",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540409",
    "subDistrictNameTh": "ร่องกาศ",
    "subDistrictNameEn": "Rong Kat",
    "postcode": "54130",
    "districtCode": "5404",
    "districtNameTh": "สูงเม่น",
    "districtNameEn": "Sung Men",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540410",
    "subDistrictNameTh": "สบสาย",
    "subDistrictNameEn": "Sop Sai",
    "postcode": "54130",
    "districtCode": "5404",
    "districtNameTh": "สูงเม่น",
    "districtNameEn": "Sung Men",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540411",
    "subDistrictNameTh": "เวียงทอง",
    "subDistrictNameEn": "Wiang Thong",
    "postcode": "54000",
    "districtCode": "5404",
    "districtNameTh": "สูงเม่น",
    "districtNameEn": "Sung Men",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540412",
    "subDistrictNameTh": "พระหลวง",
    "subDistrictNameEn": "Phra Luang",
    "postcode": "54130",
    "districtCode": "5404",
    "districtNameTh": "สูงเม่น",
    "districtNameEn": "Sung Men",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540501",
    "subDistrictNameTh": "เด่นชัย",
    "subDistrictNameEn": "Den Chai",
    "postcode": "54110",
    "districtCode": "5405",
    "districtNameTh": "เด่นชัย",
    "districtNameEn": "Den Chai",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540502",
    "subDistrictNameTh": "แม่จั๊วะ",
    "subDistrictNameEn": "Mae Chua",
    "postcode": "54110",
    "districtCode": "5405",
    "districtNameTh": "เด่นชัย",
    "districtNameEn": "Den Chai",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540503",
    "subDistrictNameTh": "ไทรย้อย",
    "subDistrictNameEn": "Sai Yoi",
    "postcode": "54110",
    "districtCode": "5405",
    "districtNameTh": "เด่นชัย",
    "districtNameEn": "Den Chai",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540504",
    "subDistrictNameTh": "ห้วยไร่",
    "subDistrictNameEn": "Huai Rai",
    "postcode": "54110",
    "districtCode": "5405",
    "districtNameTh": "เด่นชัย",
    "districtNameEn": "Den Chai",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540505",
    "subDistrictNameTh": "ปงป่าหวาย",
    "subDistrictNameEn": "Pong Pa Wai",
    "postcode": "54110",
    "districtCode": "5405",
    "districtNameTh": "เด่นชัย",
    "districtNameEn": "Den Chai",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540601",
    "subDistrictNameTh": "บ้านหนุน",
    "subDistrictNameEn": "Ban Nun",
    "postcode": "54120",
    "districtCode": "5406",
    "districtNameTh": "สอง",
    "districtNameEn": "Song",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540602",
    "subDistrictNameTh": "บ้านกลาง",
    "subDistrictNameEn": "Ban Klang",
    "postcode": "54120",
    "districtCode": "5406",
    "districtNameTh": "สอง",
    "districtNameEn": "Song",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540603",
    "subDistrictNameTh": "ห้วยหม้าย",
    "subDistrictNameEn": "Huai Mai",
    "postcode": "54120",
    "districtCode": "5406",
    "districtNameTh": "สอง",
    "districtNameEn": "Song",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540604",
    "subDistrictNameTh": "เตาปูน",
    "subDistrictNameEn": "Tao Pun",
    "postcode": "54120",
    "districtCode": "5406",
    "districtNameTh": "สอง",
    "districtNameEn": "Song",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540605",
    "subDistrictNameTh": "หัวเมือง",
    "subDistrictNameEn": "Hua Mueang",
    "postcode": "54120",
    "districtCode": "5406",
    "districtNameTh": "สอง",
    "districtNameEn": "Song",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540606",
    "subDistrictNameTh": "สะเอียบ",
    "subDistrictNameEn": "Sa-iap",
    "postcode": "54120",
    "districtCode": "5406",
    "districtNameTh": "สอง",
    "districtNameEn": "Song",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540607",
    "subDistrictNameTh": "แดนชุมพล",
    "subDistrictNameEn": "Daen Chumphon",
    "postcode": "54120",
    "districtCode": "5406",
    "districtNameTh": "สอง",
    "districtNameEn": "Song",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540608",
    "subDistrictNameTh": "ทุ่งน้าว",
    "subDistrictNameEn": "Thung Nao",
    "postcode": "54120",
    "districtCode": "5406",
    "districtNameTh": "สอง",
    "districtNameEn": "Song",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540701",
    "subDistrictNameTh": "วังชิ้น",
    "subDistrictNameEn": "Wang Chin",
    "postcode": "54160",
    "districtCode": "5407",
    "districtNameTh": "วังชิ้น",
    "districtNameEn": "Wang Chin",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540702",
    "subDistrictNameTh": "สรอย",
    "subDistrictNameEn": "Soi",
    "postcode": "54160",
    "districtCode": "5407",
    "districtNameTh": "วังชิ้น",
    "districtNameEn": "Wang Chin",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540703",
    "subDistrictNameTh": "แม่ป้าก",
    "subDistrictNameEn": "Mae Pak",
    "postcode": "54160",
    "districtCode": "5407",
    "districtNameTh": "วังชิ้น",
    "districtNameEn": "Wang Chin",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540704",
    "subDistrictNameTh": "นาพูน",
    "subDistrictNameEn": "Na Phun",
    "postcode": "54160",
    "districtCode": "5407",
    "districtNameTh": "วังชิ้น",
    "districtNameEn": "Wang Chin",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540705",
    "subDistrictNameTh": "แม่พุง",
    "subDistrictNameEn": "Mae Phung",
    "postcode": "54160",
    "districtCode": "5407",
    "districtNameTh": "วังชิ้น",
    "districtNameEn": "Wang Chin",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540706",
    "subDistrictNameTh": "ป่าสัก",
    "subDistrictNameEn": "Pa Sak",
    "postcode": "54160",
    "districtCode": "5407",
    "districtNameTh": "วังชิ้น",
    "districtNameEn": "Wang Chin",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540707",
    "subDistrictNameTh": "แม่เกิ๋ง",
    "subDistrictNameEn": "Mae Koeng",
    "postcode": "54160",
    "districtCode": "5407",
    "districtNameTh": "วังชิ้น",
    "districtNameEn": "Wang Chin",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540801",
    "subDistrictNameTh": "แม่คำมี",
    "subDistrictNameEn": "Mae Kham Mi",
    "postcode": "54170",
    "districtCode": "5408",
    "districtNameTh": "หนองม่วงไข่",
    "districtNameEn": "Nong Muang Khai",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540802",
    "subDistrictNameTh": "หนองม่วงไข่",
    "subDistrictNameEn": "Nong Muang Khai",
    "postcode": "54170",
    "districtCode": "5408",
    "districtNameTh": "หนองม่วงไข่",
    "districtNameEn": "Nong Muang Khai",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540803",
    "subDistrictNameTh": "น้ำรัด",
    "subDistrictNameEn": "Nam Rat",
    "postcode": "54170",
    "districtCode": "5408",
    "districtNameTh": "หนองม่วงไข่",
    "districtNameEn": "Nong Muang Khai",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540804",
    "subDistrictNameTh": "วังหลวง",
    "subDistrictNameEn": "wang Luang",
    "postcode": "54170",
    "districtCode": "5408",
    "districtNameTh": "หนองม่วงไข่",
    "districtNameEn": "Nong Muang Khai",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540805",
    "subDistrictNameTh": "ตำหนักธรรม",
    "subDistrictNameEn": "Tamnak Tham",
    "postcode": "54170",
    "districtCode": "5408",
    "districtNameTh": "หนองม่วงไข่",
    "districtNameEn": "Nong Muang Khai",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "540806",
    "subDistrictNameTh": "ทุ่งแค้ว",
    "subDistrictNameEn": "Thung Khaeo",
    "postcode": "54170",
    "districtCode": "5408",
    "districtNameTh": "หนองม่วงไข่",
    "districtNameEn": "Nong Muang Khai",
    "provinceCode": "54",
    "provinceNameTh": "แพร่",
    "provinceNameEn": "Phrae"
},
{
    "subDistrictCode": "550101",
    "subDistrictNameTh": "ในเวียง",
    "subDistrictNameEn": "Nai Wiang",
    "postcode": "55000",
    "districtCode": "5501",
    "districtNameTh": "เมืองน่าน",
    "districtNameEn": "Mueang Nan",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550102",
    "subDistrictNameTh": "บ่อ",
    "subDistrictNameEn": "Bo",
    "postcode": "55000",
    "districtCode": "5501",
    "districtNameTh": "เมืองน่าน",
    "districtNameEn": "Mueang Nan",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550103",
    "subDistrictNameTh": "ผาสิงห์",
    "subDistrictNameEn": "Pha Sing",
    "postcode": "55000",
    "districtCode": "5501",
    "districtNameTh": "เมืองน่าน",
    "districtNameEn": "Mueang Nan",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550104",
    "subDistrictNameTh": "ไชยสถาน",
    "subDistrictNameEn": "Chai Sathan",
    "postcode": "55000",
    "districtCode": "5501",
    "districtNameTh": "เมืองน่าน",
    "districtNameEn": "Mueang Nan",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550105",
    "subDistrictNameTh": "ถืมตอง",
    "subDistrictNameEn": "Thuem Tong",
    "postcode": "55000",
    "districtCode": "5501",
    "districtNameTh": "เมืองน่าน",
    "districtNameEn": "Mueang Nan",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550106",
    "subDistrictNameTh": "เรือง",
    "subDistrictNameEn": "Rueang",
    "postcode": "55000",
    "districtCode": "5501",
    "districtNameTh": "เมืองน่าน",
    "districtNameEn": "Mueang Nan",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550107",
    "subDistrictNameTh": "นาซาว",
    "subDistrictNameEn": "Na Sao",
    "postcode": "55000",
    "districtCode": "5501",
    "districtNameTh": "เมืองน่าน",
    "districtNameEn": "Mueang Nan",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550108",
    "subDistrictNameTh": "ดู่ใต้",
    "subDistrictNameEn": "Du Tai",
    "postcode": "55000",
    "districtCode": "5501",
    "districtNameTh": "เมืองน่าน",
    "districtNameEn": "Mueang Nan",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550109",
    "subDistrictNameTh": "กองควาย",
    "subDistrictNameEn": "Kong Khwai",
    "postcode": "55000",
    "districtCode": "5501",
    "districtNameTh": "เมืองน่าน",
    "districtNameEn": "Mueang Nan",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550116",
    "subDistrictNameTh": "บ่อสวก",
    "subDistrictNameEn": "Bo Suak",
    "postcode": "55000",
    "districtCode": "5501",
    "districtNameTh": "เมืองน่าน",
    "districtNameEn": "Mueang Nan",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550117",
    "subDistrictNameTh": "สะเนียน",
    "subDistrictNameEn": "Sanian",
    "postcode": "55000",
    "districtCode": "5501",
    "districtNameTh": "เมืองน่าน",
    "districtNameEn": "Mueang Nan",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550202",
    "subDistrictNameTh": "หนองแดง",
    "subDistrictNameEn": "Nong Daeng",
    "postcode": "55170",
    "districtCode": "5502",
    "districtNameTh": "แม่จริม",
    "districtNameEn": "Mae Charim",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550203",
    "subDistrictNameTh": "หมอเมือง",
    "subDistrictNameEn": "Mo Mueang",
    "postcode": "55170",
    "districtCode": "5502",
    "districtNameTh": "แม่จริม",
    "districtNameEn": "Mae Charim",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550204",
    "subDistrictNameTh": "น้ำพาง",
    "subDistrictNameEn": "Nam Phang",
    "postcode": "55170",
    "districtCode": "5502",
    "districtNameTh": "แม่จริม",
    "districtNameEn": "Mae Charim",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550205",
    "subDistrictNameTh": "น้ำปาย",
    "subDistrictNameEn": "Nam Pai",
    "postcode": "55170",
    "districtCode": "5502",
    "districtNameTh": "แม่จริม",
    "districtNameEn": "Mae Charim",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550206",
    "subDistrictNameTh": "แม่จริม",
    "subDistrictNameEn": "Mae Charim",
    "postcode": "55170",
    "districtCode": "5502",
    "districtNameTh": "แม่จริม",
    "districtNameEn": "Mae Charim",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550301",
    "subDistrictNameTh": "บ้านฟ้า",
    "subDistrictNameEn": "Ban Fa",
    "postcode": "55190",
    "districtCode": "5503",
    "districtNameTh": "บ้านหลวง",
    "districtNameEn": "Ban Luang",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550302",
    "subDistrictNameTh": "ป่าคาหลวง",
    "subDistrictNameEn": "Pa Kha Luang",
    "postcode": "55190",
    "districtCode": "5503",
    "districtNameTh": "บ้านหลวง",
    "districtNameEn": "Ban Luang",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550303",
    "subDistrictNameTh": "สวด",
    "subDistrictNameEn": "Suat",
    "postcode": "55190",
    "districtCode": "5503",
    "districtNameTh": "บ้านหลวง",
    "districtNameEn": "Ban Luang",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550304",
    "subDistrictNameTh": "บ้านพี้",
    "subDistrictNameEn": "Ban Phi",
    "postcode": "55190",
    "districtCode": "5503",
    "districtNameTh": "บ้านหลวง",
    "districtNameEn": "Ban Luang",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550401",
    "subDistrictNameTh": "นาน้อย",
    "subDistrictNameEn": "Na Noi",
    "postcode": "55150",
    "districtCode": "5504",
    "districtNameTh": "นาน้อย",
    "districtNameEn": "Na Noi",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550402",
    "subDistrictNameTh": "เชียงของ",
    "subDistrictNameEn": "Chiang Khong",
    "postcode": "55150",
    "districtCode": "5504",
    "districtNameTh": "นาน้อย",
    "districtNameEn": "Na Noi",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550403",
    "subDistrictNameTh": "ศรีษะเกษ",
    "subDistrictNameEn": "Sisa Ket",
    "postcode": "55150",
    "districtCode": "5504",
    "districtNameTh": "นาน้อย",
    "districtNameEn": "Na Noi",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550404",
    "subDistrictNameTh": "สถาน",
    "subDistrictNameEn": "Sathan",
    "postcode": "55150",
    "districtCode": "5504",
    "districtNameTh": "นาน้อย",
    "districtNameEn": "Na Noi",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550405",
    "subDistrictNameTh": "สันทะ",
    "subDistrictNameEn": "Santha",
    "postcode": "55150",
    "districtCode": "5504",
    "districtNameTh": "นาน้อย",
    "districtNameEn": "Na Noi",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550406",
    "subDistrictNameTh": "บัวใหญ่",
    "subDistrictNameEn": "Bua Yai",
    "postcode": "55150",
    "districtCode": "5504",
    "districtNameTh": "นาน้อย",
    "districtNameEn": "Na Noi",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550407",
    "subDistrictNameTh": "น้ำตก",
    "subDistrictNameEn": "Nam Tok",
    "postcode": "55150",
    "districtCode": "5504",
    "districtNameTh": "นาน้อย",
    "districtNameEn": "Na Noi",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550501",
    "subDistrictNameTh": "ปัว",
    "subDistrictNameEn": "Pua",
    "postcode": "55120",
    "districtCode": "5505",
    "districtNameTh": "ปัว",
    "districtNameEn": "Pua",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550502",
    "subDistrictNameTh": "แงง",
    "subDistrictNameEn": "Ngaeng",
    "postcode": "55120",
    "districtCode": "5505",
    "districtNameTh": "ปัว",
    "districtNameEn": "Pua",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550503",
    "subDistrictNameTh": "สถาน",
    "subDistrictNameEn": "Sathan",
    "postcode": "55120",
    "districtCode": "5505",
    "districtNameTh": "ปัว",
    "districtNameEn": "Pua",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550504",
    "subDistrictNameTh": "ศิลาแลง",
    "subDistrictNameEn": "Sila Laeng",
    "postcode": "55120",
    "districtCode": "5505",
    "districtNameTh": "ปัว",
    "districtNameEn": "Pua",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550505",
    "subDistrictNameTh": "ศิลาเพชร",
    "subDistrictNameEn": "Sila Phet",
    "postcode": "55120",
    "districtCode": "5505",
    "districtNameTh": "ปัว",
    "districtNameEn": "Pua",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550506",
    "subDistrictNameTh": "อวน",
    "subDistrictNameEn": "Uan",
    "postcode": "55120",
    "districtCode": "5505",
    "districtNameTh": "ปัว",
    "districtNameEn": "Pua",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550509",
    "subDistrictNameTh": "ไชยวัฒนา",
    "subDistrictNameEn": "Chai Watthana",
    "postcode": "55120",
    "districtCode": "5505",
    "districtNameTh": "ปัว",
    "districtNameEn": "Pua",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550510",
    "subDistrictNameTh": "เจดีย์ชัย",
    "subDistrictNameEn": "Chedi Chai",
    "postcode": "55120",
    "districtCode": "5505",
    "districtNameTh": "ปัว",
    "districtNameEn": "Pua",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550511",
    "subDistrictNameTh": "ภูคา",
    "subDistrictNameEn": "Phu Kha",
    "postcode": "55120",
    "districtCode": "5505",
    "districtNameTh": "ปัว",
    "districtNameEn": "Pua",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550512",
    "subDistrictNameTh": "สกาด",
    "subDistrictNameEn": "Sakat",
    "postcode": "55120",
    "districtCode": "5505",
    "districtNameTh": "ปัว",
    "districtNameEn": "Pua",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550513",
    "subDistrictNameTh": "ป่ากลาง",
    "subDistrictNameEn": "Pa Klang",
    "postcode": "55120",
    "districtCode": "5505",
    "districtNameTh": "ปัว",
    "districtNameEn": "Pua",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550514",
    "subDistrictNameTh": "วรนคร",
    "subDistrictNameEn": "Woranakhon",
    "postcode": "55120",
    "districtCode": "5505",
    "districtNameTh": "ปัว",
    "districtNameEn": "Pua",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550601",
    "subDistrictNameTh": "ริม",
    "subDistrictNameEn": "Rim",
    "postcode": "55140",
    "districtCode": "5506",
    "districtNameTh": "ท่าวังผา",
    "districtNameEn": "Tha Wang Pha",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550602",
    "subDistrictNameTh": "ป่าคา",
    "subDistrictNameEn": "Pa Kha",
    "postcode": "55140",
    "districtCode": "5506",
    "districtNameTh": "ท่าวังผา",
    "districtNameEn": "Tha Wang Pha",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550603",
    "subDistrictNameTh": "ผาตอ",
    "subDistrictNameEn": "Pha To",
    "postcode": "55140",
    "districtCode": "5506",
    "districtNameTh": "ท่าวังผา",
    "districtNameEn": "Tha Wang Pha",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550604",
    "subDistrictNameTh": "ยม",
    "subDistrictNameEn": "Yom",
    "postcode": "55140",
    "districtCode": "5506",
    "districtNameTh": "ท่าวังผา",
    "districtNameEn": "Tha Wang Pha",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550605",
    "subDistrictNameTh": "ตาลชุม",
    "subDistrictNameEn": "Tan Chum",
    "postcode": "55140",
    "districtCode": "5506",
    "districtNameTh": "ท่าวังผา",
    "districtNameEn": "Tha Wang Pha",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550606",
    "subDistrictNameTh": "ศรีภูมิ",
    "subDistrictNameEn": "Si Phum",
    "postcode": "55140",
    "districtCode": "5506",
    "districtNameTh": "ท่าวังผา",
    "districtNameEn": "Tha Wang Pha",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550607",
    "subDistrictNameTh": "จอมพระ",
    "subDistrictNameEn": "Chom Phra",
    "postcode": "55140",
    "districtCode": "5506",
    "districtNameTh": "ท่าวังผา",
    "districtNameEn": "Tha Wang Pha",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550608",
    "subDistrictNameTh": "แสนทอง",
    "subDistrictNameEn": "Saen Thong",
    "postcode": "55140",
    "districtCode": "5506",
    "districtNameTh": "ท่าวังผา",
    "districtNameEn": "Tha Wang Pha",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550609",
    "subDistrictNameTh": "ท่าวังผา",
    "subDistrictNameEn": "Tha Wang Pha",
    "postcode": "55140",
    "districtCode": "5506",
    "districtNameTh": "ท่าวังผา",
    "districtNameEn": "Tha Wang Pha",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550610",
    "subDistrictNameTh": "ผาทอง",
    "subDistrictNameEn": "Pha Thong",
    "postcode": "55140",
    "districtCode": "5506",
    "districtNameTh": "ท่าวังผา",
    "districtNameEn": "Tha Wang Pha",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550701",
    "subDistrictNameTh": "กลางเวียง",
    "subDistrictNameEn": "Klang Wiang",
    "postcode": "55110",
    "districtCode": "5507",
    "districtNameTh": "เวียงสา",
    "districtNameEn": "Wiang Sa",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550702",
    "subDistrictNameTh": "ขึ่ง",
    "subDistrictNameEn": "Khueng",
    "postcode": "55110",
    "districtCode": "5507",
    "districtNameTh": "เวียงสา",
    "districtNameEn": "Wiang Sa",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550703",
    "subDistrictNameTh": "ไหล่น่าน",
    "subDistrictNameEn": "Lai Nan",
    "postcode": "55110",
    "districtCode": "5507",
    "districtNameTh": "เวียงสา",
    "districtNameEn": "Wiang Sa",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550704",
    "subDistrictNameTh": "ตาลชุม",
    "subDistrictNameEn": "Tan Chum",
    "postcode": "55110",
    "districtCode": "5507",
    "districtNameTh": "เวียงสา",
    "districtNameEn": "Wiang Sa",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550705",
    "subDistrictNameTh": "นาเหลือง",
    "subDistrictNameEn": "Na Lueang",
    "postcode": "55110",
    "districtCode": "5507",
    "districtNameTh": "เวียงสา",
    "districtNameEn": "Wiang Sa",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550706",
    "subDistrictNameTh": "ส้าน",
    "subDistrictNameEn": "San",
    "postcode": "55110",
    "districtCode": "5507",
    "districtNameTh": "เวียงสา",
    "districtNameEn": "Wiang Sa",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550707",
    "subDistrictNameTh": "น้ำมวบ",
    "subDistrictNameEn": "Nam Muap",
    "postcode": "55110",
    "districtCode": "5507",
    "districtNameTh": "เวียงสา",
    "districtNameEn": "Wiang Sa",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550708",
    "subDistrictNameTh": "น้ำปั้ว",
    "subDistrictNameEn": "Nam Pua",
    "postcode": "55110",
    "districtCode": "5507",
    "districtNameTh": "เวียงสา",
    "districtNameEn": "Wiang Sa",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550709",
    "subDistrictNameTh": "ยาบหัวนา",
    "subDistrictNameEn": "Yap Hua Na",
    "postcode": "55110",
    "districtCode": "5507",
    "districtNameTh": "เวียงสา",
    "districtNameEn": "Wiang Sa",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550710",
    "subDistrictNameTh": "ปงสนุก",
    "subDistrictNameEn": "Pong Sanuk",
    "postcode": "55110",
    "districtCode": "5507",
    "districtNameTh": "เวียงสา",
    "districtNameEn": "Wiang Sa",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550711",
    "subDistrictNameTh": "อ่ายนาไลย",
    "subDistrictNameEn": "Ai Na Lai",
    "postcode": "55110",
    "districtCode": "5507",
    "districtNameTh": "เวียงสา",
    "districtNameEn": "Wiang Sa",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550712",
    "subDistrictNameTh": "ส้านนาหนองใหม่",
    "subDistrictNameEn": "San Na Nong Mai",
    "postcode": "55110",
    "districtCode": "5507",
    "districtNameTh": "เวียงสา",
    "districtNameEn": "Wiang Sa",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550713",
    "subDistrictNameTh": "แม่ขะนิง",
    "subDistrictNameEn": "Mae Khaning",
    "postcode": "55110",
    "districtCode": "5507",
    "districtNameTh": "เวียงสา",
    "districtNameEn": "Wiang Sa",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550714",
    "subDistrictNameTh": "แม่สาคร",
    "subDistrictNameEn": "Mae Sakhon",
    "postcode": "55110",
    "districtCode": "5507",
    "districtNameTh": "เวียงสา",
    "districtNameEn": "Wiang Sa",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550715",
    "subDistrictNameTh": "จอมจันทร์",
    "subDistrictNameEn": "Chom Chan",
    "postcode": "55110",
    "districtCode": "5507",
    "districtNameTh": "เวียงสา",
    "districtNameEn": "Wiang Sa",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550716",
    "subDistrictNameTh": "แม่สา",
    "subDistrictNameEn": "Mae Sa",
    "postcode": "55110",
    "districtCode": "5507",
    "districtNameTh": "เวียงสา",
    "districtNameEn": "Wiang Sa",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550717",
    "subDistrictNameTh": "ทุ่งศรีทอง",
    "subDistrictNameEn": "Thung Si Thong",
    "postcode": "55110",
    "districtCode": "5507",
    "districtNameTh": "เวียงสา",
    "districtNameEn": "Wiang Sa",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550801",
    "subDistrictNameTh": "ปอน",
    "subDistrictNameEn": "Pon",
    "postcode": "55130",
    "districtCode": "5508",
    "districtNameTh": "ทุ่งช้าง",
    "districtNameEn": "Thung Chang",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550802",
    "subDistrictNameTh": "งอบ",
    "subDistrictNameEn": "Ngop",
    "postcode": "55130",
    "districtCode": "5508",
    "districtNameTh": "ทุ่งช้าง",
    "districtNameEn": "Thung Chang",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550803",
    "subDistrictNameTh": "และ",
    "subDistrictNameEn": "Lae",
    "postcode": "55130",
    "districtCode": "5508",
    "districtNameTh": "ทุ่งช้าง",
    "districtNameEn": "Thung Chang",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550804",
    "subDistrictNameTh": "ทุ่งช้าง",
    "subDistrictNameEn": "Thung Chang",
    "postcode": "55130",
    "districtCode": "5508",
    "districtNameTh": "ทุ่งช้าง",
    "districtNameEn": "Thung Chang",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550901",
    "subDistrictNameTh": "เชียงกลาง",
    "subDistrictNameEn": "Chiang Klang",
    "postcode": "55160",
    "districtCode": "5509",
    "districtNameTh": "เชียงกลาง",
    "districtNameEn": "Chiang Klang",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550902",
    "subDistrictNameTh": "เปือ",
    "subDistrictNameEn": "Puea",
    "postcode": "55160",
    "districtCode": "5509",
    "districtNameTh": "เชียงกลาง",
    "districtNameEn": "Chiang Klang",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550903",
    "subDistrictNameTh": "เชียงคาน",
    "subDistrictNameEn": "Chiang Khan",
    "postcode": "55160",
    "districtCode": "5509",
    "districtNameTh": "เชียงกลาง",
    "districtNameEn": "Chiang Klang",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550904",
    "subDistrictNameTh": "พระธาตุ",
    "subDistrictNameEn": "Phra That",
    "postcode": "55160",
    "districtCode": "5509",
    "districtNameTh": "เชียงกลาง",
    "districtNameEn": "Chiang Klang",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550908",
    "subDistrictNameTh": "พญาแก้ว",
    "subDistrictNameEn": "Phaya Kaeo",
    "postcode": "55160",
    "districtCode": "5509",
    "districtNameTh": "เชียงกลาง",
    "districtNameEn": "Chiang Klang",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "550909",
    "subDistrictNameTh": "พระพุทธบาท",
    "subDistrictNameEn": "Phra Phutthabat",
    "postcode": "55160",
    "districtCode": "5509",
    "districtNameTh": "เชียงกลาง",
    "districtNameEn": "Chiang Klang",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "551001",
    "subDistrictNameTh": "นาทะนุง",
    "subDistrictNameEn": "Na Thanung",
    "postcode": "55180",
    "districtCode": "5510",
    "districtNameTh": "นาหมื่น",
    "districtNameEn": "Na Muen",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "551002",
    "subDistrictNameTh": "บ่อแก้ว",
    "subDistrictNameEn": "Bo Kaeo",
    "postcode": "55180",
    "districtCode": "5510",
    "districtNameTh": "นาหมื่น",
    "districtNameEn": "Na Muen",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "551003",
    "subDistrictNameTh": "เมืองลี",
    "subDistrictNameEn": "Mueang Li",
    "postcode": "55180",
    "districtCode": "5510",
    "districtNameTh": "นาหมื่น",
    "districtNameEn": "Na Muen",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "551004",
    "subDistrictNameTh": "ปิงหลวง",
    "subDistrictNameEn": "Ping Luang",
    "postcode": "55180",
    "districtCode": "5510",
    "districtNameTh": "นาหมื่น",
    "districtNameEn": "Na Muen",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "551101",
    "subDistrictNameTh": "ดู่พงษ์",
    "subDistrictNameEn": "Du Phong",
    "postcode": "55210",
    "districtCode": "5511",
    "districtNameTh": "สันติสุข",
    "districtNameEn": "Santi Suk",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "551102",
    "subDistrictNameTh": "ป่าแลวหลวง",
    "subDistrictNameEn": "Pa Laeo Luang",
    "postcode": "55210",
    "districtCode": "5511",
    "districtNameTh": "สันติสุข",
    "districtNameEn": "Santi Suk",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "551103",
    "subDistrictNameTh": "พงษ์",
    "subDistrictNameEn": "Phong",
    "postcode": "55210",
    "districtCode": "5511",
    "districtNameTh": "สันติสุข",
    "districtNameEn": "Santi Suk",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "551201",
    "subDistrictNameTh": "บ่อเกลือเหนือ",
    "subDistrictNameEn": "Bo Kluea Nuea",
    "postcode": "55220",
    "districtCode": "5512",
    "districtNameTh": "บ่อเกลือ",
    "districtNameEn": "Bo Kluea",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "551202",
    "subDistrictNameTh": "บ่อเกลือใต้",
    "subDistrictNameEn": "Bo Kluea Tai",
    "postcode": "55220",
    "districtCode": "5512",
    "districtNameTh": "บ่อเกลือ",
    "districtNameEn": "Bo Kluea",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "551204",
    "subDistrictNameTh": "ภูฟ้า",
    "subDistrictNameEn": "Phu Fa",
    "postcode": "55220",
    "districtCode": "5512",
    "districtNameTh": "บ่อเกลือ",
    "districtNameEn": "Bo Kluea",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "551205",
    "subDistrictNameTh": "ดงพญา",
    "subDistrictNameEn": "Dong Phaya",
    "postcode": "55220",
    "districtCode": "5512",
    "districtNameTh": "บ่อเกลือ",
    "districtNameEn": "Bo Kluea",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "551301",
    "subDistrictNameTh": "นาไร่หลวง",
    "subDistrictNameEn": "Na Rai Luang",
    "postcode": "55160",
    "districtCode": "5513",
    "districtNameTh": "สองแคว",
    "districtNameEn": "Song Khwae",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "551302",
    "subDistrictNameTh": "ชนแดน",
    "subDistrictNameEn": "Chon Daen",
    "postcode": "55160",
    "districtCode": "5513",
    "districtNameTh": "สองแคว",
    "districtNameEn": "Song Khwae",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "551303",
    "subDistrictNameTh": "ยอด",
    "subDistrictNameEn": "Yot",
    "postcode": "55160",
    "districtCode": "5513",
    "districtNameTh": "สองแคว",
    "districtNameEn": "Song Khwae",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "551401",
    "subDistrictNameTh": "ม่วงตึ๊ด",
    "subDistrictNameEn": "Muang Tuet",
    "postcode": "55000",
    "districtCode": "5514",
    "districtNameTh": "ภูเพียง",
    "districtNameEn": "Phu Phiang",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "551402",
    "subDistrictNameTh": "นาปัง",
    "subDistrictNameEn": "Na Pang",
    "postcode": "55000",
    "districtCode": "5514",
    "districtNameTh": "ภูเพียง",
    "districtNameEn": "Phu Phiang",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "551403",
    "subDistrictNameTh": "น้ำแก่น",
    "subDistrictNameEn": "Nam Kaen",
    "postcode": "55000",
    "districtCode": "5514",
    "districtNameTh": "ภูเพียง",
    "districtNameEn": "Phu Phiang",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "551404",
    "subDistrictNameTh": "น้ำเกี๋ยน",
    "subDistrictNameEn": "Nam Kian",
    "postcode": "55000",
    "districtCode": "5514",
    "districtNameTh": "ภูเพียง",
    "districtNameEn": "Phu Phiang",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "551405",
    "subDistrictNameTh": "เมืองจัง",
    "subDistrictNameEn": "Mueang Chang",
    "postcode": "55000",
    "districtCode": "5514",
    "districtNameTh": "ภูเพียง",
    "districtNameEn": "Phu Phiang",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "551406",
    "subDistrictNameTh": "ท่าน้าว",
    "subDistrictNameEn": "Tha Nao",
    "postcode": "55000",
    "districtCode": "5514",
    "districtNameTh": "ภูเพียง",
    "districtNameEn": "Phu Phiang",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "551407",
    "subDistrictNameTh": "ฝายแก้ว",
    "subDistrictNameEn": "Fai Kaeo",
    "postcode": "55000",
    "districtCode": "5514",
    "districtNameTh": "ภูเพียง",
    "districtNameEn": "Phu Phiang",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "551501",
    "subDistrictNameTh": "ห้วยโก๋น",
    "subDistrictNameEn": "Huai Kon",
    "postcode": "55130",
    "districtCode": "5515",
    "districtNameTh": "เฉลิมพระเกียรติ",
    "districtNameEn": "Chaloem Phra Kiat",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "551502",
    "subDistrictNameTh": "ขุนน่าน",
    "subDistrictNameEn": "Khun Nan",
    "postcode": "55220",
    "districtCode": "5515",
    "districtNameTh": "เฉลิมพระเกียรติ",
    "districtNameEn": "Chaloem Phra Kiat",
    "provinceCode": "55",
    "provinceNameTh": "น่าน",
    "provinceNameEn": "Nan"
},
{
    "subDistrictCode": "560101",
    "subDistrictNameTh": "เวียง",
    "subDistrictNameEn": "Wiang",
    "postcode": "56000",
    "districtCode": "5601",
    "districtNameTh": "เมืองพะเยา",
    "districtNameEn": "Mueang Phayao",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560102",
    "subDistrictNameTh": "แม่ต๋ำ",
    "subDistrictNameEn": "Mae Tam",
    "postcode": "56000",
    "districtCode": "5601",
    "districtNameTh": "เมืองพะเยา",
    "districtNameEn": "Mueang Phayao",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560104",
    "subDistrictNameTh": "แม่นาเรือ",
    "subDistrictNameEn": "Mae Na Ruea",
    "postcode": "56000",
    "districtCode": "5601",
    "districtNameTh": "เมืองพะเยา",
    "districtNameEn": "Mueang Phayao",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560105",
    "subDistrictNameTh": "บ้านตุ่น",
    "subDistrictNameEn": "Ban Tun",
    "postcode": "56000",
    "districtCode": "5601",
    "districtNameTh": "เมืองพะเยา",
    "districtNameEn": "Mueang Phayao",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560106",
    "subDistrictNameTh": "บ้านต๊ำ",
    "subDistrictNameEn": "Ban Tam",
    "postcode": "56000",
    "districtCode": "5601",
    "districtNameTh": "เมืองพะเยา",
    "districtNameEn": "Mueang Phayao",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560107",
    "subDistrictNameTh": "บ้านต๋อม",
    "subDistrictNameEn": "Ban Tom",
    "postcode": "56000",
    "districtCode": "5601",
    "districtNameTh": "เมืองพะเยา",
    "districtNameEn": "Mueang Phayao",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560108",
    "subDistrictNameTh": "แม่ปืม",
    "subDistrictNameEn": "Mae Puem",
    "postcode": "56000",
    "districtCode": "5601",
    "districtNameTh": "เมืองพะเยา",
    "districtNameEn": "Mueang Phayao",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560110",
    "subDistrictNameTh": "แม่กา",
    "subDistrictNameEn": "Mae Ka",
    "postcode": "56000",
    "districtCode": "5601",
    "districtNameTh": "เมืองพะเยา",
    "districtNameEn": "Mueang Phayao",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560111",
    "subDistrictNameTh": "บ้านใหม่",
    "subDistrictNameEn": "Ban Mai",
    "postcode": "56000",
    "districtCode": "5601",
    "districtNameTh": "เมืองพะเยา",
    "districtNameEn": "Mueang Phayao",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560112",
    "subDistrictNameTh": "จำป่าหวาย",
    "subDistrictNameEn": "Cham Pa Wai",
    "postcode": "56000",
    "districtCode": "5601",
    "districtNameTh": "เมืองพะเยา",
    "districtNameEn": "Mueang Phayao",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560113",
    "subDistrictNameTh": "ท่าวังทอง",
    "subDistrictNameEn": "Tha Wang Thong",
    "postcode": "56000",
    "districtCode": "5601",
    "districtNameTh": "เมืองพะเยา",
    "districtNameEn": "Mueang Phayao",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560114",
    "subDistrictNameTh": "แม่ใส",
    "subDistrictNameEn": "Mae Sai",
    "postcode": "56000",
    "districtCode": "5601",
    "districtNameTh": "เมืองพะเยา",
    "districtNameEn": "Mueang Phayao",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560115",
    "subDistrictNameTh": "บ้านสาง",
    "subDistrictNameEn": "Ban Sang",
    "postcode": "56000",
    "districtCode": "5601",
    "districtNameTh": "เมืองพะเยา",
    "districtNameEn": "Mueang Phayao",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560116",
    "subDistrictNameTh": "ท่าจำปี",
    "subDistrictNameEn": "Tha Champi",
    "postcode": "56000",
    "districtCode": "5601",
    "districtNameTh": "เมืองพะเยา",
    "districtNameEn": "Mueang Phayao",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560118",
    "subDistrictNameTh": "สันป่าม่วง",
    "subDistrictNameEn": "San Pa Muang",
    "postcode": "56000",
    "districtCode": "5601",
    "districtNameTh": "เมืองพะเยา",
    "districtNameEn": "Mueang Phayao",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560201",
    "subDistrictNameTh": "ห้วยข้าวก่ำ",
    "subDistrictNameEn": "Huai Khao Kam",
    "postcode": "56150",
    "districtCode": "5602",
    "districtNameTh": "จุน",
    "districtNameEn": "Chun",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560202",
    "subDistrictNameTh": "จุน",
    "subDistrictNameEn": "Chun",
    "postcode": "56150",
    "districtCode": "5602",
    "districtNameTh": "จุน",
    "districtNameEn": "Chun",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560203",
    "subDistrictNameTh": "ลอ",
    "subDistrictNameEn": "Lo",
    "postcode": "56150",
    "districtCode": "5602",
    "districtNameTh": "จุน",
    "districtNameEn": "Chun",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560204",
    "subDistrictNameTh": "หงส์หิน",
    "subDistrictNameEn": "Hong Hin",
    "postcode": "56150",
    "districtCode": "5602",
    "districtNameTh": "จุน",
    "districtNameEn": "Chun",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560205",
    "subDistrictNameTh": "ทุ่งรวงทอง",
    "subDistrictNameEn": "Thung Ruang Thong",
    "postcode": "56150",
    "districtCode": "5602",
    "districtNameTh": "จุน",
    "districtNameEn": "Chun",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560206",
    "subDistrictNameTh": "ห้วยยางขาม",
    "subDistrictNameEn": "Huai Yang Kham",
    "postcode": "56150",
    "districtCode": "5602",
    "districtNameTh": "จุน",
    "districtNameEn": "Chun",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560207",
    "subDistrictNameTh": "พระธาตุขิงแกง",
    "subDistrictNameEn": "Phra That Khing Kaeng",
    "postcode": "56150",
    "districtCode": "5602",
    "districtNameTh": "จุน",
    "districtNameEn": "Chun",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560301",
    "subDistrictNameTh": "หย่วน",
    "subDistrictNameEn": "Yuan",
    "postcode": "56110",
    "districtCode": "5603",
    "districtNameTh": "เชียงคำ",
    "districtNameEn": "Chiang Kham",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560306",
    "subDistrictNameTh": "น้ำแวน",
    "subDistrictNameEn": "Nam Waen",
    "postcode": "56110",
    "districtCode": "5603",
    "districtNameTh": "เชียงคำ",
    "districtNameEn": "Chiang Kham",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560307",
    "subDistrictNameTh": "เวียง",
    "subDistrictNameEn": "Wiang",
    "postcode": "56110",
    "districtCode": "5603",
    "districtNameTh": "เชียงคำ",
    "districtNameEn": "Chiang Kham",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560308",
    "subDistrictNameTh": "ฝายกวาง",
    "subDistrictNameEn": "Fai Kwang",
    "postcode": "56110",
    "districtCode": "5603",
    "districtNameTh": "เชียงคำ",
    "districtNameEn": "Chiang Kham",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560309",
    "subDistrictNameTh": "เจดีย์คำ",
    "subDistrictNameEn": "Chedi Kham",
    "postcode": "56110",
    "districtCode": "5603",
    "districtNameTh": "เชียงคำ",
    "districtNameEn": "Chiang Kham",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560310",
    "subDistrictNameTh": "ร่มเย็น",
    "subDistrictNameEn": "Rom Yen",
    "postcode": "56110",
    "districtCode": "5603",
    "districtNameTh": "เชียงคำ",
    "districtNameEn": "Chiang Kham",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560311",
    "subDistrictNameTh": "เชียงบาน",
    "subDistrictNameEn": "Chiang Ban",
    "postcode": "56110",
    "districtCode": "5603",
    "districtNameTh": "เชียงคำ",
    "districtNameEn": "Chiang Kham",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560312",
    "subDistrictNameTh": "แม่ลาว",
    "subDistrictNameEn": "Mae Lao",
    "postcode": "56110",
    "districtCode": "5603",
    "districtNameTh": "เชียงคำ",
    "districtNameEn": "Chiang Kham",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560313",
    "subDistrictNameTh": "อ่างทอง",
    "subDistrictNameEn": "Ang Thong",
    "postcode": "56110",
    "districtCode": "5603",
    "districtNameTh": "เชียงคำ",
    "districtNameEn": "Chiang Kham",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560314",
    "subDistrictNameTh": "ทุ่งผาสุข",
    "subDistrictNameEn": "Thung Pha Suk",
    "postcode": "56110",
    "districtCode": "5603",
    "districtNameTh": "เชียงคำ",
    "districtNameEn": "Chiang Kham",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560401",
    "subDistrictNameTh": "เชียงม่วน",
    "subDistrictNameEn": "Chiang Muan",
    "postcode": "56160",
    "districtCode": "5604",
    "districtNameTh": "เชียงม่วน",
    "districtNameEn": "Chiang Muan",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560402",
    "subDistrictNameTh": "บ้านมาง",
    "subDistrictNameEn": "Ban Mang",
    "postcode": "56160",
    "districtCode": "5604",
    "districtNameTh": "เชียงม่วน",
    "districtNameEn": "Chiang Muan",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560403",
    "subDistrictNameTh": "สระ",
    "subDistrictNameEn": "Sa",
    "postcode": "56160",
    "districtCode": "5604",
    "districtNameTh": "เชียงม่วน",
    "districtNameEn": "Chiang Muan",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560501",
    "subDistrictNameTh": "ดอกคำใต้",
    "subDistrictNameEn": "Dok Kham Tai",
    "postcode": "56120",
    "districtCode": "5605",
    "districtNameTh": "ดอกคำใต้",
    "districtNameEn": "Dok Khamtai",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560502",
    "subDistrictNameTh": "ดอนศรีชุม",
    "subDistrictNameEn": "Don Si Chum",
    "postcode": "56120",
    "districtCode": "5605",
    "districtNameTh": "ดอกคำใต้",
    "districtNameEn": "Dok Khamtai",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560503",
    "subDistrictNameTh": "บ้านถ้ำ",
    "subDistrictNameEn": "Ban Tham",
    "postcode": "56120",
    "districtCode": "5605",
    "districtNameTh": "ดอกคำใต้",
    "districtNameEn": "Dok Khamtai",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560504",
    "subDistrictNameTh": "บ้านปิน",
    "subDistrictNameEn": "Ban Pin",
    "postcode": "56120",
    "districtCode": "5605",
    "districtNameTh": "ดอกคำใต้",
    "districtNameEn": "Dok Khamtai",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560505",
    "subDistrictNameTh": "ห้วยลาน",
    "subDistrictNameEn": "Huai Lan",
    "postcode": "56120",
    "districtCode": "5605",
    "districtNameTh": "ดอกคำใต้",
    "districtNameEn": "Dok Khamtai",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560506",
    "subDistrictNameTh": "สันโค้ง",
    "subDistrictNameEn": "San Khong",
    "postcode": "56120",
    "districtCode": "5605",
    "districtNameTh": "ดอกคำใต้",
    "districtNameEn": "Dok Khamtai",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560507",
    "subDistrictNameTh": "ป่าซาง",
    "subDistrictNameEn": "Pa Sang",
    "postcode": "56120",
    "districtCode": "5605",
    "districtNameTh": "ดอกคำใต้",
    "districtNameEn": "Dok Khamtai",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560508",
    "subDistrictNameTh": "หนองหล่ม",
    "subDistrictNameEn": "Nong Lom",
    "postcode": "56120",
    "districtCode": "5605",
    "districtNameTh": "ดอกคำใต้",
    "districtNameEn": "Dok Khamtai",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560509",
    "subDistrictNameTh": "ดงสุวรรณ",
    "subDistrictNameEn": "Dong Suwan",
    "postcode": "56120",
    "districtCode": "5605",
    "districtNameTh": "ดอกคำใต้",
    "districtNameEn": "Dok Khamtai",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560510",
    "subDistrictNameTh": "บุญเกิด",
    "subDistrictNameEn": "Bun Koet",
    "postcode": "56120",
    "districtCode": "5605",
    "districtNameTh": "ดอกคำใต้",
    "districtNameEn": "Dok Khamtai",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560511",
    "subDistrictNameTh": "สว่างอารมณ์",
    "subDistrictNameEn": "Sawang Arom",
    "postcode": "56120",
    "districtCode": "5605",
    "districtNameTh": "ดอกคำใต้",
    "districtNameEn": "Dok Khamtai",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560512",
    "subDistrictNameTh": "คือเวียง",
    "subDistrictNameEn": "Khue Wiang",
    "postcode": "56120",
    "districtCode": "5605",
    "districtNameTh": "ดอกคำใต้",
    "districtNameEn": "Dok Khamtai",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560601",
    "subDistrictNameTh": "ปง",
    "subDistrictNameEn": "Pong",
    "postcode": "56140",
    "districtCode": "5606",
    "districtNameTh": "ปง",
    "districtNameEn": "Pong",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560602",
    "subDistrictNameTh": "ควร",
    "subDistrictNameEn": "Khuan",
    "postcode": "56140",
    "districtCode": "5606",
    "districtNameTh": "ปง",
    "districtNameEn": "Pong",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560603",
    "subDistrictNameTh": "ออย",
    "subDistrictNameEn": "Oi",
    "postcode": "56140",
    "districtCode": "5606",
    "districtNameTh": "ปง",
    "districtNameEn": "Pong",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560604",
    "subDistrictNameTh": "งิม",
    "subDistrictNameEn": "Ngim",
    "postcode": "56140",
    "districtCode": "5606",
    "districtNameTh": "ปง",
    "districtNameEn": "Pong",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560605",
    "subDistrictNameTh": "ผาช้างน้อย",
    "subDistrictNameEn": "Pha Chang Noi",
    "postcode": "56140",
    "districtCode": "5606",
    "districtNameTh": "ปง",
    "districtNameEn": "Pong",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560606",
    "subDistrictNameTh": "นาปรัง",
    "subDistrictNameEn": "Na Prang",
    "postcode": "56140",
    "districtCode": "5606",
    "districtNameTh": "ปง",
    "districtNameEn": "Pong",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560607",
    "subDistrictNameTh": "ขุนควร",
    "subDistrictNameEn": "Khun Khuan",
    "postcode": "56140",
    "districtCode": "5606",
    "districtNameTh": "ปง",
    "districtNameEn": "Pong",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560701",
    "subDistrictNameTh": "แม่ใจ",
    "subDistrictNameEn": "Mae Chai",
    "postcode": "56130",
    "districtCode": "5607",
    "districtNameTh": "แม่ใจ",
    "districtNameEn": "Mae Chai",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560702",
    "subDistrictNameTh": "ศรีถ้อย",
    "subDistrictNameEn": "Si Thoi",
    "postcode": "56130",
    "districtCode": "5607",
    "districtNameTh": "แม่ใจ",
    "districtNameEn": "Mae Chai",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560703",
    "subDistrictNameTh": "แม่สุก",
    "subDistrictNameEn": "Mae Suk",
    "postcode": "56130",
    "districtCode": "5607",
    "districtNameTh": "แม่ใจ",
    "districtNameEn": "Mae Chai",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560704",
    "subDistrictNameTh": "ป่าแฝก",
    "subDistrictNameEn": "Pa Faek",
    "postcode": "56130",
    "districtCode": "5607",
    "districtNameTh": "แม่ใจ",
    "districtNameEn": "Mae Chai",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560705",
    "subDistrictNameTh": "บ้านเหล่า",
    "subDistrictNameEn": "Ban Lao",
    "postcode": "56130",
    "districtCode": "5607",
    "districtNameTh": "แม่ใจ",
    "districtNameEn": "Mae Chai",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560706",
    "subDistrictNameTh": "เจริญราษฎร์",
    "subDistrictNameEn": "Charoen Rat",
    "postcode": "56130",
    "districtCode": "5607",
    "districtNameTh": "แม่ใจ",
    "districtNameEn": "Mae Chai",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560801",
    "subDistrictNameTh": "ภูซาง",
    "subDistrictNameEn": "Phu Sang",
    "postcode": "56110",
    "districtCode": "5608",
    "districtNameTh": "ภูซาง",
    "districtNameEn": "Phu Sang",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560802",
    "subDistrictNameTh": "ป่าสัก",
    "subDistrictNameEn": "Pa Sak",
    "postcode": "56110",
    "districtCode": "5608",
    "districtNameTh": "ภูซาง",
    "districtNameEn": "Phu Sang",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560803",
    "subDistrictNameTh": "ทุ่งกล้วย",
    "subDistrictNameEn": "Thung Kluai",
    "postcode": "56110",
    "districtCode": "5608",
    "districtNameTh": "ภูซาง",
    "districtNameEn": "Phu Sang",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560804",
    "subDistrictNameTh": "เชียงแรง",
    "subDistrictNameEn": "Chiang Raeng",
    "postcode": "56110",
    "districtCode": "5608",
    "districtNameTh": "ภูซาง",
    "districtNameEn": "Phu Sang",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560805",
    "subDistrictNameTh": "สบบง",
    "subDistrictNameEn": "Sop Bong",
    "postcode": "56110",
    "districtCode": "5608",
    "districtNameTh": "ภูซาง",
    "districtNameEn": "Phu Sang",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560901",
    "subDistrictNameTh": "ห้วยแก้ว",
    "subDistrictNameEn": "Huai Kaeo",
    "postcode": "56000",
    "districtCode": "5609",
    "districtNameTh": "ภูกามยาว",
    "districtNameEn": "Phu Kamyao",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560902",
    "subDistrictNameTh": "ดงเจน",
    "subDistrictNameEn": "Dong Chen",
    "postcode": "56000",
    "districtCode": "5609",
    "districtNameTh": "ภูกามยาว",
    "districtNameEn": "Phu Kamyao",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "560903",
    "subDistrictNameTh": "แม่อิง",
    "subDistrictNameEn": "Mae Ing",
    "postcode": "56000",
    "districtCode": "5609",
    "districtNameTh": "ภูกามยาว",
    "districtNameEn": "Phu Kamyao",
    "provinceCode": "56",
    "provinceNameTh": "พะเยา",
    "provinceNameEn": "Phayao"
},
{
    "subDistrictCode": "570101",
    "subDistrictNameTh": "เวียง",
    "subDistrictNameEn": "Wiang",
    "postcode": "57000",
    "districtCode": "5701",
    "districtNameTh": "เมืองเชียงราย",
    "districtNameEn": "Mueang Chiang Rai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570102",
    "subDistrictNameTh": "รอบเวียง",
    "subDistrictNameEn": "Rop Wiang",
    "postcode": "57000",
    "districtCode": "5701",
    "districtNameTh": "เมืองเชียงราย",
    "districtNameEn": "Mueang Chiang Rai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570103",
    "subDistrictNameTh": "บ้านดู่",
    "subDistrictNameEn": "Ban Du",
    "postcode": "57100",
    "districtCode": "5701",
    "districtNameTh": "เมืองเชียงราย",
    "districtNameEn": "Mueang Chiang Rai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570104",
    "subDistrictNameTh": "นางแล",
    "subDistrictNameEn": "Nang Lae",
    "postcode": "57100",
    "districtCode": "5701",
    "districtNameTh": "เมืองเชียงราย",
    "districtNameEn": "Mueang Chiang Rai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570105",
    "subDistrictNameTh": "แม่ข้าวต้ม",
    "subDistrictNameEn": "Mae Khao Tom",
    "postcode": "57100",
    "districtCode": "5701",
    "districtNameTh": "เมืองเชียงราย",
    "districtNameEn": "Mueang Chiang Rai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570106",
    "subDistrictNameTh": "แม่ยาว",
    "subDistrictNameEn": "Mae Yao",
    "postcode": "57100",
    "districtCode": "5701",
    "districtNameTh": "เมืองเชียงราย",
    "districtNameEn": "Mueang Chiang Rai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570107",
    "subDistrictNameTh": "สันทราย",
    "subDistrictNameEn": "San Sai",
    "postcode": "57000",
    "districtCode": "5701",
    "districtNameTh": "เมืองเชียงราย",
    "districtNameEn": "Mueang Chiang Rai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570111",
    "subDistrictNameTh": "แม่กรณ์",
    "subDistrictNameEn": "Mae Kon",
    "postcode": "57000",
    "districtCode": "5701",
    "districtNameTh": "เมืองเชียงราย",
    "districtNameEn": "Mueang Chiang Rai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570112",
    "subDistrictNameTh": "ห้วยชมภู",
    "subDistrictNameEn": "Huai Chomphu",
    "postcode": "57000",
    "districtCode": "5701",
    "districtNameTh": "เมืองเชียงราย",
    "districtNameEn": "Mueang Chiang Rai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570113",
    "subDistrictNameTh": "ห้วยสัก",
    "subDistrictNameEn": "Huai Sak",
    "postcode": "57000",
    "districtCode": "5701",
    "districtNameTh": "เมืองเชียงราย",
    "districtNameEn": "Mueang Chiang Rai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570114",
    "subDistrictNameTh": "ริมกก",
    "subDistrictNameEn": "Rim Kok",
    "postcode": "57100",
    "districtCode": "5701",
    "districtNameTh": "เมืองเชียงราย",
    "districtNameEn": "Mueang Chiang Rai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570115",
    "subDistrictNameTh": "ดอยลาน",
    "subDistrictNameEn": "Doi Lan",
    "postcode": "57000",
    "districtCode": "5701",
    "districtNameTh": "เมืองเชียงราย",
    "districtNameEn": "Mueang Chiang Rai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570116",
    "subDistrictNameTh": "ป่าอ้อดอนชัย",
    "subDistrictNameEn": "Pa O Don Chai",
    "postcode": "57000",
    "districtCode": "5701",
    "districtNameTh": "เมืองเชียงราย",
    "districtNameEn": "Mueang Chiang Rai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570118",
    "subDistrictNameTh": "ท่าสาย",
    "subDistrictNameEn": "Tha Sai",
    "postcode": "57000",
    "districtCode": "5701",
    "districtNameTh": "เมืองเชียงราย",
    "districtNameEn": "Mueang Chiang Rai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570120",
    "subDistrictNameTh": "ดอยฮาง",
    "subDistrictNameEn": "Doi Hang",
    "postcode": "57000",
    "districtCode": "5701",
    "districtNameTh": "เมืองเชียงราย",
    "districtNameEn": "Mueang Chiang Rai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570121",
    "subDistrictNameTh": "ท่าสุด",
    "subDistrictNameEn": "Tha Sut",
    "postcode": "57100",
    "districtCode": "5701",
    "districtNameTh": "เมืองเชียงราย",
    "districtNameEn": "Mueang Chiang Rai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570202",
    "subDistrictNameTh": "เวียงชัย",
    "subDistrictNameEn": "Wiang Chai",
    "postcode": "57210",
    "districtCode": "5702",
    "districtNameTh": "เวียงชัย",
    "districtNameEn": "Wiang Chai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570203",
    "subDistrictNameTh": "ผางาม",
    "subDistrictNameEn": "Pha Ngam",
    "postcode": "57210",
    "districtCode": "5702",
    "districtNameTh": "เวียงชัย",
    "districtNameEn": "Wiang Chai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570204",
    "subDistrictNameTh": "เวียงเหนือ",
    "subDistrictNameEn": "Wiang Nuea",
    "postcode": "57210",
    "districtCode": "5702",
    "districtNameTh": "เวียงชัย",
    "districtNameEn": "Wiang Chai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570206",
    "subDistrictNameTh": "ดอนศิลา",
    "subDistrictNameEn": "Don Sila",
    "postcode": "57210",
    "districtCode": "5702",
    "districtNameTh": "เวียงชัย",
    "districtNameEn": "Wiang Chai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570208",
    "subDistrictNameTh": "เมืองชุม",
    "subDistrictNameEn": "Mueang Chum",
    "postcode": "57210",
    "districtCode": "5702",
    "districtNameTh": "เวียงชัย",
    "districtNameEn": "Wiang Chai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570301",
    "subDistrictNameTh": "เวียง",
    "subDistrictNameEn": "Wiang",
    "postcode": "57140",
    "districtCode": "5703",
    "districtNameTh": "เชียงของ",
    "districtNameEn": "Chiang Khong",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570302",
    "subDistrictNameTh": "สถาน",
    "subDistrictNameEn": "Sathan",
    "postcode": "57140",
    "districtCode": "5703",
    "districtNameTh": "เชียงของ",
    "districtNameEn": "Chiang Khong",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570303",
    "subDistrictNameTh": "ครึ่ง",
    "subDistrictNameEn": "Khrueng",
    "postcode": "57140",
    "districtCode": "5703",
    "districtNameTh": "เชียงของ",
    "districtNameEn": "Chiang Khong",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570304",
    "subDistrictNameTh": "บุญเรือง",
    "subDistrictNameEn": "Bun Rueang",
    "postcode": "57140",
    "districtCode": "5703",
    "districtNameTh": "เชียงของ",
    "districtNameEn": "Chiang Khong",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570305",
    "subDistrictNameTh": "ห้วยซ้อ",
    "subDistrictNameEn": "Huai So",
    "postcode": "57140",
    "districtCode": "5703",
    "districtNameTh": "เชียงของ",
    "districtNameEn": "Chiang Khong",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570308",
    "subDistrictNameTh": "ศรีดอนชัย",
    "subDistrictNameEn": "Si Don Chai",
    "postcode": "57140",
    "districtCode": "5703",
    "districtNameTh": "เชียงของ",
    "districtNameEn": "Chiang Khong",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570310",
    "subDistrictNameTh": "ริมโขง",
    "subDistrictNameEn": "Rim Khong",
    "postcode": "57140",
    "districtCode": "5703",
    "districtNameTh": "เชียงของ",
    "districtNameEn": "Chiang Khong",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570401",
    "subDistrictNameTh": "เวียง",
    "subDistrictNameEn": "Wiang",
    "postcode": "57160",
    "districtCode": "5704",
    "districtNameTh": "เทิง",
    "districtNameEn": "Thoeng",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570402",
    "subDistrictNameTh": "งิ้ว",
    "subDistrictNameEn": "Ngio",
    "postcode": "57160",
    "districtCode": "5704",
    "districtNameTh": "เทิง",
    "districtNameEn": "Thoeng",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570403",
    "subDistrictNameTh": "ปล้อง",
    "subDistrictNameEn": "Plong",
    "postcode": "57230",
    "districtCode": "5704",
    "districtNameTh": "เทิง",
    "districtNameEn": "Thoeng",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570404",
    "subDistrictNameTh": "แม่ลอย",
    "subDistrictNameEn": "Mae Loi",
    "postcode": "57230",
    "districtCode": "5704",
    "districtNameTh": "เทิง",
    "districtNameEn": "Thoeng",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570405",
    "subDistrictNameTh": "เชียงเคี่ยน",
    "subDistrictNameEn": "Chiang Khian",
    "postcode": "57230",
    "districtCode": "5704",
    "districtNameTh": "เทิง",
    "districtNameEn": "Thoeng",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570409",
    "subDistrictNameTh": "ตับเต่า",
    "subDistrictNameEn": "Tap Tao",
    "postcode": "57160",
    "districtCode": "5704",
    "districtNameTh": "เทิง",
    "districtNameEn": "Thoeng",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570410",
    "subDistrictNameTh": "หงาว",
    "subDistrictNameEn": "Ngao",
    "postcode": "57160",
    "districtCode": "5704",
    "districtNameTh": "เทิง",
    "districtNameEn": "Thoeng",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570411",
    "subDistrictNameTh": "สันทรายงาม",
    "subDistrictNameEn": "San Sai Ngam",
    "postcode": "57160",
    "districtCode": "5704",
    "districtNameTh": "เทิง",
    "districtNameEn": "Thoeng",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570412",
    "subDistrictNameTh": "ศรีดอนไชย",
    "subDistrictNameEn": "Si Don Chai",
    "postcode": "57230",
    "districtCode": "5704",
    "districtNameTh": "เทิง",
    "districtNameEn": "Thoeng",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570413",
    "subDistrictNameTh": "หนองแรด",
    "subDistrictNameEn": "Nong Raet",
    "postcode": "57230",
    "districtCode": "5704",
    "districtNameTh": "เทิง",
    "districtNameEn": "Thoeng",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570501",
    "subDistrictNameTh": "สันมะเค็ด",
    "subDistrictNameEn": "San Makhet",
    "postcode": "57120",
    "districtCode": "5705",
    "districtNameTh": "พาน",
    "districtNameEn": "Phan",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570502",
    "subDistrictNameTh": "แม่อ้อ",
    "subDistrictNameEn": "Mae O",
    "postcode": "57120",
    "districtCode": "5705",
    "districtNameTh": "พาน",
    "districtNameEn": "Phan",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570503",
    "subDistrictNameTh": "ธารทอง",
    "subDistrictNameEn": "Than Thong",
    "postcode": "57250",
    "districtCode": "5705",
    "districtNameTh": "พาน",
    "districtNameEn": "Phan",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570504",
    "subDistrictNameTh": "สันติสุข",
    "subDistrictNameEn": "Santi Suk",
    "postcode": "57120",
    "districtCode": "5705",
    "districtNameTh": "พาน",
    "districtNameEn": "Phan",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570505",
    "subDistrictNameTh": "ดอยงาม",
    "subDistrictNameEn": "Doi Ngam",
    "postcode": "57120",
    "districtCode": "5705",
    "districtNameTh": "พาน",
    "districtNameEn": "Phan",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570506",
    "subDistrictNameTh": "หัวง้ม",
    "subDistrictNameEn": "Hua Ngom",
    "postcode": "57120",
    "districtCode": "5705",
    "districtNameTh": "พาน",
    "districtNameEn": "Phan",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570507",
    "subDistrictNameTh": "เจริญเมือง",
    "subDistrictNameEn": "Charoen Mueang",
    "postcode": "57120",
    "districtCode": "5705",
    "districtNameTh": "พาน",
    "districtNameEn": "Phan",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570508",
    "subDistrictNameTh": "ป่าหุ่ง",
    "subDistrictNameEn": "Pa Hung",
    "postcode": "57120",
    "districtCode": "5705",
    "districtNameTh": "พาน",
    "districtNameEn": "Phan",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570509",
    "subDistrictNameTh": "ม่วงคำ",
    "subDistrictNameEn": "Muang Kham",
    "postcode": "57120",
    "districtCode": "5705",
    "districtNameTh": "พาน",
    "districtNameEn": "Phan",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570510",
    "subDistrictNameTh": "ทรายขาว",
    "subDistrictNameEn": "Sai Khao",
    "postcode": "57120",
    "districtCode": "5705",
    "districtNameTh": "พาน",
    "districtNameEn": "Phan",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570511",
    "subDistrictNameTh": "สันกลาง",
    "subDistrictNameEn": "San Klang",
    "postcode": "57120",
    "districtCode": "5705",
    "districtNameTh": "พาน",
    "districtNameEn": "Phan",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570512",
    "subDistrictNameTh": "แม่เย็น",
    "subDistrictNameEn": "Mae Yen",
    "postcode": "57280",
    "districtCode": "5705",
    "districtNameTh": "พาน",
    "districtNameEn": "Phan",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570513",
    "subDistrictNameTh": "เมืองพาน",
    "subDistrictNameEn": "Mueang Phan",
    "postcode": "57120",
    "districtCode": "5705",
    "districtNameTh": "พาน",
    "districtNameEn": "Phan",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570514",
    "subDistrictNameTh": "ทานตะวัน",
    "subDistrictNameEn": "Than Tawan",
    "postcode": "57280",
    "districtCode": "5705",
    "districtNameTh": "พาน",
    "districtNameEn": "Phan",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570515",
    "subDistrictNameTh": "เวียงห้าว",
    "subDistrictNameEn": "Wiang Hao",
    "postcode": "57120",
    "districtCode": "5705",
    "districtNameTh": "พาน",
    "districtNameEn": "Phan",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570601",
    "subDistrictNameTh": "ป่าแดด",
    "subDistrictNameEn": "Pa Daet",
    "postcode": "57190",
    "districtCode": "5706",
    "districtNameTh": "ป่าแดด",
    "districtNameEn": "Pa Daet",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570602",
    "subDistrictNameTh": "ป่าแงะ",
    "subDistrictNameEn": "Pa Ngae",
    "postcode": "57190",
    "districtCode": "5706",
    "districtNameTh": "ป่าแดด",
    "districtNameEn": "Pa Daet",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570603",
    "subDistrictNameTh": "สันมะค่า",
    "subDistrictNameEn": "San Makha",
    "postcode": "57190",
    "districtCode": "5706",
    "districtNameTh": "ป่าแดด",
    "districtNameEn": "Pa Daet",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570605",
    "subDistrictNameTh": "โรงช้าง",
    "subDistrictNameEn": "Rong Chang",
    "postcode": "57190",
    "districtCode": "5706",
    "districtNameTh": "ป่าแดด",
    "districtNameEn": "Pa Daet",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570606",
    "subDistrictNameTh": "ศรีโพธิ์เงิน",
    "subDistrictNameEn": "Si Pho Ngoen",
    "postcode": "57190",
    "districtCode": "5706",
    "districtNameTh": "ป่าแดด",
    "districtNameEn": "Pa Daet",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570701",
    "subDistrictNameTh": "แม่จัน",
    "subDistrictNameEn": "Mae Chan",
    "postcode": "57110",
    "districtCode": "5707",
    "districtNameTh": "แม่จัน",
    "districtNameEn": "Mae Chan",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570702",
    "subDistrictNameTh": "จันจว้า",
    "subDistrictNameEn": "Chan Chwa",
    "postcode": "57270",
    "districtCode": "5707",
    "districtNameTh": "แม่จัน",
    "districtNameEn": "Mae Chan",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570703",
    "subDistrictNameTh": "แม่คำ",
    "subDistrictNameEn": "Mae Kham",
    "postcode": "57240",
    "districtCode": "5707",
    "districtNameTh": "แม่จัน",
    "districtNameEn": "Mae Chan",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570704",
    "subDistrictNameTh": "ป่าซาง",
    "subDistrictNameEn": "Pa Sang",
    "postcode": "57110",
    "districtCode": "5707",
    "districtNameTh": "แม่จัน",
    "districtNameEn": "Mae Chan",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570705",
    "subDistrictNameTh": "สันทราย",
    "subDistrictNameEn": "San Sai",
    "postcode": "57110",
    "districtCode": "5707",
    "districtNameTh": "แม่จัน",
    "districtNameEn": "Mae Chan",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570706",
    "subDistrictNameTh": "ท่าข้าวเปลือก",
    "subDistrictNameEn": "Tha Khao Plueak",
    "postcode": "57110",
    "districtCode": "5707",
    "districtNameTh": "แม่จัน",
    "districtNameEn": "Mae Chan",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570708",
    "subDistrictNameTh": "ป่าตึง",
    "subDistrictNameEn": "Pa Tueng",
    "postcode": "57110",
    "districtCode": "5707",
    "districtNameTh": "แม่จัน",
    "districtNameEn": "Mae Chan",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570710",
    "subDistrictNameTh": "แม่ไร่",
    "subDistrictNameEn": "Mae Rai",
    "postcode": "57240",
    "districtCode": "5707",
    "districtNameTh": "แม่จัน",
    "districtNameEn": "Mae Chan",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570711",
    "subDistrictNameTh": "ศรีค้ำ",
    "subDistrictNameEn": "Si Kham",
    "postcode": "57110",
    "districtCode": "5707",
    "districtNameTh": "แม่จัน",
    "districtNameEn": "Mae Chan",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570712",
    "subDistrictNameTh": "จันจว้าใต้",
    "subDistrictNameEn": "Chan Chwa Tai",
    "postcode": "57270",
    "districtCode": "5707",
    "districtNameTh": "แม่จัน",
    "districtNameEn": "Mae Chan",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570713",
    "subDistrictNameTh": "จอมสวรรค์",
    "subDistrictNameEn": "Chom Sawan",
    "postcode": "57110",
    "districtCode": "5707",
    "districtNameTh": "แม่จัน",
    "districtNameEn": "Mae Chan",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570801",
    "subDistrictNameTh": "เวียง",
    "subDistrictNameEn": "Wiang",
    "postcode": "57150",
    "districtCode": "5708",
    "districtNameTh": "เชียงแสน",
    "districtNameEn": "Chiang Saen",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570802",
    "subDistrictNameTh": "ป่าสัก",
    "subDistrictNameEn": "Pa Sak",
    "postcode": "57150",
    "districtCode": "5708",
    "districtNameTh": "เชียงแสน",
    "districtNameEn": "Chiang Saen",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570803",
    "subDistrictNameTh": "บ้านแซว",
    "subDistrictNameEn": "Ban Saeo",
    "postcode": "57150",
    "districtCode": "5708",
    "districtNameTh": "เชียงแสน",
    "districtNameEn": "Chiang Saen",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570804",
    "subDistrictNameTh": "ศรีดอนมูล",
    "subDistrictNameEn": "Si Don Mun",
    "postcode": "57150",
    "districtCode": "5708",
    "districtNameTh": "เชียงแสน",
    "districtNameEn": "Chiang Saen",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570805",
    "subDistrictNameTh": "แม่เงิน",
    "subDistrictNameEn": "Mae Ngoen",
    "postcode": "57150",
    "districtCode": "5708",
    "districtNameTh": "เชียงแสน",
    "districtNameEn": "Chiang Saen",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570806",
    "subDistrictNameTh": "โยนก",
    "subDistrictNameEn": "Yonok",
    "postcode": "57150",
    "districtCode": "5708",
    "districtNameTh": "เชียงแสน",
    "districtNameEn": "Chiang Saen",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570901",
    "subDistrictNameTh": "แม่สาย",
    "subDistrictNameEn": "Mae Sai",
    "postcode": "57130",
    "districtCode": "5709",
    "districtNameTh": "แม่สาย",
    "districtNameEn": "Mae Sai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570902",
    "subDistrictNameTh": "ห้วยไคร้",
    "subDistrictNameEn": "Huai Khrai",
    "postcode": "57220",
    "districtCode": "5709",
    "districtNameTh": "แม่สาย",
    "districtNameEn": "Mae Sai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570903",
    "subDistrictNameTh": "เกาะช้าง",
    "subDistrictNameEn": "Ko Chang",
    "postcode": "57130",
    "districtCode": "5709",
    "districtNameTh": "แม่สาย",
    "districtNameEn": "Mae Sai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570904",
    "subDistrictNameTh": "โป่งผา",
    "subDistrictNameEn": "Pong Pha",
    "postcode": "57130",
    "districtCode": "5709",
    "districtNameTh": "แม่สาย",
    "districtNameEn": "Mae Sai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570905",
    "subDistrictNameTh": "ศรีเมืองชุม",
    "subDistrictNameEn": "Si Mueang Chum",
    "postcode": "57130",
    "districtCode": "5709",
    "districtNameTh": "แม่สาย",
    "districtNameEn": "Mae Sai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570906",
    "subDistrictNameTh": "เวียงพางคำ",
    "subDistrictNameEn": "Wiang Phang Kham",
    "postcode": "57130",
    "districtCode": "5709",
    "districtNameTh": "แม่สาย",
    "districtNameEn": "Mae Sai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570908",
    "subDistrictNameTh": "บ้านด้าย",
    "subDistrictNameEn": "Ban Dai",
    "postcode": "57220",
    "districtCode": "5709",
    "districtNameTh": "แม่สาย",
    "districtNameEn": "Mae Sai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "570909",
    "subDistrictNameTh": "โป่งงาม",
    "subDistrictNameEn": "Pong Ngam",
    "postcode": "57130",
    "districtCode": "5709",
    "districtNameTh": "แม่สาย",
    "districtNameEn": "Mae Sai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "571001",
    "subDistrictNameTh": "แม่สรวย",
    "subDistrictNameEn": "Mae Suai",
    "postcode": "57180",
    "districtCode": "5710",
    "districtNameTh": "แม่สรวย",
    "districtNameEn": "Mae Suai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "571002",
    "subDistrictNameTh": "ป่าแดด",
    "subDistrictNameEn": "Pa Daet",
    "postcode": "57180",
    "districtCode": "5710",
    "districtNameTh": "แม่สรวย",
    "districtNameEn": "Mae Suai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "571003",
    "subDistrictNameTh": "แม่พริก",
    "subDistrictNameEn": "Mae Phrik",
    "postcode": "57180",
    "districtCode": "5710",
    "districtNameTh": "แม่สรวย",
    "districtNameEn": "Mae Suai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "571004",
    "subDistrictNameTh": "ศรีถ้อย",
    "subDistrictNameEn": "Si Thoi",
    "postcode": "57180",
    "districtCode": "5710",
    "districtNameTh": "แม่สรวย",
    "districtNameEn": "Mae Suai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "571005",
    "subDistrictNameTh": "ท่าก๊อ",
    "subDistrictNameEn": "Tha Ko",
    "postcode": "57180",
    "districtCode": "5710",
    "districtNameTh": "แม่สรวย",
    "districtNameEn": "Mae Suai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "571006",
    "subDistrictNameTh": "วาวี",
    "subDistrictNameEn": "Wawi",
    "postcode": "57180",
    "districtCode": "5710",
    "districtNameTh": "แม่สรวย",
    "districtNameEn": "Mae Suai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "571007",
    "subDistrictNameTh": "เจดีย์หลวง",
    "subDistrictNameEn": "Chedi Luang",
    "postcode": "57180",
    "districtCode": "5710",
    "districtNameTh": "แม่สรวย",
    "districtNameEn": "Mae Suai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "571101",
    "subDistrictNameTh": "สันสลี",
    "subDistrictNameEn": "San Sali",
    "postcode": "57170",
    "districtCode": "5711",
    "districtNameTh": "เวียงป่าเป้า",
    "districtNameEn": "Wiang Pa Pao",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "571102",
    "subDistrictNameTh": "เวียง",
    "subDistrictNameEn": "Wiang",
    "postcode": "57170",
    "districtCode": "5711",
    "districtNameTh": "เวียงป่าเป้า",
    "districtNameEn": "Wiang Pa Pao",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "571103",
    "subDistrictNameTh": "บ้านโป่ง",
    "subDistrictNameEn": "Ban Pong",
    "postcode": "57170",
    "districtCode": "5711",
    "districtNameTh": "เวียงป่าเป้า",
    "districtNameEn": "Wiang Pa Pao",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "571104",
    "subDistrictNameTh": "ป่างิ้ว",
    "subDistrictNameEn": "Pa Ngio",
    "postcode": "57170",
    "districtCode": "5711",
    "districtNameTh": "เวียงป่าเป้า",
    "districtNameEn": "Wiang Pa Pao",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "571105",
    "subDistrictNameTh": "เวียงกาหลง",
    "subDistrictNameEn": "Wiang Ka Long",
    "postcode": "57260",
    "districtCode": "5711",
    "districtNameTh": "เวียงป่าเป้า",
    "districtNameEn": "Wiang Pa Pao",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "571106",
    "subDistrictNameTh": "แม่เจดีย์",
    "subDistrictNameEn": "Mae Chedi",
    "postcode": "57260",
    "districtCode": "5711",
    "districtNameTh": "เวียงป่าเป้า",
    "districtNameEn": "Wiang Pa Pao",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "571107",
    "subDistrictNameTh": "แม่เจดีย์ใหม่",
    "subDistrictNameEn": "Mae Chedi Mai",
    "postcode": "57260",
    "districtCode": "5711",
    "districtNameTh": "เวียงป่าเป้า",
    "districtNameEn": "Wiang Pa Pao",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "571201",
    "subDistrictNameTh": "แม่เปา",
    "subDistrictNameEn": "Mae Pao",
    "postcode": "57290",
    "districtCode": "5712",
    "districtNameTh": "พญาเม็งราย",
    "districtNameEn": "Phaya Mengrai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "571202",
    "subDistrictNameTh": "แม่ต๋ำ",
    "subDistrictNameEn": "Mae Tam",
    "postcode": "57290",
    "districtCode": "5712",
    "districtNameTh": "พญาเม็งราย",
    "districtNameEn": "Phaya Mengrai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "571203",
    "subDistrictNameTh": "ไม้ยา",
    "subDistrictNameEn": "Mai Ya",
    "postcode": "57290",
    "districtCode": "5712",
    "districtNameTh": "พญาเม็งราย",
    "districtNameEn": "Phaya Mengrai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "571204",
    "subDistrictNameTh": "เม็งราย",
    "subDistrictNameEn": "Mengrai",
    "postcode": "57290",
    "districtCode": "5712",
    "districtNameTh": "พญาเม็งราย",
    "districtNameEn": "Phaya Mengrai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "571205",
    "subDistrictNameTh": "ตาดควัน",
    "subDistrictNameEn": "Tat Khwan",
    "postcode": "57290",
    "districtCode": "5712",
    "districtNameTh": "พญาเม็งราย",
    "districtNameEn": "Phaya Mengrai",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "571301",
    "subDistrictNameTh": "ม่วงยาย",
    "subDistrictNameEn": "Muang Yai",
    "postcode": "57310",
    "districtCode": "5713",
    "districtNameTh": "เวียงแก่น",
    "districtNameEn": "Wiang Kaen",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "571302",
    "subDistrictNameTh": "ปอ",
    "subDistrictNameEn": "Po",
    "postcode": "57310",
    "districtCode": "5713",
    "districtNameTh": "เวียงแก่น",
    "districtNameEn": "Wiang Kaen",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "571303",
    "subDistrictNameTh": "หล่ายงาว",
    "subDistrictNameEn": "Lai Ngao",
    "postcode": "57310",
    "districtCode": "5713",
    "districtNameTh": "เวียงแก่น",
    "districtNameEn": "Wiang Kaen",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "571304",
    "subDistrictNameTh": "ท่าข้าม",
    "subDistrictNameEn": "Tha Kham",
    "postcode": "57310",
    "districtCode": "5713",
    "districtNameTh": "เวียงแก่น",
    "districtNameEn": "Wiang Kaen",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "571401",
    "subDistrictNameTh": "ต้า",
    "subDistrictNameEn": "Ta",
    "postcode": "57340",
    "districtCode": "5714",
    "districtNameTh": "ขุนตาล",
    "districtNameEn": "Khun Tan",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "571402",
    "subDistrictNameTh": "ป่าตาล",
    "subDistrictNameEn": "Pa Tan",
    "postcode": "57340",
    "districtCode": "5714",
    "districtNameTh": "ขุนตาล",
    "districtNameEn": "Khun Tan",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "571403",
    "subDistrictNameTh": "ยางฮอม",
    "subDistrictNameEn": "Yang Hom",
    "postcode": "57340",
    "districtCode": "5714",
    "districtNameTh": "ขุนตาล",
    "districtNameEn": "Khun Tan",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "571501",
    "subDistrictNameTh": "เทอดไทย",
    "subDistrictNameEn": "Thoet Thai",
    "postcode": "57240",
    "districtCode": "5715",
    "districtNameTh": "แม่ฟ้าหลวง",
    "districtNameEn": "Mae Fa Luang",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "571502",
    "subDistrictNameTh": "แม่สลองใน",
    "subDistrictNameEn": "Mae Salong Nai",
    "postcode": "57110",
    "districtCode": "5715",
    "districtNameTh": "แม่ฟ้าหลวง",
    "districtNameEn": "Mae Fa Luang",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "571503",
    "subDistrictNameTh": "แม่สลองนอก",
    "subDistrictNameEn": "Mae Salong Nok",
    "postcode": "57110",
    "districtCode": "5715",
    "districtNameTh": "แม่ฟ้าหลวง",
    "districtNameEn": "Mae Fa Luang",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "571504",
    "subDistrictNameTh": "แม่ฟ้าหลวง",
    "subDistrictNameEn": "Mae Fa Luang",
    "postcode": "57240",
    "districtCode": "5715",
    "districtNameTh": "แม่ฟ้าหลวง",
    "districtNameEn": "Mae Fa Luang",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "571601",
    "subDistrictNameTh": "ดงมะดะ",
    "subDistrictNameEn": "Dong Mada",
    "postcode": "57250",
    "districtCode": "5716",
    "districtNameTh": "แม่ลาว",
    "districtNameEn": "Mae Lao",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "571602",
    "subDistrictNameTh": "จอมหมอกแก้ว",
    "subDistrictNameEn": "Chom Mok Kaeo",
    "postcode": "57250",
    "districtCode": "5716",
    "districtNameTh": "แม่ลาว",
    "districtNameEn": "Mae Lao",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "571603",
    "subDistrictNameTh": "บัวสลี",
    "subDistrictNameEn": "Bua Sali",
    "postcode": "57250",
    "districtCode": "5716",
    "districtNameTh": "แม่ลาว",
    "districtNameEn": "Mae Lao",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "571604",
    "subDistrictNameTh": "ป่าก่อดำ",
    "subDistrictNameEn": "Pa Ko Dam",
    "postcode": "57250",
    "districtCode": "5716",
    "districtNameTh": "แม่ลาว",
    "districtNameEn": "Mae Lao",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "571605",
    "subDistrictNameTh": "โป่งแพร่",
    "subDistrictNameEn": "Pong Phrae",
    "postcode": "57000",
    "districtCode": "5716",
    "districtNameTh": "แม่ลาว",
    "districtNameEn": "Mae Lao",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "571701",
    "subDistrictNameTh": "ทุ่งก่อ",
    "subDistrictNameEn": "Thung Ko",
    "postcode": "57210",
    "districtCode": "5717",
    "districtNameTh": "เวียงเชียงรุ้ง",
    "districtNameEn": "Wiang Chiang Rung",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "571702",
    "subDistrictNameTh": "ดงมหาวัน",
    "subDistrictNameEn": "Dong Maha Wan",
    "postcode": "57210",
    "districtCode": "5717",
    "districtNameTh": "เวียงเชียงรุ้ง",
    "districtNameEn": "Wiang Chiang Rung",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "571703",
    "subDistrictNameTh": "ป่าซาง",
    "subDistrictNameEn": "Pa Sang",
    "postcode": "57210",
    "districtCode": "5717",
    "districtNameTh": "เวียงเชียงรุ้ง",
    "districtNameEn": "Wiang Chiang Rung",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "571801",
    "subDistrictNameTh": "ปงน้อย",
    "subDistrictNameEn": "Pong Noi",
    "postcode": "57110",
    "districtCode": "5718",
    "districtNameTh": "ดอยหลวง",
    "districtNameEn": "Doi Luang",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "571802",
    "subDistrictNameTh": "โชคชัย",
    "subDistrictNameEn": "Chok Chai",
    "postcode": "57110",
    "districtCode": "5718",
    "districtNameTh": "ดอยหลวง",
    "districtNameEn": "Doi Luang",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "571803",
    "subDistrictNameTh": "หนองป่าก่อ",
    "subDistrictNameEn": "Nong Pa Ko",
    "postcode": "57110",
    "districtCode": "5718",
    "districtNameTh": "ดอยหลวง",
    "districtNameEn": "Doi Luang",
    "provinceCode": "57",
    "provinceNameTh": "เชียงราย",
    "provinceNameEn": "Chiang Rai"
},
{
    "subDistrictCode": "580101",
    "subDistrictNameTh": "จองคำ",
    "subDistrictNameEn": "Chong Kham",
    "postcode": "58000",
    "districtCode": "5801",
    "districtNameTh": "เมืองแม่ฮ่องสอน",
    "districtNameEn": "Mueang Mae Hong Son",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "subDistrictCode": "580102",
    "subDistrictNameTh": "ห้วยโป่ง",
    "subDistrictNameEn": "Huai Pong",
    "postcode": "58000",
    "districtCode": "5801",
    "districtNameTh": "เมืองแม่ฮ่องสอน",
    "districtNameEn": "Mueang Mae Hong Son",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "subDistrictCode": "580103",
    "subDistrictNameTh": "ผาบ่อง",
    "subDistrictNameEn": "Pha Bong",
    "postcode": "58000",
    "districtCode": "5801",
    "districtNameTh": "เมืองแม่ฮ่องสอน",
    "districtNameEn": "Mueang Mae Hong Son",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "subDistrictCode": "580104",
    "subDistrictNameTh": "ปางหมู",
    "subDistrictNameEn": "Pang Mu",
    "postcode": "58000",
    "districtCode": "5801",
    "districtNameTh": "เมืองแม่ฮ่องสอน",
    "districtNameEn": "Mueang Mae Hong Son",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "subDistrictCode": "580105",
    "subDistrictNameTh": "หมอกจำแป่",
    "subDistrictNameEn": "Mok Champae",
    "postcode": "58000",
    "districtCode": "5801",
    "districtNameTh": "เมืองแม่ฮ่องสอน",
    "districtNameEn": "Mueang Mae Hong Son",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "subDistrictCode": "580106",
    "subDistrictNameTh": "ห้วยผา",
    "subDistrictNameEn": "Huai Pha",
    "postcode": "58000",
    "districtCode": "5801",
    "districtNameTh": "เมืองแม่ฮ่องสอน",
    "districtNameEn": "Mueang Mae Hong Son",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "subDistrictCode": "580109",
    "subDistrictNameTh": "ห้วยปูลิง",
    "subDistrictNameEn": "Huai Pu Ling",
    "postcode": "58000",
    "districtCode": "5801",
    "districtNameTh": "เมืองแม่ฮ่องสอน",
    "districtNameEn": "Mueang Mae Hong Son",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "subDistrictCode": "580201",
    "subDistrictNameTh": "ขุนยวม",
    "subDistrictNameEn": "Khun Yuam",
    "postcode": "58140",
    "districtCode": "5802",
    "districtNameTh": "ขุนยวม",
    "districtNameEn": "Khun Yuam",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "subDistrictCode": "580202",
    "subDistrictNameTh": "แม่เงา",
    "subDistrictNameEn": "Mae Ngao",
    "postcode": "58140",
    "districtCode": "5802",
    "districtNameTh": "ขุนยวม",
    "districtNameEn": "Khun Yuam",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "subDistrictCode": "580203",
    "subDistrictNameTh": "เมืองปอน",
    "subDistrictNameEn": "Mueang Pon",
    "postcode": "58140",
    "districtCode": "5802",
    "districtNameTh": "ขุนยวม",
    "districtNameEn": "Khun Yuam",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "subDistrictCode": "580204",
    "subDistrictNameTh": "แม่ยวมน้อย",
    "subDistrictNameEn": "Mae Yuam Noi",
    "postcode": "58140",
    "districtCode": "5802",
    "districtNameTh": "ขุนยวม",
    "districtNameEn": "Khun Yuam",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "subDistrictCode": "580205",
    "subDistrictNameTh": "แม่กิ๊",
    "subDistrictNameEn": "Mae Ki",
    "postcode": "58140",
    "districtCode": "5802",
    "districtNameTh": "ขุนยวม",
    "districtNameEn": "Khun Yuam",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "subDistrictCode": "580206",
    "subDistrictNameTh": "แม่อูคอ",
    "subDistrictNameEn": "Mae Ukho",
    "postcode": "58140",
    "districtCode": "5802",
    "districtNameTh": "ขุนยวม",
    "districtNameEn": "Khun Yuam",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "subDistrictCode": "580301",
    "subDistrictNameTh": "เวียงใต้",
    "subDistrictNameEn": "Wiang Tai",
    "postcode": "58130",
    "districtCode": "5803",
    "districtNameTh": "ปาย",
    "districtNameEn": "Pai",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "subDistrictCode": "580302",
    "subDistrictNameTh": "เวียงเหนือ",
    "subDistrictNameEn": "Wiang Nuea",
    "postcode": "58130",
    "districtCode": "5803",
    "districtNameTh": "ปาย",
    "districtNameEn": "Pai",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "subDistrictCode": "580303",
    "subDistrictNameTh": "แม่นาเติง",
    "subDistrictNameEn": "Mae Na Toeng",
    "postcode": "58130",
    "districtCode": "5803",
    "districtNameTh": "ปาย",
    "districtNameEn": "Pai",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "subDistrictCode": "580304",
    "subDistrictNameTh": "แม่ฮี้",
    "subDistrictNameEn": "Mae Hi",
    "postcode": "58130",
    "districtCode": "5803",
    "districtNameTh": "ปาย",
    "districtNameEn": "Pai",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "subDistrictCode": "580305",
    "subDistrictNameTh": "ทุ่งยาว",
    "subDistrictNameEn": "Thung Yao",
    "postcode": "58130",
    "districtCode": "5803",
    "districtNameTh": "ปาย",
    "districtNameEn": "Pai",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "subDistrictCode": "580306",
    "subDistrictNameTh": "เมืองแปง",
    "subDistrictNameEn": "Mueang Paeng",
    "postcode": "58130",
    "districtCode": "5803",
    "districtNameTh": "ปาย",
    "districtNameEn": "Pai",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "subDistrictCode": "580307",
    "subDistrictNameTh": "โป่งสา",
    "subDistrictNameEn": "Pong Sa",
    "postcode": "58130",
    "districtCode": "5803",
    "districtNameTh": "ปาย",
    "districtNameEn": "Pai",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "subDistrictCode": "580401",
    "subDistrictNameTh": "บ้านกาศ",
    "subDistrictNameEn": "Ban Kat",
    "postcode": "58110",
    "districtCode": "5804",
    "districtNameTh": "แม่สะเรียง",
    "districtNameEn": "Mae Sariang",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "subDistrictCode": "580402",
    "subDistrictNameTh": "แม่สะเรียง",
    "subDistrictNameEn": "Mae Sariang",
    "postcode": "58110",
    "districtCode": "5804",
    "districtNameTh": "แม่สะเรียง",
    "districtNameEn": "Mae Sariang",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "subDistrictCode": "580403",
    "subDistrictNameTh": "แม่คง",
    "subDistrictNameEn": "Mae Khong",
    "postcode": "58110",
    "districtCode": "5804",
    "districtNameTh": "แม่สะเรียง",
    "districtNameEn": "Mae Sariang",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "subDistrictCode": "580404",
    "subDistrictNameTh": "แม่เหาะ",
    "subDistrictNameEn": "Mae Ho",
    "postcode": "58110",
    "districtCode": "5804",
    "districtNameTh": "แม่สะเรียง",
    "districtNameEn": "Mae Sariang",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "subDistrictCode": "580405",
    "subDistrictNameTh": "แม่ยวม",
    "subDistrictNameEn": "Mae Yuam",
    "postcode": "58110",
    "districtCode": "5804",
    "districtNameTh": "แม่สะเรียง",
    "districtNameEn": "Mae Sariang",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "subDistrictCode": "580406",
    "subDistrictNameTh": "เสาหิน",
    "subDistrictNameEn": "Sao Hin",
    "postcode": "58110",
    "districtCode": "5804",
    "districtNameTh": "แม่สะเรียง",
    "districtNameEn": "Mae Sariang",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "subDistrictCode": "580408",
    "subDistrictNameTh": "ป่าแป๋",
    "subDistrictNameEn": "Pa Pae",
    "postcode": "58110",
    "districtCode": "5804",
    "districtNameTh": "แม่สะเรียง",
    "districtNameEn": "Mae Sariang",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "subDistrictCode": "580501",
    "subDistrictNameTh": "แม่ลาน้อย",
    "subDistrictNameEn": "Mae La Noi",
    "postcode": "58120",
    "districtCode": "5805",
    "districtNameTh": "แม่ลาน้อย",
    "districtNameEn": "Mae La Noi",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "subDistrictCode": "580502",
    "subDistrictNameTh": "แม่ลาหลวง",
    "subDistrictNameEn": "Mae La Luang",
    "postcode": "58120",
    "districtCode": "5805",
    "districtNameTh": "แม่ลาน้อย",
    "districtNameEn": "Mae La Noi",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "subDistrictCode": "580503",
    "subDistrictNameTh": "ท่าผาปุ้ม",
    "subDistrictNameEn": "Tha Pha Pum",
    "postcode": "58120",
    "districtCode": "5805",
    "districtNameTh": "แม่ลาน้อย",
    "districtNameEn": "Mae La Noi",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "subDistrictCode": "580504",
    "subDistrictNameTh": "แม่โถ",
    "subDistrictNameEn": "Mae Tho",
    "postcode": "58120",
    "districtCode": "5805",
    "districtNameTh": "แม่ลาน้อย",
    "districtNameEn": "Mae La Noi",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "subDistrictCode": "580505",
    "subDistrictNameTh": "ห้วยห้อม",
    "subDistrictNameEn": "Huai Hom",
    "postcode": "58120",
    "districtCode": "5805",
    "districtNameTh": "แม่ลาน้อย",
    "districtNameEn": "Mae La Noi",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "subDistrictCode": "580506",
    "subDistrictNameTh": "แม่นาจาง",
    "subDistrictNameEn": "Mae Na Chang",
    "postcode": "58120",
    "districtCode": "5805",
    "districtNameTh": "แม่ลาน้อย",
    "districtNameEn": "Mae La Noi",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "subDistrictCode": "580507",
    "subDistrictNameTh": "สันติคีรี",
    "subDistrictNameEn": "Santi Khiri",
    "postcode": "58120",
    "districtCode": "5805",
    "districtNameTh": "แม่ลาน้อย",
    "districtNameEn": "Mae La Noi",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "subDistrictCode": "580508",
    "subDistrictNameTh": "ขุนแม่ลาน้อย",
    "subDistrictNameEn": "Khun Mae La Noi",
    "postcode": "58120",
    "districtCode": "5805",
    "districtNameTh": "แม่ลาน้อย",
    "districtNameEn": "Mae La Noi",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "subDistrictCode": "580601",
    "subDistrictNameTh": "สบเมย",
    "subDistrictNameEn": "Sop Moei",
    "postcode": "58110",
    "districtCode": "5806",
    "districtNameTh": "สบเมย",
    "districtNameEn": "Sop Moei",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "subDistrictCode": "580602",
    "subDistrictNameTh": "แม่คะตวน",
    "subDistrictNameEn": "Mae Khatuan",
    "postcode": "58110",
    "districtCode": "5806",
    "districtNameTh": "สบเมย",
    "districtNameEn": "Sop Moei",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "subDistrictCode": "580603",
    "subDistrictNameTh": "กองก๋อย",
    "subDistrictNameEn": "Kong Koi",
    "postcode": "58110",
    "districtCode": "5806",
    "districtNameTh": "สบเมย",
    "districtNameEn": "Sop Moei",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "subDistrictCode": "580604",
    "subDistrictNameTh": "แม่สวด",
    "subDistrictNameEn": "Mae Suat",
    "postcode": "58110",
    "districtCode": "5806",
    "districtNameTh": "สบเมย",
    "districtNameEn": "Sop Moei",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "subDistrictCode": "580605",
    "subDistrictNameTh": "ป่าโปง",
    "subDistrictNameEn": "Pa Pong",
    "postcode": "58110",
    "districtCode": "5806",
    "districtNameTh": "สบเมย",
    "districtNameEn": "Sop Moei",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "subDistrictCode": "580606",
    "subDistrictNameTh": "แม่สามแลบ",
    "subDistrictNameEn": "Mae Sam Laep",
    "postcode": "58110",
    "districtCode": "5806",
    "districtNameTh": "สบเมย",
    "districtNameEn": "Sop Moei",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "subDistrictCode": "580701",
    "subDistrictNameTh": "สบป่อง",
    "subDistrictNameEn": "Sop Pong",
    "postcode": "58150",
    "districtCode": "5807",
    "districtNameTh": "ปางมะผ้า",
    "districtNameEn": "Pang Mapha",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "subDistrictCode": "580702",
    "subDistrictNameTh": "ปางมะผ้า",
    "subDistrictNameEn": "Pang Mapha",
    "postcode": "58150",
    "districtCode": "5807",
    "districtNameTh": "ปางมะผ้า",
    "districtNameEn": "Pang Mapha",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "subDistrictCode": "580703",
    "subDistrictNameTh": "ถ้ำลอด",
    "subDistrictNameEn": "Tham Lot",
    "postcode": "58150",
    "districtCode": "5807",
    "districtNameTh": "ปางมะผ้า",
    "districtNameEn": "Pang Mapha",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "subDistrictCode": "580704",
    "subDistrictNameTh": "นาปู่ป้อม",
    "subDistrictNameEn": "Na Pu Pom",
    "postcode": "58150",
    "districtCode": "5807",
    "districtNameTh": "ปางมะผ้า",
    "districtNameEn": "Pang Mapha",
    "provinceCode": "58",
    "provinceNameTh": "แม่ฮ่องสอน",
    "provinceNameEn": "Mae Hong Son"
},
{
    "subDistrictCode": "600101",
    "subDistrictNameTh": "ปากน้ำโพ",
    "subDistrictNameEn": "Pak Nam Pho",
    "postcode": "60000",
    "districtCode": "6001",
    "districtNameTh": "เมืองนครสวรรค์",
    "districtNameEn": "Mueang Nakhon Sawan",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600102",
    "subDistrictNameTh": "กลางแดด",
    "subDistrictNameEn": "Klang Daet",
    "postcode": "60000",
    "districtCode": "6001",
    "districtNameTh": "เมืองนครสวรรค์",
    "districtNameEn": "Mueang Nakhon Sawan",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600103",
    "subDistrictNameTh": "เกรียงไกร",
    "subDistrictNameEn": "Kriangkrai",
    "postcode": "60000",
    "districtCode": "6001",
    "districtNameTh": "เมืองนครสวรรค์",
    "districtNameEn": "Mueang Nakhon Sawan",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600104",
    "subDistrictNameTh": "แควใหญ่",
    "subDistrictNameEn": "Khwae Yai",
    "postcode": "60000",
    "districtCode": "6001",
    "districtNameTh": "เมืองนครสวรรค์",
    "districtNameEn": "Mueang Nakhon Sawan",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600105",
    "subDistrictNameTh": "ตะเคียนเลื่อน",
    "subDistrictNameEn": "Takhian Luean",
    "postcode": "60000",
    "districtCode": "6001",
    "districtNameTh": "เมืองนครสวรรค์",
    "districtNameEn": "Mueang Nakhon Sawan",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600106",
    "subDistrictNameTh": "นครสวรรค์ตก",
    "subDistrictNameEn": "Nakhon Sawan Tok",
    "postcode": "60000",
    "districtCode": "6001",
    "districtNameTh": "เมืองนครสวรรค์",
    "districtNameEn": "Mueang Nakhon Sawan",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600107",
    "subDistrictNameTh": "นครสวรรค์ออก",
    "subDistrictNameEn": "Nakhon Sawan Ok",
    "postcode": "60000",
    "districtCode": "6001",
    "districtNameTh": "เมืองนครสวรรค์",
    "districtNameEn": "Mueang Nakhon Sawan",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600108",
    "subDistrictNameTh": "บางพระหลวง",
    "subDistrictNameEn": "Bang Phra Luang",
    "postcode": "60000",
    "districtCode": "6001",
    "districtNameTh": "เมืองนครสวรรค์",
    "districtNameEn": "Mueang Nakhon Sawan",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600109",
    "subDistrictNameTh": "บางม่วง",
    "subDistrictNameEn": "Bang Muang",
    "postcode": "60000",
    "districtCode": "6001",
    "districtNameTh": "เมืองนครสวรรค์",
    "districtNameEn": "Mueang Nakhon Sawan",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600110",
    "subDistrictNameTh": "บ้านมะเกลือ",
    "subDistrictNameEn": "Ban Makluea",
    "postcode": "60000",
    "districtCode": "6001",
    "districtNameTh": "เมืองนครสวรรค์",
    "districtNameEn": "Mueang Nakhon Sawan",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600111",
    "subDistrictNameTh": "บ้านแก่ง",
    "subDistrictNameEn": "Ban Kaeng",
    "postcode": "60000",
    "districtCode": "6001",
    "districtNameTh": "เมืองนครสวรรค์",
    "districtNameEn": "Mueang Nakhon Sawan",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600112",
    "subDistrictNameTh": "พระนอน",
    "subDistrictNameEn": "Phra Non",
    "postcode": "60000",
    "districtCode": "6001",
    "districtNameTh": "เมืองนครสวรรค์",
    "districtNameEn": "Mueang Nakhon Sawan",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600113",
    "subDistrictNameTh": "วัดไทร",
    "subDistrictNameEn": "Wat Sai",
    "postcode": "60000",
    "districtCode": "6001",
    "districtNameTh": "เมืองนครสวรรค์",
    "districtNameEn": "Mueang Nakhon Sawan",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600114",
    "subDistrictNameTh": "หนองกรด",
    "subDistrictNameEn": "Nong Krot",
    "postcode": "60240",
    "districtCode": "6001",
    "districtNameTh": "เมืองนครสวรรค์",
    "districtNameEn": "Mueang Nakhon Sawan",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600115",
    "subDistrictNameTh": "หนองกระโดน",
    "subDistrictNameEn": "Nong Kradon",
    "postcode": "60240",
    "districtCode": "6001",
    "districtNameTh": "เมืองนครสวรรค์",
    "districtNameEn": "Mueang Nakhon Sawan",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600116",
    "subDistrictNameTh": "หนองปลิง",
    "subDistrictNameEn": "Nong Pling",
    "postcode": "60000",
    "districtCode": "6001",
    "districtNameTh": "เมืองนครสวรรค์",
    "districtNameEn": "Mueang Nakhon Sawan",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600117",
    "subDistrictNameTh": "บึงเสนาท",
    "subDistrictNameEn": "Bueng Senat",
    "postcode": "60000",
    "districtCode": "6001",
    "districtNameTh": "เมืองนครสวรรค์",
    "districtNameEn": "Mueang Nakhon Sawan",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600201",
    "subDistrictNameTh": "โกรกพระ",
    "subDistrictNameEn": "Krok Phra",
    "postcode": "60170",
    "districtCode": "6002",
    "districtNameTh": "โกรกพระ",
    "districtNameEn": "Krok Phra",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600202",
    "subDistrictNameTh": "ยางตาล",
    "subDistrictNameEn": "Yang Tan",
    "postcode": "60170",
    "districtCode": "6002",
    "districtNameTh": "โกรกพระ",
    "districtNameEn": "Krok Phra",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600203",
    "subDistrictNameTh": "บางมะฝ่อ",
    "subDistrictNameEn": "Bang Mafo",
    "postcode": "60170",
    "districtCode": "6002",
    "districtNameTh": "โกรกพระ",
    "districtNameEn": "Krok Phra",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600204",
    "subDistrictNameTh": "บางประมุง",
    "subDistrictNameEn": "Bang Pramung",
    "postcode": "60170",
    "districtCode": "6002",
    "districtNameTh": "โกรกพระ",
    "districtNameEn": "Krok Phra",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600205",
    "subDistrictNameTh": "นากลาง",
    "subDistrictNameEn": "Na Klang",
    "postcode": "60170",
    "districtCode": "6002",
    "districtNameTh": "โกรกพระ",
    "districtNameEn": "Krok Phra",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600206",
    "subDistrictNameTh": "ศาลาแดง",
    "subDistrictNameEn": "Sala Daeng",
    "postcode": "60170",
    "districtCode": "6002",
    "districtNameTh": "โกรกพระ",
    "districtNameEn": "Krok Phra",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600207",
    "subDistrictNameTh": "เนินกว้าว",
    "subDistrictNameEn": "Noen Kwao",
    "postcode": "60170",
    "districtCode": "6002",
    "districtNameTh": "โกรกพระ",
    "districtNameEn": "Krok Phra",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600208",
    "subDistrictNameTh": "เนินศาลา",
    "subDistrictNameEn": "Noen Sala",
    "postcode": "60170",
    "districtCode": "6002",
    "districtNameTh": "โกรกพระ",
    "districtNameEn": "Krok Phra",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600209",
    "subDistrictNameTh": "หาดสูง",
    "subDistrictNameEn": "Hat Sung",
    "postcode": "60170",
    "districtCode": "6002",
    "districtNameTh": "โกรกพระ",
    "districtNameEn": "Krok Phra",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600301",
    "subDistrictNameTh": "ชุมแสง",
    "subDistrictNameEn": "Chum Saeng",
    "postcode": "60120",
    "districtCode": "6003",
    "districtNameTh": "ชุมแสง",
    "districtNameEn": "Chum Saeng",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600302",
    "subDistrictNameTh": "ทับกฤช",
    "subDistrictNameEn": "Thap Krit",
    "postcode": "60250",
    "districtCode": "6003",
    "districtNameTh": "ชุมแสง",
    "districtNameEn": "Chum Saeng",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600303",
    "subDistrictNameTh": "พิกุล",
    "subDistrictNameEn": "Phikun",
    "postcode": "60120",
    "districtCode": "6003",
    "districtNameTh": "ชุมแสง",
    "districtNameEn": "Chum Saeng",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600304",
    "subDistrictNameTh": "เกยไชย",
    "subDistrictNameEn": "Koei Chai",
    "postcode": "60120",
    "districtCode": "6003",
    "districtNameTh": "ชุมแสง",
    "districtNameEn": "Chum Saeng",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600305",
    "subDistrictNameTh": "ท่าไม้",
    "subDistrictNameEn": "Tha Mai",
    "postcode": "60120",
    "districtCode": "6003",
    "districtNameTh": "ชุมแสง",
    "districtNameEn": "Chum Saeng",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600306",
    "subDistrictNameTh": "บางเคียน",
    "subDistrictNameEn": "Bang Khian",
    "postcode": "60120",
    "districtCode": "6003",
    "districtNameTh": "ชุมแสง",
    "districtNameEn": "Chum Saeng",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600307",
    "subDistrictNameTh": "หนองกระเจา",
    "subDistrictNameEn": "Nong Krachao",
    "postcode": "60120",
    "districtCode": "6003",
    "districtNameTh": "ชุมแสง",
    "districtNameEn": "Chum Saeng",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600308",
    "subDistrictNameTh": "พันลาน",
    "subDistrictNameEn": "Phan Lan",
    "postcode": "60250",
    "districtCode": "6003",
    "districtNameTh": "ชุมแสง",
    "districtNameEn": "Chum Saeng",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600309",
    "subDistrictNameTh": "โคกหม้อ",
    "subDistrictNameEn": "Khok Mo",
    "postcode": "60120",
    "districtCode": "6003",
    "districtNameTh": "ชุมแสง",
    "districtNameEn": "Chum Saeng",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600310",
    "subDistrictNameTh": "ไผ่สิงห์",
    "subDistrictNameEn": "Phai Sing",
    "postcode": "60120",
    "districtCode": "6003",
    "districtNameTh": "ชุมแสง",
    "districtNameEn": "Chum Saeng",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600311",
    "subDistrictNameTh": "ฆะมัง",
    "subDistrictNameEn": "Khamang",
    "postcode": "60120",
    "districtCode": "6003",
    "districtNameTh": "ชุมแสง",
    "districtNameEn": "Chum Saeng",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600312",
    "subDistrictNameTh": "ทับกฤชใต้",
    "subDistrictNameEn": "Thap Krit Tai",
    "postcode": "60250",
    "districtCode": "6003",
    "districtNameTh": "ชุมแสง",
    "districtNameEn": "Chum Saeng",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600401",
    "subDistrictNameTh": "หนองบัว",
    "subDistrictNameEn": "Nong Bua",
    "postcode": "60110",
    "districtCode": "6004",
    "districtNameTh": "หนองบัว",
    "districtNameEn": "Nong Bua",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600402",
    "subDistrictNameTh": "หนองกลับ",
    "subDistrictNameEn": "Nong Klap",
    "postcode": "60110",
    "districtCode": "6004",
    "districtNameTh": "หนองบัว",
    "districtNameEn": "Nong Bua",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600403",
    "subDistrictNameTh": "ธารทหาร",
    "subDistrictNameEn": "Than Thahan",
    "postcode": "60110",
    "districtCode": "6004",
    "districtNameTh": "หนองบัว",
    "districtNameEn": "Nong Bua",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600404",
    "subDistrictNameTh": "ห้วยร่วม",
    "subDistrictNameEn": "Huai Ruam",
    "postcode": "60110",
    "districtCode": "6004",
    "districtNameTh": "หนองบัว",
    "districtNameEn": "Nong Bua",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600405",
    "subDistrictNameTh": "ห้วยถั่วใต้",
    "subDistrictNameEn": "Huai Thua Tai",
    "postcode": "60110",
    "districtCode": "6004",
    "districtNameTh": "หนองบัว",
    "districtNameEn": "Nong Bua",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600406",
    "subDistrictNameTh": "ห้วยถั่วเหนือ",
    "subDistrictNameEn": "Huai Thua Nuea",
    "postcode": "60110",
    "districtCode": "6004",
    "districtNameTh": "หนองบัว",
    "districtNameEn": "Nong Bua",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600407",
    "subDistrictNameTh": "ห้วยใหญ่",
    "subDistrictNameEn": "Huai Yai",
    "postcode": "60110",
    "districtCode": "6004",
    "districtNameTh": "หนองบัว",
    "districtNameEn": "Nong Bua",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600408",
    "subDistrictNameTh": "ทุ่งทอง",
    "subDistrictNameEn": "Thung Thong",
    "postcode": "60110",
    "districtCode": "6004",
    "districtNameTh": "หนองบัว",
    "districtNameEn": "Nong Bua",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600409",
    "subDistrictNameTh": "วังบ่อ",
    "subDistrictNameEn": "Wang Bo",
    "postcode": "60110",
    "districtCode": "6004",
    "districtNameTh": "หนองบัว",
    "districtNameEn": "Nong Bua",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600501",
    "subDistrictNameTh": "ท่างิ้ว",
    "subDistrictNameEn": "Tha Ngio",
    "postcode": "60180",
    "districtCode": "6005",
    "districtNameTh": "บรรพตพิสัย",
    "districtNameEn": "Banphot Phisai",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600502",
    "subDistrictNameTh": "บางตาหงาย",
    "subDistrictNameEn": "Bang Ta Ngai",
    "postcode": "60180",
    "districtCode": "6005",
    "districtNameTh": "บรรพตพิสัย",
    "districtNameEn": "Banphot Phisai",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600503",
    "subDistrictNameTh": "หูกวาง",
    "subDistrictNameEn": "Hu Kwang",
    "postcode": "60180",
    "districtCode": "6005",
    "districtNameTh": "บรรพตพิสัย",
    "districtNameEn": "Banphot Phisai",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600504",
    "subDistrictNameTh": "อ่างทอง",
    "subDistrictNameEn": "Ang Thong",
    "postcode": "60180",
    "districtCode": "6005",
    "districtNameTh": "บรรพตพิสัย",
    "districtNameEn": "Banphot Phisai",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600505",
    "subDistrictNameTh": "บ้านแดน",
    "subDistrictNameEn": "Ban Daen",
    "postcode": "60180",
    "districtCode": "6005",
    "districtNameTh": "บรรพตพิสัย",
    "districtNameEn": "Banphot Phisai",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600506",
    "subDistrictNameTh": "บางแก้ว",
    "subDistrictNameEn": "Bang Kaeo",
    "postcode": "60180",
    "districtCode": "6005",
    "districtNameTh": "บรรพตพิสัย",
    "districtNameEn": "Banphot Phisai",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600507",
    "subDistrictNameTh": "ตาขีด",
    "subDistrictNameEn": "Ta Khit",
    "postcode": "60180",
    "districtCode": "6005",
    "districtNameTh": "บรรพตพิสัย",
    "districtNameEn": "Banphot Phisai",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600508",
    "subDistrictNameTh": "ตาสัง",
    "subDistrictNameEn": "Ta Sang",
    "postcode": "60180",
    "districtCode": "6005",
    "districtNameTh": "บรรพตพิสัย",
    "districtNameEn": "Banphot Phisai",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600509",
    "subDistrictNameTh": "ด่านช้าง",
    "subDistrictNameEn": "Dan Chang",
    "postcode": "60180",
    "districtCode": "6005",
    "districtNameTh": "บรรพตพิสัย",
    "districtNameEn": "Banphot Phisai",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600510",
    "subDistrictNameTh": "หนองกรด",
    "subDistrictNameEn": "Nong Krot",
    "postcode": "60180",
    "districtCode": "6005",
    "districtNameTh": "บรรพตพิสัย",
    "districtNameEn": "Banphot Phisai",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600511",
    "subDistrictNameTh": "หนองตางู",
    "subDistrictNameEn": "Nong Ta Ngu",
    "postcode": "60180",
    "districtCode": "6005",
    "districtNameTh": "บรรพตพิสัย",
    "districtNameEn": "Banphot Phisai",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600512",
    "subDistrictNameTh": "บึงปลาทู",
    "subDistrictNameEn": "Bueng Pla Thu",
    "postcode": "60180",
    "districtCode": "6005",
    "districtNameTh": "บรรพตพิสัย",
    "districtNameEn": "Banphot Phisai",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600513",
    "subDistrictNameTh": "เจริญผล",
    "subDistrictNameEn": "Charoen Phon",
    "postcode": "60180",
    "districtCode": "6005",
    "districtNameTh": "บรรพตพิสัย",
    "districtNameEn": "Banphot Phisai",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600601",
    "subDistrictNameTh": "มหาโพธิ",
    "subDistrictNameEn": "Maha Phot",
    "postcode": "60230",
    "districtCode": "6006",
    "districtNameTh": "เก้าเลี้ยว",
    "districtNameEn": "Kao Liao",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600602",
    "subDistrictNameTh": "เก้าเลี้ยว",
    "subDistrictNameEn": "Kao Liao",
    "postcode": "60230",
    "districtCode": "6006",
    "districtNameTh": "เก้าเลี้ยว",
    "districtNameEn": "Kao Liao",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600603",
    "subDistrictNameTh": "หนองเต่า",
    "subDistrictNameEn": "Nong Tao",
    "postcode": "60230",
    "districtCode": "6006",
    "districtNameTh": "เก้าเลี้ยว",
    "districtNameEn": "Kao Liao",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600604",
    "subDistrictNameTh": "เขาดิน",
    "subDistrictNameEn": "Khao Din",
    "postcode": "60230",
    "districtCode": "6006",
    "districtNameTh": "เก้าเลี้ยว",
    "districtNameEn": "Kao Liao",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600605",
    "subDistrictNameTh": "หัวดง",
    "subDistrictNameEn": "Hua Dong",
    "postcode": "60230",
    "districtCode": "6006",
    "districtNameTh": "เก้าเลี้ยว",
    "districtNameEn": "Kao Liao",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600701",
    "subDistrictNameTh": "ตาคลี",
    "subDistrictNameEn": "Takhli",
    "postcode": "60140",
    "districtCode": "6007",
    "districtNameTh": "ตาคลี",
    "districtNameEn": "Takhli",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600702",
    "subDistrictNameTh": "ช่องแค",
    "subDistrictNameEn": "Chong Khae",
    "postcode": "60210",
    "districtCode": "6007",
    "districtNameTh": "ตาคลี",
    "districtNameEn": "Takhli",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600703",
    "subDistrictNameTh": "จันเสน",
    "subDistrictNameEn": "Chan Sen",
    "postcode": "60260",
    "districtCode": "6007",
    "districtNameTh": "ตาคลี",
    "districtNameEn": "Takhli",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600704",
    "subDistrictNameTh": "ห้วยหอม",
    "subDistrictNameEn": "Huai Hom",
    "postcode": "60210",
    "districtCode": "6007",
    "districtNameTh": "ตาคลี",
    "districtNameEn": "Takhli",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600705",
    "subDistrictNameTh": "หัวหวาย",
    "subDistrictNameEn": "Hua Wai",
    "postcode": "60140",
    "districtCode": "6007",
    "districtNameTh": "ตาคลี",
    "districtNameEn": "Takhli",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600706",
    "subDistrictNameTh": "หนองโพ",
    "subDistrictNameEn": "Nong Pho",
    "postcode": "60140",
    "districtCode": "6007",
    "districtNameTh": "ตาคลี",
    "districtNameEn": "Takhli",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600707",
    "subDistrictNameTh": "หนองหม้อ",
    "subDistrictNameEn": "Nong Mo",
    "postcode": "60140",
    "districtCode": "6007",
    "districtNameTh": "ตาคลี",
    "districtNameEn": "Takhli",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600708",
    "subDistrictNameTh": "สร้อยทอง",
    "subDistrictNameEn": "Soi Thong",
    "postcode": "60210",
    "districtCode": "6007",
    "districtNameTh": "ตาคลี",
    "districtNameEn": "Takhli",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600709",
    "subDistrictNameTh": "ลาดทิพรส",
    "subDistrictNameEn": "Lat Thippharot",
    "postcode": "60260",
    "districtCode": "6007",
    "districtNameTh": "ตาคลี",
    "districtNameEn": "Takhli",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600710",
    "subDistrictNameTh": "พรหมนิมิต",
    "subDistrictNameEn": "Phrom Nimit",
    "postcode": "60210",
    "districtCode": "6007",
    "districtNameTh": "ตาคลี",
    "districtNameEn": "Takhli",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600801",
    "subDistrictNameTh": "ท่าตะโก",
    "subDistrictNameEn": "Tha Tako",
    "postcode": "60160",
    "districtCode": "6008",
    "districtNameTh": "ท่าตะโก",
    "districtNameEn": "Tha Tako",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600802",
    "subDistrictNameTh": "พนมรอก",
    "subDistrictNameEn": "Phanom Rok",
    "postcode": "60160",
    "districtCode": "6008",
    "districtNameTh": "ท่าตะโก",
    "districtNameEn": "Tha Tako",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600803",
    "subDistrictNameTh": "หัวถนน",
    "subDistrictNameEn": "Hua Thanon",
    "postcode": "60160",
    "districtCode": "6008",
    "districtNameTh": "ท่าตะโก",
    "districtNameEn": "Tha Tako",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600804",
    "subDistrictNameTh": "สายลำโพง",
    "subDistrictNameEn": "Sai Lamphong",
    "postcode": "60160",
    "districtCode": "6008",
    "districtNameTh": "ท่าตะโก",
    "districtNameEn": "Tha Tako",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600805",
    "subDistrictNameTh": "วังมหากร",
    "subDistrictNameEn": "Wang Mahakon",
    "postcode": "60160",
    "districtCode": "6008",
    "districtNameTh": "ท่าตะโก",
    "districtNameEn": "Tha Tako",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600806",
    "subDistrictNameTh": "ดอนคา",
    "subDistrictNameEn": "Don Kha",
    "postcode": "60160",
    "districtCode": "6008",
    "districtNameTh": "ท่าตะโก",
    "districtNameEn": "Tha Tako",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600807",
    "subDistrictNameTh": "ทำนบ",
    "subDistrictNameEn": "Thamnop",
    "postcode": "60160",
    "districtCode": "6008",
    "districtNameTh": "ท่าตะโก",
    "districtNameEn": "Tha Tako",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600808",
    "subDistrictNameTh": "วังใหญ่",
    "subDistrictNameEn": "Wang Yai",
    "postcode": "60160",
    "districtCode": "6008",
    "districtNameTh": "ท่าตะโก",
    "districtNameEn": "Tha Tako",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600809",
    "subDistrictNameTh": "พนมเศษ",
    "subDistrictNameEn": "Phanom Set",
    "postcode": "60160",
    "districtCode": "6008",
    "districtNameTh": "ท่าตะโก",
    "districtNameEn": "Tha Tako",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600810",
    "subDistrictNameTh": "หนองหลวง",
    "subDistrictNameEn": "Nong Luang",
    "postcode": "60160",
    "districtCode": "6008",
    "districtNameTh": "ท่าตะโก",
    "districtNameEn": "Tha Tako",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600901",
    "subDistrictNameTh": "โคกเดื่อ",
    "subDistrictNameEn": "Khok Duea",
    "postcode": "60220",
    "districtCode": "6009",
    "districtNameTh": "ไพศาลี",
    "districtNameEn": "Phaisali",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600902",
    "subDistrictNameTh": "สำโรงชัย",
    "subDistrictNameEn": "Samrong Chai",
    "postcode": "60220",
    "districtCode": "6009",
    "districtNameTh": "ไพศาลี",
    "districtNameEn": "Phaisali",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600903",
    "subDistrictNameTh": "วังน้ำลัด",
    "subDistrictNameEn": "Wang Nam Lat",
    "postcode": "60220",
    "districtCode": "6009",
    "districtNameTh": "ไพศาลี",
    "districtNameEn": "Phaisali",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600904",
    "subDistrictNameTh": "ตะคร้อ",
    "subDistrictNameEn": "Takhro",
    "postcode": "60220",
    "districtCode": "6009",
    "districtNameTh": "ไพศาลี",
    "districtNameEn": "Phaisali",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600905",
    "subDistrictNameTh": "โพธิ์ประสาท",
    "subDistrictNameEn": "Pho Prasat",
    "postcode": "60220",
    "districtCode": "6009",
    "districtNameTh": "ไพศาลี",
    "districtNameEn": "Phaisali",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600906",
    "subDistrictNameTh": "วังข่อย",
    "subDistrictNameEn": "Wang Khoi",
    "postcode": "60220",
    "districtCode": "6009",
    "districtNameTh": "ไพศาลี",
    "districtNameEn": "Phaisali",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600907",
    "subDistrictNameTh": "นาขอม",
    "subDistrictNameEn": "Na Khom",
    "postcode": "60220",
    "districtCode": "6009",
    "districtNameTh": "ไพศาลี",
    "districtNameEn": "Phaisali",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "600908",
    "subDistrictNameTh": "ไพศาลี",
    "subDistrictNameEn": "Phaisali",
    "postcode": "60220",
    "districtCode": "6009",
    "districtNameTh": "ไพศาลี",
    "districtNameEn": "Phaisali",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "601001",
    "subDistrictNameTh": "พยุหะ",
    "subDistrictNameEn": "Phayuha",
    "postcode": "60130",
    "districtCode": "6010",
    "districtNameTh": "พยุหะคีรี",
    "districtNameEn": "Phayuha Khiri",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "601002",
    "subDistrictNameTh": "เนินมะกอก",
    "subDistrictNameEn": "Noen Makok",
    "postcode": "60130",
    "districtCode": "6010",
    "districtNameTh": "พยุหะคีรี",
    "districtNameEn": "Phayuha Khiri",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "601003",
    "subDistrictNameTh": "นิคมเขาบ่อแก้ว",
    "subDistrictNameEn": "Nikhom Khao Bo Kaeo",
    "postcode": "60130",
    "districtCode": "6010",
    "districtNameTh": "พยุหะคีรี",
    "districtNameEn": "Phayuha Khiri",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "601004",
    "subDistrictNameTh": "ม่วงหัก",
    "subDistrictNameEn": "Muang Hak",
    "postcode": "60130",
    "districtCode": "6010",
    "districtNameTh": "พยุหะคีรี",
    "districtNameEn": "Phayuha Khiri",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "601005",
    "subDistrictNameTh": "ยางขาว",
    "subDistrictNameEn": "Yang Khao",
    "postcode": "60130",
    "districtCode": "6010",
    "districtNameTh": "พยุหะคีรี",
    "districtNameEn": "Phayuha Khiri",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "601006",
    "subDistrictNameTh": "ย่านมัทรี",
    "subDistrictNameEn": "Yan Matsi",
    "postcode": "60130",
    "districtCode": "6010",
    "districtNameTh": "พยุหะคีรี",
    "districtNameEn": "Phayuha Khiri",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "601007",
    "subDistrictNameTh": "เขาทอง",
    "subDistrictNameEn": "Khao Thong",
    "postcode": "60130",
    "districtCode": "6010",
    "districtNameTh": "พยุหะคีรี",
    "districtNameEn": "Phayuha Khiri",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "601008",
    "subDistrictNameTh": "ท่าน้ำอ้อย",
    "subDistrictNameEn": "Tha Nam Oi",
    "postcode": "60130",
    "districtCode": "6010",
    "districtNameTh": "พยุหะคีรี",
    "districtNameEn": "Phayuha Khiri",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "601009",
    "subDistrictNameTh": "น้ำทรง",
    "subDistrictNameEn": "Nam Song",
    "postcode": "60130",
    "districtCode": "6010",
    "districtNameTh": "พยุหะคีรี",
    "districtNameEn": "Phayuha Khiri",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "601010",
    "subDistrictNameTh": "เขากะลา",
    "subDistrictNameEn": "Khao Kala",
    "postcode": "60130",
    "districtCode": "6010",
    "districtNameTh": "พยุหะคีรี",
    "districtNameEn": "Phayuha Khiri",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "601011",
    "subDistrictNameTh": "สระทะเล",
    "subDistrictNameEn": "Sa Thale",
    "postcode": "60130",
    "districtCode": "6010",
    "districtNameTh": "พยุหะคีรี",
    "districtNameEn": "Phayuha Khiri",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "601101",
    "subDistrictNameTh": "ลาดยาว",
    "subDistrictNameEn": "Lat Yao",
    "postcode": "60150",
    "districtCode": "6011",
    "districtNameTh": "ลาดยาว",
    "districtNameEn": "Lat Yao",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "601102",
    "subDistrictNameTh": "ห้วยน้ำหอม",
    "subDistrictNameEn": "Huai Nam Hom",
    "postcode": "60150",
    "districtCode": "6011",
    "districtNameTh": "ลาดยาว",
    "districtNameEn": "Lat Yao",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "601103",
    "subDistrictNameTh": "วังม้า",
    "subDistrictNameEn": "Wang Ma",
    "postcode": "60150",
    "districtCode": "6011",
    "districtNameTh": "ลาดยาว",
    "districtNameEn": "Lat Yao",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "601104",
    "subDistrictNameTh": "วังเมือง",
    "subDistrictNameEn": "Wang Mueang",
    "postcode": "60150",
    "districtCode": "6011",
    "districtNameTh": "ลาดยาว",
    "districtNameEn": "Lat Yao",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "601105",
    "subDistrictNameTh": "สร้อยละคร",
    "subDistrictNameEn": "Soi Lakhon",
    "postcode": "60150",
    "districtCode": "6011",
    "districtNameTh": "ลาดยาว",
    "districtNameEn": "Lat Yao",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "601106",
    "subDistrictNameTh": "มาบแก",
    "subDistrictNameEn": "Map Kae",
    "postcode": "60150",
    "districtCode": "6011",
    "districtNameTh": "ลาดยาว",
    "districtNameEn": "Lat Yao",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "601107",
    "subDistrictNameTh": "หนองยาว",
    "subDistrictNameEn": "Nong Yao",
    "postcode": "60150",
    "districtCode": "6011",
    "districtNameTh": "ลาดยาว",
    "districtNameEn": "Lat Yao",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "601108",
    "subDistrictNameTh": "หนองนมวัว",
    "subDistrictNameEn": "Nong Nom Wua",
    "postcode": "60150",
    "districtCode": "6011",
    "districtNameTh": "ลาดยาว",
    "districtNameEn": "Lat Yao",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "601109",
    "subDistrictNameTh": "บ้านไร่",
    "subDistrictNameEn": "Ban Rai",
    "postcode": "60150",
    "districtCode": "6011",
    "districtNameTh": "ลาดยาว",
    "districtNameEn": "Lat Yao",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "601110",
    "subDistrictNameTh": "เนินขี้เหล็ก",
    "subDistrictNameEn": "Noen Khilek",
    "postcode": "60150",
    "districtCode": "6011",
    "districtNameTh": "ลาดยาว",
    "districtNameEn": "Lat Yao",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "601116",
    "subDistrictNameTh": "ศาลเจ้าไก่ต่อ",
    "subDistrictNameEn": "San Chao Kai to",
    "postcode": "60150",
    "districtCode": "6011",
    "districtNameTh": "ลาดยาว",
    "districtNameEn": "Lat Yao",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "601117",
    "subDistrictNameTh": "สระแก้ว",
    "subDistrictNameEn": "Sa Kaeo",
    "postcode": "60150",
    "districtCode": "6011",
    "districtNameTh": "ลาดยาว",
    "districtNameEn": "Lat Yao",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "601201",
    "subDistrictNameTh": "ตากฟ้า",
    "subDistrictNameEn": "Tak Fa",
    "postcode": "60190",
    "districtCode": "6012",
    "districtNameTh": "ตากฟ้า",
    "districtNameEn": "Tak Fa",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "601202",
    "subDistrictNameTh": "ลำพยนต์",
    "subDistrictNameEn": "Lam Phayon",
    "postcode": "60190",
    "districtCode": "6012",
    "districtNameTh": "ตากฟ้า",
    "districtNameEn": "Tak Fa",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "601203",
    "subDistrictNameTh": "สุขสำราญ",
    "subDistrictNameEn": "Suk Samran",
    "postcode": "60190",
    "districtCode": "6012",
    "districtNameTh": "ตากฟ้า",
    "districtNameEn": "Tak Fa",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "601204",
    "subDistrictNameTh": "หนองพิกุล",
    "subDistrictNameEn": "Nong Phikun",
    "postcode": "60190",
    "districtCode": "6012",
    "districtNameTh": "ตากฟ้า",
    "districtNameEn": "Tak Fa",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "601205",
    "subDistrictNameTh": "พุนกยูง",
    "subDistrictNameEn": "Phu Nok Yung",
    "postcode": "60190",
    "districtCode": "6012",
    "districtNameTh": "ตากฟ้า",
    "districtNameEn": "Tak Fa",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "601206",
    "subDistrictNameTh": "อุดมธัญญา",
    "subDistrictNameEn": "Udom Thanya",
    "postcode": "60190",
    "districtCode": "6012",
    "districtNameTh": "ตากฟ้า",
    "districtNameEn": "Tak Fa",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "601207",
    "subDistrictNameTh": "เขาชายธง",
    "subDistrictNameEn": "Khao Chai Thong",
    "postcode": "60190",
    "districtCode": "6012",
    "districtNameTh": "ตากฟ้า",
    "districtNameEn": "Tak Fa",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "601301",
    "subDistrictNameTh": "แม่วงก์",
    "subDistrictNameEn": "Mae Wong",
    "postcode": "60150",
    "districtCode": "6013",
    "districtNameTh": "แม่วงก์",
    "districtNameEn": "Mae Wong",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "601303",
    "subDistrictNameTh": "แม่เล่ย์",
    "subDistrictNameEn": "Mae Le",
    "postcode": "60150",
    "districtCode": "6013",
    "districtNameTh": "แม่วงก์",
    "districtNameEn": "Mae Wong",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "601304",
    "subDistrictNameTh": "วังซ่าน",
    "subDistrictNameEn": "Wang San",
    "postcode": "60150",
    "districtCode": "6013",
    "districtNameTh": "แม่วงก์",
    "districtNameEn": "Mae Wong",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "601305",
    "subDistrictNameTh": "เขาชนกัน",
    "subDistrictNameEn": "Khao Chon Kan",
    "postcode": "60150",
    "districtCode": "6013",
    "districtNameTh": "แม่วงก์",
    "districtNameEn": "Mae Wong",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "601401",
    "subDistrictNameTh": "แม่เปิน",
    "subDistrictNameEn": "Mae Poen",
    "postcode": "60150",
    "districtCode": "6014",
    "districtNameTh": "แม่เปิน",
    "districtNameEn": "Mae Poen",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "601501",
    "subDistrictNameTh": "ชุมตาบง",
    "subDistrictNameEn": "Chum Ta Bong",
    "postcode": "60150",
    "districtCode": "6015",
    "districtNameTh": "ชุมตาบง",
    "districtNameEn": "Chum Ta Bong",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "601502",
    "subDistrictNameTh": "ปางสวรรค์",
    "subDistrictNameEn": "Pang Sawan",
    "postcode": "60150",
    "districtCode": "6015",
    "districtNameTh": "ชุมตาบง",
    "districtNameEn": "Chum Ta Bong",
    "provinceCode": "60",
    "provinceNameTh": "นครสวรรค์",
    "provinceNameEn": "Nakhon Sawan"
},
{
    "subDistrictCode": "610101",
    "subDistrictNameTh": "อุทัยใหม่",
    "subDistrictNameEn": "Uthai Mai",
    "postcode": "61000",
    "districtCode": "6101",
    "districtNameTh": "เมืองอุทัยธานี",
    "districtNameEn": "Mueang Uthai Thani",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610102",
    "subDistrictNameTh": "น้ำซึม",
    "subDistrictNameEn": "Nam Suem",
    "postcode": "61000",
    "districtCode": "6101",
    "districtNameTh": "เมืองอุทัยธานี",
    "districtNameEn": "Mueang Uthai Thani",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610103",
    "subDistrictNameTh": "สะแกกรัง",
    "subDistrictNameEn": "Sakae Krang",
    "postcode": "61000",
    "districtCode": "6101",
    "districtNameTh": "เมืองอุทัยธานี",
    "districtNameEn": "Mueang Uthai Thani",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610104",
    "subDistrictNameTh": "ดอนขวาง",
    "subDistrictNameEn": "Don Khwang",
    "postcode": "61000",
    "districtCode": "6101",
    "districtNameTh": "เมืองอุทัยธานี",
    "districtNameEn": "Mueang Uthai Thani",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610105",
    "subDistrictNameTh": "หาดทนง",
    "subDistrictNameEn": "Hat Thanong",
    "postcode": "61000",
    "districtCode": "6101",
    "districtNameTh": "เมืองอุทัยธานี",
    "districtNameEn": "Mueang Uthai Thani",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610106",
    "subDistrictNameTh": "เกาะเทโพ",
    "subDistrictNameEn": "Ko Thepho",
    "postcode": "61000",
    "districtCode": "6101",
    "districtNameTh": "เมืองอุทัยธานี",
    "districtNameEn": "Mueang Uthai Thani",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610107",
    "subDistrictNameTh": "ท่าซุง",
    "subDistrictNameEn": "Tha Sung",
    "postcode": "61000",
    "districtCode": "6101",
    "districtNameTh": "เมืองอุทัยธานี",
    "districtNameEn": "Mueang Uthai Thani",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610108",
    "subDistrictNameTh": "หนองแก",
    "subDistrictNameEn": "Nong Kae",
    "postcode": "61000",
    "districtCode": "6101",
    "districtNameTh": "เมืองอุทัยธานี",
    "districtNameEn": "Mueang Uthai Thani",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610109",
    "subDistrictNameTh": "โนนเหล็ก",
    "subDistrictNameEn": "Non Lek",
    "postcode": "61000",
    "districtCode": "6101",
    "districtNameTh": "เมืองอุทัยธานี",
    "districtNameEn": "Mueang Uthai Thani",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610110",
    "subDistrictNameTh": "หนองเต่า",
    "subDistrictNameEn": "Nong Tao",
    "postcode": "61000",
    "districtCode": "6101",
    "districtNameTh": "เมืองอุทัยธานี",
    "districtNameEn": "Mueang Uthai Thani",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610111",
    "subDistrictNameTh": "หนองไผ่แบน",
    "subDistrictNameEn": "Nong Phai Baen",
    "postcode": "61000",
    "districtCode": "6101",
    "districtNameTh": "เมืองอุทัยธานี",
    "districtNameEn": "Mueang Uthai Thani",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610112",
    "subDistrictNameTh": "หนองพังค่า",
    "subDistrictNameEn": "Nong Phang Kha",
    "postcode": "61000",
    "districtCode": "6101",
    "districtNameTh": "เมืองอุทัยธานี",
    "districtNameEn": "Mueang Uthai Thani",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610113",
    "subDistrictNameTh": "ทุ่งใหญ่",
    "subDistrictNameEn": "Thung Yai",
    "postcode": "61000",
    "districtCode": "6101",
    "districtNameTh": "เมืองอุทัยธานี",
    "districtNameEn": "Mueang Uthai Thani",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610114",
    "subDistrictNameTh": "เนินแจง",
    "subDistrictNameEn": "Noen Chaeng",
    "postcode": "61000",
    "districtCode": "6101",
    "districtNameTh": "เมืองอุทัยธานี",
    "districtNameEn": "Mueang Uthai Thani",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610201",
    "subDistrictNameTh": "ทัพทัน",
    "subDistrictNameEn": "Thap Than",
    "postcode": "61120",
    "districtCode": "6102",
    "districtNameTh": "ทัพทัน",
    "districtNameEn": "Thap Than",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610202",
    "subDistrictNameTh": "ทุ่งนาไทย",
    "subDistrictNameEn": "Thung Na Thai",
    "postcode": "61120",
    "districtCode": "6102",
    "districtNameTh": "ทัพทัน",
    "districtNameEn": "Thap Than",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610203",
    "subDistrictNameTh": "เขาขี้ฝอย",
    "subDistrictNameEn": "Khao Khi Foi",
    "postcode": "61120",
    "districtCode": "6102",
    "districtNameTh": "ทัพทัน",
    "districtNameEn": "Thap Than",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610204",
    "subDistrictNameTh": "หนองหญ้าปล้อง",
    "subDistrictNameEn": "Nong Ya Plong",
    "postcode": "61120",
    "districtCode": "6102",
    "districtNameTh": "ทัพทัน",
    "districtNameEn": "Thap Than",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610205",
    "subDistrictNameTh": "โคกหม้อ",
    "subDistrictNameEn": "Khok Mo",
    "postcode": "61120",
    "districtCode": "6102",
    "districtNameTh": "ทัพทัน",
    "districtNameEn": "Thap Than",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610206",
    "subDistrictNameTh": "หนองยายดา",
    "subDistrictNameEn": "Nong Yai Da",
    "postcode": "61120",
    "districtCode": "6102",
    "districtNameTh": "ทัพทัน",
    "districtNameEn": "Thap Than",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610207",
    "subDistrictNameTh": "หนองกลางดง",
    "subDistrictNameEn": "Nong Klang Dong",
    "postcode": "61120",
    "districtCode": "6102",
    "districtNameTh": "ทัพทัน",
    "districtNameEn": "Thap Than",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610208",
    "subDistrictNameTh": "หนองกระทุ่ม",
    "subDistrictNameEn": "Nong Krathum",
    "postcode": "61120",
    "districtCode": "6102",
    "districtNameTh": "ทัพทัน",
    "districtNameEn": "Thap Than",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610209",
    "subDistrictNameTh": "หนองสระ",
    "subDistrictNameEn": "Nong Sa",
    "postcode": "61120",
    "districtCode": "6102",
    "districtNameTh": "ทัพทัน",
    "districtNameEn": "Thap Than",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610210",
    "subDistrictNameTh": "ตลุกดู่",
    "subDistrictNameEn": "Taluk Du",
    "postcode": "61120",
    "districtCode": "6102",
    "districtNameTh": "ทัพทัน",
    "districtNameEn": "Thap Than",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610301",
    "subDistrictNameTh": "สว่างอารมณ์",
    "subDistrictNameEn": "Sawang Arom",
    "postcode": "61150",
    "districtCode": "6103",
    "districtNameTh": "สว่างอารมณ์",
    "districtNameEn": "Sawang Arom",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610302",
    "subDistrictNameTh": "หนองหลวง",
    "subDistrictNameEn": "Nong Luang",
    "postcode": "61150",
    "districtCode": "6103",
    "districtNameTh": "สว่างอารมณ์",
    "districtNameEn": "Sawang Arom",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610303",
    "subDistrictNameTh": "พลวงสองนาง",
    "subDistrictNameEn": "Phluang Song Nang",
    "postcode": "61150",
    "districtCode": "6103",
    "districtNameTh": "สว่างอารมณ์",
    "districtNameEn": "Sawang Arom",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610304",
    "subDistrictNameTh": "ไผ่เขียว",
    "subDistrictNameEn": "Phai Khiao",
    "postcode": "61150",
    "districtCode": "6103",
    "districtNameTh": "สว่างอารมณ์",
    "districtNameEn": "Sawang Arom",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610305",
    "subDistrictNameTh": "บ่อยาง",
    "subDistrictNameEn": "Bo Yang",
    "postcode": "61150",
    "districtCode": "6103",
    "districtNameTh": "สว่างอารมณ์",
    "districtNameEn": "Sawang Arom",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610401",
    "subDistrictNameTh": "หนองฉาง",
    "subDistrictNameEn": "Nong Chang",
    "postcode": "61110",
    "districtCode": "6104",
    "districtNameTh": "หนองฉาง",
    "districtNameEn": "Nong Chang",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610402",
    "subDistrictNameTh": "หนองยาง",
    "subDistrictNameEn": "Nong Yang",
    "postcode": "61110",
    "districtCode": "6104",
    "districtNameTh": "หนองฉาง",
    "districtNameEn": "Nong Chang",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610403",
    "subDistrictNameTh": "หนองนางนวล",
    "subDistrictNameEn": "Nong Nang Nuan",
    "postcode": "61110",
    "districtCode": "6104",
    "districtNameTh": "หนองฉาง",
    "districtNameEn": "Nong Chang",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610404",
    "subDistrictNameTh": "หนองสรวง",
    "subDistrictNameEn": "Nong Suang",
    "postcode": "61110",
    "districtCode": "6104",
    "districtNameTh": "หนองฉาง",
    "districtNameEn": "Nong Chang",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610405",
    "subDistrictNameTh": "บ้านเก่า",
    "subDistrictNameEn": "Ban Kao",
    "postcode": "61110",
    "districtCode": "6104",
    "districtNameTh": "หนองฉาง",
    "districtNameEn": "Nong Chang",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610406",
    "subDistrictNameTh": "อุทัยเก่า",
    "subDistrictNameEn": "Utai Kao",
    "postcode": "61110",
    "districtCode": "6104",
    "districtNameTh": "หนองฉาง",
    "districtNameEn": "Nong Chang",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610407",
    "subDistrictNameTh": "ทุ่งโพ",
    "subDistrictNameEn": "Thung Pho",
    "postcode": "61110",
    "districtCode": "6104",
    "districtNameTh": "หนองฉาง",
    "districtNameEn": "Nong Chang",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610408",
    "subDistrictNameTh": "ทุ่งพง",
    "subDistrictNameEn": "Thung Phong",
    "postcode": "61110",
    "districtCode": "6104",
    "districtNameTh": "หนองฉาง",
    "districtNameEn": "Nong Chang",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610409",
    "subDistrictNameTh": "เขาบางแกรก",
    "subDistrictNameEn": "Khao Bang Kraek",
    "postcode": "61170",
    "districtCode": "6104",
    "districtNameTh": "หนองฉาง",
    "districtNameEn": "Nong Chang",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610410",
    "subDistrictNameTh": "เขากวางทอง",
    "subDistrictNameEn": "Khao Kwang Thong",
    "postcode": "61110",
    "districtCode": "6104",
    "districtNameTh": "หนองฉาง",
    "districtNameEn": "Nong Chang",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610501",
    "subDistrictNameTh": "หนองขาหย่าง",
    "subDistrictNameEn": "Nong Khayang",
    "postcode": "61130",
    "districtCode": "6105",
    "districtNameTh": "หนองขาหย่าง",
    "districtNameEn": "Nong Khayang",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610502",
    "subDistrictNameTh": "หนองไผ่",
    "subDistrictNameEn": "Nong Phai",
    "postcode": "61130",
    "districtCode": "6105",
    "districtNameTh": "หนองขาหย่าง",
    "districtNameEn": "Nong Khayang",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610503",
    "subDistrictNameTh": "ดอนกลอย",
    "subDistrictNameEn": "Don Kloi",
    "postcode": "61130",
    "districtCode": "6105",
    "districtNameTh": "หนองขาหย่าง",
    "districtNameEn": "Nong Khayang",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610504",
    "subDistrictNameTh": "ห้วยรอบ",
    "subDistrictNameEn": "Huai Rop",
    "postcode": "61130",
    "districtCode": "6105",
    "districtNameTh": "หนองขาหย่าง",
    "districtNameEn": "Nong Khayang",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610505",
    "subDistrictNameTh": "ทุ่งพึ่ง",
    "subDistrictNameEn": "Thung Phueng",
    "postcode": "61130",
    "districtCode": "6105",
    "districtNameTh": "หนองขาหย่าง",
    "districtNameEn": "Nong Khayang",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610506",
    "subDistrictNameTh": "ท่าโพ",
    "subDistrictNameEn": "Tha Pho",
    "postcode": "61130",
    "districtCode": "6105",
    "districtNameTh": "หนองขาหย่าง",
    "districtNameEn": "Nong Khayang",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610507",
    "subDistrictNameTh": "หมกแถว",
    "subDistrictNameEn": "Mok Thaeo",
    "postcode": "61130",
    "districtCode": "6105",
    "districtNameTh": "หนองขาหย่าง",
    "districtNameEn": "Nong Khayang",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610508",
    "subDistrictNameTh": "หลุมเข้า",
    "subDistrictNameEn": "Lum Khao",
    "postcode": "61130",
    "districtCode": "6105",
    "districtNameTh": "หนองขาหย่าง",
    "districtNameEn": "Nong Khayang",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610509",
    "subDistrictNameTh": "ดงขวาง",
    "subDistrictNameEn": "Dong Khwang",
    "postcode": "61130",
    "districtCode": "6105",
    "districtNameTh": "หนองขาหย่าง",
    "districtNameEn": "Nong Khayang",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610601",
    "subDistrictNameTh": "บ้านไร่",
    "subDistrictNameEn": "Ban Rai",
    "postcode": "61140",
    "districtCode": "6106",
    "districtNameTh": "บ้านไร่",
    "districtNameEn": "Ban Rai",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610602",
    "subDistrictNameTh": "ทัพหลวง",
    "subDistrictNameEn": "Thap Luang",
    "postcode": "61140",
    "districtCode": "6106",
    "districtNameTh": "บ้านไร่",
    "districtNameEn": "Ban Rai",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610603",
    "subDistrictNameTh": "ห้วยแห้ง",
    "subDistrictNameEn": "Huai Haeng",
    "postcode": "61140",
    "districtCode": "6106",
    "districtNameTh": "บ้านไร่",
    "districtNameEn": "Ban Rai",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610604",
    "subDistrictNameTh": "คอกควาย",
    "subDistrictNameEn": "Khok Khwai",
    "postcode": "61140",
    "districtCode": "6106",
    "districtNameTh": "บ้านไร่",
    "districtNameEn": "Ban Rai",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610605",
    "subDistrictNameTh": "วังหิน",
    "subDistrictNameEn": "Wang Hin",
    "postcode": "61180",
    "districtCode": "6106",
    "districtNameTh": "บ้านไร่",
    "districtNameEn": "Ban Rai",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610606",
    "subDistrictNameTh": "เมืองการุ้ง",
    "subDistrictNameEn": "Muang Ka Rung",
    "postcode": "61180",
    "districtCode": "6106",
    "districtNameTh": "บ้านไร่",
    "districtNameEn": "Ban Rai",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610607",
    "subDistrictNameTh": "แก่นมะกรูด",
    "subDistrictNameEn": "Kaen Makrut",
    "postcode": "61140",
    "districtCode": "6106",
    "districtNameTh": "บ้านไร่",
    "districtNameEn": "Ban Rai",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610609",
    "subDistrictNameTh": "หนองจอก",
    "subDistrictNameEn": "Nong Chok",
    "postcode": "61180",
    "districtCode": "6106",
    "districtNameTh": "บ้านไร่",
    "districtNameEn": "Ban Rai",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610610",
    "subDistrictNameTh": "หูช้าง",
    "subDistrictNameEn": "Hu Chang",
    "postcode": "61180",
    "districtCode": "6106",
    "districtNameTh": "บ้านไร่",
    "districtNameEn": "Ban Rai",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610611",
    "subDistrictNameTh": "บ้านบึง",
    "subDistrictNameEn": "Ban Bueng",
    "postcode": "61140",
    "districtCode": "6106",
    "districtNameTh": "บ้านไร่",
    "districtNameEn": "Ban Rai",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610612",
    "subDistrictNameTh": "บ้านใหม่คลองเคียน",
    "subDistrictNameEn": "Ban Mai Khlong Khian",
    "postcode": "61180",
    "districtCode": "6106",
    "districtNameTh": "บ้านไร่",
    "districtNameEn": "Ban Rai",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610613",
    "subDistrictNameTh": "หนองบ่มกล้วย",
    "subDistrictNameEn": "Nong Bom Kluai",
    "postcode": "61180",
    "districtCode": "6106",
    "districtNameTh": "บ้านไร่",
    "districtNameEn": "Ban Rai",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610614",
    "subDistrictNameTh": "เจ้าวัด",
    "subDistrictNameEn": "Chao Wat",
    "postcode": "61140",
    "districtCode": "6106",
    "districtNameTh": "บ้านไร่",
    "districtNameEn": "Ban Rai",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610701",
    "subDistrictNameTh": "ลานสัก",
    "subDistrictNameEn": "Lan Sak",
    "postcode": "61160",
    "districtCode": "6107",
    "districtNameTh": "ลานสัก",
    "districtNameEn": "Lan Sak",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610702",
    "subDistrictNameTh": "ประดู่ยืน",
    "subDistrictNameEn": "Pradu Yuen",
    "postcode": "61160",
    "districtCode": "6107",
    "districtNameTh": "ลานสัก",
    "districtNameEn": "Lan Sak",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610703",
    "subDistrictNameTh": "ป่าอ้อ",
    "subDistrictNameEn": "Pa O",
    "postcode": "61160",
    "districtCode": "6107",
    "districtNameTh": "ลานสัก",
    "districtNameEn": "Lan Sak",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610704",
    "subDistrictNameTh": "ระบำ",
    "subDistrictNameEn": "Rabam",
    "postcode": "61160",
    "districtCode": "6107",
    "districtNameTh": "ลานสัก",
    "districtNameEn": "Lan Sak",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610705",
    "subDistrictNameTh": "น้ำรอบ",
    "subDistrictNameEn": "Nam Rop",
    "postcode": "61160",
    "districtCode": "6107",
    "districtNameTh": "ลานสัก",
    "districtNameEn": "Lan Sak",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610706",
    "subDistrictNameTh": "ทุ่งนางาม",
    "subDistrictNameEn": "Thung Na Ngam",
    "postcode": "61160",
    "districtCode": "6107",
    "districtNameTh": "ลานสัก",
    "districtNameEn": "Lan Sak",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610801",
    "subDistrictNameTh": "สุขฤทัย",
    "subDistrictNameEn": "Suk Ruethai",
    "postcode": "61170",
    "districtCode": "6108",
    "districtNameTh": "ห้วยคต",
    "districtNameEn": "Huai Khot",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610802",
    "subDistrictNameTh": "ทองหลาง",
    "subDistrictNameEn": "Thong Lang",
    "postcode": "61170",
    "districtCode": "6108",
    "districtNameTh": "ห้วยคต",
    "districtNameEn": "Huai Khot",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "610803",
    "subDistrictNameTh": "ห้วยคต",
    "subDistrictNameEn": "Huai Khot",
    "postcode": "61170",
    "districtCode": "6108",
    "districtNameTh": "ห้วยคต",
    "districtNameEn": "Huai Khot",
    "provinceCode": "61",
    "provinceNameTh": "อุทัยธานี",
    "provinceNameEn": "Uthai Thani"
},
{
    "subDistrictCode": "620101",
    "subDistrictNameTh": "ในเมือง",
    "subDistrictNameEn": "Nai Mueang",
    "postcode": "62000",
    "districtCode": "6201",
    "districtNameTh": "เมืองกำแพงเพชร",
    "districtNameEn": "Mueang Kamphaeng Phet",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620102",
    "subDistrictNameTh": "ไตรตรึงษ์",
    "subDistrictNameEn": "Trai Trueng",
    "postcode": "62160",
    "districtCode": "6201",
    "districtNameTh": "เมืองกำแพงเพชร",
    "districtNameEn": "Mueang Kamphaeng Phet",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620103",
    "subDistrictNameTh": "อ่างทอง",
    "subDistrictNameEn": "Ang Thong",
    "postcode": "62000",
    "districtCode": "6201",
    "districtNameTh": "เมืองกำแพงเพชร",
    "districtNameEn": "Mueang Kamphaeng Phet",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620104",
    "subDistrictNameTh": "นาบ่อคำ",
    "subDistrictNameEn": "Na Bo Kham",
    "postcode": "62000",
    "districtCode": "6201",
    "districtNameTh": "เมืองกำแพงเพชร",
    "districtNameEn": "Mueang Kamphaeng Phet",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620105",
    "subDistrictNameTh": "นครชุม",
    "subDistrictNameEn": "Nakhon Chum",
    "postcode": "62000",
    "districtCode": "6201",
    "districtNameTh": "เมืองกำแพงเพชร",
    "districtNameEn": "Mueang Kamphaeng Phet",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620106",
    "subDistrictNameTh": "ทรงธรรม",
    "subDistrictNameEn": "Song Tham",
    "postcode": "62000",
    "districtCode": "6201",
    "districtNameTh": "เมืองกำแพงเพชร",
    "districtNameEn": "Mueang Kamphaeng Phet",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620107",
    "subDistrictNameTh": "ลานดอกไม้",
    "subDistrictNameEn": "Lan Dokmai",
    "postcode": "62000",
    "districtCode": "6201",
    "districtNameTh": "เมืองกำแพงเพชร",
    "districtNameEn": "Mueang Kamphaeng Phet",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620110",
    "subDistrictNameTh": "หนองปลิง",
    "subDistrictNameEn": "Nong Pling",
    "postcode": "62000",
    "districtCode": "6201",
    "districtNameTh": "เมืองกำแพงเพชร",
    "districtNameEn": "Mueang Kamphaeng Phet",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620111",
    "subDistrictNameTh": "คณฑี",
    "subDistrictNameEn": "Khonthi",
    "postcode": "62000",
    "districtCode": "6201",
    "districtNameTh": "เมืองกำแพงเพชร",
    "districtNameEn": "Mueang Kamphaeng Phet",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620112",
    "subDistrictNameTh": "นิคมทุ่งโพธิ์ทะเล",
    "subDistrictNameEn": "Nikhom Thung Pho Thale",
    "postcode": "62000",
    "districtCode": "6201",
    "districtNameTh": "เมืองกำแพงเพชร",
    "districtNameEn": "Mueang Kamphaeng Phet",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620113",
    "subDistrictNameTh": "เทพนคร",
    "subDistrictNameEn": "Thep Nakhon",
    "postcode": "62000",
    "districtCode": "6201",
    "districtNameTh": "เมืองกำแพงเพชร",
    "districtNameEn": "Mueang Kamphaeng Phet",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620114",
    "subDistrictNameTh": "วังทอง",
    "subDistrictNameEn": "Wang Thong",
    "postcode": "62000",
    "districtCode": "6201",
    "districtNameTh": "เมืองกำแพงเพชร",
    "districtNameEn": "Mueang Kamphaeng Phet",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620115",
    "subDistrictNameTh": "ท่าขุนราม",
    "subDistrictNameEn": "Tha Khun Ram",
    "postcode": "62000",
    "districtCode": "6201",
    "districtNameTh": "เมืองกำแพงเพชร",
    "districtNameEn": "Mueang Kamphaeng Phet",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620117",
    "subDistrictNameTh": "คลองแม่ลาย",
    "subDistrictNameEn": "Khlong Mae Lai",
    "postcode": "62000",
    "districtCode": "6201",
    "districtNameTh": "เมืองกำแพงเพชร",
    "districtNameEn": "Mueang Kamphaeng Phet",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620118",
    "subDistrictNameTh": "ธำมรงค์",
    "subDistrictNameEn": "Thammarong",
    "postcode": "62160",
    "districtCode": "6201",
    "districtNameTh": "เมืองกำแพงเพชร",
    "districtNameEn": "Mueang Kamphaeng Phet",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620119",
    "subDistrictNameTh": "สระแก้ว",
    "subDistrictNameEn": "Sa Kaeo",
    "postcode": "62000",
    "districtCode": "6201",
    "districtNameTh": "เมืองกำแพงเพชร",
    "districtNameEn": "Mueang Kamphaeng Phet",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620201",
    "subDistrictNameTh": "ไทรงาม",
    "subDistrictNameEn": "Sai Ngam",
    "postcode": "62150",
    "districtCode": "6202",
    "districtNameTh": "ไทรงาม",
    "districtNameEn": "Sai Ngam",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620202",
    "subDistrictNameTh": "หนองคล้า",
    "subDistrictNameEn": "Nong Khla",
    "postcode": "62150",
    "districtCode": "6202",
    "districtNameTh": "ไทรงาม",
    "districtNameEn": "Sai Ngam",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620203",
    "subDistrictNameTh": "หนองทอง",
    "subDistrictNameEn": "Nong Thong",
    "postcode": "62150",
    "districtCode": "6202",
    "districtNameTh": "ไทรงาม",
    "districtNameEn": "Sai Ngam",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620204",
    "subDistrictNameTh": "หนองไม้กอง",
    "subDistrictNameEn": "Nong Mai Kong",
    "postcode": "62150",
    "districtCode": "6202",
    "districtNameTh": "ไทรงาม",
    "districtNameEn": "Sai Ngam",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620205",
    "subDistrictNameTh": "มหาชัย",
    "subDistrictNameEn": "Maha Chai",
    "postcode": "62150",
    "districtCode": "6202",
    "districtNameTh": "ไทรงาม",
    "districtNameEn": "Sai Ngam",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620206",
    "subDistrictNameTh": "พานทอง",
    "subDistrictNameEn": "Phan Thong",
    "postcode": "62150",
    "districtCode": "6202",
    "districtNameTh": "ไทรงาม",
    "districtNameEn": "Sai Ngam",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620207",
    "subDistrictNameTh": "หนองแม่แตง",
    "subDistrictNameEn": "Nong Mae Taeng",
    "postcode": "62150",
    "districtCode": "6202",
    "districtNameTh": "ไทรงาม",
    "districtNameEn": "Sai Ngam",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620301",
    "subDistrictNameTh": "คลองน้ำไหล",
    "subDistrictNameEn": "Khlong Nam Lai",
    "postcode": "62180",
    "districtCode": "6203",
    "districtNameTh": "คลองลาน",
    "districtNameEn": "Khlong Lan",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620302",
    "subDistrictNameTh": "โป่งน้ำร้อน",
    "subDistrictNameEn": "Pong Nam Ron",
    "postcode": "62180",
    "districtCode": "6203",
    "districtNameTh": "คลองลาน",
    "districtNameEn": "Khlong Lan",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620303",
    "subDistrictNameTh": "คลองลานพัฒนา",
    "subDistrictNameEn": "Khlong Lan Phatthana",
    "postcode": "62180",
    "districtCode": "6203",
    "districtNameTh": "คลองลาน",
    "districtNameEn": "Khlong Lan",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620304",
    "subDistrictNameTh": "สักงาม",
    "subDistrictNameEn": "Sak Ngam",
    "postcode": "62180",
    "districtCode": "6203",
    "districtNameTh": "คลองลาน",
    "districtNameEn": "Khlong Lan",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620403",
    "subDistrictNameTh": "ยางสูง",
    "subDistrictNameEn": "Yang Sung",
    "postcode": "62130",
    "districtCode": "6204",
    "districtNameTh": "ขาณุวรลักษบุรี",
    "districtNameEn": "Khanu Woralaksaburi",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620404",
    "subDistrictNameTh": "ป่าพุทรา",
    "subDistrictNameEn": "Pa Phutsa",
    "postcode": "62130",
    "districtCode": "6204",
    "districtNameTh": "ขาณุวรลักษบุรี",
    "districtNameEn": "Khanu Woralaksaburi",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620405",
    "subDistrictNameTh": "แสนตอ",
    "subDistrictNameEn": "Saen To",
    "postcode": "62130",
    "districtCode": "6204",
    "districtNameTh": "ขาณุวรลักษบุรี",
    "districtNameEn": "Khanu Woralaksaburi",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620406",
    "subDistrictNameTh": "สลกบาตร",
    "subDistrictNameEn": "Salokbat",
    "postcode": "62140",
    "districtCode": "6204",
    "districtNameTh": "ขาณุวรลักษบุรี",
    "districtNameEn": "Khanu Woralaksaburi",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620407",
    "subDistrictNameTh": "บ่อถ้ำ",
    "subDistrictNameEn": "Bo Tham",
    "postcode": "62140",
    "districtCode": "6204",
    "districtNameTh": "ขาณุวรลักษบุรี",
    "districtNameEn": "Khanu Woralaksaburi",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620408",
    "subDistrictNameTh": "ดอนแตง",
    "subDistrictNameEn": "Don Taeng",
    "postcode": "62140",
    "districtCode": "6204",
    "districtNameTh": "ขาณุวรลักษบุรี",
    "districtNameEn": "Khanu Woralaksaburi",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620409",
    "subDistrictNameTh": "วังชะพลู",
    "subDistrictNameEn": "Wang Chaphlu",
    "postcode": "62140",
    "districtCode": "6204",
    "districtNameTh": "ขาณุวรลักษบุรี",
    "districtNameEn": "Khanu Woralaksaburi",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620410",
    "subDistrictNameTh": "โค้งไผ่",
    "subDistrictNameEn": "Khong Phai",
    "postcode": "62140",
    "districtCode": "6204",
    "districtNameTh": "ขาณุวรลักษบุรี",
    "districtNameEn": "Khanu Woralaksaburi",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620411",
    "subDistrictNameTh": "ปางมะค่า",
    "subDistrictNameEn": "Pang Makha",
    "postcode": "62140",
    "districtCode": "6204",
    "districtNameTh": "ขาณุวรลักษบุรี",
    "districtNameEn": "Khanu Woralaksaburi",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620412",
    "subDistrictNameTh": "วังหามแห",
    "subDistrictNameEn": "Wang Ham Hae",
    "postcode": "62140",
    "districtCode": "6204",
    "districtNameTh": "ขาณุวรลักษบุรี",
    "districtNameEn": "Khanu Woralaksaburi",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620413",
    "subDistrictNameTh": "เกาะตาล",
    "subDistrictNameEn": "Ko Tan",
    "postcode": "62130",
    "districtCode": "6204",
    "districtNameTh": "ขาณุวรลักษบุรี",
    "districtNameEn": "Khanu Woralaksaburi",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620501",
    "subDistrictNameTh": "คลองขลุง",
    "subDistrictNameEn": "Khlong Khlung",
    "postcode": "62120",
    "districtCode": "6205",
    "districtNameTh": "คลองขลุง",
    "districtNameEn": "Khlong Khlung",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620502",
    "subDistrictNameTh": "ท่ามะเขือ",
    "subDistrictNameEn": "Tha Makhuea",
    "postcode": "62120",
    "districtCode": "6205",
    "districtNameTh": "คลองขลุง",
    "districtNameEn": "Khlong Khlung",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620504",
    "subDistrictNameTh": "ท่าพุทรา",
    "subDistrictNameEn": "Tha Phutsa",
    "postcode": "62120",
    "districtCode": "6205",
    "districtNameTh": "คลองขลุง",
    "districtNameEn": "Khlong Khlung",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620505",
    "subDistrictNameTh": "แม่ลาด",
    "subDistrictNameEn": "Mae Lat",
    "postcode": "62120",
    "districtCode": "6205",
    "districtNameTh": "คลองขลุง",
    "districtNameEn": "Khlong Khlung",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620506",
    "subDistrictNameTh": "วังยาง",
    "subDistrictNameEn": "Wang Yang",
    "postcode": "62120",
    "districtCode": "6205",
    "districtNameTh": "คลองขลุง",
    "districtNameEn": "Khlong Khlung",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620507",
    "subDistrictNameTh": "วังแขม",
    "subDistrictNameEn": "Wang Khaem",
    "postcode": "62120",
    "districtCode": "6205",
    "districtNameTh": "คลองขลุง",
    "districtNameEn": "Khlong Khlung",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620508",
    "subDistrictNameTh": "หัวถนน",
    "subDistrictNameEn": "Hua Thanon",
    "postcode": "62120",
    "districtCode": "6205",
    "districtNameTh": "คลองขลุง",
    "districtNameEn": "Khlong Khlung",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620509",
    "subDistrictNameTh": "วังไทร",
    "subDistrictNameEn": "Wang Sai",
    "postcode": "62120",
    "districtCode": "6205",
    "districtNameTh": "คลองขลุง",
    "districtNameEn": "Khlong Khlung",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620513",
    "subDistrictNameTh": "วังบัว",
    "subDistrictNameEn": "Wang Bua",
    "postcode": "62120",
    "districtCode": "6205",
    "districtNameTh": "คลองขลุง",
    "districtNameEn": "Khlong Khlung",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620516",
    "subDistrictNameTh": "คลองสมบูรณ์",
    "subDistrictNameEn": "Khlong Sombun",
    "postcode": "62120",
    "districtCode": "6205",
    "districtNameTh": "คลองขลุง",
    "districtNameEn": "Khlong Khlung",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620601",
    "subDistrictNameTh": "พรานกระต่าย",
    "subDistrictNameEn": "Phran Kratai",
    "postcode": "62110",
    "districtCode": "6206",
    "districtNameTh": "พรานกระต่าย",
    "districtNameEn": "Phran Kratai",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620602",
    "subDistrictNameTh": "หนองหัววัว",
    "subDistrictNameEn": "Nong Hua Wua",
    "postcode": "62110",
    "districtCode": "6206",
    "districtNameTh": "พรานกระต่าย",
    "districtNameEn": "Phran Kratai",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620603",
    "subDistrictNameTh": "ท่าไม้",
    "subDistrictNameEn": "Tha Mai",
    "postcode": "62110",
    "districtCode": "6206",
    "districtNameTh": "พรานกระต่าย",
    "districtNameEn": "Phran Kratai",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620604",
    "subDistrictNameTh": "วังควง",
    "subDistrictNameEn": "Wang Khuang",
    "postcode": "62110",
    "districtCode": "6206",
    "districtNameTh": "พรานกระต่าย",
    "districtNameEn": "Phran Kratai",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620605",
    "subDistrictNameTh": "วังตะแบก",
    "subDistrictNameEn": "Wang Tabaek",
    "postcode": "62110",
    "districtCode": "6206",
    "districtNameTh": "พรานกระต่าย",
    "districtNameEn": "Phran Kratai",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620606",
    "subDistrictNameTh": "เขาคีริส",
    "subDistrictNameEn": "Khao Khirit",
    "postcode": "62110",
    "districtCode": "6206",
    "districtNameTh": "พรานกระต่าย",
    "districtNameEn": "Phran Kratai",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620607",
    "subDistrictNameTh": "คุยบ้านโอง",
    "subDistrictNameEn": "Khui Ban Ong",
    "postcode": "62110",
    "districtCode": "6206",
    "districtNameTh": "พรานกระต่าย",
    "districtNameEn": "Phran Kratai",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620608",
    "subDistrictNameTh": "คลองพิไกร",
    "subDistrictNameEn": "Khlong Phikrai",
    "postcode": "62110",
    "districtCode": "6206",
    "districtNameTh": "พรานกระต่าย",
    "districtNameEn": "Phran Kratai",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620609",
    "subDistrictNameTh": "ถ้ำกระต่ายทอง",
    "subDistrictNameEn": "Tham Kratai Thong",
    "postcode": "62110",
    "districtCode": "6206",
    "districtNameTh": "พรานกระต่าย",
    "districtNameEn": "Phran Kratai",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620610",
    "subDistrictNameTh": "ห้วยยั้ง",
    "subDistrictNameEn": "Huai Yang",
    "postcode": "62110",
    "districtCode": "6206",
    "districtNameTh": "พรานกระต่าย",
    "districtNameEn": "Phran Kratai",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620701",
    "subDistrictNameTh": "ลานกระบือ",
    "subDistrictNameEn": "Lan Krabue",
    "postcode": "62170",
    "districtCode": "6207",
    "districtNameTh": "ลานกระบือ",
    "districtNameEn": "Lan Krabue",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620702",
    "subDistrictNameTh": "ช่องลม",
    "subDistrictNameEn": "Chong Lom",
    "postcode": "62170",
    "districtCode": "6207",
    "districtNameTh": "ลานกระบือ",
    "districtNameEn": "Lan Krabue",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620703",
    "subDistrictNameTh": "หนองหลวง",
    "subDistrictNameEn": "Nong Luang",
    "postcode": "62170",
    "districtCode": "6207",
    "districtNameTh": "ลานกระบือ",
    "districtNameEn": "Lan Krabue",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620704",
    "subDistrictNameTh": "โนนพลวง",
    "subDistrictNameEn": "Non Phluang",
    "postcode": "62170",
    "districtCode": "6207",
    "districtNameTh": "ลานกระบือ",
    "districtNameEn": "Lan Krabue",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620705",
    "subDistrictNameTh": "ประชาสุขสันต์",
    "subDistrictNameEn": "Pracha Suk San",
    "postcode": "62170",
    "districtCode": "6207",
    "districtNameTh": "ลานกระบือ",
    "districtNameEn": "Lan Krabue",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620706",
    "subDistrictNameTh": "บึงทับแรต",
    "subDistrictNameEn": "Bueng Thap Raet",
    "postcode": "62170",
    "districtCode": "6207",
    "districtNameTh": "ลานกระบือ",
    "districtNameEn": "Lan Krabue",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620707",
    "subDistrictNameTh": "จันทิมา",
    "subDistrictNameEn": "Chanthima",
    "postcode": "62170",
    "districtCode": "6207",
    "districtNameTh": "ลานกระบือ",
    "districtNameEn": "Lan Krabue",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620801",
    "subDistrictNameTh": "ทุ่งทราย",
    "subDistrictNameEn": "Thung Sai",
    "postcode": "62190",
    "districtCode": "6208",
    "districtNameTh": "ทรายทองวัฒนา",
    "districtNameEn": "Sai Thong Watthana",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620802",
    "subDistrictNameTh": "ทุ่งทอง",
    "subDistrictNameEn": "Thung Thong",
    "postcode": "62190",
    "districtCode": "6208",
    "districtNameTh": "ทรายทองวัฒนา",
    "districtNameEn": "Sai Thong Watthana",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620803",
    "subDistrictNameTh": "ถาวรวัฒนา",
    "subDistrictNameEn": "Thawon Watthana",
    "postcode": "62190",
    "districtCode": "6208",
    "districtNameTh": "ทรายทองวัฒนา",
    "districtNameEn": "Sai Thong Watthana",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620901",
    "subDistrictNameTh": "โพธิ์ทอง",
    "subDistrictNameEn": "Pho Thong",
    "postcode": "62120",
    "districtCode": "6209",
    "districtNameTh": "ปางศิลาทอง",
    "districtNameEn": "Pang Sila Thong",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620902",
    "subDistrictNameTh": "หินดาต",
    "subDistrictNameEn": "Hin Dat",
    "postcode": "62120",
    "districtCode": "6209",
    "districtNameTh": "ปางศิลาทอง",
    "districtNameEn": "Pang Sila Thong",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "620903",
    "subDistrictNameTh": "ปางตาไว",
    "subDistrictNameEn": "Pang Ta Wai",
    "postcode": "62120",
    "districtCode": "6209",
    "districtNameTh": "ปางศิลาทอง",
    "districtNameEn": "Pang Sila Thong",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "621001",
    "subDistrictNameTh": "บึงสามัคคี",
    "subDistrictNameEn": "Bueng Samakkhi",
    "postcode": "62210",
    "districtCode": "6210",
    "districtNameTh": "บึงสามัคคี",
    "districtNameEn": "Bueng Samakkhi",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "621002",
    "subDistrictNameTh": "วังชะโอน",
    "subDistrictNameEn": "Wang Cha-on",
    "postcode": "62210",
    "districtCode": "6210",
    "districtNameTh": "บึงสามัคคี",
    "districtNameEn": "Bueng Samakkhi",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "621003",
    "subDistrictNameTh": "ระหาน",
    "subDistrictNameEn": "Rahan",
    "postcode": "62210",
    "districtCode": "6210",
    "districtNameTh": "บึงสามัคคี",
    "districtNameEn": "Bueng Samakkhi",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "621004",
    "subDistrictNameTh": "เทพนิมิต",
    "subDistrictNameEn": "Thep Nimit",
    "postcode": "62210",
    "districtCode": "6210",
    "districtNameTh": "บึงสามัคคี",
    "districtNameEn": "Bueng Samakkhi",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "621101",
    "subDistrictNameTh": "โกสัมพี",
    "subDistrictNameEn": "Kosamphi",
    "postcode": "62000",
    "districtCode": "6211",
    "districtNameTh": "โกสัมพีนคร",
    "districtNameEn": "Kosamphi Nakhon",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "621102",
    "subDistrictNameTh": "เพชรชมภู",
    "subDistrictNameEn": "Phet Chomphu",
    "postcode": "62000",
    "districtCode": "6211",
    "districtNameTh": "โกสัมพีนคร",
    "districtNameEn": "Kosamphi Nakhon",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "621103",
    "subDistrictNameTh": "ลานดอกไม้ตก",
    "subDistrictNameEn": "Lan Dokmai Tok",
    "postcode": "62000",
    "districtCode": "6211",
    "districtNameTh": "โกสัมพีนคร",
    "districtNameEn": "Kosamphi Nakhon",
    "provinceCode": "62",
    "provinceNameTh": "กำแพงเพชร",
    "provinceNameEn": "Kamphaeng Phet"
},
{
    "subDistrictCode": "630101",
    "subDistrictNameTh": "ระแหง",
    "subDistrictNameEn": "Rahaeng",
    "postcode": "63000",
    "districtCode": "6301",
    "districtNameTh": "เมืองตาก",
    "districtNameEn": "Mueang Tak",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630102",
    "subDistrictNameTh": "หนองหลวง",
    "subDistrictNameEn": "Nong Luang",
    "postcode": "63000",
    "districtCode": "6301",
    "districtNameTh": "เมืองตาก",
    "districtNameEn": "Mueang Tak",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630103",
    "subDistrictNameTh": "เชียงเงิน",
    "subDistrictNameEn": "Chiang Ngoen",
    "postcode": "63000",
    "districtCode": "6301",
    "districtNameTh": "เมืองตาก",
    "districtNameEn": "Mueang Tak",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630104",
    "subDistrictNameTh": "หัวเดียด",
    "subDistrictNameEn": "Hua Diat",
    "postcode": "63000",
    "districtCode": "6301",
    "districtNameTh": "เมืองตาก",
    "districtNameEn": "Mueang Tak",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630105",
    "subDistrictNameTh": "หนองบัวเหนือ",
    "subDistrictNameEn": "Nong Bua Nuea",
    "postcode": "63000",
    "districtCode": "6301",
    "districtNameTh": "เมืองตาก",
    "districtNameEn": "Mueang Tak",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630106",
    "subDistrictNameTh": "ไม้งาม",
    "subDistrictNameEn": "Mai Ngam",
    "postcode": "63000",
    "districtCode": "6301",
    "districtNameTh": "เมืองตาก",
    "districtNameEn": "Mueang Tak",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630107",
    "subDistrictNameTh": "โป่งแดง",
    "subDistrictNameEn": "Pong Daeng",
    "postcode": "63000",
    "districtCode": "6301",
    "districtNameTh": "เมืองตาก",
    "districtNameEn": "Mueang Tak",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630108",
    "subDistrictNameTh": "น้ำรึม",
    "subDistrictNameEn": "Nam Ruem",
    "postcode": "63000",
    "districtCode": "6301",
    "districtNameTh": "เมืองตาก",
    "districtNameEn": "Mueang Tak",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630109",
    "subDistrictNameTh": "วังหิน",
    "subDistrictNameEn": "Wang Hin",
    "postcode": "63000",
    "districtCode": "6301",
    "districtNameTh": "เมืองตาก",
    "districtNameEn": "Mueang Tak",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630111",
    "subDistrictNameTh": "แม่ท้อ",
    "subDistrictNameEn": "Mae Tho",
    "postcode": "63000",
    "districtCode": "6301",
    "districtNameTh": "เมืองตาก",
    "districtNameEn": "Mueang Tak",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630112",
    "subDistrictNameTh": "ป่ามะม่วง",
    "subDistrictNameEn": "Pa Mamuang",
    "postcode": "63000",
    "districtCode": "6301",
    "districtNameTh": "เมืองตาก",
    "districtNameEn": "Mueang Tak",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630113",
    "subDistrictNameTh": "หนองบัวใต้",
    "subDistrictNameEn": "Nong Bua Tai",
    "postcode": "63000",
    "districtCode": "6301",
    "districtNameTh": "เมืองตาก",
    "districtNameEn": "Mueang Tak",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630114",
    "subDistrictNameTh": "วังประจบ",
    "subDistrictNameEn": "Wang Prachop",
    "postcode": "63000",
    "districtCode": "6301",
    "districtNameTh": "เมืองตาก",
    "districtNameEn": "Mueang Tak",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630115",
    "subDistrictNameTh": "ตลุกกลางทุ่ง",
    "subDistrictNameEn": "Taluk Klang Thung",
    "postcode": "63000",
    "districtCode": "6301",
    "districtNameTh": "เมืองตาก",
    "districtNameEn": "Mueang Tak",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630201",
    "subDistrictNameTh": "ตากออก",
    "subDistrictNameEn": "Tak Ok",
    "postcode": "63120",
    "districtCode": "6302",
    "districtNameTh": "บ้านตาก",
    "districtNameEn": "Ban Tak",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630202",
    "subDistrictNameTh": "สมอโคน",
    "subDistrictNameEn": "Samo Khon",
    "postcode": "63120",
    "districtCode": "6302",
    "districtNameTh": "บ้านตาก",
    "districtNameEn": "Ban Tak",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630203",
    "subDistrictNameTh": "แม่สลิด",
    "subDistrictNameEn": "Mae Salit",
    "postcode": "63120",
    "districtCode": "6302",
    "districtNameTh": "บ้านตาก",
    "districtNameEn": "Ban Tak",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630204",
    "subDistrictNameTh": "ตากตก",
    "subDistrictNameEn": "Tak Tok",
    "postcode": "63120",
    "districtCode": "6302",
    "districtNameTh": "บ้านตาก",
    "districtNameEn": "Ban Tak",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630205",
    "subDistrictNameTh": "เกาะตะเภา",
    "subDistrictNameEn": "Ko Taphao",
    "postcode": "63120",
    "districtCode": "6302",
    "districtNameTh": "บ้านตาก",
    "districtNameEn": "Ban Tak",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630206",
    "subDistrictNameTh": "ทุ่งกระเชาะ",
    "subDistrictNameEn": "Thung Kracho",
    "postcode": "63120",
    "districtCode": "6302",
    "districtNameTh": "บ้านตาก",
    "districtNameEn": "Ban Tak",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630207",
    "subDistrictNameTh": "ท้องฟ้า",
    "subDistrictNameEn": "Thong Fa",
    "postcode": "63120",
    "districtCode": "6302",
    "districtNameTh": "บ้านตาก",
    "districtNameEn": "Ban Tak",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630301",
    "subDistrictNameTh": "สามเงา",
    "subDistrictNameEn": "Sam Ngao",
    "postcode": "63130",
    "districtCode": "6303",
    "districtNameTh": "สามเงา",
    "districtNameEn": "Sam Ngao",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630302",
    "subDistrictNameTh": "วังหมัน",
    "subDistrictNameEn": "Wang Man",
    "postcode": "63130",
    "districtCode": "6303",
    "districtNameTh": "สามเงา",
    "districtNameEn": "Sam Ngao",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630303",
    "subDistrictNameTh": "ยกกระบัตร",
    "subDistrictNameEn": "Yokkrabat",
    "postcode": "63130",
    "districtCode": "6303",
    "districtNameTh": "สามเงา",
    "districtNameEn": "Sam Ngao",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630304",
    "subDistrictNameTh": "ย่านรี",
    "subDistrictNameEn": "Yan Ri",
    "postcode": "63130",
    "districtCode": "6303",
    "districtNameTh": "สามเงา",
    "districtNameEn": "Sam Ngao",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630305",
    "subDistrictNameTh": "บ้านนา",
    "subDistrictNameEn": "Ban Na",
    "postcode": "63130",
    "districtCode": "6303",
    "districtNameTh": "สามเงา",
    "districtNameEn": "Sam Ngao",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630306",
    "subDistrictNameTh": "วังจันทร์",
    "subDistrictNameEn": "Wang Chan",
    "postcode": "63130",
    "districtCode": "6303",
    "districtNameTh": "สามเงา",
    "districtNameEn": "Sam Ngao",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630401",
    "subDistrictNameTh": "แม่ระมาด",
    "subDistrictNameEn": "Mae Ramat",
    "postcode": "63140",
    "districtCode": "6304",
    "districtNameTh": "แม่ระมาด",
    "districtNameEn": "Mae Ramat",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630402",
    "subDistrictNameTh": "แม่จะเรา",
    "subDistrictNameEn": "Mae Charao",
    "postcode": "63140",
    "districtCode": "6304",
    "districtNameTh": "แม่ระมาด",
    "districtNameEn": "Mae Ramat",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630403",
    "subDistrictNameTh": "ขะเนจื้อ",
    "subDistrictNameEn": "Khane Chue",
    "postcode": "63140",
    "districtCode": "6304",
    "districtNameTh": "แม่ระมาด",
    "districtNameEn": "Mae Ramat",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630404",
    "subDistrictNameTh": "แม่ตื่น",
    "subDistrictNameEn": "Mae Tuen",
    "postcode": "63140",
    "districtCode": "6304",
    "districtNameTh": "แม่ระมาด",
    "districtNameEn": "Mae Ramat",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630405",
    "subDistrictNameTh": "สามหมื่น",
    "subDistrictNameEn": "Sam Muen",
    "postcode": "63140",
    "districtCode": "6304",
    "districtNameTh": "แม่ระมาด",
    "districtNameEn": "Mae Ramat",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630406",
    "subDistrictNameTh": "พระธาตุ",
    "subDistrictNameEn": "Phra That",
    "postcode": "63140",
    "districtCode": "6304",
    "districtNameTh": "แม่ระมาด",
    "districtNameEn": "Mae Ramat",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630501",
    "subDistrictNameTh": "ท่าสองยาง",
    "subDistrictNameEn": "Tha Song Yang",
    "postcode": "63150",
    "districtCode": "6305",
    "districtNameTh": "ท่าสองยาง",
    "districtNameEn": "Tha Song Yang",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630502",
    "subDistrictNameTh": "แม่ต้าน",
    "subDistrictNameEn": "Mae Tan",
    "postcode": "63150",
    "districtCode": "6305",
    "districtNameTh": "ท่าสองยาง",
    "districtNameEn": "Tha Song Yang",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630503",
    "subDistrictNameTh": "แม่สอง",
    "subDistrictNameEn": "Mae Song",
    "postcode": "63150",
    "districtCode": "6305",
    "districtNameTh": "ท่าสองยาง",
    "districtNameEn": "Tha Song Yang",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630504",
    "subDistrictNameTh": "แม่หละ",
    "subDistrictNameEn": "Mae La",
    "postcode": "63150",
    "districtCode": "6305",
    "districtNameTh": "ท่าสองยาง",
    "districtNameEn": "Tha Song Yang",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630505",
    "subDistrictNameTh": "แม่วะหลวง",
    "subDistrictNameEn": "Mae Wa Luang",
    "postcode": "63150",
    "districtCode": "6305",
    "districtNameTh": "ท่าสองยาง",
    "districtNameEn": "Tha Song Yang",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630506",
    "subDistrictNameTh": "แม่อุสุ",
    "subDistrictNameEn": "Mae U-su",
    "postcode": "63150",
    "districtCode": "6305",
    "districtNameTh": "ท่าสองยาง",
    "districtNameEn": "Tha Song Yang",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630601",
    "subDistrictNameTh": "แม่สอด",
    "subDistrictNameEn": "Mae Sot",
    "postcode": "63110",
    "districtCode": "6306",
    "districtNameTh": "แม่สอด",
    "districtNameEn": "Mae Sot",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630602",
    "subDistrictNameTh": "แม่กุ",
    "subDistrictNameEn": "Mae Ku",
    "postcode": "63110",
    "districtCode": "6306",
    "districtNameTh": "แม่สอด",
    "districtNameEn": "Mae Sot",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630603",
    "subDistrictNameTh": "พะวอ",
    "subDistrictNameEn": "Phawo",
    "postcode": "63110",
    "districtCode": "6306",
    "districtNameTh": "แม่สอด",
    "districtNameEn": "Mae Sot",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630604",
    "subDistrictNameTh": "แม่ตาว",
    "subDistrictNameEn": "Mae Tao",
    "postcode": "63110",
    "districtCode": "6306",
    "districtNameTh": "แม่สอด",
    "districtNameEn": "Mae Sot",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630605",
    "subDistrictNameTh": "แม่กาษา",
    "subDistrictNameEn": "Mae Kasa",
    "postcode": "63110",
    "districtCode": "6306",
    "districtNameTh": "แม่สอด",
    "districtNameEn": "Mae Sot",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630606",
    "subDistrictNameTh": "ท่าสายลวด",
    "subDistrictNameEn": "Tha Sai Luat",
    "postcode": "63110",
    "districtCode": "6306",
    "districtNameTh": "แม่สอด",
    "districtNameEn": "Mae Sot",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630607",
    "subDistrictNameTh": "แม่ปะ",
    "subDistrictNameEn": "Mae Pa",
    "postcode": "63110",
    "districtCode": "6306",
    "districtNameTh": "แม่สอด",
    "districtNameEn": "Mae Sot",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630608",
    "subDistrictNameTh": "มหาวัน",
    "subDistrictNameEn": "Maha Wan",
    "postcode": "63110",
    "districtCode": "6306",
    "districtNameTh": "แม่สอด",
    "districtNameEn": "Mae Sot",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630609",
    "subDistrictNameTh": "ด่านแม่ละเมา",
    "subDistrictNameEn": "Dan Mae Lamao",
    "postcode": "63110",
    "districtCode": "6306",
    "districtNameTh": "แม่สอด",
    "districtNameEn": "Mae Sot",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630610",
    "subDistrictNameTh": "พระธาตุผาแดง",
    "subDistrictNameEn": "Phra That Pha Daeng",
    "postcode": "63110",
    "districtCode": "6306",
    "districtNameTh": "แม่สอด",
    "districtNameEn": "Mae Sot",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630701",
    "subDistrictNameTh": "พบพระ",
    "subDistrictNameEn": "Phop Phra",
    "postcode": "63160",
    "districtCode": "6307",
    "districtNameTh": "พบพระ",
    "districtNameEn": "Phop Phra",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630702",
    "subDistrictNameTh": "ช่องแคบ",
    "subDistrictNameEn": "Chong Khaep",
    "postcode": "63160",
    "districtCode": "6307",
    "districtNameTh": "พบพระ",
    "districtNameEn": "Phop Phra",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630703",
    "subDistrictNameTh": "คีรีราษฎร์",
    "subDistrictNameEn": "Khiri Rat",
    "postcode": "63160",
    "districtCode": "6307",
    "districtNameTh": "พบพระ",
    "districtNameEn": "Phop Phra",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630704",
    "subDistrictNameTh": "วาเล่ย์",
    "subDistrictNameEn": "Wale",
    "postcode": "63160",
    "districtCode": "6307",
    "districtNameTh": "พบพระ",
    "districtNameEn": "Phop Phra",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630705",
    "subDistrictNameTh": "รวมไทยพัฒนา",
    "subDistrictNameEn": "Ruam Thai Phatthana",
    "postcode": "63160",
    "districtCode": "6307",
    "districtNameTh": "พบพระ",
    "districtNameEn": "Phop Phra",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630801",
    "subDistrictNameTh": "อุ้มผาง",
    "subDistrictNameEn": "Umphang",
    "postcode": "63170",
    "districtCode": "6308",
    "districtNameTh": "อุ้มผาง",
    "districtNameEn": "Umphang",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630802",
    "subDistrictNameTh": "หนองหลวง",
    "subDistrictNameEn": "Nong Luang",
    "postcode": "63170",
    "districtCode": "6308",
    "districtNameTh": "อุ้มผาง",
    "districtNameEn": "Umphang",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630803",
    "subDistrictNameTh": "โมโกร",
    "subDistrictNameEn": "Mokro",
    "postcode": "63170",
    "districtCode": "6308",
    "districtNameTh": "อุ้มผาง",
    "districtNameEn": "Umphang",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630804",
    "subDistrictNameTh": "แม่จัน",
    "subDistrictNameEn": "Mae Chan",
    "postcode": "63170",
    "districtCode": "6308",
    "districtNameTh": "อุ้มผาง",
    "districtNameEn": "Umphang",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630805",
    "subDistrictNameTh": "แม่ละมุ้ง",
    "subDistrictNameEn": "Mae Lamung",
    "postcode": "63170",
    "districtCode": "6308",
    "districtNameTh": "อุ้มผาง",
    "districtNameEn": "Umphang",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630806",
    "subDistrictNameTh": "แม่กลอง",
    "subDistrictNameEn": "Mae Klong",
    "postcode": "63170",
    "districtCode": "6308",
    "districtNameTh": "อุ้มผาง",
    "districtNameEn": "Umphang",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630901",
    "subDistrictNameTh": "เชียงทอง",
    "subDistrictNameEn": "Chiang Thong",
    "postcode": "63180",
    "districtCode": "6309",
    "districtNameTh": "วังเจ้า",
    "districtNameEn": "Wang Chao",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630902",
    "subDistrictNameTh": "นาโบสถ์",
    "subDistrictNameEn": "Na Bot",
    "postcode": "63180",
    "districtCode": "6309",
    "districtNameTh": "วังเจ้า",
    "districtNameEn": "Wang Chao",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "630903",
    "subDistrictNameTh": "ประดาง",
    "subDistrictNameEn": "Pradang",
    "postcode": "63180",
    "districtCode": "6309",
    "districtNameTh": "วังเจ้า",
    "districtNameEn": "Wang Chao",
    "provinceCode": "63",
    "provinceNameTh": "ตาก",
    "provinceNameEn": "Tak"
},
{
    "subDistrictCode": "640101",
    "subDistrictNameTh": "ธานี",
    "subDistrictNameEn": "Thani",
    "postcode": "64000",
    "districtCode": "6401",
    "districtNameTh": "เมืองสุโขทัย",
    "districtNameEn": "Mueang Sukhothai",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640102",
    "subDistrictNameTh": "บ้านสวน",
    "subDistrictNameEn": "Ban Suan",
    "postcode": "64220",
    "districtCode": "6401",
    "districtNameTh": "เมืองสุโขทัย",
    "districtNameEn": "Mueang Sukhothai",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640103",
    "subDistrictNameTh": "เมืองเก่า",
    "subDistrictNameEn": "Mueang Kao",
    "postcode": "64210",
    "districtCode": "6401",
    "districtNameTh": "เมืองสุโขทัย",
    "districtNameEn": "Mueang Sukhothai",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640104",
    "subDistrictNameTh": "ปากแคว",
    "subDistrictNameEn": "Pak Khwae",
    "postcode": "64000",
    "districtCode": "6401",
    "districtNameTh": "เมืองสุโขทัย",
    "districtNameEn": "Mueang Sukhothai",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640105",
    "subDistrictNameTh": "ยางซ้าย",
    "subDistrictNameEn": "Yang Sai",
    "postcode": "64000",
    "districtCode": "6401",
    "districtNameTh": "เมืองสุโขทัย",
    "districtNameEn": "Mueang Sukhothai",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640106",
    "subDistrictNameTh": "บ้านกล้วย",
    "subDistrictNameEn": "Ban Kluai",
    "postcode": "64000",
    "districtCode": "6401",
    "districtNameTh": "เมืองสุโขทัย",
    "districtNameEn": "Mueang Sukhothai",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640107",
    "subDistrictNameTh": "บ้านหลุม",
    "subDistrictNameEn": "Ban Lum",
    "postcode": "64000",
    "districtCode": "6401",
    "districtNameTh": "เมืองสุโขทัย",
    "districtNameEn": "Mueang Sukhothai",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640108",
    "subDistrictNameTh": "ตาลเตี้ย",
    "subDistrictNameEn": "Tan Tia",
    "postcode": "64220",
    "districtCode": "6401",
    "districtNameTh": "เมืองสุโขทัย",
    "districtNameEn": "Mueang Sukhothai",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640109",
    "subDistrictNameTh": "ปากพระ",
    "subDistrictNameEn": "Pak Phra",
    "postcode": "64000",
    "districtCode": "6401",
    "districtNameTh": "เมืองสุโขทัย",
    "districtNameEn": "Mueang Sukhothai",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640110",
    "subDistrictNameTh": "วังทองแดง",
    "subDistrictNameEn": "Wang Thong Daeng",
    "postcode": "64210",
    "districtCode": "6401",
    "districtNameTh": "เมืองสุโขทัย",
    "districtNameEn": "Mueang Sukhothai",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640201",
    "subDistrictNameTh": "ลานหอย",
    "subDistrictNameEn": "Lan Hoi",
    "postcode": "64140",
    "districtCode": "6402",
    "districtNameTh": "บ้านด่านลานหอย",
    "districtNameEn": "Ban Dan Lan Hoi",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640202",
    "subDistrictNameTh": "บ้านด่าน",
    "subDistrictNameEn": "Ban Dan",
    "postcode": "64140",
    "districtCode": "6402",
    "districtNameTh": "บ้านด่านลานหอย",
    "districtNameEn": "Ban Dan Lan Hoi",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640203",
    "subDistrictNameTh": "วังตะคร้อ",
    "subDistrictNameEn": "Wang Takhro",
    "postcode": "64140",
    "districtCode": "6402",
    "districtNameTh": "บ้านด่านลานหอย",
    "districtNameEn": "Ban Dan Lan Hoi",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640204",
    "subDistrictNameTh": "วังน้ำขาว",
    "subDistrictNameEn": "Wang Nam Khao",
    "postcode": "64140",
    "districtCode": "6402",
    "districtNameTh": "บ้านด่านลานหอย",
    "districtNameEn": "Ban Dan Lan Hoi",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640205",
    "subDistrictNameTh": "ตลิ่งชัน",
    "subDistrictNameEn": "Taling Chan",
    "postcode": "64140",
    "districtCode": "6402",
    "districtNameTh": "บ้านด่านลานหอย",
    "districtNameEn": "Ban Dan Lan Hoi",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640206",
    "subDistrictNameTh": "หนองหญ้าปล้อง",
    "subDistrictNameEn": "Nong Ya Plong",
    "postcode": "64140",
    "districtCode": "6402",
    "districtNameTh": "บ้านด่านลานหอย",
    "districtNameEn": "Ban Dan Lan Hoi",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640207",
    "subDistrictNameTh": "วังลึก",
    "subDistrictNameEn": "Wang Luek",
    "postcode": "64140",
    "districtCode": "6402",
    "districtNameTh": "บ้านด่านลานหอย",
    "districtNameEn": "Ban Dan Lan Hoi",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640301",
    "subDistrictNameTh": "โตนด",
    "subDistrictNameEn": "Tanot",
    "postcode": "64160",
    "districtCode": "6403",
    "districtNameTh": "คีรีมาศ",
    "districtNameEn": "Khiri Mat",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640302",
    "subDistrictNameTh": "ทุ่งหลวง",
    "subDistrictNameEn": "Thung Luang",
    "postcode": "64160",
    "districtCode": "6403",
    "districtNameTh": "คีรีมาศ",
    "districtNameEn": "Khiri Mat",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640303",
    "subDistrictNameTh": "บ้านป้อม",
    "subDistrictNameEn": "Ban Pom",
    "postcode": "64160",
    "districtCode": "6403",
    "districtNameTh": "คีรีมาศ",
    "districtNameEn": "Khiri Mat",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640304",
    "subDistrictNameTh": "สามพวง",
    "subDistrictNameEn": "Sam Phuang",
    "postcode": "64160",
    "districtCode": "6403",
    "districtNameTh": "คีรีมาศ",
    "districtNameEn": "Khiri Mat",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640305",
    "subDistrictNameTh": "ศรีคีรีมาศ",
    "subDistrictNameEn": "Si Khiri Mat",
    "postcode": "64160",
    "districtCode": "6403",
    "districtNameTh": "คีรีมาศ",
    "districtNameEn": "Khiri Mat",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640306",
    "subDistrictNameTh": "หนองจิก",
    "subDistrictNameEn": "Nong Chik",
    "postcode": "64160",
    "districtCode": "6403",
    "districtNameTh": "คีรีมาศ",
    "districtNameEn": "Khiri Mat",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640307",
    "subDistrictNameTh": "นาเชิงคีรี",
    "subDistrictNameEn": "Na Choeng Khiri",
    "postcode": "64160",
    "districtCode": "6403",
    "districtNameTh": "คีรีมาศ",
    "districtNameEn": "Khiri Mat",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640308",
    "subDistrictNameTh": "หนองกระดิ่ง",
    "subDistrictNameEn": "Nong Krading",
    "postcode": "64160",
    "districtCode": "6403",
    "districtNameTh": "คีรีมาศ",
    "districtNameEn": "Khiri Mat",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640309",
    "subDistrictNameTh": "บ้านน้ำพุ",
    "subDistrictNameEn": "Ban Nam Phu",
    "postcode": "64160",
    "districtCode": "6403",
    "districtNameTh": "คีรีมาศ",
    "districtNameEn": "Khiri Mat",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640310",
    "subDistrictNameTh": "ทุ่งยางเมือง",
    "subDistrictNameEn": "Thung Yang Mueang",
    "postcode": "64160",
    "districtCode": "6403",
    "districtNameTh": "คีรีมาศ",
    "districtNameEn": "Khiri Mat",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640401",
    "subDistrictNameTh": "กง",
    "subDistrictNameEn": "Kong",
    "postcode": "64170",
    "districtCode": "6404",
    "districtNameTh": "กงไกรลาศ",
    "districtNameEn": "Kong Krailat",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640402",
    "subDistrictNameTh": "บ้านกร่าง",
    "subDistrictNameEn": "Ban Krang",
    "postcode": "64170",
    "districtCode": "6404",
    "districtNameTh": "กงไกรลาศ",
    "districtNameEn": "Kong Krailat",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640403",
    "subDistrictNameTh": "ไกรนอก",
    "subDistrictNameEn": "Krai Nok",
    "postcode": "64170",
    "districtCode": "6404",
    "districtNameTh": "กงไกรลาศ",
    "districtNameEn": "Kong Krailat",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640404",
    "subDistrictNameTh": "ไกรกลาง",
    "subDistrictNameEn": "Krai Klang",
    "postcode": "64170",
    "districtCode": "6404",
    "districtNameTh": "กงไกรลาศ",
    "districtNameEn": "Kong Krailat",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640405",
    "subDistrictNameTh": "ไกรใน",
    "subDistrictNameEn": "Krai Nai",
    "postcode": "64170",
    "districtCode": "6404",
    "districtNameTh": "กงไกรลาศ",
    "districtNameEn": "Kong Krailat",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640406",
    "subDistrictNameTh": "ดงเดือย",
    "subDistrictNameEn": "Dong Dueai",
    "postcode": "64170",
    "districtCode": "6404",
    "districtNameTh": "กงไกรลาศ",
    "districtNameEn": "Kong Krailat",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640407",
    "subDistrictNameTh": "ป่าแฝก",
    "subDistrictNameEn": "Pa Faek",
    "postcode": "64170",
    "districtCode": "6404",
    "districtNameTh": "กงไกรลาศ",
    "districtNameEn": "Kong Krailat",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640408",
    "subDistrictNameTh": "กกแรต",
    "subDistrictNameEn": "Kok Raet",
    "postcode": "64170",
    "districtCode": "6404",
    "districtNameTh": "กงไกรลาศ",
    "districtNameEn": "Kong Krailat",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640409",
    "subDistrictNameTh": "ท่าฉนวน",
    "subDistrictNameEn": "Tha Chanuan",
    "postcode": "64170",
    "districtCode": "6404",
    "districtNameTh": "กงไกรลาศ",
    "districtNameEn": "Kong Krailat",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640410",
    "subDistrictNameTh": "หนองตูม",
    "subDistrictNameEn": "Nong Tum",
    "postcode": "64170",
    "districtCode": "6404",
    "districtNameTh": "กงไกรลาศ",
    "districtNameEn": "Kong Krailat",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640411",
    "subDistrictNameTh": "บ้านใหม่สุขเกษม",
    "subDistrictNameEn": "Ban Mai Suk Kasem",
    "postcode": "64170",
    "districtCode": "6404",
    "districtNameTh": "กงไกรลาศ",
    "districtNameEn": "Kong Krailat",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640501",
    "subDistrictNameTh": "หาดเสี้ยว",
    "subDistrictNameEn": "Hat Siao",
    "postcode": "64130",
    "districtCode": "6405",
    "districtNameTh": "ศรีสัชนาลัย",
    "districtNameEn": "Si Satchanalai",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640502",
    "subDistrictNameTh": "ป่างิ้ว",
    "subDistrictNameEn": "Pa Ngio",
    "postcode": "64130",
    "districtCode": "6405",
    "districtNameTh": "ศรีสัชนาลัย",
    "districtNameEn": "Si Satchanalai",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640503",
    "subDistrictNameTh": "แม่สำ",
    "subDistrictNameEn": "Mae Sam",
    "postcode": "64130",
    "districtCode": "6405",
    "districtNameTh": "ศรีสัชนาลัย",
    "districtNameEn": "Si Satchanalai",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640504",
    "subDistrictNameTh": "แม่สิน",
    "subDistrictNameEn": "Mae Sin",
    "postcode": "64130",
    "districtCode": "6405",
    "districtNameTh": "ศรีสัชนาลัย",
    "districtNameEn": "Si Satchanalai",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640505",
    "subDistrictNameTh": "บ้านตึก",
    "subDistrictNameEn": "Ban Tuek",
    "postcode": "64130",
    "districtCode": "6405",
    "districtNameTh": "ศรีสัชนาลัย",
    "districtNameEn": "Si Satchanalai",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640506",
    "subDistrictNameTh": "หนองอ้อ",
    "subDistrictNameEn": "Nong O",
    "postcode": "64130",
    "districtCode": "6405",
    "districtNameTh": "ศรีสัชนาลัย",
    "districtNameEn": "Si Satchanalai",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640507",
    "subDistrictNameTh": "ท่าชัย",
    "subDistrictNameEn": "Tha Chai",
    "postcode": "64190",
    "districtCode": "6405",
    "districtNameTh": "ศรีสัชนาลัย",
    "districtNameEn": "Si Satchanalai",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640508",
    "subDistrictNameTh": "ศรีสัชนาลัย",
    "subDistrictNameEn": "Si Satchanalai",
    "postcode": "64190",
    "districtCode": "6405",
    "districtNameTh": "ศรีสัชนาลัย",
    "districtNameEn": "Si Satchanalai",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640509",
    "subDistrictNameTh": "ดงคู่",
    "subDistrictNameEn": "Dong Khu",
    "postcode": "64130",
    "districtCode": "6405",
    "districtNameTh": "ศรีสัชนาลัย",
    "districtNameEn": "Si Satchanalai",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640510",
    "subDistrictNameTh": "บ้านแก่ง",
    "subDistrictNameEn": "Ban Kaeng",
    "postcode": "64130",
    "districtCode": "6405",
    "districtNameTh": "ศรีสัชนาลัย",
    "districtNameEn": "Si Satchanalai",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640511",
    "subDistrictNameTh": "สารจิตร",
    "subDistrictNameEn": "San Chit",
    "postcode": "64130",
    "districtCode": "6405",
    "districtNameTh": "ศรีสัชนาลัย",
    "districtNameEn": "Si Satchanalai",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640601",
    "subDistrictNameTh": "คลองตาล",
    "subDistrictNameEn": "Khlong Tan",
    "postcode": "64120",
    "districtCode": "6406",
    "districtNameTh": "ศรีสำโรง",
    "districtNameEn": "Si Samrong",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640602",
    "subDistrictNameTh": "วังลึก",
    "subDistrictNameEn": "Wang Luek",
    "postcode": "64120",
    "districtCode": "6406",
    "districtNameTh": "ศรีสำโรง",
    "districtNameEn": "Si Samrong",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640603",
    "subDistrictNameTh": "สามเรือน",
    "subDistrictNameEn": "Sam Ruean",
    "postcode": "64120",
    "districtCode": "6406",
    "districtNameTh": "ศรีสำโรง",
    "districtNameEn": "Si Samrong",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640604",
    "subDistrictNameTh": "บ้านนา",
    "subDistrictNameEn": "Ban Na",
    "postcode": "64120",
    "districtCode": "6406",
    "districtNameTh": "ศรีสำโรง",
    "districtNameEn": "Si Samrong",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640605",
    "subDistrictNameTh": "วังทอง",
    "subDistrictNameEn": "Wang Thong",
    "postcode": "64120",
    "districtCode": "6406",
    "districtNameTh": "ศรีสำโรง",
    "districtNameEn": "Si Samrong",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640606",
    "subDistrictNameTh": "นาขุนไกร",
    "subDistrictNameEn": "Na Khun Krai",
    "postcode": "64120",
    "districtCode": "6406",
    "districtNameTh": "ศรีสำโรง",
    "districtNameEn": "Si Samrong",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640607",
    "subDistrictNameTh": "เกาะตาเลี้ยง",
    "subDistrictNameEn": "Ko Ta Liang",
    "postcode": "64120",
    "districtCode": "6406",
    "districtNameTh": "ศรีสำโรง",
    "districtNameEn": "Si Samrong",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640608",
    "subDistrictNameTh": "วัดเกาะ",
    "subDistrictNameEn": "Wat Ko",
    "postcode": "64120",
    "districtCode": "6406",
    "districtNameTh": "ศรีสำโรง",
    "districtNameEn": "Si Samrong",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640609",
    "subDistrictNameTh": "บ้านไร่",
    "subDistrictNameEn": "Ban Rai",
    "postcode": "64120",
    "districtCode": "6406",
    "districtNameTh": "ศรีสำโรง",
    "districtNameEn": "Si Samrong",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640610",
    "subDistrictNameTh": "ทับผึ้ง",
    "subDistrictNameEn": "Thap Phueng",
    "postcode": "64120",
    "districtCode": "6406",
    "districtNameTh": "ศรีสำโรง",
    "districtNameEn": "Si Samrong",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640611",
    "subDistrictNameTh": "บ้านซ่าน",
    "subDistrictNameEn": "Ban San",
    "postcode": "64120",
    "districtCode": "6406",
    "districtNameTh": "ศรีสำโรง",
    "districtNameEn": "Si Samrong",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640612",
    "subDistrictNameTh": "วังใหญ่",
    "subDistrictNameEn": "Wang Yai",
    "postcode": "64120",
    "districtCode": "6406",
    "districtNameTh": "ศรีสำโรง",
    "districtNameEn": "Si Samrong",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640613",
    "subDistrictNameTh": "ราวต้นจันทร์",
    "subDistrictNameEn": "Rao Ton Chan",
    "postcode": "64120",
    "districtCode": "6406",
    "districtNameTh": "ศรีสำโรง",
    "districtNameEn": "Si Samrong",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640701",
    "subDistrictNameTh": "เมืองสวรรคโลก",
    "subDistrictNameEn": "Mueang Sawankhalok",
    "postcode": "64110",
    "districtCode": "6407",
    "districtNameTh": "สวรรคโลก",
    "districtNameEn": "Sawankhalok",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640702",
    "subDistrictNameTh": "ในเมือง",
    "subDistrictNameEn": "Nai Mueang",
    "postcode": "64110",
    "districtCode": "6407",
    "districtNameTh": "สวรรคโลก",
    "districtNameEn": "Sawankhalok",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640703",
    "subDistrictNameTh": "คลองกระจง",
    "subDistrictNameEn": "Khlong Krachong",
    "postcode": "64110",
    "districtCode": "6407",
    "districtNameTh": "สวรรคโลก",
    "districtNameEn": "Sawankhalok",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640704",
    "subDistrictNameTh": "วังพิณพาทย์",
    "subDistrictNameEn": "Wang Phinphat",
    "postcode": "64110",
    "districtCode": "6407",
    "districtNameTh": "สวรรคโลก",
    "districtNameEn": "Sawankhalok",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640705",
    "subDistrictNameTh": "วังไม้ขอน",
    "subDistrictNameEn": "Wang Mai Khon",
    "postcode": "64110",
    "districtCode": "6407",
    "districtNameTh": "สวรรคโลก",
    "districtNameEn": "Sawankhalok",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640706",
    "subDistrictNameTh": "ย่านยาว",
    "subDistrictNameEn": "Yan Yao",
    "postcode": "64110",
    "districtCode": "6407",
    "districtNameTh": "สวรรคโลก",
    "districtNameEn": "Sawankhalok",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640707",
    "subDistrictNameTh": "นาทุ่ง",
    "subDistrictNameEn": "Na Thung",
    "postcode": "64110",
    "districtCode": "6407",
    "districtNameTh": "สวรรคโลก",
    "districtNameEn": "Sawankhalok",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640708",
    "subDistrictNameTh": "คลองยาง",
    "subDistrictNameEn": "Khlong Yang",
    "postcode": "64110",
    "districtCode": "6407",
    "districtNameTh": "สวรรคโลก",
    "districtNameEn": "Sawankhalok",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640709",
    "subDistrictNameTh": "เมืองบางยม",
    "subDistrictNameEn": "Mueang Bang Yom",
    "postcode": "64110",
    "districtCode": "6407",
    "districtNameTh": "สวรรคโลก",
    "districtNameEn": "Sawankhalok",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640710",
    "subDistrictNameTh": "ท่าทอง",
    "subDistrictNameEn": "Tha Tong",
    "postcode": "64110",
    "districtCode": "6407",
    "districtNameTh": "สวรรคโลก",
    "districtNameEn": "Sawankhalok",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640711",
    "subDistrictNameTh": "ปากน้ำ",
    "subDistrictNameEn": "Pak Nam",
    "postcode": "64110",
    "districtCode": "6407",
    "districtNameTh": "สวรรคโลก",
    "districtNameEn": "Sawankhalok",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640712",
    "subDistrictNameTh": "ป่ากุมเกาะ",
    "subDistrictNameEn": "Pa Kum Ko",
    "postcode": "64110",
    "districtCode": "6407",
    "districtNameTh": "สวรรคโลก",
    "districtNameEn": "Sawankhalok",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640713",
    "subDistrictNameTh": "เมืองบางขลัง",
    "subDistrictNameEn": "Mueang Bang Khlang",
    "postcode": "64110",
    "districtCode": "6407",
    "districtNameTh": "สวรรคโลก",
    "districtNameEn": "Sawankhalok",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640714",
    "subDistrictNameTh": "หนองกลับ",
    "subDistrictNameEn": "Nong Klap",
    "postcode": "64110",
    "districtCode": "6407",
    "districtNameTh": "สวรรคโลก",
    "districtNameEn": "Sawankhalok",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640801",
    "subDistrictNameTh": "ศรีนคร",
    "subDistrictNameEn": "Si Nakhon",
    "postcode": "64180",
    "districtCode": "6408",
    "districtNameTh": "ศรีนคร",
    "districtNameEn": "Si Nakhon",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640802",
    "subDistrictNameTh": "นครเดิฐ",
    "subDistrictNameEn": "Nakhon Doet",
    "postcode": "64180",
    "districtCode": "6408",
    "districtNameTh": "ศรีนคร",
    "districtNameEn": "Si Nakhon",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640803",
    "subDistrictNameTh": "น้ำขุม",
    "subDistrictNameEn": "Nam Khum",
    "postcode": "64180",
    "districtCode": "6408",
    "districtNameTh": "ศรีนคร",
    "districtNameEn": "Si Nakhon",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640804",
    "subDistrictNameTh": "คลองมะพลับ",
    "subDistrictNameEn": "Khlong Maphlap",
    "postcode": "64180",
    "districtCode": "6408",
    "districtNameTh": "ศรีนคร",
    "districtNameEn": "Si Nakhon",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640805",
    "subDistrictNameTh": "หนองบัว",
    "subDistrictNameEn": "Nong Bua",
    "postcode": "64180",
    "districtCode": "6408",
    "districtNameTh": "ศรีนคร",
    "districtNameEn": "Si Nakhon",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640901",
    "subDistrictNameTh": "บ้านใหม่ไชยมงคล",
    "subDistrictNameEn": "Ban Mai Chai Mongkhon",
    "postcode": "64230",
    "districtCode": "6409",
    "districtNameTh": "ทุ่งเสลี่ยม",
    "districtNameEn": "Thung Saliam",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640902",
    "subDistrictNameTh": "ไทยชนะศึก",
    "subDistrictNameEn": "Thai Chana Suek",
    "postcode": "64150",
    "districtCode": "6409",
    "districtNameTh": "ทุ่งเสลี่ยม",
    "districtNameEn": "Thung Saliam",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640903",
    "subDistrictNameTh": "ทุ่งเสลี่ยม",
    "subDistrictNameEn": "Thung Saliam",
    "postcode": "64150",
    "districtCode": "6409",
    "districtNameTh": "ทุ่งเสลี่ยม",
    "districtNameEn": "Thung Saliam",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640904",
    "subDistrictNameTh": "กลางดง",
    "subDistrictNameEn": "Klang Dong",
    "postcode": "64150",
    "districtCode": "6409",
    "districtNameTh": "ทุ่งเสลี่ยม",
    "districtNameEn": "Thung Saliam",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "640905",
    "subDistrictNameTh": "เขาแก้วศรีสมบูรณ์",
    "subDistrictNameEn": "Khao Kaeo Si Sombun",
    "postcode": "64230",
    "districtCode": "6409",
    "districtNameTh": "ทุ่งเสลี่ยม",
    "districtNameEn": "Thung Saliam",
    "provinceCode": "64",
    "provinceNameTh": "สุโขทัย",
    "provinceNameEn": "Sukhothai"
},
{
    "subDistrictCode": "650101",
    "subDistrictNameTh": "ในเมือง",
    "subDistrictNameEn": "Nai Mueang",
    "postcode": "65000",
    "districtCode": "6501",
    "districtNameTh": "เมืองพิษณุโลก",
    "districtNameEn": "Mueang Phitsanulok",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650102",
    "subDistrictNameTh": "วังน้ำคู้",
    "subDistrictNameEn": "Wang Nam Khu",
    "postcode": "65230",
    "districtCode": "6501",
    "districtNameTh": "เมืองพิษณุโลก",
    "districtNameEn": "Mueang Phitsanulok",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650103",
    "subDistrictNameTh": "วัดจันทร์",
    "subDistrictNameEn": "Wat Chan",
    "postcode": "65000",
    "districtCode": "6501",
    "districtNameTh": "เมืองพิษณุโลก",
    "districtNameEn": "Mueang Phitsanulok",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650104",
    "subDistrictNameTh": "วัดพริก",
    "subDistrictNameEn": "Wat Phrik",
    "postcode": "65230",
    "districtCode": "6501",
    "districtNameTh": "เมืองพิษณุโลก",
    "districtNameEn": "Mueang Phitsanulok",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650105",
    "subDistrictNameTh": "ท่าทอง",
    "subDistrictNameEn": "Tha Thong",
    "postcode": "65000",
    "districtCode": "6501",
    "districtNameTh": "เมืองพิษณุโลก",
    "districtNameEn": "Mueang Phitsanulok",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650106",
    "subDistrictNameTh": "ท่าโพธิ์",
    "subDistrictNameEn": "Tha Pho",
    "postcode": "65000",
    "districtCode": "6501",
    "districtNameTh": "เมืองพิษณุโลก",
    "districtNameEn": "Mueang Phitsanulok",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650107",
    "subDistrictNameTh": "สมอแข",
    "subDistrictNameEn": "Samo Khae",
    "postcode": "65000",
    "districtCode": "6501",
    "districtNameTh": "เมืองพิษณุโลก",
    "districtNameEn": "Mueang Phitsanulok",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650108",
    "subDistrictNameTh": "ดอนทอง",
    "subDistrictNameEn": "Don Thong",
    "postcode": "65000",
    "districtCode": "6501",
    "districtNameTh": "เมืองพิษณุโลก",
    "districtNameEn": "Mueang Phitsanulok",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650109",
    "subDistrictNameTh": "บ้านป่า",
    "subDistrictNameEn": "Ban Pa",
    "postcode": "65000",
    "districtCode": "6501",
    "districtNameTh": "เมืองพิษณุโลก",
    "districtNameEn": "Mueang Phitsanulok",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650110",
    "subDistrictNameTh": "ปากโทก",
    "subDistrictNameEn": "Pak Thok",
    "postcode": "65000",
    "districtCode": "6501",
    "districtNameTh": "เมืองพิษณุโลก",
    "districtNameEn": "Mueang Phitsanulok",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650111",
    "subDistrictNameTh": "หัวรอ",
    "subDistrictNameEn": "Hua Ro",
    "postcode": "65000",
    "districtCode": "6501",
    "districtNameTh": "เมืองพิษณุโลก",
    "districtNameEn": "Mueang Phitsanulok",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650112",
    "subDistrictNameTh": "จอมทอง",
    "subDistrictNameEn": "Chom Thong",
    "postcode": "65000",
    "districtCode": "6501",
    "districtNameTh": "เมืองพิษณุโลก",
    "districtNameEn": "Mueang Phitsanulok",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650113",
    "subDistrictNameTh": "บ้านกร่าง",
    "subDistrictNameEn": "Ban Krang",
    "postcode": "65000",
    "districtCode": "6501",
    "districtNameTh": "เมืองพิษณุโลก",
    "districtNameEn": "Mueang Phitsanulok",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650114",
    "subDistrictNameTh": "บ้านคลอง",
    "subDistrictNameEn": "Ban Khlong",
    "postcode": "65000",
    "districtCode": "6501",
    "districtNameTh": "เมืองพิษณุโลก",
    "districtNameEn": "Mueang Phitsanulok",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650115",
    "subDistrictNameTh": "พลายชุมพล",
    "subDistrictNameEn": "Phlai Chumphon",
    "postcode": "65000",
    "districtCode": "6501",
    "districtNameTh": "เมืองพิษณุโลก",
    "districtNameEn": "Mueang Phitsanulok",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650116",
    "subDistrictNameTh": "มะขามสูง",
    "subDistrictNameEn": "Makham Sung",
    "postcode": "65000",
    "districtCode": "6501",
    "districtNameTh": "เมืองพิษณุโลก",
    "districtNameEn": "Mueang Phitsanulok",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650117",
    "subDistrictNameTh": "อรัญญิก",
    "subDistrictNameEn": "Aranyik",
    "postcode": "65000",
    "districtCode": "6501",
    "districtNameTh": "เมืองพิษณุโลก",
    "districtNameEn": "Mueang Phitsanulok",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650118",
    "subDistrictNameTh": "บึงพระ",
    "subDistrictNameEn": "Bueng Phra",
    "postcode": "65000",
    "districtCode": "6501",
    "districtNameTh": "เมืองพิษณุโลก",
    "districtNameEn": "Mueang Phitsanulok",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650119",
    "subDistrictNameTh": "ไผ่ขอดอน",
    "subDistrictNameEn": "Phai Kho Don",
    "postcode": "65000",
    "districtCode": "6501",
    "districtNameTh": "เมืองพิษณุโลก",
    "districtNameEn": "Mueang Phitsanulok",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650120",
    "subDistrictNameTh": "งิ้วงาม",
    "subDistrictNameEn": "Ngio Ngam",
    "postcode": "65230",
    "districtCode": "6501",
    "districtNameTh": "เมืองพิษณุโลก",
    "districtNameEn": "Mueang Phitsanulok",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650201",
    "subDistrictNameTh": "นครไทย",
    "subDistrictNameEn": "Nakhon Thai",
    "postcode": "65120",
    "districtCode": "6502",
    "districtNameTh": "นครไทย",
    "districtNameEn": "Nakhon Thai",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650202",
    "subDistrictNameTh": "หนองกะท้าว",
    "subDistrictNameEn": "Nong Kathao",
    "postcode": "65120",
    "districtCode": "6502",
    "districtNameTh": "นครไทย",
    "districtNameEn": "Nakhon Thai",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650203",
    "subDistrictNameTh": "บ้านแยง",
    "subDistrictNameEn": "Ban Yaeng",
    "postcode": "65120",
    "districtCode": "6502",
    "districtNameTh": "นครไทย",
    "districtNameEn": "Nakhon Thai",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650204",
    "subDistrictNameTh": "เนินเพิ่ม",
    "subDistrictNameEn": "Noen Phoem",
    "postcode": "65120",
    "districtCode": "6502",
    "districtNameTh": "นครไทย",
    "districtNameEn": "Nakhon Thai",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650205",
    "subDistrictNameTh": "นาบัว",
    "subDistrictNameEn": "Na Bua",
    "postcode": "65120",
    "districtCode": "6502",
    "districtNameTh": "นครไทย",
    "districtNameEn": "Nakhon Thai",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650206",
    "subDistrictNameTh": "นครชุม",
    "subDistrictNameEn": "Nakhon Chum",
    "postcode": "65120",
    "districtCode": "6502",
    "districtNameTh": "นครไทย",
    "districtNameEn": "Nakhon Thai",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650207",
    "subDistrictNameTh": "น้ำกุ่ม",
    "subDistrictNameEn": "Nam Kum",
    "postcode": "65120",
    "districtCode": "6502",
    "districtNameTh": "นครไทย",
    "districtNameEn": "Nakhon Thai",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650208",
    "subDistrictNameTh": "ยางโกลน",
    "subDistrictNameEn": "Yang Klon",
    "postcode": "65120",
    "districtCode": "6502",
    "districtNameTh": "นครไทย",
    "districtNameEn": "Nakhon Thai",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650209",
    "subDistrictNameTh": "บ่อโพธิ์",
    "subDistrictNameEn": "Bo Pho",
    "postcode": "65120",
    "districtCode": "6502",
    "districtNameTh": "นครไทย",
    "districtNameEn": "Nakhon Thai",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650210",
    "subDistrictNameTh": "บ้านพร้าว",
    "subDistrictNameEn": "Ban Phrao",
    "postcode": "65120",
    "districtCode": "6502",
    "districtNameTh": "นครไทย",
    "districtNameEn": "Nakhon Thai",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650211",
    "subDistrictNameTh": "ห้วยเฮี้ย",
    "subDistrictNameEn": "Huai Hia",
    "postcode": "65120",
    "districtCode": "6502",
    "districtNameTh": "นครไทย",
    "districtNameEn": "Nakhon Thai",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650301",
    "subDistrictNameTh": "ป่าแดง",
    "subDistrictNameEn": "Pa Daeng",
    "postcode": "65170",
    "districtCode": "6503",
    "districtNameTh": "ชาติตระการ",
    "districtNameEn": "Chat Trakan",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650302",
    "subDistrictNameTh": "ชาติตระการ",
    "subDistrictNameEn": "Chat Trakan",
    "postcode": "65170",
    "districtCode": "6503",
    "districtNameTh": "ชาติตระการ",
    "districtNameEn": "Chat Trakan",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650303",
    "subDistrictNameTh": "สวนเมี่ยง",
    "subDistrictNameEn": "Suan Miang",
    "postcode": "65170",
    "districtCode": "6503",
    "districtNameTh": "ชาติตระการ",
    "districtNameEn": "Chat Trakan",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650304",
    "subDistrictNameTh": "บ้านดง",
    "subDistrictNameEn": "Ban Dong",
    "postcode": "65170",
    "districtCode": "6503",
    "districtNameTh": "ชาติตระการ",
    "districtNameEn": "Chat Trakan",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650305",
    "subDistrictNameTh": "บ่อภาค",
    "subDistrictNameEn": "Bo Phak",
    "postcode": "65170",
    "districtCode": "6503",
    "districtNameTh": "ชาติตระการ",
    "districtNameEn": "Chat Trakan",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650306",
    "subDistrictNameTh": "ท่าสะแก",
    "subDistrictNameEn": "Tha Sakae",
    "postcode": "65170",
    "districtCode": "6503",
    "districtNameTh": "ชาติตระการ",
    "districtNameEn": "Chat Trakan",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650401",
    "subDistrictNameTh": "บางระกำ",
    "subDistrictNameEn": "Bang Rakam",
    "postcode": "65140",
    "districtCode": "6504",
    "districtNameTh": "บางระกำ",
    "districtNameEn": "Bang Rakam",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650402",
    "subDistrictNameTh": "ปลักแรด",
    "subDistrictNameEn": "Plak Raet",
    "postcode": "65140",
    "districtCode": "6504",
    "districtNameTh": "บางระกำ",
    "districtNameEn": "Bang Rakam",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650403",
    "subDistrictNameTh": "พันเสา",
    "subDistrictNameEn": "Phan Sao",
    "postcode": "65140",
    "districtCode": "6504",
    "districtNameTh": "บางระกำ",
    "districtNameEn": "Bang Rakam",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650404",
    "subDistrictNameTh": "วังอิทก",
    "subDistrictNameEn": "Wang Ithok",
    "postcode": "65140",
    "districtCode": "6504",
    "districtNameTh": "บางระกำ",
    "districtNameEn": "Bang Rakam",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650405",
    "subDistrictNameTh": "บึงกอก",
    "subDistrictNameEn": "Bueng Kok",
    "postcode": "65140",
    "districtCode": "6504",
    "districtNameTh": "บางระกำ",
    "districtNameEn": "Bang Rakam",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650406",
    "subDistrictNameTh": "หนองกุลา",
    "subDistrictNameEn": "Nong Kula",
    "postcode": "65140",
    "districtCode": "6504",
    "districtNameTh": "บางระกำ",
    "districtNameEn": "Bang Rakam",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650407",
    "subDistrictNameTh": "ชุมแสงสงคราม",
    "subDistrictNameEn": "Chum Saeng Songkhram",
    "postcode": "65240",
    "districtCode": "6504",
    "districtNameTh": "บางระกำ",
    "districtNameEn": "Bang Rakam",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650408",
    "subDistrictNameTh": "นิคมพัฒนา",
    "subDistrictNameEn": "Nikhom Phatthana",
    "postcode": "65140",
    "districtCode": "6504",
    "districtNameTh": "บางระกำ",
    "districtNameEn": "Bang Rakam",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650409",
    "subDistrictNameTh": "บ่อทอง",
    "subDistrictNameEn": "Bo Thong",
    "postcode": "65140",
    "districtCode": "6504",
    "districtNameTh": "บางระกำ",
    "districtNameEn": "Bang Rakam",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650410",
    "subDistrictNameTh": "ท่านางงาม",
    "subDistrictNameEn": "Tha Nang Ngam",
    "postcode": "65140",
    "districtCode": "6504",
    "districtNameTh": "บางระกำ",
    "districtNameEn": "Bang Rakam",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650411",
    "subDistrictNameTh": "คุยม่วง",
    "subDistrictNameEn": "Khui Muang",
    "postcode": "65240",
    "districtCode": "6504",
    "districtNameTh": "บางระกำ",
    "districtNameEn": "Bang Rakam",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650501",
    "subDistrictNameTh": "บางกระทุ่ม",
    "subDistrictNameEn": "Bang Krathum",
    "postcode": "65110",
    "districtCode": "6505",
    "districtNameTh": "บางกระทุ่ม",
    "districtNameEn": "Bang Krathum",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650502",
    "subDistrictNameTh": "บ้านไร่",
    "subDistrictNameEn": "Ban Rai",
    "postcode": "65110",
    "districtCode": "6505",
    "districtNameTh": "บางกระทุ่ม",
    "districtNameEn": "Bang Krathum",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650503",
    "subDistrictNameTh": "โคกสลุด",
    "subDistrictNameEn": "Khok Salut",
    "postcode": "65110",
    "districtCode": "6505",
    "districtNameTh": "บางกระทุ่ม",
    "districtNameEn": "Bang Krathum",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650504",
    "subDistrictNameTh": "สนามคลี",
    "subDistrictNameEn": "Sanam Khli",
    "postcode": "65110",
    "districtCode": "6505",
    "districtNameTh": "บางกระทุ่ม",
    "districtNameEn": "Bang Krathum",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650505",
    "subDistrictNameTh": "ท่าตาล",
    "subDistrictNameEn": "Tha Tan",
    "postcode": "65110",
    "districtCode": "6505",
    "districtNameTh": "บางกระทุ่ม",
    "districtNameEn": "Bang Krathum",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650506",
    "subDistrictNameTh": "ไผ่ล้อม",
    "subDistrictNameEn": "Phai Lom",
    "postcode": "65110",
    "districtCode": "6505",
    "districtNameTh": "บางกระทุ่ม",
    "districtNameEn": "Bang Krathum",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650507",
    "subDistrictNameTh": "นครป่าหมาก",
    "subDistrictNameEn": "Nakhon Pa Mak",
    "postcode": "65110",
    "districtCode": "6505",
    "districtNameTh": "บางกระทุ่ม",
    "districtNameEn": "Bang Krathum",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650508",
    "subDistrictNameTh": "เนินกุ่ม",
    "subDistrictNameEn": "Noen Kum",
    "postcode": "65210",
    "districtCode": "6505",
    "districtNameTh": "บางกระทุ่ม",
    "districtNameEn": "Bang Krathum",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650509",
    "subDistrictNameTh": "วัดตายม",
    "subDistrictNameEn": "Wat Ta Yom",
    "postcode": "65210",
    "districtCode": "6505",
    "districtNameTh": "บางกระทุ่ม",
    "districtNameEn": "Bang Krathum",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650601",
    "subDistrictNameTh": "พรหมพิราม",
    "subDistrictNameEn": "Phrom Phiram",
    "postcode": "65150",
    "districtCode": "6506",
    "districtNameTh": "พรหมพิราม",
    "districtNameEn": "Phrom Phiram",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650602",
    "subDistrictNameTh": "ท่าช้าง",
    "subDistrictNameEn": "Tha Chang",
    "postcode": "65150",
    "districtCode": "6506",
    "districtNameTh": "พรหมพิราม",
    "districtNameEn": "Phrom Phiram",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650603",
    "subDistrictNameTh": "วงฆ้อง",
    "subDistrictNameEn": "Wong Khong",
    "postcode": "65180",
    "districtCode": "6506",
    "districtNameTh": "พรหมพิราม",
    "districtNameEn": "Phrom Phiram",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650604",
    "subDistrictNameTh": "มะตูม",
    "subDistrictNameEn": "Matum",
    "postcode": "65150",
    "districtCode": "6506",
    "districtNameTh": "พรหมพิราม",
    "districtNameEn": "Phrom Phiram",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650605",
    "subDistrictNameTh": "หอกลอง",
    "subDistrictNameEn": "Ho Klong",
    "postcode": "65150",
    "districtCode": "6506",
    "districtNameTh": "พรหมพิราม",
    "districtNameEn": "Phrom Phiram",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650606",
    "subDistrictNameTh": "ศรีภิรมย์",
    "subDistrictNameEn": "Si Phirom",
    "postcode": "65180",
    "districtCode": "6506",
    "districtNameTh": "พรหมพิราม",
    "districtNameEn": "Phrom Phiram",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650607",
    "subDistrictNameTh": "ตลุกเทียม",
    "subDistrictNameEn": "Taluk Thiam",
    "postcode": "65180",
    "districtCode": "6506",
    "districtNameTh": "พรหมพิราม",
    "districtNameEn": "Phrom Phiram",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650608",
    "subDistrictNameTh": "วังวน",
    "subDistrictNameEn": "Wang Won",
    "postcode": "65150",
    "districtCode": "6506",
    "districtNameTh": "พรหมพิราม",
    "districtNameEn": "Phrom Phiram",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650609",
    "subDistrictNameTh": "หนองแขม",
    "subDistrictNameEn": "Nong Khaem",
    "postcode": "65150",
    "districtCode": "6506",
    "districtNameTh": "พรหมพิราม",
    "districtNameEn": "Phrom Phiram",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650610",
    "subDistrictNameTh": "มะต้อง",
    "subDistrictNameEn": "Matong",
    "postcode": "65180",
    "districtCode": "6506",
    "districtNameTh": "พรหมพิราม",
    "districtNameEn": "Phrom Phiram",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650611",
    "subDistrictNameTh": "ทับยายเชียง",
    "subDistrictNameEn": "Thap Yai Chiang",
    "postcode": "65150",
    "districtCode": "6506",
    "districtNameTh": "พรหมพิราม",
    "districtNameEn": "Phrom Phiram",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650612",
    "subDistrictNameTh": "ดงประคำ",
    "subDistrictNameEn": "Dong Prakham",
    "postcode": "65180",
    "districtCode": "6506",
    "districtNameTh": "พรหมพิราม",
    "districtNameEn": "Phrom Phiram",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650701",
    "subDistrictNameTh": "วัดโบสถ์",
    "subDistrictNameEn": "Wat Bot",
    "postcode": "65160",
    "districtCode": "6507",
    "districtNameTh": "วัดโบสถ์",
    "districtNameEn": "Wat Bot",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650702",
    "subDistrictNameTh": "ท่างาม",
    "subDistrictNameEn": "Tha Ngam",
    "postcode": "65160",
    "districtCode": "6507",
    "districtNameTh": "วัดโบสถ์",
    "districtNameEn": "Wat Bot",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650703",
    "subDistrictNameTh": "ท้อแท้",
    "subDistrictNameEn": "Thothae",
    "postcode": "65160",
    "districtCode": "6507",
    "districtNameTh": "วัดโบสถ์",
    "districtNameEn": "Wat Bot",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650704",
    "subDistrictNameTh": "บ้านยาง",
    "subDistrictNameEn": "Ban Yang",
    "postcode": "65160",
    "districtCode": "6507",
    "districtNameTh": "วัดโบสถ์",
    "districtNameEn": "Wat Bot",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650705",
    "subDistrictNameTh": "หินลาด",
    "subDistrictNameEn": "Hin Lat",
    "postcode": "65160",
    "districtCode": "6507",
    "districtNameTh": "วัดโบสถ์",
    "districtNameEn": "Wat Bot",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650706",
    "subDistrictNameTh": "คันโช้ง",
    "subDistrictNameEn": "Khan Chong",
    "postcode": "65160",
    "districtCode": "6507",
    "districtNameTh": "วัดโบสถ์",
    "districtNameEn": "Wat Bot",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650801",
    "subDistrictNameTh": "วังทอง",
    "subDistrictNameEn": "Wang Thong",
    "postcode": "65130",
    "districtCode": "6508",
    "districtNameTh": "วังทอง",
    "districtNameEn": "Wang Thong",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650802",
    "subDistrictNameTh": "พันชาลี",
    "subDistrictNameEn": "Phan Chali",
    "postcode": "65130",
    "districtCode": "6508",
    "districtNameTh": "วังทอง",
    "districtNameEn": "Wang Thong",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650803",
    "subDistrictNameTh": "แม่ระกา",
    "subDistrictNameEn": "Mae Raka",
    "postcode": "65130",
    "districtCode": "6508",
    "districtNameTh": "วังทอง",
    "districtNameEn": "Wang Thong",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650804",
    "subDistrictNameTh": "บ้านกลาง",
    "subDistrictNameEn": "Ban Klang",
    "postcode": "65220",
    "districtCode": "6508",
    "districtNameTh": "วังทอง",
    "districtNameEn": "Wang Thong",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650805",
    "subDistrictNameTh": "วังพิกุล",
    "subDistrictNameEn": "Wang Phikun",
    "postcode": "65130",
    "districtCode": "6508",
    "districtNameTh": "วังทอง",
    "districtNameEn": "Wang Thong",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650806",
    "subDistrictNameTh": "แก่งโสภา",
    "subDistrictNameEn": "Kaeng Sopha",
    "postcode": "65220",
    "districtCode": "6508",
    "districtNameTh": "วังทอง",
    "districtNameEn": "Wang Thong",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650807",
    "subDistrictNameTh": "ท่าหมื่นราม",
    "subDistrictNameEn": "Tha Muen Ram",
    "postcode": "65130",
    "districtCode": "6508",
    "districtNameTh": "วังทอง",
    "districtNameEn": "Wang Thong",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650808",
    "subDistrictNameTh": "วังนกแอ่น",
    "subDistrictNameEn": "Wang Nok Aen",
    "postcode": "65130",
    "districtCode": "6508",
    "districtNameTh": "วังทอง",
    "districtNameEn": "Wang Thong",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650809",
    "subDistrictNameTh": "หนองพระ",
    "subDistrictNameEn": "Nong Phra",
    "postcode": "65130",
    "districtCode": "6508",
    "districtNameTh": "วังทอง",
    "districtNameEn": "Wang Thong",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650810",
    "subDistrictNameTh": "ชัยนาม",
    "subDistrictNameEn": "Chai Nam",
    "postcode": "65130",
    "districtCode": "6508",
    "districtNameTh": "วังทอง",
    "districtNameEn": "Wang Thong",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650811",
    "subDistrictNameTh": "ดินทอง",
    "subDistrictNameEn": "Din Thong",
    "postcode": "65130",
    "districtCode": "6508",
    "districtNameTh": "วังทอง",
    "districtNameEn": "Wang Thong",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650901",
    "subDistrictNameTh": "ชมพู",
    "subDistrictNameEn": "Chomphu",
    "postcode": "65190",
    "districtCode": "6509",
    "districtNameTh": "เนินมะปราง",
    "districtNameEn": "Noen Maprang",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650902",
    "subDistrictNameTh": "บ้านมุง",
    "subDistrictNameEn": "Ban Mung",
    "postcode": "65190",
    "districtCode": "6509",
    "districtNameTh": "เนินมะปราง",
    "districtNameEn": "Noen Maprang",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650903",
    "subDistrictNameTh": "ไทรย้อย",
    "subDistrictNameEn": "Sai Yoi",
    "postcode": "65190",
    "districtCode": "6509",
    "districtNameTh": "เนินมะปราง",
    "districtNameEn": "Noen Maprang",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650904",
    "subDistrictNameTh": "วังโพรง",
    "subDistrictNameEn": "Wang Phrong",
    "postcode": "65190",
    "districtCode": "6509",
    "districtNameTh": "เนินมะปราง",
    "districtNameEn": "Noen Maprang",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650905",
    "subDistrictNameTh": "บ้านน้อยซุ้มขี้เหล็ก",
    "subDistrictNameEn": "Ban Noi Sum Khilek",
    "postcode": "65190",
    "districtCode": "6509",
    "districtNameTh": "เนินมะปราง",
    "districtNameEn": "Noen Maprang",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650906",
    "subDistrictNameTh": "เนินมะปราง",
    "subDistrictNameEn": "Noen Maprang",
    "postcode": "65190",
    "districtCode": "6509",
    "districtNameTh": "เนินมะปราง",
    "districtNameEn": "Noen Maprang",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "650907",
    "subDistrictNameTh": "วังยาง",
    "subDistrictNameEn": "Wang Yang",
    "postcode": "65190",
    "districtCode": "6509",
    "districtNameTh": "เนินมะปราง",
    "districtNameEn": "Noen Maprang",
    "provinceCode": "65",
    "provinceNameTh": "พิษณุโลก",
    "provinceNameEn": "Phitsanulok"
},
{
    "subDistrictCode": "660101",
    "subDistrictNameTh": "ในเมือง",
    "subDistrictNameEn": "Nai Mueang",
    "postcode": "66000",
    "districtCode": "6601",
    "districtNameTh": "เมืองพิจิตร",
    "districtNameEn": "Mueang Phichit",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660102",
    "subDistrictNameTh": "ไผ่ขวาง",
    "subDistrictNameEn": "Phai Khwang",
    "postcode": "66000",
    "districtCode": "6601",
    "districtNameTh": "เมืองพิจิตร",
    "districtNameEn": "Mueang Phichit",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660103",
    "subDistrictNameTh": "ย่านยาว",
    "subDistrictNameEn": "Yan Yao",
    "postcode": "66000",
    "districtCode": "6601",
    "districtNameTh": "เมืองพิจิตร",
    "districtNameEn": "Mueang Phichit",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660104",
    "subDistrictNameTh": "ท่าฬ่อ",
    "subDistrictNameEn": "Tha Lo",
    "postcode": "66000",
    "districtCode": "6601",
    "districtNameTh": "เมืองพิจิตร",
    "districtNameEn": "Mueang Phichit",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660105",
    "subDistrictNameTh": "ปากทาง",
    "subDistrictNameEn": "Pak Thang",
    "postcode": "66000",
    "districtCode": "6601",
    "districtNameTh": "เมืองพิจิตร",
    "districtNameEn": "Mueang Phichit",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660106",
    "subDistrictNameTh": "คลองคะเชนทร์",
    "subDistrictNameEn": "Khlong Khachen",
    "postcode": "66000",
    "districtCode": "6601",
    "districtNameTh": "เมืองพิจิตร",
    "districtNameEn": "Mueang Phichit",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660107",
    "subDistrictNameTh": "โรงช้าง",
    "subDistrictNameEn": "Rong Chang",
    "postcode": "66000",
    "districtCode": "6601",
    "districtNameTh": "เมืองพิจิตร",
    "districtNameEn": "Mueang Phichit",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660108",
    "subDistrictNameTh": "เมืองเก่า",
    "subDistrictNameEn": "Mueang Kao",
    "postcode": "66000",
    "districtCode": "6601",
    "districtNameTh": "เมืองพิจิตร",
    "districtNameEn": "Mueang Phichit",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660109",
    "subDistrictNameTh": "ท่าหลวง",
    "subDistrictNameEn": "Tha Luang",
    "postcode": "66000",
    "districtCode": "6601",
    "districtNameTh": "เมืองพิจิตร",
    "districtNameEn": "Mueang Phichit",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660110",
    "subDistrictNameTh": "บ้านบุ่ง",
    "subDistrictNameEn": "Ban Bung",
    "postcode": "66000",
    "districtCode": "6601",
    "districtNameTh": "เมืองพิจิตร",
    "districtNameEn": "Mueang Phichit",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660111",
    "subDistrictNameTh": "ฆะมัง",
    "subDistrictNameEn": "Kha Mung",
    "postcode": "66000",
    "districtCode": "6601",
    "districtNameTh": "เมืองพิจิตร",
    "districtNameEn": "Mueang Phichit",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660112",
    "subDistrictNameTh": "ดงป่าคำ",
    "subDistrictNameEn": "Dong Pa Kham",
    "postcode": "66170",
    "districtCode": "6601",
    "districtNameTh": "เมืองพิจิตร",
    "districtNameEn": "Mueang Phichit",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660113",
    "subDistrictNameTh": "หัวดง",
    "subDistrictNameEn": "Hua Dong",
    "postcode": "66170",
    "districtCode": "6601",
    "districtNameTh": "เมืองพิจิตร",
    "districtNameEn": "Mueang Phichit",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660115",
    "subDistrictNameTh": "ป่ามะคาบ",
    "subDistrictNameEn": "Pa Makhap",
    "postcode": "66000",
    "districtCode": "6601",
    "districtNameTh": "เมืองพิจิตร",
    "districtNameEn": "Mueang Phichit",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660119",
    "subDistrictNameTh": "สายคำโห้",
    "subDistrictNameEn": "Sai Kham Ho",
    "postcode": "66000",
    "districtCode": "6601",
    "districtNameTh": "เมืองพิจิตร",
    "districtNameEn": "Mueang Phichit",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660120",
    "subDistrictNameTh": "ดงกลาง",
    "subDistrictNameEn": "Dong Klang",
    "postcode": "66170",
    "districtCode": "6601",
    "districtNameTh": "เมืองพิจิตร",
    "districtNameEn": "Mueang Phichit",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660201",
    "subDistrictNameTh": "วังทรายพูน",
    "subDistrictNameEn": "Wang Sai Phun",
    "postcode": "66180",
    "districtCode": "6602",
    "districtNameTh": "วังทรายพูน",
    "districtNameEn": "Wang Sai Phun",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660202",
    "subDistrictNameTh": "หนองปลาไหล",
    "subDistrictNameEn": "Nong Pla Lai",
    "postcode": "66180",
    "districtCode": "6602",
    "districtNameTh": "วังทรายพูน",
    "districtNameEn": "Wang Sai Phun",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660203",
    "subDistrictNameTh": "หนองพระ",
    "subDistrictNameEn": "Nong Phra",
    "postcode": "66180",
    "districtCode": "6602",
    "districtNameTh": "วังทรายพูน",
    "districtNameEn": "Wang Sai Phun",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660204",
    "subDistrictNameTh": "หนองปล้อง",
    "subDistrictNameEn": "Nong Plong",
    "postcode": "66180",
    "districtCode": "6602",
    "districtNameTh": "วังทรายพูน",
    "districtNameEn": "Wang Sai Phun",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660301",
    "subDistrictNameTh": "โพธิ์ประทับช้าง",
    "subDistrictNameEn": "Pho Prathap Chang",
    "postcode": "66190",
    "districtCode": "6603",
    "districtNameTh": "โพธิ์ประทับช้าง",
    "districtNameEn": "Pho Prathap Chang",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660302",
    "subDistrictNameTh": "ไผ่ท่าโพ",
    "subDistrictNameEn": "Phai Tha Pho",
    "postcode": "66190",
    "districtCode": "6603",
    "districtNameTh": "โพธิ์ประทับช้าง",
    "districtNameEn": "Pho Prathap Chang",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660303",
    "subDistrictNameTh": "วังจิก",
    "subDistrictNameEn": "Wang Chik",
    "postcode": "66190",
    "districtCode": "6603",
    "districtNameTh": "โพธิ์ประทับช้าง",
    "districtNameEn": "Pho Prathap Chang",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660304",
    "subDistrictNameTh": "ไผ่รอบ",
    "subDistrictNameEn": "Phai Rop",
    "postcode": "66190",
    "districtCode": "6603",
    "districtNameTh": "โพธิ์ประทับช้าง",
    "districtNameEn": "Pho Prathap Chang",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660305",
    "subDistrictNameTh": "ดงเสือเหลือง",
    "subDistrictNameEn": "Dong Suea Lueang",
    "postcode": "66190",
    "districtCode": "6603",
    "districtNameTh": "โพธิ์ประทับช้าง",
    "districtNameEn": "Pho Prathap Chang",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660306",
    "subDistrictNameTh": "เนินสว่าง",
    "subDistrictNameEn": "Noen Sawang",
    "postcode": "66190",
    "districtCode": "6603",
    "districtNameTh": "โพธิ์ประทับช้าง",
    "districtNameEn": "Pho Prathap Chang",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660307",
    "subDistrictNameTh": "ทุ่งใหญ่",
    "subDistrictNameEn": "Thung Yai",
    "postcode": "66190",
    "districtCode": "6603",
    "districtNameTh": "โพธิ์ประทับช้าง",
    "districtNameEn": "Pho Prathap Chang",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660401",
    "subDistrictNameTh": "ตะพานหิน",
    "subDistrictNameEn": "Taphan Hin",
    "postcode": "66110",
    "districtCode": "6604",
    "districtNameTh": "ตะพานหิน",
    "districtNameEn": "Taphan Hin",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660402",
    "subDistrictNameTh": "งิ้วราย",
    "subDistrictNameEn": "Ngio Rai",
    "postcode": "66110",
    "districtCode": "6604",
    "districtNameTh": "ตะพานหิน",
    "districtNameEn": "Taphan Hin",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660403",
    "subDistrictNameTh": "ห้วยเกตุ",
    "subDistrictNameEn": "Huai Ket",
    "postcode": "66110",
    "districtCode": "6604",
    "districtNameTh": "ตะพานหิน",
    "districtNameEn": "Taphan Hin",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660404",
    "subDistrictNameTh": "ไทรโรงโขน",
    "subDistrictNameEn": "Sai Rong Khon",
    "postcode": "66110",
    "districtCode": "6604",
    "districtNameTh": "ตะพานหิน",
    "districtNameEn": "Taphan Hin",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660405",
    "subDistrictNameTh": "หนองพยอม",
    "subDistrictNameEn": "Nong Phayom",
    "postcode": "66110",
    "districtCode": "6604",
    "districtNameTh": "ตะพานหิน",
    "districtNameEn": "Taphan Hin",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660406",
    "subDistrictNameTh": "ทุ่งโพธิ์",
    "subDistrictNameEn": "Thung Pho",
    "postcode": "66150",
    "districtCode": "6604",
    "districtNameTh": "ตะพานหิน",
    "districtNameEn": "Taphan Hin",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660407",
    "subDistrictNameTh": "ดงตะขบ",
    "subDistrictNameEn": "Dong Takhop",
    "postcode": "66110",
    "districtCode": "6604",
    "districtNameTh": "ตะพานหิน",
    "districtNameEn": "Taphan Hin",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660408",
    "subDistrictNameTh": "คลองคูณ",
    "subDistrictNameEn": "Khlong Khun",
    "postcode": "66110",
    "districtCode": "6604",
    "districtNameTh": "ตะพานหิน",
    "districtNameEn": "Taphan Hin",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660409",
    "subDistrictNameTh": "วังสำโรง",
    "subDistrictNameEn": "Wang Samrong",
    "postcode": "66110",
    "districtCode": "6604",
    "districtNameTh": "ตะพานหิน",
    "districtNameEn": "Taphan Hin",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660410",
    "subDistrictNameTh": "วังหว้า",
    "subDistrictNameEn": "Wang Wa",
    "postcode": "66110",
    "districtCode": "6604",
    "districtNameTh": "ตะพานหิน",
    "districtNameEn": "Taphan Hin",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660411",
    "subDistrictNameTh": "วังหลุม",
    "subDistrictNameEn": "Wang Lum",
    "postcode": "66150",
    "districtCode": "6604",
    "districtNameTh": "ตะพานหิน",
    "districtNameEn": "Taphan Hin",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660412",
    "subDistrictNameTh": "ทับหมัน",
    "subDistrictNameEn": "Thap Man",
    "postcode": "66110",
    "districtCode": "6604",
    "districtNameTh": "ตะพานหิน",
    "districtNameEn": "Taphan Hin",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660413",
    "subDistrictNameTh": "ไผ่หลวง",
    "subDistrictNameEn": "Phai Luang",
    "postcode": "66110",
    "districtCode": "6604",
    "districtNameTh": "ตะพานหิน",
    "districtNameEn": "Taphan Hin",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660501",
    "subDistrictNameTh": "บางมูลนาก",
    "subDistrictNameEn": "Bang Mun Nak",
    "postcode": "66120",
    "districtCode": "6605",
    "districtNameTh": "บางมูลนาก",
    "districtNameEn": "Bang Mun Nak",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660502",
    "subDistrictNameTh": "บางไผ่",
    "subDistrictNameEn": "Bang Phai",
    "postcode": "66120",
    "districtCode": "6605",
    "districtNameTh": "บางมูลนาก",
    "districtNameEn": "Bang Mun Nak",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660503",
    "subDistrictNameTh": "หอไกร",
    "subDistrictNameEn": "Ho Krai",
    "postcode": "66120",
    "districtCode": "6605",
    "districtNameTh": "บางมูลนาก",
    "districtNameEn": "Bang Mun Nak",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660504",
    "subDistrictNameTh": "เนินมะกอก",
    "subDistrictNameEn": "Noen Makok",
    "postcode": "66120",
    "districtCode": "6605",
    "districtNameTh": "บางมูลนาก",
    "districtNameEn": "Bang Mun Nak",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660505",
    "subDistrictNameTh": "วังสำโรง",
    "subDistrictNameEn": "Wang Samrong",
    "postcode": "66120",
    "districtCode": "6605",
    "districtNameTh": "บางมูลนาก",
    "districtNameEn": "Bang Mun Nak",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660506",
    "subDistrictNameTh": "ภูมิ",
    "subDistrictNameEn": "Phum",
    "postcode": "66120",
    "districtCode": "6605",
    "districtNameTh": "บางมูลนาก",
    "districtNameEn": "Bang Mun Nak",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660507",
    "subDistrictNameTh": "วังกรด",
    "subDistrictNameEn": "Wang Krot",
    "postcode": "66120",
    "districtCode": "6605",
    "districtNameTh": "บางมูลนาก",
    "districtNameEn": "Bang Mun Nak",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660508",
    "subDistrictNameTh": "ห้วยเขน",
    "subDistrictNameEn": "Huai Khen",
    "postcode": "66120",
    "districtCode": "6605",
    "districtNameTh": "บางมูลนาก",
    "districtNameEn": "Bang Mun Nak",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660509",
    "subDistrictNameTh": "วังตะกู",
    "subDistrictNameEn": "Wang Taku",
    "postcode": "66210",
    "districtCode": "6605",
    "districtNameTh": "บางมูลนาก",
    "districtNameEn": "Bang Mun Nak",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660514",
    "subDistrictNameTh": "ลำประดา",
    "subDistrictNameEn": "Lam Prada",
    "postcode": "66120",
    "districtCode": "6605",
    "districtNameTh": "บางมูลนาก",
    "districtNameEn": "Bang Mun Nak",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660601",
    "subDistrictNameTh": "โพทะเล",
    "subDistrictNameEn": "Pho Thale",
    "postcode": "66130",
    "districtCode": "6606",
    "districtNameTh": "โพทะเล",
    "districtNameEn": "Pho Thale",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660602",
    "subDistrictNameTh": "ท้ายน้ำ",
    "subDistrictNameEn": "Thai Nam",
    "postcode": "66130",
    "districtCode": "6606",
    "districtNameTh": "โพทะเล",
    "districtNameEn": "Pho Thale",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660603",
    "subDistrictNameTh": "ทะนง",
    "subDistrictNameEn": "Thanong",
    "postcode": "66130",
    "districtCode": "6606",
    "districtNameTh": "โพทะเล",
    "districtNameEn": "Pho Thale",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660604",
    "subDistrictNameTh": "ท่าบัว",
    "subDistrictNameEn": "Tha Bua",
    "postcode": "66130",
    "districtCode": "6606",
    "districtNameTh": "โพทะเล",
    "districtNameEn": "Pho Thale",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660605",
    "subDistrictNameTh": "ทุ่งน้อย",
    "subDistrictNameEn": "Thung Noi",
    "postcode": "66130",
    "districtCode": "6606",
    "districtNameTh": "โพทะเล",
    "districtNameEn": "Pho Thale",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660606",
    "subDistrictNameTh": "ท่าขมิ้น",
    "subDistrictNameEn": "Tha Khamin",
    "postcode": "66130",
    "districtCode": "6606",
    "districtNameTh": "โพทะเล",
    "districtNameEn": "Pho Thale",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660607",
    "subDistrictNameTh": "ท่าเสา",
    "subDistrictNameEn": "Tha Sao",
    "postcode": "66130",
    "districtCode": "6606",
    "districtNameTh": "โพทะเล",
    "districtNameEn": "Pho Thale",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660608",
    "subDistrictNameTh": "บางคลาน",
    "subDistrictNameEn": "Bang Khlan",
    "postcode": "66130",
    "districtCode": "6606",
    "districtNameTh": "โพทะเล",
    "districtNameEn": "Pho Thale",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660611",
    "subDistrictNameTh": "ท่านั่ง",
    "subDistrictNameEn": "Tha Nang",
    "postcode": "66130",
    "districtCode": "6606",
    "districtNameTh": "โพทะเล",
    "districtNameEn": "Pho Thale",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660612",
    "subDistrictNameTh": "บ้านน้อย",
    "subDistrictNameEn": "Ban Noi",
    "postcode": "66130",
    "districtCode": "6606",
    "districtNameTh": "โพทะเล",
    "districtNameEn": "Pho Thale",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660613",
    "subDistrictNameTh": "วัดขวาง",
    "subDistrictNameEn": "Wat Khwang",
    "postcode": "66130",
    "districtCode": "6606",
    "districtNameTh": "โพทะเล",
    "districtNameEn": "Pho Thale",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660701",
    "subDistrictNameTh": "สามง่าม",
    "subDistrictNameEn": "Sam Ngam",
    "postcode": "66140",
    "districtCode": "6607",
    "districtNameTh": "สามง่าม",
    "districtNameEn": "Sam Ngam",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660702",
    "subDistrictNameTh": "กำแพงดิน",
    "subDistrictNameEn": "Kamphaeng Din",
    "postcode": "66220",
    "districtCode": "6607",
    "districtNameTh": "สามง่าม",
    "districtNameEn": "Sam Ngam",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660703",
    "subDistrictNameTh": "รังนก",
    "subDistrictNameEn": "Rang Nok",
    "postcode": "66140",
    "districtCode": "6607",
    "districtNameTh": "สามง่าม",
    "districtNameEn": "Sam Ngam",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660706",
    "subDistrictNameTh": "เนินปอ",
    "subDistrictNameEn": "Noen Po",
    "postcode": "66140",
    "districtCode": "6607",
    "districtNameTh": "สามง่าม",
    "districtNameEn": "Sam Ngam",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660707",
    "subDistrictNameTh": "หนองโสน",
    "subDistrictNameEn": "Nong Sano",
    "postcode": "66140",
    "districtCode": "6607",
    "districtNameTh": "สามง่าม",
    "districtNameEn": "Sam Ngam",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660801",
    "subDistrictNameTh": "ทับคล้อ",
    "subDistrictNameEn": "Thap Khlo",
    "postcode": "66150",
    "districtCode": "6608",
    "districtNameTh": "ทับคล้อ",
    "districtNameEn": "Thap Khlo",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660802",
    "subDistrictNameTh": "เขาทราย",
    "subDistrictNameEn": "Khao Sai",
    "postcode": "66230",
    "districtCode": "6608",
    "districtNameTh": "ทับคล้อ",
    "districtNameEn": "Thap Khlo",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660803",
    "subDistrictNameTh": "เขาเจ็ดลูก",
    "subDistrictNameEn": "Khao Chet Luk",
    "postcode": "66230",
    "districtCode": "6608",
    "districtNameTh": "ทับคล้อ",
    "districtNameEn": "Thap Khlo",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660804",
    "subDistrictNameTh": "ท้ายทุ่ง",
    "subDistrictNameEn": "Thai Thung",
    "postcode": "66150",
    "districtCode": "6608",
    "districtNameTh": "ทับคล้อ",
    "districtNameEn": "Thap Khlo",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660901",
    "subDistrictNameTh": "สากเหล็ก",
    "subDistrictNameEn": "Sak Lek",
    "postcode": "66160",
    "districtCode": "6609",
    "districtNameTh": "สากเหล็ก",
    "districtNameEn": "Sak Lek",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660902",
    "subDistrictNameTh": "ท่าเยี่ยม",
    "subDistrictNameEn": "Tha Yiam",
    "postcode": "66160",
    "districtCode": "6609",
    "districtNameTh": "สากเหล็ก",
    "districtNameEn": "Sak Lek",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660903",
    "subDistrictNameTh": "คลองทราย",
    "subDistrictNameEn": "Khlong Sai",
    "postcode": "66160",
    "districtCode": "6609",
    "districtNameTh": "สากเหล็ก",
    "districtNameEn": "Sak Lek",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660904",
    "subDistrictNameTh": "หนองหญ้าไทร",
    "subDistrictNameEn": "Nong Ya Sai",
    "postcode": "66160",
    "districtCode": "6609",
    "districtNameTh": "สากเหล็ก",
    "districtNameEn": "Sak Lek",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "660905",
    "subDistrictNameTh": "วังทับไทร",
    "subDistrictNameEn": "Wang Thap Sai",
    "postcode": "66160",
    "districtCode": "6609",
    "districtNameTh": "สากเหล็ก",
    "districtNameEn": "Sak Lek",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "661001",
    "subDistrictNameTh": "ห้วยแก้ว",
    "subDistrictNameEn": "Huai Kaeo",
    "postcode": "66130",
    "districtCode": "6610",
    "districtNameTh": "บึงนาราง",
    "districtNameEn": "Bueng Na Rang",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "661002",
    "subDistrictNameTh": "โพธิ์ไทรงาม",
    "subDistrictNameEn": "Pho Sai Ngam",
    "postcode": "66130",
    "districtCode": "6610",
    "districtNameTh": "บึงนาราง",
    "districtNameEn": "Bueng Na Rang",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "661003",
    "subDistrictNameTh": "แหลมรัง",
    "subDistrictNameEn": "Laem Rang",
    "postcode": "66130",
    "districtCode": "6610",
    "districtNameTh": "บึงนาราง",
    "districtNameEn": "Bueng Na Rang",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "661004",
    "subDistrictNameTh": "บางลาย",
    "subDistrictNameEn": "Bang Lai",
    "postcode": "66130",
    "districtCode": "6610",
    "districtNameTh": "บึงนาราง",
    "districtNameEn": "Bueng Na Rang",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "661005",
    "subDistrictNameTh": "บึงนาราง",
    "subDistrictNameEn": "Bueng Na Rang",
    "postcode": "66130",
    "districtCode": "6610",
    "districtNameTh": "บึงนาราง",
    "districtNameEn": "Bueng Na Rang",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "661101",
    "subDistrictNameTh": "วังงิ้วใต้",
    "subDistrictNameEn": "Wang Ngio Tai",
    "postcode": "66210",
    "districtCode": "6611",
    "districtNameTh": "ดงเจริญ",
    "districtNameEn": "Dong Charoen",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "661102",
    "subDistrictNameTh": "วังงิ้ว",
    "subDistrictNameEn": "Wang Ngio",
    "postcode": "66210",
    "districtCode": "6611",
    "districtNameTh": "ดงเจริญ",
    "districtNameEn": "Dong Charoen",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "661103",
    "subDistrictNameTh": "ห้วยร่วม",
    "subDistrictNameEn": "Huai Ruam",
    "postcode": "66210",
    "districtCode": "6611",
    "districtNameTh": "ดงเจริญ",
    "districtNameEn": "Dong Charoen",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "661104",
    "subDistrictNameTh": "ห้วยพุก",
    "subDistrictNameEn": "Huai Phuk",
    "postcode": "66210",
    "districtCode": "6611",
    "districtNameTh": "ดงเจริญ",
    "districtNameEn": "Dong Charoen",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "661105",
    "subDistrictNameTh": "สำนักขุนเณร",
    "subDistrictNameEn": "Samnak Khun Nen",
    "postcode": "66210",
    "districtCode": "6611",
    "districtNameTh": "ดงเจริญ",
    "districtNameEn": "Dong Charoen",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "661201",
    "subDistrictNameTh": "บ้านนา",
    "subDistrictNameEn": "Ban Na",
    "postcode": "66140",
    "districtCode": "6612",
    "districtNameTh": "วชิรบารมี",
    "districtNameEn": "Wachirabarami",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "661202",
    "subDistrictNameTh": "บึงบัว",
    "subDistrictNameEn": "Bueng Bua",
    "postcode": "66140",
    "districtCode": "6612",
    "districtNameTh": "วชิรบารมี",
    "districtNameEn": "Wachirabarami",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "661203",
    "subDistrictNameTh": "วังโมกข์",
    "subDistrictNameEn": "Wang Mok",
    "postcode": "66140",
    "districtCode": "6612",
    "districtNameTh": "วชิรบารมี",
    "districtNameEn": "Wachirabarami",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "661204",
    "subDistrictNameTh": "หนองหลุม",
    "subDistrictNameEn": "Nong Lum",
    "postcode": "66220",
    "districtCode": "6612",
    "districtNameTh": "วชิรบารมี",
    "districtNameEn": "Wachirabarami",
    "provinceCode": "66",
    "provinceNameTh": "พิจิตร",
    "provinceNameEn": "Phichit"
},
{
    "subDistrictCode": "670101",
    "subDistrictNameTh": "ในเมือง",
    "subDistrictNameEn": "Nai Mueang",
    "postcode": "67000",
    "districtCode": "6701",
    "districtNameTh": "เมืองเพชรบูรณ์",
    "districtNameEn": "Mueang Phetchabun",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670102",
    "subDistrictNameTh": "ตะเบาะ",
    "subDistrictNameEn": "Tabo",
    "postcode": "67000",
    "districtCode": "6701",
    "districtNameTh": "เมืองเพชรบูรณ์",
    "districtNameEn": "Mueang Phetchabun",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670103",
    "subDistrictNameTh": "บ้านโตก",
    "subDistrictNameEn": "Ban Tok",
    "postcode": "67000",
    "districtCode": "6701",
    "districtNameTh": "เมืองเพชรบูรณ์",
    "districtNameEn": "Mueang Phetchabun",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670104",
    "subDistrictNameTh": "สะเดียง",
    "subDistrictNameEn": "Sadiang",
    "postcode": "67000",
    "districtCode": "6701",
    "districtNameTh": "เมืองเพชรบูรณ์",
    "districtNameEn": "Mueang Phetchabun",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670105",
    "subDistrictNameTh": "ป่าเลา",
    "subDistrictNameEn": "Pa Lao",
    "postcode": "67000",
    "districtCode": "6701",
    "districtNameTh": "เมืองเพชรบูรณ์",
    "districtNameEn": "Mueang Phetchabun",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670106",
    "subDistrictNameTh": "นางั่ว",
    "subDistrictNameEn": "Na Ngua",
    "postcode": "67000",
    "districtCode": "6701",
    "districtNameTh": "เมืองเพชรบูรณ์",
    "districtNameEn": "Mueang Phetchabun",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670107",
    "subDistrictNameTh": "ท่าพล",
    "subDistrictNameEn": "Tha Phon",
    "postcode": "67250",
    "districtCode": "6701",
    "districtNameTh": "เมืองเพชรบูรณ์",
    "districtNameEn": "Mueang Phetchabun",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670108",
    "subDistrictNameTh": "ดงมูลเหล็ก",
    "subDistrictNameEn": "Dong Mun Lek",
    "postcode": "67000",
    "districtCode": "6701",
    "districtNameTh": "เมืองเพชรบูรณ์",
    "districtNameEn": "Mueang Phetchabun",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670109",
    "subDistrictNameTh": "บ้านโคก",
    "subDistrictNameEn": "Ban Khok",
    "postcode": "67000",
    "districtCode": "6701",
    "districtNameTh": "เมืองเพชรบูรณ์",
    "districtNameEn": "Mueang Phetchabun",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670110",
    "subDistrictNameTh": "ชอนไพร",
    "subDistrictNameEn": "Chon Phrai",
    "postcode": "67000",
    "districtCode": "6701",
    "districtNameTh": "เมืองเพชรบูรณ์",
    "districtNameEn": "Mueang Phetchabun",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670111",
    "subDistrictNameTh": "นาป่า",
    "subDistrictNameEn": "Na Pa",
    "postcode": "67000",
    "districtCode": "6701",
    "districtNameTh": "เมืองเพชรบูรณ์",
    "districtNameEn": "Mueang Phetchabun",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670112",
    "subDistrictNameTh": "นายม",
    "subDistrictNameEn": "Na Yom",
    "postcode": "67210",
    "districtCode": "6701",
    "districtNameTh": "เมืองเพชรบูรณ์",
    "districtNameEn": "Mueang Phetchabun",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670113",
    "subDistrictNameTh": "วังชมภู",
    "subDistrictNameEn": "Wang Chomphu",
    "postcode": "67210",
    "districtCode": "6701",
    "districtNameTh": "เมืองเพชรบูรณ์",
    "districtNameEn": "Mueang Phetchabun",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670114",
    "subDistrictNameTh": "น้ำร้อน",
    "subDistrictNameEn": "Nam Ron",
    "postcode": "67000",
    "districtCode": "6701",
    "districtNameTh": "เมืองเพชรบูรณ์",
    "districtNameEn": "Mueang Phetchabun",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670115",
    "subDistrictNameTh": "ห้วยสะแก",
    "subDistrictNameEn": "Huai Sakae",
    "postcode": "67210",
    "districtCode": "6701",
    "districtNameTh": "เมืองเพชรบูรณ์",
    "districtNameEn": "Mueang Phetchabun",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670116",
    "subDistrictNameTh": "ห้วยใหญ่",
    "subDistrictNameEn": "Huai Yai",
    "postcode": "67000",
    "districtCode": "6701",
    "districtNameTh": "เมืองเพชรบูรณ์",
    "districtNameEn": "Mueang Phetchabun",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670117",
    "subDistrictNameTh": "ระวิง",
    "subDistrictNameEn": "Rawing",
    "postcode": "67210",
    "districtCode": "6701",
    "districtNameTh": "เมืองเพชรบูรณ์",
    "districtNameEn": "Mueang Phetchabun",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670201",
    "subDistrictNameTh": "ชนแดน",
    "subDistrictNameEn": "Chon Daen",
    "postcode": "67150",
    "districtCode": "6702",
    "districtNameTh": "ชนแดน",
    "districtNameEn": "Chon Daen",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670202",
    "subDistrictNameTh": "ดงขุย",
    "subDistrictNameEn": "Dong Khui",
    "postcode": "67190",
    "districtCode": "6702",
    "districtNameTh": "ชนแดน",
    "districtNameEn": "Chon Daen",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670203",
    "subDistrictNameTh": "ท่าข้าม",
    "subDistrictNameEn": "Tha Kham",
    "postcode": "67150",
    "districtCode": "6702",
    "districtNameTh": "ชนแดน",
    "districtNameEn": "Chon Daen",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670204",
    "subDistrictNameTh": "พุทธบาท",
    "subDistrictNameEn": "Phutthabat",
    "postcode": "67150",
    "districtCode": "6702",
    "districtNameTh": "ชนแดน",
    "districtNameEn": "Chon Daen",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670205",
    "subDistrictNameTh": "ลาดแค",
    "subDistrictNameEn": "Lat Khae",
    "postcode": "67150",
    "districtCode": "6702",
    "districtNameTh": "ชนแดน",
    "districtNameEn": "Chon Daen",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670206",
    "subDistrictNameTh": "บ้านกล้วย",
    "subDistrictNameEn": "Ban Kluai",
    "postcode": "67190",
    "districtCode": "6702",
    "districtNameTh": "ชนแดน",
    "districtNameEn": "Chon Daen",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670208",
    "subDistrictNameTh": "ซับพุทรา",
    "subDistrictNameEn": "Sap Phutsa",
    "postcode": "67150",
    "districtCode": "6702",
    "districtNameTh": "ชนแดน",
    "districtNameEn": "Chon Daen",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670209",
    "subDistrictNameTh": "ตะกุดไร",
    "subDistrictNameEn": "Takut Rai",
    "postcode": "67190",
    "districtCode": "6702",
    "districtNameTh": "ชนแดน",
    "districtNameEn": "Chon Daen",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670210",
    "subDistrictNameTh": "ศาลาลาย",
    "subDistrictNameEn": "Sala Lai",
    "postcode": "67150",
    "districtCode": "6702",
    "districtNameTh": "ชนแดน",
    "districtNameEn": "Chon Daen",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670301",
    "subDistrictNameTh": "หล่มสัก",
    "subDistrictNameEn": "Lom Sak",
    "postcode": "67110",
    "districtCode": "6703",
    "districtNameTh": "หล่มสัก",
    "districtNameEn": "Lom Sak",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670302",
    "subDistrictNameTh": "วัดป่า",
    "subDistrictNameEn": "Wat Pa",
    "postcode": "67110",
    "districtCode": "6703",
    "districtNameTh": "หล่มสัก",
    "districtNameEn": "Lom Sak",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670303",
    "subDistrictNameTh": "ตาลเดี่ยว",
    "subDistrictNameEn": "Tan Diao",
    "postcode": "67110",
    "districtCode": "6703",
    "districtNameTh": "หล่มสัก",
    "districtNameEn": "Lom Sak",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670304",
    "subDistrictNameTh": "ฝายนาแซง",
    "subDistrictNameEn": "Fai Na Saeng",
    "postcode": "67110",
    "districtCode": "6703",
    "districtNameTh": "หล่มสัก",
    "districtNameEn": "Lom Sak",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670305",
    "subDistrictNameTh": "หนองสว่าง",
    "subDistrictNameEn": "Nong Sawang",
    "postcode": "67110",
    "districtCode": "6703",
    "districtNameTh": "หล่มสัก",
    "districtNameEn": "Lom Sak",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670306",
    "subDistrictNameTh": "น้ำเฮี้ย",
    "subDistrictNameEn": "Nam Hia",
    "postcode": "67110",
    "districtCode": "6703",
    "districtNameTh": "หล่มสัก",
    "districtNameEn": "Lom Sak",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670307",
    "subDistrictNameTh": "สักหลง",
    "subDistrictNameEn": "Sak Long",
    "postcode": "67110",
    "districtCode": "6703",
    "districtNameTh": "หล่มสัก",
    "districtNameEn": "Lom Sak",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670308",
    "subDistrictNameTh": "ท่าอิบุญ",
    "subDistrictNameEn": "Tha Ibun",
    "postcode": "67110",
    "districtCode": "6703",
    "districtNameTh": "หล่มสัก",
    "districtNameEn": "Lom Sak",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670309",
    "subDistrictNameTh": "บ้านโสก",
    "subDistrictNameEn": "Ban Sok",
    "postcode": "67110",
    "districtCode": "6703",
    "districtNameTh": "หล่มสัก",
    "districtNameEn": "Lom Sak",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670310",
    "subDistrictNameTh": "บ้านติ้ว",
    "subDistrictNameEn": "Ban Tio",
    "postcode": "67110",
    "districtCode": "6703",
    "districtNameTh": "หล่มสัก",
    "districtNameEn": "Lom Sak",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670311",
    "subDistrictNameTh": "ห้วยไร่",
    "subDistrictNameEn": "Huai Rai",
    "postcode": "67110",
    "districtCode": "6703",
    "districtNameTh": "หล่มสัก",
    "districtNameEn": "Lom Sak",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670312",
    "subDistrictNameTh": "น้ำก้อ",
    "subDistrictNameEn": "Nam Ko",
    "postcode": "67110",
    "districtCode": "6703",
    "districtNameTh": "หล่มสัก",
    "districtNameEn": "Lom Sak",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670313",
    "subDistrictNameTh": "ปากช่อง",
    "subDistrictNameEn": "Pak Chong",
    "postcode": "67110",
    "districtCode": "6703",
    "districtNameTh": "หล่มสัก",
    "districtNameEn": "Lom Sak",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670314",
    "subDistrictNameTh": "น้ำชุน",
    "subDistrictNameEn": "Nam Chun",
    "postcode": "67110",
    "districtCode": "6703",
    "districtNameTh": "หล่มสัก",
    "districtNameEn": "Lom Sak",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670315",
    "subDistrictNameTh": "หนองไขว่",
    "subDistrictNameEn": "Nong Khwai",
    "postcode": "67110",
    "districtCode": "6703",
    "districtNameTh": "หล่มสัก",
    "districtNameEn": "Lom Sak",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670316",
    "subDistrictNameTh": "ลานบ่า",
    "subDistrictNameEn": "Lan Ba",
    "postcode": "67110",
    "districtCode": "6703",
    "districtNameTh": "หล่มสัก",
    "districtNameEn": "Lom Sak",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670317",
    "subDistrictNameTh": "บุ่งคล้า",
    "subDistrictNameEn": "Bung Khla",
    "postcode": "67110",
    "districtCode": "6703",
    "districtNameTh": "หล่มสัก",
    "districtNameEn": "Lom Sak",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670318",
    "subDistrictNameTh": "บุ่งน้ำเต้า",
    "subDistrictNameEn": "Bung Namtao",
    "postcode": "67110",
    "districtCode": "6703",
    "districtNameTh": "หล่มสัก",
    "districtNameEn": "Lom Sak",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670319",
    "subDistrictNameTh": "บ้านกลาง",
    "subDistrictNameEn": "Ban Klang",
    "postcode": "67110",
    "districtCode": "6703",
    "districtNameTh": "หล่มสัก",
    "districtNameEn": "Lom Sak",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670320",
    "subDistrictNameTh": "ช้างตะลูด",
    "subDistrictNameEn": "Chang Talut",
    "postcode": "67110",
    "districtCode": "6703",
    "districtNameTh": "หล่มสัก",
    "districtNameEn": "Lom Sak",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670321",
    "subDistrictNameTh": "บ้านไร่",
    "subDistrictNameEn": "Ban Rai",
    "postcode": "67110",
    "districtCode": "6703",
    "districtNameTh": "หล่มสัก",
    "districtNameEn": "Lom Sak",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670322",
    "subDistrictNameTh": "ปากดุก",
    "subDistrictNameEn": "Pak Duk",
    "postcode": "67110",
    "districtCode": "6703",
    "districtNameTh": "หล่มสัก",
    "districtNameEn": "Lom Sak",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670323",
    "subDistrictNameTh": "บ้านหวาย",
    "subDistrictNameEn": "Ban Wai",
    "postcode": "67110",
    "districtCode": "6703",
    "districtNameTh": "หล่มสัก",
    "districtNameEn": "Lom Sak",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670401",
    "subDistrictNameTh": "หล่มเก่า",
    "subDistrictNameEn": "Lom Kao",
    "postcode": "67120",
    "districtCode": "6704",
    "districtNameTh": "หล่มเก่า",
    "districtNameEn": "Lom Kao",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670402",
    "subDistrictNameTh": "นาซำ",
    "subDistrictNameEn": "Na Sam",
    "postcode": "67120",
    "districtCode": "6704",
    "districtNameTh": "หล่มเก่า",
    "districtNameEn": "Lom Kao",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670403",
    "subDistrictNameTh": "หินฮาว",
    "subDistrictNameEn": "Hin Hao",
    "postcode": "67120",
    "districtCode": "6704",
    "districtNameTh": "หล่มเก่า",
    "districtNameEn": "Lom Kao",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670404",
    "subDistrictNameTh": "บ้านเนิน",
    "subDistrictNameEn": "Ban Noen",
    "postcode": "67120",
    "districtCode": "6704",
    "districtNameTh": "หล่มเก่า",
    "districtNameEn": "Lom Kao",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670405",
    "subDistrictNameTh": "ศิลา",
    "subDistrictNameEn": "Sila",
    "postcode": "67120",
    "districtCode": "6704",
    "districtNameTh": "หล่มเก่า",
    "districtNameEn": "Lom Kao",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670406",
    "subDistrictNameTh": "นาแซง",
    "subDistrictNameEn": "Na Saeng",
    "postcode": "67120",
    "districtCode": "6704",
    "districtNameTh": "หล่มเก่า",
    "districtNameEn": "Lom Kao",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670407",
    "subDistrictNameTh": "วังบาล",
    "subDistrictNameEn": "Wang Ban",
    "postcode": "67120",
    "districtCode": "6704",
    "districtNameTh": "หล่มเก่า",
    "districtNameEn": "Lom Kao",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670408",
    "subDistrictNameTh": "นาเกาะ",
    "subDistrictNameEn": "Na Ko",
    "postcode": "67120",
    "districtCode": "6704",
    "districtNameTh": "หล่มเก่า",
    "districtNameEn": "Lom Kao",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670409",
    "subDistrictNameTh": "ตาดกลอย",
    "subDistrictNameEn": "Tat Kloi",
    "postcode": "67120",
    "districtCode": "6704",
    "districtNameTh": "หล่มเก่า",
    "districtNameEn": "Lom Kao",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670501",
    "subDistrictNameTh": "ท่าโรง",
    "subDistrictNameEn": "Tha Rong",
    "postcode": "67130",
    "districtCode": "6705",
    "districtNameTh": "วิเชียรบุรี",
    "districtNameEn": "Wichian Buri",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670502",
    "subDistrictNameTh": "สระประดู่",
    "subDistrictNameEn": "Sa Pradu",
    "postcode": "67130",
    "districtCode": "6705",
    "districtNameTh": "วิเชียรบุรี",
    "districtNameEn": "Wichian Buri",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670503",
    "subDistrictNameTh": "สามแยก",
    "subDistrictNameEn": "Sam Yaek",
    "postcode": "67130",
    "districtCode": "6705",
    "districtNameTh": "วิเชียรบุรี",
    "districtNameEn": "Wichian Buri",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670504",
    "subDistrictNameTh": "โคกปรง",
    "subDistrictNameEn": "Khok Prong",
    "postcode": "67130",
    "districtCode": "6705",
    "districtNameTh": "วิเชียรบุรี",
    "districtNameEn": "Wichian Buri",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670505",
    "subDistrictNameTh": "น้ำร้อน",
    "subDistrictNameEn": "Nam Ron",
    "postcode": "67130",
    "districtCode": "6705",
    "districtNameTh": "วิเชียรบุรี",
    "districtNameEn": "Wichian Buri",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670506",
    "subDistrictNameTh": "บ่อรัง",
    "subDistrictNameEn": "Bo Rang",
    "postcode": "67130",
    "districtCode": "6705",
    "districtNameTh": "วิเชียรบุรี",
    "districtNameEn": "Wichian Buri",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670507",
    "subDistrictNameTh": "พุเตย",
    "subDistrictNameEn": "Phu Toei",
    "postcode": "67180",
    "districtCode": "6705",
    "districtNameTh": "วิเชียรบุรี",
    "districtNameEn": "Wichian Buri",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670508",
    "subDistrictNameTh": "พุขาม",
    "subDistrictNameEn": "Phu Kham",
    "postcode": "67180",
    "districtCode": "6705",
    "districtNameTh": "วิเชียรบุรี",
    "districtNameEn": "Wichian Buri",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670509",
    "subDistrictNameTh": "ภูน้ำหยด",
    "subDistrictNameEn": "Phu Nam Yot",
    "postcode": "67180",
    "districtCode": "6705",
    "districtNameTh": "วิเชียรบุรี",
    "districtNameEn": "Wichian Buri",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670510",
    "subDistrictNameTh": "ซับสมบูรณ์",
    "subDistrictNameEn": "Sap Sombun",
    "postcode": "67180",
    "districtCode": "6705",
    "districtNameTh": "วิเชียรบุรี",
    "districtNameEn": "Wichian Buri",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670511",
    "subDistrictNameTh": "บึงกระจับ",
    "subDistrictNameEn": "Bueng Krachap",
    "postcode": "67130",
    "districtCode": "6705",
    "districtNameTh": "วิเชียรบุรี",
    "districtNameEn": "Wichian Buri",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670512",
    "subDistrictNameTh": "วังใหญ่",
    "subDistrictNameEn": "Wang Yai",
    "postcode": "67180",
    "districtCode": "6705",
    "districtNameTh": "วิเชียรบุรี",
    "districtNameEn": "Wichian Buri",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670513",
    "subDistrictNameTh": "ยางสาว",
    "subDistrictNameEn": "Yang Sao",
    "postcode": "67130",
    "districtCode": "6705",
    "districtNameTh": "วิเชียรบุรี",
    "districtNameEn": "Wichian Buri",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670514",
    "subDistrictNameTh": "ซับน้อย",
    "subDistrictNameEn": "Sap Noi",
    "postcode": "67180",
    "districtCode": "6705",
    "districtNameTh": "วิเชียรบุรี",
    "districtNameEn": "Wichian Buri",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670601",
    "subDistrictNameTh": "ศรีเทพ",
    "subDistrictNameEn": "Si Thep",
    "postcode": "67170",
    "districtCode": "6706",
    "districtNameTh": "ศรีเทพ",
    "districtNameEn": "Si Thep",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670602",
    "subDistrictNameTh": "สระกรวด",
    "subDistrictNameEn": "Sa Kruat",
    "postcode": "67170",
    "districtCode": "6706",
    "districtNameTh": "ศรีเทพ",
    "districtNameEn": "Si Thep",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670603",
    "subDistrictNameTh": "คลองกระจัง",
    "subDistrictNameEn": "Khlong Krachang",
    "postcode": "67170",
    "districtCode": "6706",
    "districtNameTh": "ศรีเทพ",
    "districtNameEn": "Si Thep",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670604",
    "subDistrictNameTh": "นาสนุ่น",
    "subDistrictNameEn": "Na Sanun",
    "postcode": "67170",
    "districtCode": "6706",
    "districtNameTh": "ศรีเทพ",
    "districtNameEn": "Si Thep",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670605",
    "subDistrictNameTh": "โคกสะอาด",
    "subDistrictNameEn": "Khok Sa-at",
    "postcode": "67170",
    "districtCode": "6706",
    "districtNameTh": "ศรีเทพ",
    "districtNameEn": "Si Thep",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670606",
    "subDistrictNameTh": "หนองย่างทอย",
    "subDistrictNameEn": "Nong Yang Thoi",
    "postcode": "67170",
    "districtCode": "6706",
    "districtNameTh": "ศรีเทพ",
    "districtNameEn": "Si Thep",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670607",
    "subDistrictNameTh": "ประดู่งาม",
    "subDistrictNameEn": "Pradu Ngam",
    "postcode": "67170",
    "districtCode": "6706",
    "districtNameTh": "ศรีเทพ",
    "districtNameEn": "Si Thep",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670701",
    "subDistrictNameTh": "กองทูล",
    "subDistrictNameEn": "Kong Thun",
    "postcode": "67140",
    "districtCode": "6707",
    "districtNameTh": "หนองไผ่",
    "districtNameEn": "Nong Phai",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670702",
    "subDistrictNameTh": "นาเฉลียง",
    "subDistrictNameEn": "Na Chaliang",
    "postcode": "67220",
    "districtCode": "6707",
    "districtNameTh": "หนองไผ่",
    "districtNameEn": "Nong Phai",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670703",
    "subDistrictNameTh": "บ้านโภชน์",
    "subDistrictNameEn": "Ban Phot",
    "postcode": "67140",
    "districtCode": "6707",
    "districtNameTh": "หนองไผ่",
    "districtNameEn": "Nong Phai",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670704",
    "subDistrictNameTh": "ท่าแดง",
    "subDistrictNameEn": "Tha Daeng",
    "postcode": "67140",
    "districtCode": "6707",
    "districtNameTh": "หนองไผ่",
    "districtNameEn": "Nong Phai",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670705",
    "subDistrictNameTh": "เพชรละคร",
    "subDistrictNameEn": "Phet Lakhon",
    "postcode": "67140",
    "districtCode": "6707",
    "districtNameTh": "หนองไผ่",
    "districtNameEn": "Nong Phai",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670706",
    "subDistrictNameTh": "บ่อไทย",
    "subDistrictNameEn": "Bo Thai",
    "postcode": "67140",
    "districtCode": "6707",
    "districtNameTh": "หนองไผ่",
    "districtNameEn": "Nong Phai",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670707",
    "subDistrictNameTh": "ห้วยโป่ง",
    "subDistrictNameEn": "Huai Pong",
    "postcode": "67220",
    "districtCode": "6707",
    "districtNameTh": "หนองไผ่",
    "districtNameEn": "Nong Phai",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670708",
    "subDistrictNameTh": "วังท่าดี",
    "subDistrictNameEn": "Wang Tha Di",
    "postcode": "67140",
    "districtCode": "6707",
    "districtNameTh": "หนองไผ่",
    "districtNameEn": "Nong Phai",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670709",
    "subDistrictNameTh": "บัววัฒนา",
    "subDistrictNameEn": "Bua Watthana",
    "postcode": "67140",
    "districtCode": "6707",
    "districtNameTh": "หนองไผ่",
    "districtNameEn": "Nong Phai",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670710",
    "subDistrictNameTh": "หนองไผ่",
    "subDistrictNameEn": "Nong Phai",
    "postcode": "67140",
    "districtCode": "6707",
    "districtNameTh": "หนองไผ่",
    "districtNameEn": "Nong Phai",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670711",
    "subDistrictNameTh": "วังโบสถ์",
    "subDistrictNameEn": "Wang Bot",
    "postcode": "67140",
    "districtCode": "6707",
    "districtNameTh": "หนองไผ่",
    "districtNameEn": "Nong Phai",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670712",
    "subDistrictNameTh": "ยางงาม",
    "subDistrictNameEn": "Yang Ngam",
    "postcode": "67220",
    "districtCode": "6707",
    "districtNameTh": "หนองไผ่",
    "districtNameEn": "Nong Phai",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670713",
    "subDistrictNameTh": "ท่าด้วง",
    "subDistrictNameEn": "Tha Duang",
    "postcode": "67140",
    "districtCode": "6707",
    "districtNameTh": "หนองไผ่",
    "districtNameEn": "Nong Phai",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670801",
    "subDistrictNameTh": "ซับสมอทอด",
    "subDistrictNameEn": "Sap Samo Thot",
    "postcode": "67160",
    "districtCode": "6708",
    "districtNameTh": "บึงสามพัน",
    "districtNameEn": "Bueng Sam Phan",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670802",
    "subDistrictNameTh": "ซับไม้แดง",
    "subDistrictNameEn": "Sap Mai Daeng",
    "postcode": "67160",
    "districtCode": "6708",
    "districtNameTh": "บึงสามพัน",
    "districtNameEn": "Bueng Sam Phan",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670803",
    "subDistrictNameTh": "หนองแจง",
    "subDistrictNameEn": "Nong Chaeng",
    "postcode": "67160",
    "districtCode": "6708",
    "districtNameTh": "บึงสามพัน",
    "districtNameEn": "Bueng Sam Phan",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670804",
    "subDistrictNameTh": "กันจุ",
    "subDistrictNameEn": "Kanchu",
    "postcode": "67160",
    "districtCode": "6708",
    "districtNameTh": "บึงสามพัน",
    "districtNameEn": "Bueng Sam Phan",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670805",
    "subDistrictNameTh": "วังพิกุล",
    "subDistrictNameEn": "Wang Phikun",
    "postcode": "67230",
    "districtCode": "6708",
    "districtNameTh": "บึงสามพัน",
    "districtNameEn": "Bueng Sam Phan",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670806",
    "subDistrictNameTh": "พญาวัง",
    "subDistrictNameEn": "Phaya Wang",
    "postcode": "67160",
    "districtCode": "6708",
    "districtNameTh": "บึงสามพัน",
    "districtNameEn": "Bueng Sam Phan",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670807",
    "subDistrictNameTh": "ศรีมงคล",
    "subDistrictNameEn": "Si Mongkhon",
    "postcode": "67160",
    "districtCode": "6708",
    "districtNameTh": "บึงสามพัน",
    "districtNameEn": "Bueng Sam Phan",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670808",
    "subDistrictNameTh": "สระแก้ว",
    "subDistrictNameEn": "Sa Kaeo",
    "postcode": "67160",
    "districtCode": "6708",
    "districtNameTh": "บึงสามพัน",
    "districtNameEn": "Bueng Sam Phan",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670809",
    "subDistrictNameTh": "บึงสามพัน",
    "subDistrictNameEn": "Bueng Sam Phan",
    "postcode": "67160",
    "districtCode": "6708",
    "districtNameTh": "บึงสามพัน",
    "districtNameEn": "Bueng Sam Phan",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670901",
    "subDistrictNameTh": "น้ำหนาว",
    "subDistrictNameEn": "Nam Nao",
    "postcode": "67260",
    "districtCode": "6709",
    "districtNameTh": "น้ำหนาว",
    "districtNameEn": "Nam Nao",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670902",
    "subDistrictNameTh": "หลักด่าน",
    "subDistrictNameEn": "Lak Dan",
    "postcode": "67260",
    "districtCode": "6709",
    "districtNameTh": "น้ำหนาว",
    "districtNameEn": "Nam Nao",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670903",
    "subDistrictNameTh": "วังกวาง",
    "subDistrictNameEn": "Wang Kwang",
    "postcode": "67260",
    "districtCode": "6709",
    "districtNameTh": "น้ำหนาว",
    "districtNameEn": "Nam Nao",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "670904",
    "subDistrictNameTh": "โคกมน",
    "subDistrictNameEn": "Khok Mon",
    "postcode": "67260",
    "districtCode": "6709",
    "districtNameTh": "น้ำหนาว",
    "districtNameEn": "Nam Nao",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "671001",
    "subDistrictNameTh": "วังโป่ง",
    "subDistrictNameEn": "Wang Pong",
    "postcode": "67240",
    "districtCode": "6710",
    "districtNameTh": "วังโป่ง",
    "districtNameEn": "Wang Pong",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "671002",
    "subDistrictNameTh": "ท้ายดง",
    "subDistrictNameEn": "Thai Dong",
    "postcode": "67240",
    "districtCode": "6710",
    "districtNameTh": "วังโป่ง",
    "districtNameEn": "Wang Pong",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "671003",
    "subDistrictNameTh": "ซับเปิบ",
    "subDistrictNameEn": "Sap Poep",
    "postcode": "67240",
    "districtCode": "6710",
    "districtNameTh": "วังโป่ง",
    "districtNameEn": "Wang Pong",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "671004",
    "subDistrictNameTh": "วังหิน",
    "subDistrictNameEn": "Wang Hin",
    "postcode": "67240",
    "districtCode": "6710",
    "districtNameTh": "วังโป่ง",
    "districtNameEn": "Wang Pong",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "671005",
    "subDistrictNameTh": "วังศาล",
    "subDistrictNameEn": "Wang San",
    "postcode": "67240",
    "districtCode": "6710",
    "districtNameTh": "วังโป่ง",
    "districtNameEn": "Wang Pong",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "671101",
    "subDistrictNameTh": "ทุ่งสมอ",
    "subDistrictNameEn": "Thung Samo",
    "postcode": "67270",
    "districtCode": "6711",
    "districtNameTh": "เขาค้อ",
    "districtNameEn": "Khao Kho",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "671102",
    "subDistrictNameTh": "แคมป์สน",
    "subDistrictNameEn": "Khaem Son",
    "postcode": "67280",
    "districtCode": "6711",
    "districtNameTh": "เขาค้อ",
    "districtNameEn": "Khao Kho",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "671103",
    "subDistrictNameTh": "เขาค้อ",
    "subDistrictNameEn": "Khao Kho",
    "postcode": "67270",
    "districtCode": "6711",
    "districtNameTh": "เขาค้อ",
    "districtNameEn": "Khao Kho",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "671104",
    "subDistrictNameTh": "ริมสีม่วง",
    "subDistrictNameEn": "Rim Si Muang",
    "postcode": "67270",
    "districtCode": "6711",
    "districtNameTh": "เขาค้อ",
    "districtNameEn": "Khao Kho",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "671105",
    "subDistrictNameTh": "สะเดาะพง",
    "subDistrictNameEn": "Sado Phong",
    "postcode": "67270",
    "districtCode": "6711",
    "districtNameTh": "เขาค้อ",
    "districtNameEn": "Khao Kho",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "671106",
    "subDistrictNameTh": "หนองแม่นา",
    "subDistrictNameEn": "Nong Mae Na",
    "postcode": "67270",
    "districtCode": "6711",
    "districtNameTh": "เขาค้อ",
    "districtNameEn": "Khao Kho",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "671107",
    "subDistrictNameTh": "เข็กน้อย",
    "subDistrictNameEn": "Khek Noi",
    "postcode": "67280",
    "districtCode": "6711",
    "districtNameTh": "เขาค้อ",
    "districtNameEn": "Khao Kho",
    "provinceCode": "67",
    "provinceNameTh": "เพชรบูรณ์",
    "provinceNameEn": "Phetchabun"
},
{
    "subDistrictCode": "700101",
    "subDistrictNameTh": "หน้าเมือง",
    "subDistrictNameEn": "Na Mueang",
    "postcode": "70000",
    "districtCode": "7001",
    "districtNameTh": "เมืองราชบุรี",
    "districtNameEn": "Mueang Ratchaburi",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700102",
    "subDistrictNameTh": "เจดีย์หัก",
    "subDistrictNameEn": "Chedi Hak",
    "postcode": "70000",
    "districtCode": "7001",
    "districtNameTh": "เมืองราชบุรี",
    "districtNameEn": "Mueang Ratchaburi",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700103",
    "subDistrictNameTh": "ดอนตะโก",
    "subDistrictNameEn": "Don Tako",
    "postcode": "70000",
    "districtCode": "7001",
    "districtNameTh": "เมืองราชบุรี",
    "districtNameEn": "Mueang Ratchaburi",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700104",
    "subDistrictNameTh": "หนองกลางนา",
    "subDistrictNameEn": "Nong Klang Na",
    "postcode": "70000",
    "districtCode": "7001",
    "districtNameTh": "เมืองราชบุรี",
    "districtNameEn": "Mueang Ratchaburi",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700105",
    "subDistrictNameTh": "ห้วยไผ่",
    "subDistrictNameEn": "Huai Phai",
    "postcode": "70000",
    "districtCode": "7001",
    "districtNameTh": "เมืองราชบุรี",
    "districtNameEn": "Mueang Ratchaburi",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700106",
    "subDistrictNameTh": "คุ้งน้ำวน",
    "subDistrictNameEn": "Khung Nam Won",
    "postcode": "70000",
    "districtCode": "7001",
    "districtNameTh": "เมืองราชบุรี",
    "districtNameEn": "Mueang Ratchaburi",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700107",
    "subDistrictNameTh": "คุ้งกระถิน",
    "subDistrictNameEn": "Khung Krathin",
    "postcode": "70000",
    "districtCode": "7001",
    "districtNameTh": "เมืองราชบุรี",
    "districtNameEn": "Mueang Ratchaburi",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700108",
    "subDistrictNameTh": "อ่างทอง",
    "subDistrictNameEn": "Ang Thong",
    "postcode": "70000",
    "districtCode": "7001",
    "districtNameTh": "เมืองราชบุรี",
    "districtNameEn": "Mueang Ratchaburi",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700109",
    "subDistrictNameTh": "โคกหม้อ",
    "subDistrictNameEn": "Khok Mo",
    "postcode": "70000",
    "districtCode": "7001",
    "districtNameTh": "เมืองราชบุรี",
    "districtNameEn": "Mueang Ratchaburi",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700110",
    "subDistrictNameTh": "สามเรือน",
    "subDistrictNameEn": "Sam Ruean",
    "postcode": "70000",
    "districtCode": "7001",
    "districtNameTh": "เมืองราชบุรี",
    "districtNameEn": "Mueang Ratchaburi",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700111",
    "subDistrictNameTh": "พิกุลทอง",
    "subDistrictNameEn": "Phikun Thong",
    "postcode": "70000",
    "districtCode": "7001",
    "districtNameTh": "เมืองราชบุรี",
    "districtNameEn": "Mueang Ratchaburi",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700112",
    "subDistrictNameTh": "น้ำพุ",
    "subDistrictNameEn": "Nam Phu",
    "postcode": "70000",
    "districtCode": "7001",
    "districtNameTh": "เมืองราชบุรี",
    "districtNameEn": "Mueang Ratchaburi",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700113",
    "subDistrictNameTh": "ดอนแร่",
    "subDistrictNameEn": "Don Rae",
    "postcode": "70000",
    "districtCode": "7001",
    "districtNameTh": "เมืองราชบุรี",
    "districtNameEn": "Mueang Ratchaburi",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700114",
    "subDistrictNameTh": "หินกอง",
    "subDistrictNameEn": "Hin Kong",
    "postcode": "70000",
    "districtCode": "7001",
    "districtNameTh": "เมืองราชบุรี",
    "districtNameEn": "Mueang Ratchaburi",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700115",
    "subDistrictNameTh": "เขาแร้ง",
    "subDistrictNameEn": "Khao Raeng",
    "postcode": "70000",
    "districtCode": "7001",
    "districtNameTh": "เมืองราชบุรี",
    "districtNameEn": "Mueang Ratchaburi",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700116",
    "subDistrictNameTh": "เกาะพลับพลา",
    "subDistrictNameEn": "Ko Phlapphla",
    "postcode": "70000",
    "districtCode": "7001",
    "districtNameTh": "เมืองราชบุรี",
    "districtNameEn": "Mueang Ratchaburi",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700117",
    "subDistrictNameTh": "หลุมดิน",
    "subDistrictNameEn": "Lum Din",
    "postcode": "70000",
    "districtCode": "7001",
    "districtNameTh": "เมืองราชบุรี",
    "districtNameEn": "Mueang Ratchaburi",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700118",
    "subDistrictNameTh": "บางป่า",
    "subDistrictNameEn": "Bang Pa",
    "postcode": "70000",
    "districtCode": "7001",
    "districtNameTh": "เมืองราชบุรี",
    "districtNameEn": "Mueang Ratchaburi",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700119",
    "subDistrictNameTh": "พงสวาย",
    "subDistrictNameEn": "Phong Sawai",
    "postcode": "70000",
    "districtCode": "7001",
    "districtNameTh": "เมืองราชบุรี",
    "districtNameEn": "Mueang Ratchaburi",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700120",
    "subDistrictNameTh": "คูบัว",
    "subDistrictNameEn": "Khu Bua",
    "postcode": "70000",
    "districtCode": "7001",
    "districtNameTh": "เมืองราชบุรี",
    "districtNameEn": "Mueang Ratchaburi",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700121",
    "subDistrictNameTh": "ท่าราบ",
    "subDistrictNameEn": "Tha Rap",
    "postcode": "70000",
    "districtCode": "7001",
    "districtNameTh": "เมืองราชบุรี",
    "districtNameEn": "Mueang Ratchaburi",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700122",
    "subDistrictNameTh": "บ้านไร่",
    "subDistrictNameEn": "Ban Rai",
    "postcode": "70000",
    "districtCode": "7001",
    "districtNameTh": "เมืองราชบุรี",
    "districtNameEn": "Mueang Ratchaburi",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700201",
    "subDistrictNameTh": "จอมบึง",
    "subDistrictNameEn": "Chom Bueng",
    "postcode": "70150",
    "districtCode": "7002",
    "districtNameTh": "จอมบึง",
    "districtNameEn": "Chom Bueng",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700202",
    "subDistrictNameTh": "ปากช่อง",
    "subDistrictNameEn": "Pak Chong",
    "postcode": "70150",
    "districtCode": "7002",
    "districtNameTh": "จอมบึง",
    "districtNameEn": "Chom Bueng",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700203",
    "subDistrictNameTh": "เบิกไพร",
    "subDistrictNameEn": "Boek Phrai",
    "postcode": "70150",
    "districtCode": "7002",
    "districtNameTh": "จอมบึง",
    "districtNameEn": "Chom Bueng",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700204",
    "subDistrictNameTh": "ด่านทับตะโก",
    "subDistrictNameEn": "Dan Thap Tako",
    "postcode": "70150",
    "districtCode": "7002",
    "districtNameTh": "จอมบึง",
    "districtNameEn": "Chom Bueng",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700205",
    "subDistrictNameTh": "แก้มอ้น",
    "subDistrictNameEn": "Kaem On",
    "postcode": "70150",
    "districtCode": "7002",
    "districtNameTh": "จอมบึง",
    "districtNameEn": "Chom Bueng",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700206",
    "subDistrictNameTh": "รางบัว",
    "subDistrictNameEn": "Rang Bua",
    "postcode": "70150",
    "districtCode": "7002",
    "districtNameTh": "จอมบึง",
    "districtNameEn": "Chom Bueng",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700301",
    "subDistrictNameTh": "สวนผึ้ง",
    "subDistrictNameEn": "Suan Phueng",
    "postcode": "70180",
    "districtCode": "7003",
    "districtNameTh": "สวนผึ้ง",
    "districtNameEn": "Suan Phueng",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700302",
    "subDistrictNameTh": "ป่าหวาย",
    "subDistrictNameEn": "Pa Wai",
    "postcode": "70180",
    "districtCode": "7003",
    "districtNameTh": "สวนผึ้ง",
    "districtNameEn": "Suan Phueng",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700304",
    "subDistrictNameTh": "ท่าเคย",
    "subDistrictNameEn": "Tha Khoei",
    "postcode": "70180",
    "districtCode": "7003",
    "districtNameTh": "สวนผึ้ง",
    "districtNameEn": "Suan Phueng",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700307",
    "subDistrictNameTh": "ตะนาวศรี",
    "subDistrictNameEn": "Tanao Si",
    "postcode": "70180",
    "districtCode": "7003",
    "districtNameTh": "สวนผึ้ง",
    "districtNameEn": "Suan Phueng",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700401",
    "subDistrictNameTh": "ดำเนินสะดวก",
    "subDistrictNameEn": "Damnoen Saduak",
    "postcode": "70130",
    "districtCode": "7004",
    "districtNameTh": "ดำเนินสะดวก",
    "districtNameEn": "Damnoen Saduak",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700402",
    "subDistrictNameTh": "ประสาทสิทธิ์",
    "subDistrictNameEn": "Prasat Sit",
    "postcode": "70210",
    "districtCode": "7004",
    "districtNameTh": "ดำเนินสะดวก",
    "districtNameEn": "Damnoen Saduak",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700403",
    "subDistrictNameTh": "ศรีสุราษฎร์",
    "subDistrictNameEn": "Si Surat",
    "postcode": "70130",
    "districtCode": "7004",
    "districtNameTh": "ดำเนินสะดวก",
    "districtNameEn": "Damnoen Saduak",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700404",
    "subDistrictNameTh": "ตาหลวง",
    "subDistrictNameEn": "Ta Luang",
    "postcode": "70130",
    "districtCode": "7004",
    "districtNameTh": "ดำเนินสะดวก",
    "districtNameEn": "Damnoen Saduak",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700405",
    "subDistrictNameTh": "ดอนกรวย",
    "subDistrictNameEn": "Don Kruai",
    "postcode": "70130",
    "districtCode": "7004",
    "districtNameTh": "ดำเนินสะดวก",
    "districtNameEn": "Damnoen Saduak",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700406",
    "subDistrictNameTh": "ดอนคลัง",
    "subDistrictNameEn": "Don Khlang",
    "postcode": "70130",
    "districtCode": "7004",
    "districtNameTh": "ดำเนินสะดวก",
    "districtNameEn": "Damnoen Saduak",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700407",
    "subDistrictNameTh": "บัวงาม",
    "subDistrictNameEn": "Bua Ngam",
    "postcode": "70210",
    "districtCode": "7004",
    "districtNameTh": "ดำเนินสะดวก",
    "districtNameEn": "Damnoen Saduak",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700408",
    "subDistrictNameTh": "บ้านไร่",
    "subDistrictNameEn": "Ban Rai",
    "postcode": "70130",
    "districtCode": "7004",
    "districtNameTh": "ดำเนินสะดวก",
    "districtNameEn": "Damnoen Saduak",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700409",
    "subDistrictNameTh": "แพงพวย",
    "subDistrictNameEn": "Phaengphuai",
    "postcode": "70130",
    "districtCode": "7004",
    "districtNameTh": "ดำเนินสะดวก",
    "districtNameEn": "Damnoen Saduak",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700410",
    "subDistrictNameTh": "สี่หมื่น",
    "subDistrictNameEn": "Si Muen",
    "postcode": "70130",
    "districtCode": "7004",
    "districtNameTh": "ดำเนินสะดวก",
    "districtNameEn": "Damnoen Saduak",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700411",
    "subDistrictNameTh": "ท่านัด",
    "subDistrictNameEn": "Tha Nat",
    "postcode": "70130",
    "districtCode": "7004",
    "districtNameTh": "ดำเนินสะดวก",
    "districtNameEn": "Damnoen Saduak",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700412",
    "subDistrictNameTh": "ขุนพิทักษ์",
    "subDistrictNameEn": "Khun Phithak",
    "postcode": "70130",
    "districtCode": "7004",
    "districtNameTh": "ดำเนินสะดวก",
    "districtNameEn": "Damnoen Saduak",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700413",
    "subDistrictNameTh": "ดอนไผ่",
    "subDistrictNameEn": "Don Phai",
    "postcode": "70130",
    "districtCode": "7004",
    "districtNameTh": "ดำเนินสะดวก",
    "districtNameEn": "Damnoen Saduak",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700501",
    "subDistrictNameTh": "บ้านโป่ง",
    "subDistrictNameEn": "Ban Pong",
    "postcode": "70110",
    "districtCode": "7005",
    "districtNameTh": "บ้านโป่ง",
    "districtNameEn": "Ban Pong",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700502",
    "subDistrictNameTh": "ท่าผา",
    "subDistrictNameEn": "Tha Pha",
    "postcode": "70110",
    "districtCode": "7005",
    "districtNameTh": "บ้านโป่ง",
    "districtNameEn": "Ban Pong",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700503",
    "subDistrictNameTh": "กรับใหญ่",
    "subDistrictNameEn": "Krap Yai",
    "postcode": "70190",
    "districtCode": "7005",
    "districtNameTh": "บ้านโป่ง",
    "districtNameEn": "Ban Pong",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700504",
    "subDistrictNameTh": "ปากแรต",
    "subDistrictNameEn": "Pak Raet",
    "postcode": "70110",
    "districtCode": "7005",
    "districtNameTh": "บ้านโป่ง",
    "districtNameEn": "Ban Pong",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700505",
    "subDistrictNameTh": "หนองกบ",
    "subDistrictNameEn": "Nong Kop",
    "postcode": "70110",
    "districtCode": "7005",
    "districtNameTh": "บ้านโป่ง",
    "districtNameEn": "Ban Pong",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700506",
    "subDistrictNameTh": "หนองอ้อ",
    "subDistrictNameEn": "Nong O",
    "postcode": "70110",
    "districtCode": "7005",
    "districtNameTh": "บ้านโป่ง",
    "districtNameEn": "Ban Pong",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700507",
    "subDistrictNameTh": "ดอนกระเบื้อง",
    "subDistrictNameEn": "Don Krabueang",
    "postcode": "70110",
    "districtCode": "7005",
    "districtNameTh": "บ้านโป่ง",
    "districtNameEn": "Ban Pong",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700508",
    "subDistrictNameTh": "สวนกล้วย",
    "subDistrictNameEn": "Suan Kluai",
    "postcode": "70110",
    "districtCode": "7005",
    "districtNameTh": "บ้านโป่ง",
    "districtNameEn": "Ban Pong",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700509",
    "subDistrictNameTh": "นครชุมน์",
    "subDistrictNameEn": "Nakhon Chum",
    "postcode": "70110",
    "districtCode": "7005",
    "districtNameTh": "บ้านโป่ง",
    "districtNameEn": "Ban Pong",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700510",
    "subDistrictNameTh": "บ้านม่วง",
    "subDistrictNameEn": "Ban Muang",
    "postcode": "70110",
    "districtCode": "7005",
    "districtNameTh": "บ้านโป่ง",
    "districtNameEn": "Ban Pong",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700511",
    "subDistrictNameTh": "คุ้งพยอม",
    "subDistrictNameEn": "Khung Phayom",
    "postcode": "70110",
    "districtCode": "7005",
    "districtNameTh": "บ้านโป่ง",
    "districtNameEn": "Ban Pong",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700512",
    "subDistrictNameTh": "หนองปลาหมอ",
    "subDistrictNameEn": "Nong Pla Mo",
    "postcode": "70110",
    "districtCode": "7005",
    "districtNameTh": "บ้านโป่ง",
    "districtNameEn": "Ban Pong",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700513",
    "subDistrictNameTh": "เขาขลุง",
    "subDistrictNameEn": "Khao Khlung",
    "postcode": "70110",
    "districtCode": "7005",
    "districtNameTh": "บ้านโป่ง",
    "districtNameEn": "Ban Pong",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700514",
    "subDistrictNameTh": "เบิกไพร",
    "subDistrictNameEn": "Boek Phrai",
    "postcode": "70110",
    "districtCode": "7005",
    "districtNameTh": "บ้านโป่ง",
    "districtNameEn": "Ban Pong",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700515",
    "subDistrictNameTh": "ลาดบัวขาว",
    "subDistrictNameEn": "Lat Bua Khao",
    "postcode": "70110",
    "districtCode": "7005",
    "districtNameTh": "บ้านโป่ง",
    "districtNameEn": "Ban Pong",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700601",
    "subDistrictNameTh": "บางแพ",
    "subDistrictNameEn": "Bang Phae",
    "postcode": "70160",
    "districtCode": "7006",
    "districtNameTh": "บางแพ",
    "districtNameEn": "Bang Phae",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700602",
    "subDistrictNameTh": "วังเย็น",
    "subDistrictNameEn": "Wang Yen",
    "postcode": "70160",
    "districtCode": "7006",
    "districtNameTh": "บางแพ",
    "districtNameEn": "Bang Phae",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700603",
    "subDistrictNameTh": "หัวโพ",
    "subDistrictNameEn": "Hua Pho",
    "postcode": "70160",
    "districtCode": "7006",
    "districtNameTh": "บางแพ",
    "districtNameEn": "Bang Phae",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700604",
    "subDistrictNameTh": "วัดแก้ว",
    "subDistrictNameEn": "Wat Kaeo",
    "postcode": "70160",
    "districtCode": "7006",
    "districtNameTh": "บางแพ",
    "districtNameEn": "Bang Phae",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700605",
    "subDistrictNameTh": "ดอนใหญ่",
    "subDistrictNameEn": "Don Yai",
    "postcode": "70160",
    "districtCode": "7006",
    "districtNameTh": "บางแพ",
    "districtNameEn": "Bang Phae",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700606",
    "subDistrictNameTh": "ดอนคา",
    "subDistrictNameEn": "Don Kha",
    "postcode": "70160",
    "districtCode": "7006",
    "districtNameTh": "บางแพ",
    "districtNameEn": "Bang Phae",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700607",
    "subDistrictNameTh": "โพหัก",
    "subDistrictNameEn": "Pho Hak",
    "postcode": "70160",
    "districtCode": "7006",
    "districtNameTh": "บางแพ",
    "districtNameEn": "Bang Phae",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700701",
    "subDistrictNameTh": "โพธาราม",
    "subDistrictNameEn": "Photharam",
    "postcode": "70120",
    "districtCode": "7007",
    "districtNameTh": "โพธาราม",
    "districtNameEn": "Photharam",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700702",
    "subDistrictNameTh": "ดอนกระเบื้อง",
    "subDistrictNameEn": "Don Krabueang",
    "postcode": "70120",
    "districtCode": "7007",
    "districtNameTh": "โพธาราม",
    "districtNameEn": "Photharam",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700703",
    "subDistrictNameTh": "หนองโพ",
    "subDistrictNameEn": "Nong Pho",
    "postcode": "70120",
    "districtCode": "7007",
    "districtNameTh": "โพธาราม",
    "districtNameEn": "Photharam",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700704",
    "subDistrictNameTh": "บ้านเลือก",
    "subDistrictNameEn": "Ban Lueak",
    "postcode": "70120",
    "districtCode": "7007",
    "districtNameTh": "โพธาราม",
    "districtNameEn": "Photharam",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700705",
    "subDistrictNameTh": "คลองตาคต",
    "subDistrictNameEn": "Khlong Ta Khot",
    "postcode": "70120",
    "districtCode": "7007",
    "districtNameTh": "โพธาราม",
    "districtNameEn": "Photharam",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700706",
    "subDistrictNameTh": "บ้านฆ้อง",
    "subDistrictNameEn": "Ban Khong",
    "postcode": "70120",
    "districtCode": "7007",
    "districtNameTh": "โพธาราม",
    "districtNameEn": "Photharam",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700707",
    "subDistrictNameTh": "บ้านสิงห์",
    "subDistrictNameEn": "Ban Sing",
    "postcode": "70120",
    "districtCode": "7007",
    "districtNameTh": "โพธาราม",
    "districtNameEn": "Photharam",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700708",
    "subDistrictNameTh": "ดอนทราย",
    "subDistrictNameEn": "Don Sai",
    "postcode": "70120",
    "districtCode": "7007",
    "districtNameTh": "โพธาราม",
    "districtNameEn": "Photharam",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700709",
    "subDistrictNameTh": "เจ็ดเสมียน",
    "subDistrictNameEn": "Chet Samian",
    "postcode": "70120",
    "districtCode": "7007",
    "districtNameTh": "โพธาราม",
    "districtNameEn": "Photharam",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700710",
    "subDistrictNameTh": "คลองข่อย",
    "subDistrictNameEn": "Khlong Khoi",
    "postcode": "70120",
    "districtCode": "7007",
    "districtNameTh": "โพธาราม",
    "districtNameEn": "Photharam",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700711",
    "subDistrictNameTh": "ชำแระ",
    "subDistrictNameEn": "Chamrae",
    "postcode": "70120",
    "districtCode": "7007",
    "districtNameTh": "โพธาราม",
    "districtNameEn": "Photharam",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700712",
    "subDistrictNameTh": "สร้อยฟ้า",
    "subDistrictNameEn": "Soi Fa",
    "postcode": "70120",
    "districtCode": "7007",
    "districtNameTh": "โพธาราม",
    "districtNameEn": "Photharam",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700713",
    "subDistrictNameTh": "ท่าชุมพล",
    "subDistrictNameEn": "Tha Chumphon",
    "postcode": "70120",
    "districtCode": "7007",
    "districtNameTh": "โพธาราม",
    "districtNameEn": "Photharam",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700714",
    "subDistrictNameTh": "บางโตนด",
    "subDistrictNameEn": "Bang Tanot",
    "postcode": "70120",
    "districtCode": "7007",
    "districtNameTh": "โพธาราม",
    "districtNameEn": "Photharam",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700715",
    "subDistrictNameTh": "เตาปูน",
    "subDistrictNameEn": "Tao Pun",
    "postcode": "70120",
    "districtCode": "7007",
    "districtNameTh": "โพธาราม",
    "districtNameEn": "Photharam",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700716",
    "subDistrictNameTh": "นางแก้ว",
    "subDistrictNameEn": "Nang Kaeo",
    "postcode": "70120",
    "districtCode": "7007",
    "districtNameTh": "โพธาราม",
    "districtNameEn": "Photharam",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700717",
    "subDistrictNameTh": "ธรรมเสน",
    "subDistrictNameEn": "Thammasen",
    "postcode": "70120",
    "districtCode": "7007",
    "districtNameTh": "โพธาราม",
    "districtNameEn": "Photharam",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700718",
    "subDistrictNameTh": "เขาชะงุ้ม",
    "subDistrictNameEn": "Khao Cha-ngum",
    "postcode": "70120",
    "districtCode": "7007",
    "districtNameTh": "โพธาราม",
    "districtNameEn": "Photharam",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700719",
    "subDistrictNameTh": "หนองกวาง",
    "subDistrictNameEn": "Nong Kwang",
    "postcode": "70120",
    "districtCode": "7007",
    "districtNameTh": "โพธาราม",
    "districtNameEn": "Photharam",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700801",
    "subDistrictNameTh": "ทุ่งหลวง",
    "subDistrictNameEn": "Thung Luang",
    "postcode": "70140",
    "districtCode": "7008",
    "districtNameTh": "ปากท่อ",
    "districtNameEn": "Pak Tho",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700802",
    "subDistrictNameTh": "วังมะนาว",
    "subDistrictNameEn": "Wang Manao",
    "postcode": "70140",
    "districtCode": "7008",
    "districtNameTh": "ปากท่อ",
    "districtNameEn": "Pak Tho",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700803",
    "subDistrictNameTh": "ดอนทราย",
    "subDistrictNameEn": "Don Sai",
    "postcode": "70140",
    "districtCode": "7008",
    "districtNameTh": "ปากท่อ",
    "districtNameEn": "Pak Tho",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700804",
    "subDistrictNameTh": "หนองกระทุ่ม",
    "subDistrictNameEn": "Nong Krathum",
    "postcode": "70140",
    "districtCode": "7008",
    "districtNameTh": "ปากท่อ",
    "districtNameEn": "Pak Tho",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700805",
    "subDistrictNameTh": "ปากท่อ",
    "subDistrictNameEn": "Pak Tho",
    "postcode": "70140",
    "districtCode": "7008",
    "districtNameTh": "ปากท่อ",
    "districtNameEn": "Pak Tho",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700806",
    "subDistrictNameTh": "ป่าไก่",
    "subDistrictNameEn": "Pa Kai",
    "postcode": "70140",
    "districtCode": "7008",
    "districtNameTh": "ปากท่อ",
    "districtNameEn": "Pak Tho",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700807",
    "subDistrictNameTh": "วัดยางงาม",
    "subDistrictNameEn": "Wat Yang Ngam",
    "postcode": "70140",
    "districtCode": "7008",
    "districtNameTh": "ปากท่อ",
    "districtNameEn": "Pak Tho",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700808",
    "subDistrictNameTh": "อ่างหิน",
    "subDistrictNameEn": "Ang Hin",
    "postcode": "70140",
    "districtCode": "7008",
    "districtNameTh": "ปากท่อ",
    "districtNameEn": "Pak Tho",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700809",
    "subDistrictNameTh": "บ่อกระดาน",
    "subDistrictNameEn": "Bo Kradan",
    "postcode": "70140",
    "districtCode": "7008",
    "districtNameTh": "ปากท่อ",
    "districtNameEn": "Pak Tho",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700810",
    "subDistrictNameTh": "ยางหัก",
    "subDistrictNameEn": "Yang Hak",
    "postcode": "70140",
    "districtCode": "7008",
    "districtNameTh": "ปากท่อ",
    "districtNameEn": "Pak Tho",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700811",
    "subDistrictNameTh": "วันดาว",
    "subDistrictNameEn": "Wan Dao",
    "postcode": "70140",
    "districtCode": "7008",
    "districtNameTh": "ปากท่อ",
    "districtNameEn": "Pak Tho",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700812",
    "subDistrictNameTh": "ห้วยยางโทน",
    "subDistrictNameEn": "Huai Yang Thon",
    "postcode": "70140",
    "districtCode": "7008",
    "districtNameTh": "ปากท่อ",
    "districtNameEn": "Pak Tho",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700901",
    "subDistrictNameTh": "เกาะศาลพระ",
    "subDistrictNameEn": "Ko San Phra",
    "postcode": "70170",
    "districtCode": "7009",
    "districtNameTh": "วัดเพลง",
    "districtNameEn": "Wat Phleng",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700902",
    "subDistrictNameTh": "จอมประทัด",
    "subDistrictNameEn": "Chom Prathat",
    "postcode": "70170",
    "districtCode": "7009",
    "districtNameTh": "วัดเพลง",
    "districtNameEn": "Wat Phleng",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "700903",
    "subDistrictNameTh": "วัดเพลง",
    "subDistrictNameEn": "Wat Phleng",
    "postcode": "70170",
    "districtCode": "7009",
    "districtNameTh": "วัดเพลง",
    "districtNameEn": "Wat Phleng",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "701001",
    "subDistrictNameTh": "บ้านคา",
    "subDistrictNameEn": "Ban Kha",
    "postcode": "70180",
    "districtCode": "7010",
    "districtNameTh": "บ้านคา",
    "districtNameEn": "Ban Kha",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "701002",
    "subDistrictNameTh": "บ้านบึง",
    "subDistrictNameEn": "Ban Bueng",
    "postcode": "70180",
    "districtCode": "7010",
    "districtNameTh": "บ้านคา",
    "districtNameEn": "Ban Kha",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "701003",
    "subDistrictNameTh": "หนองพันจันทร์",
    "subDistrictNameEn": "Nong Phan Chan",
    "postcode": "70180",
    "districtCode": "7010",
    "districtNameTh": "บ้านคา",
    "districtNameEn": "Ban Kha",
    "provinceCode": "70",
    "provinceNameTh": "ราชบุรี",
    "provinceNameEn": "Ratchaburi"
},
{
    "subDistrictCode": "710101",
    "subDistrictNameTh": "บ้านเหนือ",
    "subDistrictNameEn": "Ban Nuea",
    "postcode": "71000",
    "districtCode": "7101",
    "districtNameTh": "เมืองกาญจนบุรี",
    "districtNameEn": "Mueang Kanchanaburi",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710102",
    "subDistrictNameTh": "บ้านใต้",
    "subDistrictNameEn": "Ban Tai",
    "postcode": "71000",
    "districtCode": "7101",
    "districtNameTh": "เมืองกาญจนบุรี",
    "districtNameEn": "Mueang Kanchanaburi",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710103",
    "subDistrictNameTh": "ปากแพรก",
    "subDistrictNameEn": "Pak Phraek",
    "postcode": "71000",
    "districtCode": "7101",
    "districtNameTh": "เมืองกาญจนบุรี",
    "districtNameEn": "Mueang Kanchanaburi",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710104",
    "subDistrictNameTh": "ท่ามะขาม",
    "subDistrictNameEn": "Tha Makham",
    "postcode": "71000",
    "districtCode": "7101",
    "districtNameTh": "เมืองกาญจนบุรี",
    "districtNameEn": "Mueang Kanchanaburi",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710105",
    "subDistrictNameTh": "แก่งเสี้ยน",
    "subDistrictNameEn": "Kaeng Sian",
    "postcode": "71000",
    "districtCode": "7101",
    "districtNameTh": "เมืองกาญจนบุรี",
    "districtNameEn": "Mueang Kanchanaburi",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710106",
    "subDistrictNameTh": "หนองบัว",
    "subDistrictNameEn": "Nong Bua",
    "postcode": "71190",
    "districtCode": "7101",
    "districtNameTh": "เมืองกาญจนบุรี",
    "districtNameEn": "Mueang Kanchanaburi",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710107",
    "subDistrictNameTh": "ลาดหญ้า",
    "subDistrictNameEn": "Lat Ya",
    "postcode": "71190",
    "districtCode": "7101",
    "districtNameTh": "เมืองกาญจนบุรี",
    "districtNameEn": "Mueang Kanchanaburi",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710108",
    "subDistrictNameTh": "วังด้ง",
    "subDistrictNameEn": "Wang Dong",
    "postcode": "71190",
    "districtCode": "7101",
    "districtNameTh": "เมืองกาญจนบุรี",
    "districtNameEn": "Mueang Kanchanaburi",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710109",
    "subDistrictNameTh": "ช่องสะเดา",
    "subDistrictNameEn": "Chong Sadao",
    "postcode": "71190",
    "districtCode": "7101",
    "districtNameTh": "เมืองกาญจนบุรี",
    "districtNameEn": "Mueang Kanchanaburi",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710110",
    "subDistrictNameTh": "หนองหญ้า",
    "subDistrictNameEn": "Nong Ya",
    "postcode": "71000",
    "districtCode": "7101",
    "districtNameTh": "เมืองกาญจนบุรี",
    "districtNameEn": "Mueang Kanchanaburi",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710111",
    "subDistrictNameTh": "เกาะสำโรง",
    "subDistrictNameEn": "Ko Samrong",
    "postcode": "71000",
    "districtCode": "7101",
    "districtNameTh": "เมืองกาญจนบุรี",
    "districtNameEn": "Mueang Kanchanaburi",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710113",
    "subDistrictNameTh": "บ้านเก่า",
    "subDistrictNameEn": "Ban Kao",
    "postcode": "71000",
    "districtCode": "7101",
    "districtNameTh": "เมืองกาญจนบุรี",
    "districtNameEn": "Mueang Kanchanaburi",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710116",
    "subDistrictNameTh": "วังเย็น",
    "subDistrictNameEn": "Wang Yen",
    "postcode": "71000",
    "districtCode": "7101",
    "districtNameTh": "เมืองกาญจนบุรี",
    "districtNameEn": "Mueang Kanchanaburi",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710201",
    "subDistrictNameTh": "ลุ่มสุ่ม",
    "subDistrictNameEn": "Lum Sum",
    "postcode": "71150",
    "districtCode": "7102",
    "districtNameTh": "ไทรโยค",
    "districtNameEn": "Sai Yok",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710202",
    "subDistrictNameTh": "ท่าเสา",
    "subDistrictNameEn": "Tha Sao",
    "postcode": "71150",
    "districtCode": "7102",
    "districtNameTh": "ไทรโยค",
    "districtNameEn": "Sai Yok",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710203",
    "subDistrictNameTh": "สิงห์",
    "subDistrictNameEn": "Sing",
    "postcode": "71150",
    "districtCode": "7102",
    "districtNameTh": "ไทรโยค",
    "districtNameEn": "Sai Yok",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710204",
    "subDistrictNameTh": "ไทรโยค",
    "subDistrictNameEn": "Sai Yok",
    "postcode": "71150",
    "districtCode": "7102",
    "districtNameTh": "ไทรโยค",
    "districtNameEn": "Sai Yok",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710205",
    "subDistrictNameTh": "วังกระแจะ",
    "subDistrictNameEn": "Wang Khrachae",
    "postcode": "71150",
    "districtCode": "7102",
    "districtNameTh": "ไทรโยค",
    "districtNameEn": "Sai Yok",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710206",
    "subDistrictNameTh": "ศรีมงคล",
    "subDistrictNameEn": "Si Mongkhon",
    "postcode": "71150",
    "districtCode": "7102",
    "districtNameTh": "ไทรโยค",
    "districtNameEn": "Sai Yok",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710207",
    "subDistrictNameTh": "บ้องตี้",
    "subDistrictNameEn": "Bongti",
    "postcode": "71150",
    "districtCode": "7102",
    "districtNameTh": "ไทรโยค",
    "districtNameEn": "Sai Yok",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710301",
    "subDistrictNameTh": "บ่อพลอย",
    "subDistrictNameEn": "Bo Phloi",
    "postcode": "71160",
    "districtCode": "7103",
    "districtNameTh": "บ่อพลอย",
    "districtNameEn": "Bo Phloi",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710302",
    "subDistrictNameTh": "หนองกุ่ม",
    "subDistrictNameEn": "Nong Kum",
    "postcode": "71160",
    "districtCode": "7103",
    "districtNameTh": "บ่อพลอย",
    "districtNameEn": "Bo Phloi",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710303",
    "subDistrictNameTh": "หนองรี",
    "subDistrictNameEn": "Nong Ri",
    "postcode": "71220",
    "districtCode": "7103",
    "districtNameTh": "บ่อพลอย",
    "districtNameEn": "Bo Phloi",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710305",
    "subDistrictNameTh": "หลุมรัง",
    "subDistrictNameEn": "Lum Rang",
    "postcode": "71160",
    "districtCode": "7103",
    "districtNameTh": "บ่อพลอย",
    "districtNameEn": "Bo Phloi",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710308",
    "subDistrictNameTh": "ช่องด่าน",
    "subDistrictNameEn": "Chong Dan",
    "postcode": "71160",
    "districtCode": "7103",
    "districtNameTh": "บ่อพลอย",
    "districtNameEn": "Bo Phloi",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710309",
    "subDistrictNameTh": "หนองกร่าง",
    "subDistrictNameEn": "Nong Krang",
    "postcode": "71220",
    "districtCode": "7103",
    "districtNameTh": "บ่อพลอย",
    "districtNameEn": "Bo Phloi",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710401",
    "subDistrictNameTh": "นาสวน",
    "subDistrictNameEn": "Na Suan",
    "postcode": "71250",
    "districtCode": "7104",
    "districtNameTh": "ศรีสวัสดิ์",
    "districtNameEn": "Si Sawat",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710402",
    "subDistrictNameTh": "ด่านแม่แฉลบ",
    "subDistrictNameEn": "Dan Mae Chalaep",
    "postcode": "71250",
    "districtCode": "7104",
    "districtNameTh": "ศรีสวัสดิ์",
    "districtNameEn": "Si Sawat",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710403",
    "subDistrictNameTh": "หนองเป็ด",
    "subDistrictNameEn": "Nong Pet",
    "postcode": "71250",
    "districtCode": "7104",
    "districtNameTh": "ศรีสวัสดิ์",
    "districtNameEn": "Si Sawat",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710404",
    "subDistrictNameTh": "ท่ากระดาน",
    "subDistrictNameEn": "Tha Kradan",
    "postcode": "71250",
    "districtCode": "7104",
    "districtNameTh": "ศรีสวัสดิ์",
    "districtNameEn": "Si Sawat",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710405",
    "subDistrictNameTh": "เขาโจด",
    "subDistrictNameEn": "Khao Chot",
    "postcode": "71220",
    "districtCode": "7104",
    "districtNameTh": "ศรีสวัสดิ์",
    "districtNameEn": "Si Sawat",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710406",
    "subDistrictNameTh": "แม่กระบุง",
    "subDistrictNameEn": "Mae Krabung",
    "postcode": "71250",
    "districtCode": "7104",
    "districtNameTh": "ศรีสวัสดิ์",
    "districtNameEn": "Si Sawat",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710501",
    "subDistrictNameTh": "พงตึก",
    "subDistrictNameEn": "Phong Tuek",
    "postcode": "71120",
    "districtCode": "7105",
    "districtNameTh": "ท่ามะกา",
    "districtNameEn": "Tha Maka",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710502",
    "subDistrictNameTh": "ยางม่วง",
    "subDistrictNameEn": "Yang Muang",
    "postcode": "71120",
    "districtCode": "7105",
    "districtNameTh": "ท่ามะกา",
    "districtNameEn": "Tha Maka",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710503",
    "subDistrictNameTh": "ดอนชะเอม",
    "subDistrictNameEn": "Don Cha-em",
    "postcode": "71130",
    "districtCode": "7105",
    "districtNameTh": "ท่ามะกา",
    "districtNameEn": "Tha Maka",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710504",
    "subDistrictNameTh": "ท่าไม้",
    "subDistrictNameEn": "Tha Mai",
    "postcode": "71120",
    "districtCode": "7105",
    "districtNameTh": "ท่ามะกา",
    "districtNameEn": "Tha Maka",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710505",
    "subDistrictNameTh": "ตะคร้ำเอน",
    "subDistrictNameEn": "Takhram En",
    "postcode": "71130",
    "districtCode": "7105",
    "districtNameTh": "ท่ามะกา",
    "districtNameEn": "Tha Maka",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710506",
    "subDistrictNameTh": "ท่ามะกา",
    "subDistrictNameEn": "Tha Maka",
    "postcode": "71120",
    "districtCode": "7105",
    "districtNameTh": "ท่ามะกา",
    "districtNameEn": "Tha Maka",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710507",
    "subDistrictNameTh": "ท่าเรือ",
    "subDistrictNameEn": "Tha Ruea",
    "postcode": "71130",
    "districtCode": "7105",
    "districtNameTh": "ท่ามะกา",
    "districtNameEn": "Tha Maka",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710508",
    "subDistrictNameTh": "โคกตะบอง",
    "subDistrictNameEn": "Khok Tabong",
    "postcode": "71120",
    "districtCode": "7105",
    "districtNameTh": "ท่ามะกา",
    "districtNameEn": "Tha Maka",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710509",
    "subDistrictNameTh": "ดอนขมิ้น",
    "subDistrictNameEn": "Don Khamin",
    "postcode": "71120",
    "districtCode": "7105",
    "districtNameTh": "ท่ามะกา",
    "districtNameEn": "Tha Maka",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710510",
    "subDistrictNameTh": "อุโลกสี่หมื่น",
    "subDistrictNameEn": "Ulok Si Muen",
    "postcode": "71130",
    "districtCode": "7105",
    "districtNameTh": "ท่ามะกา",
    "districtNameEn": "Tha Maka",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710511",
    "subDistrictNameTh": "เขาสามสิบหาบ",
    "subDistrictNameEn": "Khao Sam Sip Hap",
    "postcode": "71120",
    "districtCode": "7105",
    "districtNameTh": "ท่ามะกา",
    "districtNameEn": "Tha Maka",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710512",
    "subDistrictNameTh": "พระแท่น",
    "subDistrictNameEn": "Phra Thaen",
    "postcode": "71130",
    "districtCode": "7105",
    "districtNameTh": "ท่ามะกา",
    "districtNameEn": "Tha Maka",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710513",
    "subDistrictNameTh": "หวายเหนียว",
    "subDistrictNameEn": "Wai Niao",
    "postcode": "71120",
    "districtCode": "7105",
    "districtNameTh": "ท่ามะกา",
    "districtNameEn": "Tha Maka",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710514",
    "subDistrictNameTh": "แสนตอ",
    "subDistrictNameEn": "Saen To",
    "postcode": "71130",
    "districtCode": "7105",
    "districtNameTh": "ท่ามะกา",
    "districtNameEn": "Tha Maka",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710515",
    "subDistrictNameTh": "สนามแย้",
    "subDistrictNameEn": "Sanam Yae",
    "postcode": "70190",
    "districtCode": "7105",
    "districtNameTh": "ท่ามะกา",
    "districtNameEn": "Tha Maka",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710516",
    "subDistrictNameTh": "ท่าเสา",
    "subDistrictNameEn": "Tha Sao",
    "postcode": "71120",
    "districtCode": "7105",
    "districtNameTh": "ท่ามะกา",
    "districtNameEn": "Tha Maka",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710517",
    "subDistrictNameTh": "หนองลาน",
    "subDistrictNameEn": "Nong lan",
    "postcode": "71130",
    "districtCode": "7105",
    "districtNameTh": "ท่ามะกา",
    "districtNameEn": "Tha Maka",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710601",
    "subDistrictNameTh": "ท่าม่วง",
    "subDistrictNameEn": "Tha Muang",
    "postcode": "71110",
    "districtCode": "7106",
    "districtNameTh": "ท่าม่วง",
    "districtNameEn": "Tha Muang",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710602",
    "subDistrictNameTh": "วังขนาย",
    "subDistrictNameEn": "Wang Khanai",
    "postcode": "71110",
    "districtCode": "7106",
    "districtNameTh": "ท่าม่วง",
    "districtNameEn": "Tha Muang",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710603",
    "subDistrictNameTh": "วังศาลา",
    "subDistrictNameEn": "Wang Sala",
    "postcode": "71110",
    "districtCode": "7106",
    "districtNameTh": "ท่าม่วง",
    "districtNameEn": "Tha Muang",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710604",
    "subDistrictNameTh": "ท่าล้อ",
    "subDistrictNameEn": "Tha Lo",
    "postcode": "71000",
    "districtCode": "7106",
    "districtNameTh": "ท่าม่วง",
    "districtNameEn": "Tha Muang",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710605",
    "subDistrictNameTh": "หนองขาว",
    "subDistrictNameEn": "Nong Khao",
    "postcode": "71110",
    "districtCode": "7106",
    "districtNameTh": "ท่าม่วง",
    "districtNameEn": "Tha Muang",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710606",
    "subDistrictNameTh": "ทุ่งทอง",
    "subDistrictNameEn": "Thung Thong",
    "postcode": "71110",
    "districtCode": "7106",
    "districtNameTh": "ท่าม่วง",
    "districtNameEn": "Tha Muang",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710607",
    "subDistrictNameTh": "เขาน้อย",
    "subDistrictNameEn": "Khao Noi",
    "postcode": "71110",
    "districtCode": "7106",
    "districtNameTh": "ท่าม่วง",
    "districtNameEn": "Tha Muang",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710608",
    "subDistrictNameTh": "ม่วงชุม",
    "subDistrictNameEn": "Muang Chum",
    "postcode": "71110",
    "districtCode": "7106",
    "districtNameTh": "ท่าม่วง",
    "districtNameEn": "Tha Muang",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710609",
    "subDistrictNameTh": "บ้านใหม่",
    "subDistrictNameEn": "Ban Mai",
    "postcode": "71110",
    "districtCode": "7106",
    "districtNameTh": "ท่าม่วง",
    "districtNameEn": "Tha Muang",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710610",
    "subDistrictNameTh": "พังตรุ",
    "subDistrictNameEn": "Phang Tru",
    "postcode": "71110",
    "districtCode": "7106",
    "districtNameTh": "ท่าม่วง",
    "districtNameEn": "Tha Muang",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710611",
    "subDistrictNameTh": "ท่าตะคร้อ",
    "subDistrictNameEn": "Tha Takro",
    "postcode": "71130",
    "districtCode": "7106",
    "districtNameTh": "ท่าม่วง",
    "districtNameEn": "Tha Muang",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710612",
    "subDistrictNameTh": "รางสาลี่",
    "subDistrictNameEn": "Rang Sali",
    "postcode": "71110",
    "districtCode": "7106",
    "districtNameTh": "ท่าม่วง",
    "districtNameEn": "Tha Muang",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710613",
    "subDistrictNameTh": "หนองตากยา",
    "subDistrictNameEn": "Nong Tak Ya",
    "postcode": "71110",
    "districtCode": "7106",
    "districtNameTh": "ท่าม่วง",
    "districtNameEn": "Tha Muang",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710701",
    "subDistrictNameTh": "ท่าขนุน",
    "subDistrictNameEn": "Tha Khanun",
    "postcode": "71180",
    "districtCode": "7107",
    "districtNameTh": "ทองผาภูมิ",
    "districtNameEn": "Thong Pha Phum",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710702",
    "subDistrictNameTh": "ปิล๊อก",
    "subDistrictNameEn": "Pilok",
    "postcode": "71180",
    "districtCode": "7107",
    "districtNameTh": "ทองผาภูมิ",
    "districtNameEn": "Thong Pha Phum",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710703",
    "subDistrictNameTh": "หินดาด",
    "subDistrictNameEn": "Hin Dat",
    "postcode": "71180",
    "districtCode": "7107",
    "districtNameTh": "ทองผาภูมิ",
    "districtNameEn": "Thong Pha Phum",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710704",
    "subDistrictNameTh": "ลิ่นถิ่น",
    "subDistrictNameEn": "Linthin",
    "postcode": "71180",
    "districtCode": "7107",
    "districtNameTh": "ทองผาภูมิ",
    "districtNameEn": "Thong Pha Phum",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710705",
    "subDistrictNameTh": "ชะแล",
    "subDistrictNameEn": "Chalae",
    "postcode": "71180",
    "districtCode": "7107",
    "districtNameTh": "ทองผาภูมิ",
    "districtNameEn": "Thong Pha Phum",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710706",
    "subDistrictNameTh": "ห้วยเขย่ง",
    "subDistrictNameEn": "Huai Khayeng",
    "postcode": "71180",
    "districtCode": "7107",
    "districtNameTh": "ทองผาภูมิ",
    "districtNameEn": "Thong Pha Phum",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710707",
    "subDistrictNameTh": "สหกรณ์นิคม",
    "subDistrictNameEn": "Sahakon Nikhom",
    "postcode": "71180",
    "districtCode": "7107",
    "districtNameTh": "ทองผาภูมิ",
    "districtNameEn": "Thong Pha Phum",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710801",
    "subDistrictNameTh": "หนองลู",
    "subDistrictNameEn": "Nong Lu",
    "postcode": "71240",
    "districtCode": "7108",
    "districtNameTh": "สังขละบุรี",
    "districtNameEn": "Sangkhla Buri",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710802",
    "subDistrictNameTh": "ปรังเผล",
    "subDistrictNameEn": "Prang Phle",
    "postcode": "71240",
    "districtCode": "7108",
    "districtNameTh": "สังขละบุรี",
    "districtNameEn": "Sangkhla Buri",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710803",
    "subDistrictNameTh": "ไล่โว่",
    "subDistrictNameEn": "Laiwo",
    "postcode": "71240",
    "districtCode": "7108",
    "districtNameTh": "สังขละบุรี",
    "districtNameEn": "Sangkhla Buri",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710901",
    "subDistrictNameTh": "พนมทวน",
    "subDistrictNameEn": "Phanom Thuan",
    "postcode": "71140",
    "districtCode": "7109",
    "districtNameTh": "พนมทวน",
    "districtNameEn": "Phanom Thuan",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710902",
    "subDistrictNameTh": "หนองโรง",
    "subDistrictNameEn": "Nong Rong",
    "postcode": "71140",
    "districtCode": "7109",
    "districtNameTh": "พนมทวน",
    "districtNameEn": "Phanom Thuan",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710903",
    "subDistrictNameTh": "ทุ่งสมอ",
    "subDistrictNameEn": "Thung Samo",
    "postcode": "71140",
    "districtCode": "7109",
    "districtNameTh": "พนมทวน",
    "districtNameEn": "Phanom Thuan",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710904",
    "subDistrictNameTh": "ดอนเจดีย์",
    "subDistrictNameEn": "Don Chedi",
    "postcode": "71140",
    "districtCode": "7109",
    "districtNameTh": "พนมทวน",
    "districtNameEn": "Phanom Thuan",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710905",
    "subDistrictNameTh": "พังตรุ",
    "subDistrictNameEn": "Phang Tru",
    "postcode": "71140",
    "districtCode": "7109",
    "districtNameTh": "พนมทวน",
    "districtNameEn": "Phanom Thuan",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710906",
    "subDistrictNameTh": "รางหวาย",
    "subDistrictNameEn": "Rang Wai",
    "postcode": "71170",
    "districtCode": "7109",
    "districtNameTh": "พนมทวน",
    "districtNameEn": "Phanom Thuan",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710911",
    "subDistrictNameTh": "หนองสาหร่าย",
    "subDistrictNameEn": "Nong Sarai",
    "postcode": "71140",
    "districtCode": "7109",
    "districtNameTh": "พนมทวน",
    "districtNameEn": "Phanom Thuan",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "710912",
    "subDistrictNameTh": "ดอนตาเพชร",
    "subDistrictNameEn": "Don Ta Phet",
    "postcode": "71140",
    "districtCode": "7109",
    "districtNameTh": "พนมทวน",
    "districtNameEn": "Phanom Thuan",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "711001",
    "subDistrictNameTh": "เลาขวัญ",
    "subDistrictNameEn": "Lao Khwan",
    "postcode": "71210",
    "districtCode": "7110",
    "districtNameTh": "เลาขวัญ",
    "districtNameEn": "Lao Khwan",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "711002",
    "subDistrictNameTh": "หนองโสน",
    "subDistrictNameEn": "Nong Sano",
    "postcode": "71210",
    "districtCode": "7110",
    "districtNameTh": "เลาขวัญ",
    "districtNameEn": "Lao Khwan",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "711003",
    "subDistrictNameTh": "หนองประดู่",
    "subDistrictNameEn": "Nong Pradu",
    "postcode": "71210",
    "districtCode": "7110",
    "districtNameTh": "เลาขวัญ",
    "districtNameEn": "Lao Khwan",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "711004",
    "subDistrictNameTh": "หนองปลิง",
    "subDistrictNameEn": "Nong Pling",
    "postcode": "71210",
    "districtCode": "7110",
    "districtNameTh": "เลาขวัญ",
    "districtNameEn": "Lao Khwan",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "711005",
    "subDistrictNameTh": "หนองนกแก้ว",
    "subDistrictNameEn": "Nong Nok Kaeo",
    "postcode": "71210",
    "districtCode": "7110",
    "districtNameTh": "เลาขวัญ",
    "districtNameEn": "Lao Khwan",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "711006",
    "subDistrictNameTh": "ทุ่งกระบ่ำ",
    "subDistrictNameEn": "Thung Krabam",
    "postcode": "71210",
    "districtCode": "7110",
    "districtNameTh": "เลาขวัญ",
    "districtNameEn": "Lao Khwan",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "711007",
    "subDistrictNameTh": "หนองฝ้าย",
    "subDistrictNameEn": "Nong Fai",
    "postcode": "71210",
    "districtCode": "7110",
    "districtNameTh": "เลาขวัญ",
    "districtNameEn": "Lao Khwan",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "711101",
    "subDistrictNameTh": "ด่านมะขามเตี้ย",
    "subDistrictNameEn": "Dan Makham Tia",
    "postcode": "71260",
    "districtCode": "7111",
    "districtNameTh": "ด่านมะขามเตี้ย",
    "districtNameEn": "Dan Makham Tia",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "711102",
    "subDistrictNameTh": "กลอนโด",
    "subDistrictNameEn": "Klon Do",
    "postcode": "71260",
    "districtCode": "7111",
    "districtNameTh": "ด่านมะขามเตี้ย",
    "districtNameEn": "Dan Makham Tia",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "711103",
    "subDistrictNameTh": "จรเข้เผือก",
    "subDistrictNameEn": "Chorakhe Phueak",
    "postcode": "71260",
    "districtCode": "7111",
    "districtNameTh": "ด่านมะขามเตี้ย",
    "districtNameEn": "Dan Makham Tia",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "711104",
    "subDistrictNameTh": "หนองไผ่",
    "subDistrictNameEn": "Nong Phai",
    "postcode": "71260",
    "districtCode": "7111",
    "districtNameTh": "ด่านมะขามเตี้ย",
    "districtNameEn": "Dan Makham Tia",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "711201",
    "subDistrictNameTh": "หนองปรือ",
    "subDistrictNameEn": "Nong Prue",
    "postcode": "71220",
    "districtCode": "7112",
    "districtNameTh": "หนองปรือ",
    "districtNameEn": "Nong Prue",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "711202",
    "subDistrictNameTh": "หนองปลาไหล",
    "subDistrictNameEn": "Nong Pla Lai",
    "postcode": "71220",
    "districtCode": "7112",
    "districtNameTh": "หนองปรือ",
    "districtNameEn": "Nong Prue",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "711203",
    "subDistrictNameTh": "สมเด็จเจริญ",
    "subDistrictNameEn": "Somdet Charoen",
    "postcode": "71220",
    "districtCode": "7112",
    "districtNameTh": "หนองปรือ",
    "districtNameEn": "Nong Prue",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "711301",
    "subDistrictNameTh": "ห้วยกระเจา",
    "subDistrictNameEn": "Huai Krachao",
    "postcode": "71170",
    "districtCode": "7113",
    "districtNameTh": "ห้วยกระเจา",
    "districtNameEn": "Huai Krachao",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "711302",
    "subDistrictNameTh": "วังไผ่",
    "subDistrictNameEn": "Wang Phai",
    "postcode": "71170",
    "districtCode": "7113",
    "districtNameTh": "ห้วยกระเจา",
    "districtNameEn": "Huai Krachao",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "711303",
    "subDistrictNameTh": "ดอนแสลบ",
    "subDistrictNameEn": "Don Salaep",
    "postcode": "71170",
    "districtCode": "7113",
    "districtNameTh": "ห้วยกระเจา",
    "districtNameEn": "Huai Krachao",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "711304",
    "subDistrictNameTh": "สระลงเรือ",
    "subDistrictNameEn": "Sa Long Ruea",
    "postcode": "71170",
    "districtCode": "7113",
    "districtNameTh": "ห้วยกระเจา",
    "districtNameEn": "Huai Krachao",
    "provinceCode": "71",
    "provinceNameTh": "กาญจนบุรี",
    "provinceNameEn": "Kanchanaburi"
},
{
    "subDistrictCode": "720101",
    "subDistrictNameTh": "ท่าพี่เลี้ยง",
    "subDistrictNameEn": "Tha Phi Liang",
    "postcode": "72000",
    "districtCode": "7201",
    "districtNameTh": "เมืองสุพรรณบุรี",
    "districtNameEn": "Mueang Suphan Buri",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720102",
    "subDistrictNameTh": "รั้วใหญ่",
    "subDistrictNameEn": "Rua Yai",
    "postcode": "72000",
    "districtCode": "7201",
    "districtNameTh": "เมืองสุพรรณบุรี",
    "districtNameEn": "Mueang Suphan Buri",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720103",
    "subDistrictNameTh": "ทับตีเหล็ก",
    "subDistrictNameEn": "Thap Ti Lek",
    "postcode": "72000",
    "districtCode": "7201",
    "districtNameTh": "เมืองสุพรรณบุรี",
    "districtNameEn": "Mueang Suphan Buri",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720104",
    "subDistrictNameTh": "ท่าระหัด",
    "subDistrictNameEn": "Tha Rahat",
    "postcode": "72000",
    "districtCode": "7201",
    "districtNameTh": "เมืองสุพรรณบุรี",
    "districtNameEn": "Mueang Suphan Buri",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720105",
    "subDistrictNameTh": "ไผ่ขวาง",
    "subDistrictNameEn": "Phai Khwang",
    "postcode": "72000",
    "districtCode": "7201",
    "districtNameTh": "เมืองสุพรรณบุรี",
    "districtNameEn": "Mueang Suphan Buri",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720106",
    "subDistrictNameTh": "โคกโคเฒ่า",
    "subDistrictNameEn": "Khok Kho Thao",
    "postcode": "72000",
    "districtCode": "7201",
    "districtNameTh": "เมืองสุพรรณบุรี",
    "districtNameEn": "Mueang Suphan Buri",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720107",
    "subDistrictNameTh": "ดอนตาล",
    "subDistrictNameEn": "Don Tan",
    "postcode": "72000",
    "districtCode": "7201",
    "districtNameTh": "เมืองสุพรรณบุรี",
    "districtNameEn": "Mueang Suphan Buri",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720108",
    "subDistrictNameTh": "ดอนมะสังข์",
    "subDistrictNameEn": "Don Masang",
    "postcode": "72000",
    "districtCode": "7201",
    "districtNameTh": "เมืองสุพรรณบุรี",
    "districtNameEn": "Mueang Suphan Buri",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720109",
    "subDistrictNameTh": "พิหารแดง",
    "subDistrictNameEn": "Phihan Daeng",
    "postcode": "72000",
    "districtCode": "7201",
    "districtNameTh": "เมืองสุพรรณบุรี",
    "districtNameEn": "Mueang Suphan Buri",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720110",
    "subDistrictNameTh": "ดอนกำยาน",
    "subDistrictNameEn": "Don Kamyan",
    "postcode": "72000",
    "districtCode": "7201",
    "districtNameTh": "เมืองสุพรรณบุรี",
    "districtNameEn": "Mueang Suphan Buri",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720111",
    "subDistrictNameTh": "ดอนโพธิ์ทอง",
    "subDistrictNameEn": "Don Pho Thong",
    "postcode": "72000",
    "districtCode": "7201",
    "districtNameTh": "เมืองสุพรรณบุรี",
    "districtNameEn": "Mueang Suphan Buri",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720112",
    "subDistrictNameTh": "บ้านโพธิ์",
    "subDistrictNameEn": "Bang Pho",
    "postcode": "72000",
    "districtCode": "7201",
    "districtNameTh": "เมืองสุพรรณบุรี",
    "districtNameEn": "Mueang Suphan Buri",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720113",
    "subDistrictNameTh": "สระแก้ว",
    "subDistrictNameEn": "Sa Kaeo",
    "postcode": "72230",
    "districtCode": "7201",
    "districtNameTh": "เมืองสุพรรณบุรี",
    "districtNameEn": "Mueang Suphan Buri",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720114",
    "subDistrictNameTh": "ตลิ่งชัน",
    "subDistrictNameEn": "Taling Chan",
    "postcode": "72230",
    "districtCode": "7201",
    "districtNameTh": "เมืองสุพรรณบุรี",
    "districtNameEn": "Mueang Suphan Buri",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720115",
    "subDistrictNameTh": "บางกุ้ง",
    "subDistrictNameEn": "Bang Kung",
    "postcode": "72210",
    "districtCode": "7201",
    "districtNameTh": "เมืองสุพรรณบุรี",
    "districtNameEn": "Mueang Suphan Buri",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720116",
    "subDistrictNameTh": "ศาลาขาว",
    "subDistrictNameEn": "Sala Khao",
    "postcode": "72210",
    "districtCode": "7201",
    "districtNameTh": "เมืองสุพรรณบุรี",
    "districtNameEn": "Mueang Suphan Buri",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720117",
    "subDistrictNameTh": "สวนแตง",
    "subDistrictNameEn": "Suan Taeng",
    "postcode": "72210",
    "districtCode": "7201",
    "districtNameTh": "เมืองสุพรรณบุรี",
    "districtNameEn": "Mueang Suphan Buri",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720118",
    "subDistrictNameTh": "สนามชัย",
    "subDistrictNameEn": "Sanam Chai",
    "postcode": "72000",
    "districtCode": "7201",
    "districtNameTh": "เมืองสุพรรณบุรี",
    "districtNameEn": "Mueang Suphan Buri",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720119",
    "subDistrictNameTh": "โพธิ์พระยา",
    "subDistrictNameEn": "Pho Phraya",
    "postcode": "72000",
    "districtCode": "7201",
    "districtNameTh": "เมืองสุพรรณบุรี",
    "districtNameEn": "Mueang Suphan Buri",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720120",
    "subDistrictNameTh": "สนามคลี",
    "subDistrictNameEn": "Sanam Khli",
    "postcode": "72230",
    "districtCode": "7201",
    "districtNameTh": "เมืองสุพรรณบุรี",
    "districtNameEn": "Mueang Suphan Buri",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720201",
    "subDistrictNameTh": "เขาพระ",
    "subDistrictNameEn": "Khao Phra",
    "postcode": "72120",
    "districtCode": "7202",
    "districtNameTh": "เดิมบางนางบวช",
    "districtNameEn": "Doem Bang Nang Buat",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720202",
    "subDistrictNameTh": "เดิมบาง",
    "subDistrictNameEn": "Doem Bang",
    "postcode": "72120",
    "districtCode": "7202",
    "districtNameTh": "เดิมบางนางบวช",
    "districtNameEn": "Doem Bang Nang Buat",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720203",
    "subDistrictNameTh": "นางบวช",
    "subDistrictNameEn": "Nang Buat",
    "postcode": "72120",
    "districtCode": "7202",
    "districtNameTh": "เดิมบางนางบวช",
    "districtNameEn": "Doem Bang Nang Buat",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720204",
    "subDistrictNameTh": "เขาดิน",
    "subDistrictNameEn": "Khao Din",
    "postcode": "72120",
    "districtCode": "7202",
    "districtNameTh": "เดิมบางนางบวช",
    "districtNameEn": "Doem Bang Nang Buat",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720205",
    "subDistrictNameTh": "ปากน้ำ",
    "subDistrictNameEn": "Pak Nam",
    "postcode": "72120",
    "districtCode": "7202",
    "districtNameTh": "เดิมบางนางบวช",
    "districtNameEn": "Doem Bang Nang Buat",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720206",
    "subDistrictNameTh": "ทุ่งคลี",
    "subDistrictNameEn": "Thung Khli",
    "postcode": "72120",
    "districtCode": "7202",
    "districtNameTh": "เดิมบางนางบวช",
    "districtNameEn": "Doem Bang Nang Buat",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720207",
    "subDistrictNameTh": "โคกช้าง",
    "subDistrictNameEn": "Khok Chang",
    "postcode": "72120",
    "districtCode": "7202",
    "districtNameTh": "เดิมบางนางบวช",
    "districtNameEn": "Doem Bang Nang Buat",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720208",
    "subDistrictNameTh": "หัวเขา",
    "subDistrictNameEn": "Hua Khao",
    "postcode": "72120",
    "districtCode": "7202",
    "districtNameTh": "เดิมบางนางบวช",
    "districtNameEn": "Doem Bang Nang Buat",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720209",
    "subDistrictNameTh": "หัวนา",
    "subDistrictNameEn": "Hua Na",
    "postcode": "72120",
    "districtCode": "7202",
    "districtNameTh": "เดิมบางนางบวช",
    "districtNameEn": "Doem Bang Nang Buat",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720210",
    "subDistrictNameTh": "บ่อกรุ",
    "subDistrictNameEn": "Bo Kru",
    "postcode": "72120",
    "districtCode": "7202",
    "districtNameTh": "เดิมบางนางบวช",
    "districtNameEn": "Doem Bang Nang Buat",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720211",
    "subDistrictNameTh": "วังศรีราช",
    "subDistrictNameEn": "Wang Si Rat",
    "postcode": "72120",
    "districtCode": "7202",
    "districtNameTh": "เดิมบางนางบวช",
    "districtNameEn": "Doem Bang Nang Buat",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720212",
    "subDistrictNameTh": "ป่าสะแก",
    "subDistrictNameEn": "Pa Sakae",
    "postcode": "72120",
    "districtCode": "7202",
    "districtNameTh": "เดิมบางนางบวช",
    "districtNameEn": "Doem Bang Nang Buat",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720213",
    "subDistrictNameTh": "ยางนอน",
    "subDistrictNameEn": "Yang Non",
    "postcode": "72120",
    "districtCode": "7202",
    "districtNameTh": "เดิมบางนางบวช",
    "districtNameEn": "Doem Bang Nang Buat",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720214",
    "subDistrictNameTh": "หนองกระทุ่ม",
    "subDistrictNameEn": "Nong Krathum",
    "postcode": "72120",
    "districtCode": "7202",
    "districtNameTh": "เดิมบางนางบวช",
    "districtNameEn": "Doem Bang Nang Buat",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720301",
    "subDistrictNameTh": "หนองมะค่าโมง",
    "subDistrictNameEn": "Nong Makha Mong",
    "postcode": "72180",
    "districtCode": "7203",
    "districtNameTh": "ด่านช้าง",
    "districtNameEn": "Dan Chang",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720302",
    "subDistrictNameTh": "ด่านช้าง",
    "subDistrictNameEn": "Dan Chang",
    "postcode": "72180",
    "districtCode": "7203",
    "districtNameTh": "ด่านช้าง",
    "districtNameEn": "Dan Chang",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720303",
    "subDistrictNameTh": "ห้วยขมิ้น",
    "subDistrictNameEn": "Huai Khamin",
    "postcode": "72180",
    "districtCode": "7203",
    "districtNameTh": "ด่านช้าง",
    "districtNameEn": "Dan Chang",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720304",
    "subDistrictNameTh": "องค์พระ",
    "subDistrictNameEn": "Ong Phra",
    "postcode": "72180",
    "districtCode": "7203",
    "districtNameTh": "ด่านช้าง",
    "districtNameEn": "Dan Chang",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720305",
    "subDistrictNameTh": "วังคัน",
    "subDistrictNameEn": "Wang Khan",
    "postcode": "72180",
    "districtCode": "7203",
    "districtNameTh": "ด่านช้าง",
    "districtNameEn": "Dan Chang",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720306",
    "subDistrictNameTh": "นิคมกระเสียว",
    "subDistrictNameEn": "Nikhom Krasiao",
    "postcode": "72180",
    "districtCode": "7203",
    "districtNameTh": "ด่านช้าง",
    "districtNameEn": "Dan Chang",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720307",
    "subDistrictNameTh": "วังยาว",
    "subDistrictNameEn": "Wang Yao",
    "postcode": "72180",
    "districtCode": "7203",
    "districtNameTh": "ด่านช้าง",
    "districtNameEn": "Dan Chang",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720401",
    "subDistrictNameTh": "โคกคราม",
    "subDistrictNameEn": "Khok Khram",
    "postcode": "72150",
    "districtCode": "7204",
    "districtNameTh": "บางปลาม้า",
    "districtNameEn": "Bang Pla Ma",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720402",
    "subDistrictNameTh": "บางปลาม้า",
    "subDistrictNameEn": "Bang Pla Ma",
    "postcode": "72150",
    "districtCode": "7204",
    "districtNameTh": "บางปลาม้า",
    "districtNameEn": "Bang Pla Ma",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720403",
    "subDistrictNameTh": "ตะค่า",
    "subDistrictNameEn": "Takha",
    "postcode": "72150",
    "districtCode": "7204",
    "districtNameTh": "บางปลาม้า",
    "districtNameEn": "Bang Pla Ma",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720404",
    "subDistrictNameTh": "บางใหญ่",
    "subDistrictNameEn": "Bang Yai",
    "postcode": "72150",
    "districtCode": "7204",
    "districtNameTh": "บางปลาม้า",
    "districtNameEn": "Bang Pla Ma",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720405",
    "subDistrictNameTh": "กฤษณา",
    "subDistrictNameEn": "Kritsana",
    "postcode": "72150",
    "districtCode": "7204",
    "districtNameTh": "บางปลาม้า",
    "districtNameEn": "Bang Pla Ma",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720406",
    "subDistrictNameTh": "สาลี",
    "subDistrictNameEn": "Sali",
    "postcode": "72150",
    "districtCode": "7204",
    "districtNameTh": "บางปลาม้า",
    "districtNameEn": "Bang Pla Ma",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720407",
    "subDistrictNameTh": "ไผ่กองดิน",
    "subDistrictNameEn": "Phai Kong Din",
    "postcode": "72150",
    "districtCode": "7204",
    "districtNameTh": "บางปลาม้า",
    "districtNameEn": "Bang Pla Ma",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720408",
    "subDistrictNameTh": "องครักษ์",
    "subDistrictNameEn": "Ongkharak",
    "postcode": "72150",
    "districtCode": "7204",
    "districtNameTh": "บางปลาม้า",
    "districtNameEn": "Bang Pla Ma",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720409",
    "subDistrictNameTh": "จรเข้ใหญ่",
    "subDistrictNameEn": "Chorakhe Yai",
    "postcode": "72150",
    "districtCode": "7204",
    "districtNameTh": "บางปลาม้า",
    "districtNameEn": "Bang Pla Ma",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720410",
    "subDistrictNameTh": "บ้านแหลม",
    "subDistrictNameEn": "Ban Laem",
    "postcode": "72150",
    "districtCode": "7204",
    "districtNameTh": "บางปลาม้า",
    "districtNameEn": "Bang Pla Ma",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720411",
    "subDistrictNameTh": "มะขามล้ม",
    "subDistrictNameEn": "Makham Lom",
    "postcode": "72150",
    "districtCode": "7204",
    "districtNameTh": "บางปลาม้า",
    "districtNameEn": "Bang Pla Ma",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720412",
    "subDistrictNameTh": "วังน้ำเย็น",
    "subDistrictNameEn": "Wang Nam Yen",
    "postcode": "72150",
    "districtCode": "7204",
    "districtNameTh": "บางปลาม้า",
    "districtNameEn": "Bang Pla Ma",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720413",
    "subDistrictNameTh": "วัดโบสถ์",
    "subDistrictNameEn": "Wat Bot",
    "postcode": "72150",
    "districtCode": "7204",
    "districtNameTh": "บางปลาม้า",
    "districtNameEn": "Bang Pla Ma",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720414",
    "subDistrictNameTh": "วัดดาว",
    "subDistrictNameEn": "Wat Dao",
    "postcode": "72150",
    "districtCode": "7204",
    "districtNameTh": "บางปลาม้า",
    "districtNameEn": "Bang Pla Ma",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720501",
    "subDistrictNameTh": "ศรีประจันต์",
    "subDistrictNameEn": "Si Prachan",
    "postcode": "72140",
    "districtCode": "7205",
    "districtNameTh": "ศรีประจันต์",
    "districtNameEn": "Si Prachan",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720502",
    "subDistrictNameTh": "บ้านกร่าง",
    "subDistrictNameEn": "Ban Krang",
    "postcode": "72140",
    "districtCode": "7205",
    "districtNameTh": "ศรีประจันต์",
    "districtNameEn": "Si Prachan",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720503",
    "subDistrictNameTh": "มดแดง",
    "subDistrictNameEn": "Mot Daeng",
    "postcode": "72140",
    "districtCode": "7205",
    "districtNameTh": "ศรีประจันต์",
    "districtNameEn": "Si Prachan",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720504",
    "subDistrictNameTh": "บางงาม",
    "subDistrictNameEn": "Bang Ngam",
    "postcode": "72140",
    "districtCode": "7205",
    "districtNameTh": "ศรีประจันต์",
    "districtNameEn": "Si Prachan",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720505",
    "subDistrictNameTh": "ดอนปรู",
    "subDistrictNameEn": "Don Pru",
    "postcode": "72140",
    "districtCode": "7205",
    "districtNameTh": "ศรีประจันต์",
    "districtNameEn": "Si Prachan",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720506",
    "subDistrictNameTh": "ปลายนา",
    "subDistrictNameEn": "Plai Na",
    "postcode": "72140",
    "districtCode": "7205",
    "districtNameTh": "ศรีประจันต์",
    "districtNameEn": "Si Prachan",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720507",
    "subDistrictNameTh": "วังหว้า",
    "subDistrictNameEn": "Wang Wa",
    "postcode": "72140",
    "districtCode": "7205",
    "districtNameTh": "ศรีประจันต์",
    "districtNameEn": "Si Prachan",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720508",
    "subDistrictNameTh": "วังน้ำซับ",
    "subDistrictNameEn": "Wang Nam Sap",
    "postcode": "72140",
    "districtCode": "7205",
    "districtNameTh": "ศรีประจันต์",
    "districtNameEn": "Si Prachan",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720509",
    "subDistrictNameTh": "วังยาง",
    "subDistrictNameEn": "Wang Yang",
    "postcode": "72140",
    "districtCode": "7205",
    "districtNameTh": "ศรีประจันต์",
    "districtNameEn": "Si Prachan",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720601",
    "subDistrictNameTh": "ดอนเจดีย์",
    "subDistrictNameEn": "Don Chedi",
    "postcode": "72170",
    "districtCode": "7206",
    "districtNameTh": "ดอนเจดีย์",
    "districtNameEn": "Don Chedi",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720602",
    "subDistrictNameTh": "หนองสาหร่าย",
    "subDistrictNameEn": "Nong Sarai",
    "postcode": "72170",
    "districtCode": "7206",
    "districtNameTh": "ดอนเจดีย์",
    "districtNameEn": "Don Chedi",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720603",
    "subDistrictNameTh": "ไร่รถ",
    "subDistrictNameEn": "Rai Rot",
    "postcode": "72170",
    "districtCode": "7206",
    "districtNameTh": "ดอนเจดีย์",
    "districtNameEn": "Don Chedi",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720604",
    "subDistrictNameTh": "สระกระโจม",
    "subDistrictNameEn": "Sa Krachom",
    "postcode": "72250",
    "districtCode": "7206",
    "districtNameTh": "ดอนเจดีย์",
    "districtNameEn": "Don Chedi",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720605",
    "subDistrictNameTh": "ทะเลบก",
    "subDistrictNameEn": "Thale Bok",
    "postcode": "72250",
    "districtCode": "7206",
    "districtNameTh": "ดอนเจดีย์",
    "districtNameEn": "Don Chedi",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720701",
    "subDistrictNameTh": "สองพี่น้อง",
    "subDistrictNameEn": "Song Phi Nong",
    "postcode": "72110",
    "districtCode": "7207",
    "districtNameTh": "สองพี่น้อง",
    "districtNameEn": "Song Phi Nong",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720702",
    "subDistrictNameTh": "บางเลน",
    "subDistrictNameEn": "Bang Len",
    "postcode": "72110",
    "districtCode": "7207",
    "districtNameTh": "สองพี่น้อง",
    "districtNameEn": "Song Phi Nong",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720703",
    "subDistrictNameTh": "บางตาเถร",
    "subDistrictNameEn": "Bang Ta Then",
    "postcode": "72110",
    "districtCode": "7207",
    "districtNameTh": "สองพี่น้อง",
    "districtNameEn": "Song Phi Nong",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720704",
    "subDistrictNameTh": "บางตะเคียน",
    "subDistrictNameEn": "Bang Takhian",
    "postcode": "72110",
    "districtCode": "7207",
    "districtNameTh": "สองพี่น้อง",
    "districtNameEn": "Song Phi Nong",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720705",
    "subDistrictNameTh": "บ้านกุ่ม",
    "subDistrictNameEn": "Ban Kum",
    "postcode": "72110",
    "districtCode": "7207",
    "districtNameTh": "สองพี่น้อง",
    "districtNameEn": "Song Phi Nong",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720706",
    "subDistrictNameTh": "หัวโพธิ์",
    "subDistrictNameEn": "Hua Pho",
    "postcode": "72110",
    "districtCode": "7207",
    "districtNameTh": "สองพี่น้อง",
    "districtNameEn": "Song Phi Nong",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720707",
    "subDistrictNameTh": "บางพลับ",
    "subDistrictNameEn": "Bang Phlap",
    "postcode": "72110",
    "districtCode": "7207",
    "districtNameTh": "สองพี่น้อง",
    "districtNameEn": "Song Phi Nong",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720708",
    "subDistrictNameTh": "เนินพระปรางค์",
    "subDistrictNameEn": "Noen Phra Prang",
    "postcode": "72110",
    "districtCode": "7207",
    "districtNameTh": "สองพี่น้อง",
    "districtNameEn": "Song Phi Nong",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720709",
    "subDistrictNameTh": "บ้านช้าง",
    "subDistrictNameEn": "Ban Chang",
    "postcode": "72110",
    "districtCode": "7207",
    "districtNameTh": "สองพี่น้อง",
    "districtNameEn": "Song Phi Nong",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720710",
    "subDistrictNameTh": "ต้นตาล",
    "subDistrictNameEn": "Ton Tan",
    "postcode": "72110",
    "districtCode": "7207",
    "districtNameTh": "สองพี่น้อง",
    "districtNameEn": "Song Phi Nong",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720711",
    "subDistrictNameTh": "ศรีสำราญ",
    "subDistrictNameEn": "Si Samran",
    "postcode": "72110",
    "districtCode": "7207",
    "districtNameTh": "สองพี่น้อง",
    "districtNameEn": "Song Phi Nong",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720712",
    "subDistrictNameTh": "ทุ่งคอก",
    "subDistrictNameEn": "Thung Khok",
    "postcode": "72110",
    "districtCode": "7207",
    "districtNameTh": "สองพี่น้อง",
    "districtNameEn": "Song Phi Nong",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720713",
    "subDistrictNameTh": "หนองบ่อ",
    "subDistrictNameEn": "Nong Bo",
    "postcode": "72190",
    "districtCode": "7207",
    "districtNameTh": "สองพี่น้อง",
    "districtNameEn": "Song Phi Nong",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720714",
    "subDistrictNameTh": "บ่อสุพรรณ",
    "subDistrictNameEn": "Bo Suphan",
    "postcode": "72190",
    "districtCode": "7207",
    "districtNameTh": "สองพี่น้อง",
    "districtNameEn": "Song Phi Nong",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720715",
    "subDistrictNameTh": "ดอนมะนาว",
    "subDistrictNameEn": "Don Manao",
    "postcode": "72110",
    "districtCode": "7207",
    "districtNameTh": "สองพี่น้อง",
    "districtNameEn": "Song Phi Nong",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720801",
    "subDistrictNameTh": "ย่านยาว",
    "subDistrictNameEn": "Yan Yao",
    "postcode": "72130",
    "districtCode": "7208",
    "districtNameTh": "สามชุก",
    "districtNameEn": "Sam Chuk",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720802",
    "subDistrictNameTh": "วังลึก",
    "subDistrictNameEn": "Wang Luek",
    "postcode": "72130",
    "districtCode": "7208",
    "districtNameTh": "สามชุก",
    "districtNameEn": "Sam Chuk",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720803",
    "subDistrictNameTh": "สามชุก",
    "subDistrictNameEn": "Sam Chuk",
    "postcode": "72130",
    "districtCode": "7208",
    "districtNameTh": "สามชุก",
    "districtNameEn": "Sam Chuk",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720804",
    "subDistrictNameTh": "หนองผักนาก",
    "subDistrictNameEn": "Nong Phak Nak",
    "postcode": "72130",
    "districtCode": "7208",
    "districtNameTh": "สามชุก",
    "districtNameEn": "Sam Chuk",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720805",
    "subDistrictNameTh": "บ้านสระ",
    "subDistrictNameEn": "Ban Sa",
    "postcode": "72130",
    "districtCode": "7208",
    "districtNameTh": "สามชุก",
    "districtNameEn": "Sam Chuk",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720806",
    "subDistrictNameTh": "หนองสะเดา",
    "subDistrictNameEn": "Nong Sadao",
    "postcode": "72130",
    "districtCode": "7208",
    "districtNameTh": "สามชุก",
    "districtNameEn": "Sam Chuk",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720807",
    "subDistrictNameTh": "กระเสียว",
    "subDistrictNameEn": "Krasiao",
    "postcode": "72130",
    "districtCode": "7208",
    "districtNameTh": "สามชุก",
    "districtNameEn": "Sam Chuk",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720901",
    "subDistrictNameTh": "อู่ทอง",
    "subDistrictNameEn": "U Thong",
    "postcode": "72160",
    "districtCode": "7209",
    "districtNameTh": "อู่ทอง",
    "districtNameEn": "U Thong",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720902",
    "subDistrictNameTh": "สระยายโสม",
    "subDistrictNameEn": "Sa Yai Som",
    "postcode": "72220",
    "districtCode": "7209",
    "districtNameTh": "อู่ทอง",
    "districtNameEn": "U Thong",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720903",
    "subDistrictNameTh": "จรเข้สามพัน",
    "subDistrictNameEn": "Chorakhe Sam Phan",
    "postcode": "71170",
    "districtCode": "7209",
    "districtNameTh": "อู่ทอง",
    "districtNameEn": "U Thong",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720904",
    "subDistrictNameTh": "บ้านดอน",
    "subDistrictNameEn": "Ban Don",
    "postcode": "72160",
    "districtCode": "7209",
    "districtNameTh": "อู่ทอง",
    "districtNameEn": "U Thong",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720905",
    "subDistrictNameTh": "ยุ้งทะลาย",
    "subDistrictNameEn": "Yung Thalai",
    "postcode": "72160",
    "districtCode": "7209",
    "districtNameTh": "อู่ทอง",
    "districtNameEn": "U Thong",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720906",
    "subDistrictNameTh": "ดอนมะเกลือ",
    "subDistrictNameEn": "Don Makluea",
    "postcode": "72220",
    "districtCode": "7209",
    "districtNameTh": "อู่ทอง",
    "districtNameEn": "U Thong",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720907",
    "subDistrictNameTh": "หนองโอ่ง",
    "subDistrictNameEn": "Nong Ong",
    "postcode": "72160",
    "districtCode": "7209",
    "districtNameTh": "อู่ทอง",
    "districtNameEn": "U Thong",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720908",
    "subDistrictNameTh": "ดอนคา",
    "subDistrictNameEn": "Don Kha",
    "postcode": "72160",
    "districtCode": "7209",
    "districtNameTh": "อู่ทอง",
    "districtNameEn": "U Thong",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720909",
    "subDistrictNameTh": "พลับพลาไชย",
    "subDistrictNameEn": "Phlapphla Chai",
    "postcode": "72160",
    "districtCode": "7209",
    "districtNameTh": "อู่ทอง",
    "districtNameEn": "U Thong",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720910",
    "subDistrictNameTh": "บ้านโข้ง",
    "subDistrictNameEn": "Ban Khong",
    "postcode": "72160",
    "districtCode": "7209",
    "districtNameTh": "อู่ทอง",
    "districtNameEn": "U Thong",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720911",
    "subDistrictNameTh": "เจดีย์",
    "subDistrictNameEn": "Chedi",
    "postcode": "72160",
    "districtCode": "7209",
    "districtNameTh": "อู่ทอง",
    "districtNameEn": "U Thong",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720912",
    "subDistrictNameTh": "สระพังลาน",
    "subDistrictNameEn": "Sa Phang Lan",
    "postcode": "72220",
    "districtCode": "7209",
    "districtNameTh": "อู่ทอง",
    "districtNameEn": "U Thong",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "720913",
    "subDistrictNameTh": "กระจัน",
    "subDistrictNameEn": "Krachan",
    "postcode": "72160",
    "districtCode": "7209",
    "districtNameTh": "อู่ทอง",
    "districtNameEn": "U Thong",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "721001",
    "subDistrictNameTh": "หนองหญ้าไซ",
    "subDistrictNameEn": "Nong Ya Sai",
    "postcode": "72240",
    "districtCode": "7210",
    "districtNameTh": "หนองหญ้าไซ",
    "districtNameEn": "Nong Ya Sai",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "721002",
    "subDistrictNameTh": "หนองราชวัตร",
    "subDistrictNameEn": "Nong Ratchawat",
    "postcode": "72240",
    "districtCode": "7210",
    "districtNameTh": "หนองหญ้าไซ",
    "districtNameEn": "Nong Ya Sai",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "721003",
    "subDistrictNameTh": "หนองโพธิ์",
    "subDistrictNameEn": "Nong Pho",
    "postcode": "72240",
    "districtCode": "7210",
    "districtNameTh": "หนองหญ้าไซ",
    "districtNameEn": "Nong Ya Sai",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "721004",
    "subDistrictNameTh": "แจงงาม",
    "subDistrictNameEn": "Chaeng Ngam",
    "postcode": "72240",
    "districtCode": "7210",
    "districtNameTh": "หนองหญ้าไซ",
    "districtNameEn": "Nong Ya Sai",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "721005",
    "subDistrictNameTh": "หนองขาม",
    "subDistrictNameEn": "Nong Kham",
    "postcode": "72240",
    "districtCode": "7210",
    "districtNameTh": "หนองหญ้าไซ",
    "districtNameEn": "Nong Ya Sai",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "721006",
    "subDistrictNameTh": "ทัพหลวง",
    "subDistrictNameEn": "Thap Luang",
    "postcode": "72240",
    "districtCode": "7210",
    "districtNameTh": "หนองหญ้าไซ",
    "districtNameEn": "Nong Ya Sai",
    "provinceCode": "72",
    "provinceNameTh": "สุพรรณบุรี",
    "provinceNameEn": "Suphan Buri"
},
{
    "subDistrictCode": "730101",
    "subDistrictNameTh": "พระปฐมเจดีย์",
    "subDistrictNameEn": "Phra Pathom Chedi",
    "postcode": "73000",
    "districtCode": "7301",
    "districtNameTh": "เมืองนครปฐม",
    "districtNameEn": "Mueang Nakhon Pathom",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730102",
    "subDistrictNameTh": "บางแขม",
    "subDistrictNameEn": "Bang Khaem",
    "postcode": "73000",
    "districtCode": "7301",
    "districtNameTh": "เมืองนครปฐม",
    "districtNameEn": "Mueang Nakhon Pathom",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730103",
    "subDistrictNameTh": "พระประโทน",
    "subDistrictNameEn": "Phra Prathon",
    "postcode": "73000",
    "districtCode": "7301",
    "districtNameTh": "เมืองนครปฐม",
    "districtNameEn": "Mueang Nakhon Pathom",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730104",
    "subDistrictNameTh": "ธรรมศาลา",
    "subDistrictNameEn": "Thammasala",
    "postcode": "73000",
    "districtCode": "7301",
    "districtNameTh": "เมืองนครปฐม",
    "districtNameEn": "Mueang Nakhon Pathom",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730105",
    "subDistrictNameTh": "ตาก้อง",
    "subDistrictNameEn": "Ta Kong",
    "postcode": "73000",
    "districtCode": "7301",
    "districtNameTh": "เมืองนครปฐม",
    "districtNameEn": "Mueang Nakhon Pathom",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730106",
    "subDistrictNameTh": "มาบแค",
    "subDistrictNameEn": "Map Khae",
    "postcode": "73000",
    "districtCode": "7301",
    "districtNameTh": "เมืองนครปฐม",
    "districtNameEn": "Mueang Nakhon Pathom",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730107",
    "subDistrictNameTh": "สนามจันทร์",
    "subDistrictNameEn": "Sanam Chan",
    "postcode": "73000",
    "districtCode": "7301",
    "districtNameTh": "เมืองนครปฐม",
    "districtNameEn": "Mueang Nakhon Pathom",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730108",
    "subDistrictNameTh": "ดอนยายหอม",
    "subDistrictNameEn": "Don Yai Hom",
    "postcode": "73000",
    "districtCode": "7301",
    "districtNameTh": "เมืองนครปฐม",
    "districtNameEn": "Mueang Nakhon Pathom",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730109",
    "subDistrictNameTh": "ถนนขาด",
    "subDistrictNameEn": "Thanon Khat",
    "postcode": "73000",
    "districtCode": "7301",
    "districtNameTh": "เมืองนครปฐม",
    "districtNameEn": "Mueang Nakhon Pathom",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730110",
    "subDistrictNameTh": "บ่อพลับ",
    "subDistrictNameEn": "Bo Phlap",
    "postcode": "73000",
    "districtCode": "7301",
    "districtNameTh": "เมืองนครปฐม",
    "districtNameEn": "Mueang Nakhon Pathom",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730111",
    "subDistrictNameTh": "นครปฐม",
    "subDistrictNameEn": "Nakhon Pathom",
    "postcode": "73000",
    "districtCode": "7301",
    "districtNameTh": "เมืองนครปฐม",
    "districtNameEn": "Mueang Nakhon Pathom",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730112",
    "subDistrictNameTh": "วังตะกู",
    "subDistrictNameEn": "Wang Taku",
    "postcode": "73000",
    "districtCode": "7301",
    "districtNameTh": "เมืองนครปฐม",
    "districtNameEn": "Mueang Nakhon Pathom",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730113",
    "subDistrictNameTh": "หนองปากโลง",
    "subDistrictNameEn": "Nong Pak Long",
    "postcode": "73000",
    "districtCode": "7301",
    "districtNameTh": "เมืองนครปฐม",
    "districtNameEn": "Mueang Nakhon Pathom",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730114",
    "subDistrictNameTh": "สามควายเผือก",
    "subDistrictNameEn": "Sam Khwai Phueak",
    "postcode": "73000",
    "districtCode": "7301",
    "districtNameTh": "เมืองนครปฐม",
    "districtNameEn": "Mueang Nakhon Pathom",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730115",
    "subDistrictNameTh": "ทุ่งน้อย",
    "subDistrictNameEn": "Thung Noi",
    "postcode": "73000",
    "districtCode": "7301",
    "districtNameTh": "เมืองนครปฐม",
    "districtNameEn": "Mueang Nakhon Pathom",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730116",
    "subDistrictNameTh": "หนองดินแดง",
    "subDistrictNameEn": "Nong Din Daeng",
    "postcode": "73000",
    "districtCode": "7301",
    "districtNameTh": "เมืองนครปฐม",
    "districtNameEn": "Mueang Nakhon Pathom",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730117",
    "subDistrictNameTh": "วังเย็น",
    "subDistrictNameEn": "Wang Yen",
    "postcode": "73000",
    "districtCode": "7301",
    "districtNameTh": "เมืองนครปฐม",
    "districtNameEn": "Mueang Nakhon Pathom",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730118",
    "subDistrictNameTh": "โพรงมะเดื่อ",
    "subDistrictNameEn": "Phrong Maduea",
    "postcode": "73000",
    "districtCode": "7301",
    "districtNameTh": "เมืองนครปฐม",
    "districtNameEn": "Mueang Nakhon Pathom",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730119",
    "subDistrictNameTh": "ลำพยา",
    "subDistrictNameEn": "Lam Phaya",
    "postcode": "73000",
    "districtCode": "7301",
    "districtNameTh": "เมืองนครปฐม",
    "districtNameEn": "Mueang Nakhon Pathom",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730120",
    "subDistrictNameTh": "สระกะเทียม",
    "subDistrictNameEn": "Sra Kathiam",
    "postcode": "73000",
    "districtCode": "7301",
    "districtNameTh": "เมืองนครปฐม",
    "districtNameEn": "Mueang Nakhon Pathom",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730121",
    "subDistrictNameTh": "สวนป่าน",
    "subDistrictNameEn": "Suan Pan",
    "postcode": "73000",
    "districtCode": "7301",
    "districtNameTh": "เมืองนครปฐม",
    "districtNameEn": "Mueang Nakhon Pathom",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730122",
    "subDistrictNameTh": "ห้วยจรเข้",
    "subDistrictNameEn": "Huai Chorakhe",
    "postcode": "73000",
    "districtCode": "7301",
    "districtNameTh": "เมืองนครปฐม",
    "districtNameEn": "Mueang Nakhon Pathom",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730123",
    "subDistrictNameTh": "ทัพหลวง",
    "subDistrictNameEn": "Thap Luang",
    "postcode": "73000",
    "districtCode": "7301",
    "districtNameTh": "เมืองนครปฐม",
    "districtNameEn": "Mueang Nakhon Pathom",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730124",
    "subDistrictNameTh": "หนองงูเหลือม",
    "subDistrictNameEn": "Nong Ngu Lueam",
    "postcode": "73000",
    "districtCode": "7301",
    "districtNameTh": "เมืองนครปฐม",
    "districtNameEn": "Mueang Nakhon Pathom",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730125",
    "subDistrictNameTh": "บ้านยาง",
    "subDistrictNameEn": "Ban Yang",
    "postcode": "73000",
    "districtCode": "7301",
    "districtNameTh": "เมืองนครปฐม",
    "districtNameEn": "Mueang Nakhon Pathom",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730201",
    "subDistrictNameTh": "ทุ่งกระพังโหม",
    "subDistrictNameEn": "Thung Kraphang Hom",
    "postcode": "73140",
    "districtCode": "7302",
    "districtNameTh": "กำแพงแสน",
    "districtNameEn": "Kamphaeng Saen",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730202",
    "subDistrictNameTh": "กระตีบ",
    "subDistrictNameEn": "Kratip",
    "postcode": "73180",
    "districtCode": "7302",
    "districtNameTh": "กำแพงแสน",
    "districtNameEn": "Kamphaeng Saen",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730203",
    "subDistrictNameTh": "ทุ่งลูกนก",
    "subDistrictNameEn": "Thung Luk Nok",
    "postcode": "73140",
    "districtCode": "7302",
    "districtNameTh": "กำแพงแสน",
    "districtNameEn": "Kamphaeng Saen",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730204",
    "subDistrictNameTh": "ห้วยขวาง",
    "subDistrictNameEn": "Huai Khwang",
    "postcode": "73140",
    "districtCode": "7302",
    "districtNameTh": "กำแพงแสน",
    "districtNameEn": "Kamphaeng Saen",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730205",
    "subDistrictNameTh": "ทุ่งขวาง",
    "subDistrictNameEn": "Thung Khwang",
    "postcode": "73140",
    "districtCode": "7302",
    "districtNameTh": "กำแพงแสน",
    "districtNameEn": "Kamphaeng Saen",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730206",
    "subDistrictNameTh": "สระสี่มุม",
    "subDistrictNameEn": "Sa Si Mum",
    "postcode": "73180",
    "districtCode": "7302",
    "districtNameTh": "กำแพงแสน",
    "districtNameEn": "Kamphaeng Saen",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730207",
    "subDistrictNameTh": "ทุ่งบัว",
    "subDistrictNameEn": "Thung Bua",
    "postcode": "73140",
    "districtCode": "7302",
    "districtNameTh": "กำแพงแสน",
    "districtNameEn": "Kamphaeng Saen",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730208",
    "subDistrictNameTh": "ดอนข่อย",
    "subDistrictNameEn": "Don Khoi",
    "postcode": "73140",
    "districtCode": "7302",
    "districtNameTh": "กำแพงแสน",
    "districtNameEn": "Kamphaeng Saen",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730209",
    "subDistrictNameTh": "สระพัฒนา",
    "subDistrictNameEn": "Sa Phatthana",
    "postcode": "73180",
    "districtCode": "7302",
    "districtNameTh": "กำแพงแสน",
    "districtNameEn": "Kamphaeng Saen",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730210",
    "subDistrictNameTh": "ห้วยหมอนทอง",
    "subDistrictNameEn": "Huai Mon Thong",
    "postcode": "73140",
    "districtCode": "7302",
    "districtNameTh": "กำแพงแสน",
    "districtNameEn": "Kamphaeng Saen",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730211",
    "subDistrictNameTh": "ห้วยม่วง",
    "subDistrictNameEn": "huai Muang",
    "postcode": "73180",
    "districtCode": "7302",
    "districtNameTh": "กำแพงแสน",
    "districtNameEn": "Kamphaeng Saen",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730212",
    "subDistrictNameTh": "กำแพงแสน",
    "subDistrictNameEn": "Kamphaeng Saen",
    "postcode": "73140",
    "districtCode": "7302",
    "districtNameTh": "กำแพงแสน",
    "districtNameEn": "Kamphaeng Saen",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730213",
    "subDistrictNameTh": "รางพิกุล",
    "subDistrictNameEn": "Rang Phikun",
    "postcode": "73140",
    "districtCode": "7302",
    "districtNameTh": "กำแพงแสน",
    "districtNameEn": "Kamphaeng Saen",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730214",
    "subDistrictNameTh": "หนองกระทุ่ม",
    "subDistrictNameEn": "Nong Krathum",
    "postcode": "73140",
    "districtCode": "7302",
    "districtNameTh": "กำแพงแสน",
    "districtNameEn": "Kamphaeng Saen",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730215",
    "subDistrictNameTh": "วังน้ำเขียว",
    "subDistrictNameEn": "Wang Nam Khiao",
    "postcode": "73140",
    "districtCode": "7302",
    "districtNameTh": "กำแพงแสน",
    "districtNameEn": "Kamphaeng Saen",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730301",
    "subDistrictNameTh": "นครชัยศรี",
    "subDistrictNameEn": "Nakhon Chai Si",
    "postcode": "73120",
    "districtCode": "7303",
    "districtNameTh": "นครชัยศรี",
    "districtNameEn": "Nakhon Chai Si",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730302",
    "subDistrictNameTh": "บางกระเบา",
    "subDistrictNameEn": "Bang Krabao",
    "postcode": "73120",
    "districtCode": "7303",
    "districtNameTh": "นครชัยศรี",
    "districtNameEn": "Nakhon Chai Si",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730303",
    "subDistrictNameTh": "วัดแค",
    "subDistrictNameEn": "Wat Khae",
    "postcode": "73120",
    "districtCode": "7303",
    "districtNameTh": "นครชัยศรี",
    "districtNameEn": "Nakhon Chai Si",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730304",
    "subDistrictNameTh": "ท่าตำหนัก",
    "subDistrictNameEn": "Tha Tamnak",
    "postcode": "73120",
    "districtCode": "7303",
    "districtNameTh": "นครชัยศรี",
    "districtNameEn": "Nakhon Chai Si",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730305",
    "subDistrictNameTh": "บางแก้ว",
    "subDistrictNameEn": "Bang Kaeo",
    "postcode": "73120",
    "districtCode": "7303",
    "districtNameTh": "นครชัยศรี",
    "districtNameEn": "Nakhon Chai Si",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730306",
    "subDistrictNameTh": "ท่ากระชับ",
    "subDistrictNameEn": "Tha Krachap",
    "postcode": "73120",
    "districtCode": "7303",
    "districtNameTh": "นครชัยศรี",
    "districtNameEn": "Nakhon Chai Si",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730307",
    "subDistrictNameTh": "ขุนแก้ว",
    "subDistrictNameEn": "Khun Kaeo",
    "postcode": "73120",
    "districtCode": "7303",
    "districtNameTh": "นครชัยศรี",
    "districtNameEn": "Nakhon Chai Si",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730308",
    "subDistrictNameTh": "ท่าพระยา",
    "subDistrictNameEn": "Tha Phraya",
    "postcode": "73120",
    "districtCode": "7303",
    "districtNameTh": "นครชัยศรี",
    "districtNameEn": "Nakhon Chai Si",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730309",
    "subDistrictNameTh": "พะเนียด",
    "subDistrictNameEn": "Phaniat",
    "postcode": "73120",
    "districtCode": "7303",
    "districtNameTh": "นครชัยศรี",
    "districtNameEn": "Nakhon Chai Si",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730310",
    "subDistrictNameTh": "บางระกำ",
    "subDistrictNameEn": "Bang Rakam",
    "postcode": "73120",
    "districtCode": "7303",
    "districtNameTh": "นครชัยศรี",
    "districtNameEn": "Nakhon Chai Si",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730311",
    "subDistrictNameTh": "โคกพระเจดีย์",
    "subDistrictNameEn": "Khok Phra Chedi",
    "postcode": "73120",
    "districtCode": "7303",
    "districtNameTh": "นครชัยศรี",
    "districtNameEn": "Nakhon Chai Si",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730312",
    "subDistrictNameTh": "ศรีษะทอง",
    "subDistrictNameEn": "Sisa Thong",
    "postcode": "73120",
    "districtCode": "7303",
    "districtNameTh": "นครชัยศรี",
    "districtNameEn": "Nakhon Chai Si",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730313",
    "subDistrictNameTh": "แหลมบัว",
    "subDistrictNameEn": "Laem Bua",
    "postcode": "73120",
    "districtCode": "7303",
    "districtNameTh": "นครชัยศรี",
    "districtNameEn": "Nakhon Chai Si",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730314",
    "subDistrictNameTh": "ศรีมหาโพธิ์",
    "subDistrictNameEn": "Si Maha Pho",
    "postcode": "73120",
    "districtCode": "7303",
    "districtNameTh": "นครชัยศรี",
    "districtNameEn": "Nakhon Chai Si",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730315",
    "subDistrictNameTh": "สัมปทวน",
    "subDistrictNameEn": "Sampathuan",
    "postcode": "73120",
    "districtCode": "7303",
    "districtNameTh": "นครชัยศรี",
    "districtNameEn": "Nakhon Chai Si",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730316",
    "subDistrictNameTh": "วัดสำโรง",
    "subDistrictNameEn": "Wat Samrong",
    "postcode": "73120",
    "districtCode": "7303",
    "districtNameTh": "นครชัยศรี",
    "districtNameEn": "Nakhon Chai Si",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730317",
    "subDistrictNameTh": "ดอนแฝก",
    "subDistrictNameEn": "Don Faek",
    "postcode": "73120",
    "districtCode": "7303",
    "districtNameTh": "นครชัยศรี",
    "districtNameEn": "Nakhon Chai Si",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730318",
    "subDistrictNameTh": "ห้วยพลู",
    "subDistrictNameEn": "Huai Phlu",
    "postcode": "73120",
    "districtCode": "7303",
    "districtNameTh": "นครชัยศรี",
    "districtNameEn": "Nakhon Chai Si",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730319",
    "subDistrictNameTh": "วัดละมุด",
    "subDistrictNameEn": "Wat Lamut",
    "postcode": "73120",
    "districtCode": "7303",
    "districtNameTh": "นครชัยศรี",
    "districtNameEn": "Nakhon Chai Si",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730320",
    "subDistrictNameTh": "บางพระ",
    "subDistrictNameEn": "Bang Phra",
    "postcode": "73120",
    "districtCode": "7303",
    "districtNameTh": "นครชัยศรี",
    "districtNameEn": "Nakhon Chai Si",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730321",
    "subDistrictNameTh": "บางแก้วฟ้า",
    "subDistrictNameEn": "Bang Kaeo Fa",
    "postcode": "73120",
    "districtCode": "7303",
    "districtNameTh": "นครชัยศรี",
    "districtNameEn": "Nakhon Chai Si",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730322",
    "subDistrictNameTh": "ลานตากฟ้า",
    "subDistrictNameEn": "Lan Tak Fa",
    "postcode": "73120",
    "districtCode": "7303",
    "districtNameTh": "นครชัยศรี",
    "districtNameEn": "Nakhon Chai Si",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730323",
    "subDistrictNameTh": "งิ้วราย",
    "subDistrictNameEn": "Ngio Rai",
    "postcode": "73120",
    "districtCode": "7303",
    "districtNameTh": "นครชัยศรี",
    "districtNameEn": "Nakhon Chai Si",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730324",
    "subDistrictNameTh": "ไทยาวาส",
    "subDistrictNameEn": "Thaiyawat",
    "postcode": "73120",
    "districtCode": "7303",
    "districtNameTh": "นครชัยศรี",
    "districtNameEn": "Nakhon Chai Si",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730401",
    "subDistrictNameTh": "สามง่าม",
    "subDistrictNameEn": "Sam Ngam",
    "postcode": "73150",
    "districtCode": "7304",
    "districtNameTh": "ดอนตูม",
    "districtNameEn": "Don Tum",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730402",
    "subDistrictNameTh": "ห้วยพระ",
    "subDistrictNameEn": "Huai Phra",
    "postcode": "73150",
    "districtCode": "7304",
    "districtNameTh": "ดอนตูม",
    "districtNameEn": "Don Tum",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730403",
    "subDistrictNameTh": "ลำเหย",
    "subDistrictNameEn": "Lam Hoei",
    "postcode": "73150",
    "districtCode": "7304",
    "districtNameTh": "ดอนตูม",
    "districtNameEn": "Don Tum",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730404",
    "subDistrictNameTh": "ดอนพุทรา",
    "subDistrictNameEn": "Don Phutsa",
    "postcode": "73150",
    "districtCode": "7304",
    "districtNameTh": "ดอนตูม",
    "districtNameEn": "Don Tum",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730405",
    "subDistrictNameTh": "บ้านหลวง",
    "subDistrictNameEn": "Ban Luang",
    "postcode": "73150",
    "districtCode": "7304",
    "districtNameTh": "ดอนตูม",
    "districtNameEn": "Don Tum",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730406",
    "subDistrictNameTh": "ดอนรวก",
    "subDistrictNameEn": "Don Ruak",
    "postcode": "73150",
    "districtCode": "7304",
    "districtNameTh": "ดอนตูม",
    "districtNameEn": "Don Tum",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730407",
    "subDistrictNameTh": "ห้วยด้วน",
    "subDistrictNameEn": "Huai Duan",
    "postcode": "73150",
    "districtCode": "7304",
    "districtNameTh": "ดอนตูม",
    "districtNameEn": "Don Tum",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730408",
    "subDistrictNameTh": "ลำลูกบัว",
    "subDistrictNameEn": "Lam Luk Bua",
    "postcode": "73150",
    "districtCode": "7304",
    "districtNameTh": "ดอนตูม",
    "districtNameEn": "Don Tum",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730501",
    "subDistrictNameTh": "บางเลน",
    "subDistrictNameEn": "Bang Len",
    "postcode": "73130",
    "districtCode": "7305",
    "districtNameTh": "บางเลน",
    "districtNameEn": "Bang Len",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730502",
    "subDistrictNameTh": "บางปลา",
    "subDistrictNameEn": "Bang Pla",
    "postcode": "73130",
    "districtCode": "7305",
    "districtNameTh": "บางเลน",
    "districtNameEn": "Bang Len",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730503",
    "subDistrictNameTh": "บางหลวง",
    "subDistrictNameEn": "Bang Luang",
    "postcode": "73190",
    "districtCode": "7305",
    "districtNameTh": "บางเลน",
    "districtNameEn": "Bang Len",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730504",
    "subDistrictNameTh": "บางภาษี",
    "subDistrictNameEn": "Bang Phasi",
    "postcode": "73130",
    "districtCode": "7305",
    "districtNameTh": "บางเลน",
    "districtNameEn": "Bang Len",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730505",
    "subDistrictNameTh": "บางระกำ",
    "subDistrictNameEn": "Bang Rakam",
    "postcode": "73130",
    "districtCode": "7305",
    "districtNameTh": "บางเลน",
    "districtNameEn": "Bang Len",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730506",
    "subDistrictNameTh": "บางไทรป่า",
    "subDistrictNameEn": "Bang Sai Pa",
    "postcode": "73130",
    "districtCode": "7305",
    "districtNameTh": "บางเลน",
    "districtNameEn": "Bang Len",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730507",
    "subDistrictNameTh": "หินมูล",
    "subDistrictNameEn": "Hin Mun",
    "postcode": "73190",
    "districtCode": "7305",
    "districtNameTh": "บางเลน",
    "districtNameEn": "Bang Len",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730508",
    "subDistrictNameTh": "ไทรงาม",
    "subDistrictNameEn": "Sai Ngam",
    "postcode": "73130",
    "districtCode": "7305",
    "districtNameTh": "บางเลน",
    "districtNameEn": "Bang Len",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730509",
    "subDistrictNameTh": "ดอนตูม",
    "subDistrictNameEn": "Don Tum",
    "postcode": "73130",
    "districtCode": "7305",
    "districtNameTh": "บางเลน",
    "districtNameEn": "Bang Len",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730510",
    "subDistrictNameTh": "นิลเพชร",
    "subDistrictNameEn": "Nin Phet",
    "postcode": "73130",
    "districtCode": "7305",
    "districtNameTh": "บางเลน",
    "districtNameEn": "Bang Len",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730511",
    "subDistrictNameTh": "บัวปากท่า",
    "subDistrictNameEn": "Bua Pak Tha",
    "postcode": "73130",
    "districtCode": "7305",
    "districtNameTh": "บางเลน",
    "districtNameEn": "Bang Len",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730512",
    "subDistrictNameTh": "คลองนกกระทุง",
    "subDistrictNameEn": "Khlong Nok Krathung",
    "postcode": "73130",
    "districtCode": "7305",
    "districtNameTh": "บางเลน",
    "districtNameEn": "Bang Len",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730513",
    "subDistrictNameTh": "นราภิรมย์",
    "subDistrictNameEn": "Naraphirom",
    "postcode": "73130",
    "districtCode": "7305",
    "districtNameTh": "บางเลน",
    "districtNameEn": "Bang Len",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730514",
    "subDistrictNameTh": "ลำพญา",
    "subDistrictNameEn": "Lam Phaya",
    "postcode": "73130",
    "districtCode": "7305",
    "districtNameTh": "บางเลน",
    "districtNameEn": "Bang Len",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730515",
    "subDistrictNameTh": "ไผ่หูช้าง",
    "subDistrictNameEn": "Phai Hu Chang",
    "postcode": "73130",
    "districtCode": "7305",
    "districtNameTh": "บางเลน",
    "districtNameEn": "Bang Len",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730601",
    "subDistrictNameTh": "ท่าข้าม",
    "subDistrictNameEn": "Tha Kham",
    "postcode": "73110",
    "districtCode": "7306",
    "districtNameTh": "สามพราน",
    "districtNameEn": "Sam Phran",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730602",
    "subDistrictNameTh": "ทรงคนอง",
    "subDistrictNameEn": "Song Khanong",
    "postcode": "73210",
    "districtCode": "7306",
    "districtNameTh": "สามพราน",
    "districtNameEn": "Sam Phran",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730603",
    "subDistrictNameTh": "หอมเกร็ด",
    "subDistrictNameEn": "Hom Kret",
    "postcode": "73110",
    "districtCode": "7306",
    "districtNameTh": "สามพราน",
    "districtNameEn": "Sam Phran",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730604",
    "subDistrictNameTh": "บางกระทึก",
    "subDistrictNameEn": "Bang Krathuek",
    "postcode": "73210",
    "districtCode": "7306",
    "districtNameTh": "สามพราน",
    "districtNameEn": "Sam Phran",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730605",
    "subDistrictNameTh": "บางเตย",
    "subDistrictNameEn": "Bang Toei",
    "postcode": "73210",
    "districtCode": "7306",
    "districtNameTh": "สามพราน",
    "districtNameEn": "Sam Phran",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730606",
    "subDistrictNameTh": "สามพราน",
    "subDistrictNameEn": "Sam Phran",
    "postcode": "73110",
    "districtCode": "7306",
    "districtNameTh": "สามพราน",
    "districtNameEn": "Sam Phran",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730607",
    "subDistrictNameTh": "บางช้าง",
    "subDistrictNameEn": "Bang Chang",
    "postcode": "73110",
    "districtCode": "7306",
    "districtNameTh": "สามพราน",
    "districtNameEn": "Sam Phran",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730608",
    "subDistrictNameTh": "ไร่ขิง",
    "subDistrictNameEn": "Rai Khing",
    "postcode": "73210",
    "districtCode": "7306",
    "districtNameTh": "สามพราน",
    "districtNameEn": "Sam Phran",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730609",
    "subDistrictNameTh": "ท่าตลาด",
    "subDistrictNameEn": "Tha Talat",
    "postcode": "73110",
    "districtCode": "7306",
    "districtNameTh": "สามพราน",
    "districtNameEn": "Sam Phran",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730610",
    "subDistrictNameTh": "กระทุ่มล้ม",
    "subDistrictNameEn": "Krathum Lom",
    "postcode": "73220",
    "districtCode": "7306",
    "districtNameTh": "สามพราน",
    "districtNameEn": "Sam Phran",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730611",
    "subDistrictNameTh": "คลองใหม่",
    "subDistrictNameEn": "Khlong Mai",
    "postcode": "73110",
    "districtCode": "7306",
    "districtNameTh": "สามพราน",
    "districtNameEn": "Sam Phran",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730612",
    "subDistrictNameTh": "ตลาดจินดา",
    "subDistrictNameEn": "Talat Chinda",
    "postcode": "73110",
    "districtCode": "7306",
    "districtNameTh": "สามพราน",
    "districtNameEn": "Sam Phran",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730613",
    "subDistrictNameTh": "คลองจินดา",
    "subDistrictNameEn": "Khlong Chinda",
    "postcode": "73110",
    "districtCode": "7306",
    "districtNameTh": "สามพราน",
    "districtNameEn": "Sam Phran",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730614",
    "subDistrictNameTh": "ยายชา",
    "subDistrictNameEn": "Yai Cha",
    "postcode": "73110",
    "districtCode": "7306",
    "districtNameTh": "สามพราน",
    "districtNameEn": "Sam Phran",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730615",
    "subDistrictNameTh": "บ้านใหม่",
    "subDistrictNameEn": "Ban Mai",
    "postcode": "73110",
    "districtCode": "7306",
    "districtNameTh": "สามพราน",
    "districtNameEn": "Sam Phran",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730616",
    "subDistrictNameTh": "อ้อมใหญ่",
    "subDistrictNameEn": "Om Yai",
    "postcode": "73160",
    "districtCode": "7306",
    "districtNameTh": "สามพราน",
    "districtNameEn": "Sam Phran",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730701",
    "subDistrictNameTh": "ศาลายา",
    "subDistrictNameEn": "Salaya",
    "postcode": "73170",
    "districtCode": "7307",
    "districtNameTh": "พุทธมณฑล",
    "districtNameEn": "Phutthamonthon",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730702",
    "subDistrictNameTh": "คลองโยง",
    "subDistrictNameEn": "Khlong Yong",
    "postcode": "73170",
    "districtCode": "7307",
    "districtNameTh": "พุทธมณฑล",
    "districtNameEn": "Phutthamonthon",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "730703",
    "subDistrictNameTh": "มหาสวัสดิ์",
    "subDistrictNameEn": "Maha Sawat",
    "postcode": "73170",
    "districtCode": "7307",
    "districtNameTh": "พุทธมณฑล",
    "districtNameEn": "Phutthamonthon",
    "provinceCode": "73",
    "provinceNameTh": "นครปฐม",
    "provinceNameEn": "Nakhon Pathom"
},
{
    "subDistrictCode": "740101",
    "subDistrictNameTh": "มหาชัย",
    "subDistrictNameEn": "Maha Chai",
    "postcode": "74000",
    "districtCode": "7401",
    "districtNameTh": "เมืองสมุทรสาคร",
    "districtNameEn": "Mueang Samut Sakhon",
    "provinceCode": "74",
    "provinceNameTh": "สมุทรสาคร",
    "provinceNameEn": "Samut Sakhon"
},
{
    "subDistrictCode": "740102",
    "subDistrictNameTh": "ท่าฉลอม",
    "subDistrictNameEn": "Tha Chalom",
    "postcode": "74000",
    "districtCode": "7401",
    "districtNameTh": "เมืองสมุทรสาคร",
    "districtNameEn": "Mueang Samut Sakhon",
    "provinceCode": "74",
    "provinceNameTh": "สมุทรสาคร",
    "provinceNameEn": "Samut Sakhon"
},
{
    "subDistrictCode": "740103",
    "subDistrictNameTh": "โกรกกราก",
    "subDistrictNameEn": "Krokkrak",
    "postcode": "74000",
    "districtCode": "7401",
    "districtNameTh": "เมืองสมุทรสาคร",
    "districtNameEn": "Mueang Samut Sakhon",
    "provinceCode": "74",
    "provinceNameTh": "สมุทรสาคร",
    "provinceNameEn": "Samut Sakhon"
},
{
    "subDistrictCode": "740104",
    "subDistrictNameTh": "บ้านบ่อ",
    "subDistrictNameEn": "Ban Bo",
    "postcode": "74000",
    "districtCode": "7401",
    "districtNameTh": "เมืองสมุทรสาคร",
    "districtNameEn": "Mueang Samut Sakhon",
    "provinceCode": "74",
    "provinceNameTh": "สมุทรสาคร",
    "provinceNameEn": "Samut Sakhon"
},
{
    "subDistrictCode": "740105",
    "subDistrictNameTh": "บางโทรัด",
    "subDistrictNameEn": "Bang Tho Rat",
    "postcode": "74000",
    "districtCode": "7401",
    "districtNameTh": "เมืองสมุทรสาคร",
    "districtNameEn": "Mueang Samut Sakhon",
    "provinceCode": "74",
    "provinceNameTh": "สมุทรสาคร",
    "provinceNameEn": "Samut Sakhon"
},
{
    "subDistrictCode": "740106",
    "subDistrictNameTh": "กาหลง",
    "subDistrictNameEn": "Kalong",
    "postcode": "74000",
    "districtCode": "7401",
    "districtNameTh": "เมืองสมุทรสาคร",
    "districtNameEn": "Mueang Samut Sakhon",
    "provinceCode": "74",
    "provinceNameTh": "สมุทรสาคร",
    "provinceNameEn": "Samut Sakhon"
},
{
    "subDistrictCode": "740107",
    "subDistrictNameTh": "นาโคก",
    "subDistrictNameEn": "Na Khok",
    "postcode": "74000",
    "districtCode": "7401",
    "districtNameTh": "เมืองสมุทรสาคร",
    "districtNameEn": "Mueang Samut Sakhon",
    "provinceCode": "74",
    "provinceNameTh": "สมุทรสาคร",
    "provinceNameEn": "Samut Sakhon"
},
{
    "subDistrictCode": "740108",
    "subDistrictNameTh": "ท่าจีน",
    "subDistrictNameEn": "Tha Chin",
    "postcode": "74000",
    "districtCode": "7401",
    "districtNameTh": "เมืองสมุทรสาคร",
    "districtNameEn": "Mueang Samut Sakhon",
    "provinceCode": "74",
    "provinceNameTh": "สมุทรสาคร",
    "provinceNameEn": "Samut Sakhon"
},
{
    "subDistrictCode": "740109",
    "subDistrictNameTh": "นาดี",
    "subDistrictNameEn": "Na Di",
    "postcode": "74000",
    "districtCode": "7401",
    "districtNameTh": "เมืองสมุทรสาคร",
    "districtNameEn": "Mueang Samut Sakhon",
    "provinceCode": "74",
    "provinceNameTh": "สมุทรสาคร",
    "provinceNameEn": "Samut Sakhon"
},
{
    "subDistrictCode": "740110",
    "subDistrictNameTh": "ท่าทราย",
    "subDistrictNameEn": "Tha Sai",
    "postcode": "74000",
    "districtCode": "7401",
    "districtNameTh": "เมืองสมุทรสาคร",
    "districtNameEn": "Mueang Samut Sakhon",
    "provinceCode": "74",
    "provinceNameTh": "สมุทรสาคร",
    "provinceNameEn": "Samut Sakhon"
},
{
    "subDistrictCode": "740111",
    "subDistrictNameTh": "คอกกระบือ",
    "subDistrictNameEn": "Khok Krabue",
    "postcode": "74000",
    "districtCode": "7401",
    "districtNameTh": "เมืองสมุทรสาคร",
    "districtNameEn": "Mueang Samut Sakhon",
    "provinceCode": "74",
    "provinceNameTh": "สมุทรสาคร",
    "provinceNameEn": "Samut Sakhon"
},
{
    "subDistrictCode": "740112",
    "subDistrictNameTh": "บางน้ำจืด",
    "subDistrictNameEn": "Bang Nam Chuet",
    "postcode": "74000",
    "districtCode": "7401",
    "districtNameTh": "เมืองสมุทรสาคร",
    "districtNameEn": "Mueang Samut Sakhon",
    "provinceCode": "74",
    "provinceNameTh": "สมุทรสาคร",
    "provinceNameEn": "Samut Sakhon"
},
{
    "subDistrictCode": "740113",
    "subDistrictNameTh": "พันท้ายนรสิงห์",
    "subDistrictNameEn": "Phan Thai Norasing",
    "postcode": "74000",
    "districtCode": "7401",
    "districtNameTh": "เมืองสมุทรสาคร",
    "districtNameEn": "Mueang Samut Sakhon",
    "provinceCode": "74",
    "provinceNameTh": "สมุทรสาคร",
    "provinceNameEn": "Samut Sakhon"
},
{
    "subDistrictCode": "740114",
    "subDistrictNameTh": "โคกขาม",
    "subDistrictNameEn": "Khok Kham",
    "postcode": "74000",
    "districtCode": "7401",
    "districtNameTh": "เมืองสมุทรสาคร",
    "districtNameEn": "Mueang Samut Sakhon",
    "provinceCode": "74",
    "provinceNameTh": "สมุทรสาคร",
    "provinceNameEn": "Samut Sakhon"
},
{
    "subDistrictCode": "740115",
    "subDistrictNameTh": "บ้านเกาะ",
    "subDistrictNameEn": "Ban Ko",
    "postcode": "74000",
    "districtCode": "7401",
    "districtNameTh": "เมืองสมุทรสาคร",
    "districtNameEn": "Mueang Samut Sakhon",
    "provinceCode": "74",
    "provinceNameTh": "สมุทรสาคร",
    "provinceNameEn": "Samut Sakhon"
},
{
    "subDistrictCode": "740116",
    "subDistrictNameTh": "บางกระเจ้า",
    "subDistrictNameEn": "Bang Krachao",
    "postcode": "74000",
    "districtCode": "7401",
    "districtNameTh": "เมืองสมุทรสาคร",
    "districtNameEn": "Mueang Samut Sakhon",
    "provinceCode": "74",
    "provinceNameTh": "สมุทรสาคร",
    "provinceNameEn": "Samut Sakhon"
},
{
    "subDistrictCode": "740117",
    "subDistrictNameTh": "บางหญ้าแพรก",
    "subDistrictNameEn": "Bang Ya Phraek",
    "postcode": "74000",
    "districtCode": "7401",
    "districtNameTh": "เมืองสมุทรสาคร",
    "districtNameEn": "Mueang Samut Sakhon",
    "provinceCode": "74",
    "provinceNameTh": "สมุทรสาคร",
    "provinceNameEn": "Samut Sakhon"
},
{
    "subDistrictCode": "740118",
    "subDistrictNameTh": "ชัยมงคล",
    "subDistrictNameEn": "Chai Mongkon",
    "postcode": "74000",
    "districtCode": "7401",
    "districtNameTh": "เมืองสมุทรสาคร",
    "districtNameEn": "Mueang Samut Sakhon",
    "provinceCode": "74",
    "provinceNameTh": "สมุทรสาคร",
    "provinceNameEn": "Samut Sakhon"
},
{
    "subDistrictCode": "740201",
    "subDistrictNameTh": "ตลาดกระทุ่มแบน",
    "subDistrictNameEn": "Talad Krathum Baen",
    "postcode": "74110",
    "districtCode": "7402",
    "districtNameTh": "กระทุ่มแบน",
    "districtNameEn": "Krathum Baen",
    "provinceCode": "74",
    "provinceNameTh": "สมุทรสาคร",
    "provinceNameEn": "Samut Sakhon"
},
{
    "subDistrictCode": "740202",
    "subDistrictNameTh": "อ้อมน้อย",
    "subDistrictNameEn": "Om Noi",
    "postcode": "74130",
    "districtCode": "7402",
    "districtNameTh": "กระทุ่มแบน",
    "districtNameEn": "Krathum Baen",
    "provinceCode": "74",
    "provinceNameTh": "สมุทรสาคร",
    "provinceNameEn": "Samut Sakhon"
},
{
    "subDistrictCode": "740203",
    "subDistrictNameTh": "ท่าไม้",
    "subDistrictNameEn": "Tha Mai",
    "postcode": "74110",
    "districtCode": "7402",
    "districtNameTh": "กระทุ่มแบน",
    "districtNameEn": "Krathum Baen",
    "provinceCode": "74",
    "provinceNameTh": "สมุทรสาคร",
    "provinceNameEn": "Samut Sakhon"
},
{
    "subDistrictCode": "740204",
    "subDistrictNameTh": "สวนหลวง",
    "subDistrictNameEn": "Suan Luang",
    "postcode": "74110",
    "districtCode": "7402",
    "districtNameTh": "กระทุ่มแบน",
    "districtNameEn": "Krathum Baen",
    "provinceCode": "74",
    "provinceNameTh": "สมุทรสาคร",
    "provinceNameEn": "Samut Sakhon"
},
{
    "subDistrictCode": "740205",
    "subDistrictNameTh": "บางยาง",
    "subDistrictNameEn": "Bang Yang",
    "postcode": "74110",
    "districtCode": "7402",
    "districtNameTh": "กระทุ่มแบน",
    "districtNameEn": "Krathum Baen",
    "provinceCode": "74",
    "provinceNameTh": "สมุทรสาคร",
    "provinceNameEn": "Samut Sakhon"
},
{
    "subDistrictCode": "740206",
    "subDistrictNameTh": "คลองมะเดื่อ",
    "subDistrictNameEn": "Khlong Maduea",
    "postcode": "74110",
    "districtCode": "7402",
    "districtNameTh": "กระทุ่มแบน",
    "districtNameEn": "Krathum Baen",
    "provinceCode": "74",
    "provinceNameTh": "สมุทรสาคร",
    "provinceNameEn": "Samut Sakhon"
},
{
    "subDistrictCode": "740207",
    "subDistrictNameTh": "หนองนกไข่",
    "subDistrictNameEn": "Nong Nok Khai",
    "postcode": "74110",
    "districtCode": "7402",
    "districtNameTh": "กระทุ่มแบน",
    "districtNameEn": "Krathum Baen",
    "provinceCode": "74",
    "provinceNameTh": "สมุทรสาคร",
    "provinceNameEn": "Samut Sakhon"
},
{
    "subDistrictCode": "740208",
    "subDistrictNameTh": "ดอนไก่ดี",
    "subDistrictNameEn": "Don Kai Di",
    "postcode": "74110",
    "districtCode": "7402",
    "districtNameTh": "กระทุ่มแบน",
    "districtNameEn": "Krathum Baen",
    "provinceCode": "74",
    "provinceNameTh": "สมุทรสาคร",
    "provinceNameEn": "Samut Sakhon"
},
{
    "subDistrictCode": "740209",
    "subDistrictNameTh": "แคราย",
    "subDistrictNameEn": "Khae Rai",
    "postcode": "74110",
    "districtCode": "7402",
    "districtNameTh": "กระทุ่มแบน",
    "districtNameEn": "Krathum Baen",
    "provinceCode": "74",
    "provinceNameTh": "สมุทรสาคร",
    "provinceNameEn": "Samut Sakhon"
},
{
    "subDistrictCode": "740210",
    "subDistrictNameTh": "ท่าเสา",
    "subDistrictNameEn": "Tha Sao",
    "postcode": "74110",
    "districtCode": "7402",
    "districtNameTh": "กระทุ่มแบน",
    "districtNameEn": "Krathum Baen",
    "provinceCode": "74",
    "provinceNameTh": "สมุทรสาคร",
    "provinceNameEn": "Samut Sakhon"
},
{
    "subDistrictCode": "740301",
    "subDistrictNameTh": "บ้านแพ้ว",
    "subDistrictNameEn": "Ban Phaeo",
    "postcode": "74120",
    "districtCode": "7403",
    "districtNameTh": "บ้านแพ้ว",
    "districtNameEn": "Ban Phaeo",
    "provinceCode": "74",
    "provinceNameTh": "สมุทรสาคร",
    "provinceNameEn": "Samut Sakhon"
},
{
    "subDistrictCode": "740302",
    "subDistrictNameTh": "หลักสาม",
    "subDistrictNameEn": "Lak Sam",
    "postcode": "74120",
    "districtCode": "7403",
    "districtNameTh": "บ้านแพ้ว",
    "districtNameEn": "Ban Phaeo",
    "provinceCode": "74",
    "provinceNameTh": "สมุทรสาคร",
    "provinceNameEn": "Samut Sakhon"
},
{
    "subDistrictCode": "740303",
    "subDistrictNameTh": "ยกกระบัตร",
    "subDistrictNameEn": "Yokkrabat",
    "postcode": "74120",
    "districtCode": "7403",
    "districtNameTh": "บ้านแพ้ว",
    "districtNameEn": "Ban Phaeo",
    "provinceCode": "74",
    "provinceNameTh": "สมุทรสาคร",
    "provinceNameEn": "Samut Sakhon"
},
{
    "subDistrictCode": "740304",
    "subDistrictNameTh": "โรงเข้",
    "subDistrictNameEn": "Rong Khe",
    "postcode": "74120",
    "districtCode": "7403",
    "districtNameTh": "บ้านแพ้ว",
    "districtNameEn": "Ban Phaeo",
    "provinceCode": "74",
    "provinceNameTh": "สมุทรสาคร",
    "provinceNameEn": "Samut Sakhon"
},
{
    "subDistrictCode": "740305",
    "subDistrictNameTh": "หนองสองห้อง",
    "subDistrictNameEn": "Nong Song Hong",
    "postcode": "74120",
    "districtCode": "7403",
    "districtNameTh": "บ้านแพ้ว",
    "districtNameEn": "Ban Phaeo",
    "provinceCode": "74",
    "provinceNameTh": "สมุทรสาคร",
    "provinceNameEn": "Samut Sakhon"
},
{
    "subDistrictCode": "740306",
    "subDistrictNameTh": "หนองบัว",
    "subDistrictNameEn": "Nong Bua",
    "postcode": "74120",
    "districtCode": "7403",
    "districtNameTh": "บ้านแพ้ว",
    "districtNameEn": "Ban Phaeo",
    "provinceCode": "74",
    "provinceNameTh": "สมุทรสาคร",
    "provinceNameEn": "Samut Sakhon"
},
{
    "subDistrictCode": "740307",
    "subDistrictNameTh": "หลักสอง",
    "subDistrictNameEn": "Lak Song",
    "postcode": "74120",
    "districtCode": "7403",
    "districtNameTh": "บ้านแพ้ว",
    "districtNameEn": "Ban Phaeo",
    "provinceCode": "74",
    "provinceNameTh": "สมุทรสาคร",
    "provinceNameEn": "Samut Sakhon"
},
{
    "subDistrictCode": "740308",
    "subDistrictNameTh": "เจ็ดริ้ว",
    "subDistrictNameEn": "Chet Rio",
    "postcode": "74120",
    "districtCode": "7403",
    "districtNameTh": "บ้านแพ้ว",
    "districtNameEn": "Ban Phaeo",
    "provinceCode": "74",
    "provinceNameTh": "สมุทรสาคร",
    "provinceNameEn": "Samut Sakhon"
},
{
    "subDistrictCode": "740309",
    "subDistrictNameTh": "คลองตัน",
    "subDistrictNameEn": "Khlong Tan",
    "postcode": "74120",
    "districtCode": "7403",
    "districtNameTh": "บ้านแพ้ว",
    "districtNameEn": "Ban Phaeo",
    "provinceCode": "74",
    "provinceNameTh": "สมุทรสาคร",
    "provinceNameEn": "Samut Sakhon"
},
{
    "subDistrictCode": "740310",
    "subDistrictNameTh": "อำแพง",
    "subDistrictNameEn": "Amphaeng",
    "postcode": "74120",
    "districtCode": "7403",
    "districtNameTh": "บ้านแพ้ว",
    "districtNameEn": "Ban Phaeo",
    "provinceCode": "74",
    "provinceNameTh": "สมุทรสาคร",
    "provinceNameEn": "Samut Sakhon"
},
{
    "subDistrictCode": "740311",
    "subDistrictNameTh": "สวนส้ม",
    "subDistrictNameEn": "Suan Som",
    "postcode": "74120",
    "districtCode": "7403",
    "districtNameTh": "บ้านแพ้ว",
    "districtNameEn": "Ban Phaeo",
    "provinceCode": "74",
    "provinceNameTh": "สมุทรสาคร",
    "provinceNameEn": "Samut Sakhon"
},
{
    "subDistrictCode": "740312",
    "subDistrictNameTh": "เกษตรพัฒนา",
    "subDistrictNameEn": "Kaset Phatthana",
    "postcode": "74120",
    "districtCode": "7403",
    "districtNameTh": "บ้านแพ้ว",
    "districtNameEn": "Ban Phaeo",
    "provinceCode": "74",
    "provinceNameTh": "สมุทรสาคร",
    "provinceNameEn": "Samut Sakhon"
},
{
    "subDistrictCode": "750101",
    "subDistrictNameTh": "แม่กลอง",
    "subDistrictNameEn": "Mae Klong",
    "postcode": "75000",
    "districtCode": "7501",
    "districtNameTh": "เมืองสมุทรสงคราม",
    "districtNameEn": "Mueang Samut Songkhram",
    "provinceCode": "75",
    "provinceNameTh": "สมุทรสงคราม",
    "provinceNameEn": "Samut Songkhram"
},
{
    "subDistrictCode": "750102",
    "subDistrictNameTh": "บางขันแตก",
    "subDistrictNameEn": "Bang Khan Taek",
    "postcode": "75000",
    "districtCode": "7501",
    "districtNameTh": "เมืองสมุทรสงคราม",
    "districtNameEn": "Mueang Samut Songkhram",
    "provinceCode": "75",
    "provinceNameTh": "สมุทรสงคราม",
    "provinceNameEn": "Samut Songkhram"
},
{
    "subDistrictCode": "750103",
    "subDistrictNameTh": "ลาดใหญ่",
    "subDistrictNameEn": "Lat Yai",
    "postcode": "75000",
    "districtCode": "7501",
    "districtNameTh": "เมืองสมุทรสงคราม",
    "districtNameEn": "Mueang Samut Songkhram",
    "provinceCode": "75",
    "provinceNameTh": "สมุทรสงคราม",
    "provinceNameEn": "Samut Songkhram"
},
{
    "subDistrictCode": "750104",
    "subDistrictNameTh": "บ้านปรก",
    "subDistrictNameEn": "Ban Prok",
    "postcode": "75000",
    "districtCode": "7501",
    "districtNameTh": "เมืองสมุทรสงคราม",
    "districtNameEn": "Mueang Samut Songkhram",
    "provinceCode": "75",
    "provinceNameTh": "สมุทรสงคราม",
    "provinceNameEn": "Samut Songkhram"
},
{
    "subDistrictCode": "750105",
    "subDistrictNameTh": "บางแก้ว",
    "subDistrictNameEn": "Bang Kaeo",
    "postcode": "75000",
    "districtCode": "7501",
    "districtNameTh": "เมืองสมุทรสงคราม",
    "districtNameEn": "Mueang Samut Songkhram",
    "provinceCode": "75",
    "provinceNameTh": "สมุทรสงคราม",
    "provinceNameEn": "Samut Songkhram"
},
{
    "subDistrictCode": "750106",
    "subDistrictNameTh": "ท้ายหาด",
    "subDistrictNameEn": "Thai Hat",
    "postcode": "75000",
    "districtCode": "7501",
    "districtNameTh": "เมืองสมุทรสงคราม",
    "districtNameEn": "Mueang Samut Songkhram",
    "provinceCode": "75",
    "provinceNameTh": "สมุทรสงคราม",
    "provinceNameEn": "Samut Songkhram"
},
{
    "subDistrictCode": "750107",
    "subDistrictNameTh": "แหลมใหญ่",
    "subDistrictNameEn": "Laem Yai",
    "postcode": "75000",
    "districtCode": "7501",
    "districtNameTh": "เมืองสมุทรสงคราม",
    "districtNameEn": "Mueang Samut Songkhram",
    "provinceCode": "75",
    "provinceNameTh": "สมุทรสงคราม",
    "provinceNameEn": "Samut Songkhram"
},
{
    "subDistrictCode": "750108",
    "subDistrictNameTh": "คลองเขิน",
    "subDistrictNameEn": "Khlong Khoen",
    "postcode": "75000",
    "districtCode": "7501",
    "districtNameTh": "เมืองสมุทรสงคราม",
    "districtNameEn": "Mueang Samut Songkhram",
    "provinceCode": "75",
    "provinceNameTh": "สมุทรสงคราม",
    "provinceNameEn": "Samut Songkhram"
},
{
    "subDistrictCode": "750109",
    "subDistrictNameTh": "คลองโคน",
    "subDistrictNameEn": "Khlong Khon",
    "postcode": "75000",
    "districtCode": "7501",
    "districtNameTh": "เมืองสมุทรสงคราม",
    "districtNameEn": "Mueang Samut Songkhram",
    "provinceCode": "75",
    "provinceNameTh": "สมุทรสงคราม",
    "provinceNameEn": "Samut Songkhram"
},
{
    "subDistrictCode": "750110",
    "subDistrictNameTh": "นางตะเคียน",
    "subDistrictNameEn": "Nang Takian",
    "postcode": "75000",
    "districtCode": "7501",
    "districtNameTh": "เมืองสมุทรสงคราม",
    "districtNameEn": "Mueang Samut Songkhram",
    "provinceCode": "75",
    "provinceNameTh": "สมุทรสงคราม",
    "provinceNameEn": "Samut Songkhram"
},
{
    "subDistrictCode": "750111",
    "subDistrictNameTh": "บางจะเกร็ง",
    "subDistrictNameEn": "Bang Chakreng",
    "postcode": "75000",
    "districtCode": "7501",
    "districtNameTh": "เมืองสมุทรสงคราม",
    "districtNameEn": "Mueang Samut Songkhram",
    "provinceCode": "75",
    "provinceNameTh": "สมุทรสงคราม",
    "provinceNameEn": "Samut Songkhram"
},
{
    "subDistrictCode": "750201",
    "subDistrictNameTh": "กระดังงา",
    "subDistrictNameEn": "Kradangnga",
    "postcode": "75120",
    "districtCode": "7502",
    "districtNameTh": "บางคนที",
    "districtNameEn": "Bang Khonthi",
    "provinceCode": "75",
    "provinceNameTh": "สมุทรสงคราม",
    "provinceNameEn": "Samut Songkhram"
},
{
    "subDistrictCode": "750202",
    "subDistrictNameTh": "บางสะแก",
    "subDistrictNameEn": "Bang Sakae",
    "postcode": "75120",
    "districtCode": "7502",
    "districtNameTh": "บางคนที",
    "districtNameEn": "Bang Khonthi",
    "provinceCode": "75",
    "provinceNameTh": "สมุทรสงคราม",
    "provinceNameEn": "Samut Songkhram"
},
{
    "subDistrictCode": "750203",
    "subDistrictNameTh": "บางยี่รงค์",
    "subDistrictNameEn": "Bang Yi Rong",
    "postcode": "75120",
    "districtCode": "7502",
    "districtNameTh": "บางคนที",
    "districtNameEn": "Bang Khonthi",
    "provinceCode": "75",
    "provinceNameTh": "สมุทรสงคราม",
    "provinceNameEn": "Samut Songkhram"
},
{
    "subDistrictCode": "750204",
    "subDistrictNameTh": "โรงหีบ",
    "subDistrictNameEn": "Rong Hip",
    "postcode": "75120",
    "districtCode": "7502",
    "districtNameTh": "บางคนที",
    "districtNameEn": "Bang Khonthi",
    "provinceCode": "75",
    "provinceNameTh": "สมุทรสงคราม",
    "provinceNameEn": "Samut Songkhram"
},
{
    "subDistrictCode": "750205",
    "subDistrictNameTh": "บางคนที",
    "subDistrictNameEn": "Bang Khonthi",
    "postcode": "75120",
    "districtCode": "7502",
    "districtNameTh": "บางคนที",
    "districtNameEn": "Bang Khonthi",
    "provinceCode": "75",
    "provinceNameTh": "สมุทรสงคราม",
    "provinceNameEn": "Samut Songkhram"
},
{
    "subDistrictCode": "750206",
    "subDistrictNameTh": "ดอนมะโนรา",
    "subDistrictNameEn": "Don Manora",
    "postcode": "75120",
    "districtCode": "7502",
    "districtNameTh": "บางคนที",
    "districtNameEn": "Bang Khonthi",
    "provinceCode": "75",
    "provinceNameTh": "สมุทรสงคราม",
    "provinceNameEn": "Samut Songkhram"
},
{
    "subDistrictCode": "750207",
    "subDistrictNameTh": "บางพรม",
    "subDistrictNameEn": "Bang Phrom",
    "postcode": "75120",
    "districtCode": "7502",
    "districtNameTh": "บางคนที",
    "districtNameEn": "Bang Khonthi",
    "provinceCode": "75",
    "provinceNameTh": "สมุทรสงคราม",
    "provinceNameEn": "Samut Songkhram"
},
{
    "subDistrictCode": "750208",
    "subDistrictNameTh": "บางกุ้ง",
    "subDistrictNameEn": "Bang Kung",
    "postcode": "75120",
    "districtCode": "7502",
    "districtNameTh": "บางคนที",
    "districtNameEn": "Bang Khonthi",
    "provinceCode": "75",
    "provinceNameTh": "สมุทรสงคราม",
    "provinceNameEn": "Samut Songkhram"
},
{
    "subDistrictCode": "750209",
    "subDistrictNameTh": "จอมปลวก",
    "subDistrictNameEn": "Chom Pluak",
    "postcode": "75120",
    "districtCode": "7502",
    "districtNameTh": "บางคนที",
    "districtNameEn": "Bang Khonthi",
    "provinceCode": "75",
    "provinceNameTh": "สมุทรสงคราม",
    "provinceNameEn": "Samut Songkhram"
},
{
    "subDistrictCode": "750210",
    "subDistrictNameTh": "บางนกแขวก",
    "subDistrictNameEn": "Bang Nok Khwaek",
    "postcode": "75120",
    "districtCode": "7502",
    "districtNameTh": "บางคนที",
    "districtNameEn": "Bang Khonthi",
    "provinceCode": "75",
    "provinceNameTh": "สมุทรสงคราม",
    "provinceNameEn": "Samut Songkhram"
},
{
    "subDistrictCode": "750211",
    "subDistrictNameTh": "ยายแพง",
    "subDistrictNameEn": "Yai Phaeng",
    "postcode": "75120",
    "districtCode": "7502",
    "districtNameTh": "บางคนที",
    "districtNameEn": "Bang Khonthi",
    "provinceCode": "75",
    "provinceNameTh": "สมุทรสงคราม",
    "provinceNameEn": "Samut Songkhram"
},
{
    "subDistrictCode": "750212",
    "subDistrictNameTh": "บางกระบือ",
    "subDistrictNameEn": "Bang Krabue",
    "postcode": "75120",
    "districtCode": "7502",
    "districtNameTh": "บางคนที",
    "districtNameEn": "Bang Khonthi",
    "provinceCode": "75",
    "provinceNameTh": "สมุทรสงคราม",
    "provinceNameEn": "Samut Songkhram"
},
{
    "subDistrictCode": "750213",
    "subDistrictNameTh": "บ้านปราโมทย์",
    "subDistrictNameEn": "Ban Pramot",
    "postcode": "75120",
    "districtCode": "7502",
    "districtNameTh": "บางคนที",
    "districtNameEn": "Bang Khonthi",
    "provinceCode": "75",
    "provinceNameTh": "สมุทรสงคราม",
    "provinceNameEn": "Samut Songkhram"
},
{
    "subDistrictCode": "750301",
    "subDistrictNameTh": "อัมพวา",
    "subDistrictNameEn": "Amphawa",
    "postcode": "75110",
    "districtCode": "7503",
    "districtNameTh": "อัมพวา",
    "districtNameEn": "Amphawa",
    "provinceCode": "75",
    "provinceNameTh": "สมุทรสงคราม",
    "provinceNameEn": "Samut Songkhram"
},
{
    "subDistrictCode": "750302",
    "subDistrictNameTh": "สวนหลวง",
    "subDistrictNameEn": "Suan Luang",
    "postcode": "75110",
    "districtCode": "7503",
    "districtNameTh": "อัมพวา",
    "districtNameEn": "Amphawa",
    "provinceCode": "75",
    "provinceNameTh": "สมุทรสงคราม",
    "provinceNameEn": "Samut Songkhram"
},
{
    "subDistrictCode": "750303",
    "subDistrictNameTh": "ท่าคา",
    "subDistrictNameEn": "Tha Kha",
    "postcode": "75110",
    "districtCode": "7503",
    "districtNameTh": "อัมพวา",
    "districtNameEn": "Amphawa",
    "provinceCode": "75",
    "provinceNameTh": "สมุทรสงคราม",
    "provinceNameEn": "Samut Songkhram"
},
{
    "subDistrictCode": "750304",
    "subDistrictNameTh": "วัดประดู่",
    "subDistrictNameEn": "Wat Pradu",
    "postcode": "75110",
    "districtCode": "7503",
    "districtNameTh": "อัมพวา",
    "districtNameEn": "Amphawa",
    "provinceCode": "75",
    "provinceNameTh": "สมุทรสงคราม",
    "provinceNameEn": "Samut Songkhram"
},
{
    "subDistrictCode": "750305",
    "subDistrictNameTh": "เหมืองใหม่",
    "subDistrictNameEn": "Mueang Mai",
    "postcode": "75110",
    "districtCode": "7503",
    "districtNameTh": "อัมพวา",
    "districtNameEn": "Amphawa",
    "provinceCode": "75",
    "provinceNameTh": "สมุทรสงคราม",
    "provinceNameEn": "Samut Songkhram"
},
{
    "subDistrictCode": "750306",
    "subDistrictNameTh": "บางช้าง",
    "subDistrictNameEn": "Bang Chang",
    "postcode": "75110",
    "districtCode": "7503",
    "districtNameTh": "อัมพวา",
    "districtNameEn": "Amphawa",
    "provinceCode": "75",
    "provinceNameTh": "สมุทรสงคราม",
    "provinceNameEn": "Samut Songkhram"
},
{
    "subDistrictCode": "750307",
    "subDistrictNameTh": "แควอ้อม",
    "subDistrictNameEn": "Khwaae Om",
    "postcode": "75110",
    "districtCode": "7503",
    "districtNameTh": "อัมพวา",
    "districtNameEn": "Amphawa",
    "provinceCode": "75",
    "provinceNameTh": "สมุทรสงคราม",
    "provinceNameEn": "Samut Songkhram"
},
{
    "subDistrictCode": "750308",
    "subDistrictNameTh": "ปลายโพงพาง",
    "subDistrictNameEn": "Plai Phongphang",
    "postcode": "75110",
    "districtCode": "7503",
    "districtNameTh": "อัมพวา",
    "districtNameEn": "Amphawa",
    "provinceCode": "75",
    "provinceNameTh": "สมุทรสงคราม",
    "provinceNameEn": "Samut Songkhram"
},
{
    "subDistrictCode": "750309",
    "subDistrictNameTh": "บางแค",
    "subDistrictNameEn": "Bang Khae",
    "postcode": "75110",
    "districtCode": "7503",
    "districtNameTh": "อัมพวา",
    "districtNameEn": "Amphawa",
    "provinceCode": "75",
    "provinceNameTh": "สมุทรสงคราม",
    "provinceNameEn": "Samut Songkhram"
},
{
    "subDistrictCode": "750310",
    "subDistrictNameTh": "แพรกหนามแดง",
    "subDistrictNameEn": "Phraek Nam Daeng",
    "postcode": "75110",
    "districtCode": "7503",
    "districtNameTh": "อัมพวา",
    "districtNameEn": "Amphawa",
    "provinceCode": "75",
    "provinceNameTh": "สมุทรสงคราม",
    "provinceNameEn": "Samut Songkhram"
},
{
    "subDistrictCode": "750311",
    "subDistrictNameTh": "ยี่สาร",
    "subDistrictNameEn": "Yi San",
    "postcode": "75110",
    "districtCode": "7503",
    "districtNameTh": "อัมพวา",
    "districtNameEn": "Amphawa",
    "provinceCode": "75",
    "provinceNameTh": "สมุทรสงคราม",
    "provinceNameEn": "Samut Songkhram"
},
{
    "subDistrictCode": "750312",
    "subDistrictNameTh": "บางนางลี่",
    "subDistrictNameEn": "Bang Nang Li",
    "postcode": "75110",
    "districtCode": "7503",
    "districtNameTh": "อัมพวา",
    "districtNameEn": "Amphawa",
    "provinceCode": "75",
    "provinceNameTh": "สมุทรสงคราม",
    "provinceNameEn": "Samut Songkhram"
},
{
    "subDistrictCode": "760101",
    "subDistrictNameTh": "ท่าราบ",
    "subDistrictNameEn": "Tha Rap",
    "postcode": "76000",
    "districtCode": "7601",
    "districtNameTh": "เมืองเพชรบุรี",
    "districtNameEn": "Mueang Phetchaburi",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760102",
    "subDistrictNameTh": "คลองกระแชง",
    "subDistrictNameEn": "Khlong Krachaeng",
    "postcode": "76000",
    "districtCode": "7601",
    "districtNameTh": "เมืองเพชรบุรี",
    "districtNameEn": "Mueang Phetchaburi",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760103",
    "subDistrictNameTh": "บางจาน",
    "subDistrictNameEn": "Bang Chan",
    "postcode": "76000",
    "districtCode": "7601",
    "districtNameTh": "เมืองเพชรบุรี",
    "districtNameEn": "Mueang Phetchaburi",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760104",
    "subDistrictNameTh": "นาพันสาม",
    "subDistrictNameEn": "Na Phan Sam",
    "postcode": "76000",
    "districtCode": "7601",
    "districtNameTh": "เมืองเพชรบุรี",
    "districtNameEn": "Mueang Phetchaburi",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760105",
    "subDistrictNameTh": "ธงชัย",
    "subDistrictNameEn": "Thong Chai",
    "postcode": "76000",
    "districtCode": "7601",
    "districtNameTh": "เมืองเพชรบุรี",
    "districtNameEn": "Mueang Phetchaburi",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760106",
    "subDistrictNameTh": "บ้านกุ่ม",
    "subDistrictNameEn": "Ban Kum",
    "postcode": "76000",
    "districtCode": "7601",
    "districtNameTh": "เมืองเพชรบุรี",
    "districtNameEn": "Mueang Phetchaburi",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760107",
    "subDistrictNameTh": "หนองโสน",
    "subDistrictNameEn": "Nong Sano",
    "postcode": "76000",
    "districtCode": "7601",
    "districtNameTh": "เมืองเพชรบุรี",
    "districtNameEn": "Mueang Phetchaburi",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760108",
    "subDistrictNameTh": "ไร่ส้ม",
    "subDistrictNameEn": "Rai Som",
    "postcode": "76000",
    "districtCode": "7601",
    "districtNameTh": "เมืองเพชรบุรี",
    "districtNameEn": "Mueang Phetchaburi",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760109",
    "subDistrictNameTh": "เวียงคอย",
    "subDistrictNameEn": "Wiang Khoi",
    "postcode": "76000",
    "districtCode": "7601",
    "districtNameTh": "เมืองเพชรบุรี",
    "districtNameEn": "Mueang Phetchaburi",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760110",
    "subDistrictNameTh": "บางจาก",
    "subDistrictNameEn": "Bang Chak",
    "postcode": "76000",
    "districtCode": "7601",
    "districtNameTh": "เมืองเพชรบุรี",
    "districtNameEn": "Mueang Phetchaburi",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760111",
    "subDistrictNameTh": "บ้านหม้อ",
    "subDistrictNameEn": "Ban Mo",
    "postcode": "76000",
    "districtCode": "7601",
    "districtNameTh": "เมืองเพชรบุรี",
    "districtNameEn": "Mueang Phetchaburi",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760112",
    "subDistrictNameTh": "ต้นมะม่วง",
    "subDistrictNameEn": "Ton Mamuang",
    "postcode": "76000",
    "districtCode": "7601",
    "districtNameTh": "เมืองเพชรบุรี",
    "districtNameEn": "Mueang Phetchaburi",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760113",
    "subDistrictNameTh": "ช่องสะแก",
    "subDistrictNameEn": "Chong Sakae",
    "postcode": "76000",
    "districtCode": "7601",
    "districtNameTh": "เมืองเพชรบุรี",
    "districtNameEn": "Mueang Phetchaburi",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760114",
    "subDistrictNameTh": "นาวุ้ง",
    "subDistrictNameEn": "Na Wung",
    "postcode": "76000",
    "districtCode": "7601",
    "districtNameTh": "เมืองเพชรบุรี",
    "districtNameEn": "Mueang Phetchaburi",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760115",
    "subDistrictNameTh": "สำมะโรง",
    "subDistrictNameEn": "Sam Marong",
    "postcode": "76000",
    "districtCode": "7601",
    "districtNameTh": "เมืองเพชรบุรี",
    "districtNameEn": "Mueang Phetchaburi",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760116",
    "subDistrictNameTh": "โพพระ",
    "subDistrictNameEn": "Pho Phra",
    "postcode": "76000",
    "districtCode": "7601",
    "districtNameTh": "เมืองเพชรบุรี",
    "districtNameEn": "Mueang Phetchaburi",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760117",
    "subDistrictNameTh": "หาดเจ้าสำราญ",
    "subDistrictNameEn": "Hat Chao Samran",
    "postcode": "76100",
    "districtCode": "7601",
    "districtNameTh": "เมืองเพชรบุรี",
    "districtNameEn": "Mueang Phetchaburi",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760118",
    "subDistrictNameTh": "หัวสะพาน",
    "subDistrictNameEn": "Hua Saphan",
    "postcode": "76000",
    "districtCode": "7601",
    "districtNameTh": "เมืองเพชรบุรี",
    "districtNameEn": "Mueang Phetchaburi",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760119",
    "subDistrictNameTh": "ต้นมะพร้าว",
    "subDistrictNameEn": "Ton Maphrao",
    "postcode": "76000",
    "districtCode": "7601",
    "districtNameTh": "เมืองเพชรบุรี",
    "districtNameEn": "Mueang Phetchaburi",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760120",
    "subDistrictNameTh": "วังตะโก",
    "subDistrictNameEn": "Wang Tako",
    "postcode": "76000",
    "districtCode": "7601",
    "districtNameTh": "เมืองเพชรบุรี",
    "districtNameEn": "Mueang Phetchaburi",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760121",
    "subDistrictNameTh": "โพไร่หวาน",
    "subDistrictNameEn": "Pho Rai Wan",
    "postcode": "76000",
    "districtCode": "7601",
    "districtNameTh": "เมืองเพชรบุรี",
    "districtNameEn": "Mueang Phetchaburi",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760122",
    "subDistrictNameTh": "ดอนยาง",
    "subDistrictNameEn": "Don Yang",
    "postcode": "76000",
    "districtCode": "7601",
    "districtNameTh": "เมืองเพชรบุรี",
    "districtNameEn": "Mueang Phetchaburi",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760123",
    "subDistrictNameTh": "หนองขนาน",
    "subDistrictNameEn": "Nong Khanan",
    "postcode": "76000",
    "districtCode": "7601",
    "districtNameTh": "เมืองเพชรบุรี",
    "districtNameEn": "Mueang Phetchaburi",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760124",
    "subDistrictNameTh": "หนองพลับ",
    "subDistrictNameEn": "Nong Phlap",
    "postcode": "76000",
    "districtCode": "7601",
    "districtNameTh": "เมืองเพชรบุรี",
    "districtNameEn": "Mueang Phetchaburi",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760201",
    "subDistrictNameTh": "เขาย้อย",
    "subDistrictNameEn": "Khao Yoi",
    "postcode": "76140",
    "districtCode": "7602",
    "districtNameTh": "เขาย้อย",
    "districtNameEn": "Khao Yoi",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760202",
    "subDistrictNameTh": "สระพัง",
    "subDistrictNameEn": "Sa Phang",
    "postcode": "76140",
    "districtCode": "7602",
    "districtNameTh": "เขาย้อย",
    "districtNameEn": "Khao Yoi",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760203",
    "subDistrictNameTh": "บางเค็ม",
    "subDistrictNameEn": "Bang Khem",
    "postcode": "76140",
    "districtCode": "7602",
    "districtNameTh": "เขาย้อย",
    "districtNameEn": "Khao Yoi",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760204",
    "subDistrictNameTh": "ทับคาง",
    "subDistrictNameEn": "Thap Khang",
    "postcode": "76140",
    "districtCode": "7602",
    "districtNameTh": "เขาย้อย",
    "districtNameEn": "Khao Yoi",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760205",
    "subDistrictNameTh": "หนองปลาไหล",
    "subDistrictNameEn": "Nong Pla Lai",
    "postcode": "76140",
    "districtCode": "7602",
    "districtNameTh": "เขาย้อย",
    "districtNameEn": "Khao Yoi",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760206",
    "subDistrictNameTh": "หนองปรง",
    "subDistrictNameEn": "Nong Prong",
    "postcode": "76140",
    "districtCode": "7602",
    "districtNameTh": "เขาย้อย",
    "districtNameEn": "Khao Yoi",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760207",
    "subDistrictNameTh": "หนองชุมพล",
    "subDistrictNameEn": "Nong Chumphon",
    "postcode": "76140",
    "districtCode": "7602",
    "districtNameTh": "เขาย้อย",
    "districtNameEn": "Khao Yoi",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760208",
    "subDistrictNameTh": "ห้วยโรง",
    "subDistrictNameEn": "Huai Rong",
    "postcode": "76140",
    "districtCode": "7602",
    "districtNameTh": "เขาย้อย",
    "districtNameEn": "Khao Yoi",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760209",
    "subDistrictNameTh": "ห้วยท่าช้าง",
    "subDistrictNameEn": "Huai Tha Chang",
    "postcode": "76140",
    "districtCode": "7602",
    "districtNameTh": "เขาย้อย",
    "districtNameEn": "Khao Yoi",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760210",
    "subDistrictNameTh": "หนองชุมพลเหนือ",
    "subDistrictNameEn": "Nong Chumphon Nuea",
    "postcode": "76140",
    "districtCode": "7602",
    "districtNameTh": "เขาย้อย",
    "districtNameEn": "Khao Yoi",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760301",
    "subDistrictNameTh": "หนองหญ้าปล้อง",
    "subDistrictNameEn": "Nong Ya Plong",
    "postcode": "76160",
    "districtCode": "7603",
    "districtNameTh": "หนองหญ้าปล้อง",
    "districtNameEn": "Nong Ya Plong",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760302",
    "subDistrictNameTh": "ยางน้ำกลัดเหนือ",
    "subDistrictNameEn": "Yang Nam Klat Nuea",
    "postcode": "76160",
    "districtCode": "7603",
    "districtNameTh": "หนองหญ้าปล้อง",
    "districtNameEn": "Nong Ya Plong",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760303",
    "subDistrictNameTh": "ยางน้ำกลัดใต้",
    "subDistrictNameEn": "Yang Nam Klat Tai",
    "postcode": "76160",
    "districtCode": "7603",
    "districtNameTh": "หนองหญ้าปล้อง",
    "districtNameEn": "Nong Ya Plong",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760304",
    "subDistrictNameTh": "ท่าตะคร้อ",
    "subDistrictNameEn": "Tha Ta Khro",
    "postcode": "76160",
    "districtCode": "7603",
    "districtNameTh": "หนองหญ้าปล้อง",
    "districtNameEn": "Nong Ya Plong",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760401",
    "subDistrictNameTh": "ชะอำ",
    "subDistrictNameEn": "Cha-am",
    "postcode": "76120",
    "districtCode": "7604",
    "districtNameTh": "ชะอำ",
    "districtNameEn": "Cha-am",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760402",
    "subDistrictNameTh": "บางเก่า",
    "subDistrictNameEn": "Bang Kao",
    "postcode": "76120",
    "districtCode": "7604",
    "districtNameTh": "ชะอำ",
    "districtNameEn": "Cha-am",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760403",
    "subDistrictNameTh": "นายาง",
    "subDistrictNameEn": "Na Yang",
    "postcode": "76120",
    "districtCode": "7604",
    "districtNameTh": "ชะอำ",
    "districtNameEn": "Cha-am",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760404",
    "subDistrictNameTh": "เขาใหญ่",
    "subDistrictNameEn": "Khao Yai",
    "postcode": "76120",
    "districtCode": "7604",
    "districtNameTh": "ชะอำ",
    "districtNameEn": "Cha-am",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760405",
    "subDistrictNameTh": "หนองศาลา",
    "subDistrictNameEn": "Nong Sala",
    "postcode": "76120",
    "districtCode": "7604",
    "districtNameTh": "ชะอำ",
    "districtNameEn": "Cha-am",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760406",
    "subDistrictNameTh": "ห้วยทรายเหนือ",
    "subDistrictNameEn": "Huai Sai Nuea",
    "postcode": "76120",
    "districtCode": "7604",
    "districtNameTh": "ชะอำ",
    "districtNameEn": "Cha-am",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760407",
    "subDistrictNameTh": "ไร่ใหม่พัฒนา",
    "subDistrictNameEn": "Rai Mai Phatthana",
    "postcode": "76120",
    "districtCode": "7604",
    "districtNameTh": "ชะอำ",
    "districtNameEn": "Cha-am",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760408",
    "subDistrictNameTh": "สามพระยา",
    "subDistrictNameEn": "Sam Phraya",
    "postcode": "76120",
    "districtCode": "7604",
    "districtNameTh": "ชะอำ",
    "districtNameEn": "Cha-am",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760409",
    "subDistrictNameTh": "ดอนขุนห้วย",
    "subDistrictNameEn": "Don Khun Huai",
    "postcode": "76120",
    "districtCode": "7604",
    "districtNameTh": "ชะอำ",
    "districtNameEn": "Cha-am",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760501",
    "subDistrictNameTh": "ท่ายาง",
    "subDistrictNameEn": "Tha Yang",
    "postcode": "76130",
    "districtCode": "7605",
    "districtNameTh": "ท่ายาง",
    "districtNameEn": "Tha Yang",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760502",
    "subDistrictNameTh": "ท่าคอย",
    "subDistrictNameEn": "Tha Khoi",
    "postcode": "76130",
    "districtCode": "7605",
    "districtNameTh": "ท่ายาง",
    "districtNameEn": "Tha Yang",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760503",
    "subDistrictNameTh": "ยางหย่อง",
    "subDistrictNameEn": "Yang Yong",
    "postcode": "76130",
    "districtCode": "7605",
    "districtNameTh": "ท่ายาง",
    "districtNameEn": "Tha Yang",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760504",
    "subDistrictNameTh": "หนองจอก",
    "subDistrictNameEn": "Nong Chok",
    "postcode": "76130",
    "districtCode": "7605",
    "districtNameTh": "ท่ายาง",
    "districtNameEn": "Tha Yang",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760505",
    "subDistrictNameTh": "มาบปลาเค้า",
    "subDistrictNameEn": "Map Pla Khao",
    "postcode": "76130",
    "districtCode": "7605",
    "districtNameTh": "ท่ายาง",
    "districtNameEn": "Tha Yang",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760506",
    "subDistrictNameTh": "ท่าไม้รวก",
    "subDistrictNameEn": "Tha Mai Ruak",
    "postcode": "76130",
    "districtCode": "7605",
    "districtNameTh": "ท่ายาง",
    "districtNameEn": "Tha Yang",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760507",
    "subDistrictNameTh": "วังไคร้",
    "subDistrictNameEn": "Wang Khrai",
    "postcode": "76130",
    "districtCode": "7605",
    "districtNameTh": "ท่ายาง",
    "districtNameEn": "Tha Yang",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760511",
    "subDistrictNameTh": "กลัดหลวง",
    "subDistrictNameEn": "Klat Luang",
    "postcode": "76130",
    "districtCode": "7605",
    "districtNameTh": "ท่ายาง",
    "districtNameEn": "Tha Yang",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760512",
    "subDistrictNameTh": "ปึกเตียน",
    "subDistrictNameEn": "Puek Tian",
    "postcode": "76130",
    "districtCode": "7605",
    "districtNameTh": "ท่ายาง",
    "districtNameEn": "Tha Yang",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760513",
    "subDistrictNameTh": "เขากระปุก",
    "subDistrictNameEn": "Khao Krapuk",
    "postcode": "76130",
    "districtCode": "7605",
    "districtNameTh": "ท่ายาง",
    "districtNameEn": "Tha Yang",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760514",
    "subDistrictNameTh": "ท่าแลง",
    "subDistrictNameEn": "Tha Laeng",
    "postcode": "76130",
    "districtCode": "7605",
    "districtNameTh": "ท่ายาง",
    "districtNameEn": "Tha Yang",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760515",
    "subDistrictNameTh": "บ้านในดง",
    "subDistrictNameEn": "Ban Nai Dong",
    "postcode": "76130",
    "districtCode": "7605",
    "districtNameTh": "ท่ายาง",
    "districtNameEn": "Tha Yang",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760601",
    "subDistrictNameTh": "บ้านลาด",
    "subDistrictNameEn": "Ban Lat",
    "postcode": "76150",
    "districtCode": "7606",
    "districtNameTh": "บ้านลาด",
    "districtNameEn": "Ban Lat",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760602",
    "subDistrictNameTh": "บ้านหาด",
    "subDistrictNameEn": "Ban Hat",
    "postcode": "76150",
    "districtCode": "7606",
    "districtNameTh": "บ้านลาด",
    "districtNameEn": "Ban Lat",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760603",
    "subDistrictNameTh": "บ้านทาน",
    "subDistrictNameEn": "Ban Than",
    "postcode": "76150",
    "districtCode": "7606",
    "districtNameTh": "บ้านลาด",
    "districtNameEn": "Ban Lat",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760604",
    "subDistrictNameTh": "ตำหรุ",
    "subDistrictNameEn": "Tamru",
    "postcode": "76150",
    "districtCode": "7606",
    "districtNameTh": "บ้านลาด",
    "districtNameEn": "Ban Lat",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760605",
    "subDistrictNameTh": "สมอพลือ",
    "subDistrictNameEn": "Samo Phlue",
    "postcode": "76150",
    "districtCode": "7606",
    "districtNameTh": "บ้านลาด",
    "districtNameEn": "Ban Lat",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760606",
    "subDistrictNameTh": "ไร่มะขาม",
    "subDistrictNameEn": "Rai Makham",
    "postcode": "76150",
    "districtCode": "7606",
    "districtNameTh": "บ้านลาด",
    "districtNameEn": "Ban Lat",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760607",
    "subDistrictNameTh": "ท่าเสน",
    "subDistrictNameEn": "Tha Sen",
    "postcode": "76150",
    "districtCode": "7606",
    "districtNameTh": "บ้านลาด",
    "districtNameEn": "Ban Lat",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760608",
    "subDistrictNameTh": "หนองกระเจ็ด",
    "subDistrictNameEn": "Nong Krachet",
    "postcode": "76150",
    "districtCode": "7606",
    "districtNameTh": "บ้านลาด",
    "districtNameEn": "Ban Lat",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760609",
    "subDistrictNameTh": "หนองกะปุ",
    "subDistrictNameEn": "Nong Kapu",
    "postcode": "76150",
    "districtCode": "7606",
    "districtNameTh": "บ้านลาด",
    "districtNameEn": "Ban Lat",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760610",
    "subDistrictNameTh": "ลาดโพธิ์",
    "subDistrictNameEn": "Lat Pho",
    "postcode": "76150",
    "districtCode": "7606",
    "districtNameTh": "บ้านลาด",
    "districtNameEn": "Ban Lat",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760611",
    "subDistrictNameTh": "สะพานไกร",
    "subDistrictNameEn": "Saphan Krai",
    "postcode": "76150",
    "districtCode": "7606",
    "districtNameTh": "บ้านลาด",
    "districtNameEn": "Ban Lat",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760612",
    "subDistrictNameTh": "ไร่โคก",
    "subDistrictNameEn": "Rai Khok",
    "postcode": "76150",
    "districtCode": "7606",
    "districtNameTh": "บ้านลาด",
    "districtNameEn": "Ban Lat",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760613",
    "subDistrictNameTh": "โรงเข้",
    "subDistrictNameEn": "Rong Khe",
    "postcode": "76150",
    "districtCode": "7606",
    "districtNameTh": "บ้านลาด",
    "districtNameEn": "Ban Lat",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760614",
    "subDistrictNameTh": "ไร่สะท้อน",
    "subDistrictNameEn": "Rai Sathon",
    "postcode": "76150",
    "districtCode": "7606",
    "districtNameTh": "บ้านลาด",
    "districtNameEn": "Ban Lat",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760615",
    "subDistrictNameTh": "ห้วยข้อง",
    "subDistrictNameEn": "Huai Khong",
    "postcode": "76150",
    "districtCode": "7606",
    "districtNameTh": "บ้านลาด",
    "districtNameEn": "Ban Lat",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760616",
    "subDistrictNameTh": "ท่าช้าง",
    "subDistrictNameEn": "Tha Chang",
    "postcode": "76150",
    "districtCode": "7606",
    "districtNameTh": "บ้านลาด",
    "districtNameEn": "Ban Lat",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760617",
    "subDistrictNameTh": "ถ้ำรงค์",
    "subDistrictNameEn": "Tham Rong",
    "postcode": "76150",
    "districtCode": "7606",
    "districtNameTh": "บ้านลาด",
    "districtNameEn": "Ban Lat",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760618",
    "subDistrictNameTh": "ห้วยลึก",
    "subDistrictNameEn": "Huai Luek",
    "postcode": "76150",
    "districtCode": "7606",
    "districtNameTh": "บ้านลาด",
    "districtNameEn": "Ban Lat",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760701",
    "subDistrictNameTh": "บ้านแหลม",
    "subDistrictNameEn": "Ban Laem",
    "postcode": "76110",
    "districtCode": "7607",
    "districtNameTh": "บ้านแหลม",
    "districtNameEn": "Ban Laem",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760702",
    "subDistrictNameTh": "บางขุนไทร",
    "subDistrictNameEn": "Bang Khun Sai",
    "postcode": "76110",
    "districtCode": "7607",
    "districtNameTh": "บ้านแหลม",
    "districtNameEn": "Ban Laem",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760703",
    "subDistrictNameTh": "ปากทะเล",
    "subDistrictNameEn": "Pak Thale",
    "postcode": "76110",
    "districtCode": "7607",
    "districtNameTh": "บ้านแหลม",
    "districtNameEn": "Ban Laem",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760704",
    "subDistrictNameTh": "บางแก้ว",
    "subDistrictNameEn": "Bang Kaeo",
    "postcode": "76110",
    "districtCode": "7607",
    "districtNameTh": "บ้านแหลม",
    "districtNameEn": "Ban Laem",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760705",
    "subDistrictNameTh": "แหลมผักเบี้ย",
    "subDistrictNameEn": "Laem Phak Bia",
    "postcode": "76100",
    "districtCode": "7607",
    "districtNameTh": "บ้านแหลม",
    "districtNameEn": "Ban Laem",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760706",
    "subDistrictNameTh": "บางตะบูน",
    "subDistrictNameEn": "Bang Tabun",
    "postcode": "76110",
    "districtCode": "7607",
    "districtNameTh": "บ้านแหลม",
    "districtNameEn": "Ban Laem",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760707",
    "subDistrictNameTh": "บางตะบูนออก",
    "subDistrictNameEn": "Bang Tabun Ok",
    "postcode": "76110",
    "districtCode": "7607",
    "districtNameTh": "บ้านแหลม",
    "districtNameEn": "Ban Laem",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760708",
    "subDistrictNameTh": "บางครก",
    "subDistrictNameEn": "Bang Khrok",
    "postcode": "76110",
    "districtCode": "7607",
    "districtNameTh": "บ้านแหลม",
    "districtNameEn": "Ban Laem",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760709",
    "subDistrictNameTh": "ท่าแร้ง",
    "subDistrictNameEn": "Tha Raeng",
    "postcode": "76110",
    "districtCode": "7607",
    "districtNameTh": "บ้านแหลม",
    "districtNameEn": "Ban Laem",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760710",
    "subDistrictNameTh": "ท่าแร้งออก",
    "subDistrictNameEn": "Tha Raeng Ok",
    "postcode": "76110",
    "districtCode": "7607",
    "districtNameTh": "บ้านแหลม",
    "districtNameEn": "Ban Laem",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760801",
    "subDistrictNameTh": "แก่งกระจาน",
    "subDistrictNameEn": "Kaeng Krachan",
    "postcode": "76170",
    "districtCode": "7608",
    "districtNameTh": "แก่งกระจาน",
    "districtNameEn": "Kaeng Krachan",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760802",
    "subDistrictNameTh": "สองพี่น้อง",
    "subDistrictNameEn": "Song Phi Nong",
    "postcode": "76170",
    "districtCode": "7608",
    "districtNameTh": "แก่งกระจาน",
    "districtNameEn": "Kaeng Krachan",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760803",
    "subDistrictNameTh": "วังจันทร์",
    "subDistrictNameEn": "Wang Chan",
    "postcode": "76170",
    "districtCode": "7608",
    "districtNameTh": "แก่งกระจาน",
    "districtNameEn": "Kaeng Krachan",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760804",
    "subDistrictNameTh": "ป่าเด็ง",
    "subDistrictNameEn": "Pa Deng",
    "postcode": "76170",
    "districtCode": "7608",
    "districtNameTh": "แก่งกระจาน",
    "districtNameEn": "Kaeng Krachan",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760805",
    "subDistrictNameTh": "พุสวรรค์",
    "subDistrictNameEn": "Phu Sawan",
    "postcode": "76170",
    "districtCode": "7608",
    "districtNameTh": "แก่งกระจาน",
    "districtNameEn": "Kaeng Krachan",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "760806",
    "subDistrictNameTh": "ห้วยแม่เพรียง",
    "subDistrictNameEn": "Huai Mae Phriang",
    "postcode": "76170",
    "districtCode": "7608",
    "districtNameTh": "แก่งกระจาน",
    "districtNameEn": "Kaeng Krachan",
    "provinceCode": "76",
    "provinceNameTh": "เพชรบุรี",
    "provinceNameEn": "Phetchaburi"
},
{
    "subDistrictCode": "770101",
    "subDistrictNameTh": "ประจวบคีรีขันธ์",
    "subDistrictNameEn": "Prachuap Khiri Khan",
    "postcode": "77000",
    "districtCode": "7701",
    "districtNameTh": "เมืองประจวบคีรีขันธ์",
    "districtNameEn": "Mueang Prachuap Khiri Khan",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770102",
    "subDistrictNameTh": "เกาะหลัก",
    "subDistrictNameEn": "Ko Lak",
    "postcode": "77000",
    "districtCode": "7701",
    "districtNameTh": "เมืองประจวบคีรีขันธ์",
    "districtNameEn": "Mueang Prachuap Khiri Khan",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770103",
    "subDistrictNameTh": "คลองวาฬ",
    "subDistrictNameEn": "Khlong Wan",
    "postcode": "77000",
    "districtCode": "7701",
    "districtNameTh": "เมืองประจวบคีรีขันธ์",
    "districtNameEn": "Mueang Prachuap Khiri Khan",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770104",
    "subDistrictNameTh": "ห้วยทราย",
    "subDistrictNameEn": "Huai Sai",
    "postcode": "77000",
    "districtCode": "7701",
    "districtNameTh": "เมืองประจวบคีรีขันธ์",
    "districtNameEn": "Mueang Prachuap Khiri Khan",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770105",
    "subDistrictNameTh": "อ่าวน้อย",
    "subDistrictNameEn": "Ao Noi",
    "postcode": "77210",
    "districtCode": "7701",
    "districtNameTh": "เมืองประจวบคีรีขันธ์",
    "districtNameEn": "Mueang Prachuap Khiri Khan",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770106",
    "subDistrictNameTh": "บ่อนอก",
    "subDistrictNameEn": "Bo Nok",
    "postcode": "77210",
    "districtCode": "7701",
    "districtNameTh": "เมืองประจวบคีรีขันธ์",
    "districtNameEn": "Mueang Prachuap Khiri Khan",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770201",
    "subDistrictNameTh": "กุยบุรี",
    "subDistrictNameEn": "Kui Buri",
    "postcode": "77150",
    "districtCode": "7702",
    "districtNameTh": "กุยบุรี",
    "districtNameEn": "Kui Buri",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770202",
    "subDistrictNameTh": "กุยเหนือ",
    "subDistrictNameEn": "Kui Nuea",
    "postcode": "77150",
    "districtCode": "7702",
    "districtNameTh": "กุยบุรี",
    "districtNameEn": "Kui Buri",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770203",
    "subDistrictNameTh": "เขาแดง",
    "subDistrictNameEn": "Khao Daeng",
    "postcode": "77150",
    "districtCode": "7702",
    "districtNameTh": "กุยบุรี",
    "districtNameEn": "Kui Buri",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770204",
    "subDistrictNameTh": "ดอนยายหนู",
    "subDistrictNameEn": "Don Yai Nu",
    "postcode": "77150",
    "districtCode": "7702",
    "districtNameTh": "กุยบุรี",
    "districtNameEn": "Kui Buri",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770206",
    "subDistrictNameTh": "สามกระทาย",
    "subDistrictNameEn": "Sam Krathai",
    "postcode": "77150",
    "districtCode": "7702",
    "districtNameTh": "กุยบุรี",
    "districtNameEn": "Kui Buri",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770207",
    "subDistrictNameTh": "หาดขาม",
    "subDistrictNameEn": "Hat Kham",
    "postcode": "77150",
    "districtCode": "7702",
    "districtNameTh": "กุยบุรี",
    "districtNameEn": "Kui Buri",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770301",
    "subDistrictNameTh": "ทับสะแก",
    "subDistrictNameEn": "Thap Sakae",
    "postcode": "77130",
    "districtCode": "7703",
    "districtNameTh": "ทับสะแก",
    "districtNameEn": "Thap Sakae",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770302",
    "subDistrictNameTh": "อ่างทอง",
    "subDistrictNameEn": "Ang Thong",
    "postcode": "77130",
    "districtCode": "7703",
    "districtNameTh": "ทับสะแก",
    "districtNameEn": "Thap Sakae",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770303",
    "subDistrictNameTh": "นาหูกวาง",
    "subDistrictNameEn": "Na Hu Kwang",
    "postcode": "77130",
    "districtCode": "7703",
    "districtNameTh": "ทับสะแก",
    "districtNameEn": "Thap Sakae",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770304",
    "subDistrictNameTh": "เขาล้าน",
    "subDistrictNameEn": "Khao Lan",
    "postcode": "77130",
    "districtCode": "7703",
    "districtNameTh": "ทับสะแก",
    "districtNameEn": "Thap Sakae",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770305",
    "subDistrictNameTh": "ห้วยยาง",
    "subDistrictNameEn": "Huai Yang",
    "postcode": "77130",
    "districtCode": "7703",
    "districtNameTh": "ทับสะแก",
    "districtNameEn": "Thap Sakae",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770306",
    "subDistrictNameTh": "แสงอรุณ",
    "subDistrictNameEn": "Saeng Arun",
    "postcode": "77130",
    "districtCode": "7703",
    "districtNameTh": "ทับสะแก",
    "districtNameEn": "Thap Sakae",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770401",
    "subDistrictNameTh": "กำเนิดนพคุณ",
    "subDistrictNameEn": "Kamnoet Nopphakhun",
    "postcode": "77140",
    "districtCode": "7704",
    "districtNameTh": "บางสะพาน",
    "districtNameEn": "Bang Saphan",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770402",
    "subDistrictNameTh": "พงศ์ประศาสน์",
    "subDistrictNameEn": "Phong Prasat",
    "postcode": "77140",
    "districtCode": "7704",
    "districtNameTh": "บางสะพาน",
    "districtNameEn": "Bang Saphan",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770403",
    "subDistrictNameTh": "ร่อนทอง",
    "subDistrictNameEn": "Ron Thong",
    "postcode": "77230",
    "districtCode": "7704",
    "districtNameTh": "บางสะพาน",
    "districtNameEn": "Bang Saphan",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770404",
    "subDistrictNameTh": "ธงชัย",
    "subDistrictNameEn": "Thong Chai",
    "postcode": "77190",
    "districtCode": "7704",
    "districtNameTh": "บางสะพาน",
    "districtNameEn": "Bang Saphan",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770405",
    "subDistrictNameTh": "ชัยเกษม",
    "subDistrictNameEn": "Chai Kasem",
    "postcode": "77190",
    "districtCode": "7704",
    "districtNameTh": "บางสะพาน",
    "districtNameEn": "Bang Saphan",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770406",
    "subDistrictNameTh": "ทองมงคล",
    "subDistrictNameEn": "Thong Mongkhon",
    "postcode": "77230",
    "districtCode": "7704",
    "districtNameTh": "บางสะพาน",
    "districtNameEn": "Bang Saphan",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770407",
    "subDistrictNameTh": "แม่รำพึง",
    "subDistrictNameEn": "Mae Ramphueng",
    "postcode": "77140",
    "districtCode": "7704",
    "districtNameTh": "บางสะพาน",
    "districtNameEn": "Bang Saphan",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770501",
    "subDistrictNameTh": "ปากแพรก",
    "subDistrictNameEn": "Pak Phraek",
    "postcode": "77170",
    "districtCode": "7705",
    "districtNameTh": "บางสะพานน้อย",
    "districtNameEn": "Bang Saphan Noi",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770502",
    "subDistrictNameTh": "บางสะพาน",
    "subDistrictNameEn": "Bang Saphan",
    "postcode": "77170",
    "districtCode": "7705",
    "districtNameTh": "บางสะพานน้อย",
    "districtNameEn": "Bang Saphan Noi",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770503",
    "subDistrictNameTh": "ทรายทอง",
    "subDistrictNameEn": "Sai Thong",
    "postcode": "77170",
    "districtCode": "7705",
    "districtNameTh": "บางสะพานน้อย",
    "districtNameEn": "Bang Saphan Noi",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770504",
    "subDistrictNameTh": "ช้างแรก",
    "subDistrictNameEn": "Chang Raek",
    "postcode": "77170",
    "districtCode": "7705",
    "districtNameTh": "บางสะพานน้อย",
    "districtNameEn": "Bang Saphan Noi",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770505",
    "subDistrictNameTh": "ไชยราช",
    "subDistrictNameEn": "Chai Rat",
    "postcode": "77170",
    "districtCode": "7705",
    "districtNameTh": "บางสะพานน้อย",
    "districtNameEn": "Bang Saphan Noi",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770601",
    "subDistrictNameTh": "ปราณบุรี",
    "subDistrictNameEn": "Pran Buri",
    "postcode": "77120",
    "districtCode": "7706",
    "districtNameTh": "ปราณบุรี",
    "districtNameEn": "Pran Buri",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770602",
    "subDistrictNameTh": "เขาน้อย",
    "subDistrictNameEn": "Khao Noi",
    "postcode": "77120",
    "districtCode": "7706",
    "districtNameTh": "ปราณบุรี",
    "districtNameEn": "Pran Buri",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770604",
    "subDistrictNameTh": "ปากน้ำปราณ",
    "subDistrictNameEn": "Pak Nam Pran",
    "postcode": "77220",
    "districtCode": "7706",
    "districtNameTh": "ปราณบุรี",
    "districtNameEn": "Pran Buri",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770607",
    "subDistrictNameTh": "หนองตาแต้ม",
    "subDistrictNameEn": "Nong Ta Taem",
    "postcode": "77120",
    "districtCode": "7706",
    "districtNameTh": "ปราณบุรี",
    "districtNameEn": "Pran Buri",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770608",
    "subDistrictNameTh": "วังก์พง",
    "subDistrictNameEn": "Wang Phong",
    "postcode": "77120",
    "districtCode": "7706",
    "districtNameTh": "ปราณบุรี",
    "districtNameEn": "Pran Buri",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770609",
    "subDistrictNameTh": "เขาจ้าว",
    "subDistrictNameEn": "Khao Chao",
    "postcode": "77120",
    "districtCode": "7706",
    "districtNameTh": "ปราณบุรี",
    "districtNameEn": "Pran Buri",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770701",
    "subDistrictNameTh": "หัวหิน",
    "subDistrictNameEn": "Hua Hin",
    "postcode": "77110",
    "districtCode": "7707",
    "districtNameTh": "หัวหิน",
    "districtNameEn": "Hua Hin",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770702",
    "subDistrictNameTh": "หนองแก",
    "subDistrictNameEn": "Nong Kae",
    "postcode": "77110",
    "districtCode": "7707",
    "districtNameTh": "หัวหิน",
    "districtNameEn": "Hua Hin",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770703",
    "subDistrictNameTh": "หินเหล็กไฟ",
    "subDistrictNameEn": "Hin Lek Fai",
    "postcode": "77110",
    "districtCode": "7707",
    "districtNameTh": "หัวหิน",
    "districtNameEn": "Hua Hin",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770704",
    "subDistrictNameTh": "หนองพลับ",
    "subDistrictNameEn": "Nong Phlap",
    "postcode": "77110",
    "districtCode": "7707",
    "districtNameTh": "หัวหิน",
    "districtNameEn": "Hua Hin",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770705",
    "subDistrictNameTh": "ทับใต้",
    "subDistrictNameEn": "Thap Tai",
    "postcode": "77110",
    "districtCode": "7707",
    "districtNameTh": "หัวหิน",
    "districtNameEn": "Hua Hin",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770706",
    "subDistrictNameTh": "ห้วยสัตว์ใหญ่",
    "subDistrictNameEn": "Huai Sat Yai",
    "postcode": "77110",
    "districtCode": "7707",
    "districtNameTh": "หัวหิน",
    "districtNameEn": "Hua Hin",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770707",
    "subDistrictNameTh": "บึงนคร",
    "subDistrictNameEn": "Bueng Nakhon",
    "postcode": "77110",
    "districtCode": "7707",
    "districtNameTh": "หัวหิน",
    "districtNameEn": "Hua Hin",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770801",
    "subDistrictNameTh": "สามร้อยยอด",
    "subDistrictNameEn": "Sam Roi Yot",
    "postcode": "77120",
    "districtCode": "7708",
    "districtNameTh": "สามร้อยยอด",
    "districtNameEn": "Sam Roi Yot",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770802",
    "subDistrictNameTh": "ศิลาลอย",
    "subDistrictNameEn": "Sila Loi",
    "postcode": "77180",
    "districtCode": "7708",
    "districtNameTh": "สามร้อยยอด",
    "districtNameEn": "Sam Roi Yot",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770803",
    "subDistrictNameTh": "ไร่เก่า",
    "subDistrictNameEn": "Rai Kao",
    "postcode": "77180",
    "districtCode": "7708",
    "districtNameTh": "สามร้อยยอด",
    "districtNameEn": "Sam Roi Yot",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770804",
    "subDistrictNameTh": "ศาลาลัย",
    "subDistrictNameEn": "Salalai",
    "postcode": "77180",
    "districtCode": "7708",
    "districtNameTh": "สามร้อยยอด",
    "districtNameEn": "Sam Roi Yot",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "770805",
    "subDistrictNameTh": "ไร่ใหม่",
    "subDistrictNameEn": "Rai Mai",
    "postcode": "77180",
    "districtCode": "7708",
    "districtNameTh": "สามร้อยยอด",
    "districtNameEn": "Sam Roi Yot",
    "provinceCode": "77",
    "provinceNameTh": "ประจวบคีรีขันธ์",
    "provinceNameEn": "Prachuap Khiri Khan"
},
{
    "subDistrictCode": "800101",
    "subDistrictNameTh": "ในเมือง",
    "subDistrictNameEn": "Nai Mueang",
    "postcode": "80000",
    "districtCode": "8001",
    "districtNameTh": "เมืองนครศรีธรรมราช",
    "districtNameEn": "Mueang Nakhon Si Thammarat",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800102",
    "subDistrictNameTh": "ท่าวัง",
    "subDistrictNameEn": "Tha Wang",
    "postcode": "80000",
    "districtCode": "8001",
    "districtNameTh": "เมืองนครศรีธรรมราช",
    "districtNameEn": "Mueang Nakhon Si Thammarat",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800103",
    "subDistrictNameTh": "คลัง",
    "subDistrictNameEn": "Khlang",
    "postcode": "80000",
    "districtCode": "8001",
    "districtNameTh": "เมืองนครศรีธรรมราช",
    "districtNameEn": "Mueang Nakhon Si Thammarat",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800106",
    "subDistrictNameTh": "ท่าไร่",
    "subDistrictNameEn": "Tha Rai",
    "postcode": "80000",
    "districtCode": "8001",
    "districtNameTh": "เมืองนครศรีธรรมราช",
    "districtNameEn": "Mueang Nakhon Si Thammarat",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800107",
    "subDistrictNameTh": "ปากนคร",
    "subDistrictNameEn": "Pak Nakhon",
    "postcode": "80000",
    "districtCode": "8001",
    "districtNameTh": "เมืองนครศรีธรรมราช",
    "districtNameEn": "Mueang Nakhon Si Thammarat",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800108",
    "subDistrictNameTh": "นาทราย",
    "subDistrictNameEn": "Na Sai",
    "postcode": "80280",
    "districtCode": "8001",
    "districtNameTh": "เมืองนครศรีธรรมราช",
    "districtNameEn": "Mueang Nakhon Si Thammarat",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800112",
    "subDistrictNameTh": "กำแพงเซา",
    "subDistrictNameEn": "Kamphaeng Sao",
    "postcode": "80280",
    "districtCode": "8001",
    "districtNameTh": "เมืองนครศรีธรรมราช",
    "districtNameEn": "Mueang Nakhon Si Thammarat",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800113",
    "subDistrictNameTh": "ไชยมนตรี",
    "subDistrictNameEn": "Chai Montri",
    "postcode": "80000",
    "districtCode": "8001",
    "districtNameTh": "เมืองนครศรีธรรมราช",
    "districtNameEn": "Mueang Nakhon Si Thammarat",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800114",
    "subDistrictNameTh": "มะม่วงสองต้น",
    "subDistrictNameEn": "Mamuang Song Ton",
    "postcode": "80000",
    "districtCode": "8001",
    "districtNameTh": "เมืองนครศรีธรรมราช",
    "districtNameEn": "Mueang Nakhon Si Thammarat",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800115",
    "subDistrictNameTh": "นาเคียน",
    "subDistrictNameEn": "Na Khian",
    "postcode": "80000",
    "districtCode": "8001",
    "districtNameTh": "เมืองนครศรีธรรมราช",
    "districtNameEn": "Mueang Nakhon Si Thammarat",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800116",
    "subDistrictNameTh": "ท่างิ้ว",
    "subDistrictNameEn": "Tha Ngio",
    "postcode": "80280",
    "districtCode": "8001",
    "districtNameTh": "เมืองนครศรีธรรมราช",
    "districtNameEn": "Mueang Nakhon Si Thammarat",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800118",
    "subDistrictNameTh": "โพธิ์เสด็จ",
    "subDistrictNameEn": "Pho Sadet",
    "postcode": "80000",
    "districtCode": "8001",
    "districtNameTh": "เมืองนครศรีธรรมราช",
    "districtNameEn": "Mueang Nakhon Si Thammarat",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800119",
    "subDistrictNameTh": "บางจาก",
    "subDistrictNameEn": "Bang Chak",
    "postcode": "80330",
    "districtCode": "8001",
    "districtNameTh": "เมืองนครศรีธรรมราช",
    "districtNameEn": "Mueang Nakhon Si Thammarat",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800120",
    "subDistrictNameTh": "ปากพูน",
    "subDistrictNameEn": "Pak Phun",
    "postcode": "80000",
    "districtCode": "8001",
    "districtNameTh": "เมืองนครศรีธรรมราช",
    "districtNameEn": "Mueang Nakhon Si Thammarat",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800121",
    "subDistrictNameTh": "ท่าซัก",
    "subDistrictNameEn": "Tha Sak",
    "postcode": "80000",
    "districtCode": "8001",
    "districtNameTh": "เมืองนครศรีธรรมราช",
    "districtNameEn": "Mueang Nakhon Si Thammarat",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800122",
    "subDistrictNameTh": "ท่าเรือ",
    "subDistrictNameEn": "Tha Ruea",
    "postcode": "80000",
    "districtCode": "8001",
    "districtNameTh": "เมืองนครศรีธรรมราช",
    "districtNameEn": "Mueang Nakhon Si Thammarat",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800201",
    "subDistrictNameTh": "พรหมโลก",
    "subDistrictNameEn": "Phrommalok",
    "postcode": "80320",
    "districtCode": "8002",
    "districtNameTh": "พรหมคีรี",
    "districtNameEn": "Phrom Khiri",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800202",
    "subDistrictNameTh": "บ้านเกาะ",
    "subDistrictNameEn": "Ban Ko",
    "postcode": "80320",
    "districtCode": "8002",
    "districtNameTh": "พรหมคีรี",
    "districtNameEn": "Phrom Khiri",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800203",
    "subDistrictNameTh": "อินคีรี",
    "subDistrictNameEn": "In Khiri",
    "postcode": "80320",
    "districtCode": "8002",
    "districtNameTh": "พรหมคีรี",
    "districtNameEn": "Phrom Khiri",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800204",
    "subDistrictNameTh": "ทอนหงส์",
    "subDistrictNameEn": "Thon Hong",
    "postcode": "80320",
    "districtCode": "8002",
    "districtNameTh": "พรหมคีรี",
    "districtNameEn": "Phrom Khiri",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800205",
    "subDistrictNameTh": "นาเรียง",
    "subDistrictNameEn": "Na Riang",
    "postcode": "80320",
    "districtCode": "8002",
    "districtNameTh": "พรหมคีรี",
    "districtNameEn": "Phrom Khiri",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800301",
    "subDistrictNameTh": "เขาแก้ว",
    "subDistrictNameEn": "Khao Kaeo",
    "postcode": "80230",
    "districtCode": "8003",
    "districtNameTh": "ลานสกา",
    "districtNameEn": "Lan Saka",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800302",
    "subDistrictNameTh": "ลานสกา",
    "subDistrictNameEn": "Lan Saka",
    "postcode": "80230",
    "districtCode": "8003",
    "districtNameTh": "ลานสกา",
    "districtNameEn": "Lan Saka",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800303",
    "subDistrictNameTh": "ท่าดี",
    "subDistrictNameEn": "Tha Di",
    "postcode": "80230",
    "districtCode": "8003",
    "districtNameTh": "ลานสกา",
    "districtNameEn": "Lan Saka",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800304",
    "subDistrictNameTh": "กำโลน",
    "subDistrictNameEn": "Kamlon",
    "postcode": "80230",
    "districtCode": "8003",
    "districtNameTh": "ลานสกา",
    "districtNameEn": "Lan Saka",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800305",
    "subDistrictNameTh": "ขุนทะเล",
    "subDistrictNameEn": "Khun Thale",
    "postcode": "80230",
    "districtCode": "8003",
    "districtNameTh": "ลานสกา",
    "districtNameEn": "Lan Saka",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800401",
    "subDistrictNameTh": "ฉวาง",
    "subDistrictNameEn": "Chawang",
    "postcode": "80150",
    "districtCode": "8004",
    "districtNameTh": "ฉวาง",
    "districtNameEn": "Chawang",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800403",
    "subDistrictNameTh": "ละอาย",
    "subDistrictNameEn": "La-ai",
    "postcode": "80250",
    "districtCode": "8004",
    "districtNameTh": "ฉวาง",
    "districtNameEn": "Chawang",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800404",
    "subDistrictNameTh": "นาแว",
    "subDistrictNameEn": "Na Wae",
    "postcode": "80260",
    "districtCode": "8004",
    "districtNameTh": "ฉวาง",
    "districtNameEn": "Chawang",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800405",
    "subDistrictNameTh": "ไม้เรียง",
    "subDistrictNameEn": "Mai Riang",
    "postcode": "80260",
    "districtCode": "8004",
    "districtNameTh": "ฉวาง",
    "districtNameEn": "Chawang",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800406",
    "subDistrictNameTh": "กะเปียด",
    "subDistrictNameEn": "Kapiat",
    "postcode": "80260",
    "districtCode": "8004",
    "districtNameTh": "ฉวาง",
    "districtNameEn": "Chawang",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800407",
    "subDistrictNameTh": "นากะชะ",
    "subDistrictNameEn": "Na Kacha",
    "postcode": "80260",
    "districtCode": "8004",
    "districtNameTh": "ฉวาง",
    "districtNameEn": "Chawang",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800409",
    "subDistrictNameTh": "ห้วยปริก",
    "subDistrictNameEn": "Huai Prik",
    "postcode": "80260",
    "districtCode": "8004",
    "districtNameTh": "ฉวาง",
    "districtNameEn": "Chawang",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800410",
    "subDistrictNameTh": "ไสหร้า",
    "subDistrictNameEn": "Sai Ra",
    "postcode": "80150",
    "districtCode": "8004",
    "districtNameTh": "ฉวาง",
    "districtNameEn": "Chawang",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800415",
    "subDistrictNameTh": "นาเขลียง",
    "subDistrictNameEn": "Na Khliang",
    "postcode": "80260",
    "districtCode": "8004",
    "districtNameTh": "ฉวาง",
    "districtNameEn": "Chawang",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800416",
    "subDistrictNameTh": "จันดี",
    "subDistrictNameEn": "Chan Di",
    "postcode": "80250",
    "districtCode": "8004",
    "districtNameTh": "ฉวาง",
    "districtNameEn": "Chawang",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800501",
    "subDistrictNameTh": "พิปูน",
    "subDistrictNameEn": "Phipun",
    "postcode": "80270",
    "districtCode": "8005",
    "districtNameTh": "พิปูน",
    "districtNameEn": "Phipun",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800502",
    "subDistrictNameTh": "กะทูน",
    "subDistrictNameEn": "Kathun",
    "postcode": "80270",
    "districtCode": "8005",
    "districtNameTh": "พิปูน",
    "districtNameEn": "Phipun",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800503",
    "subDistrictNameTh": "เขาพระ",
    "subDistrictNameEn": "Khao Phra",
    "postcode": "80270",
    "districtCode": "8005",
    "districtNameTh": "พิปูน",
    "districtNameEn": "Phipun",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800504",
    "subDistrictNameTh": "ยางค้อม",
    "subDistrictNameEn": "Yang Khom",
    "postcode": "80270",
    "districtCode": "8005",
    "districtNameTh": "พิปูน",
    "districtNameEn": "Phipun",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800505",
    "subDistrictNameTh": "ควนกลาง",
    "subDistrictNameEn": "Khuan Klang",
    "postcode": "80270",
    "districtCode": "8005",
    "districtNameTh": "พิปูน",
    "districtNameEn": "Phipun",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800601",
    "subDistrictNameTh": "เชียรใหญ่",
    "subDistrictNameEn": "Chian Yai",
    "postcode": "80190",
    "districtCode": "8006",
    "districtNameTh": "เชียรใหญ่",
    "districtNameEn": "Chian Yai",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800603",
    "subDistrictNameTh": "ท่าขนาน",
    "subDistrictNameEn": "Tha Khanan",
    "postcode": "80190",
    "districtCode": "8006",
    "districtNameTh": "เชียรใหญ่",
    "districtNameEn": "Chian Yai",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800604",
    "subDistrictNameTh": "บ้านกลาง",
    "subDistrictNameEn": "Ban Klang",
    "postcode": "80190",
    "districtCode": "8006",
    "districtNameTh": "เชียรใหญ่",
    "districtNameEn": "Chian Yai",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800605",
    "subDistrictNameTh": "บ้านเนิน",
    "subDistrictNameEn": "Ban Noen",
    "postcode": "80190",
    "districtCode": "8006",
    "districtNameTh": "เชียรใหญ่",
    "districtNameEn": "Chian Yai",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800606",
    "subDistrictNameTh": "ไสหมาก",
    "subDistrictNameEn": "Sai Mak",
    "postcode": "80190",
    "districtCode": "8006",
    "districtNameTh": "เชียรใหญ่",
    "districtNameEn": "Chian Yai",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800607",
    "subDistrictNameTh": "ท้องลำเจียก",
    "subDistrictNameEn": "Thong Lamchiak",
    "postcode": "80190",
    "districtCode": "8006",
    "districtNameTh": "เชียรใหญ่",
    "districtNameEn": "Chian Yai",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800610",
    "subDistrictNameTh": "เสือหึง",
    "subDistrictNameEn": "Suea Hueng",
    "postcode": "80190",
    "districtCode": "8006",
    "districtNameTh": "เชียรใหญ่",
    "districtNameEn": "Chian Yai",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800611",
    "subDistrictNameTh": "การะเกด",
    "subDistrictNameEn": "Karaket",
    "postcode": "80190",
    "districtCode": "8006",
    "districtNameTh": "เชียรใหญ่",
    "districtNameEn": "Chian Yai",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800612",
    "subDistrictNameTh": "เขาพระบาท",
    "subDistrictNameEn": "Khao Phra Bat",
    "postcode": "80190",
    "districtCode": "8006",
    "districtNameTh": "เชียรใหญ่",
    "districtNameEn": "Chian Yai",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800613",
    "subDistrictNameTh": "แม่เจ้าอยู่หัว",
    "subDistrictNameEn": "Mae chao Yu Hua",
    "postcode": "80190",
    "districtCode": "8006",
    "districtNameTh": "เชียรใหญ่",
    "districtNameEn": "Chian Yai",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800701",
    "subDistrictNameTh": "ชะอวด",
    "subDistrictNameEn": "Cha-uat",
    "postcode": "80180",
    "districtCode": "8007",
    "districtNameTh": "ชะอวด",
    "districtNameEn": "Cha-uat",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800702",
    "subDistrictNameTh": "ท่าเสม็ด",
    "subDistrictNameEn": "Tha Samet",
    "postcode": "80180",
    "districtCode": "8007",
    "districtNameTh": "ชะอวด",
    "districtNameEn": "Cha-uat",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800703",
    "subDistrictNameTh": "ท่าประจะ",
    "subDistrictNameEn": "Tha Pracha",
    "postcode": "80180",
    "districtCode": "8007",
    "districtNameTh": "ชะอวด",
    "districtNameEn": "Cha-uat",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800704",
    "subDistrictNameTh": "เคร็ง",
    "subDistrictNameEn": "Khreng",
    "postcode": "80180",
    "districtCode": "8007",
    "districtNameTh": "ชะอวด",
    "districtNameEn": "Cha-uat",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800705",
    "subDistrictNameTh": "วังอ่าง",
    "subDistrictNameEn": "Wang Ang",
    "postcode": "80180",
    "districtCode": "8007",
    "districtNameTh": "ชะอวด",
    "districtNameEn": "Cha-uat",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800706",
    "subDistrictNameTh": "บ้านตูล",
    "subDistrictNameEn": "Ban Tun",
    "postcode": "80180",
    "districtCode": "8007",
    "districtNameTh": "ชะอวด",
    "districtNameEn": "Cha-uat",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800707",
    "subDistrictNameTh": "ขอนหาด",
    "subDistrictNameEn": "Khon Hat",
    "postcode": "80180",
    "districtCode": "8007",
    "districtNameTh": "ชะอวด",
    "districtNameEn": "Cha-uat",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800708",
    "subDistrictNameTh": "เกาะขันธ์",
    "subDistrictNameEn": "Ko Khan",
    "postcode": "80180",
    "districtCode": "8007",
    "districtNameTh": "ชะอวด",
    "districtNameEn": "Cha-uat",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800709",
    "subDistrictNameTh": "ควนหนองหงษ์",
    "subDistrictNameEn": "Khuan Nong Hong",
    "postcode": "80180",
    "districtCode": "8007",
    "districtNameTh": "ชะอวด",
    "districtNameEn": "Cha-uat",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800710",
    "subDistrictNameTh": "เขาพระทอง",
    "subDistrictNameEn": "Khao Phra Thong",
    "postcode": "80180",
    "districtCode": "8007",
    "districtNameTh": "ชะอวด",
    "districtNameEn": "Cha-uat",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800711",
    "subDistrictNameTh": "นางหลง",
    "subDistrictNameEn": "Nong Long",
    "postcode": "80180",
    "districtCode": "8007",
    "districtNameTh": "ชะอวด",
    "districtNameEn": "Cha-uat",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800801",
    "subDistrictNameTh": "ท่าศาลา",
    "subDistrictNameEn": "Tha Sala",
    "postcode": "80160",
    "districtCode": "8008",
    "districtNameTh": "ท่าศาลา",
    "districtNameEn": "Tha Sala",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800802",
    "subDistrictNameTh": "กลาย",
    "subDistrictNameEn": "Klai",
    "postcode": "80160",
    "districtCode": "8008",
    "districtNameTh": "ท่าศาลา",
    "districtNameEn": "Tha Sala",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800803",
    "subDistrictNameTh": "ท่าขึ้น",
    "subDistrictNameEn": "Tha Khuen",
    "postcode": "80160",
    "districtCode": "8008",
    "districtNameTh": "ท่าศาลา",
    "districtNameEn": "Tha Sala",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800804",
    "subDistrictNameTh": "หัวตะพาน",
    "subDistrictNameEn": "Hua Taphan",
    "postcode": "80160",
    "districtCode": "8008",
    "districtNameTh": "ท่าศาลา",
    "districtNameEn": "Tha Sala",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800806",
    "subDistrictNameTh": "สระแก้ว",
    "subDistrictNameEn": "Sa Kaeo",
    "postcode": "80160",
    "districtCode": "8008",
    "districtNameTh": "ท่าศาลา",
    "districtNameEn": "Tha Sala",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800807",
    "subDistrictNameTh": "โมคลาน",
    "subDistrictNameEn": "Mokkhalan",
    "postcode": "80160",
    "districtCode": "8008",
    "districtNameTh": "ท่าศาลา",
    "districtNameEn": "Tha Sala",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800809",
    "subDistrictNameTh": "ไทยบุรี",
    "subDistrictNameEn": "Thai Buri",
    "postcode": "80160",
    "districtCode": "8008",
    "districtNameTh": "ท่าศาลา",
    "districtNameEn": "Tha Sala",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800810",
    "subDistrictNameTh": "ดอนตะโก",
    "subDistrictNameEn": "Don Tako",
    "postcode": "80160",
    "districtCode": "8008",
    "districtNameTh": "ท่าศาลา",
    "districtNameEn": "Tha Sala",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800811",
    "subDistrictNameTh": "ตลิ่งชัน",
    "subDistrictNameEn": "Taling Chan",
    "postcode": "80160",
    "districtCode": "8008",
    "districtNameTh": "ท่าศาลา",
    "districtNameEn": "Tha Sala",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800813",
    "subDistrictNameTh": "โพธิ์ทอง",
    "subDistrictNameEn": "Pho Thong",
    "postcode": "80160",
    "districtCode": "8008",
    "districtNameTh": "ท่าศาลา",
    "districtNameEn": "Tha Sala",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800901",
    "subDistrictNameTh": "ปากแพรก",
    "subDistrictNameEn": "Pak Phraek",
    "postcode": "80110",
    "districtCode": "8009",
    "districtNameTh": "ทุ่งสง",
    "districtNameEn": "Thung Song",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800902",
    "subDistrictNameTh": "ชะมาย",
    "subDistrictNameEn": "Chamai",
    "postcode": "80110",
    "districtCode": "8009",
    "districtNameTh": "ทุ่งสง",
    "districtNameEn": "Thung Song",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800903",
    "subDistrictNameTh": "หนองหงส์",
    "subDistrictNameEn": "Nong Hong",
    "postcode": "80110",
    "districtCode": "8009",
    "districtNameTh": "ทุ่งสง",
    "districtNameEn": "Thung Song",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800904",
    "subDistrictNameTh": "ควนกรด",
    "subDistrictNameEn": "Khuan Krot",
    "postcode": "80110",
    "districtCode": "8009",
    "districtNameTh": "ทุ่งสง",
    "districtNameEn": "Thung Song",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800905",
    "subDistrictNameTh": "นาไม้ไผ่",
    "subDistrictNameEn": "Na Mai Phai",
    "postcode": "80110",
    "districtCode": "8009",
    "districtNameTh": "ทุ่งสง",
    "districtNameEn": "Thung Song",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800906",
    "subDistrictNameTh": "นาหลวงเสน",
    "subDistrictNameEn": "Na Luang Sen",
    "postcode": "80110",
    "districtCode": "8009",
    "districtNameTh": "ทุ่งสง",
    "districtNameEn": "Thung Song",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800907",
    "subDistrictNameTh": "เขาโร",
    "subDistrictNameEn": "Khao Ro",
    "postcode": "80110",
    "districtCode": "8009",
    "districtNameTh": "ทุ่งสง",
    "districtNameEn": "Thung Song",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800908",
    "subDistrictNameTh": "กะปาง",
    "subDistrictNameEn": "Kapang",
    "postcode": "80310",
    "districtCode": "8009",
    "districtNameTh": "ทุ่งสง",
    "districtNameEn": "Thung Song",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800909",
    "subDistrictNameTh": "ที่วัง",
    "subDistrictNameEn": "Thi Wang",
    "postcode": "80110",
    "districtCode": "8009",
    "districtNameTh": "ทุ่งสง",
    "districtNameEn": "Thung Song",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800910",
    "subDistrictNameTh": "น้ำตก",
    "subDistrictNameEn": "Nam Tok",
    "postcode": "80110",
    "districtCode": "8009",
    "districtNameTh": "ทุ่งสง",
    "districtNameEn": "Thung Song",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800911",
    "subDistrictNameTh": "ถ้ำใหญ่",
    "subDistrictNameEn": "Tham Yai",
    "postcode": "80110",
    "districtCode": "8009",
    "districtNameTh": "ทุ่งสง",
    "districtNameEn": "Thung Song",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800912",
    "subDistrictNameTh": "นาโพธิ์",
    "subDistrictNameEn": "Na Pho",
    "postcode": "80110",
    "districtCode": "8009",
    "districtNameTh": "ทุ่งสง",
    "districtNameEn": "Thung Song",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "800913",
    "subDistrictNameTh": "เขาขาว",
    "subDistrictNameEn": "Khao Khao",
    "postcode": "80110",
    "districtCode": "8009",
    "districtNameTh": "ทุ่งสง",
    "districtNameEn": "Thung Song",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801001",
    "subDistrictNameTh": "นาบอน",
    "subDistrictNameEn": "Na Bon",
    "postcode": "80220",
    "districtCode": "8010",
    "districtNameTh": "นาบอน",
    "districtNameEn": "Na Bon",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801002",
    "subDistrictNameTh": "ทุ่งสง",
    "subDistrictNameEn": "Thung Song",
    "postcode": "80220",
    "districtCode": "8010",
    "districtNameTh": "นาบอน",
    "districtNameEn": "Na Bon",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801003",
    "subDistrictNameTh": "แก้วแสน",
    "subDistrictNameEn": "Kaeo Saen",
    "postcode": "80220",
    "districtCode": "8010",
    "districtNameTh": "นาบอน",
    "districtNameEn": "Na Bon",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801101",
    "subDistrictNameTh": "ท่ายาง",
    "subDistrictNameEn": "Tha Yang",
    "postcode": "80240",
    "districtCode": "8011",
    "districtNameTh": "ทุ่งใหญ่",
    "districtNameEn": "Thung Yai",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801102",
    "subDistrictNameTh": "ทุ่งสัง",
    "subDistrictNameEn": "Thung Sang",
    "postcode": "80240",
    "districtCode": "8011",
    "districtNameTh": "ทุ่งใหญ่",
    "districtNameEn": "Thung Yai",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801103",
    "subDistrictNameTh": "ทุ่งใหญ่",
    "subDistrictNameEn": "Thung Yai",
    "postcode": "80240",
    "districtCode": "8011",
    "districtNameTh": "ทุ่งใหญ่",
    "districtNameEn": "Thung Yai",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801104",
    "subDistrictNameTh": "กุแหระ",
    "subDistrictNameEn": "Kurae",
    "postcode": "80240",
    "districtCode": "8011",
    "districtNameTh": "ทุ่งใหญ่",
    "districtNameEn": "Thung Yai",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801105",
    "subDistrictNameTh": "ปริก",
    "subDistrictNameEn": "Prik",
    "postcode": "80240",
    "districtCode": "8011",
    "districtNameTh": "ทุ่งใหญ่",
    "districtNameEn": "Thung Yai",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801106",
    "subDistrictNameTh": "บางรูป",
    "subDistrictNameEn": "Bang Rup",
    "postcode": "80240",
    "districtCode": "8011",
    "districtNameTh": "ทุ่งใหญ่",
    "districtNameEn": "Thung Yai",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801107",
    "subDistrictNameTh": "กรุงหยัน",
    "subDistrictNameEn": "Krung Yan",
    "postcode": "80240",
    "districtCode": "8011",
    "districtNameTh": "ทุ่งใหญ่",
    "districtNameEn": "Thung Yai",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801201",
    "subDistrictNameTh": "ปากพนัง",
    "subDistrictNameEn": "Pak Phanang",
    "postcode": "80140",
    "districtCode": "8012",
    "districtNameTh": "ปากพนัง",
    "districtNameEn": "Pak Phanang",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801202",
    "subDistrictNameTh": "คลองน้อย",
    "subDistrictNameEn": "Khlong Noi",
    "postcode": "80330",
    "districtCode": "8012",
    "districtNameTh": "ปากพนัง",
    "districtNameEn": "Pak Phanang",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801203",
    "subDistrictNameTh": "ป่าระกำ",
    "subDistrictNameEn": "Pa Rakam",
    "postcode": "80140",
    "districtCode": "8012",
    "districtNameTh": "ปากพนัง",
    "districtNameEn": "Pak Phanang",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801204",
    "subDistrictNameTh": "ชะเมา",
    "subDistrictNameEn": "Chamao",
    "postcode": "80330",
    "districtCode": "8012",
    "districtNameTh": "ปากพนัง",
    "districtNameEn": "Pak Phanang",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801205",
    "subDistrictNameTh": "คลองกระบือ",
    "subDistrictNameEn": "Khlong Krabue",
    "postcode": "80140",
    "districtCode": "8012",
    "districtNameTh": "ปากพนัง",
    "districtNameEn": "Pak Phanang",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801206",
    "subDistrictNameTh": "เกาะทวด",
    "subDistrictNameEn": "Ko Thuat",
    "postcode": "80330",
    "districtCode": "8012",
    "districtNameTh": "ปากพนัง",
    "districtNameEn": "Pak Phanang",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801207",
    "subDistrictNameTh": "บ้านใหม่",
    "subDistrictNameEn": "Ban Mai",
    "postcode": "80140",
    "districtCode": "8012",
    "districtNameTh": "ปากพนัง",
    "districtNameEn": "Pak Phanang",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801208",
    "subDistrictNameTh": "หูล่อง",
    "subDistrictNameEn": "Hu Long",
    "postcode": "80140",
    "districtCode": "8012",
    "districtNameTh": "ปากพนัง",
    "districtNameEn": "Pak Phanang",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801209",
    "subDistrictNameTh": "แหลมตะลุมพุก",
    "subDistrictNameEn": "Laem Talumphuk",
    "postcode": "80140",
    "districtCode": "8012",
    "districtNameTh": "ปากพนัง",
    "districtNameEn": "Pak Phanang",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801210",
    "subDistrictNameTh": "ปากพนังฝั่งตะวันตก",
    "subDistrictNameEn": "Pak Phanang Fang Tawan Tok",
    "postcode": "80140",
    "districtCode": "8012",
    "districtNameTh": "ปากพนัง",
    "districtNameEn": "Pak Phanang",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801211",
    "subDistrictNameTh": "บางศาลา",
    "subDistrictNameEn": "Bang Sala",
    "postcode": "80140",
    "districtCode": "8012",
    "districtNameTh": "ปากพนัง",
    "districtNameEn": "Pak Phanang",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801212",
    "subDistrictNameTh": "บางพระ",
    "subDistrictNameEn": "Bang Phra",
    "postcode": "80140",
    "districtCode": "8012",
    "districtNameTh": "ปากพนัง",
    "districtNameEn": "Pak Phanang",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801213",
    "subDistrictNameTh": "บางตะพง",
    "subDistrictNameEn": "Bang Taphong",
    "postcode": "80140",
    "districtCode": "8012",
    "districtNameTh": "ปากพนัง",
    "districtNameEn": "Pak Phanang",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801214",
    "subDistrictNameTh": "ปากพนังฝั่งตะวันออก",
    "subDistrictNameEn": "Pak Phanang Fang Tawan Ok",
    "postcode": "80140",
    "districtCode": "8012",
    "districtNameTh": "ปากพนัง",
    "districtNameEn": "Pak Phanang",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801215",
    "subDistrictNameTh": "บ้านเพิง",
    "subDistrictNameEn": "Ban Phoeng",
    "postcode": "80140",
    "districtCode": "8012",
    "districtNameTh": "ปากพนัง",
    "districtNameEn": "Pak Phanang",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801216",
    "subDistrictNameTh": "ท่าพยา",
    "subDistrictNameEn": "Tha Phaya",
    "postcode": "80140",
    "districtCode": "8012",
    "districtNameTh": "ปากพนัง",
    "districtNameEn": "Pak Phanang",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801217",
    "subDistrictNameTh": "ปากแพรก",
    "subDistrictNameEn": "Pak Phraek",
    "postcode": "80140",
    "districtCode": "8012",
    "districtNameTh": "ปากพนัง",
    "districtNameEn": "Pak Phanang",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801218",
    "subDistrictNameTh": "ขนาบนาก",
    "subDistrictNameEn": "Khanap Nak",
    "postcode": "80140",
    "districtCode": "8012",
    "districtNameTh": "ปากพนัง",
    "districtNameEn": "Pak Phanang",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801301",
    "subDistrictNameTh": "ร่อนพิบูลย์",
    "subDistrictNameEn": "Ron Phibun",
    "postcode": "80130",
    "districtCode": "8013",
    "districtNameTh": "ร่อนพิบูลย์",
    "districtNameEn": "Ron Phibun",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801302",
    "subDistrictNameTh": "หินตก",
    "subDistrictNameEn": "Hin Tok",
    "postcode": "80350",
    "districtCode": "8013",
    "districtNameTh": "ร่อนพิบูลย์",
    "districtNameEn": "Ron Phibun",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801303",
    "subDistrictNameTh": "เสาธง",
    "subDistrictNameEn": "Sao Thong",
    "postcode": "80350",
    "districtCode": "8013",
    "districtNameTh": "ร่อนพิบูลย์",
    "districtNameEn": "Ron Phibun",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801304",
    "subDistrictNameTh": "ควนเกย",
    "subDistrictNameEn": "Khuan Koei",
    "postcode": "80130",
    "districtCode": "8013",
    "districtNameTh": "ร่อนพิบูลย์",
    "districtNameEn": "Ron Phibun",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801305",
    "subDistrictNameTh": "ควนพัง",
    "subDistrictNameEn": "Khuan Phang",
    "postcode": "80130",
    "districtCode": "8013",
    "districtNameTh": "ร่อนพิบูลย์",
    "districtNameEn": "Ron Phibun",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801306",
    "subDistrictNameTh": "ควนชุม",
    "subDistrictNameEn": "Khuan Chum",
    "postcode": "80130",
    "districtCode": "8013",
    "districtNameTh": "ร่อนพิบูลย์",
    "districtNameEn": "Ron Phibun",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801401",
    "subDistrictNameTh": "สิชล",
    "subDistrictNameEn": "Sichon",
    "postcode": "80120",
    "districtCode": "8014",
    "districtNameTh": "สิชล",
    "districtNameEn": "Sichon",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801402",
    "subDistrictNameTh": "ทุ่งปรัง",
    "subDistrictNameEn": "Thung Prang",
    "postcode": "80120",
    "districtCode": "8014",
    "districtNameTh": "สิชล",
    "districtNameEn": "Sichon",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801403",
    "subDistrictNameTh": "ฉลอง",
    "subDistrictNameEn": "Chalong",
    "postcode": "80120",
    "districtCode": "8014",
    "districtNameTh": "สิชล",
    "districtNameEn": "Sichon",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801404",
    "subDistrictNameTh": "เสาเภา",
    "subDistrictNameEn": "Sao Phao",
    "postcode": "80340",
    "districtCode": "8014",
    "districtNameTh": "สิชล",
    "districtNameEn": "Sichon",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801405",
    "subDistrictNameTh": "เปลี่ยน",
    "subDistrictNameEn": "Plian",
    "postcode": "80120",
    "districtCode": "8014",
    "districtNameTh": "สิชล",
    "districtNameEn": "Sichon",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801406",
    "subDistrictNameTh": "สี่ขีด",
    "subDistrictNameEn": "Si Khit",
    "postcode": "80120",
    "districtCode": "8014",
    "districtNameTh": "สิชล",
    "districtNameEn": "Sichon",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801407",
    "subDistrictNameTh": "เทพราช",
    "subDistrictNameEn": "Theppharat",
    "postcode": "80340",
    "districtCode": "8014",
    "districtNameTh": "สิชล",
    "districtNameEn": "Sichon",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801408",
    "subDistrictNameTh": "เขาน้อย",
    "subDistrictNameEn": "Khao Noi",
    "postcode": "80120",
    "districtCode": "8014",
    "districtNameTh": "สิชล",
    "districtNameEn": "Sichon",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801409",
    "subDistrictNameTh": "ทุ่งใส",
    "subDistrictNameEn": "Thung Sai",
    "postcode": "80120",
    "districtCode": "8014",
    "districtNameTh": "สิชล",
    "districtNameEn": "Sichon",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801501",
    "subDistrictNameTh": "ขนอม",
    "subDistrictNameEn": "Khanom",
    "postcode": "80210",
    "districtCode": "8015",
    "districtNameTh": "ขนอม",
    "districtNameEn": "Khanom",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801502",
    "subDistrictNameTh": "ควนทอง",
    "subDistrictNameEn": "Khuan Thong",
    "postcode": "80210",
    "districtCode": "8015",
    "districtNameTh": "ขนอม",
    "districtNameEn": "Khanom",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801503",
    "subDistrictNameTh": "ท้องเนียน",
    "subDistrictNameEn": "Thong Nian",
    "postcode": "80210",
    "districtCode": "8015",
    "districtNameTh": "ขนอม",
    "districtNameEn": "Khanom",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801601",
    "subDistrictNameTh": "หัวไทร",
    "subDistrictNameEn": "Hua Sai",
    "postcode": "80170",
    "districtCode": "8016",
    "districtNameTh": "หัวไทร",
    "districtNameEn": "Hua Sai",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801602",
    "subDistrictNameTh": "หน้าสตน",
    "subDistrictNameEn": "Na Saton",
    "postcode": "80170",
    "districtCode": "8016",
    "districtNameTh": "หัวไทร",
    "districtNameEn": "Hua Sai",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801603",
    "subDistrictNameTh": "ทรายขาว",
    "subDistrictNameEn": "Sai Khao",
    "postcode": "80170",
    "districtCode": "8016",
    "districtNameTh": "หัวไทร",
    "districtNameEn": "Hua Sai",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801604",
    "subDistrictNameTh": "แหลม",
    "subDistrictNameEn": "Laem",
    "postcode": "80170",
    "districtCode": "8016",
    "districtNameTh": "หัวไทร",
    "districtNameEn": "Hua Sai",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801605",
    "subDistrictNameTh": "เขาพังไกร",
    "subDistrictNameEn": "Khao Phang Krai",
    "postcode": "80170",
    "districtCode": "8016",
    "districtNameTh": "หัวไทร",
    "districtNameEn": "Hua Sai",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801606",
    "subDistrictNameTh": "บ้านราม",
    "subDistrictNameEn": "Ban Ram",
    "postcode": "80170",
    "districtCode": "8016",
    "districtNameTh": "หัวไทร",
    "districtNameEn": "Hua Sai",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801607",
    "subDistrictNameTh": "บางนบ",
    "subDistrictNameEn": "Bang Nop",
    "postcode": "80170",
    "districtCode": "8016",
    "districtNameTh": "หัวไทร",
    "districtNameEn": "Hua Sai",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801608",
    "subDistrictNameTh": "ท่าซอม",
    "subDistrictNameEn": "Tha Som",
    "postcode": "80170",
    "districtCode": "8016",
    "districtNameTh": "หัวไทร",
    "districtNameEn": "Hua Sai",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801609",
    "subDistrictNameTh": "ควนชะลิก",
    "subDistrictNameEn": "Khuan Chalik",
    "postcode": "80170",
    "districtCode": "8016",
    "districtNameTh": "หัวไทร",
    "districtNameEn": "Hua Sai",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801610",
    "subDistrictNameTh": "รามแก้ว",
    "subDistrictNameEn": "Ram Kaeo",
    "postcode": "80170",
    "districtCode": "8016",
    "districtNameTh": "หัวไทร",
    "districtNameEn": "Hua Sai",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801611",
    "subDistrictNameTh": "เกาะเพชร",
    "subDistrictNameEn": "Ko Phet",
    "postcode": "80170",
    "districtCode": "8016",
    "districtNameTh": "หัวไทร",
    "districtNameEn": "Hua Sai",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801701",
    "subDistrictNameTh": "บางขัน",
    "subDistrictNameEn": "Bang Khan",
    "postcode": "80360",
    "districtCode": "8017",
    "districtNameTh": "บางขัน",
    "districtNameEn": "Bang Khan",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801702",
    "subDistrictNameTh": "บ้านลำนาว",
    "subDistrictNameEn": "Ban Lam Nao",
    "postcode": "80360",
    "districtCode": "8017",
    "districtNameTh": "บางขัน",
    "districtNameEn": "Bang Khan",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801703",
    "subDistrictNameTh": "วังหิน",
    "subDistrictNameEn": "Wang Hin",
    "postcode": "80360",
    "districtCode": "8017",
    "districtNameTh": "บางขัน",
    "districtNameEn": "Bang Khan",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801704",
    "subDistrictNameTh": "บ้านนิคม",
    "subDistrictNameEn": "Ban Nikhom",
    "postcode": "80360",
    "districtCode": "8017",
    "districtNameTh": "บางขัน",
    "districtNameEn": "Bang Khan",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801801",
    "subDistrictNameTh": "ถ้ำพรรณรา",
    "subDistrictNameEn": "Tham Phannara",
    "postcode": "80260",
    "districtCode": "8018",
    "districtNameTh": "ถ้ำพรรณรา",
    "districtNameEn": "Tham Phannara",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801802",
    "subDistrictNameTh": "คลองเส",
    "subDistrictNameEn": "Khlong Se",
    "postcode": "80260",
    "districtCode": "8018",
    "districtNameTh": "ถ้ำพรรณรา",
    "districtNameEn": "Tham Phannara",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801803",
    "subDistrictNameTh": "ดุสิต",
    "subDistrictNameEn": "Dusit",
    "postcode": "80260",
    "districtCode": "8018",
    "districtNameTh": "ถ้ำพรรณรา",
    "districtNameEn": "Tham Phannara",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801901",
    "subDistrictNameTh": "บ้านควนมุด",
    "subDistrictNameEn": "Ban Khuan Mut",
    "postcode": "80180",
    "districtCode": "8019",
    "districtNameTh": "จุฬาภรณ์",
    "districtNameEn": "Chulabhorn",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801902",
    "subDistrictNameTh": "บ้านชะอวด",
    "subDistrictNameEn": "Ban Cha-uat",
    "postcode": "80180",
    "districtCode": "8019",
    "districtNameTh": "จุฬาภรณ์",
    "districtNameEn": "Chulabhorn",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801903",
    "subDistrictNameTh": "ควนหนองคว้า",
    "subDistrictNameEn": "Khuan Nong Khwa",
    "postcode": "80130",
    "districtCode": "8019",
    "districtNameTh": "จุฬาภรณ์",
    "districtNameEn": "Chulabhorn",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801904",
    "subDistrictNameTh": "ทุ่งโพธิ์",
    "subDistrictNameEn": "Thung Pho",
    "postcode": "80130",
    "districtCode": "8019",
    "districtNameTh": "จุฬาภรณ์",
    "districtNameEn": "Chulabhorn",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801905",
    "subDistrictNameTh": "นาหมอบุญ",
    "subDistrictNameEn": "Na Mo Bun",
    "postcode": "80130",
    "districtCode": "8019",
    "districtNameTh": "จุฬาภรณ์",
    "districtNameEn": "Chulabhorn",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "801906",
    "subDistrictNameTh": "สามตำบล",
    "subDistrictNameEn": "Sam Tambon",
    "postcode": "80130",
    "districtCode": "8019",
    "districtNameTh": "จุฬาภรณ์",
    "districtNameEn": "Chulabhorn",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "802001",
    "subDistrictNameTh": "นาพรุ",
    "subDistrictNameEn": "Na Phru",
    "postcode": "80000",
    "districtCode": "8020",
    "districtNameTh": "พระพรหม",
    "districtNameEn": "Phra Phrom",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "802002",
    "subDistrictNameTh": "นาสาร",
    "subDistrictNameEn": "Na San",
    "postcode": "80000",
    "districtCode": "8020",
    "districtNameTh": "พระพรหม",
    "districtNameEn": "Phra Phrom",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "802003",
    "subDistrictNameTh": "ท้ายสำเภา",
    "subDistrictNameEn": "Thai Samphao",
    "postcode": "80000",
    "districtCode": "8020",
    "districtNameTh": "พระพรหม",
    "districtNameEn": "Phra Phrom",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "802004",
    "subDistrictNameTh": "ช้างซ้าย",
    "subDistrictNameEn": "Chang Sai",
    "postcode": "80000",
    "districtCode": "8020",
    "districtNameTh": "พระพรหม",
    "districtNameEn": "Phra Phrom",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "802101",
    "subDistrictNameTh": "นบพิตำ",
    "subDistrictNameEn": "Nopphitam",
    "postcode": "80160",
    "districtCode": "8021",
    "districtNameTh": "นบพิตำ",
    "districtNameEn": "Nopphitam",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "802102",
    "subDistrictNameTh": "กรุงชิง",
    "subDistrictNameEn": "Krung Ching",
    "postcode": "80160",
    "districtCode": "8021",
    "districtNameTh": "นบพิตำ",
    "districtNameEn": "Nopphitam",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "802103",
    "subDistrictNameTh": "กะหรอ",
    "subDistrictNameEn": "Kalo",
    "postcode": "80160",
    "districtCode": "8021",
    "districtNameTh": "นบพิตำ",
    "districtNameEn": "Nopphitam",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "802104",
    "subDistrictNameTh": "นาเหรง",
    "subDistrictNameEn": "Na Reng",
    "postcode": "80160",
    "districtCode": "8021",
    "districtNameTh": "นบพิตำ",
    "districtNameEn": "Nopphitam",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "802201",
    "subDistrictNameTh": "ช้างกลาง",
    "subDistrictNameEn": "Chang Klang",
    "postcode": "80250",
    "districtCode": "8022",
    "districtNameTh": "ช้างกลาง",
    "districtNameEn": "Chang Klang",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "802202",
    "subDistrictNameTh": "หลักช้าง",
    "subDistrictNameEn": "Lak Chang",
    "postcode": "80250",
    "districtCode": "8022",
    "districtNameTh": "ช้างกลาง",
    "districtNameEn": "Chang Klang",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "802203",
    "subDistrictNameTh": "สวนขัน",
    "subDistrictNameEn": "Suan Khan",
    "postcode": "80250",
    "districtCode": "8022",
    "districtNameTh": "ช้างกลาง",
    "districtNameEn": "Chang Klang",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "802301",
    "subDistrictNameTh": "เชียรเขา",
    "subDistrictNameEn": "Chian Khao",
    "postcode": "80190",
    "districtCode": "8023",
    "districtNameTh": "เฉลิมพระเกียรติ",
    "districtNameEn": "Chaloem Phra Kiat",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "802302",
    "subDistrictNameTh": "ดอนตรอ",
    "subDistrictNameEn": "Don Tro",
    "postcode": "80290",
    "districtCode": "8023",
    "districtNameTh": "เฉลิมพระเกียรติ",
    "districtNameEn": "Chaloem Phra Kiat",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "802303",
    "subDistrictNameTh": "สวนหลวง",
    "subDistrictNameEn": "Suan Luang",
    "postcode": "80190",
    "districtCode": "8023",
    "districtNameTh": "เฉลิมพระเกียรติ",
    "districtNameEn": "Chaloem Phra Kiat",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "802304",
    "subDistrictNameTh": "ทางพูน",
    "subDistrictNameEn": "Thang Phun",
    "postcode": "80290",
    "districtCode": "8023",
    "districtNameTh": "เฉลิมพระเกียรติ",
    "districtNameEn": "Chaloem Phra Kiat",
    "provinceCode": "80",
    "provinceNameTh": "นครศรีธรรมราช",
    "provinceNameEn": "Nakhon Si Thammarat"
},
{
    "subDistrictCode": "810101",
    "subDistrictNameTh": "ปากน้ำ",
    "subDistrictNameEn": "Pak Nam",
    "postcode": "81000",
    "districtCode": "8101",
    "districtNameTh": "เมืองกระบี่",
    "districtNameEn": "Mueang Krabi",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810102",
    "subDistrictNameTh": "กระบี่ใหญ่",
    "subDistrictNameEn": "Krabi Yai",
    "postcode": "81000",
    "districtCode": "8101",
    "districtNameTh": "เมืองกระบี่",
    "districtNameEn": "Mueang Krabi",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810103",
    "subDistrictNameTh": "กระบี่น้อย",
    "subDistrictNameEn": "Krabi Noi",
    "postcode": "81000",
    "districtCode": "8101",
    "districtNameTh": "เมืองกระบี่",
    "districtNameEn": "Mueang Krabi",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810105",
    "subDistrictNameTh": "เขาคราม",
    "subDistrictNameEn": "Khao Khram",
    "postcode": "81000",
    "districtCode": "8101",
    "districtNameTh": "เมืองกระบี่",
    "districtNameEn": "Mueang Krabi",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810106",
    "subDistrictNameTh": "เขาทอง",
    "subDistrictNameEn": "Khao Thong",
    "postcode": "81000",
    "districtCode": "8101",
    "districtNameTh": "เมืองกระบี่",
    "districtNameEn": "Mueang Krabi",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810111",
    "subDistrictNameTh": "ทับปริก",
    "subDistrictNameEn": "Thap Prik",
    "postcode": "81000",
    "districtCode": "8101",
    "districtNameTh": "เมืองกระบี่",
    "districtNameEn": "Mueang Krabi",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810115",
    "subDistrictNameTh": "ไสไทย",
    "subDistrictNameEn": "Sai Thai",
    "postcode": "81000",
    "districtCode": "8101",
    "districtNameTh": "เมืองกระบี่",
    "districtNameEn": "Mueang Krabi",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810116",
    "subDistrictNameTh": "อ่าวนาง",
    "subDistrictNameEn": "Ao Nang",
    "postcode": "81000",
    "districtCode": "8101",
    "districtNameTh": "เมืองกระบี่",
    "districtNameEn": "Mueang Krabi",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810117",
    "subDistrictNameTh": "หนองทะเล",
    "subDistrictNameEn": "Nong Thale",
    "postcode": "81000",
    "districtCode": "8101",
    "districtNameTh": "เมืองกระบี่",
    "districtNameEn": "Mueang Krabi",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810118",
    "subDistrictNameTh": "คลองประสงค์",
    "subDistrictNameEn": "Khlong Prasong",
    "postcode": "81000",
    "districtCode": "8101",
    "districtNameTh": "เมืองกระบี่",
    "districtNameEn": "Mueang Krabi",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810201",
    "subDistrictNameTh": "เขาพนม",
    "subDistrictNameEn": "Khao Phanom",
    "postcode": "81140",
    "districtCode": "8102",
    "districtNameTh": "เขาพนม",
    "districtNameEn": "Khao Phanom",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810202",
    "subDistrictNameTh": "เขาดิน",
    "subDistrictNameEn": "Khao Din",
    "postcode": "81140",
    "districtCode": "8102",
    "districtNameTh": "เขาพนม",
    "districtNameEn": "Khao Phanom",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810203",
    "subDistrictNameTh": "สินปุน",
    "subDistrictNameEn": "Sin Pun",
    "postcode": "80240",
    "districtCode": "8102",
    "districtNameTh": "เขาพนม",
    "districtNameEn": "Khao Phanom",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810204",
    "subDistrictNameTh": "พรุเตียว",
    "subDistrictNameEn": "Phru Tiao",
    "postcode": "81140",
    "districtCode": "8102",
    "districtNameTh": "เขาพนม",
    "districtNameEn": "Khao Phanom",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810205",
    "subDistrictNameTh": "หน้าเขา",
    "subDistrictNameEn": "Na Khao",
    "postcode": "81140",
    "districtCode": "8102",
    "districtNameTh": "เขาพนม",
    "districtNameEn": "Khao Phanom",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810206",
    "subDistrictNameTh": "โคกหาร",
    "subDistrictNameEn": "Khok Han",
    "postcode": "80240",
    "districtCode": "8102",
    "districtNameTh": "เขาพนม",
    "districtNameEn": "Khao Phanom",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810301",
    "subDistrictNameTh": "เกาะลันตาใหญ่",
    "subDistrictNameEn": "Ko Lanta Yai",
    "postcode": "81150",
    "districtCode": "8103",
    "districtNameTh": "เกาะลันตา",
    "districtNameEn": "Ko Lanta",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810302",
    "subDistrictNameTh": "เกาะลันตาน้อย",
    "subDistrictNameEn": "Ko Lanta Noi",
    "postcode": "81150",
    "districtCode": "8103",
    "districtNameTh": "เกาะลันตา",
    "districtNameEn": "Ko Lanta",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810303",
    "subDistrictNameTh": "เกาะกลาง",
    "subDistrictNameEn": "Ko Klang",
    "postcode": "81120",
    "districtCode": "8103",
    "districtNameTh": "เกาะลันตา",
    "districtNameEn": "Ko Lanta",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810304",
    "subDistrictNameTh": "คลองยาง",
    "subDistrictNameEn": "Khlong Yang",
    "postcode": "81120",
    "districtCode": "8103",
    "districtNameTh": "เกาะลันตา",
    "districtNameEn": "Ko Lanta",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810305",
    "subDistrictNameTh": "ศาลาด่าน",
    "subDistrictNameEn": "Sala Dan",
    "postcode": "81150",
    "districtCode": "8103",
    "districtNameTh": "เกาะลันตา",
    "districtNameEn": "Ko Lanta",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810401",
    "subDistrictNameTh": "คลองท่อมใต้",
    "subDistrictNameEn": "Khlong Thom Tai",
    "postcode": "81120",
    "districtCode": "8104",
    "districtNameTh": "คลองท่อม",
    "districtNameEn": "Khlong Thom",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810402",
    "subDistrictNameTh": "คลองท่อมเหนือ",
    "subDistrictNameEn": "Khlong Thom Nuea",
    "postcode": "81120",
    "districtCode": "8104",
    "districtNameTh": "คลองท่อม",
    "districtNameEn": "Khlong Thom",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810403",
    "subDistrictNameTh": "คลองพน",
    "subDistrictNameEn": "Khlong Phon",
    "postcode": "81170",
    "districtCode": "8104",
    "districtNameTh": "คลองท่อม",
    "districtNameEn": "Khlong Thom",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810404",
    "subDistrictNameTh": "ทรายขาว",
    "subDistrictNameEn": "Sai Khao",
    "postcode": "81170",
    "districtCode": "8104",
    "districtNameTh": "คลองท่อม",
    "districtNameEn": "Khlong Thom",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810405",
    "subDistrictNameTh": "ห้วยน้ำขาว",
    "subDistrictNameEn": "Huai Nam Khao",
    "postcode": "81120",
    "districtCode": "8104",
    "districtNameTh": "คลองท่อม",
    "districtNameEn": "Khlong Thom",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810406",
    "subDistrictNameTh": "พรุดินนา",
    "subDistrictNameEn": "Phru Din Na",
    "postcode": "81120",
    "districtCode": "8104",
    "districtNameTh": "คลองท่อม",
    "districtNameEn": "Khlong Thom",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810407",
    "subDistrictNameTh": "เพหลา",
    "subDistrictNameEn": "Phela",
    "postcode": "81120",
    "districtCode": "8104",
    "districtNameTh": "คลองท่อม",
    "districtNameEn": "Khlong Thom",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810501",
    "subDistrictNameTh": "อ่าวลึกใต้",
    "subDistrictNameEn": "Ao Luek Tai",
    "postcode": "81110",
    "districtCode": "8105",
    "districtNameTh": "อ่าวลึก",
    "districtNameEn": "Ao Luek",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810502",
    "subDistrictNameTh": "แหลมสัก",
    "subDistrictNameEn": "Laem Sak",
    "postcode": "81110",
    "districtCode": "8105",
    "districtNameTh": "อ่าวลึก",
    "districtNameEn": "Ao Luek",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810503",
    "subDistrictNameTh": "นาเหนือ",
    "subDistrictNameEn": "Na Nuea",
    "postcode": "81110",
    "districtCode": "8105",
    "districtNameTh": "อ่าวลึก",
    "districtNameEn": "Ao Luek",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810504",
    "subDistrictNameTh": "คลองหิน",
    "subDistrictNameEn": "Khlong Hin",
    "postcode": "81110",
    "districtCode": "8105",
    "districtNameTh": "อ่าวลึก",
    "districtNameEn": "Ao Luek",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810505",
    "subDistrictNameTh": "อ่าวลึกน้อย",
    "subDistrictNameEn": "Ao Luek Noi",
    "postcode": "81110",
    "districtCode": "8105",
    "districtNameTh": "อ่าวลึก",
    "districtNameEn": "Ao Luek",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810506",
    "subDistrictNameTh": "อ่าวลึกเหนือ",
    "subDistrictNameEn": "Ao Luek Nuea",
    "postcode": "81110",
    "districtCode": "8105",
    "districtNameTh": "อ่าวลึก",
    "districtNameEn": "Ao Luek",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810507",
    "subDistrictNameTh": "เขาใหญ่",
    "subDistrictNameEn": "Khao Yai",
    "postcode": "81110",
    "districtCode": "8105",
    "districtNameTh": "อ่าวลึก",
    "districtNameEn": "Ao Luek",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810508",
    "subDistrictNameTh": "คลองยา",
    "subDistrictNameEn": "Khlong Ya",
    "postcode": "81110",
    "districtCode": "8105",
    "districtNameTh": "อ่าวลึก",
    "districtNameEn": "Ao Luek",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810509",
    "subDistrictNameTh": "บ้านกลาง",
    "subDistrictNameEn": "Ban Klang",
    "postcode": "81110",
    "districtCode": "8105",
    "districtNameTh": "อ่าวลึก",
    "districtNameEn": "Ao Luek",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810601",
    "subDistrictNameTh": "ปลายพระยา",
    "subDistrictNameEn": "Plai Phraya",
    "postcode": "81160",
    "districtCode": "8106",
    "districtNameTh": "ปลายพระยา",
    "districtNameEn": "Plai Phraya",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810602",
    "subDistrictNameTh": "เขาเขน",
    "subDistrictNameEn": "Khao Khen",
    "postcode": "81160",
    "districtCode": "8106",
    "districtNameTh": "ปลายพระยา",
    "districtNameEn": "Plai Phraya",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810603",
    "subDistrictNameTh": "เขาต่อ",
    "subDistrictNameEn": "Khao To",
    "postcode": "81160",
    "districtCode": "8106",
    "districtNameTh": "ปลายพระยา",
    "districtNameEn": "Plai Phraya",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810604",
    "subDistrictNameTh": "คีรีวง",
    "subDistrictNameEn": "Khiri Wong",
    "postcode": "81160",
    "districtCode": "8106",
    "districtNameTh": "ปลายพระยา",
    "districtNameEn": "Plai Phraya",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810701",
    "subDistrictNameTh": "ลำทับ",
    "subDistrictNameEn": "Lam Thap",
    "postcode": "81190",
    "districtCode": "8107",
    "districtNameTh": "ลำทับ",
    "districtNameEn": "Lam Thap",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810702",
    "subDistrictNameTh": "ดินอุดม",
    "subDistrictNameEn": "Din Udom",
    "postcode": "81190",
    "districtCode": "8107",
    "districtNameTh": "ลำทับ",
    "districtNameEn": "Lam Thap",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810703",
    "subDistrictNameTh": "ทุ่งไทรทอง",
    "subDistrictNameEn": "Thung Sai Thong",
    "postcode": "81190",
    "districtCode": "8107",
    "districtNameTh": "ลำทับ",
    "districtNameEn": "Lam Thap",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810704",
    "subDistrictNameTh": "ดินแดง",
    "subDistrictNameEn": "Din Daeng",
    "postcode": "81190",
    "districtCode": "8107",
    "districtNameTh": "ลำทับ",
    "districtNameEn": "Lam Thap",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810801",
    "subDistrictNameTh": "เหนือคลอง",
    "subDistrictNameEn": "Nuea Khlong",
    "postcode": "81130",
    "districtCode": "8108",
    "districtNameTh": "เหนือคลอง",
    "districtNameEn": "Nuea Khlong",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810802",
    "subDistrictNameTh": "เกาะศรีบอยา",
    "subDistrictNameEn": "Ko Siboya",
    "postcode": "81130",
    "districtCode": "8108",
    "districtNameTh": "เหนือคลอง",
    "districtNameEn": "Nuea Khlong",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810803",
    "subDistrictNameTh": "คลองขนาน",
    "subDistrictNameEn": "Khlong Khanan",
    "postcode": "81130",
    "districtCode": "8108",
    "districtNameTh": "เหนือคลอง",
    "districtNameEn": "Nuea Khlong",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810804",
    "subDistrictNameTh": "คลองเขม้า",
    "subDistrictNameEn": "Khlong Khamao",
    "postcode": "81130",
    "districtCode": "8108",
    "districtNameTh": "เหนือคลอง",
    "districtNameEn": "Nuea Khlong",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810805",
    "subDistrictNameTh": "โคกยาง",
    "subDistrictNameEn": "Khok Yang",
    "postcode": "81130",
    "districtCode": "8108",
    "districtNameTh": "เหนือคลอง",
    "districtNameEn": "Nuea Khlong",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810806",
    "subDistrictNameTh": "ตลิ่งชัน",
    "subDistrictNameEn": "Taling Chan",
    "postcode": "81130",
    "districtCode": "8108",
    "districtNameTh": "เหนือคลอง",
    "districtNameEn": "Nuea Khlong",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810807",
    "subDistrictNameTh": "ปกาสัย",
    "subDistrictNameEn": "Pakasai",
    "postcode": "81130",
    "districtCode": "8108",
    "districtNameTh": "เหนือคลอง",
    "districtNameEn": "Nuea Khlong",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "810808",
    "subDistrictNameTh": "ห้วยยูง",
    "subDistrictNameEn": "Huai Yung",
    "postcode": "81130",
    "districtCode": "8108",
    "districtNameTh": "เหนือคลอง",
    "districtNameEn": "Nuea Khlong",
    "provinceCode": "81",
    "provinceNameTh": "กระบี่",
    "provinceNameEn": "Krabi"
},
{
    "subDistrictCode": "820101",
    "subDistrictNameTh": "ท้ายช้าง",
    "subDistrictNameEn": "Thai Chang",
    "postcode": "82000",
    "districtCode": "8201",
    "districtNameTh": "เมืองพังงา",
    "districtNameEn": "Mueang Phang-nga",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820102",
    "subDistrictNameTh": "นบปริง",
    "subDistrictNameEn": "Nop Pring",
    "postcode": "82000",
    "districtCode": "8201",
    "districtNameTh": "เมืองพังงา",
    "districtNameEn": "Mueang Phang-nga",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820103",
    "subDistrictNameTh": "ถ้ำน้ำผุด",
    "subDistrictNameEn": "Tham Nam Phut",
    "postcode": "82000",
    "districtCode": "8201",
    "districtNameTh": "เมืองพังงา",
    "districtNameEn": "Mueang Phang-nga",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820104",
    "subDistrictNameTh": "บางเตย",
    "subDistrictNameEn": "Bang Toei",
    "postcode": "82000",
    "districtCode": "8201",
    "districtNameTh": "เมืองพังงา",
    "districtNameEn": "Mueang Phang-nga",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820105",
    "subDistrictNameTh": "ตากแดด",
    "subDistrictNameEn": "Tak Daet",
    "postcode": "82000",
    "districtCode": "8201",
    "districtNameTh": "เมืองพังงา",
    "districtNameEn": "Mueang Phang-nga",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820106",
    "subDistrictNameTh": "สองแพรก",
    "subDistrictNameEn": "Song Phraek",
    "postcode": "82000",
    "districtCode": "8201",
    "districtNameTh": "เมืองพังงา",
    "districtNameEn": "Mueang Phang-nga",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820107",
    "subDistrictNameTh": "ทุ่งคาโงก",
    "subDistrictNameEn": "Thung Kha Ngok",
    "postcode": "82000",
    "districtCode": "8201",
    "districtNameTh": "เมืองพังงา",
    "districtNameEn": "Mueang Phang-nga",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820108",
    "subDistrictNameTh": "เกาะปันหยี",
    "subDistrictNameEn": "Ko Panyi",
    "postcode": "82000",
    "districtCode": "8201",
    "districtNameTh": "เมืองพังงา",
    "districtNameEn": "Mueang Phang-nga",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820109",
    "subDistrictNameTh": "ป่ากอ",
    "subDistrictNameEn": "Pa Ko",
    "postcode": "82000",
    "districtCode": "8201",
    "districtNameTh": "เมืองพังงา",
    "districtNameEn": "Mueang Phang-nga",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820201",
    "subDistrictNameTh": "เกาะยาวน้อย",
    "subDistrictNameEn": "Ko Yao Noi",
    "postcode": "82160",
    "districtCode": "8202",
    "districtNameTh": "เกาะยาว",
    "districtNameEn": "Ko Yao",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820202",
    "subDistrictNameTh": "เกาะยาวใหญ่",
    "subDistrictNameEn": "Ko yao Yai",
    "postcode": "82160",
    "districtCode": "8202",
    "districtNameTh": "เกาะยาว",
    "districtNameEn": "Ko Yao",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820203",
    "subDistrictNameTh": "พรุใน",
    "subDistrictNameEn": "Phru Nai",
    "postcode": "83000",
    "districtCode": "8202",
    "districtNameTh": "เกาะยาว",
    "districtNameEn": "Ko Yao",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820301",
    "subDistrictNameTh": "กะปง",
    "subDistrictNameEn": "Kapong",
    "postcode": "82170",
    "districtCode": "8203",
    "districtNameTh": "กะปง",
    "districtNameEn": "Kapong",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820302",
    "subDistrictNameTh": "ท่านา",
    "subDistrictNameEn": "Tha Na",
    "postcode": "82170",
    "districtCode": "8203",
    "districtNameTh": "กะปง",
    "districtNameEn": "Kapong",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820303",
    "subDistrictNameTh": "เหมาะ",
    "subDistrictNameEn": "Mo",
    "postcode": "82170",
    "districtCode": "8203",
    "districtNameTh": "กะปง",
    "districtNameEn": "Kapong",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820304",
    "subDistrictNameTh": "เหล",
    "subDistrictNameEn": "Le",
    "postcode": "82170",
    "districtCode": "8203",
    "districtNameTh": "กะปง",
    "districtNameEn": "Kapong",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820305",
    "subDistrictNameTh": "รมณีย์",
    "subDistrictNameEn": "Rommani",
    "postcode": "82170",
    "districtCode": "8203",
    "districtNameTh": "กะปง",
    "districtNameEn": "Kapong",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820401",
    "subDistrictNameTh": "ถ้ำ",
    "subDistrictNameEn": "Tham",
    "postcode": "82130",
    "districtCode": "8204",
    "districtNameTh": "ตะกั่วทุ่ง",
    "districtNameEn": "Takua Thung",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820402",
    "subDistrictNameTh": "กระโสม",
    "subDistrictNameEn": "Krasom",
    "postcode": "82130",
    "districtCode": "8204",
    "districtNameTh": "ตะกั่วทุ่ง",
    "districtNameEn": "Takua Thung",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820403",
    "subDistrictNameTh": "กะไหล",
    "subDistrictNameEn": "Kalai",
    "postcode": "82130",
    "districtCode": "8204",
    "districtNameTh": "ตะกั่วทุ่ง",
    "districtNameEn": "Takua Thung",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820404",
    "subDistrictNameTh": "ท่าอยู่",
    "subDistrictNameEn": "Tha Yu",
    "postcode": "82130",
    "districtCode": "8204",
    "districtNameTh": "ตะกั่วทุ่ง",
    "districtNameEn": "Takua Thung",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820405",
    "subDistrictNameTh": "หล่อยูง",
    "subDistrictNameEn": "Lo Yung",
    "postcode": "82140",
    "districtCode": "8204",
    "districtNameTh": "ตะกั่วทุ่ง",
    "districtNameEn": "Takua Thung",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820406",
    "subDistrictNameTh": "โคกกลอย",
    "subDistrictNameEn": "Khok Kloi",
    "postcode": "82140",
    "districtCode": "8204",
    "districtNameTh": "ตะกั่วทุ่ง",
    "districtNameEn": "Takua Thung",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820407",
    "subDistrictNameTh": "คลองเคียน",
    "subDistrictNameEn": "Khlong Khian",
    "postcode": "82130",
    "districtCode": "8204",
    "districtNameTh": "ตะกั่วทุ่ง",
    "districtNameEn": "Takua Thung",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820501",
    "subDistrictNameTh": "ตะกั่วป่า",
    "subDistrictNameEn": "Takua Pa",
    "postcode": "82110",
    "districtCode": "8205",
    "districtNameTh": "ตะกั่วป่า",
    "districtNameEn": "Takua Pa",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820502",
    "subDistrictNameTh": "บางนายสี",
    "subDistrictNameEn": "Bang Nai Si",
    "postcode": "82110",
    "districtCode": "8205",
    "districtNameTh": "ตะกั่วป่า",
    "districtNameEn": "Takua Pa",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820503",
    "subDistrictNameTh": "บางไทร",
    "subDistrictNameEn": "Bang Sai",
    "postcode": "82110",
    "districtCode": "8205",
    "districtNameTh": "ตะกั่วป่า",
    "districtNameEn": "Takua Pa",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820504",
    "subDistrictNameTh": "บางม่วง",
    "subDistrictNameEn": "Bang Muang",
    "postcode": "82190",
    "districtCode": "8205",
    "districtNameTh": "ตะกั่วป่า",
    "districtNameEn": "Takua Pa",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820505",
    "subDistrictNameTh": "ตำตัว",
    "subDistrictNameEn": "Tam Tua",
    "postcode": "82110",
    "districtCode": "8205",
    "districtNameTh": "ตะกั่วป่า",
    "districtNameEn": "Takua Pa",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820506",
    "subDistrictNameTh": "โคกเคียน",
    "subDistrictNameEn": "Khok Khian",
    "postcode": "82110",
    "districtCode": "8205",
    "districtNameTh": "ตะกั่วป่า",
    "districtNameEn": "Takua Pa",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820507",
    "subDistrictNameTh": "คึกคัก",
    "subDistrictNameEn": "Khuekkhak",
    "postcode": "82190",
    "districtCode": "8205",
    "districtNameTh": "ตะกั่วป่า",
    "districtNameEn": "Takua Pa",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820508",
    "subDistrictNameTh": "เกาะคอเขา",
    "subDistrictNameEn": "Ko Kho Khao",
    "postcode": "82190",
    "districtCode": "8205",
    "districtNameTh": "ตะกั่วป่า",
    "districtNameEn": "Takua Pa",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820601",
    "subDistrictNameTh": "คุระ",
    "subDistrictNameEn": "Khura",
    "postcode": "82150",
    "districtCode": "8206",
    "districtNameTh": "คุระบุรี",
    "districtNameEn": "Khura Buri",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820602",
    "subDistrictNameTh": "บางวัน",
    "subDistrictNameEn": "Bang Wan",
    "postcode": "82150",
    "districtCode": "8206",
    "districtNameTh": "คุระบุรี",
    "districtNameEn": "Khura Buri",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820603",
    "subDistrictNameTh": "เกาะพระทอง",
    "subDistrictNameEn": "Ko Phra Thong",
    "postcode": "82150",
    "districtCode": "8206",
    "districtNameTh": "คุระบุรี",
    "districtNameEn": "Khura Buri",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820605",
    "subDistrictNameTh": "แม่นางขาว",
    "subDistrictNameEn": "Mae Nang Khao",
    "postcode": "82150",
    "districtCode": "8206",
    "districtNameTh": "คุระบุรี",
    "districtNameEn": "Khura Buri",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820701",
    "subDistrictNameTh": "ทับปุด",
    "subDistrictNameEn": "Thap Put",
    "postcode": "82180",
    "districtCode": "8207",
    "districtNameTh": "ทับปุด",
    "districtNameEn": "Thap Put",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820702",
    "subDistrictNameTh": "มะรุ่ย",
    "subDistrictNameEn": "Marui",
    "postcode": "82180",
    "districtCode": "8207",
    "districtNameTh": "ทับปุด",
    "districtNameEn": "Thap Put",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820703",
    "subDistrictNameTh": "บ่อแสน",
    "subDistrictNameEn": "Bo Saen",
    "postcode": "82180",
    "districtCode": "8207",
    "districtNameTh": "ทับปุด",
    "districtNameEn": "Thap Put",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820704",
    "subDistrictNameTh": "ถ้ำทองหลาง",
    "subDistrictNameEn": "Tham Thonglang",
    "postcode": "82180",
    "districtCode": "8207",
    "districtNameTh": "ทับปุด",
    "districtNameEn": "Thap Put",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820705",
    "subDistrictNameTh": "โคกเจริญ",
    "subDistrictNameEn": "Khok Charoen",
    "postcode": "82180",
    "districtCode": "8207",
    "districtNameTh": "ทับปุด",
    "districtNameEn": "Thap Put",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820706",
    "subDistrictNameTh": "บางเหรียง",
    "subDistrictNameEn": "Bang Riang",
    "postcode": "82180",
    "districtCode": "8207",
    "districtNameTh": "ทับปุด",
    "districtNameEn": "Thap Put",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820801",
    "subDistrictNameTh": "ท้ายเหมือง",
    "subDistrictNameEn": "Thai Mueang",
    "postcode": "82120",
    "districtCode": "8208",
    "districtNameTh": "ท้ายเหมือง",
    "districtNameEn": "Thai Mueang",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820802",
    "subDistrictNameTh": "นาเตย",
    "subDistrictNameEn": "Na Toei",
    "postcode": "82120",
    "districtCode": "8208",
    "districtNameTh": "ท้ายเหมือง",
    "districtNameEn": "Thai Mueang",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820803",
    "subDistrictNameTh": "บางทอง",
    "subDistrictNameEn": "Bang Thong",
    "postcode": "82120",
    "districtCode": "8208",
    "districtNameTh": "ท้ายเหมือง",
    "districtNameEn": "Thai Mueang",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820804",
    "subDistrictNameTh": "ทุ่งมะพร้าว",
    "subDistrictNameEn": "Thung Maphrao",
    "postcode": "82120",
    "districtCode": "8208",
    "districtNameTh": "ท้ายเหมือง",
    "districtNameEn": "Thai Mueang",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820805",
    "subDistrictNameTh": "ลำภี",
    "subDistrictNameEn": "Lam Phi",
    "postcode": "82120",
    "districtCode": "8208",
    "districtNameTh": "ท้ายเหมือง",
    "districtNameEn": "Thai Mueang",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "820806",
    "subDistrictNameTh": "ลำแก่น",
    "subDistrictNameEn": "Lam Kaen",
    "postcode": "82210",
    "districtCode": "8208",
    "districtNameTh": "ท้ายเหมือง",
    "districtNameEn": "Thai Mueang",
    "provinceCode": "82",
    "provinceNameTh": "พังงา",
    "provinceNameEn": "Phang-nga"
},
{
    "subDistrictCode": "830101",
    "subDistrictNameTh": "ตลาดใหญ่",
    "subDistrictNameEn": "Talat Yai",
    "postcode": "83000",
    "districtCode": "8301",
    "districtNameTh": "เมืองภูเก็ต",
    "districtNameEn": "Mueang Phuket",
    "provinceCode": "83",
    "provinceNameTh": "ภูเก็ต",
    "provinceNameEn": "Phuket"
},
{
    "subDistrictCode": "830102",
    "subDistrictNameTh": "ตลาดเหนือ",
    "subDistrictNameEn": "Talat Nuea",
    "postcode": "83000",
    "districtCode": "8301",
    "districtNameTh": "เมืองภูเก็ต",
    "districtNameEn": "Mueang Phuket",
    "provinceCode": "83",
    "provinceNameTh": "ภูเก็ต",
    "provinceNameEn": "Phuket"
},
{
    "subDistrictCode": "830103",
    "subDistrictNameTh": "เกาะแก้ว",
    "subDistrictNameEn": "Ko Kaeo",
    "postcode": "83000",
    "districtCode": "8301",
    "districtNameTh": "เมืองภูเก็ต",
    "districtNameEn": "Mueang Phuket",
    "provinceCode": "83",
    "provinceNameTh": "ภูเก็ต",
    "provinceNameEn": "Phuket"
},
{
    "subDistrictCode": "830104",
    "subDistrictNameTh": "รัษฎา",
    "subDistrictNameEn": "Ratsada",
    "postcode": "83000",
    "districtCode": "8301",
    "districtNameTh": "เมืองภูเก็ต",
    "districtNameEn": "Mueang Phuket",
    "provinceCode": "83",
    "provinceNameTh": "ภูเก็ต",
    "provinceNameEn": "Phuket"
},
{
    "subDistrictCode": "830105",
    "subDistrictNameTh": "วิชิต",
    "subDistrictNameEn": "Wichit",
    "postcode": "83000",
    "districtCode": "8301",
    "districtNameTh": "เมืองภูเก็ต",
    "districtNameEn": "Mueang Phuket",
    "provinceCode": "83",
    "provinceNameTh": "ภูเก็ต",
    "provinceNameEn": "Phuket"
},
{
    "subDistrictCode": "830106",
    "subDistrictNameTh": "ฉลอง",
    "subDistrictNameEn": "Chalong",
    "postcode": "83130",
    "districtCode": "8301",
    "districtNameTh": "เมืองภูเก็ต",
    "districtNameEn": "Mueang Phuket",
    "provinceCode": "83",
    "provinceNameTh": "ภูเก็ต",
    "provinceNameEn": "Phuket"
},
{
    "subDistrictCode": "830107",
    "subDistrictNameTh": "ราไวย์",
    "subDistrictNameEn": "Rawai",
    "postcode": "83130",
    "districtCode": "8301",
    "districtNameTh": "เมืองภูเก็ต",
    "districtNameEn": "Mueang Phuket",
    "provinceCode": "83",
    "provinceNameTh": "ภูเก็ต",
    "provinceNameEn": "Phuket"
},
{
    "subDistrictCode": "830108",
    "subDistrictNameTh": "กะรน",
    "subDistrictNameEn": "Karon",
    "postcode": "83100",
    "districtCode": "8301",
    "districtNameTh": "เมืองภูเก็ต",
    "districtNameEn": "Mueang Phuket",
    "provinceCode": "83",
    "provinceNameTh": "ภูเก็ต",
    "provinceNameEn": "Phuket"
},
{
    "subDistrictCode": "830201",
    "subDistrictNameTh": "กะทู้",
    "subDistrictNameEn": "Kathu",
    "postcode": "83120",
    "districtCode": "8302",
    "districtNameTh": "กะทู้",
    "districtNameEn": "Kathu",
    "provinceCode": "83",
    "provinceNameTh": "ภูเก็ต",
    "provinceNameEn": "Phuket"
},
{
    "subDistrictCode": "830202",
    "subDistrictNameTh": "ป่าตอง",
    "subDistrictNameEn": "Pa Tong",
    "postcode": "83150",
    "districtCode": "8302",
    "districtNameTh": "กะทู้",
    "districtNameEn": "Kathu",
    "provinceCode": "83",
    "provinceNameTh": "ภูเก็ต",
    "provinceNameEn": "Phuket"
},
{
    "subDistrictCode": "830203",
    "subDistrictNameTh": "กมลา",
    "subDistrictNameEn": "Kamala",
    "postcode": "83150",
    "districtCode": "8302",
    "districtNameTh": "กะทู้",
    "districtNameEn": "Kathu",
    "provinceCode": "83",
    "provinceNameTh": "ภูเก็ต",
    "provinceNameEn": "Phuket"
},
{
    "subDistrictCode": "830301",
    "subDistrictNameTh": "เทพกระษัตรี",
    "subDistrictNameEn": "Thep Krasatti",
    "postcode": "83110",
    "districtCode": "8303",
    "districtNameTh": "ถลาง",
    "districtNameEn": "Thalang",
    "provinceCode": "83",
    "provinceNameTh": "ภูเก็ต",
    "provinceNameEn": "Phuket"
},
{
    "subDistrictCode": "830302",
    "subDistrictNameTh": "ศรีสุนทร",
    "subDistrictNameEn": "Si Sunthon",
    "postcode": "83110",
    "districtCode": "8303",
    "districtNameTh": "ถลาง",
    "districtNameEn": "Thalang",
    "provinceCode": "83",
    "provinceNameTh": "ภูเก็ต",
    "provinceNameEn": "Phuket"
},
{
    "subDistrictCode": "830303",
    "subDistrictNameTh": "เชิงทะเล",
    "subDistrictNameEn": "Choeng Thale",
    "postcode": "83110",
    "districtCode": "8303",
    "districtNameTh": "ถลาง",
    "districtNameEn": "Thalang",
    "provinceCode": "83",
    "provinceNameTh": "ภูเก็ต",
    "provinceNameEn": "Phuket"
},
{
    "subDistrictCode": "830304",
    "subDistrictNameTh": "ป่าคลอก",
    "subDistrictNameEn": "Pa Khlok",
    "postcode": "83110",
    "districtCode": "8303",
    "districtNameTh": "ถลาง",
    "districtNameEn": "Thalang",
    "provinceCode": "83",
    "provinceNameTh": "ภูเก็ต",
    "provinceNameEn": "Phuket"
},
{
    "subDistrictCode": "830305",
    "subDistrictNameTh": "ไม้ขาว",
    "subDistrictNameEn": "Mai Khao",
    "postcode": "83110",
    "districtCode": "8303",
    "districtNameTh": "ถลาง",
    "districtNameEn": "Thalang",
    "provinceCode": "83",
    "provinceNameTh": "ภูเก็ต",
    "provinceNameEn": "Phuket"
},
{
    "subDistrictCode": "830306",
    "subDistrictNameTh": "สาคู",
    "subDistrictNameEn": "Sakhu",
    "postcode": "83110",
    "districtCode": "8303",
    "districtNameTh": "ถลาง",
    "districtNameEn": "Thalang",
    "provinceCode": "83",
    "provinceNameTh": "ภูเก็ต",
    "provinceNameEn": "Phuket"
},
{
    "subDistrictCode": "840101",
    "subDistrictNameTh": "ตลาด",
    "subDistrictNameEn": "Talat",
    "postcode": "84000",
    "districtCode": "8401",
    "districtNameTh": "เมืองสุราษฎร์ธานี",
    "districtNameEn": "Mueang Surat Thani",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840102",
    "subDistrictNameTh": "มะขามเตี้ย",
    "subDistrictNameEn": "Makham Tia",
    "postcode": "84000",
    "districtCode": "8401",
    "districtNameTh": "เมืองสุราษฎร์ธานี",
    "districtNameEn": "Mueang Surat Thani",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840103",
    "subDistrictNameTh": "วัดประดู่",
    "subDistrictNameEn": "Wat Pradu",
    "postcode": "84000",
    "districtCode": "8401",
    "districtNameTh": "เมืองสุราษฎร์ธานี",
    "districtNameEn": "Mueang Surat Thani",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840104",
    "subDistrictNameTh": "ขุนทะเล",
    "subDistrictNameEn": "Khun Thale",
    "postcode": "84100",
    "districtCode": "8401",
    "districtNameTh": "เมืองสุราษฎร์ธานี",
    "districtNameEn": "Mueang Surat Thani",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840105",
    "subDistrictNameTh": "บางใบไม้",
    "subDistrictNameEn": "Bang Bai Mai",
    "postcode": "84000",
    "districtCode": "8401",
    "districtNameTh": "เมืองสุราษฎร์ธานี",
    "districtNameEn": "Mueang Surat Thani",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840106",
    "subDistrictNameTh": "บางชนะ",
    "subDistrictNameEn": "Bang Chana",
    "postcode": "84000",
    "districtCode": "8401",
    "districtNameTh": "เมืองสุราษฎร์ธานี",
    "districtNameEn": "Mueang Surat Thani",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840107",
    "subDistrictNameTh": "คลองน้อย",
    "subDistrictNameEn": "Khlong Noi",
    "postcode": "84000",
    "districtCode": "8401",
    "districtNameTh": "เมืองสุราษฎร์ธานี",
    "districtNameEn": "Mueang Surat Thani",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840108",
    "subDistrictNameTh": "บางไทร",
    "subDistrictNameEn": "Bang Sai",
    "postcode": "84000",
    "districtCode": "8401",
    "districtNameTh": "เมืองสุราษฎร์ธานี",
    "districtNameEn": "Mueang Surat Thani",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840109",
    "subDistrictNameTh": "บางโพธิ์",
    "subDistrictNameEn": "Bang Pho",
    "postcode": "84000",
    "districtCode": "8401",
    "districtNameTh": "เมืองสุราษฎร์ธานี",
    "districtNameEn": "Mueang Surat Thani",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840110",
    "subDistrictNameTh": "บางกุ้ง",
    "subDistrictNameEn": "Bang Kung",
    "postcode": "84000",
    "districtCode": "8401",
    "districtNameTh": "เมืองสุราษฎร์ธานี",
    "districtNameEn": "Mueang Surat Thani",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840111",
    "subDistrictNameTh": "คลองฉนาก",
    "subDistrictNameEn": "Khlong Chanak",
    "postcode": "84000",
    "districtCode": "8401",
    "districtNameTh": "เมืองสุราษฎร์ธานี",
    "districtNameEn": "Mueang Surat Thani",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840201",
    "subDistrictNameTh": "ท่าทองใหม่",
    "subDistrictNameEn": "Tha Thong Mai",
    "postcode": "84290",
    "districtCode": "8402",
    "districtNameTh": "กาญจนดิษฐ์",
    "districtNameEn": "Kanchanadit",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840202",
    "subDistrictNameTh": "ท่าทอง",
    "subDistrictNameEn": "Tha Thong",
    "postcode": "84160",
    "districtCode": "8402",
    "districtNameTh": "กาญจนดิษฐ์",
    "districtNameEn": "Kanchanadit",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840203",
    "subDistrictNameTh": "กะแดะ",
    "subDistrictNameEn": "Kadae",
    "postcode": "84160",
    "districtCode": "8402",
    "districtNameTh": "กาญจนดิษฐ์",
    "districtNameEn": "Kanchanadit",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840204",
    "subDistrictNameTh": "ทุ่งกง",
    "subDistrictNameEn": "Thung Kong",
    "postcode": "84290",
    "districtCode": "8402",
    "districtNameTh": "กาญจนดิษฐ์",
    "districtNameEn": "Kanchanadit",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840205",
    "subDistrictNameTh": "กรูด",
    "subDistrictNameEn": "Krut",
    "postcode": "84160",
    "districtCode": "8402",
    "districtNameTh": "กาญจนดิษฐ์",
    "districtNameEn": "Kanchanadit",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840206",
    "subDistrictNameTh": "ช้างซ้าย",
    "subDistrictNameEn": "Chang Sai",
    "postcode": "84160",
    "districtCode": "8402",
    "districtNameTh": "กาญจนดิษฐ์",
    "districtNameEn": "Kanchanadit",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840207",
    "subDistrictNameTh": "พลายวาส",
    "subDistrictNameEn": "Phlai Wat",
    "postcode": "84160",
    "districtCode": "8402",
    "districtNameTh": "กาญจนดิษฐ์",
    "districtNameEn": "Kanchanadit",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840208",
    "subDistrictNameTh": "ป่าร่อน",
    "subDistrictNameEn": "Pa Ron",
    "postcode": "84160",
    "districtCode": "8402",
    "districtNameTh": "กาญจนดิษฐ์",
    "districtNameEn": "Kanchanadit",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840209",
    "subDistrictNameTh": "ตะเคียนทอง",
    "subDistrictNameEn": "Takhian Thong",
    "postcode": "84160",
    "districtCode": "8402",
    "districtNameTh": "กาญจนดิษฐ์",
    "districtNameEn": "Kanchanadit",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840210",
    "subDistrictNameTh": "ช้างขวา",
    "subDistrictNameEn": "Chang Khwa",
    "postcode": "84160",
    "districtCode": "8402",
    "districtNameTh": "กาญจนดิษฐ์",
    "districtNameEn": "Kanchanadit",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840211",
    "subDistrictNameTh": "ท่าอุแท",
    "subDistrictNameEn": "Tha U-thae",
    "postcode": "84160",
    "districtCode": "8402",
    "districtNameTh": "กาญจนดิษฐ์",
    "districtNameEn": "Kanchanadit",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840212",
    "subDistrictNameTh": "ทุ่งรัง",
    "subDistrictNameEn": "Thung Rang",
    "postcode": "84290",
    "districtCode": "8402",
    "districtNameTh": "กาญจนดิษฐ์",
    "districtNameEn": "Kanchanadit",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840213",
    "subDistrictNameTh": "คลองสระ",
    "subDistrictNameEn": "Khlong Sa",
    "postcode": "84160",
    "districtCode": "8402",
    "districtNameTh": "กาญจนดิษฐ์",
    "districtNameEn": "Kanchanadit",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840301",
    "subDistrictNameTh": "ดอนสัก",
    "subDistrictNameEn": "Don Sak",
    "postcode": "84220",
    "districtCode": "8403",
    "districtNameTh": "ดอนสัก",
    "districtNameEn": "Don Sak",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840302",
    "subDistrictNameTh": "ชลคราม",
    "subDistrictNameEn": "Chonlakhram",
    "postcode": "84160",
    "districtCode": "8403",
    "districtNameTh": "ดอนสัก",
    "districtNameEn": "Don Sak",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840303",
    "subDistrictNameTh": "ไชยคราม",
    "subDistrictNameEn": "Chaiyakhram",
    "postcode": "84220",
    "districtCode": "8403",
    "districtNameTh": "ดอนสัก",
    "districtNameEn": "Don Sak",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840304",
    "subDistrictNameTh": "ปากแพรก",
    "subDistrictNameEn": "Pak Phraek",
    "postcode": "84340",
    "districtCode": "8403",
    "districtNameTh": "ดอนสัก",
    "districtNameEn": "Don Sak",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840401",
    "subDistrictNameTh": "อ่างทอง",
    "subDistrictNameEn": "Ang Thong",
    "postcode": "84140",
    "districtCode": "8404",
    "districtNameTh": "เกาะสมุย",
    "districtNameEn": "Ko Samui",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840402",
    "subDistrictNameTh": "ลิปะน้อย",
    "subDistrictNameEn": "Lipa Noi",
    "postcode": "84140",
    "districtCode": "8404",
    "districtNameTh": "เกาะสมุย",
    "districtNameEn": "Ko Samui",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840403",
    "subDistrictNameTh": "ตลิ่งงาม",
    "subDistrictNameEn": "Taling Ngam",
    "postcode": "84140",
    "districtCode": "8404",
    "districtNameTh": "เกาะสมุย",
    "districtNameEn": "Ko Samui",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840404",
    "subDistrictNameTh": "หน้าเมือง",
    "subDistrictNameEn": "Na Mueang",
    "postcode": "84140",
    "districtCode": "8404",
    "districtNameTh": "เกาะสมุย",
    "districtNameEn": "Ko Samui",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840405",
    "subDistrictNameTh": "มะเร็ต",
    "subDistrictNameEn": "Maret",
    "postcode": "84310",
    "districtCode": "8404",
    "districtNameTh": "เกาะสมุย",
    "districtNameEn": "Ko Samui",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840406",
    "subDistrictNameTh": "บ่อผุด",
    "subDistrictNameEn": "Bo Phut",
    "postcode": "84320",
    "districtCode": "8404",
    "districtNameTh": "เกาะสมุย",
    "districtNameEn": "Ko Samui",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840407",
    "subDistrictNameTh": "แม่น้ำ",
    "subDistrictNameEn": "Mae Nam",
    "postcode": "84330",
    "districtCode": "8404",
    "districtNameTh": "เกาะสมุย",
    "districtNameEn": "Ko Samui",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840501",
    "subDistrictNameTh": "เกาะพะงัน",
    "subDistrictNameEn": "Ko Pha-ngan",
    "postcode": "84280",
    "districtCode": "8405",
    "districtNameTh": "เกาะพะงัน",
    "districtNameEn": "Ko Pha-ngan",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840502",
    "subDistrictNameTh": "บ้านใต้",
    "subDistrictNameEn": "Ban Tai",
    "postcode": "84280",
    "districtCode": "8405",
    "districtNameTh": "เกาะพะงัน",
    "districtNameEn": "Ko Pha-ngan",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840503",
    "subDistrictNameTh": "เกาะเต่า",
    "subDistrictNameEn": "Ko Tao",
    "postcode": "84360",
    "districtCode": "8405",
    "districtNameTh": "เกาะพะงัน",
    "districtNameEn": "Ko Pha-ngan",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840601",
    "subDistrictNameTh": "ตลาดไชยา",
    "subDistrictNameEn": "Talat Chaiya",
    "postcode": "84110",
    "districtCode": "8406",
    "districtNameTh": "ไชยา",
    "districtNameEn": "Chaiya",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840602",
    "subDistrictNameTh": "พุมเรียง",
    "subDistrictNameEn": "Phum Riang",
    "postcode": "84110",
    "districtCode": "8406",
    "districtNameTh": "ไชยา",
    "districtNameEn": "Chaiya",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840603",
    "subDistrictNameTh": "เลม็ด",
    "subDistrictNameEn": "Lamet",
    "postcode": "84110",
    "districtCode": "8406",
    "districtNameTh": "ไชยา",
    "districtNameEn": "Chaiya",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840604",
    "subDistrictNameTh": "เวียง",
    "subDistrictNameEn": "Wiang",
    "postcode": "84110",
    "districtCode": "8406",
    "districtNameTh": "ไชยา",
    "districtNameEn": "Chaiya",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840605",
    "subDistrictNameTh": "ทุ่ง",
    "subDistrictNameEn": "Thung",
    "postcode": "84110",
    "districtCode": "8406",
    "districtNameTh": "ไชยา",
    "districtNameEn": "Chaiya",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840606",
    "subDistrictNameTh": "ป่าเว",
    "subDistrictNameEn": "Pa We",
    "postcode": "84110",
    "districtCode": "8406",
    "districtNameTh": "ไชยา",
    "districtNameEn": "Chaiya",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840607",
    "subDistrictNameTh": "ตะกรบ",
    "subDistrictNameEn": "Takrop",
    "postcode": "84110",
    "districtCode": "8406",
    "districtNameTh": "ไชยา",
    "districtNameEn": "Chaiya",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840608",
    "subDistrictNameTh": "โมถ่าย",
    "subDistrictNameEn": "Mo Thai",
    "postcode": "84110",
    "districtCode": "8406",
    "districtNameTh": "ไชยา",
    "districtNameEn": "Chaiya",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840609",
    "subDistrictNameTh": "ปากหมาก",
    "subDistrictNameEn": "Pak Mak",
    "postcode": "84110",
    "districtCode": "8406",
    "districtNameTh": "ไชยา",
    "districtNameEn": "Chaiya",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840701",
    "subDistrictNameTh": "ท่าชนะ",
    "subDistrictNameEn": "Tha Chana",
    "postcode": "84170",
    "districtCode": "8407",
    "districtNameTh": "ท่าชนะ",
    "districtNameEn": "Tha Chana",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840702",
    "subDistrictNameTh": "สมอทอง",
    "subDistrictNameEn": "Samo Thong",
    "postcode": "84170",
    "districtCode": "8407",
    "districtNameTh": "ท่าชนะ",
    "districtNameEn": "Tha Chana",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840703",
    "subDistrictNameTh": "ประสงค์",
    "subDistrictNameEn": "Prasong",
    "postcode": "84170",
    "districtCode": "8407",
    "districtNameTh": "ท่าชนะ",
    "districtNameEn": "Tha Chana",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840704",
    "subDistrictNameTh": "คันธุลี",
    "subDistrictNameEn": "Khan Thuli",
    "postcode": "84170",
    "districtCode": "8407",
    "districtNameTh": "ท่าชนะ",
    "districtNameEn": "Tha Chana",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840705",
    "subDistrictNameTh": "วัง",
    "subDistrictNameEn": "Wang",
    "postcode": "84170",
    "districtCode": "8407",
    "districtNameTh": "ท่าชนะ",
    "districtNameEn": "Tha Chana",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840706",
    "subDistrictNameTh": "คลองพา",
    "subDistrictNameEn": "Khlong Pha",
    "postcode": "84170",
    "districtCode": "8407",
    "districtNameTh": "ท่าชนะ",
    "districtNameEn": "Tha Chana",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840801",
    "subDistrictNameTh": "ท่าขนอน",
    "subDistrictNameEn": "Tha Khanon",
    "postcode": "84180",
    "districtCode": "8408",
    "districtNameTh": "คีรีรัฐนิคม",
    "districtNameEn": "Khiri Rat Nikhom",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840802",
    "subDistrictNameTh": "บ้านยาง",
    "subDistrictNameEn": "Ban Yang",
    "postcode": "84180",
    "districtCode": "8408",
    "districtNameTh": "คีรีรัฐนิคม",
    "districtNameEn": "Khiri Rat Nikhom",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840803",
    "subDistrictNameTh": "น้ำหัก",
    "subDistrictNameEn": "Nam Hak",
    "postcode": "84180",
    "districtCode": "8408",
    "districtNameTh": "คีรีรัฐนิคม",
    "districtNameEn": "Khiri Rat Nikhom",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840806",
    "subDistrictNameTh": "กะเปา",
    "subDistrictNameEn": "Kapao",
    "postcode": "84180",
    "districtCode": "8408",
    "districtNameTh": "คีรีรัฐนิคม",
    "districtNameEn": "Khiri Rat Nikhom",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840807",
    "subDistrictNameTh": "ท่ากระดาน",
    "subDistrictNameEn": "Tha Kradan",
    "postcode": "84180",
    "districtCode": "8408",
    "districtNameTh": "คีรีรัฐนิคม",
    "districtNameEn": "Khiri Rat Nikhom",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840808",
    "subDistrictNameTh": "ย่านยาว",
    "subDistrictNameEn": "Yan Yao",
    "postcode": "84180",
    "districtCode": "8408",
    "districtNameTh": "คีรีรัฐนิคม",
    "districtNameEn": "Khiri Rat Nikhom",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840809",
    "subDistrictNameTh": "ถ้ำสิงขร",
    "subDistrictNameEn": "Tham Singkhon",
    "postcode": "84180",
    "districtCode": "8408",
    "districtNameTh": "คีรีรัฐนิคม",
    "districtNameEn": "Khiri Rat Nikhom",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840810",
    "subDistrictNameTh": "บ้านทำเนียบ",
    "subDistrictNameEn": "Ban Thamniap",
    "postcode": "84180",
    "districtCode": "8408",
    "districtNameTh": "คีรีรัฐนิคม",
    "districtNameEn": "Khiri Rat Nikhom",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840901",
    "subDistrictNameTh": "เขาวง",
    "subDistrictNameEn": "Khao Wong",
    "postcode": "84230",
    "districtCode": "8409",
    "districtNameTh": "บ้านตาขุน",
    "districtNameEn": "Ban Ta Khun",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840902",
    "subDistrictNameTh": "พะแสง",
    "subDistrictNameEn": "Phasaeng",
    "postcode": "84230",
    "districtCode": "8409",
    "districtNameTh": "บ้านตาขุน",
    "districtNameEn": "Ban Ta Khun",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840903",
    "subDistrictNameTh": "พรุไทย",
    "subDistrictNameEn": "Phru Thai",
    "postcode": "84230",
    "districtCode": "8409",
    "districtNameTh": "บ้านตาขุน",
    "districtNameEn": "Ban Ta Khun",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "840904",
    "subDistrictNameTh": "เขาพัง",
    "subDistrictNameEn": "Khao Phang",
    "postcode": "84230",
    "districtCode": "8409",
    "districtNameTh": "บ้านตาขุน",
    "districtNameEn": "Ban Ta Khun",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841001",
    "subDistrictNameTh": "พนม",
    "subDistrictNameEn": "Phanom",
    "postcode": "84250",
    "districtCode": "8410",
    "districtNameTh": "พนม",
    "districtNameEn": "Phanom",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841002",
    "subDistrictNameTh": "ต้นยวน",
    "subDistrictNameEn": "Ton Yuan",
    "postcode": "84250",
    "districtCode": "8410",
    "districtNameTh": "พนม",
    "districtNameEn": "Phanom",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841003",
    "subDistrictNameTh": "คลองศก",
    "subDistrictNameEn": "Khlong Sok",
    "postcode": "84250",
    "districtCode": "8410",
    "districtNameTh": "พนม",
    "districtNameEn": "Phanom",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841004",
    "subDistrictNameTh": "พลูเถื่อน",
    "subDistrictNameEn": "Phlu Thuean",
    "postcode": "84250",
    "districtCode": "8410",
    "districtNameTh": "พนม",
    "districtNameEn": "Phanom",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841005",
    "subDistrictNameTh": "พังกาญจน์",
    "subDistrictNameEn": "Phang Kan",
    "postcode": "84250",
    "districtCode": "8410",
    "districtNameTh": "พนม",
    "districtNameEn": "Phanom",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841006",
    "subDistrictNameTh": "คลองชะอุ่น",
    "subDistrictNameEn": "Khlong Cha-un",
    "postcode": "84250",
    "districtCode": "8410",
    "districtNameTh": "พนม",
    "districtNameEn": "Phanom",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841101",
    "subDistrictNameTh": "ท่าฉาง",
    "subDistrictNameEn": "Tha Chang",
    "postcode": "84150",
    "districtCode": "8411",
    "districtNameTh": "ท่าฉาง",
    "districtNameEn": "Tha Chang",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841102",
    "subDistrictNameTh": "ท่าเคย",
    "subDistrictNameEn": "Tha Khoei",
    "postcode": "84150",
    "districtCode": "8411",
    "districtNameTh": "ท่าฉาง",
    "districtNameEn": "Tha Chang",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841103",
    "subDistrictNameTh": "คลองไทร",
    "subDistrictNameEn": "Khlong Sai",
    "postcode": "84150",
    "districtCode": "8411",
    "districtNameTh": "ท่าฉาง",
    "districtNameEn": "Tha Chang",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841104",
    "subDistrictNameTh": "เขาถ่าน",
    "subDistrictNameEn": "Khao Than",
    "postcode": "84150",
    "districtCode": "8411",
    "districtNameTh": "ท่าฉาง",
    "districtNameEn": "Tha Chang",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841105",
    "subDistrictNameTh": "เสวียด",
    "subDistrictNameEn": "Sawiat",
    "postcode": "84150",
    "districtCode": "8411",
    "districtNameTh": "ท่าฉาง",
    "districtNameEn": "Tha Chang",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841106",
    "subDistrictNameTh": "ปากฉลุย",
    "subDistrictNameEn": "Pak Chalui",
    "postcode": "84150",
    "districtCode": "8411",
    "districtNameTh": "ท่าฉาง",
    "districtNameEn": "Tha Chang",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841201",
    "subDistrictNameTh": "นาสาร",
    "subDistrictNameEn": "Na San",
    "postcode": "84120",
    "districtCode": "8412",
    "districtNameTh": "บ้านนาสาร",
    "districtNameEn": "Ban Na San",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841202",
    "subDistrictNameTh": "พรุพี",
    "subDistrictNameEn": "Phru Phi",
    "postcode": "84270",
    "districtCode": "8412",
    "districtNameTh": "บ้านนาสาร",
    "districtNameEn": "Ban Na San",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841203",
    "subDistrictNameTh": "ทุ่งเตา",
    "subDistrictNameEn": "Thung Tao",
    "postcode": "84120",
    "districtCode": "8412",
    "districtNameTh": "บ้านนาสาร",
    "districtNameEn": "Ban Na San",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841204",
    "subDistrictNameTh": "ลำพูน",
    "subDistrictNameEn": "Lamphun",
    "postcode": "84120",
    "districtCode": "8412",
    "districtNameTh": "บ้านนาสาร",
    "districtNameEn": "Ban Na San",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841205",
    "subDistrictNameTh": "ท่าชี",
    "subDistrictNameEn": "Tha Chi",
    "postcode": "84120",
    "districtCode": "8412",
    "districtNameTh": "บ้านนาสาร",
    "districtNameEn": "Ban Na San",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841206",
    "subDistrictNameTh": "ควนศรี",
    "subDistrictNameEn": "Khuan Si",
    "postcode": "84270",
    "districtCode": "8412",
    "districtNameTh": "บ้านนาสาร",
    "districtNameEn": "Ban Na San",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841207",
    "subDistrictNameTh": "ควนสุบรรณ",
    "subDistrictNameEn": "Khuan Suban",
    "postcode": "84120",
    "districtCode": "8412",
    "districtNameTh": "บ้านนาสาร",
    "districtNameEn": "Ban Na San",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841208",
    "subDistrictNameTh": "คลองปราบ",
    "subDistrictNameEn": "Khlong Prap",
    "postcode": "84120",
    "districtCode": "8412",
    "districtNameTh": "บ้านนาสาร",
    "districtNameEn": "Ban Na San",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841209",
    "subDistrictNameTh": "น้ำพุ",
    "subDistrictNameEn": "Nam Phu",
    "postcode": "84120",
    "districtCode": "8412",
    "districtNameTh": "บ้านนาสาร",
    "districtNameEn": "Ban Na San",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841210",
    "subDistrictNameTh": "ทุ่งเตาใหม่",
    "subDistrictNameEn": "Thung Tao Mai",
    "postcode": "84120",
    "districtCode": "8412",
    "districtNameTh": "บ้านนาสาร",
    "districtNameEn": "Ban Na San",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841211",
    "subDistrictNameTh": "เพิ่มพูนทรัพย์",
    "subDistrictNameEn": "Phoem Phun Sap",
    "postcode": "84120",
    "districtCode": "8412",
    "districtNameTh": "บ้านนาสาร",
    "districtNameEn": "Ban Na San",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841301",
    "subDistrictNameTh": "บ้านนา",
    "subDistrictNameEn": "Ban Na",
    "postcode": "84240",
    "districtCode": "8413",
    "districtNameTh": "บ้านนาเดิม",
    "districtNameEn": "Ban Na Doem",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841302",
    "subDistrictNameTh": "ท่าเรือ",
    "subDistrictNameEn": "Tha Ruea",
    "postcode": "84240",
    "districtCode": "8413",
    "districtNameTh": "บ้านนาเดิม",
    "districtNameEn": "Ban Na Doem",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841303",
    "subDistrictNameTh": "ทรัพย์ทวี",
    "subDistrictNameEn": "Sap Thawi",
    "postcode": "84240",
    "districtCode": "8413",
    "districtNameTh": "บ้านนาเดิม",
    "districtNameEn": "Ban Na Doem",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841304",
    "subDistrictNameTh": "นาใต้",
    "subDistrictNameEn": "Na Tai",
    "postcode": "84240",
    "districtCode": "8413",
    "districtNameTh": "บ้านนาเดิม",
    "districtNameEn": "Ban Na Doem",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841401",
    "subDistrictNameTh": "เคียนซา",
    "subDistrictNameEn": "Khian Sa",
    "postcode": "84260",
    "districtCode": "8414",
    "districtNameTh": "เคียนซา",
    "districtNameEn": "Khian Sa",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841402",
    "subDistrictNameTh": "พ่วงพรมคร",
    "subDistrictNameEn": "Phuang Phrom Khon",
    "postcode": "84210",
    "districtCode": "8414",
    "districtNameTh": "เคียนซา",
    "districtNameEn": "Khian Sa",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841403",
    "subDistrictNameTh": "เขาตอก",
    "subDistrictNameEn": "Khao Tok",
    "postcode": "84260",
    "districtCode": "8414",
    "districtNameTh": "เคียนซา",
    "districtNameEn": "Khian Sa",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841404",
    "subDistrictNameTh": "อรัญคามวารี",
    "subDistrictNameEn": "Aranyakham Wari",
    "postcode": "84260",
    "districtCode": "8414",
    "districtNameTh": "เคียนซา",
    "districtNameEn": "Khian Sa",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841405",
    "subDistrictNameTh": "บ้านเสด็จ",
    "subDistrictNameEn": "Ban Sadet",
    "postcode": "84260",
    "districtCode": "8414",
    "districtNameTh": "เคียนซา",
    "districtNameEn": "Khian Sa",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841501",
    "subDistrictNameTh": "เวียงสระ",
    "subDistrictNameEn": "Wiang Sa",
    "postcode": "84190",
    "districtCode": "8415",
    "districtNameTh": "เวียงสระ",
    "districtNameEn": "Wiang Sa",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841502",
    "subDistrictNameTh": "บ้านส้อง",
    "subDistrictNameEn": "Ban Song",
    "postcode": "84190",
    "districtCode": "8415",
    "districtNameTh": "เวียงสระ",
    "districtNameEn": "Wiang Sa",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841503",
    "subDistrictNameTh": "คลองฉนวน",
    "subDistrictNameEn": "Khlong Chanuan",
    "postcode": "84190",
    "districtCode": "8415",
    "districtNameTh": "เวียงสระ",
    "districtNameEn": "Wiang Sa",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841504",
    "subDistrictNameTh": "ทุ่งหลวง",
    "subDistrictNameEn": "Thung Luang",
    "postcode": "84190",
    "districtCode": "8415",
    "districtNameTh": "เวียงสระ",
    "districtNameEn": "Wiang Sa",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841505",
    "subDistrictNameTh": "เขานิพันธ์",
    "subDistrictNameEn": "Khao Niphan",
    "postcode": "84190",
    "districtCode": "8415",
    "districtNameTh": "เวียงสระ",
    "districtNameEn": "Wiang Sa",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841601",
    "subDistrictNameTh": "อิปัน",
    "subDistrictNameEn": "I-pan",
    "postcode": "84210",
    "districtCode": "8416",
    "districtNameTh": "พระแสง",
    "districtNameEn": "Phrasaeng",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841602",
    "subDistrictNameTh": "สินปุน",
    "subDistrictNameEn": "Sin Pun",
    "postcode": "84210",
    "districtCode": "8416",
    "districtNameTh": "พระแสง",
    "districtNameEn": "Phrasaeng",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841603",
    "subDistrictNameTh": "บางสวรรค์",
    "subDistrictNameEn": "Bang Sawan",
    "postcode": "84210",
    "districtCode": "8416",
    "districtNameTh": "พระแสง",
    "districtNameEn": "Phrasaeng",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841604",
    "subDistrictNameTh": "ไทรขึง",
    "subDistrictNameEn": "Sai Khueng",
    "postcode": "84210",
    "districtCode": "8416",
    "districtNameTh": "พระแสง",
    "districtNameEn": "Phrasaeng",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841605",
    "subDistrictNameTh": "สินเจริญ",
    "subDistrictNameEn": "Sin Charoen",
    "postcode": "84210",
    "districtCode": "8416",
    "districtNameTh": "พระแสง",
    "districtNameEn": "Phrasaeng",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841606",
    "subDistrictNameTh": "ไทรโสภา",
    "subDistrictNameEn": "Sai Sopha",
    "postcode": "84210",
    "districtCode": "8416",
    "districtNameTh": "พระแสง",
    "districtNameEn": "Phrasaeng",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841607",
    "subDistrictNameTh": "สาคู",
    "subDistrictNameEn": "Sakhu",
    "postcode": "84210",
    "districtCode": "8416",
    "districtNameTh": "พระแสง",
    "districtNameEn": "Phrasaeng",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841701",
    "subDistrictNameTh": "ท่าข้าม",
    "subDistrictNameEn": "Tha Kham",
    "postcode": "84130",
    "districtCode": "8417",
    "districtNameTh": "พุนพิน",
    "districtNameEn": "Phunphin",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841702",
    "subDistrictNameTh": "ท่าสะท้อน",
    "subDistrictNameEn": "Tha Sathon",
    "postcode": "84130",
    "districtCode": "8417",
    "districtNameTh": "พุนพิน",
    "districtNameEn": "Phunphin",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841703",
    "subDistrictNameTh": "ลีเล็ด",
    "subDistrictNameEn": "Lilet",
    "postcode": "84130",
    "districtCode": "8417",
    "districtNameTh": "พุนพิน",
    "districtNameEn": "Phunphin",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841704",
    "subDistrictNameTh": "บางมะเดื่อ",
    "subDistrictNameEn": "Bang Maduea",
    "postcode": "84130",
    "districtCode": "8417",
    "districtNameTh": "พุนพิน",
    "districtNameEn": "Phunphin",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841705",
    "subDistrictNameTh": "บางเดือน",
    "subDistrictNameEn": "Bang Duean",
    "postcode": "84130",
    "districtCode": "8417",
    "districtNameTh": "พุนพิน",
    "districtNameEn": "Phunphin",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841706",
    "subDistrictNameTh": "ท่าโรงช้าง",
    "subDistrictNameEn": "Tha Rong Chang",
    "postcode": "84130",
    "districtCode": "8417",
    "districtNameTh": "พุนพิน",
    "districtNameEn": "Phunphin",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841707",
    "subDistrictNameTh": "กรูด",
    "subDistrictNameEn": "Krut",
    "postcode": "84130",
    "districtCode": "8417",
    "districtNameTh": "พุนพิน",
    "districtNameEn": "Phunphin",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841708",
    "subDistrictNameTh": "พุนพิน",
    "subDistrictNameEn": "Phunphin",
    "postcode": "84130",
    "districtCode": "8417",
    "districtNameTh": "พุนพิน",
    "districtNameEn": "Phunphin",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841709",
    "subDistrictNameTh": "บางงอน",
    "subDistrictNameEn": "Bang Ngon",
    "postcode": "84130",
    "districtCode": "8417",
    "districtNameTh": "พุนพิน",
    "districtNameEn": "Phunphin",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841710",
    "subDistrictNameTh": "ศรีวิชัย",
    "subDistrictNameEn": "Si Wichai",
    "postcode": "84130",
    "districtCode": "8417",
    "districtNameTh": "พุนพิน",
    "districtNameEn": "Phunphin",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841711",
    "subDistrictNameTh": "น้ำรอบ",
    "subDistrictNameEn": "Nam Rop",
    "postcode": "84130",
    "districtCode": "8417",
    "districtNameTh": "พุนพิน",
    "districtNameEn": "Phunphin",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841712",
    "subDistrictNameTh": "มะลวน",
    "subDistrictNameEn": "Maluan",
    "postcode": "84130",
    "districtCode": "8417",
    "districtNameTh": "พุนพิน",
    "districtNameEn": "Phunphin",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841713",
    "subDistrictNameTh": "หัวเตย",
    "subDistrictNameEn": "Hua Toei",
    "postcode": "84130",
    "districtCode": "8417",
    "districtNameTh": "พุนพิน",
    "districtNameEn": "Phunphin",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841714",
    "subDistrictNameTh": "หนองไทร",
    "subDistrictNameEn": "Nong Sai",
    "postcode": "84130",
    "districtCode": "8417",
    "districtNameTh": "พุนพิน",
    "districtNameEn": "Phunphin",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841715",
    "subDistrictNameTh": "เขาหัวควาย",
    "subDistrictNameEn": "Khao Hua Khwai",
    "postcode": "84130",
    "districtCode": "8417",
    "districtNameTh": "พุนพิน",
    "districtNameEn": "Phunphin",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841716",
    "subDistrictNameTh": "ตะปาน",
    "subDistrictNameEn": "Tapan",
    "postcode": "84130",
    "districtCode": "8417",
    "districtNameTh": "พุนพิน",
    "districtNameEn": "Phunphin",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841801",
    "subDistrictNameTh": "สองแพรก",
    "subDistrictNameEn": "Song Phraek",
    "postcode": "84350",
    "districtCode": "8418",
    "districtNameTh": "ชัยบุรี",
    "districtNameEn": "Chai Buri",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841802",
    "subDistrictNameTh": "ชัยบุรี",
    "subDistrictNameEn": "Chai Buri",
    "postcode": "84350",
    "districtCode": "8418",
    "districtNameTh": "ชัยบุรี",
    "districtNameEn": "Chai Buri",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841803",
    "subDistrictNameTh": "คลองน้อย",
    "subDistrictNameEn": "Khlong Noi",
    "postcode": "84350",
    "districtCode": "8418",
    "districtNameTh": "ชัยบุรี",
    "districtNameEn": "Chai Buri",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841804",
    "subDistrictNameTh": "ไทรทอง",
    "subDistrictNameEn": "Sai Thong",
    "postcode": "84350",
    "districtCode": "8418",
    "districtNameTh": "ชัยบุรี",
    "districtNameEn": "Chai Buri",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841901",
    "subDistrictNameTh": "ตะกุกใต้",
    "subDistrictNameEn": "Takuk Tai",
    "postcode": "84370",
    "districtCode": "8419",
    "districtNameTh": "วิภาวดี",
    "districtNameEn": "Vibhavadi",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "841902",
    "subDistrictNameTh": "ตะกุกเหนือ",
    "subDistrictNameEn": "Takuk Nuea",
    "postcode": "84370",
    "districtCode": "8419",
    "districtNameTh": "วิภาวดี",
    "districtNameEn": "Vibhavadi",
    "provinceCode": "84",
    "provinceNameTh": "สุราษฎร์ธานี",
    "provinceNameEn": "Surat Thani"
},
{
    "subDistrictCode": "850101",
    "subDistrictNameTh": "เขานิเวศน์",
    "subDistrictNameEn": "Khao Niwet",
    "postcode": "85000",
    "districtCode": "8501",
    "districtNameTh": "เมืองระนอง",
    "districtNameEn": "Mueang Ranong",
    "provinceCode": "85",
    "provinceNameTh": "ระนอง",
    "provinceNameEn": "Ranong"
},
{
    "subDistrictCode": "850102",
    "subDistrictNameTh": "ราชกรูด",
    "subDistrictNameEn": "Ratchakrut",
    "postcode": "85000",
    "districtCode": "8501",
    "districtNameTh": "เมืองระนอง",
    "districtNameEn": "Mueang Ranong",
    "provinceCode": "85",
    "provinceNameTh": "ระนอง",
    "provinceNameEn": "Ranong"
},
{
    "subDistrictCode": "850103",
    "subDistrictNameTh": "หงาว",
    "subDistrictNameEn": "Ngao",
    "postcode": "85000",
    "districtCode": "8501",
    "districtNameTh": "เมืองระนอง",
    "districtNameEn": "Mueang Ranong",
    "provinceCode": "85",
    "provinceNameTh": "ระนอง",
    "provinceNameEn": "Ranong"
},
{
    "subDistrictCode": "850104",
    "subDistrictNameTh": "บางริ้น",
    "subDistrictNameEn": "Bang Rin",
    "postcode": "85000",
    "districtCode": "8501",
    "districtNameTh": "เมืองระนอง",
    "districtNameEn": "Mueang Ranong",
    "provinceCode": "85",
    "provinceNameTh": "ระนอง",
    "provinceNameEn": "Ranong"
},
{
    "subDistrictCode": "850105",
    "subDistrictNameTh": "ปากน้ำ",
    "subDistrictNameEn": "Pak Nam",
    "postcode": "85000",
    "districtCode": "8501",
    "districtNameTh": "เมืองระนอง",
    "districtNameEn": "Mueang Ranong",
    "provinceCode": "85",
    "provinceNameTh": "ระนอง",
    "provinceNameEn": "Ranong"
},
{
    "subDistrictCode": "850106",
    "subDistrictNameTh": "บางนอน",
    "subDistrictNameEn": "Bang Non",
    "postcode": "85000",
    "districtCode": "8501",
    "districtNameTh": "เมืองระนอง",
    "districtNameEn": "Mueang Ranong",
    "provinceCode": "85",
    "provinceNameTh": "ระนอง",
    "provinceNameEn": "Ranong"
},
{
    "subDistrictCode": "850107",
    "subDistrictNameTh": "หาดส้มแป้น",
    "subDistrictNameEn": "Hat Som Paen",
    "postcode": "85000",
    "districtCode": "8501",
    "districtNameTh": "เมืองระนอง",
    "districtNameEn": "Mueang Ranong",
    "provinceCode": "85",
    "provinceNameTh": "ระนอง",
    "provinceNameEn": "Ranong"
},
{
    "subDistrictCode": "850108",
    "subDistrictNameTh": "ทรายแดง",
    "subDistrictNameEn": "Sai Daeng",
    "postcode": "85130",
    "districtCode": "8501",
    "districtNameTh": "เมืองระนอง",
    "districtNameEn": "Mueang Ranong",
    "provinceCode": "85",
    "provinceNameTh": "ระนอง",
    "provinceNameEn": "Ranong"
},
{
    "subDistrictCode": "850109",
    "subDistrictNameTh": "เกาะพยาม",
    "subDistrictNameEn": "Ko Phayam",
    "postcode": "85000",
    "districtCode": "8501",
    "districtNameTh": "เมืองระนอง",
    "districtNameEn": "Mueang Ranong",
    "provinceCode": "85",
    "provinceNameTh": "ระนอง",
    "provinceNameEn": "Ranong"
},
{
    "subDistrictCode": "850201",
    "subDistrictNameTh": "ละอุ่นใต้",
    "subDistrictNameEn": "La-un Tai",
    "postcode": "85130",
    "districtCode": "8502",
    "districtNameTh": "ละอุ่น",
    "districtNameEn": "La-un",
    "provinceCode": "85",
    "provinceNameTh": "ระนอง",
    "provinceNameEn": "Ranong"
},
{
    "subDistrictCode": "850202",
    "subDistrictNameTh": "ละอุ่นเหนือ",
    "subDistrictNameEn": "La-un Nuea",
    "postcode": "85130",
    "districtCode": "8502",
    "districtNameTh": "ละอุ่น",
    "districtNameEn": "La-un",
    "provinceCode": "85",
    "provinceNameTh": "ระนอง",
    "provinceNameEn": "Ranong"
},
{
    "subDistrictCode": "850203",
    "subDistrictNameTh": "บางพระใต้",
    "subDistrictNameEn": "Bang Phra Tai",
    "postcode": "85130",
    "districtCode": "8502",
    "districtNameTh": "ละอุ่น",
    "districtNameEn": "La-un",
    "provinceCode": "85",
    "provinceNameTh": "ระนอง",
    "provinceNameEn": "Ranong"
},
{
    "subDistrictCode": "850204",
    "subDistrictNameTh": "บางพระเหนือ",
    "subDistrictNameEn": "Bang Phra Nuea",
    "postcode": "85130",
    "districtCode": "8502",
    "districtNameTh": "ละอุ่น",
    "districtNameEn": "La-un",
    "provinceCode": "85",
    "provinceNameTh": "ระนอง",
    "provinceNameEn": "Ranong"
},
{
    "subDistrictCode": "850205",
    "subDistrictNameTh": "บางแก้ว",
    "subDistrictNameEn": "Bang Kaeo",
    "postcode": "85130",
    "districtCode": "8502",
    "districtNameTh": "ละอุ่น",
    "districtNameEn": "La-un",
    "provinceCode": "85",
    "provinceNameTh": "ระนอง",
    "provinceNameEn": "Ranong"
},
{
    "subDistrictCode": "850206",
    "subDistrictNameTh": "ในวงเหนือ",
    "subDistrictNameEn": "Nai Wong Nuea",
    "postcode": "85130",
    "districtCode": "8502",
    "districtNameTh": "ละอุ่น",
    "districtNameEn": "La-un",
    "provinceCode": "85",
    "provinceNameTh": "ระนอง",
    "provinceNameEn": "Ranong"
},
{
    "subDistrictCode": "850207",
    "subDistrictNameTh": "ในวงใต้",
    "subDistrictNameEn": "Nai Wong Tai",
    "postcode": "85130",
    "districtCode": "8502",
    "districtNameTh": "ละอุ่น",
    "districtNameEn": "La-un",
    "provinceCode": "85",
    "provinceNameTh": "ระนอง",
    "provinceNameEn": "Ranong"
},
{
    "subDistrictCode": "850301",
    "subDistrictNameTh": "ม่วงกลวง",
    "subDistrictNameEn": "Muang Kluang",
    "postcode": "85120",
    "districtCode": "8503",
    "districtNameTh": "กะเปอร์",
    "districtNameEn": "Kapoe",
    "provinceCode": "85",
    "provinceNameTh": "ระนอง",
    "provinceNameEn": "Ranong"
},
{
    "subDistrictCode": "850302",
    "subDistrictNameTh": "กะเปอร์",
    "subDistrictNameEn": "Kapoe",
    "postcode": "85120",
    "districtCode": "8503",
    "districtNameTh": "กะเปอร์",
    "districtNameEn": "Kapoe",
    "provinceCode": "85",
    "provinceNameTh": "ระนอง",
    "provinceNameEn": "Ranong"
},
{
    "subDistrictCode": "850303",
    "subDistrictNameTh": "เชี่ยวเหลียง",
    "subDistrictNameEn": "Chiao Liang",
    "postcode": "85120",
    "districtCode": "8503",
    "districtNameTh": "กะเปอร์",
    "districtNameEn": "Kapoe",
    "provinceCode": "85",
    "provinceNameTh": "ระนอง",
    "provinceNameEn": "Ranong"
},
{
    "subDistrictCode": "850304",
    "subDistrictNameTh": "บ้านนา",
    "subDistrictNameEn": "Ban Na",
    "postcode": "85120",
    "districtCode": "8503",
    "districtNameTh": "กะเปอร์",
    "districtNameEn": "Kapoe",
    "provinceCode": "85",
    "provinceNameTh": "ระนอง",
    "provinceNameEn": "Ranong"
},
{
    "subDistrictCode": "850305",
    "subDistrictNameTh": "บางหิน",
    "subDistrictNameEn": "Bang Hin",
    "postcode": "85120",
    "districtCode": "8503",
    "districtNameTh": "กะเปอร์",
    "districtNameEn": "Kapoe",
    "provinceCode": "85",
    "provinceNameTh": "ระนอง",
    "provinceNameEn": "Ranong"
},
{
    "subDistrictCode": "850401",
    "subDistrictNameTh": "น้ำจืด",
    "subDistrictNameEn": "Nam Chuet",
    "postcode": "85110",
    "districtCode": "8504",
    "districtNameTh": "กระบุรี",
    "districtNameEn": "Kra Buri",
    "provinceCode": "85",
    "provinceNameTh": "ระนอง",
    "provinceNameEn": "Ranong"
},
{
    "subDistrictCode": "850402",
    "subDistrictNameTh": "น้ำจืดน้อย",
    "subDistrictNameEn": "Nam Chuet Noi",
    "postcode": "85110",
    "districtCode": "8504",
    "districtNameTh": "กระบุรี",
    "districtNameEn": "Kra Buri",
    "provinceCode": "85",
    "provinceNameTh": "ระนอง",
    "provinceNameEn": "Ranong"
},
{
    "subDistrictCode": "850403",
    "subDistrictNameTh": "มะมุ",
    "subDistrictNameEn": "Mamu",
    "postcode": "85110",
    "districtCode": "8504",
    "districtNameTh": "กระบุรี",
    "districtNameEn": "Kra Buri",
    "provinceCode": "85",
    "provinceNameTh": "ระนอง",
    "provinceNameEn": "Ranong"
},
{
    "subDistrictCode": "850404",
    "subDistrictNameTh": "ปากจั่น",
    "subDistrictNameEn": "Pak Chan",
    "postcode": "85110",
    "districtCode": "8504",
    "districtNameTh": "กระบุรี",
    "districtNameEn": "Kra Buri",
    "provinceCode": "85",
    "provinceNameTh": "ระนอง",
    "provinceNameEn": "Ranong"
},
{
    "subDistrictCode": "850405",
    "subDistrictNameTh": "ลำเลียง",
    "subDistrictNameEn": "Lam Liang",
    "postcode": "85110",
    "districtCode": "8504",
    "districtNameTh": "กระบุรี",
    "districtNameEn": "Kra Buri",
    "provinceCode": "85",
    "provinceNameTh": "ระนอง",
    "provinceNameEn": "Ranong"
},
{
    "subDistrictCode": "850406",
    "subDistrictNameTh": "จ.ป.ร.",
    "subDistrictNameEn": "Choporo",
    "postcode": "85110",
    "districtCode": "8504",
    "districtNameTh": "กระบุรี",
    "districtNameEn": "Kra Buri",
    "provinceCode": "85",
    "provinceNameTh": "ระนอง",
    "provinceNameEn": "Ranong"
},
{
    "subDistrictCode": "850407",
    "subDistrictNameTh": "บางใหญ่",
    "subDistrictNameEn": "Bang Yai",
    "postcode": "85110",
    "districtCode": "8504",
    "districtNameTh": "กระบุรี",
    "districtNameEn": "Kra Buri",
    "provinceCode": "85",
    "provinceNameTh": "ระนอง",
    "provinceNameEn": "Ranong"
},
{
    "subDistrictCode": "850501",
    "subDistrictNameTh": "นาคา",
    "subDistrictNameEn": "Nakha",
    "postcode": "85120",
    "districtCode": "8505",
    "districtNameTh": "สุขสำราญ",
    "districtNameEn": "Suk Samran",
    "provinceCode": "85",
    "provinceNameTh": "ระนอง",
    "provinceNameEn": "Ranong"
},
{
    "subDistrictCode": "850502",
    "subDistrictNameTh": "กำพวน",
    "subDistrictNameEn": "Kamphuan",
    "postcode": "85120",
    "districtCode": "8505",
    "districtNameTh": "สุขสำราญ",
    "districtNameEn": "Suk Samran",
    "provinceCode": "85",
    "provinceNameTh": "ระนอง",
    "provinceNameEn": "Ranong"
},
{
    "subDistrictCode": "860101",
    "subDistrictNameTh": "ท่าตะเภา",
    "subDistrictNameEn": "Tha Taphao",
    "postcode": "86000",
    "districtCode": "8601",
    "districtNameTh": "เมืองชุมพร",
    "districtNameEn": "Mueang Chumphon",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860102",
    "subDistrictNameTh": "ปากน้ำ",
    "subDistrictNameEn": "Pak Nam",
    "postcode": "86120",
    "districtCode": "8601",
    "districtNameTh": "เมืองชุมพร",
    "districtNameEn": "Mueang Chumphon",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860103",
    "subDistrictNameTh": "ท่ายาง",
    "subDistrictNameEn": "Tha Yang",
    "postcode": "86000",
    "districtCode": "8601",
    "districtNameTh": "เมืองชุมพร",
    "districtNameEn": "Mueang Chumphon",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860104",
    "subDistrictNameTh": "บางหมาก",
    "subDistrictNameEn": "Bang Mak",
    "postcode": "86000",
    "districtCode": "8601",
    "districtNameTh": "เมืองชุมพร",
    "districtNameEn": "Mueang Chumphon",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860105",
    "subDistrictNameTh": "นาทุ่ง",
    "subDistrictNameEn": "Na Thung",
    "postcode": "86000",
    "districtCode": "8601",
    "districtNameTh": "เมืองชุมพร",
    "districtNameEn": "Mueang Chumphon",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860106",
    "subDistrictNameTh": "นาชะอัง",
    "subDistrictNameEn": "Na Cha-ang",
    "postcode": "86000",
    "districtCode": "8601",
    "districtNameTh": "เมืองชุมพร",
    "districtNameEn": "Mueang Chumphon",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860107",
    "subDistrictNameTh": "ตากแดด",
    "subDistrictNameEn": "Tak Daet",
    "postcode": "86000",
    "districtCode": "8601",
    "districtNameTh": "เมืองชุมพร",
    "districtNameEn": "Mueang Chumphon",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860108",
    "subDistrictNameTh": "บางลึก",
    "subDistrictNameEn": "Bang Luek",
    "postcode": "86000",
    "districtCode": "8601",
    "districtNameTh": "เมืองชุมพร",
    "districtNameEn": "Mueang Chumphon",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860109",
    "subDistrictNameTh": "หาดพันไกร",
    "subDistrictNameEn": "Hat Phan Krai",
    "postcode": "86000",
    "districtCode": "8601",
    "districtNameTh": "เมืองชุมพร",
    "districtNameEn": "Mueang Chumphon",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860110",
    "subDistrictNameTh": "วังไผ่",
    "subDistrictNameEn": "Wang Phai",
    "postcode": "86000",
    "districtCode": "8601",
    "districtNameTh": "เมืองชุมพร",
    "districtNameEn": "Mueang Chumphon",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860111",
    "subDistrictNameTh": "วังใหม่",
    "subDistrictNameEn": "Wang Mai",
    "postcode": "86190",
    "districtCode": "8601",
    "districtNameTh": "เมืองชุมพร",
    "districtNameEn": "Mueang Chumphon",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860112",
    "subDistrictNameTh": "บ้านนา",
    "subDistrictNameEn": "Ban Na",
    "postcode": "86190",
    "districtCode": "8601",
    "districtNameTh": "เมืองชุมพร",
    "districtNameEn": "Mueang Chumphon",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860113",
    "subDistrictNameTh": "ขุนกระทิง",
    "subDistrictNameEn": "Khun Krathing",
    "postcode": "86190",
    "districtCode": "8601",
    "districtNameTh": "เมืองชุมพร",
    "districtNameEn": "Mueang Chumphon",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860114",
    "subDistrictNameTh": "ทุ่งคา",
    "subDistrictNameEn": "Thung Kha",
    "postcode": "86100",
    "districtCode": "8601",
    "districtNameTh": "เมืองชุมพร",
    "districtNameEn": "Mueang Chumphon",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860115",
    "subDistrictNameTh": "วิสัยเหนือ",
    "subDistrictNameEn": "Wisai Nuea",
    "postcode": "86100",
    "districtCode": "8601",
    "districtNameTh": "เมืองชุมพร",
    "districtNameEn": "Mueang Chumphon",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860116",
    "subDistrictNameTh": "หาดทรายรี",
    "subDistrictNameEn": "Hat Sai Ri",
    "postcode": "86120",
    "districtCode": "8601",
    "districtNameTh": "เมืองชุมพร",
    "districtNameEn": "Mueang Chumphon",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860117",
    "subDistrictNameTh": "ถ้ำสิงห์",
    "subDistrictNameEn": "Tham Sing",
    "postcode": "86100",
    "districtCode": "8601",
    "districtNameTh": "เมืองชุมพร",
    "districtNameEn": "Mueang Chumphon",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860201",
    "subDistrictNameTh": "ท่าแซะ",
    "subDistrictNameEn": "Tha Sae",
    "postcode": "86140",
    "districtCode": "8602",
    "districtNameTh": "ท่าแซะ",
    "districtNameEn": "Tha Sae",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860202",
    "subDistrictNameTh": "คุริง",
    "subDistrictNameEn": "Khuring",
    "postcode": "86140",
    "districtCode": "8602",
    "districtNameTh": "ท่าแซะ",
    "districtNameEn": "Tha Sae",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860203",
    "subDistrictNameTh": "สลุย",
    "subDistrictNameEn": "Salui",
    "postcode": "86140",
    "districtCode": "8602",
    "districtNameTh": "ท่าแซะ",
    "districtNameEn": "Tha Sae",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860204",
    "subDistrictNameTh": "นากระตาม",
    "subDistrictNameEn": "Na Kratam",
    "postcode": "86140",
    "districtCode": "8602",
    "districtNameTh": "ท่าแซะ",
    "districtNameEn": "Tha Sae",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860205",
    "subDistrictNameTh": "รับร่อ",
    "subDistrictNameEn": "Rap Ro",
    "postcode": "86190",
    "districtCode": "8602",
    "districtNameTh": "ท่าแซะ",
    "districtNameEn": "Tha Sae",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860206",
    "subDistrictNameTh": "ท่าข้าม",
    "subDistrictNameEn": "Tha Kham",
    "postcode": "86140",
    "districtCode": "8602",
    "districtNameTh": "ท่าแซะ",
    "districtNameEn": "Tha Sae",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860207",
    "subDistrictNameTh": "หงษ์เจริญ",
    "subDistrictNameEn": "Hong Charoen",
    "postcode": "86140",
    "districtCode": "8602",
    "districtNameTh": "ท่าแซะ",
    "districtNameEn": "Tha Sae",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860208",
    "subDistrictNameTh": "หินแก้ว",
    "subDistrictNameEn": "Hin Kaeo",
    "postcode": "86190",
    "districtCode": "8602",
    "districtNameTh": "ท่าแซะ",
    "districtNameEn": "Tha Sae",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860209",
    "subDistrictNameTh": "ทรัพย์อนันต์",
    "subDistrictNameEn": "Sap Anan",
    "postcode": "86140",
    "districtCode": "8602",
    "districtNameTh": "ท่าแซะ",
    "districtNameEn": "Tha Sae",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860210",
    "subDistrictNameTh": "สองพี่น้อง",
    "subDistrictNameEn": "Song Phi Nong",
    "postcode": "86140",
    "districtCode": "8602",
    "districtNameTh": "ท่าแซะ",
    "districtNameEn": "Tha Sae",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860301",
    "subDistrictNameTh": "บางสน",
    "subDistrictNameEn": "Bang Son",
    "postcode": "86160",
    "districtCode": "8603",
    "districtNameTh": "ปะทิว",
    "districtNameEn": "Pathio",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860302",
    "subDistrictNameTh": "ทะเลทรัพย์",
    "subDistrictNameEn": "Thale Sap",
    "postcode": "86160",
    "districtCode": "8603",
    "districtNameTh": "ปะทิว",
    "districtNameEn": "Pathio",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860303",
    "subDistrictNameTh": "สะพลี",
    "subDistrictNameEn": "Saphli",
    "postcode": "86230",
    "districtCode": "8603",
    "districtNameTh": "ปะทิว",
    "districtNameEn": "Pathio",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860304",
    "subDistrictNameTh": "ชุมโค",
    "subDistrictNameEn": "Chum Kho",
    "postcode": "86160",
    "districtCode": "8603",
    "districtNameTh": "ปะทิว",
    "districtNameEn": "Pathio",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860305",
    "subDistrictNameTh": "ดอนยาง",
    "subDistrictNameEn": "Don Yang",
    "postcode": "86210",
    "districtCode": "8603",
    "districtNameTh": "ปะทิว",
    "districtNameEn": "Pathio",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860306",
    "subDistrictNameTh": "ปากคลอง",
    "subDistrictNameEn": "Pak Khlong",
    "postcode": "86210",
    "districtCode": "8603",
    "districtNameTh": "ปะทิว",
    "districtNameEn": "Pathio",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860307",
    "subDistrictNameTh": "เขาไชยราช",
    "subDistrictNameEn": "Khao Chai Rat",
    "postcode": "86210",
    "districtCode": "8603",
    "districtNameTh": "ปะทิว",
    "districtNameEn": "Pathio",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860401",
    "subDistrictNameTh": "หลังสวน",
    "subDistrictNameEn": "Lang Suan",
    "postcode": "86110",
    "districtCode": "8604",
    "districtNameTh": "หลังสวน",
    "districtNameEn": "Lang Suan",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860402",
    "subDistrictNameTh": "ขันเงิน",
    "subDistrictNameEn": "Khan Ngoen",
    "postcode": "86110",
    "districtCode": "8604",
    "districtNameTh": "หลังสวน",
    "districtNameEn": "Lang Suan",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860403",
    "subDistrictNameTh": "ท่ามะพลา",
    "subDistrictNameEn": "Tha Maphla",
    "postcode": "86110",
    "districtCode": "8604",
    "districtNameTh": "หลังสวน",
    "districtNameEn": "Lang Suan",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860404",
    "subDistrictNameTh": "นาขา",
    "subDistrictNameEn": "Na Kha",
    "postcode": "86110",
    "districtCode": "8604",
    "districtNameTh": "หลังสวน",
    "districtNameEn": "Lang Suan",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860405",
    "subDistrictNameTh": "นาพญา",
    "subDistrictNameEn": "Na Phaya",
    "postcode": "86110",
    "districtCode": "8604",
    "districtNameTh": "หลังสวน",
    "districtNameEn": "Lang Suan",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860406",
    "subDistrictNameTh": "บ้านควน",
    "subDistrictNameEn": "Ban Khuan",
    "postcode": "86110",
    "districtCode": "8604",
    "districtNameTh": "หลังสวน",
    "districtNameEn": "Lang Suan",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860407",
    "subDistrictNameTh": "บางมะพร้าว",
    "subDistrictNameEn": "Bang Maphrao",
    "postcode": "86110",
    "districtCode": "8604",
    "districtNameTh": "หลังสวน",
    "districtNameEn": "Lang Suan",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860408",
    "subDistrictNameTh": "บางน้ำจืด",
    "subDistrictNameEn": "Bang Nam Chuet",
    "postcode": "86150",
    "districtCode": "8604",
    "districtNameTh": "หลังสวน",
    "districtNameEn": "Lang Suan",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860409",
    "subDistrictNameTh": "ปากน้ำ",
    "subDistrictNameEn": "Pak Nam",
    "postcode": "86150",
    "districtCode": "8604",
    "districtNameTh": "หลังสวน",
    "districtNameEn": "Lang Suan",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860410",
    "subDistrictNameTh": "พ้อแดง",
    "subDistrictNameEn": "Pho Daeng",
    "postcode": "86110",
    "districtCode": "8604",
    "districtNameTh": "หลังสวน",
    "districtNameEn": "Lang Suan",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860411",
    "subDistrictNameTh": "แหลมทราย",
    "subDistrictNameEn": "Laem Sai",
    "postcode": "86110",
    "districtCode": "8604",
    "districtNameTh": "หลังสวน",
    "districtNameEn": "Lang Suan",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860412",
    "subDistrictNameTh": "วังตะกอ",
    "subDistrictNameEn": "Wang Tako",
    "postcode": "86110",
    "districtCode": "8604",
    "districtNameTh": "หลังสวน",
    "districtNameEn": "Lang Suan",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860413",
    "subDistrictNameTh": "หาดยาย",
    "subDistrictNameEn": "Hat yai",
    "postcode": "86110",
    "districtCode": "8604",
    "districtNameTh": "หลังสวน",
    "districtNameEn": "Lang Suan",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860501",
    "subDistrictNameTh": "ละแม",
    "subDistrictNameEn": "Lamae",
    "postcode": "86170",
    "districtCode": "8605",
    "districtNameTh": "ละแม",
    "districtNameEn": "Lamae",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860502",
    "subDistrictNameTh": "ทุ่งหลวง",
    "subDistrictNameEn": "Thung Luang",
    "postcode": "86170",
    "districtCode": "8605",
    "districtNameTh": "ละแม",
    "districtNameEn": "Lamae",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860503",
    "subDistrictNameTh": "สวนแตง",
    "subDistrictNameEn": "Suan Taeng",
    "postcode": "86170",
    "districtCode": "8605",
    "districtNameTh": "ละแม",
    "districtNameEn": "Lamae",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860504",
    "subDistrictNameTh": "ทุ่งคาวัด",
    "subDistrictNameEn": "Thung Kha Wat",
    "postcode": "86170",
    "districtCode": "8605",
    "districtNameTh": "ละแม",
    "districtNameEn": "Lamae",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860601",
    "subDistrictNameTh": "พะโต๊ะ",
    "subDistrictNameEn": "Phato",
    "postcode": "86180",
    "districtCode": "8606",
    "districtNameTh": "พะโต๊ะ",
    "districtNameEn": "Phato",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860602",
    "subDistrictNameTh": "ปากทรง",
    "subDistrictNameEn": "Pak Song",
    "postcode": "86180",
    "districtCode": "8606",
    "districtNameTh": "พะโต๊ะ",
    "districtNameEn": "Phato",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860603",
    "subDistrictNameTh": "ปังหวาน",
    "subDistrictNameEn": "Pang Wan",
    "postcode": "86180",
    "districtCode": "8606",
    "districtNameTh": "พะโต๊ะ",
    "districtNameEn": "Phato",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860604",
    "subDistrictNameTh": "พระรักษ์",
    "subDistrictNameEn": "Phra Rak",
    "postcode": "86180",
    "districtCode": "8606",
    "districtNameTh": "พะโต๊ะ",
    "districtNameEn": "Phato",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860701",
    "subDistrictNameTh": "นาโพธิ์",
    "subDistrictNameEn": "Na Pho",
    "postcode": "86130",
    "districtCode": "8607",
    "districtNameTh": "สวี",
    "districtNameEn": "Sawi",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860702",
    "subDistrictNameTh": "สวี",
    "subDistrictNameEn": "Sawi",
    "postcode": "86130",
    "districtCode": "8607",
    "districtNameTh": "สวี",
    "districtNameEn": "Sawi",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860703",
    "subDistrictNameTh": "ทุ่งระยะ",
    "subDistrictNameEn": "Thung Raya",
    "postcode": "86130",
    "districtCode": "8607",
    "districtNameTh": "สวี",
    "districtNameEn": "Sawi",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860704",
    "subDistrictNameTh": "ท่าหิน",
    "subDistrictNameEn": "Tha Hin",
    "postcode": "86130",
    "districtCode": "8607",
    "districtNameTh": "สวี",
    "districtNameEn": "Sawi",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860705",
    "subDistrictNameTh": "ปากแพรก",
    "subDistrictNameEn": "Pak Phraek",
    "postcode": "86130",
    "districtCode": "8607",
    "districtNameTh": "สวี",
    "districtNameEn": "Sawi",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860706",
    "subDistrictNameTh": "ด่านสวี",
    "subDistrictNameEn": "Dan Sawi",
    "postcode": "86130",
    "districtCode": "8607",
    "districtNameTh": "สวี",
    "districtNameEn": "Sawi",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860707",
    "subDistrictNameTh": "ครน",
    "subDistrictNameEn": "Khron",
    "postcode": "86130",
    "districtCode": "8607",
    "districtNameTh": "สวี",
    "districtNameEn": "Sawi",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860708",
    "subDistrictNameTh": "วิสัยใต้",
    "subDistrictNameEn": "Wisai Tai",
    "postcode": "86130",
    "districtCode": "8607",
    "districtNameTh": "สวี",
    "districtNameEn": "Sawi",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860709",
    "subDistrictNameTh": "นาสัก",
    "subDistrictNameEn": "Na Sak",
    "postcode": "86130",
    "districtCode": "8607",
    "districtNameTh": "สวี",
    "districtNameEn": "Sawi",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860710",
    "subDistrictNameTh": "เขาทะลุ",
    "subDistrictNameEn": "Khao Thalu",
    "postcode": "86130",
    "districtCode": "8607",
    "districtNameTh": "สวี",
    "districtNameEn": "Sawi",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860711",
    "subDistrictNameTh": "เขาค่าย",
    "subDistrictNameEn": "Khao Khai",
    "postcode": "86130",
    "districtCode": "8607",
    "districtNameTh": "สวี",
    "districtNameEn": "Sawi",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860801",
    "subDistrictNameTh": "ปากตะโก",
    "subDistrictNameEn": "Pak Tako",
    "postcode": "86220",
    "districtCode": "8608",
    "districtNameTh": "ทุ่งตะโก",
    "districtNameEn": "Thung Tako",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860802",
    "subDistrictNameTh": "ทุ่งตะไคร",
    "subDistrictNameEn": "Thung Takhrai",
    "postcode": "86220",
    "districtCode": "8608",
    "districtNameTh": "ทุ่งตะโก",
    "districtNameEn": "Thung Tako",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860803",
    "subDistrictNameTh": "ตะโก",
    "subDistrictNameEn": "Tako",
    "postcode": "86220",
    "districtCode": "8608",
    "districtNameTh": "ทุ่งตะโก",
    "districtNameEn": "Thung Tako",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "860804",
    "subDistrictNameTh": "ช่องไม้แก้ว",
    "subDistrictNameEn": "Chong Mai Kaeo",
    "postcode": "86220",
    "districtCode": "8608",
    "districtNameTh": "ทุ่งตะโก",
    "districtNameEn": "Thung Tako",
    "provinceCode": "86",
    "provinceNameTh": "ชุมพร",
    "provinceNameEn": "Chumphon"
},
{
    "subDistrictCode": "900101",
    "subDistrictNameTh": "บ่อยาง",
    "subDistrictNameEn": "Bo Yang",
    "postcode": "90000",
    "districtCode": "9001",
    "districtNameTh": "เมืองสงขลา",
    "districtNameEn": "Mueang Songkhla",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900102",
    "subDistrictNameTh": "เขารูปช้าง",
    "subDistrictNameEn": "Khao Rup Chang",
    "postcode": "90000",
    "districtCode": "9001",
    "districtNameTh": "เมืองสงขลา",
    "districtNameEn": "Mueang Songkhla",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900103",
    "subDistrictNameTh": "เกาะแต้ว",
    "subDistrictNameEn": "Ko Taeo",
    "postcode": "90000",
    "districtCode": "9001",
    "districtNameTh": "เมืองสงขลา",
    "districtNameEn": "Mueang Songkhla",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900104",
    "subDistrictNameTh": "พะวง",
    "subDistrictNameEn": "Phawong",
    "postcode": "90100",
    "districtCode": "9001",
    "districtNameTh": "เมืองสงขลา",
    "districtNameEn": "Mueang Songkhla",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900105",
    "subDistrictNameTh": "ทุ่งหวัง",
    "subDistrictNameEn": "Thung Wang",
    "postcode": "90000",
    "districtCode": "9001",
    "districtNameTh": "เมืองสงขลา",
    "districtNameEn": "Mueang Songkhla",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900106",
    "subDistrictNameTh": "เกาะยอ",
    "subDistrictNameEn": "Ko Yo",
    "postcode": "90100",
    "districtCode": "9001",
    "districtNameTh": "เมืองสงขลา",
    "districtNameEn": "Mueang Songkhla",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900201",
    "subDistrictNameTh": "จะทิ้งพระ",
    "subDistrictNameEn": "Chathing Phra",
    "postcode": "90190",
    "districtCode": "9002",
    "districtNameTh": "สทิงพระ",
    "districtNameEn": "Sathing Phra",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900202",
    "subDistrictNameTh": "กระดังงา",
    "subDistrictNameEn": "Kradang-nga",
    "postcode": "90190",
    "districtCode": "9002",
    "districtNameTh": "สทิงพระ",
    "districtNameEn": "Sathing Phra",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900203",
    "subDistrictNameTh": "สนามชัย",
    "subDistrictNameEn": "Sanam Chai",
    "postcode": "90190",
    "districtCode": "9002",
    "districtNameTh": "สทิงพระ",
    "districtNameEn": "Sathing Phra",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900204",
    "subDistrictNameTh": "ดีหลวง",
    "subDistrictNameEn": "Di Luang",
    "postcode": "90190",
    "districtCode": "9002",
    "districtNameTh": "สทิงพระ",
    "districtNameEn": "Sathing Phra",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900205",
    "subDistrictNameTh": "ชุมพล",
    "subDistrictNameEn": "Chumphon",
    "postcode": "90190",
    "districtCode": "9002",
    "districtNameTh": "สทิงพระ",
    "districtNameEn": "Sathing Phra",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900206",
    "subDistrictNameTh": "คลองรี",
    "subDistrictNameEn": "Khlong Ri",
    "postcode": "90190",
    "districtCode": "9002",
    "districtNameTh": "สทิงพระ",
    "districtNameEn": "Sathing Phra",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900207",
    "subDistrictNameTh": "คูขุด",
    "subDistrictNameEn": "Khu Khut",
    "postcode": "90190",
    "districtCode": "9002",
    "districtNameTh": "สทิงพระ",
    "districtNameEn": "Sathing Phra",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900208",
    "subDistrictNameTh": "ท่าหิน",
    "subDistrictNameEn": "Tha Hin",
    "postcode": "90190",
    "districtCode": "9002",
    "districtNameTh": "สทิงพระ",
    "districtNameEn": "Sathing Phra",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900209",
    "subDistrictNameTh": "วัดจันทร์",
    "subDistrictNameEn": "Wat Chan",
    "postcode": "90190",
    "districtCode": "9002",
    "districtNameTh": "สทิงพระ",
    "districtNameEn": "Sathing Phra",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900210",
    "subDistrictNameTh": "บ่อแดง",
    "subDistrictNameEn": "Bo Daeng",
    "postcode": "90190",
    "districtCode": "9002",
    "districtNameTh": "สทิงพระ",
    "districtNameEn": "Sathing Phra",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900211",
    "subDistrictNameTh": "บ่อดาน",
    "subDistrictNameEn": "Bo Dan",
    "postcode": "90190",
    "districtCode": "9002",
    "districtNameTh": "สทิงพระ",
    "districtNameEn": "Sathing Phra",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900301",
    "subDistrictNameTh": "บ้านนา",
    "subDistrictNameEn": "Ban Na",
    "postcode": "90130",
    "districtCode": "9003",
    "districtNameTh": "จะนะ",
    "districtNameEn": "Chana",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900302",
    "subDistrictNameTh": "ป่าชิง",
    "subDistrictNameEn": "Pa Ching",
    "postcode": "90130",
    "districtCode": "9003",
    "districtNameTh": "จะนะ",
    "districtNameEn": "Chana",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900303",
    "subDistrictNameTh": "สะพานไม้แก่น",
    "subDistrictNameEn": "Saphan Mai Kaen",
    "postcode": "90130",
    "districtCode": "9003",
    "districtNameTh": "จะนะ",
    "districtNameEn": "Chana",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900304",
    "subDistrictNameTh": "สะกอม",
    "subDistrictNameEn": "Sakom",
    "postcode": "90130",
    "districtCode": "9003",
    "districtNameTh": "จะนะ",
    "districtNameEn": "Chana",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900305",
    "subDistrictNameTh": "นาหว้า",
    "subDistrictNameEn": "Na Wa",
    "postcode": "90130",
    "districtCode": "9003",
    "districtNameTh": "จะนะ",
    "districtNameEn": "Chana",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900306",
    "subDistrictNameTh": "นาทับ",
    "subDistrictNameEn": "Na Thap",
    "postcode": "90130",
    "districtCode": "9003",
    "districtNameTh": "จะนะ",
    "districtNameEn": "Chana",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900307",
    "subDistrictNameTh": "น้ำขาว",
    "subDistrictNameEn": "Nam Khao",
    "postcode": "90130",
    "districtCode": "9003",
    "districtNameTh": "จะนะ",
    "districtNameEn": "Chana",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900308",
    "subDistrictNameTh": "ขุนตัดหวาย",
    "subDistrictNameEn": "Khun Tat Wai",
    "postcode": "90130",
    "districtCode": "9003",
    "districtNameTh": "จะนะ",
    "districtNameEn": "Chana",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900309",
    "subDistrictNameTh": "ท่าหมอไทร",
    "subDistrictNameEn": "Tha Mo Sai",
    "postcode": "90130",
    "districtCode": "9003",
    "districtNameTh": "จะนะ",
    "districtNameEn": "Chana",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900310",
    "subDistrictNameTh": "จะโหนง",
    "subDistrictNameEn": "Chanong",
    "postcode": "90130",
    "districtCode": "9003",
    "districtNameTh": "จะนะ",
    "districtNameEn": "Chana",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900311",
    "subDistrictNameTh": "คู",
    "subDistrictNameEn": "Khu",
    "postcode": "90130",
    "districtCode": "9003",
    "districtNameTh": "จะนะ",
    "districtNameEn": "Chana",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900312",
    "subDistrictNameTh": "แค",
    "subDistrictNameEn": "Khae",
    "postcode": "90130",
    "districtCode": "9003",
    "districtNameTh": "จะนะ",
    "districtNameEn": "Chana",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900313",
    "subDistrictNameTh": "คลองเปียะ",
    "subDistrictNameEn": "Khlong Pia",
    "postcode": "90130",
    "districtCode": "9003",
    "districtNameTh": "จะนะ",
    "districtNameEn": "Chana",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900314",
    "subDistrictNameTh": "ตลิ่งชัน",
    "subDistrictNameEn": "Taling Chan",
    "postcode": "90130",
    "districtCode": "9003",
    "districtNameTh": "จะนะ",
    "districtNameEn": "Chana",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900401",
    "subDistrictNameTh": "นาทวี",
    "subDistrictNameEn": "Na Thawi",
    "postcode": "90160",
    "districtCode": "9004",
    "districtNameTh": "นาทวี",
    "districtNameEn": "Na Thawi",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900402",
    "subDistrictNameTh": "ฉาง",
    "subDistrictNameEn": "Chang",
    "postcode": "90160",
    "districtCode": "9004",
    "districtNameTh": "นาทวี",
    "districtNameEn": "Na Thawi",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900403",
    "subDistrictNameTh": "นาหมอศรี",
    "subDistrictNameEn": "Na Mo Si",
    "postcode": "90160",
    "districtCode": "9004",
    "districtNameTh": "นาทวี",
    "districtNameEn": "Na Thawi",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900404",
    "subDistrictNameTh": "คลองทราย",
    "subDistrictNameEn": "Khlong Sai",
    "postcode": "90160",
    "districtCode": "9004",
    "districtNameTh": "นาทวี",
    "districtNameEn": "Na Thawi",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900405",
    "subDistrictNameTh": "ปลักหนู",
    "subDistrictNameEn": "Plak Nu",
    "postcode": "90160",
    "districtCode": "9004",
    "districtNameTh": "นาทวี",
    "districtNameEn": "Na Thawi",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900406",
    "subDistrictNameTh": "ท่าประดู่",
    "subDistrictNameEn": "Tha Pradu",
    "postcode": "90160",
    "districtCode": "9004",
    "districtNameTh": "นาทวี",
    "districtNameEn": "Na Thawi",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900407",
    "subDistrictNameTh": "สะท้อน",
    "subDistrictNameEn": "Sathon",
    "postcode": "90160",
    "districtCode": "9004",
    "districtNameTh": "นาทวี",
    "districtNameEn": "Na Thawi",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900408",
    "subDistrictNameTh": "ทับช้าง",
    "subDistrictNameEn": "Thap Chang",
    "postcode": "90160",
    "districtCode": "9004",
    "districtNameTh": "นาทวี",
    "districtNameEn": "Na Thawi",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900409",
    "subDistrictNameTh": "ประกอบ",
    "subDistrictNameEn": "Prakop",
    "postcode": "90160",
    "districtCode": "9004",
    "districtNameTh": "นาทวี",
    "districtNameEn": "Na Thawi",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900410",
    "subDistrictNameTh": "คลองกวาง",
    "subDistrictNameEn": "Khlong Kwang",
    "postcode": "90160",
    "districtCode": "9004",
    "districtNameTh": "นาทวี",
    "districtNameEn": "Na Thawi",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900501",
    "subDistrictNameTh": "เทพา",
    "subDistrictNameEn": "Thepha",
    "postcode": "90150",
    "districtCode": "9005",
    "districtNameTh": "เทพา",
    "districtNameEn": "Thepha",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900502",
    "subDistrictNameTh": "ปากบาง",
    "subDistrictNameEn": "Pak Bang",
    "postcode": "90150",
    "districtCode": "9005",
    "districtNameTh": "เทพา",
    "districtNameEn": "Thepha",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900503",
    "subDistrictNameTh": "เกาะสะบ้า",
    "subDistrictNameEn": "Ko Saba",
    "postcode": "90150",
    "districtCode": "9005",
    "districtNameTh": "เทพา",
    "districtNameEn": "Thepha",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900504",
    "subDistrictNameTh": "ลำไพล",
    "subDistrictNameEn": "Lam Phlai",
    "postcode": "90260",
    "districtCode": "9005",
    "districtNameTh": "เทพา",
    "districtNameEn": "Thepha",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900505",
    "subDistrictNameTh": "ท่าม่วง",
    "subDistrictNameEn": "Tha Muang",
    "postcode": "90150",
    "districtCode": "9005",
    "districtNameTh": "เทพา",
    "districtNameEn": "Thepha",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900506",
    "subDistrictNameTh": "วังใหญ่",
    "subDistrictNameEn": "Wang Yai",
    "postcode": "90260",
    "districtCode": "9005",
    "districtNameTh": "เทพา",
    "districtNameEn": "Thepha",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900507",
    "subDistrictNameTh": "สะกอม",
    "subDistrictNameEn": "Sakom",
    "postcode": "90150",
    "districtCode": "9005",
    "districtNameTh": "เทพา",
    "districtNameEn": "Thepha",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900601",
    "subDistrictNameTh": "สะบ้าย้อย",
    "subDistrictNameEn": "Saba Yoi",
    "postcode": "90210",
    "districtCode": "9006",
    "districtNameTh": "สะบ้าย้อย",
    "districtNameEn": "Saba Yoi",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900602",
    "subDistrictNameTh": "ทุ่งพอ",
    "subDistrictNameEn": "Thung Pho",
    "postcode": "90210",
    "districtCode": "9006",
    "districtNameTh": "สะบ้าย้อย",
    "districtNameEn": "Saba Yoi",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900603",
    "subDistrictNameTh": "เปียน",
    "subDistrictNameEn": "Pian",
    "postcode": "90210",
    "districtCode": "9006",
    "districtNameTh": "สะบ้าย้อย",
    "districtNameEn": "Saba Yoi",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900604",
    "subDistrictNameTh": "บ้านโหนด",
    "subDistrictNameEn": "Ban Not",
    "postcode": "90210",
    "districtCode": "9006",
    "districtNameTh": "สะบ้าย้อย",
    "districtNameEn": "Saba Yoi",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900605",
    "subDistrictNameTh": "จะแหน",
    "subDistrictNameEn": "Chanae",
    "postcode": "90210",
    "districtCode": "9006",
    "districtNameTh": "สะบ้าย้อย",
    "districtNameEn": "Saba Yoi",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900606",
    "subDistrictNameTh": "คูหา",
    "subDistrictNameEn": "Khuha",
    "postcode": "90210",
    "districtCode": "9006",
    "districtNameTh": "สะบ้าย้อย",
    "districtNameEn": "Saba Yoi",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900607",
    "subDistrictNameTh": "เขาแดง",
    "subDistrictNameEn": "Khao Daeng",
    "postcode": "90210",
    "districtCode": "9006",
    "districtNameTh": "สะบ้าย้อย",
    "districtNameEn": "Saba Yoi",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900608",
    "subDistrictNameTh": "บาโหย",
    "subDistrictNameEn": "Ba Hoi",
    "postcode": "90210",
    "districtCode": "9006",
    "districtNameTh": "สะบ้าย้อย",
    "districtNameEn": "Saba Yoi",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900609",
    "subDistrictNameTh": "ธารคีรี",
    "subDistrictNameEn": "Than Khiri",
    "postcode": "90210",
    "districtCode": "9006",
    "districtNameTh": "สะบ้าย้อย",
    "districtNameEn": "Saba Yoi",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900701",
    "subDistrictNameTh": "ระโนด",
    "subDistrictNameEn": "Ranot",
    "postcode": "90140",
    "districtCode": "9007",
    "districtNameTh": "ระโนด",
    "districtNameEn": "Ranot",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900702",
    "subDistrictNameTh": "คลองแดน",
    "subDistrictNameEn": "Khlong Daen",
    "postcode": "90140",
    "districtCode": "9007",
    "districtNameTh": "ระโนด",
    "districtNameEn": "Ranot",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900703",
    "subDistrictNameTh": "ตะเครียะ",
    "subDistrictNameEn": "Takhria",
    "postcode": "90140",
    "districtCode": "9007",
    "districtNameTh": "ระโนด",
    "districtNameEn": "Ranot",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900704",
    "subDistrictNameTh": "ท่าบอน",
    "subDistrictNameEn": "Tha Bon",
    "postcode": "90140",
    "districtCode": "9007",
    "districtNameTh": "ระโนด",
    "districtNameEn": "Ranot",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900705",
    "subDistrictNameTh": "บ้านใหม่",
    "subDistrictNameEn": "Ban Mai",
    "postcode": "90140",
    "districtCode": "9007",
    "districtNameTh": "ระโนด",
    "districtNameEn": "Ranot",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900706",
    "subDistrictNameTh": "บ่อตรุ",
    "subDistrictNameEn": "Bo Tru",
    "postcode": "90140",
    "districtCode": "9007",
    "districtNameTh": "ระโนด",
    "districtNameEn": "Ranot",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900707",
    "subDistrictNameTh": "ปากแตระ",
    "subDistrictNameEn": "Pak Trae",
    "postcode": "90140",
    "districtCode": "9007",
    "districtNameTh": "ระโนด",
    "districtNameEn": "Ranot",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900708",
    "subDistrictNameTh": "พังยาง",
    "subDistrictNameEn": "Phang Yang",
    "postcode": "90140",
    "districtCode": "9007",
    "districtNameTh": "ระโนด",
    "districtNameEn": "Ranot",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900709",
    "subDistrictNameTh": "ระวะ",
    "subDistrictNameEn": "Rawa",
    "postcode": "90140",
    "districtCode": "9007",
    "districtNameTh": "ระโนด",
    "districtNameEn": "Ranot",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900710",
    "subDistrictNameTh": "วัดสน",
    "subDistrictNameEn": "Wat Son",
    "postcode": "90140",
    "districtCode": "9007",
    "districtNameTh": "ระโนด",
    "districtNameEn": "Ranot",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900711",
    "subDistrictNameTh": "บ้านขาว",
    "subDistrictNameEn": "Ban Khwa",
    "postcode": "90140",
    "districtCode": "9007",
    "districtNameTh": "ระโนด",
    "districtNameEn": "Ranot",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900712",
    "subDistrictNameTh": "แดนสงวน",
    "subDistrictNameEn": "Daen Sa-nguan",
    "postcode": "90140",
    "districtCode": "9007",
    "districtNameTh": "ระโนด",
    "districtNameEn": "Ranot",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900801",
    "subDistrictNameTh": "เกาะใหญ่",
    "subDistrictNameEn": "Ko Yai",
    "postcode": "90270",
    "districtCode": "9008",
    "districtNameTh": "กระแสสินธุ์",
    "districtNameEn": "Krasae Sin",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900802",
    "subDistrictNameTh": "โรง",
    "subDistrictNameEn": "Rong",
    "postcode": "90270",
    "districtCode": "9008",
    "districtNameTh": "กระแสสินธุ์",
    "districtNameEn": "Krasae Sin",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900803",
    "subDistrictNameTh": "เชิงแส",
    "subDistrictNameEn": "Choeng Sae",
    "postcode": "90270",
    "districtCode": "9008",
    "districtNameTh": "กระแสสินธุ์",
    "districtNameEn": "Krasae Sin",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900804",
    "subDistrictNameTh": "กระแสสินธุ์",
    "subDistrictNameEn": "Krasae Sin",
    "postcode": "90270",
    "districtCode": "9008",
    "districtNameTh": "กระแสสินธุ์",
    "districtNameEn": "Krasae Sin",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900901",
    "subDistrictNameTh": "กำแพงเพชร",
    "subDistrictNameEn": "Kamphaeng Phet",
    "postcode": "90180",
    "districtCode": "9009",
    "districtNameTh": "รัตภูมิ",
    "districtNameEn": "Rattaphum",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900902",
    "subDistrictNameTh": "ท่าชะมวง",
    "subDistrictNameEn": "Tha Chamuang",
    "postcode": "90180",
    "districtCode": "9009",
    "districtNameTh": "รัตภูมิ",
    "districtNameEn": "Rattaphum",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900903",
    "subDistrictNameTh": "คูหาใต้",
    "subDistrictNameEn": "Khuha Tai",
    "postcode": "90180",
    "districtCode": "9009",
    "districtNameTh": "รัตภูมิ",
    "districtNameEn": "Rattaphum",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900904",
    "subDistrictNameTh": "ควนรู",
    "subDistrictNameEn": "Khuan Ru",
    "postcode": "90180",
    "districtCode": "9009",
    "districtNameTh": "รัตภูมิ",
    "districtNameEn": "Rattaphum",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "900909",
    "subDistrictNameTh": "เขาพระ",
    "subDistrictNameEn": "Khao Phra",
    "postcode": "90180",
    "districtCode": "9009",
    "districtNameTh": "รัตภูมิ",
    "districtNameEn": "Rattaphum",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901001",
    "subDistrictNameTh": "สะเดา",
    "subDistrictNameEn": "Sadao",
    "postcode": "90120",
    "districtCode": "9010",
    "districtNameTh": "สะเดา",
    "districtNameEn": "Sadao",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901002",
    "subDistrictNameTh": "ปริก",
    "subDistrictNameEn": "Prik",
    "postcode": "90120",
    "districtCode": "9010",
    "districtNameTh": "สะเดา",
    "districtNameEn": "Sadao",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901003",
    "subDistrictNameTh": "พังลา",
    "subDistrictNameEn": "Phang La",
    "postcode": "90170",
    "districtCode": "9010",
    "districtNameTh": "สะเดา",
    "districtNameEn": "Sadao",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901004",
    "subDistrictNameTh": "สำนักแต้ว",
    "subDistrictNameEn": "Samnak Taeo",
    "postcode": "90120",
    "districtCode": "9010",
    "districtNameTh": "สะเดา",
    "districtNameEn": "Sadao",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901005",
    "subDistrictNameTh": "ทุ่งหมอ",
    "subDistrictNameEn": "Thung Mo",
    "postcode": "90240",
    "districtCode": "9010",
    "districtNameTh": "สะเดา",
    "districtNameEn": "Sadao",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901006",
    "subDistrictNameTh": "ท่าโพธิ์",
    "subDistrictNameEn": "Tha Pho",
    "postcode": "90170",
    "districtCode": "9010",
    "districtNameTh": "สะเดา",
    "districtNameEn": "Sadao",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901007",
    "subDistrictNameTh": "ปาดังเบซาร์",
    "subDistrictNameEn": "Padang Besa",
    "postcode": "90240",
    "districtCode": "9010",
    "districtNameTh": "สะเดา",
    "districtNameEn": "Sadao",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901008",
    "subDistrictNameTh": "สำนักขาม",
    "subDistrictNameEn": "Samnak Kham",
    "postcode": "90320",
    "districtCode": "9010",
    "districtNameTh": "สะเดา",
    "districtNameEn": "Sadao",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901009",
    "subDistrictNameTh": "เขามีเกียรติ",
    "subDistrictNameEn": "Khao Mi Kiat",
    "postcode": "90170",
    "districtCode": "9010",
    "districtNameTh": "สะเดา",
    "districtNameEn": "Sadao",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901101",
    "subDistrictNameTh": "หาดใหญ่",
    "subDistrictNameEn": "Hat yai",
    "postcode": "90110",
    "districtCode": "9011",
    "districtNameTh": "หาดใหญ่",
    "districtNameEn": "Hat Yai",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901102",
    "subDistrictNameTh": "ควนลัง",
    "subDistrictNameEn": "Khuan Lang",
    "postcode": "90110",
    "districtCode": "9011",
    "districtNameTh": "หาดใหญ่",
    "districtNameEn": "Hat Yai",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901103",
    "subDistrictNameTh": "คูเต่า",
    "subDistrictNameEn": "Khu Tao",
    "postcode": "90110",
    "districtCode": "9011",
    "districtNameTh": "หาดใหญ่",
    "districtNameEn": "Hat Yai",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901104",
    "subDistrictNameTh": "คอหงส์",
    "subDistrictNameEn": "Kho Hong",
    "postcode": "90110",
    "districtCode": "9011",
    "districtNameTh": "หาดใหญ่",
    "districtNameEn": "Hat Yai",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901105",
    "subDistrictNameTh": "คลองแห",
    "subDistrictNameEn": "Khlong Hae",
    "postcode": "90110",
    "districtCode": "9011",
    "districtNameTh": "หาดใหญ่",
    "districtNameEn": "Hat Yai",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901107",
    "subDistrictNameTh": "คลองอู่ตะเภา",
    "subDistrictNameEn": "Khlong U Taphao",
    "postcode": "90110",
    "districtCode": "9011",
    "districtNameTh": "หาดใหญ่",
    "districtNameEn": "Hat Yai",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901108",
    "subDistrictNameTh": "ฉลุง",
    "subDistrictNameEn": "Chalung",
    "postcode": "90110",
    "districtCode": "9011",
    "districtNameTh": "หาดใหญ่",
    "districtNameEn": "Hat Yai",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901111",
    "subDistrictNameTh": "ทุ่งใหญ่",
    "subDistrictNameEn": "Thung Yai",
    "postcode": "90110",
    "districtCode": "9011",
    "districtNameTh": "หาดใหญ่",
    "districtNameEn": "Hat Yai",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901112",
    "subDistrictNameTh": "ทุ่งตำเสา",
    "subDistrictNameEn": "Thung Tam Sao",
    "postcode": "90110",
    "districtCode": "9011",
    "districtNameTh": "หาดใหญ่",
    "districtNameEn": "Hat Yai",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901113",
    "subDistrictNameTh": "ท่าข้าม",
    "subDistrictNameEn": "Tha Kham",
    "postcode": "90110",
    "districtCode": "9011",
    "districtNameTh": "หาดใหญ่",
    "districtNameEn": "Hat Yai",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901114",
    "subDistrictNameTh": "น้ำน้อย",
    "subDistrictNameEn": "Nam Noi",
    "postcode": "90110",
    "districtCode": "9011",
    "districtNameTh": "หาดใหญ่",
    "districtNameEn": "Hat Yai",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901116",
    "subDistrictNameTh": "บ้านพรุ",
    "subDistrictNameEn": "Ban Phru",
    "postcode": "90250",
    "districtCode": "9011",
    "districtNameTh": "หาดใหญ่",
    "districtNameEn": "Hat Yai",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901118",
    "subDistrictNameTh": "พะตง",
    "subDistrictNameEn": "Phatong",
    "postcode": "90230",
    "districtCode": "9011",
    "districtNameTh": "หาดใหญ่",
    "districtNameEn": "Hat Yai",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901201",
    "subDistrictNameTh": "นาหม่อม",
    "subDistrictNameEn": "Na Mom",
    "postcode": "90310",
    "districtCode": "9012",
    "districtNameTh": "นาหม่อม",
    "districtNameEn": "Na Mom",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901202",
    "subDistrictNameTh": "พิจิตร",
    "subDistrictNameEn": "Phichit",
    "postcode": "90310",
    "districtCode": "9012",
    "districtNameTh": "นาหม่อม",
    "districtNameEn": "Na Mom",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901203",
    "subDistrictNameTh": "ทุ่งขมิ้น",
    "subDistrictNameEn": "Thung Khamin",
    "postcode": "90310",
    "districtCode": "9012",
    "districtNameTh": "นาหม่อม",
    "districtNameEn": "Na Mom",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901204",
    "subDistrictNameTh": "คลองหรัง",
    "subDistrictNameEn": "Khlong Rang",
    "postcode": "90310",
    "districtCode": "9012",
    "districtNameTh": "นาหม่อม",
    "districtNameEn": "Na Mom",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901301",
    "subDistrictNameTh": "รัตภูมิ",
    "subDistrictNameEn": "Rattaphum",
    "postcode": "90220",
    "districtCode": "9013",
    "districtNameTh": "ควนเนียง",
    "districtNameEn": "Khuan Niang",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901302",
    "subDistrictNameTh": "ควนโส",
    "subDistrictNameEn": "Khuan So",
    "postcode": "90220",
    "districtCode": "9013",
    "districtNameTh": "ควนเนียง",
    "districtNameEn": "Khuan Niang",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901303",
    "subDistrictNameTh": "ห้วยลึก",
    "subDistrictNameEn": "Huai Luek",
    "postcode": "90220",
    "districtCode": "9013",
    "districtNameTh": "ควนเนียง",
    "districtNameEn": "Khuan Niang",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901304",
    "subDistrictNameTh": "บางเหรียง",
    "subDistrictNameEn": "Bang Riang",
    "postcode": "90220",
    "districtCode": "9013",
    "districtNameTh": "ควนเนียง",
    "districtNameEn": "Khuan Niang",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901401",
    "subDistrictNameTh": "บางกล่ำ",
    "subDistrictNameEn": "Bang Klam",
    "postcode": "90110",
    "districtCode": "9014",
    "districtNameTh": "บางกล่ำ",
    "districtNameEn": "Bang Klam",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901402",
    "subDistrictNameTh": "ท่าช้าง",
    "subDistrictNameEn": "Tha Chang",
    "postcode": "90110",
    "districtCode": "9014",
    "districtNameTh": "บางกล่ำ",
    "districtNameEn": "Bang Klam",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901403",
    "subDistrictNameTh": "แม่ทอม",
    "subDistrictNameEn": "Mae Thom",
    "postcode": "90110",
    "districtCode": "9014",
    "districtNameTh": "บางกล่ำ",
    "districtNameEn": "Bang Klam",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901404",
    "subDistrictNameTh": "บ้านหาร",
    "subDistrictNameEn": "Ban Han",
    "postcode": "90110",
    "districtCode": "9014",
    "districtNameTh": "บางกล่ำ",
    "districtNameEn": "Bang Klam",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901501",
    "subDistrictNameTh": "ชิงโค",
    "subDistrictNameEn": "Ching Kho",
    "postcode": "90280",
    "districtCode": "9015",
    "districtNameTh": "สิงหนคร",
    "districtNameEn": "Singhanakhon",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901502",
    "subDistrictNameTh": "สทิงหม้อ",
    "subDistrictNameEn": "Sathing Mo",
    "postcode": "90280",
    "districtCode": "9015",
    "districtNameTh": "สิงหนคร",
    "districtNameEn": "Singhanakhon",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901503",
    "subDistrictNameTh": "ทำนบ",
    "subDistrictNameEn": "Thamnop",
    "postcode": "90280",
    "districtCode": "9015",
    "districtNameTh": "สิงหนคร",
    "districtNameEn": "Singhanakhon",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901504",
    "subDistrictNameTh": "รำแดง",
    "subDistrictNameEn": "Ram Daeng",
    "postcode": "90330",
    "districtCode": "9015",
    "districtNameTh": "สิงหนคร",
    "districtNameEn": "Singhanakhon",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901505",
    "subDistrictNameTh": "วัดขนุน",
    "subDistrictNameEn": "Wat Khanun",
    "postcode": "90330",
    "districtCode": "9015",
    "districtNameTh": "สิงหนคร",
    "districtNameEn": "Singhanakhon",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901506",
    "subDistrictNameTh": "ชะแล้",
    "subDistrictNameEn": "Chalae",
    "postcode": "90330",
    "districtCode": "9015",
    "districtNameTh": "สิงหนคร",
    "districtNameEn": "Singhanakhon",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901507",
    "subDistrictNameTh": "ปากรอ",
    "subDistrictNameEn": "Pak Ro",
    "postcode": "90330",
    "districtCode": "9015",
    "districtNameTh": "สิงหนคร",
    "districtNameEn": "Singhanakhon",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901508",
    "subDistrictNameTh": "ป่าขาด",
    "subDistrictNameEn": "Pa Khat",
    "postcode": "90330",
    "districtCode": "9015",
    "districtNameTh": "สิงหนคร",
    "districtNameEn": "Singhanakhon",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901509",
    "subDistrictNameTh": "หัวเขา",
    "subDistrictNameEn": "Hua Khao",
    "postcode": "90280",
    "districtCode": "9015",
    "districtNameTh": "สิงหนคร",
    "districtNameEn": "Singhanakhon",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901510",
    "subDistrictNameTh": "บางเขียด",
    "subDistrictNameEn": "Bang Khiat",
    "postcode": "90330",
    "districtCode": "9015",
    "districtNameTh": "สิงหนคร",
    "districtNameEn": "Singhanakhon",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901511",
    "subDistrictNameTh": "ม่วงงาม",
    "subDistrictNameEn": "Muang Ngam",
    "postcode": "90330",
    "districtCode": "9015",
    "districtNameTh": "สิงหนคร",
    "districtNameEn": "Singhanakhon",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901601",
    "subDistrictNameTh": "คลองหอยโข่ง",
    "subDistrictNameEn": "Khlong Hoi Khong",
    "postcode": "90230",
    "districtCode": "9016",
    "districtNameTh": "คลองหอยโข่ง",
    "districtNameEn": "Khlong Hoi Khong",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901602",
    "subDistrictNameTh": "ทุ่งลาน",
    "subDistrictNameEn": "Thung Lan",
    "postcode": "90230",
    "districtCode": "9016",
    "districtNameTh": "คลองหอยโข่ง",
    "districtNameEn": "Khlong Hoi Khong",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901603",
    "subDistrictNameTh": "โคกม่วง",
    "subDistrictNameEn": "Khok Muang",
    "postcode": "90230",
    "districtCode": "9016",
    "districtNameTh": "คลองหอยโข่ง",
    "districtNameEn": "Khlong Hoi Khong",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "901604",
    "subDistrictNameTh": "คลองหลา",
    "subDistrictNameEn": "Khlong La",
    "postcode": "90110",
    "districtCode": "9016",
    "districtNameTh": "คลองหอยโข่ง",
    "districtNameEn": "Khlong Hoi Khong",
    "provinceCode": "90",
    "provinceNameTh": "สงขลา",
    "provinceNameEn": "Songkhla"
},
{
    "subDistrictCode": "910101",
    "subDistrictNameTh": "พิมาน",
    "subDistrictNameEn": "Phiman",
    "postcode": "91000",
    "districtCode": "9101",
    "districtNameTh": "เมืองสตูล",
    "districtNameEn": "Mueang Satun",
    "provinceCode": "91",
    "provinceNameTh": "สตูล",
    "provinceNameEn": "Satun"
},
{
    "subDistrictCode": "910102",
    "subDistrictNameTh": "คลองขุด",
    "subDistrictNameEn": "Khlong Khut",
    "postcode": "91000",
    "districtCode": "9101",
    "districtNameTh": "เมืองสตูล",
    "districtNameEn": "Mueang Satun",
    "provinceCode": "91",
    "provinceNameTh": "สตูล",
    "provinceNameEn": "Satun"
},
{
    "subDistrictCode": "910103",
    "subDistrictNameTh": "ควนขัน",
    "subDistrictNameEn": "Khuan Khan",
    "postcode": "91000",
    "districtCode": "9101",
    "districtNameTh": "เมืองสตูล",
    "districtNameEn": "Mueang Satun",
    "provinceCode": "91",
    "provinceNameTh": "สตูล",
    "provinceNameEn": "Satun"
},
{
    "subDistrictCode": "910104",
    "subDistrictNameTh": "บ้านควน",
    "subDistrictNameEn": "Ban Khuan",
    "postcode": "91140",
    "districtCode": "9101",
    "districtNameTh": "เมืองสตูล",
    "districtNameEn": "Mueang Satun",
    "provinceCode": "91",
    "provinceNameTh": "สตูล",
    "provinceNameEn": "Satun"
},
{
    "subDistrictCode": "910105",
    "subDistrictNameTh": "ฉลุง",
    "subDistrictNameEn": "Chalung",
    "postcode": "91140",
    "districtCode": "9101",
    "districtNameTh": "เมืองสตูล",
    "districtNameEn": "Mueang Satun",
    "provinceCode": "91",
    "provinceNameTh": "สตูล",
    "provinceNameEn": "Satun"
},
{
    "subDistrictCode": "910106",
    "subDistrictNameTh": "เกาะสาหร่าย",
    "subDistrictNameEn": "Ko Sarai",
    "postcode": "91000",
    "districtCode": "9101",
    "districtNameTh": "เมืองสตูล",
    "districtNameEn": "Mueang Satun",
    "provinceCode": "91",
    "provinceNameTh": "สตูล",
    "provinceNameEn": "Satun"
},
{
    "subDistrictCode": "910107",
    "subDistrictNameTh": "ตันหยงโป",
    "subDistrictNameEn": "Tanyong Po",
    "postcode": "91000",
    "districtCode": "9101",
    "districtNameTh": "เมืองสตูล",
    "districtNameEn": "Mueang Satun",
    "provinceCode": "91",
    "provinceNameTh": "สตูล",
    "provinceNameEn": "Satun"
},
{
    "subDistrictCode": "910108",
    "subDistrictNameTh": "เจ๊ะบิลัง",
    "subDistrictNameEn": "Che Bilang",
    "postcode": "91000",
    "districtCode": "9101",
    "districtNameTh": "เมืองสตูล",
    "districtNameEn": "Mueang Satun",
    "provinceCode": "91",
    "provinceNameTh": "สตูล",
    "provinceNameEn": "Satun"
},
{
    "subDistrictCode": "910109",
    "subDistrictNameTh": "ตำมะลัง",
    "subDistrictNameEn": "Tam Malang",
    "postcode": "91000",
    "districtCode": "9101",
    "districtNameTh": "เมืองสตูล",
    "districtNameEn": "Mueang Satun",
    "provinceCode": "91",
    "provinceNameTh": "สตูล",
    "provinceNameEn": "Satun"
},
{
    "subDistrictCode": "910110",
    "subDistrictNameTh": "ปูยู",
    "subDistrictNameEn": "Puyu",
    "postcode": "91000",
    "districtCode": "9101",
    "districtNameTh": "เมืองสตูล",
    "districtNameEn": "Mueang Satun",
    "provinceCode": "91",
    "provinceNameTh": "สตูล",
    "provinceNameEn": "Satun"
},
{
    "subDistrictCode": "910111",
    "subDistrictNameTh": "ควนโพธิ์",
    "subDistrictNameEn": "Khuan Pho",
    "postcode": "91140",
    "districtCode": "9101",
    "districtNameTh": "เมืองสตูล",
    "districtNameEn": "Mueang Satun",
    "provinceCode": "91",
    "provinceNameTh": "สตูล",
    "provinceNameEn": "Satun"
},
{
    "subDistrictCode": "910112",
    "subDistrictNameTh": "เกตรี",
    "subDistrictNameEn": "Ket Ri",
    "postcode": "91140",
    "districtCode": "9101",
    "districtNameTh": "เมืองสตูล",
    "districtNameEn": "Mueang Satun",
    "provinceCode": "91",
    "provinceNameTh": "สตูล",
    "provinceNameEn": "Satun"
},
{
    "subDistrictCode": "910201",
    "subDistrictNameTh": "ควนโดน",
    "subDistrictNameEn": "Khuan Don",
    "postcode": "91160",
    "districtCode": "9102",
    "districtNameTh": "ควนโดน",
    "districtNameEn": "Khuan Don",
    "provinceCode": "91",
    "provinceNameTh": "สตูล",
    "provinceNameEn": "Satun"
},
{
    "subDistrictCode": "910202",
    "subDistrictNameTh": "ควนสตอ",
    "subDistrictNameEn": "Khuan Sato",
    "postcode": "91160",
    "districtCode": "9102",
    "districtNameTh": "ควนโดน",
    "districtNameEn": "Khuan Don",
    "provinceCode": "91",
    "provinceNameTh": "สตูล",
    "provinceNameEn": "Satun"
},
{
    "subDistrictCode": "910203",
    "subDistrictNameTh": "ย่านซื่อ",
    "subDistrictNameEn": "Yan Sue",
    "postcode": "91160",
    "districtCode": "9102",
    "districtNameTh": "ควนโดน",
    "districtNameEn": "Khuan Don",
    "provinceCode": "91",
    "provinceNameTh": "สตูล",
    "provinceNameEn": "Satun"
},
{
    "subDistrictCode": "910204",
    "subDistrictNameTh": "วังประจัน",
    "subDistrictNameEn": "Wang Prachan",
    "postcode": "91160",
    "districtCode": "9102",
    "districtNameTh": "ควนโดน",
    "districtNameEn": "Khuan Don",
    "provinceCode": "91",
    "provinceNameTh": "สตูล",
    "provinceNameEn": "Satun"
},
{
    "subDistrictCode": "910301",
    "subDistrictNameTh": "ทุ่งนุ้ย",
    "subDistrictNameEn": "Thung Nui",
    "postcode": "91130",
    "districtCode": "9103",
    "districtNameTh": "ควนกาหลง",
    "districtNameEn": "Khuan Kalong",
    "provinceCode": "91",
    "provinceNameTh": "สตูล",
    "provinceNameEn": "Satun"
},
{
    "subDistrictCode": "910302",
    "subDistrictNameTh": "ควนกาหลง",
    "subDistrictNameEn": "Khuan Kalong",
    "postcode": "91130",
    "districtCode": "9103",
    "districtNameTh": "ควนกาหลง",
    "districtNameEn": "Khuan Kalong",
    "provinceCode": "91",
    "provinceNameTh": "สตูล",
    "provinceNameEn": "Satun"
},
{
    "subDistrictCode": "910303",
    "subDistrictNameTh": "อุใดเจริญ",
    "subDistrictNameEn": "Udai Charoen",
    "postcode": "91130",
    "districtCode": "9103",
    "districtNameTh": "ควนกาหลง",
    "districtNameEn": "Khuan Kalong",
    "provinceCode": "91",
    "provinceNameTh": "สตูล",
    "provinceNameEn": "Satun"
},
{
    "subDistrictCode": "910401",
    "subDistrictNameTh": "ท่าแพ",
    "subDistrictNameEn": "Tha Phae",
    "postcode": "91150",
    "districtCode": "9104",
    "districtNameTh": "ท่าแพ",
    "districtNameEn": "Tha Phae",
    "provinceCode": "91",
    "provinceNameTh": "สตูล",
    "provinceNameEn": "Satun"
},
{
    "subDistrictCode": "910402",
    "subDistrictNameTh": "แป-ระ",
    "subDistrictNameEn": "Pae-ra",
    "postcode": "91150",
    "districtCode": "9104",
    "districtNameTh": "ท่าแพ",
    "districtNameEn": "Tha Phae",
    "provinceCode": "91",
    "provinceNameTh": "สตูล",
    "provinceNameEn": "Satun"
},
{
    "subDistrictCode": "910403",
    "subDistrictNameTh": "สาคร",
    "subDistrictNameEn": "Sakhon",
    "postcode": "91150",
    "districtCode": "9104",
    "districtNameTh": "ท่าแพ",
    "districtNameEn": "Tha Phae",
    "provinceCode": "91",
    "provinceNameTh": "สตูล",
    "provinceNameEn": "Satun"
},
{
    "subDistrictCode": "910404",
    "subDistrictNameTh": "ท่าเรือ",
    "subDistrictNameEn": "Tha Ruea",
    "postcode": "91150",
    "districtCode": "9104",
    "districtNameTh": "ท่าแพ",
    "districtNameEn": "Tha Phae",
    "provinceCode": "91",
    "provinceNameTh": "สตูล",
    "provinceNameEn": "Satun"
},
{
    "subDistrictCode": "910501",
    "subDistrictNameTh": "กำแพง",
    "subDistrictNameEn": "Kamphaeng",
    "postcode": "91110",
    "districtCode": "9105",
    "districtNameTh": "ละงู",
    "districtNameEn": "La-ngu",
    "provinceCode": "91",
    "provinceNameTh": "สตูล",
    "provinceNameEn": "Satun"
},
{
    "subDistrictCode": "910502",
    "subDistrictNameTh": "ละงู",
    "subDistrictNameEn": "La-ngu",
    "postcode": "91110",
    "districtCode": "9105",
    "districtNameTh": "ละงู",
    "districtNameEn": "La-ngu",
    "provinceCode": "91",
    "provinceNameTh": "สตูล",
    "provinceNameEn": "Satun"
},
{
    "subDistrictCode": "910503",
    "subDistrictNameTh": "เขาขาว",
    "subDistrictNameEn": "Khao Khao",
    "postcode": "91110",
    "districtCode": "9105",
    "districtNameTh": "ละงู",
    "districtNameEn": "La-ngu",
    "provinceCode": "91",
    "provinceNameTh": "สตูล",
    "provinceNameEn": "Satun"
},
{
    "subDistrictCode": "910504",
    "subDistrictNameTh": "ปากน้ำ",
    "subDistrictNameEn": "Pak Nam",
    "postcode": "91110",
    "districtCode": "9105",
    "districtNameTh": "ละงู",
    "districtNameEn": "La-ngu",
    "provinceCode": "91",
    "provinceNameTh": "สตูล",
    "provinceNameEn": "Satun"
},
{
    "subDistrictCode": "910505",
    "subDistrictNameTh": "น้ำผุด",
    "subDistrictNameEn": "Nam Phut",
    "postcode": "91110",
    "districtCode": "9105",
    "districtNameTh": "ละงู",
    "districtNameEn": "La-ngu",
    "provinceCode": "91",
    "provinceNameTh": "สตูล",
    "provinceNameEn": "Satun"
},
{
    "subDistrictCode": "910506",
    "subDistrictNameTh": "แหลมสน",
    "subDistrictNameEn": "Laem Son",
    "postcode": "91110",
    "districtCode": "9105",
    "districtNameTh": "ละงู",
    "districtNameEn": "La-ngu",
    "provinceCode": "91",
    "provinceNameTh": "สตูล",
    "provinceNameEn": "Satun"
},
{
    "subDistrictCode": "910601",
    "subDistrictNameTh": "ทุ่งหว้า",
    "subDistrictNameEn": "Thung Wa",
    "postcode": "91120",
    "districtCode": "9106",
    "districtNameTh": "ทุ่งหว้า",
    "districtNameEn": "Thung Wa",
    "provinceCode": "91",
    "provinceNameTh": "สตูล",
    "provinceNameEn": "Satun"
},
{
    "subDistrictCode": "910602",
    "subDistrictNameTh": "นาทอน",
    "subDistrictNameEn": "Na Thon",
    "postcode": "91120",
    "districtCode": "9106",
    "districtNameTh": "ทุ่งหว้า",
    "districtNameEn": "Thung Wa",
    "provinceCode": "91",
    "provinceNameTh": "สตูล",
    "provinceNameEn": "Satun"
},
{
    "subDistrictCode": "910603",
    "subDistrictNameTh": "ขอนคลาน",
    "subDistrictNameEn": "Khon Khlan",
    "postcode": "91120",
    "districtCode": "9106",
    "districtNameTh": "ทุ่งหว้า",
    "districtNameEn": "Thung Wa",
    "provinceCode": "91",
    "provinceNameTh": "สตูล",
    "provinceNameEn": "Satun"
},
{
    "subDistrictCode": "910604",
    "subDistrictNameTh": "ทุ่งบุหลัง",
    "subDistrictNameEn": "Thung Bu Lang",
    "postcode": "91120",
    "districtCode": "9106",
    "districtNameTh": "ทุ่งหว้า",
    "districtNameEn": "Thung Wa",
    "provinceCode": "91",
    "provinceNameTh": "สตูล",
    "provinceNameEn": "Satun"
},
{
    "subDistrictCode": "910605",
    "subDistrictNameTh": "ป่าแก่บ่อหิน",
    "subDistrictNameEn": "Pa Kae Bo Hin",
    "postcode": "91120",
    "districtCode": "9106",
    "districtNameTh": "ทุ่งหว้า",
    "districtNameEn": "Thung Wa",
    "provinceCode": "91",
    "provinceNameTh": "สตูล",
    "provinceNameEn": "Satun"
},
{
    "subDistrictCode": "910701",
    "subDistrictNameTh": "ปาล์มพัฒนา",
    "subDistrictNameEn": "Pam Phatthana",
    "postcode": "91130",
    "districtCode": "9107",
    "districtNameTh": "มะนัง",
    "districtNameEn": "Manang",
    "provinceCode": "91",
    "provinceNameTh": "สตูล",
    "provinceNameEn": "Satun"
},
{
    "subDistrictCode": "910702",
    "subDistrictNameTh": "นิคมพัฒนา",
    "subDistrictNameEn": "Nikhom Phatthana",
    "postcode": "91130",
    "districtCode": "9107",
    "districtNameTh": "มะนัง",
    "districtNameEn": "Manang",
    "provinceCode": "91",
    "provinceNameTh": "สตูล",
    "provinceNameEn": "Satun"
},
{
    "subDistrictCode": "920101",
    "subDistrictNameTh": "ทับเที่ยง",
    "subDistrictNameEn": "Thap Thiang",
    "postcode": "92000",
    "districtCode": "9201",
    "districtNameTh": "เมืองตรัง",
    "districtNameEn": "Mueang Trang",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920104",
    "subDistrictNameTh": "นาพละ",
    "subDistrictNameEn": "Na Phala",
    "postcode": "92000",
    "districtCode": "9201",
    "districtNameTh": "เมืองตรัง",
    "districtNameEn": "Mueang Trang",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920105",
    "subDistrictNameTh": "บ้านควน",
    "subDistrictNameEn": "Ban Khuan",
    "postcode": "92000",
    "districtCode": "9201",
    "districtNameTh": "เมืองตรัง",
    "districtNameEn": "Mueang Trang",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920106",
    "subDistrictNameTh": "นาบินหลา",
    "subDistrictNameEn": "Na Bin La",
    "postcode": "92170",
    "districtCode": "9201",
    "districtNameTh": "เมืองตรัง",
    "districtNameEn": "Mueang Trang",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920107",
    "subDistrictNameTh": "ควนปริง",
    "subDistrictNameEn": "Khuan Pring",
    "postcode": "92000",
    "districtCode": "9201",
    "districtNameTh": "เมืองตรัง",
    "districtNameEn": "Mueang Trang",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920108",
    "subDistrictNameTh": "นาโยงใต้",
    "subDistrictNameEn": "Na Yong Tai",
    "postcode": "92170",
    "districtCode": "9201",
    "districtNameTh": "เมืองตรัง",
    "districtNameEn": "Mueang Trang",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920109",
    "subDistrictNameTh": "บางรัก",
    "subDistrictNameEn": "Bang Rak",
    "postcode": "92000",
    "districtCode": "9201",
    "districtNameTh": "เมืองตรัง",
    "districtNameEn": "Mueang Trang",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920110",
    "subDistrictNameTh": "โคกหล่อ",
    "subDistrictNameEn": "Khok Lo",
    "postcode": "92000",
    "districtCode": "9201",
    "districtNameTh": "เมืองตรัง",
    "districtNameEn": "Mueang Trang",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920113",
    "subDistrictNameTh": "นาโต๊ะหมิง",
    "subDistrictNameEn": "Na To Ming",
    "postcode": "92000",
    "districtCode": "9201",
    "districtNameTh": "เมืองตรัง",
    "districtNameEn": "Mueang Trang",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920114",
    "subDistrictNameTh": "หนองตรุด",
    "subDistrictNameEn": "Nong Trut",
    "postcode": "92000",
    "districtCode": "9201",
    "districtNameTh": "เมืองตรัง",
    "districtNameEn": "Mueang Trang",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920115",
    "subDistrictNameTh": "น้ำผุด",
    "subDistrictNameEn": "Nam Phut",
    "postcode": "92000",
    "districtCode": "9201",
    "districtNameTh": "เมืองตรัง",
    "districtNameEn": "Mueang Trang",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920117",
    "subDistrictNameTh": "นาตาล่วง",
    "subDistrictNameEn": "Na Ta Luang",
    "postcode": "92000",
    "districtCode": "9201",
    "districtNameTh": "เมืองตรัง",
    "districtNameEn": "Mueang Trang",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920118",
    "subDistrictNameTh": "บ้านโพธิ์",
    "subDistrictNameEn": "Ban Pho",
    "postcode": "92000",
    "districtCode": "9201",
    "districtNameTh": "เมืองตรัง",
    "districtNameEn": "Mueang Trang",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920119",
    "subDistrictNameTh": "นาท่ามเหนือ",
    "subDistrictNameEn": "Na Tham Nuea",
    "postcode": "92190",
    "districtCode": "9201",
    "districtNameTh": "เมืองตรัง",
    "districtNameEn": "Mueang Trang",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920120",
    "subDistrictNameTh": "นาท่ามใต้",
    "subDistrictNameEn": "Na Tham Tai",
    "postcode": "92190",
    "districtCode": "9201",
    "districtNameTh": "เมืองตรัง",
    "districtNameEn": "Mueang Trang",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920201",
    "subDistrictNameTh": "กันตัง",
    "subDistrictNameEn": "Kantang",
    "postcode": "92110",
    "districtCode": "9202",
    "districtNameTh": "กันตัง",
    "districtNameEn": "Kantang",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920202",
    "subDistrictNameTh": "ควนธานี",
    "subDistrictNameEn": "Khuan Thani",
    "postcode": "92110",
    "districtCode": "9202",
    "districtNameTh": "กันตัง",
    "districtNameEn": "Kantang",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920203",
    "subDistrictNameTh": "บางหมาก",
    "subDistrictNameEn": "Bang Mak",
    "postcode": "92110",
    "districtCode": "9202",
    "districtNameTh": "กันตัง",
    "districtNameEn": "Kantang",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920204",
    "subDistrictNameTh": "บางเป้า",
    "subDistrictNameEn": "Bang Pao",
    "postcode": "92110",
    "districtCode": "9202",
    "districtNameTh": "กันตัง",
    "districtNameEn": "Kantang",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920205",
    "subDistrictNameTh": "วังวน",
    "subDistrictNameEn": "Wang Won",
    "postcode": "92110",
    "districtCode": "9202",
    "districtNameTh": "กันตัง",
    "districtNameEn": "Kantang",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920206",
    "subDistrictNameTh": "กันตังใต้",
    "subDistrictNameEn": "Kantang Tai",
    "postcode": "92110",
    "districtCode": "9202",
    "districtNameTh": "กันตัง",
    "districtNameEn": "Kantang",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920207",
    "subDistrictNameTh": "โคกยาง",
    "subDistrictNameEn": "Khok Yang",
    "postcode": "92110",
    "districtCode": "9202",
    "districtNameTh": "กันตัง",
    "districtNameEn": "Kantang",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920208",
    "subDistrictNameTh": "คลองลุ",
    "subDistrictNameEn": "Khlong Lu",
    "postcode": "92110",
    "districtCode": "9202",
    "districtNameTh": "กันตัง",
    "districtNameEn": "Kantang",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920209",
    "subDistrictNameTh": "ย่านซื่อ",
    "subDistrictNameEn": "Yan Sue",
    "postcode": "92110",
    "districtCode": "9202",
    "districtNameTh": "กันตัง",
    "districtNameEn": "Kantang",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920210",
    "subDistrictNameTh": "บ่อน้ำร้อน",
    "subDistrictNameEn": "Bo Nam Ron",
    "postcode": "92110",
    "districtCode": "9202",
    "districtNameTh": "กันตัง",
    "districtNameEn": "Kantang",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920211",
    "subDistrictNameTh": "บางสัก",
    "subDistrictNameEn": "Bang Sak",
    "postcode": "92110",
    "districtCode": "9202",
    "districtNameTh": "กันตัง",
    "districtNameEn": "Kantang",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920212",
    "subDistrictNameTh": "นาเกลือ",
    "subDistrictNameEn": "Na Kluea",
    "postcode": "92110",
    "districtCode": "9202",
    "districtNameTh": "กันตัง",
    "districtNameEn": "Kantang",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920213",
    "subDistrictNameTh": "เกาะลิบง",
    "subDistrictNameEn": "Ko Libong",
    "postcode": "92110",
    "districtCode": "9202",
    "districtNameTh": "กันตัง",
    "districtNameEn": "Kantang",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920214",
    "subDistrictNameTh": "คลองชีล้อม",
    "subDistrictNameEn": "Khlong Chi Lom",
    "postcode": "92110",
    "districtCode": "9202",
    "districtNameTh": "กันตัง",
    "districtNameEn": "Kantang",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920301",
    "subDistrictNameTh": "ย่านตาขาว",
    "subDistrictNameEn": "Yan Ta Khao",
    "postcode": "92140",
    "districtCode": "9203",
    "districtNameTh": "ย่านตาขาว",
    "districtNameEn": "Yan Ta Khao",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920302",
    "subDistrictNameTh": "หนองบ่อ",
    "subDistrictNameEn": "Nong Bo",
    "postcode": "92140",
    "districtCode": "9203",
    "districtNameTh": "ย่านตาขาว",
    "districtNameEn": "Yan Ta Khao",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920303",
    "subDistrictNameTh": "นาชุมเห็ด",
    "subDistrictNameEn": "Na Chum Het",
    "postcode": "92140",
    "districtCode": "9203",
    "districtNameTh": "ย่านตาขาว",
    "districtNameEn": "Yan Ta Khao",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920304",
    "subDistrictNameTh": "ในควน",
    "subDistrictNameEn": "Nai Khuan",
    "postcode": "92140",
    "districtCode": "9203",
    "districtNameTh": "ย่านตาขาว",
    "districtNameEn": "Yan Ta Khao",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920305",
    "subDistrictNameTh": "โพรงจระเข้",
    "subDistrictNameEn": "Phrong Chorakhe",
    "postcode": "92140",
    "districtCode": "9203",
    "districtNameTh": "ย่านตาขาว",
    "districtNameEn": "Yan Ta Khao",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920306",
    "subDistrictNameTh": "ทุ่งกระบือ",
    "subDistrictNameEn": "Thung Krabue",
    "postcode": "92140",
    "districtCode": "9203",
    "districtNameTh": "ย่านตาขาว",
    "districtNameEn": "Yan Ta Khao",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920307",
    "subDistrictNameTh": "ทุ่งค่าย",
    "subDistrictNameEn": "Thung Khai",
    "postcode": "92140",
    "districtCode": "9203",
    "districtNameTh": "ย่านตาขาว",
    "districtNameEn": "Yan Ta Khao",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920308",
    "subDistrictNameTh": "เกาะเปียะ",
    "subDistrictNameEn": "Ko Pia",
    "postcode": "92140",
    "districtCode": "9203",
    "districtNameTh": "ย่านตาขาว",
    "districtNameEn": "Yan Ta Khao",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920401",
    "subDistrictNameTh": "ท่าข้าม",
    "subDistrictNameEn": "Tha Kham",
    "postcode": "92120",
    "districtCode": "9204",
    "districtNameTh": "ปะเหลียน",
    "districtNameEn": "Palian",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920402",
    "subDistrictNameTh": "ทุ่งยาว",
    "subDistrictNameEn": "Thung Yao",
    "postcode": "92180",
    "districtCode": "9204",
    "districtNameTh": "ปะเหลียน",
    "districtNameEn": "Palian",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920403",
    "subDistrictNameTh": "ปะเหลียน",
    "subDistrictNameEn": "Palian",
    "postcode": "92180",
    "districtCode": "9204",
    "districtNameTh": "ปะเหลียน",
    "districtNameEn": "Palian",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920404",
    "subDistrictNameTh": "บางด้วน",
    "subDistrictNameEn": "Bang Duan",
    "postcode": "92140",
    "districtCode": "9204",
    "districtNameTh": "ปะเหลียน",
    "districtNameEn": "Palian",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920407",
    "subDistrictNameTh": "บ้านนา",
    "subDistrictNameEn": "Ban Na",
    "postcode": "92140",
    "districtCode": "9204",
    "districtNameTh": "ปะเหลียน",
    "districtNameEn": "Palian",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920409",
    "subDistrictNameTh": "สุโสะ",
    "subDistrictNameEn": "Suso",
    "postcode": "92120",
    "districtCode": "9204",
    "districtNameTh": "ปะเหลียน",
    "districtNameEn": "Palian",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920410",
    "subDistrictNameTh": "ลิพัง",
    "subDistrictNameEn": "Liphang",
    "postcode": "92180",
    "districtCode": "9204",
    "districtNameTh": "ปะเหลียน",
    "districtNameEn": "Palian",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920411",
    "subDistrictNameTh": "เกาะสุกร",
    "subDistrictNameEn": "Ko Sukon",
    "postcode": "92120",
    "districtCode": "9204",
    "districtNameTh": "ปะเหลียน",
    "districtNameEn": "Palian",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920412",
    "subDistrictNameTh": "ท่าพญา",
    "subDistrictNameEn": "Tha Phaya",
    "postcode": "92140",
    "districtCode": "9204",
    "districtNameTh": "ปะเหลียน",
    "districtNameEn": "Palian",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920413",
    "subDistrictNameTh": "แหลมสอม",
    "subDistrictNameEn": "Laem Som",
    "postcode": "92180",
    "districtCode": "9204",
    "districtNameTh": "ปะเหลียน",
    "districtNameEn": "Palian",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920501",
    "subDistrictNameTh": "บ่อหิน",
    "subDistrictNameEn": "Bo Hin",
    "postcode": "92150",
    "districtCode": "9205",
    "districtNameTh": "สิเกา",
    "districtNameEn": "Sikao",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920502",
    "subDistrictNameTh": "เขาไม้แก้ว",
    "subDistrictNameEn": "Khao Mai Kaeo",
    "postcode": "92150",
    "districtCode": "9205",
    "districtNameTh": "สิเกา",
    "districtNameEn": "Sikao",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920503",
    "subDistrictNameTh": "กะลาเส",
    "subDistrictNameEn": "Kalase",
    "postcode": "92150",
    "districtCode": "9205",
    "districtNameTh": "สิเกา",
    "districtNameEn": "Sikao",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920504",
    "subDistrictNameTh": "ไม้ฝาด",
    "subDistrictNameEn": "Mai Fat",
    "postcode": "92150",
    "districtCode": "9205",
    "districtNameTh": "สิเกา",
    "districtNameEn": "Sikao",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920505",
    "subDistrictNameTh": "นาเมืองเพชร",
    "subDistrictNameEn": "Na Mueang Phet",
    "postcode": "92000",
    "districtCode": "9205",
    "districtNameTh": "สิเกา",
    "districtNameEn": "Sikao",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920601",
    "subDistrictNameTh": "ห้วยยอด",
    "subDistrictNameEn": "Huai Yot",
    "postcode": "92130",
    "districtCode": "9206",
    "districtNameTh": "ห้วยยอด",
    "districtNameEn": "Huai Yot",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920602",
    "subDistrictNameTh": "หนองช้างแล่น",
    "subDistrictNameEn": "Nong Chang Laen",
    "postcode": "92130",
    "districtCode": "9206",
    "districtNameTh": "ห้วยยอด",
    "districtNameEn": "Huai Yot",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920605",
    "subDistrictNameTh": "บางดี",
    "subDistrictNameEn": "Bang Di",
    "postcode": "92210",
    "districtCode": "9206",
    "districtNameTh": "ห้วยยอด",
    "districtNameEn": "Huai Yot",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920606",
    "subDistrictNameTh": "บางกุ้ง",
    "subDistrictNameEn": "Bang Kung",
    "postcode": "92210",
    "districtCode": "9206",
    "districtNameTh": "ห้วยยอด",
    "districtNameEn": "Huai Yot",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920607",
    "subDistrictNameTh": "เขากอบ",
    "subDistrictNameEn": "Khao Kop",
    "postcode": "92130",
    "districtCode": "9206",
    "districtNameTh": "ห้วยยอด",
    "districtNameEn": "Huai Yot",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920608",
    "subDistrictNameTh": "เขาขาว",
    "subDistrictNameEn": "Khao Khao",
    "postcode": "92130",
    "districtCode": "9206",
    "districtNameTh": "ห้วยยอด",
    "districtNameEn": "Huai Yot",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920609",
    "subDistrictNameTh": "เขาปูน",
    "subDistrictNameEn": "Khao Pun",
    "postcode": "92130",
    "districtCode": "9206",
    "districtNameTh": "ห้วยยอด",
    "districtNameEn": "Huai Yot",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920610",
    "subDistrictNameTh": "ปากแจ่ม",
    "subDistrictNameEn": "Pak Chaem",
    "postcode": "92190",
    "districtCode": "9206",
    "districtNameTh": "ห้วยยอด",
    "districtNameEn": "Huai Yot",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920611",
    "subDistrictNameTh": "ปากคม",
    "subDistrictNameEn": "Pak Khom",
    "postcode": "92130",
    "districtCode": "9206",
    "districtNameTh": "ห้วยยอด",
    "districtNameEn": "Huai Yot",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920614",
    "subDistrictNameTh": "ท่างิ้ว",
    "subDistrictNameEn": "Tha Ngio",
    "postcode": "92130",
    "districtCode": "9206",
    "districtNameTh": "ห้วยยอด",
    "districtNameEn": "Huai Yot",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920615",
    "subDistrictNameTh": "ลำภูรา",
    "subDistrictNameEn": "Lamphu Ra",
    "postcode": "92190",
    "districtCode": "9206",
    "districtNameTh": "ห้วยยอด",
    "districtNameEn": "Huai Yot",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920616",
    "subDistrictNameTh": "นาวง",
    "subDistrictNameEn": "Na Wong",
    "postcode": "92210",
    "districtCode": "9206",
    "districtNameTh": "ห้วยยอด",
    "districtNameEn": "Huai Yot",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920617",
    "subDistrictNameTh": "ห้วยนาง",
    "subDistrictNameEn": "Huai Nang",
    "postcode": "92130",
    "districtCode": "9206",
    "districtNameTh": "ห้วยยอด",
    "districtNameEn": "Huai Yot",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920619",
    "subDistrictNameTh": "ในเตา",
    "subDistrictNameEn": "Nai Tao",
    "postcode": "92130",
    "districtCode": "9206",
    "districtNameTh": "ห้วยยอด",
    "districtNameEn": "Huai Yot",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920620",
    "subDistrictNameTh": "ทุ่งต่อ",
    "subDistrictNameEn": "Thung To",
    "postcode": "92130",
    "districtCode": "9206",
    "districtNameTh": "ห้วยยอด",
    "districtNameEn": "Huai Yot",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920621",
    "subDistrictNameTh": "วังคีรี",
    "subDistrictNameEn": "Wang Khiri",
    "postcode": "92210",
    "districtCode": "9206",
    "districtNameTh": "ห้วยยอด",
    "districtNameEn": "Huai Yot",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920701",
    "subDistrictNameTh": "เขาวิเศษ",
    "subDistrictNameEn": "Khao Wiset",
    "postcode": "92220",
    "districtCode": "9207",
    "districtNameTh": "วังวิเศษ",
    "districtNameEn": "Wang Wiset",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920702",
    "subDistrictNameTh": "วังมะปราง",
    "subDistrictNameEn": "Wang Maprang",
    "postcode": "92220",
    "districtCode": "9207",
    "districtNameTh": "วังวิเศษ",
    "districtNameEn": "Wang Wiset",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920703",
    "subDistrictNameTh": "อ่าวตง",
    "subDistrictNameEn": "Ao Tong",
    "postcode": "92220",
    "districtCode": "9207",
    "districtNameTh": "วังวิเศษ",
    "districtNameEn": "Wang Wiset",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920704",
    "subDistrictNameTh": "ท่าสะบ้า",
    "subDistrictNameEn": "Tha Saba",
    "postcode": "92000",
    "districtCode": "9207",
    "districtNameTh": "วังวิเศษ",
    "districtNameEn": "Wang Wiset",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920705",
    "subDistrictNameTh": "วังมะปรางเหนือ",
    "subDistrictNameEn": "Wang Maprang Nuea",
    "postcode": "92220",
    "districtCode": "9207",
    "districtNameTh": "วังวิเศษ",
    "districtNameEn": "Wang Wiset",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920801",
    "subDistrictNameTh": "นาโยงเหนือ",
    "subDistrictNameEn": "Na Yong Nuea",
    "postcode": "92170",
    "districtCode": "9208",
    "districtNameTh": "นาโยง",
    "districtNameEn": "Na Yong",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920802",
    "subDistrictNameTh": "ช่อง",
    "subDistrictNameEn": "Chong",
    "postcode": "92170",
    "districtCode": "9208",
    "districtNameTh": "นาโยง",
    "districtNameEn": "Na Yong",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920803",
    "subDistrictNameTh": "ละมอ",
    "subDistrictNameEn": "Lamo",
    "postcode": "92170",
    "districtCode": "9208",
    "districtNameTh": "นาโยง",
    "districtNameEn": "Na Yong",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920804",
    "subDistrictNameTh": "โคกสะบ้า",
    "subDistrictNameEn": "Khok Saba",
    "postcode": "92170",
    "districtCode": "9208",
    "districtNameTh": "นาโยง",
    "districtNameEn": "Na Yong",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920805",
    "subDistrictNameTh": "นาหมื่นศรี",
    "subDistrictNameEn": "Na Muen Si",
    "postcode": "92170",
    "districtCode": "9208",
    "districtNameTh": "นาโยง",
    "districtNameEn": "Na Yong",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920806",
    "subDistrictNameTh": "นาข้าวเสีย",
    "subDistrictNameEn": "Na Khao Sia",
    "postcode": "92170",
    "districtCode": "9208",
    "districtNameTh": "นาโยง",
    "districtNameEn": "Na Yong",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920901",
    "subDistrictNameTh": "ควนเมา",
    "subDistrictNameEn": "Khuan Mao",
    "postcode": "92160",
    "districtCode": "9209",
    "districtNameTh": "รัษฎา",
    "districtNameEn": "Ratsada",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920902",
    "subDistrictNameTh": "คลองปาง",
    "subDistrictNameEn": "Khlong Pang",
    "postcode": "92160",
    "districtCode": "9209",
    "districtNameTh": "รัษฎา",
    "districtNameEn": "Ratsada",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920903",
    "subDistrictNameTh": "หนองบัว",
    "subDistrictNameEn": "Nong Bua",
    "postcode": "92160",
    "districtCode": "9209",
    "districtNameTh": "รัษฎา",
    "districtNameEn": "Ratsada",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920904",
    "subDistrictNameTh": "หนองปรือ",
    "subDistrictNameEn": "Nong Prue",
    "postcode": "92130",
    "districtCode": "9209",
    "districtNameTh": "รัษฎา",
    "districtNameEn": "Ratsada",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "920905",
    "subDistrictNameTh": "เขาไพร",
    "subDistrictNameEn": "Khao Phrai",
    "postcode": "92160",
    "districtCode": "9209",
    "districtNameTh": "รัษฎา",
    "districtNameEn": "Ratsada",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "921001",
    "subDistrictNameTh": "หาดสำราญ",
    "subDistrictNameEn": "Hat Samran",
    "postcode": "92120",
    "districtCode": "9210",
    "districtNameTh": "หาดสำราญ",
    "districtNameEn": "Hat Samran",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "921002",
    "subDistrictNameTh": "บ้าหวี",
    "subDistrictNameEn": "Ba Wi",
    "postcode": "92120",
    "districtCode": "9210",
    "districtNameTh": "หาดสำราญ",
    "districtNameEn": "Hat Samran",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "921003",
    "subDistrictNameTh": "ตะเสะ",
    "subDistrictNameEn": "Tase",
    "postcode": "92120",
    "districtCode": "9210",
    "districtNameTh": "หาดสำราญ",
    "districtNameEn": "Hat Samran",
    "provinceCode": "92",
    "provinceNameTh": "ตรัง",
    "provinceNameEn": "Trang"
},
{
    "subDistrictCode": "930101",
    "subDistrictNameTh": "คูหาสวรรค์",
    "subDistrictNameEn": "Khuha Sawan",
    "postcode": "93000",
    "districtCode": "9301",
    "districtNameTh": "เมืองพัทลุง",
    "districtNameEn": "Mueang Phatthalung",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930103",
    "subDistrictNameTh": "เขาเจียก",
    "subDistrictNameEn": "Khao Chiak",
    "postcode": "93000",
    "districtCode": "9301",
    "districtNameTh": "เมืองพัทลุง",
    "districtNameEn": "Mueang Phatthalung",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930104",
    "subDistrictNameTh": "ท่ามิหรำ",
    "subDistrictNameEn": "Tha Miram",
    "postcode": "93000",
    "districtCode": "9301",
    "districtNameTh": "เมืองพัทลุง",
    "districtNameEn": "Mueang Phatthalung",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930105",
    "subDistrictNameTh": "โคกชะงาย",
    "subDistrictNameEn": "Khok Cha-ngai",
    "postcode": "93000",
    "districtCode": "9301",
    "districtNameTh": "เมืองพัทลุง",
    "districtNameEn": "Mueang Phatthalung",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930106",
    "subDistrictNameTh": "นาท่อม",
    "subDistrictNameEn": "Na Thom",
    "postcode": "93000",
    "districtCode": "9301",
    "districtNameTh": "เมืองพัทลุง",
    "districtNameEn": "Mueang Phatthalung",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930107",
    "subDistrictNameTh": "ปรางหมู่",
    "subDistrictNameEn": "Prang Mu",
    "postcode": "93000",
    "districtCode": "9301",
    "districtNameTh": "เมืองพัทลุง",
    "districtNameEn": "Mueang Phatthalung",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930108",
    "subDistrictNameTh": "ท่าแค",
    "subDistrictNameEn": "Tha Kae",
    "postcode": "93000",
    "districtCode": "9301",
    "districtNameTh": "เมืองพัทลุง",
    "districtNameEn": "Mueang Phatthalung",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930109",
    "subDistrictNameTh": "ลำปำ",
    "subDistrictNameEn": "Lampam",
    "postcode": "93000",
    "districtCode": "9301",
    "districtNameTh": "เมืองพัทลุง",
    "districtNameEn": "Mueang Phatthalung",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930110",
    "subDistrictNameTh": "ตำนาน",
    "subDistrictNameEn": "Tamnan",
    "postcode": "93000",
    "districtCode": "9301",
    "districtNameTh": "เมืองพัทลุง",
    "districtNameEn": "Mueang Phatthalung",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930111",
    "subDistrictNameTh": "ควนมะพร้าว",
    "subDistrictNameEn": "Khuan Maphrao",
    "postcode": "93000",
    "districtCode": "9301",
    "districtNameTh": "เมืองพัทลุง",
    "districtNameEn": "Mueang Phatthalung",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930112",
    "subDistrictNameTh": "ร่มเมือง",
    "subDistrictNameEn": "Rom Mueang",
    "postcode": "93000",
    "districtCode": "9301",
    "districtNameTh": "เมืองพัทลุง",
    "districtNameEn": "Mueang Phatthalung",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930113",
    "subDistrictNameTh": "ชัยบุรี",
    "subDistrictNameEn": "Chai Buri",
    "postcode": "93000",
    "districtCode": "9301",
    "districtNameTh": "เมืองพัทลุง",
    "districtNameEn": "Mueang Phatthalung",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930114",
    "subDistrictNameTh": "นาโหนด",
    "subDistrictNameEn": "Na Not",
    "postcode": "93000",
    "districtCode": "9301",
    "districtNameTh": "เมืองพัทลุง",
    "districtNameEn": "Mueang Phatthalung",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930115",
    "subDistrictNameTh": "พญาขัน",
    "subDistrictNameEn": "Phaya Khan",
    "postcode": "93000",
    "districtCode": "9301",
    "districtNameTh": "เมืองพัทลุง",
    "districtNameEn": "Mueang Phatthalung",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930201",
    "subDistrictNameTh": "กงหรา",
    "subDistrictNameEn": "Kong Ra",
    "postcode": "93180",
    "districtCode": "9302",
    "districtNameTh": "กงหรา",
    "districtNameEn": "Kong Ra",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930202",
    "subDistrictNameTh": "ชะรัด",
    "subDistrictNameEn": "Charat",
    "postcode": "93000",
    "districtCode": "9302",
    "districtNameTh": "กงหรา",
    "districtNameEn": "Kong Ra",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930203",
    "subDistrictNameTh": "คลองเฉลิม",
    "subDistrictNameEn": "Khlong Chaloem",
    "postcode": "93180",
    "districtCode": "9302",
    "districtNameTh": "กงหรา",
    "districtNameEn": "Kong Ra",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930204",
    "subDistrictNameTh": "คลองทรายขาว",
    "subDistrictNameEn": "Khlong Sai Khao",
    "postcode": "93180",
    "districtCode": "9302",
    "districtNameTh": "กงหรา",
    "districtNameEn": "Kong Ra",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930205",
    "subDistrictNameTh": "สมหวัง",
    "subDistrictNameEn": "Som Wang",
    "postcode": "93000",
    "districtCode": "9302",
    "districtNameTh": "กงหรา",
    "districtNameEn": "Kong Ra",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930301",
    "subDistrictNameTh": "เขาชัยสน",
    "subDistrictNameEn": "Khao Chaison",
    "postcode": "93130",
    "districtCode": "9303",
    "districtNameTh": "เขาชัยสน",
    "districtNameEn": "Khao Chaison",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930302",
    "subDistrictNameTh": "ควนขนุน",
    "subDistrictNameEn": "Khuan Khanun",
    "postcode": "93130",
    "districtCode": "9303",
    "districtNameTh": "เขาชัยสน",
    "districtNameEn": "Khao Chaison",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930305",
    "subDistrictNameTh": "จองถนน",
    "subDistrictNameEn": "Chong Thanon",
    "postcode": "93130",
    "districtCode": "9303",
    "districtNameTh": "เขาชัยสน",
    "districtNameEn": "Khao Chaison",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930306",
    "subDistrictNameTh": "หานโพธิ์",
    "subDistrictNameEn": "Han Pho",
    "postcode": "93130",
    "districtCode": "9303",
    "districtNameTh": "เขาชัยสน",
    "districtNameEn": "Khao Chaison",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930307",
    "subDistrictNameTh": "โคกม่วง",
    "subDistrictNameEn": "Khok Muang",
    "postcode": "93130",
    "districtCode": "9303",
    "districtNameTh": "เขาชัยสน",
    "districtNameEn": "Khao Chaison",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930401",
    "subDistrictNameTh": "แม่ขรี",
    "subDistrictNameEn": "Mae Khari",
    "postcode": "93160",
    "districtCode": "9304",
    "districtNameTh": "ตะโหมด",
    "districtNameEn": "Tamot",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930402",
    "subDistrictNameTh": "ตะโหมด",
    "subDistrictNameEn": "Tamot",
    "postcode": "93160",
    "districtCode": "9304",
    "districtNameTh": "ตะโหมด",
    "districtNameEn": "Tamot",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930403",
    "subDistrictNameTh": "คลองใหญ่",
    "subDistrictNameEn": "Khlong Yai",
    "postcode": "93160",
    "districtCode": "9304",
    "districtNameTh": "ตะโหมด",
    "districtNameEn": "Tamot",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930501",
    "subDistrictNameTh": "ควนขนุน",
    "subDistrictNameEn": "Khuan Khanun",
    "postcode": "93110",
    "districtCode": "9305",
    "districtNameTh": "ควนขนุน",
    "districtNameEn": "Khuan Khanun",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930502",
    "subDistrictNameTh": "ทะเลน้อย",
    "subDistrictNameEn": "Thale Noi",
    "postcode": "93150",
    "districtCode": "9305",
    "districtNameTh": "ควนขนุน",
    "districtNameEn": "Khuan Khanun",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930504",
    "subDistrictNameTh": "นาขยาด",
    "subDistrictNameEn": "Na Khayat",
    "postcode": "93110",
    "districtCode": "9305",
    "districtNameTh": "ควนขนุน",
    "districtNameEn": "Khuan Khanun",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930505",
    "subDistrictNameTh": "พนมวังก์",
    "subDistrictNameEn": "Phanom Wang",
    "postcode": "93110",
    "districtCode": "9305",
    "districtNameTh": "ควนขนุน",
    "districtNameEn": "Khuan Khanun",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930506",
    "subDistrictNameTh": "แหลมโตนด",
    "subDistrictNameEn": "Laem Tanot",
    "postcode": "93110",
    "districtCode": "9305",
    "districtNameTh": "ควนขนุน",
    "districtNameEn": "Khuan Khanun",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930508",
    "subDistrictNameTh": "ปันแต",
    "subDistrictNameEn": "Pan Tae",
    "postcode": "93110",
    "districtCode": "9305",
    "districtNameTh": "ควนขนุน",
    "districtNameEn": "Khuan Khanun",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930509",
    "subDistrictNameTh": "โตนดด้วน",
    "subDistrictNameEn": "Tanot Duan",
    "postcode": "93110",
    "districtCode": "9305",
    "districtNameTh": "ควนขนุน",
    "districtNameEn": "Khuan Khanun",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930510",
    "subDistrictNameTh": "ดอนทราย",
    "subDistrictNameEn": "Don Sai",
    "postcode": "93110",
    "districtCode": "9305",
    "districtNameTh": "ควนขนุน",
    "districtNameEn": "Khuan Khanun",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930511",
    "subDistrictNameTh": "มะกอกเหนือ",
    "subDistrictNameEn": "Makok Nuea",
    "postcode": "93150",
    "districtCode": "9305",
    "districtNameTh": "ควนขนุน",
    "districtNameEn": "Khuan Khanun",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930512",
    "subDistrictNameTh": "พนางตุง",
    "subDistrictNameEn": "Phanang Tung",
    "postcode": "93150",
    "districtCode": "9305",
    "districtNameTh": "ควนขนุน",
    "districtNameEn": "Khuan Khanun",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930513",
    "subDistrictNameTh": "ชะมวง",
    "subDistrictNameEn": "Chamuang",
    "postcode": "93110",
    "districtCode": "9305",
    "districtNameTh": "ควนขนุน",
    "districtNameEn": "Khuan Khanun",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930516",
    "subDistrictNameTh": "แพรกหา",
    "subDistrictNameEn": "Phraek Ha",
    "postcode": "93110",
    "districtCode": "9305",
    "districtNameTh": "ควนขนุน",
    "districtNameEn": "Khuan Khanun",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930601",
    "subDistrictNameTh": "ปากพะยูน",
    "subDistrictNameEn": "Pak Phayun",
    "postcode": "93120",
    "districtCode": "9306",
    "districtNameTh": "ปากพะยูน",
    "districtNameEn": "Pak Phayun",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930602",
    "subDistrictNameTh": "ดอนประดู่",
    "subDistrictNameEn": "Don Pradu",
    "postcode": "93120",
    "districtCode": "9306",
    "districtNameTh": "ปากพะยูน",
    "districtNameEn": "Pak Phayun",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930603",
    "subDistrictNameTh": "เกาะนางคำ",
    "subDistrictNameEn": "Ko Nang Kham",
    "postcode": "93120",
    "districtCode": "9306",
    "districtNameTh": "ปากพะยูน",
    "districtNameEn": "Pak Phayun",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930604",
    "subDistrictNameTh": "เกาะหมาก",
    "subDistrictNameEn": "Ko Mak",
    "postcode": "93120",
    "districtCode": "9306",
    "districtNameTh": "ปากพะยูน",
    "districtNameEn": "Pak Phayun",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930605",
    "subDistrictNameTh": "ฝาละมี",
    "subDistrictNameEn": "Falami",
    "postcode": "93120",
    "districtCode": "9306",
    "districtNameTh": "ปากพะยูน",
    "districtNameEn": "Pak Phayun",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930606",
    "subDistrictNameTh": "หารเทา",
    "subDistrictNameEn": "Han Thao",
    "postcode": "93120",
    "districtCode": "9306",
    "districtNameTh": "ปากพะยูน",
    "districtNameEn": "Pak Phayun",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930607",
    "subDistrictNameTh": "ดอนทราย",
    "subDistrictNameEn": "Don Sai",
    "postcode": "93120",
    "districtCode": "9306",
    "districtNameTh": "ปากพะยูน",
    "districtNameEn": "Pak Phayun",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930701",
    "subDistrictNameTh": "เขาย่า",
    "subDistrictNameEn": "Khao Ya",
    "postcode": "93190",
    "districtCode": "9307",
    "districtNameTh": "ศรีบรรพต",
    "districtNameEn": "Si Banphot",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930702",
    "subDistrictNameTh": "เขาปู่",
    "subDistrictNameEn": "Khao Pu",
    "postcode": "93190",
    "districtCode": "9307",
    "districtNameTh": "ศรีบรรพต",
    "districtNameEn": "Si Banphot",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930703",
    "subDistrictNameTh": "ตะแพน",
    "subDistrictNameEn": "Taphaen",
    "postcode": "93190",
    "districtCode": "9307",
    "districtNameTh": "ศรีบรรพต",
    "districtNameEn": "Si Banphot",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930801",
    "subDistrictNameTh": "ป่าบอน",
    "subDistrictNameEn": "Pa Bon",
    "postcode": "93170",
    "districtCode": "9308",
    "districtNameTh": "ป่าบอน",
    "districtNameEn": "Pa Bon",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930802",
    "subDistrictNameTh": "โคกทราย",
    "subDistrictNameEn": "Khok Sai",
    "postcode": "93170",
    "districtCode": "9308",
    "districtNameTh": "ป่าบอน",
    "districtNameEn": "Pa Bon",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930803",
    "subDistrictNameTh": "หนองธง",
    "subDistrictNameEn": "Nong Thong",
    "postcode": "93170",
    "districtCode": "9308",
    "districtNameTh": "ป่าบอน",
    "districtNameEn": "Pa Bon",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930804",
    "subDistrictNameTh": "ทุ่งนารี",
    "subDistrictNameEn": "Thung Nari",
    "postcode": "93170",
    "districtCode": "9308",
    "districtNameTh": "ป่าบอน",
    "districtNameEn": "Pa Bon",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930806",
    "subDistrictNameTh": "วังใหม่",
    "subDistrictNameEn": "Wang Mai",
    "postcode": "93170",
    "districtCode": "9308",
    "districtNameTh": "ป่าบอน",
    "districtNameEn": "Pa Bon",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930901",
    "subDistrictNameTh": "ท่ามะเดื่อ",
    "subDistrictNameEn": "Tha Maduea",
    "postcode": "93140",
    "districtCode": "9309",
    "districtNameTh": "บางแก้ว",
    "districtNameEn": "Bang Kaeo",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930902",
    "subDistrictNameTh": "นาปะขอ",
    "subDistrictNameEn": "Na Pakho",
    "postcode": "93140",
    "districtCode": "9309",
    "districtNameTh": "บางแก้ว",
    "districtNameEn": "Bang Kaeo",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "930903",
    "subDistrictNameTh": "โคกสัก",
    "subDistrictNameEn": "Khok Sak",
    "postcode": "93140",
    "districtCode": "9309",
    "districtNameTh": "บางแก้ว",
    "districtNameEn": "Bang Kaeo",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "931001",
    "subDistrictNameTh": "ป่าพะยอม",
    "subDistrictNameEn": "Pa Phayom",
    "postcode": "93210",
    "districtCode": "9310",
    "districtNameTh": "ป่าพะยอม",
    "districtNameEn": "Pa Phayom",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "931002",
    "subDistrictNameTh": "ลานข่อย",
    "subDistrictNameEn": "Lan Khoi",
    "postcode": "93210",
    "districtCode": "9310",
    "districtNameTh": "ป่าพะยอม",
    "districtNameEn": "Pa Phayom",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "931003",
    "subDistrictNameTh": "เกาะเต่า",
    "subDistrictNameEn": "Ko Tao",
    "postcode": "93210",
    "districtCode": "9310",
    "districtNameTh": "ป่าพะยอม",
    "districtNameEn": "Pa Phayom",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "931004",
    "subDistrictNameTh": "บ้านพร้าว",
    "subDistrictNameEn": "Ban Phrao",
    "postcode": "93210",
    "districtCode": "9310",
    "districtNameTh": "ป่าพะยอม",
    "districtNameEn": "Pa Phayom",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "931101",
    "subDistrictNameTh": "ชุมพล",
    "subDistrictNameEn": "Chumpon",
    "postcode": "93000",
    "districtCode": "9311",
    "districtNameTh": "ศรีนครินทร์",
    "districtNameEn": "Srinagarindra",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "931102",
    "subDistrictNameTh": "บ้านนา",
    "subDistrictNameEn": "Ban Na",
    "postcode": "93000",
    "districtCode": "9311",
    "districtNameTh": "ศรีนครินทร์",
    "districtNameEn": "Srinagarindra",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "931103",
    "subDistrictNameTh": "อ่างทอง",
    "subDistrictNameEn": "Ang Thong",
    "postcode": "93000",
    "districtCode": "9311",
    "districtNameTh": "ศรีนครินทร์",
    "districtNameEn": "Srinagarindra",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "931104",
    "subDistrictNameTh": "ลำสินธุ์",
    "subDistrictNameEn": "Lam Sin",
    "postcode": "93000",
    "districtCode": "9311",
    "districtNameTh": "ศรีนครินทร์",
    "districtNameEn": "Srinagarindra",
    "provinceCode": "93",
    "provinceNameTh": "พัทลุง",
    "provinceNameEn": "Phatthalung"
},
{
    "subDistrictCode": "940101",
    "subDistrictNameTh": "สะบารัง",
    "subDistrictNameEn": "Sabarang",
    "postcode": "94000",
    "districtCode": "9401",
    "districtNameTh": "เมืองปัตตานี",
    "districtNameEn": "Mueang Pattani",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940102",
    "subDistrictNameTh": "อาเนาะรู",
    "subDistrictNameEn": "Anoru",
    "postcode": "94000",
    "districtCode": "9401",
    "districtNameTh": "เมืองปัตตานี",
    "districtNameEn": "Mueang Pattani",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940103",
    "subDistrictNameTh": "จะบังติกอ",
    "subDistrictNameEn": "Chabang Tiko",
    "postcode": "94000",
    "districtCode": "9401",
    "districtNameTh": "เมืองปัตตานี",
    "districtNameEn": "Mueang Pattani",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940104",
    "subDistrictNameTh": "บานา",
    "subDistrictNameEn": "Bana",
    "postcode": "94000",
    "districtCode": "9401",
    "districtNameTh": "เมืองปัตตานี",
    "districtNameEn": "Mueang Pattani",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940105",
    "subDistrictNameTh": "ตันหยงลุโละ",
    "subDistrictNameEn": "Tanyong Lulo",
    "postcode": "94000",
    "districtCode": "9401",
    "districtNameTh": "เมืองปัตตานี",
    "districtNameEn": "Mueang Pattani",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940106",
    "subDistrictNameTh": "คลองมานิง",
    "subDistrictNameEn": "Khlong Maning",
    "postcode": "94000",
    "districtCode": "9401",
    "districtNameTh": "เมืองปัตตานี",
    "districtNameEn": "Mueang Pattani",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940107",
    "subDistrictNameTh": "กะมิยอ",
    "subDistrictNameEn": "Kamiyo",
    "postcode": "94000",
    "districtCode": "9401",
    "districtNameTh": "เมืองปัตตานี",
    "districtNameEn": "Mueang Pattani",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940108",
    "subDistrictNameTh": "บาราโหม",
    "subDistrictNameEn": "Bara Hom",
    "postcode": "94000",
    "districtCode": "9401",
    "districtNameTh": "เมืองปัตตานี",
    "districtNameEn": "Mueang Pattani",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940109",
    "subDistrictNameTh": "ปะกาฮะรัง",
    "subDistrictNameEn": "Paka Harang",
    "postcode": "94000",
    "districtCode": "9401",
    "districtNameTh": "เมืองปัตตานี",
    "districtNameEn": "Mueang Pattani",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940110",
    "subDistrictNameTh": "รูสะมิแล",
    "subDistrictNameEn": "Ru Samilae",
    "postcode": "94000",
    "districtCode": "9401",
    "districtNameTh": "เมืองปัตตานี",
    "districtNameEn": "Mueang Pattani",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940111",
    "subDistrictNameTh": "ตะลุโบะ",
    "subDistrictNameEn": "Talubo",
    "postcode": "94000",
    "districtCode": "9401",
    "districtNameTh": "เมืองปัตตานี",
    "districtNameEn": "Mueang Pattani",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940112",
    "subDistrictNameTh": "บาราเฮาะ",
    "subDistrictNameEn": "Bara Ho",
    "postcode": "94000",
    "districtCode": "9401",
    "districtNameTh": "เมืองปัตตานี",
    "districtNameEn": "Mueang Pattani",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940113",
    "subDistrictNameTh": "ปุยุด",
    "subDistrictNameEn": "Puyut",
    "postcode": "94000",
    "districtCode": "9401",
    "districtNameTh": "เมืองปัตตานี",
    "districtNameEn": "Mueang Pattani",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940201",
    "subDistrictNameTh": "โคกโพธิ์",
    "subDistrictNameEn": "Khok Pho",
    "postcode": "94120",
    "districtCode": "9402",
    "districtNameTh": "โคกโพธิ์",
    "districtNameEn": "Khok Pho",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940202",
    "subDistrictNameTh": "มะกรูด",
    "subDistrictNameEn": "Makrut",
    "postcode": "94120",
    "districtCode": "9402",
    "districtNameTh": "โคกโพธิ์",
    "districtNameEn": "Khok Pho",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940203",
    "subDistrictNameTh": "บางโกระ",
    "subDistrictNameEn": "Bang Kro",
    "postcode": "94120",
    "districtCode": "9402",
    "districtNameTh": "โคกโพธิ์",
    "districtNameEn": "Khok Pho",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940204",
    "subDistrictNameTh": "ป่าบอน",
    "subDistrictNameEn": "Pa Bon",
    "postcode": "94120",
    "districtCode": "9402",
    "districtNameTh": "โคกโพธิ์",
    "districtNameEn": "Khok Pho",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940205",
    "subDistrictNameTh": "ทรายขาว",
    "subDistrictNameEn": "Sai Khao",
    "postcode": "94120",
    "districtCode": "9402",
    "districtNameTh": "โคกโพธิ์",
    "districtNameEn": "Khok Pho",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940206",
    "subDistrictNameTh": "นาประดู่",
    "subDistrictNameEn": "Na Pradu",
    "postcode": "94180",
    "districtCode": "9402",
    "districtNameTh": "โคกโพธิ์",
    "districtNameEn": "Khok Pho",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940207",
    "subDistrictNameTh": "ปากล่อ",
    "subDistrictNameEn": "Pak Lo",
    "postcode": "94180",
    "districtCode": "9402",
    "districtNameTh": "โคกโพธิ์",
    "districtNameEn": "Khok Pho",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940208",
    "subDistrictNameTh": "ทุ่งพลา",
    "subDistrictNameEn": "Thung Phala",
    "postcode": "94180",
    "districtCode": "9402",
    "districtNameTh": "โคกโพธิ์",
    "districtNameEn": "Khok Pho",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940211",
    "subDistrictNameTh": "ท่าเรือ",
    "subDistrictNameEn": "Tha Ruea",
    "postcode": "94120",
    "districtCode": "9402",
    "districtNameTh": "โคกโพธิ์",
    "districtNameEn": "Khok Pho",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940213",
    "subDistrictNameTh": "นาเกตุ",
    "subDistrictNameEn": "Na Ket",
    "postcode": "94120",
    "districtCode": "9402",
    "districtNameTh": "โคกโพธิ์",
    "districtNameEn": "Khok Pho",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940214",
    "subDistrictNameTh": "ควนโนรี",
    "subDistrictNameEn": "Khuan Nori",
    "postcode": "94180",
    "districtCode": "9402",
    "districtNameTh": "โคกโพธิ์",
    "districtNameEn": "Khok Pho",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940215",
    "subDistrictNameTh": "ช้างให้ตก",
    "subDistrictNameEn": "Chang Hai Tok",
    "postcode": "94120",
    "districtCode": "9402",
    "districtNameTh": "โคกโพธิ์",
    "districtNameEn": "Khok Pho",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940301",
    "subDistrictNameTh": "เกาะเปาะ",
    "subDistrictNameEn": "Ko Po",
    "postcode": "94170",
    "districtCode": "9403",
    "districtNameTh": "หนองจิก",
    "districtNameEn": "Nong Chik",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940302",
    "subDistrictNameTh": "คอลอตันหยง",
    "subDistrictNameEn": "Kholo Tanyong",
    "postcode": "94170",
    "districtCode": "9403",
    "districtNameTh": "หนองจิก",
    "districtNameEn": "Nong Chik",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940303",
    "subDistrictNameTh": "ดอนรัก",
    "subDistrictNameEn": "Don Rak",
    "postcode": "94170",
    "districtCode": "9403",
    "districtNameTh": "หนองจิก",
    "districtNameEn": "Nong Chik",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940304",
    "subDistrictNameTh": "ดาโต๊ะ",
    "subDistrictNameEn": "Dato",
    "postcode": "94170",
    "districtCode": "9403",
    "districtNameTh": "หนองจิก",
    "districtNameEn": "Nong Chik",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940305",
    "subDistrictNameTh": "ตุยง",
    "subDistrictNameEn": "Tuyong",
    "postcode": "94170",
    "districtCode": "9403",
    "districtNameTh": "หนองจิก",
    "districtNameEn": "Nong Chik",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940306",
    "subDistrictNameTh": "ท่ากำชำ",
    "subDistrictNameEn": "Tha Kamcham",
    "postcode": "94170",
    "districtCode": "9403",
    "districtNameTh": "หนองจิก",
    "districtNameEn": "Nong Chik",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940307",
    "subDistrictNameTh": "บ่อทอง",
    "subDistrictNameEn": "Bo Thong",
    "postcode": "94170",
    "districtCode": "9403",
    "districtNameTh": "หนองจิก",
    "districtNameEn": "Nong Chik",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940308",
    "subDistrictNameTh": "บางเขา",
    "subDistrictNameEn": "Bang Khao",
    "postcode": "94170",
    "districtCode": "9403",
    "districtNameTh": "หนองจิก",
    "districtNameEn": "Nong Chik",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940309",
    "subDistrictNameTh": "บางตาวา",
    "subDistrictNameEn": "Bang Tawa",
    "postcode": "94170",
    "districtCode": "9403",
    "districtNameTh": "หนองจิก",
    "districtNameEn": "Nong Chik",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940310",
    "subDistrictNameTh": "ปุโละปุโย",
    "subDistrictNameEn": "Pulo Puyo",
    "postcode": "94170",
    "districtCode": "9403",
    "districtNameTh": "หนองจิก",
    "districtNameEn": "Nong Chik",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940311",
    "subDistrictNameTh": "ยาบี",
    "subDistrictNameEn": "Yabi",
    "postcode": "94170",
    "districtCode": "9403",
    "districtNameTh": "หนองจิก",
    "districtNameEn": "Nong Chik",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940312",
    "subDistrictNameTh": "ลิปะสะโง",
    "subDistrictNameEn": "Lipa Sa-ngo",
    "postcode": "94170",
    "districtCode": "9403",
    "districtNameTh": "หนองจิก",
    "districtNameEn": "Nong Chik",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940401",
    "subDistrictNameTh": "ปะนาเระ",
    "subDistrictNameEn": "Panare",
    "postcode": "94130",
    "districtCode": "9404",
    "districtNameTh": "ปะนาเระ",
    "districtNameEn": "Panare",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940402",
    "subDistrictNameTh": "ท่าข้าม",
    "subDistrictNameEn": "Tha Kham",
    "postcode": "94130",
    "districtCode": "9404",
    "districtNameTh": "ปะนาเระ",
    "districtNameEn": "Panare",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940403",
    "subDistrictNameTh": "บ้านนอก",
    "subDistrictNameEn": "Ban Nok",
    "postcode": "94130",
    "districtCode": "9404",
    "districtNameTh": "ปะนาเระ",
    "districtNameEn": "Panare",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940404",
    "subDistrictNameTh": "ดอน",
    "subDistrictNameEn": "Don",
    "postcode": "94190",
    "districtCode": "9404",
    "districtNameTh": "ปะนาเระ",
    "districtNameEn": "Panare",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940405",
    "subDistrictNameTh": "ควน",
    "subDistrictNameEn": "Khuan",
    "postcode": "94190",
    "districtCode": "9404",
    "districtNameTh": "ปะนาเระ",
    "districtNameEn": "Panare",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940406",
    "subDistrictNameTh": "ท่าน้ำ",
    "subDistrictNameEn": "Tha Nam",
    "postcode": "94130",
    "districtCode": "9404",
    "districtNameTh": "ปะนาเระ",
    "districtNameEn": "Panare",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940407",
    "subDistrictNameTh": "คอกกระบือ",
    "subDistrictNameEn": "Khok Krabue",
    "postcode": "94130",
    "districtCode": "9404",
    "districtNameTh": "ปะนาเระ",
    "districtNameEn": "Panare",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940408",
    "subDistrictNameTh": "พ่อมิ่ง",
    "subDistrictNameEn": "Pho Ming",
    "postcode": "94130",
    "districtCode": "9404",
    "districtNameTh": "ปะนาเระ",
    "districtNameEn": "Panare",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940409",
    "subDistrictNameTh": "บ้านกลาง",
    "subDistrictNameEn": "Ban Klang",
    "postcode": "94130",
    "districtCode": "9404",
    "districtNameTh": "ปะนาเระ",
    "districtNameEn": "Panare",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940410",
    "subDistrictNameTh": "บ้านน้ำบ่อ",
    "subDistrictNameEn": "Ban Nam Bo",
    "postcode": "94130",
    "districtCode": "9404",
    "districtNameTh": "ปะนาเระ",
    "districtNameEn": "Panare",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940501",
    "subDistrictNameTh": "มายอ",
    "subDistrictNameEn": "Mayo",
    "postcode": "94140",
    "districtCode": "9405",
    "districtNameTh": "มายอ",
    "districtNameEn": "Mayo",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940502",
    "subDistrictNameTh": "ถนน",
    "subDistrictNameEn": "Thanon",
    "postcode": "94140",
    "districtCode": "9405",
    "districtNameTh": "มายอ",
    "districtNameEn": "Mayo",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940503",
    "subDistrictNameTh": "ตรัง",
    "subDistrictNameEn": "Trang",
    "postcode": "94140",
    "districtCode": "9405",
    "districtNameTh": "มายอ",
    "districtNameEn": "Mayo",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940504",
    "subDistrictNameTh": "กระหวะ",
    "subDistrictNameEn": "Krawa",
    "postcode": "94190",
    "districtCode": "9405",
    "districtNameTh": "มายอ",
    "districtNameEn": "Mayo",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940505",
    "subDistrictNameTh": "ลุโบะยิไร",
    "subDistrictNameEn": "Lubo yilai",
    "postcode": "94140",
    "districtCode": "9405",
    "districtNameTh": "มายอ",
    "districtNameEn": "Mayo",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940506",
    "subDistrictNameTh": "ลางา",
    "subDistrictNameEn": "La-nga",
    "postcode": "94190",
    "districtCode": "9405",
    "districtNameTh": "มายอ",
    "districtNameEn": "Mayo",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940507",
    "subDistrictNameTh": "กระเสาะ",
    "subDistrictNameEn": "Kraso",
    "postcode": "94140",
    "districtCode": "9405",
    "districtNameTh": "มายอ",
    "districtNameEn": "Mayo",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940508",
    "subDistrictNameTh": "เกาะจัน",
    "subDistrictNameEn": "Ko Chan",
    "postcode": "94140",
    "districtCode": "9405",
    "districtNameTh": "มายอ",
    "districtNameEn": "Mayo",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940509",
    "subDistrictNameTh": "ปะโด",
    "subDistrictNameEn": "Pado",
    "postcode": "94140",
    "districtCode": "9405",
    "districtNameTh": "มายอ",
    "districtNameEn": "Mayo",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940510",
    "subDistrictNameTh": "สาคอบน",
    "subDistrictNameEn": "Sakho Bon",
    "postcode": "94140",
    "districtCode": "9405",
    "districtNameTh": "มายอ",
    "districtNameEn": "Mayo",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940511",
    "subDistrictNameTh": "สาคอใต้",
    "subDistrictNameEn": "Sakho Tai",
    "postcode": "94140",
    "districtCode": "9405",
    "districtNameTh": "มายอ",
    "districtNameEn": "Mayo",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940512",
    "subDistrictNameTh": "สะกำ",
    "subDistrictNameEn": "Sakam",
    "postcode": "94140",
    "districtCode": "9405",
    "districtNameTh": "มายอ",
    "districtNameEn": "Mayo",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940513",
    "subDistrictNameTh": "ปานัน",
    "subDistrictNameEn": "Panan",
    "postcode": "94140",
    "districtCode": "9405",
    "districtNameTh": "มายอ",
    "districtNameEn": "Mayo",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940601",
    "subDistrictNameTh": "ตะโละแมะนา",
    "subDistrictNameEn": "Talo Maena",
    "postcode": "94140",
    "districtCode": "9406",
    "districtNameTh": "ทุ่งยางแดง",
    "districtNameEn": "Thung Yang Daeng",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940602",
    "subDistrictNameTh": "พิเทน",
    "subDistrictNameEn": "Phithen",
    "postcode": "94140",
    "districtCode": "9406",
    "districtNameTh": "ทุ่งยางแดง",
    "districtNameEn": "Thung Yang Daeng",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940603",
    "subDistrictNameTh": "น้ำดำ",
    "subDistrictNameEn": "Nam Dam",
    "postcode": "94140",
    "districtCode": "9406",
    "districtNameTh": "ทุ่งยางแดง",
    "districtNameEn": "Thung Yang Daeng",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940604",
    "subDistrictNameTh": "ปากู",
    "subDistrictNameEn": "Paku",
    "postcode": "94140",
    "districtCode": "9406",
    "districtNameTh": "ทุ่งยางแดง",
    "districtNameEn": "Thung Yang Daeng",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940701",
    "subDistrictNameTh": "ตะลุบัน",
    "subDistrictNameEn": "Taluban",
    "postcode": "94110",
    "districtCode": "9407",
    "districtNameTh": "สายบุรี",
    "districtNameEn": "Sai Buri",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940702",
    "subDistrictNameTh": "ตะบิ้ง",
    "subDistrictNameEn": "Tabing",
    "postcode": "94110",
    "districtCode": "9407",
    "districtNameTh": "สายบุรี",
    "districtNameEn": "Sai Buri",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940703",
    "subDistrictNameTh": "ปะเสยะวอ",
    "subDistrictNameEn": "Pase Yawo",
    "postcode": "94110",
    "districtCode": "9407",
    "districtNameTh": "สายบุรี",
    "districtNameEn": "Sai Buri",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940704",
    "subDistrictNameTh": "บางเก่า",
    "subDistrictNameEn": "Bang Kao",
    "postcode": "94110",
    "districtCode": "9407",
    "districtNameTh": "สายบุรี",
    "districtNameEn": "Sai Buri",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940705",
    "subDistrictNameTh": "บือเระ",
    "subDistrictNameEn": "Bue Re",
    "postcode": "94110",
    "districtCode": "9407",
    "districtNameTh": "สายบุรี",
    "districtNameEn": "Sai Buri",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940706",
    "subDistrictNameTh": "เตราะบอน",
    "subDistrictNameEn": "Tro Bon",
    "postcode": "94110",
    "districtCode": "9407",
    "districtNameTh": "สายบุรี",
    "districtNameEn": "Sai Buri",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940707",
    "subDistrictNameTh": "กะดุนง",
    "subDistrictNameEn": "Kadunong",
    "postcode": "94110",
    "districtCode": "9407",
    "districtNameTh": "สายบุรี",
    "districtNameEn": "Sai Buri",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940708",
    "subDistrictNameTh": "ละหาร",
    "subDistrictNameEn": "Lahan",
    "postcode": "94110",
    "districtCode": "9407",
    "districtNameTh": "สายบุรี",
    "districtNameEn": "Sai Buri",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940709",
    "subDistrictNameTh": "มะนังดาลำ",
    "subDistrictNameEn": "Manang Dalam",
    "postcode": "94110",
    "districtCode": "9407",
    "districtNameTh": "สายบุรี",
    "districtNameEn": "Sai Buri",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940710",
    "subDistrictNameTh": "แป้น",
    "subDistrictNameEn": "Paen",
    "postcode": "94110",
    "districtCode": "9407",
    "districtNameTh": "สายบุรี",
    "districtNameEn": "Sai Buri",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940711",
    "subDistrictNameTh": "ทุ่งคล้า",
    "subDistrictNameEn": "Thung Khla",
    "postcode": "94190",
    "districtCode": "9407",
    "districtNameTh": "สายบุรี",
    "districtNameEn": "Sai Buri",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940801",
    "subDistrictNameTh": "ไทรทอง",
    "subDistrictNameEn": "Sai Thong",
    "postcode": "94220",
    "districtCode": "9408",
    "districtNameTh": "ไม้แก่น",
    "districtNameEn": "Mai Kaen",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940802",
    "subDistrictNameTh": "ไม้แก่น",
    "subDistrictNameEn": "Mai Kaen",
    "postcode": "94220",
    "districtCode": "9408",
    "districtNameTh": "ไม้แก่น",
    "districtNameEn": "Mai Kaen",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940803",
    "subDistrictNameTh": "ตะโละไกรทอง",
    "subDistrictNameEn": "Talo Kraithong",
    "postcode": "94220",
    "districtCode": "9408",
    "districtNameTh": "ไม้แก่น",
    "districtNameEn": "Mai Kaen",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940804",
    "subDistrictNameTh": "ดอนทราย",
    "subDistrictNameEn": "Don Sai",
    "postcode": "94220",
    "districtCode": "9408",
    "districtNameTh": "ไม้แก่น",
    "districtNameEn": "Mai Kaen",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940901",
    "subDistrictNameTh": "ตะโละ",
    "subDistrictNameEn": "Talo",
    "postcode": "94150",
    "districtCode": "9409",
    "districtNameTh": "ยะหริ่ง",
    "districtNameEn": "Yaring",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940902",
    "subDistrictNameTh": "ตะโละกาโปร์",
    "subDistrictNameEn": "Talo Kapo",
    "postcode": "94150",
    "districtCode": "9409",
    "districtNameTh": "ยะหริ่ง",
    "districtNameEn": "Yaring",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940903",
    "subDistrictNameTh": "ตันหยงดาลอ",
    "subDistrictNameEn": "Tanyong Dalo",
    "postcode": "94150",
    "districtCode": "9409",
    "districtNameTh": "ยะหริ่ง",
    "districtNameEn": "Yaring",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940904",
    "subDistrictNameTh": "ตันหยงจึงงา",
    "subDistrictNameEn": "Tanyong Chueng-nga",
    "postcode": "94190",
    "districtCode": "9409",
    "districtNameTh": "ยะหริ่ง",
    "districtNameEn": "Yaring",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940905",
    "subDistrictNameTh": "ตอหลัง",
    "subDistrictNameEn": "Tolang",
    "postcode": "94150",
    "districtCode": "9409",
    "districtNameTh": "ยะหริ่ง",
    "districtNameEn": "Yaring",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940906",
    "subDistrictNameTh": "ตาแกะ",
    "subDistrictNameEn": "Takae",
    "postcode": "94150",
    "districtCode": "9409",
    "districtNameTh": "ยะหริ่ง",
    "districtNameEn": "Yaring",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940907",
    "subDistrictNameTh": "ตาลีอายร์",
    "subDistrictNameEn": "Tali-ai",
    "postcode": "94150",
    "districtCode": "9409",
    "districtNameTh": "ยะหริ่ง",
    "districtNameEn": "Yaring",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940908",
    "subDistrictNameTh": "ยามู",
    "subDistrictNameEn": "Yamu",
    "postcode": "94150",
    "districtCode": "9409",
    "districtNameTh": "ยะหริ่ง",
    "districtNameEn": "Yaring",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940909",
    "subDistrictNameTh": "บางปู",
    "subDistrictNameEn": "Bang Pu",
    "postcode": "94150",
    "districtCode": "9409",
    "districtNameTh": "ยะหริ่ง",
    "districtNameEn": "Yaring",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940910",
    "subDistrictNameTh": "หนองแรต",
    "subDistrictNameEn": "Nong Raet",
    "postcode": "94150",
    "districtCode": "9409",
    "districtNameTh": "ยะหริ่ง",
    "districtNameEn": "Yaring",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940911",
    "subDistrictNameTh": "ปิยามุมัง",
    "subDistrictNameEn": "Piya Mumang",
    "postcode": "94150",
    "districtCode": "9409",
    "districtNameTh": "ยะหริ่ง",
    "districtNameEn": "Yaring",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940912",
    "subDistrictNameTh": "ปุลากง",
    "subDistrictNameEn": "Pulakong",
    "postcode": "94150",
    "districtCode": "9409",
    "districtNameTh": "ยะหริ่ง",
    "districtNameEn": "Yaring",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940913",
    "subDistrictNameTh": "บาโลย",
    "subDistrictNameEn": "Baloi",
    "postcode": "94190",
    "districtCode": "9409",
    "districtNameTh": "ยะหริ่ง",
    "districtNameEn": "Yaring",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940914",
    "subDistrictNameTh": "สาบัน",
    "subDistrictNameEn": "Saban",
    "postcode": "94150",
    "districtCode": "9409",
    "districtNameTh": "ยะหริ่ง",
    "districtNameEn": "Yaring",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940915",
    "subDistrictNameTh": "มะนังยง",
    "subDistrictNameEn": "Manang Yong",
    "postcode": "94150",
    "districtCode": "9409",
    "districtNameTh": "ยะหริ่ง",
    "districtNameEn": "Yaring",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940916",
    "subDistrictNameTh": "ราตาปันยัง",
    "subDistrictNameEn": "Rata Panyang",
    "postcode": "94150",
    "districtCode": "9409",
    "districtNameTh": "ยะหริ่ง",
    "districtNameEn": "Yaring",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940917",
    "subDistrictNameTh": "จะรัง",
    "subDistrictNameEn": "Charang",
    "postcode": "94150",
    "districtCode": "9409",
    "districtNameTh": "ยะหริ่ง",
    "districtNameEn": "Yaring",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "940918",
    "subDistrictNameTh": "แหลมโพธิ์",
    "subDistrictNameEn": "Laem Pho",
    "postcode": "94150",
    "districtCode": "9409",
    "districtNameTh": "ยะหริ่ง",
    "districtNameEn": "Yaring",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "941001",
    "subDistrictNameTh": "ยะรัง",
    "subDistrictNameEn": "Yarang",
    "postcode": "94160",
    "districtCode": "9410",
    "districtNameTh": "ยะรัง",
    "districtNameEn": "Yarang",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "941002",
    "subDistrictNameTh": "สะดาวา",
    "subDistrictNameEn": "Sadawa",
    "postcode": "94160",
    "districtCode": "9410",
    "districtNameTh": "ยะรัง",
    "districtNameEn": "Yarang",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "941003",
    "subDistrictNameTh": "ประจัน",
    "subDistrictNameEn": "Prachan",
    "postcode": "94160",
    "districtCode": "9410",
    "districtNameTh": "ยะรัง",
    "districtNameEn": "Yarang",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "941004",
    "subDistrictNameTh": "สะนอ",
    "subDistrictNameEn": "Sano",
    "postcode": "94160",
    "districtCode": "9410",
    "districtNameTh": "ยะรัง",
    "districtNameEn": "Yarang",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "941005",
    "subDistrictNameTh": "ระแว้ง",
    "subDistrictNameEn": "Rawaeng",
    "postcode": "94160",
    "districtCode": "9410",
    "districtNameTh": "ยะรัง",
    "districtNameEn": "Yarang",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "941006",
    "subDistrictNameTh": "ปิตูมุดี",
    "subDistrictNameEn": "Pitu Mudi",
    "postcode": "94160",
    "districtCode": "9410",
    "districtNameTh": "ยะรัง",
    "districtNameEn": "Yarang",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "941007",
    "subDistrictNameTh": "วัด",
    "subDistrictNameEn": "Wat",
    "postcode": "94160",
    "districtCode": "9410",
    "districtNameTh": "ยะรัง",
    "districtNameEn": "Yarang",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "941008",
    "subDistrictNameTh": "กระโด",
    "subDistrictNameEn": "Krado",
    "postcode": "94160",
    "districtCode": "9410",
    "districtNameTh": "ยะรัง",
    "districtNameEn": "Yarang",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "941009",
    "subDistrictNameTh": "คลองใหม่",
    "subDistrictNameEn": "Khlong Mai",
    "postcode": "94160",
    "districtCode": "9410",
    "districtNameTh": "ยะรัง",
    "districtNameEn": "Yarang",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "941010",
    "subDistrictNameTh": "เมาะมาวี",
    "subDistrictNameEn": "Mo Mawi",
    "postcode": "94160",
    "districtCode": "9410",
    "districtNameTh": "ยะรัง",
    "districtNameEn": "Yarang",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "941011",
    "subDistrictNameTh": "กอลำ",
    "subDistrictNameEn": "Kolam",
    "postcode": "94160",
    "districtCode": "9410",
    "districtNameTh": "ยะรัง",
    "districtNameEn": "Yarang",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "941012",
    "subDistrictNameTh": "เขาตูม",
    "subDistrictNameEn": "Khao Tum",
    "postcode": "94160",
    "districtCode": "9410",
    "districtNameTh": "ยะรัง",
    "districtNameEn": "Yarang",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "941101",
    "subDistrictNameTh": "กะรุบี",
    "subDistrictNameEn": "Karubi",
    "postcode": "94230",
    "districtCode": "9411",
    "districtNameTh": "กะพ้อ",
    "districtNameEn": "Kapho",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "941102",
    "subDistrictNameTh": "ตะโละดือรามัน",
    "subDistrictNameEn": "Talo Due Raman",
    "postcode": "94230",
    "districtCode": "9411",
    "districtNameTh": "กะพ้อ",
    "districtNameEn": "Kapho",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "941103",
    "subDistrictNameTh": "ปล่องหอย",
    "subDistrictNameEn": "Plong Hoi",
    "postcode": "94230",
    "districtCode": "9411",
    "districtNameTh": "กะพ้อ",
    "districtNameEn": "Kapho",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "941201",
    "subDistrictNameTh": "แม่ลาน",
    "subDistrictNameEn": "Mae Lan",
    "postcode": "94180",
    "districtCode": "9412",
    "districtNameTh": "แม่ลาน",
    "districtNameEn": "Mae Lan",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "941202",
    "subDistrictNameTh": "ม่วงเตี้ย",
    "subDistrictNameEn": "Muang Tia",
    "postcode": "94180",
    "districtCode": "9412",
    "districtNameTh": "แม่ลาน",
    "districtNameEn": "Mae Lan",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "941203",
    "subDistrictNameTh": "ป่าไร่",
    "subDistrictNameEn": "Pa Rai",
    "postcode": "94180",
    "districtCode": "9412",
    "districtNameTh": "แม่ลาน",
    "districtNameEn": "Mae Lan",
    "provinceCode": "94",
    "provinceNameTh": "ปัตตานี",
    "provinceNameEn": "Pattani"
},
{
    "subDistrictCode": "950101",
    "subDistrictNameTh": "สะเตง",
    "subDistrictNameEn": "Sateng",
    "postcode": "95000",
    "districtCode": "9501",
    "districtNameTh": "เมืองยะลา",
    "districtNameEn": "Mueang Yala",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950102",
    "subDistrictNameTh": "บุดี",
    "subDistrictNameEn": "Budi",
    "postcode": "95000",
    "districtCode": "9501",
    "districtNameTh": "เมืองยะลา",
    "districtNameEn": "Mueang Yala",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950103",
    "subDistrictNameTh": "ยุโป",
    "subDistrictNameEn": "Yupo",
    "postcode": "95000",
    "districtCode": "9501",
    "districtNameTh": "เมืองยะลา",
    "districtNameEn": "Mueang Yala",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950104",
    "subDistrictNameTh": "ลิดล",
    "subDistrictNameEn": "Lidon",
    "postcode": "95160",
    "districtCode": "9501",
    "districtNameTh": "เมืองยะลา",
    "districtNameEn": "Mueang Yala",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950106",
    "subDistrictNameTh": "ยะลา",
    "subDistrictNameEn": "Yala",
    "postcode": "95000",
    "districtCode": "9501",
    "districtNameTh": "เมืองยะลา",
    "districtNameEn": "Mueang Yala",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950108",
    "subDistrictNameTh": "ท่าสาป",
    "subDistrictNameEn": "Tha Sap",
    "postcode": "95000",
    "districtCode": "9501",
    "districtNameTh": "เมืองยะลา",
    "districtNameEn": "Mueang Yala",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950109",
    "subDistrictNameTh": "ลำใหม่",
    "subDistrictNameEn": "Lam Mai",
    "postcode": "95160",
    "districtCode": "9501",
    "districtNameTh": "เมืองยะลา",
    "districtNameEn": "Mueang Yala",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950110",
    "subDistrictNameTh": "หน้าถ้ำ",
    "subDistrictNameEn": "Na Tham",
    "postcode": "95000",
    "districtCode": "9501",
    "districtNameTh": "เมืองยะลา",
    "districtNameEn": "Mueang Yala",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950111",
    "subDistrictNameTh": "ลำพะยา",
    "subDistrictNameEn": "Lam Phaya",
    "postcode": "95160",
    "districtCode": "9501",
    "districtNameTh": "เมืองยะลา",
    "districtNameEn": "Mueang Yala",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950112",
    "subDistrictNameTh": "เปาะเส้ง",
    "subDistrictNameEn": "Po Seng",
    "postcode": "95000",
    "districtCode": "9501",
    "districtNameTh": "เมืองยะลา",
    "districtNameEn": "Mueang Yala",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950114",
    "subDistrictNameTh": "พร่อน",
    "subDistrictNameEn": "Phron",
    "postcode": "95160",
    "districtCode": "9501",
    "districtNameTh": "เมืองยะลา",
    "districtNameEn": "Mueang Yala",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950115",
    "subDistrictNameTh": "บันนังสาเรง",
    "subDistrictNameEn": "Bannang Sareng",
    "postcode": "95000",
    "districtCode": "9501",
    "districtNameTh": "เมืองยะลา",
    "districtNameEn": "Mueang Yala",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950116",
    "subDistrictNameTh": "สะเตงนอก",
    "subDistrictNameEn": "Sateng Nok",
    "postcode": "95000",
    "districtCode": "9501",
    "districtNameTh": "เมืองยะลา",
    "districtNameEn": "Mueang Yala",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950118",
    "subDistrictNameTh": "ตาเซะ",
    "subDistrictNameEn": "Tase",
    "postcode": "95000",
    "districtCode": "9501",
    "districtNameTh": "เมืองยะลา",
    "districtNameEn": "Mueang Yala",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950201",
    "subDistrictNameTh": "เบตง",
    "subDistrictNameEn": "Betong",
    "postcode": "95110",
    "districtCode": "9502",
    "districtNameTh": "เบตง",
    "districtNameEn": "Betong",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950202",
    "subDistrictNameTh": "ยะรม",
    "subDistrictNameEn": "Yarom",
    "postcode": "95110",
    "districtCode": "9502",
    "districtNameTh": "เบตง",
    "districtNameEn": "Betong",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950203",
    "subDistrictNameTh": "ตาเนาะแมเราะ",
    "subDistrictNameEn": "Tano Maero",
    "postcode": "95110",
    "districtCode": "9502",
    "districtNameTh": "เบตง",
    "districtNameEn": "Betong",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950204",
    "subDistrictNameTh": "อัยเยอร์เวง",
    "subDistrictNameEn": "Aiyoeweng",
    "postcode": "95110",
    "districtCode": "9502",
    "districtNameTh": "เบตง",
    "districtNameEn": "Betong",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950205",
    "subDistrictNameTh": "ธารน้ำทิพย์",
    "subDistrictNameEn": "Than Nam Thip",
    "postcode": "95110",
    "districtCode": "9502",
    "districtNameTh": "เบตง",
    "districtNameEn": "Betong",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950301",
    "subDistrictNameTh": "บันนังสตา",
    "subDistrictNameEn": "Bannang Sata",
    "postcode": "95130",
    "districtCode": "9503",
    "districtNameTh": "บันนังสตา",
    "districtNameEn": "Bannang Sata",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950302",
    "subDistrictNameTh": "บาเจาะ",
    "subDistrictNameEn": "Bacho",
    "postcode": "95130",
    "districtCode": "9503",
    "districtNameTh": "บันนังสตา",
    "districtNameEn": "Bannang Sata",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950303",
    "subDistrictNameTh": "ตาเนาะปูเต๊ะ",
    "subDistrictNameEn": "Tanao Pute",
    "postcode": "95130",
    "districtCode": "9503",
    "districtNameTh": "บันนังสตา",
    "districtNameEn": "Bannang Sata",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950304",
    "subDistrictNameTh": "ถ้ำทะลุ",
    "subDistrictNameEn": "Tham Thalu",
    "postcode": "95130",
    "districtCode": "9503",
    "districtNameTh": "บันนังสตา",
    "districtNameEn": "Bannang Sata",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950305",
    "subDistrictNameTh": "ตลิ่งชัน",
    "subDistrictNameEn": "Taling Chan",
    "postcode": "95130",
    "districtCode": "9503",
    "districtNameTh": "บันนังสตา",
    "districtNameEn": "Bannang Sata",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950306",
    "subDistrictNameTh": "เขื่อนบางลาง",
    "subDistrictNameEn": "Khuean Bang Lang",
    "postcode": "95130",
    "districtCode": "9503",
    "districtNameTh": "บันนังสตา",
    "districtNameEn": "Bannang Sata",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950401",
    "subDistrictNameTh": "ธารโต",
    "subDistrictNameEn": "Than To",
    "postcode": "95150",
    "districtCode": "9504",
    "districtNameTh": "ธารโต",
    "districtNameEn": "Than To",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950402",
    "subDistrictNameTh": "บ้านแหร",
    "subDistrictNameEn": "Ban Rae",
    "postcode": "95150",
    "districtCode": "9504",
    "districtNameTh": "ธารโต",
    "districtNameEn": "Than To",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950403",
    "subDistrictNameTh": "แม่หวาด",
    "subDistrictNameEn": "Mae Wat",
    "postcode": "95170",
    "districtCode": "9504",
    "districtNameTh": "ธารโต",
    "districtNameEn": "Than To",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950404",
    "subDistrictNameTh": "คีรีเขต",
    "subDistrictNameEn": "Khiri Khet",
    "postcode": "95150",
    "districtCode": "9504",
    "districtNameTh": "ธารโต",
    "districtNameEn": "Than To",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950501",
    "subDistrictNameTh": "ยะหา",
    "subDistrictNameEn": "Yaha",
    "postcode": "95120",
    "districtCode": "9505",
    "districtNameTh": "ยะหา",
    "districtNameEn": "Yaha",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950502",
    "subDistrictNameTh": "ละแอ",
    "subDistrictNameEn": "La-ae",
    "postcode": "95120",
    "districtCode": "9505",
    "districtNameTh": "ยะหา",
    "districtNameEn": "Yaha",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950503",
    "subDistrictNameTh": "ปะแต",
    "subDistrictNameEn": "Patae",
    "postcode": "95120",
    "districtCode": "9505",
    "districtNameTh": "ยะหา",
    "districtNameEn": "Yaha",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950504",
    "subDistrictNameTh": "บาโร๊ะ",
    "subDistrictNameEn": "Baro",
    "postcode": "95120",
    "districtCode": "9505",
    "districtNameTh": "ยะหา",
    "districtNameEn": "Yaha",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950506",
    "subDistrictNameTh": "ตาชี",
    "subDistrictNameEn": "Tashi",
    "postcode": "95120",
    "districtCode": "9505",
    "districtNameTh": "ยะหา",
    "districtNameEn": "Yaha",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950507",
    "subDistrictNameTh": "บาโงยซิแน",
    "subDistrictNameEn": "Ba-ngoi Sinae",
    "postcode": "95120",
    "districtCode": "9505",
    "districtNameTh": "ยะหา",
    "districtNameEn": "Yaha",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950508",
    "subDistrictNameTh": "กาตอง",
    "subDistrictNameEn": "Ka Tong",
    "postcode": "95120",
    "districtCode": "9505",
    "districtNameTh": "ยะหา",
    "districtNameEn": "Yaha",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950601",
    "subDistrictNameTh": "กายูบอเกาะ",
    "subDistrictNameEn": "Kayu Boko",
    "postcode": "95140",
    "districtCode": "9506",
    "districtNameTh": "รามัน",
    "districtNameEn": "Raman",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950602",
    "subDistrictNameTh": "กาลูปัง",
    "subDistrictNameEn": "Kalupang",
    "postcode": "95140",
    "districtCode": "9506",
    "districtNameTh": "รามัน",
    "districtNameEn": "Raman",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950603",
    "subDistrictNameTh": "กาลอ",
    "subDistrictNameEn": "Kalo",
    "postcode": "95140",
    "districtCode": "9506",
    "districtNameTh": "รามัน",
    "districtNameEn": "Raman",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950604",
    "subDistrictNameTh": "กอตอตือร๊ะ",
    "subDistrictNameEn": "Koto Tuera",
    "postcode": "95140",
    "districtCode": "9506",
    "districtNameTh": "รามัน",
    "districtNameEn": "Raman",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950605",
    "subDistrictNameTh": "โกตาบารู",
    "subDistrictNameEn": "Kota Baru",
    "postcode": "95140",
    "districtCode": "9506",
    "districtNameTh": "รามัน",
    "districtNameEn": "Raman",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950606",
    "subDistrictNameTh": "เกะรอ",
    "subDistrictNameEn": "Kero",
    "postcode": "95140",
    "districtCode": "9506",
    "districtNameTh": "รามัน",
    "districtNameEn": "Raman",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950607",
    "subDistrictNameTh": "จะกว๊ะ",
    "subDistrictNameEn": "Cha-kwa",
    "postcode": "95140",
    "districtCode": "9506",
    "districtNameTh": "รามัน",
    "districtNameEn": "Raman",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950608",
    "subDistrictNameTh": "ท่าธง",
    "subDistrictNameEn": "Tha Thong",
    "postcode": "95140",
    "districtCode": "9506",
    "districtNameTh": "รามัน",
    "districtNameEn": "Raman",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950609",
    "subDistrictNameTh": "เนินงาม",
    "subDistrictNameEn": "Noen Ngam",
    "postcode": "95140",
    "districtCode": "9506",
    "districtNameTh": "รามัน",
    "districtNameEn": "Raman",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950610",
    "subDistrictNameTh": "บาลอ",
    "subDistrictNameEn": "Balo",
    "postcode": "95140",
    "districtCode": "9506",
    "districtNameTh": "รามัน",
    "districtNameEn": "Raman",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950611",
    "subDistrictNameTh": "บาโงย",
    "subDistrictNameEn": "Ba-ngoi",
    "postcode": "95140",
    "districtCode": "9506",
    "districtNameTh": "รามัน",
    "districtNameEn": "Raman",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950612",
    "subDistrictNameTh": "บือมัง",
    "subDistrictNameEn": "Buemang",
    "postcode": "95140",
    "districtCode": "9506",
    "districtNameTh": "รามัน",
    "districtNameEn": "Raman",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950613",
    "subDistrictNameTh": "ยะต๊ะ",
    "subDistrictNameEn": "Yata",
    "postcode": "95140",
    "districtCode": "9506",
    "districtNameTh": "รามัน",
    "districtNameEn": "Raman",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950614",
    "subDistrictNameTh": "วังพญา",
    "subDistrictNameEn": "Wang Phaya",
    "postcode": "95140",
    "districtCode": "9506",
    "districtNameTh": "รามัน",
    "districtNameEn": "Raman",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950615",
    "subDistrictNameTh": "อาซ่อง",
    "subDistrictNameEn": "A-song",
    "postcode": "95140",
    "districtCode": "9506",
    "districtNameTh": "รามัน",
    "districtNameEn": "Raman",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950616",
    "subDistrictNameTh": "ตะโล๊ะหะลอ",
    "subDistrictNameEn": "Talo Halo",
    "postcode": "95140",
    "districtCode": "9506",
    "districtNameTh": "รามัน",
    "districtNameEn": "Raman",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950701",
    "subDistrictNameTh": "กาบัง",
    "subDistrictNameEn": "Kabang",
    "postcode": "95120",
    "districtCode": "9507",
    "districtNameTh": "กาบัง",
    "districtNameEn": "Kabang",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950702",
    "subDistrictNameTh": "บาละ",
    "subDistrictNameEn": "Bala",
    "postcode": "95120",
    "districtCode": "9507",
    "districtNameTh": "กาบัง",
    "districtNameEn": "Kabang",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950801",
    "subDistrictNameTh": "กรงปินัง",
    "subDistrictNameEn": "Krong Pinang",
    "postcode": "95000",
    "districtCode": "9508",
    "districtNameTh": "กรงปินัง",
    "districtNameEn": "Krong Pinang",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950802",
    "subDistrictNameTh": "สะเอะ",
    "subDistrictNameEn": "Sa-e",
    "postcode": "95000",
    "districtCode": "9508",
    "districtNameTh": "กรงปินัง",
    "districtNameEn": "Krong Pinang",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950803",
    "subDistrictNameTh": "ห้วยกระทิง",
    "subDistrictNameEn": "Huai Krathing",
    "postcode": "95000",
    "districtCode": "9508",
    "districtNameTh": "กรงปินัง",
    "districtNameEn": "Krong Pinang",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "950804",
    "subDistrictNameTh": "ปุโรง",
    "subDistrictNameEn": "Purong",
    "postcode": "95000",
    "districtCode": "9508",
    "districtNameTh": "กรงปินัง",
    "districtNameEn": "Krong Pinang",
    "provinceCode": "95",
    "provinceNameTh": "ยะลา",
    "provinceNameEn": "Yala"
},
{
    "subDistrictCode": "960101",
    "subDistrictNameTh": "บางนาค",
    "subDistrictNameEn": "Bang Nak",
    "postcode": "96000",
    "districtCode": "9601",
    "districtNameTh": "เมืองนราธิวาส",
    "districtNameEn": "Mueang Narathiwat",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960102",
    "subDistrictNameTh": "ลำภู",
    "subDistrictNameEn": "Lamphu",
    "postcode": "96000",
    "districtCode": "9601",
    "districtNameTh": "เมืองนราธิวาส",
    "districtNameEn": "Mueang Narathiwat",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960103",
    "subDistrictNameTh": "มะนังตายอ",
    "subDistrictNameEn": "Manang Tayo",
    "postcode": "96000",
    "districtCode": "9601",
    "districtNameTh": "เมืองนราธิวาส",
    "districtNameEn": "Mueang Narathiwat",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960104",
    "subDistrictNameTh": "บางปอ",
    "subDistrictNameEn": "Bang Po",
    "postcode": "96000",
    "districtCode": "9601",
    "districtNameTh": "เมืองนราธิวาส",
    "districtNameEn": "Mueang Narathiwat",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960105",
    "subDistrictNameTh": "กะลุวอ",
    "subDistrictNameEn": "Kaluwo",
    "postcode": "96000",
    "districtCode": "9601",
    "districtNameTh": "เมืองนราธิวาส",
    "districtNameEn": "Mueang Narathiwat",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960106",
    "subDistrictNameTh": "กะลุวอเหนือ",
    "subDistrictNameEn": "Kaluwo Nuea",
    "postcode": "96000",
    "districtCode": "9601",
    "districtNameTh": "เมืองนราธิวาส",
    "districtNameEn": "Mueang Narathiwat",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960107",
    "subDistrictNameTh": "โคกเคียน",
    "subDistrictNameEn": "Khok Khian",
    "postcode": "96000",
    "districtCode": "9601",
    "districtNameTh": "เมืองนราธิวาส",
    "districtNameEn": "Mueang Narathiwat",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960201",
    "subDistrictNameTh": "เจ๊ะเห",
    "subDistrictNameEn": "Chehe",
    "postcode": "96110",
    "districtCode": "9602",
    "districtNameTh": "ตากใบ",
    "districtNameEn": "Tak Bai",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960202",
    "subDistrictNameTh": "ไพรวัน",
    "subDistrictNameEn": "Phraiwan",
    "postcode": "96110",
    "districtCode": "9602",
    "districtNameTh": "ตากใบ",
    "districtNameEn": "Tak Bai",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960203",
    "subDistrictNameTh": "พร่อน",
    "subDistrictNameEn": "Phron",
    "postcode": "96110",
    "districtCode": "9602",
    "districtNameTh": "ตากใบ",
    "districtNameEn": "Tak Bai",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960204",
    "subDistrictNameTh": "ศาลาใหม่",
    "subDistrictNameEn": "Sala Mai",
    "postcode": "96110",
    "districtCode": "9602",
    "districtNameTh": "ตากใบ",
    "districtNameEn": "Tak Bai",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960205",
    "subDistrictNameTh": "บางขุนทอง",
    "subDistrictNameEn": "Bang Khun Thong",
    "postcode": "96110",
    "districtCode": "9602",
    "districtNameTh": "ตากใบ",
    "districtNameEn": "Tak Bai",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960206",
    "subDistrictNameTh": "เกาะสะท้อน",
    "subDistrictNameEn": "Ko Sathon",
    "postcode": "96110",
    "districtCode": "9602",
    "districtNameTh": "ตากใบ",
    "districtNameEn": "Tak Bai",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960207",
    "subDistrictNameTh": "นานาค",
    "subDistrictNameEn": "Na Nak",
    "postcode": "96110",
    "districtCode": "9602",
    "districtNameTh": "ตากใบ",
    "districtNameEn": "Tak Bai",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960208",
    "subDistrictNameTh": "โฆษิต",
    "subDistrictNameEn": "Khosit",
    "postcode": "96110",
    "districtCode": "9602",
    "districtNameTh": "ตากใบ",
    "districtNameEn": "Tak Bai",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960301",
    "subDistrictNameTh": "บาเจาะ",
    "subDistrictNameEn": "Bacho",
    "postcode": "96170",
    "districtCode": "9603",
    "districtNameTh": "บาเจาะ",
    "districtNameEn": "Bacho",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960302",
    "subDistrictNameTh": "ลุโบะสาวอ",
    "subDistrictNameEn": "Lubo Sawo",
    "postcode": "96170",
    "districtCode": "9603",
    "districtNameTh": "บาเจาะ",
    "districtNameEn": "Bacho",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960303",
    "subDistrictNameTh": "กาเยาะมาตี",
    "subDistrictNameEn": "Kayo Mati",
    "postcode": "96170",
    "districtCode": "9603",
    "districtNameTh": "บาเจาะ",
    "districtNameEn": "Bacho",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960304",
    "subDistrictNameTh": "ปะลุกาสาเมาะ",
    "subDistrictNameEn": "Paluka Samo",
    "postcode": "96170",
    "districtCode": "9603",
    "districtNameTh": "บาเจาะ",
    "districtNameEn": "Bacho",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960305",
    "subDistrictNameTh": "บาเระเหนือ",
    "subDistrictNameEn": "Bare Nuea",
    "postcode": "96170",
    "districtCode": "9603",
    "districtNameTh": "บาเจาะ",
    "districtNameEn": "Bacho",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960306",
    "subDistrictNameTh": "บาเระใต้",
    "subDistrictNameEn": "Bare Tai",
    "postcode": "96170",
    "districtCode": "9603",
    "districtNameTh": "บาเจาะ",
    "districtNameEn": "Bacho",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960401",
    "subDistrictNameTh": "ยี่งอ",
    "subDistrictNameEn": "Yi-ngo",
    "postcode": "96180",
    "districtCode": "9604",
    "districtNameTh": "ยี่งอ",
    "districtNameEn": "Yi-ngo",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960402",
    "subDistrictNameTh": "ละหาร",
    "subDistrictNameEn": "Lahan",
    "postcode": "96180",
    "districtCode": "9604",
    "districtNameTh": "ยี่งอ",
    "districtNameEn": "Yi-ngo",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960403",
    "subDistrictNameTh": "จอเบาะ",
    "subDistrictNameEn": "Cho Bo",
    "postcode": "96180",
    "districtCode": "9604",
    "districtNameTh": "ยี่งอ",
    "districtNameEn": "Yi-ngo",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960404",
    "subDistrictNameTh": "ลุโบะบายะ",
    "subDistrictNameEn": "Lubo Baya",
    "postcode": "96180",
    "districtCode": "9604",
    "districtNameTh": "ยี่งอ",
    "districtNameEn": "Yi-ngo",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960405",
    "subDistrictNameTh": "ลุโบะบือซา",
    "subDistrictNameEn": "Lubo Buesa",
    "postcode": "96180",
    "districtCode": "9604",
    "districtNameTh": "ยี่งอ",
    "districtNameEn": "Yi-ngo",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960406",
    "subDistrictNameTh": "ตะปอเยาะ",
    "subDistrictNameEn": "Tapoyo",
    "postcode": "96180",
    "districtCode": "9604",
    "districtNameTh": "ยี่งอ",
    "districtNameEn": "Yi-ngo",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960501",
    "subDistrictNameTh": "ตันหยงมัส",
    "subDistrictNameEn": "Tanyong Mat",
    "postcode": "96130",
    "districtCode": "9605",
    "districtNameTh": "ระแงะ",
    "districtNameEn": "Ra-ngae",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960502",
    "subDistrictNameTh": "ตันหยงลิมอ",
    "subDistrictNameEn": "Tanyong Limo",
    "postcode": "96130",
    "districtCode": "9605",
    "districtNameTh": "ระแงะ",
    "districtNameEn": "Ra-ngae",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960506",
    "subDistrictNameTh": "บองอ",
    "subDistrictNameEn": "Bo-ngo",
    "postcode": "96220",
    "districtCode": "9605",
    "districtNameTh": "ระแงะ",
    "districtNameEn": "Ra-ngae",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960507",
    "subDistrictNameTh": "กาลิซา",
    "subDistrictNameEn": "Kalisa",
    "postcode": "96130",
    "districtCode": "9605",
    "districtNameTh": "ระแงะ",
    "districtNameEn": "Ra-ngae",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960508",
    "subDistrictNameTh": "บาโงสะโต",
    "subDistrictNameEn": "Ba-ngo Sato",
    "postcode": "96130",
    "districtCode": "9605",
    "districtNameTh": "ระแงะ",
    "districtNameEn": "Ra-ngae",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960509",
    "subDistrictNameTh": "เฉลิม",
    "subDistrictNameEn": "Chaloem",
    "postcode": "96130",
    "districtCode": "9605",
    "districtNameTh": "ระแงะ",
    "districtNameEn": "Ra-ngae",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960510",
    "subDistrictNameTh": "มะรือโบตก",
    "subDistrictNameEn": "Marue Botok",
    "postcode": "96130",
    "districtCode": "9605",
    "districtNameTh": "ระแงะ",
    "districtNameEn": "Ra-ngae",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960601",
    "subDistrictNameTh": "รือเสาะ",
    "subDistrictNameEn": "Rueso",
    "postcode": "96150",
    "districtCode": "9606",
    "districtNameTh": "รือเสาะ",
    "districtNameEn": "Rueso",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960602",
    "subDistrictNameTh": "สาวอ",
    "subDistrictNameEn": "Sawo",
    "postcode": "96150",
    "districtCode": "9606",
    "districtNameTh": "รือเสาะ",
    "districtNameEn": "Rueso",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960603",
    "subDistrictNameTh": "เรียง",
    "subDistrictNameEn": "Riang",
    "postcode": "96150",
    "districtCode": "9606",
    "districtNameTh": "รือเสาะ",
    "districtNameEn": "Rueso",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960604",
    "subDistrictNameTh": "สามัคคี",
    "subDistrictNameEn": "Samakkhi",
    "postcode": "96150",
    "districtCode": "9606",
    "districtNameTh": "รือเสาะ",
    "districtNameEn": "Rueso",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960605",
    "subDistrictNameTh": "บาตง",
    "subDistrictNameEn": "Batong",
    "postcode": "96150",
    "districtCode": "9606",
    "districtNameTh": "รือเสาะ",
    "districtNameEn": "Rueso",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960606",
    "subDistrictNameTh": "ลาโละ",
    "subDistrictNameEn": "Lalo",
    "postcode": "96150",
    "districtCode": "9606",
    "districtNameTh": "รือเสาะ",
    "districtNameEn": "Rueso",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960607",
    "subDistrictNameTh": "รือเสาะออก",
    "subDistrictNameEn": "Rueso Ok",
    "postcode": "96150",
    "districtCode": "9606",
    "districtNameTh": "รือเสาะ",
    "districtNameEn": "Rueso",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960608",
    "subDistrictNameTh": "โคกสะตอ",
    "subDistrictNameEn": "Khok Sato",
    "postcode": "96150",
    "districtCode": "9606",
    "districtNameTh": "รือเสาะ",
    "districtNameEn": "Rueso",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960609",
    "subDistrictNameTh": "สุวารี",
    "subDistrictNameEn": "Suwari",
    "postcode": "96150",
    "districtCode": "9606",
    "districtNameTh": "รือเสาะ",
    "districtNameEn": "Rueso",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960701",
    "subDistrictNameTh": "ซากอ",
    "subDistrictNameEn": "Sako",
    "postcode": "96210",
    "districtCode": "9607",
    "districtNameTh": "ศรีสาคร",
    "districtNameEn": "Si Sakhon",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960702",
    "subDistrictNameTh": "ตะมะยูง",
    "subDistrictNameEn": "Tamayung",
    "postcode": "96210",
    "districtCode": "9607",
    "districtNameTh": "ศรีสาคร",
    "districtNameEn": "Si Sakhon",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960703",
    "subDistrictNameTh": "ศรีสาคร",
    "subDistrictNameEn": "Si Sakhon",
    "postcode": "96210",
    "districtCode": "9607",
    "districtNameTh": "ศรีสาคร",
    "districtNameEn": "Si Sakhon",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960704",
    "subDistrictNameTh": "เชิงคีรี",
    "subDistrictNameEn": "Choeng Khiri",
    "postcode": "96210",
    "districtCode": "9607",
    "districtNameTh": "ศรีสาคร",
    "districtNameEn": "Si Sakhon",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960705",
    "subDistrictNameTh": "กาหลง",
    "subDistrictNameEn": "Kalong",
    "postcode": "96210",
    "districtCode": "9607",
    "districtNameTh": "ศรีสาคร",
    "districtNameEn": "Si Sakhon",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960706",
    "subDistrictNameTh": "ศรีบรรพต",
    "subDistrictNameEn": "Si Banphot",
    "postcode": "96210",
    "districtCode": "9607",
    "districtNameTh": "ศรีสาคร",
    "districtNameEn": "Si Sakhon",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960801",
    "subDistrictNameTh": "แว้ง",
    "subDistrictNameEn": "Waeng",
    "postcode": "96160",
    "districtCode": "9608",
    "districtNameTh": "แว้ง",
    "districtNameEn": "Waeng",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960802",
    "subDistrictNameTh": "กายูคละ",
    "subDistrictNameEn": "Kayu Khla",
    "postcode": "96160",
    "districtCode": "9608",
    "districtNameTh": "แว้ง",
    "districtNameEn": "Waeng",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960803",
    "subDistrictNameTh": "ฆอเลาะ",
    "subDistrictNameEn": "Kholo",
    "postcode": "96160",
    "districtCode": "9608",
    "districtNameTh": "แว้ง",
    "districtNameEn": "Waeng",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960804",
    "subDistrictNameTh": "โละจูด",
    "subDistrictNameEn": "Lochut",
    "postcode": "96160",
    "districtCode": "9608",
    "districtNameTh": "แว้ง",
    "districtNameEn": "Waeng",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960805",
    "subDistrictNameTh": "แม่ดง",
    "subDistrictNameEn": "Mae Dong",
    "postcode": "96160",
    "districtCode": "9608",
    "districtNameTh": "แว้ง",
    "districtNameEn": "Waeng",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960806",
    "subDistrictNameTh": "เอราวัณ",
    "subDistrictNameEn": "Erawan",
    "postcode": "96160",
    "districtCode": "9608",
    "districtNameTh": "แว้ง",
    "districtNameEn": "Waeng",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960901",
    "subDistrictNameTh": "มาโมง",
    "subDistrictNameEn": "Mamong",
    "postcode": "96190",
    "districtCode": "9609",
    "districtNameTh": "สุคิริน",
    "districtNameEn": "Sukhirin",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960902",
    "subDistrictNameTh": "สุคิริน",
    "subDistrictNameEn": "Sukhirin",
    "postcode": "96190",
    "districtCode": "9609",
    "districtNameTh": "สุคิริน",
    "districtNameEn": "Sukhirin",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960903",
    "subDistrictNameTh": "เกียร์",
    "subDistrictNameEn": "Kia",
    "postcode": "96190",
    "districtCode": "9609",
    "districtNameTh": "สุคิริน",
    "districtNameEn": "Sukhirin",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960904",
    "subDistrictNameTh": "ภูเขาทอง",
    "subDistrictNameEn": "Phukhao Thong",
    "postcode": "96190",
    "districtCode": "9609",
    "districtNameTh": "สุคิริน",
    "districtNameEn": "Sukhirin",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "960905",
    "subDistrictNameTh": "ร่มไทร",
    "subDistrictNameEn": "Rom Sai",
    "postcode": "96190",
    "districtCode": "9609",
    "districtNameTh": "สุคิริน",
    "districtNameEn": "Sukhirin",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "961001",
    "subDistrictNameTh": "สุไหงโก-ลก",
    "subDistrictNameEn": "Su-ngai Kolok",
    "postcode": "96120",
    "districtCode": "9610",
    "districtNameTh": "สุไหงโก-ลก",
    "districtNameEn": "Su-ngai Kolok",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "961002",
    "subDistrictNameTh": "ปาเสมัส",
    "subDistrictNameEn": "Pasemat",
    "postcode": "96120",
    "districtCode": "9610",
    "districtNameTh": "สุไหงโก-ลก",
    "districtNameEn": "Su-ngai Kolok",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "961003",
    "subDistrictNameTh": "มูโนะ",
    "subDistrictNameEn": "Muno",
    "postcode": "96120",
    "districtCode": "9610",
    "districtNameTh": "สุไหงโก-ลก",
    "districtNameEn": "Su-ngai Kolok",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "961004",
    "subDistrictNameTh": "ปูโยะ",
    "subDistrictNameEn": "Puyo",
    "postcode": "96120",
    "districtCode": "9610",
    "districtNameTh": "สุไหงโก-ลก",
    "districtNameEn": "Su-ngai Kolok",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "961101",
    "subDistrictNameTh": "ปะลุรู",
    "subDistrictNameEn": "Paluru",
    "postcode": "96140",
    "districtCode": "9611",
    "districtNameTh": "สุไหงปาดี",
    "districtNameEn": "Su-ngai Padi",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "961102",
    "subDistrictNameTh": "สุไหงปาดี",
    "subDistrictNameEn": "Su-ngai Padi",
    "postcode": "96140",
    "districtCode": "9611",
    "districtNameTh": "สุไหงปาดี",
    "districtNameEn": "Su-ngai Padi",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "961103",
    "subDistrictNameTh": "โต๊ะเด็ง",
    "subDistrictNameEn": "Todeng",
    "postcode": "96140",
    "districtCode": "9611",
    "districtNameTh": "สุไหงปาดี",
    "districtNameEn": "Su-ngai Padi",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "961104",
    "subDistrictNameTh": "สากอ",
    "subDistrictNameEn": "Sako",
    "postcode": "96140",
    "districtCode": "9611",
    "districtNameTh": "สุไหงปาดี",
    "districtNameEn": "Su-ngai Padi",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "961105",
    "subDistrictNameTh": "ริโก๋",
    "subDistrictNameEn": "Riko",
    "postcode": "96140",
    "districtCode": "9611",
    "districtNameTh": "สุไหงปาดี",
    "districtNameEn": "Su-ngai Padi",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "961106",
    "subDistrictNameTh": "กาวะ",
    "subDistrictNameEn": "Kawa",
    "postcode": "96140",
    "districtCode": "9611",
    "districtNameTh": "สุไหงปาดี",
    "districtNameEn": "Su-ngai Padi",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "961201",
    "subDistrictNameTh": "จะแนะ",
    "subDistrictNameEn": "Chanae",
    "postcode": "96220",
    "districtCode": "9612",
    "districtNameTh": "จะแนะ",
    "districtNameEn": "Chanae",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "961202",
    "subDistrictNameTh": "ดุซงญอ",
    "subDistrictNameEn": "Dusongyo",
    "postcode": "96220",
    "districtCode": "9612",
    "districtNameTh": "จะแนะ",
    "districtNameEn": "Chanae",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "961203",
    "subDistrictNameTh": "ผดุงมาตร",
    "subDistrictNameEn": "Phadung Mat",
    "postcode": "96220",
    "districtCode": "9612",
    "districtNameTh": "จะแนะ",
    "districtNameEn": "Chanae",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "961204",
    "subDistrictNameTh": "ช้างเผือก",
    "subDistrictNameEn": "Chang Phueak",
    "postcode": "96220",
    "districtCode": "9612",
    "districtNameTh": "จะแนะ",
    "districtNameEn": "Chanae",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "961301",
    "subDistrictNameTh": "จวบ",
    "subDistrictNameEn": "Chuap",
    "postcode": "96130",
    "districtCode": "9613",
    "districtNameTh": "เจาะไอร้อง",
    "districtNameEn": "Cho-airong",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "961302",
    "subDistrictNameTh": "บูกิต",
    "subDistrictNameEn": "Bukit",
    "postcode": "96130",
    "districtCode": "9613",
    "districtNameTh": "เจาะไอร้อง",
    "districtNameEn": "Cho-airong",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
},
{
    "subDistrictCode": "961303",
    "subDistrictNameTh": "มะรือโบออก",
    "subDistrictNameEn": "Maruebo Ok",
    "postcode": "96130",
    "districtCode": "9613",
    "districtNameTh": "เจาะไอร้อง",
    "districtNameEn": "Cho-airong",
    "provinceCode": "96",
    "provinceNameTh": "นราธิวาส",
    "provinceNameEn": "Narathiwat"
}
];


export const getProvinceList = (language) => {
    //console.log(district);
    //console.log('ProvinceCode:', provinceCode);
    //console.log(district.length);

    let list = [];
    //list.push({ key: '', text: 'กรุณาเลือก' });
    for (let i = 0; i < province.length; i++) {

      if (language === 'en') {
        list.push({ key: province[i].provinceCode, text: province[i].provinceNameEn});
      } else {
        list.push({ key: province[i].provinceCode, text: province[i].provinceNameTh});
      }
       
    }
    
    return list;
    

    //let list = this.district.filter();
}

export const getDistrictList = (provinceCode, language) => {
    //console.log(district);
    //console.log('ProvinceCode:', provinceCode);
    //console.log(district.length);

    let list = [];
    for (let i = 0; i < district.length; i++) {
        if (district[i].provinceCode === provinceCode) {
            //console.log('"provinceCode":', district[i].districtCode);
            //list.push({ key: district[i].districtCode, text: district[i].districtName});

            if (language === 'en') {
              list.push({ key: district[i].districtCode, text: district[i].districtNameEn});
            } else {
              list.push({ key: district[i].districtCode, text: district[i].districtNameTh});
            }
        }
    }
    return list;
    

    //let list = this.district.filter();
}

export const getSubDistrictList = (districtCode, language) => {
    //console.log(district);
    //console.log('ProvinceCode:', provinceCode);
    //console.log(district.length);

    let list = [];
    for (let i = 0; i < subDistrict.length; i++) {
        if (subDistrict[i].districtCode === districtCode) {
            //console.log('"provinceCode":', district[i].districtCode);
            //list.push({ key: subDistrict[i].subDistrictCode, text: subDistrict[i].subDistrictName});

            if (language === 'en') {
              list.push({ key: subDistrict[i].subDistrictCode, text: subDistrict[i].subDistrictNameEn});
            } else {
              list.push({ key: subDistrict[i].subDistrictCode, text: subDistrict[i].subDistrictNameTh});
            }
        }
    }
    return list;
    

    //let list = this.district.filter();
}

export const getProvince = (provinceCode, language) => {
  //console.log(district);
  //console.log('ProvinceCode:', provinceCode);
  //console.log(district.length);

  let list = {};
  //list.push({ key: '', text: 'กรุณาเลือก' });
  for (let i = 0; i < province.length; i++) {
    if (province[i].provinceCode === provinceCode) {

      if (language === 'en') {
        list = { key: province[i].provinceCode, text: province[i].provinceNameEn};
      } else {
        list = { key: province[i].provinceCode, text: province[i].provinceNameTh};
      }

    }
     
  }
  
  return list;
  

  //let list = this.district.filter();
}

export const getDistrict = (districtCode, language) => {
  //console.log(district);
  //console.log('ProvinceCode:', provinceCode);
  //console.log(district.length);

  //let list = [];
  let list = {};
  for (let i = 0; i < district.length; i++) {
      if (district[i].districtCode === districtCode) {
          //console.log('"provinceCode":', district[i].districtCode);
          //list.push({ key: district[i].districtCode, text: district[i].districtName});

          if (language === 'en') {
            //list.push({ key: district[i].districtCode, text: district[i].districtNameEn});
            list = { key: district[i].districtCode, text: district[i].districtNameEn};
          } else {
            //list.push({ key: district[i].districtCode, text: district[i].districtNameTh});
            list = { key: district[i].districtCode, text: district[i].districtNameTh};
          }
      }
  }
  return list;
  

  //let list = this.district.filter();
}

export const getSubDistrict = (subDistrictCode, language) => {
  //console.log(district);
  //console.log('ProvinceCode:', provinceCode);
  //console.log(district.length);

  let list = {};
  for (let i = 0; i < subDistrict.length; i++) {
      if (subDistrict[i].subDistrictCode === subDistrictCode) {
          //console.log('"provinceCode":', district[i].districtCode);
          //list.push({ key: subDistrict[i].subDistrictCode, text: subDistrict[i].subDistrictName});

          if (language === 'en') {
            //list.push({ key: subDistrict[i].subDistrictCode, text: subDistrict[i].subDistrictNameEn});
            list = { key: subDistrict[i].subDistrictCode, text: subDistrict[i].subDistrictNameEn};
          } else {
            //list.push({ key: subDistrict[i].subDistrictCode, text: subDistrict[i].subDistrictNameTh});
            list = { key: subDistrict[i].subDistrictCode, text: subDistrict[i].subDistrictNameTh};
          }
      }
  }
  return list;
  

  //let list = this.district.filter();
}