import React, { useState, useEffect } from 'react';
import './App.css';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    //Link    
} from 'react-router-dom';

import { MsalProvider, useMsal } from "@azure/msal-react";
import { MsalAuthenticationTemplate, useAccount } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { silentRequest } from "./authConfig";
//import { profileRequest } from "./authConfig";

//import { forgotPasswordRequest } from "../authConfig.js";
import { loginRequest } from "./authConfig";
//import { Check } from 'office-ui-fabric-react';

import axios from 'axios';

import { initializeFileTypeIcons } from '@uifabric/file-type-icons';
import { 
    //calculatePrecision,
    initializeIcons,
    Stack,
    Icon,
    Text
} from '@fluentui/react';

import Navigation from './components/navigation/Navigation';
import Header from './components/header/Header';
import PrepaidAccounts from './components/accounts/prepaid/PrepaidAccounts'
import Companies from './components/companies/Companies';
import LogOut from './components/logout/LogOut';
import Loading from './components/loading/Loading';
import ScrollToTop from './components/scroll-to-top/ScrollToTop';
import Maintenance from './components/maintenance/Maintenance'
import DocumentsCreditsAdd from './components/accounts/credits/documents/add/DocumentsCreditsAdd';
import SmsCreditsAdd from './components/accounts/credits/sms/add/SmsCreditsAdd';
import Billings from './components/billings/Billings';
// import Checkout from './components/billings/subscriptions/checkout/Checkout';
import SmsCheckout from './components/billings/sms/checkout/SmsCheckout';
import Complete from './components/billings/complete/Complete';
import DocumentsCheckout from './components/billings/documents/checkout/DocumentsCheckout';
import Parking from './components/parking/Parking';
import Histories from './components/histories/Histories';

// Register icons and pull the fonts from the default SharePoint cdn.
initializeFileTypeIcons();
initializeIcons();


const API_URL = process.env.REACT_APP_API_URL;
const LECEIPT_VERSION = process.env.REACT_APP_LECEIPT_VERSION;

// Styles definition
const stackStyles = {
    root: {
      //background: DefaultPalette.themeTertiary,
      //width: 300,
      background: '#FAF9F8',
      //minHeight: '100vh',
      //height: '100%',
      height: '100vh',
      //height: '500px',
      margin: 0,
      padding: 0,
      minWidth: '1400px',
      minHeight: '100%',
      //overflowY: 'scroll',
      //overflowX: 'hidden',
      
    },
  };


function App({ pca }) {
    return (
        <MsalProvider instance={pca}>
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={loginRequest}
                /* authenticationRequest={{
                    redirectStartPage: "http://localhost:3000/",
                }} */
                //errorComponent={ErrorComponent}
                errorComponent={LogOut}
                //errorComponent={{redirectStartPage: "http://localhost:3000/"}}
                loadingComponent={Loading}
            >
                <Body />
    
            </MsalAuthenticationTemplate>
        </MsalProvider>
    );
    
}

const Pages = () => {
    
	return (
		
        <Switch>                
            <Route exact path="/accounts/prepaid" >                                    
                <ScrollToTop />
                <PrepaidAccounts/>                
            </Route>            
            <Route exact path="/accounts/credits/documents/add" >                                    
                <ScrollToTop />
                <DocumentsCreditsAdd />                
            </Route>
            <Route exact path="/accounts/credits/sms/add" >                                    
                <ScrollToTop />
                <SmsCreditsAdd />                
            </Route>
            <Route exact path="/companies" >                                    
                <ScrollToTop />
                <Companies />                
            </Route>            
            <Route exact path="/maintenance" >                                    
                <ScrollToTop />
                <Maintenance />                
            </Route>
            <Route exact path="/parking" >                                    
                <ScrollToTop />
                <Parking />                
            </Route>            
            <Route exact path="/billings">
                <ScrollToTop />
                <Billings />
            </Route>            
            <Route exact path="/billings/documents/checkout">
                <ScrollToTop />
                <DocumentsCheckout />
            </Route>
            <Route exact path="/billings/sms/checkout">
                <ScrollToTop />
                <SmsCheckout />
            </Route>
            <Route exact path="/billings/:orderId/complete">
                <ScrollToTop />
                <Complete />
            </Route>
            <Route exact path="/histories">
                <ScrollToTop />
                <Histories />
            </Route>   
            
            <Redirect to="/accounts/prepaid" />
            {/* <Redirect to="/parking" /> */}
                         
        </Switch>

                        
	)
}

const Body = () =>{    

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const [companySetting, setCompanySetting] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    
    const [isLoadAccountsComplete, setIsLoadAccountsComplete] = useState(false);
    const [accountData, setAccountData] = useState({});
    const [companyData, setCompanyData] = useState({});

    const [isLoadRolesComplete, setIsLoadRolesComplete] = useState(false);
    const [role, setRole] = useState('');

    const initial = async () => {

        await instance.acquireTokenSilent({...silentRequest, account: account}).then(async tokenResponse => {
            console.log('tokenResponse: ', tokenResponse);

            axios
                .get(API_URL + '/etax/companies', {
                    headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken,
                    },
                })
                .then(
                    (response) => {
                    console.log(response);

                    if (response.data.length === 0) {
                        setCompanySetting(true);
                    } else {
                        setCompanySetting(false);
                        setCompanyData(response.data[0]);
                    }

                    setIsLoading(false);
                    },
                    (error) => {
                    console.log(error);
                    }
                );

            await axios.get(API_URL + '/accounts', 
                {
                headers: { 
                    'Authorization': 'Bearer ' + tokenResponse.accessToken
                } 
                })
                .then((response) => {                   

                    console.log(response);

                    if (response.data) {
                        
                        
                        setAccountData(response.data);

                        if (response.data.resellers && response.data.resellers.type && response.data.resellers.enable) {

                            setRole(response.data.resellers.type);
                            
                        } 
    
                        setIsLoadRolesComplete(true);
                        
                    } 

                    setIsLoadAccountsComplete(true);

                    //setIsLoading(false);

                    
                }, (error) => {
                    console.log(error);
                
                });           


        }).catch(error => {
           
            //Acquire token silent failure, and send an interactive request
            console.log(error);
            instance.acquireTokenRedirect(silentRequest);

        });

    }

    useEffect(() => {
        console.log('userEffect retrieve accounts data!');    

        initial();       

    }, []);

    return (
        
        <Router>
        {(isLoading || !isLoadAccountsComplete) ? (
                <div>
                <Loading />
                </div>
            ) : companySetting ? (
                /* <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <Header />
                                </div>
                                <div className="ms-Grid-row" style={{ background: '#FAF9F8' }}>
                                    <EtaxCompanies />
                                    
                                </div>
                            </div> */
                <Stack styles={stackStyles} disableShrink={false} wrap={false}>
                    <Stack horizontal>
                        <Header
                        {...{
                            accountData: accountData,
                            companyData: { name: 'กรุณาตั้งค่าข้อมูลบริษัท...' },
                        }}
                        />
                    </Stack>

                    <Stack
                        horizontal
                        horizontalAlign="center"
                        style={{ background: '#FAF9F8' }}
                    >
                        {/*     <Stack vertical style={{ background: 'blue', height: '100%', minHeight: '100%' }} verticalAlign='space-between'>
                                        </Stack>
                                        <Stack vertical > */}
                        <Companies />
                        {/* </Stack> */}
                    </Stack>
                </Stack>
            ) : 

                (role === 'reseller') ?
                
                    isLoadAccountsComplete ? (
                        <Stack styles={stackStyles} disableShrink={false} wrap={false}>
                            <Stack
                                horizontal
                                style={
                                {
                                    //background: 'green',
                                    //minWidth: '1440px'
                                }
                                }
                            >
                                <Header
                                {...{ accountData: accountData, companyData: companyData }}
                                />
                            </Stack>
                            <Stack horizontal>
                                <Stack
                                vertical
                                style={{
                                    background: 'white',
                                    height: '100%',
                                    minHeight: 'calc(100vh - 70px)',
                                }}
                                verticalAlign="space-between"
                                >
                                {/* <Stack vertical style={{ background: 'white' }} verticalAlign='space-between'> */}

                                <Navigation />

                                <span style={{ paddingLeft: '25px' }}>
                                    {/* 
                                                                    <br/>
                                                                    <a href="http://nav.cx/3p1xabz" target="_blank">
                                                                        <img src={PUBLIC_URL + '/images/line-add-friend.png'} alt="เพิ่มเพื่อน" height="36" border="0"></img>
                                                                    </a>
                                                                    <br/>
                                                                    <br/>
                                                                    */}
                                    <Text variant='medium' nowrap block>
                                    <b>
                                        <Icon
                                        iconName="Telemarketer"
                                        style={{ fontSize: '20px' }}
                                        />{' '}
                                        ฝ่ายบริการลูกค้า
                                    </b>
                                    </Text>
                                    {/* <br /> */}
                                    <Text variant='medium' nowrap block>
                                    <a href="http://nav.cx/3p1xabz" target="_blank">
                                        Line: @leceipt
                                    </a>
                                    </Text>
                                    {/* <br /> */}
                                    <Text variant='medium' nowrap block>โทร. 082-579-8555</Text>
                                    {/* <br /> */}
                                    <Text variant='medium' nowrap block>เวลาทำการ 8.30 น. - 17.30 น. ทุกวัน</Text>
                                    {/* <br /> */}
                                    <Text variant='medium' nowrap block>เวอร์ชั่น {LECEIPT_VERSION}</Text>
                                    {/* <br /> */}
                                    <br />
                                </span>
                                </Stack>
                                {/* <Stack vertical style={{ background: '#FAF9F8', width: '100%', overflowY: 'scroll', overflowY: 'scroll', height: '91vh' }}> */}
                                <Stack vertical style={{ background: '#FAF9F8', width: '100%' }}>
                                <Stack horizontal horizontalAlign="center" wrap={false}>
                                    <Pages />
                                </Stack>
                                {/* {JSON.stringify(accountData)} */}
                                </Stack>
                            </Stack>
                        </Stack>
                    ) : (
                        <div>
                        <Loading />
                        </div>
                    )
        
            :
            isLoadRolesComplete &&
               
                <div>
                    <Parking />
                    
                </div>   
            }    
        </Router>
        
    
    );
    
}

export default App;
