import React, { useState, useEffect, useRef } from 'react';

import axios from 'axios';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../authConfig';
//import b2cauth from 'react-azure-adb2c';

//import { TextField, MaskedTextField } from 'office-ui-fabric-react/lib/TextField';
//import { TextField } from 'office-ui-fabric-react/lib/TextField';
//import { Stack } from 'office-ui-fabric-react/lib/Stack';
//import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
//import { PrimaryButton } from 'office-ui-fabric-react';
//import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
//import { useForm, Controller  } from "react-hook-form";

import { useHistory } from 'react-router-dom';

import {
  TextField,
  //DatePicker,
  //DayOfWeek,
  //mergeStyles,
  PrimaryButton,
  //DefaultButton,
  //IconButton,
  //Checkbox,
  Dropdown,
  Stack,
  getTheme,
  Shimmer,
  ShimmerElementType,
  Icon,
  //getYearEnd,
  //FontIcon,
  //initializeIcons,
  Spinner,
  //SpinnerSize,
  //MessageBarButton,
  MessageBar,
  MessageBarType,
  Separator,
} from '@fluentui/react';

//import Province from './address/Province';
//import District from './address/District';
//import SubDistrict from './address/SubDistrict';
//import Branch from './address/Branch';

import {
  getProvinceList,
  getDistrictList,
  getSubDistrictList,
  getProvince,
  getDistrict,
  getSubDistrict,
} from '../../providers/AreaData';

const API_URL = process.env.REACT_APP_API_URL;

const theme = getTheme();

const stackTokens = { childrenGap: 10 };

const dropdownStyles = {
  dropdown: { width: 450 },
};

const cardStyle = {
  paddingLeft: 30,
  paddingRight: 30,
  paddingTop: 20,
  paddingBottom: 30,
  background: '#ffffff',
  marginLeft: '0',
  marginTop: '10px',
  boxShadow: theme.effects.elevation4,
};
/*
const homeStyles = {    
    //width: '208',
    //height: '10vh',
    //width: 185,
    //height: 70,
    //boxSizing: 'border-box',
    //border: '1px solid #eee',
    //overflowY: 'auto',
    //paddingTop: '10vh',    
    //background: '#FFFFFF',
    //background: '#106ebe',
    border: 0,
    paddingTop: '2%',
    paddingLeft: '2%',
    paddingRight: '2%',
    margin: 0,
    //minHeight: '100%',
    //height: '100%',        
    //minHeight: '100vh',
    //width: '100%',
    background: '#FAF9F8'
    //background: '#106ebe',
    
}

//const iconProps = { iconName: 'Calendar' };
//const stackStyles = { root: { width: 800 } };
 
const _alertClicked = () => {
    alert('Clicked');
}
 */

//initializeIcons();
const shimmerRowHeight32 = [
  { type: ShimmerElementType.line, height: 32, width: 410 },
];

const shimmerRowOne = [
  { type: ShimmerElementType.line, height: 30, width: 410 },
];

const shimmerRowTwo = [
  //{ type: ShimmerElementType.circle },
  { type: ShimmerElementType.line, height: 30, width: 150 },
  { type: ShimmerElementType.gap, width: 10 },
  { type: ShimmerElementType.line, height: 30, width: 250 },
  /* { type: ShimmerElementType.gap, width: '1%' },
    { type: ShimmerElementType.line, height: 30 }, */
];

const shimmerRowThree = [
  { type: ShimmerElementType.line, height: 30, width: 150 },
];

const Companies = () => {
  console.log('Re render');

  //const auth = Auth();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const [company, setCompany] = useState([]);
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [buildingNumber, setBuildingNumber] = useState('');
  const [address, setAddress] = useState('');
  const [streetPrefix, setStreetPrefix] = useState('');
  const [streetName, setStreetName] = useState('');
  const [provincePrefix, setProvincePrefix] = useState('');
  const [province, setProvince] = useState({ key: '', text: '' });

  const [districtPrefix, setDistrictPrefix] = useState('');
  const [district, setDistrict] = useState({ key: '', text: '' });
  const [districtList, setDistrictList] = useState([]);

  const [subDistrictPrefix, setSubDistrictPrefix] = useState('');
  const [subDistrict, setSubDistrict] = useState({ key: '', text: '' });
  const [subDistrictList, setSubDistrictList] = useState([]);

  const [postcode, setPostcode] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [taxId, setTaxId] = useState('');
  const [branch, setBranch] = useState('00000');
  const [branchNumber, setBranchNumber] = useState('00000');
  const [showBranchNumber, setShowBranchNumber] = useState(false);
  const [branchText, setBranchText] = useState('(สำนักงานใหญ่)');
  const [branchDisplay, setBranchDisplay] = useState([
    { key: '00000', text: 'สำนักงานใหญ่' },
    { key: 'branchNumber', text: 'สาขาเลขที่' },
  ]);

  let taxIdRef = useRef(null);
  let branchNumberRef = useRef(null);

  const [buttonClick, setButtonClick] = useState(false);
  const [showMessageBarSuccess, setShowMessageBarSuccess] = useState(false);
  const [showMessageBarError, setShowMessageBarError] = useState(false);

  const [language, setLanguage] = useState('th');

  const provinceList = getProvinceList(language);

  const [isLoadComplete, setIsLoadComplete] = useState(false);
  const [isSearchCompleate, setIsSearchCompleate] = useState(true);

  const [registerVat, setRegisterVat] = useState(true);

  //Error Message
  const [provinceErrMsg, setProvinceErrMsg] = useState('');
  const [districtErrMsg, setDistrictErrMsg] = useState('');
  const [subDistrictErrMsg, setSubDistrictErrMsg] = useState('');
  /* 
    const [streetPrefixErrMsg, setStreetPrefixErrMsg] = useState('');
    const [provincePrefixErrMsg, setProvincePrefixErrMsg] = useState('');
    const [districtPrefixErrMsg, setDistrictPrefixErrMsg] = useState('');
    const [subDistrictPrefixErrMsg, setSubDistrictPrefixErrMsg] = useState('');
 */
  //const [branchErrMsg, setBranchErrMsg] = useState('');

  const DropdownErrMsg = 'กรุณาเลือก';

  const phoneRegEx = /^0[1-9][0-9]{1,13}$/g;
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  const history = useHistory();

  //let districtList = [];
  /* 
    useEffect(() => {
        console.log('Province Effect', province.key);
        
        if (province.key != null) {
            //districtList = getDistrictList(province.key);
            console.log(districtList);

        } else {
            //const districtList = [{ key: '', text:'' }];
            console.log('Define districtList');
        }

    }, [province])
    
     */

  //const { control, register, handleSubmit, watch, errors } = useForm();
  //const onSubmit = data => console.log('Submit', data);
  /* 
    useEffect(() => {
        register({name: 'subDistrictData'}, {required: true});
    }, [register])
 */
  //console.log(watch('companyName'));

  useEffect(async () => {
    console.log('userEffect retrieve company data!');

    //this.token = b2cauth.getAccessToken();
    //b2cauth.run();
    //let token = await AccessToken();
    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        //axios.get('https://etax.azure-api.net/company/b5cf38c9-5e52-4bd3-b737-caf5c6d30635')
        //axios.get('https://etax.azure-api.net/company/' + userId)
        axios
          .get(API_URL + '/etax/companies', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              console.log(response);

              if (response.data[0]) {
                //console.log(JSON.stringify(response.data));
                console.log('Company data: ', response.data);
                setCompany(response.data);

                setId(response.data[0].id);
                setName(response.data[0].name);
                setBuildingNumber(response.data[0].buildingNumber);
                setAddress(response.data[0].address);
                setStreetPrefix(response.data[0].streetPrefix);
                setStreetName(response.data[0].streetName);
                setProvincePrefix(response.data[0].provincePrefix);
                setProvince({
                  key: response.data[0].provinceCode,
                  text: response.data[0].provinceName,
                });
                //setProvinceCode(response.data[0].provinceCode);
                //setProvinceName(response.data[0].provinceName);

                setDistrictList(
                  getDistrictList(response.data[0].provinceCode, language)
                );
                setDistrictPrefix(response.data[0].districtPrefix);
                setDistrict({
                  key: response.data[0].districtCode,
                  text: response.data[0].districtName,
                });
                //setDistrictCode(response.data[0].subDistrictCode);
                //setDistrictName(response.data[0].subDistrictName);

                setSubDistrictList(
                  getSubDistrictList(response.data[0].districtCode, language)
                );
                setSubDistrictPrefix(response.data[0].subDistrictPrefix);
                setSubDistrict({
                  key: response.data[0].subDistrictCode,
                  text: response.data[0].subDistrictName,
                });
                //setSubDistrictCode(response.data[0].subDistrictCode);
                //setSubDistrictName(response.data[0].subDistrictName);

                setPostcode(response.data[0].postcode);
                setPhone(response.data[0].phone);
                setEmail(response.data[0].email);
                setTaxId(response.data[0].taxId);
                setBranchNumber(response.data[0].branchNumber);
                setBranchText(response.data[0].branchText);

                setLanguage(response.data[0].language);
                setRegisterVat(response.data[0].registerVat);

                if (response.data[0].branchNumber === '00000') {
                  setBranch('00000');
                  setShowBranchNumber(false);
                } else {
                  setBranch('branchNumber');
                  setShowBranchNumber(true);
                }

                console.log('Branch number: ', branchNumber);
              }

              setIsLoadComplete(true);
            },
            (error) => {
              console.log(error);
              //b2cauth.run();
            }
          );
      })
      .catch((error) => {
        /* 
            if (error instanceof InteractionRequiredAuthError) {
                // fallback to interaction when silent call fails
                return instance.acquireTokenRedirect(tokenRequest)
            }
     */

        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);

        /* 
            if (error.errorMessage.indexOf("interaction_required") !== -1) {
                instance.acquireTokenRedirect(silentRequest);
            }
            */
      });
  }, []);

  useEffect(() => {
    console.log('Language Effect: ', language);

    if (language != null) {
      setProvince(getProvince(province.key, language));
      setDistrict(getDistrict(district.key, language));
      setDistrictList(getDistrictList(province.key, language));
      setSubDistrict(getSubDistrict(subDistrict.key, language));
      setSubDistrictList(getSubDistrictList(district.key, language));

      //setSubDistrictList(getSubDistrictList(language, language));

      handleBranch(branchNumber, language);
    }

    if (language === 'en') {
      setSubDistrictPrefix('');
      setDistrictPrefix('');
      setProvincePrefix('');
      setStreetPrefix('');
    } else {
      if (province.key === '10') {
        setSubDistrictPrefix('แขวง');
        setDistrictPrefix('เขต');
        setProvincePrefix('');
      } else if (province.key) {
        setSubDistrictPrefix('ต.');
        setDistrictPrefix('อ.');
        setProvincePrefix('จ.');
      } else {
        setSubDistrictPrefix('');
        setDistrictPrefix('');
        setProvincePrefix('');
      }
      if (streetName) {
        setStreetPrefix('ถ.');
      } else {
        setStreetPrefix('');
      }
    }
  }, [language]);

  function handleSearch() {
    console.log('search function');

    if (taxId.length !== 13) return;

    console.log('searching');
    setIsSearchCompleate(false);

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        // try {
        axios
          .get(API_URL + '/sources/rd/vat/' + taxId, {
            // .get('https://ws.leceipt.com/sources/rd/vat/' + data.companyTaxId, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'Content-Type': 'application/json',
            },
          })
          .then((response) => {
            console.log('response from rd vat', response);
            if (!response.data.msgerr[0]) {
              setRegisterVat(true);
              // if (response.data.BranchTitleName[0].trim() === 'บริษัท') {
              //   // setTaxNumberType('TXID');
              //   setRegisterVat(true);
              // } else {
              //   // setTaxNumberType('NIDN');
              //   setRegisterVat(false);
              // }

              if (response.data.BranchNumber[0] === 0) {
                if (language === 'th') {
                  setBranchText('สำนักงานใหญ่');
                } else {
                  setBranchText('Head office');
                }
                setBranchNumber('00000');
              } else if (response.data.BranchNumber[0] !== '-') {
                if (language === 'th') {
                  setBranchText(
                    'สาขา ' +
                      String(response.data.BranchNumber[0]).padStart(5, '0')
                  );
                } else {
                  setBranchText(
                    'Branch ' +
                      String(response.data.BranchNumber[0]).padStart(5, '0')
                  );
                }
                setBranchNumber(
                  String(response.data.BranchNumber[0]).padStart(5, '0')
                );
              }

              setName(
                response.data.BranchTitleName[0].trim() +
                  ' ' +
                  response.data.Name[0].trim()
              );
              setBuildingNumber(response.data.HouseNumber[0].trim());

              let _address = '';

              if (response.data.BuildingName[0].trim() !== '-') {
                _address =
                  _address + response.data.BuildingName[0].trim() + ' ';
              }

              if (response.data.FloorNumber[0].trim() !== '-') {
                _address =
                  _address +
                  'ชั้น ' +
                  response.data.FloorNumber[0].trim() +
                  ' ';
              }

              if (response.data.RoomNumber[0].trim() !== '-') {
                _address =
                  _address + 'ห้อง ' + response.data.RoomNumber[0].trim() + ' ';
              }

              if (response.data.MooNumber[0].trim() !== '-') {
                _address =
                  _address + 'หมู่ ' + response.data.MooNumber[0].trim() + ' ';
              }

              if (response.data.SoiName[0].trim() !== '-') {
                _address =
                  _address + 'ซอย ' + response.data.SoiName[0].trim() + ' ';
              }

              if (response.data.VillageName[0].trim() !== '-') {
                _address =
                  _address +
                  'หมู่บ้าน ' +
                  response.data.VillageName[0].trim() +
                  ' ';
              }
              setAddress(_address.trim());

              setStreetPrefix(
                response.data.StreetName[0].trim() !== '-' ? 'ถ.' : ''
              );
              setStreetName(
                response.data.StreetName[0].trim() !== '-'
                  ? response.data.StreetName[0].trim()
                  : ''
              );

              // setProvincePrefix('จ.');

              provinceList.forEach((province) => {
                if (province.text === response.data.Province[0]) {
                  setProvince({
                    key: province.key,
                    text: province.text,
                  });

                  if (province.key === '10') {
                    setSubDistrictPrefix('แขวง');
                    setDistrictPrefix('เขต');
                    setProvincePrefix('');
                  } else if (province.key) {
                    setSubDistrictPrefix('ต.');
                    setDistrictPrefix('อ.');
                    setProvincePrefix('จ.');
                  } else {
                    setSubDistrictPrefix('');
                    setDistrictPrefix('');
                    setProvincePrefix('');
                  }

                  let districtList = getDistrictList(province.key, language);

                  setDistrictList(districtList);
                  // setDistrictPrefix('อ.');
                  districtList.forEach((district) => {
                    if (district.text === response.data.Amphur[0]) {
                      setDistrict({
                        key: district.key,
                        text: district.text,
                      });

                      let subDistrictList = getSubDistrictList(
                        district.key,
                        language
                      );
                      setSubDistrictList(subDistrictList);
                      // setSubDistrictPrefix('ต.');
                      subDistrictList.forEach((subDistrict) => {
                        if (subDistrict.text === response.data.Thambol[0]) {
                          setSubDistrict({
                            key: subDistrict.key,
                            text: subDistrict.text,
                          });
                        }
                      });
                    }
                  });
                }
              });

              setPostcode(response.data.PostCode[0].trim());

              setIsSearchCompleate(true);
            } else {
              setIsSearchCompleate(true);
              // setRdError(true);
            }
          });
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  }

  function handleSubmit(event) {
    //if (company.length > 0) {
    event.preventDefault();
    //}

    let isCorrect = true;
    /* 
        if (streetPrefix == undefined || streetPrefix == null || streetPrefix == '') {
            isCorrect = false;
            setStreetPrefixErrMsg(DropdownErrMsg);
            //provinceRef.current.focus();
        }

        if (provincePrefix == undefined || provincePrefix == null || provincePrefix == '') {
            isCorrect = false;
            setProvincePrefixErrMsg(DropdownErrMsg);
            //provinceRef.current.focus();
        } */

    if (
      province.key == undefined ||
      province.key == null ||
      province.key == ''
    ) {
      isCorrect = false;
      setProvinceErrMsg(DropdownErrMsg);
      //provinceRef.current.focus();
    }
    /* 
        if (districtPrefix == undefined || districtPrefix == null || districtPrefix == '') {
            isCorrect = false;
            setDistrictPrefixErrMsg(DropdownErrMsg);
            //provinceRef.current.focus();
        }
 */
    if (
      district.key == undefined ||
      district.key == null ||
      district.key == ''
    ) {
      isCorrect = false;
      setDistrictErrMsg(DropdownErrMsg);
      //provinceRef.current.focus();
    }
    /* 
        if (subDistrictPrefix == undefined || subDistrictPrefix == null || subDistrictPrefix == '') {
            isCorrect = false;
            setSubDistrictPrefixErrMsg(DropdownErrMsg);
            //provinceRef.current.focus();
        }
 */
    if (
      subDistrict.key == undefined ||
      subDistrict.key == null ||
      subDistrict.key == ''
    ) {
      isCorrect = false;
      setSubDistrictErrMsg(DropdownErrMsg);
      //provinceRef.current.focus();
    }

    if (taxId.length !== 13) {
      isCorrect = false;
      taxIdRef.focus();
      //provinceRef.current.focus();
    }

    if (branchNumber.length !== 5) {
      console.log('branchNumber length: ', branchNumber.length);
      isCorrect = false;
      branchNumberRef.focus();
    }

    if (phoneNumberError) {
      isCorrect = false;
    }

    setButtonClick(true);
    setShowMessageBarSuccess(false);
    setShowMessageBarError(false);

    //console.log( 'Email:', email, 'Password: ', password);
    console.log(
      'Company:',
      name,
      '\nid:',
      id,
      '\nbuildingNumber:',
      buildingNumber,
      '\naddress:',
      address,
      '\nProvince:',
      province,
      '\nDistrict:',
      district,
      '\nSubDistrict:',
      subDistrict,
      '\nPostCode:',
      postcode,
      '\nPhone:',
      phone,
      '\nTaxId:',
      taxId,
      '\nBranch:',
      branchNumber
    );
    // You should see email and password in console.
    // ..code to submit form to backend here...

    //b2cauth.run();

    //let token = AccessToken();

    if (isCorrect) {
      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          if (id !== '') {
            //axios.post('https://etax.azure-api.net/company/create', {
            axios
              .put(
                API_URL + '/etax/companies/' + id,
                {
                  address: address,
                  branchNumber: branchNumber,
                  branchText: branchText,
                  buildingNumber: buildingNumber,
                  districtCode: district.key,
                  districtName: district.text,
                  districtPrefix: districtPrefix,
                  email: email,
                  name: name,
                  phone: phone,
                  phoneCountryCode: '+66',
                  postcode: postcode,
                  provinceCode: province.key,
                  provinceName: province.text,
                  provincePrefix: provincePrefix,
                  streetName: streetName,
                  streetPrefix: streetPrefix,
                  subDistrictCode: subDistrict.key,
                  subDistrictName: subDistrict.text,
                  subDistrictPrefix: subDistrictPrefix,
                  taxId: taxId,
                  taxNumberType: 'TXID',
                  language: language,
                  registerVat: registerVat,
                  unstructure: false,
                },
                {
                  headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken,
                    'Content-Type': 'application/json',
                  },
                }
              )
              .then(
                (response) => {
                  //console.log(response.data.id);
                  console.log(response);
                  setButtonClick(false);
                  setShowMessageBarSuccess(true);
                  window.scrollTo(0, 0);
                },
                (error) => {
                  console.log(error);
                  setButtonClick(false);
                  setShowMessageBarError(true);
                  window.scrollTo(0, 0);
                }
              );
          } else {
            axios
              .post(
                API_URL + '/etax/companies',
                {
                  address: address,
                  branchNumber: branchNumber,
                  branchText: branchText,
                  buildingNumber: buildingNumber,
                  districtCode: district.key,
                  districtName: district.text,
                  districtPrefix: districtPrefix,
                  email: email,
                  name: name,
                  phone: phone,
                  phoneCountryCode: '+66',
                  postcode: postcode,
                  provinceCode: province.key,
                  provinceName: province.text,
                  provincePrefix: provincePrefix,
                  streetName: streetName,
                  streetPrefix: streetPrefix,
                  subDistrictCode: subDistrict.key,
                  subDistrictName: subDistrict.text,
                  subDistrictPrefix: subDistrictPrefix,
                  taxId: taxId,
                  taxNumberType: 'TXID',
                  language: language,
                  registerVat: registerVat,
                  unstructure: false,
                },
                {
                  headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken,
                    'Content-Type': 'application/json',
                  },
                }
              )
              .then(
                (response) => {
                  //console.log(response.data.id);
                  console.log(response);
                  setButtonClick(false);
                  setShowMessageBarSuccess(true);
                  window.scrollTo(0, 0);
                  //window.location.href = '/etax/receipts-taxinvoices';
                  window.location.href = '/etax/documents/all';
                },
                (error) => {
                  console.log(error);
                  setButtonClick(false);
                  setShowMessageBarError(true);
                  window.scrollTo(0, 0);
                }
              );
          }
        })
        .catch((error) => {
          /* 
                if (error instanceof InteractionRequiredAuthError) {
                    // fallback to interaction when silent call fails
                    return instance.acquireTokenRedirect(tokenRequest)
                }
        */

          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);

          /* 
                if (error.errorMessage.indexOf("interaction_required") !== -1) {
                    instance.acquireTokenRedirect(silentRequest);
                }
 */
        });
    } else {
      setButtonClick(false);
    }
  }

  const handleBranch = (branchNum, lang) => {
    //e.preventDefault();
    //console.log('Items', items);
    console.log('Change branch:', branchNum);
    if (branchNum === '00000') {
      setShowBranchNumber(false);
      setBranch('00000');
      setBranchNumber('00000');

      if (lang === 'en') {
        console.log('Set brach text English!');
        setBranchText('Head office');
      } else {
        setBranchText('สำนักงานใหญ่');
      }
    } else {
      setBranch('branchNumber');
      if (branchNum === 'branchNumber') {
        setBranchNumber('00000');

        if (lang === 'en') {
          setBranchText('Branch 00000');
        } else {
          setBranchText('สาขา 00000');
        }
      } else {
        setBranchNumber(branchNum);

        if (lang === 'en') {
          setBranchText('Branch ' + branchNum);
        } else {
          setBranchText('สาขา ' + branchNum);
        }
      }
      setShowBranchNumber(true);
    }

    if (lang === 'en') {
      setBranchDisplay([
        { key: '00000', text: 'Head office' },
        { key: 'branchNumber', text: 'Branch' },
      ]);
    } else {
      setBranchDisplay([
        { key: '00000', text: 'สำนักงานใหญ่' },
        { key: 'branchNumber', text: 'สาขาเลขที่' },
      ]);
    }
  };
  /* 
    const closeMessageBar = () => {
        //this.setState({showMessageBar: false})    
        setShowMessageBarSuccess(false);
    }
     */

  const getErrorMessage = (value) => {
    return value && value !== '0' && value.trim() ? '' : 'กรุณากรอกข้อมูล';
  };

  const getErrorMessageTaxId = (value) => {
    return value && value.length === 13
      ? ''
      : 'กรอกเลขประจำตัวผู้เสียภาษีให้ครบ 13 หลัก';
  };

  const getErrorMessageBranchNumber = (value) => {
    return value && value.length === 5 ? '' : 'กรุณากรอกเลขสาขาให้ครบ 5 หลัก';
  };

  const getPhoneNumberErrorMessage = (value) => {
    if (phone && !phoneRegEx.test(phone)) {
      setPhoneNumberError(true);
      return 'รูปแบบไม่ถูกต้อง ตัวอย่าง: 0812345678';
    } else if (value === '' || value === null || value === undefined) {
      setPhoneNumberError(false);
      return 'กรุณากรอกข้อมูล';
    } else {
      setPhoneNumberError(false);
      return '';
    }
  };

  return (
    <Stack style={{ background: '#FAF9F8' }}>
      {showMessageBarSuccess && (
        <MessageBar
          /* 
                actions={
                <div>
                    <MessageBarButton>Yes</MessageBarButton>
                    <MessageBarButton>No</MessageBarButton>
                </div>
                }
                 */
          messageBarType={MessageBarType.success}
          isMultiline={false}
          onDismiss={() => setShowMessageBarSuccess(false)}
          dismissButtonAriaLabel="Close"
        >
          บันทึกสำเร็จ!
        </MessageBar>
      )}
      {showMessageBarError && (
        <MessageBar
          /* 
                actions={
                <div>
                    <MessageBarButton>Yes</MessageBarButton>
                    <MessageBarButton>No</MessageBarButton>
                </div>
                }
                 */
          messageBarType={MessageBarType.error}
          isMultiline={false}
          onDismiss={() => setShowMessageBarError(false)}
          dismissButtonAriaLabel="Close"
        >
          บันทึกไม่สำเร็จ!
        </MessageBar>
      )}
      <form onSubmit={handleSubmit}>
        <h2 style={{ marginLeft: '0', marginTop: '27px' }}>
          <Icon iconName="CityNext2" style={{ color: '#106ebe' }} />{' '}
          ข้อมูลตัวแทนจำหน่าย (Reseller)
        </h2>
        
        <div style={{ marginLeft: '0' }}>
            {/* (ข้อมูลทั้งหมดข้างล่างนี้ ใช้สำหรับแสดงเพื่อการติดต่อซื้อ ที่หน้าเติมเครดิตในบัญชีลูกค้าของท่าน) */}
            (ข้อมูลทั้งหมดข้างล่างนี้ ใช้สำหรับออกใบเสร็จรับเงิน/ใบกำกับภาษี ให้กับท่าน)
        </div>
        {/* <div className="ms-Grid" dir="ltr" style={{ paddingLeft: 30, paddingRight: 30, paddingTop: 20, paddingBottom: 30, background: '#ffffff', margin: '2%', boxShadow: theme.effects.elevation4 }}>  */}
        <div
        // style={{
        //   paddingLeft: 30,
        //   paddingRight: 30,
        //   paddingTop: 20,
        //   paddingBottom: 30,
        //   background: '#ffffff',
        //   marginLeft: '0',
        //   marginTop: '10px',
        //   boxShadow: theme.effects.elevation4,
        // }}
        >
          {/*< div className="ms-Grid-row"> */}

          {isLoadComplete ? (
            <div style={cardStyle}>
              <strong>ข้อมูลชื่อกิจการ</strong>
              <Stack
                horizontal
                tokens={stackTokens}
                styles={{ root: { marginTop: 10 } }}
              >
                <TextField
                  componentRef={(input) => (taxIdRef = input)}
                  name="taxId"
                  label="เลขประจำตัวผู้เสียภาษี 13 หลัก"
                  styles={{ root: { width: 220 } }}
                  value={taxId}
                  onChange={(e) => {
                    console.log('Tax id lenght: ', e.target.value);
                    setTaxId(e.target.value);
                  }}
                  onGetErrorMessage={getErrorMessageTaxId}
                  validateOnLoad={false}
                  validateOnFocusOut
                  required
                />
                <Dropdown
                  placeholder="กรุณาเลือก"
                  label="สำนักงานใหญ่/สาขา"
                  /* 
                                    options={[
                                        { key: '00000', text: 'สำนักงานใหญ่' },
                                        { key: 'branchNumber', text: 'สาขาเลขที่' }                                    
                                    ]}
 */
                  options={branchDisplay}
                  //selectedKey="TXID"
                  //required={showBranch}
                  selectedKey={branch}
                  styles={{ root: { width: 160 } }}
                  onChange={(e, selectedOption) =>
                    handleBranch(selectedOption.key, language)
                  }
                  //errorMessage={branchErrMsg}
                  required
                  disabled={!registerVat}
                />

                {showBranchNumber && (
                  <TextField
                    componentRef={(input) => (branchNumberRef = input)}
                    name="branchNumber"
                    label="เลขที่"
                    styles={{ root: { width: 100 } }}
                    value={branchNumber}
                    required={showBranchNumber}
                    onChange={(e) => {
                      setBranchNumber(e.target.value);

                      if (language === 'en') {
                        setBranchText('Branch ' + e.target.value);
                      } else {
                        setBranchText('สาขา ' + e.target.value);
                      }
                    }}
                    onGetErrorMessage={getErrorMessageBranchNumber}
                    validateOnLoad={false}
                    validateOnFocusOut
                    description="ตัวอย่าง 00001 (อ้างอิงจาก ภ.พ.20)"
                  />
                )}
                <PrimaryButton
                  styles={{ root: { marginTop: 28.4 } }}
                  text="ค้นหา"
                  disabled={!isSearchCompleate}
                  iconProps={{ iconName: 'Search' }}
                  onClick={() => handleSearch()}
                />
              </Stack>

              {isSearchCompleate ? (
                <Stack horizontal tokens={stackTokens}>
                  <TextField
                    name="name"
                    label="ชื่อบริษัท"
                    styles={{ root: { width: 610 } }}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onGetErrorMessage={getErrorMessage}
                    validateOnLoad={false}
                    validateOnFocusOut
                    placeholder="บริษัท ผู้ขาย จำกัด"
                    required
                  />
                </Stack>
              ) : (
                <Stack styles={{ root: { marginTop: 28.4 } }}>
                  <Shimmer shimmerElements={shimmerRowHeight32} width="610px" />
                </Stack>
              )}
            </div>
          ) : (
            <div style={cardStyle}>
              <strong>ข้อมูลชื่อกิจการ</strong>
              <Stack
                horizontal
                tokens={stackTokens}
                styles={{ root: { marginTop: 38.4 } }}
              >
                <Shimmer shimmerElements={shimmerRowHeight32} width="220px" />
                <Shimmer shimmerElements={shimmerRowHeight32} width="160px" />
                <Shimmer shimmerElements={shimmerRowHeight32} width="100px" />
              </Stack>
              <Stack styles={{ root: { marginTop: 28.4 } }}>
                <Shimmer shimmerElements={shimmerRowHeight32} width="610px" />
              </Stack>
            </div>
          )}

          <br />
          {isLoadComplete && isSearchCompleate ? (
            <div style={cardStyle}>
              <strong>ที่อยู่สำหรับออกเอกสาร</strong>
              <Stack
                horizontal
                tokens={stackTokens}
                styles={{ root: { marginTop: 10 } }}
              >
                <TextField
                  name="buildingNumber"
                  label="บ้านเลขที่"
                  styles={{ root: { width: 150 } }}
                  value={buildingNumber}
                  onChange={(e) => setBuildingNumber(e.target.value)}
                  onGetErrorMessage={getErrorMessage}
                  validateOnLoad={false}
                  validateOnFocusOut
                  required
                />
                <TextField
                  name="address"
                  label="ที่อยู่"
                  styles={{ root: { width: 450 } }}
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  // onGetErrorMessage={getErrorMessage}
                  // validateOnLoad={false}
                  // validateOnFocusOut
                  //   required
                />
              </Stack>
              <Stack horizontal tokens={stackTokens}>
                <Dropdown
                  name="streetPrefix"
                  placeholder="กรุณาเลือก"
                  label="คำนำหน้าถนน"
                  options={[
                    { key: 'ถ.', text: 'ถ.' },
                    { key: 'ถนน', text: 'ถนน' },
                    { key: '', text: 'ไม่แสดง' },
                  ]}
                  selectedKey={streetPrefix}
                  //required={true}
                  styles={{ root: { width: 150 } }}
                  onChange={(e, selectedOption) => {
                    //console.log(selectedOption);
                    setStreetPrefix(selectedOption.key);
                  }}
                />
                <TextField
                  name="streetName"
                  label="ถนน"
                  styles={{ root: { width: 450 } }}
                  value={streetName}
                  onChange={(e) => setStreetName(e.target.value)}
                  // onGetErrorMessage={getErrorMessage}
                  // validateOnLoad={false}
                  // validateOnFocusOut
                  //   required
                />
              </Stack>
              <Stack horizontal tokens={stackTokens}>
                <Dropdown
                  name="language"
                  placeholder="กรุณาเลือก"
                  label="แสดงชื่อจังหวัด อำเภอฯ"
                  options={[
                    { key: 'th', text: 'ภาษาไทย' },
                    { key: 'en', text: 'ภาษาอังกฤษ' },
                  ]}
                  selectedKey={language}
                  //required={true}
                  styles={{ root: { width: 150 } }}
                  onChange={(e, selectedOption) => {
                    //console.log(selectedOption);
                    setLanguage(selectedOption.key);
                  }}
                />
              </Stack>
              <Stack horizontal tokens={stackTokens}>
                <Dropdown
                  name="provincePrefix"
                  placeholder="กรุณาเลือก"
                  label="คำนำหน้าจังหวัด"
                  options={[
                    { key: 'จ.', text: 'จ.' },
                    { key: 'จังหวัด', text: 'จังหวัด' },
                    { key: '', text: 'ไม่แสดง' },
                  ]}
                  selectedKey={provincePrefix}
                  //required={true}
                  styles={{ root: { width: 150 } }}
                  onChange={(e, selectedOption) => {
                    //console.log(selectedOption);
                    setProvincePrefix(selectedOption.key);
                  }}
                />
                <Dropdown
                  placeholder="กรุณาเลือก"
                  label="จังหวัด"
                  options={provinceList}
                  selectedKey={province.key}
                  //required={true}
                  styles={dropdownStyles}
                  onChange={(e, selectedOption) => {
                    console.log(selectedOption);
                    //setChoosedProvince(selectedOption.key);
                    setProvince(selectedOption);
                    setDistrict({ key: '', text: '' });
                    setDistrictList(
                      getDistrictList(selectedOption.key, language)
                    );
                    setSubDistrict({ key: '', text: '' });
                    console.log('Province changed', districtList);

                    setProvinceErrMsg('');
                    setDistrictErrMsg('');
                    setSubDistrictErrMsg('');
                  }}
                  onBlur={(e) => {
                    //console.log('onBlur');
                    console.log(province.key);
                    if (province.key === '') {
                      setProvinceErrMsg(DropdownErrMsg);
                    } else {
                      setProvinceErrMsg('');
                    }
                  }}
                  errorMessage={provinceErrMsg}
                  required
                />
              </Stack>
              <Stack horizontal tokens={stackTokens}>
                <Dropdown
                  name="districtPrefix"
                  placeholder="กรุณาเลือก"
                  label="คำนำหน้าอำเภอ"
                  options={[
                    { key: 'อ.', text: 'อ.' },
                    { key: 'อำเภอ', text: 'อำเภอ' },
                    { key: 'เขต', text: 'เขต' },
                    { key: '', text: 'ไม่แสดง' },
                  ]}
                  selectedKey={districtPrefix}
                  //required={true}
                  styles={{ root: { width: 150 } }}
                  onChange={(e, selectedOption) => {
                    //console.log(selectedOption);
                    setDistrictPrefix(selectedOption.key);
                  }}
                />
                <Dropdown
                  placeholder="กรุณาเลือก"
                  label="อำเภอ (เลือกจังหวัดก่อน)"
                  options={districtList}
                  selectedKey={district.key}
                  //required={true}
                  styles={dropdownStyles}
                  onChange={(e, selectedOption) => {
                    //console.log(selectedOption);
                    //setChoosedProvince(selectedOption.key);
                    setDistrict(selectedOption);
                    setSubDistrict({ key: '', text: '' });
                    setSubDistrictList(
                      getSubDistrictList(selectedOption.key, language)
                    );
                    console.log('District changed', subDistrictList);

                    setDistrictErrMsg('');
                    setSubDistrictErrMsg('');
                  }}
                  onBlur={(e) => {
                    //console.log('onBlur');
                    console.log(district.key);
                    if (province.key !== '' && district.key === '') {
                      setDistrictErrMsg(DropdownErrMsg);
                    } else {
                      setDistrictErrMsg('');
                    }
                  }}
                  errorMessage={districtErrMsg}
                  required
                />
              </Stack>
              <Stack horizontal tokens={stackTokens}>
                <Dropdown
                  name="subDistrictPrefix"
                  placeholder="กรุณาเลือก"
                  label="คำนำหน้าตำบล"
                  options={[
                    { key: 'ต.', text: 'ต.' },
                    { key: 'ตำบล', text: 'ตำบล' },
                    { key: 'แขวง', text: 'แขวง' },
                    { key: '', text: 'ไม่แสดง' },
                  ]}
                  selectedKey={subDistrictPrefix}
                  //required={true}
                  styles={{ root: { width: 150 } }}
                  onChange={(e, selectedOption) => {
                    //console.log(selectedOption);
                    setSubDistrictPrefix(selectedOption.key);
                  }}
                />
                <Dropdown
                  placeholder="กรุณาเลือก"
                  label="ตำบล (เลือกอำเภอก่อน)"
                  options={subDistrictList}
                  selectedKey={subDistrict.key}
                  //required={true}
                  styles={dropdownStyles}
                  onChange={(e, selectedOption) => {
                    //console.log(selectedOption);
                    //setChoosedProvince(selectedOption.key);
                    setSubDistrict(selectedOption);

                    setSubDistrictErrMsg('');
                  }}
                  onBlur={(e) => {
                    //console.log('onBlur');
                    console.log(subDistrict.key);
                    if (district.key !== '' && subDistrict.key === '') {
                      setSubDistrictErrMsg(DropdownErrMsg);
                    } else {
                      setSubDistrictErrMsg('');
                    }
                  }}
                  errorMessage={subDistrictErrMsg}
                  required
                />
              </Stack>
              <Stack horizontal tokens={stackTokens}>
                <TextField
                  name="postcode"
                  label="รหัสไปรษณีย์"
                  styles={{ root: { width: 150 } }}
                  value={postcode}
                  onChange={(e) => {
                    if (
                      !e.target.value.includes(' ') &&
                      !isNaN(e.target.value)
                    ) {
                      setPostcode(e.target.value);
                    }
                  }}
                  onGetErrorMessage={() => {
                    if (postcode.length !== 5) {
                      return 'กรุณาระบุเลขให้ครบ 5 หลัก';
                    } else {
                      getErrorMessage();
                    }
                  }}
                  validateOnLoad={false}
                  validateOnFocusOut
                  required
                />
              </Stack>
            </div>
          ) : (
            <div style={cardStyle}>
              <strong>ที่อยู่สำหรับออกเอกสาร</strong>
              <Stack
                horizontal
                tokens={stackTokens}
                styles={{ root: { marginTop: 38.4 } }}
              >
                <Shimmer shimmerElements={shimmerRowHeight32} width="150px" />
                <Shimmer shimmerElements={shimmerRowHeight32} width="450px" />
              </Stack>
              <Stack
                horizontal
                tokens={stackTokens}
                styles={{ root: { marginTop: 28.4 } }}
              >
                <Shimmer shimmerElements={shimmerRowHeight32} width="150px" />
                <Shimmer shimmerElements={shimmerRowHeight32} width="450px" />
              </Stack>
              <Stack
                horizontal
                tokens={stackTokens}
                styles={{ root: { marginTop: 28.4 } }}
              >
                <Shimmer shimmerElements={shimmerRowHeight32} width="150px" />
              </Stack>
              <Stack
                horizontal
                tokens={stackTokens}
                styles={{ root: { marginTop: 28.4 } }}
              >
                <Shimmer shimmerElements={shimmerRowHeight32} width="150px" />
                <Shimmer shimmerElements={shimmerRowHeight32} width="450px" />
              </Stack>
              <Stack
                horizontal
                tokens={stackTokens}
                styles={{ root: { marginTop: 28.4 } }}
              >
                <Shimmer shimmerElements={shimmerRowHeight32} width="150px" />
                <Shimmer shimmerElements={shimmerRowHeight32} width="450px" />
              </Stack>
              <Stack
                horizontal
                tokens={stackTokens}
                styles={{ root: { marginTop: 28.4 } }}
              >
                <Shimmer shimmerElements={shimmerRowHeight32} width="150px" />
                <Shimmer shimmerElements={shimmerRowHeight32} width="450px" />
              </Stack>
              <Stack
                horizontal
                tokens={stackTokens}
                styles={{ root: { marginTop: 28.4 } }}
              >
                <Shimmer shimmerElements={shimmerRowHeight32} width="150px" />
              </Stack>
            </div>
          )}

          <br />
          {isLoadComplete ? (
            <div style={cardStyle}>
              <strong>ลูกค้าติดต่อที่</strong>
              <Stack
                horizontal
                tokens={stackTokens}
                styles={{ root: { marginTop: 10 } }}
              >
                <TextField
                  name="phone"
                  label="เบอร์โทร"
                  styles={{ root: { width: 300 } }}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  onGetErrorMessage={getPhoneNumberErrorMessage}
                  validateOnLoad={false}
                  validateOnFocusOut
                  required
                />
                <TextField
                  name="email"
                  label="อีเมล"
                  styles={{ root: { width: 300 } }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onGetErrorMessage={getErrorMessage}
                  validateOnLoad={false}
                  validateOnFocusOut
                  required
                />
              </Stack>
            </div>
          ) : (
            <div style={cardStyle}>
              <strong>ลูกค้าติดต่อที่</strong>
              <Stack
                horizontal
                tokens={stackTokens}
                styles={{ root: { marginTop: 38.4 } }}
              >
                <Shimmer shimmerElements={shimmerRowHeight32} width="300px" />
                <Shimmer shimmerElements={shimmerRowHeight32} width="300px" />
              </Stack>
            </div>
          )}
          {/* <br />
          {isLoadComplete ? (
            <Stack horizontal tokens={stackTokens}>
              <TextField
                componentRef={(input) => (taxIdRef = input)}
                name="taxId"
                label="เลขประจำตัวผู้เสียภาษี 13 หลัก"
                styles={{ root: { width: 220 } }}
                value={taxId}
                onChange={(e) => {
                  console.log('Tax id lenght: ', e.target.value);
                  setTaxId(e.target.value);
                }}
                onGetErrorMessage={getErrorMessageTaxId}
                validateOnLoad={false}
                validateOnFocusOut
                required
              />
            </Stack>
          ) : (
            <Shimmer shimmerElements={shimmerRowThree} width="220px" />
          )} */}
          <br />
          {isLoadComplete && isSearchCompleate ? (
            <div style={cardStyle}>
              <Stack horizontal tokens={{ childrenGap: 15 }}>
                <Dropdown
                  placeholder="กรุณาเลือก"
                  label="จดทะเบียนภาษีมูลค่าเพิ่ม"
                  options={[
                    { key: true, text: 'ได้จดทะเบียน ภ.พ. 20' },
                    { key: false, text: 'ไม่ได้จดทะเบียน ภ.พ. 20' },
                  ]}
                  //options={branchDisplay}

                  //selectedKey="TXID"
                  //required={showBranch}
                  selectedKey={registerVat}
                  styles={{ root: { width: 220 } }}
                  onChange={(e, selectedOption) => {
                    setRegisterVat(selectedOption.key);
                    handleBranch('00000', language);
                  }}
                  //errorMessage={branchErrMsg}

                  required
                />
              </Stack>
            </div>
          ) : (
            <div style={cardStyle}>
              <Stack
                horizontal
                tokens={{ childrenGap: 15 }}
                styles={{ root: { marginTop: 28.4 } }}
              >
                <Shimmer shimmerElements={shimmerRowHeight32} width="220px" />
              </Stack>
            </div>
          )}
          <br />
          <br />
          <Separator>กดปุ่มเพื่อบันทึกข้อมูล</Separator>
          <br />
          <br />
          {/* <PrimaryButton text="บันทึก" onClick={_alertClicked} type="submit" /> */}

          <div align="center">
            {!buttonClick && (
              <PrimaryButton
                name="save"
                text="บันทึก"
                iconProps={{ iconName: 'Save' }}
                type="submit" /* onClick={handleSubmit} */
              />
            )}
            {/* <Spinner label="กำลังบันทึกข้อมูล..." labelPosition="right" size={SpinnerSize.large} /> */}
            {buttonClick && (
              <Spinner label="กำลังบันทึก..." labelPosition="right" />
            )}
          </div>
          <br />
          <br />

          {/* </div> */}
        </div>

        {/* <Controller as ={TextField} control={control} name="companyName" label="ชื่อบริษัท" />
                    <Controller as ={TextField} control={control} name="buildingNumber" label="บ้านเลขที่" />
                    <Controller as ={TextField} control={control} name="address" label="ที่อยู่" /> */}
        {/* <Controller as ={Province} control={control} name="ProvinceData" />  */}
        {/* <SubDistrict /> */}
        {/* <District /> */}
        {/* <Controller as ={<District />} control={control} name="districtData" /> */}

        {/* <Province selectedProvince={ choosedProvince => setProvince(choosedProvince) } /> */}

        {/* <District selectedDistrict={ choosedDistrict => setDistrict(choosedDistrict) } /> */}

        {/* <SubDistrict selectedSubDistrict={ choosedSubDistrict => setSubDistrict(choosedSubDistrict) } /> */}

        <br />
        <br />
      </form>
    </Stack>
  );
};

export default Companies;
