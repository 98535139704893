import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import axios from 'axios';

import { useMsal, useAccount } from "@azure/msal-react";
import { silentRequest } from "../../../authConfig";
//import b2cauth from 'react-azure-adb2c';

//import { TextField, MaskedTextField } from 'office-ui-fabric-react/lib/TextField';
//import { TextField } from 'office-ui-fabric-react/lib/TextField';
//import { Stack } from 'office-ui-fabric-react/lib/Stack';
//import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
//import { PrimaryButton } from 'office-ui-fabric-react';
//import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
//import { useForm, Controller  } from "react-hook-form";

import { useHistory, useLocation } from 'react-router-dom';

import { 
    TextField,     
    mergeStyles, 
    PrimaryButton, 
    DefaultButton, 
    IconButton, 
    //Checkbox, 
    Dropdown, 
    Stack, 
    getTheme, 
    Shimmer,  
    ShimmerElementType,    
    Spinner,
    SpinnerSize,
    MessageBarButton,
    MessageBar,
    MessageBarType,   
    ChoiceGroup,
    Separator
    
    
} from '@fluentui/react';

//import Province from './address/Province';
//import District from './address/District';
//import SubDistrict from './address/SubDistrict';
//import Branch from './address/Branch';

const API_URL = process.env.REACT_APP_API_URL;

const theme = getTheme();

const stackTokens = { childrenGap: 15 };

const dropdownStyles = {
    dropdown: { width: 250 },
};

//const iconProps = { iconName: 'Calendar' };
//const stackStyles = { root: { width: 800 } };
/* 
const _alertClicked = () => {
    alert('Clicked');
}
 */

//initializeIcons();

//center loading
const centerItem = { display: "flex", alignItems: "center", justifyContent: "center" };

const centerScreen = {
    position: "fixed",
    top: "50%",
    left: "50%",
    //marginTop: "-58px",
    //marginLeft: "-50px",
    //marginRight: "-50%",
    transform: "translate(-50%, -50%)"
}


const Complete = () => {

    console.log('Re render');

    const history = useHistory();
    const location = useLocation();

    const { orderId } = useParams();

    console.log('Order id: ', orderId);

    //const [data] = useState(location.state);

    const [id, setId] = useState('');
    const [email, setEmail] = useState('');
    const [createdTime, setCreatedTime] = useState('');
    const [description, setDescription] = useState('');
    //const [planName, setPlanName] = useState('');
    //const [planEnd, setPlanEnd] = useState('');
    const [grandTotal, setGrandTotal] = useState('');
    const [paymentStatus, setPaymentStatus] = useState(true);
    const [paymentSuccess, setPaymentSuccess] = useState(true);
    const [isLoadComplete, setIsLoadComplete] = useState(false);
    //const [period, setPeriod] = useState('');

    //console.log('data: ', data);

    if (orderId && (orderId === '')) {
        history.push({
            //pathname: '/etax/signatures/register/companies',
            pathname: '/billings',
            //search: '?update=true',  // query string
            state: {}
        })
    }

    //const auth = Auth();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});    


/* 
    useEffect(() => {
        console.log('useEffect data call!: ', data);

        if (data !== undefined) {
            

        }


    }, [data]);

 */
    useEffect(() => {
        console.log('userEffect retrieve order data!');

        //this.token = b2cauth.getAccessToken();
        //b2cauth.run();
        //let token = await AccessToken();
        instance.acquireTokenSilent({...silentRequest, account: account}).then(tokenResponse => {

            //axios.get('https://etax.azure-api.net/company/b5cf38c9-5e52-4bd3-b737-caf5c6d30635')
            //axios.get('https://etax.azure-api.net/company/' + userId)
            axios.get(API_URL + '/etax/orders/' + orderId, 
                {
                headers: { 
                    'Authorization': 'Bearer ' + tokenResponse.accessToken
                } 
                })
                .then((response) => {                    

                    console.log(response);

                    if (response.data && (response.data.payment !== null)) {

                        //console.log(JSON.stringify(response.data));
                        console.log('Order data: ', response.data);
                        setPaymentStatus(response.data.payment.status);
                        setId(response.data.orderId);
                        setCreatedTime(new Date(response.data.payment.createdTime).toLocaleDateString('th-TH', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            timeZone: 'Asia/Bangkok',
                            hour: '2-digit', 
                            minute: '2-digit',          
                            second: '2-digit'                  
                        }) + ' น.');
                        //setPeriod(response.data.subscription.duration.period);

                        if (response.data.payment.status === "successful") {

                            setPaymentSuccess(true);
/*                             
                            setPlanEnd(new Date(response.data.subscription.to).toLocaleDateString('th-TH', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                                timeZone: 'Asia/Bangkok'
                                //hour: '2-digit', 
                                //minute: '2-digit'
                            }) + ' 23:59:59 น.');
 */
                        } else {

                            setPaymentSuccess(false);
                            //setPlanEnd('N/A');

                        }

                        setEmail(response.data.email);
                        setDescription(response.data.description);
                        //setPlanName(response.data.subscription.plan.name);
                        //setPlanEnd(response.data.subscription.plan.end);
                        setGrandTotal((response.data.grandTotal).toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2}));

                        setIsLoadComplete(true);

                    } else {

                        history.push({
                            //pathname: '/etax/signatures/register/companies',
                            pathname: '/billings',
                            //search: '?update=true',  // query string
                            state: {}
                        })

                    }
                        
                    
                }, (error) => {
                    console.log(error);
                    setIsLoadComplete(true);
                });

        }).catch(error => {
            /* 
            if (error instanceof InteractionRequiredAuthError) {
                // fallback to interaction when silent call fails
                return instance.acquireTokenRedirect(tokenRequest)
            }
     */
    
            //Acquire token silent failure, and send an interactive request
            console.log(error);
            instance.acquireTokenRedirect(silentRequest);
            
        });


    }, []);



    return (
        <div>                       
                
                {/* <h2 style={{ margin: '2%' }}>ชำระเงินสำเร็จ</h2> */}
                {/* <div style={{ margin: '2%' }}>(ข้อมูลทั้งหมดข้างล่างนี้ ใช้สำหรับแสดงบนหัวเอกสารของท่าน)</div> */}
                
                { isLoadComplete
                ?
                <center>
                    {/* <div className="ms-Grid" dir="ltr" style={{ width: 500, height: 736, paddingLeft: 30, paddingRight: 30, paddingTop: 20, paddingBottom: 30, background: '#ffffff', margin: '2%', boxShadow: theme.effects.elevation4 }}>  */}
                    {/* <div className="ms-Grid" dir="ltr" style={{ width: 500, height: 736, paddingLeft: 30, paddingRight: 30, paddingTop: 20, paddingBottom: 30, background: '#ffffff', marginTop: '50px', boxShadow: theme.effects.elevation4 }}>  */}
                    <div className="ms-Grid" dir="ltr" style={{ width: 510, height: 736, paddingLeft: 30, paddingRight: 30, paddingTop: 20, paddingBottom: 30, background: '#ffffff', marginTop: '50px', boxShadow: theme.effects.elevation4 }}> 
                        <div className="ms-Grid-row">
                            { paymentSuccess 
                                ?
                                    <h2 style={{ margin: '2%', color: '#0078D4' }}>ชำระเงินสำเร็จ</h2>
                                :
                                    <h2 style={{ margin: '2%', color: '#0078D4' }}>ชำระเงินไม่สำเร็จ</h2>
                            }
                            {/* <Separator/> */}
                            {/*<br />
                            <br />                         */}
                            { paymentSuccess 
                                ?
                                    <img  height="180" src="/images/success03.gif" />                        
                                :
                                    <img  height="180" src="/images/fail01.gif" />                        
                            }
                            { paymentSuccess 
                                ?
                                    <h3 style={{ margin: '2%', color: '#656766' }}>รายละเอียดการชำระเงิน</h3>
                                :
                                    <h3 style={{ margin: '2%', color: '#656766' }}>กรุณาลองใหม่อีกครั้ง</h3>
                            }
                        </div>
                        <br/>
                        <Separator/>
                        <br /> 
                        {/* 
                       
                         
                        <div className="ms-Grid-row" style={{ color: '#656766' }}>
                            
                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3" style={{ textAlign: 'right' }}><b>ช่องทาง :</b></div>
                            <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9" style={{ textAlign: 'left' }}>&nbsp;&nbsp;บัตรเครดิต/บัตรเดบิต</div>
                            
                        </div>
                        <br/>
                        <div className="ms-Grid-row" style={{ color: '#656766' }}>
                            
                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3" style={{ textAlign: 'right' }}><b>ใช้ได้ถึง :</b></div>
                            <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9" style={{ textAlign: 'left' }}>&nbsp;&nbsp;{planEnd}</div>
                            
                        </div>
                        <br/>*/}
                        <div className="ms-Grid-row" style={{ color: '#656766' }}>
                            
                        {/*     <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3" style={{ textAlign: 'right' }}><b>ชื่อแพ็กเกจ :</b></div>
                            <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9" style={{ textAlign: 'left' }}>&nbsp;&nbsp;{planName}</div> */}
                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3" style={{ textAlign: 'right' }}><b>รายละเอียด :</b></div>
                            <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9" style={{ textAlign: 'left' }}>&nbsp;&nbsp;{description}</div>
                            
                        </div>
    {/*                     <br/>
                        <div className="ms-Grid-row" style={{ color: '#656766' }}>
                            
                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3" style={{ textAlign: 'right' }}><b>ชำระค่าบริการ :</b></div>
                            <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9" style={{ textAlign: 'left' }}>&nbsp;&nbsp;{period} เดือน</div> 
                            
                        </div>
    */}
                        <br/>
                        <div className="ms-Grid-row" style={{ color: '#656766' }}>
                            
                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3" style={{ textAlign: 'right' }}><b>จำนวนเงิน :</b></div>
                            <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9" style={{ textAlign: 'left' }}>&nbsp;&nbsp;{grandTotal} บาท</div>
                            
                        </div> 
                        <br/>   
                        <div className="ms-Grid-row" style={{ color: '#656766' }}>
                            
                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3" style={{ textAlign: 'right' }}><b>ผู้ใช้งาน :</b></div>
                            <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9" style={{ textAlign: 'left' }}>&nbsp;&nbsp;{email}</div>
                            
                        </div>
                        <br /> 
                                            
                        <Separator/>
                        <br /> 
                        <div className="ms-Grid-row" style={{ color: '#656766' }}>
                            
                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3" style={{ textAlign: 'right' }}><b>วันที่ทำรายการ :</b></div>
                            <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9" style={{ textAlign: 'left' }}>&nbsp;&nbsp;{createdTime}</div>
                            
                        </div>
                        <br/>     
                        <div className="ms-Grid-row" style={{ color: '#656766' }}>
                            
                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3" style={{ textAlign: 'right' }}><b>เลขที่รายการ :</b></div>
                            <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9" style={{ textAlign: 'left' }}>&nbsp;&nbsp;<small>{id}</small></div>
                            
                        </div>                     
                                       
                        
                        
                        
                        {!paymentSuccess &&
                        <div>
                        <br/>
                        <div className="ms-Grid-row" style={{ color: '#656766' }}>
                            
                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3" style={{ textAlign: 'right' }}><b>สถานะ :</b></div>
                            <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9" style={{ textAlign: 'left' }}>&nbsp;&nbsp;ทำรายการไม่สำเร็จ</div>
                            
                        </div>  
                        </div>
                        }                
                        <br />              
                        <Separator/>

                            
                        {/* <br/> */}
                        {/* <br/> */}
                        {/* <img  src="/images/favicon-32x32.png" />ขอบคุณที่ใช้บริการ */}
                        {/* <div style={{ margin: '2%', color: '#656766' }}>😃 ขอบคุณที่ใช้บริการ 🙏</div> */}
                        {/* { paymentSuccess &&                        
                            <div style={{ margin: '2%', color: '#656766' }}>ขอบคุณที่ใช้บริการ</div>                        
                        } */}
                        {/* <br />  */}
                        <br /> 
                        {/* <br />   */}                 
                        <PrimaryButton 
                        //type="submit"
                        //name="submit"

                            onClick={ () => { 
                                    //window.location.href = '/billing';

                                    history.push({
                                        //pathname: '/billing',
                                        pathname: '/billings',
                                        //search: '?update=true',  // query string
                                        state: {}
                                    }); 


                                } 
                            }  
                            
                            text={ paymentSuccess ? "รายละเอียด" : "ลองอีกครั้ง" }
                        />
                            
                        
                            
                            
                        
                        
                    
                    </div>
                    </center>
                :
                <div>    
                    <br/> 
                    <br/>          
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <div style={centerItem}>
                        <img width="60" src="/images/loading.gif"/>
                    </div>
                </div>
/* 
                <Stack horizontal disableShrink>
                <Stack grow>
                    <Stack verticalAlign="center" styles={{
                        root: {                            
                            height: 500
                        },}}>
                        <div style={centerItem}>
                            <img height="120" src="/images/loading.gif"/>
                        </div>
                    </Stack>
                </Stack>
                </Stack>
                 */
                }
                
                    
                    
                
           
            
        </div>
    );
}

export default Complete;